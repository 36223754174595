import React, { useMemo } from 'react';
import { Dropdown, Loader } from 'semantic-ui-react';
import { SatCoreRegister } from '../SatCoreRegistry';
import '../css/NestedDropdown.less';

const convertToLinearOptions = (options, level = 1) => options.reduce((result, { children, className, ...option }) => {
  result.push({
    ...option,
    className: `option-level${level}${className ? ` ${className}` : ''}`,
    as: (props) => <div {...props} ref={(el) => el?.style?.setProperty('padding', `4px ${25 * level}px`, 'important')} />
  });
  children && result.push(...convertToLinearOptions(children, level + 1));
  return result;
}, []);

// eslint-disable-next-line no-unused-vars
const NestedDropdown = ({ options, isLoading, className, i18n, t, tReady, ...props }) => {
  const linearOptions = useMemo(() => (isLoading ?
    [{ key: 'loader', text: <div><Loader active inline='centered' /></div>, className: 'option-loader', disabled: true, selected: false }] :
    convertToLinearOptions(options)),
  [options, isLoading]
  );
  return <Dropdown className={['nested-dropdown', className].filter((klass) => klass).join(' ')} options={linearOptions} {...props} />;
};

export default NestedDropdown;

SatCoreRegister('NestedDropdown', NestedDropdown);
