import Auth from '../managers/AuthManager';
import { generateUrl } from '../utils/url';

/** @deprecated functions in this class (in some cases) seem to be causing unexpected behavior when called by other async functions */
class BaseApi {
  getUrl(endpoint, params) {
    return generateUrl(`${Auth.ecms}${endpoint}`, params);
  }

  // Delegate methods to Auth
  async fetch(url, options) {
    return await Auth.safe_fetch(url, options);
  }

  async post(url, options) {
    return await Auth.post(url, options);
  }

  async put(url, options) {
    return await Auth.put(url, options);
  }

  async delete(url, options) {
    return await Auth.delete(url, options);
  }
}

export default BaseApi;
