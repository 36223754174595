/* eslint-disable sort-keys */
/**
 * Convert a Date object to a specified string format
 *
 * |Strategy          |Equivalent to       |Examples (given in en-US locale)|
 * |---               |---                 |---                             |
 * |'fullDate'        |'EEEE, MMMM d, y'   |Friday, August 13, 2021         |
 * |'fullDateNoYear'  |'EEEE, MMMM d'      |Friday, August 13
 * |'twoDigitDate'    |'MM/dd/yy'          |08/13/21                        |
 * |'twoDigitTime'    |'hh:mm:ss a'        |07:05:17 PM                     |
 * |'twoDigitDateTime'|'MM/d/yy, h:mm:ss a |08/13/21, 07:05:17 PM           |
 * |null (default)    |'M/dd/yyyy'         |8/13/2021                       |
 *
 * @param {Date | string | number | null} date
 * @param {'fullDate' | 'fullDateNoYear' | 'twoDigitDate' | 'twoDigitTime' | 'twoDigitDateTime' | null} strategy
 * @param locale
 */
export const dateFormat = (date, strategy, locale = 'en-US') => {
  if (!date) {
    return 'N/A';
  }
  switch (strategy) {
  case 'fullDate':
    return new Date(date).toLocaleDateString(locale, {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
    });
  case 'fullDateNoYear':
    return new Date(date).toLocaleDateString(locale, {
      weekday: 'long', month: 'long', day: 'numeric'
    });
  case 'twoDigitDate':
    return new Date(date).toLocaleDateString(locale, {
      year: '2-digit', month: '2-digit', day: '2-digit'
    });
  case 'twoDigitTime':
    return new Date(date).toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit',
    });
  case 'twoDigitDateTime':
    return new Date(date).toLocaleDateString(locale, {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  default:
    return new Date(date).toLocaleDateString(locale, {});
  }
};

export const DATE_FORMAT_STRATEGIES = [
  'fullDate', 'fullDateNoYear', 'twoDigitDate', 'twoDigitTime', 'twoDigitDateTime'
];
