/* eslint-disable sort-keys */
import iconTeacherAssign from '../img/pacing/icon-teacher-assign.svg';
import iconStudentPaced from '../img/pacing/icon-student-paced.svg';
import iconVisibleToStudents from '../img/pacing/icon-visible-to-students.svg';
import iconHiddenFromStudents from '../img/pacing/icon-hidden-from-students.svg';
import iconDuration from '../img/pacing/icon-duration.svg';
import iconAttempts from '../img/pacing/icon-attempts.svg';
import iconAssigned from '../img/pacing/icon-assigned.svg';
import iconManualGrade from '../img/pacing/icon-manual-grade.svg';

import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';

import courseDeliveryManager from '../managers/CourseDeliveryManager';

export const RESOURCE_PACING_TOOLBAR_ITEM = {
  ASSIGNED: {
    DASHED_KEY: 'assigned',
    KEY: 'assigned',
    ICON: iconAssigned
  },
  TEACHER_ASSIGN: {
    DASHED_KEY: 'teacher-assign',
    KEY: 'teacherAssign',
    ICON: iconTeacherAssign
  },
  STUDENT_PACED: {
    DASHED_KEY: 'student-paced',
    KEY: 'studentPaced',
    ICON: iconStudentPaced
  },
  VISIBLE_TO_STUDENTS: {
    DASHED_KEY: 'visible-to-students',
    KEY: 'visibleToStudents',
    ICON: iconVisibleToStudents
  },
  HIDDEN_FROM_STUDENTS: {
    DASHED_KEY: 'hidden-from-students',
    KEY: 'hiddenFromStudents',
    ICON: iconHiddenFromStudents
  },
  AVAILABlE_UNTIL: {
    DASHED_KEY: 'available_until',
    KEY: 'availableUntil',
    ICON: iconDuration
  },
  DURATION: {
    DASHED_KEY: 'duration',
    KEY: 'duration',
    ICON: iconDuration
  },
  REPORTS_AND_GRADING: {
    DASHED_KEY: 'reports-and-grading',
    KEY: 'reportsAndGrading',
    ICON: null
  },
  ATTEMPTS: {
    DASHED_KEY: 'attempts',
    KEY: 'attempts',
    ICON: iconAttempts
  },
  MANUAL_SCORING: {
    DASHED_KEY: 'manual-scoring',
    KEY: 'manualScoring',
    ICON: iconManualGrade
  },
  INSTRUCTIONS: {
    DASHED_KEY: 'instructions',
    KEY: 'instructions',
    ICON: null
  },
  MODE: {
    DASHED_KEY: 'mode',
    KEY: 'mode',
    ICON: null
  }
};

export const INIT_DURATION_DAYS_TEACHER_ASSIGNED = 1;
export const INIT_DURATION_HOURS_TEACHER_ASSIGNED = 0;

export const INIT_DURATION_DAYS_STUDENT_PACED = 30;
export const INIT_DURATION_HOURS_STUDENT_PACED = 0;

const { ASSESSMENT, LESSON, LEARNOSITY_ACTIVITY_RESOURCE, PDF_RESOURCE } = CONTENT_ITEM_TYPES;

/** note: order matters here, do not alphabetize */
export const RESOURCE_PILL = {
  interactive: {
    supportedModes: [ASSESSMENT, LESSON, LEARNOSITY_ACTIVITY_RESOURCE],
    value: 'interactive'
  },
  autoScored: {
    supportedModes: [ASSESSMENT, LESSON, LEARNOSITY_ACTIVITY_RESOURCE],
    value: 'autoScored'
  },
  manualScored: {
    supportedModes: [ASSESSMENT, LESSON, LEARNOSITY_ACTIVITY_RESOURCE, PDF_RESOURCE],
    value: 'manualScored'
  },
  pdfPlus: {
    supportedModes: [PDF_RESOURCE],
    value: 'pdfPlus'
  }
};

export const ONE_DAY = 24;
export default class ResourcePacingService {
  /**
   * @param {ToolbarItem} toolbarItem
   */
  static allowResourceToolbarItem = (toolbarItem) => {
    if (toolbarItem) {
      const { resourcePacingToolbarItemKeysToExclude } = courseDeliveryManager;
      return !resourcePacingToolbarItemKeysToExclude.includes(toolbarItem.KEY);
    } else {
      throw new TypeError('ResourcePacingService.allowResourceToolbarItem: toolbarItem is required');
    }
  }

  static shouldAutoToggleResourceToolbarItemWithoutOpeningModal = (toolbarItem) => {
    if (!toolbarItem) {
      return false;
    }
    const { resourcePacingToolbarItemKeysToAutoToggleWithoutOpeningModal } = courseDeliveryManager;
    return resourcePacingToolbarItemKeysToAutoToggleWithoutOpeningModal.includes(toolbarItem.KEY);
  }

  static getDurationString(duration) {
    const days = this.getDurationDays(duration);
    const hours = this.getDurationHours(duration);
    const daysStr = `${days} ${days === 1 ? 'Day' : 'Days'}`;
    const hoursStr = `${hours} ${hours === 1 ? 'Hour' : 'Hours'}`;
    return `${daysStr}, ${hoursStr}`;
  }

  static getTotalDuration(durationDays = 0, durationHours = 0) {
    return +durationHours + (+durationDays * ONE_DAY);
  }

  static getDurationDays(duration) {
    return Math.floor(+duration / ONE_DAY);
  }

  static getDurationHours(duration) {
    return +duration % ONE_DAY;
  }

  /** @param {{ courseElement, resourcePillKeys: AdditionalResourcePillKey[] }} */
  static getAvailableResourcePills = ({ courseElement, resourcePillKeys = [] } = {}) => {
    resourcePillKeys = resourcePillKeys || courseDeliveryManager.allowedResourcePillKeys || [];
    const availableResourcePills = resourcePillKeys.filter((resourcePillKey) => {
      if (resourcePillKey === 'autoScored') {
        return courseElement?.autoScored;
      } else if (resourcePillKey === 'manualScored') {
        return courseElement?.deliveryMode?.includes('assign') && courseElement?.hasManualScoredItems;
      } else {
        return true;
      }
    }).map((resourcePillKey) => {
      return RESOURCE_PILL[resourcePillKey];
    });
    return availableResourcePills;
  }

  /**
   * **deprecated** - we are phasing this method out
   *
   * check directly for `resource.pacingModeOverride` instead
   *
   * @return {string} `pacingMode` as `string`
   */
  static pacingMode(resource) {
    // const { pacingMode, pacingModeOverride } = resource;
    // return pacingModeOverride ? pacingModeOverride : pacingMode;
    return resource.pacingModeOverride;
  }

  /**
   * **deprecated** - we are phasing this method out
   *
   * check directly for `resource.visibilityModeOverride` instead
   *
   * @return {string} `visibilityMode` as `string`
   */
  static visibilityMode(resource) {
    let { visibilityMode, visibilityModeOverride } = resource;
    visibilityMode = visibilityMode && visibilityMode !== 'null' ? visibilityMode : null;
    visibilityModeOverride = visibilityModeOverride && visibilityModeOverride !== 'null' ? visibilityModeOverride : null;
    if (visibilityModeOverride || visibilityMode) {
      // return visibilityModeOverride ? visibilityModeOverride : visibilityMode;
      return visibilityModeOverride;
    }
    return 'visible_to_students';
  }

  /**
   * **deprecated** - we are phasing this method out
   *
   * check directly for `resource.durationOverride` instead
   *
   * @return {number} `duration` as `number`
   */
  static duration(resource) {
    // const { duration, durationOverride } = resource;
    // return durationOverride ? durationOverride : duration;
    return resource.durationOverride;
  }

  /**
   * **deprecated** - we are phasing this method out
   *
   * check directly for `resource.includeInReportsOverride` instead
   *
   * @return {boolean} `includeInReports` as `boolean`
   */
  static includeInReports(resource) {
    // const { includeInReports, includeInReportsOverride } = resource;
    // return includeInReportsOverride === true || includeInReportsOverride === false ? includeInReportsOverride : includeInReports;
    return resource.includeInReportsOverride;
  }

  /**
   * **deprecated** - we are phasing this method out
   *
   * check directly for `resource.scoresReleasedOverride` instead
   *
   * @return {boolean} `scoresReleased` as `boolean`
   */
  static scoresReleased(resource) {
    // const { scoresReleased, scoresReleasedOverride } = resource;
    // return scoresReleasedOverride === true || scoresReleasedOverride === false ? scoresReleasedOverride : scoresReleased;
    return resource.scoresReleasedOverride;
  }

  /**
   * **deprecated** - we are phasing this method out
   *
   * check directly for `resource.studentReviewOverride` instead
   *
   * @return `studentReview` as `boolean`
   */
  static studentReview(resource) {
    // const { studentReview, studentReviewOverride } = resource;
    // return studentReviewOverride === true || studentReviewOverride === false ? studentReviewOverride : studentReview;
    return resource.studentReviewOverride;
  }

  /**
   * **deprecated** - we are phasing this method out
   *
   * check directly for `resource.attemptsOverride` instead
   *
   * @return {number} `attempts` as `number`
   */
  static attempts(resource) {
    // const { attempts, attemptsOverride } = resource;
    // return attemptsOverride ? attemptsOverride : attempts;
    return resource.attemptsOverride;
  }

  /**
   * **deprecated** - we are phasing this method out
   *
   * check directly for `resource.endDateOverride` instead
   *
   * @return {string} `dueDate` as `string`
   */
  static dueDate(resource) {
    // const { attempts, attemptsOverride } = resource;
    // return attemptsOverride ? attemptsOverride : attempts;
    return resource.endDateOverride;
  }
}

/**
 * @typedef {{ DASHED_KEY: string, KEY: string, ICON: any }} ToolbarItem
 */

/**
 * @typedef {'autoScored' | 'interactive' | 'manualScored' | 'pdfPlus'} AdditionalResourcePillKey
 */
