import { action, computed, makeObservable, observable } from 'mobx';
import ClassroomRosterApi from '../api/ClassroomRosterApi';

export const DefaultRoster = { purchaseVerified: true, trialExpired: false };

export class ClassroomRosterManager {
  // Load it only if user is student and trial feature is turned on
  @observable currentRoster = { ...DefaultRoster };

  constructor() {
    makeObservable(this);
  }

  @computed get isPurchaseVerified() {
    return this.currentRoster.purchaseVerified;
  }

  @computed get isExpired() {
    return this.currentRoster.trialExpired && !this.currentRoster.purchaseVerified;
  }

  @action setCurrentRoster = (roster) => {
    this.currentRoster = roster || { ...DefaultRoster };
  };

  @action async fetchCurrentRoster(userId = null, classroomId = null) {
    this.setCurrentRoster(await ClassroomRosterApi.getByStudent(userId, classroomId));
  }
}

export default new ClassroomRosterManager();
