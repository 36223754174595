import React, { useEffect, useState } from 'react';

import { Button, Icon } from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/LibraryStandardsTagsModal.less';
import UtilityService from '../../services/UtilityService';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

const ModalBanner = SatCoreComponent('ModalBanner');

const Standard = (props) => {
  const { standard } = props;
  const { id, name, description } = standard;
  const newParsedDesc = (description) ? UtilityService.reactHtmlParserWrapper(description).parsed : '';
  return (
    <div key={id} className='standard'>
      <span className='name'>{name}</span>
      <span className='description'>{newParsedDesc}</span>
    </div>
  );
};

const Document = (props) => {
  const { document, isOpen, toggleDocState } = props;
  const { id, name, displayName, standards } = document;

  return (
    <React.Fragment key={id}>
      <div className='document clickable' data-value={id} onClick={toggleDocState}>
        <Icon name={(isOpen) ? 'caret down' : 'caret right'} />
        <span>{displayName || name}</span>
      </div>
      {isOpen && (
        <div className='standards'>
          {standards.map((standard) => {
            return (
              <Standard key={standard.id} standard={standard} />
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

const Tag = (props) => {
  const { tag } = props;
  const { id, name } = tag;

  return (
    <span key={id} className='tag'>{name}</span>
  );
};

const Category = (props) => {
  const { category, isOpen, toggleCategoryState } = props;
  const { id, name, tags } = category;

  return (
    <React.Fragment key={id}>
      <div className='category clickable' data-value={id} onClick={toggleCategoryState}>
        <Icon name={(isOpen) ? 'caret down' : 'caret right'} />
        <span>{name}</span>
      </div>
      {isOpen && (
        <div className='tags'>
          {tags.map((tag) => {
            return (
              <Tag key={tag.id} tag={tag} />
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

const LibraryStandardsTagsModal = (props) => {
  const { libraryStandardsTagsModalOpen, close, modalTitle, modalType, name, description, standards, tags, text, type, guid, t } = props;
  const { documents } = standards;
  const hasStandards = documents && documents.length > 0;
  const hasTags = tags && tags.length > 0;
  //const isAssessmentOrLesson = type && (type.toLowerCase() === 'assessment' || type.toLowerCase() === 'lesson');
  const entityIDPrefix = (modalType === 'item-details' ? 'Item' : type || 'Assessment');
  const entityTypePrefix = modalType === 'item-details' ? 'Item' : ''
  
  const [openDocs, setOpenDocs] = useState({});
  const [openCategories, setOpenCategories] = useState({});
  const [showSystemInfo, setShowSystemInfo] = useState(false);

  const newParsedText = (text) ? UtilityService.reactHtmlParserWrapper(text).parsed : null;

  const toggleDocState = (event) => {
    const id = event.target.dataset.value || event.target.parentNode.dataset.value;
    setOpenDocs((prevState) => {
      return { ...prevState, [id]: !prevState[id] };
    });
  };

  const toggleCategoryState = (event) => {
    const id = event.target.dataset.value || event.target.parentNode.dataset.value;
    setOpenCategories((prevState) => {
      return { ...prevState, [id]: !prevState[id] };
    });
  };

  const toggleSystemInfo = (event) => {
    setShowSystemInfo(!showSystemInfo)
  };

  useEffect(() => {
    const docs = {};
    if (hasStandards) {
      documents.forEach((document, i) => {
        // initially set just the first document as open
        docs[document.id] = i === 0;
      });
      setOpenDocs(docs);
    }
    const categories = {};
    if (hasTags) {
      tags.forEach((category, i) => {
        // initially set just the first document as open
        categories[category.id] = i === 0;
      });
      setOpenCategories(categories);
    }
  }, []);

  return (
    <Modal
      className='library-standards-documents-modal'
      closeOnDimmerClick={true}
      closeOnEscape={true}
      open={libraryStandardsTagsModalOpen}>
      <ModalBanner
        onClose={close} title={modalTitle} />
      <Modal.Content>
        {(modalType === 'item-details' || modalType === 'assessment-details' || modalType === 'resource-view-details') && (
          <>
            {newParsedText && (
              <>
                <div className={`${modalType} standards-label`}>Question Stem</div>
                <div className='standards-underline' />
                {text && (
                  <div className='container'>
                    <div className='text-content'>
                      {newParsedText}
                    </div>
                  </div>
                )}   
              </>          
            )}
            {modalType !== 'resource-details' && (
              <>
                <div className={`${modalType} standards-label`}>Standards</div>
                <div className='standards-underline' />
              </>
            )}
            {hasStandards && (
              <div className='container'>
                {documents.map((document) => {
                  return (
                    <Document
                      key={document.id}
                      data-id={document.id}
                      document={document}
                      isOpen={openDocs[document.id]}
                      toggleDocState={toggleDocState} />
                  );
                })}
              </div>
            )}
            {!hasStandards && <div className='container'><div className='document'>No standards</div></div>}
            {modalType !== 'resource-details' || modalType === 'resource-view-details' && (
              <>
                <div className={`${modalType} standards-label`}>Tags</div>
                <div className='standards-underline' />
              </>
            )}
            {hasTags && (
              <div className='container'>
                {tags.map((category) => {
                  return (
                    <Category
                      key={category.id}
                      category={category}
                      isOpen={openCategories[category.id]}
                      toggleCategoryState={toggleCategoryState} />
                  );
                })}
              </div>
            )}
            {!hasTags && <div className='container'><div className='category'>No tags</div></div>}
            <div className='standards-underline' />
            <div className='container'>
              <React.Fragment key={'system-info-section'}>
                <div className={`${modalType} standards-label`}>
                  <div className='clickable' onClick={toggleSystemInfo}>
                    <Icon name={(showSystemInfo) ? 'caret down' : 'caret right'} />
                    <span>{t('systemInfoLabel', 'System Information')}</span>
                  </div>
                </div>
                {showSystemInfo && (
                  <div className='category'>
                    <div className={`${modalType} standards-label`}>{entityTypePrefix} Type</div>
                    <div className='standards-underline' />
                    {type && (
                      <div className='container'>
                        <div className='text-content'>
                          {type}
                        </div>
                      </div>
                    )}
                    <div className={`${modalType} standards-label`}>GUID</div>
                    <div className='standards-underline' />
                    {guid && (
                      <div className='container'>
                        <div className='text-content'>
                          {guid}
                        </div>
                      </div>
                    )}
                    <div className={`${modalType} standards-label`}>{entityIDPrefix} ID</div>
                    <div className='standards-underline' />
                    {name && (
                      <div className='container'>
                        <div className='text-content'>
                          {name}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            </div>
          </>
        )}
        {(modalType === 'resource-details' || modalType === 'resource-view-details') && (
          
          <>
            <div className={`${modalType} standards-label`}>Name</div>
            {name && (
              <div className='container'>
                <div className='text-content'>
                  {name}
                </div>
              </div>
            )}
            <div className={`${modalType} standards-label`}>Description</div>
            {!description && (
              <div className='container'>
                <div className='text-content'>No Description</div>
              </div>
            )}
            {description && (
              <>
                <div className='container'>
                  <div className='text-content'>
                    {UtilityService.reactHtmlParserWrapper(description).stripped}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button basic className='modal-close-button' onClick={close} primary
          type='button'>CLOSE
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default LibraryStandardsTagsModal;
SatCoreRegister('LibraryStandardsTagsModal', LibraryStandardsTagsModal);
