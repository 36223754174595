import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import { register } from '../../../i18n';

import EngagementService from '../../../services/EngagementService';

const EngagementHeaderAuxiliaryResourcesUsageCells = observer((props) => {
  const { /* managers */ } = useContext(MobXProviderContext);

  const t = register('EngagementTable');

  const renderEngagementHeaderAuxiliaryResourcesUsageCells = () => {
    return (
      <>
        {EngagementService.allowEngagementColumn('viewBasicCalculatorAvailable') &&
          renderEngagementHeaderAuxiliaryResourcesCell({
            allowPopupComponent: false,
            cellText: t('engagedBasicCalculator'),
            className: 'engagement-header-label detail-resources-usage-header engagedBasicCalculator',
            isHeaderCell: true,
            popupContent: t('engagedBasicCalculatorDescription'),
            popupOn: 'click'
          })}
        {EngagementService.allowEngagementColumn('viewScientificCalculatorAvailable') &&
          renderEngagementHeaderAuxiliaryResourcesCell({
            allowPopupComponent: false,
            cellText: t('engagedScientificCalculator'),
            className: 'engagement-header-label detail-resources-usage-header engagedScientificCalculator',
            isHeaderCell: true,
            popupContent: t('engagedScientificCalculatorDescription'),
            popupOn: 'click'
          })}
        {EngagementService.allowEngagementColumn('viewGraphingCalculatorAvailable') &&
          renderEngagementHeaderAuxiliaryResourcesCell({
            allowPopupComponent: false,
            cellText: t('engagedGraphingCalculator'),
            className: 'engagement-header-label detail-resources-usage-header engagedGraphingCalculator',
            isHeaderCell: true,
            popupContent: t('engagedGraphingCalculatorDescription'),
            popupOn: 'click'
          })}
        {EngagementService.allowEngagementColumn('viewDictionaryAvailable') &&
          renderEngagementHeaderAuxiliaryResourcesCell({
            allowPopupComponent: false,
            cellText: t('engagedDictionary'),
            className: 'engagement-header-label detail-resources-usage-header engagedDictionary',
            isHeaderCell: true,
            popupContent: t('engagedDictionaryDescription'),
            popupOn: 'click'
          })}
        {EngagementService.allowEngagementColumn('viewCustomaryRulerAvailable') &&
          renderEngagementHeaderAuxiliaryResourcesCell({
            allowPopupComponent: false,
            cellText: t('engagedCustomaryRuler'),
            className: 'engagement-header-label detail-resources-usage-header engagedCustomaryRuler',
            isHeaderCell: true,
            popupContent: t('engagedCustomaryRulerDescription'),
            popupOn: 'click'
          })}
        {EngagementService.allowEngagementColumn('viewMetricRulerAvailable') &&
          renderEngagementHeaderAuxiliaryResourcesCell({
            allowPopupComponent: false,
            cellText: t('engagedMetricRuler'),
            className: 'engagement-header-label detail-resources-usage-header engagedMetricRuler',
            isHeaderCell: true,
            popupContent: t('engagedMetricRulerDescription'),
            popupOn: 'click'
          })}
        {EngagementService.allowEngagementColumn('viewProtractorAvailable') &&
          renderEngagementHeaderAuxiliaryResourcesCell({
            allowPopupComponent: false,
            cellText: t('engagedProtractor'),
            className: 'engagement-header-label detail-resources-usage-header engagedProtractor',
            isHeaderCell: true,
            popupContent: t('engagedProtractorDescription'),
            popupOn: 'click'
          })}
        {EngagementService.allowEngagementColumn('viewResourcesAvailable') &&
          renderEngagementHeaderAuxiliaryResourcesCell({
            allowPopupComponent: false,
            cellText: t('engagedReferences'),
            className: 'engagement-header-label detail-resources-usage-header engagedReferences',
            isHeaderCell: true,
            popupContent: t('engagedReferencesDescription'),
            popupOn: 'click'
          })}
      </>
    );
  };

  const renderEngagementHeaderAuxiliaryResourcesCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };
  return renderEngagementHeaderAuxiliaryResourcesUsageCells();
});
export default EngagementHeaderAuxiliaryResourcesUsageCells;

SatCoreRegister('EngagementHeaderAuxiliaryResourcesUsageCells', EngagementHeaderAuxiliaryResourcesUsageCells);
