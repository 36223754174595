import React from 'react';

import { Button } from 'semantic-ui-react';

import classNames from 'classnames';

import { kebabCase } from 'lodash';

import { register } from '../i18n';

const t = register('Button');

export const renderButton = ({
  additionalClassNames = '',
  additionalProps = {},
  basic = false,
  buttonKey = '',
  disabled = false,
  doNotTranslate = false,
  loading = false,
  onClick = () => {},
  primary = false
} = {}) => {
  return (
    <Button
      {...additionalProps}
      basic={basic}
      className={classNames(additionalClassNames, kebabCase(buttonKey))}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      primary={primary}>
      {doNotTranslate ? buttonKey : t(buttonKey)}
    </Button>
  );
};
