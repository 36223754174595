import React from 'react';

import classNames from 'classnames';

import { register } from '../../i18n';

import { MIN_HIGH_ENGAGEMENT } from './ReportConstants';

import PopupService from '../PopupService';
import { registerClass } from '../../SatCoreRegistry';

// TODO remove // import iconSpeed from '../../img/icon-speed.svg';
// TODO remove // import iconDisengage from '../../img/icon-disengage.svg';

const t = register('ReportEngagementService');

export default class ReportEngagementService {
  static renderReportEngagementLabel = (accessor, {
    isExpandableRow,
    isFacultyAverageCell = false
  } = {}) => {
    let engagementScore = isFacultyAverageCell ? accessor : accessor?.engagementData?.engagementScore;
    // round to 1 decimal point max; do not include decimal if first decimal digit is 0
    engagementScore = typeof engagementScore === 'number' ? Number(engagementScore.toFixed(1)) : engagementScore;

    return typeof engagementScore === 'number' ? PopupService.renderPopup({
      className: 'report-engagement-cell-popup',
      content: this.renderReportEngagementCellPopupContent(accessor),
      disabled: isExpandableRow || isFacultyAverageCell,
      offset: [18, 9],
      position: 'top right',
      trigger: (
        <div className={classNames('report-engagement-cell-inner', {
          'high-engagement': engagementScore >= MIN_HIGH_ENGAGEMENT,
          'low-engagement': engagementScore < MIN_HIGH_ENGAGEMENT
        })}>
          {engagementScore}
        </div>
      )
    }) : null;
  }

  static renderReportEngagementCellPopupContent = (accessor) => {
    return (
      <div className='report-engagement-cell-popup-content'>
        {this.renderReportEngagementCellPopupContentRow(accessor, 'engagementTimeOnTaskSubscore')}
        {this.renderReportEngagementCellPopupContentRow(accessor, 'engagementFeedbackUsageSubscore')}
        {this.renderReportEngagementCellPopupContentRow(accessor, 'engagementMiscSubscore')}
      </div>
    );
  }

  static renderReportEngagementCellPopupContentRow = (accessor, key) => {
    return (
      <div className='report-engagement-cell-popup-content-row'>
        {t(`${key}Label`)}:&nbsp;
        <strong>
          {t(`${key}Value`, {
            value: accessor?.engagementData?.[key] || 0
          })}
        </strong>
        {/* TODO remove */}
        {/* <div className='report-engagement-cell-popup-content-label'>
          {t(`${key}Label`)}:
        </div>
        <div className='report-engagement-cell-popup-content-value'>
          {t(`${key}Value`, {
            value: accessor?.engagementData?.[key] || 0
          })}
        </div> */}
      </div>
    );
  }
}

registerClass('ReportEngagementService', ReportEngagementService);
