import React, { useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import Modal from '../Modal';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import '../../css/CertificateModal.less';

const CertificateModal = (props) => {
  const { certificateOpen, closeCertificateModal, downloadCertificate } = props;

  useEffect(() => {
  }, []);

  const ModalBanner = SatCoreComponent('ModalBanner');

  return (
    <Modal className='certificate-modal' open={certificateOpen}>
      <ModalBanner
        label='Retrieve Certificate'
        onClose={() => { closeCertificateModal(); }} />
      <Modal.Content image>
        <Modal.Description>
          Download your certification from the link below.
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={async () => {
            downloadCertificate();
            closeCertificateModal();
          }} primary>Download Certificate
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default CertificateModal;
SatCoreRegister('CertificateModal', CertificateModal);
