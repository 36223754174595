import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { MainView, SatCoreRegister, VIEW_SELECTION } from 'sat-core';

export default
@inject('contentManager', 'userManager')
@observer
class MainViewOverride extends Component {
  showResources = async (props) => {
    const { contentManager } = this.props;
    //  TODO: keep this and view in case iFrame works out later release.
    //  props.navigationManager.setView(VIEW_SELECTION.RESOURCES);
    //  await props.history.push(`/resources?view=${VIEW_SELECTION.RESOURCES}`);
    if (contentManager.resourceIFrameUrl !== null) {
      window.open(contentManager.resourceIFrameUrl, '_blank');
    }
  }

  getNavTabs = (props, parent) => {
    const { userManager: {
      isDistrictAdmin,
      isProductAdmin,
      isSchoolAdmin,
      isStudent,
      isTeacher
    }} = this.props;
    const help = parent.renderMenuItem(
      <>
        {isStudent ? 'Help' : 'Support'}
      </>,
      VIEW_SELECTION.RESOURCES,
      { className: 'resources', onClick: () => this.showResources(props) }
    );
    if (isDistrictAdmin || isSchoolAdmin || isProductAdmin) {
      return help;
    } else if (isTeacher || isStudent) {
      return (
        <>
          {parent.getBaseNavTabs()}
          {isTeacher && help}
        </>
      );
    }
  }

  render() {
    return <MainView {...this.props} getNavTabs={this.getNavTabs} />;
  }
}

SatCoreRegister('MainView', MainViewOverride);
