import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';

import { Button, Checkbox, Container, Header, Image, Loader } from 'semantic-ui-react';

import '../css/Class.less';
import '../css/CourseListing.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { VIEW_SELECTION } from '../managers/NavigationManager';

import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';

import CourseService from '../services/CourseService';
import ClassroomService from '../services/ClassroomService';
import UtilityService from '../services/UtilityService';
import PopupService from '../services/PopupService';

import CarouselContainer from '../components/CarouselContainer';

import chapterDefaultCardImage from '../img/default-chapter.svg';

export default
@inject(
  'assignmentManager', 'courseDeliveryManager', 'courseManager',
  'navigationManager', 'productManager', 'userManager'
)
@observer
class ClassDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingClassDetails: true,
      courseUnitArray: [],
      courseSectionMap: new Map(),
      courseUnitResourcesArray: [],
      selectedUnit: null,
      selectedSection: new Map()
    };

    this.BreadCrumbsCourseDropdown = SatCoreComponent('BreadCrumbsCourseDropdown');
    this.CourseLayout = SatCoreComponent('CourseLayout');
    this.CourseListBanner = SatCoreComponent('CourseListBanner');
  }

  async componentDidMount() {
    await this.initClassDetails();
  }

  async componentWillUnmount() {
    console.log("!!!!!!!!!component unmounting!!!!!!!!!!!")
  }

  // eslint-disable-next-line react/sort-comp
  initClassDetails = async ({
    courseElementId = undefined,
    courseId = undefined
  } = {}) => {
    this.setState({ isLoadingClassDetails: true });

    const {
      courseManager, navigationManager, productManager
    } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');
    if (view && !productManager.FROM_TEACHER_PRODUCTS_NAV) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.BOOK);
    }

    const previewAsStudent = urlParams.get('previewAsStudent');
    if (previewAsStudent) {
      courseManager.setPreviewAsStudent(true);
    }

    // ensure currentClassroomId is initialized
    ClassroomService.getCurrentClassroomId({ alsoUpdateObservableWithUrlParamIfNull: true });

    if (urlParams.has('courseId')) {
      if (courseManager.currentCourseId === null) {
        courseManager.setCurrentCourseId(urlParams.get('courseId'));
      }
    }

    if (productManager.isFromProduct) {
      await CourseService.initCourseDataProduct();
    } else {
      await CourseService.initCourseData(true, true, true, {
        courseElementId, courseId
      });
    }

    // if Ribbon TOC is turned on, process the course data to extract units, subsections and resources
    if (courseManager.allowRibbonTOC) {
      this.handleRibbonTOCData();
    }

    setTimeout(() => {
      this.setState({ isLoadingClassDetails: false });
    }, 500);
  }

  handleToggleHiddenResources = (_event, data) => {
    const shouldShowHiddenResources = data.checked;
    const { courseDeliveryManager } = this.props;
    courseDeliveryManager.setShouldShowHiddenResources(shouldShowHiddenResources);
  }

  handleRibbonTOCData() {
    const { courseManager } = this.props;
    let { courseUnitArray, courseSectionMap, courseUnitResourcesArray } = this.state
    {courseManager.currentCourseElementList.map((courseElement, index) => {
      if (courseElement.type === 'UNIT') {
        courseUnitArray.push(courseElement);
      } else if (courseElement.type === 'SECTION') {
        let elementArray = [];
        // Get the parent ribbon or create one
        if (courseSectionMap.has(courseElement.parentElementId)) {
          elementArray = courseSectionMap.get(courseElement.parentElementId);
        }
        elementArray.push(courseElement);
        courseSectionMap.set(courseElement.parentElementId, elementArray);
      } else {
        courseUnitResourcesArray.push(courseElement); 
      }
    })};
    this.setState({ courseUnitArray, courseSectionMap, courseUnitResourcesArray} );
  }

  async onRibbonUnitClick(courseElement) {
    const { isLoadingClassDetails } = this.state;
    if (!isLoadingClassDetails) {
      const name = UtilityService.reactHtmlParserWrapper(courseElement.name).selectivelyStripped[0];
      console.log(`Clicked on Unit "${name}"`);
      this.setState({ isLoadingClassDetails: true, selectedUnit: courseElement, selectedSection: new Map(), courseSectionMap: new Map(), courseUnitResourcesArray: [] } );
      this.fetchRibbonData(null, courseElement);
    }
  }

  async onRibbonSectionClick(mapKey, courseElement) {
    let { isLoadingClassDetails, selectedSection, courseSectionMap } = this.state;
    if (!isLoadingClassDetails) {
      const name = UtilityService.reactHtmlParserWrapper(courseElement.name).selectivelyStripped[0];
      console.log(`Clicked on Section "${name}"`);
      // if we don't have the current element, add it.
      if (!selectedSection.has(courseElement.elementId)) {
        selectedSection.set(courseElement.elementId, courseElement);
      }
      // remove selection for any other sections in the same ribbon.
      const courseElementArray = courseSectionMap.get(courseElement.parentElementId);
      if (courseElementArray) {
        for (const existingCourseElement of courseElementArray ) {
          if (courseElement.elementId !== existingCourseElement.elementId) {
            selectedSection.delete(existingCourseElement.elementId);
          }
        }
      }
      this.setState({  isLoadingClassDetails: true, selectedSection, courseSectionMap, courseUnitResourcesArray: [] } );
      this.fetchRibbonData(mapKey, courseElement)
    }
  }

  trimRibbonSections(mapKey) {
    const { courseSectionMap, selectedSection } = this.state;
    const newSectionMap = new Map();
    let lastFound = false;
    for (let [key, courseElementArray] of courseSectionMap) {
      if (lastFound) {
        // see if the dropped sections had selection records
        for (const courseElement of courseElementArray) {
          console.log(`selected section: ${courseElement.name} id: ${courseElement.elementId} parentId: ${courseElement.parentElementId}`);
          if (selectedSection.has(courseElement.elementId)) {
            selectedSection.delete(courseElement.elementId);
          }
        }
        break;
      } else {
        newSectionMap.set(key, courseElementArray);
      }
      if (key === mapKey) {
        lastFound = true;
      }
    }
    this.setState({ courseSectionMap: newSectionMap, selectedSection });
  }

  async fetchRibbonData(mapKey, currentCourseElement) {
    const { courseManager } = this.props;
    let { courseSectionMap } = this.state;
    let courseElementId = courseManager.currentElementId;
    const courseId = courseManager.currentCourseId;
    if (currentCourseElement) {
      courseElementId = currentCourseElement.elementId;
    } else {
      if (courseSectionMap) {
        courseSectionMap.delete(courseElementId);
      }
      this.setState({ courseUnitResourcesArray: [], courseSectionMap });
    }
    courseManager.setCurrentCourseId(courseId);
    courseManager.setCurrentElementId(courseElementId);
    console.log(`courseElementId: ${courseElementId} courseId: ${courseId}`);
    await CourseService.initCourseData(true, true, true, {});
    if (mapKey) {
      // See if we need to trim section ribbons no longer in scope
      this.trimRibbonSections(mapKey);
    }
    this.handleRibbonTOCData();
    setTimeout(() => {
      this.setState({ isLoadingClassDetails: false });
    }, 500);
  }

  renderClassicCourseView() {
    const {
      assignmentManager, courseDeliveryManager, courseManager,
      history, productManager, t, userManager
    } = this.props;

    const { isLoadingClassDetails } = this.state;

    const { activePermissionId } = userManager;
    const { BreadCrumbsCourseDropdown, CourseLayout, CourseListBanner } = this;
    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);

    const { FROM_TEACHER_PRODUCTS_NAV } = productManager;

    // eslint-disable-next-line react/destructuring-assignment
    const zeroState = this.props.zerostate || (
      <div className='no-courses'>
        {!isCustomCourse ? t('coursesZeroStateMsg')
          : t('customAssessmentZeroStateMsg', 'There are no assessments added yet.')}
      </div>
    );

    // NOTE: we typically need `hideAddCourseButton` to be true here,
    // as we only want to display '+ Add Course' at the Course Level (ClassCourses.js).
    // ---
    // Instead, when ClassCourses is called, that calls its own instance of CourseListBanner, which will have `hideAddCourseButton=false`
    // ---
    // We currently hide the '+ Add Course' button from all other Course Tree levels (branch, leaf).
    let hideAddCourseButton = true;
    // The only case where this should currently be false is for an old (unused) DEMO-1787 feature (see TeacherProductView for more info)
    if (FROM_TEACHER_PRODUCTS_NAV) {
      hideAddCourseButton = false;
    }

    return courseManager.isTreeLoading || isLoadingClassDetails ? <Loader active /> : (
      <Container className={classNames('class-detail', {
        'teacher-product-class-detail': FROM_TEACHER_PRODUCTS_NAV
      })} fluid>
        <CourseListBanner
          forceHideHeader={false}
          hideAddCourseButton={hideAddCourseButton}
          hideAddStudentButton={true}
          history={history}
          resetClassDetails={this.initClassDetails}
          showBreadCrumbs='bottom'
          showBreadCrumbsDropdown='bottom'
          showCourseSearchButton={assignmentManager.enableCourseSearch}
          title={t('BannerTitle', 'No Class Courses Banner Title')} />
        {(courseManager.currentCourseId !== null
          && courseManager.currentCourseElementList.length > 0) ? (
            <Container
              className={classNames(`class-content resources-container class-details-inner-container ${activePermissionId}`, {
                'has-course-search-btn': assignmentManager.enableCourseSearch
              })} fluid>
              {!courseManager.isTreeLoading && (
                <div className='class-details-header'>
                  <div className='class-details-header-left'>
                    {/* marked as 'display: none' by default */}
                    <BreadCrumbsCourseDropdown className='top' history={history} />
                  </div>
                  <div className='class-details-header-mid'>
                    {/* placeholder */}
                  </div>
                  <div className='class-details-header-right'>
                    {!userManager.isStudent && courseDeliveryManager.showResourcePacingToolbar && (
                      <div className='toggle-hidden-resources-wrapper'>
                        <div className='hidden-resources-toggler-label'>
                          {t('showHiddenResources', 'Show hidden resources')}
                        </div>
                        <Checkbox
                          className='hidden-resources-toggler'
                          defaultChecked={courseDeliveryManager.shouldShowHiddenResources}
                          onChange={this.handleToggleHiddenResources}
                          toggle />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <CourseLayout history={history} />
            </Container>
          ) : !courseManager.isTreeLoading && !courseManager.currentCourseElementList.length && !isLoadingClassDetails && (
            <Container className='class-content'>
              {zeroState}
            </Container>
          )}
      </Container>
    );
  }

  renderRibbonTOC() {
    const { courseManager, history, navigationManager, productManager, t, userManager } = this.props;
    const { FROM_TEACHER_PRODUCTS_NAV } = productManager;
    const { activePermissionId } = userManager;
    const { CourseLayout, CourseListBanner } = this;
    const { isLoadingClassDetails, courseUnitArray, courseSectionMap, courseUnitResourcesArray, selectedUnit, selectedSection } = this.state;

    navigationManager.clearCoursePaths();

    let selectedSectionString = '';

    for (const section of Array.from(selectedSection.values()).map((obj) => obj)) {
      if(selectedSection.size > 0) {
        selectedSectionString += ': ';
      }
      selectedSectionString += UtilityService.reactHtmlParserWrapper(section.name).selectivelyStripped[0];
    }

    const headerTitle = `${(selectedUnit) ? UtilityService.reactHtmlParserWrapper(selectedUnit.name).selectivelyStripped[0] : ''}${selectedSectionString}`;

    const carouselSettings = {
      arrows: true,
      dots: false,
      infinite: true,
      focusOnSelect: true,
      slidesToScroll: 1,
      slidesToShow: 6,
      speed: 500
    };

    // eslint-disable-next-line react/destructuring-assignment
    const zeroState = this.props.zerostate || (
      <div className='no-courses'>
        {t('coursesZeroStateMsg')}
      </div>
    );

    const selectedSectionIds = Array.from(selectedSection.keys()).map((obj) => obj);

    return (
      <Container className={classNames('class-detail', {
        'teacher-product-class-detail': FROM_TEACHER_PRODUCTS_NAV
      })} fluid>
        <CourseListBanner
          forceHideHeader={true}
          // hideAddCourseButton={hideAddCourseButton}
          // hideAddStudentButton={true}
          history={history}
          resetClassDetails={this.initClassDetails}
          showBreadCrumbs='bottom'
          // showBreadCrumbsDropdown='none'
          showCourseSearchButton={false}
          title={t('BannerTitle', 'No Class Courses Banner Title')}
         />
        <div className='headerContainer'>
        {(selectedUnit || selectedSection.size > 0) &&
          <div className='headerWrapper'>
            <Header as='h1' className='theme-header-title'>{headerTitle}</Header>
          </div>
        }
        </div>
        {(courseUnitArray.length > 0) && (
          <Container className="ribbon-container unit-ribbon">
            <CarouselContainer {...carouselSettings}>
              {courseUnitArray.map((courseElement, index) => (
                <Button
                  key={`unit-${index}`}
                  className={`unit-carousel-card ${(selectedUnit && selectedUnit.elementId === courseElement.elementId) ? 'selected' : ''} course-card-image-wrapper`}
                  onClick={() => {this.onRibbonUnitClick(courseElement)}}>
                  <div className={`course-card-image ${(courseElement.attachmentContentItemId === null) ? 'default' : ''}`}>
                    {(courseElement.attachmentContentItemId === null) ? 
                      <Image src={chapterDefaultCardImage} wrapped />
                      : 
                      <Image fluid src={CONTENT_ITEM_TYPES.getContentIdImageUrl(courseElement.attachmentContentItemId)}/>
                    }
                  </div>
                  {PopupService.renderPopup({
                      className: 'course-card-text-popup',
                      content: (UtilityService.reactHtmlParserWrapper(courseElement.name).selectivelyStripped[0]),
                      position: 'bottom left',
                      trigger: (<div className="course-card-text">{UtilityService.reactHtmlParserWrapper(courseElement.name).selectivelyStripped[0]}</div>)
                    })
                  }
                </Button>
              )
              )}
            </CarouselContainer>
          </Container>
        )}
        {(courseSectionMap.size > 0) && 
          Array.from(courseSectionMap?.entries() || []).map(([mapKey, elementArray]) => {

            return (
              <Container className="ribbon-container section-ribbon-wrapper">
                <Container className="ribbon-container section-ribbon">
                  <CarouselContainer {...carouselSettings}>
                    {elementArray.map((courseElement, index) => (
                      <Button
                        key={`unit-${index}`}
                        className={`section-carousel-card ${(selectedSection && selectedSectionIds.includes(courseElement.elementId)) ? 'selected' : ''}`}
                        onClick={() => {this.onRibbonSectionClick(mapKey, courseElement)}}>
                        {PopupService.renderPopup({
                            className: 'course-card-text-popup',
                            content: (UtilityService.reactHtmlParserWrapper(courseElement.name).selectivelyStripped[0]),
                            position: 'bottom left',
                            trigger: (<span className="section-text">{UtilityService.reactHtmlParserWrapper(courseElement.name).selectivelyStripped[0]}</span>)
                          })
                        }
                      </Button>
                    )
                    )}
                  </CarouselContainer>
                </Container>
              </Container>
            )
          }                    
        )}
        {(courseUnitResourcesArray.length > 0) && (
          <Container className={classNames(`resources-container ${activePermissionId}`)} fluid>
            <CourseLayout history={history} courseElementList={courseUnitResourcesArray} refreshRibbonHandler={this.fetchRibbonData.bind(this)}/>
          </Container>
        )}
        {!courseManager.isTreeLoading && !courseManager.currentCourseElementList.length && !isLoadingClassDetails && (
          <Container className='class-content'>
            {zeroState}
          </Container>
        )}
      </Container>
    );
  }

  render() {
    const { courseManager } = this.props;
    return (
      courseManager.allowRibbonTOC ? (
        this.renderRibbonTOC()
      ) : this.renderClassicCourseView()
    );
  }
}

SatCoreRegister('ClassDetails', ClassDetails);
