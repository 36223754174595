import { action, makeObservable, observable } from 'mobx';
import { generateRandomCryptographicId } from '../utils';

export class ThirdPartyManager {
  constructor() {
    makeObservable(this);
  }

  @observable allowedThirdPartyChatLauncherUserRoles = [/* 'teacher_user', 'student_user', ... etc */];
  @observable thirdPartyChatLauncherId = generateRandomCryptographicId();
  @observable thirdPartyChatLauncherScriptContent = '';
  @observable thirdPartyChatLauncherUrl = '';

  @action clearAll = () => {
    this.regenerateThirdPartyChatLauncherId();
  }

  @action regenerateThirdPartyChatLauncherId = () => {
    this.thirdPartyChatLauncherId = generateRandomCryptographicId();
  }

  /** @param {import('../services/UserService').UserRole[]} userRoles */
  @action setAllowedThirdPartyChatLauncherUserRoles = (userRoles) => {
    this.allowedThirdPartyChatLauncherUserRoles = userRoles;
  };

  @action setThirdPartyChatLauncherUrl = (url) => {
    this.thirdPartyChatLauncherUrl = url;
  };
}

export default new ThirdPartyManager();
