/* eslint-disable jsx-a11y/anchor-is-valid, max-len, sort-keys */
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { Button, Container, Dropdown, Image, Loader, Pagination, Table } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';
import { PATH_TYPES } from '../managers/NavigationManager';
import { DIALOG_NAMES } from '../managers/DialogManager';
import { useParams } from '../hoc/withParams';
import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';

import iconRename from '../img/icon-rename.svg';
import iconPreview from '../img/icon-preview-not-filled.svg';
import iconDownload from '../img/icon-download.svg';
import iconReplace from '../img/icon-replace.svg';
import iconRemove from '../img/icon-remove.svg';

import '../css/ClassWorkspace.less';

export const WorkspaceTable = ({ children, ...props }) => {
  return (
    <Table striped {...props}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className='file'>File</Table.HeaderCell>
          <Table.HeaderCell className='type'>Type</Table.HeaderCell>
          <Table.HeaderCell className='name'>Name</Table.HeaderCell>
          <Table.HeaderCell className='options'>Options</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {children}
      </Table.Body>
    </Table>
  );
};

export const WorkspaceOptions = observer(({
  downloadUrl, onPreviewClick, onRenameClick, onRemoveClick, onReplaceClick
}) => {
  const { contentItemManager, userManager } = useContext(MobXProviderContext);

  let buttons = null;
  if (userManager.isStudent) {
    const { preventStudentWorkspaceOnRenameOption, preventStudentWorkspaceOnReplaceOption } = contentItemManager;
    buttons = [
      (!preventStudentWorkspaceOnRenameOption && !!onRenameClick) && { title: 'Rename', icon: iconRename, onClick: onRenameClick },
      !!onPreviewClick && { title: 'Preview', icon: iconPreview, onClick: onPreviewClick },
      !!downloadUrl && { title: 'Download', icon: iconDownload, href: downloadUrl, as: 'a', download: true },
      (!preventStudentWorkspaceOnReplaceOption && !!onReplaceClick) && { title: 'Replace', icon: iconReplace, onClick: onReplaceClick },
      !!onRemoveClick && { title: 'Remove', icon: iconRemove, onClick: onRemoveClick }
    ].filter((button) => button);
  } else {
    buttons = [
      !!onRenameClick && { title: 'Rename', icon: iconRename, onClick: onRenameClick },
      !!onPreviewClick && { title: 'Preview', icon: iconPreview, onClick: onPreviewClick },
      !!downloadUrl && { title: 'Download', icon: iconDownload, href: downloadUrl, as: 'a', download: true },
      !!onReplaceClick && { title: 'Replace', icon: iconReplace, onClick: onReplaceClick },
      !!onRemoveClick && { title: 'Remove', icon: iconRemove, onClick: onRemoveClick }
    ].filter((button) => button);
  }

  return (
    <Dropdown icon={null} trigger='...'>
      <Dropdown.Menu>
        {buttons.map(({ icon, title, ...props }, index) => (
          <Dropdown.Item key={index} {...props}>
            <Image src={icon} />
            <span className='text'>{title}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
});

export const WorkspaceTableRow = ({
  contentItemManager,
  contentManager,
  dialogManager,
  resource,
  t,
  userManager,
  ...props
}) => {
  const { id, name, entityTypeId, ownerName, ownerId } = resource;
  const type = entityTypeId ? entityTypeId.replace('_resource', '').replace('_', ' ') : 'file';
  const canPreview = [CONTENT_ITEM_TYPES.AUDIO_RESOURCE, CONTENT_ITEM_TYPES.IMAGE_RESOURCE, CONTENT_ITEM_TYPES.VIDEO_RESOURCE, CONTENT_ITEM_TYPES.PDF_RESOURCE].includes(entityTypeId);
  const canRemove = !userManager.isStudent || ownerId === userManager.userId;
  const downloadUrl = contentItemManager.getDownloadUrl(resource);
  const onRenameClick = useCallback(() => dialogManager.setOpenDialog(DIALOG_NAMES.WORKSPACE_FILE_RENAME_MODAL, { resource }), [dialogManager, resource]);
  const onPreviewClick = useCallback(async () => {
    await contentManager.fetchResourceItem(id, { forceFetchResourceItem: true });

    return dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
      resourceName: name,
      contentItemId: id,
      contentItemType: entityTypeId,
      fullContentSize: true,
      skipGetContentData: true
    }, () => {
      dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW);
    }, [dialogManager, name, id, entityTypeId]);
  });
  const onReplaceClick = useCallback(() => dialogManager.setOpenDialog(DIALOG_NAMES.WORKSPACE_FILE_MODAL, { id }), [dialogManager, id]);
  const onRemoveClick = useCallback(() => {
    dialogManager.setOpenDialog(
      DIALOG_NAMES.CONFIRM,
      {
        title: t('confirmDeleteTitle', 'Remove File'),
        message: t('confirmDeleteMessage', 'Are you sure you want to remove this file?'),
        cancelButtonName: t('confirmDeleteCancelButtonLabel', 'Cancel'),
        confirmButtonName: t('confirmDeleteSubmitButtonLabel', 'Remove'),
        className: 'workspace-modal',
        confirmHandler: async () => {
          await contentItemManager.delete(id);
          dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
        }
      }
    );
  }, [dialogManager, contentItemManager, id, t]);

  return (
    <Table.Row {...props}>
      <Table.Cell className='file'>
        {canPreview ?
          <a onClick={onPreviewClick}>{name}</a> :
          <a download href={downloadUrl}>{name}</a>}
      </Table.Cell>
      <Table.Cell className='type'>
        <span>{type}</span>
      </Table.Cell>
      <Table.Cell className='name'>{ownerName}</Table.Cell>
      <Table.Cell className='options'>
        <WorkspaceOptions {...{
          downloadUrl,
          onPreviewClick: canPreview && onPreviewClick,
          onRemoveClick: canRemove && onRemoveClick,
          onRenameClick,
          onReplaceClick,
          resource
        }} />
      </Table.Cell>
    </Table.Row>
  );
};

export const WorkspaceTableLoader = () => {
  return (
    <Table.Row>
      <Table.Cell className='loader' colSpan='4'>
        <Loader active />
      </Table.Cell>
    </Table.Row>
  );
};

const ClassWorkspace = observer((props) => {
  const { history, t } = props;
  const {
    contentManager,
    classroomManager,
    contentItemManager,
    dialogManager,
    navigationManager,
    userManager,
  } = useContext(MobXProviderContext);
  const { isStudent } = userManager;
  const { params: { classroomId: currentClassroomId = classroomManager.currentClassroomId, page = 1 }, setParams } = useParams(props);
  const { resources, loaded, totalPages, index } = contentItemManager;
  const BreadCrumbs = useMemo(() => SatCoreComponent('BreadCrumbs'), []);
  const name = classroomManager.getClassName(currentClassroomId);

  const setClassroomBreadcrumbs = useCallback(() => {
    navigationManager.clearAllPaths();
    isStudent ?
      navigationManager.addPath({ name: 'Home', type: PATH_TYPES.LINK, resetCurrentClassroomId: false }) :
      navigationManager.addPath({ currentClassroomId, name, type: PATH_TYPES.LINK });
    navigationManager.addPath({ name: t('workspaceTitle'), type: PATH_TYPES.LINK });
  }, [currentClassroomId, name, isStudent]);

  const load = useCallback(() => {
    contentItemManager.list({ classroomId: currentClassroomId, page });
  }, [currentClassroomId, page, index]);

  useEffect(() => {
    setClassroomBreadcrumbs();
  }, [setClassroomBreadcrumbs]);

  useEffect(load, [load]);

  useEffect(() => {
    loaded && page !== 1 && page > totalPages && setParams({ page: page - 1 });
  }, [totalPages]);

  return (
    <Container className='class-workspace-view' fluid>
      <div className='bread-crumb-wrapper'>
        <BreadCrumbs history={history} />
      </div>
      <h1 className='class-workspace-header'>{t('WorkspaceLabel')}</h1>
      <Container className='class-workspace-wrapper'>
        <h1 className='class-workspace-subheader'>{name}{name ? `: ${ t('workspaceTitle')}` : `${ t('workspaceTitle')}`}</h1>
        <div className='class-workspace-actions'>
          <Button onClick={() => dialogManager.setOpenDialog(
            DIALOG_NAMES.WORKSPACE_FILE_MODAL,
            { classroomId: currentClassroomId, submit: false },
            () => {
              dialogManager.closeDialog(DIALOG_NAMES.WORKSPACE_FILE_MODAL);
              load();
            }
          )} primary>
            + Add File
          </Button>
          {totalPages > 1 && (
            <Pagination
              activePage={page}
              onPageChange={(_, { activePage }) => setParams({ page: activePage === 1 ? null : activePage })}
              totalPages={totalPages} />
          )}
        </div>
        <WorkspaceTable>
          {loaded ?
            resources.map((resource, index) => (
              <WorkspaceTableRow key={index} {...{
                contentItemManager,
                contentManager,
                dialogManager,
                resource,
                t,
                userManager
              }} />
            )) :
            <WorkspaceTableLoader />}
        </WorkspaceTable>
      </Container>
    </Container>
  );
});

export default ClassWorkspace;
SatCoreRegister('ClassWorkspace', ClassWorkspace);
