import React from 'react';
import { ProgressView, SatCoreRegister, StudentHelperService } from 'sat-core';

const ProgressViewSummaryLegend = ({ data }) => {
  return StudentHelperService.getProgressSummaryLegend({ data });
};

const ProgressViewOverride = (props) => {
  const progressViewOverrideOptions = StudentHelperService.getProgressViewOverrideOptions({
    weeklyPerformanceWidget: false
  });
  return (
    <ProgressView {...props} {...progressViewOverrideOptions}
      summaryLegend={ProgressViewSummaryLegend} />
  );
};

SatCoreRegister('ProgressView', ProgressViewOverride);
