/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Container, Dropdown, Input, Loader, Message, Pagination } from 'semantic-ui-react';

import '../css/AdminContentView.less';

import { debounce } from 'lodash';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

import { VIEW_SELECTION } from '../managers/NavigationManager';

import { DIALOG_NAMES } from '../managers/DialogManager';

import { PLAYER_TYPES } from '../managers/ContentManager';
import { allowDocumentBodyScroll, scrollBrowserWindowToTop } from '../utils';

export default @inject(
  'contentManager',
  'courseManager',
  'dialogManager',
  'libraryManager',
  'navigationManager',
  'productManager',
  'tagContentManager',
  'userManager',
)
@observer
class AdminContentView extends Component {
  constructor(props) {
    super(props);

    this.LibraryResourceCard = SatCoreComponent('LibraryResourceCard');
    this.BreadCrumbs = SatCoreComponent('BreadCrumbs');
    this.FullscreenModal = SatCoreComponent('FullscreenModal');
    this.DocReaderModal = SatCoreComponent('DocReaderModal');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');

    this.state = {
      courseContentItemIdsForCurrentUser: new Set(),
      deletedLibraryResourceName: null,
      docreaderViewerShowing: false,
      fileViewerShowing: false,
      lessonPlayerShowing: false,
      libraryCardData: null,
      productOptions: [],
      searchText: '',
      searchTypes: null,
      selectedMenu: '',
      serverErrorMsg: null,
      showKey: 'all',
      sortColumn: null,
      sortDirection: null,
      sortKey: ''
    };
  }

  componentDidMount() {
    const { libraryManager } = this.props;
    // const { activePage = 1 } = libraryManager;
    const {
      navigationManager
    } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('view')) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.PRODUCTS);
    }

    this.setState({ selectedMenu: 'courses' });
  }

  async componentDidUpdate(_prevProps, prevState) {
    const { sortColumn, sortDirection, showKey, selectedMenu } = this.state;
    if (prevState.selectedMenu !== selectedMenu ||
        prevState.sortColumn !== sortColumn ||
          prevState.sortDirection !== sortDirection ||
            prevState.showKey !== showKey) {
      this.refreshData();
    }
  }

  async componentWillUnmount() {
    const { libraryManager } = this.props;
    libraryManager.setActivePage(1);
  }

  refreshData = async (activePage = 1) => {
    // Some of the BE aws operations seem to take a bit to process, so if this is a refresh
    // add some delay before fetch.
    setTimeout(() => {
      this.fetchData(activePage);
    }, 500);
  }

  clearLibraryCardData = async () => {
    const { libraryManager } = this.props;
    libraryManager.setLibraryLoading(true);
    this.setState({ libraryCardData: null });
  }

  fetchData = async (activePage = 1) => {
    const { courseManager, libraryManager, userManager } = this.props;
    const { searchTypes, selectedProduct, selectedMenu, sortDirection, sortColumn, searchText } = this.state;
    let libraryCardData = null;
    let productOptions = [];
    let courseContentItemIdsForCurrentUser = new Set();

    await this.clearLibraryCardData();
    let typesToSearch = searchTypes;
    if (selectedMenu === 'itemBank') {
      const sortColumnName = sortColumn === 'sortName' ? 'name' : sortColumn;
      libraryCardData = await libraryManager.fetchLibraryItemBanks(activePage, 25, sortColumnName, sortDirection);
    } else if (selectedMenu === 'courses') {
      const sortColumnName = sortColumn === 'sortName' ? 'name' : sortColumn;
      typesToSearch = typesToSearch || 'course_resource';
      productOptions = await this.fetchProductDropdownData();
      // don't send 'all' for product when fetching as the manager uses a different api if there is no product sent.
      const searchProduct = (selectedProduct && selectedProduct !== 'all') ? selectedProduct : undefined;
      libraryCardData = await libraryManager.fetchLibraryCourses(activePage, 25, typesToSearch, sortColumnName, sortDirection, searchText, searchProduct);

      if (userManager.isTeacher) {
        const courseContentItemIdsForCurrentUserArray = await courseManager.fetchClassroomCourseContentItemIdsForCurrentUser();
        courseContentItemIdsForCurrentUser = new Set(courseContentItemIdsForCurrentUserArray);
      }
    } else if (selectedMenu === 'resourceBanks') {
      const sortColumnName = sortColumn === 'sortName' ? 'name' : sortColumn;
      libraryCardData = await libraryManager.fetchLibraryResourceBanks(userManager.userId, activePage, 25, sortColumnName, sortDirection);
    }
    this.setState({ courseContentItemIdsForCurrentUser, libraryCardData, productOptions });
  }

  fetchProductDropdownData = async () => {
    const { productManager, userManager } = this.props;
    const products = await productManager.fetchLicensedCourseProducts(userManager.userId);
    const productOptions = [];
    if (products && products.length > 0) {
      productOptions.push({ key: -1, text: 'All Products', value: 'all' });
      products.forEach((product) => {
        productOptions.push({
          key: product.id,
          product,
          text: product.friendlyName || product.displayName,
          value: product.id
        });
      });
    }
    return productOptions;
  }

  onPageChange = async (_event, pageInfo) => {
    const { activePage } = pageInfo;
    this.fetchData(activePage);
  }

  handleMenuClick = async (selectedMenu) => {
    this.setState({
      libraryCardData: null,
      searchText: '',
      searchTypes: null,
      selectedMenu,
      selectedProduct: null,
      serverErrorMsg: null,
      showKey: 'all',
      sortColumn: null,
      sortDirection: null,
      sortKey: null,
    });
  }

  handleShow = async (_event, { value }) => {
    let searchTypes = value;
    if (value === 'all') {
      searchTypes = 'course_resource,assessment';
    }
    this.setState({ searchTypes, showKey: value });
  }

  handleSort = async (_event, { value }) => {
    let sortColumn = '';
    let sortDirection = '';
    if (value === 'oldest') {
      sortColumn = 'modificationDate';
      sortDirection = 'asc';
    } else if (value === 'title-asc') {
      sortColumn = 'sortName';
      sortDirection = 'asc';
    } else if (value === 'title-desc') {
      sortColumn = 'sortName';
      sortDirection = 'desc';
    } else {
      // default is "newest"
      sortColumn = 'modificationDate';
      sortDirection = 'desc';
    }
    this.setState({ sortColumn, sortDirection, sortKey: value });
  }

  handleSelectProduct = async (_event, { value }) => {
    const { selectedProduct } = this.state;
    if (value !== selectedProduct) {
      let productId = null;
      if (value && value !== 'all') {
        productId = value;
      } else {
        if (selectedProduct) {
          productId = 'all';
        } else {
          this.setState({ selectedProduct: 'all' });
          return;
        }
      }
      this.setState({ libraryCardData: [], selectedProduct: productId });
      this.refreshData();
    }
  }

  handleChangeSearchTextDelayed = debounce((searchText) => {
    // only get data if the search term is over 1 char
    if (searchText === '' || searchText.length > 1) {
      this.fetchData();
    }
  }, 1000); // delay 1 second after the user stops typing and then do the fetch

  handleChangeSearchText = (event) => {
    const searchText = event.target.value;
    this.setState({ searchText });
    this.handleChangeSearchTextDelayed(searchText);
  };

  handleItemBankView = async (libraryCardData) => {
    const { history } = this.props;
    const { dialogManager, tagContentManager, userManager } = this.props;

    // load some things it may need.
    await tagContentManager.fetchAvailableCategoriesWithTags(null, 'ITEM_BANK'); // passing null uses the default set in tag content manager
    await tagContentManager.fetchStandardFrameworkBookmarks(userManager.userId); // passing null uses the default set in tag content manager
    dialogManager.setOpenDialog(DIALOG_NAMES.ITEM_BANK_VIEW, { assessmentItemsSearch: true, history, libraryCardData },
      dialogManager.closeDialog(DIALOG_NAMES.ITEM_BANK_VIEW));
  }

  handleResourceBankView = async (libraryCardData) => {
    const { history } = this.props;
    const { dialogManager, tagContentManager, userManager } = this.props;

    // load some things it may need.
    await tagContentManager.fetchAvailableCategoriesWithTags(null, 'RESOURCE_BANK'); // passing null uses the default set in tag content manager
    await tagContentManager.fetchStandardFrameworkBookmarks(userManager.userId); // passing null uses the default set in tag content manager
    dialogManager.setOpenDialog(DIALOG_NAMES.RESOURCE_BANK_VIEW, {
      assessmentItemsSearch: true,
      history,
      libraryCardData,
      previewLibraryResource: this.previewLibraryResource
    },
    dialogManager.closeDialog(DIALOG_NAMES.RESOURCE_BANK_VIEW));
  }

  handleItemBankDetails = (libraryCardData) => {
    alert(`Details ${libraryCardData.typeDisplayName}.`);
    // TODO open custom itembank card details page
  }

  closeCourseElementModal = async () => {
    const { dialogManager, history } = this.props;
    // the course element modal ends up chaging the url by adding params so reset
    await history.push(`/content?view=${VIEW_SELECTION.PRODUCTS}`);
    dialogManager.closeAllDialogs();
  }

  previewLibraryResource = (libraryCardData) => {
    const { userManager, t } = this.props;

    if (userManager.isTeacher) {
      // if user is a teacher, we want to launch the Course Preview as read-only in a new browser tab
      window.open(`/publisherCourse?courseContentItemId=${libraryCardData.id}&authKey=${Auth.authKey}`);
      return;
    }

    if (libraryCardData?.libraryResource?.entityTypeId === 'course_resource' || libraryCardData?.libraryResource?.isLicensedCourse) {
      const { courseManager, dialogManager, navigationManager } = this.props;
      const contentItemId = libraryCardData?.libraryResource?.contentItemId || libraryCardData?.libraryResource?.id;
      const contentItemName = libraryCardData?.libraryResource?.name;

      courseManager.clearAll();
      courseManager.setCurrentCourseId(contentItemId);
      courseManager.setCurrentCourseName(contentItemName);
      courseManager.setCurrentElementId('');

      navigationManager.clearCoursePaths();
      dialogManager.setOpenDialog(DIALOG_NAMES.COURSE_ELEMENT, {
        ...this.props,
        courseId: contentItemId,
        courseName: contentItemName,
        modalTitle: libraryCardData?.libraryResource?.isLicensedCourse ? t('licensedCourseLabel') : t('assessmentBankLabel'),
        showAdminIcons: true
      },
      this.closeCourseElementModal);
    } else if (libraryCardData?.libraryResource?.entityTypeId === 'publisher') {
      // dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
      //   message: `TBD Preview of ${t('resourceBankLabel')}`,
      //   title: `Preview ${t('resourceBankLabel')}`
      // }, () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
      this.handleResourceBankView(libraryCardData);
    } else {
      this.handleView(
        libraryCardData.id,
        libraryCardData?.libraryResource?.entityTypeId,
        libraryCardData?.libraryResource?.displayName
      );
    }
  }

  editLibraryResource = (libraryCardData) => {
    // TODO open resource in edit mode by resource type
    const { history } = this.props;
    const { courseManager, contentManager, dialogManager } = this.props;

    if (libraryCardData?.libraryResource?.entityTypeId === 'course_resource') {
      if (libraryCardData?.typeDisplayName === 'Course') {
        // if published, we can't edit so redirect to preview
        this.previewLibraryResource(libraryCardData);
      }
    } else if (libraryCardData?.libraryResource?.entityTypeId === 'publisher' && libraryCardData?.libraryResource?.type === 'resource') {
      // for resource banks, we do a preview
      this.previewLibraryResource(libraryCardData);
    } else if (libraryCardData?.libraryResource?.entityTypeId === 'publisher' && libraryCardData?.libraryResource?.type === 'item_bank') {
      // for item banks we open a different open/edit view
      this.handleItemBankView(libraryCardData);
    }
  }

  closeLibraryAssessmentBuilderModal = async () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW);
  }

  confirmEditPublishedAssessmentModal = (libraryCardData) => {
    const { dialogManager, t } = this.props;
    const title = t('editUnavailableTitle');
    const message = `${t('editUnavailableMsg')}`;
    dialogManager.setOpenDialog(DIALOG_NAMES.CONFIRM, {
      title,
      message,
      cancelButtonClass: 'cancelButton',
      cancelButtonName: t('editUnavailableCancelButton'),
      confirmButtonClass: 'saveButton',
      confirmButtonName: 'Copy',
      confirmHandler: () => this.copyLibraryResourceConfirm(libraryCardData, () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM))
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM));
  }

  hasClassesWarning = (linkedClassroomNames) => {
    const { dialogManager, t } = this.props;
    const title = t('hasClassesTitle');
    let message = t('hasClasses');
    let index = 0;
    for (const className of linkedClassroomNames) {
      message = `${message + ((index > 0) ? ',' : '')}"${className}"`;
      index++;
    }

    dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);

    dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
      title,
      message,
      closeButtonClass: 'deleteButton',
      closeButtonName: 'Ok'
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
  }

  deleteLibraryResource = async (libraryCardData) => {
    const { courseManager, dialogManager, libraryManager } = this.props;
    const { activePage } = libraryManager;

    dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);

    const response = await courseManager.deleteContentItem(libraryCardData.id, false);
    let result = false;
    if (response && response.status === 'SUCCESS') {
      result = true;
    }
    if (response && response.status === 'FAILURE') {
      result = false;
      if (response.statusCode === 'HAS_CLASSROOM_LINKS') {
        this.deleteLibraryResourceConfirm(null, contentItem, response.linkedClassroomNames);
        return;
      }
    }
    if (!result) {
      console.error(`Error deleting ${libraryCardData.typeDisplayName}: ${libraryCardData.id}`);
      const errorMessage = `Error deleting${libraryCardData.typeDisplayName}: ${libraryCardData.id} ${response.statusMessage}`;
      console.error(errorMessage);
      let tempMessage = errorMessage;
      tempMessage = tempMessage.length > 99 ? `${tempMessage.substring(0, 96)}...` : tempMessage;
      this.setState({ deletedLibraryResourceName: null, serverErrorMsg: tempMessage });
    } else {
      let tempMessage = `"${libraryCardData.title}"`;
      tempMessage = tempMessage.length > 99 ? `${tempMessage.substring(0, 96)}...` : tempMessage;
      tempMessage += ' has been removed.';
      await courseManager.clearAll(); // clear course data so assessment banks get updated.
      await this.refreshData(activePage);
      this.setState({ deletedLibraryResourceName: tempMessage, serverErrorMsg: tempMessage });
    }
  }

  deleteLibraryResourceConfirm = (libraryCardData, linkedClassroomNames = null) => {
    const { dialogManager, t } = this.props;
    if (linkedClassroomNames && linkedClassroomNames.length > 0) {
      this.hasClassesWarning(linkedClassroomNames);
    } else {
      const title = t('confirmDeleteTitle');
      const message = `${t('confirmDeletePrefix')} "${libraryCardData.title}" ${t('confirmDeleteSuffix')}`;
      dialogManager.setOpenDialog(DIALOG_NAMES.CONFIRM, {
        title,
        message,
        cancelButtonClass: 'keepButton',
        cancelButtonName: 'No, Cancel',
        confirmButtonClass: 'deleteButton',
        confirmButtonName: 'Yes, Continue',
        confirmHandler: () => this.deleteLibraryResource(libraryCardData)
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM));
    }
  }

  moveLibraryResourceToBank = (libraryCardData) => {
    alert(`Move to bank ${libraryCardData.typeDisplayName} called`);
    // TODO move resource to assessment bank by resource type
  }

  removeLibraryResourceFromBank = (libraryCardData) => {
    alert(`Remove from bank ${libraryCardData.typeDisplayName} called`);
    // TODO Remove resource from bank by resource type
  }

  showLibraryResourceDetails = (libraryCardData, onCloseCallback) => {
    const { dialogManager, t, userManager } = this.props;
    const { selectedProduct } = this.state;
    if (libraryCardData?.libraryResource?.isLicensedCourse) {
      if (!userManager.isTeacher) {
        // default (admin) resource details
        dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_RESOURCE_DETAILS_MODAL, {
          courseId: libraryCardData.id,
          headerLabel: `${t('course')} ${t('detailsDialogHeaderLabel')}`,
          open: true,
          productId: libraryCardData?.libraryResource?.productsList || selectedProduct,
          resourceCodeLabel: 'Product Code',
          resourceIdLabel: 'Course ID',
          resourceNameLabel: 'Course Name',
        }, () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_RESOURCE_DETAILS_MODAL));
      } else {
        // teacher resource details
        const { productOptions } = this.state;

        const currentProductOption = productOptions?.find?.((productOption) => {
          return libraryCardData?.libraryResource?.productsList?.includes?.(productOption?.key);
        });

        allowDocumentBodyScroll(false);
        scrollBrowserWindowToTop();
        dialogManager.setOpenDialog(DIALOG_NAMES.TEACHER_LIBRARY_RESOURCE_DETAILS_MODAL, {
          courseContentItemId: libraryCardData.id,
          headerLabel: `${t('course')} ${t('detailsDialogHeaderLabel')}`,
          onClose: () => {
            allowDocumentBodyScroll(true);
            dialogManager.closeDialog(DIALOG_NAMES.TEACHER_LIBRARY_RESOURCE_DETAILS_MODAL);
          },
          product: currentProductOption?.product
        }/* , () => {} */);
      }
    } else if (libraryCardData?.libraryResource?.entityTypeId === 'publisher') {
      dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_RESOURCE_DETAILS_MODAL, {
        headerLabel: `Product ${t('detailsDialogHeaderLabel')}`,
        open: true,
        productId: libraryCardData.id,
        resourceCodeLabel: 'Product Code',
        resourceIdLabel: 'Product ID',
        resourceNameLabel: 'Product Name',
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_RESOURCE_DETAILS_MODAL));
    } else {
      // Launch the create new assessment/(bank) modal in edit mode
      dialogManager.setOpenDialog(DIALOG_NAMES.CREATE_NEW_LIBRARY_RESOURCE_MODAL, {
        close: { close },
        closeCreateNewLibraryResourceModal: { close },
        contentItemId: libraryCardData.id,
        isEdit: !libraryCardData.libraryResource.published,
        isReadOnly: libraryCardData?.libraryResource?.published,
        onClose: () => this.onCloseCreateNewLibraryResourceModal(libraryCardData, onCloseCallback),
        onCloseCreateNewLibraryResourceModal: () => this.onCloseCreateNewLibraryResourceModal(libraryCardData, onCloseCallback),
        open: true,
        selectedResourceType: libraryCardData?.libraryResource?.entityTypeId
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.CREATE_NEW_LIBRARY_RESOURCE_MODAL));
    }
  }

  handleAddLibraryCourseToClassroom = (libraryCardData) => {
    const { dialogManager } = this.props;

    const course = libraryCardData.libraryResource;

    let { selectedProduct: selectedProductId } = this.state;

    if (!selectedProductId || selectedProductId === 'all') {
      // no `selectedProductId` is specified, so set to undefined
      selectedProductId = undefined;
      // TODO remove
      // // no selected product via AdminContentView at the moment
      // // so here we will use the first productId associated with this course as the default
      // const productIdsStr = course?.productsList;
      // const productIds = productIdsStr?.split(',');
      // selectedProductId = productIds?.[0] || '';
    }

    allowDocumentBodyScroll(false);
    scrollBrowserWindowToTop();
    dialogManager.setOpenDialog(DIALOG_NAMES.ADD_LIBRARY_COURSE_TO_CLASSROOMS, {
      course,
      onClose: ({ shouldRefreshData = false } = {}) => {
        if (shouldRefreshData) {
          this.fetchData();
        }
        allowDocumentBodyScroll(true);
        dialogManager.closeDialog(DIALOG_NAMES.ADD_LIBRARY_COURSE_TO_CLASSROOMS);
      },
      selectedProductId
    }/* , () => {} */);
  }

  showLibraryResourceTagsStandards = async (libraryCardData) => {
    const { dialogManager, libraryManager, t } = this.props;
    // alert(`Show tags and standards ${libraryCardData.typeDisplayName} contentItemId: ${libraryCardData.id} called`);
    const results = await libraryManager.getStandardTagAlignmentsByContentId(libraryCardData.id);
    // Open resource tags and standards modal
    dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_STANDARDS_TAGS_MODAL, {
      modalTitle: `${libraryCardData.typeDisplayName} ${t('tagsAndStandardsModalTitle')}`,
      standards: results.standards,
      tags: results.tags
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_STANDARDS_TAGS_MODAL));
  }

  onLibraryCardClick = (libraryCardData) => {
    this.editLibraryResource(libraryCardData);
  }

  hideError = () => {
    this.setState({ deletedLibraryResourceName: null, serverErrorMsg: null });
  }

  getLibraryCardOptions = (resource) => {
    const { selectedMenu } = this.state;
    const { t, userManager } = this.props;
    const options = {};
    // define common options
    const preview = {
      callback: this.previewLibraryResource,
      key: 'preview',
      label: selectedMenu !== 'resourceBanks' ? t('previewOptionLabel') : t('viewOptionLabel')
    };
    const details = {
      callback: this.showLibraryResourceDetails,
      key: 'details',
      label: t('detailsOptionLabel')
    };
    const addToClass = {
      callback: this.handleAddLibraryCourseToClassroom,
      key: 'addToClass',
      label: t('addToClassLabel')
    };

    // add specific options for each menu selection and resource type
    if (selectedMenu === 'itemBank') {
      // add specific options for this menu
      options.open = {
        callback: this.handleItemBankView,
        key: 'open',
        label: t('viewOptionLabel')
      };
      // options.details = {
      //   key: 'details',
      //   label: t('detailsOptionLabel'),
      //   callback: this.handleItemBankDetails
      // };
    } else if (selectedMenu === 'courses' || selectedMenu === 'resourceBanks') {
      options.preview = preview;
      options.details = details;
      if (userManager.isTeacher) {
        options.addToClass = addToClass;
      }
    }
    return options;
  }

  closeItemBankViewRefresh = () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.ITEM_BANK_VIEW);
  }

  handleView = async (contentItemId, entityTypeId, name) => {
    const {
      contentManager,
      dialogManager,
      userManager,
      t
    } = this.props;
    let option = null;
    option = await contentManager.getOptionsForTeacherPreview(
      contentItemId, entityTypeId, null, origin,
      userManager.canViewAsTeacher, userManager.userId,
      null, null,
      null
    );

    const { playerType, viewUrl, isFlowpaper } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    } else if (playerType === null && viewUrl === null) {
      // if playerType and viewURL are null this is an unviewable type.
      if (entityTypeId === 'file_resource') {
        // For file resources, these are download only so show message.
        dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
          title: t('downloadOnlyTitle'),
          message: t('downloadOnlyModalMsg'),
          closeButtonClass: 'deleteButton',
          closeButtonName: 'Okay'
        },
        () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
        return;
      } else {
        // otherwise show generic message.
        dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
          title: t('noPreviewAvailableTitle'),
          message: t('noPreviewAvailableMsg'),
          closeButtonClass: 'deleteButton',
          closeButtonName: 'Ok'
        },
        () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
        return;
      }
    }

    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        contentItemId,
        contentItemType: entityTypeId,
        resourceName: name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
    }
    this.setState({
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      contentUrl: viewUrl,
      contentMode: '',
      isFlowpaper,
      contentItemId,
      contentItemType: entityTypeId,
      contentName: name,
      assignmentId: '',
      previewContentType: entityTypeId,
      previewContentItemId: '',
      resourceName: '',
      instruction: '',
      contentImageUrl: ''
    });
  }

  hideIframe = () => {
    this.setState({ lessonPlayerShowing: false });
  }

  hideModal = () => {
    this.setState({
      docreaderViewerShowing: false,
      fileViewerShowing: false,
      instruction: '',
      learnosityPlayerShowing: false
    });
  }

  renderFileViewer = () => {
    const { userManager, renderFileViewer } = this.props;
    const { contentImageUrl, contentName, contentUrl, previewContentType, instruction, isFlowpaper, resourceName } = this.state;
    const { FileViewerModal } = this;

    if (renderFileViewer !== undefined) {
      return renderFileViewer();
    }

    return (
      <div className='course-content'>
        <FileViewerModal
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentName={contentName}
          contentType={previewContentType}
          instruction={instruction}
          isFlowpaper={isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='file-viewer'
          resourceName={resourceName}
          url={contentUrl} />
      </div>
    );
  }

  renderDocreaderViewer = () => {
    const { userManager, contentManager, renderDocreaderViewer } = this.props;
    const { contentImageUrl, contentMode, contentName, contentUrl, previewContentType, instruction, isFlowpaper, resourceName } = this.state;
    const { DocReaderModal } = this;
    if (renderDocreaderViewer !== undefined) {
      return renderDocreaderViewer();
    }

    return (
      <div className='course-content'>
        <DocReaderModal
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentMode={contentMode}
          contentName={contentName}
          contentType={previewContentType}
          instruction={instruction}
          isFlowpaper={isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='docreader-viewer'
          resourceName={resourceName}
          sessionId={contentManager.currentflowPaperSessionId}
          url={contentUrl} />
      </div>
    );
  }

  renderLessonPlayer = () => {
    const { FullscreenModal } = this;
    const { userManager } = this.props;
    const { contentImageUrl, contentName, contentUrl } = this.state;
    return (
      <Container className='libraryView' fluid>
        <FullscreenModal
          closeIframeCallback={this.hideIframe}
          contentImageUrl={contentImageUrl}
          contentName={contentName}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={contentUrl} />
      </Container>
    );
  }

  renderMenuView = () => {
    const { libraryManager, t } = this.props;
    const { libraryCardData, selectedMenu } = this.state;
    const { libraryLoading } = libraryManager;
    if (libraryCardData && libraryCardData.length > 0) {
      return (
        <>
          {this.renderLibraryViewCards()}
        </>
      );
    } else if (libraryLoading && (!libraryCardData || (libraryCardData && libraryCardData.length < 1))) {
      return (
        <div className='null-state-panel'>
          <Loader active inline />
        </div>
      );
    } else {
      return (
        <div className='null-state-panel'>
          {t('noData')}
        </div>
      );
    }
  }

  renderLibraryViewCards = () => {
    const { libraryCardData, courseContentItemIdsForCurrentUser } = this.state;
    const { LibraryResourceCard } = this;
    let libraryCards = [];
    if (libraryCardData && libraryCardData.length > 0) {
      libraryCards = libraryCardData.map((data, index) => {
        const inUseByCurrentUser = courseContentItemIdsForCurrentUser.has(data.id);

        const useCardClickTypes = 'course_resource, assessment, publisher'; // TODO should other cards have a body click action?
        const useCardClick = useCardClickTypes.includes(data.libraryResource?.entityTypeId);
        const libraryCardKebabOptions = this.getLibraryCardOptions(data);

        return (
          <LibraryResourceCard key={index}
            libraryCardKebabOptions={libraryCardKebabOptions}
            libraryCardParams={data}
            onBodyClick={useCardClick ? this.onLibraryCardClick : null}
            showClassroomsBanner={inUseByCurrentUser} />
        );
      });
    }
    return (
      <div className='content-view-card-container'>
        {libraryCards}
      </div>
    );
  }

  renderLibraryView() {
    const {
      libraryManager,
      userManager,
      t
    } = this.props;
    const { activePage = 1, libraryLoading, totalPages, totalResults } = libraryManager;
    const { libraryCardData, productOptions, searchText, selectedMenu, selectedProduct, serverErrorMsg, sortKey } = this.state;
    const startResultNumber = activePage <= 1 ? 1 : (activePage - 1) * 25 + 1;
    const endResultNumber = (activePage * 25) > totalResults ? totalResults : activePage * 25;
    return (
      <Container className='contentView' fluid>
        <div className='content-view-container'>
          <div className='content-view-menu-container'>
            <Button basic className={`menu-item courses ${selectedMenu === 'courses' ? 'selected' : ''}`} disabled={libraryLoading} onClick={selectedMenu !== 'courses' ? () => this.handleMenuClick('courses') : null}
              primary>{t('coursesButtonLabel')}
            </Button>
            {userManager.canViewLibraryItemBank && !userManager.isTeacher && (
              <Button basic className={`menu-item item-bank ${selectedMenu === 'itemBank' ? 'selected' : ''}`} disabled={libraryLoading} onClick={selectedMenu !== 'itemBank' ? () => this.handleMenuClick('itemBank') : null}
                primary>{t('itemBankLabel')}
              </Button>
            )}
            {userManager.canViewLibraryResourceBank && !userManager.isTeacher && (
              <Button basic className={`menu-item resourceBanks ${selectedMenu === 'resourceBanks' ? 'selected' : ''}`} disabled={libraryLoading} onClick={selectedMenu !== 'resourceBanks' ? () => this.handleMenuClick('resourceBanks') : null}
                primary>{t('resourceBanksButtonLabel')}
              </Button>
            )}
          </div>
          <div className='content-view-content-container'>
            <div className='content-view-content-header'>
              <div className='header-top-row'>
                <div className='header-top-row-top-wrapper'>
                  <div className='error-message-wrapper'>
                    <Message
                      className='content-view-error-msg'
                      content={`${serverErrorMsg}`}
                      error
                      hidden={serverErrorMsg === null}
                      onDismiss={this.hideError} />
                  </div>
                </div>
                <div className='content-description-text'>
                  {userManager.canViewLibraryItemBank ? t(`${selectedMenu}Description`) : t(`${selectedMenu}ResourcesOnlyDescription`)}
                </div>
              </div>
              <div className='header-bottom-row'>
                <div className='left-container'>
                  {(selectedMenu !== 'courses' && libraryCardData && libraryCardData.length > 0) && (
                    <div className='content-sort-container'>
                      <Dropdown
                        key='sort-select'
                        fluid
                        name='sort-select'
                        onChange={this.handleSort}
                        options={[
                          { key: 1, text: 'Sort by Newest', value: 'newest' },
                          { key: 2, text: 'Sort by Oldest', value: 'oldest' },
                          { key: 3, text: 'Sort by Title - Ascending', value: 'title-asc' },
                          { key: 4, text: 'Sort by Title - Descending', value: 'title-desc' }
                        ]}
                        placeholder='Sort by'
                        search
                        selection
                        value={sortKey} />
                    </div>
                  )}
                  {(selectedMenu === 'courses') && (
                    <div className='content-product-container'>
                      <Dropdown
                        key='product-select'
                        fluid
                        name='product-select'
                        onChange={this.handleSelectProduct}
                        options={productOptions}
                        placeholder='Product'
                        search
                        selection
                        value={selectedProduct} />
                    </div>
                  )}
                  {(selectedMenu === 'courses') && (
                    <div className='content-search-container'>
                      <Input
                        icon='search'
                        name='searchText'
                        onChange={this.handleChangeSearchText}
                        placeholder='Search'
                        type='text'
                        value={searchText || ''} />
                    </div>
                  )}
                </div>
                <div className='paginator-container'>
                  {(libraryCardData && libraryCardData.length > 0) && (
                    <>
                      <div className='pagination-text'>
                        {`${startResultNumber}-${endResultNumber} of ${totalResults}`}
                      </div>
                      <Pagination
                        activePage={activePage}
                        onPageChange={this.onPageChange}
                        totalPages={totalPages} />
                    </>
                  )}
                </div>
              </div>
            </div>
            {this.renderMenuView()}
          </div>
        </div>
      </Container>
    );
  }

  render() {
    const {
      docreaderViewerShowing,
      fileViewerShowing,
      lessonPlayerShowing
    } = this.state;
    if (lessonPlayerShowing) {
      return (this.renderLessonPlayer());
    } else if (fileViewerShowing) {
      return (this.renderFileViewer());
    } else if (docreaderViewerShowing) {
      return (this.renderDocreaderViewer());
    } else {
      return (this.renderLibraryView());
    }
  }
}
SatCoreRegister('AdminContentView', AdminContentView);
