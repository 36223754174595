import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Auth from '../managers/AuthManager';

export default function withAuth(AuthComponent) {
  return class AuthWrapped extends Component {
    render() {
      return (
        <>
          {(!Auth.loggedIn()) ?
            <Redirect to='/signin' /> :
            <AuthComponent history={this.props.history} />}
        </>
      );
    }
  };
}
