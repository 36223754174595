import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { SatCoreRegister } from '../../SatCoreRegistry';
import UtilityService from '../../services/UtilityService';

import '../../css/ModalBannerDraggable.less';

const ModalBannerDraggable = (props) => {
  const { label, title, onClose, titleContainer, infoPopup } = props;

  // We need the label and title to be one unit as they both can vary by length and so need the browser
  // to calculate the whole length to get ellipsis to work correctly.
  const bannerLabel = label ? UtilityService.reactHtmlParserWrapper(label).parsedNoPWrap : '';
  const bannerTitle = title ? UtilityService.reactHtmlParserWrapper(title).parsedNoPWrap : '';
  const processedTitle = (
    <span>
      {bannerLabel}
      {' '}
      {bannerTitle}
    </span>
  );

  return (
    <div className='modal-header'>
      <div className='modal-header-bar'>
        {bannerTitle ? (
          <span className='modal-title-container' {...titleContainer}>
            <span className='modal-banner-title-container'>
              <Popup
                content={bannerTitle}
                on='hover'
                position='top right'
                trigger={(
                  <span className='modal-banner-title'>
                    {processedTitle}
                  </span>
              )}
                wide />
              <span className='info-popup'>
                {infoPopup &&
                  infoPopup}
              </span>
            </span>
          </span>
        ) : (
          <div className='modal-banner-title' {...titleContainer}>
            {bannerLabel}
          </div>
        )}
        <Button
          aria-label='Close modal'
          basic
          className='btn-modal-banner-close'
          icon='close'
          onClick={onClose}
          size='huge' />
      </div>
    </div>
  );
};

export default ModalBannerDraggable;

SatCoreRegister('ModalBannerDraggable', ModalBannerDraggable);
