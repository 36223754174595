import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import Modal from '../Modal';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import '../../css/UpdateUserPasswordModal.less';

export default
@inject('userManager', 'dialogManager', 'navigationManager', 'adminUsersManager')
@observer
class UpdateUserPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      hidePasswordError: true,
      passwordInputType: 'password'
    };
    this.ShowPasswordButton = SatCoreComponent('ShowPasswordButton');
  }

  onChangePassword = async () => {
    const { save, close } = this.props;
    const { newPassword } = this.state;
    if (!this.validate()) {
      return;
    }
    await save(newPassword);
    close();
  }

  validate = () => {
    const { newPassword } = this.state;
    if (!/^.{8,}$/.test(newPassword) || !/^\s*\S.*$/.test(newPassword)) {
      this.setState({ hidePasswordError: false });
      return false;
    }
    return true;
  }

  onChangePasswordInput = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({ ...prevState, newPassword: value }));
  }

  clearError = () => {
    this.setState({ hidePasswordError: true });
  }

  makeAccessCode = async () => {
    const { userManager } = this.props;
    const code = await userManager.getTempStudentCode();
    this.setState({ newPassword: code, passwordInputType: 'text' });
  }

  render() {
    const { userName, open, close } = this.props;
    const { newPassword, passwordInputType, hidePasswordError } = this.state;
    const { ShowPasswordButton } = this;

    return (
      <Modal
        className='updateUserPasswordModal'
        closeOnDimmerClick={false}
        closeOnEscape={true}
        onClose={close}
        open={open}>
        <Modal.Header>Reset Password</Modal.Header>
        <Modal.Content>
          <p className='studentName'>{userName}</p>
          <Form.Field>
            <Input
              label={(
                <>
                  <ShowPasswordButton
                    isPassword={passwordInputType === 'password'}
                    name='passwordInputType'
                    onFlip={(name, isPassword) => this.setState({ [name]: isPassword ? 'password' : 'text' })} />
                  <a onClick={this.makeAccessCode}>Refresh</a>
                </>
            )}
              labelPosition='right'
              name='password'
              onChange={this.onChangePasswordInput}
              onFocus={this.clearError}
              onKeyPress={(e) => e.stopPropagation()}
              placeholder='New Password...'
              type={passwordInputType}
              value={newPassword} />
            <Message
              content='Password must be at least 8 characters and cannot be all spaces.'
              error
              hidden={hidePasswordError} />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button basic className='cancelButton' onClick={close} primary>Cancel</Button>
          <Button basic className='saveButton' onClick={this.onChangePassword} primary>Save</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('UpdateUserPasswordModal', UpdateUserPasswordModal);
