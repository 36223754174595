import { registerClass } from '../SatCoreRegistry';

import classroomManager from '../managers/ClassroomManager';
import courseManager, { ELEMENT_TYPE } from '../managers/CourseManager';
import dialogManager, { DIALOG_NAMES } from '../managers/DialogManager';
import productManager from '../managers/ProductManager';
import userManager from '../managers/UserManager';

import CustomCourseResourceService from './CustomCourseResourceService';
import NavigationService from './NavigationService';

import { dateFormat } from '../utils';

export default class CourseService {
  static manager() {
    return courseManager;
  }

  static forceRefreshCourseData = async () => {
    const courseId = courseManager.currentCourseId;
    const classId = classroomManager.currentClassroomId;
    const elementId = courseManager.currentElementId;
    const force = true;
    const { isStudent } = userManager;
    const skipSetElement = false;
    await courseManager.fetchCourseData(
      courseId, classId, elementId, force, isStudent, skipSetElement
    );
  }

  /** called when 'Add Course' button is clicked via CourseListBanner */
  static async handleAddCourse(history) {
    productManager.setShouldFetchLicensedProducts(true);
    dialogManager.setOpenDialog(DIALOG_NAMES.ADD_COURSE_TO_CLASS, {
      classroomId: classroomManager.currentClassroomId,
      history
    }, () => dialogManager.closeDialog(DIALOG_NAMES.ADD_COURSE_TO_CLASS));
  }

  static async handleAddCustomAssessment(history) {
    const { currentClassroomId } = classroomManager;
    const { currentCourseId } = courseManager;
    dialogManager.setOpenDialog(DIALOG_NAMES.ADD_CUSTOM_ASSESSMENT, {
      classroomId: currentClassroomId,
      courseId: currentCourseId,
      history
    }, () => dialogManager.closeDialog(DIALOG_NAMES.ADD_CUSTOM_ASSESSMENT));
    courseManager.clearProductCourses();
    const returnImmediatelyAfterResponse = false;
    await CustomCourseResourceService.fetchUserCustomAssessmentList(returnImmediatelyAfterResponse);
  }

  static async initCourseData(shouldLoadData = true, force = false, _rebuild, {
    courseId,
    courseElementId
  } = {}) {
    const elementId = courseElementId || (courseManager.currentElementId === ELEMENT_TYPE.ROOT) ? '' : courseManager.currentElementId;
    if (force || (!courseManager.treeLoading &&
      (courseManager.currentCourseElementList === null || courseManager.currentCourseElementList.length === 0))) {
      const list = await courseManager.fetchCourseData(
        (courseId || courseManager.currentCourseId), classroomManager.currentClassroomId,
        elementId, force, userManager.isStudent);
      if (shouldLoadData) {
        CourseService.loadData(list);
      }
    }
  }

  static async initCourseDataProduct(shouldLoadData = true, force = false) {
    if (force || (!courseManager.treeLoading &&
      (courseManager.currentCourseElementList === null || courseManager.currentCourseElementList.length === 0))) {
      const elementId = (courseManager.currentElementId === ELEMENT_TYPE.ROOT) ? '' : courseManager.currentElementId;
      const list = await courseManager.fetchCourseData(
        courseManager.currentCourseId, classroomManager.currentClassroomId,
        elementId, false, userManager.isStudent);
      if (shouldLoadData) {
        this.loadDataProduct(list);
      }
    }
  }

  static loadData(list) {
    const classroom = classroomManager.getClassroom(classroomManager.currentClassroomId);

    const course = courseManager.getCurrentCourse(classroomManager.currentClassroomId);
    const courseCount = courseManager.getClassroomCourseCount(classroomManager.currentClassroomId);
    NavigationService.buildReFreshPaths(
      classroom, courseCount,
      classroomManager.getClassName(classroomManager.currentClassroomId),
      course, list, courseManager, userManager.isStudent
    );
  }

  static loadDataProduct(list) {
    const classroom = productManager.getProductClassroom();
    const course = productManager.getProductCourse(courseManager.currentCourseId, courseManager.currentCourseName);
    const courseCount = 1;
    NavigationService.buildReFreshPaths(
      classroom, courseCount,
      classroomManager.getClassName(classroomManager.currentClassroomId),
      course, list, courseManager, userManager.isStudent
    );
  }

  static async searchCourseResourceElements() {
    await courseManager.searchCourseResourceElements();
  }

  static fetchClassroomCourses(classroomId, isTeacher, isStudent) {
    isTeacher = typeof isTeacher === 'boolean' ? isTeacher : userManager.isTeacher;
    isStudent = typeof isStudent === 'boolean' ? isStudent : userManager.isStudent;
    return courseManager.fetchClassroomCourses(classroomId, isTeacher, isStudent).then(() => {});
  }

  static formatTimeframeCardData (reviewOutsideTimeframe, timeframeStartDate, timeframeEndDate, timeframeStartDateStr, timeframeStartTimeStr, timeframeEndDateStr, timeframeEndTimeStr) {

    const showTimeframe = (timeframeStartDate || timeframeEndDate) ? true : false;

    const formattedTimeframeStartDate = timeframeStartDateStr// these are now formatted on BE ? dateFormat(timeframeStartDate, 'twoDigitDate') : null;
    const formattedTimeframeEndDate = timeframeEndDateStr// these are now formatted on BE ? dateFormat(timeframeEndDate, 'twoDigitDate') : null;
    const formattedTimeframeStartTime = timeframeStartTimeStr;// these are now formatted on BE ? dateFormat(timeframeStartDate, 'twoDigitTime') : null;
    const formattedTimeframeEndTime = timeframeEndTimeStr;// these are now formatted on BE ? dateFormat(timeframeEndDate, 'twoDigitTime') : null;

    let allowTimeframeViewing = true;
    let timeframeStartPending = false;
    let timeframeEndPassed = false;
    if (!reviewOutsideTimeframe) {
      const now = new Date();
      const realTimeFrameStartDate = timeframeStartDate ? new Date(timeframeStartDate) : null;
      const realTimeFrameEndDate = timeframeEndDate ? new Date(timeframeEndDate) : null;
      if (realTimeFrameStartDate && realTimeFrameEndDate) {
        allowTimeframeViewing = (realTimeFrameStartDate < now) && (realTimeFrameEndDate > now);
        timeframeStartPending = !(realTimeFrameStartDate < now);
        timeframeEndPassed = !(realTimeFrameEndDate > now)
      } else if (realTimeFrameStartDate && !realTimeFrameEndDate) {
        allowTimeframeViewing = realTimeFrameStartDate < now;
        timeframeStartPending = !allowTimeframeViewing;
      } else if (!realTimeFrameStartDate && realTimeFrameEndDate) {
        allowTimeframeViewing = realTimeFrameEndDate > now;
        timeframeEndPassed = !allowTimeframeViewing;
      }
    }

    let timeframeText = formattedTimeframeStartDate || formattedTimeframeEndDate ? `Available` : '';
    timeframeText += formattedTimeframeStartDate ? ` ${formattedTimeframeStartDate}` : '';
    timeframeText += formattedTimeframeStartTime ? ` at ${formattedTimeframeStartTime}` : '';
    const endPreposition = formattedTimeframeStartDate ? 'to' : 'until';
    timeframeText += formattedTimeframeEndDate ? ` ${endPreposition} ${formattedTimeframeEndDate}` : '';
    timeframeText += formattedTimeframeEndTime ? ` at ${formattedTimeframeEndTime}` : '';
    const timeframeData = {
      allowTimeframeViewing,
      formattedTimeframeStartDate,
      formattedTimeframeEndDate,
      formattedTimeframeStartTime,
      formattedTimeframeEndTime,
      showTimeframe,
      timeframeStartPending,
      timeframeEndPassed,
      timeframeText
    };

    return timeframeData;
  }

  // TODO unused
  // static getCourseTreeMapId = () => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const courseContentItemId = urlParams.get('courseId');
  //   const classroomId = ClassroomService.getCurrentClassroomId();
  //   const courseResourceElementId = urlParams.get('elementId');
  //   if (courseContentItemId && classroomId && courseResourceElementId) {
  //     return `${courseContentItemId}${classroomId}${courseResourceElementId}`;
  //   }
  // }
}

registerClass('CourseService', CourseService);
