import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, Icon } from 'semantic-ui-react';

import '../../css/reports/ReportDownloadButtons.less';

import { SatCoreRegister } from '../../SatCoreRegistry';

import { register } from '../../i18n';

import { DIALOG_NAMES } from '../../managers/DialogManager';

import { stripHtmlTags } from '../../utils';

import PopupService from '../../services/PopupService';
import ReportIdentityService from '../../services/reports/ReportIdentityService';

const t = register('ReportDownloadButtons');

const ReportDownloadButtons = observer((props) => {
  const { isExportForReportTableRowResource } = props;

  const {
    dialogManager,
    reportIdentityManager,
    reportContextManager
  } = useContext(MobXProviderContext);

  const {
    isTableIndividualReport, isTableSummaryReport
  } = reportIdentityManager;

  const handleExportReport = () => {
    dialogManager.setOpenDialog(DIALOG_NAMES.EXPORT_REPORT, ReportIdentityService.getReportExportParams());
  };

  const handleExportReportForTableRowResource = () => {
    dialogManager.setOpenDialog(DIALOG_NAMES.EXPORT_ASSIGNMENT, {
      ...props,
      // TODO remove // ...ReportIdentityService.getReportExportParams(),
      modalBannerLabel: t('exportModalBannerLabel', {
        resourceName: stripHtmlTags(props?.row?.original?.elementName || 'Assignment')
      })
    });
  };

  const renderReportDownloadButtons = () => {
    const { isContextProgressReport, isContextEngagementReport } = reportContextManager;

    if (isExportForReportTableRowResource) {
      return renderReportTableRowResourceExportButton();
    }

    const isReportStandardsClassroomDetailStudents = ReportIdentityService.isReportStandardsClassroomDetailStudents();
    const allowReportDownloadButtons = !isTableSummaryReport && !isReportStandardsClassroomDetailStudents;
    const allowExportReport = allowReportDownloadButtons && !isTableIndividualReport && !isContextProgressReport
      && !isContextEngagementReport;
    const allowPrintReport = allowReportDownloadButtons && isTableIndividualReport;
    return (
      <>
        {allowReportDownloadButtons && (
          <div className='report-download-buttons'>
            {allowExportReport && (
              <div className='report-export'>
                <Button onClick={() => handleExportReport()}>
                  <Icon name='download' />
                  {t('export')}
                </Button>
              </div>
            )}
            {allowPrintReport && (
              <div className='report-print'>
                <Button onClick={() => { window.print(); }}>
                  <Icon name='print' />
                  {t('print')}
                </Button>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const renderReportTableRowResourceExportButton = () => {
    const reportTableRowResourceExportButtonDescription = t('reportTableRowResourceExportButtonDescription');
    return PopupService.renderPopup({
      content: (
        <div className='report-table-row-resource-export-button-content'>
          {reportTableRowResourceExportButtonDescription}
        </div>
      ),
      offset: [-3, 0],
      position: 'bottom center',
      trigger: (
        <div className='report-table-row-resource-export-button-trigger'
          onClick={() => handleExportReportForTableRowResource()}>
          <Icon name='download' />
        </div>
      )
    });
  };

  return renderReportDownloadButtons();
});
export default ReportDownloadButtons;

SatCoreRegister('ReportDownloadButtons', ReportDownloadButtons);
