import React, { Component } from 'react';
import { inject } from 'mobx-react';

import { Image, Popup } from 'semantic-ui-react';
import iconInfo from '../img/icon-info.svg';
import iconInfoWhite from '../img/icon-info-white.svg';

import { SatCoreRegister } from '../SatCoreRegistry';

export default @inject(
  'dialogManager', 'userManager'
)
class RegTypeInfoIconPopup extends Component {
  render() {
    const {
      dialogManager, iconImageClassName, popupClassName, userManager
    } = this.props;
    let infoPopupContent = <></>;
    if (userManager.isSsoUser) {
      infoPopupContent = (
        <>
          <div className='info-icon-popup-header'>
            Account data has been linked.
          </div>
          Your account details are added to the system through single sign on.
        </>
      );
    } else {
      infoPopupContent = (
        <>
          <div className='info-icon-popup-header'>
            Account data has been linked.
          </div>
          Your account details are added to the system through self-registration.
        </>
      );
    }
    const icon = dialogManager.useWhiteInfoIcon ? iconInfoWhite : iconInfo;
    return (
      <Popup
        className={popupClassName}
        content={infoPopupContent}
        on='click'
        position='right center'
        trigger={(
          <Image
            alt=''
            className={iconImageClassName}
            spaced
            src={icon} />
        )} />
    );
  }
}
SatCoreRegister('RegTypeInfoIconPopup', RegTypeInfoIconPopup);
