import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../css/reports/ReportColorKeyCard.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { getStyleVar } from '../../utils';

import ReportScoreService from '../../services/reports/ReportScoreService';

const ReportColorKeyCard = observer((props) => {
  const { reportIdentityManager } = useContext(MobXProviderContext);

  const ColorKeyCard = SatCoreComponent('ColorKeyCard');

  const renderReportColorKeyCard = () => {
    const {
      isStandardsReport, isTableIndividualReport, reportInfoClassNames
    } = reportIdentityManager;

    const colorKeyDataArray = ReportScoreService.getReportScoreColorKeyDataArray();
    const pieChartSlices = colorKeyDataArray.map((key) => {
      return {
        color: getStyleVar(`--report-${isStandardsReport ? 'standards' : 'default'}-${key.id}`)
      };
    });
    const showCounts = isTableIndividualReport;
    const showPieChart = isTableIndividualReport;
    return (
      <ColorKeyCard {...props}
        additionalClassNames={`report-color-key-card report-font-size ${reportInfoClassNames}`}
        colorKeyDataArray={colorKeyDataArray}
        keyFlexPercent={50}
        pieChartOptions={{
          slices: pieChartSlices
        }}
        showCounts={showCounts}
        showPieChart={showPieChart}
        useColorAsClassName />
    );
  };
  return renderReportColorKeyCard();
});
export default ReportColorKeyCard;

SatCoreRegister('ReportColorKeyCard', ReportColorKeyCard);
