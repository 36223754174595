import React from 'react';
import { Image } from 'semantic-ui-react';
import { SatCoreRegister } from '../SatCoreRegistry';

import { ASSIGNMENT_STATUS } from '../managers/AssignmentManager';
import iconClosed from '../img/icon-closed.svg';
import iconCompleted from '../img/icon-completed.svg';
import iconLocked from '../img/icon-locked.svg';
import iconPending from '../img/icon-pending.svg';
import iconReady from '../img/icon-ready.svg';
import iconStarted from '../img/icon-started.svg';
// import iconSubmitted from '../img/icon-submitted.svg';
// import iconNotSubmitted from '../img/icon-not-submitted.svg';

import '../css/AssignmentStatus.less';

const AssignmentStatus = (props) => {
  const {
    direction, isGradebook, /* isSubmitted, */
    noLabel, status, t
  } = props;
  let statusImage = '';
  let statusClass = '';
  const assignmentStatus = isGradebook ? t(status, ASSIGNMENT_STATUS.getFlag(status)) : t(ASSIGNMENT_STATUS.getFlag(status));
  if (status?.includes?.('pending')) {
    statusImage = iconPending;
    statusClass = 'icon-pending';
  } else if (status === ASSIGNMENT_STATUS.STARTED) {
    statusImage = iconStarted;
    statusClass = 'icon-started';
  } else if (status === ASSIGNMENT_STATUS.READY) {
    statusImage = iconReady;
    statusClass = 'icon-ready';
  } else if (status === ASSIGNMENT_STATUS.LOCKED) {
    statusImage = iconLocked;
    statusClass = 'icon-locked';
  } else if (status === ASSIGNMENT_STATUS.CLOSED) {
    statusImage = iconClosed;
    statusClass = 'icon-closed';
  } else if (status === ASSIGNMENT_STATUS.COMPLETED) {
    statusImage = iconCompleted;
    statusClass = 'icon-completed';
  }
  return (
    <div
      className={(direction === 'vertical') ? 'status-vertical status-wrapper' : 'status-horizontal status-wrapper'}
      onClick={props.onClick}>
      {noLabel === true ? null : <div className='status card-label'>Status:</div>}
      <div className='status-image'><Image alt='' className={statusClass} src={statusImage} /></div>
      <div className='status card-value'>{assignmentStatus}</div>
    </div>
  );
};
export default AssignmentStatus;

SatCoreRegister('AssignmentStatus', AssignmentStatus);
