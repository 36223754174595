import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Button, Container, Header } from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/AssignmentInfoModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import UtilityService from '../../services/UtilityService';

export default class AssignmentInfoModal extends Component {
  render() {
    const {
      addInfoOpen,
      assignment = null,
      bannerLabel,
      contentItemDescription,
      closeInfoModal,
      extraClassNames,
      infoName,
      resourceInfo,
      studentInstruction,
      t
    } = this.props;

    const ModalBanner = SatCoreComponent('ModalBanner');
    const AssignmentInfoCard = SatCoreComponent('AssignmentInfoCard');

    const transformFn = UtilityService.reactHtmlParserTransform;

    let classNames = 'assignment-info-dialog';
    classNames += extraClassNames ? ` ${extraClassNames}` : '';

    const infoNameObj = UtilityService.reactHtmlParserWrapper(
      infoName
    );

    return (
      <Modal
        className={classNames}
        open={addInfoOpen}>
        <ModalBanner
          label={bannerLabel || 'Info: '}
          onClose={() => { closeInfoModal(); }}
          title={infoNameObj.stripped} />
        <Modal.Content>
          <div className='content-container'>
            {assignment && (
              <AssignmentInfoCard
                assignment={assignment} />
            )}
            <Modal.Description>
              {(contentItemDescription) ? (
                <>
                  <Header>{t('ContentHeader', 'Content Description')}</Header>
                  <Container>
                    {ReactHtmlParser(contentItemDescription, {
                      transform: transformFn
                    })}
                  </Container>
                </>
              ) : null}
              {(resourceInfo) ? (
                <>
                  <Header className='info-subtitle'>{t('WhenToUse', 'Missing when to use translation')}</Header>
                  <Container>
                    {ReactHtmlParser(resourceInfo, {
                      transform: transformFn
                    })}
                  </Container>
                </>
              ) : null}
              {(studentInstruction) ? (
                <>
                  <Header>Student Instructions</Header>
                  <Container>
                    {ReactHtmlParser(studentInstruction, {
                      transform: transformFn
                    })}
                  </Container>
                </>
              ) : null}
            </Modal.Description>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => { closeInfoModal(); }} primary>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('AssignmentInfoModal', AssignmentInfoModal);
