import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import PopupService from '../../../services/PopupService';
import ReportTableService from '../../../services/reports/ReportTableService';
import UsageService from '../../../services/UsageService';
import ReportEngagementService from '../../../services/reports/ReportEngagementService';

const ReportAverageResultCell = observer((props) => {
  const { row, t, value } = props;

  const isRowExemptFromHavingValue = ReportTableService.isRowExemptFromHavingValue(row);
  if (isRowExemptFromHavingValue) {
    return null;
  }

  const {
    reportContextManager,
    reportIdentityManager
  } = useContext(MobXProviderContext);

  const { isContextEngagementReport, isContextPerformanceReport } = reportContextManager;
  const { reportInfoClassNames } = reportIdentityManager;

  if (isContextPerformanceReport) {
    const avgScoreStr = typeof value === 'number' ? `${Math.round(value)}%` : '—';

    const content = (
      <div className='cell-average-result-content'>
        {avgScoreStr === '—' ? t('dashText') : avgScoreStr}
      </div>
    );
    const trigger = avgScoreStr;

    return PopupService.renderPopup({
      className: `cell-average-result-popup ${reportInfoClassNames}`,
      content,
      disabled: true,
      trigger
    });
  } else if (isContextEngagementReport) {
    return ReportEngagementService.renderReportEngagementLabel(value, {
      isFacultyAverageCell: true
    }) || '—';
  } else {
    const viewedTimeInSeconds = value;
    return UsageService.getUsageLabel({ viewedTimeInSeconds });
  }
});
export default ReportAverageResultCell;

SatCoreRegister('ReportAverageResultCell', ReportAverageResultCell);
