import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import {
  Button, Container, Form, Grid,
  Header, Input, Label,
  Message, Pagination, Popup, Tab
} from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/AddCourseModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import Auth from '../../managers/AuthManager';

import CourseService from '../../services/CourseService';
import CustomCourseResourceService from '../../services/CustomCourseResourceService';
import ProductService from '../../services/ProductService';
import CourseNavigationService from '../../services/CourseNavigationService';

import { DIALOG_NAMES } from '../../managers/DialogManager';

import InputModal from './InputModal';

const PAGE_SIZE = 999; // we need all product course data in advance since we currently handle that filtering on the FE

export default
@inject(
  'classroomManager', 'courseManager', 'dialogManager',
  'navigationManager', 'productManager', 'userManager')
@observer
class AddCourseToClassroom extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.CourseCard = SatCoreComponent('CourseCard');
    this.ProductDropdown = SatCoreComponent('ProductDropdown');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');
  }

  componentDidMount = async () => {
    await this.refreshAllCourseData();
  }

  getInitialState = () => ({
    deletedCourseName: null,
    isInputModalOpen: false,
    isSelectedCoursesPopupOpen: false,
    loading: true,
    serverError: false,
    serverErrorMsg: null,
    totalProductPages: 1,
    zeroStateMsg: null
  })

  closeAddCourseToClassModal = async (didWeAddCourses = false) => {
    const { courseManager, productManager } = this.props;
    productManager.setIsFromProduct(false);
    productManager.setSelectedUserProductId('allProducts');
    this.setState(this.getInitialState());
    // eslint-disable-next-line react/destructuring-assignment
    this.props.closeAddCourseToClassModal(didWeAddCourses);
    courseManager.clearSelectedNotAttachedProductCourses();
    courseManager.setActiveTabIndex(0);
    courseManager.setActiveTab('publisher_courses');
    await this.refreshAllCourseData();
  }

  submitAddSelectedCoursesToClass = async () => {
    if (this.state.loading) {
      return false;
    }
    this.setState({ loading: true });
    await this.saveResults();
  }

  saveResults = async () => {
    const { courseManager } = this.props;
    if (courseManager.selectedNotAttachedProductCourseIds.length) {
      await this.doSaveThenRefresh();
    } else {
      this.setState({ loading: false });
      await this.closeAddCourseToClassModal();
    }
  }

  doSaveThenRefresh = async () => {
    const { classroomId, courseManager } = this.props;
    const result = await courseManager.addCoursesToClassroom(
      classroomId, courseManager.selectedNotAttachedProductCourseIds
    );
    if (!result) {
      alert('A recently deleted course cannot be added to this class. It will disappear from the list soon.');
      this.setState({ loading: false });
    } else {
      await this.doRefresh();
    }
  }

  doRefresh = async (closeShoppingExperienceAfterRefresh = true) => {
    const {
      classroomId, classroomManager, userManager
    } = this.props;
    try {
      await classroomManager.fetchClassroomData(classroomId);
      await CourseService.fetchClassroomCourses(classroomId, userManager.isTeacher, userManager.isStudent);
      if (closeShoppingExperienceAfterRefresh) {
        const didWeAddCourses = true;
        // remove the classes course cache as it may interfere with opening the new course
        CourseNavigationService.removeClassroomCourseLocation(classroomId);
        await this.closeAddCourseToClassModal(didWeAddCourses);
      } else {
        await this.refreshAllCourseData();
      }
    } catch (error) {
      console.error(error);
      this.setState({
        serverError: true,
        serverErrorMsg: 'Something went wrong when refreshing courses, please try again'
      });
    }
  }

  handleReadOnlyPreview = (course) => {
    window.open(`/publisherCourse?courseContentItemId=${course.id}&authKey=${Auth.authKey}`);
  }

  handleTogglePopup = (toggleOpen = true) => {
    const { isSelectedCoursesPopupOpen } = this.state;
    if (toggleOpen && !isSelectedCoursesPopupOpen) {
      this.setState({ isSelectedCoursesPopupOpen: true });
    } else if (!toggleOpen && isSelectedCoursesPopupOpen) {
      this.setState({ isSelectedCoursesPopupOpen: false });
    }
  }

  handleTabChange = async (_event, data) => {
    const { activeIndex } = data;
    const { courseManager } = this.props;
    courseManager.setActiveTabIndex(activeIndex);
    switch (activeIndex) {
      case 0:
        courseManager.setActiveTab('publisher_courses');
        await this.refreshAllCourseData();
        break;
      case 1:
        courseManager.setActiveTab('custom_courses');
        await this.refreshAllCourseData();
        break;
    }
  }

  handlePageChange = async (_event, pageInfo) => {
    const { courseManager } = this.props;
    let { activePage } = pageInfo;
    let { totalProductPages } = this.state;
    totalProductPages = typeof totalProductPages === 'number' ? totalProductPages : 0;
    activePage = totalProductPages > 1 && typeof activePage === 'number' ? Math.ceil(activePage) : 1;
    courseManager.setActiveProductCoursePage(activePage);
  }

  handleChangeSearch = (_event, obj) => {
    const searchText = obj ? obj.value : '';
    const { classroomId, courseManager } = this.props;
    courseManager.setSearchText(searchText);
    courseManager.setSearchTextTimeout(clearTimeout(courseManager.searchTextTimeout));
    courseManager.setSearchTextTimeout(setTimeout(() => {
      if (!searchText || searchText.length >= 3) {
        const page = undefined, pageSize = PAGE_SIZE;
        courseManager.fetchUserCourseList(
          classroomId, page, pageSize, searchText
        ).then(() => {});
      }
    }, 1000));
    this.setState({ deletedCourseName: null, serverErrorMsg: null });
  }

  handleToggleCheckmark = (course) => {
    const { courseManager } = this.props;
    courseManager.setProductCourseSelected(course.id);
    this.setState({ deletedCourseName: null, serverErrorMsg: null });
  }

  handleChangeProductDropdown = (_event, _data) => {
    const { courseManager } = this.props;
    courseManager.setActiveProductCoursePage(1);
    this.refreshAllCourseData();
  }

  handleClickCreateNewCustomCourse = () => {
    this.setState({ isInputModalOpen: true });
  }

  doesNotHaveDuplicateCourseName = async (name) => {
    const { courseManager } = this.props;
    const customCourses = courseManager.customCoursesList;
    const found = customCourses.find((course) => course.name === name);
    return !found;
  }

  handleSaveCreateCustomCourse = async (save, name, subtitle) => {
    if (save) {
      this.setState({ loading: true });
      const { classroomId, courseManager } = this.props;
      await CustomCourseResourceService.createCustomCourse(classroomId, name, subtitle, 'Assessments');
      this.setState({ isInputModalOpen: false });
      courseManager.setNewCustomCourseName(name);
      setTimeout(async () => {
        const closeShoppingExperienceAfterRefresh = false;
        await this.doRefresh(closeShoppingExperienceAfterRefresh);
        this.setState({ loading: false });
      }, 1000);
    } else {
      this.setState({ isInputModalOpen: false });
    }
  }

  getCoursesJsx = (selectedOnly = null) => {
    const { courseManager, productManager, t, userManager } = this.props;
    const { selectedUserProductId } = productManager;

    const { CourseCard, SCCheckbox } = this;
    let jsxArray = [];
    let productCourseList = [];
    let totalProductPages;
    if (selectedOnly) {
      productCourseList = courseManager.selectedNotAttachedProductCourseArray;
    } else if (!selectedOnly) {
      productCourseList = ProductService.getPagedSelectedProductAvailableCourses();
      totalProductPages = ProductService.getTotalProductPages();
      // eslint-disable-next-line react/destructuring-assignment
      if (totalProductPages !== this.state.totalProductPages) {
        this.setState({ totalProductPages });
      }
    }
    jsxArray = jsxArray.concat(productCourseList.filter((course) => {
      if (!selectedUserProductId || selectedUserProductId === 'allProducts' || courseManager.activeTab === 'custom_courses') {
        return true;
      } else if (selectedOnly && courseManager.selectedNotAttachedProductCourses.has(course.id)) {
        return true;
      } else {
        const productIdStr = course.productsList;
        return productIdStr && productIdStr?.includes(selectedUserProductId);
      }
    }).map((course) => {
      if (!selectedOnly && courseManager.productCourses.has(course.id)) {
        course = { ...courseManager.productCourses.get(course.id) };
      }
      if (!selectedOnly || (course.selected && !course.attached)) {
        const { productCodesStr, productNamesStr } = ProductService.getCourseProductCodesAndNamesStrObj(course);
        const isChecked = courseManager.selectedNotAttachedProductCourses.has(course.id);
        return (
          <Form.Field key={`${course.id}_FF`} className='add-course-field'>
            <SCCheckbox
              key={course.id}
              checked={isChecked}
              disabled={course.attached}
              onChange={() => this.handleToggleCheckmark(course)} />
            <CourseCard
              authKey={userManager.authKey}
              course={course}
              description={productNamesStr}
              disabled={course.attached}
              handleCardClick={() => {
                if (!course.attached) {
                  this.handleToggleCheckmark(course);
                }
              }}
              handleCourseCardRemove={this.deleteCourseConfirm}
              handleReadOnlyPreview={this.handleReadOnlyPreview}
              hideTypicalCardNavButtons={true}
              imageUrl={course.imageUrl}
              isFromCourseShop={true}
              secondaryDescription={productCodesStr}
              showReadOnlyPreviewButton={!selectedOnly}
              showTrashIcon={true}
              {...this.props} />
          </Form.Field>
        );
      } else {
        return undefined;
      }
    }).filter((course) => course));
    const totalVisibleProductCourseCount = jsxArray.length;

    const allowShowSearchBox = this.allowShowSearchBox();

    let zeroStateMsg = null;
    if (!selectedOnly) {
      if (courseManager.activeTab === 'custom_courses' && courseManager.isCustomCourseMapEmpty) {
        zeroStateMsg = t('customCourseZeroStateMsg');
      } else if (!courseManager.searchText && courseManager.activeTab === 'publisher_courses' && !totalVisibleProductCourseCount) {
        if (selectedUserProductId && selectedUserProductId !== 'allProducts') {
          zeroStateMsg = t('courseZeroStateForSelectedProductMsg');
        } else if (!allowShowSearchBox) {
          zeroStateMsg = t('courseZeroStateMsg');
        }
      }
    }
    if (!selectedOnly && zeroStateMsg) {
      // eslint-disable-next-line react/destructuring-assignment
      return (!courseManager.coursesLoading && !this.state.loading) && (
        <div
          key='course-modal-zero-state'
          className='course-modal-zero-state'>
          <div className='course-modal-zero-state-text'>
            {zeroStateMsg}
          </div>
        </div>
      );
    } else {
      // get translation for t('selectCourseCardTopLabel') if applicable
      // note: t('selectCourseCardTopLabel') will be blank by default. if blank, we will not show a top 'Select' label
      // to show for a certain satellite, we can define `selectCourseCardTopLabel` in the satellite's custom translation file
      const selectCourseCardTopLabelTranslation = !selectedOnly ? t('selectCourseCardTopLabel') : '';
      return (
        <>
          {!!selectCourseCardTopLabelTranslation && (
            <div className='select-course-card-top-label'>
              {selectCourseCardTopLabelTranslation}
            </div>
          )}
          {/* render modal course cards */}
          {jsxArray}
        </>
      );
    }
  }

  /**
   * Refresh all course data (used by this modal) to its initial state.
   *
   * Note: `clearSelectedNotAttachedProductCourses()` is intentionally left out of this call.
   *
   * Instead, `clearSelectedNotAttachedProductCourses()` is called when `closeAddCourseToClassModal()` is executed.
   */
  refreshAllCourseData = async () => {
    const { classroomId, courseManager, history, navigationManager } = this.props;

    this.setState({ loading: true });
    courseManager.setCoursesLoading(true);
    courseManager.clearProductCourses();
    courseManager.setSearchText('');

    const page = 0;
    const pageSize = PAGE_SIZE;
    await courseManager.fetchUserCourseList(classroomId, page, pageSize);

    const activePage = 1;
    courseManager.setActiveProductCoursePage(activePage);

    const replace = true;
    navigationManager.navigateToClassroom(classroomId, history, replace);

    courseManager.setCoursesLoading(false);
    this.setState({ loading: false, deletedCourseName: null, serverErrorMsg: null });
  }

  deleteCourseConfirm = (course, linkedClassroomNames) => {
    const { dialogManager, t } = this.props;
    if (linkedClassroomNames && linkedClassroomNames.length > 0) {
      this.hasClassesWarning(linkedClassroomNames);
    } else {
      const title = t('confirmDeleteTitle', 'AddCourseToClassModal: delete course title message missing.');
      const message = t('confirmDelete', 'AddCourseToClassModal: hasClasses message missing.');
      dialogManager.setOpenDialog(DIALOG_NAMES.CONFIRM, {
        title,
        message,
        cancelButtonClass: 'keepButton',
        cancelButtonName: 'No, Cancel',
        confirmButtonClass: 'deleteButton',
        confirmButtonName: 'Yes, Continue',
        confirmHandler: () => this.deleteCourse(course)
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM));
    }
  }

  hasClassesWarning = (linkedClassroomNames) => {
    const { dialogManager, t } = this.props;
    const title = t('hasClassesTitle', 'AddCourseToClassModal: hasClassesTitle message missing.');
    let message = t('hasClasses', 'AddCourseToClassModal: hasClasses message missing.');
    let index = 0;
    for (const className of linkedClassroomNames) {
      message = `${message + ((index > 0) ? ',' : '')}"${className}"`;
      index++;
    }

    dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);

    dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
      title,
      message,
      closeButtonClass: 'deleteButton',
      closeButtonName: 'Ok'
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
  }

  deleteCourse = async (course) => {
    const { dialogManager, courseManager } = this.props;
    const response = await courseManager.deleteContentItem(course.id, true);
    let result = false;
    if (response && response.status === 'SUCCESS') {
      courseManager.deleteCustomCourse(course.id);
      result = true;
    }
    if (response && response.status === 'FAILURE') {
      result = false;
      if (response.statusCode === 'HAS_CLASSROOM_LINKS') {
        this.deleteCourseConfirm(course, response.linkedClassroomNames);
        return;
      }
    }

    if (!result) {
      const errorMessage = `Error deleting course: ${course.id} ${response.statusMessage}`;
      console.error(errorMessage);
      let tempMessage = errorMessage;
      tempMessage = tempMessage.length > 99 ? `${tempMessage.substring(0, 96)}...` : tempMessage;
      dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
      await this.refreshAllCourseData();
      this.setState({ deletedCourseName: null, serverErrorMsg: tempMessage });
    } else {
      let tempMessage = course.name;
      tempMessage = tempMessage.length > 99 ? `${tempMessage.substring(0, 96)}...` : tempMessage;
      tempMessage += ' Item Bank has been removed.';
      dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
      await this.refreshAllCourseData();
      this.setState({ deletedCourseName: tempMessage, serverErrorMsg: tempMessage });
    }
  }

  hideError = () => {
    this.setState({ deletedCourseName: null, serverErrorMsg: null });
  }

  allowShowSearchBox = () => {
    const { courseManager } = this.props;
    const isCustomCourseTab = courseManager.activeTab === 'custom_courses';
    return (
      // eslint-disable-next-line react/destructuring-assignment
      !courseManager.coursesLoading && !this.state.loading
      && (!isCustomCourseTab || !courseManager.isCustomCourseMapEmpty)
      && (!isCustomCourseTab && !!courseManager.truePublisherCoursesCount)
    );
  }

  render() {
    const { ProductDropdown, SatCoreLoader } = this;
    const { courseManager, displayAddCourseToClassModal, t } = this.props;
    const {
      isInputModalOpen,
      isSelectedCoursesPopupOpen,
      totalProductPages
    } = this.state;
    const { activeProductCoursePage, activeTab, selectedNotAttachedProductCourseIds } = courseManager;
    const selectedCount = selectedNotAttachedProductCourseIds.length ? selectedNotAttachedProductCourseIds.length : 0;
    const paginationJsx = (
      <Pagination
        activePage={activeProductCoursePage}
        onPageChange={this.handlePageChange}
        totalPages={totalProductPages} />
    );
    const searchboxJsx = (
      <Grid>
        <Grid.Column>
          <Input
            autoFocus={!!courseManager.searchText}
            icon='search'
            name='search'
            onChange={this.handleChangeSearch}
            placeholder={t('searchContentName')}
            type='text'
            value={courseManager.searchText} />
        </Grid.Column>
      </Grid>
    );
    const { length } = courseManager.productCourseList;

    const allowShowSearchBox = this.allowShowSearchBox();
    const bannerLabel = t('modalAddNewCustomCourse', 'Create New Custom Course');

    const tabPaneJsx = (
      <Tab.Pane className='tab-pane-publisher-courses'>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {courseManager.coursesLoading || this.state.loading
          ? <SatCoreLoader /> : (
            <Modal.Content scrolling>
              <Container className='class-course-container' fluid>
                <Container className='class-course-list-container' fluid>
                  <div className='course-shop-header'>
                    <div className='course-shop-header-left'>
                      {activeTab === 'publisher_courses' && (
                        <ProductDropdown {...this.props}
                          onChange={this.handleChangeProductDropdown} />
                      )}
                      <div className='btn-create-custom-course-wrapper'>
                        {activeTab === 'custom_courses' && (
                          <Button
                            className='ui primary button create-custom-course-button'
                            disabled={isSelectedCoursesPopupOpen}
                            onClick={() => this.handleClickCreateNewCustomCourse()}>
                            {t('addNewCustomCourse', '+ Create New Custom Course')}
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className='course-shop-header-mid'>
                      {/* placeholder */}
                    </div>
                    <div className='course-shop-header-right'>
                      <div className='course-shop-header-pagination'>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {length ?
                          (totalProductPages > 1 && paginationJsx)
                          : (allowShowSearchBox && courseManager.searchText ? (
                            <div className='paginator-zero-state'>
                              {t('noResultsZeroStateMsg', 'No results found.')}
                            </div>
                          ) : '')}
                      </div>
                      {allowShowSearchBox && (
                        <div className='course-shop-header-searchbox'>
                          {searchboxJsx}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='error-message-wrapper'>
                    <Message
                      className='custom-assessment-modal-error-msg'
                      // eslint-disable-next-line react/destructuring-assignment
                      content={`${this.state.serverErrorMsg}`}
                      error
                      // eslint-disable-next-line react/destructuring-assignment
                      hidden={this.state.serverErrorMsg === null}
                      onDismiss={this.hideError} />
                  </div>
                  {this.getCoursesJsx()}
                  <div className='course-shop-footer'>
                    <div className='course-shop-footer-left'>
                      {/* placeholder */}
                    </div>
                    <div className='course-shop-footer-mid'>
                      {/* placeholder */}
                    </div>
                    <div className='course-shop-footer-right'>
                      {length >= 5 && totalProductPages > 1 && (
                        <div className='modal-content-footer-pagination'>
                          {paginationJsx}
                        </div>
                      )}
                    </div>
                  </div>
                </Container>
              </Container>
              {isInputModalOpen && (
              <InputModal
                bannerLabel={bannerLabel}
                cancelLabel='Cancel'
                isOpen={isInputModalOpen}
                isValidName={this.doesNotHaveDuplicateCourseName}
                name=''
                onSave={(save, name, subtitle) => this.handleSaveCreateCustomCourse(save, name, subtitle)}
                saveLabel='Create'
                subtitle=''
                {...this.props} />
              )}
            </Modal.Content>
          )}
      </Tab.Pane>
    );
    const tabPanes = [{
      menuItem: t('publisherCoursesTabName', 'Publisher Courses'),
      render: () => tabPaneJsx
    }];
    if (courseManager.allowCustomCourses) {
      tabPanes.push({ menuItem: t('customCoursesTabName', 'My Custom Courses'), render: () => tabPaneJsx });
    }
    let activeIndex;
    switch (activeTab) {
      case 'publisher_courses':
        activeIndex = 0;
        break;
      case 'custom_courses':
        activeIndex = 1;
        break;
    }
    return (
      <>
        <Modal
          className='AddCourseToClassModal'
          closeOnDimmerClick={false}
          closeOnEscape={false}
          onClose={this.closeAddCourseToClassModal}
          open={displayAddCourseToClassModal}
          size='fullscreen'
          style={{ top: '-14px', overflow: 'hidden' }}>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {(courseManager.coursesLoading || this.state.loading) &&
            !courseManager.productCourseList.length ? (
              <Modal.Content>
                <SatCoreLoader />
              </Modal.Content>
            ) : (
              <>
                <Modal.Header className='modal-header'>
                  <Header.Content className='modal-header-bar'>
                    <span className='modal-header-label'>
                      {t('selectCourses', 'Select Courses')}
                    </span>
                    <div className='modal-header-buttons'>
                      <Popup
                        className={`selected-courses-popup ${selectedCount ? 'has-selected-courses' : 'has-no-selected-courses'}`}
                        content={selectedCount ? (
                          <>
                            <div className='selected-courses-popup-title'>
                              {t('selectedCourses', 'Selected Courses')}
                            </div>
                            <Container
                              className='class-course-container'
                              fluid>
                              <Container className='class-course-list-container' fluid>
                                {this.getCoursesJsx('selectedOnly')}
                              </Container>
                            </Container>
                          </>
                        ) : t('noNewSelectedCourses', 'No new selected courses yet.')}
                        hoverable
                        offset={[0, -295]}
                        on='hover'
                        onClose={() => { this.handleTogglePopup(false); }}
                        onOpen={() => { this.handleTogglePopup(true); }}
                        position='left center'
                        trigger={(
                          <div className={`selected-count-wrapper ${selectedCount ? 'has-selected-count' : 'has-no-selected-count'}`}>
                            <Label circular>
                              {selectedCount}
                            </Label>
                            <span className='selected-text-label'>
                              {t('selectedCountLabel')}
                            </span>
                          </div>
                        )} />

                      <Button
                        basic
                        className='cancelButton'
                        disabled={isSelectedCoursesPopupOpen}
                        onClick={this.closeAddCourseToClassModal}
                        primary>
                        {t('cancel')}
                      </Button>
                      <Button
                        className='ui primary button saveButton'
                        // eslint-disable-next-line react/destructuring-assignment
                        disabled={this.state.loading || isSelectedCoursesPopupOpen}
                        onClick={this.submitAddSelectedCoursesToClass}>
                        {t('save')}
                      </Button>
                    </div>
                  </Header.Content>
                </Modal.Header>
                <div className='nav-separator' />
                <Tab
                  activeIndex={activeIndex}
                  onTabChange={this.handleTabChange}
                  panes={tabPanes} />
              </>
            )}
        </Modal>
      </>
    );
  }
}

SatCoreRegister('AddCourseToClassModal', AddCourseToClassroom);
