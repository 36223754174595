import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button } from 'semantic-ui-react';
import '../../css/reports/ReportEnhancedUsageColorKeyCard.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { REPORT_USAGE_MODES } from '../../services/reports/ReportConstants';

const ReportEnhancedUsageColorKeyCard = observer((props) => {
  const { history, t } = props;
  const { reportUsageManager } = useContext(MobXProviderContext);

  const ColorKeyCard = SatCoreComponent('ColorKeyCard');

  const { BACKGROUND_COLORS_ONLY, MINUTES_AND_COLORS, MINUTES_ONLY } = REPORT_USAGE_MODES;

  const colorKeyDataArray = reportUsageManager.usageColorKeyDataArray.map((key) => {
    return {
      color: key.color,
      descriptionBody: t(key.descriptionBody),
      descriptionHeader: t(key.descriptionHeader),
      label: t(key.label)
    };
  });

  const renderUsageModeButton = (usageMode) => {
    return (
      <Button onClick={() => reportUsageManager.setUsageMode(usageMode)}
        primary={reportUsageManager.usageMode === usageMode}>
        {t(usageMode)}
      </Button>
    );
  };

  const unallowedPathnames = ['standards-classroom-summary'];
  const unallowedPathname = unallowedPathnames.find((pathname) => {
    return history.location.pathname.includes(pathname);
  });

  return (
    <div className='report-enhanced-usage-color-key-card-container'>
      <ColorKeyCard
        boldLabel={false}
        colorKeyDataArray={colorKeyDataArray}
        infoIconPopupText={t('usageReportKeyInfoDescription')}
        keyFlexPercent={50}
        width='430px' />
      {!unallowedPathname && (
        <div className='usage-mode-buttons-wrapper'>
          {renderUsageModeButton(MINUTES_AND_COLORS)}
          {renderUsageModeButton(MINUTES_ONLY)}
          {renderUsageModeButton(BACKGROUND_COLORS_ONLY)}
        </div>
      )}
    </div>
  );
});
export default ReportEnhancedUsageColorKeyCard;

SatCoreRegister('ReportEnhancedUsageColorKeyCard', ReportEnhancedUsageColorKeyCard);
