import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { debounce, isEqual } from 'lodash';

import {
  Container, Dropdown, Grid, Input, Segment, Tab
} from 'semantic-ui-react';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import CourseService from '../services/CourseService';

/**
 * AssessmentItemFilter aka ResourceItemFilter is utilized by AssessmentItemShopModal (aka ResourceItemShopModal)
 * and shared among two features: 'COURSE SEARCH' and 'CUSTOM COURSES (LEARNOSITY)'
 */
export default
@inject(
  'assessmentManager',
  'classroomManager',
  'contentManager',
  'courseManager',
  'curriculumMapManager',
  'productManager',
  'tagContentManager',
  'teacherProductViewManager',
  'userManager'
)
@observer
class AssessmentItemFilter extends Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    // props specifying what this filter is being used for
    assessmentItemsSearch: false,
    courseResourcesSearch: false,
  };

  courseOptions = [];
  courseMap = {};
  courseIds = [];

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.SCRadio = SatCoreComponent('SCRadio');
    this.saveState = debounce(this.saveState, 1000);
  }

  getInitialState = () => {
    const { tagContentManager } = this.props;
    const dynamicState = {
      activeIndex: 0,
      expanded: [],
      isUpdating: false,
      productCmap: '',
      searchOperator: 'and', // TODO GAL-602: this is now **always** 'and'
      searchStandards: [],
      searchTags: [],
      searchTagsMap: new Map(),
      searchTerms: '',
      standardFramework: ''
    };
    tagContentManager.categoriesWithTagsArray.map((pItem) => {
      dynamicState[pItem.id] = [];
    });
    return { ...dynamicState, ...this.loadState() };
  }

  componentDidMount = async () => {
    const {
      assessmentItemsSearch,
      assessmentManager,
      classroomManager,
      courseManager,
      courseResourcesSearch,
      curriculumMapManager,
      isCustomCourseResource,
      productManager,
      t
    } = this.props;

    if (productManager.FROM_TEACHER_PRODUCTS_NAV) {
      curriculumMapManager.clearAll();
    }

    if (courseResourcesSearch) {
      courseManager.clearCourseElementSearch();
      this.setState({ resourceName: '' });

      await this.handleClearCategorySearch();
      await this.handleClearStandardsSearch();

      this.courseOptions = [];
      const { currentClassroomId } = classroomManager;

      const courseList = courseManager.getCourseList(currentClassroomId);

      this.courseMap = {};
      for (const course of courseList) {
        this.courseMap[course.id] = course;
      }

      this.courseOptions = courseList
        .filter((course) => {
          if (course.id === 'EXTRA_ASSIGNMENTS' || !course.entityId) {
            return false;
          }
          return true;
        })
        .map((course) => {
          return ({
            text: course.name, value: course.id
          });
        });

      let initialCourseId;

      if (this.courseOptions.length > 1) {
        // more than 1 course available in dropdown
        this.courseOptions.unshift({ text: t('allCourses'), value: 'ALL_COURSES' });
        const urlParams = new URLSearchParams(window.location.search);
        initialCourseId = urlParams.get('courseId') || 'ALL_COURSES';
        courseManager.setCurrentCourseId(initialCourseId);
      } else if (this.courseOptions.length === 1) {
        // only 1 course available in dropdown
        initialCourseId = this.courseOptions[0].value;
        courseManager.setCurrentCourseId(initialCourseId);
      }

      if (initialCourseId === 'ALL_COURSES') {
        await this.initCourseSearchAllCourses();
      } else {
        await this.initCourseSearchSingleCourse({ courseContentItemId: initialCourseId });
      }
    } else if (assessmentItemsSearch) {
      const { searchOperator, searchTerms, searchTags, productCmap, searchStandards, standardFramework } = this.state;
      assessmentManager.setAssessmentItemsLoading(true);
      assessmentManager.clearAssessmentFilterStandardsMaps();
      assessmentManager.setOriginalAssessmentItems(new Map());
      this.fetchSearch(searchOperator, searchTerms, searchTags, searchStandards);
      await assessmentManager.fetchCmapList(isCustomCourseResource);
      productCmap !== '' && await assessmentManager.fetchFullCmap(productCmap);
      await assessmentManager.fetchAdoptedPublisherStandardFrameworks();
      standardFramework !== '' && await assessmentManager.fetchFullStandardDocument(standardFramework);
      assessmentManager.setAssessmentItemsLoading(false);
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    !isEqual(this.state, prevState) && this.saveState();
  }

  // eslint-disable-next-line react/sort-comp
  loadState() {
    const { userManager } = this.props;
    try {
      const data = JSON.parse(sessionStorage.getItem(`AssessmentItemFilter-${userManager.userId}`) || '{}');
      data.searchTagsMap = new Map(data.searchTagsMap);
      return data;
    } catch {
      return {};
    }
  }

  saveState() {
    const { userManager } = this.props;
    const data = { ...this.state, searchTagsMap: [...this.state.searchTagsMap] };
    sessionStorage.setItem(`AssessmentItemFilter-${userManager.userId}`, JSON.stringify(data));
  }

  handleClearCategorySearch = async () => {
    const { tagContentManager } = this.props;
    this.setState({ searchTerms: '' });
    this.setState({ searchTags: [], searchTagsMap: new Map() });
    tagContentManager.categoriesWithTagsArray.map((pItem) => {
      this.setState({ [pItem.id]: [] });
    });
  }

  getCourseResourceElements = async () => {
    await CourseService.searchCourseResourceElements();
  }

  handleChangeResourceNameDelayed = debounce(async (resourceName) => {
    const { courseManager } = this.props;

    courseManager.setResourceName(resourceName);
    await this.getCourseResourceElements();
  }, 1500); // delay 1.5 seconds after the user stops typing and then do the fetch

  handleChangeResourceName = (event) => {
    const resourceName = event.target.value;
    this.setState({ resourceName });
    this.handleChangeResourceNameDelayed(resourceName);
  };

  handleClearStandardsSearch = async () => {
    this.setState({ productCmap: '', standardFramework: '' });
    this.setState({ currentAlignmentTreeStandards: [], currentTOCTreeStandards: [], searchStandards: [] });
  }

  handleClickResetFilters = async () => {
    const {
      assessmentItemsSearch, assessmentManager, courseManager, courseResourcesSearch
    } = this.props;

    // clear
    this.handleClearCategorySearch();
    this.handleClearStandardsSearch();
    assessmentManager.clearCmapStandardsJson();
    assessmentManager.clearCmapStandardsMap();
    assessmentManager.clearAdoptedStandardsJson();
    assessmentManager.clearExpanded();

    // reinit values
    const searchOperator = 'and'; // TODO GAL-602: this is now **always** 'and'
    const searchTerms = '';
    const searchTags = [];
    const searchStandards = [];

    if (assessmentItemsSearch) {
      this.resetSearch(searchOperator, searchTerms, searchTags, searchStandards);
    } else if (courseResourcesSearch) {
      courseManager.resetCourseElementSearch();
      this.setState({ resourceName: '' });

      const courseContentItemId = this.courseOptions[0].value;
      courseManager.setCurrentCourseId(courseContentItemId);
      if (courseContentItemId === 'ALL_COURSES') {
        await this.initCourseSearchAllCourses();
      } else {
        await this.initCourseSearchSingleCourse({ courseContentItemId });
      }
    }
  }

  initCourseSearchAllCourses = async () => {
    const { classroomManager, courseManager } = this.props;

    const { currentClassroomId } = classroomManager;

    await this.fetchAndUpdateCurriculumMapsIfApplicable(currentClassroomId);

    const courseList = courseManager.getCourseList(currentClassroomId);
    this.courseIds = courseList.filter((course) => course.entityId).map((course) => course.entityId);
    courseManager.setCourseContentItemIds(courseList.filter((course) => course.entityId).map((course) => course.id));

    this.getCourseResourceElements();
  }

  initCourseSearchSingleCourse = async ({ courseContentItemId } = {}) => {
    const { courseManager, curriculumMapManager } = this.props;

    const course = this.courseMap[courseContentItemId];
    const courseId = course.entityId;

    await curriculumMapManager.fetchCourseResourceCurriculumMaps(courseId);
    this.updateCurriculumMaps();

    this.courseIds = [courseId];
    courseManager.setCourseContentItemIds([courseContentItemId]);

    this.getCourseResourceElements();
  }

  handleChangeSearch = async (_event, { value }) => {
    const { assessmentManager } = this.props;
    const { searchOperator, searchTags, searchStandards } = this.state;
    const searchTerms = value;
    assessmentManager.setSearchTextTimeout(clearTimeout(assessmentManager.searchTextTimeout));
    assessmentManager.setSearchTextTimeout(setTimeout(() => {
      if (!searchTerms || searchTerms.length >= 3) {
        this.resetSearch(searchOperator, searchTerms, searchTags, searchStandards);
      }
    }, 1000));
    this.setState({ searchTerms });
  }

  fetchAndUpdateCurriculumMapsIfApplicable = async (currentClassroomId) => {
    const { curriculumMapManager, productManager, teacherProductViewManager } = this.props;
    if (!productManager.FROM_TEACHER_PRODUCTS_NAV) {
      await curriculumMapManager.fetchClassroomCurriculumMaps(currentClassroomId);
      this.updateCurriculumMaps();
    } else {
      await curriculumMapManager.fetchCourseResourceCurriculumMaps(
        teacherProductViewManager.currentTeacherProductCourse?.contentItemEntityId
      );
      this.updateCurriculumMaps();
    }
  }

  updateCurriculumMaps = async () => {
    const { curriculumMapManager, courseManager, courseResourcesSearch } = this.props;
    this.setState({ searchStandards: [] });
    courseManager.setCurriculumMapElementIds([]);
    if (curriculumMapManager.curriculumMaps && curriculumMapManager.curriculumMaps.length > 0) {
      const curriculumMap = curriculumMapManager.curriculumMaps[0];
      this.setState({ productCmap: curriculumMap.id });
      await curriculumMapManager.fetchCurriculumMap(curriculumMap.id, courseResourcesSearch);
      setTimeout(() => {
        this.setState({ expanded: curriculumMapManager.expanded });
      }, 1000);
    }
  }

  handleChangeCourse = async (_event, data) => {
    const { classroomManager, courseManager, curriculumMapManager } = this.props;

    await this.handleClearStandardsSearch();

    const courseContentItemId = data.value;

    courseManager.setCurrentCourseId(courseContentItemId);
    if (courseContentItemId === 'ALL_COURSES') {
      const { currentClassroomId } = classroomManager;

      await this.fetchAndUpdateCurriculumMapsIfApplicable(currentClassroomId);

      const courseList = courseManager.getCourseList(currentClassroomId);
      this.courseIds = courseList.filter((course) => course.entityId).map((course) => course.entityId);
      courseManager.setCourseContentItemIds(courseList.filter((course) => course.entityId).map((course) => course.id));
    } else {
      const course = this.courseMap[courseContentItemId];
      const courseId = course.entityId;

      await curriculumMapManager.fetchCourseResourceCurriculumMaps(courseId);
      this.updateCurriculumMaps();

      this.courseIds = [courseId];
      courseManager.setCourseContentItemIds([courseContentItemId]);
    }

    await this.getCourseResourceElements();
  }

  handleChangeCategories = async (_event, { value }, catId) => {
    const { multiSelect, courseResourcesSearch, courseManager } = this.props;

    // if multiple search pills not supported for this search
    if (!multiSelect) {
      // ensure we only have up to 1 active 'search pill' per category
      const lastValueInArray = value[value.length - 1];
      value = lastValueInArray ? [lastValueInArray] : [];
    }

    const { assessmentManager } = this.props;
    const tempSearchTagsMap = this.state.searchTagsMap;
    if (catId !== '' && catId !== null && catId !== undefined && value.length < 1) {
      tempSearchTagsMap.delete(catId);
    } else {
      tempSearchTagsMap.set(catId, value);
    }
    const tempSearchTags = assessmentManager.getArrayFromMap(tempSearchTagsMap);

    const { searchOperator, searchStandards, searchTerms } = this.state;
    await this.resetSearch(searchOperator, searchTerms, tempSearchTags, searchStandards);

    // set the state after search
    this.setState({ searchTags: tempSearchTags });
    this.setState({ searchTagsMap: tempSearchTagsMap });
    const dynamicState = {};
    dynamicState[catId] = value;
    this.setState(dynamicState);

    if (courseResourcesSearch) {
      courseManager.setTagIds([].concat(...tempSearchTags));
      await this.getCourseResourceElements();
    }
  }

  // This function can be called for an assessement items search or couseResource search
  // If called for assessment items, it is either called from the TOC tab document tree or the
  // Alignments (standards) tab document tree.  We have to maintain two arrays, one for each of thsoe
  // separate trees to keep things in sync.
  handleChangeStandards = async (searchElementIds, isTOCSearch = false) => {
    const { assessmentItemsSearch, courseResourcesSearch, assessmentManager, courseManager } = this.props;
    const { currentAlignmentTreeStandards, currentTOCTreeStandards, searchOperator, searchTerms, searchTags } = this.state;
    let modifiedAlignmentTreeStandards = [];
    let modifiedTOCTreeStandards = [];
    if (isTOCSearch) {
      // since updating TOC search, just pass along the existing Alignment standards
      modifiedAlignmentTreeStandards = currentAlignmentTreeStandards || [];
      // modify TOC collection based on passed in selections.
      modifiedTOCTreeStandards = searchElementIds;
    } else {
      // since not updating TOC search, just pass along the existing TOC standards
      modifiedTOCTreeStandards = currentTOCTreeStandards || [];
      // modify Alignment collection based on passed in selections
      modifiedAlignmentTreeStandards = searchElementIds;
    }
    if (assessmentItemsSearch) {
      const searchStandards = [];
      // Convert the search curriculum element ids to a standards list
      if (assessmentManager.stdFrwkStandardsMap) {
        modifiedTOCTreeStandards.forEach((elementId) => {
          // Get the standard for the stdFrwkElement
          const stdFrwkElement = assessmentManager.stdFrwkStandardsMap.get(elementId);
          if (stdFrwkElement) {
            searchStandards.push(stdFrwkElement.standardId);
          }
        });
      }
      if (assessmentManager.cmapStandardsMap) {
        modifiedAlignmentTreeStandards.forEach((elementId) => {
          // Get the standard for the cmapElement
          const cmapElement = assessmentManager.cmapStandardsMap.get(elementId);
          if (cmapElement) {
            searchStandards.push(cmapElement.standardId);
          }
        });
      }
      await this.resetSearch(searchOperator, searchTerms, searchTags, searchStandards);
      this.setState({
        currentAlignmentTreeStandards: modifiedAlignmentTreeStandards,
        currentTOCTreeStandards: modifiedTOCTreeStandards,
        searchStandards
      });
    } else if (courseResourcesSearch) {
      courseManager.setCurriculumMapElementIds(searchElementIds);
      this.setState({
        currentAlignmentTreeStandards: modifiedAlignmentTreeStandards,
        currentTOCTreeStandards: modifiedTOCTreeStandards,
        searchStandards: searchElementIds
      });
      await this.getCourseResourceElements();
    }
  }

  resetSearch = async (searchOperator, searchTerms, searchTags, searchStandards) => {
    const { assessmentManager } = this.props;
    const filteredAssessmentSettings = {
      searchOperator, searchStandards, searchTags, searchTerms
    };
    await this.fetchSearch(searchOperator, searchTerms, searchTags, searchStandards);
    assessmentManager.setFilteredAssessmentSettings(filteredAssessmentSettings);
  }

  fetchSearch = async (searchOperator, searchTerms, searchTags, searchStandards) => {
    this.setState({ isUpdating: true });
    await this.props.handleFetchAssessmentItems(searchOperator, searchTerms, searchTags, searchStandards);
    this.setState({ isUpdating: false });
  }

  getCategoryItems = () => {
    const { tagContentManager, multiSelect } = this.props;
    const categories = [];
    tagContentManager.categoriesWithTagsArray.map((pItem) => {
      let value = this.state[pItem.id]; // eslint-disable-line react/destructuring-assignment
      if (!value || !Array.isArray(value) || !value.length) {
        // value is empty; ensure it is an empty array
        value = [];
      } else if (!multiSelect && value && value.length > 1) {
        // ensure we only have up to 1 active 'search pill' per category
        const lastValueInArray = value[value.length - 1];
        value = lastValueInArray ? [lastValueInArray] : [];
      }

      const customName = tagContentManager.getCustomCategoryNameClass(pItem.name);
      const thisClassName = `filter-dropdown-section ${customName}`;
      categories.push(
        <Container key={`${pItem.id}_a`}>
          <div className={thisClassName}>
            <div className='filter-label'>{pItem.name}</div>
            <Dropdown
              key={`${pItem.id}_dd`}
              className='assessment-item-filter-dropdown'
              clearable={false}
              closeOnChange
              disabled={false}
              fluid
              multiple // we set this to true for the 'search pill' design effect, but we will only allow one pill per category
              onChange={(event, object) => this.handleChangeCategories(event, object, pItem.id)}
              options={this.getTagItemsAsOptions(pItem.tags)}
              placeholder={`Select ${pItem.name || ''}`}
              search
              selection
              value={value} />
          </div>
        </Container>
      );
    });
    return categories;
  }

  getTagItemsAsOptions = (tagsObj) => {
    const options = tagsObj.map((tag, index) => ({
      key: tag.id + index,
      text: tag.name,
      value: tag.id
    }));
    return options;
  }

  handleChangeProductCmap = async (_event, { value }) => {
    const { assessmentItemsSearch, courseResourcesSearch, assessmentManager, curriculumMapManager } = this.props;

    if (assessmentItemsSearch) {
      if (value.length > 0) {
        const id = value;
        await assessmentManager.fetchFullCmap(id);
      } else {
        assessmentManager.clearCmapStandardsJson();
        assessmentManager.clearCmapStandardsMap();
      }
      this.setState({ productCmap: value });
      setTimeout(() => {
        this.setState({ expanded: assessmentManager.expanded });
      }, 1000);
    } else if (courseResourcesSearch) {
      await curriculumMapManager.clearExpanded();
      const id = value;
      await curriculumMapManager.fetchCurriculumMap(id, courseResourcesSearch);
      this.setState({ productCmap: value });
      setTimeout(() => {
        this.setState({ expanded: curriculumMapManager.expanded });
      }, 1000);
    }
  }

  handleChangeStandardsFramework = async (_event, { value }) => {
    const { assessmentItemsSearch, assessmentManager } = this.props;

    if (assessmentItemsSearch) {
      if (value.length > 0) {
        const id = value;
        await assessmentManager.fetchFullStandardDocument(id);
      } else {
        assessmentManager.clearAdoptedStandardsJson();
        assessmentManager.clearStdFrwkStandardsMap();
      }
      this.setState({ standardFramework: value });
      setTimeout(() => {
        this.setState({ expanded: assessmentManager.expanded });
      }, 1000);
    }
  }

  // State variable currentTOCTreeStandards holds the currently selected standards for this tree only
  renderTOCSearch() {
    const { assessmentItemsSearch } = this.props;
    const { currentTOCTreeStandards, standardFramework, expanded } = this.state;
    const { assessmentManager } = this.props;
    let options = [];
    const isClearable = true;
    if (assessmentItemsSearch) {
      options = assessmentManager.adoptedStandardFrameworksArray.map((standardFramework, index) => ({
        key: standardFramework.id + index,
        text: standardFramework.name,
        value: standardFramework.sourceId
      }));
    }

    return (
      <div className='tab-container'>
        <Container>
          {(assessmentManager.adoptedStandardFrameworksArray) ? (
            <Dropdown
              clearable={isClearable}
              fluid
              onChange={this.handleChangeStandardsFramework}
              options={options}
              placeholder='Standard Framework'
              selection
              value={standardFramework} />
          ) : null}
        </Container>
        <div className='checkbox-tree-container'>
          <CheckboxTree
            checked={currentTOCTreeStandards}
            expanded={expanded}
            icons={{
              check: <span className='rct-icon rct-icon-check' />,
              uncheck: <span className='rct-icon rct-icon-uncheck' />,
              halfCheck: <span className='rct-icon rct-icon-half-check' />,
              expandClose: <span className='icon-expand-close' />,
              expandOpen: <span className='icon-expand-open' />,
              expandAll: <span className='rct-icon rct-icon-expand-all' />,
              collapseAll: <span className='rct-icon rct-icon-collapse-all' />,
              parentClose: <span className='rct-icon rct-icon-parent-close' />,
              parentOpen: <span className='rct-icon rct-icon-parent-open' />,
              leaf: <span className='rct-icon rct-icon-leaf' />
            }}
            nativeCheckboxes={true}
            noCascade={true}
            nodes={assessmentManager.adoptedStandardsJson}
            onCheck={(checked) => this.handleChangeStandards(checked, true)}
            onExpand={(expanded) => this.setState({ expanded })} />
        </div>
      </div>
    );
  }

  renderTagsSearch() {
    const { assessmentItemsSearch, courseResourcesSearch, courseManager, t } = this.props;
    const { resourceName, searchTerms } = this.state;

    const loading = courseManager.isLoading || courseManager.isTreeLoading;

    return (
      <div className='tab-container'>
        {assessmentItemsSearch && (
          <Grid>
            <Grid.Column>
              <Input
                icon='search'
                name='searchTerms'
                onChange={this.handleChangeSearch}
                placeholder='Search question stem'
                type='text'
                value={searchTerms || ''} />
            </Grid.Column>
          </Grid>
        )}
        {courseResourcesSearch && (
          <>
            <Grid>
              <Grid.Column>
                <Input
                  icon='search'
                  name='resourceName'
                  onChange={this.handleChangeResourceName}
                  placeholder='Search'
                  type='text'
                  value={resourceName || ''} />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column>
                <Container>
                  <div className='filter-dropdown-section-z'>
                    <div className='filter-label'>{t('course')}</div>
                    <Dropdown
                      disabled={loading}
                      fluid
                      loading={loading}
                      onChange={this.handleChangeCourse}
                      options={this.courseOptions}
                      selection
                      value={courseManager.currentCourseId} />
                  </div>
                </Container>
              </Grid.Column>
            </Grid>
          </>
        )}
        <Grid>
          <Grid.Column>
            {this.getCategoryItems()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  // State variable currentAlignmentTreeStandards holds the currently selected standards for this tree only
  renderStandardsSearch() {
    const { assessmentItemsSearch, courseResourcesSearch, curriculumMapManager } = this.props;
    const { currentAlignmentTreeStandards, productCmap, expanded } = this.state;
    const { assessmentManager } = this.props;
    let options = [];
    let isClearable = true;
    if (assessmentItemsSearch) {
      options = assessmentManager.productCmapArray.map((pcm, index) => ({
        key: pcm.id + index,
        text: pcm.friendlyName || pcm.name,
        value: pcm.id
      }));
    } else if (courseResourcesSearch) {
      const { curriculumMaps } = curriculumMapManager;
      options = curriculumMaps.map((cmap) => {
        const name = cmap.friendlyName || cmap.name;
        return ({
          text: name, value: cmap.id
        });
      });

      isClearable = false;
    }

    return (
      <div className='tab-container'>
        <Container>
          {(assessmentManager.productCmapArray) ? (
            <Dropdown
              clearable={isClearable}
              fluid
              onChange={this.handleChangeProductCmap}
              options={options}
              placeholder='CMAP'
              selection
              value={productCmap} />
          ) : null}
        </Container>
        <div className='checkbox-tree-container'>
          <CheckboxTree
            checked={currentAlignmentTreeStandards}
            expanded={expanded}
            icons={{
              check: <span className='rct-icon rct-icon-check' />,
              uncheck: <span className='rct-icon rct-icon-uncheck' />,
              halfCheck: <span className='rct-icon rct-icon-half-check' />,
              expandClose: <span className='icon-expand-close' />,
              expandOpen: <span className='icon-expand-open' />,
              expandAll: <span className='rct-icon rct-icon-expand-all' />,
              collapseAll: <span className='rct-icon rct-icon-collapse-all' />,
              parentClose: <span className='rct-icon rct-icon-parent-close' />,
              parentOpen: <span className='rct-icon rct-icon-parent-open' />,
              leaf: <span className='rct-icon rct-icon-leaf' />
            }}
            nativeCheckboxes={true}
            noCascade={true}
            nodes={assessmentItemsSearch ? assessmentManager.cmapStandardsJson : curriculumMapManager.curriculumMapJson}
            onCheck={(checked) => this.handleChangeStandards(checked)}
            onExpand={(expanded) => this.setState({ expanded })} />
        </div>
      </div>
    );
  }

  render() {
    const { assessmentItemsSearch } = this.props;
    const {
      assessmentManager,
      // Keep to cause re-render tab pane on change
      assessmentManager: { productCmapArray, cmapStandardsJson }
    } = this.props;

    if (!assessmentManager.assessmentItemsLoading) {
      const { activeIndex } = this.state;
      const panes = [
        { menuItem: 'Tags', render: () => <Tab.Pane>{this.renderTagsSearch()}</Tab.Pane> },
        { menuItem: 'Alignments', render: () => <Tab.Pane>{this.renderStandardsSearch()}</Tab.Pane> }
      ];

      if (assessmentItemsSearch) {
        panes.splice(0, 0, { menuItem: 'TOC', render: () => <Tab.Pane>{this.renderTOCSearch()}</Tab.Pane> });
      }

      const { isUpdating } = this.state;

      return (
        <div style={isUpdating ? { cursor: 'wait' } : {}}>

          <Segment
            className='filter'
            style={isUpdating ? { pointerEvents: 'none' } : {}}>
            <div>
              <div className='blue clickable reset'
                onClick={() => this.handleClickResetFilters()}>
                Reset Filters
              </div>
            </div>
            <Tab
              activeIndex={activeIndex}
              onTabChange={(_event, { activeIndex }) => this.setState({ activeIndex })}
              panes={panes} />
          </Segment>
        </div>
      );
    }
    return null;
  }
}

SatCoreRegister('AssessmentItemFilter', AssessmentItemFilter);
