import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Container, Header, Loader } from 'semantic-ui-react';

import classNames from 'classnames';

import '../css/Dashboard.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { ASSIGNMENT_MENU_STATUS, ASSIGNMENT_STATUS } from '../managers/AssignmentManager';
import { CONTENT_MODE, PLAYER_TYPES } from '../managers/ContentManager';
import { DIALOG_NAMES } from '../managers/DialogManager';
import Trophy from '../components/Trophy';
import { VIEW_SELECTION } from '../managers/NavigationManager';

import AssignmentService from '../services/AssignmentService';
import ImageService from '../services/ImageService';
import ResourcePacingService from '../services/ResourcePacingService';
import StudentHelperService from '../services/StudentHelperService';

import BookImageCard from '../components/BookImageCard';
import CarouselContainer from '../components/CarouselContainer';

import { dateFormat, getSessionStorageItem, removeSessionStorageItem } from '../utils';

const DefaultProgressWidget = ({ trophyCounts, progressWidget, showProgress, t }) => (
  <div className='dashboard-widget student-dashboard-widget-progress orange progress'>
    <div className='w-title'>{t('myProgress')}</div>
    <div className='w-content'>
      <div className='trophy-content'>
        <Trophy count={trophyCounts.gold} type='gold' />
        <div className='trophy-spacer' />
        <Trophy count={trophyCounts.silver} type='silver' />
      </div>
      <div className='actions'>
        <Button disabled={!progressWidget} onClick={showProgress} primary type='button'>
          {t('open')}
        </Button>
      </div>
    </div>
  </div>
);

export default @inject(
  'accessManager',
  'appManager',
  'assignmentManager',
  'classroomManager',
  'contentManager',
  'courseManager',
  'dialogManager',
  'dynamicSatelliteManager',
  'navigationManager',
  'studentContentCardManager',
  'studentProgressManager',
  'tagContentManager',
  'userManager'
)
@observer
class StudentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignmentId: null,
      contentImageUrl: '',
      contentItemId: '',
      contentName: '',
      contentSubtitle: null,
      currentCourseButtonElement: null,
      disablePresent: false,
      docreaderViewerShowing: false,
      fileViewerShowing: false,
      hasMore: true,
      instruction: '',
      learnosityPlayerShowing: false,
      lessonPlayerShowing: false,
      learnosityScoringPlayerShowing: false,
      loadingStudentDashboard: true,
      resourceName: '',
    };

    this.ClassCard = SatCoreComponent('ClassCard');
    this.StudentAssignmentCard = SatCoreComponent('StudentAssignmentCard');
    this.FullscreenModal = SatCoreComponent('FullscreenModal');
    this.LearnosityPlayerModal = SatCoreComponent('LearnosityPlayerModal');
    this.LearnosityScoringModal = SatCoreComponent('LearnosityScoringModal');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');
    this.DocReaderModal = SatCoreComponent('DocReaderModal');
    this.NoAssignmentsMessage = SatCoreComponent('NoAssignmentsMessage');
  }

  async componentDidMount() {
    const { appManager, assignmentManager, dynamicSatelliteManager, navigationManager } = this.props;
    const { isDynamicSatellite } = dynamicSatelliteManager;

    const assignmentLinkId = getSessionStorageItem('c2c_assignmentLinkId');

    // ensure we are still on the StudentDashboard before loading its data
    if (!isDynamicSatellite || assignmentLinkId || window.location.pathname === '/') {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('view')) {
        navigationManager.setView(urlParams.get('view'));
      } else {
        navigationManager.setView(VIEW_SELECTION.DASHBOARD);
      }
      const status = [ASSIGNMENT_STATUS.LOCKED, ASSIGNMENT_STATUS.READY, ASSIGNMENT_STATUS.STARTED];
      assignmentManager.setAssignmentStatus(status);

      // if we have an assignmentLinkId, we need to attempt to launch a student assignment,
      // as this was set from an external launch link.
      if (assignmentLinkId) {
        removeSessionStorageItem('c2c_assignmentLinkId');
        this.launchStudentLinkAssignment(assignmentLinkId);
        this.setState({ loadingStudentDashboard: false });
      }

      if (!appManager.initializingApp) {
        // this.setState({ loadingStudentDashboard: true });
        await StudentHelperService.fetchStudentAssignments({ status });
        this.setState({ loadingStudentDashboard: false });
      } else {
        this.setState({ loadingStudentDashboard: false });
      }
    }
  }

  launchStudentLinkAssignment = async (assignmentLinkId) => {
    const { assignmentManager, userManager } = this.props;
    let errorMessage = null;
    if (!userManager.isStudent) {
      this.showTextMessage('error', 'You do not have access to this assignment');
      return;
    }
    const response = await assignmentManager.fetchStudentActivity(assignmentLinkId);
    if (response) {
      if (response.status !== 'SUCCESS') {
        errorMessage = response.statusMessage;
      } else {
        const assignment = response.data;
        const activityInstance = response.activityInstance;
        if (assignment && activityInstance) {
          let contentMode = null;
          const isOpen = ASSIGNMENT_STATUS.getOpenStatuses().includes(assignment.status);
          const isReview = assignment.studentReview && (assignment.status === ASSIGNMENT_STATUS.COMPLETED || (assignment.status === ASSIGNMENT_STATUS.CLOSED && assignment.submitted));
          if (isOpen || isReview) {
            contentMode = isOpen ? CONTENT_MODE.ASSESS : CONTENT_MODE.REVIEW;
            // setup params for presentation
            const params = {
              assignment: {...assignment, ...activityInstance},
              turnInLate: false,
              contentMode
            };
            // open the linked assignment
            this.handlePresent(params, contentMode, false);
          } else {
            errorMessage = 'You do not have access to this assignment';
          }
        } else {
          errorMessage = 'Assignment not found.';
        }
      }
    } else {
      errorMessage = 'Assignment not found.';
    }
    if (errorMessage) {
      this.showTextMessage('Error', errorMessage);
    }
  }

  showTextMessage = (title, message) => {
    const { dialogManager } = this.props;
    if (message) {
      dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
        title,
        message
      }, () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
    }
  }

  handlePresent = async (params) => {
    const {
      assignmentManager, contentManager, studentContentCardManager, tagContentManager, userManager
    } = this.props;

    const { assignment, contentMode = CONTENT_MODE.ASSESS, turnInLate } = params;
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.disablePresent) {
      return false;
    }
    this.setState({ disablePresent: true });

    if (contentMode === CONTENT_MODE.ASSESS) {
      const preStartContentItemId = assignment.contentItemId;
      if (ASSIGNMENT_STATUS.getCheckableStatuses().includes(assignment.status)) {
        const isReady = await assignmentManager.checkActivity(assignment.activityId);
        if (isReady) {
          const isOk = await assignmentManager.startActivity(assignment.id, assignment.activityId, turnInLate);
          if (isOk === false) {
            return false;
          }
        } else {
          this.setState({ disablePresent: false });
          return false;
        }
      }

      const postStartContentItemId = assignment.contentItemId;
      if (preStartContentItemId !== postStartContentItemId && tagContentManager.useContentTags) {
        // this has been versioned. make sure the new has an image.
        // console.log('*** A new version: ' + postStartContentItemId +' has been created from: '+ preStartContentItemId);
        tagContentManager.createNewCardContentTagFromOld(preStartContentItemId, postStartContentItemId);
      }
    }

    let option = null;
    if (contentMode === CONTENT_MODE.REVIEW) {
      option = await contentManager.getOptionsForStudentReview(
        assignment, window.location.origin, userManager.isTeacher, userManager.userId
      );
    } else {
      option = await contentManager.getOptionsForStudentAssessment(
        assignment, window.location.origin, userManager.isTeacher, userManager.userId
      );
    }

    const { playerType, viewUrl, isFlowpaper } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      this.setState({ disablePresent: false });

      if (studentContentCardManager.isLoading) {
        studentContentCardManager.setIsLoading(false);
        this.forceUpdate();
      }
      return;
    }

    contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    const contentImageUrl = ImageService.getImageUrl(assignment);

    const { dialogManager } = this.props;
    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        resourceName: (assignment.resourceWebTitle !== '' && assignment.resourceWebTitle != null) ?
          assignment.resourceWebTitle : assignment.name,
        contentItemId: assignment.contentItemId,
        assignmentId: assignment.id,
        contentItemType: assignment.contentItemEntityTypeId,
        contentMode,
        instruction: assignment.activityInstruction,
        closeModalCallback: this.hideModal
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
      this.setState({ disablePresent: false });
      return;
    }

    const {
      resourceWebTitle, resourceWebSubtitle
    } = AssignmentService.getAssignmentTitleAndDescription(assignment);

    this.setState({
      activityId: assignment.activityId,
      assignmentId: assignment.id,
      contentImageUrl,
      contentItemId: assignment.contentItemId,
      contentMode,
      contentName: resourceWebTitle,
      contentPreviewShowing: (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER),
      contentSubtitle: resourceWebSubtitle,
      contentUrl: viewUrl,
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      grade: assignment.grade,
      instruction: assignment.activityInstruction,
      isFlowpaper,
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      learnosityScoringPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_SCORING_PLAYER),
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      previewContentItemId: assignment.id,
      previewContentType: assignment.contentItemEntityTypeId,
      resourceName: assignment.contentItemName,
    });

    this.setState({ disablePresent: false });
  }

  hideIframeFromOuterClick = (event) => {
    if (event.data === 'hideIframe') {
      this.setState({ lessonPlayerShowing: false });
      this.hideModal();
      window.removeEventListener('message', this.hideIframeFromOuterClick);
    }
  }

  hideModal = async () => {
    this.setState({
      docreaderViewerShowing: false,
      fileViewerShowing: false,
      learnosityPlayerShowing: false,
      learnosityScoringPlayerShowing: false,
    });
    const status = [ASSIGNMENT_STATUS.LOCKED, ASSIGNMENT_STATUS.READY, ASSIGNMENT_STATUS.STARTED];
    await StudentHelperService.fetchStudentAssignments({ status });
  }

  renderAssignmentCards = () => {
    const { accessManager, assignmentManager, t } = this.props;
    const { policies } = accessManager;
    const { studentDashboard } = policies;
    const { assignmentCard } = studentDashboard;

    const { NoAssignmentsMessage } = this;

    const { StudentAssignmentCard } = this;

    const assignmentsJsx = (assignmentManager.assignmentArray || []).map((assignment) => {
      const visibilityMode = ResourcePacingService.visibilityMode(assignment);
      const contentImageUrl = ImageService.getImageUrl(assignment);
      return visibilityMode !== 'hidden_from_students' && (
        <div key={`AAC_${assignment.id}`} className='dash-card ui items'>
          <StudentAssignmentCard
            assignment={assignment}
            contentImageUrl={contentImageUrl}
            dashboard={true}
            disabled={!assignmentCard || assignment.licenseExpired}
            handlePresent={this.handlePresent}
            menuStatus={ASSIGNMENT_MENU_STATUS.CURRENT} />
        </div>
      );
    }).filter((assignmentJsx) => assignmentJsx);
    return assignmentsJsx?.length ? assignmentsJsx : (
      <NoAssignmentsMessage message={t('NoAssignmentsYet', 'No assignments yet')} />
    );
  };

  showBook = async (courseId) => {
    const { classroomManager, courseManager, history, navigationManager } = this.props;
    courseManager.setCurrentElementId('');
    courseManager.setCurrentCourseId(courseId);
    navigationManager.setView(VIEW_SELECTION.BOOK);
    await navigationManager.navigateToClassroomSkipSingleCourse(classroomManager.currentClassroomId,
      1, // always 1 for student so we skip the course selection page
      courseManager.currentCourseId,
      VIEW_SELECTION.BOOK,
      history,
      false);
  };

  showProgress = async () => {
    this.props.navigationManager.setView(VIEW_SELECTION.PROGRESS);
    await this.props.history.push(`/progress?view=${VIEW_SELECTION.PROGRESS}`);
  };

  renderLearnosityPlayer = () => {
    const { userManager, history } = this.props;
    const { LearnosityPlayerModal } = this;
    return (
      <div className='course-content'>
        <LearnosityPlayerModal
          assignmentId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentItemId={this.state.contentItemId}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentSubtitle={this.state.contentSubtitle}
          history={history}
          instruction={this.state.instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-player'
          resourceName={this.state.resourceName} />
      </div>
    );
  }

  renderFileViewer = () => {
    const { userManager } = this.props;
    const { FileViewerModal } = this;
    return (
      <div className='course-content'>
        <FileViewerModal
          activityId={this.state.activityId}
          assignmentId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentItemId={this.state.contentItemId}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentType={this.state.previewContentType}
          instruction={this.state.instruction}
          isFlowpaper={this.state.isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='file-viewer'
          resourceName={this.state.resourceName}
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderDocreaderViewer = () => {
    const { userManager, contentManager } = this.props;
    const { DocReaderModal } = this;
    if (this.props.renderDocreaderViewer !== undefined) {
      return this.props.renderDocreaderViewer();
    }

    return (
      <div className='course-content'>
        <DocReaderModal
          assignmentId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentType={this.state.previewContentType}
          instruction={this.state.instruction}
          isFlowpaper={this.state.isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='docreader-viewer'
          resourceName={this.state.resourceName}
          sessionId={contentManager.currentflowPaperSessionId}
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderLessonPlayer = () => {
    const { userManager } = this.props;
    const { FullscreenModal } = this;

    return (
      <div className='course-content'>
        <FullscreenModal
          closeIframeCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentName={this.state.contentName}
          instruction={this.state.instruction}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={this.state.contentUrl} />
      </div>
    );
  }

  getBookCardContent = () => {
    const {
      accessManager: { policies: { studentDashboard: { bookImageCard } } },
      assignmentManager, classroomManager, courseManager, history, navigationManager
    } = this.props;

    const { currentCourseButtonElement } = this.state;

    const { customDefaultAssignmentCardImg } = assignmentManager;

    const { currentClassroomId } = classroomManager;

    if (this.props.getBookCardContent !== undefined) { // eslint-disable-line react/destructuring-assignment
      return this.props.getBookCardContent(this.props, this); // eslint-disable-line react/destructuring-assignment
    }

    const getButtonAndSetTabIndex = () => {
      const activeButtonElement = document.querySelector('.slick-active .open-course-button');
      if (activeButtonElement) {
        activeButtonElement.tabIndex = 0;
        this.setState({ currentCourseButtonElement: activeButtonElement });
      }
    };

    const bookSettings = {
      afterChange: getButtonAndSetTabIndex,
      beforeChange: () => {
        if (currentCourseButtonElement) {
          currentCourseButtonElement.tabIndex = -1;
        }
      },
      dots: false,
      infinite: true,
      onInit: getButtonAndSetTabIndex,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 500
    };

    const courseList = courseManager.getCourseList(currentClassroomId);

    return (
      <div className={classNames('w-content w-content-book-card-container', {
        'allow-carousel-adaptive-height': navigationManager.allowCarouselAdaptiveHeight
      })}>
        <CarouselContainer {...bookSettings}>
          {courseList.map((course, index) => (
            <BookImageCard
              key={`csc_${course.id}`}
              course={course}
              customDefaultCardImg={customDefaultAssignmentCardImg}
              disabled={!bookImageCard}
              history={history}
              index={index}
              showBook={this.showBook} />
          )
          )}
        </CarouselContainer>
      </div>
    );
  }

  renderProgressWidget = () => {
    const { accessManager: { policies: { studentDashboard: { progressWidget } } }, classroomManager, studentProgressManager } = this.props;
    const { t } = this.props;
    const { trophyCounts, meterCounts } = studentProgressManager;
    studentProgressManager.setClassroom(classroomManager.currentClassroomId);

    const ProgressWidgetOverride = SatCoreComponent('ProgressWidget');
    const ProgressWidget = (ProgressWidgetOverride === Fragment) ? DefaultProgressWidget : ProgressWidgetOverride;

    return (
      <ProgressWidget
        meterCounts={meterCounts}
        progressWidget={progressWidget}
        showProgress={this.showProgress}
        t={t}
        trophyCounts={trophyCounts} />
    );
  }

  renderLearnosityScoringModal = () => {
    const { history, userManager } = this.props;
    const {
      assignmentId,
      contentImageUrl,
      contentItemDescription,
      contentItemId,
      contentName,
      grade,
      instruction,
      resourceName
    } = this.state;
    const { LearnosityScoringModal } = this;
    return (
      <div className='course-content'>
        <LearnosityScoringModal
          activityInstanceId={assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentItemId={contentItemId}
          contentSubTitle={contentItemDescription}
          contentTitle={contentName}
          grade={grade}
          history={history}
          instruction={instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-scoring'
          resourceName={resourceName}
          studentName={`${userManager.firstName} ${userManager.lastName}`} />
      </div>
    );
  }

  renderRest() {
    // TODO remove
    // const months = [
    //   'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    // const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const { appManager, classroomManager, t, userManager } = this.props;
    const { loadingStudentDashboard } = this.state;
    // TODO remove // const course = courseManager.getCurrentCourse(currentClassroomId);

    // const locale = InitService.getLocale();
    const date = new Date();

    return loadingStudentDashboard || appManager.initializingApp ? <Loader active /> : (
      <Container className='dashboard-backdrop' fluid>
        <Container className='dashboard-container'>
          <div className='headerContainer'>
            <div className='headerWrapper'>
              <Header as='h1' className='welcome-header theme-header-title'>
                {t('WelcomeLabel', 'Welcome')}&nbsp;
                {userManager.firstName}
                !
              </Header>
              <div
                className='welcome-date'>
                {dateFormat(date, 'fullDate', appManager.locale)}
                {/* TODO remove */}
                {/* {`${days[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`} */}
              </div>
            </div>
          </div>
          <Container className='widget-wrapper'>
            {(userManager.loaded && classroomManager.loaded/* && course !== null */) ? (
              <>
                <Container className='left-col'>
                  <div aria-label={`${t('BookLabel', 'My Content')} Carousel`}
                    className='dashboard-widget student-dashboard-widget-carousel red' tabIndex={0}>
                    <div className='w-title'>{t('BookLabel', 'Missing book label translation')}</div>
                    {this.getBookCardContent()}
                  </div>
                  {this.renderProgressWidget()}
                </Container>
                <Container className='right-col'>
                  <div className='dashboard-widget student-dashboard-widget-assignments green'>
                    <div className='w-title'>{t('AssignmentLabel', 'Missing assignment label translation')}</div>
                    <div className='w-content'>
                      <div className='ui items scroller'>
                        {this.renderAssignmentCards()}
                      </div>
                    </div>
                  </div>
                </Container>
              </>
            ) : null}
          </Container>
        </Container>
      </Container>
    );
  }

  render() {
    if (this.state.learnosityPlayerShowing) {
      return this.renderLearnosityPlayer();
    } else if (this.state.lessonPlayerShowing) {
      return (this.renderLessonPlayer());
    } else if (this.state.fileViewerShowing) {
      return (this.renderFileViewer());
    } else if (this.state.docreaderViewerShowing) {
      return (this.renderDocreaderViewer());
    } else if (this.state.learnosityScoringPlayerShowing) {
      return this.renderLearnosityScoringModal();
    }
    return (this.renderRest());
  }
}

SatCoreRegister('StudentDashboard', StudentDashboard);
