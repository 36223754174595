import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Container,
  Input, Message
} from 'semantic-ui-react';

import '../css/AdminAddTeacher.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { VIEW_SELECTION } from '../managers/NavigationManager';
import { ERROR_MESSAGES } from '../managers/UserManager';

export default @inject(
  'adminUsersManager',
  'classroomManager',
  'dialogManager',
  'filteredHeaderTableManager',
  'navigationManager',
  'userManager',
  'schoolManager'
)
@observer
class AdminAddTeacher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalUsername: '',
      originalFirstName: '',
      originalLastName: '',
      originalPassword: '',
      confirmPassword: null,
      saved: false,
      error: false,
      errorMessage: '',
      changesHappened: false,
      newPassword: '',
      newConfirmPassword: '',
      confirmPasswordInputType: 'password',
      newPasswordInputType: 'password'
    };

    this.FilteredHeaderTable = SatCoreComponent('FilteredHeaderTable');
    this.Avatar = SatCoreComponent('Avatar');
    this.BreadCrumbs = SatCoreComponent('BreadCrumbs');
    this.ShowPasswordButton = SatCoreComponent('ShowPasswordButton');
  }

  async componentDidMount() {
    const {
      navigationManager,
      adminUsersManager
    } = this.props;
    navigationManager.setView(VIEW_SELECTION.USERS);

    // clear any current user from the manager
    adminUsersManager.setUser({});

    await this.setBreadcrumbs();
  }

  setBreadcrumbs = async () => {
    const { navigationManager, userManager } = this.props;
    navigationManager.clearAllPaths();
    const { institutionName, urlParamStr } = await navigationManager.getAdminUrlParams();

    const schoolsBreadcrumbObj = {
      fromView: VIEW_SELECTION.DASHBOARD,
      path: { name: 'Schools' }
    };
    const schoolNameBreadcrumbObj = {
      fromView: VIEW_SELECTION.DASHBOARD,
      path: {},
      paramName: 'institutionName'
    };
    let routerUrl_users = `/users?view=${VIEW_SELECTION.USERS}`;
    if (urlParamStr) {
      routerUrl_users += `&${urlParamStr}`;
    }
    const usersBreadcrumbObj = {
      fromView: VIEW_SELECTION.USERS,
      path: { name: 'Users', routerUrl: routerUrl_users }
    };
    let routerUrl_adminProfile = `/adminProfile?view=${VIEW_SELECTION.USERS}`;
    if (urlParamStr) {
      routerUrl_adminProfile += `&${urlParamStr}`;
    }
    const userNameBreadcrumbObj = {
      path: {
        routerUrl: routerUrl_adminProfile
      },
      paramName: 'userName'
    };
    if (institutionName && userManager.isDistrictAdmin) {
      await navigationManager.setBreadcrumb(schoolsBreadcrumbObj);
      await navigationManager.setBreadcrumb(schoolNameBreadcrumbObj);
      await navigationManager.setBreadcrumb(usersBreadcrumbObj);
      await navigationManager.setBreadcrumb(userNameBreadcrumbObj);
    } else {
      await navigationManager.setBreadcrumb(usersBreadcrumbObj);
      await navigationManager.setBreadcrumb(userNameBreadcrumbObj);
    }
  }

  verifyChangesAndExit = async () => {
    await this.verifyChanges();
    if (!this.state.error) {
      setTimeout(() => {
        this.handleCancel();
      }, 1250);
    }
  }

  verifyChangesAndClear = async () => {
    const { adminUsersManager } = this.props;
    await this.verifyChanges();
    if (!this.state.error) {
      setTimeout(() => {
        // clear any current user from the manager
        adminUsersManager.setUser({});
        this.setState({ saved: false, error: false, errorMessage: '', confirmPassword: null });
      }, 1000);
    }
  }

  verifyChanges = async () => {
    const { adminUsersManager, userManager, schoolManager } = this.props;
    const school = schoolManager.getCurrentSchool();

    this.setState({ saved: false, error: false, errorMessage: '' });

    let changesHappened = false;

    if (!adminUsersManager.currentUser.firstName) {
      this.setState({
        saved: false,
        error: true,
        errorMessage: 'The First Name cannot be empty.'
      });
      return;
    }

    if (!adminUsersManager.currentUser.lastName) {
      this.setState({
        saved: false,
        error: true,
        errorMessage: 'The Last Name cannot be empty.'
      });
      return;
    }

    if (!adminUsersManager.currentUser.username) {
      this.setState({
        saved: false,
        error: true,
        errorMessage: 'The Username cannot be empty.'
      });
      return;
    }
    if (this.state.originalUsername !== adminUsersManager.currentUser.username) {
      const usernameExists = await userManager.validateUserEmail(school.publisherId, adminUsersManager.currentUser.username);
      let errorMessage = null;
      if (usernameExists) {
        errorMessage = ERROR_MESSAGES.USERNAME_EXISTS;
      }
      if (errorMessage !== null) {
        this.setState({ error: true, errorMessage });
        return false;
      }
      changesHappened = true;
    }
    if (this.state.originalFirstName !== adminUsersManager.currentUser.firstName) {
      changesHappened = true;
    }
    if (this.state.originalLastName !== adminUsersManager.currentUser.lastName) {
      changesHappened = true;
    }
    if (this.state.originalPassword !== adminUsersManager.currentUser.password) {
      changesHappened = true;
      if (adminUsersManager.currentUser.password.length < 8) {
        this.setState({ error: true, errorMessage: ERROR_MESSAGES.PASSWORD_LENGTH });
        return false;
      }
    }
    if (!this.state.confirmPassword) {
      this.setState({ error: true, errorMessage: ERROR_MESSAGES.PASSWORDS_DO_NOT_MATCH });
      return false;
    }

    let result = false;
    const errorMessage = 'Your profile failed to save. Please try again.';

    if (changesHappened) {
      const body = {
        accessCodeId: school.ssoTeacherAccessCodeId,
        institutionId: school.id,
        password: adminUsersManager.currentUser.password,
        firstName: adminUsersManager.currentUser.firstName,
        lastName: adminUsersManager.currentUser.lastName,
        username: adminUsersManager.currentUser.username,
        userType: 'teacher',
        requireEmail: false // not requiring the teacher user to activiate by email in this case
      };

      result = await userManager.registerUser(body, null);

      if (result) {
        this.setState({
          saved: true,
          error: false,
          changesHappened: false
        });
      } else {
        this.setState({
          saved: false,
          error: true,
          errorMessage
        });
      }
    }
  };

  handleChangeUsername = (_event, data) => {
    const { adminUsersManager } = this.props;
    const isValid = this.validateUsername(data.value);
    adminUsersManager.setUserName(data.value.toLowerCase());
    if (isValid) {
      this.setState({ changesHappened: true, error: false, errorMessage: '' });
    } else {
      this.setState({ changesHappened: false, error: true, errorMessage: ERROR_MESSAGES.VALID_EMAIL_FORMAT });
    }
  };

  handleChangeFirstName = (_event, data) => {
    const { adminUsersManager } = this.props;
    adminUsersManager.setFirstName(data.value);
    this.setState({ changesHappened: true });
  };

  handleChangeLastName = (_event, data) => {
    const { adminUsersManager } = this.props;
    adminUsersManager.setLastName(data.value);
    this.setState({ changesHappened: true });
  };

  // first password
  handleChangePassword = (_event, data) => {
    const { adminUsersManager } = this.props;
    if (this.state.confirmPassword) {
      this.setState({ confirmPassword: null });
    }
    adminUsersManager.setPassword(data.value);
  };

  // confirm password
  handleChangeConfirmPassword = (_event, data) => {
    const isValid = this.validatePassword(data.value);
    if (isValid) {
      this.setState({ changesHappened: true, error: false, errorMessage: '' });
    } else {
      this.setState({ changesHappened: false, error: true, errorMessage: ERROR_MESSAGES.PASSWORDS_DO_NOT_MATCH });
    }
    this.setState({ confirmPassword: data.value });
  };

  handleCancel = async () => {
    const { history, navigationManager, schoolManager } = this.props;
    const resetState = {
      originalUsername: '',
      originalFirstName: '',
      originalLastName: '',
      originalPassword: '',
      saved: false,
      error: false,
      errorMessage: '',
      changesHappened: false,
      newPassword: ''
    };
    this.setState(resetState);
    const school = schoolManager.getCurrentSchool();
    navigationManager.setView(VIEW_SELECTION.USERS);
    const routerUrl = `/users?view=${VIEW_SELECTION.USERS}&institutionId=${school.id}&districtId=${school.parentId}&institutionName=${school.name}`;
    await history.push(routerUrl);
  }

  validatePassword = (password) => {
    const { adminUsersManager } = this.props;
    let result = false;
    if (!/^\s*\S.*$/.test(password)) {
      result = true;
    }
    if (adminUsersManager.currentUser.password === password) {
      // make sure passwords match
      result = true;
    }
    return result;
  }

  validateUsername = (username) => {
    const { adminUsersManager } = this.props;
    let result = false;
    if (adminUsersManager.validateUserEmail(username)) {
      result = true;
    } else {
      result = false;
    }
    return result;
  }

  gotoUserClassRoster = async (classroom) => {
    const { history, navigationManager } = this.props;
    let routerUrl = `/roster?classroomId=${classroom.id}`;
    const paramsToExcludeFromStr = 'classroomId';
    const { urlParamStr } = await navigationManager.getAdminUrlParams(
      paramsToExcludeFromStr
    );
    if (urlParamStr) {
      routerUrl += `&${urlParamStr}`;
    }
    history.push(routerUrl);
  }

  renderRoleNames = (roleIds) => {
    const { adminUsersManager } = this.props;
    const rolesArrayContent = [];
    if (roleIds) {
      const rolesIdsArray = roleIds.split(',');
      if (rolesIdsArray.length > 0) {
        rolesIdsArray.map((roleId, index) => {
          const role = adminUsersManager.roles.find((role) => role.id === roleId);
          if (role) {
            rolesArrayContent.push(<Input key={index} disabled={true} type='text' value={role.name || ''} />);
          }
        });
      }
    }
    if (rolesArrayContent.length < 1) {
      rolesArrayContent.push(<Input key={0} disabled={true} type='text' value='No visible roles set for user.' />);
    }
    return rolesArrayContent;
  }

  handleShowPasswordPressed = (name, isPasswordInput) => {
    const inputType = isPasswordInput ? 'password' : 'text';
    this.setState({ [name]: inputType });
  }

  render() {
    const { adminUsersManager, history, userManager, t } = this.props;
    const { ShowPasswordButton } = this;
    const { isSsoUser } = userManager;
    const { BreadCrumbs } = this;

    return (
      <div className='admin-add-teacher-page'>
        <div className='header-nav'>
          <Container className='bread-crumb-wrapper top' fluid>
            <BreadCrumbs history={history} />
          </Container>
        </div>
        <div className='admin-add-teacher-content'>
          <div className='add-teacher-container'>
            <div className='add-teacher-header' />
            <div className='add-teacher-fields'>
              <div className='admin-user-add-teacher-header'>
                <div className='header-title-text'>
                  {t('addTeacherHeaderText', 'Add New Teacher')}
                </div>
                <div className='modal-title-button'>
                  <Button basic className='cancelButton' onClick={() => this.handleCancel()} primary>Cancel</Button>
                  {!isSsoUser && (
                    <>
                      <Button className='save' disabled={!this.state.changesHappened} onClick={() => this.verifyChangesAndExit()} primary>{t('saveButton', 'Save')}</Button>
                      <Button className='saveAndAdd' disabled={!this.state.changesHappened} onClick={() => this.verifyChangesAndClear()} primary>{t('save&AddButton', 'Save & Add')}</Button>
                    </>
                  )}
                </div>
              </div>
              <div className='add-teacher-fields-row'>
                <div className='add-teacher-field'>
                  <div className='add-teacher-field-txt'>{t('firstName', 'First Name')}</div>
                  <Input
                    disabled={isSsoUser}
                    name='firstName'
                    onChange={this.handleChangeFirstName}
                    placeholder='First Name'
                    type='text'
                    value={adminUsersManager.currentUser.firstName || ''} />
                </div>
                <div className='add-teacher-field'>
                  <div className='add-teacher-field-txt'>{t('lastName', 'Last Name')}</div>
                  <Input
                    disabled={isSsoUser}
                    name='lastName'
                    onChange={this.handleChangeLastName}
                    placeholder='Last Name'
                    type='text'
                    value={adminUsersManager.currentUser.lastName || ''} />
                </div>
              </div>
              <div className='add-teacher-fields-row'>
                <div className='add-teacher-field fullwidth'>
                  <div className='add-teacher-field-txt'>{t('username', 'Username/email)')}</div>
                  <Input
                    disabled={isSsoUser}
                    onChange={this.handleChangeUsername}
                    placeholder='Username/email'
                    type='text'
                    value={adminUsersManager.currentUser.username || ''} />
                </div>
              </div>
              <div className='add-teacher-fields-row'>
                <div className='add-teacher-field fullwidth'>
                  <div className='add-teacher-field-txt'>{t('enterPassword', 'Enter Password')}</div>
                  <Input
                    disabled={isSsoUser}
                    icon={(
                      <ShowPasswordButton
                        isPassword={(this.state.newPasswordInputType === 'password')}
                        name='newPasswordInputType'
                        onFlip={this.handleShowPasswordPressed} />
                    )}
                    name='newPassword'
                    onChange={this.handleChangePassword}
                    placeholder='password'
                    type={this.state.newPasswordInputType}
                    value={adminUsersManager.currentUser.password || ''} />
                </div>
              </div>
              <div className='add-teacher-fields-row'>
                <div className='add-teacher-field fullwidth'>
                  <div className='add-teacher-field-txt'>{t('confirmPassword', 'Confirm Password')}</div>
                  <Input
                    disabled={isSsoUser}
                    icon={(
                      <ShowPasswordButton
                        isPassword={(this.state.confirmPasswordInputType === 'password')}
                        name='confirmPasswordInputType'
                        onFlip={this.handleShowPasswordPressed} />
                    )}
                    name='newConfirmPassword'
                    onChange={this.handleChangeConfirmPassword}
                    placeholder='password'
                    type={this.state.confirmPasswordInputType}
                    value={this.state.confirmPassword || ''} />
                </div>
              </div>
              {this.state.saved
                ? (
                  <Message positive>
                    <Message.Header>Success</Message.Header>
                    <p>
                      Your profile has been updated.
                    </p>
                  </Message>
                ) : null}
              {this.state.error
                ? (
                  <Message negative>
                    <Message.Header>Something went wrong</Message.Header>
                    <p>
                      {this.state.errorMessage}
                    </p>
                  </Message>
                )
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SatCoreRegister('AdminAddTeacher', AdminAddTeacher);
