/** @typedef {'addClassroomMode' | 'selectClassroomMode' | 'selectReportClassroomMode'} InstitutionDropDownMode */

export const INSTITUTION_DROPDOWN_MODE = {
  ADD_CLASSROOM_MODE: 'addClassroomMode',
  ALL_MODES: 'allModes',
  SELECT_CLASSROOM_MODE: 'selectClassroomMode',
  SELECT_REPORT_CLASSROOM_MODE: 'selectReportClassroomMode'
};

export const ORIGINAL_SELECTED_DROPDOWN_INSTITUTION_ID_OBSERVABLE_OBJ = {
  addClassroomMode: null,
  selectClassroomMode: null,
  selectReportClassroomMode: null
};
