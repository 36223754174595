import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import iconCautionBlack from '../img/icon-caution-black.svg';

import '../css/InfoIcon.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

const CautionIcon = observer((props = {}) => {
  const { /* managers */ } = useContext(MobXProviderContext);

  const InfoIcon = SatCoreComponent('InfoIcon');

  const { expiredLicenseCautionIcon, t } = props;

  const className = classNames('CautionIcon', props.className, {
    ExpiredLicenseCautionIcon: expiredLicenseCautionIcon
  });

  const icon = props.icon || iconCautionBlack;

  let popupOptions;
  if (expiredLicenseCautionIcon) {
    // add custom 'expiredLicenseCautionIcon' logic here
    if (!props.popupOptions) {
      const expiredLicenseCautionMsg = t('expiredLicenseCautionMsg');
      popupOptions = {
        content: expiredLicenseCautionMsg, position: 'bottom center'
      };
    }
  } else {
    popupOptions = props?.popupOptions;
  }

  return (
    <InfoIcon {...props} className={className} icon={icon} popupOptions={popupOptions} />
  );
});
export default CautionIcon;

SatCoreRegister('CautionIcon', CautionIcon);
