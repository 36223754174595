import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import { register } from '../../../i18n';

import EngagementService from '../../../services/EngagementService';

const EngagementHeaderAuxiliaryLanguageToolUsageCells = observer((props) => {
  const { /* managers */ } = useContext(MobXProviderContext);

  const t = register('EngagementTable');

  const renderEngagementHeaderAuxiliaryLanguageToolUsageCells = () => {
    return (
      <>
        {EngagementService.allowEngagementColumn('viewTextToSpeechAvailable') &&
          renderEngagementHeaderAuxiliaryLanguageToolCell({
            allowPopupComponent: true,
            cellText: t('engagedTextToSpeechUsage'),
            className: 'engagement-header-label detail-language-tool-usage-header engagedTextToSpeechUsage',
            isHeaderCell: true,
            popupContent: t('engagedTextToSpeechUsageDescription'),
            popupOn: 'click'
          })}
        {EngagementService.allowEngagementColumn('viewTranslateAvailable') &&
          renderEngagementHeaderAuxiliaryLanguageToolCell({
            allowPopupComponent: true,
            cellText: t('engagedTranslateUsage'),
            className: 'engagement-header-label detail-language-tool-usage-header engagedTranslateUsage',
            isHeaderCell: true,
            popupContent: t('engagedTranslateUsageDescription'),
            popupOn: 'click'
          })}
      </>
    );
  };

  const renderEngagementHeaderAuxiliaryLanguageToolCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementHeaderAuxiliaryLanguageToolUsageCells();
});
export default EngagementHeaderAuxiliaryLanguageToolUsageCells;

SatCoreRegister('EngagementHeaderAuxiliaryLanguageToolUsageCells', EngagementHeaderAuxiliaryLanguageToolUsageCells);
