import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Image, Popup } from 'semantic-ui-react';
import { MobXProviderContext, observer } from 'mobx-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';
import UtilityService from '../services/UtilityService';
import NavigationService from '../services/NavigationService';
import ResourcePacingService from '../services/ResourcePacingService';
import { ELEMENT_TYPE } from '../managers/CourseManager';
import menuCloseIcon from '../img/icon-menu-close.svg';
import menuOpenIcon from '../img/icon-menu-open.svg';
import { useParams } from '../hoc/withParams';

import '../css/BreadCrumbsCourseDropdown.less';

const convertToNestedDropdownOptions = (courseElements, props) => {
  const { onClick, isTeacher, isStudent, showHidden, getUnitSectionName } = props;
  let unitOrderCount = -1;
  return courseElements.reduce((result, courseElement) => {
    const { id, type, CourseResourceElements } = courseElement;

    // Make sure that CourseElementList has same logic
    const orderNum = (!courseElement.unitHidden) ? ++unitOrderCount : -1;

    const visibilityMode = ResourcePacingService.visibilityMode(courseElement);
    if (!ELEMENT_TYPE.isBranchType(courseElement) || ((!isTeacher || courseElement.deliveryMode !== 'teacher_only') && (isStudent || !showHidden) && visibilityMode === 'hidden_from_students')) {
      return result;
    }

    const courseName = getUnitSectionName(courseElement, orderNum);
    const content = <div>{UtilityService.reactHtmlParserWrapper(courseName).parsed}</div>;
    result.push({
      value: id,
      text: <Popup
        content={content}
        hoverable
        mouseEnterDelay={2000}
        on='hover'
        trigger={content}
        wide='very' />,
      onClick: () => onClick(courseElement),
      children: type === ELEMENT_TYPE.ROOT ? [] : convertToNestedDropdownOptions(CourseResourceElements, props),
      className: `option-${type}`
    });
    if (type === ELEMENT_TYPE.ROOT) {
      result.push(...convertToNestedDropdownOptions(CourseResourceElements, props));
    }
    return result;
  }, []);
};

const BreadCrumbsCourseDropdown = observer((props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const { params: { elementId } } = useParams(props);

  const { history, className, ...restProps } = props;
  const { t } = props;
  const { classroomManager, courseManager, userManager: { isTeacher, isStudent }, courseDeliveryManager: { shouldShowHiddenResourcesObservable } } = useContext(MobXProviderContext);
  const { fullCourseResource, getUnitSectionName } = courseManager;

  const NestedDropdown = useMemo(() => SatCoreComponent('NestedDropdown'), []);
  const trigger = useMemo(() => (
    <Button className={`${open ? 'close' : 'open'}-menu`} primary>
      <Image alt='' src={open ? menuCloseIcon : menuOpenIcon} />
      <span>{open ? t('closeLabel', 'Close Nav') : t('openLabel', 'Quick Nav')}</span>
    </Button>
  ), [open]);
  const onClick = useCallback(async (courseElement) => {
    courseManager.setTreeLoading(true);
    await NavigationService.handleCourseElementClick(courseElement, history);
    courseManager.setTreeLoading(false);
  }, [courseManager, history]);
  // TODO remove // useEffect(() => courseManager.fullCourseResource = null, [classroomManager.currentClassroomId, courseManager.currentCourseId]);
  useEffect(() => courseManager.setFullCourseResource(null), [classroomManager.currentClassroomId, courseManager.currentCourseId]);
  useEffect(() => {
    open && classroomManager.currentClassroomId && courseManager.currentCourseId && !fullCourseResource &&
      courseManager.getFullCourseResource(classroomManager.currentClassroomId, courseManager.currentCourseId);
  }, [classroomManager.currentClassroomId, courseManager.currentCourseId, open]);
  useEffect(
    () => setOptions(fullCourseResource ? convertToNestedDropdownOptions([fullCourseResource], { onClick, isTeacher, isStudent, showHidden: shouldShowHiddenResourcesObservable, getUnitSectionName: getUnitSectionName.bind(courseManager) }) : []),
    [fullCourseResource, onClick, isTeacher, isStudent, shouldShowHiddenResourcesObservable]
  );
  return (
    <div className={['bread-crumb-course-dropdown-wrapper', className].filter((name) => name).join(' ')}>
      <NestedDropdown
        aria-label={t('label', 'Quick Nav')}
        className='bread-crumbs-course-dropdown'
        icon={null}
        isLoading={fullCourseResource === null}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        options={options}
        scrolling
        trigger={trigger}
        value={elementId}
        {...restProps} />
    </div>
  );
});

export default BreadCrumbsCourseDropdown;

SatCoreRegister('BreadCrumbsCourseDropdown', BreadCrumbsCourseDropdown);
