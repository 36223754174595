import { registerClass } from '../../SatCoreRegistry';

import reportIdentityManager from '../../managers/reports/ReportIdentityManager';
import reportOverrideManager from '../../managers/reports/ReportOverrideManager';

export default class ReportOverrideService {
  static shouldUseCmapId = () => {
    const { isStandardsReport } = reportIdentityManager;
    const { allowUseCmapId } = reportOverrideManager;
    return allowUseCmapId && isStandardsReport;
  };
}

registerClass('ReportOverrideService', ReportOverrideService);
