import React, { useContext } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import { Button } from 'semantic-ui-react';

import '../../css/reports/ReportContextSwitcher.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import {
  REPORT_ASSIGNMENT_TYPE,
  REPORT_CONTENT_TYPE,
  REPORT_CONTEXT_TYPE,
  REPORT_FAILURE_BEHAVIOR_TYPE
} from '../../services/reports/ReportConstants';

import ReportContextService from '../../services/reports/ReportContextService';
import ReportIdentityService from '../../services/reports/ReportIdentityService';

const ReportContextSwitcher = observer((props) => {
  const { t } = props;

  const {
    reportIdentityManager, reportContextManager,
    reportOverrideManager, reportStandardsManager,
    userManager
  } = useContext(MobXProviderContext);

  const InfoIcon = SatCoreComponent('InfoIcon');

  /** @param {import('../../managers/reports/ReportContextManager').ReportContextType} reportContextType */
  const handleClickReportContextSwitcherButton = async (reportContextType) => {
    if (reportContextType === REPORT_CONTEXT_TYPE.PROGRESS) {
      const contentType = reportStandardsManager.selectedReportMoreFiltersContentType;
      const assignmentType = reportStandardsManager.selectedReportMoreFiltersAssignmentType;

      // only the base report has progress data so load if necessary
      if (contentType !== REPORT_CONTENT_TYPE.ALL || assignmentType !== REPORT_ASSIGNMENT_TYPE.ALL) {
        // TODO unused // reportStandardsManager.setSelectedReportMoreFiltersContentType(REPORT_CONTENT_TYPE.ALL);
        reportStandardsManager.setSelectedReportMoreFiltersAssignmentType(REPORT_ASSIGNMENT_TYPE.ALL);
        const reportId = ReportIdentityService.getReportId(REPORT_CONTENT_TYPE.ALL, REPORT_ASSIGNMENT_TYPE.ALL, {
          isStandardsFilterReportId: true
        });

        const curriculumMapId = reportStandardsManager.selectedReportCmapContentItemId;

        await reportIdentityManager.fetchReportResponseJson({
          curriculumMapId,
          failureBehaviorType: REPORT_FAILURE_BEHAVIOR_TYPE.FALLBACK_AND_FORCE_NO_DATA_STATE,
          reportId
        });
      }
    }

    ReportContextService.switchReportContext(reportContextType);
  };

  const renderReportContextSwitcher = () => {
    const {
      isTableSummaryReport, reportInfoClassNames, isFacultyClassroomReport, isStandardsReport, isCourseReport
    } = reportIdentityManager;

    const { allowProgressReportContext } = reportOverrideManager;

    const { hasViewEngagementDataPermission } = userManager;

    const { PERFORMANCE, USAGE, PROGRESS, ITEM_LEVEL, RESOURCE_LEVEL, ENGAGEMENT } = REPORT_CONTEXT_TYPE;

    const showProgressButton = allowProgressReportContext && isFacultyClassroomReport && isStandardsReport;

    const showEngagementButton = hasViewEngagementDataPermission && isFacultyClassroomReport && isCourseReport;

    const defaultContextSwitcherButtons = (
      <>
        {renderReportContextSwitcherButton(PERFORMANCE)}
        {renderReportContextSwitcherButton(USAGE)}
        {showProgressButton &&
          renderReportContextSwitcherButton(PROGRESS)}
        {showEngagementButton &&
          renderReportContextSwitcherButton(ENGAGEMENT)}
      </>
    );

    const standardsClassroomDetailStudentsContextSwitcherButtons = (
      <>
        {renderReportContextSwitcherButton(RESOURCE_LEVEL)}
        {renderReportContextSwitcherButton(ITEM_LEVEL)}
      </>
    );

    const isReportStandardsClassroomDetailStudents = ReportIdentityService.isReportStandardsClassroomDetailStudents();
    const shouldRenderDefaultButtons = !isTableSummaryReport && !isReportStandardsClassroomDetailStudents;
    const shouldRenderStandardsClassroomDetailStudentsButtons = isReportStandardsClassroomDetailStudents;

    const shouldRenderContextSwitcher = shouldRenderDefaultButtons || shouldRenderStandardsClassroomDetailStudentsButtons;
    if (shouldRenderContextSwitcher) {
      let contextSwitcherButtons;
      if (shouldRenderStandardsClassroomDetailStudentsButtons) {
        contextSwitcherButtons = standardsClassroomDetailStudentsContextSwitcherButtons;
      } else {
        contextSwitcherButtons = defaultContextSwitcherButtons;
      }
      return (
        <div className={`report-context-switcher buttons ${reportInfoClassNames}`}>
          {contextSwitcherButtons}
        </div>
      );
    } else {
      return null;
    }
  };

  /** @param {import('../../managers/reports/ReportContextManager').ReportContextType} reportContextType */
  const renderReportContextSwitcherButton = (reportContextType) => {
    let activeReportContextType;
    if (ReportIdentityService.isReportStandardsClassroomDetailStudents()) {
      activeReportContextType = reportContextManager.reportStandardsClassroomDetailStudentsContextType;
      activeReportContextType = activeReportContextType || REPORT_CONTEXT_TYPE.RESOURCE_LEVEL;
    } else {
      activeReportContextType = reportContextManager.reportContextType;
      activeReportContextType = activeReportContextType || REPORT_CONTEXT_TYPE.PERFORMANCE;
    }

    let buttonLabel = t(reportContextType);
    if (reportContextType === 'growth') {
      // progress button has an info icon with popup info
      buttonLabel = (
        <div>
          <span>{t(reportContextType)}</span>
          <span className='info-icon'>
            <InfoIcon popupOptions={{ content: t('progressInfo'), on: 'hover' }} />
          </span>
        </div>
      );
    }

    return (
      <div className={`context-switcher-button ${reportContextType}`}>
        <Button
          key={reportContextType}
          onClick={() => handleClickReportContextSwitcherButton(reportContextType)}
          primary={reportContextType === activeReportContextType}>
          {buttonLabel}
        </Button>
      </div>
    );
  };
  return renderReportContextSwitcher();
});
export default ReportContextSwitcher;

SatCoreRegister('ReportContextSwitcher', ReportContextSwitcher);
