import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button } from 'semantic-ui-react';

import '../css/StudentAccessCode.less';

import { SatCoreRegister } from '../SatCoreRegistry';

export default
@inject('classroomManager')
@observer
class StudentAccessCode extends Component {
  componentDidMount() {}

  handleClickRefreshCode = (e) => {
    const { classroomManager, classId } = this.props;
    e.preventDefault();
    classroomManager.refreshStudentAccessCode(classId);
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onClick?.();
  }

  render() {
    const { classroomManager, classId, onClick, t } = this.props;
    let code = '';
    if (classId && classId !== '') {
      code = classroomManager.getStudentAccessCode(classId);
    }
    return (
      <div className='studentAccessCode' onClick={onClick}>
        <div className='accessCodeHeader'>
          {t('studentAccessCode', 'Student Access Code')}
        </div>
        <div className='accessCode'>
          <div className='accessCodeLabel'>{code}</div>
          <div className='accessCodeRefresh'>
            <Button basic onClick={this.handleClickRefreshCode}
              size='mini' type='button'>
              Refresh
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

SatCoreRegister('StudentAccessCode', StudentAccessCode);
