import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Loader, Pagination } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import '../css/CartModalClassrooms.less';

export default
@inject('adminClassroomManager', 'filteredHeaderTableManager')
@observer
class CartModalClassrooms extends Component {
  filteredHeaderTableKey = 'cart-modal-classrooms-table';

  constructor(props) {
    super(props);
    this.FilteredHeaderTable = SatCoreComponent('FilteredHeaderTable');
  }

  async componentDidMount() {
    const { filteredHeaderTableManager } = this.props;
    filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);
    await this.fetchClassrooms();
  }

  async componentDidUpdate(prevProps) {
    const { filteredHeaderTableManager, institutionId } = this.props;
    let changesMade = false;
    if (institutionId !== prevProps.institutionId) {
      changesMade = true;
    }
    if (!institutionId && filteredHeaderTableManager.isCleared) {
      filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);
      changesMade = true;
    }
    if (changesMade) {
      await this.fetchClassrooms();
    }
  }

  institutionFilter() {
    const { institutionId } = this.props;
    return institutionId && { field: 'institutionId', operator: 'eq', value: institutionId };
  }

  async fetchClassrooms() {
    const { adminClassroomManager, filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    await adminClassroomManager.fetchClassrooms(
      tableData?.activePage || 1,
      adminClassroomManager.ADMIN_CLASSROOM_FETCH_PAGE_SIZE,
      tableData?.column || '',
      !tableData || tableData.direction === 'ascending' ? 'asc' : 'desc',
      [...(tableData?.filters || []), this.institutionFilter()].filter((item) => item)
    );
  }

  onPageChange = async (_event, pageInfo) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, pageInfo.activePage);
      await this.fetchClassrooms();
    }
  }

  handleSort = async (clickedColumn, filters) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      if (clickedColumn) {
        const newDirection = tableData.direction === 'ascending' ? 'descending' : 'ascending';
        filteredHeaderTableManager.setSortColumn(this.filteredHeaderTableKey, clickedColumn);
        filteredHeaderTableManager.setSortDirection(this.filteredHeaderTableKey, newDirection);
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
        await this.fetchClassrooms();
      } else {
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      }
    }
  }

  handleFilter = async (filters) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, 1);
      await this.fetchClassrooms();
    }
  }

  createHeaderData = () => {
    const { t } = this.props;
    return [
      {
        /* School (institution) Name */
        label: 'School',
        columnClassName: 'truncate-long',
        sortKey: 'schoolName',
        filterKey: 'schoolName',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        /* Classroom Name */
        label: t('className', 'Class Name'),
        columnClassName: 'truncate-long',
        sortKey: 'classroomName',
        filterKey: 'classroomName',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        /* Classroom ID */
        label: t('classId', 'Class ID'),
        columnClassName: 'truncate-long',
        sortKey: null,
        filterKey: null,
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        /* Teacher First Name */
        label: 'Teacher First Name',
        columnClassName: 'truncate-medium',
        sortKey: 'teacherFirstName',
        filterKey: 'teacherFirstName',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        /* Teacher Last Name */
        label: 'Teacher Last Name',
        columnClassName: 'truncate-medium',
        sortKey: 'teacherLastName',
        filterKey: 'teacherLastName',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        /* Add Button (placeholder) */
        label: '',
        columnClassName: '',
        sortKey: '',
        filterKey: ''
      }
    ];
  }

  createBodyData = () => {
    const { adminClassroomManager, classrooms, onRemove, onAdd, addLabel = 'Add', addedLabel = 'Added' } = this.props;
    return adminClassroomManager.classroomsArray.map((classroom) => {
      const inCart = !!classrooms.find((item) => item.classroomId === classroom.classroomId);
      return [
        {
          /* School (institution) Name */
          columnName: 'schoolName',
          columnText: classroom.institutionName,
          columnClassName: 'truncate-long',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* Classroom Name */
          columnName: 'classroomName',
          columnText: classroom.classroomName,
          columnClassName: 'truncate-long',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* Classroom ID */
          columnName: 'classroomId',
          columnText: classroom.classroomId,
          columnClassName: 'truncate-long',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* Teacher First Name */
          columnName: 'teacherFirstName',
          columnText: classroom.leadTeacherFirstName,
          columnClassName: 'truncate-medium',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* Teacher Last Name */
          columnName: 'teacherLastName',
          columnText: classroom.leadTeacherLastName,
          columnClassName: 'truncate-medium',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* View Button */
          columnName: null,
          columnText: inCart ? addedLabel : addLabel,
          columnClassName: '',
          columnOnClick: null,
          columnButtonOnClick: () => (inCart ? onRemove(classroom) : onAdd(classroom)),
          columnButtonBasic: !inCart,
          showTooltip: true,
          showTootipEvent: 'click'
        }
      ];
    });
  }

  render() {
    const { adminClassroomManager, filteredHeaderTableManager, t } = this.props;
    const { FilteredHeaderTable } = this;
    const tableBodyData = adminClassroomManager.loaded ? this.createBodyData() : [];
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    let activePage = 0;
    let column = '';
    let direction = '';
    let filters = [];
    if (tableData) {
      activePage = tableData.activePage;
      column = tableData.column;
      direction = tableData.direction;
      filters = tableData.filters;
    }

    return (
      <Container className='cart-modal-classrooms' fluid>
        <Container>
          <div className='tableContainerHeader'>
            <div className='tableTitle theme-header-title'>{t('classesLabel', 'Classes')}</div>
            <div className='tableSpacer' />
            <div className='tableRightHeader'>
              <div className='tablePagination'>
                {tableBodyData.length > 0 && (
                <Pagination
                  activePage={activePage}
                  onPageChange={this.onPageChange}
                  totalPages={adminClassroomManager.totalPages} />
                )}
              </div>
            </div>
          </div>
          <div className='tableContainer'>
            <FilteredHeaderTable
              autoCompleteSearchData={null}
              column={column}
              direction={direction}
              filtersData={filters}
              handleFilter={this.handleFilter}
              handleSort={this.handleSort}
              tableBodyData={tableBodyData}
              tableHeaderData={this.createHeaderData()}
              tableId={this.filteredHeaderTableKey} />
          </div>
          {!adminClassroomManager.loaded && (
          <div className='null-state-panel'>
            <Loader key={0} active inline />
          </div>
          )}
          {adminClassroomManager.loaded && (!tableBodyData || tableBodyData.length < 1) && (
          <div className='null-state-panel'>
            No Data Found
          </div>
          )}
        </Container>
      </Container>
    );
  }
}

SatCoreRegister('CartModalClassrooms', CartModalClassrooms);
