import React from 'react';
import { Image } from 'semantic-ui-react';
import { SatCoreRegister } from '../SatCoreRegistry';
import comingSoonImg from '../img/image-coming-soon.svg';
import '../css/ComingSoon.less';

export default function ComingSoon(props) {
  return (
    <div className='coming-soon-container'>
      <div className='coming-soon'>
        <h1 className='coming-soon-title'>{props.title}</h1>
        <Image src={comingSoonImg} />
      </div>
    </div>
  );
}

SatCoreRegister('ComingSoon', ComingSoon);
