import React, { useMemo, useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import Modal from '../Modal';

import ActivityModeDropdown from '../ActivityModeDropdown';

import '../../css/SelectPreviewModeModal.less';

const SelectPreviewModeModal = (props) => {
  const {
    close, contentMode, courseElement, modes,
    open, pdfFormatSelected, setPreviewModeId, t
  } = props;
  const ModalBanner = useMemo(() => SatCoreComponent('ModalBanner'), []);
  const alternateModeId = courseElement.alternateModeIdOverride || courseElement.alternateModeId;
  /* MODE: which mode to use */
  const [publisherModeId, setPreviewMode] = useState(alternateModeId);

  const doSave = () => {
    // set to current mode and continue
    setPreviewModeId(courseElement, contentMode, pdfFormatSelected, publisherModeId);
  };

  const setActivityMode = async (activityModeId) => {
    // save selection to state
    setPreviewMode(activityModeId);
  };

  const doCancel = () => {
    // reset to original mode and continue
    // setPreviewModeId(assignmentcourseElement, contentMode, pdfFormatSelected, alternateModeId);
    close();
  };

  return (
    <Modal
      className='select-preview-mode-modal'
      closeOnDimmerClick={false}
      closeOnEscape={true}
      onClose={close}
      open={open}>
      <ModalBanner onClose={close} title={t('selectPreviewMode', 'Select Preview Mode')} />
      <Modal.Content>
        <Form.Field>
          <div className='resource-lesson-mode'>
            <div className='resource-lesson-mode-input-wrapper'>
              <ActivityModeDropdown
                activityMode={publisherModeId}
                courseResourceElementId={courseElement.elementId || courseElement.courseResourceElementId}
                setActivityMode={setActivityMode}
                modes={modes} />
            </div>
          </div>
        </Form.Field>
      </Modal.Content>
      <Modal.Actions>
        <Button basic className='cancelButton' onClick={doCancel} primary>
          {t('cancel', 'Cancel button text missing')}
        </Button>
        <Button className='ui primary button saveButton' disabled={publisherModeId === null} onClick={doSave}>
          {t('save', 'Save button text missing')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SelectPreviewModeModal;

SatCoreRegister('SelectPreviewModeModal', SelectPreviewModeModal);
