import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { SatCoreRegister } from '../SatCoreRegistry';

import { useScript } from '../hooks';

import ThirdPartyService from '../services/ThirdPartyService';

const ThirdPartyChatLauncher = observer((_props) => {
  const { thirdPartyManager } = useContext(MobXProviderContext);

  useScript({
    cryptographicId: thirdPartyManager.thirdPartyChatLauncherId,
    url: thirdPartyManager.thirdPartyChatLauncherUrl
  });

  useEffect(() => {
    if (typeof ThirdPartyService.initAdditionalThirdPartyChatLauncherLogic === 'function') {
      ThirdPartyService.initAdditionalThirdPartyChatLauncherLogic();
    }
  }, []);

  return (<></>);
});
export default ThirdPartyChatLauncher;

SatCoreRegister('ThirdPartyChatLauncher', ThirdPartyChatLauncher);
