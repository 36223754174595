import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Dropdown } from 'semantic-ui-react';

import '../../css/reports/ReportDropdown.less';

import { SatCoreRegister } from '../../SatCoreRegistry';

import { getSessionStorageItem } from '../../utils';

import ReportIdentityService from '../../services/reports/ReportIdentityService';
import ReportTableService from '../../services/reports/ReportTableService';
import ReportTypeService from '../../services/reports/ReportTypeService';
import { REPORT_FAILURE_BEHAVIOR_TYPE } from '../../services/reports/ReportConstants';

const ReportCourseDropdown = observer((props) => {
  const { t } = props;

  const {
    reportCourseManager,
    reportIdentityManager,
    reportTypeManager
  } = useContext(MobXProviderContext);

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  useEffect(() => {
    (async () => {
      reportCourseManager.setSelectedReportCourseInfo(
        getSessionStorageItem('selectedReportCourseContentItemId'),
        getSessionStorageItem('selectedReportCourseName')
      );
    })();
  }, []);

  const handleChangeReportCourseDropdown = async (_event, data) => {
    const { reportCourseNamesByInstitutionMap } = reportCourseManager;

    const courseContentItemId = data.value;
    const courseName = reportCourseNamesByInstitutionMap.get(courseContentItemId);

    reportCourseManager.setSelectedReportCourseInfo(courseContentItemId, courseName);

    if (reportIdentityManager.isStandardsReport) {
      const { activeReportType, activeReportFacultyType } = reportIdentityManager;
      const { selectedReportLessonFilterType } = reportTypeManager;
      let reportId = ReportTypeService.getLessonFilterReportIdIfApplicable(selectedReportLessonFilterType);
      reportId = reportId || ReportIdentityService.getReportId(activeReportType, activeReportFacultyType);

      await reportIdentityManager.fetchReportResponseJson({
        courseId: courseContentItemId,
        failureBehaviorType: REPORT_FAILURE_BEHAVIOR_TYPE.FALLBACK_AND_FORCE_NO_DATA_STATE,
        reportId
      });
    } else {
      reportIdentityManager.setForceShowNoDataZeroState(false);
      ReportTableService.forceRerenderReportTable();
      ReportTypeService.initReportTableData();
    }
  };

  const renderReportCourseDropdown = () => {
    const { reportCourseDropdownOptions, selectedReportCourseContentItemId } = reportCourseManager;
    const { reportInfoClassNames } = reportIdentityManager;

    let placeholder;
    if (!reportCourseDropdownOptions.length) {
      placeholder = t('noCoursesToDisplay');
    } else {
      placeholder = t('selectCourse');
    }

    return (
      <div className={`report-dropdown report-course-dropdown ${reportInfoClassNames}`}>
        <div className='dropdown-label'>
          <div className='text'>
            {t('course')}
          </div>
        </div>
        <Dropdown
          disabled={!reportCourseDropdownOptions || (selectedReportCourseContentItemId && reportCourseDropdownOptions.length <= 1)}
          onChange={handleChangeReportCourseDropdown}
          options={reportCourseDropdownOptions}
          placeholder={t(placeholder)}
          search
          selection
          value={selectedReportCourseContentItemId} />
      </div>
    );
  };
  return renderReportCourseDropdown();
});
export default ReportCourseDropdown;

SatCoreRegister('ReportCourseDropdown', ReportCourseDropdown);
