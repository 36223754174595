import React, { useContext, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Checkbox, Image, Loader, Table } from 'semantic-ui-react';

import classNames from 'classnames';

import '../css/AccommodationsTable.less';

import iconInfo from '../img/icon-info.svg';

import { SatCoreRegister } from '../SatCoreRegistry';

import AccommodationsService from '../services/AccommodationsService';
import PopupService from '../services/PopupService';
import { ACCOMMODATION_TOGGLE_KEY } from '../services/AccommodationConstants';

const AccommodationsTable = observer((props) => {
  const { /* hideEdit, readOnly, */t } = props;

  const {
    accommodationsManager
  } = useContext(MobXProviderContext);

  const [hideTable, setHideTable] = useState(false);
  const [loadingAccommodationsTable, setLoadingAccommodationsTable] = useState(false);

  const config = AccommodationsService.ACCOMMODATIONS_CONFIG();

  const handleToggleAccommodation = async (_event, { checked }, {
    rosterItem,
    sectionKey,
    toggleKey
  } = {}) => {
    setLoadingAccommodationsTable(true);

    const showingAllSections = config.assessmentSection?.show && config.lessonSection?.show;
    const shouldRefreshTableData = !rosterItem || (showingAllSections && config.treatAllSectionsAsOne);

    if (shouldRefreshTableData) {
      setHideTable(true);
    }
    const shouldRemove = !checked;
    const sectionKeys = config.treatAllSectionsAsOne ? ['assessmentSection', 'lessonSection'] : [sectionKey];
    await updateStudentOrClassroomAccommodationOption({ rosterItem, sectionKeys, shouldRemove, toggleKey });

    if (!hideTable) {
      setHideTable(true);
    }
    if (shouldRefreshTableData) {
      setTimeout(async () => {
        await accommodationsManager.fetchAccommodationsRoster();
        setLoadingAccommodationsTable(false);
        setHideTable(false);
      }, 1500);
    } else {
      setLoadingAccommodationsTable(false);
      setHideTable(false);
    }
  };

  const updateStudentOrClassroomAccommodationOption = async ({
    rosterItem,
    sectionKeys,
    shouldRemove,
    toggleKey
  } = {}) => {
    for (const sectionKey of sectionKeys) {
      const accommodationId = ACCOMMODATION_TOGGLE_KEY[sectionKey][toggleKey];
      // eslint-disable-next-line no-await-in-loop
      await accommodationsManager.updateStudentOrClassroomAccommodationOption(
        rosterItem?.id,
        accommodationId,
        shouldRemove
      );
    }
  };

  const getTableCellAdditionalClassNames = (index) => {
    let additionalClassNames = '';
    if (config.cellDividerClassName === 'section-divider' && index === 0) {
      additionalClassNames += ' section-divider';
    } else if (config.cellDividerClassName && config.cellDividerClassName !== 'section-divider') {
      additionalClassNames += ` ${config.cellDividerClassName}`;
    }
    return additionalClassNames;
  };

  const renderAccommodationsTable = () => {
    return (hideTable && loadingAccommodationsTable) ? <Loader active /> : (
      <>
        <Loader active={!hideTable && loadingAccommodationsTable} />
        <Table className='accommodations-table' striped>
          {config.showTopHeader && renderAccommodationsTopHeader()}
          <Table.Header>
            {renderAccommodationsTableHeader()}
          </Table.Header>
          <Table.Body>
            {renderAccommodationsTableBody()}
          </Table.Body>
        </Table>
      </>
    );
  };

  const renderAccommodationsTopHeader = () => {
    const assessmentSectionToggleCount = AccommodationsService.getAvailableSectionToggleCount({
      sectionKey: 'assessmentSection'
    });
    const lessonSectionToggleCount = AccommodationsService.getAvailableSectionToggleCount({
      sectionKey: 'lessonSection'
    });
    return (
      <Table.Header>
        <Table.Row className='accommodations-table-top-header'>
          <Table.Cell className='accommodations-cell-first-name firstName placeholder'>
            {/* placeholder */}
          </Table.Cell>
          <Table.Cell className='accommodations-cell-last-name lastName placeholder'>
            {/* placeholder */}
          </Table.Cell>

          {renderAccommodationsTopHeaderSection({
            sectionKey: 'assessmentSection', toggleCount: assessmentSectionToggleCount
          })}
          {renderAccommodationsTopHeaderSection({
            sectionKey: 'lessonSection', toggleCount: lessonSectionToggleCount
          })}
        </Table.Row>
      </Table.Header>
    );
  };

  /**
   * @param {{
   *   sectionKey: 'assessmentSection' | 'lessonSection'
   *   toggleCount: number
   * }}
   */
  const renderAccommodationsTopHeaderSection = ({ sectionKey, toggleCount } = {}) => {
    const cells = new Array(toggleCount).fill();

    const firstSectionCellIndex = 0;
    const lastSectionCellIndex = cells.length - 1;
    const sectionCellIndex = sectionKey === 'assessmentSection' ? lastSectionCellIndex : firstSectionCellIndex;
    cells[sectionCellIndex] = (
      <>
        {t(sectionKey)}
        {renderAccommodationsHeaderPopup({ content: t(`${sectionKey}Description`) })}
      </>
    );
    return (
      <>
        {cells.map((cellJsx, index) => {
          let additionalClassNames = getTableCellAdditionalClassNames(index)?.trim?.();
          additionalClassNames = classNames(sectionKey, {
            assessmentSectionLabel: index === firstSectionCellIndex || index === lastSectionCellIndex
          }, additionalClassNames);
          return (
            <Table.Cell key={`placeholder_${sectionKey}_${index}`}
              className={additionalClassNames}>
              {cellJsx}
            </Table.Cell>
          );
        })}
      </>
    );
  };

  const renderAccommodationsTableHeader = () => {
    let tableHeaderClassNames = 'accommodations-header-row';
    tableHeaderClassNames += config?.showTopHeader ? ' has-top-header' : '';
    return (
      <Table.Row className={tableHeaderClassNames} style={{ fontSize: config.tableHeaderFontSize || 'inherit' }}>
        {/* HEADER: FIRST NAME */}
        <Table.HeaderCell className='accommodations-cell-first-name firstName'>
          {t('firstName')}
        </Table.HeaderCell>
        {/* HEADER: LAST NAME */}
        <Table.HeaderCell className='accommodations-cell-last-name lastName'>
          {t('lastName')}
        </Table.HeaderCell>
        {/* HEADER: TOGGLES */}
        {renderAccommodationsTableHeaderRowSection({ sectionKey: 'assessmentSection' })}
        {renderAccommodationsTableHeaderRowSection({ sectionKey: 'lessonSection' })}
      </Table.Row>
    );
  };

  /**
   * @param {{ sectionKey: 'assessmentSection' | 'lessonSection' }}
   */
  const renderAccommodationsTableHeaderRowSection = ({ sectionKey } = {}) => {
    return config[sectionKey].show && (
      Object.keys(config[sectionKey].toggles).map((toggleKey, index) => {
        const infoIconLabelTranslationKey = `${toggleKey}InfoIconLabel`; // e.g. 'contentLanguageToggleInfoIconLabel'
        const infoIconLabelTranslationValue = t(infoIconLabelTranslationKey, ''); // e.g. 'Coming Fall 2024'

        // only show an info icon for a toggle label if it has a proper translation value associated with it
        // else if `infoIconLabelTranslationValue` is empty or equals the translation key, do not show the info icon
        const hasInfoIcon = infoIconLabelTranslationValue && (
          infoIconLabelTranslationKey !== infoIconLabelTranslationValue
        );

        const additionalClassNames = getTableCellAdditionalClassNames(index);
        return config[sectionKey].toggles[toggleKey] && (
          <Table.HeaderCell key={toggleKey}
            className={`accommodations-header-cell ${sectionKey} ${toggleKey}${additionalClassNames}`}>
            {t(toggleKey)}
            {hasInfoIcon && renderAccommodationsHeaderPopup({ content: infoIconLabelTranslationValue })}
          </Table.HeaderCell>
        );
      })
    );
  };

  const renderAccommodationsHeaderPopup = ({ content }) => {
    return PopupService.renderPopup({
      className: 'toolbar-item-popup',
      content,
      trigger: <Image alt='' className='info-icon-pointable' spaced src={iconInfo} />
    });
  };

  const renderAccommodationsTableBody = () => {
    return (
      <>
        {accommodationsManager.currentAccommodationsRosterArray?.length ? (
          <Table.Row>
            {/* BODY: TOP (summary) ROW */}
            <Table.Cell>{t('selectAllStudents')}</Table.Cell>
            <Table.Cell>{/* placeholder */}</Table.Cell>
            {renderAccommodationsTableBodySummaryRowSection({ sectionKey: 'assessmentSection' })}
            {renderAccommodationsTableBodySummaryRowSection({ sectionKey: 'lessonSection' })}
          </Table.Row>
        ) : (
          <Table.Row>
            <Table.Cell className='accommodations-cell zeroState'>{t('accommodationsZeroState')}</Table.Cell>
          </Table.Row>
        )}
        {/* BODY: STUDENT ROWS */}
        {accommodationsManager.currentAccommodationsRosterArray?.map((rosterItem) => {
          return (
            <Table.Row key={rosterItem.id}>
              {renderTableBodyPopupCell({ cellKey: 'firstName', content: rosterItem.firstName })}
              {renderTableBodyPopupCell({ cellKey: 'lastName', content: rosterItem.lastName })}
              {renderAccommodationsTableBodyRowSection({ rosterItem, sectionKey: 'assessmentSection' })}
              {renderAccommodationsTableBodyRowSection({ rosterItem, sectionKey: 'lessonSection' })}
            </Table.Row>
          );
        })}
      </>
    );
  };

  /**
   * @param {{ sectionKey: 'assessmentSection' | 'lessonSection' }}
   */
  const renderAccommodationsTableBodySummaryRowSection = ({ sectionKey } = {}) => {
    if (!config[sectionKey].show) {
      return null;
    }
    const toggleKeys = AccommodationsService.getConfigEnabledAccommodationToggleKeys({
      config, sectionKey
    });

    const rosterItems = accommodationsManager.currentAccommodationsRosterArray;

    const offAccommodationsForAll = AccommodationsService.getOffAccommodationsForAll({
      config, rosterItems, sectionKey
    });

    return toggleKeys.map((toggleKey, index) => {
      const additionalClassNames = getTableCellAdditionalClassNames(index);
      const isAccommodationOn = !offAccommodationsForAll.includes(toggleKey);
      return (
        <Table.Cell key={`all_${toggleKey}`}
          className={`accommodations-cell toggle ${sectionKey} ${toggleKey}${additionalClassNames}`}>
          {renderToggler({
            checked: isAccommodationOn,
            sectionKey,
            toggleKey
          })}
        </Table.Cell>
      );
    });
  };

  const renderTableBodyPopupCell = ({ cellKey, content } = {}) => {
    return (
      <Table.Cell className={`accommodations-cell popup-cell ${cellKey}`}>
        {PopupService.renderPopup({
          content,
          offset: [-11, 0],
          position: 'top left',
          trigger: content
        })}
      </Table.Cell>
    );
  };

  /**
   * @param {{ sectionKey: 'assessmentSection' | 'lessonSection' }}
   */
  const renderAccommodationsTableBodyRowSection = ({ rosterItem, sectionKey } = {}) => {
    return config[sectionKey].show && (
      Object.keys(config[sectionKey].toggles).filter((toggleKey) => {
        return config[sectionKey].toggles[toggleKey];
      }).map((toggleKey, index) => {
        const additionalClassNames = getTableCellAdditionalClassNames(index);
        const isAccommodationOn = !rosterItem.offAccommodations?.includes(
          ACCOMMODATION_TOGGLE_KEY[sectionKey][toggleKey]
        );
        return (
          <Table.Cell key={toggleKey}
            className={`accommodations-cell toggle ${sectionKey} ${toggleKey}${additionalClassNames}`}>
            {renderToggler({
              checked: isAccommodationOn,
              rosterItem,
              sectionKey,
              toggleKey
            })}
          </Table.Cell>
        );
      })
    );
  };

  const renderToggler = ({ checked, rosterItem, sectionKey, toggleKey } = {}) => {
    return (
      <div className={`accommodations-table-column-toggler ${toggleKey}`}>
        <div className='toggle-on-or-off-wrapper'>
          <Checkbox
            className='on-or-off-toggler'
            defaultChecked={checked}
            onChange={(event, { checked }) => handleToggleAccommodation(event, { checked }, {
              rosterItem, sectionKey, toggleKey
            })}
            toggle />
        </div>
      </div>
    );
  };
  return renderAccommodationsTable();
});
export default AccommodationsTable;

SatCoreRegister('AccommodationsTable', AccommodationsTable);
