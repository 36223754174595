import { action, makeObservable, observable } from 'mobx';
import Auth from './AuthManager';

export class LearnosityDataManager {
  @observable responseJSON = {};
  @observable questionData = [];
  
  constructor() {
    makeObservable(this);
  }

  @action
  setResponseJSON = (responseJSON) => {
    this.responseJSON = responseJSON;
  }

  @action
  setQuestionData = (questionData) => {
    this.questionData = questionData;
  }

  @action getLearnosityDataApiResponseScores = async (assignmentId) => {
    try {
      this.clearAll();

      const response = await Auth.fetch(`${Auth.ecms}/api/activity-instance-response-scores?activityInstanceId=${assignmentId}`, {
        method: 'GET'
      });

      if (response !== null) {
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  }

  @action getLearnosityDataApiActivityQuestions = async (contentItemId) => {
    try {
      this.clearAll();

      const response = await Auth.fetch(`${Auth.ecms}/api/activity-question-data?contentItemId=${contentItemId}`, {
        method: 'GET'
      });

      if (response !== null) {
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  }

  @action getLearnosityDataApiItemQuestions = async (itemRefIds) => {
    try {
      this.clearAll();

      if (Array.isArray(itemRefIds)) {
        itemRefIds = itemRefIds.join(',');
      }

      const response = await Auth.fetch(`${Auth.ecms}/api/item-question-data?itemReferences=${itemRefIds}`, {
        method: 'GET'
      });

      if (response !== null) {
        const questions = JSON.parse(response.questions);
        return questions;
      }
    } catch (error) {
      console.log(error);
    }
  }

  @action updateLearnosityDataApiResponseScores = async (activityInstanceId, responseIds, scores) => {
    try {
      if (Array.isArray(responseIds)) {
        responseIds = responseIds.join(',');
      }
      if (Array.isArray(scores)) {
        scores = scores.join(',');
      }
      const body = {
        responseIds,
        activityInstanceId,
        scores
      };

      const response = await Auth.fetch(`${Auth.ecms}/api/update-sessions-response-scores`, {
        method: 'POST',
        body
      });

      if (response !== null) {
        return response;
      }
    } catch (e) {
      console.log(e);
      // Setting the token in Cookies
      return false;
    }
  }

  clearAll = () => {
    this.responseJSON = {};
    this.questionData = [];
  }
}

export default new LearnosityDataManager();
