export const NOTIFICATION_ENTITY_TYPE = {
  REPORT_OUTPUT: 'report_output'
  // TODO unused
  // APPROVAL: 'approval',
  // APPROVED: 'approved',
  // ASSIGNMENT_EXPORT: 'assignment_export',
  // C2CMAP_EXPORT: 'c2cmap_export',
  // CLASSROOM_EXPIRATION: 'classroom_expiration',
  // CLASSROOM_EXPORT: 'classroom_export',
  // COLLABORATION: 'collaboration',
  // COMMENT: 'comment',
  // COURSE_EXPORT: 'course_export',
  // GENERAL: 'general',
  // GOOGLE_DRIVE_IMPORT: 'google_drive_import',
  // LIKE: 'like',
  // PROJECT_TASK: 'project_task',
  // REJECTED: 'rejected',
  // REMOVAL: 'removal',
  // REMOVED: 'removed',
  // SHARE: 'share'
};

export const NOTIFICATION_NAV_MAX_COUNT = 99;

export const NOTIFICATION_NAV_TYPE = {
  MANUAL: 'manualNotifications',
  SYSTEM: 'systemNotifications'
};
