import { flatMapDeep } from 'lodash';

/**
 * Flatten an array of objects with `children` arrays
 * @returns {[]} `any[]`
 */
export const flattenChildren = (data, propName = 'children') => {
  const getChildren = (data) => {
    if (!data || !data[propName] || !data[propName].length) {
      return data;
    } else {
      return [data, flatMapDeep(data[propName], getChildren)];
    }
  };
  return flatMapDeep(data, getChildren);
};

// TODO remove
// export const flattenChildren = (arr = [], propName = 'children') => (arr ? arr.reduce((result, item) => [
//   ...result,
//   { ...item },
//   ...flattenChildren(item[propName])
// ], []) : []);
