import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Container, Divider, Image, Loader, Popup, Segment
} from 'semantic-ui-react';

import iconAssign from '../img/icon-assign.svg';
import iconCourse from '../img/icon-course.svg';
import iconSettings from '../img/icon-class-settings.svg';
import iconRoster from '../img/icon-roster.svg';
import iconAccommodations from '../img/icon-accommodations.svg';
import iconGroups from '../img/icon-groups.svg';
import iconWorkspace from '../img/icon-workspace.svg';

import '../css/ClassCards.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { VIEW_SELECTION } from '../managers/NavigationManager';

import CourseService from '../services/CourseService';
import UtilityService from '../services/UtilityService';

@inject(
  'accommodationsManager', 'appManager', 'assignmentManager',
  'classroomManager', 'courseManager', 'dialogManager',
  'navigationManager', 'userManager'
)
@observer
class ClassCard extends Component {
  constructor(props) {
    super(props);
    this.ClassCardImage = SatCoreComponent('ClassCardImage');
    this.ClassroomTitle = SatCoreComponent('ClassroomTitle');
    this.InfoIcon = SatCoreComponent('InfoIcon');
  }

  popup = (classroom) => {
    const {
      classroomId, courseManager, userManager, t
    } = this.props;

    if (classroom.courseCount > 0) {
      return (
        <>
          <div className='card-label courses-label'>
            {t('coursesLabel', 'Courses')}
            :
          </div>
          <Popup
            hideOnScroll
            on='click'
            onOpen={() => {
              CourseService.fetchClassroomCourses(classroomId, userManager.isTeacher, userManager.isStudent);
            }}
            position='top right'
            trigger={(
              <div className='clickable courses'>
                <div className='card-list-text card-list-text-courses blue'>{classroom.courseCount}</div>
              </div>
            )}
            wide='very'>
            <>
              {(courseManager.getCourseList(classroomId).length === 0) ?
                <Loader active inline /> : null}

              {courseManager.getCourseList(classroomId).map((course, index) => {
                const courseNameObj = UtilityService.reactHtmlParserWrapper(course.name);
                return (
                  <div key={course.id + classroomId} className='popup-text-wrapper'>
                    <span className='card-list-text'>
                      {`${t('courseLabel', 'Course')} ${index + 1}: `}
                    </span>
                    <span
                      className='card-list-text blue clickable'
                      onClick={() => this.handleClickCourse(classroomId, course)}
                      title={courseNameObj.stripped}>
                      {courseNameObj.parsed}
                    </span>
                  </div>
                );
              })}
            </>
          </Popup>
        </>
      );
    }
    return (
      <>
        <div className='card-label courses-label'>
          {t('coursesLabel', 'Courses')}
          :
        </div>
        <div className='card-list-text card-list-text-courses-none'>{t('none')}</div>
      </>
    );
  }

  handleClick = async () => {
    const {
      classroomId, classroomManager, handleCardClick, navigationManager
    } = this.props;
    classroomManager.setCurrentClassroomId(classroomId);
    const classroom = classroomManager.getClassroom(classroomId);
    await navigationManager.clearAllPaths();
    handleCardClick(classroom.id);
  }

  handleClickCourse = async (classroomId, course) => {
    const { handleClickCourse } = this.props;
    await handleClickCourse(classroomId, course);
  }

  handleRosterClick = async () => {
    const {
      classroomId, classroomManager, navigationManager, handleNavigationClick
    } = this.props;
    classroomManager.setCurrentClassroomId(classroomId);
    const classroom = classroomManager.getClassroom(classroomId);
    await navigationManager.clearAllPaths();
    handleNavigationClick(`/roster?classroomId=${classroom.id}`, classroom.id);
  }

  handleAccommodationsClick = async () => {
    const {
      classroomId, classroomManager, navigationManager, handleNavigationClick
    } = this.props;
    classroomManager.setCurrentClassroomId(classroomId);
    const classroom = classroomManager.getClassroom(classroomId);
    await navigationManager.clearAllPaths();
    handleNavigationClick(`/accommodations?classroomId=${classroom.id}`, classroom.id);
  }

  handleGroupsClick = async () => {
    const {
      classroomId, classroomManager, navigationManager, handleNavigationClick
    } = this.props;
    classroomManager.setCurrentClassroomId(classroomId);
    const classroom = classroomManager.getClassroom(classroomId);
    await navigationManager.clearAllPaths();
    handleNavigationClick(`/groups?classroomId=${classroom.id}&view=${VIEW_SELECTION.GROUPS}`, classroom.id);
  }

  handleSettingsClick = async () => {
    const {
      classroomId, classroomManager, navigationManager, handleNavigationClick
    } = this.props;
    classroomManager.setCurrentClassroomId(classroomId);
    const classroom = classroomManager.getClassroom(classroomId);
    await navigationManager.clearAllPaths();
    handleNavigationClick(`/settings?classroomId=${classroom.id}`, classroom.id);
  }

  handleAssignmentClick = async (activitiesDueTodayCount) => {
    const {
      assignmentManager, classroomId, classroomManager, navigationManager, handleNavigationClick
    } = this.props;
    classroomManager.setCurrentClassroomId(classroomId);
    const classroom = classroomManager.getClassroom(classroomId);
    const now = new Date();
    const dateParam = assignmentManager.convertAssignmentDateToJavaString(now);
    await navigationManager.clearAllPaths();

    const path = (activitiesDueTodayCount !== null) ? `/assignments?classroomId=${classroom.id}&filterDate=${dateParam}&view=${VIEW_SELECTION.ASSIGNMENTS}` :
      `/assignments?classroomId=${classroom.id}&view=${VIEW_SELECTION.ASSIGNMENTS}`;
    handleNavigationClick(path, classroom.id);
  }

  handleWorkspaceClick = async () => {
    const { classroomId, handleNavigationClick } = this.props;
    handleNavigationClick(`/workspace?classroomId=${classroomId}&view=${VIEW_SELECTION.WORKSPACE}`, classroomId);
  }

  showContentAssignments = (classroomId, fetchAssignmentsToGrade = false, useAggregateCards = false) => {
    const { showContentAssignments } = this.props;
    showContentAssignments(classroomId, fetchAssignmentsToGrade, useAggregateCards);
  }

  render() {
    const {
      accommodationsManager, appManager, classroomId, classroomManager,
      defaultImage, isLeadTeacher, openImagePicker, t, userManager
    } = this.props;

    const { isSsoUser, isTeacher } = userManager;

    const classroom = classroomManager.getClassroom(classroomId);

    const { ClassCardImage, ClassroomTitle } = this;

    return (
      <Segment key={classroom.id} className='class-card-satcore list-card'>
        <ClassCardImage
          classroomId={classroomId}
          defaultImage={defaultImage}
          onClick={this.handleClick}
          openImagePicker={openImagePicker} />
        <div className='card-body'>
          <div className='card-info'>
            <Container className='class-card-header' fluid>
              <ClassroomTitle classroom={classroom} />
            </Container>
            <div className='class-card-info'>
              {this.popup(classroom)}
              <div className='card-label due-today-label'>
                {t('dueToday')}
              </div>
              {(classroom.activitiesDueTodayCount > 0) ? (
                <div className='card-list-text blue clickable due-today'
                  onClick={() => this.showContentAssignments(classroom.id, false, true)}>
                  {classroom.activitiesDueTodayCount}
                </div>
              ) : (
                <div className='card-list-text due-today'>
                  {t('none')}
                </div>
              )}
              <div className='card-label ready-grade-label'>
                {t('readyToGrade')}
              </div>
              {(classroom.readyToGradeCount > 0) ? (
                <div className='card-list-text blue clickable ready-grade'
                  onClick={() => this.showContentAssignments(classroom.id, true, true)}>
                  {classroom.readyToGradeCount}
                </div>
              ) : (
                <div className='card-list-text ready-grade'>
                  {t('none')}
                </div>
              )}
            </div>
          </div>
          <Divider />
          <div className='card-nav-buttons'>
            <div className='button'>
              <Image alt='' className='course-icon' src={iconCourse} />
              <div
                className='card-list-text blue clickable course'
                onClick={this.handleClick}>
                {t('course')}
              </div>
            </div>
            <div className='button assignment-icon'>
              <Image alt='' className='assignment-icon' src={iconAssign} />
              <div
                className='card-list-text blue clickable assignment'
                onClick={() => this.handleAssignmentClick(null)}>
                {t('assignments')}
              </div>
            </div>
            {(isTeacher) ? (
              <div className='button'>
                <Image alt='' className='roster-icon' src={iconRoster} />
                <div
                  className='card-list-text blue clickable roster'
                  onClick={this.handleRosterClick}>
                  {t('roster')}
                </div>
              </div>
            ) : null}
            {(isTeacher && isLeadTeacher) || (isSsoUser && isTeacher) ? (
              <div className='button'>
                <Image alt='' className='settings-icon' src={iconSettings} />
                <div
                  className='card-list-text blue clickable settings'
                  onClick={this.handleSettingsClick}>
                  {t('classSettings')}
                </div>
              </div>
            ) : null}
            {(isTeacher && isLeadTeacher && accommodationsManager.includeClassroomAccommodations) ? (
              <div className='button'>
                <Image alt='' className='accommodations-icon' src={iconAccommodations} />
                <div
                  className='card-list-text blue clickable settings'
                  onClick={this.handleAccommodationsClick}>
                  {t('accommodations')}
                </div>
              </div>
            ) : null}
            {(isTeacher && classroomManager.includeGroups) ? (
              <div className='button'>
                <Image alt='' className='groups-icon' src={iconGroups} />
                <div
                  className='card-list-text blue clickable settings'
                  onClick={this.handleGroupsClick}>
                  {t('groupsLabel', 'Groups')}
                </div>
              </div>
            ) : null}
            {isTeacher && appManager.classWorkspaceFeature && userManager.hasManageUploadedFilesPermission ? (
              <div className='button'>
                <Image alt='' className='workspace-icon' src={iconWorkspace} />
                <div
                  className='card-list-text blue clickable workspace'
                  onClick={this.handleWorkspaceClick}>
                  {t('workspace')}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Segment>
    );
  }
}

export default ClassCard;

SatCoreRegister('ClassCard', ClassCard);
