import assessmentManager from '../managers/AssessmentManager';
import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';
import courseManager from '../managers/CourseManager';
import dialogManager, { DIALOG_NAMES } from '../managers/DialogManager';
import tagContentManager from '../managers/TagContentManager';

export default class CustomCourseResourceService {
  static fetchUserCustomCourseList = async (returnImmediatelyAfterResponse = false, classroomId, page = 0, pageSize = 25, searchText = '') => {
    const includeBlankCard = false;
    const searchTerms = searchTerms || searchText;
    const searchTypes = 'course_resource';
    const searchScope = 'MINE';
    const skip = (page ? page - 1 : 0) * pageSize;
    await courseManager.searchContentItems(
      returnImmediatelyAfterResponse, classroomId, includeBlankCard,
      searchTerms, searchTypes, searchScope, page, pageSize, searchText
    );
  }

  /**
   * Fetch **ALL** available custom assessments to choose from,
   * then perform a check against `cachedAssessments`
   *
   * Assessments that already exist in the `cachedAssessments`
   * map are already part of the Custom Course the teacher is in.
   *
   * The rest are potential future choices that the teacher
   * can pick from, within the `AddCustomAssessmentModal`
   * */
  static fetchUserCustomAssessmentList = async (
    returnImmediatelyAfterResponse = false,
    classroomId,
    page = 0,
    pageSize = 25,
    searchText = ''
  ) => {
    const includeBlankCard = false;
    const searchTerms = searchTerms || searchText;
    const searchTypes = CONTENT_ITEM_TYPES.ASSESSMENT;
    const searchScope = 'MINE';
    // TODO DEMO-680 remove // const skip = (page ? page - 1 : 0) * pageSize;
    const response = await courseManager.searchContentItems(
      returnImmediatelyAfterResponse,
      classroomId,
      includeBlankCard,
      searchTerms,
      searchTypes,
      searchScope,
      page,
      pageSize,
      searchText
    );
    if (response && response.status === 'SUCCESS') {
      assessmentManager.clearPagedAssessments();
      if (searchText) {
        assessmentManager.clearAssessments();
      }
      const assessments = response.contentItems;
      assessments.map((assessment) => {
        const assessmentContentItemId = assessment.id;
        const isCachedAssessment = assessmentManager.cachedAssessments.has(assessmentContentItemId);
        if (isCachedAssessment) {
          assessment.selected = true;
          assessment.attached = true;
        } else {
          assessment.selected = false;
          assessment.attached = false;
        }
        assessmentManager.addAssessment(assessmentContentItemId, assessment);
      });
    }
  }

  static fetchCustomAssessmentItemsList = async (searchCondition, searchTerms, searchTags, searchStandards, page, pageSize) => {
    const searchTypes = 'learnosity_item_resource';
    await assessmentManager.fetchCustomAssessmentItemsList(searchCondition, searchTerms, searchTags, searchStandards, searchTypes, page,
      pageSize);
  }

  static fetchCourseResourcesList = async (searchCondition, searchTerms, searchTags, searchStandards, page, pageSize) => {
    const searchTypes = 'assessment,lesson,article_resource,audio_resource,bookmark_resource,file_resource,'
      + 'image_resource,video_resource,learnosity_activity_resource,google_doc_resource,google_sheet_resource,'
      + 'google_slide_resource,pdf_resource';

    await assessmentManager.fetchCustomAssessmentItemsList(searchCondition, searchTerms, searchTags, searchStandards, searchTypes, page,
      pageSize);
  }

  static createCustomCourse = async (classroomId, customCourseName, customCourseSubtitle, unitName) => {
    await courseManager.createCustomCourse(classroomId, customCourseName, customCourseSubtitle, unitName);
  }

  static createCustomAssessment = async (resourceName, resourceDescription,optionProfileId) => {
    const courseContentItemId = courseManager.currentCourseId;
    const parentCourseResourceElementId = courseManager.currentElementId;
    const resourceType = 'assessment';

    const orderNumber = 0;
    await courseManager.createCoursePlaceholderResource(courseContentItemId, parentCourseResourceElementId, resourceType, resourceName, resourceDescription, orderNumber, optionProfileId);
  }

  static async handleAssessmentEditor(courseElement) {
    await assessmentManager.fetchContentItem(courseElement.contentItemId);
    await assessmentManager.fetchResource(courseElement.contentItemId);

    const entityTypeIds = 'learnosity_item_resource';
    await this.fetchAvailableCategoriesWithTags(entityTypeIds, 'LEARNOSITY');

    dialogManager.setOpenDialog(DIALOG_NAMES.ASSESSMENT_EDITOR, {
      courseElement
    }, () => dialogManager.closeDialog(DIALOG_NAMES.ASSESSMENT_EDITOR));
  }

  static async handleLearnosityItemPreview(contentItem) {
    dialogManager.setOpenDialog(DIALOG_NAMES.LEARNOSITY_ITEM_PREVIEW, {
      contentItemId: contentItem.id
    }, () => dialogManager.closeDialog(DIALOG_NAMES.LEARNOSITY_ITEM_PREVIEW));
  }

  static addElementsToLearnosityActivity = async () => {
  //  assessmentManager.setAssessmentItemsLoading(true);
    const learnosityActivityContentItemId = assessmentManager.currentContentItem.id;
    const entityTypeId = 'content_item'; // always this if entityId is item.id
    const type = 'learnosity_item_resource';
    const courseResourceElementId = courseManager.currentElementId;
    const courseContentItemId = courseManager.currentCourseId;

    const entityIds = [];
    assessmentManager.cartedAssessmentItems.forEach(async (item) => {
      if (!item.attached) {
        entityIds.push(item.id);
      }
    });
    const entityIdsStr = entityIds.join();
    await assessmentManager.addItemsToLearnosityActivityWithAlignments(learnosityActivityContentItemId, entityIdsStr, entityTypeId, courseResourceElementId, courseContentItemId, null, type);
  //  assessmentManager.setAssessmentItemsLoading(false);
  }

  static deleteElementFromLearnosityActivity = async (contentItemId) => {
    const learnosityActivityContentItemId = assessmentManager.currentContentItem.id;
    await assessmentManager.deleteElementFromLearnosityActivity(learnosityActivityContentItemId, contentItemId);
  }

  static fetchAvailableCategoriesWithTags = async (entityTypeIds, satelliteComponent) => {
    await tagContentManager.fetchAvailableCategoriesWithTags(entityTypeIds, satelliteComponent);
  }
}
