/* eslint-disable sort-keys */
/* eslint-disable max-len */
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import ReactHtmlParser from 'react-html-parser';
import Auth from './AuthManager';
import UtilityService from '../services/UtilityService';

// TODO: this is Gallo only.
export const ITEM_TYPES = {
  MULTIPLE_CHOICE: 'Multiple Choice',
  MULTIPLE_SELECT: 'Multiple Select'
};

// eslint-disable-next-line max-len
export const BANK_ITEM_TYPES = 'article_resource,audio_resource,bookmark_resource,mathia_resource,google_doc_resource,google_sheet_resource,google_slide_resource,google_resource,file_resource,pdf_resource,image_resource,video_resource,assessment,learnosity_item_resource,learnosity_activity_resource,lesson,lesson_plan,course_resource,curriculum_map,rubric,microsite_resource,carnegie_resource,flipgrid,performance,custom_activity,cog_tutor_workspace,ready_check,ipa,ebook_activity,ereader,iculture,item_resource,Presentation,StudentInstruction,TeacherInstruction,WebPage,Multichoice,Multiselect,ShortText,LongText,Upload,Cloze,ClozeDragDrop,ClozeMultichoice,ClozeMath,Categorize,Griddable,ImageLabel,ImageLabelMultichoice,ImageLabelDragDrop,Draw,ChoiceMatrix,ChoiceMatrixMulti,TextSelector,TextHighlight,HotText,OrderList,OrderSentences,ClozeSpelling,ImageTextMatch,ImageTextMatchFlip,Analogies,BubbleWeb,CategoryTree,CauseAndEffect,CompareContrast,CornellNotes,CircleDefine,KWLChart,Relationships,Sequence,TChart,Venn,WordAssociation';

export class ItemBankManager {
questionTypeOptions =
  [
    { key: 'Categorize', value: 'Categorize', text: 'Classify' },
    { key: 'ClozeDragDrop', value: 'ClozeDragDrop', text: 'Fill In The Blank Drag-Drop' },
    { key: 'ClozeMath', value: 'ClozeMath', text: 'Fill In The Blank Math' },
    { key: 'ClozeMultichoice', value: 'ClozeMultichoice', text: 'Fill In The Blank Multi-Choice' },
    { key: 'Cloze', value: 'Cloze', text: 'Fill In The Blank Typing' },
    { key: 'Free-Draw', value: 'Draw', text: 'Free-Draw' },
    { key: 'LongText', value: 'LongText', text: 'Free Response' },
    { key: 'Griddable', value: 'Griddable', text: 'Griddable' },
    { key: 'TextHighlight', value: 'TextHighlight', text: 'Highlight Text' },
    { key: 'HotText', value: 'HotText', text: 'Hot Text' },
    { key: 'ImageHotSpot', value: 'ImageHotSpot', text: 'Image Hot Spot' },
    { key: 'ImageLabel', value: 'ImageLabel', text: 'Label Image Typing' },
    { key: 'ImageLabelMultichoice', value: 'ImageLabelMultichoice', text: 'Label Image Multiple Choice' },
    { key: 'ImageLabelDragDrop', value: 'ImageLabelDragDrop', text: 'Label Image Drag Drop' },
    { key: 'Multichoice', value: 'Multichoice', text: 'Multiple Choice' },
    { key: 'ChoiceMatrixMulti', value: 'ChoiceMatrixMulti', text: 'Multiple Choice Table' },
    { key: 'Multiselect', value: 'Multiselect', text: 'Multiple Select' },
    { key: 'NumberLine', value: 'NumberLine', text: 'Number Line' },
    { key: 'OrderList', value: 'OrderList', text: 'Order List' },
    { key: 'OrderSentences', value: 'OrderSentences', text: 'Order Sentences' },
    { key: 'TextSelector', value: 'TextSelector', text: 'Select Text' },
    { key: 'ShortText', value: 'ShortText', text: 'Short Response' },
    { key: 'ChoiceMatrix', value: 'ChoiceMatrix', text: 'True - False' },
    { key: 'Upload', value: 'Upload', text: 'Upload Files' },
    { key: 'MultipartActivity', value: 'MultipartActivity', text: 'Multipart Activity' },
    { key: 'VideoQuestion', value: 'VideoQuestion', text: 'Video Activity' },
    { key: 'AudioQuestion', value: 'AudioQuestion', text: 'Audio Activity' },
    { key: 'BookletActivity', value: 'BookletActivity', text: 'Book Activity' },
    { key: 'BookmarkActivity', value: 'BookmarkActivity', text: 'Bookmark Activity' },
    { key: 'ArticleQuestion', value: 'ArticleQuestion', text: 'Passage Activity' },
    { key: 'ImageQuestion', value: 'ImageQuestion', text: 'Image Activity' },
    { key: 'StudentInstruction', value: 'StudentInstruction', text: 'Student Instructions' },
    { key: 'TeacherInstruction', value: 'TeacherInstruction', text: 'Teacher Instructions' }
    // { key: 'TChart', value: 'TChart', text: 'T Chart'}
    // { key: 'ClozeSpelling', value: 'ClozeSpelling', text: 'Spelling' },
    // { key: 'ImageTextMatch', value: 'ImageTextMatch', text: 'Image Text Match' },
    // { key: 'ImageTextMatchFlip', value: 'ImageTextMatchFlip', text: 'Image Text Flip' }
  ];

  @observable itemBanksLoading = false;
  @observable activePage = 0;
  @observable totalPages = 0;
  @observable totalResults = 0;
  @observable itemBankItems = [];

  @observable searchQuestionTypes = [];
  //@observable searchOperator = 'OR';

  // presently not controlled
  @observable tagSearchOperator = 'AND';
  @observable standardSearchOperator = 'OR';

  @observable searchTerms = '';
  @observable searchTags = [];
  @observable searchStandards = [];
  @observable searchTextTimeout = null;

  @observable bookmarkStandards = [];

  @action setQuestionTypeOptions(optionsArray) {
    this.questionTypeOptions = optionsArray;
  }

  @action setSearchTextTimeout = (searchTextTimeout) => {
    this.searchTextTimeout = searchTextTimeout;
  }

  @action setSearchQuestionTypes = async (val) => {
    this.searchQuestionTypes = val;
  }

  // @action setSearchOperator = async (val) => {
  //   this.searchOperator = val;
  // }

  @action setTagSearchOperator = async (val) => {
    this.searchTagOperator = val;
  }

  @action setStandardSearchOperator = async (val) => {
    this.searchStandardOperator = val;
  }

  @action setSearchTerms = async (val) => {
    this.searchTerms = val;
  }

  @action setSearchTags = async (val) => {
    const merged = [].concat(...val);
    this.searchTags = merged;
  }

  @action setSearchStandards = async (val) => {
    this.searchStandards = val;
  }

  @action setBookmarkStandards = async (val) => {
    this.bookmarkStandards = val;
  }

  @action resetPaginator = () => {
    this.activePage = 0;
    this.totalPages = 0;
    this.totalResults = 0;
  }

  @action setActivePage = (pageNum) => {
    this.activePage = pageNum;
  }

  @action setContentItemsLoading = (toggle) => {
    this.itemBanksLoading = toggle;
  }

  @action setItemBankItems = (itemBankItems) => {
    this.itemBankItems = itemBankItems;
  }

  @action searchContentItemsForItemBank = async (
    id = null,
    searchQuestionTypes,
    page = 0,
    pageSize = 25,
    sortColumn = 'modificationDate',
    sortDirection = 'desc') => {
    try {
      this.setContentItemsLoading(true);
      const itemBankItems = [];
      const apiUrlParams = `?pageSize=${pageSize}`;
      const skip = !pageSize ? 0 : (page ? page - 1 : 0) * pageSize;
      // apiUrlParams += `&skip=${skip}`;
      // if (sortColumn) {
      //  apiUrlParams += `&sort[0][field]=${sortColumn}`;
      //  apiUrlParams += `&sort[0][dir]=${sortDirection}`;
      // }

      const questionTypeCriteria = (this.searchQuestionTypes && this.searchQuestionTypes.length > 0) ? this.searchQuestionTypes.join(',') : '';
      const tagCriteria = (this.searchTags && this.searchTags.length > 0) ? this.searchTags.join(',') : '';
      const searchTermCriteria = (this.searchTerms && this.searchTerms.length > 0) ? this.searchTerms : '';
      const searchStandardsCriteria = (this.searchStandards && this.searchStandards.length > 0) ? this.searchStandards.join(',') : '';
      //const searchOperatorCriteria = (this.searchOperator && this.searchOperator.length > 0) ? this.searchOperator : 'OR';
      const tagSearchOperatorCriteria = (this.tagSearchOperator && this.tagSearchOperator.length > 0) ? this.tagSearchOperator : 'AND';
      const standardSearchOperatorCriteria = (this.standardSearchOperator && this.standardSearchOperator.length > 0) ? this.standardSearchOperator : 'OR';


      const response = await Auth.fetch(`${Auth.ecms}/api/searchContentItemsByItemBank`, {
        method: 'POST',
        body: {
          workspaceId: id,
          searchTerms: searchTermCriteria,
          searchTags: tagCriteria,
          searchStandards: searchStandardsCriteria,
          searchTypes: 'item_resource',
          searchSubTypes: questionTypeCriteria,
          schools: '',
          tagSearchCondition: tagSearchOperatorCriteria,
          standardSearchCondition: standardSearchOperatorCriteria,
          searchScope: 'LICENSE',
          includeBlankCard: false,
          take: 25,
          skip,
          page,
          pageSize: 25
        }
      });
      if (response && response.status === 'SUCCESS') {
        const { contentItems } = response;
        for (const contentItem of contentItems) {
          itemBankItems.push({
            id: contentItem.id,
            backgroundImg: contentItem.fullImageUrl || contentItem.imageUrl,
            cardClassName: 'item-bank',
            libraryResource: contentItem
          });

          // itemBankItems.push({ contentItem });
        }
        this.totalPages = Math.ceil(+response.pageTotal / pageSize);
        this.totalResults = response.pageTotal;
        this.setActivePage(page);
        this.setContentItemsLoading(false);
        this.setItemBankItems(itemBankItems);
        console.log(this.itemBankItems.length);
      }
      this.setContentItemsLoading(false);
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  getArrayFromMap(map) {
    if (map) {
      return Array.from(toJS(map).values());
    }
    return [];
  }

  getQuestionTypeText(id) {
    for (const item of this.questionTypeOptions) {
      if (id === item.key) {
        return item.text;
      }
    }
  }
}

export default new ItemBankManager();
