import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

// import { register } from '../../../i18n';

import EngagementService from '../../../services/EngagementService';

const EngagementRowAuxiliaryLanguageToolUsageCells = observer((props) => {
  const { engagementData } = props;

  const { viewRawTextToSpeechCount, viewRawTranslateCount } = engagementData;

  const { /* managers */ } = useContext(MobXProviderContext);

  // const t = register('EngagementTable');

  const renderEngagementRowAuxiliaryLanguageToolUsageCells = () => {
    return (
      <>
        {EngagementService.allowEngagementColumn('viewTextToSpeechAvailable') &&
          renderEngagementRowAuxiliaryLanguageToolUsageCell({
            allowPopupComponent: true,
            className: 'engagement-body-label language-tool-usage-detail-body engagedTextToSpeechUsage',
            popupContent: '',
            popupDisabled: true,
            popupTrigger: viewRawTextToSpeechCount || 0
          })}
        {EngagementService.allowEngagementColumn('viewTranslateAvailable') &&
          renderEngagementRowAuxiliaryLanguageToolUsageCell({
            allowPopupComponent: true,
            className: 'engagement-body-label language-tool-usage-detail-body engagedTranslateUsage',
            popupContent: '',
            popupDisabled: true,
            popupTrigger: viewRawTranslateCount || 0
          })}
      </>
    );
  };

  const renderEngagementRowAuxiliaryLanguageToolUsageCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementRowAuxiliaryLanguageToolUsageCells();
});
export default EngagementRowAuxiliaryLanguageToolUsageCells;

SatCoreRegister('EngagementRowAuxiliaryLanguageToolUsageCells', EngagementRowAuxiliaryLanguageToolUsageCells);
