/**
 * get the value of a CSS variable for the current active satellite
 * ```
 * // example
 * getStyleVar('--theme-font-size') // '14px'
 * ```
 */
export const getStyleVar = (styleVariable = '') => {
  return getComputedStyle(document.documentElement)
    ?.getPropertyValue(styleVariable)?.trim();
};

export const setStyleVar = (styleVariable = '', value = '') => {
  document.documentElement.style.setProperty(styleVariable, value);
};

/**
 * Example usage:
 *
 * ```
 * const cssUrlValue = "url('img/demo/demo-fav-icon.ico')";
 * getCssUrlArgument(cssUrlValue); // returns "img/demo/demo-fav-icon.ico";
 * ```
 */
export const getCssUrlArgument = (cssUrlValue = '') => {
  if (!cssUrlValue || typeof cssUrlValue !== 'string') {
    return '';
  }
  const pattern = /'([^']*)'/;
  return cssUrlValue.match(pattern)?.[1] || '';
};

export const isWhiteStyleColor = (str) => {
  return typeof str === 'string' && (
    str.startsWith('white') ||
    str.startsWith('#fff') ||
    str.includes('255, 255, 255')
  );
};
