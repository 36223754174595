import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';

import { capitalize } from 'lodash';
import UtilityService from '../../../services/UtilityService';

import { SatCoreComponent, SatCoreRegister } from '../../../SatCoreRegistry';

import { stripHtmlTags } from '../../../utils';

import ImageService from '../../../services/ImageService';
import PopupService from '../../../services/PopupService';
import ReportTableService from '../../../services/reports/ReportTableService';

const ReportResourceTitleCell = observer((props) => {
  const { row, value } = props;

  const {
    reportIdentityManager, reportOverrideManager
  } = useContext(MobXProviderContext);

  const Badge = SatCoreComponent('Badge');
  const ReportStandardCard = SatCoreComponent('ReportStandardCard');

  const { isCourseReport, reportInfoClassNames } = reportIdentityManager;
  const { allowUseDefaultImageSource } = reportOverrideManager;

  const rawTitle = value?.name;
  const rawDescription = value?.description;
  const { elementType, type } = row.original;

  let strippedRowTitle, strippedRowDescription;
  if (rawTitle) {
    // remove <p> tags wrapping the title if they exist
    const unwrappedTitle = UtilityService.reactHtmlParserWrapper(rawTitle).parsedNoPWrap;

    // convert character entities if there are any
    const transformFn = UtilityService.reactHtmlParserTransform;
    strippedRowTitle = ReactHtmlParser(unwrappedTitle, { transform: transformFn });

    if ((row.canExpand || isCourseReport) && !strippedRowTitle) {
      strippedRowTitle = capitalize(elementType || type || '');
    }
  } else if (row.canExpand || isCourseReport) {
    strippedRowTitle = capitalize(elementType || type);
  }
  if (isCourseReport && reportOverrideManager.shouldAppendCourseElementContentTypeAndElementDescriptionToReportTableRowTitle) {
    strippedRowTitle = ReportTableService.appendCourseElementContentTypeAndElementDescriptionToReportTableRowTitle(row, strippedRowTitle);
  }
  if (rawDescription) {
    strippedRowDescription = stripHtmlTags(rawDescription);
  }

  let contentClassName = 'cell-resource-title-content';
  contentClassName += ReportTableService.getExpandableRowClassNameIfApplicable(elementType, props);

  const titleWithDescriptionJsx = (
    <>
      {(strippedRowTitle && strippedRowDescription) ? <strong>{strippedRowTitle}&nbsp;</strong> : ''}
      {(strippedRowTitle && !strippedRowDescription) ? <strong>{strippedRowTitle}</strong> : ''}
      {strippedRowDescription}
    </>
  );

  let badgeName;
  const { readinessAndSupporting } = props.row.original;
  if (typeof readinessAndSupporting === 'string') {
    badgeName = readinessAndSupporting;
  } else {
    badgeName = readinessAndSupporting?.name;
  }

  const content = !badgeName ? (
    <span className={contentClassName}>
      {titleWithDescriptionJsx}
    </span>
  ) : (
    <ReportStandardCard
      {...props}
      standard={props.row.original}
      style={{ padding: '5px 0 0' }} />
  );

  let triggerClassName = 'cell-resource-title-trigger';
  const expandableRowClassName = ReportTableService.getExpandableRowClassNameIfApplicable(elementType, props);
  triggerClassName += expandableRowClassName;

  let defaultImgClassName = '';
  let imageSource = ImageService.getImageUrl(props.row.original);
  if (!imageSource && isCourseReport && allowUseDefaultImageSource) {
    imageSource = ImageService.getDefaultReportImageSource(props.row);
    defaultImgClassName = `default-image${expandableRowClassName}`;
  }

  const trigger = (
    <span className={triggerClassName}>
      <Badge
        additionalClassNames={defaultImgClassName}
        badgeImageSource={imageSource}
        badgeName={badgeName} />
      <span className='cell-resource-title-text'>
        {titleWithDescriptionJsx}
      </span>
    </span>
  );

  return PopupService.renderPopup({
    className: `cell-resource-title-popup ${reportInfoClassNames}`,
    content,
    offset: [-10, 0],
    position: 'top left',
    trigger
  });
});
export default ReportResourceTitleCell;

SatCoreRegister('ReportResourceTitleCell', ReportResourceTitleCell);
