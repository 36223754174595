import { MAX_REPORT_FACULTY_SCORE_CELLS_PER_PAGE } from './ReportConstants';

import classroomManager from '../../managers/ClassroomManager';
import courseManager from '../../managers/CourseManager';
import reportStandardsClassroomDetailStudentsManager from '../../managers/reports/ReportStandardsClassroomDetailStudentsManager';
import reportStandardsManager from '../../managers/reports/ReportStandardsManager';

import ImageService from '../ImageService';
import ReportJsonHelperService from './ReportJsonHelperService';
import ReportIdentityService from './ReportIdentityService';

export default class ReportStandardsClassroomDetailStudentsService {
  static getApplicableUrlParamValues = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const classroomId = urlParams.get('classroomId');
    const institutionName = urlParams.get('institutionName');
    const standardElementId = urlParams.get('standardElementId');
    const truncatedStandardDescription = urlParams.get('truncatedStandardDescription');
    return {
      classroomId,
      institutionName,
      standardElementId,
      truncatedStandardDescription
    };
  }

  static initReportCourseElements = ({
    async = true,
    returnOnly = false,
    standardElementId = null
  } = {}) => {
    const reportCourseElements = this._initReportCourseElements({
      returnOnly, standardElementId
    });
    return async ? (async () => reportCourseElements)() : reportCourseElements;
  };

  static _initReportCourseElements = ({
    returnOnly = false,
    standardElementId = null
  } = {}) => {
    courseManager.clearAll();
    courseManager.setAllowFetchCourseData(false);
    courseManager.setAllowCourseTreeLeafFooter(true);

    // get `alignments` array
    const urlParams = new URLSearchParams(window.location.search);
    standardElementId = standardElementId || urlParams.get('standardElementId');
    const REPORT_STANDARD_TO_ALIGNMENT_KEYS_WITH_DATA = ReportJsonHelperService.REPORT_STANDARD_TO_ALIGNMENT_KEYS_WITH_DATA();
    const alignments = REPORT_STANDARD_TO_ALIGNMENT_KEYS_WITH_DATA[standardElementId] || [];

    // loop thru `alignments` array. get its `reportCourseElement`
    const allReportCourseElements = ReportJsonHelperService.REPORT_STANDARDS_COURSE_ELEMENTS();

    const { selectedReportCmapObj } = reportStandardsManager;

    const reportCourseElementsWithStandardAlignments = [];
    for (const alignment of alignments) {
      if (selectedReportCmapObj?.id && selectedReportCmapObj.id !== alignment.curriculumMapId) {
        // eslint-disable-next-line no-continue
        continue;
      }
      const reportCourseElement = allReportCourseElements.find((element) => {
        return alignment.elementId === element.elementId;
      });
      reportCourseElementsWithStandardAlignments.push({ ...reportCourseElement, alignment });
    }
    if (!returnOnly) {
      reportStandardsClassroomDetailStudentsManager.setReportCourseElementsWithStandardAlignments(
        reportCourseElementsWithStandardAlignments
      );
    }
    return reportCourseElementsWithStandardAlignments;
  }

  static initReportCourseElementForModal = async (reportCourseElementWithStandardAlignment = {}) => {
    const { currentClassroomId } = classroomManager;
    const { courseContentItemId, courseId, elementId } = reportCourseElementWithStandardAlignment;

    const fullCourseElement = await courseManager.fetchSingularCourseElementForClassroom(
      elementId, courseContentItemId, currentClassroomId
    );
    const key = courseId + currentClassroomId + elementId;
    courseManager.addTree(key, [{
      ...reportCourseElementWithStandardAlignment, ...fullCourseElement
    }]); // store in mobx for CourseElementModal

    // TODO remove
    // reportStandardsClassroomDetailStudentsManager.setCurrentReportStandardsFullCourseElement(fullCourseElement);
  }

  static getPaginatedReportCourseElements = (reportCourseElements = [], activePage) => {
    const PAGE_SIZE = MAX_REPORT_FACULTY_SCORE_CELLS_PER_PAGE;
    const start = (activePage - 1) * PAGE_SIZE;
    const end = activePage * PAGE_SIZE;

    const paginatedReportCourseElements = reportCourseElements.slice(start, end);
    return paginatedReportCourseElements;
  }

  /** totalPages */
  static getTotalReportCourseElementPages = ({ reportCourseElementsWithStandardAlignments } = {}) => {
    reportCourseElementsWithStandardAlignments = reportCourseElementsWithStandardAlignments ||
      reportStandardsClassroomDetailStudentsManager.reportCourseElementsWithStandardAlignments;
    const reportCourseElementCount = reportCourseElementsWithStandardAlignments.length;
    const PAGE_SIZE = MAX_REPORT_FACULTY_SCORE_CELLS_PER_PAGE;
    return Math.ceil(reportCourseElementCount / PAGE_SIZE) || 1;
  }

  static initReportCourseElementContentImageSources = async (reportCourseElements = []) => {
    if (!Array.isArray(reportCourseElements)) {
      console.error('initReportCourseElementContentImageSources: `reportCourseElements` must be an array');
      return;
    }
    reportCourseElements.forEach(async (reportCourseElement) => {
      const imageUrl = ImageService.getImageUrl(reportCourseElement);
      const reportCourseElementId = reportCourseElement.elementId;
      const { addToReportStandardsClassroomDetailContentImageSourceMap } = reportStandardsClassroomDetailStudentsManager;
      if (imageUrl) {
        addToReportStandardsClassroomDetailContentImageSourceMap(reportCourseElementId, {
          imageSource: imageUrl,
          imageSourceClassName: 'report-standards-course-element-img'
        });
      } else {
        const { defaultImageSource, className } = ImageService.getDefaultImageSourceAndClassName();
        addToReportStandardsClassroomDetailContentImageSourceMap(reportCourseElementId, {
          imageSource: defaultImageSource,
          imageSourceClassName: className
        });
      }
    });
  }

  static getReportItemLevelStudentScoreCount = (student, standardId) => {
    const allScoreInfo = ReportJsonHelperService.REPORT_SCORE_INFO_BY_STANDARD_FOR_STUDENTS();
    if (!allScoreInfo[standardId]) {
      return 0;
    }
    const { studentId } = student;
    const scoreInfo = allScoreInfo[standardId][studentId];

    if (!scoreInfo) {
      return 0;
    }
    return (typeof scoreInfo.taken === 'number') ? scoreInfo.taken : 0;
  }

  static getStudentScoresForTableColumns = (student, reportCourseElements = [], activePage = null) => {
    const studentScoresForTableColumns = [];
    let studentScoreSum = 0, studentScoreCount = 0;

    for (const reportCourseElement of reportCourseElements) {
      const { alignment } = reportCourseElement;
      if (alignment?.studentScoreDetails || alignment?.studentScores) {
        const studentScoreDetails = alignment?.studentScoreDetails && alignment.studentScoreDetails[student.studentId];
        let studentScore = studentScoreDetails?.grade || studentScoreDetails?.itemScore;
        if (typeof studentScore !== 'number') {
          studentScore = alignment?.studentScores[student.studentId];
        }
        studentScore = typeof studentScore === 'number' ? Math.round(studentScore) : undefined;
        studentScoresForTableColumns.push(studentScore);
        if (typeof studentScore === 'number') {
          studentScoreSum += studentScore;
          studentScoreCount++;
        }
      } else {
        studentScoresForTableColumns.push(undefined);
      }
    }

    let studentScoreAvg;
    // only include `studentScoreAvg` if we have all the data and `studentScoreCount > 0`
    // note: `activePage` implies pagination, so only include `studentScoreAvg` if `activePage` is not a number
    const isUsingPagination = typeof activePage === 'number';
    if (!isUsingPagination && studentScoreCount) {
      studentScoreAvg = Math.round(studentScoreSum / studentScoreCount);
    }
    return {
      resourceLevelStudentScoreCount: studentScoreCount,
      studentScoreAvg,
      studentScoresForTableColumns
    };
  }

  static cleanupAfterLeaving = () => {
    const isReportStandardsClassroomDetailStudents = ReportIdentityService.isReportStandardsClassroomDetailStudents();
    if (!isReportStandardsClassroomDetailStudents) {
      courseManager.clearAll();
      courseManager.setAllowFetchCourseData(true);
      courseManager.setAllowCourseTreeLeafFooter(false);

      reportStandardsClassroomDetailStudentsManager.setCurrentStandard(null);
      // TODO remove // reportStandardsClassroomDetailStudentsManager.setCurrentReportStandardsFullCourseElement(null);
      reportStandardsClassroomDetailStudentsManager.clearActiveReportStandardsClassroomDetailStudentsTablePage();
    }
  }
}
