import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { Image, Table } from 'semantic-ui-react';

import '../../../css/EngagementTable.less';

import iconInfo from '../../../img/icon-info.svg';

import { register } from '../../../i18n';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import EngagementService from '../../../services/EngagementService';
import PopupService from '../../../services/PopupService';

const EngagementHeaderCoreCells = observer((props) => {
  const { /* managers */ } = useContext(MobXProviderContext);

  const allowEngagementFlagColumn = EngagementService.allowEngagementFlagColumn();

  const t = register('EngagementTable');

  const renderEngagementHeaderCoreCells = () => {
    return (
      <>
        {renderEngagementHeaderCoreCell({
          cellText: t('activityNumber'),
          className: 'engagement-core-cell engagement-activity-number-cell'
        })}
        {renderEngagementHeaderCoreCell({
          cellText: t('firstName'),
          className: 'engagement-core-cell engagement-first-name-cell'
        })}
        {renderEngagementHeaderCoreCell({
          cellText: t('lastName'),
          className: 'engagement-core-cell engagement-last-name-cell'
        })}
        {allowEngagementFlagColumn && renderEngagementHeaderCoreCell({
          cellText: t('flag'),
          className: 'engagement-core-cell engagement-flag-cell'
        })}
      </>
    );
  }

  const renderEngagementHeaderCoreCell = ({
    allowPopupComponent = false,
    className,
    cellText,
    popupContent = t('TBD', 'TBD'),
    popupOn = 'click',
    popupPosition = 'top center',
    popupTrigger = <Image alt='' className='info-icon' spaced src={iconInfo} />,
    popupWide = ''
  } = {}) => {
    return (
      <Table.HeaderCell className={className}>
        <div>
          {cellText}
          {allowPopupComponent && PopupService.renderPopup({
            content: popupContent,
            on: popupOn,
            position: popupPosition,
            trigger: popupTrigger,
            wide: popupWide,
          })}
        </div>
      </Table.HeaderCell>
    );
  }
  return renderEngagementHeaderCoreCells();
});
export default EngagementHeaderCoreCells;

SatCoreRegister('EngagementHeaderCoreCells', EngagementHeaderCoreCells);
