import { action, makeObservable, observable } from 'mobx';

export class TopNavManager {
  @observable activeTopNavName = undefined;

  @observable showFullNotificationBanner = false;

  @observable additionalAdminTopNavExternalResources = [];
  @observable additionalTeacherTopNavExternalResources = [];
  @observable additionalStudentTopNavExternalResources = [];

  @observable isTopNavClassMenuOpen = false;
  @observable isTopNavProfileMenuOpen = false;

  constructor() {
    makeObservable(this);
  }

  @action clearAll = () => {
    this.activeTopNavName = undefined;
    this.isTopNavClassMenuOpen = false;
    this.isTopNavProfileMenuOpen = false;
  }

  @action setActiveTopNavName = (topNavName) => {
    this.activeTopNavName = topNavName;
  }

  @action setIsTopNavClassMenuOpen = (isTopNavClassMenuOpen) => {
    this.isTopNavClassMenuOpen = isTopNavClassMenuOpen;
  }

  @action setIsTopNavProfileMenuOpen = (isTopNavProfileMenuOpen) => {
    this.isTopNavProfileMenuOpen = isTopNavProfileMenuOpen;
  }

  @action setShowFullNotificationBanner = (showBanner) => {
    this.showFullNotificationBanner = showBanner;
  };

  /**
   * Set additional external resources for the top navigation bar based on the user type.
   *
   * @param {'admin' | 'teacher' | 'student'} userRole - `'admin' | 'teacher' | 'student'`
   * @param {{
   *   _id: string,
   *   url: string,
   *   className?: string,
   *   navTranslationKey?: string,
   *   navTranslationRolloverKey?: string,
   *   viewSelectionKey?: string
   * }[]} resources - An array of resource objects to add to the top navigation.
   *
   * This data will be used for the **TopNav** `getAdditionalTopNavExternalResources()` methods.
   *
   * Note, via `getAdditionalTopNavExternalResources()`:
   *
   * * `className` will default to `'resources' + _id` if not provided.
   * * `navTranslationKey` will default to `_id` if not provided.
   * * `navTranslationRolloverKey` will default to `undefined` if not provided.
   * * `viewSelectionKey` will default to `'resources'` if not provided.
   *
   * @example
   * setAdditionalTopNavExternalResources('admin', [
   *   { _id: 'documentation', url: 'https://docs.example.com' },
   *   { _id: 'frequentlyAskedQuestions', url: 'https://faq.example.com', navTranslationKey: 'faqLink' }
   * ]);
   */
  @action setAdditionalTopNavExternalResources(userRole, resources) {
    switch (userRole) {
      case 'admin':
        this.additionalAdminTopNavExternalResources = resources;
        break;
      case 'teacher':
        this.additionalTeacherTopNavExternalResources = resources;
        break;
      case 'student':
        this.additionalStudentTopNavExternalResources = resources;
        break;
    }
  }
}

export default new TopNavManager();
