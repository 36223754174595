import React from 'react';

import { Popup } from 'semantic-ui-react';

import { registerClass } from '../SatCoreRegistry';

export default class PopupService {
  static renderPopup = ({
    className = '',
    content,
    disabled = false,
    header = null,
    key = undefined,
    mouseEnterDelay = 200,
    mouseLeaveDelay = 0,
    offset = null,
    on = 'hover',
    onClose = null,
    onOpen = null,
    open,
    pinned,
    position = 'top center',
    trigger = null,
    wide = 'very'
  } = {}) => {
    return (
      <Popup key={key}
        className={className || 'custom-popup'}
        content={content}
        disabled={disabled}
        header={header}
        hoverable={on === 'hover'}
        mouseEnterDelay={mouseEnterDelay}
        mouseLeaveDelay={mouseLeaveDelay}
        offset={offset}
        on={on}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        pinned={pinned}
        position={position}
        positionFixed
        trigger={<span className='popup-trigger-wrapper'>{(trigger || typeof trigger === 'number') ? trigger : content}</span>}
        wide={wide === 'very' ? 'very' : undefined} />
    );
  }
}

registerClass('PopupService', PopupService);
