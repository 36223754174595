import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { inject, observer } from 'mobx-react';
import { Popup } from 'semantic-ui-react';
import { SatCoreRegister } from '../SatCoreRegistry';
import '../css/StandardsList.less';
import { DIALOG_NAMES } from '../managers/DialogManager';
import { STANDARD_TRANSLATE } from '../managers/AssignmentManager';
import UtilityService from '../services/UtilityService';

import { stripHtmlTags } from '../utils';

export default
@inject('assignmentManager', 'dialogManager', 'userManager')
@observer
class StandardsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addInfoOpen: false,
      infoMessage: '',
      showErrorDialog: false
    };
  }

  handleOpenInfo = () => {
    this.setState({ addInfoOpen: true });
  }

  closeInfoModal = () => {
    this.setState({ addInfoOpen: false });
  }

  handleViewStandards = async () => {
    const { dialogManager, contentImageUrl, standards, title } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.STANDARDS, {
      contentItemDescription: null,
      resourceInfo: null,
      studentInstruction: this.props.instruction,
      addInfoOpen: this.state.addInfoOpen,
      closeInfoModal: this.closeInfoModal,
      imageUrl: contentImageUrl,
      infoName: title,
      standards
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.STANDARDS));
  }

  render() {
    const { standards, assignmentManager, assignmentId, userManager } = this.props;
    const transformFn = UtilityService.reactHtmlParserTransform;

    if (!assignmentManager.includeAlignments) {
      return ('');
    }
    let standardsList = [];
    let remaining = 0;

    if (Array.isArray(standards) && !(userManager.isStudent && assignmentManager.alwaysExcludeStudentAssignmentCardAlignmentPills)) {
      const MAX_PILLS = 6;
      const displayList = standards.filter((standard) => !!standard?.name)?.slice?.(0, MAX_PILLS) || [];
      remaining = standards.length - MAX_PILLS;

      standardsList = displayList.map((standard, index) => {
        const name = STANDARD_TRANSLATE.convertStandardName(standard.name);
        let pillClassname = 'name-pill';
        const isPrereq = standard.curriculumElementType === 'CONTENT' && standard.curriculumElementName.toLowerCase().startsWith('prereq');
        if (isPrereq) {
          pillClassname += ' prereq-pill';
        }
        const isCore = standard.curriculumElementType === 'CONTENT' && standard.curriculumElementName.toLowerCase().startsWith('core');
        if (isCore) {
          pillClassname += ' core-pill';
        }
        const isEnrichment = standard.curriculumElementType === 'CONTENT' && standard.curriculumElementName.toLowerCase().startsWith('enrichment');
        if (isEnrichment) {
          pillClassname += ' enrichment-pill';
        }

        // hide 'On Level' pills
        const isOnlevel = standard.curriculumElementType === 'CONTENT'
          && standard.curriculumElementName.toLowerCase().startsWith('on')
          && standard.curriculumElementName.toLowerCase().includes('level');
        if (isOnlevel) {
          pillClassname = 'hide';
        }

        const popupContentForAccessibility = `${stripHtmlTags(name)}: ${stripHtmlTags(standard.description)}`;

        return (
          <Popup
            key={`${assignmentId}-${index}`}
            content={ReactHtmlParser(standard.description)}
            on={['hover', 'focus']}
            trigger={(
              <button
                aria-label={popupContentForAccessibility}
                className={pillClassname}
                onClick={() => this.handleViewStandards()}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.handleViewStandards();
                  }
                }}
                tabIndex={0}
                type='button'>
                {ReactHtmlParser(name, {
                  transform: transformFn
                })}
              </button>
            )} />
        );
      });
    }

    return (
      <div className='standards-list-container'>
        {standardsList}
        {(remaining > 0) ? (
          <div className='clickable blue'
            onClick={() => this.handleViewStandards()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.handleViewStandards();
              }
            }}
            tabIndex={0}>
            {' '}
            +more
          </div>
        ) : ''}
      </div>
    );
  }
}

SatCoreRegister('StandardsList', StandardsList);
