import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import { register } from '../../../i18n';
import EngagementService from '../../../services/EngagementService';

const EngagementHeaderAuxiliaryAnnotationsUsageCells = observer((props) => {
  const { /* managers */ } = useContext(MobXProviderContext);

  const t = register('EngagementTable');

  const renderEngagementHeaderAuxiliaryAnnotationsUsageCells = () => {
    return (
      <>
        {EngagementService.allowEngagementColumn('viewHatAvailable') &&
          renderEngagementHeaderAuxiliaryAnnotationsCell({
            allowPopupComponent: true,
            cellText: t('engagedHighlightUsage'),
            className: 'engagement-header-label detail-annotations-usage-header engagedHighlightUsage',
            isHeaderCell: true,
            popupContent: t('engagedHighlightUsageDescription'),
            popupOn: 'click'
          })}
        {EngagementService.allowEngagementColumn('viewEliminatorAvailable') &&
          renderEngagementHeaderAuxiliaryAnnotationsCell({
            allowPopupComponent: true,
            cellText: t('engagedStrikethroughUsage'),
            className: 'engagement-header-label detail-annotations-usage-header engagedStrikethroughUsage',
            isHeaderCell: true,
            popupContent: t('engagedStrikethroughUsageDescription'),
            popupOn: 'click'
          })}
        {EngagementService.allowEngagementColumn('viewStickyNotesAvailable') &&
          renderEngagementHeaderAuxiliaryAnnotationsCell({
            allowPopupComponent: true,
            cellText: t('engagedStickyNotesUsage'),
            className: 'engagement-header-label detail-annotations-usage-header engagedStickyNotesUsage',
            isHeaderCell: true,
            popupContent: t('engagedStickyNotesUsageDescription'),
            popupOn: 'click'
          })}
        {EngagementService.allowEngagementColumn('viewBookmarkAvailable') &&
          renderEngagementHeaderAuxiliaryAnnotationsCell({
            allowPopupComponent: true,
            cellText: t('engagedMarkForReviewUsage'),
            className: 'engagement-header-label detail-annotations-usage-header engagedMarkForReviewUsage',
            isHeaderCell: true,
            popupContent: t('engagedMarkForReviewUsageDescription'),
            popupOn: 'click'
          })}
      </>
    );
  };

  const renderEngagementHeaderAuxiliaryAnnotationsCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementHeaderAuxiliaryAnnotationsUsageCells();
});
export default EngagementHeaderAuxiliaryAnnotationsUsageCells;

SatCoreRegister('EngagementHeaderAuxiliaryAnnotationsUsageCells', EngagementHeaderAuxiliaryAnnotationsUsageCells);
