import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Dropdown } from 'semantic-ui-react';

import '../css/ProductDropdown.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import ClassroomService from '../services/ClassroomService';
import ProductService from '../services/ProductService';

const ProductDropdown = observer((props = {}) => {
  const { allowSelectAllInstitutions = true, t } = props;

  const { productManager } = useContext(MobXProviderContext);

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  useEffect(() => {
    (async () => {
      if (productManager.shouldFetchLicensedProducts) {
        productManager.setLoadingProductDropdown(true);
        await ProductService.fetchLicensedProducts({
          classroomId: !productManager.FROM_TEACHER_PRODUCTS_NAV ? ClassroomService.getCurrentClassroomId() : null
        });
        ProductService.initSelectedUserProductId();
        productManager.setShouldFetchLicensedProducts(false);
        productManager.setLoadingProductDropdown(false);
      } else {
        ProductService.initSelectedUserProductId();
      }
    })();
  }, []);

  const handleChangeProductDropdown = (event, data) => {
    productManager.setSelectedUserProductId(data.value);
    if (props.onChange) {
      props.onChange(event, data);
    }
  };

  const renderProductDropdown = () => {
    const options = [...productManager.productDropdownOptions];
    if (allowSelectAllInstitutions) {
      options.unshift({ text: t('allProducts'), value: 'allProducts' });
      if (!productManager.selectedUserProductId) {
        productManager.setSelectedUserProductId('allProducts');
      }
    }
    return (
      <div className='product-dropdown'>
        <Dropdown
          disabled={productManager.loadingProductDropdown}
          onChange={handleChangeProductDropdown}
          options={options}
          placeholder={productManager.loadingProductDropdown ? t('loading') : t('selectProduct')}
          selectOnNavigation={false}
          selection
          value={productManager.selectedUserProductId} />
      </div>
    );
  };
  return renderProductDropdown();
});
export default ProductDropdown;

SatCoreRegister('ProductDropdown', ProductDropdown);
