import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/EditAssignmentModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { ASSIGNMENT_STATUS } from '../../managers/AssignmentManager';

/** TODO possibly remove; `TimeAdjustModal` appears to be unused */
class TimeAdjustModal extends Component {
  constructor(props) {
    super(props);
    this.ModalBanner = SatCoreComponent('ModalBanner');
  }

  render() {
    const { open, close } = this.props;
    const { ModalBanner } = this;
    return (
      <Modal
        onClose={close}
        open={open}
        size='tiny'>
        <ModalBanner
          label='Not Started Status Warning: '
          onClose={close}
          title='Dates have been adjusted.' />
        <Modal.Content>
          {(this.props.status === ASSIGNMENT_STATUS.LOCKED) ? (
            <>
              <p>
                Assignments in the
                {' '}
                <b>Locked</b>
                {' '}
                state must have a start date in the future because the
                Scheduling system will unlock all assignments with a start date in the past.
              </p>
              <p>
                You must ensure your start and due dates are in the future or this assignment will automatically flip to
                <b>Not Started</b>
              </p>
              <p>
                Please manually adjust these dates
                <b>before you save!</b>
              </p>
            </>
          )
            : (
              <>
                <p>
                  Assignments in the
                  {' '}
                  <b>Not Started</b>
                  {' '}
                  or
                  {' '}
                  <b>Started</b>
                  {' '}
                  state must have a start date
                  in the past and a due date in the future.
                </p>
                <p>
                  Because you are changing the status to
                  <b>Not Started</b>
                  {' '}
                  or
                  <b>Started</b>
                  , we have adjusted the start date and/or due date.
                </p>
                <p>Please manually adjust these dates further if needed.</p>
              </>
            )}
        </Modal.Content>
        <Modal.Actions>
          <Button content='OK' onClick={close} primary />
        </Modal.Actions>
      </Modal>
    );
  }
}
export default TimeAdjustModal;

SatCoreRegister('TimeAdjustModal', TimeAdjustModal);
