export const focusTrap = (element, event) => {
  const focusableElements = [...element.querySelectorAll(
    'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
  )].filter((el) => !el.hasAttribute('disabled'));
  if (event.key === 'Tab' && document.activeElement === focusableElements[event.shiftKey ? 0 : focusableElements.length - 1]) {
    focusableElements[event.shiftKey ? focusableElements.length - 1 : 0].focus();
    event.preventDefault();
  }
};

/** Unfocus the current `activeElement` being focused on. */
export const unfocus = () => {
  document?.activeElement?.blur?.();
};
