import { action, makeObservable, observable } from 'mobx';

import Auth from './AuthManager';

import { stripHtmlTags } from '../utils';

export const CURRICULUM_MAP_ENDPOINTS = {
  GET_FULL_CMAP: '/api/viewFullCurriculumMapJson',
  VIEW_CLASSROOM_CURRICULUM_MAPS: '/api/viewClassroomCurriculumMaps',
  VIEW_COURSE_RESOURCE_CURRICULUM_MAPS: '/api/viewCourseResourceCurriculumMaps',
};

export class CurriculumMapManager {
  @observable curriculumMaps = [];
  @observable curriculumMapJson = [];
  @observable expanded = [];

  constructor() {
    makeObservable(this);
  }

  @action clearAll = () => {
    this.clearExpanded();
    this.setCurriculumMapJson([]);
    this.setCurriculumMaps([]);
  }

  @action setCurriculumMaps(curriculumMaps) {
    this.curriculumMaps = curriculumMaps;
  }

  @action setCurriculumMapJson = (json) => {
    this.curriculumMapJson = json;
  }

  @action addExpanded = (id) => {
    this.expanded.push(id);
  }

  @action clearExpanded = () => {
    this.expanded = [];
  }

  buildCurriculumMapJson = (elemInput, cmapName, showParentCheckboxes) => {
    let index = 1;
    if (!elemInput || elemInput.length < 0) return [];

    return elemInput.map((obj) => {
      const cmapElementId = obj.id; // curriculumMapElement id is unique but the entity id is not so use this for the node value
      // const standardId = obj.entityId || CryptoMD5(obj).toString() || null;
      let name = '';
      // get dropdown label `name` for standards AssignmentItemFilter
      if (obj.displayName || obj.name) {
        // if obj.displayName or obj.name, use that
        name = obj.displayName || obj.name;
      } else if (cmapName) {
        // else if cmapName, use that
        name = cmapName;
      } else /* if (obj.type === 'UNIT') */ {
        // else use default 'Unit' label
        // (edge case, will likely never happen)
        name = obj.unitName || 'Unit';
        name = `${name} ${index}`;
        index++;
      }
      name = stripHtmlTags(name);
      // if the node is a leaf node, show a checkbox for it
      let showCheckbox = (obj.CurriculumMapElements.length === 0);

      // if all the children are Leaf nodes, show a checkbox on this node too
      if (showParentCheckboxes && obj.CurriculumMapElements.length > 0) {
        let allLeafNodes = true;
        obj.CurriculumMapElements.forEach((child) => {
          if (child.CurriculumMapElements.length > 0) {
            allLeafNodes = false;
          }
        });
        showCheckbox = allLeafNodes;
      }

      if (showCheckbox) {
        const desc = stripHtmlTags(obj.decodedDescription);
        name = `${name} ${desc}`;
      }

      const cmapElement = {
        label: name,
        showCheckbox,
        // standardId,
        value: cmapElementId
      };
      if (obj.CurriculumMapElements.length > 0) {
        this.addExpanded(cmapElementId);
        cmapElement.children = this.buildCurriculumMapJson(obj.CurriculumMapElements, '', showParentCheckboxes);
      }
      // add a mapping by curriculumElementId so we can swap out the standard id later
      // this.addCmapStandardsMapItem(cmapElementId, cmapElement);
      return cmapElement;
    });
  }

  @action fetchClassroomCurriculumMaps = async (classroomId) => {
    const { VIEW_CLASSROOM_CURRICULUM_MAPS } = CURRICULUM_MAP_ENDPOINTS;

    try {
      const response = await Auth.fetch(`${Auth.ecms}${VIEW_CLASSROOM_CURRICULUM_MAPS}?classroomId=${classroomId}`, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        this.setCurriculumMaps(response.data);
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action fetchCourseResourceCurriculumMaps = async (courseResourceId) => {
    const { VIEW_COURSE_RESOURCE_CURRICULUM_MAPS } = CURRICULUM_MAP_ENDPOINTS;

    try {
      const response = await Auth.fetch(`${Auth.ecms}${VIEW_COURSE_RESOURCE_CURRICULUM_MAPS}?courseResourceId=${courseResourceId}`, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        this.setCurriculumMaps(response.data);
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  fetchCurriculumMap = async (cmapContentItemId, showParentCheckboxes = false) => {
    if (!cmapContentItemId || cmapContentItemId.length < 1) {
      return false;
    }

    let response = null;
    try {
      const apiUrlParams = `?cmapContentItemId=${cmapContentItemId}`;
      const apiUrl = `${Auth.ecms}${CURRICULUM_MAP_ENDPOINTS.GET_FULL_CMAP}${apiUrlParams}`;
      response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
    } catch (error) {
      console.error(error);
    }

    if (response && response.jsonString) {
      const jsonObj = JSON.parse(response.jsonString);
      const startObj = jsonObj.CurriculumMapElements[1];
      if (startObj) {
        const formattedJson = this.buildCurriculumMapJson(startObj.CurriculumMapElements, jsonObj.name, showParentCheckboxes);
        this.setCurriculumMapJson(formattedJson);
        return true;
      }
    }
    return false;
  }
}

export default new CurriculumMapManager();
