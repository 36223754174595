import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Dropdown } from 'semantic-ui-react';

import classNames from 'classnames';

import '../css/CustomDropdown.less';

import { SatCoreRegister } from '../SatCoreRegistry';

const CustomDropdown = observer((props = {}) => {
  const {
    className,
    clearable,
    dropdownLabel,
    loading,
    onOpen,
    options,
    placeholder,
    search,
    selection,
    selectedDropdownValue
  } = props;

  const { /* managers */ } = useContext(MobXProviderContext);

  const handleChangeCustomDropdown = (event, data) => {
    if (props.onChange) {
      props.onChange(event, data);
    }
  };

  const renderCustomDropdown = () => {
    return (
      <div className={classNames('CustomDropdown', className)}>
        {!!dropdownLabel && (
          <div className='dropdown-label'>
            <div className='text'>
              {dropdownLabel}
            </div>
          </div>
        )}
        <Dropdown
          className={classNames({
            'has-dropdown-label': dropdownLabel
          })}
          clearable={clearable}
          loading={loading}
          onChange={handleChangeCustomDropdown}
          onOpen={onOpen}
          options={options}
          placeholder={placeholder}
          search={search}
          selection={selection}
          value={selectedDropdownValue} />
      </div>
    );
  };
  return renderCustomDropdown();
});
export default CustomDropdown;

SatCoreRegister('CustomDropdown', CustomDropdown);
