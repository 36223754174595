export const ACCOMMODATION_TOGGLE_KEY = {
  assessmentSection: {
    basicCalculatorToggle: 'ASM_BASIC_CALC',
    contentLanguageToggle: 'ASM_CONTENT_LANG',
    dictionaryToggle: 'ASM_DICTIONARY',
    graphingCalculatorToggle: 'ASM_GRAPH_CALC',
    scientificCalculatorToggle: 'ASM_SCI_CALC',
    spellcheckerToggle: 'ASM_SPELLCHECKER',
    textToSpeechToggle: 'ASM_TEXT_SPEECH',
    translationToggle: 'ASM_TRANSLATION'
  },
  lessonSection: {
    basicCalculatorToggle: 'LSN_BASIC_CALC',
    // contentLanguageToggle: 'LSN_CONTENT_LANG',
    dictionaryToggle: 'LSN_DICTIONARY',
    graphingCalculatorToggle: 'LSN_GRAPH_CALC',
    scientificCalculatorToggle: 'LSN_SCI_CALC',
    spellcheckerToggle: 'LSN_SPELLCHECKER',
    textToSpeechToggle: 'LSN_TEXT_SPEECH',
    translationToggle: 'LSN_TRANSLATION'
  }
};
