/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { inject, observer } from 'mobx-react';
import { Image, Popup } from 'semantic-ui-react';
import { SatCoreRegister } from '../SatCoreRegistry';
import '../css/SimplePillList.less';
import { DIALOG_NAMES } from '../managers/DialogManager';
import UtilityService from '../services/UtilityService';
import iconTag from '../img/icon-tag.svg';

export default
@inject('assignmentManager', 'courseManager', 'dialogManager')
@observer
class SimplePillList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addInfoOpen: false,
      infoMessage: '',
      showErrorDialog: false
    };
  }

  handleOpenInfo = () => {
    this.setState({ addInfoOpen: true });
  }

  closeInfoModal = () => {
    this.setState({ addInfoOpen: false });
  }

  handleViewTagsStandards = async () => {
    const { dialogManager, assessmentItem } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.TAGS_STANDARDS, {
      addInfoOpen: this.state.addInfoOpen,
      closeInfoModal: this.closeInfoModal,
      assessmentItem
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.TAGS_STANDARDS));
  }

  render() {
    const { pills, isFullList } = this.props;
    if (pills === null || pills === undefined || pills.length < 1) {
      return ('');
    }

    if (isFullList) {
      return this.renderFull(pills);
    }
    return this.renderTruncated(pills);
  }

  renderTruncated(pills) {
    const { handleShowItemDetails, libraryCardData, pillClass } = this.props;
    const MAX_PILLS = 4;
    let truncatePills = false;
    if (pills.length > MAX_PILLS) {
      truncatePills = true;
    }

    const transformFn = UtilityService.reactHtmlParserTransform;
    let categoryTagsList = [];

    if (pills !== null && pills.length > 0) {
      if (truncatePills) {
        pills = pills.slice(0, 3);
      }

      categoryTagsList = pills.map((pill, index) => {
        if (pill && pill.name !== undefined && pill.name !== null && pill.visible) {
          const defaultPillClass = (pillClass) || 'default-tag';
          const pillClassName = pill.sortClass || defaultPillClass;
          const { name } = pill;
          const fullName = pill.fullStatement || pill.name;
          const classNameValue = `tag-category-pill ${pillClassName}`;

          return (
            <Popup
              key={`${pill.id}-${index}-pu`}
              content={ReactHtmlParser(fullName)}
              on='click'
              trigger={(
                <div key={`${pill.id}-${index}-ptrunc`} className={classNameValue}>
                  {ReactHtmlParser(name, {
                    transform: transformFn
                  })}
                </div>
              )} />
          );
        }
      });
    }

    return (
      <div className='simple-pill-list-container'>
        {/* <Image alt='' className='tag-image' src={iconTag} /> */}
        {categoryTagsList}
        {(truncatePills) ? (
          <div className='clickable blue' onClick={() => handleShowItemDetails(libraryCardData)}>
            {' '}
            +More
          </div>
        ) : ''}
      </div>
    );
  }

  renderFull(pills) {
    const transformFn = UtilityService.reactHtmlParserTransform;
    let categoryTagsList = [];

    if (pills) {
      categoryTagsList = pills.map((pill, index) => {
        if (pill && pill.name) {
          const pillClass = (pill.sortClass) ? pill.sortClass : 'default-tag';
          const { name } = pill;
          const fullName = pill.fullStatement || pill.name;
          const classNameValue = `tag-category-pill ${pillClass}`;
          return (
            <Popup
              key={`${pill.id}-${index}-pu`}
              content={ReactHtmlParser(fullName)}
              on='click'
              trigger={(
                <div key={`${pill.id}-${index}-pfull`} className={classNameValue}>
                  {ReactHtmlParser(name, {
                    transform: transformFn
                  })}
                </div>
)} />
          );
        }
      });
    }

    return (
      <div className='simple-pill-list-container'>
        {categoryTagsList}
      </div>
    );
  }
}

SatCoreRegister('SimplePillList', SimplePillList);
