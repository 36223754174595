import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../css/reports/ReportStandardCard.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { stripHtmlTags } from '../../utils';

const ReportStandardCard = observer((props) => {
  const { additionalClassNames, standard, style, t } = props;

  const { /* managers */ } = useContext(MobXProviderContext);

  const Badge = SatCoreComponent('Badge');

  if (!standard) {
    return null;
  }
  const renderReportStandardCardHeader = () => {
    let badgeJsx;
    const { readinessAndSupporting } = standard;
    if (readinessAndSupporting) {
      const badgeName = typeof readinessAndSupporting === 'string' ? readinessAndSupporting : readinessAndSupporting.name;
      badgeJsx = (
        <>
          <Badge badgeName={badgeName} />
          <span className={`badge-header-label ${badgeName}`}>
            {badgeName}
          </span>
        </>
      );
    }
    let weightJsx;
    if (standard.weight) {
      weightJsx = (
        <>
          <span className='weight-header-label'>
            {t('weight')}&nbsp;
          </span>
          <span>{`${+(standard.weight.toFixed(2))}%`}</span>
        </>
      );
    }
    return (
      <div className='report-standard-card-header'>
        <div className='badge-and-weight'>
          {badgeJsx}
          {badgeJsx && weightJsx &&
            <span className='divider'>|</span>}
          {weightJsx}
          {standard.type === 'BREAKOUTS' && (
            <div className='breakout'>
              {t('breakout')}
            </div>
          )}
        </div>
      </div>
    );
  };

  const strippedStandardName = stripHtmlTags(standard.name);
  const strippedStandardDescription = stripHtmlTags(standard.description);
  const renderReportStandardCardBody = () => (
    <div className='report-standard-card-body'>
      {!!strippedStandardName && (
        <strong>
          {strippedStandardName}&nbsp;
        </strong>
      )}
      {!!strippedStandardDescription && (
        <>
          {strippedStandardDescription}
        </>
      )}
    </div>
  );

  let reportStandardCardClassName = 'report-standard-card';
  reportStandardCardClassName += additionalClassNames ? ` ${additionalClassNames}` : '';

  return (
    <div
      className={reportStandardCardClassName}
      style={style}>
      {renderReportStandardCardHeader()}
      {renderReportStandardCardBody()}
    </div>
  );
});
export default ReportStandardCard;

SatCoreRegister('ReportStandardCard', ReportStandardCard);
