import { action, computed } from 'mobx';

import {
  getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem
} from '../../utils';

export class ReportScoreManager {
  // TODO uncomment when we have observables in this manager
  // constructor() {
  //   makeObservable(this);
  // }

  // TODO remove
  // @observable hideContentWithoutScoresObservable = this.shouldHideContentWithoutScores;

  @action clearAll = () => {
    /* placeholder */
  }

  @action setShouldHideContentWithoutScores = (toggle) => {
    if (toggle) {
      setSessionStorageItem('shouldHideContentWithoutScores', 'true');
    } else {
      removeSessionStorageItem('shouldHideContentWithoutScores');
    }
  }

  @computed get shouldHideContentWithoutScores() {
    return getSessionStorageItem('shouldHideContentWithoutScores');
  }
}

export default new ReportScoreManager();
