import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Button, Image } from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/StandardsDialog.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { STANDARD_TRANSLATE } from '../../managers/AssignmentManager';

import UtilityService from '../../services/UtilityService';

export default class StandardsModal extends Component {
  renderStandards() {
    const { standards } = this.props;
    const transformFn = UtilityService.reactHtmlParserTransform;
    const standardsList = standards.map((standard, index) => {
      const name = STANDARD_TRANSLATE.convertStandardName(standard.name);
      let pillClassname = 'name-pill';
      const isPrereq = standard.curriculumElementType === 'CONTENT' && standard.curriculumElementName.toLowerCase().startsWith('prereq');
      if (isPrereq) {
        pillClassname += 'prereq-pill';
      }
      const isCore = standard.curriculumElementType === 'CONTENT' && standard.curriculumElementName.toLowerCase().startsWith('core');
      if (isCore) {
        pillClassname += ' core-pill';
      }
      const isEnrichment = standard.curriculumElementType === 'CONTENT' && standard.curriculumElementName.toLowerCase().startsWith('enrichment');
      if (isEnrichment) {
        pillClassname += ' enrichment-pill';
      }

      // hide 'On Level' pills
      const isOnlevel = standard.curriculumElementType === 'CONTENT'
        && standard.curriculumElementName.toLowerCase().startsWith('on')
        && standard.curriculumElementName.toLowerCase().includes('level');
      if (isOnlevel) {
        return null;
      }

      return (
        <div key={`${index}-${name}`} id={`${index}-${name}`}>
          <div className={pillClassname}>
            {ReactHtmlParser(name, {
              transform: transformFn
            })}
          </div>
          <div className='desc-text'>
            {ReactHtmlParser(standard.description, {
              transform: transformFn
            })}
          </div>
        </div>
      );
    });

    return (
      <div className='standards-container'>
        {standardsList}
      </div>
    );
  }

  render() {
    const {
      addInfoOpen,
      infoName,
      closeInfoModal,
      imageUrl
    } = this.props;
    const title = infoName;

    const ModalBanner = SatCoreComponent('ModalBanner');

    return (
      <Modal
        className='standards-dialog'
        closeOnDimmerClick={true}
        closeOnEscape={true}
        onClose={this.closeInfoModal}
        open={addInfoOpen}>
        <ModalBanner
          label='Standards: '
          onClose={() => { closeInfoModal(); }}
          title={title} />
        <Modal.Content image>
          {(imageUrl) ? <Image src={imageUrl} wrapped /> : <div className='ui image' />}
          {this.renderStandards()}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => { closeInfoModal(); }} primary>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('StandardsModal', StandardsModal);
