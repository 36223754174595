import React from 'react';
import { SatCoreRegister } from '../SatCoreRegistry';

import { getStyleVar } from '../utils';

function ProgressBar({ progress, total, width, height }) {
  const remaining = total - progress;
  const template = `${progress}fr ${remaining}fr`;

  width = (width) || 191;
  height = (height) || getStyleVar('--student-progress-widget-course-bar-height');

  return (
    <div style={{
      display: 'inline-grid',
      backgroundColor: getStyleVar('--student-progress-widget-course-bar-inactive-background-color'),
      borderRadius: getStyleVar('--student-progress-widget-course-bar-border-radius'),
      gridTemplateColumns: template,
      width,
      height
    }}>
      <div style={{
        backgroundColor: getStyleVar('--student-progress-widget-course-bar-active-background-color'),
        borderRadius: getStyleVar('--student-progress-widget-course-bar-border-radius')
      }} />
      <div style={{
        backgroundColor: getStyleVar('--student-progress-widget-course-bar-inactive-background-color'),
        borderRadius: '0px 5px 5px 0px'
      }} />
    </div>
  );
}

export default ProgressBar;
SatCoreRegister('ProgressBar', ProgressBar);
