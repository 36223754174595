import React from 'react';

import '../css/CardTabLabel.less';

/**
 * @param {string} props.name
 * @param {number} props.largeCharSize - if `props.name.length` is equal or greater, larger tab width will be used. `(default: 32)`
 */
export default function CardTabLabel(props) {
  const DEFAULT_LARGE_CHAR_SIZE = 32;
  const { additionalLabelTextClassNames } = props;
  let { largeCharSize, name } = props;
  name = typeof name === 'string' ? name : '[Unnamed Class]';
  largeCharSize = typeof largeCharSize === 'number' ? largeCharSize : DEFAULT_LARGE_CHAR_SIZE;
  const tabSizeClassName = name.length <= largeCharSize ? 'card-tab-label-small' : 'card-tab-label-large';
  let labelTextClassNames = 'card-tab-label-text';
  if (additionalLabelTextClassNames) {
    labelTextClassNames += ` ${additionalLabelTextClassNames}`;
  }
  return (
    <div className={`card-tab-label-wrapper ${tabSizeClassName}`}>
      <div className={labelTextClassNames} title={name}>
        {name}
      </div>
    </div>
  );
}
