/* eslint-disable sort-keys */
import React from 'react';

import defaultSchool from '../../img/default-school.svg';

import { getInitials } from '../../utils';

import reportIdentityManager from '../../managers/reports/ReportIdentityManager';
import reportTableManager from '../../managers/reports/ReportTableManager';
import schoolManager from '../../managers/SchoolManager';

import PopupService from '../PopupService';
import ReportJsonHelperService from './ReportJsonHelperService';

import Avatar from '../../components/Avatar';

export default class ReportStandardsDistrictService {
  /** Intended to be called via `ReportTypeService.initReportTableFacultyCellConfigArray` */
  static _initReportTableFacultyCellConfigArray = (initProps) => {
    const { handleViewNextReport, renderFacultyResultCell } = initProps;
    const { reportInfoClassNames } = reportIdentityManager;

    const facultyCells = ReportJsonHelperService.REPORT_SCHOOLS().map((school) => {
      const { schoolId } = school;
      return {
        Cell: (props) => {
          return renderFacultyResultCell({ ...initProps, ...props, school });
        },
        Header: (props) => {
          return this.renderDistrictHeaderScoreCell({
            ...initProps,
            ...props,
            handleViewNextReport,
            renderFacultyResultCell,
            school
          });
        },
        accessor: (props) => {
          const standardId = props.id;
          const allScoreInfo = ReportJsonHelperService.REPORT_SCORE_INFO_BY_STANDARD_FOR_STUDENTS();
          const scoreInfoObj = allScoreInfo[standardId] && allScoreInfo[standardId][schoolId];

          if (typeof scoreInfoObj === 'object' && !('averageViewedTime' in scoreInfoObj)) {
            scoreInfoObj['averageViewedTime'] = scoreInfoObj['viewedTime'];
          }

          return scoreInfoObj/* && scoreInfoObj[activeReportScorePropName] */;
        },
        className: `cell-faculty-result ${reportInfoClassNames}`,
        id: schoolId,
        originalData: school
      };
    });
    reportTableManager.setReportTableFacultyCellsConfig(facultyCells);
    return facultyCells;
  };

  static renderDistrictHeaderScoreCell = (props) => {
    const { school } = props;

    const { reportInfoClassNames } = reportIdentityManager;

    let imageUrl = (school.fullImageUrl || school.imageUrl);
    imageUrl = schoolManager.createSchoolImageUrl(imageUrl);

    const popupLabel = PopupService.renderPopup({
      className: `${reportInfoClassNames} report-table-header-popup`,
      content: (
        <div className={`header-cell-faculty-result-content ${reportInfoClassNames}`}>
          {school.schoolName}
        </div>
      ),
      offset: [10, 0],
      on: 'hover',
      pinned: true,
      position: 'bottom right',
      trigger: getInitials(school.schoolName)
    });

    const urlParams = new URLSearchParams(window.location.search);
    const institutionName = urlParams.get('institutionName');
    return (
      <div className={`faculty-avatar-wrapper ${reportInfoClassNames}`}
        onClick={() => props.handleViewNextReport({
          institutionId: school.schoolId || null,
          institutionName
        })}>
        <Avatar className='faculty-avatar' defaultImage={defaultSchool} fullProfileImageUrl={imageUrl} />
        <div className='faculty-avatar-label'>
          {popupLabel}
        </div>
      </div>
    );
  }
}
