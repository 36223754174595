import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Container, Dropdown } from 'semantic-ui-react';

import '../css/CourseListBanner.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { DIALOG_NAMES } from '../managers/DialogManager';

import CourseService from '../services/CourseService';
import UserService from '../services/UserService';
import UtilityService from '../services/UtilityService';
import ClassroomService from '../services/ClassroomService';
import { renderButton } from '../utils';

@inject(
  'assignmentManager', 'classroomManager', 'courseManager',
  'dialogManager', 'productManager', 'studentProgressManager',
  'tagContentManager', 'teacherProductViewManager', 'userManager'
)
@observer
class CourseListBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingCourseSearch: false
    };

    this.BreadCrumbs = SatCoreComponent('BreadCrumbs');
    this.BreadCrumbsCourseDropdown = SatCoreComponent('BreadCrumbsCourseDropdown');
    this.TeacherProductListener = SatCoreComponent('TeacherProductListener');

    this.contentTypeOptions = [
      { key: 'all-content', text: 'All Content', value: 'all-content' },
      { key: 'ExperTrack', text: 'ExperTrack Only', value: 'ExperTrack' }
    ];

    const { userManager } = this.props;
    if (userManager.institutionState) {
      const userState = userManager.institutionState.toLocaleLowerCase();
      if (userState === 'georgia') {
        this.contentTypeOptions.push({ key: 'Clickbook', text: 'Clickbook Only', value: 'Clickbook' });
      }
    }
  }

  handleCourseSearch = async () => {
    const { dialogManager, history, tagContentManager } = this.props;

    const { state } = this;
    if (state.loadingCourseSearch) {
      return;
    }

    this.setState({ loadingCourseSearch: true });
    // await CustomCourseResourceService.handleAssessmentEditor(courseElement);
    const entityTypeIds = 'assessment,lesson,article_resource,audio_resource,bookmark_resource,file_resource,'
      + 'image_resource,video_resource,learnosity_activity_resource,google_doc_resource,google_sheet_resource,'
      + 'google_slide_resource,pdf_resource';
    await tagContentManager.fetchAvailableCategoriesWithTags(entityTypeIds, 'COURSE');
    this.setState({ loadingCourseSearch: false });

    dialogManager.setOpenDialog(DIALOG_NAMES.RESOURCE_ITEM_SHOP, {
      courseResourcesSearch: true,
      history
    }, (fromSave) => this.handleCloseCourseSearch(fromSave));
  }

  handleCloseCourseSearch = async (/* fromSave */) => {
    const { dialogManager, resetClassDetails } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.RESOURCE_ITEM_SHOP);

    if (resetClassDetails) {
      const urlParams = new URLSearchParams(window.location.search);
      const courseId = urlParams.get('courseId');
      const courseElementId = urlParams.get('elementId');
      resetClassDetails({
        courseElementId,
        courseId
      });
    }
  }

  handleAddCourse = async () => {
    const { handleAddCourse, history } = this.props;
    await handleAddCourse ? handleAddCourse() : CourseService.handleAddCourse(history);
  }

  handleAddCourseToClass = async () => {
    const { courseManager, teacherProductViewManager } = this.props;
    const course = courseManager.getCurrentCourse('FROM_TEACHER_PRODUCTS_NAV') || {
      id: courseManager.currentCourseId
    };
    teacherProductViewManager.setCurrentTeacherProductCourse(course);
    teacherProductViewManager.setIsClassroomSelectionModalOpen(true);
  }

  handleAddCustomAssessment = async () => {
    const { history } = this.props;
    await CourseService.handleAddCustomAssessment(history);
  }

  handleRegisterStudentModal = () => {
    const { dialogManager, classroomManager, currentAccessCodeValue } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.REGISTER_STUDENT, {
      accessCode: currentAccessCodeValue,
      currentClassroomId: classroomManager.currentClassroomId
    }, this.closeRegisterStudent);
  }

  closeRegisterStudent = () => {
    const { dialogManager, classroomManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.REGISTER_STUDENT);
    classroomManager.fetchClassroomRoster(classroomManager.currentClassroomId);
  }

  handleCreateGroupModal = () => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.ADD_EDIT_GROUP, {}, this.closeCreateGroupModal);
  }

  closeCreateGroupModal = (_event, didSave) => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.ADD_EDIT_GROUP);
    // Don't reload if we didn't save anything
    if (didSave) {
      window.location.reload(false);
    }
  }

  handleAddStudentsToClassroomModal = () => {
    const { dialogManager, classroomManager } = this.props;
    const classroom = classroomManager.getClassroom(classroomManager.currentClassroomId);
    dialogManager.setOpenDialog(DIALOG_NAMES.ADD_STUDENTS_TO_CLASSROOM, {
      classroomId: classroomManager.currentClassroomId,
      institutionId: classroom?.institutionId
    }, this.closeAddStudentsToClassroomModal);
  }

  closeAddStudentsToClassroomModal = (_event, _data, saved) => {
    const { dialogManager, classroomManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.ADD_STUDENTS_TO_CLASSROOM);
    saved && classroomManager.fetchClassroomRoster(classroomManager.currentClassroomId);
  }

  filterContentType = (_event, { value }) => {
    const { studentProgressManager } = this.props;
    studentProgressManager.setSelectedContentType(value);
  };

  render() {
    const {
      assignmentManager, classroomManager, courseManager, currentContentType,
      customContainerClassName, forceHideHeader = false, hideAddCourseButton,
      hideAddStudentButton, hideBackgroundImage, history, isMyProgress,
      productManager, showAddGroupButton, showBreadCrumbs, showBreadCrumbsDropdown,
      showCourseSearchButton, t, teacherProductViewManager, title, userManager
    } = this.props;

    const { loadingCourseSearch } = this.state;

    const { FROM_TEACHER_PRODUCTS_NAV } = productManager;
    const { isPathnameTeacherProducts } = teacherProductViewManager;

    const { allowSsoAddExistingStudentToSelfRegClassroom, hideAddExistingStudentButtonForTeachers } = classroomManager;

    const {
      authKey, isDistrictAdmin, isSchoolAdmin, isTeacher, isStudent
    } = userManager;
    const isAdmin = isDistrictAdmin || isSchoolAdmin;
    const { currentClassroomId } = classroomManager;

    const { BreadCrumbs, BreadCrumbsCourseDropdown, TeacherProductListener } = this;

    const { loaded } = classroomManager;
    const classroom = classroomManager.getClassroom(currentClassroomId);
    const classroomName = (classroom) ? classroomManager.getClassName(classroom.id) : '';
    const hasImage = (loaded && classroom && classroom.imageUrl);
    const isArchived = classroom && classroom.archived;
    const classroomNameObj = UtilityService.reactHtmlParserWrapper(classroomName);
    const titleObj = UtilityService.reactHtmlParserWrapper(
      productManager.FROM_TEACHER_PRODUCTS_NAV ? t('products') : title
    );
    const courseCount = courseManager.getClassroomCourseCount(currentClassroomId);

    let courseListBannerObj = {
      original: '', parsed: '', parsedMathOnly: '', stripped: ''
    };
    if (loaded && classroom && !productManager.FROM_TEACHER_PRODUCTS_NAV) {
      courseListBannerObj = (isAdmin || isTeacher) ? classroomNameObj : titleObj;
    } else {
      courseListBannerObj = titleObj;
    }

    if (isMyProgress) {
      const str = t('progressLabel', 'My Progress');
      courseListBannerObj = { original: str, parsed: str, stripped: str };
    }

    const urlParams = new URLSearchParams(window.location.search);
    const elementId = urlParams.get('elementId');

    const isSsoUserOrHasSsoInstitution = UserService.isSsoUserOrHasSsoInstitution();
    const isSelfRegClassroom = !ClassroomService.isSsoClassroom();

    // TODO remove
    // const isSsoUserAndSelfRegClassroom = UserService.isSsoUserAndSelfRegClassroom();
    // const isSsoInstitutionAndSelfRegClassroom = UserService.isSsoInstitutionAndSelfRegClassroom();

    let allowAddExistingStudent;
    if (!hideAddStudentButton && (!isSsoUserOrHasSsoInstitution || allowSsoAddExistingStudentToSelfRegClassroom)) {
      if (isAdmin && isSelfRegClassroom) {
        allowAddExistingStudent = true;
      } else if (isTeacher && !hideAddExistingStudentButtonForTeachers) {
        allowAddExistingStudent = true;
      } else if (allowSsoAddExistingStudentToSelfRegClassroom && (isSsoUserOrHasSsoInstitution && isSelfRegClassroom)) {
        allowAddExistingStudent = true;
      }
    }

    // TODO remove // const hideHeader = forceHideHeader || isStudent || (!isStudent && showBreadCrumbs !== 'top');
    const hideHeader = forceHideHeader || (isStudent && showBreadCrumbs === 'top');

    return (
      <div className={customContainerClassName || 'course-list-banner-container'}>
        {showBreadCrumbs === 'top' && (
          <Container className='bread-crumb-wrapper top' fluid>
            <BreadCrumbs breadcrumbClassName='course-list-banner-breadcrumb' history={history} />
          </Container>
        )}
        {showBreadCrumbsDropdown === 'top' && <BreadCrumbsCourseDropdown className='top' history={history} />}
        {!hideHeader && (
          <div
            className={!hideBackgroundImage && (hasImage || (!isAdmin && !isTeacher))
              ? 'class-header with-image' : 'class-header'}
            style={!hideBackgroundImage && hasImage && (isAdmin || isTeacher) ? {
              backgroundImage: `url(${`${classroom.fullImageUrl}&authKey=${authKey}`})`
            } : {}}>

            {hasImage && (isAdmin || isTeacher) && <div className='overlay' />}

            <div className={`banner${isMyProgress ? ' my-progress' : ''}`}>
              {/* eslint-disable-next-line react/destructuring-assignment */}
              {this.props.themeHeaderTitle || (
                <h2
                  className='theme-header-title course-list-banner-title'
                  title={(showAddGroupButton) ? `Class Groups: ${classroomNameObj.stripped}` : courseListBannerObj.stripped}>
                  {(showAddGroupButton) ? `Class Groups: ${classroomNameObj.parsed}` : courseListBannerObj.parsed}
                </h2>
              )}

              {/* BUTTON: COURSE SEARCH */}
              {(isAdmin || isTeacher) && (showCourseSearchButton) && (
                <>
                  {(assignmentManager.enableCourseSearch && (courseCount > 0 || FROM_TEACHER_PRODUCTS_NAV)) && (
                    <Button
                      className='add-course-button course-search-button'
                      loading={loadingCourseSearch}
                      onClick={this.handleCourseSearch}
                      primary
                      type='button'>
                      {t(FROM_TEACHER_PRODUCTS_NAV ? 'courseSearchButtonFromTeacherProducts' : 'courseSearchButton')}
                    </Button>
                  )}
                </>
              )}

              {/* BUTTON: ADD COURSE */}
              {(isAdmin || isTeacher) && (!hideAddCourseButton) && (
                <>
                  {renderButton({
                    additionalClassNames: { 'add-course-button': FROM_TEACHER_PRODUCTS_NAV },
                    buttonKey: FROM_TEACHER_PRODUCTS_NAV ? 'addToClass' : 'addCourseButton',
                    onClick: FROM_TEACHER_PRODUCTS_NAV ? this.handleAddCourseToClass : this.handleAddCourse,
                    primary: true
                  })}
                </>
              )}

              {allowAddExistingStudent && (
                <Button
                  className='add-course-button existing-student'
                  disabled={isArchived}
                  onClick={this.handleAddStudentsToClassroomModal}
                  primary
                  type='button'>
                  {t('addExistingStudentButton', '+ Add Existing Student')}
                </Button>
              )}

              {((isAdmin || isTeacher) && !hideAddStudentButton
                && (!isSsoUserOrHasSsoInstitution)) && (
                <Button
                  className='add-course-button new-student'
                  disabled={isArchived}
                  onClick={this.handleRegisterStudentModal}
                  primary
                  type='button'>
                  {t('addStudentButton', '+ Add New Student')}
                </Button>
              )}

              {((isAdmin || isTeacher) && showAddGroupButton) && (
                <Button
                  className='add-course-button new-group'
                  onClick={this.handleCreateGroupModal}
                  primary
                  type='button'>
                  {t('addGroupButton', '+ Add Group')}
                </Button>
              )}

              {isTeacher && elementId !== 'ROOT' && courseManager.isCustomCourse() && (
                <Button
                  className='add-course-button'
                  onClick={this.handleAddCustomAssessment}
                  primary
                  type='button'>
                  {t('addCustomAssessmentButton', '+ Add Assessment')}
                </Button>
              )}

              {(isMyProgress && userManager.clientPrefix === 'GALL') && (
                <div className='content-type-menu'>
                  <Dropdown
                    defaultValue={currentContentType}
                    inline
                    onChange={this.filterContentType}
                    options={this.contentTypeOptions} />
                </div>
              )}
            </div>
          </div>
        )}
        {showBreadCrumbs === 'bottom' && (
          <Container className='bread-crumb-wrapper bottom' fluid>
            <BreadCrumbs history={history} />
          </Container>
        )}
        {showBreadCrumbsDropdown === 'bottom' && <BreadCrumbsCourseDropdown className='bottom' history={history} />}
        {FROM_TEACHER_PRODUCTS_NAV && !isPathnameTeacherProducts && <TeacherProductListener />}
      </div>
    );
  }
}

export default CourseListBanner;

SatCoreRegister('CourseListBanner', CourseListBanner);
