import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

// import { register } from '../../../i18n';

import EngagementService from '../../../services/EngagementService';

const EngagementRowMainFeedbackUsageCells = observer((props) => {
  const { engagementData } = props;

  const { /* managers */ } = useContext(MobXProviderContext);

  // const t = register('EngagementTable');

  const renderEngagementRowMainFeedbackUsageCells = () => {
    return (
      <>
        {renderEngagementRowMainFeedbackUsageCell({
          allowPopupComponent: true,
          className: classNames('feedback-usage-cell main-cell feedbackUsage', {
            warn: !!engagementData.supportToolUse // TODO
          }),
          popupContent: '',
          popupDisabled: true,
          popupOn: 'hover',
          popupTrigger: `${engagementData.supportToolUseNumerator}/${engagementData.supportToolUseDenominator}`
        })}
      </>
    );
  };

  const renderEngagementRowMainFeedbackUsageCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementRowMainFeedbackUsageCells();
});
export default EngagementRowMainFeedbackUsageCells;

SatCoreRegister('EngagementRowMainFeedbackUsageCells', EngagementRowMainFeedbackUsageCells);
