import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { SatCoreRegister } from '../SatCoreRegistry';
import Auth from '../managers/AuthManager';

const LessonPortal = observer(() => {
  const {
    userManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  const [lessonUrl, setLessonUrl] = useState(null);

  useEffect(() => {
    async function init() {
      const checkUserData = await Auth.checkUser();
      if (Auth.lesson && checkUserData && checkUserData.authKey) {
        setLessonUrl(`${Auth.lesson}/manuscript?contentItemId=CAC50B26D75F44A2A6B35FAF8D6C3049#${checkUserData.authKey}`);
      }
    }

    init();
  }, []); // eslint-disable-lin react-hooks/exhaustive-deps

  return (
    <>
      {/* <h1>Lesson Portal</h1> */}
      <iframe
        src={lessonUrl}
        style={{
          position: 'relative',
          display: 'block',
          height: '100%',
          width: '100%',
          border: 'none'
        }}
        title='lessonFrame' />
    </>
  );
});

export default LessonPortal;
SatCoreRegister('LessonPortal', LessonPortal);
