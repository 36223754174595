import React, { useMemo, useState } from 'react';
import { Button, Dropdown, Form } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import Modal from '../Modal';
import '../../css/SelectLessonProfileModal.less';

const SelectLessonProfileModal = (props) => {
  const {
    closeFull, open, setProfileId, templateOptions, t
  } = props;
  const ModalBanner = useMemo(() => SatCoreComponent('ModalBanner'), []);
  // const alternateModeId = courseElement.alternateModeIdOverride || courseElement.alternateModeId;
  /* MODE: which mode to use */
  const [resourceTemplate, setResourceTemplate] = useState(null);

  const doSave = () => {
    // set to current mode and continue
    setProfileId(resourceTemplate);
  };

  const handleChangeResourceTemplate = async (_event, { value }) => {
    setResourceTemplate(value);
  };

  return (
    <Modal
      className='select-lesson-profile-modal'
      closeOnDimmerClick={false}
      closeOnEscape={true}
      onClose={closeFull}
      open={open}>
      <ModalBanner onClose={closeFull} title={t('selectPreviewMode', 'Select Preview Mode')} />
      <Modal.Content>
        <Form.Field>
          <div className='resource-lesson-mode'>
            {templateOptions.length > 0 && (
              <>
                <div className='label-text'>
                  {t('selectOptionProfileDescription')}
                </div>
                <div className='resource-lesson-mode-input-wrapper'>
                  <Dropdown
                    key='profile-select'
                    fluid
                    name='sort-select'
                    onChange={handleChangeResourceTemplate}
                    options={templateOptions}
                    placeholder={t('selectOption')}
                    search
                    selection
                    value={resourceTemplate} />
                </div>
              </>
            )}
            {templateOptions.length === 0 && (
              <>
                <div className='label-text'>There are no profiles available.<br />
                  Please add a profile and make it visible on satellite.
                </div>
              </>
            )}

          </div>
        </Form.Field>
      </Modal.Content>
      {templateOptions.length !== 0 && (
        <Modal.Actions>
          <Button className='ui primary button saveButton' disabled={resourceTemplate === null} onClick={doSave}>
            {t('save', 'Save button text missing')}
          </Button>
        </Modal.Actions>
      )}
    </Modal>
  );
};

export default SelectLessonProfileModal;

SatCoreRegister('SelectLessonProfileModal', SelectLessonProfileModal);
