import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { inject, observer } from 'mobx-react';
import { Image, Popup } from 'semantic-ui-react';
import { SatCoreRegister } from '../SatCoreRegistry';
import '../css/CategoryTagsList.less';
import { DIALOG_NAMES } from '../managers/DialogManager';
import UtilityService from '../services/UtilityService';
import iconTag from '../img/icon-tag.svg';

export default
@inject('assignmentManager', 'courseManager', 'dialogManager')
@observer
class CategoryTagsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addInfoOpen: false,
      infoMessage: '',
      showErrorDialog: false
    };
  }

  handleOpenInfo = () => {
    this.setState({ addInfoOpen: true });
  }

  closeInfoModal = () => {
    this.setState({ addInfoOpen: false });
  }

  handleViewTagsStandards = async () => {
    const { dialogManager, assessmentItem } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.TAGS_STANDARDS, {
      addInfoOpen: this.state.addInfoOpen,
      closeInfoModal: this.closeInfoModal,
      assessmentItem
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.TAGS_STANDARDS));
  }

  render() {
    const { pills, isFullList } = this.props;
    if (pills === null || pills === undefined || pills.length < 1) {
      return ('');
    }

    if (isFullList) {
      return this.renderFull(pills);
    }
    return this.renderTruncated(pills);
  }

  renderTruncated(pills) {
    const MAX_PILLS = 5;
    let remaining = 0;
    const transformFn = UtilityService.reactHtmlParserTransform;
    let categoryTagsList = [];

    if (pills !== null && pills.length > 0) {
      const noDisplayPills = pills.filter((pill) => pill.sortClass === 'z-custom-category');
      const showPills = pills.length - noDisplayPills.length;
      const showPillsLimit = (showPills > MAX_PILLS) ? MAX_PILLS : showPills;
      const displayList = pills.slice(0, showPillsLimit);
      remaining = pills.length - showPillsLimit;

      categoryTagsList = displayList.map((pill, index) => {
        if (pill && pill.name !== undefined && pill.name !== null) {
          const { name } = pill;
          const fullName = (pill.fullStatement !== null && pill.fullStatement !== '' && pill.fullStatement !== undefined) ? pill.fullStatement : pill.name;
          const classNameValue = `tag-category-pill ${pill.sortClass}`;
          return (
            <Popup
              key={`${pill.id}-${index}`}
              content={ReactHtmlParser(fullName)}
              on='click'
              trigger={(
                <div className={classNameValue}>
                  {ReactHtmlParser(name, {
                    transform: transformFn
                  })}
                </div>
              )} />
          );
        }
      });
    }

    return (
      <div className='standards-list-container'>
        <Image alt='' className='tag-image' src={iconTag} />
        {categoryTagsList}
        {(remaining > 0) ? (
          <div className='clickable blue' onClick={() => this.handleViewTagsStandards()}>
            {' '}
            +
            {remaining}
            {' '}
            Tags
          </div>
        ) : ''}
      </div>
    );
  }

  renderFull(pills) {
    const transformFn = UtilityService.reactHtmlParserTransform;
    let categoryTagsList = [];

    if (pills) {
      categoryTagsList = pills.map((pill, index) => {
        if (pill && pill.name) {
          const { name } = pill;
          const fullName = pill.fullStatement || pill.name;
          const classNameValue = `tag-category-pill ${pill.sortClass}`;
          return (
            <Popup
              key={`${pill.id}-${index}`}
              content={ReactHtmlParser(fullName)}
              on='click'
              trigger={(
                <div className={classNameValue}>
                  {ReactHtmlParser(name, {
                    transform: transformFn
                  })}
                </div>
)} />
          );
        }
      });
    }

    return (
      <div className='standards-list-container'>
        {categoryTagsList}
      </div>
    );
  }
}

SatCoreRegister('CategoryTagsList', CategoryTagsList);
