/* eslint-disable react/jsx-sort-props */
import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../css/IconCircleStatus.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import { ICON_CIRCLE_STATUS } from '../constants/IconCircleStatusConstants';
import IconCircleStatusService from '../services/IconCircleStatusService';

const IconCircleStatus = observer((props = {}) => {
  const { status } = props;

  const { /* managers */ } = useContext(MobXProviderContext);

  const { EMPTY_CIRCLE/* , FULL_CIRCLE, HALF_CIRCLE */ } = ICON_CIRCLE_STATUS;

  const {
    className,
    // key
  } = typeof status === 'object' ? status : EMPTY_CIRCLE;

  const isIconCircleStatusFull = IconCircleStatusService.isIconCircleStatusFull(status);
  const isIconCircleStatusHalf = IconCircleStatusService.isIconCircleStatusHalf(status);
  // TODO unused // const isIconCircleStatusEmpty = !isIconCircleStatusFull && !isIconCircleStatusHalf;

  return (
    <div className={classNames('IconCircleStatus', className)}>
      {!isIconCircleStatusFull && (
        <svg
          xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'>
          <g>
            <circle className='icon-circle-status-circlePart1' cx='6' cy='6' r='6' />
            <circle className='icon-circle-status-circlePart2' cx='6' cy='6' r='5.5' />
          </g>
          {isIconCircleStatusHalf && (
            <path data-name='Path 6479' d='M12 6A6 6 0 0 1 0 6c3.592.037 8.617-.125 12 0z' />
          )}
        </svg>
      )}
      {isIconCircleStatusFull && (
        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'>
          <circle className='icon-circle-status-top' cx='6' cy='6' r='6' />
        </svg>
      )}
    </div>
  );
});
export default IconCircleStatus;

SatCoreRegister('IconCircleStatus', IconCircleStatus);
