import { action, makeObservable, observable } from 'mobx';

export class FilteredHeaderTableManager {
  @observable tableDataMap = new Map();

  @observable isCleared = false;

  constructor() {
    makeObservable(this);
  }

  @action cleartableDataMap() {
    this.tableDataMap.clear();
  }

  /**
   *  Each table that a component wants to manage should be registered using this function.
   *
   *  `tableId` should be a unique key.
   */
  @action registerTable(tableId, activePage = 0, column = '', direction = 'asc', totalPages = 0, filters = []) {
    if (tableId && !this.tableDataMap.has(tableId)) {
      const tableData = {
        activePage,
        column,
        direction,
        filters,
        tableId,
        totalPages
      };
      this.tableDataMap.set(tableId, tableData);
      this.isCleared = false;
      return this.tableDataMap.has(tableId);
    }
    return false;
  }

  /** Returns current table state by tableId */
  @action getTableData(tableId) {
    if (this.tableDataMap.has(tableId)) {
      return this.tableDataMap.get(tableId);
    }
    return null;
  }

  /** Deletes the table with matching tableId from this manager */
  @action deleteTable(tableId) {
    return this.tableDataMap.delete(tableId);
  }

  /** Set the active page in the table data for the specified tableId */
  @action setActivePage(tableId, activePage) {
    const tableData = this.getTableData(tableId);
    if (tableData) {
      tableData.activePage = activePage;
      return true;
    }
    return this.registerTable(tableId, activePage);
  }

  /** Set the sort Column in the table data for the specified tableId */
  @action setSortColumn(tableId, column) {
    const tableData = this.getTableData(tableId);
    if (tableData) {
      tableData.column = column;
      return true;
    }
    return this.registerTable(tableId, 0, column);
  }

  /** Set the sort direction in the table data for the specified tableId */
  @action setSortDirection(tableId, direction) {
    const tableData = this.getTableData(tableId);
    if (tableData) {
      tableData.direction = direction;
      return true;
    }
    return this.registerTable(tableId, 0, '', direction);
  }

  /** Set the total pages in the table data for the specified tableId
   * used for the paginator
   */
  @action setTotalPages(tableId, totalPages) {
    const tableData = this.getTableData(tableId);
    if (tableData) {
      tableData.totalPages = totalPages;
      return true;
    }
    return this.registerTable(tableId, 0, '', 'ascending', totalPages);
  }

  /** Set the filters object in the table data for the specified tableId */
  @action setFilters(tableId, filters) {
    const tableData = this.getTableData(tableId);
    if (tableData) {
      tableData.filters = filters;
      return true;
    }
    return this.registerTable(tableId, 0, '', 'ascending', 0, filters);
  }

  /** clears all table data from the manager */
  clearAll() {
    this.cleartableDataMap();
    this.isCleared = true;
  }
}

export default new FilteredHeaderTableManager();
