import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import iconEye from '../../img/icon-eye.svg';
import iconEyeLineThru from '../../img/icon-eye-with-line-thru.svg';

import '../../css/reports/ReportProgressFilter.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

const ReportProgressFilter = observer((props) => {
  const { reportContextManager } = useContext(MobXProviderContext);
  const { t } = props;

  function toggleFilter(id) {
    const { progressFilters } = reportContextManager;
    progressFilters[id] = !progressFilters[id];
    reportContextManager.setProgressFilters({ ...progressFilters });
  }

  const { progressFilters } = reportContextManager;

  const InfoIcon = SatCoreComponent('InfoIcon');
  const ReportCellValueToggler = SatCoreComponent('ReportCellValueToggler');

  const renderReportProgressFilter = () => {
    return (
      <>
        {/* 1st row */}
        <div className='report-progress-filter'>
          <div className='empty' />
          <div className='performance'>
            <span>Performance</span>
            <span className='info-icon'>
              <InfoIcon popupOptions={{ content: t('performance'), on: 'click' }} />
            </span>
          </div>
          {/* 2nd row */}
          <div className='growth'>
            <span>Growth</span>
            <span className='info-icon'>
              <InfoIcon popupOptions={{ content: t('growth'), on: 'click' }} />
            </span>
          </div>
          <div className='high'>Meets+<br />(High)</div>
          <div className='average'>Approaches<br />(Average)</div>
          <div className='low'>Does Not Meet<br />(Low)</div>
          {/* 3rd row */}
          <div className='more-growth'>More Growth</div>
          <button
            className={(progressFilters.highMore) ? 'filter high-more' : 'filter-off'}
            onClick={() => toggleFilter('highMore')}
            type='button'>
            <img alt='' className='eye' src={(progressFilters.highMore) ? iconEye : iconEyeLineThru} />
            <span>Blue</span>
          </button>
          <button
            className={(progressFilters.averageMore) ? 'filter average-more' : 'filter-off'}
            onClick={() => toggleFilter('averageMore')}
            type='button'>
            <img alt='' className='eye' src={(progressFilters.averageMore) ? iconEye : iconEyeLineThru} />
            <span>Green</span>
          </button>
          <button
            className={(progressFilters.lowMore) ? 'filter low-more' : 'filter-off'}
            onClick={() => toggleFilter('lowMore')}
            type='button'>
            <img alt='' className='eye' src={(progressFilters.lowMore) ? iconEye : iconEyeLineThru} />
            <span>Red</span>
          </button>
          {/* 4th row */}
          <div className='less-growth'>Less Growth</div>
          <button
            className={(progressFilters.highLess) ? 'filter high-less' : 'filter-off'}
            onClick={() => toggleFilter('highLess')}
            type='button'>
            <img alt='' className='eye' src={(progressFilters.highLess) ? iconEye : iconEyeLineThru} />
            <span>Blue</span>
          </button>
          <button
            className={(progressFilters.averageLess) ? 'filter average-less' : 'filter-off'}
            onClick={() => toggleFilter('averageLess')}
            type='button'>
            <img alt='' className='eye' src={(progressFilters.averageLess) ? iconEye : iconEyeLineThru} />
            <span>Green</span>
          </button>
          <button
            className={(progressFilters.lowLess) ? 'filter low-less' : 'filter-off'}
            onClick={() => toggleFilter('lowLess')}
            type='button'>
            <img alt='' className='eye' src={(progressFilters.lowLess) ? iconEye : iconEyeLineThru} />
            <span>Red</span>
          </button>
          {/* 5th row */}
          <div className='instructions'>Select a key color to filter progress levels</div>
        </div>
      </>
    );
  };

  return (
    <>
      {renderReportProgressFilter()}
      <ReportCellValueToggler />
    </>
  );
});
export default ReportProgressFilter;

SatCoreRegister('ReportProgressFilter', ReportProgressFilter);
