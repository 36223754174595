import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Input, Loader, Message } from 'semantic-ui-react';

import classNames from 'classnames';

import { SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

import DynamicSatelliteService from '../services/DynamicSatelliteService';

import '../css/TrialLauncherView.less';

import { renderButton } from '../utils';

import UtilityService from '../services/UtilityService';
import InitService from '../services/InitService';
import PurchaseCodeService from '../services/PurchaseCodeService';

const TrialLauncherView = observer((props) => {
  const TRIAL_ACCESS_CODE_STATUS = {
    INVALID: 'invalid',
    VALID: 'valid'
  };

  const { t } = props;

  const { contentManager, dynamicSatelliteManager, userManager } = useContext(MobXProviderContext);

  const [loaded, setLoaded] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(undefined);

  const [trialAccessCode, setTrialAccessCode] = useState('');

  const [isSubmittedTrialAccessCode, setIsSubmittedTrialAccessCode] = useState(false);
  const [trialAccessCodeStatus, setTrialAccessCodeStatus] = useState(undefined); // 'valid' | 'invalid' | undefined

  const [trialDaysRemaining, setTrialDaysRemaining] = useState('0');
  const [isTrialExpired, setIsTrialExpired] = useState(undefined);
  const [classroomId, setClassroomId] = useState(undefined);
  const [cacheId, setCacheId] = useState(undefined);

  const [isAccessCodePanel, setIsAccessCodePanel] = useState(false);

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const _trialDaysRemaining = urlParams.get('trialDaysRemaining') || '';
      const _isTrialExpired = urlParams.get('isTrialExpired') !== 'false';
      const _classroomId = urlParams.get('classroomId');
      const _cacheId = urlParams.get('cacheId');
      const authKey = urlParams.get('authKey');

      setTrialDaysRemaining(_trialDaysRemaining);
      setIsTrialExpired(_isTrialExpired);
      setClassroomId(_classroomId);
      setCacheId(_cacheId);

      if (_isTrialExpired) {
        setIsAccessCodePanel(true);
      }

      let _isAuthenticated;
      if (authKey) {
        InitService.doLogoutCleanUp('', { authKey });
        _isAuthenticated = await userManager.authKeyLogIn(authKey);
        setIsAuthenticated(_isAuthenticated);
      }

      if (dynamicSatelliteManager.isDynamicSatellite && !Auth.publisherSatelliteCode) {
        await DynamicSatelliteService.fetchDynamicSatelliteByDomain();
        setTimeout(() => {
          setLoaded(true);
        }, 500);
      } else {
        setLoaded(true);
      }
    })();
  }, []);

  const handleChangeTrialAccessCodeInput = (event) => {
    handleClearTrialAccessCodeSubmission();
    const accessCode = event?.target?.value?.toUpperCase?.();
    setTrialAccessCode(accessCode);
  };

  const handleClearTrialAccessCodeSubmission = () => {
    if (isSubmittedTrialAccessCode || trialAccessCodeStatus) {
      setIsSubmittedTrialAccessCode(false);
      setTrialAccessCodeStatus(undefined);
    }
  };

  const handleSubmitTrialAccessCode = async () => {
    const validated = await PurchaseCodeService.validateCode(trialAccessCode);
    setIsSubmittedTrialAccessCode(true);
    setTrialAccessCodeStatus(validated ? TRIAL_ACCESS_CODE_STATUS.VALID : TRIAL_ACCESS_CODE_STATUS.INVALID);

    if (validated) {
      const burnCodeSuccess = await PurchaseCodeService.burnCode(trialAccessCode, { classroomId });

      setTrialAccessCodeStatus(burnCodeSuccess ? TRIAL_ACCESS_CODE_STATUS.VALID : TRIAL_ACCESS_CODE_STATUS.INVALID);

      if (burnCodeSuccess) {
        handleRedirectToContent();
      }
    } else {
      setTrialAccessCodeStatus(TRIAL_ACCESS_CODE_STATUS.INVALID);
    }
  };

  const handleRedirectToContent = async () => {
    setLoaded(false);

    const cachedItemLink = await contentManager.fetchCachedItemLink(cacheId);

    if (cachedItemLink) {
      window.location.href = cachedItemLink;
    } else {
      setTrialAccessCodeStatus(TRIAL_ACCESS_CODE_STATUS.INVALID);
      setLoaded(false);
    }
  };

  const renderTrialAccessCodeInput = () => {
    return (
      <div className='trial-launcher-box-access-code-input-wrapper'>
        <Input autoFocus
          onChange={handleChangeTrialAccessCodeInput}
          placeholder={t('trialLauncherBoxAccessCodeInputPlaceholder')}
          value={trialAccessCode} />
      </div>
    );
  };

  let trialLauncherBoxTitle, trialLauncherBoxDescription;
  if (isAuthenticated) {
    if (isTrialExpired) {
      trialLauncherBoxTitle = t('trialLauncherBoxExpiredTitle');
    } else {
      trialLauncherBoxTitle = !isAccessCodePanel ? t('trialLauncherBoxTitle') : t('trialLauncherBoxAccessCodeTitle');
    }

    const dayVerbiage = +trialDaysRemaining === 1 ? t('dayVerbiage') : t('daysVerbiage');
    if (isTrialExpired) {
      trialLauncherBoxDescription = t('trialLauncherBoxExpiredDescription');
    } else {
      trialLauncherBoxDescription = !isAccessCodePanel ? (
        t('trialLauncherBoxDescription', { dayVerbiage, trialDaysRemaining })
      ) : t('trialLauncherBoxAccessCodeDescription');
    }
  } else {
    trialLauncherBoxTitle = t('trialLauncherBoxUnauthenticatedTitle');
    trialLauncherBoxDescription = t('trialLauncherBoxUnauthenticatedDescription');
  }

  return loaded ? (
    <div className='TrialLauncherView'>
      <div className={classNames('trial-launcher-box', {
        'trial-launcher-box-large': !!isSubmittedTrialAccessCode && trialAccessCodeStatus === TRIAL_ACCESS_CODE_STATUS.INVALID
      })}>
        <div className='trial-launcher-box-title'>
          {UtilityService.reactHtmlParserWrapper(trialLauncherBoxTitle)?.parsed}
        </div>
        <div className='trial-launcher-box-description'>
          {UtilityService.reactHtmlParserWrapper(trialLauncherBoxDescription)?.parsed}
          {isAuthenticated && isAccessCodePanel && (
            renderTrialAccessCodeInput()
          )}
          {isSubmittedTrialAccessCode && trialAccessCodeStatus === TRIAL_ACCESS_CODE_STATUS.INVALID && (
            <Message content={t('invalidAccessCode')} error visible />
          )}
        </div>
        {isAuthenticated && (
          <div className='trial-launcher-box-actions'>
            {!isAccessCodePanel && !isTrialExpired && renderButton({
              buttonKey: 'continueTrial',
              onClick: handleRedirectToContent,
              primary: true
            })}
            {!isAccessCodePanel && renderButton({
              buttonKey: 'enterAccessCode',
              onClick: () => setIsAccessCodePanel(true),
              primary: true
            })}
            {isAccessCodePanel && !isTrialExpired && renderButton({
              buttonKey: 'back',
              onClick: () => {
                handleClearTrialAccessCodeSubmission();
                setTrialAccessCode('');
                setIsAccessCodePanel(false);
              },
              primary: true
            })}
            {isAccessCodePanel && renderButton({
              buttonKey: 'submit',
              disabled: !trialAccessCode || trialAccessCodeStatus === TRIAL_ACCESS_CODE_STATUS.INVALID,
              onClick: () => handleSubmitTrialAccessCode(),
              primary: true
            })}
          </div>
        )}
      </div>
    </div>
  ) : <Loader active />;
});
export default TrialLauncherView;

SatCoreRegister('TrialLauncherView', TrialLauncherView);
