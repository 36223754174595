import { getRegisteredClass } from '../SatCoreRegistry';

export default class DropdownService {
  static getSemanticUiDropdownProps = ({
    className,
    clearable = false,
    defaultValue,
    dropdownLabel,
    onChange,
    options,
    placeholder,
    search = true,
    selection = true,
  } = {}) => {
    return {
      className,
      clearable,
      defaultValue,
      dropdownLabel,
      onChange,
      options,
      placeholder,
      search,
      selection
    };
  }

  /**
   * Generate dropdown options from `originalData` (an array of objects).
   *
   * @param {Object} props - props for the function.
   * @param {Array<Object>} props.originalData - original data array.
   * @param {boolean} props.allowDropdownOptionRollover - if true, wrap each dropdown option in a span with html5 title rollover
   * @param {{
   *  key: string | number, text: string, value: string
   * } | undefined} props.optionalFirstDropdownOption - optional dropdown option that will be prepended to the generated dropdown options.
   * @param {string | Function} props.keyPropName - property name or function to generate the key for each dropdown option.
   * @param {string | Function} props.labelPropName - property name or function to generate the label for each dropdown option.
   * @param {string | Function} props.valuePropName - property name or function to generate the value for each dropdown option.
   * @returns {Array<Object>} array of dropdown options.
   *
   * @example
   * const dropdownOptions = getDropdownOptions({
   *   originalData: products,
   *   optionalFirstDropdownOption: { key: -1, text: 'All Products', value: 'all' }
   *   keyPropName: 'id',
   *   labelPropName: (obj) => obj.friendlyName || obj.displayName,
   *   valuePropName: 'id'
   * });
   *
   * // Result:
   * // [
   * //   { key: -1, text: 'All Products', value: 'all' },
   * //   { key: '1', obj: { id: '1', friendlyName: 'Product 1' }, text: 'Product 1', value: '1' },
   * //   { key: '2', obj: { id: '2', displayName: 'Product 2' }, text: 'Product 2', value: '2' }
   * // ]
   */
  static getDropdownOptions = ({
    originalData = [],
    allowDropdownOptionRollover = true,
    optionalFirstDropdownOption,
    keyPropName,
    labelPropName,
    valuePropName,
  } = {}) => {
    if (!Array.isArray(originalData)) {
      console.error('DropdownService.getDropdrownOptions: `originalData` must be an array');
      return;
    }
    const PopupService = getRegisteredClass('PopupService');

    const dropdownOptions = originalData.map((obj) => {
      const dropdownLabel = typeof labelPropName === 'function' ? labelPropName(obj) : obj[labelPropName];
      return {
        content: PopupService.renderPopup({
          content: dropdownLabel,
          disabled: !allowDropdownOptionRollover,
          mouseEnterDelay: 2000,
          position: 'top left',
          trigger: dropdownLabel,
          wide: false
        }),
        key: typeof keyPropName === 'function' ? keyPropName(obj) : obj[keyPropName],
        original: obj,
        text: dropdownLabel,
        value: typeof valuePropName === 'function' ? valuePropName(obj) : obj[valuePropName]
      };
    });
    if (optionalFirstDropdownOption) {
      dropdownOptions.unshift(optionalFirstDropdownOption);
    }
    return dropdownOptions;
  }
}
