import React, { useContext, useState } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { setSessionStorageItem, stripHtmlTags } from '../../utils';

import ReportIdentityService from '../../services/reports/ReportIdentityService';

const ClassroomReportTypeSelectorModal = observer((props = {}) => {
  const {
    buttonKeyCancelLabel = 'cancel',
    buttonKeyConfirmLabel = 'open',
    className = 'ClassroomReportTypeSelectorModal',
    classroom,
    classroomStatus,
    closeOnDimmerClick = false,
    closeOnEscape = true,
    disableKeystroke = false,
    disableMove = false,
    disableResize = true,
    history,
    initHeight,
    initWidth,
    minHeight = 'auto',
    minWidth = 570,
    onOpen = () => {},
    onRequestClose,
    open: isOpen,
    overlay = 'medium',
    t, top = window.innerHeight / 3
  } = props;

  const {
    reportOverrideManager
  } = useContext(MobXProviderContext);

  const DEFAULT_SELECTED_REPORT_URL_KEY_SUFFIX = 'course-classroom-summary';

  const [isLoadingClassroomReportTypeSelectorModal, setIsLoadingClassroomReportTypeSelectorModal] = useState(false);

  const [selectedReportUrlKeySuffix, setSelectedReportUrlKeySuffix] = useState(DEFAULT_SELECTED_REPORT_URL_KEY_SUFFIX);

  const GenericModal = SatCoreComponent('GenericModal');
  const SCRadio = SatCoreComponent('SCRadio');

  // TODO unused
  // useEffect(() => {
  //   (async () => {
  //     // placeholder
  //   })();
  // }, []);

  const handleChangeSelectedReportUrlKeySuffix = (event) => {
    setSelectedReportUrlKeySuffix(event?.target?.value);
  };

  const handleCloseClassroomReportTypeSelectorModal = async (_event, actionButtonData = {}) => {
    if (!actionButtonData?.className?.includes?.(buttonKeyConfirmLabel)) {
      props.onClose?.();
      return;
    }

    setIsLoadingClassroomReportTypeSelectorModal(true);

    const reportStartUrl = await ReportIdentityService.getAdminClassroomReportTypeSelectorStartUrl({
      classroomId: classroom.classroomId || classroom.id,
      classroomStatus,
      selectedReportUrlKeySuffix
    });

    setSessionStorageItem('adminClassroomReportFacultyName', classroom.classroomName || classroom.name);
    const leadTeacherName = ReportIdentityService.getReportLeadTeacherName(classroom);
    setSessionStorageItem('adminClassroomReportLeadTeacherName', leadTeacherName);

    history.push(reportStartUrl);

    props.onClose?.();

    setIsLoadingClassroomReportTypeSelectorModal(false);
  };

  const renderReportTypeRadioOption = (radioOptionKey) => {
    return (
      <SCRadio
        additionalClassNames={radioOptionKey}
        checked={radioOptionKey === selectedReportUrlKeySuffix}
        label={t(radioOptionKey)}
        name={radioOptionKey}
        onChange={handleChangeSelectedReportUrlKeySuffix}
        value={radioOptionKey} />
    );
  };

  const radioOptionsJsx = (
    <div className='report-selector-modal-radio-options'>
      {renderReportTypeRadioOption('course-classroom-summary')}
      {!!reportOverrideManager.allowReportStandards && (
        renderReportTypeRadioOption('standards-classroom-summary')
      )}
    </div>
  );

  let headerTitle = stripHtmlTags(classroom?.classroomName || classroom?.name || '');
  headerTitle = `${headerTitle} ${t('reports')}`.trim();

  return (
    <GenericModal
      bodyMsg={radioOptionsJsx}
      buttonKeyCancelLabel={buttonKeyCancelLabel}
      buttonKeyConfirmLabel={buttonKeyConfirmLabel}
      className={className}
      closeOnDimmerClick={closeOnDimmerClick}
      closeOnEscape={closeOnEscape}
      disableKeystroke={disableKeystroke}
      disableMove={disableMove}
      disableResize={disableResize}
      headerMsg={headerTitle}
      initHeight={initHeight || minHeight}
      initWidth={initWidth || minWidth}
      isLoading={isLoadingClassroomReportTypeSelectorModal}
      isOpen={isOpen}
      onClose={handleCloseClassroomReportTypeSelectorModal}
      onOpen={onOpen}
      onRequestClose={onRequestClose}
      overlay={overlay}
      top={top} />
  );
});

export default ClassroomReportTypeSelectorModal;

SatCoreRegister('ClassroomReportTypeSelectorModal', ClassroomReportTypeSelectorModal);
