import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../css/ClassroomSelectionCard.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import ClassroomService from '../services/ClassroomService';
import UtilityService from '../services/UtilityService';
import PopupService from '../services/PopupService';

const ClassroomSelectionCard = observer((props) => {
  const {
    checked, classroom, disabled, handleToggleClassroom, showCheckbox = true, t
  } = props;
  const {
    // managers
  } = useContext(MobXProviderContext);

  const ClassCardImage = SatCoreComponent('ClassCardImage');
  const ClassroomTitle = SatCoreComponent('ClassroomTitle');
  const SCCheckbox = SatCoreComponent('SCCheckbox');

  const handleOpenClassroomImagePicker = () => {
    ClassroomService.handleOpenClassroomImagePicker({
      classroomId: classroom.id
    });
  };

  const renderClassroomSelectionCard = () => {
    const classroomNicknameObj = UtilityService.reactHtmlParserWrapper(classroom.nickname || '');

    const classroomAlreadyHasCourse = disabled;

    return PopupService.renderPopup({
      className: 'classroom-selection-card-popup',
      content: t('classroomAlreadyHasCourse'),
      disabled: !classroomAlreadyHasCourse, // i.e. don't allow the popup tooltip if user is able to select the card
      mouseEnterDelay: 750,
      mouseLeaveDelay: 250,
      offset: [-10, -60],
      position: 'bottom left',
      trigger: (
        <div className={classNames('classroom-selection-card', {
          'classroom-selection-card-disabled': disabled
        })}>
          {showCheckbox && (
            <div className='classroom-selection-card-checkbox'>
              <SCCheckbox
                checked={checked}
                disabled={disabled}
                onChange={(event) => handleToggleClassroom(event, classroom)} />
            </div>
          )}
          <div className='classroom-selection-card-inner'>
            <ClassCardImage
              additionalClassNames='classroom-selection-card-image'
              classroomId={classroom.id}
              openImagePicker={handleOpenClassroomImagePicker} />
            <div className='classroom-selection-card-body'>
              <div className='classroom-selection-card-caption'>
                <ClassroomTitle classroom={classroom} />
                <div className='classroom-selection-card-nickname'
                  title={classroomNicknameObj.stripped}>
                  {classroomNicknameObj.parsed}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    });
  };
  return renderClassroomSelectionCard();
});

export default ClassroomSelectionCard;

SatCoreRegister('ClassroomSelectionCard', ClassroomSelectionCard);
