import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../css/Footnotes.less';

import { SatCoreRegister } from '../SatCoreRegistry';

const Footnotes = observer((props = {}) => {
  const {
    additionalClassNames = '', // e.g. 'print-only'
    showModifiedContentFootnote = false,
    style,
    t
  } = props;

  const { /* managers */ } = useContext(MobXProviderContext);

  let footnotesClassName = 'footnotes';
  footnotesClassName += additionalClassNames ? ` ${additionalClassNames}` : '';
  return (
    <div className={footnotesClassName} style={style}>
      {/* FOOTNOTE: MODIFIED CONTENT */}
      {showModifiedContentFootnote && (
        <div className='footnote-modified-content'>
          <div className='report-table-cell modified'>
            {/* placeholder */}
          </div>
          <div className='text'>
            {t('modifiedContentFootnote')}
          </div>
        </div>
      )}
    </div>
  );
});
export default Footnotes;

SatCoreRegister('Footnotes', Footnotes);
