/* eslint-disable sort-keys,react/destructuring-assignment */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { Dimmer, Loader, Popup } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';
import { SatCoreRegister } from '../SatCoreRegistry';
import PopupButton from '../components/PopupButton';
import LTIPreview from '../components/LTIPreview';
import { DIALOG_NAMES } from '../managers/DialogManager';
import UserService from '../services/UserService';
import AssignmentService from '../services/AssignmentService';
import '../css/LTICourses.less';

const LTICourses = observer((props) => {
  const [mode, setMode] = useState('assignment');

  const indentFactor = 36;
  const indentBase = 0;
  const popupOffset = (mode === 'assignment') ? 20 : 60;

  const { assignmentManager, classroomManager, courseManager, userManager, dialogManager } = useContext(MobXProviderContext);

  const [status, setStatus] = useState('loading');
  const [objectOpenStates, setObjectOpenStates] = useState({});
  const [previewCourseElement, setPreviewCourseElement] = useState(null);
  const [isAddingContent, setIsAddingContent] = useState(false);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const courseIds = useMemo(() => {
    return [];
  }, []);

  const courseElementMap = useMemo(() => {
    return new Map();
  }, []);

  const addCourseElementToCourse = useMemo(() => {
    return new Set();
  }, []);

  const createAssignment = useMemo(() => {
    return new Set();
  }, []);

  const allowStudentReview = useMemo(() => {
    return new Set();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(props.location.search);
      let authKey = null;
      if (urlParams.has('authKey') && urlParams.get('authKey') !== 'null') {
        authKey = urlParams.get('authKey');
        await userManager.authKeyLogIn(authKey);
        // If user has multiple roles and has the teacher_user role, set the active role to teacher_user.
        // If this isn't done, no courses are loaded on the courses page when LTI launched.
        if (!userManager.activePermissionId) {
          const roles = UserService.getAvailableRoles();
          if (roles.includes('teacher_user')) {
            userManager.setActivePermissionId('teacher_user');
          }
        }
      } else {
        setStatus('unauthorized');
        return;
      }

      if (!userManager.hasTeacherPermission) {
        setStatus('unauthorized');
        return;
      }

      const classroomId = urlParams.get('classroomId');
      classroomManager.setCurrentClassroomId(classroomId);
      await courseManager.fetchUserCourseList(classroomId, 0, 999, '', 'sortName', 'asc');

      initOpenStates();

      let mode = urlParams.get('mode');
      if (mode !== 'content' && mode !== 'assignment') {
        mode = 'assignment';
      }
      setMode(mode);

      setStatus('loaded');
    };

    fetchData();
  }, []);

  const initOpenStates = () => {
    const courseMap = courseManager.productCourses;
    const newCourseIds = Array.from(courseMap.keys());

    const newOpenStates = {};
    newCourseIds.forEach((newCourseId) => {
      newOpenStates[newCourseId] = false;
      courseIds.push(newCourseId);
    });

    setObjectOpenStates(newOpenStates);
  };

  const clearAllCheckboxes = () => {
    addCourseElementToCourse.clear();
    createAssignment.clear();
    allowStudentReview.clear();
  };

  const isStudentAssignable = (deliveryMode) => {
    const assignable = deliveryMode && (deliveryMode.indexOf('assign') > -1 || deliveryMode === 'student_always');
    return assignable;
  };

  const getDeliveryModeText = (deliveryMode) => {
    switch (deliveryMode) {
      case 'assignable':
        return 'Assignable';
      case 'student_always':
        return 'Student Always';
      case 'student_always_assignable':
        return 'Student Always Assignable';
      case 'student_always_rou_assigned':
        return 'Read Only Until Assigned';
      case 'teacher_only':
        return 'Teacher Only';
      default:
        return '';
    }
  };

  const addAssignment = async (elementId, studentReview) => {
    const courseElement = courseElementMap.get(elementId);
    const { contentItemId, courseContentItemId } = courseElement;
    const classroomId = classroomManager.currentClassroomId;
    try {
      await AssignmentService.addLTIAssignment(contentItemId, elementId, classroomId, courseContentItemId, studentReview);

      const assignmentData = assignmentManager.getLastAddedAssignment();
      window.parent.postMessage({
        custom: {
          contentItemId: assignmentData.contentItemId,
          courseResourceElementId: assignmentData.courseResourceElementId,
          subdomainLinkId: assignmentData.subdomainLinkId
        },
        type: 'lti'
      }, '*');
    } catch (error) {
      dialogManager.setOpenDialog(DIALOG_NAMES.ERROR, {
        errorLabel: 'Assignment Error',
        errorMessage: error.message,
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.ERROR));
    }
  };

  const contentAdded = () => {
    dialogManager.closeDialog(DIALOG_NAMES.TEXT);
    window.close();
  };

  const addContent = async () => {
    if (addCourseElementToCourse.size === 0) {
      dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
        message: 'No content is selected so nothing has been added.',
        size: 'tiny',
        title: 'No content selected',
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
      return;
    }

    setIsAddingContent(true);

    const classroomId = classroomManager.currentClassroomId;
    const data = {
      classroomId,
      courseElements: [],
    };

    addCourseElementToCourse.forEach((elementId) => {
      data.courseElements.push({
        elementId,
        addToCourse: true,
        createAssignment: createAssignment.has(elementId),
        allowStudentReview: allowStudentReview.has(elementId),
      });
    });

    try {
      await courseManager.addCourseElementsToLTICourse(data);

      setIsAddingContent(false);

      // This isn't needed because the page is closed on success. It was causing courses to be duplicated behind the dialog.
      // initOpenStates();
      // clearAllCheckboxes();

      dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
        message: 'Content has been added.  It may take a few minutes for your content to appear.',
        size: 'tiny',
        title: 'Content has been added',
      },
      () => contentAdded());
    } catch (error) {
      setIsAddingContent(false);

      dialogManager.setOpenDialog(DIALOG_NAMES.ERROR, {
        errorLabel: 'Add Content Error',
        errorMessage: error.message,
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.ERROR));
    }
  };

  const openAddAssignmentDialog = async (elementId) => {
    dialogManager.setOpenDialog(DIALOG_NAMES.ADD_LTI_ASSIGNMENT, {
      addAssignment,
      elementId,
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.ADD_LTI_ASSIGNMENT));
  };

  const openPreview = async (elementId) => {
    const courseElement = courseElementMap.get(elementId);
    setPreviewCourseElement(courseElement);
  };

  const closePreview = async () => {
    setPreviewCourseElement(null);
  };

  const handleCourseClick = async (event) => {
    // ignore when checkbox is clicked
    if (event.target.nodeName === 'INPUT') {
      return;
    }

    const courseId = event.target.dataset.courseId || event.target.parentNode.dataset.courseId;

    const courseMap = courseManager.productCourses;
    const course = courseMap.get(courseId);

    if (!course.children) {
      const newCourseElements = await courseManager.fetchTeacherCourseElements({
        classroomId: classroomManager.currentClassroomId,
        courseContentItemId: courseId,
        includeAlignments: false,
      });

      const elementIds = [];
      const newOpenStates = {};
      newCourseElements.forEach((newCourseElement) => {
        const newElementId = newCourseElement.elementId;
        newOpenStates[newElementId] = false;

        newCourseElement.courseContentItemId = courseId;

        courseElementMap.set(newElementId, newCourseElement);

        elementIds.push(newElementId);
      });

      course['children'] = elementIds;

      setObjectOpenStates((prevState) => {
        prevState[courseId] = !prevState[courseId];
        return { ...prevState, ...newOpenStates };
      });
    } else {
      setObjectOpenStates((prevState) => {
        prevState[courseId] = !prevState[courseId];
        return { ...prevState };
      });
    }
  };

  const handleCourseElementClick = async (event) => {
    // ignore when checkbox is clicked
    if (event.target.nodeName === 'INPUT') {
      return;
    }

    const courseId = event.target.dataset.courseId || event.target.parentNode.dataset.courseId
      || event.target.parentNode.parentNode.dataset.courseId;
    const courseElementId = event.target.dataset.courseElementId || event.target.parentNode.dataset.courseElementId
      || event.target.parentNode.parentNode.dataset.courseElementId;

    const courseElement = courseElementMap.get(courseElementId);

    if (!courseElement.children) {
      const newCourseElements = await courseManager.fetchTeacherCourseElements({
        classroomId: classroomManager.currentClassroomId,
        courseContentItemId: courseId,
        courseResourceElementId: courseElementId,
        includeAlignments: false,
      });

      const elementIds = [];
      const newOpenStates = {};
      newCourseElements.forEach((newCourseElement) => {
        const newElementId = newCourseElement.elementId;
        newOpenStates[newElementId] = false;

        newCourseElement.courseContentItemId = courseId;

        courseElementMap.set(newElementId, newCourseElement);

        elementIds.push(newElementId);
      });

      courseElement['children'] = elementIds;

      setObjectOpenStates((prevState) => {
        prevState[courseElementId] = !prevState[courseElementId];
        return { ...prevState, ...newOpenStates };
      });
    } else {
      setObjectOpenStates((prevState) => {
        prevState[courseElementId] = !prevState[courseElementId];
        return { ...prevState };
      });
    }
  };

  const handleCourseElementCheckbox = async (event) => {
    const elementId = event.target.defaultValue;
    if (addCourseElementToCourse.has(elementId)) {
      addCourseElementToCourse.delete(elementId);

      // also uncheck create assignment and student review if checked
      let update = false;
      if (createAssignment.has(elementId)) {
        createAssignment.delete(elementId);
        update = true;
      }

      if (allowStudentReview.has(elementId)) {
        allowStudentReview.delete(elementId);
        update = true;
      }

      if (update) {
        forceUpdate();
      }
    } else {
      addCourseElementToCourse.add(elementId);
    }
    forceUpdate();
  };

  const handleAssignmentCheckbox = async (event) => {
    const elementId = event.target.defaultValue;
    if (createAssignment.has(elementId)) {
      createAssignment.delete(elementId);

      // also uncheck student review if checked because student review is meaningless if not assigned
      if (allowStudentReview.has(elementId)) {
        allowStudentReview.delete(elementId);
        forceUpdate();
      }
    } else {
      createAssignment.add(elementId);
    }
  };

  const handleStudentReviewCheckbox = async (event) => {
    const elementId = event.target.defaultValue;
    if (allowStudentReview.has(elementId)) {
      allowStudentReview.delete(elementId);
    } else {
      allowStudentReview.add(elementId);

      // also check create assignment if unchecked because student review is meaningless if not assigned
      if (!createAssignment.has(elementId)) {
        createAssignment.add(elementId);
        forceUpdate();
      }
    }
  };

  const CourseElementRow = (props) => {
    const { elementId, level } = props;
    const courseElement = courseElementMap.get(elementId);
    const { courseContentItemId, deliveryMode, autoScored } = courseElement;
    let { name } = courseElement;
    let subtitle = courseElement.description || courseElement.contentItemDescription;
    const openedBlind = <i aria-hidden='true' className='caret down icon' />;
    const closedBlind = <i aria-hidden='true' className='caret right icon' />;
    const isOpen = objectOpenStates[elementId];
    const blindIcon = (isOpen) ? openedBlind : closedBlind;
    let blind = null;
    let handleClick = null;
    if (!deliveryMode) {
      blind = <div className='blind-div' data-course-element-id={elementId} data-course-id={courseContentItemId}>{blindIcon}</div>;
      handleClick = handleCourseElementClick;
    }

    const indent = indentBase + (level * indentFactor);

    const studentAssignable = isStudentAssignable(deliveryMode);
    const teacherOnly = deliveryMode && !studentAssignable;
    let assignButton = null;
    let previewButton = null;
    let addToCourseCheckbox = null;
    let assignmentCheckbox = null;
    let studentReviewCheckbox = null;
    // disable checkboxes to the right if left checkbox is not checked
    const disabled = (addCourseElementToCourse.has(elementId)) ? '' : 'disabled';

    if (studentAssignable || teacherOnly) {
      addToCourseCheckbox = (
        <input
          defaultChecked={addCourseElementToCourse.has(elementId)}
          onChange={handleCourseElementCheckbox}
          type='checkbox'
          value={elementId}
        />
      );
    } else {
      addToCourseCheckbox = (
        <input
          disabled
          type='checkbox'
        />
      );
    }

    if (studentAssignable) {
      assignButton = (
        <PopupButton
          basic
          buttonLabel='Add'
          onClick={() => openAddAssignmentDialog(elementId)}
          primary />
      );

      previewButton = (
        <PopupButton
          basic
          buttonLabel='Preview'
          onClick={() => openPreview(elementId)}
          primary />
      );

      assignmentCheckbox = (
        <input
          defaultChecked={createAssignment.has(elementId)}
          disabled={disabled}
          onChange={handleAssignmentCheckbox}
          type='checkbox'
          value={elementId}
        />
      );

      studentReviewCheckbox = (
        <Popup
          content={(<div>By checking this box, students can see the correct answer for this activity after completion.</div>)}
          offset={[-21, 0]}
          trigger={(
            <div>
              <input
                defaultChecked={allowStudentReview.has(elementId)}
                disabled={disabled}
                onChange={handleStudentReviewCheckbox}
                type='checkbox'
                value={elementId}
              />
            </div>
          )}
        />
      );
    }

    if (teacherOnly) {
      assignmentCheckbox = (
        <input
          disabled
          type='checkbox'
        />
      );

      studentReviewCheckbox = (
        <input
          disabled
          type='checkbox'
        />
      );

      // this makes the preview button availble for teacher only content on content mode page
      if (mode === 'content') {
        previewButton = (
          <PopupButton
            basic
            buttonLabel='Preview'
            onClick={() => openPreview(elementId)}
            primary />
        );
      }
    }

    let scoring = null;
    if (studentAssignable && !autoScored) {
      scoring = 'Manual Grade';
    }

    const deliveryModeText = getDeliveryModeText(deliveryMode);

    name = ReactHtmlParser(name);
    subtitle = ReactHtmlParser(subtitle);

    const { children } = courseElement;

    const nextLevel = level + 1;

    const popup = (
      <div className='lti-assign-course-label'>
        <div className='lti-assign-course-name'>{name}</div>
        {subtitle && <div className='lti-assign-course-description'>{subtitle}</div>}
      </div>
    );

    const courseElementLabel = (
      <div className='lti-assign-course-label' data-course-element-id={elementId} data-course-id={courseContentItemId}>
        <div className='lti-assign-course-name ellipsis'>{name}</div>
        {subtitle && <div className='lti-assign-course-description ellipsis'>{subtitle}</div>}
      </div>
    );

    return (
      <>
        <Popup
          content={(<div>{popup}</div>)}
          offset={[indent + popupOffset, 0]}
          trigger={(
            <div
              className='col1'
              data-course-element-id={elementId}
              data-course-id={courseContentItemId}
              onClick={handleClick}
              style={{ paddingLeft: indent }}
            >
              <div className='inner-cell'>
                {mode === 'content' && (
                  <div className='content-checkbox'>{addToCourseCheckbox}</div>
                )}
                <div className='inner-cell-text'>
                  {blind}
                  <div className='label-div'>{courseElementLabel}</div>
                </div>
              </div>
            </div>
          )}
          wide='very'
        />
        <div className='col2'><span>{scoring}</span></div>
        <div className='col3'><span>{deliveryModeText}</span></div>
        {mode === 'assignment' && (
          <>
            <div className='col4'><span>{assignButton}</span></div>
            <div className='col5'><span>{previewButton}</span></div>
          </>
        )}
        {mode === 'content' && (
          <>
            <div className='col5'><span>{previewButton}</span></div>
            <div className='col6'>{assignmentCheckbox}</div>
            <div className='col7'>{studentReviewCheckbox}</div>
          </>
        )}

        {/* show the course elements of this course element */}
        {isOpen && children.map((elementId) => {
          return (
            <CourseElementRow
              key={elementId}
              elementId={elementId}
              level={nextLevel}
            />
          );
        })}
      </>
    );
  };

  const CourseRow = (props) => {
    const { courseId } = props;
    const courseMap = courseManager.productCourses;
    const course = courseMap.get(courseId);
    const { id } = course;
    let { name, description } = course;
    const openedBlind = <i aria-hidden='true' className='caret down icon' />;
    const closedBlind = <i aria-hidden='true' className='caret right icon' />;
    const isOpen = objectOpenStates[courseId];
    const blindIcon = (isOpen) ? openedBlind : closedBlind;

    const addToCourseCheckbox = (
      <input
        disabled
        type='checkbox'
      />
    );

    const { children } = course;

    name = ReactHtmlParser(name);
    description = ReactHtmlParser(description);

    const popup = (
      <div className='lti-assign-course-label'>
        <div className='lti-assign-course-name'>{name}</div>
        {description && <div className='lti-assign-course-description'>{description}</div>}
      </div>
    );

    const courseLabel = (
      <div className='lti-assign-course-label' data-course-id={id}>
        <div className='lti-assign-course-name ellipsis'>{name}</div>
        {description && <div className='lti-assign-course-description ellipsis'>{description}</div>}
      </div>
    );

    return (
      <>
        <Popup
          content={(<div>{popup}</div>)}
          offset={[popupOffset, 0]}
          trigger={(
            <div
              className='col1'
              data-course-id={id}
              onClick={handleCourseClick}
              style={{ paddingLeft: indentBase }}
            >
              <div className='inner-cell'>
                {mode === 'content' && (
                  <div className='content-checkbox'>{addToCourseCheckbox}</div>
                )}
                <div className='inner-cell-text' data-course-id={id}>
                  <div className='blind-div' data-course-id={id}>{blindIcon}</div>
                  <div className='label-div'>{courseLabel}</div>
                </div>
              </div>
            </div>
          )}
          wide='very'
        />
        <div className='col2' />
        <div className='col3' />
        {mode === 'assignment' && (
          <>
            <div className='col4' />
            <div className='col5' />
          </>
        )}
        {mode === 'content' && (
          <>
            <div className='col5' />
            <div className='col6' />
            <div className='col7' />
          </>
        )}

        {/* show the course elements of this course */}
        {isOpen && children.map((elementId) => {
          return (
            <CourseElementRow
              key={elementId}
              elementId={elementId}
              level={1}
            />
          );
        })}
      </>
    );
  };

  const AddContentButton = () => {
    return (
      <div className='add-content-button'>
        <PopupButton
          buttonLabel='+ Add All Selected Content'
          onClick={() => addContent()}
          primary
        />
      </div>
    );
  };

  const HeaderRow = () => {
    return (
      <>
        <div className='headerCol1'><div>Add to<br />Course</div></div>
        <div />
        <div />
        <div />
        <div className='headerCol6'>Create<br />Assignment</div>
        <div className='headerCol7'>Allow Student<br />Review</div>
      </>
    );
  };

  let statusMessage = '';
  if (status === 'loading') {
    statusMessage = 'Loading...';
  } else if (status === 'loaded' && courseIds.length === 0) {
    statusMessage = 'You do not have access to any courses.';
  } else if (status === 'unauthorized') {
    statusMessage = 'Unauthorized';
  } else {
    statusMessage = '';
  }

  if (previewCourseElement) {
    return (
      <LTIPreview
        close={closePreview}
        courseElement={previewCourseElement}
      />
    );
  }

  return (
    <>
      <div className='outer-container'>
        <div className={`grid-container ${mode}`}>
          {(statusMessage) && <div className='status-message'>{statusMessage}</div>}
          {/* show the courses and their course elements */}
          {mode === 'content' && <AddContentButton />}
          {mode === 'content' && <HeaderRow />}
          {courseIds.map((courseId) => {
            return (
              <CourseRow
                key={courseId}
                courseId={courseId}
              />
            );
          })}
        </div>
      </div>
      <Dimmer active={isAddingContent} page>
        <Loader size='big'>Adding Content</Loader>
      </Dimmer>
    </>
  );
});
export default LTICourses;

SatCoreRegister('LTICourses', LTICourses);
