import React, { useContext, useEffect, useState } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import '../css/ClassCourses.less';
import '../css/TeacherProductView.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import TeacherProductViewService from '../services/TeacherProductViewService';

const TeacherProductListener = observer((props) => {
  const { t } = props;

  const { classroomManager, teacherProductViewManager } = useContext(MobXProviderContext);

  const [loadingTeacherProductListener, setLoadingTeacherProductListener] = useState(true);

  const ClassroomSelectionModal = SatCoreComponent('ClassroomSelectionModal');
  const SatCoreLoader = SatCoreComponent('SatCoreLoader');

  useEffect(() => {
    (async () => {
      setLoadingTeacherProductListener(true);
      // placeholder
      setLoadingTeacherProductListener(false);
    })();
  }, []);

  const renderClassroomSelectionModalIfApplicable = () => {
    const classrooms = classroomManager.classroomsArray.filter((classroom) => {
      return !classroom.archived;
    });
    return (
      <ClassroomSelectionModal
        classrooms={classrooms}
        isOpen={teacherProductViewManager.isClassroomSelectionModalOpen}
        label={classrooms?.length ? t('classroomSelectionModalBannerLabel') : t('classroomSelectionModalBannerZeroStateLabel')}
        onClose={handleCloseClassroomSelectionModal}
        t={t} />
    );
  };

  const handleCloseClassroomSelectionModal = async (event, buttonActionObj, selectedClassroomsMap) => {
    if (buttonActionObj?.className?.includes?.('add') && selectedClassroomsMap?.size) {
      await TeacherProductViewService.addTeacherProductCoursesToClassroom({
        currentTeacherProductCourse: teacherProductViewManager.currentTeacherProductCourse,
        selectedClassroomsMap
      });
    }
    const userClickedActionButton = !!event && !!buttonActionObj;
    const userPressedEscapeButtonOrClickedExitIcon = !event;
    if (userClickedActionButton || userPressedEscapeButtonOrClickedExitIcon) {
      teacherProductViewManager.setCurrentTeacherProductCourse(null);
      teacherProductViewManager.setIsClassroomSelectionModalOpen(false);
    }
  };

  const renderTeacherProductListener = () => {
    return (
      <>
        {renderClassroomSelectionModalIfApplicable()}
      </>
    );
  };

  return !loadingTeacherProductListener ? renderTeacherProductListener() : <SatCoreLoader />;
});

export default TeacherProductListener;

SatCoreRegister('TeacherProductListener', TeacherProductListener);
