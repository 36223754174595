import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../css/ClassroomInfoBanner.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import ClassroomService from '../services/ClassroomService';

const ClassroomInfoBanner = observer((props) => {
  const { classroom, style, t } = props;

  const classroomInfo = classroom && ClassroomService.getClassroomInfoFromClassroomObj(classroom);

  const { /* managers */ } = useContext(MobXProviderContext);

  const renderClassroomInfoSection = (labelKey, content) => {
    return (
      <div className={`classroom-info-section ${labelKey}`}>
        {labelKey && labelKey !== 'classroomName' && (
          <div className='label'>
            {labelKey !== 'classroomName' && `${t(labelKey)}:`}&nbsp;
          </div>
        )}
        {content && (
          <div className='content'>
            {content}
          </div>
        )}
      </div>
    );
  };

  return classroom && classroomInfo && (
    <div className='classroom-info-banner' style={style}>
      <div className='classroom-info-sections'>
        {renderClassroomInfoSection('classroomName', `{${classroomInfo.name || t('classroom')}}`)}
        {renderClassroomInfoSection('classroomId', classroomInfo.id)}
        {renderClassroomInfoSection('institutionName', classroomInfo.institutionName)}
        {renderClassroomInfoSection('leadTeacherName', classroomInfo.leadTeacherName)}
      </div>
    </div>
  );
});
export default ClassroomInfoBanner;

SatCoreRegister('ClassroomInfoBanner', ClassroomInfoBanner);
