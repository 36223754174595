import React from 'react';
import { CourseListBanner, SatCoreRegister } from 'sat-core';

const CourseListBannerOverride = (props) => {
  const { showBreadCrumbs, showBreadCrumbsDropdown } = props;
  return (
    <CourseListBanner
      {...props}
      showBreadCrumbs={showBreadCrumbs && 'top'}
      showBreadCrumbsDropdown={showBreadCrumbsDropdown && 'top'} />
  );
};

SatCoreRegister('CourseListBanner', CourseListBannerOverride);
