import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Popup } from 'semantic-ui-react';
import '../css/SCRadio.less';
import { SatCoreRegister } from '../SatCoreRegistry';
import UtilityService from '../services/UtilityService';

const transformFn = UtilityService.reactHtmlParserTransform;

const SCRadio = (props) => {
  const {
    additionalClassNames = '',
    checked,
    disabled,
    hoverLabelContent,
    label,
    name,
    onChange,
    useHoverLabel,
    value
  } = props;
  const transformedLabel = ReactHtmlParser(label, {
    transform: transformFn
  });
  const content = (useHoverLabel && hoverLabelContent) ? hoverLabelContent : <div>{transformedLabel}</div>;
  return (
    <div className={`sc-radio${additionalClassNames ? ` ${additionalClassNames}` : ''}`}>
      {/* eslint-disable-next-line no-nested-ternary */}
      <label className={(disabled) ? 'radio-label disabled' : (checked) ? 'radio-label checked' : 'radio-label'}>
        <input
          checked={checked}
          disabled={disabled}
          name={name}
          onChange={onChange}
          type='radio'
          value={value} />
        <div className='radio-button' />
        {(useHoverLabel) ? (
          <span>
            <Popup
              content={content}
              hoverable
              mouseEnterDelay={300}
              mouseLeaveDelay={0}
              on='hover'
              trigger={<span>{transformedLabel}</span>} />
          </span>
        )
          :
          ReactHtmlParser(label, {
            transform: transformFn
          })}
      </label>
    </div>
  );
};

export default SCRadio;

SatCoreRegister('SCRadio', SCRadio);
