import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

// import { register } from '../../../i18n';

import EngagementService from '../../../services/EngagementService';

const EngagementRowAuxiliaryAnnotationsUsageCells = observer((props) => {
  const { engagementData } = props;

  const { viewBookmarkCount, viewRawEliminatorCount, viewRawHatCount, viewRawStickyNotesCount } = engagementData;

  const { /* managers */ } = useContext(MobXProviderContext);

  // const t = register('EngagementTable');

  const renderEngagementRowAuxiliaryAnnotationsUsageCells = () => {
    return (
      <>
        {EngagementService.allowEngagementColumn('viewHatAvailable') &&
          renderEngagementRowAuxiliaryAnnotationsUsageCell({
            allowPopupComponent: true,
            className: 'engagement-body-label annotations-usage-detail-body engagedHighlightUsage',
            popupContent: '',
            popupDisabled: true,
            popupTrigger: viewRawHatCount || 0 // Highlight
          })}
        {EngagementService.allowEngagementColumn('viewEliminatorAvailable') &&
          renderEngagementRowAuxiliaryAnnotationsUsageCell({
            allowPopupComponent: true,
            className: 'engagement-body-label annotations-usage-detail-body engagedStrikethroughUsage',
            popupContent: '',
            popupDisabled: true,
            popupTrigger: viewRawEliminatorCount || 0 // Strikethrough
          })}
        {EngagementService.allowEngagementColumn('viewStickyNotesAvailable') &&
          renderEngagementRowAuxiliaryAnnotationsUsageCell({
            allowPopupComponent: true,
            className: 'engagement-body-label annotations-usage-detail-body engagedStickyNotesUsage',
            popupContent: '',
            popupDisabled: true,
            popupTrigger: viewRawStickyNotesCount || 0 // Sticky Notes
          })}
        {EngagementService.allowEngagementColumn('viewBookmarkAvailable') &&
          renderEngagementRowAuxiliaryAnnotationsUsageCell({
            allowPopupComponent: true,
            className: 'engagement-body-label annotations-usage-detail-body engagedMarkForReviewUsage',
            popupContent: '',
            popupDisabled: true,
            popupTrigger: viewBookmarkCount || 0 // Marked for Review
          })}
      </>
    );
  };

  const renderEngagementRowAuxiliaryAnnotationsUsageCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementRowAuxiliaryAnnotationsUsageCells();
});
export default EngagementRowAuxiliaryAnnotationsUsageCells;

SatCoreRegister('EngagementRowAuxiliaryAnnotationsUsageCells', EngagementRowAuxiliaryAnnotationsUsageCells);
