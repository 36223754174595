/* eslint-disable sort-keys */
import { action, computed, makeObservable, observable } from 'mobx';
import ReactHtmlParser from 'react-html-parser';
import Auth from './AuthManager';
import UtilityService from '../services/UtilityService';

// TODO: this is Gallo only.
export const ONLINE_CONTENT_TYPES = {
  ARTISTIC_EXPRESSION: 'Artistic Expression',
  BACKMATTER: 'BackMatter',
  BIOGRAPHY_READER: 'Biography Reader',
  BIOGRAPHY_RECAP: 'Biography Recap',
  BIOGRAPHY_WORKSHEET: 'Biography Worksheet',
  CHAPTER_OPENER: 'Chapter Opener',
  CHAPTER_PLANNER: 'Chapter Planner',
  CLICKBOOK: 'Clickbook',
  CUSTOM_EXPERTRACK: 'Custom ExperTrack',
  DOCUMENT_BASED_QUESTIONS: 'Document-Based Questions',
  ESSENTIAL_QUESTIONS: 'Essential Questions',
  EXPECTATIONS_FOR_LEARNING: 'Expectations for Learning',
  EXPERTRACK: 'ExperTrack',
  FRONTMATTER: 'FrontMatter',
  GRAPHIC_ORGANIZER: 'Graphic Organizer',
  IMAGE_GALLERY: 'Image Gallery',
  INQUIRY_BASED_LEARNING: 'Inquiry-Based Learning',
  INTERACTIVE_READ_ALOUD: 'Interactive Read Aloud',
  INTERACTIVE_WORKBOOK: 'Interactive Workbook',
  INTERNET_LINK: 'Internet Link',
  INTRODUCTION: 'Introduction',
  LEARNING_OBJECTIVES: 'Learning Objectives',
  LEARNING_OBJECTIVES_CORRELATIONS: 'Learning Objectives & Correlations',
  LESSON_OPENER: 'Lesson Opener',
  LESSON_PLANNER: 'Lesson Planner',
  LETS_PRACTICE: 'Let\'s Practice',
  LETS_RESEARCH: 'Let\'s Research',
  LETS_REVIEW: 'Let\'s Review',
  LEVELED_LITERACY: 'Leveled Literacy',
  LITERACY: 'Literacy',
  MAP_SKILLS: 'Map Skills',
  ONE_MORE_FOR_FUN: 'One More For Fun!',
  PACING_GUIDE: 'Pacing Guide',
  PRIMARY_SOURCE_ANALYSIS: 'Primary Source Analysis',
  PROJECT_BASED_LEARNING: 'Project-Based Learning',
  SECTION_OPENER: 'Section Opener',
  SECTION_PLANNER: 'Section Planner',
  SOCIAL_STUDIES_THEMES: 'Social Studies Themes',
  STUDENT_BOOK: 'Student Book',
  STUDENT_WORKBOOK: 'Student Workbook',
  STUDY_GUIDE: 'Study Guide',
  TEACHERS_EDITION: 'Teacher\'s Edition',
  UNIT_OPENER: 'Unit Opener',
  UNIT_PLANNER: 'Unit Planner',
  VIDEO: 'Video',
  VOCABULARY_BUILDER: 'Vocabulary Builder',
  VOCABULARY_QUIZ: 'Vocabulary Quiz',
  VOCABULARY_RESOURCE: 'Vocabulary Resource',
  VOCABULARY_REVIEW: 'Vocabulary Review',
  WRITING_PROMPT: 'Writing Prompt',
  getContentImageUrl: (name) => {
    // TODO: REPLACE GALLOPADE WITH PUBLISHER NAME IN PREFIX OR MOVE THIS TO GALLO, SINCE IT IS LIKELY UNIQUE TO THEM.
    const prefix = 'https://c2c-satellite-assets.s3.amazonaws.com/gallopade/images/';

    if (name === ONLINE_CONTENT_TYPES.ARTISTIC_EXPRESSION) return `${prefix}cardimage-artistic_expression.png`;
    if (name === ONLINE_CONTENT_TYPES.BIOGRAPHY_READER) return `${prefix}cardimage-biography_reader.png`;
    if (name === ONLINE_CONTENT_TYPES.BIOGRAPHY_RECAP) return `${prefix}cardimage-biography_recap.png`;
    if (name === ONLINE_CONTENT_TYPES.BIOGRAPHY_WORKSHEET) return `${prefix}cardimage-biography_worksheet.png`;
    if (name === ONLINE_CONTENT_TYPES.CLICKBOOK) return `${prefix}cardimage-clickbook.png`;
    if (name === ONLINE_CONTENT_TYPES.ESSENTIAL_QUESTIONS) return `${prefix}cardimage-essential_questions.png`;
    if (name === ONLINE_CONTENT_TYPES.EXPERTRACK) return `${prefix}cardimage-expertrack.png`;
    if (name === ONLINE_CONTENT_TYPES.CUSTOM_EXPERTRACK) return `${prefix}cardimage-custom-expertrack.png`;
    if (name === ONLINE_CONTENT_TYPES.IMAGE_GALLERY) return `${prefix}cardimage-image_gallery.png`;
    if (name === ONLINE_CONTENT_TYPES.GRAPHIC_ORGANIZER) return `${prefix}cardimage-graphic_organizer.png`;
    if (name === ONLINE_CONTENT_TYPES.INQUIRY_BASED_LEARNING) return `${prefix}`;
    if (name === ONLINE_CONTENT_TYPES.INTERACTIVE_READ_ALOUD) return `${prefix}cardimage-interactive_read_aloud.png`;
    if (name === ONLINE_CONTENT_TYPES.INTERACTIVE_WORKBOOK) return `${prefix}cardimage-interactive_workbook.png`;
    if (name === ONLINE_CONTENT_TYPES.LEARNING_OBJECTIVES_CORRELATIONS) return `${prefix}`;
    if (name === ONLINE_CONTENT_TYPES.LETS_PRACTICE) return `${prefix}cardimage_lets_practice.png`;
    if (name === ONLINE_CONTENT_TYPES.LETS_RESEARCH) return `${prefix}cardimage-lets_research.png`;
    if (name === ONLINE_CONTENT_TYPES.LETS_REVIEW) return `${prefix}cardimage-lets_review.png`;
    if (name === ONLINE_CONTENT_TYPES.LEVELED_LITERACY) return `${prefix}cardimage-leveled_literacy.png`;
    if (name === ONLINE_CONTENT_TYPES.LITERACY) return `${prefix}cardimage-literacy.png`;
    if (name === ONLINE_CONTENT_TYPES.MAP_SKILLS) return `${prefix}cardimage-map_skills.png`;
    if (name === ONLINE_CONTENT_TYPES.ONE_MORE_FOR_FUN) return `${prefix}cardimage-one_more_for_fun.png`;
    if (name === ONLINE_CONTENT_TYPES.SECTION_PLANNER) return `${prefix}cardimage-section_planner.png`;
    if (name === ONLINE_CONTENT_TYPES.PRIMARY_SOURCE_ANALYSIS) return `${prefix}cardimage_primary_source_analysis.png`;
    if (name === ONLINE_CONTENT_TYPES.SOCIAL_STUDIES_THEMES) return `${prefix}cardimage-social_studies_themes.png`;
    if (name === ONLINE_CONTENT_TYPES.STUDENT_WORKBOOK) return `${prefix}cardimage-student_book.png`;
    if (name === ONLINE_CONTENT_TYPES.STUDENT_BOOK) return `${prefix}cardimage-student_book.png`;
    if (name === ONLINE_CONTENT_TYPES.STUDY_GUIDE) return `${prefix}cardimage-study_guide.png`;
    if (name === ONLINE_CONTENT_TYPES.VOCABULARY_BUILDER) return `${prefix}cardimage-vocabulary_builder.png`;
    if (name === ONLINE_CONTENT_TYPES.VOCABULARY_QUIZ) return `${prefix}cardimage-vocabulary_quiz.png`;
    if (name === ONLINE_CONTENT_TYPES.VOCABULARY_REVIEW) return `${prefix}cardimage-vocabulary_review.png`;
    if (name === ONLINE_CONTENT_TYPES.WRITING_PROMPT) return `${prefix}cardimage-writing_prompt.png`;
    if (name === ONLINE_CONTENT_TYPES.EXPECTATIONS_FOR_LEARNING) return `${prefix}cardimage_expectations_for_learning.png`;
    if (name === ONLINE_CONTENT_TYPES.CHAPTER_OPENER) return `${prefix}cardimage-chapter_opener.png`;
    if (name === ONLINE_CONTENT_TYPES.CHAPTER_PLANNER) return `${prefix}cardimage-chapter_planner.png`;
    if (name === ONLINE_CONTENT_TYPES.DOCUMENT_BASED_QUESTIONS) return `${prefix}cardimage-dbq.png`;
    if (name === ONLINE_CONTENT_TYPES.INTERNET_LINK) return `${prefix}cardimage-internet_link.png`;
    if (name === ONLINE_CONTENT_TYPES.INTRODUCTION) return `${prefix}cardimage-introduction.png`;
    if (name === ONLINE_CONTENT_TYPES.LEARNING_OBJECTIVES) return `${prefix}cardimage-learning_objectives.png`;
    if (name === ONLINE_CONTENT_TYPES.LESSON_OPENER) return `${prefix}cardimage-lesson_opener.png`;
    if (name === ONLINE_CONTENT_TYPES.LESSON_PLANNER) return `${prefix}cardimage-lesson_planner.png`;
    if (name === ONLINE_CONTENT_TYPES.PACING_GUIDE) return `${prefix}cardimage-pacing_guide.png`;
    if (name === ONLINE_CONTENT_TYPES.PROJECT_BASED_LEARNING) return `${prefix}cardimage-project.png`;
    if (name === ONLINE_CONTENT_TYPES.SECTION_OPENER) return `${prefix}cardimage-section_opener.png`;
    if (name === ONLINE_CONTENT_TYPES.UNIT_OPENER) return `${prefix}cardimage-unit_opener.png`;
    if (name === ONLINE_CONTENT_TYPES.UNIT_PLANNER) return `${prefix}cardimage-unit_planner.png`;
    if (name === ONLINE_CONTENT_TYPES.VIDEO) return `${prefix}cardimage-video.png`;
    if (name === ONLINE_CONTENT_TYPES.VOCABULARY_RESOURCE) return `${prefix}cardimage-vocabulary_resource.png`;

    // FORGOTTEN TYPES
    if (name === ONLINE_CONTENT_TYPES.BACKMATTER) return '';
    if (name === ONLINE_CONTENT_TYPES.FRONTMATTER) return '';
    if (name === ONLINE_CONTENT_TYPES.TEACHERS_EDITION) return '';
  }
};

export class TagContentManager {
  // @observable tagContentItemIds = [];

  @observable tagContentItemIdSet = new Set();

  @observable contentCategoryTags = new Map();

  @observable cardContentTags = new Map();

  @observable useContentTags = false;

  @observable categoriesWithTags = new Map();
  @observable previousSatelliteComponent = 'ALL';

  @observable tagIdToNameMap = new Map();

  @observable customCategoryNameA = null;

  @observable customCategoryNameB = null;

  @observable authorityStates = [];

  @observable standardDocuments = [];

  @observable standardToBookmarkMap = new Map();

  @observable bookmarkedStandardIds = [];

  constructor() {
    makeObservable(this);
  }

  @computed get tagContentItemIds() {
    return Array.from(this.tagContentItemIdSet.values());
  }

  getTagContentItemIds = () => this.tagContentItemIds

  @computed get categoriesWithTagsArray() {
    if (this.categoriesWithTags) {
      return Array.from(this.categoriesWithTags.values());
    }
    return null;
  }

  @action clearTagContentItemIds = () => {
    this.tagContentItemIdSet = new Set();
  }

  @action addTagId = (id) => {
    if (id) {
      // this.tagContentItemIds.push(id);
      this.tagContentItemIdSet.add(id);
    }
  }

  // This loads a categoryId:categorytags map and tagId:tag map
  @action loadCategoriesWithTags = (cswt) => {
    if (cswt) {
      // sort
      // cswt.sort((a, b) => (a.name > b.name) ? 1 : -1);

      cswt.forEach((cwt) => {
        if (cwt.id !== null && cwt.id !== '' && cwt.id !== undefined && cwt.visible) {
          this.categoriesWithTags.set(cwt.id, cwt);
          const sortClass = this.getCustomCategoryNameClass(cwt.name);
          cwt.tags.forEach((tag) => {
            if (tag.id !== null && tag.id !== '' && tag.id !== undefined) {
              tag.sortClass = sortClass;
              tag.sortBy = sortClass + tag.name;
              this.tagIdToNameMap.set(tag.id, tag);
            }
          });
        }
      });
    }
  }

  // @computed get tagIdToNameArray() {
  //   if (this.tagIdToNameMap) {
  //     return Array.from(toJS(this.tagIdToNameMap).values());
  //   }
  //   return [];
  // }

  @action initContentCategoryTags(categoryTags) {
    this.contentCategoryTags.clear();
    this.loadContentCategoryTags(categoryTags);
  }

  @action loadContentCategoryTags = (categoryTags) => {
    if (categoryTags) {
      categoryTags.forEach((categoryTag) => {
        if (categoryTag.id !== null) {
          this.contentCategoryTags.set(categoryTag.id, categoryTag);
        }
      });
    }
  }

  @action initCardContentTags(cardContentTagsMap, contentItemIds) {
    for (const k of contentItemIds) {
      this.cardContentTags.set(k, null);
    }
    for (const k of Object.keys(cardContentTagsMap)) {
      this.cardContentTags.set(k, cardContentTagsMap[k]);
    }
  }

  @computed get contentCategoryTagArray() {
    if (this.contentCategoryTags) {
      return Array.from(this.contentCategoryTags.values());
    }
    return null;
  }

  @action setContentCategoryTags(tags) {
    if (tags != null) {
      this.contentCategoryTags.concat(tags);
    }
  }

  // DEMO-1833
  @action setAuthorityStates(states) {
    if (states != null) {
      this.authorityStates = states;
    }
  }

  @action setStandardDocuments(docs) {
    if (docs != null) {
      this.standardDocuments = docs;
      console.log(this.standardDocuments);
    }
  }

  @computed get standardBookmarkArray() {
    if (this.standardToBookmarkMap) {
      return Array.from(this.standardToBookmarkMap.values());
    }
    return null;
  }

  @action getStandardDocuments = () => this.standardDocuments;

  @action setBookmarkedStandardIds(ids) {
    if (ids != null) {
      this.bookmarkedStandardIds = ids;
    }
  }

  setUseContentTags = (useContentTags) => {
    this.useContentTags = useContentTags;
  }

  setCustomCategoryNameA = (name) => {
    this.customCategoryNameA = name;
  }

  setCustomCategoryNameB = (name) => {
    this.customCategoryNameB = name;
  }

  setCustomCategoryNameC = (name) => {
    this.customCategoryNameC = name;
  }

  getCustomCategoryNameClass = (name) => {
    if (name === this.customCategoryNameA) {
      return 'a-custom-category';
    } else if (name === this.customCategoryNameB) {
      return 'b-custom-category';
    } else if (name === this.customCategoryNameC) {
      return 'c-custom-category';
    }
    return 'z-custom-category';
  }

  // When we version content, we swap out the contentItemId at first use. Duplicate cardTag for new.
  createNewCardContentTagFromOld(oldContentItemId, newContentItemId) {
    if (oldContentItemId !== newContentItemId) {
      const old = this.getCardContentTag(oldContentItemId);
      this.cardContentTags.set(newContentItemId, old);
    }
  }

  getContentCategoryTag = (id) => {
    if (id) {
      const contentCategoryTag = this.contentCategoryTags.get(id);
      if (contentCategoryTag !== null && contentCategoryTag != undefined) {
        return this.contentCategoryTags.get(id);
      }
    }
    return null;
  }

  getContentCategoryTagName = (id) => {
    const contentCategoryTag = this.getContentCategoryTag(id);

    if (contentCategoryTag === null || contentCategoryTag === undefined) {
      return '';
    }
    if (contentCategoryTag.name) {
      const { transformFn } = UtilityService;
      return ReactHtmlParser(contentCategoryTag.name, {
        transform: transformFn
      });
    }
    return '';
  }

  getCardContentTag = (id) => {
    if (id) {
      const cardContentTag = this.cardContentTags.get(id);
      if (cardContentTag !== null && cardContentTag != undefined) {
        return cardContentTag;
      }
    }
    return null;
  }

  getCardContentTagName = (id) => {
    const cardContentTag = this.getCardContentTag(id);
    if (cardContentTag === null || cardContentTag === undefined) {
      return '';
    }
    if (cardContentTag[0].name) {
      return cardContentTag[0].name;
    }
    return '';
  }

  getCardContentImage = (id) => {
    const cardContentTag = this.getCardContentTag(id);
    if (cardContentTag === null || cardContentTag === undefined) {
      return '';
    }
    if (cardContentTag[0].name) {
      return cardContentTag[0].name;
    }
    return '';
  }

  getSortedTagsForAssessmentItem = (assessmentItem, doSort = false) => {
    const tagArray = [];
    if (assessmentItem.tagList !== null && assessmentItem.tagList !== '' && assessmentItem.tagList !== undefined) {
      const idArray = assessmentItem.tagList.split(',');
      idArray.map((tId, index) => {
        const tag = this.tagIdToNameMap.get(tId);
        if (tag !== null) {
          tagArray.push(tag);
        }
      });

      if (doSort) {
        tagArray.sort((a, b) => ((a.sortBy > b.sortBy) ? 1 : -1));
      }
    }

    const filteredTagArray = tagArray.filter((t) => t != null);
    return filteredTagArray;
  }

  getSortedTagsForTagIdArray = (idArray) => {
    const tagArray = [];
    if (idArray.length > 0) {
      idArray.map((tId, index) => {
        const tag = this.tagIdToNameMap.get(tId);
        if (tag !== null) {
          tagArray.push(tag);
        }
      });
      tagArray.sort((a, b) => ((a.sortBy > b.sortBy) ? 1 : -1));
    }

    const filteredTagArray = tagArray.filter((t) => t != null);
    return filteredTagArray;
  }

  fetchTagsByCategoryAndContentItems = async (contentItemIds, typeName = 'Content Type') => {
    try {
      const newContentIds = [];
      contentItemIds.forEach((id) => {
        if (!this.cardContentTags.has(id)) {
          newContentIds.push(id);
        }
      });
      if (newContentIds.length > 0) {
        const url = `${Auth.ecms}/api/getContentItemsTagsByCategoryName?tagCategoryName=${typeName}&contentItemIds=${contentItemIds}`;
        const response = await Auth.fetch(url, {
          method: 'GET'
        });
        if (response.status === 'SUCCESS') {
          this.initCardContentTags(response.categoryTags, contentItemIds);
        }
      }
    } catch (error) {
      console.log(error.message);
      return false;
    }
  }

  fetchContentCategoryTagsByName = async (typeName = 'Content Type') => {
    try {
      if (!this.useContentTags) {
        return false;
      }

      const url = `${Auth.ecms}/api/categoryTagsByName?categoryName=${typeName}`;
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        this.initContentCategoryTags(response.categoryTags);
      }
    } catch (error) {
      console.log(error.message);
      return false;
    }
  }

  fetchAvailableCategoriesWithTags = async (entityTypeIds, satelliteComponent) => {
    try {
      if (entityTypeIds === null) {
        entityTypeIds = 'article_resource,audio_resource,bookmark_resource,mathia_resource,google_doc_resource,google_sheet_resource,google_slide_resource,google_resource,file_resource,pdf_resource,item_resource,image_resource,video_resource,assessment,learnosity_item_resource,learnosity_activity_resource,lesson,lesson_plan,course_resource,curriculum_map,rubric';
      }
      if (satelliteComponent === null) {
        satelliteComponent = 'ALL';
      }
      // This does a lot. It's cached, no need to do it again.
      if (this.categoriesWithTags.size > 0 && this.previousSatelliteComponent === satelliteComponent) {
        return false;
      } else {
        this.categoriesWithTags = new Map();
        this.previousSatelliteComponent = satelliteComponent;
      }
      const url = `${Auth.ecms}/api/getAvailableCategoriesAndTags?satelliteComponent=${satelliteComponent}&entityTypeIds=${entityTypeIds}&cacheBuster=${(new Date().getTime())}`;
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        this.loadCategoriesWithTags(response.data);
      }
    } catch (error) {
      console.log(error.message);
      return false;
    }
  }

  fetchContentItemTags = async (contentItemId) => {
    try {
      const url = `${Auth.ecms}/api/viewContentItemTags?contentItemId=${contentItemId}`;
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        return response.data;
      }
    } catch (error) {
      console.error(error.message);
      return false;
    }
  }

  // The following calls are new for DEMO-1833 and will be moved to a c2c-lesson service.
  fetchAuthorityStates = async () => {
    if (this.authorityStates && this.authorityStates.length > 0) {
      return false;
    }

    try {
      const url = `${Auth.ecms}/api/viewStatesByCountry`;
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        this.setAuthorityStates(response.states);
      }
    } catch (error) {
      console.error(error.message);
      return false;
    }
  }

  fetchStandardFrameworkDomainList = async (stateId) => {
    try {
      const url = `${Auth.ecms}/api/viewStandardFrameworkDomainList?stateId=${stateId}`;
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        this.setStandardDocuments(response.data);
      }
    } catch (error) {
      console.error(error.message);
      return false;
    }
  }

  fetchStandardFrameworkBookmarks = async (entityId) => {
    try {
      const url = `${Auth.ecms}/api/viewStandardFrameworkBookmarks?entityId=${entityId}&pageSize=100`;
      const response = await Auth.fetch(url, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        this.loadStandardBookmarks(response.data);
      }
    } catch (error) {
      console.error(error.message);
      return false;
    }
  }

  addStandardFrameworkBookmark = async (sourceId, entityId) => {
    try {
      const body = {
        entityTypeId: 'USER',
        entityId,
        sourceId
      };

      const response = await Auth.fetch(`${Auth.ecms}/api/addStandardFrameworkBookmark`, {
        method: 'POST',
        body
      });

      if (response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  deleteStandardFrameworkBookmark = async (id) => {
    try {
      const body = {
        id
      };

      const response = await Auth.fetch(`${Auth.ecms}/api/deleteStandardFrameworkBookmark`, {
        method: 'POST',
        body
      });

      if (response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  // This loads a categoryId:categorytags map and tagId:tag map
  @action loadStandardBookmarks = (bookmarkStandardData) => {
    const ids = [];
    if (bookmarkStandardData) {
      this.standardToBookmarkMap.clear();
      bookmarkStandardData.forEach((data) => {
        if (data.id !== null && data.id !== '' && data.id !== undefined) {
          this.standardToBookmarkMap.set(data.sourceId, data);
          ids.push(data.sourceId);
        }
      });

      this.setBookmarkedStandardIds(ids);
    }
  }
}

export default new TagContentManager();
