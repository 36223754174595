import React, { useContext, useEffect, useState } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import { Loader } from 'semantic-ui-react';

import classNames from 'classnames';

import '../../css/TeacherLibraryResourceDetailsModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

const TeacherLibraryResourceDetailsModal = observer((props = {}) => {
  const {
    buttonKeyCancelLabel = '',
    buttonKeyConfirmLabel = 'close',
    className = 'TeacherLibraryResourceDetailsModal',
    closeOnDimmerClick = false,
    closeOnEscape = true,
    courseContentItemId,
    disableKeystroke = false,
    disableMove = false,
    disableResize = false,
    headerLabel,
    initHeight,
    initWidth,
    left = window.innerWidth / 2.75,
    minHeight = 400,
    minWidth = 540,
    onOpen = () => {},
    onRequestClose,
    open: isOpen,
    overlay = 'medium',
    product,
    t,
    top = window.innerHeight / 4
  } = props;

  const {
    courseManager
  } = useContext(MobXProviderContext);

  const [isLoadingTeacherLibraryResourceDetailsModal, setIsLoadingTeacherLibraryResourceDetailsModal] = useState(true);

  const [courseDetails, setCourseDetails] = useState();

  const GenericModal = SatCoreComponent('GenericModal');

  useEffect(() => {
    (async () => {
      await fetchTeacherCourseDetails();
    })();
  }, []);

  const fetchTeacherCourseDetails = async () => {
    setIsLoadingTeacherLibraryResourceDetailsModal(true);
    const _courseDetails = await courseManager.fetchTeacherCourseDetails(courseContentItemId);
    setCourseDetails(_courseDetails);
    setIsLoadingTeacherLibraryResourceDetailsModal(false);
  };

  const handleCloseTeacherLibraryResourceDetailsModal = async (_event, _actionButtonData = {}) => {
    // setIsLoadingTeacherLibraryResourceDetailsModal(true);
    props.onClose?.();
    // setIsLoadingTeacherLibraryResourceDetailsModal(false);
  };

  const renderTeacherLibraryResourceDetails = () => {
    const {
      // productId,
      // productName
      classroomList: classroomNames,
      courseContentItemFriendlyName,
      courseContentItemId,
      courseContentItemName,
      licenseList: licenseNames,
      productCode,
    } = courseDetails || {};
    return isLoadingTeacherLibraryResourceDetailsModal ? (
      <Loader active inline />
    ) : (
      <div className='teacher-library-course-details-modal-body-inner'>
        {renderTeacherLibraryResourceDetail('courseContentItemName', [
          (courseContentItemFriendlyName || courseContentItemName || t('unknown'))
        ])}
        {renderTeacherLibraryResourceDetail('courseContentItemId', [courseContentItemId || t('unknown')])}
        {renderTeacherLibraryResourceDetail('productCode', [productCode || product?.code || t('unknown')])}
        {renderTeacherLibraryResourceDetail('licenseNames', [...new Set(licenseNames?.length ? licenseNames : [t('unknown')])])}
        {renderTeacherLibraryResourceDetail('classroomNames', [...new Set(classroomNames?.length ? classroomNames : [t('none')])])}
      </div>
    );
  };

  const renderTeacherLibraryResourceDetail = (detailKey, detailValues = []) => {
    return (
      <div className={classNames('teacher-library-resource-detail-wrapper', detailKey)}>
        <div className={classNames('teacher-library-resource-detail-label', detailKey)}>
          {t(detailKey)}:
        </div>
        <div className={classNames('teacher-library-resource-detail-values', detailKey)}>
          {detailValues.map((detailValue, index) => {
            return (
              <div key={`${detailKey}-${index}`}
                className={classNames('teacher-library-resource-detail-value', detailKey)}>
                {detailValue}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <GenericModal
      bodyMsg={renderTeacherLibraryResourceDetails}
      buttonKeyCancelLabel={buttonKeyCancelLabel}
      buttonKeyConfirmLabel={buttonKeyConfirmLabel}
      className={className}
      closeOnDimmerClick={closeOnDimmerClick}
      closeOnEscape={closeOnEscape}
      disableKeystroke={disableKeystroke}
      disableMove={disableMove}
      disableResize={disableResize}
      headerMsg={headerLabel}
      initHeight={initHeight || minHeight}
      initWidth={initWidth || minWidth}
      isLoading={isLoadingTeacherLibraryResourceDetailsModal}
      isOpen={isOpen}
      left={left}
      onClose={handleCloseTeacherLibraryResourceDetailsModal}
      onOpen={onOpen}
      onRequestClose={onRequestClose}
      overlay={overlay}
      top={top} />
  );
});

export default TeacherLibraryResourceDetailsModal;

SatCoreRegister('TeacherLibraryResourceDetailsModal', TeacherLibraryResourceDetailsModal);
