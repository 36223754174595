import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Form, Message } from 'semantic-ui-react';

import { cloneDeep } from 'lodash';

import '../../css/TrialEnterPurchaseCodesModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import PurchaseCodeApi from '../../api/PurchaseCodeApi';

import PurchaseCodeService from '../../services/PurchaseCodeService';
import UtilityService from '../../services/UtilityService';

import Modal from '../Modal';

export const defaultCodeInputState = { error: null, value: '' };
export const nameCourseRegex = /^course\[(.*)\]$/;

export default
@inject('classroomManager', 'courseManager', 'userManager')
@observer
class TrialEnterPurchaseCodesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codes: {},
      error: null,
      submitted: false
    };
    this.ModalBanner = SatCoreComponent('ModalBanner');
  }

  setCodeData = (id, field, value) => {
    this.setState((prevState) => {
      const newState = { ...prevState };
      newState.codes[id] = newState.codes[id] || { ...defaultCodeInputState };
      newState.codes[id][field] = value;
      return newState;
    });
  };

  handleCodeChange = (e) => {
    const { name, value } = e.target;
    const [, id] = name.match(nameCourseRegex);
    id && this.setCodeData(id, 'value', value && value.toUpperCase().trim());
  };

  handleCodeFocus = (e) => {
    const { name } = e.target;
    const [, id] = name.match(nameCourseRegex);
    id && this.setCodeData(id, 'error', null);
  };

  validate = async () => {
    const { t } = this.props;
    // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
    const codes = cloneDeep(this.state.codes);
    let valid = true;
    const uniqCodes = this.codes().reduce((usedCodes, [id, value]) => {
      if (Object.values(usedCodes).includes(value)) {
        codes[id].error = t('codeUniqError', 'Error: Your code is not unique');
        valid = false;
      } else {
        usedCodes[id] = value;
      }
      return usedCodes;
    }, {});
    const result = await PurchaseCodeApi.validateCodes(uniqCodes);
    result.forEach(([id, , result]) => {
      if (!result) {
        codes[id].error = t('codeError', 'Error: Your code is incorrect');
        valid = false;
      }
    });
    !valid && this.setState({ codes });
    return valid;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { close, t } = this.props;
    this.setState({ submitted: true, error: null });
    const valid = await this.validate();
    if (!valid) {
      this.setState({ submitted: false });
      return;
    }
    const result = await PurchaseCodeService.burnCodes(this.codes().map(([, value]) => value));
    result ? close() : this.setState({ error: t('generalError', 'Something has gone wrong.'), submitted: false });
  };

  isComplete = () => this.courses().length === this.codes().length;

  codes() {
    const { codes } = this.state;
    return this.courses().filter(({ id }) => {
      return codes[id] && codes[id].value.length && !codes[id].error;
    }).map(({ id }) => [id, codes[id].value]);
  }

  courses() {
    const { courseManager, classroomManager: { currentClassroomId } } = this.props;
    return courseManager.getCourseList(currentClassroomId);
  }

  renderFindAndPurchase() {
    const { t, userManager } = this.props;
    const findAndPurchaseUrl = userManager.licenseInstructionUrl;
    const findAndPurchaseLabel = userManager.licenseInstruction;
    return (
      <div className='find-and-purchase-block'>
        <a href={findAndPurchaseUrl || t('findAndPurchaseUrl')}
          rel='noreferrer' target='_blank'>
          {findAndPurchaseLabel || t('findAndPurchaseLabel')}
        </a>
      </div>
    );
  }

  renderForm() {
    const { t } = this.props;
    const { error, codes } = this.state;
    return (
      <Form>
        {this.courses().map(({ id, name }) => {
          const { value, error } = codes[id] || defaultCodeInputState;
          return (
            <Form.Field key={id}>
              <Form.Input
                label={UtilityService.reactHtmlParserWrapper(name).parsed}
                name={`course[${id}]`}
                onChange={this.handleCodeChange}
                onFocus={this.handleCodeFocus}
                placeholder={t('codeInputPlaceholder', 'Enter code')}
                type='text'
                value={value} />
              <Message content={error} error visible={error !== null} />
            </Form.Field>
          );
        })}
        <Message content={error} error visible={error !== null} />
        {this.renderFindAndPurchase()}
      </Form>
    );
  }

  renderActions() {
    const { close, t } = this.props;
    const { submitted } = this.state;
    return (
      <>
        <Button basic className='cancelButton' onClick={close} primary>{t('cancelButtonLabel', 'Cancel')}</Button>
        <Button disabled={submitted || !this.isComplete()} onClick={this.handleSubmit} primary>{t('submitButtonLabel', 'Submit')}</Button>
      </>
    );
  }

  render() {
    const { open, close, t } = this.props;
    const { ModalBanner } = this;
    return (
      <Modal
        className='trial-enter-purchase-codes-modal'
        closeOnDimmerClick={false}
        closeOnEscape={true}
        onClose={close}
        open={open}
        size='tiny'>
        <ModalBanner onClose={close} title={t('modalTitle', 'Enter Your Access Code')} />
        <Modal.Content>{this.renderForm()}</Modal.Content>
        <Modal.Actions>{this.renderActions()}</Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('TrialEnterPurchaseCodesModal', TrialEnterPurchaseCodesModal);
