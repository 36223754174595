/* eslint-disable prefer-template */
export const stripHtmlTags = (str, ...htmlTagsToKeep) => {
  if (typeof str === 'string') {
    return str.replace(/<(\/?)(\w+)[^>]*\/?>/g, (_, endMark, tag) => {
      return htmlTagsToKeep.includes(tag) ? '<' + endMark + tag + '>' : '';
    }).replace(/<!--.*?-->/g, '') // strip all html tags except those specified in `htmlTagsToKeep`
      .replace(/&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6});/ig, '') // strip all html entities
      .trim();
  }
};

export const stripHtmlTagsExceptImg = (str) => {
  if (typeof str === 'string') {
    return str.replace(/<\/?(?!img)\w*\b[^>]*>/ig, '');
  }
  return str;
};

export const stripImgHtmlTags = (str) => {
  if (typeof str === 'string') {
    return str.replace(/<img[^>'']*((('[^']*')|('[^']*'))[^''>]*)*>/g, '');
  }
  return str;
};

export const stripHtmlEntities = (str) => {
  if (typeof str === 'string') {
    return str.replace(/&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-f]{1,6});/ig, '');
  }
  return str;
};

export const stripWrappingParagraphTags = (str) => {
  if (typeof str === 'string') {
    str = str.trim();
    return str.replace(/^<p[^>]*>|<\/p>$/g, '');
  }
  return str;
};

export const stripNonNumeric = (str) => {
  return typeof str === 'string' ? str.replace(/[^\d.-]/g, '') : str;
};
