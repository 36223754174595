import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button } from 'semantic-ui-react';
import Modal from '../Modal';
// TODO create '../../css/DeleteAssignmentModal.less';
import '../../css/EditAssignmentModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import UtilityService from '../../services/UtilityService';

@inject('navigationManager')
@observer
class DeleteAssignmentModal extends Component {
  constructor(props) {
    super(props);
    this.ModalBanner = SatCoreComponent('ModalBanner');
  }

  state = {
    isSubmitting: false,
    open: false
  }

  open = () => this.setState({ open: true });

  close = () => this.setState({ open: false });

  confirm = () => {
    const { deleteFunction, assignment } = this.props;
    deleteFunction(assignment);
    this.close();
  }

  render() {
    const { open } = this.state;
    const { assignment } = this.props;
    const { ModalBanner } = this;

    const assignmentTitle = assignment.resourceWebTitle ? assignment.resourceWebTitle : assignment.name;
    const assignmentTitleObj = UtilityService.reactHtmlParserWrapper(assignmentTitle);
    return (
      <Modal
        onClose={this.close}
        onOpen={this.open}
        open={open}
        size='tiny'
        trigger={<Button basic>Delete</Button>}>
        <ModalBanner
          label='Delete Assignment: '
          onClose={this.close}
          title={assignmentTitleObj.stripped} />
        <Modal.Content>
          <p>Deleting an assignment removes the assignment from the assignment queue, erases all grades from gradebook and reports and from any / all student’s or teacher’s records.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic className='keepButton' content='No, Keep' onClick={this.close} primary />
          <Button className='deleteButton' content='Yes, Delete' onClick={this.confirm} primary />
        </Modal.Actions>
      </Modal>
    );
  }
}
export default DeleteAssignmentModal;

SatCoreRegister('DeleteAssignmentModal', DeleteAssignmentModal);
