/* eslint-disable sort-imports */
/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
/* eslint-disable sort-keys */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { debounce, isEqual } from 'lodash';

import {
  Button, Container, Dropdown, Grid, Input, Tab
} from 'semantic-ui-react';
// import iconBookmark from '../img/bookmark.svg';
// import iconBookmarkSelected from '../img/bookmark-selected.svg';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import CourseService from '../services/CourseService';
import UtilityService from '../services/UtilityService';

export default
@inject(
  'assessmentManager',
  'courseManager',
  'productManager',
  'resourceBankShopManager',
  'tagContentManager',
  'teacherProductViewManager',
  'userManager'
)
@observer
class ResourceFilter extends Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    // props specifying what this filter is being used for
  };

  courseOptions = [];
  courseMap = {};
  courseIds = [];
  useSessionStorage = false;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.SCRadio = SatCoreComponent('SCRadio');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.saveState = debounce(this.saveState, 1000);
  }

  getInitialState = () => {
    const { tagContentManager, userManager } = this.props;
    const dynamicState = {
      activeIndex: 0,
      isUpdating: false,
      productCmapArray: [],
      searchLocation: (userManager.canViewLibraryItemBank) ? 'myDrafts' : 'resourceBank',
      selectedResourceBanks: [],
      searchOperator: 'AND', // TODO GAL-602: this is now **always** 'and'
      searchTerms: '',
      searchResourceTypes: [],
      searchTags: [],
      searchTagsMap: new Map(),
      productCmap: '',
      searchStandards: [],
      bookmarkedStandards: tagContentManager.bookmarkedStandardIds,
      expanded: [],
      standardFramework: '',
      authorityState: '',
      curriculumFilterType: 'bookmark',
      bookmarkMode: false,
      loadingMode: false,
      refreshTree: 'refresh'
    };
    tagContentManager.categoriesWithTagsArray.map((pItem) => {
      dynamicState[pItem.id] = [];
    });
    return { ...dynamicState, ...this.loadState() };
  }

  componentDidMount = async () => {
    const {
      assessmentManager,
      productId,
      productManager,
      resourceBankShopManager,
      userManager
    } = this.props;

    const { searchResourceTypes, searchTerms, searchTags, searchStandards, searchLocation, selectedResourceBanks, productCmap, standardFramework } = this.state;
    assessmentManager.clearAssessmentFilterStandardsMaps();
    assessmentManager.setOriginalAssessmentItems(new Map());
    if (searchLocation === 'resourceBank') {
      // if we are defaulting to resourceBank, get the list of banks for the dropdown
      await resourceBankShopManager.fetchResourceBanks(userManager.userId);
    }
    assessmentManager.clearProductCmapList();
    let productCmapArray = [];
    if (productId) {
      productCmapArray = await productManager.fetchCmapListForProduct(productId);
    } else {
      await assessmentManager.fetchProductCmapList();
      productCmapArray = assessmentManager.productCmapArray || [];
    }
    this.setState({ productCmapArray });
    await resourceBankShopManager.setSearchLocation(searchLocation);
    await this.fetchSearch(searchTerms, searchTags, searchStandards, searchResourceTypes, searchLocation, selectedResourceBanks);
    productCmap !== '' && await assessmentManager.fetchFullCmap(productCmap);
    // await assessmentManager.fetchAdoptedPublisherStandardFrameworks();
    // standardFramework !== '' && await assessmentManager.fetchFullStandardDocument(standardFramework);
    resourceBankShopManager.setResourcesLoading(false);
  }

  componentDidUpdate(_prevProps, prevState) {
    !isEqual(this.state, prevState) && this.saveState();
  }

  async componentWillUnmount() {
    this.resetFilters();
  }

  // eslint-disable-next-line react/sort-comp
  loadState() {
    try {
      if (this.useSessionStorage) {
        const data = JSON.parse(sessionStorage.getItem('ResourceFilter') || '{}');
        data.searchTagsMap = new Map(data.searchTagsMap);
        return data;
      }
    } catch {
      return {};
    }
  }

  saveState() {
    const { searchTagsMap } = this.state;
    const data = { ...this.state, searchTagsMap: [...searchTagsMap] };
    if (this.useSessionStorage) {
      sessionStorage.setItem('ResourceFilter', JSON.stringify(data));
    }
  }

  handleClearCategorySearch = async () => {
    const { tagContentManager } = this.props;
    this.setState({ searchTerms: '' });
    this.setState({ searchTags: [] });
    tagContentManager.categoriesWithTagsArray.map((pItem) => {
      this.setState({ [pItem.id]: [] });
    });
  }

  getCourseResourceElements = () => {
    CourseService.searchCourseResourceElements();
  }

  handleClearStandardsSearch = async () => {
    this.setState({ productCmap: '', standardFramework: '' });
    this.setState({ searchStandards: [] });
  }

  handleChangeSearch = async (_event, { value }) => {
    const { resourceBankShopManager } = this.props;
    const { searchLocation, selectedResourceBanks, searchTags, searchStandards, searchResourceTypes } = this.state;
    const searchTerms = value;
    resourceBankShopManager.setSearchTextTimeout(clearTimeout(resourceBankShopManager.searchTextTimeout));
    await resourceBankShopManager.setSearchTerms(searchTerms);
    resourceBankShopManager.setSearchTextTimeout(setTimeout(() => {
      if (!searchTerms || searchTerms.length >= 3) {
        this.resetSearch(searchTerms, searchTags, searchStandards, searchResourceTypes, searchLocation, selectedResourceBanks);
      }
    }, 1000));
    this.setState({ searchTerms });
  }

  resetFilters = async () => {
    const {
      assessmentManager, clearResourceResults, courseManager,
      resourceBankShopManager, tagContentManager, userManager
    } = this.props;

    const defaultSearchLocation = (userManager.canViewLibraryItemBank) ? 'myDrafts' : 'resourceBank';

    courseManager.setCurriculumMapElementIds([]);
    resourceBankShopManager.setSearchLocation(defaultSearchLocation);
    resourceBankShopManager.setSearchResourceTypes([]);
    resourceBankShopManager.setSearchStandards([]);
    resourceBankShopManager.setSearchTags([]);
    resourceBankShopManager.setSearchTerms('');
    resourceBankShopManager.setSearchResourceBanks([]);
    assessmentManager.clearAllCmapData();

    // reset current results;
    if (clearResourceResults) {
      clearResourceResults();
    }

    await this.resetSearch('', '', '', '', defaultSearchLocation, '');
    this.setState({
      activeIndex: 0,
      isUpdating: false,
      searchLocation: defaultSearchLocation,
      searchOperator: 'AND', // TODO GAL-602: this is now **always** 'and'
      selectedResourceBanks: [],
      searchTerms: '',
      searchResourceTypes: [],
      searchTags: [],
      searchTagsMap: new Map(),
      productCmap: '',
      searchStandards: [],
      expanded: [],
      standardFramework: '',
      authorityState: '',
      curriculumFilterType: 'standards'
    });

    tagContentManager.categoriesWithTagsArray.map((pItem) => {
      this.setState({ [pItem.id]: [] });
    });
  }

  handleClearDocument = async () => {
    this.setState({ standardFramework: '' });
    this.setState({ bookmarkMode: false });
  }

  handleChangeSourceLocation = async (event) => {
    const { clearResourceResults, resourceBankShopManager, userManager } = this.props;
    const tmpSearchLocation = event.target.value;
    // reset current results;
    if (clearResourceResults) {
      clearResourceResults();
    }
    await resourceBankShopManager.setSearchLocation(tmpSearchLocation);
    await resourceBankShopManager.setSearchResourceBanks([]);
    // Don't fetch if we selected resourceBank as a further selection is required for that option.
    if (tmpSearchLocation !== 'resourceBank') {
      const { searchTerms, searchStandards, searchTags, searchResourceTypes } = this.state;
      // if we are changing source, reset selected resource banks
      await this.resetSearch(searchTerms, searchTags, searchStandards, searchResourceTypes, tmpSearchLocation, []);
    } else if (tmpSearchLocation === 'resourceBank') {
      // call to get resource banks to populate the filter dropdown
      await resourceBankShopManager.fetchResourceBanks(userManager.userId);
    }
    // set the state after search
    this.setState({ searchLocation: tmpSearchLocation, selectedResourceBanks: [] });
  }

  handleChangeSelectedResourceBank = async (_event, { value }, resourceBanks) => {
    const { resourceBankShopManager } = this.props;
    const { searchTerms, searchStandards, searchTags, searchResourceTypes, searchLocation } = this.state;
    // if we have no product ids, clear the results
    const tempSelectedResourceBanks = (!value || value.length < 1) ? null : value;
    await resourceBankShopManager.setSearchResourceBanks(tempSelectedResourceBanks);
    await this.resetSearch(searchTerms, searchTags, searchStandards, searchResourceTypes, searchLocation, tempSelectedResourceBanks);

    // set the state after search
    this.setState({ selectedResourceBanks: value });
  }

  handleChangeResourceTypes = async (_event, { value }, resourceTypes) => {
    const { resourceBankShopManager } = this.props;
    const { searchLocation, selectedResourceBanks, searchTerms, searchStandards, searchTags } = this.state;
    // if we have no product ids, clear the results
    const tempSearchResourceTypes = (!value || value.length < 1) ? null : value;
    await resourceBankShopManager.setSearchResourceTypes(value);
    await this.resetSearch(searchTerms, searchTags, searchStandards, tempSearchResourceTypes, searchLocation, selectedResourceBanks);

    // set the state after search
    this.setState({ searchResourceTypes: tempSearchResourceTypes });
  }

  handleChangeCategories = async (_event, { value }, catId) => {
    const { resourceBankShopManager } = this.props;
    const { searchTagsMap } = this.state;
    const tempSearchTagsMap = searchTagsMap;

    if (catId !== '' && catId !== null && catId !== undefined && value.length < 1) {
      tempSearchTagsMap.delete(catId);
    } else {
      tempSearchTagsMap.set(catId, value);
    }
    const tempSearchTags = resourceBankShopManager.getArrayFromMap(tempSearchTagsMap);
    await resourceBankShopManager.setSearchTags(tempSearchTags);

    const { searchLocation, selectedResourceBanks, searchTerms, searchStandards, searchResourceTypes } = this.state;
    await this.resetSearch(searchTerms, tempSearchTags, searchStandards, searchResourceTypes, searchLocation, selectedResourceBanks);

    // set the state after search
    this.setState({ searchTags: tempSearchTags });
    this.setState({ searchTagsMap: tempSearchTagsMap });
    const dynamicState = {};
    dynamicState[catId] = value;
    this.setState(dynamicState);
  }

  handleClickStandardsLabel = async (_data) => {
    // do nothing right now.
    // console.log(event.target, data);
  }

  handleChangeStandards = async (searchElementIds, node, isTOCSearch = false) => {
    const { assessmentManager, resourceBankShopManager } = this.props;
    const { searchLocation, selectedResourceBanks, searchTerms, searchTags, searchResourceTypes } = this.state;
    const searchStandards = [];
    // Convert the search curriculum element ids to a standards list
    if (isTOCSearch && assessmentManager.stdFrwkStandardsMap) {
      searchElementIds.forEach((elementId) => {
        // Get the standard for the stdFrwkElement
        const stdFrwkElement = assessmentManager.stdFrwkStandardsMap.get(elementId);
        if (stdFrwkElement) {
          searchStandards.push(stdFrwkElement.standardId);
        }
      });
    } else if (assessmentManager.cmapStandardsMap) {
      searchElementIds.forEach((elementId) => {
        // Get the standard for the cmapElement
        const cmapElement = assessmentManager.cmapStandardsMap.get(elementId);
        if (cmapElement) {
          searchStandards.push(cmapElement.standardId);
        }
      });
    }

    await resourceBankShopManager.setSearchStandards(searchStandards);
    await this.resetSearch(searchTerms, searchTags, searchStandards, searchResourceTypes, searchLocation, selectedResourceBanks);
    this.setState({ searchStandards: searchElementIds });
  }

  handleChangeBookmarks = async (ids, node) => {
    const { tagContentManager, userManager } = this.props;

    if (!node.value) {
      return false;
    }

    if (tagContentManager.bookmarkedStandardIds.includes(node.value)) {
      const bookmark = tagContentManager.standardToBookmarkMap.get(node.value);
      await tagContentManager.deleteStandardFrameworkBookmark(bookmark.id);
    } else {
      await tagContentManager.addStandardFrameworkBookmark(node.value, userManager.userId);
    }

    await tagContentManager.fetchStandardFrameworkBookmarks(userManager.userId);
    this.setState({ bookmarkedStandards: tagContentManager.bookmarkedStandardIds });
  }

  resetSearch = async (searchTerms, searchTags, searchStandards, searchResourceTypes, searchLocation, selectedResourceBanks) => {
    const { assessmentManager } = this.props;
    const filteredAssessmentSettings = {
      searchTerms, searchTags, searchStandards, searchResourceTypes, searchLocation, selectedResourceBanks
    };
    await this.fetchSearch(searchTerms, searchTags, searchStandards, searchResourceTypes, searchLocation, selectedResourceBanks);
    assessmentManager.setFilteredAssessmentSettings(filteredAssessmentSettings);
  }

  fetchSearch = async (searchTerms, searchTags, searchStandards, searchResourceTypes = null, searchLocation = null, selectedResourceBanks = null) => {
    const { userManager } = this.props;
    if (searchLocation === null) {
      searchLocation = (userManager.canViewLibraryItemBank) ? 'myDrafts' : 'resourceBank';
    }
    this.setState({ isUpdating: true });
    const { handleFetchResourceBankResources } = this.props;
    await handleFetchResourceBankResources(searchTerms, searchTags, searchStandards, searchResourceTypes, searchLocation, selectedResourceBanks);
    this.setState({ isUpdating: false });
  }

  getSourceLocation = () => {
    const { userManager, t } = this.props;
    const { searchLocation } = this.state;
    const { SCRadio } = this;

    return (
      <Container className='location-filter-container'>
        <div className='filter-label location'>Location</div>
        <Container className='location-filter-wrapper'>
          {userManager.canViewLibraryItemBank && (
            <>
              <SCRadio
                checked={searchLocation === 'myDrafts'}
                disabled={false}
                label={t('myDraftsLabel', 'My Drafts')}
                name='sourceLocation'
                onChange={this.handleChangeSourceLocation}
                value='myDrafts' />
              <SCRadio
                checked={searchLocation === 'sharedDrafts'}
                disabled={false}
                label={t('sharedDraftsLabel', 'Shared Drafts')}
                name='sourceLocation'
                onChange={this.handleChangeSourceLocation}
                value='sharedDrafts' />
            </>
          )}
          {userManager.canViewLibraryResourceBank && (
            <SCRadio
              checked={searchLocation === 'resourceBank'}
              disabled={false}
              label={t('resourceBankLabel', 'Resource Bank')}
              name='sourceLocation'
              onChange={this.handleChangeSourceLocation}
              value='resourceBank' />
          )}
        </Container>
      </Container>
    );
  }

  getResourceBank = () => {
    const { resourceBankShopManager } = this.props;
    const { searchLocation, selectedResourceBanks } = this.state;
    const thisClassName = 'filter-dropdown-section z-custom-category';

    return (
      <Container key='resource-bank-container' className='resource-bank-container'>
        <div className={thisClassName}>
          <div className='filter-label'>Resource Bank</div>
          <Dropdown
            key='resource-banks'
            className='resource-bank-filter-dropdown'
            clearable={false}
            closeOnChange
            disabled={searchLocation !== 'resourceBank'}
            fluid
            multiple // we set this to true for the 'search pill' design effect, but we will only allow one pill per category
            onChange={(event, object) => this.handleChangeSelectedResourceBank(event, object)}
            options={resourceBankShopManager.resourceBankOptions}
            placeholder='Select Resource Bank'
            search
            selection
            value={selectedResourceBanks} />
        </div>
      </Container>
    );
  }

  getResourceType = () => {
    const { resourceBankShopManager, userManager } = this.props;
    const thisClassName = 'filter-dropdown-section z-custom-category';
    const resourceTypeOptions = userManager.canViewLibraryResourceBank ? resourceBankShopManager.resourceBankTypeOptions : resourceBankShopManager.itemBankOnlyTypeOptions;
    return (
      <Container key='question-type-container'>
        <div className={thisClassName}>
          <div className='filter-label'>Resource Type</div>
          <Dropdown
            key='resource-types'
            className='resource-type-filter-dropdown'
            clearable={false}
            closeOnChange
            disabled={false}
            fluid
            multiple // we set this to true for the 'search pill' design effect, but we will only allow one pill per category
            onChange={(event, object) => this.handleChangeResourceTypes(event, object)}
            options={resourceTypeOptions}
            placeholder='Select Resource Types'
            search
            selection
            value={resourceBankShopManager.searchResourceTypes} />
        </div>
      </Container>
    );
  }

  getCategoryItems = () => {
    const { tagContentManager, multiSelect } = this.props;
    const categories = [];
    tagContentManager.categoriesWithTagsArray.map((pItem) => {
      let value = this.state[pItem.id];
      if (!value || !Array.isArray(value) || !value.length) {
        // value is empty; ensure it is an empty array
        value = [];
      } else if (!multiSelect && value && value.length > 1) {
        // ensure we only have up to 1 active 'search pill' per category
        const lastValueInArray = value[value.length - 1];
        value = lastValueInArray ? [lastValueInArray] : [];
      }

      const customName = tagContentManager.getCustomCategoryNameClass(pItem.name);
      const thisClassName = `filter-dropdown-section ${customName}`;
      categories.push(
        <Container key={`${pItem.id}_a`}>
          <div className={thisClassName}>
            <div className='filter-label'>{pItem.name}</div>
            <Dropdown
              key={`${pItem.id}_dd`}
              className='assessment-filter-dropdown'
              clearable={false}
              closeOnChange
              disabled={false}
              fluid
              multiple // we set this to true for the 'search pill' design effect, but we will only allow one pill per category
              onChange={(event, object) => this.handleChangeCategories(event, object, pItem.id)}
              options={this.getTagItemsAsOptions(pItem.tags)}
              placeholder={`Select ${pItem.name || ''}`}
              search
              selection
              value={value} />
          </div>
        </Container>
      );
    });
    return categories;
  }

  getTagItemsAsOptions = (tagsObj) => {
    const options = tagsObj.map((tag, index) => ({
      key: tag.id + index,
      text: tag.name,
      value: tag.id
    }));
    return options;
  }

  handleChangeProductCmap = async (_event, { value }) => {
    const { assessmentManager } = this.props;
    await assessmentManager.clearExpanded();
    if (value.length > 0) {
      const id = value;
      await assessmentManager.fetchFullCmap(id);
    } else {
      assessmentManager.clearCmapStandardsJson();
      assessmentManager.clearCmapStandardsMap();
    }
    this.setState({ productCmap: value });
    setTimeout(() => {
      this.setState({ expanded: assessmentManager.expanded });
    }, 1000);
  }

  renderCurriculumFrameworkSearch() {
    // const bookmarkMode = false;
    const { assessmentItemsSearch } = this.props;
    const { authorityState, standardFramework, searchStandards, bookmarkedStandards, expanded, curriculumFilterType, bookmarkMode, loadingMode, refreshTree } = this.state;
    const { assessmentManager, tagContentManager } = this.props;
    const { SCRadio } = this;
    const { SCCheckbox } = this;
    // const standards = (bookmarkMode) ? tagContentManager.bookmarkedStandards : searchStandards;

    let statesOptions = [];
    const isClearable = true;
    if (assessmentItemsSearch) {
      statesOptions = tagContentManager.authorityStates.map((authorityState, index) => ({
        key: authorityState.id + index,
        text: authorityState.name,
        value: authorityState.code
      }));
    }

    return (
      <div className='tab-container'>
        <div className='radio-wrapper'>
          <SCRadio
            checked={curriculumFilterType === 'bookmark'}
            label='Show Bookmarks'
            name='frameworkFilter'
            onChange={this.handleChangeCurriculumFilterType}
            value='bookmark' />
          <SCRadio
            checked={curriculumFilterType === 'standards'}
            label='Browse Standards'
            name='frameworkFilter'
            onChange={this.handleChangeCurriculumFilterType}
            value='standards' />
        </div>
        <Container>
          {/* {(assessmentManager.adoptedStandardFrameworksArray) ? (
            <Dropdown
              clearable={isClearable}
              fluid
              onChange={this.handleChangeStandardsFramework}
              options={options}
              placeholder='Standard Framework'
              selection
              value={standardFramework} />
          ) : null} */}
        </Container>

        {(curriculumFilterType === 'standards') ? (
          <div className='standards-section'>
            <Container>
              {(tagContentManager.authorityStates && !standardFramework) ? (
                <Dropdown
                  clearable={isClearable}
                  fluid
                  onChange={this.handleChangeAuthorityState}
                  options={statesOptions}
                  placeholder='Authority State'
                  selection
                  value={authorityState} />
              ) : null}
            </Container>

            {(authorityState && !standardFramework) ? (
              <div className='framework-list-container'>
                {tagContentManager.standardDocuments.map(
                  (doc, index) => (
                    <div key={index} className={`${(index % 2 !== 0) ? 'shade' : ''} standard-row standard-doc-label`} onClick={() => this.handleSelectStandardsFramework(doc.id)}>
                      <Image src={iconBookmark} />
                      <div key={index} className='standard-doc-label'>{doc.listName}</div>
                    </div>
                  ))}
              </div>
            ) : null}

            {(assessmentManager.adoptedStandardsJson && standardFramework && !loadingMode) ? (
              <>
                <div className='doc-controls'>
                  <SCCheckbox
                    checked={bookmarkMode}
                    label='Bookmark Mode'
                    name='status'
                    onChange={this.handleChangeBookmarkMode}
                    value={bookmarkMode} />
                  <Button
                    className='reset-link'
                    disabled={!standardFramework}
                    onClick={this.handleClearDocument}>
                    Clear Document
                  </Button>
                </div>
                <div className='checkbox-tree-container' refresh-attr={refreshTree}>
                  <CheckboxTree
                    checked={(bookmarkMode) ? bookmarkedStandards : searchStandards}
                    expanded={expanded}
                    icons={{
                      check: <>{(bookmarkMode) ? <Image className='bookmark-mode' src={iconBookmarkSelected} /> : <span className='rct-icon rct-icon-check' />}</>,
                      uncheck: <>{(bookmarkMode) ? <Image className='bookmark-mode' src={iconBookmark} /> : <span className='rct-icon rct-icon-uncheck' />}</>,
                      halfCheck: <span className='rct-icon rct-icon-half-check' />,
                      expandClose: <span className='icon-expand-close' />,
                      expandOpen: <span className='icon-expand-open' />,
                      expandAll: <span className='rct-icon rct-icon-expand-all' />,
                      collapseAll: <span className='rct-icon rct-icon-collapse-all' />,
                      parentClose: <span className='rct-icon rct-icon-parent-close' />,
                      parentOpen: <span className='rct-icon rct-icon-parent-open' />,
                      leaf: <span className='rct-icon rct-icon-leaf' />
                    }}
                    nativeCheckboxes={!bookmarkMode}
                    noCascade={true}
                    nodes={assessmentManager.adoptedStandardsJson}
                    onCheck={(checked, node) => ((bookmarkMode) ? this.handleChangeBookmarks(checked, node) : this.handleChangeStandards(checked, node, true))}
                  // onClick={(event, data) => this.handleClickStandardsLabel(event, data)}
                    onExpand={(expanded) => this.setState({ expanded })} />
                </div>
              </>
            ) : null}

            {(loadingMode) ? <Loader active /> : null}

          </div>
        ) : (
          <div className='bookmark-list-container'>
            {tagContentManager.standardBookmarkArray.map(
              (bookmark, index) => (
                <div key={index} className={`${(index % 2 !== 0) ? 'shade' : ''} standard-row standard-doc-label`} onClick={() => this.handleSelectStandardsFramework(bookmark.sourceId, true)}>
                  <Image src={iconBookmarkSelected} />
                  <div key={index} className='standard-doc-label'>{(bookmark.name.length > 100 ? `${bookmark.name.substring(0, 100)}...` : bookmark.name)}</div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    );
  }

  handleChangeStandardsFramework = async (_event, { value }) => {
    this.handleSelectStandardsFramework(value);
  }

  handleSelectStandardsFramework = async (value, showStandards = false) => {
    const { assessmentManager } = this.props;
    await assessmentManager.clearExpanded();
    if (value.length > 0) {
      const id = value;
      this.handleChangeLoadingMode(true);
      const defaultExpanded = false;
      await assessmentManager.fetchFullStandardDocument(id, defaultExpanded);
      this.handleChangeLoadingMode(false);
    } else {
      assessmentManager.clearAdoptedStandardsJson();
      assessmentManager.clearStdFrwkStandardsMap();
    }
    this.setState({ standardFramework: value });

    if (showStandards) {
      this.setState({ curriculumFilterType: 'standards' });
    }

    setTimeout(() => {
      this.setState({ expanded: assessmentManager.expanded });
    }, 1000);
  }

  handleChangeAuthorityState = async (_event, { value }) => {
    const { tagContentManager } = this.props;
    await tagContentManager.fetchStandardFrameworkDomainList(value);
    this.setState({ authorityState: value });
  }

  handleChangeCurriculumFilterType = async (event) => {
    this.setState({ standardFramework: '' });
    this.setState({ bookmarkMode: false });
    this.setState({ curriculumFilterType: event.target.value });
  }

  handleChangeBookmarkMode = async (event) => {
    const { bookmarkMode } = this.state;
    const { assessmentManager } = this.props;
    this.setState({ bookmarkMode: !bookmarkMode });

    const formattedJson = await assessmentManager.buildAdoptedStandardsJson(assessmentManager.standardsResponseData, false, event.target.checked);
    await assessmentManager.setAdoptedStandardsJson(formattedJson);

    setTimeout(() => {
      this.setState({ refreshTree: 'refreshed' });
    }, 500);
  }

  handleChangeLoadingMode = async (mode) => {
    this.setState({ loadingMode: mode });
  }

  handleSearchOperator = async (_event, { value }) => {
    const { resourceBankShopManager } = this.props;
    await resourceBankShopManager.setSearchOperator(value);

    const { searchLocation, selectedResourceBanks, searchTerms, searchResourceTypes, searchStandards, searchTags } = this.state;
    await this.resetSearch(searchTerms, searchTags, searchStandards, searchResourceTypes, searchLocation, selectedResourceBanks);

    this.setState({ searchOperator: value });
  }

  renderSearchOptions() {
    const { searchOperator } = this.state;
    return (
      <div className='search-option-dropdown-wrapper'>
        <div className='filter-label'>Search Options</div>
        <Dropdown
          key='search-operator'
          fluid
          id='search-operator'
          name='sort-select'
          onChange={this.handleSearchOperator}
          options={[
            { key: 'key_or', text: '"OR" Search', value: 'OR' },
            { key: 'key_and', text: '"AND" Search', value: 'AND' },
            { key: 'key_not', text: '"NOT" Search', value: 'NOT' },
          ]}
          placeholder='Sort by'
          search
          selection
          value={searchOperator} />
      </div>
    );
  }

  renderTextSearch() {
    const { searchTerms } = this.state;

    return (
      <Container key='search-text-container'>
        <div className='search-text-wrapper'>
          <Input
            icon='search'
            name='searchTerms'
            onChange={this.handleChangeSearch}
            placeholder='Search'
            type='text'
            value={searchTerms || ''} />
        </div>
      </Container>
    );
  }

  renderTagsSearch() {
    return (
      <div className='tab-container'>
        <Grid>
          <Grid.Column>
            {this.getResourceType()}
            {this.getCategoryItems()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  renderCmapSearch() {
    const { assessmentManager } = this.props;
    let { productCmapArray } = this.state;
    const { productCmap, searchStandards, expanded } = this.state;
    let options = [];
    const isClearable = true;
    productCmapArray.sort((a, b) => ((a.sortBy > b.sortBy) ? 1 : -1));
    options = productCmapArray.map((pcm, index) => ({
      key: pcm.id + index,
      text: pcm.friendlyName || pcm.displayName || pcm.name,
      value: pcm.id
    }));

    return (
      <div className='tab-container'>
        <Container>
          {(productCmapArray) ? (
            <Dropdown
              clearable={isClearable}
              fluid
              onChange={this.handleChangeProductCmap}
              options={options}
              placeholder='Select...'
              selection
              value={productCmap} />
          ) : null}
        </Container>
        <div className='checkbox-tree-container'>
          <CheckboxTree
            checked={searchStandards}
            expanded={expanded}
            icons={{
              check: <span className='rct-icon rct-icon-check' />,
              uncheck: <span className='rct-icon rct-icon-uncheck' />,
              halfCheck: <span className='rct-icon rct-icon-half-check' />,
              expandClose: <span className='icon-expand-close' />,
              expandOpen: <span className='icon-expand-open' />,
              expandAll: <span className='rct-icon rct-icon-expand-all' />,
              collapseAll: <span className='rct-icon rct-icon-collapse-all' />,
              parentClose: <span className='rct-icon rct-icon-parent-close' />,
              parentOpen: <span className='rct-icon rct-icon-parent-open' />,
              leaf: <span className='rct-icon rct-icon-leaf' />
            }}
            nativeCheckboxes={true}
            noCascade={true}
            nodes={assessmentManager.cmapStandardsJson}
            onCheck={(checked) => this.handleChangeStandards(checked)}
            onExpand={(expanded) => this.setState({ expanded })} />
        </div>
      </div>
    );
  }

  render() {
    const {
      resourceBankShopManager, isShopping, t
      // Keep to cause re-render tab pane on change
    } = this.props;
    const { resourcesLoading } = resourceBankShopManager;
    if (!resourcesLoading) {
      const { activeIndex, searchLocation } = this.state;
      const panes = [
        {
          menuItem: 'Standards',
          render: () => (
            <Tab.Pane>
              {this.renderCmapSearch()}
            </Tab.Pane>
          )
        },
        { menuItem: 'Tags', render: () => <Tab.Pane>{this.renderTagsSearch()}</Tab.Pane> },
        // { menuItem: 'Curriculum Framework', render: () => <Tab.Pane>{this.renderCurriculumFrameworkSearch()}</Tab.Pane> }
      ];

      const { isUpdating } = this.state;

      const filterStandardsAndTagsDescriptionTranslation = isShopping ?
        t('filterStandardsAndTagsDescriptionShopping') : t('filterStandardsAndTagsDescription');

      return (
        <div style={isUpdating ? { cursor: 'wait', height: '100%' } : { height: '100%' }}>
          {/* DEMO-1933 - remove search options filter, leaving in case we revisit it later
          <div className='filter-search-options-wrapper'>
            {this.renderSearchOptions()}
          </div> */}
          <div className='filter-title-wrapper'>
            <div className='filter-instruction'>
              <div>
                {UtilityService.reactHtmlParserWrapper(filterStandardsAndTagsDescriptionTranslation).parsed}
              </div>
            </div>
            <Button
              className='reset-link'
              disabled={isUpdating}
              onClick={this.resetFilters}>
              {t('resetFilters')}
            </Button>
          </div>
          <div
            className='filter'
            style={isUpdating ? { pointerEvents: 'none' } : {}}>
            {isShopping && this.renderTextSearch()}
            {isShopping && this.getSourceLocation()}
            {(isShopping && searchLocation === 'resourceBank') && this.getResourceBank()}
            <Tab
              className='filter-tab-menu'
              activeIndex={activeIndex}
              onTabChange={(_event, { activeIndex }) => this.setState({ activeIndex })}
              panes={panes} />
          </div>
        </div>
      );
    }
    return null;
  }
}

SatCoreRegister('ResourceFilter', ResourceFilter);
