import React, { useContext, useEffect, useState } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import { Image, Loader, Pagination, Table } from 'semantic-ui-react';

import '../../css/reports/ReportStandardsClassroomDetailStudentsTable.less';

import { SatCoreRegister } from '../../SatCoreRegistry';

import { DIALOG_NAMES } from '../../managers/DialogManager';

import { MAX_REPORT_FACULTY_SCORE_CELLS_PER_PAGE } from '../../services/reports/ReportConstants';

import { flattenChildren } from '../../utils';

import PopupService from '../../services/PopupService';
import ReportBreadcrumbService from '../../services/reports/ReportBreadcrumbService';
import ReportJsonHelperService from '../../services/reports/ReportJsonHelperService';
import ReportScoreService from '../../services/reports/ReportScoreService';
import ReportStandardsClassroomDetailStudentsService from '../../services/reports/ReportStandardsClassroomDetailStudentsService';
import UserService from '../../services/UserService';
import UtilityService from '../../services/UtilityService';

const ReportStandardsClassroomDetailStudentsTable = observer((props) => {
  const { t } = props;
  const {
    classroomManager, courseManager, dialogManager, reportIdentityManager,
    reportContextManager, reportStandardsClassroomDetailStudentsManager,
    reportStandardsManager, userManager
  } = useContext(MobXProviderContext);

  const { isStudent, isTeacher } = userManager;

  const PAGE_SIZE = MAX_REPORT_FACULTY_SCORE_CELLS_PER_PAGE;

  const [isLoading, setIsLoading] = useState(true);

  const [initializingCourseElementImages, setInitializingCourseElementImages] = useState(true);

  const {
    classroomId,
    institutionName,
    standardElementId,
    truncatedStandardDescription
  } = ReportStandardsClassroomDetailStudentsService.getApplicableUrlParamValues();

  /** equivalent to componentDidMount(), i.e. only called once (on mount) */
  useEffect(() => {
    (async () => {
      setIsLoading(true);

      await initCurrentReportClassroom();
      await initCurrentStandard();

      const reportCourseElementsWithStandardAlignments = await ReportStandardsClassroomDetailStudentsService.initReportCourseElements();

      await ReportBreadcrumbService.initBreadcrumbs();

      setIsLoading(false);

      setInitializingCourseElementImages(true);
      await initContentImageSourcesForReportCourseElements(reportCourseElementsWithStandardAlignments);
      setInitializingCourseElementImages(false);
    })();
  }, []);

  /** equivalent to componentDidUpdate(), i.e. called whenever component is updated */
  // TODO unused
  // useEffect(() => {
  //   /* placeholder */
  // });

  /** equivalent to componentWillUnmount(), i.e. called whenever component is unmounting */
  useEffect(() => () => {
    ReportStandardsClassroomDetailStudentsService.cleanupAfterLeaving();
  });

  const initCurrentStandard = async () => {
    const flatStandards = flattenChildren(ReportJsonHelperService.REPORT_STANDARDS());
    const currentStandard = flatStandards.find((standard) => {
      return standard.id === standardElementId;
    });
    reportStandardsClassroomDetailStudentsManager.setCurrentStandard(currentStandard);
  };

  const initCurrentReportClassroom = async () => {
    classroomManager.setCurrentClassroomId(classroomId);
    if (isTeacher || isStudent) {
      await classroomManager.fetchClassroomData(classroomId);
    }
  };

  const initContentImageSourcesForReportCourseElements = async (reportCourseElementsWithStandardAlignments) => {
    await ReportStandardsClassroomDetailStudentsService.initReportCourseElementContentImageSources(
      reportCourseElementsWithStandardAlignments
    );
  };

  const handlePageChange = async (_event, pageInfo) => {
    const totalPages = ReportStandardsClassroomDetailStudentsService.getTotalReportCourseElementPages();
    if (totalPages > 1) {
      const setActivePage = reportStandardsClassroomDetailStudentsManager.setActiveReportStandardsClassroomDetailStudentsTablePage;
      setActivePage(Math.ceil(pageInfo.activePage));
    }
  };

  const handleOpenCourseElementModal = async (reportCourseElementWithStandardAlignment) => {
    const { reportInfoClassNames } = reportIdentityManager;
    const { showCutScoreAssignType } = reportStandardsManager;

    await ReportStandardsClassroomDetailStudentsService.initReportCourseElementForModal(reportCourseElementWithStandardAlignment);

    const { courseId, courseName, elementId } = reportCourseElementWithStandardAlignment;

    courseManager.setCurrentCourseId(courseId);
    courseManager.setCurrentElementId(elementId);

    dialogManager.setOpenDialog(DIALOG_NAMES.COURSE_ELEMENT, {
      ...props,
      additionalClassNames: `${reportInfoClassNames}`,
      modalTitle: courseName,
      shouldSkipComponentDidMountLogic: true,
      showCutScoreAssignType
    }, () => handleCloseCourseElementModal());
  };

  const handleViewIndividualStudentReport = (student) => {
    return props.handleViewNextReport({
      byStudents: true,
      classroomId: classroomManager.currentClassroomId,
      institutionId: reportIdentityManager.activeReportInstitutionId,
      institutionName,
      standardElementId,
      studentFullName: UserService.getUserFullNameStr(student),
      studentId: student.studentId,
      truncatedStandardDescription
    });
  };

  const handleCloseCourseElementModal = async () => {
    dialogManager.closeAllDialogs();
  };

  const setStudentCutScore = async (student, creditClassName) => {
    reportStandardsClassroomDetailStudentsManager.setStudentCutScore(student, creditClassName);
  };

  const renderReportTable = () => {
    const { reportInfoClassNames } = reportIdentityManager;
    const { reportCourseElementsWithStandardAlignments } = reportStandardsClassroomDetailStudentsManager;

    const totalPages = ReportStandardsClassroomDetailStudentsService.getTotalReportCourseElementPages({
      reportCourseElementsWithStandardAlignments
    });
    const additionalClassNames = totalPages > 1 ? ' has-paginator' : '';
    return (
      <div className={`report-table-container ${reportInfoClassNames} students`}>
        <div className={`report-paginator-wrapper ${reportInfoClassNames}${additionalClassNames} students`}>
          {renderTablePaginator()}
        </div>
        <div className={`report-table-wrapper ${reportInfoClassNames} students`}>
          <Table celled
            className={`report-table ${reportInfoClassNames} students`} singleLine striped>
            {renderTableHeader()}
            {renderTableBody()}
          </Table>
        </div>
      </div>
    );
  };

  const renderTablePaginator = () => {
    const { reportInfoClassNames } = reportIdentityManager;
    const { reportCourseElementsWithStandardAlignments } = reportStandardsClassroomDetailStudentsManager;

    const totalPages = ReportStandardsClassroomDetailStudentsService.getTotalReportCourseElementPages({
      reportCourseElementsWithStandardAlignments
    });
    const activePage = reportStandardsClassroomDetailStudentsManager.activeReportStandardsClassroomDetailStudentsTablePage;
    return totalPages > 1 && (
      <div className={`report-table-paginator ${reportInfoClassNames} students`}>
        <Pagination activePage={activePage}
          className={reportInfoClassNames}
          onPageChange={handlePageChange}
          totalPages={totalPages} />
      </div>
    );
  };

  const renderTableHeader = () => {
    const { reportCourseElementsWithStandardAlignments } = reportStandardsClassroomDetailStudentsManager;
    const activePage = reportStandardsClassroomDetailStudentsManager.activeReportStandardsClassroomDetailStudentsTablePage;

    const paginatedReportCourseElements = ReportStandardsClassroomDetailStudentsService.getPaginatedReportCourseElements(
      reportCourseElementsWithStandardAlignments, activePage
    );
    return (
      <Table.Header>
        <Table.Row
          className={isLoading ? 'table-header-row loading-row' : 'table-header-row'}
          disabled={isLoading}>

          {/* HEADER: FIRST NAME */}
          <Table.HeaderCell
            key='key_header_cell_first_name'
            className='cell-first-name students'>
            <span>{t('firstName')}</span>
          </Table.HeaderCell>

          {/* HEADER: LAST NAME */}
          <Table.HeaderCell
            key='key_header_cell_last_name'
            className='cell-last-name students'>
            <span>{t('lastName')}</span>
          </Table.HeaderCell>

          {/* HEADER: AVERAGE SCORE */}
          <Table.HeaderCell
            key='key_header_cell_average_score'
            className='cell-average-result students'>
            <span>{t('averageScore')}</span>
          </Table.HeaderCell>

          {/* HEADER: ASSIGNMENT COUNT */}
          <Table.HeaderCell
            key='key_header_cell_assignment_count'
            className='cell-assignment-count students'>
            <span>{t('assignmentCount')}</span>
          </Table.HeaderCell>

          {/* HEADER: SCORE INDEXES */}
          {paginatedReportCourseElements.map((reportCourseElement, scoreIndex) => {
            const { reportStandardsClassroomDetailContentImageSourceMap } = reportStandardsClassroomDetailStudentsManager;
            const imageSourceObj = reportStandardsClassroomDetailContentImageSourceMap.get(reportCourseElement.elementId);
            const imageSource = imageSourceObj ? imageSourceObj.imageSource : '';
            const imageSourceClassName = imageSourceObj ? imageSourceObj.imageSourceClassName : '';

            const trueScoreIndex = scoreIndex + ((activePage - 1) * PAGE_SIZE);
            const className = `cell-score ${trueScoreIndex} ${imageSourceClassName} students`;

            const { courseName, elementName, elementContentItemName } = reportCourseElement;
            const questionNum = reportCourseElement && reportCourseElement.alignment && reportCourseElement.alignment.questionNum;

            let content = courseName;
            if (elementName || elementContentItemName) {
              content += ` | ${elementName || elementContentItemName}`;
            }
            if (questionNum && reportContextManager.isContextItemLevelReport) {
              content += ` | #${questionNum}`;
            }
            content = UtilityService.reactHtmlParserWrapper(content).parsed;

            let trigger;
            if (initializingCourseElementImages && !imageSourceObj) {
              trigger = (
                <Loader
                  key={`key_header_img_loader_${trueScoreIndex}_${reportCourseElement.elementId}`} active inline />
              );
            } else {
              trigger = (
                <Image
                  key={`key_header_img_src_${trueScoreIndex}_${reportCourseElement.elementId}`}
                  className={imageSourceClassName} src={imageSource} />
              );
            }
            const popupClassName = '';

            return (
              <Table.HeaderCell
                key={`key_header_cell_score_${trueScoreIndex}_${reportCourseElement.elementId}`}
                className={className}
                onClick={() => handleOpenCourseElementModal(reportCourseElement)}>
                {renderPopup(content, trigger, popupClassName, 'bottom right')}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
    );
  };

  const renderTableBody = () => {
    const { isContextItemLevelReport, isContextResourceLevelReport } = reportContextManager;
    const { reportCourseElementsWithStandardAlignments } = reportStandardsClassroomDetailStudentsManager;

    const activePage = reportStandardsClassroomDetailStudentsManager.activeReportStandardsClassroomDetailStudentsTablePage;

    const paginatedReportCourseElements = ReportStandardsClassroomDetailStudentsService.getPaginatedReportCourseElements(
      reportCourseElementsWithStandardAlignments, activePage
    );
    const students = ReportJsonHelperService.REPORT_STUDENTS();
    const rows = [];

    // clear the student cut score data, it will be recreated below with current data
    reportStandardsClassroomDetailStudentsManager.clearCurrentStudentCutScoreMap();

    // eslint-disable-next-line array-callback-return
    students.map((student, studentIndex) => {
      const itemLevelStudentScoreCount = isContextItemLevelReport && ReportStandardsClassroomDetailStudentsService
        .getReportItemLevelStudentScoreCount(student, standardElementId);

      const nullActivePage = null;

      const { resourceLevelStudentScoreCount, studentScoreAvg } = ReportStandardsClassroomDetailStudentsService
        .getStudentScoresForTableColumns(student, reportCourseElementsWithStandardAlignments, nullActivePage);

      const studentScoreCount = (isContextResourceLevelReport ?
        resourceLevelStudentScoreCount : itemLevelStudentScoreCount) || 0;

      const studentScoreAvgClassName = ReportScoreService.getReportScoreCellClassName({
        value: studentScoreAvg
      });

      const keyName = ReportScoreService.getReportScoreCellKeyName({
        value: studentScoreAvg
      });

      const { studentScoresForTableColumns } = ReportStandardsClassroomDetailStudentsService
        .getStudentScoresForTableColumns(student, paginatedReportCourseElements, activePage);

      // Save off the student cut score
      setStudentCutScore(student, keyName);

      rows.push(
        <Table.Row
          key={`key_data_row_${studentIndex}_${student.studentId}`}
          className={isLoading ? 'loading-row' : ''}
          disabled={isLoading}>

          {/* BODY: FIRST NAME */}
          <Table.Cell
            key={`key_data_cell_first_name_${studentIndex}_${student.studentId}`}
            className='cell-first-name'
            onClick={() => handleViewIndividualStudentReport(student)}>
            {renderPopup(student.firstName)}
          </Table.Cell>

          {/* BODY: LAST NAME */}
          <Table.Cell
            key={`key_data_cell_last_name_${studentIndex}_${student.studentId}`}
            className='cell-last-name'
            onClick={() => handleViewIndividualStudentReport(student)}>
            {renderPopup(student.lastName)}
          </Table.Cell>

          {/* BODY: AVERAGE SCORE */}
          <Table.Cell
            key={`key_data_cell_average_score_${student.studentId}`}
            className={`cell-average-result ${studentScoreAvgClassName}`}>
            {typeof studentScoreAvg === 'number' ?
              `${studentScoreAvg}%` : t('notAvailableDash')}
          </Table.Cell>

          {/* BODY: ASSIGNMENT COUNT */}
          <Table.Cell
            key={`key_data_cell_assignment_count_${student.studentId}`}
            className='cell-assignment-count'>
            {studentScoreCount}
          </Table.Cell>

          {/* BODY: SCORE INDEXES */}
          {studentScoresForTableColumns.map((studentScore, scoreIndex) => {
            const trueScoreIndex = scoreIndex + ((activePage - 1) * PAGE_SIZE);

            let className = `cell-score ${trueScoreIndex}`;

            const creditClassName = ReportScoreService.getReportScoreCellClassName({
              value: studentScore
            });
            className += ` scorable ${creditClassName}`;

            if (typeof studentScore === 'number') {
              return (
                <Table.Cell
                  key={`key_data_cell_${(student.studentId)}_${trueScoreIndex}`}
                  className={className}>
                  <span>
                    {studentScore}%
                  </span>
                </Table.Cell>
              );
            }
            return (
              <Table.Cell
                key={`key_data_cell_${(student.studentId)}_${trueScoreIndex}`}
                className={className}>
                <span>—</span>
              </Table.Cell>
            );
          })}
        </Table.Row>
      );
    });
    return <Table.Body>{rows}</Table.Body>;
  };

  const renderPopup = (
    content, trigger = null, className = '',
    position = 'bottom center', disabled = null, wide = 'very'
  ) => {
    disabled = typeof disabled !== 'boolean' ? (isLoading || initializingCourseElementImages) : disabled;
    return PopupService.renderPopup({
      className, content, disabled, position, trigger, wide
    });
  };

  return isLoading ? <Loader active /> : renderReportTable();
});
export default ReportStandardsClassroomDetailStudentsTable;

SatCoreRegister('ReportStandardsClassroomDetailStudentsTable', ReportStandardsClassroomDetailStudentsTable);
