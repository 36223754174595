/**
 * Utilize `overflow` style to determine whether to allow scrolling at the document body level.
 *
 * **Example use case:** Wanting to prevent the user from scrolling out-of-view of an open modal.
 */
export const allowDocumentBodyScroll = (allow) => {
  if (allow) {
    document.body.style.overflow = null;
  } else {
    document.body.style.overflow = 'hidden';
  }
};
