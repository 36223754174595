import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Image, Input, Loader, Tab
} from 'semantic-ui-react';

import ReactCrop from 'react-image-crop';
import ModalBanner from './ModalBanner';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from '../Modal';

import '../../css/SchoolImagePicker.less';

import imageBlueBackground from '../../img/image-blue-background.png';

import { SatCoreRegister } from '../../SatCoreRegistry';

import ImageService from '../../services/ImageService';

export default
@inject('schoolManager', 'userManager')
@observer
class SchoolImagePicker extends Component {
  cropPaneIndex = 1;

  constructor(props) {
    super(props);
    this.state = {
      panes: [],
      crop: { unit: '%', aspect: 1.22, x: 5, y: 5, width: 90 },
      showCropper: false,
      loading: false,
      originalFullImageUrl: null,
      originalDirectSchoolImageUrl: null,
      fullImageUrl: null,
      directSchoolImageUrl: null
    };
  }

  componentDidMount = async () => {
    const { school, schoolManager, userManager } = this.props;
    const { authKey } = userManager;

    if (school.imageContentItemId) {
      schoolManager.updatedSchoolImageContentItemId = school.imageContentItemId;
      const {
        fullImageUrl, directSchoolImageUrl
      } = await schoolManager.fetchDirectSchoolImageUrl(school, authKey);
      this.setState({
        fullImageUrl,
        directSchoolImageUrl,
        originalFullImageUrl: fullImageUrl,
        originalDirectSchoolImageUrl: directSchoolImageUrl
      });
    }
  }

  cropImagePane = () => {
    const { showCropper } = this.state;
    return (
      <div className='image-crop-pane'>
        {showCropper ? this.renderCropElements() : null}
      </div>
    );
  };

  uploadImagePane = () => (
    <div className='image-upload-pane'>
      <label className='ui button basic upload-button' htmlFor='school-image-file'>Choose File (PNG, JPG)</label>
      <Input
        accept='image/png, image/jpg'
        className='school-image-file'
        id='school-image-file'
        name='school-image-file'
        onChange={this.prepareUploadSchoolImage}
        style={{ opacity: 0 }}
        type='file' />
      {this.currentImage()}
      <Loader active={this.state.loading} className='modal-loader' inline />
    </div>
  );

  currentImage = () => {
    const { directSchoolImageUrl } = this.state;
    if (directSchoolImageUrl) {
      return (
        <Image
          className='current-image'
          onError={(event) => event.target.src = imageBlueBackground}
          src={directSchoolImageUrl}
          wrapped />
      );
    }
    return (
      <div className='no-image'>
        Please upload an image file.
      </div>
    );
  }

  createPanes = () => {
    const uploadPane = {
      menuItem: 'Upload Image',
      render: () => (
        <Tab.Pane>
          {this.uploadImagePane()}
        </Tab.Pane>
      )
    };
    const cropPane = {
      menuItem: 'Crop Image',
      render: () => (
        <Tab.Pane className='crop-tab'>
          {this.cropImagePane()}
        </Tab.Pane>
      )
    };
    return [uploadPane, cropPane];
  };

  setCrop = (newCrop) => {
    this.setState({ crop: newCrop });
  };

  cancelSaveImage = async () => {
    const { originalFullImageUrl, originalDirectSchoolImageUrl } = this.state;
    this.toggleSchoolImageEditor(originalFullImageUrl, originalDirectSchoolImageUrl);
  }

  saveImage = async () => {
    const { school, schoolManager } = this.props;
    const schoolId = school.id;
    const imageContentItemId = schoolManager.updatedSchoolImageContentItemId;

    await schoolManager.setImageToSchool(schoolId, imageContentItemId);
    const { fullImageUrl, directSchoolImageUrl } = this.state;
    this.toggleSchoolImageEditor(fullImageUrl, directSchoolImageUrl);
  }

  toggleSchoolImageEditor = (fullImageUrl = null, directSchoolImageUrl = null) => {
    const { schoolManager } = this.props;
    schoolManager.updatedSchoolImageContentItemId = null;
    this.props.toggleSchoolImageEditor(fullImageUrl, directSchoolImageUrl);
  }

  prepareUploadSchoolImage = async (_unusedEvent, _unusedData, formData = null) => {
    const { school } = this.props;
    this.setState({ loading: true });
    if (!formData) {
      const formData = new FormData();
      const schoolImageFileInputElement = document.getElementById('school-image-file');
      if (!schoolImageFileInputElement.files.length) {
        this.setState({ loading: false });
        alert('Select an image to upload');
        return;
      }
      const file = schoolImageFileInputElement.files[0];
      formData.append('schoolImageFile', file);
      await this.uploadSchoolImageAndFetchUrl(school, formData);
    } else {
      await this.uploadSchoolImageAndFetchUrl(school, formData);
    }
  };

  uploadSchoolImageAndFetchUrl = async (_school, formData) => {
    const { schoolManager, userManager } = this.props;
    const { authKey } = userManager;

    const {
      fullImageUrl, directSchoolImageUrl
    } = await schoolManager.uploadSchoolImage(formData, authKey);

    if (fullImageUrl && directSchoolImageUrl) {
      this.setState({ loading: false, fullImageUrl, directSchoolImageUrl });
      return { fullImageUrl, directSchoolImageUrl };
    }
    this.setState({ loading: false });
    alert('Uploading the image has failed. Please try again');
  }

  handleTabChange = (_event, { activeIndex }) => {
    if (activeIndex === this.cropPaneIndex) {
      this.setState({ showCropper: true });
    } else {
      this.setState({ showCropper: false });
    }
  }

  loadImage = (image) => {
    this.scaleX = image.naturalWidth / image.width;
    this.scaleY = image.naturalHeight / image.height;
    const crop = { unit: '%', aspect: 1.22, x: 5, y: 5, width: 90 };
    this.setState({ crop });
  }

  submitCrop = async () => {
    try {
      const { school, schoolManager, userManager } = this.props;
      const { crop, directSchoolImageUrl } = this.state;

      let imageContentItemId = schoolManager.updatedSchoolImageContentItemId;
      const fullImageUrl = schoolManager.getFullImageUrl(imageContentItemId);

      const updatedSchool = {
        ...school,
        imageContentItemId,
        fullImageUrl
      };
      if (this.scaleX !== undefined) {
        crop.x *= this.scaleX;
        crop.width *= this.scaleX;
      }
      if (this.scaleY !== undefined) {
        crop.y *= this.scaleY;
        crop.height *= this.scaleY;
      }
      let tmpFileName = updatedSchool.imageContentItemId;
      tmpFileName = `${tmpFileName}.png`;

      const croppedImageBlob = await ImageService.cropImage(directSchoolImageUrl, crop, tmpFileName);
      const croppedImageFile = new File([croppedImageBlob], tmpFileName, {
        type: 'image/png'
      });
      const formData = new FormData();
      formData.append('schoolImageFile', croppedImageFile);

      schoolManager.uploadSchoolImage(formData, userManager.authKey)
        .then(({ fullImageUrl, directSchoolImageUrl }) => {
          imageContentItemId = schoolManager.updatedSchoolImageContentItemId;
          schoolManager.setImageToSchool(updatedSchool.id, imageContentItemId)
            .then(() => this.toggleSchoolImageEditor(fullImageUrl, directSchoolImageUrl));
        });
    } catch (error) {
      console.error(error);
      alert('Sorry, an error occurred when attempting to crop this image.');
      this.toggleSchoolImageEditor();
    }
  }

  renderCropElements = () => {
    const { directSchoolImageUrl } = this.state;
    if (!directSchoolImageUrl) {
      return this.currentImage();
    }
    return (
      <div className='cropper-container'>
        <ReactCrop
          crop={this.state.crop}
          onChange={(newCrop) => this.setCrop(newCrop)}
          onImageLoaded={this.loadImage}
          src={directSchoolImageUrl} />
      </div>
    );
  }

  render() {
    const panes = this.createPanes();
    const { showCropper } = this.state;
    const { openSchoolImagePicker } = this.props;
    return (
      <Modal
        className='school-picker-dialog'
        closeOnDimmerClick={false}
        closeOnEscape={true}
        onClose={this.toggleSchoolImageEditor}
        open={openSchoolImagePicker}>
        <ModalBanner
          label='Edit Card Image'
          onClose={this.toggleSchoolImageEditor} />
        <Modal.Content image scrolling>
          <div className='school-image-picker-parent'>
            <Tab onTabChange={this.handleTabChange} panes={panes} />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            className='save-button'
            onClick={this.cancelSaveImage}
            primary>
            Cancel
          </Button>
          <Button
            className='save-button'
            onClick={(showCropper) ? this.submitCrop : this.saveImage}
            primary>
            {(showCropper) ? 'Crop and Save' : 'Save'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('SchoolImagePicker', SchoolImagePicker);
