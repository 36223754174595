import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {
  Button, Image, Input,
  Loader, Tab
} from 'semantic-ui-react';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import Modal from '../Modal';
import '../../css/AvatarPicker.less';

export default
@inject('adminUsersManager', 'userManager')
@observer
class AvatarPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crop: { aspect: 1 },
      currentImageName: null,
      loading: false,
      localImgUrl: undefined,
      panes: [], // eslint-disable-line react/no-unused-state
      selectedAvatar: null,
      tabIndex: 0
    };
    this.StudentDefaultAvatarPicker = SatCoreComponent('StudentDefaultAvatarPicker');
    this.DefaultAvatarPicker = SatCoreComponent('DefaultAvatarPicker');
    this.ModalBanner = SatCoreComponent('ModalBanner');
  }

  componentDidMount = async () => {
    // placeholder
  };

  selectAvatar = (name) => {
    this.setState({ selectedAvatar: name });
  };

  saveDefault = async () => {
    const { userManager, userId } = this.props;
    const { selectedAvatar } = this.state;
    await userManager.saveUserDefaultAvatar(selectedAvatar, userId);
    this.toggleAvatarEditor();
  };

  toggleAvatarEditor = () => {
    const { props } = this;
    // no need to reset state here - state will be reinitialized each time the AvatarPicker is relaunched
    // ---
    // this.setState({
    //   crop: { aspect: 1 },
    //   currentImageName: null,
    //   loading: false,
    //   selectedAvatar: null,
    //   tabIndex: 0
    // });
    props.toggleAvatarEditor();
  };

  teacherDefaultAvatarPane = () => {
    const { DefaultAvatarPicker } = this;
    const { selectedAvatar } = this.state;
    return (
      <div className='avatar-picker-container'>
        <DefaultAvatarPicker
          selectAvatar={this.selectAvatar}
          selectedAvatar={selectedAvatar}
          toggleAvatarEditor={this.toggleAvatarEditor} />
      </div>
    );
  };

  studentDefaultAvatarPane = () => {
    const { StudentDefaultAvatarPicker } = this;
    const { selectedAvatar } = this.state;
    return (
      <div className='avatar-picker-container'>
        <StudentDefaultAvatarPicker
          selectAvatar={this.selectAvatar}
          selectedAvatar={selectedAvatar}
          toggleAvatarEditor={this.toggleAvatarEditor} />
      </div>
    );
  };

  cropImagePane = () => {
    const { tabIndex } = this.state;
    return (
      <div className='image-crop-pane'>
        {(tabIndex === 2)
          ? this.renderCropElements()
          : null}
      </div>
    );
  }

  uploadImagePane = () => {
    const { loading } = this.state;
    return (
      <div className='image-upload-pane'>
        <label className='ui button basic upload-button' htmlFor='avatar-file'>Choose File (PNG, JPG)</label>
        <Input
          accept='image/png, image/jpg'
          className='avatar-file'
          id='avatar-file'
          name='avatar-file'
          onChange={this.uploadAvatar}
          style={{ opacity: 0 }}
          type='file' />
        {this.currentImage()}
        <Loader active={loading} className='modal-loader' inline />
      </div>
    );
  }

  createPanes = () => {
    const { userManager } = this.props;
    const {
      isDistrictAdmin, isSchoolAdmin, isProductAdmin, isStudent, isTeacher, restrictAvatar
    } = userManager;
    const isAdmin = isDistrictAdmin || isSchoolAdmin;
    const canUploadOrCropImage = isAdmin || isProductAdmin || isTeacher || !restrictAvatar;
    const teacherDefaultAvatarPane = {
      menuItem: 'Avatars',
      render: () => <Tab.Pane>{this.teacherDefaultAvatarPane()}</Tab.Pane>
    };
    const studentDefaultAvatarPane = {
      menuItem: 'Avatars',
      render: () => <Tab.Pane>{this.studentDefaultAvatarPane()}</Tab.Pane>
    };
    const avatarPane = isStudent ? studentDefaultAvatarPane : teacherDefaultAvatarPane;
    if (canUploadOrCropImage) {
      const uploadPane = { menuItem: 'Upload Image', render: () => <Tab.Pane>{this.uploadImagePane()}</Tab.Pane> };
      const cropPane = { menuItem: 'Crop Image', render: () => <Tab.Pane className='crop-pane'>{this.cropImagePane()}</Tab.Pane> };
      return [avatarPane, uploadPane, cropPane];
    }
    return [avatarPane];
  };

  currentImage = () => {
    const { userManager } = this.props;
    const { currentImageName, localImgUrl } = this.state;

    if (localImgUrl) {
      return (<Image className='avatar-current-image' src={localImgUrl} wrapped />);
    } else if (currentImageName) {
      const fullImageUrl = userManager.getFullUrlFromName(currentImageName);
      return (<Image className='avatar-current-image' src={fullImageUrl} wrapped />);
    } else if (userManager.fullProfileImageUrl) {
      const fullImageUrl = userManager.fullProfileImageUrl;
      return (<Image className='avatar-current-image' src={fullImageUrl} wrapped />);
    }
    return (<div className='no-image'>Please upload an image file.</div>);
  }

  saveImage = async (newImage) => {
    const { currentImageName } = this.state;
    const { userId, userManager } = this.props;

    const save = true;
    if (newImage) {
      await userManager.setProfileImageUrl(newImage, save, userId);
      this.toggleAvatarEditor();
    } else if (currentImageName) {
      await userManager.setProfileImageUrl(currentImageName, save, userId);
      this.toggleAvatarEditor();
    }
  }

  uploadAvatar = async () => {
    const { userManager } = this.props;
    this.setState({ loading: true });
    const formData = new FormData();
    const avatarImgFile = document.getElementById('avatar-file');
    if (!avatarImgFile.files.length) {
      this.setState({ loading: false });
      alert('Select an image to upload'); // eslint-disable-line no-alert
      return;
    }

    const localImg = avatarImgFile.files[0];
    const localImgUrl = URL.createObjectURL(localImg);

    formData.append('contentItemImageSelectLocalImage', localImg);
    const result = await userManager.uploadImage(formData);
    if (result) {
      this.setState({ currentImageName: result, loading: false, localImgUrl });
    } else {
      this.setState({ currentImageName: null, loading: false });
      alert('Uploading the image has failed. Please try again'); // eslint-disable-line no-alert
    }
  };

  handleTabChange = (_event, { activeIndex }) => {
    this.setState({ tabIndex: activeIndex });
  }

  loadImage = (image) => {
    this.scaleX = image.naturalWidth / image.width;
    this.scaleY = image.naturalHeight / image.height;
  }

  submitCrop = async () => {
    const { userManager } = this.props;
    const { currentImageName, crop } = this.state;
    if (this.scaleX !== undefined) {
      crop.x *= this.scaleX;
      crop.width *= this.scaleX;
    }

    if (this.scaleY !== undefined) {
      crop.y *= this.scaleY;
      crop.height *= this.scaleY;
    }
    const newImage = await userManager.submitCrop(currentImageName, crop);
    this.saveImage(newImage);
  }

  renderCropElements = () => {
    const { userManager } = this.props;
    const { crop, currentImageName, localImgUrl } = this.state;
    const fullUrl = localImgUrl || userManager.getFullUrlFromName(currentImageName);

    if (currentImageName === null && userManager.profileImageUrl === null) {
      return this.currentImage();
    }

    return (
      <div className='cropper-container'>
        <ReactCrop circularCrop={true} crop={crop}
          onChange={(newCrop) => this.setCrop(newCrop)}
          onImageLoaded={this.loadImage}
          src={(fullUrl !== null) ? fullUrl : userManager.fullProfileImageUrl} />
      </div>
    );
  }

  setCrop = (crop) => {
    this.setState({ crop });
  }

  render() {
    const panes = this.createPanes();
    const { openAvatarPicker } = this.props;
    const { tabIndex } = this.state;
    const { ModalBanner } = this;

    const showCropper = (tabIndex === 2);
    const showDefault = (tabIndex === 0);
    // eslint-disable-next-line no-nested-ternary
    const onClickSave = (showCropper) ? this.submitCrop : (showDefault) ? this.saveDefault : () => this.saveImage();

    return (
      <Modal
        closeOnDimmerClick={false}
        closeOnEscape={true}
        onClose={this.toggleAvatarEditor}
        open={openAvatarPicker}>
        <ModalBanner
          label='Edit Profile Image'
          onClose={this.toggleAvatarEditor} />
        <Modal.Content image scrolling>
          <div className='avatar-picker-parent'>
            <Tab onTabChange={this.handleTabChange} panes={panes} />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button basic className='save-button' onClick={this.toggleAvatarEditor} primary>Cancel</Button>
          <Button className='save-button'
            onClick={onClickSave}
            primary>{(showCropper) ? 'Crop and Save' : 'Save'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('AvatarPicker', AvatarPicker);
