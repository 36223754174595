import { action, computed, makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { getRegisteredClass } from '../SatCoreRegistry';

import Auth from './AuthManager';
import userManager from './UserManager';

import { INSTITUTION_DROPDOWN_MODE } from '../services/UserInstitutionDropdownConstants';

import UtilityService from '../services/UtilityService';

import { getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem } from '../utils/session';

export const CLASSROOM_ENDPOINTS = {
  ADD_CO_TEACHER: '/api/addCoTeacherToClassrooms',
  ADD_STUDENT: '/api/addStudentToClassrooms',
  ADD_STUDENTS: '/api/addStudentsToClassroom',
  CREATE_CLASSROOM_FOR_TEACHER: '/api/createClassroomForTeacher',
  CREATE_CLASSROOM_FOR_USER: '/api/createClassroomForUser',
  REMOVE_CO_TEACHER: '/api/deleteClassroomCoteacher',
  VIEW_LEAD_TEACHER_CLASSROOMS: '/api/viewLeadTeacherClassrooms',
  VIEW_LEAD_TEACHER_CLASSROOM_BY_ID: '/api/viewLeadTeacherClassroomById',
  VIEW_STUDENT_CLASSROOMS: '/api/viewStudentClassrooms'
};

export class ClassroomManager {
  @observable allowSsoAddExistingStudentToSelfRegClassroom = false;
  @observable allowSsoCreateSelfRegClassroom = false;
  @observable allowSsoViewClassroomSettings = false;
  @observable allowStudentClassMenuAltPosition = false;
  @observable allowStudentDataMigrate = true;

  @observable timestampForMostRecentApiCallToFetchClassroomData = null;

  @observable loaded = false;

  @observable classrooms = new Map();

  @observable selectedNotAttachedTerseClassrooms = new Map();

  @observable classroomsTotalPages = 0;
  @observable currentClassroomId = null;

  @observable rosterMap = new Map();
  @observable archRosterMap = new Map();

  @observable hasMore = true;
  @observable rosterHasMore = true;

  @observable enabledStudentsOnly = true;

  @observable hideAddExistingStudentButtonForTeachers = true;

  @observable includeGroups = true; // Individual satellites can shut this off in index.js

  @observable joinAccessCodeErrorObj = {
    error: false,
    errorMessage: ''
  };

  CLASSROOM_FETCH_PAGE_SIZE = 15; /* magic number but the page size is always 15 */
  CLASSROOM_ROSTER_PAGE_SIZE = 250; /* magic number but the page size is always 250 */

  constructor() {
    makeObservable(this);
  }

  currentClassArchiveStatus = computedFn((id) => {
    const currentClass = this.classrooms.get(id);
    return currentClass?.archived;
  })

  @action setHideAddExistingStudentButtonForTeachers = (trigger) => {
    this.hideAddExistingStudentButtonForTeachers = trigger;
  }

  @action setIncludeGroups = (includeGroups) => {
    this.includeGroups = includeGroups;
  }

  @action initClassrooms(classrooms) {
    this.classrooms.clear();
    this.loadClassrooms(classrooms);
  }

  @action loadClassrooms = (classrooms) => {
    if (classrooms) {
      classrooms.forEach((classroom) => {
        if (classroom.id !== null) {
          this.classrooms.set(classroom.id, classroom);
        }
      });
    }
  }

  @action setHasMore = (hasMore) => {
    this.hasMore = hasMore;
  }

  @action setRosterHasMore = (hasMore) => {
    this.rosterHasMore = hasMore;
  }

  @action setEnabledStudentsOnly = (enabled) => {
    this.enabledStudentsOnly = enabled;
  }

  updateClassroomImage = async (id, formData) => {
    const token = Auth.getToken();
    const headers = new Headers({
      Authorization: token,
      Accept: 'application/json; text/plain'
    });
    try {
      const response = await fetch(`${Auth.ecms}/api/saveClassroomTempImage?classroomId=${id}`, {
        method: 'POST',
        body: formData,
        headers
      });
      const data = await response.json();
      return data.newFileImageUrl;
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  submitCrop = async (id, crop, currentFileName) => {
    const classroom = this.getClassroom(id);
    const body = {
      x: String(crop.x),
      y: String(crop.y),
      toX: String(crop.x), /* not used in backend */
      toY: String(crop.y), /* not used in backend */
      width: String(crop.width),
      height: String(crop.height),
      fileName: String((currentFileName) || classroom.imageUrl)
    };
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/cropImagesForImagePicker?imageFromResources=false&folderName=classroomImages&classroomId=${id}`, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        this.setClassroomImageUrl(classroom.id, response.newFileName);
        await this.updateClassroomObject(classroom);
        return classroom.imageUrl;
      }
    } catch (error) {
      console.error(error);
      return '';
    }
    return '';
  }

  @action
  setAdminClassroom = async (classroomId, classroom) => {
    this.setClassroom(classroomId, classroom);
    this.setClassroomData(classroomId, classroom);
    this.setCurrentClassroomId(classroomId);
  }

  @action setTimestampForMostRecentApiCallToFetchClassroomData = (now) => {
    now = now || Date.now();
    this.timestampForMostRecentApiCallToFetchClassroomData = now;
  }

  @action
  setClassroom = (id, classroom) => {
    this.classrooms.set(id, classroom);
  }

  @action
  setClassroomName = (id, name) => {
    const classroom = this.getClassroom(id);
    if (classroom) {
      classroom.name = name;
    }
  }

  @action
  setClassroomNickname = (id, nickname) => {
    const classroom = this.getClassroom(id);
    if (classroom) {
      classroom.nickname = nickname;
    }
  }

  @action
  setClassroomStartDate = (id, startDate) => {
    const classroom = this.getClassroom(id);
    if (classroom) {
      classroom.startDate = startDate;
    }
  }

  @action
  setClassroomEndDate = (id, endDate) => {
    const classroom = this.getClassroom(id);
    if (classroom) {
      classroom.endDate = endDate;
    }
  }

  @action
  setClassroomImageUrl = (id, imageUrl) => {
    const classroom = this.getClassroom(id);
    if (classroom) {
      classroom.imageUrl = imageUrl;
      return classroom;
    }
  }

  @action setClassroomData = (id, data) => {
    const classroom = this.getClassroom(id);
    if (classroom) {
      classroom.archived = data.archived;
      classroom.endDate = data.endDate;
      classroom.fullImageUrl = data.fullImageUrl;
      classroom.imageUrl = data.imageUrl;
      classroom.name = data.name;
      classroom.nickname = data.nickname;
      classroom.startDate = data.startDate;
      classroom.timezoneStartDate = data.timezoneStartDate;
      classroom.timezoneEndDate = data.timezoneEndDate;
    }
  }

  @action removeClassroom = (id) => {
    this.classrooms.delete(id);
  }

  @action setCurrentClassroomId = (id) => {
    if (!id || id === 'null' || id === 'undefined') {
      this.currentClassroomId = null;
    } else {
      this.currentClassroomId = id;
      if (userManager.isStudent) {
        this.saveCurrentSessionClassroomId(id);
      }
    }
  }

  @action fetchClassroomRoster = async (id, page = 0) => {
    try {
      const skip = page * this.CLASSROOM_ROSTER_PAGE_SIZE;
      // eslint-disable-next-line max-len
      const response = await Auth.fetch(`${Auth.ecms}/api/viewClassroomRoster?classroomId=${id}&enabledStudentsOnly=${this.enabledStudentsOnly}&skip=${skip}&pageSize=${this.CLASSROOM_ROSTER_PAGE_SIZE}`, { method: 'GET' });
      if (response.status === 'SUCCESS') {
        const roster = response.data.sort((a, b) => {
          const lastNameA = a.lastName ? a.lastName : '';
          const lastNameB = b.lastName ? b.lastName : '';

          const firstNameA = a.firstName ? a.firstName : '';
          const firstNameB = b.firstName ? b.firstName : '';

          const predicate1 = lastNameA.localeCompare(lastNameB, 'en', {
            numeric: true
          });
          const predicate2 = firstNameA.localeCompare(firstNameB, 'en', {
            numeric: true
          });
          return predicate1 || predicate2;
        });
        this.addRoster(id, roster, skip);
      }
      const roster = this.rosterMap.get(id);
      if ((roster && roster.length < parseInt(response.pageTotal)) && response.data.length > 0) {
        this.setRosterHasMore(true);
        return true;
      }
      this.setRosterHasMore(false);
      return true;
    } catch (error) {
      console.error(error);
      this.setRosterHasMore(false);
      return false;
    }
  }

  @action fetchArchiveClassroomRoster = async (id, page = 0) => {
    try {
      if (page === 0) {
        this.setRosterHasMore(true);
      }
      const skip = page * this.CLASSROOM_ROSTER_PAGE_SIZE;
      // eslint-disable-next-line max-len
      const response = await Auth.fetch(`${Auth.ecms}/api/viewClassroomRosterInactive?classroomId=${id}&skip=${skip}&pageSize=${this.CLASSROOM_ROSTER_PAGE_SIZE}`, { method: 'GET' });
      if (response.status === 'SUCCESS') {
        this.addArchRoster(id, response.data, skip);
      }
      const roster = this.archRosterMap.get(id);

      if ((roster && roster.length < parseInt(response.pageTotal)) && response.data.length > 0) {
        this.setRosterHasMore(true);
        return true;
      }
      this.setRosterHasMore(false);
      return true;
    } catch (error) {
      console.error(error);
      this.setRosterHasMore(false);
      return false;
    }
  }

  editRosterStudent = async (rosterItem) => {
    const body = {
      id: rosterItem.id,
      active: rosterItem.active,
      userId: rosterItem.userId,
      lastName: rosterItem.lastName,
      firstName: rosterItem.firstName,
      username: rosterItem.username
    };
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/editRosterStudent`, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  migrateStudentData = async (studentId, classroomId) => {
    const body = {
      userId: studentId, 
      classroomId,
      isTeacher: userManager.isTeacher
    };
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/migrateUserData`, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /** currently used by logged in teacher to self-create classrooms */
  @action async createClassroomForUser(classroomName, startDate, endDate, pacing, institutionId) {
    try {
      const ClassroomService = getRegisteredClass('ClassroomService');
      let apiUrl = CLASSROOM_ENDPOINTS.CREATE_CLASSROOM_FOR_USER;
      apiUrl += `?classroomName=${classroomName || null}`;
      apiUrl += `&endDate=${endDate || null}`;
      apiUrl += `&institutionId=${institutionId || null}`;
      apiUrl += `&pacing=${pacing || null}`;
      apiUrl += '&reactErrorType=true';
      apiUrl += `&startDate=${startDate || null}`;

      const response = await Auth.fetch(`${Auth.ecms}${apiUrl}`, { method: 'POST' });
      if (response?.status !== 'SUCCESS') {
        const modifiedResponse = await ClassroomService.getFailedCreateClassroomResponseAppendedWithErrorStatusMessage(response);
        return modifiedResponse;
      } else {
        return response;
      }
    } catch (error) {
      console.error(error);
    }
  }

  /** currently used by admins to create classrooms for other users (i.e. teachers) */
  @action async createClassroomForTeacher(teacherId, classroomName, startDate, endDate, pacing, institutionId) {
    try {
      const ClassroomService = getRegisteredClass('ClassroomService');
      let apiUrl = CLASSROOM_ENDPOINTS.CREATE_CLASSROOM_FOR_TEACHER;
      apiUrl += `?classroomName=${classroomName || null}`;
      apiUrl += `&endDate=${endDate || null}`;
      apiUrl += `&institutionId=${institutionId || null}`;
      apiUrl += `&pacing=${pacing || null}`;
      apiUrl += '&reactErrorType=true';
      apiUrl += `&startDate=${startDate || null}`;
      apiUrl += `&teacherId=${teacherId || null}`;
      const response = await Auth.fetch(`${Auth.ecms}${apiUrl}`, { method: 'POST' });
      if (response?.status !== 'SUCCESS') {
        return ClassroomService.getFailedCreateClassroomResponseAppendedWithErrorStatusMessage(response);
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  @action async joinClassroom(code, addTeacher = false) {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/joinClassroom`, {
        body: {
          signupCode: code,
          addTeacher
        },
        method: 'POST'
      });
      if (response) {
        if (response.status === 'SUCCESS') {
          if (!userManager.isStudent) {
            this.initClassrooms(response.classrooms);
          }
          return response;
        } else if (response.status === 'WARNING') {
          return response;
        }
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action addRoster(classroomId, roster, page = 0) {
    if (classroomId === null || classroomId === undefined || classroomId === '') {
      return;
    }
    if (page === 0) {
      this.rosterMap.set(classroomId, roster);
      return;
    }
    const oldRoster = this.rosterMap.get(classroomId);
    if (oldRoster !== null && oldRoster !== undefined) {
      oldRoster.push.apply(oldRoster, roster);
      this.rosterMap.set(classroomId, oldRoster);
    } else {
      this.rosterMap.set(classroomId, roster);
    }
  }

  @action addArchRoster(classroomId, roster, page = 0) {
    if (classroomId === null || classroomId === undefined || classroomId === '') {
      return;
    }
    if (page === 0) {
      this.archRosterMap.set(classroomId, roster);
      return;
    }

    const oldRoster = this.archRosterMap.get(classroomId);
    if (oldRoster !== null && oldRoster !== undefined) {
      oldRoster.push.apply(oldRoster, roster);
      this.archRosterMap.set(classroomId, oldRoster);
    } else {
      this.archRosterMap.set(classroomId, roster);
    }
  }

  @action setStudentAccessCode(classId, code) {
    if (this.classrooms.get(classId) != null) {
      this.classrooms.get(classId).studentSignUpCode = code;
    }
  }

  @action setCoTeacherAccessCode(classId, code) {
    if (this.classrooms.get(classId) != null) {
      this.classrooms.get(classId).coteacherSignUpCode = code;
    }
  }

  getClassroomImageUrl = computedFn(function (classroomId) {
    const classroom = this.getClassroom(classroomId);
    if (classroom === null || classroom === undefined) {
      return null;
    }
    const { imageUrl, fullImageUrl } = classroom;
    return this.createClassroomImageUrl(imageUrl, fullImageUrl);
  })

  createClassroomImageUrl = computedFn((imageUrl, fullImageUrl) => {
    let image = null;
    if (!imageUrl || imageUrl.length === 0 || fullImageUrl.split('/').pop() === 'null') {
      image = null;
    } else {
      const date = new Date(); /* to prevent caching of image */
      const resourceFileIndex = fullImageUrl.indexOf('resourceFileName');
      const resourceFileString = resourceFileIndex === -1 ? '' : fullImageUrl.slice(resourceFileIndex);
      if (resourceFileString !== '') {
        const resourceFileNameIndex = resourceFileString.indexOf('=');
        const resourceFileName = resourceFileNameIndex === -1 ? '' : resourceFileString.slice(resourceFileNameIndex + 1);
        image = (resourceFileName !== 'null')
          ? (`${fullImageUrl}&authKey=${Auth.authKey}&rn=${date.getTime()}`)
          : null;
      } else {
        image = null;
      }
    }
    return image;
  })

  fetchClassroomData = async (
    currentClassroomId = null,
    page = 0,
    isStudent = false,
    userId = null,
    archived = false, {
      pageSize = 999,
      shouldClearFirst = false
    } = {}) => {
    try {
      const then = this.timestampForMostRecentApiCallToFetchClassroomData;
      const now = Date.now();

      if (then && ((now - then) < 500)) {
        // do not rerun fetchClassroomData() logic if the last call to it was less than 500ms ago
        return;
      }

      this.setTimestampForMostRecentApiCallToFetchClassroomData(now);

      const { VIEW_LEAD_TEACHER_CLASSROOMS, VIEW_STUDENT_CLASSROOMS } = CLASSROOM_ENDPOINTS;
      let url = '';
      if (isStudent) {
        url = `${Auth.ecms}${VIEW_STUDENT_CLASSROOMS}?includeBlank=false&studentId=${userId}`;
      } else {
        page *= this.CLASSROOM_FETCH_PAGE_SIZE;
        url = `${Auth.ecms}${VIEW_LEAD_TEACHER_CLASSROOMS}?includeBlank=false&skip=${page}&reactErrorType=true&archived=${archived}`;
      }
      if (typeof pageSize === 'number') {
        url += `&pageSize=${pageSize}`;
      }
      const response = await Auth.fetch(url, { method: 'GET' });
      if (response && response.status === 'SUCCESS') {
        if (!shouldClearFirst && (page > 0 || isStudent)) {
          this.loadClassrooms(response.classrooms);
        } else {
          this.initClassrooms(response.classrooms);
        }
        if (isStudent) {
          if (response.classrooms.length > 0) {
            // see if we have a cached last classroom in session
            const currentSessionClassroomId = await this.getCurrentSessionClassroomId();
            if (currentSessionClassroomId) {
              this.setCurrentClassroomId(currentSessionClassroomId);
            } else {
              this.setCurrentClassroomId(response.classrooms[0].id);
            }
          }
        } else if (currentClassroomId) {
          if (this.getClassroom(currentClassroomId) === null) {
            await this.fetchClassroom(currentClassroomId);
            this.setCurrentClassroomId(currentClassroomId);
          } else {
            this.setCurrentClassroomId(currentClassroomId);
          }
        }
      }
      this.setLoaded(true);

      const hasMore = this.classrooms.size < response.pageTotal;
      this.setClassroomsTotalPages(Math.ceil(response.pageTotal / this.CLASSROOM_FETCH_PAGE_SIZE));
      this.setHasMore(hasMore);
      return hasMore;
    } catch (error) {
      console.error(error);
      this.setLoaded(true);
      this.setHasMore(false);
      return false;
    }
  }

  @action decrementCourseCount = (id, count) => {
    const classroom = this.classrooms.get(id);
    if (classroom) {
      classroom.courseCount -= count;
      if (classroom.courseCount <= 0) {
        classroom.courseCount = 0;
      }
    }
  }

  getActivityCount = (classroomId) => {
    const classroom = this.getClassroom(classroomId);
    if (classroom) {
      return (classroom.closedActivityCount +
        classroom.completedActivityCount +
        classroom.readyActivityCount +
        classroom.startedActivityCount +
        classroom.lockedActivityCount +
        classroom.previewActivityCount
      );
    }
    return 0;
  }

  @action
  changeColor = async (id, color) => {
    try {
      const classroom = this.classrooms.get(id);
      classroom.color = color;
      const response = await Auth.fetch(`${Auth.ecms}/api/updateClassroom?includeBlank=false`, {
        method: 'POST',
        body: classroom
      });
      if (response && response.status === 'SUCCESS') {
        this.loadClassrooms(response.classrooms);
      }
    } catch (error) {
      console.error(error);
    }
  }

  updateClassroomObject = async (classroom) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/updateClassroom?includeBlank=false`, {
        method: 'POST',
        body: classroom
      });
      if (response && response.status === 'SUCCESS') {
        const nClassroom = response.classrooms[0];
        this.setClassroomData(classroom.id, nClassroom);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action
  updateClassroom = async (id, name, nickname, archived, endDate, startDate, imageUrl) => {
    try {
      const body = { id, name, nickname, archived: (archived) ? 'true' : 'false', endDate, startDate, imageUrl };
      const response = await Auth.fetch(`${Auth.ecms}/api/updateClassroom?includeBlank=false`, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        this.setClassroomData(id, response.classrooms[0]);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  getClassroom = (id) => {
    if (id) {
      const classroom = this.classrooms.get(id);
      if (classroom !== null && classroom !== undefined) {
        return classroom;
      }
    }
    return null;
  }

  @computed get availableClassroomsArray() {
    const UserService = getRegisteredClass('UserService');
    const hasMultipleInstitutions = UserService.hasMultipleInstitutions();

    const { SELECT_CLASSROOM_MODE } = INSTITUTION_DROPDOWN_MODE;
    const selectedInstitutionId = UserService.getSelectedDropdownInstitutionId(SELECT_CLASSROOM_MODE);

    return this.classroomsArray.filter((classroom) => {
      if (classroom.archived) {
        return false;
      } else if (!hasMultipleInstitutions) {
        return true;
      } else if (!userManager.institutionsMap.has(classroom.institutionId)) {
        return false;
      } else {
        return selectedInstitutionId === 'allInstitutions' || selectedInstitutionId === classroom.institutionId;
      }
    });
  }

  @computed get classroomsArray() {
    if (this.classrooms) {
      return Array.from(this.classrooms.values());
    }
    return null;
  }

  @computed get classroomIdArray() {
    if (this.classrooms) {
      return Array.from(this.classrooms.keys());
    }
    return null;
  }

  isLeadTeacher(classroomId, userId) {
    return (this.loaded && this.getClassroom(classroomId)?.leadTeacherId === userId);
  }

  hasCoteachers(classroomId) {
    return (this.loaded && this.getClassroom(classroomId) && this.getClassroom(classroomId).coteacherCount > 0);
  }

  getClassName = (classroomId) => {
    const classroom = this.getClassroom(classroomId);
    if (!classroom) {
      return '';
    }
    const { name, nickname, description, displayName } = classroom;
    const classroomName = nickname || name || displayName || description;
    return classroomName && UtilityService.reactHtmlParserWrapper(classroomName).parsed;
  }

  @action setAllowSsoAddExistingStudentToSelfRegClassroom = (toggle) => {
    this.allowSsoAddExistingStudentToSelfRegClassroom = toggle;
  }

  @action setAllowSsoCreateSelfRegClassroom = (toggle) => {
    this.allowSsoCreateSelfRegClassroom = toggle;
  }

  @action setAllowSsoViewClassroomSettings = (toggle) => {
    this.allowSsoViewClassroomSettings = toggle;
  }

  @action setAllowStudentClassMenuAltPosition = (toggle) => {
    this.allowStudentClassMenuAltPosition = toggle;
  }

  @action setAllowStudentDataMigrate = (toggle) => {
    this.allowStudentDataMigrate = toggle;
  }

  @action setLoaded(toggle) {
    this.loaded = toggle;
  }

  @action addSelectedNotAttachedTerseClassroom = (terseClassroom) => {
    if (terseClassroom?.classroomId) {
      this.selectedNotAttachedTerseClassrooms.set(terseClassroom.classroomId, terseClassroom);
    }
  }

  @action removeSelectedNotAttachedTerseClassroom = (classroomId) => {
    if (classroomId && this.selectedNotAttachedTerseClassrooms.has(classroomId)) {
      this.selectedNotAttachedTerseClassrooms.delete(classroomId);
    }
  }

  @action clearSelectedNotAttachedTerseClassrooms = () => {
    this.selectedNotAttachedTerseClassrooms.clear();
  }

  @action setClassroomsTotalPages(value) {
    this.classroomsTotalPages = value;
  }

  @computed get isLoading() {
    if (this.loaded === null) {
      return null;
    }
    return !this.loaded;
  }

  @action
  clearAll() {
    this.classrooms.clear();
    this.clearSelectedNotAttachedTerseClassrooms();
    this.currentClassroomId = null;
    this.hasMore = true;
    this.rosterHasMore = true;
    this.rosterMap.clear();
    this.loaded = null;
  }

  @action getClassroomsByCourse = async (courseId) => {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewClassroomsWithCourse?courseContentItemId=${courseId}`,
        { method: 'GET' }
      );
      if (response.status === 'SUCCESS') {
        const classrooms = response.data;
        this.loadClassrooms(classrooms);
        return classrooms;
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action async resetStudentPassword(studentId, password) {
    let url = Auth.ecms;
    url += '/api/resetUserPassword';
    // Don't think that it needed here since we are just resetting password and we don't keep that data on frontend
    // so why we should show loader
    // this.setLoaded(false);
    try {
      const response = await Auth.fetch(url, {
        method: 'POST',
        body: {
          userId: studentId,
          password
        }
      });
      if (!(response.status === 'SUCCESS')) {
        /* placeholder */
      }
      // this.setLoaded(true);
    } catch (error) {
      // this.setLoaded(true);
      console.error(error);
    }
  }

  @action async fetchClassroom(classroomId, { fetchOnlyAndReturnImmediatelyAfterResponse = false, teacherId = null } = {}) {
    if (!fetchOnlyAndReturnImmediatelyAfterResponse && this.getClassroom(classroomId) !== null) {
      return this.getClassroom(classroomId);
    }
    try {
      let apiUrl = `${Auth.ecms}${CLASSROOM_ENDPOINTS.VIEW_LEAD_TEACHER_CLASSROOM_BY_ID}`;
      apiUrl += `?classroomId=${classroomId}`;
      apiUrl += `${teacherId ? `&teacherId=${teacherId}` : ''}`;
      apiUrl += '&reactErrorType=true';
      const response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
      if (fetchOnlyAndReturnImmediatelyAfterResponse) {
        return response && response.data && response.data[0];
      }
      if (response && response.data && response.data[0]) {
        this.loadClassrooms(response.data);
        return this.getClassroom(classroomId);
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  @action async fetchStudentAccessCode(classId) {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewClassroom?classroomId=${classId}`, {
        method: 'GET'
      });
      if (response && response.data[0]) {
        this.setStudentAccessCode(classId, response.data[0].studentSignUpCode);
        return response.data[0].studentSignUpCode;
      }
    } catch (error) {
      console.error(error);
    }
  }

  getStudentAccessCode(classId) {
    if (this.classrooms.get(classId) && this.classrooms.get(classId).studentSignUpCode) {
      return this.classrooms.get(classId).studentSignUpCode;
    }
    return null;
  }

  @action async refreshStudentAccessCode(classId) {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/refreshStudentSignUpCode`, {
        method: 'POST',
        body: {
          classroomId: classId
        }
      });
      if (response.status === 'SUCCESS') {
        this.fetchStudentAccessCode(classId);
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action async fetchCoTeacherAccessCode(classId) {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewClassroom?classroomId=${classId}`, {
        method: 'GET'
      });
      if (response && response.data[0]) {
        this.setCoTeacherAccessCode(classId, response.data[0].coteacherSignUpCode);
        return response.data[0].coteacherSignUpCode;
      }
    } catch (error) {
      console.error(error);
    }
  }

  getCoTeacherAccessCode(classId) {
    if (this.classrooms.get(classId) && this.classrooms.get(classId).coteacherSignUpCode) {
      return this.classrooms.get(classId).coteacherSignUpCode;
    }
    return null;
  }

  @action async refreshCoTeacherAccessCode(classId) {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/refreshCoTeacherSignUpCode`, {
        method: 'POST',
        body: {
          classroomId: classId
        }
      });
      if (response.status === 'SUCCESS') {
        this.fetchCoTeacherAccessCode(classId);
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action setJoinAccessCodeErrorObj = (error) => {
    this.joinAccessCodeErrorObj = error;
  }

  @action async migrateStudentDataCheck(studentId, classroomId) {
    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/migrateUserDataCheck?userId=${studentId}&classroomId=${classroomId}`, {
        method: 'GET'
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  deleteCoteacher = async (classroomId, coteacherId) => {
    try {
      const response = await Auth.fetch(
        `${Auth.ecms}${CLASSROOM_ENDPOINTS.REMOVE_CO_TEACHER}?classId=${classroomId}&coteacherId=${coteacherId}`, {
          method: 'DELETE'
        });
      /* return response so UI can handle any errors */
      return response;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  getCurrentSessionClassroomId = async () => {
    const lastClassroomKey = `c2c_lastClassroomId_${userManager.userId}`;
    const sessionItem = await getSessionStorageItem(lastClassroomKey);
    const data = JSON.parse(sessionItem || null);
    if (data && data.classroomId) {
      return data.classroomId;
    } else {
      return null;
    }
  }

  saveCurrentSessionClassroomId = async (classroomId) => {
    // Save location for quick return.
    const lastClassroomData = {
      classroomId
    };
    setSessionStorageItem(`c2c_lastClassroomId_${userManager.userId}`, JSON.stringify(lastClassroomData));
  }

  removeCurrentSessionClassroomId = async () => {
    const lastClassroomDataStr = getSessionStorageItem(`c2c_lastClassroomId_${userManager.userId}`);
    if (lastClassroomDataStr) {
      removeSessionStorageItem(`c2c_lastClassroomId_${userManager.userId}`);
    }
  }
}

export default new ClassroomManager();
