import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import '../css/CartModalUsersPopup.less';

export default
@inject('adminUsersManager', 'filteredHeaderTableManager')
@observer
class CartModalUsersPopup extends Component {
  filteredHeaderTableKey = 'cart-modal-users-popup-table';

  constructor(props) {
    super(props);
    this.FilteredHeaderTable = SatCoreComponent('FilteredHeaderTable');
  }

  componentDidMount = async () => {
    const { filteredHeaderTableManager } = this.props;
    filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);
  }

  handleSort = async (clickedColumn, filters) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      if (clickedColumn) {
        const newDirection = tableData.direction === 'ascending' ? 'descending' : 'ascending';
        filteredHeaderTableManager.setSortColumn(this.filteredHeaderTableKey, clickedColumn);
        filteredHeaderTableManager.setSortDirection(this.filteredHeaderTableKey, newDirection);
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      } else {
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      }
    }
  }

  createHeaderData = () => [
    {
      label: 'Email/Username',
      sortKey: 'username',
      filterKey: '',
      filterLabel: '',
      filterAutoCompleteCallback: null
    },
    {
      label: 'First Name',
      sortKey: 'firstName',
      filterKey: '',
      filterLabel: '',
      filterAutoCompleteCallback: null
    },
    {
      label: 'Last Name',
      sortKey: 'lastName',
      filterKey: '',
      filterLabel: '',
      filterAutoCompleteCallback: null
    },
    {
      label: 'School',
      sortKey: 'institutionName',
      filterKey: '',
      filterLabel: '',
      filterAutoCompleteCallback: null
    },
    {
      label: '',
      sortKey: '',
      filterKey: ''
    }
  ]

  sort = (users) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (!tableData) return users;
    const { column } = tableData;
    const { direction } = tableData;
    if (!column || column === '') return users;
    return users.sort((a, b) => {
      if (a[column] > b[column]) {
        return direction === 'ascending' ? 1 : -1;
      }
      if (a[column] < b[column]) {
        return direction === 'ascending' ? -1 : 1;
      }
      return 0;
    });
  }

  createBodyData = () => {
    const { users, onRemove } = this.props;
    return this.sort(users).map((user) => [
      {
        /* Email/Username */
        columnName: 'username',
        columnText: user.username,
        columnClassName: 'truncate-long',
        columnOnClick: null,
        columnButtonOnClick: null,
        showTooltip: true,
        showTooltipEvent: 'click'
      },
      {
        /* First Name */
        columnName: 'firstName',
        columnText: user.firstName,
        columnClassName: 'truncate-short',
        columnOnClick: null,
        columnButtonOnClick: null,
        showTooltip: true,
        showTooltipEvent: 'click'
      },
      {
        /* Last Name */
        columnName: 'lastName',
        columnText: user.lastName,
        columnClassName: 'truncate-short',
        columnOnClick: null,
        columnButtonOnClick: null,
        showTooltip: true,
        showTooltipEvent: 'click'
      },
      {
        /* School */
        columnName: 'school',
        columnText: user.institutionName,
        columnClassName: 'truncate-long',
        columnOnClick: null,
        columnButtonOnClick: null,
        showTooltip: true,
        showTooltipEvent: 'click'
      },
      {
        /* 'Remove' button placeholder */
        columnName: null,
        columnText: 'Remove',
        columnClassName: '',
        columnOnClick: null,
        columnButtonOnClick: () => onRemove(user),
        columnButtonBasic: false
      }
    ]);
  }

  render() {
    const { filteredHeaderTableManager } = this.props;
    const { FilteredHeaderTable } = this;
    const tableBodyData = this.createBodyData();
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    let column = '';
    let direction = '';
    let filters = [];
    if (tableData) {
      column = tableData.column;
      direction = tableData.direction;
      filters = tableData.filters;
    }

    return (
      <Container className='cart-modal-users-popup' fluid>
        <Container>
          <div className='tableContainerHeader'>
            <div className='tableTitle theme-header-title'>Selected Students</div>
          </div>
          <div className='tableContainer'>
            <FilteredHeaderTable
              column={column}
              direction={direction}
              filtersData={filters}
              handleSort={this.handleSort}
              tableBodyData={tableBodyData}
              tableHeaderData={this.createHeaderData()}
              tableId={this.filteredHeaderTableKey} />
          </div>
          {(!tableBodyData || tableBodyData.length < 1) && (
          <div className='null-state-panel'>
            No Data Found
          </div>
          )}
        </Container>
      </Container>
    );
  }
}

SatCoreRegister('CartModalUsersPopup', CartModalUsersPopup);
