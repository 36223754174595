import defaultLogo from '../img/Logo.svg';

import { registerClass } from '../SatCoreRegistry';

export default class BannerService {
  /**
   * intended to be overridden by satellites
   */
  static generateFooterLinkMap = () => {
    return new Map([
      ['termsOfUse', 'www.example.com'],
      ['privacy', 'www.example.com'],
      ['support', 'www.example.com']
    ]);
  }

  /**
   * intended to be overridden by satellites
   */
  static getLogoInfo = () => {
    return {
      img: defaultLogo,
      url: ''
    };
  }
}

registerClass('BannerService', BannerService);
