import React, { useContext, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../css/ButtonBar.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import { renderButton } from '../utils';

const ButtonBar = observer((props = {}) => {
  const { additionalClassNames, buttonBarObjs } = props;

  const [toggledButtonBarObj, setToggledButtonBarObj] = useState();

  const { /* managers */ } = useContext(MobXProviderContext);

  const handleClickBarButton = (event, buttonBarObj = {}) => {
    setToggledButtonBarObj(buttonBarObj);
    props.onClickBarButton?.(event, buttonBarObj);
  };

  return Array.isArray(buttonBarObjs) ? (
    <div className={classNames('ButtonBar', additionalClassNames)}>
      {buttonBarObjs.map((buttonBarObj) => {
        return (
          renderButton({
            additionalClassNames: classNames('button-bar-btn', buttonBarObj.additionalClassNames),
            additionalProps: { key: buttonBarObj.buttonKey },
            buttonKey: buttonBarObj.translatedButtonKey || buttonBarObj.buttonKey,
            doNotTranslate: !!buttonBarObj.doNotTranslate,
            onClick: (event) => handleClickBarButton(event, buttonBarObj),
            primary: toggledButtonBarObj ? (
              toggledButtonBarObj?.buttonKey === buttonBarObj.buttonKey
            ) : !!buttonBarObj.defaultActive,
          })
        );
      })}
    </div>
  ) : null;
});
export default ButtonBar;

SatCoreRegister('ButtonBar', ButtonBar);
