import InfiniteScroll from 'react-infinite-scroller';

import { SatCoreRegister } from '../SatCoreRegistry';

/**
 * The intent of this class is to override the way Infinite scroll handles the change of pageStart so it
 * resets if the pageStart value changes.  This allows the page numbers to reset if external factors change
 * but the component hasn't rerendered.
 */
export default class InfiniteScrollResetable extends InfiniteScroll {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pageStart !== this.props.pageStart) {
      this.pageLoaded = 0;
    }
    this.attachScrollListener();
  }
}
SatCoreRegister('InfiniteScrollResetable', InfiniteScrollResetable);
