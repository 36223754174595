import { useEffect } from 'react';

export const useScript = ({
  cryptographicId = '',
  defer = 'defer',
  url
} = {}) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.async = true;
    script.defer = defer;
    script.nonce = cryptographicId;
    script.src = url;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useScript;
