/* eslint-disable max-len */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import {
  Icon, Loader, Menu,
} from 'semantic-ui-react';

import '../css/LibraryResourceItemBankCard.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import PopupService from '../services/PopupService';
import UtilityService from '../services/UtilityService';

@inject('dialogManager', 'navigationManager', 'schoolManager', 'userManager', 'itemBankManager')
@observer
class ItemBankCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceId: '',
      resourceTitle: '',
      resourceType: ''
    };
    this.SimplePillList = SatCoreComponent('SimplePillList');
  }

  componentDidMount = async () => {

  }

  renderMenuContent = () => {
    const { libraryCardParams, libraryCardKebabOptions } = this.props;
    const kebabOptions = [];
    if (libraryCardKebabOptions) {
      for (const kebabOptionKey of Object.keys(libraryCardKebabOptions)) {
        const kebabOption = libraryCardKebabOptions[kebabOptionKey];
        kebabOptions.push(
          <Menu.Item key={kebabOption.key}
            name={kebabOption.label}
            onClick={() => kebabOption.callback(libraryCardParams)}>
            {kebabOption.label}
          </Menu.Item>
        );
      }
      return (
        <Menu vertical>
          {kebabOptions}
        </Menu>
      );
    } else {
      return (
        <Menu vertical>
          <Loader active />
        </Menu>
      );
    }
  }

  render() {
    const { onBodyClick, libraryCardParams, handleShowItemDetails, itemBankManager } = this.props;
    const { SimplePillList } = this;
    const { backgroundImg, cardClassName, libraryResource } = libraryCardParams;
    const name = libraryResource.name || '';
    const displayName = libraryResource.displayName || name;
    const questionStem = (libraryResource.questionStem) ? libraryResource.questionStem : displayName;
    const questionTypeText = itemBankManager.getQuestionTypeText(libraryResource.entitySubTypeId);
    const subTypeId = (libraryResource.entitySubTypeId) ? libraryResource.entitySubTypeId : '';
    let strippedText = questionStem.replace('<p>', '<div>').replace('</p>', '</div>');
    strippedText = `<div>${strippedText}</div>`;
    const newParsed = UtilityService.reactHtmlParserWrapper(strippedText).parsed;
    return (
      <div className={`item-bank-card ${cardClassName} ${subTypeId}`} style={{ backgroundImage: `url(${backgroundImg})` }}>
        <div className={`library-card-title ${subTypeId}`}>
          <div className={`library-card-title-text ${onBodyClick ? 'clickable' : ''}`} onClick={onBodyClick ? () => onBodyClick(libraryCardParams) : null}>{newParsed}</div>
          <div className='library-card-tag-section'>
            <SimplePillList assessmentItem={null} handleShowItemDetails={handleShowItemDetails} isFullList={false} libraryCardData={libraryCardParams}
              pills={libraryResource.categoryTags} pillClass={subTypeId}/>
          </div>
          <div className='library-card-footer'>
            <div className='resource-type-text'>{questionTypeText}</div>
            {PopupService.renderPopup({
              className: 'resource-option-popup',
              content: this.renderMenuContent(),
              on: 'hover',
              position: 'bottom left',
              trigger: <Icon name='ellipsis horizontal' />
            })}
          </div>
        </div>

      </div>
    );
  }
}
export default ItemBankCard;

SatCoreRegister('ItemBankCard', ItemBankCard);
