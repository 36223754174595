import React, { useContext, useEffect, useState } from 'react';

import { toJS } from 'mobx';
import { MobXProviderContext, observer } from 'mobx-react';

import { Loader } from 'semantic-ui-react';

import classNames from 'classnames';

import { kebabCase } from 'lodash';

import '../../css/AddLibraryCourseToClassroomsModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

const AddLibraryCourseToClassroomsModal = observer((props = {}) => {
  const {
    buttonKeyCancelLabel = 'cancel',
    buttonKeyConfirmLabel = 'add',
    className = 'AddLibraryCourseToClassroomsModal',
    closeOnDimmerClick = false,
    closeOnEscape = true,
    course,
    disableKeystroke = false,
    disableMove = false,
    disableResize = false,
    initHeight,
    initWidth,
    left = window.innerWidth / 2.75,
    minHeight = 320,
    minWidth = 545,
    onOpen = () => {},
    onRequestClose,
    open: isOpen,
    overlay = 'medium',
    selectedProductId,
    t,
    top = window.innerHeight / 4
  } = props;

  const {
    classroomManager, courseManager
  } = useContext(MobXProviderContext);

  const [isLoadingAddLibraryCourseToClassroomsModal, setIsLoadingAddLibraryCourseToClassroomsModal] = useState(false);

  const [allTerseClassroomsByCourseForCurrentUser, setAllTerseClassroomsByCourseForCurrentUser] = useState([]);

  const GenericModal = SatCoreComponent('GenericModal');
  const SCCheckbox = SatCoreComponent('SCCheckbox');

  useEffect(() => {
    (async () => {
      setIsLoadingAddLibraryCourseToClassroomsModal(true);
      const { id: courseContentItemId } = course;

      const terseClassrooms = await courseManager.fetchTerseClassroomsByCourseForCurrentUser({ courseContentItemId });
      setAllTerseClassroomsByCourseForCurrentUser(terseClassrooms);

      setIsLoadingAddLibraryCourseToClassroomsModal(false);
    })();
  }, []);

  const handleToggleClassroomOption = (event, terseClassroom = {}) => {
    const classroomId = terseClassroom?.classroomId;
    const attachedToCurrentCourse = terseClassroom?.attached;
    if (!classroomId || attachedToCurrentCourse) {
      return;
    }
    const checked = !!event?.target?.checked;
    if (checked) {
      classroomManager.addSelectedNotAttachedTerseClassroom(terseClassroom);
    } else {
      classroomManager.removeSelectedNotAttachedTerseClassroom(classroomId);
    }
  };

  const handleCloseAddLibraryCourseToClassroomsModal = async (_event, actionButtonData = {}) => {
    setIsLoadingAddLibraryCourseToClassroomsModal(true);

    if (actionButtonData?.className?.includes?.(kebabCase(buttonKeyConfirmLabel))) {
      // user confirmed changes via 'Add (course to classrooms)' action button
      const classroomIds = Array.from(classroomManager.selectedNotAttachedTerseClassrooms.keys());

      const { id: courseContentItemId } = course;

      await courseManager.addCourseToClassrooms(classroomIds, courseContentItemId, selectedProductId);

      props.onClose?.({
        shouldRefreshData: !!classroomIds?.length
      });

      classroomManager.clearSelectedNotAttachedTerseClassrooms();

      setIsLoadingAddLibraryCourseToClassroomsModal(false);
    } else {
      props.onClose?.();
      // user did not confirm changes, so clear all selections
      classroomManager.clearSelectedNotAttachedTerseClassrooms();
      setIsLoadingAddLibraryCourseToClassroomsModal(false);
    }
  };

  const renderClassroomOption = (terseClassroom) => {
    const attachedToCurrentCourse = terseClassroom.attached;
    const classroomId = terseClassroom?.classroomId;
    const checkboxLabel = terseClassroom?.classroomName;
    return (
      <div key={classroomId}
        className={classNames('add-library-course-to-classrooms-modal-faculty-option-row')}>
        <div className={classNames('classroom-option-checkbox-wrapper')}>
          <SCCheckbox
            checked={classroomManager.selectedNotAttachedTerseClassrooms.has(classroomId) || attachedToCurrentCourse}
            disabled={attachedToCurrentCourse}
            hoverAdditionalClassNames='classroom-option-checkbox-popup'
            hoverLabelContent={checkboxLabel}
            hoverMouseEnterDelay={2000}
            label={checkboxLabel}
            onChange={(event) => handleToggleClassroomOption(event, terseClassroom)}
            useHoverLabel
            value={classroomId} />
        </div>
      </div>
    );
  };

  const renderClassroomOptions = () => {
    return (
      <>
        <div className='add-library-course-to-classrooms-modal-body-top'>
          <div className='add-library-course-to-classrooms-modal-body-top-left'>
            <div className='add-library-course-to-classrooms-modal-body-header-label'>
              {t('addLibraryCourseToClassroomsModalBodyHeaderLabel', { courseName: course?.name || t('currentlySelectedCourse') })}
            </div>
          </div>
          <div className='add-library-course-to-classrooms-modal-body-top-mid'>
            {/* placeholder */}
          </div>
          <div className='add-library-course-to-classrooms-modal-body-top-right'>
            {/* <div className='add-library-course-to-classrooms-reset-all'
              onClick={() => {
                // TODO not implemented
              }}>
              {t('resetAll')}
            </div> */}
          </div>
        </div>
        <div className='add-library-course-to-classrooms-modal-faculty-options'>
          {isLoadingAddLibraryCourseToClassroomsModal ? (
            <Loader active inline />
          ) : (
            <>
              {toJS(allTerseClassroomsByCourseForCurrentUser)?.map((terseClassroom) => {
                return renderClassroomOption(terseClassroom);
              })}
            </>
          )}
        </div>
      </>
    );
  };

  const headerMsg = t('addLibraryCourseToClassroomsModalHeaderLabel');

  return (
    <GenericModal
      bodyMsg={renderClassroomOptions}
      buttonKeyCancelLabel={buttonKeyCancelLabel}
      buttonKeyConfirmLabel={buttonKeyConfirmLabel}
      className={className}
      closeOnDimmerClick={closeOnDimmerClick}
      closeOnEscape={closeOnEscape}
      disableKeystroke={disableKeystroke}
      disableMove={disableMove}
      disableResize={disableResize}
      headerMsg={headerMsg}
      initHeight={initHeight || minHeight}
      initWidth={initWidth || minWidth}
      isLoading={isLoadingAddLibraryCourseToClassroomsModal}
      isOpen={isOpen}
      left={left}
      onClose={handleCloseAddLibraryCourseToClassroomsModal}
      onOpen={onOpen}
      onRequestClose={onRequestClose}
      overlay={overlay}
      top={top} />
  );
});

export default AddLibraryCourseToClassroomsModal;

SatCoreRegister('AddLibraryCourseToClassroomsModal', AddLibraryCourseToClassroomsModal);
