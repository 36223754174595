import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Form,
  Grid,
  Message,
} from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import ClassroomService from '../../services/ClassroomService';
import Modal from '../Modal';

import '../../css/JoinClass.less';

export default
@inject('classroomManager', 'userManager')
@observer
class JoinClassModal extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.ModalBanner = SatCoreComponent('ModalBanner');
  }

  getInitialState = () => {
    const { classroomManager } = this.props;
    const initJoinAccessCodeErrorObj = {
      error: false,
      errorMessage: '',
    };
    classroomManager.setJoinAccessCodeErrorObj(initJoinAccessCodeErrorObj);
    return {
      joinAccessCodeValue: '',
    };
  }

  closeJoinClassModal = ({ classroom } = {}) => {
    const { classroomManager, userManager } = this.props;
    const state = this.getInitialState();
    this.setState(state);
    classroomManager.setJoinAccessCodeErrorObj({
      error: false,
      errorMessage: ''
    });
    if (userManager.isStudent) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.closeJoinClassModal({ classroom });
    } else {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.closeJoinClassModal();
    }
  }

  submitJoinClass = async () => {
    const { classroomManager, userManager } = this.props;
    if (!this.validate()) {
      return;
    }
    // eslint-disable-next-line react/destructuring-assignment
    const response = await ClassroomService.joinClassroom(this.state.joinAccessCodeValue);
    if (response) {
      if (response.status === 'SUCCESS') {
        if (userManager.isStudent && response.data?.id) {
          const classroom = response.data;
          this.closeJoinClassModal({ classroom });
        } else {
          this.closeJoinClassModal();
        }
      } else {
        const joinAccessCodeErrorObj = {
          error: true,
          errorMessage: response.statusMessage ||
           'Something went wrong when joining the class, please try again.',
        };
        classroomManager.setJoinAccessCodeErrorObj(joinAccessCodeErrorObj);
      }
    }
  }

  handleChange = (_event, { value }) => {
    const { classroomManager } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    // if (this.state.hasOwnProperty(name)) {
    // eslint-disable-line no-prototype-builtins
    // this.setState((prevState) => {
    //   const newState = { ...prevState };
    //   newState[name].value = value;
    //   newState[name].error = false;
    //   return newState;
    // });
    this.setState({
      joinAccessCodeValue: value
    });
    classroomManager.setJoinAccessCodeErrorObj({
      error: false,
      errorMessage: ''
    });
  }

  validate = () => {
    const { classroomManager } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state.joinAccessCodeValue) {
      classroomManager.setJoinAccessCodeErrorObj({
        error: true,
        errorMessage: 'Access Code is Required',
      });
      return false;
    }
    return true;
  }

  render() {
    const { classroomManager, displayJoinClassModal, userManager, t } = this.props;
    const { ModalBanner } = this;
    return (
      <Modal
        aria-labelledby='join_class'
        aria-modal='true'
        autoFocus={true}
        className={userManager.isStudent ? 'join-class-modal student-client' : 'join-class-modal'}
        closeOnDimmerClick={false}
        closeOnEscape={true}
        onClose={this.closeJoinClassModal}
        open={displayJoinClassModal}
        role='dialog'
        size='tiny'
        tabIndex='0'>
        <ModalBanner label={t('joinTitle', 'Join Class')} onClose={this.closeJoinClassModal} titleContainer={{ id: 'join_class' }} />
        <Modal.Content>
          <Grid className='fullHeight' textAlign='center' verticalAlign='middle'>
            <Grid.Column>
              <Form>
                <Form.Field>
                  <Form.Input
                    label={t('accessCode', 'Access Code')}
                    name='accessCode'
                    onChange={this.handleChange}
                    placeholder={t('accessCode', 'Access Code')}
                    type='text'
                    // eslint-disable-next-line react/destructuring-assignment
                    value={this.state.joinAccessCodeValue} />
                  <Message
                    content={classroomManager.joinAccessCodeErrorObj.errorMessage}
                    error
                    visible={classroomManager.joinAccessCodeErrorObj.error} />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button basic className='cancelButton' onClick={this.closeJoinClassModal} primary>Cancel</Button>
          <Button className='ui primary button saveButton' onClick={this.submitJoinClass}>Join</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('JoinClassModal', JoinClassModal);
