import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import EngagementService from '../../../services/EngagementService';

// import { register } from '../../../i18n';

const EngagementRowMainAnnotationsUsageCells = observer((props) => {
  const { engagementData } = props;

  const { /* managers */ } = useContext(MobXProviderContext);

  // const t = register('EngagementTable');

  const renderEngagementRowMainAnnotationsUsageCells = () => {
    const { viewBookmarkCount, viewRawEliminatorCount, viewRawHatCount, viewRawStickyNotesCount } = engagementData;
    const annotationsCount = (
      (viewRawHatCount || 0) + // Highlight
      (viewRawEliminatorCount || 0) + // Strikethrough
      (viewRawStickyNotesCount || 0) + // Sticky Notes
      (viewBookmarkCount || 0) // Marked for Review
    );
    return (
      <>
        {renderEngagementRowMainAnnotationsUsageCell({
          allowPopupComponent: true,
          className: classNames('annotations-usage-cell main-cell annotationsUsage', {
            warn: false // TODO
          }),
          popupContent: '',
          popupDisabled: true,
          popupOn: 'hover',
          popupTrigger: annotationsCount
        })}
      </>
    );
  };

  const renderEngagementRowMainAnnotationsUsageCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementRowMainAnnotationsUsageCells();
});
export default EngagementRowMainAnnotationsUsageCells;

SatCoreRegister('EngagementRowMainAnnotationsUsageCells', EngagementRowMainAnnotationsUsageCells);
