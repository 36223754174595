/* eslint-disable sort-keys */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { capitalize } from 'lodash';

import '../../css/ResourceBankViewModal.less';

import {
  Button, Container, Header, Loader, Pagination
} from 'semantic-ui-react';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { DIALOG_NAMES } from '../../managers/DialogManager';
import { CONTENT_ITEM_TYPES } from '../../managers/ContentManager';

import Modal from '../Modal';

export default
@inject(
  'assessmentManager',
  'itemBankManager',
  'dialogManager',
  'tagContentManager',
  'libraryManager',
  'resourceBankShopManager'
)
@observer
class ResourceBankViewModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.ResourceFilter = SatCoreComponent('ResourceFilter');
    this.CourseLayout = SatCoreComponent('CourseLayout');
    this.LibraryResourceCard = SatCoreComponent('LibraryResourceCard');
  }

  getInitialState = () => ({
    customAssessmentName: '',
    customAssessmentSubtitle: '',
    serverError: false,
    serverErrorMsg: ''
  })

  closeThisModal = () => {
    const { closeResourceBankModal } = this.props;
    closeResourceBankModal();
  }

  handlePageChange = async (_event, pageInfo) => {
    const { resourceBankShopManager, libraryCardData } = this.props;
    const { searchStandards, searchType, searchTags, searchTerms } = resourceBankShopManager;
    const { activePage } = pageInfo;
    const { isRefresh } = this.state;

    await this.handleFetchResourceBankResources(activePage, libraryCardData.id, searchStandards, searchType, searchTags, searchTerms);
    this.setState({ isRefresh: !isRefresh });
  }

  handleFetchResourceBankResources= async (searchTerms = null, searchTags = null, searchStandards = [], searchTypes = null) => {
    const { resourceBankShopManager, libraryCardData } = this.props;
    const { isRefresh } = this.state;
    const activePage = 1;
    const productId = libraryCardData.id;

    // TODO call here for resources in the bank
    console.log(`in handleFetchResourceBankResources: activePage: ${activePage}, productId: ${productId}, searchStandards: ${searchStandards}, searchTypes: ${searchTypes}, searchTags: ${searchTags}, searchTerms: ${searchTerms}`);
    await resourceBankShopManager.searchResourceBankResources(activePage, 25, productId, searchStandards, searchTypes, searchTags, searchTerms);
    this.setState({ isRefresh: !isRefresh });
  }

  handleShowDetails = async (libraryCardData) => {
    const { dialogManager, libraryManager, t } = this.props;
    const thePopupElement = document.getElementsByClassName('resource-option-popup')[0];
    if (thePopupElement) {
      thePopupElement.style.setProperty('visibility', 'hidden', 'important');
    }
    const titlePrefix = capitalize(CONTENT_ITEM_TYPES.getFlag(libraryCardData.libraryResource.entityTypeId));
    const results = await libraryManager.getStandardTagAlignmentsByContentId(libraryCardData.id);
    dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_STANDARDS_TAGS_MODAL, {
      standards: results.standards,
      tags: results.tags,
      name: libraryCardData.title,
      description: libraryCardData.libraryResource.description,
      guid: libraryCardData.id,
      modalTitle: `${titlePrefix} ${t('detailsOptionLabel')}`,
      modalType: 'resource-view-details',
      type: libraryCardData.libraryResource.entitySubTypeId || (libraryCardData.typeDisplayName || libraryCardData.libraryResource.entityTypeId),
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_STANDARDS_TAGS_MODAL));
  }

  renderResourceBankCards = () => {
    const { previewLibraryResource, resourceBankShopManager } = this.props;
    const { LibraryResourceCard } = this;

    const libraryCards = [];
    if (resourceBankShopManager.productResources && resourceBankShopManager.productResources.length > 0) {
      resourceBankShopManager.productResources.map((data, index) => {
        const libraryCardKebabOptions = this.getLibraryCardOptions(data);
        libraryCards.push(
          <LibraryResourceCard key={index}
            libraryCardKebabOptions={libraryCardKebabOptions}
            libraryCardParams={data}
            onBodyClick={previewLibraryResource} />);
        return true;
      });
    }
    return (
      <div className='library-view-card-container'>
        {libraryCards}
      </div>
    );
  }

  getLibraryCardOptions = (data) => {
    const { previewLibraryResource, t } = this.props;
    const options = {};
    // define common options
    const preview = {
      key: 'preview',
      label: t('previewOptionLabel'),
      callback: previewLibraryResource
    };
    const details = {
      key: 'details',
      label: t('detailsOptionLabel'),
      callback: this.handleShowDetails
    };

    options.preview = preview;
    options.details = details;

    return options;
  }

  renderResourceBankModalContent = () => {
    return (
      <div className={classNames('resource-bank-search-modal-content-container')}>
        <div className='resource-bank-search-modal-content-left scrolling content'>
          {this.renderResourceFilter()}
        </div>
        <div className='resource-bank-search-modal-content-rest scrolling content'>
          <div className='resource-bank-search-modal-content-mid'>
            {/* placeholder */}
          </div>
          <div className='resource-bank-search-modal-content-right'>
            {this.renderResourceResults()}
          </div>
        </div>

      </div>
    );
  }

  renderResourceFilter = () => {
    // things needed for the item bank filter;
    const { tagContentManager, libraryCardData } = this.props;
    tagContentManager.fetchAuthorityStates();
    const productId = libraryCardData.id;
    const { ResourceFilter } = this;
    return (
      <div className='resource-bank-filter-wrapper'>
        <ResourceFilter
          handleFetchResourceBankResources={this.handleFetchResourceBankResources}
          multiSelect={true}
          productId={productId} />
      </div>
    );
  };

  renderResourceResults = () => {
    const { resourceBankShopManager, t } = this.props;
    const { activePage, resourcesLoading, totalPages, totalResults } = resourceBankShopManager;
    const { isRefresh } = this.state;
    const length = (resourceBankShopManager.productResources) ? resourceBankShopManager.productResources.length : 0;

    const startResultNumber = activePage <= 1 ? 1 : (activePage - 1) * 25 + 1;
    const endResultNumber = (activePage * 25) > totalResults ? totalResults : activePage * 25;
    const paginationJsx = (
      <div className='paginator-container'>
        <div className='pagination-text'>
          {`${startResultNumber}-${endResultNumber} of ${totalResults}`}
        </div><Pagination
          activePage={activePage}
          onPageChange={this.handlePageChange}
          totalPages={totalPages} />
      </div>
    );

    let headerContentJsx = <></>;
    if (length > 0) {
      headerContentJsx = paginationJsx;
    } else if (resourcesLoading) {
      headerContentJsx = (
        <Loader active className='modal-loader modal-loader-small-font' />
      );
    } else if (!resourcesLoading) {
      headerContentJsx = t('noResultsZeroStateMsg');
    }

    return (
      <Container className='resource-bank-list-container' fluid refresh-attr={isRefresh ? isRefresh.toString() : 'false'}>
        <div className={classNames('modal-content-header', {
          'resource-bank-list-header-sticky': !resourcesLoading
        })}>
          <div className='modal-content-header-pagination'>
            {headerContentJsx}
          </div>
        </div>
        {this.renderResourceBankCards()}
      </Container>
    );
  }

  render() {
    const { libraryCardData } = this.props;

    const { displayAssessmentShopModal, t } = this.props;
    return (
      <>
        <Modal
          className='ResourceBankViewModal'
          closeOnDimmerClick={false}
          closeOnEscape={false}
          onClose={this.closeThisModal}
          open={displayAssessmentShopModal}
          size='fullscreen'
          style={{ overflow: 'hidden', top: '-14px' }}>
          <>
            <Modal.Header className='modal-header'>
              <Header.Content className='modal-header-bar'>
                <span className='modal-header-label'>
                  {libraryCardData.title}
                </span>
                <div className='modal-header-buttons'>
                  <Button
                    basic
                    className='cancelButton'
                    onClick={this.closeThisModal}
                    primary>
                    {t('Exit')}
                  </Button>
                </div>
              </Header.Content>
            </Modal.Header>
            <div className='nav-separator' />
            {this.renderResourceBankModalContent()}
          </>
        </Modal>
      </>
    );
  }
}

SatCoreRegister('ResourceBankViewModal', ResourceBankViewModal);
