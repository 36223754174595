import { capitalize } from 'lodash';

import ReactHtmlParser from 'react-html-parser';

import { REPORT_FACULTY_TYPE, REPORT_TABLE_TYPE, REPORT_TYPE, REPORT_URL_KEY } from './ReportConstants';

import { getRegisteredClass, registerClass } from '../../SatCoreRegistry';

import classroomManager from '../../managers/ClassroomManager';
import groupsManager from '../../managers/GroupsManager';
import navigationManager, { VIEW_SELECTION } from '../../managers/NavigationManager';
import reportContextManager from '../../managers/reports/ReportContextManager';
import reportCourseManager from '../../managers/reports/ReportCourseManager';
import reportIdentityManager from '../../managers/reports/ReportIdentityManager';
import reportOverrideManager from '../../managers/reports/ReportOverrideManager';
import reportScoreManager from '../../managers/reports/ReportScoreManager';
import reportStandardsClassroomDetailStudentsManager from '../../managers/reports/ReportStandardsClassroomDetailStudentsManager';
import reportStandardsManager from '../../managers/reports/ReportStandardsManager';
import reportTableManager from '../../managers/reports/ReportTableManager';
import reportTypeManager from '../../managers/reports/ReportTypeManager';
import reportUsageManager from '../../managers/reports/ReportUsageManager';
import userManager from '../../managers/UserManager';

import { generateUrl, getSessionStorageItem, setStyleVar } from '../../utils';

import ReportOverrideService from './ReportOverrideService';
import UtilityService from '../UtilityService';

export default class ReportIdentityService {
  static manager = () => reportIdentityManager;

  static clearAllReportManagers = () => {
    reportContextManager.clearAllExceptSession();
    reportCourseManager.clearAllExceptSession();
    reportIdentityManager.clearAll();
    reportOverrideManager.clearAll();
    reportScoreManager.clearAll();
    reportStandardsClassroomDetailStudentsManager.clearAll();
    reportStandardsManager.clearAllExceptSession();
    reportTableManager.clearAll();
    reportTypeManager.clearAllExceptSession();
    reportUsageManager.clearAll();
    groupsManager.clearAll();
    // reset special report style offsets
    setStyleVar('--report-progress-numbers-toggler-margin-left-offset', '0px');
  }

  static clearAllReportSessionStorage = () => {
    reportContextManager.clearSession();
    reportCourseManager.clearSession();
    reportStandardsManager.clearSession();
    reportTypeManager.clearSession();
  }

  static isTypeReport = (reportType = null) => {
    if (reportType) {
      reportType = capitalize(reportType);
      return reportIdentityManager[`is${reportType}Report`];
    } else {
      const { isCourseReport, isStandardsReport } = reportIdentityManager;
      return isCourseReport || isStandardsReport;
    }
  }

  static isFacultyTypeReport = (reportFacultyType = null) => {
    if (reportFacultyType) {
      reportFacultyType = capitalize(reportFacultyType);
      return reportIdentityManager[`isFaculty${reportFacultyType}Report`];
    } else {
      const { isFacultyAdminReport, isFacultyClassroomReport } = reportIdentityManager;
      return isFacultyAdminReport || isFacultyClassroomReport;
    }
  }

  static isTableTypeReport = (reportTableType = null) => {
    if (reportTableType) {
      reportTableType = capitalize(reportTableType);
      return reportIdentityManager[`isTable${reportTableType}Report`];
    } else {
      const {
        isTableSummaryReport, isTableDetailReport, isTableIndividualReport
      } = reportIdentityManager;
      return isTableSummaryReport || isTableDetailReport || isTableIndividualReport;
    }
  }

  static getReportLeadTeacherName = (classroom = undefined) => {
    if (classroom) {
      return `${classroom.leadTeacherFirstName || ''} ${classroom.leadTeacherLastName || ''}`.trim();
    } else {
      const ReportJsonHelperService = getRegisteredClass('ReportJsonHelperService');
      let leadTeacherName = ReportJsonHelperService.REPORT_CONTENT_JSON_DATA()?.leadTeacherName || '';

      const fromAdminClassroomsView = this.userCameFromAdminClassroomsView();

      if (!leadTeacherName && fromAdminClassroomsView) {
        leadTeacherName = getSessionStorageItem('adminClassroomReportLeadTeacherName') || '';
        return leadTeacherName;
      }
      return leadTeacherName;
    }
  }

  static getReportStudentName = (student = '') => {
    return `${student.firstName || ''} ${student.lastName || ''}`.trim();
  }

  static getZeroStateReportFacultyName = () => {
    const ClassroomService = getRegisteredClass('ClassroomService');
    const UserService = getRegisteredClass('UserService');

    const { reportFacultyType } = this.parseReportTypesFromBaseUrl();

    const currentInstitutionNameForLoggedInUser = UserService.getCurrentInstitutionForLoggedInUser()?.name;

    let reportFacultyTypeName = 'Data not found';
    if (reportFacultyType === REPORT_FACULTY_TYPE.DISTRICT) {
      reportFacultyTypeName = currentInstitutionNameForLoggedInUser;
    } else if (reportFacultyType === REPORT_FACULTY_TYPE.SCHOOL) {
      const urlParams = new URLSearchParams(window.location.search);
      reportFacultyTypeName = urlParams.get('institutionName') || currentInstitutionNameForLoggedInUser;
    } else if (reportFacultyType === REPORT_FACULTY_TYPE.CLASSROOM) {
      reportFacultyTypeName = classroomManager.getClassroom(ClassroomService.getCurrentClassroomId())?.name;
    }
    return reportFacultyTypeName;
  }

  static getReportIdFromParsedReportTypes = () => {
    const { reportType, reportFacultyType } = this.parseReportTypesFromBaseUrl();
    return this.getReportId(reportType, reportFacultyType);
  }

  static getReportId = (reportType = '', reportFacultyType = '', {
    isCourseFilterReportId = false,
    isStandardsFilterReportId = false
  } = {}) => {
    if (isCourseFilterReportId) {
      return reportIdentityManager.REPORT_IDS.course.filters[reportType][reportFacultyType];
    } else if (isStandardsFilterReportId) {
      return reportIdentityManager.REPORT_IDS.standards.filters[reportType][reportFacultyType];
    } else if (reportType && reportFacultyType) {
      return reportIdentityManager.REPORT_IDS[reportType][reportFacultyType];
    }
  }

  /**
   * @param {string} reportType
   * @param {string} reportFacultyType
   * @param {string} reportTableType
   * @param {{
   *  byStudents?: boolean;
   *  classroomId?: string;
   *  classroomStatus?: 'active' | 'archived';
   *  courseId?: string;
   *  institutionId?: string;
   *  institutionName?: string;
   *  reportId: string;
   * }} additionalParams
   * @returns `reportUrl`
   */
  static getReportUrl = (
    reportType = '', reportFacultyType = '', reportTableType = '', additionalParams = {}) => {
    reportType = reportType || reportIdentityManager.activeReportType;
    reportFacultyType = reportFacultyType || reportIdentityManager.activeReportFacultyType;
    reportTableType = reportTableType || reportIdentityManager.activeReportTableType;

    if (!reportType || !reportFacultyType || !reportTableType) {
      return `${REPORT_URL_KEY['/reports']}?view=${VIEW_SELECTION.REPORTS}`;
    }

    const nonNullAdditionalParams = {};
    for (const prop in additionalParams) {
      const param = additionalParams[prop];
      if (typeof param === 'string' || typeof param === 'boolean') {
        nonNullAdditionalParams[prop] = param;
      }
    }

    if (reportType && reportFacultyType && reportTableType) {
      let reportUrlPrefix;
      const isReportStandardsClassroomDetailStudents = this.isReportStandardsClassroomDetailStudents();
      if (nonNullAdditionalParams['byStudents'] && !isReportStandardsClassroomDetailStudents) {
        reportUrlPrefix = `${REPORT_URL_KEY['/reports/standards-classroom-detail-students']}`;
      } else {
        reportUrlPrefix = `/reports/${reportType}-${reportFacultyType}-${reportTableType}`;
      }
      const reportUrl = generateUrl(reportUrlPrefix, {
        ...nonNullAdditionalParams,
        reportFacultyType,
        reportTableType,
        reportType,
        view: VIEW_SELECTION.REPORTS
      });
      return reportUrl;
    }
  }

  /**
   * Parse and return `reportType`, `reportFacultyType` and `reportTableType` as an object
   * from a given `reportBaseUrl` in the following format:
   *
   * ```
   * reportBaseUrl = `/reports/${reportType}-${reportFacultyType}-${reportTableType}`
   * ```
   */
  static parseReportTypesFromBaseUrl = (reportBaseUrl = '') => {
    reportBaseUrl = reportBaseUrl || window.location.pathname;
    if (reportBaseUrl === REPORT_URL_KEY['/reports/standards-classroom-detail-students']) {
      return {
        reportFacultyType: REPORT_FACULTY_TYPE.CLASSROOM,
        reportTableType: REPORT_TABLE_TYPE.DETAIL,
        reportType: REPORT_TYPE.STANDARDS
      };
    }
    if (reportBaseUrl?.includes?.('reports/')) {
      const str = reportBaseUrl;
      const lastSlashIndex = str.lastIndexOf('/');
      const firstDashIndex = str.indexOf('-');
      const lastDashIndex = str.lastIndexOf('-');
      const queryIndex = str.includes('?') ? str.indexOf('?') : str.length;

      const reportType = str.substring(lastSlashIndex + 1, firstDashIndex);
      const reportFacultyType = str.substring(firstDashIndex + 1, lastDashIndex);
      const reportTableType = str.substring(lastDashIndex + 1, queryIndex);

      return {
        reportFacultyType,
        reportTableType,
        reportType
      };
    } else if (reportBaseUrl?.includes?.('gradebook')) {
      const urlParams = new URLSearchParams(window.location.search);
      return {
        reportFacultyType: urlParams.get('reportFacultyType'),
        reportTableType: urlParams.get('reportTableType'),
        reportType: urlParams.get('reportType')
      };
    } else if (reportBaseUrl?.includes?.('/lti-courses')) {
      const types = {
        reportFacultyType: REPORT_FACULTY_TYPE.CLASSROOM,
        reportTableType: REPORT_TABLE_TYPE.SUMMARY,
        reportType: REPORT_TYPE.STANDARDS
      };
      return types;
    } else {
      return {
        reportFacultyType: null,
        reportTableType: null,
        reportType: null
      };
    }
  }

  static isReportStandardsClassroomDetailStudents() {
    const reportUrlKey = window.location.pathname;
    return reportUrlKey === REPORT_URL_KEY['/reports/standards-classroom-detail-students'];
  }

  static isReportStandardsClassroomDetail() {
    const reportUrlKey = window.location.pathname;
    return reportUrlKey === REPORT_URL_KEY['/reports/standards-classroom-detail'];
  }

  static isReportCourseClassroomDetail() {
    const reportUrlKey = window.location.pathname;
    return reportUrlKey === REPORT_URL_KEY['/reports/course-classroom-detail'];
  }

  static isCustomStandardsFilterReport(proposedReportId = null) {
    proposedReportId = proposedReportId || reportIdentityManager.activeReportId;
    const filterKeys = Object.keys(reportIdentityManager.REPORT_IDS.standards.filters);
    const filterReportIds = filterKeys.map((filterKey) => {
      return [...Object.values(reportIdentityManager.REPORT_IDS.standards.filters[filterKey])];
    }).flat();
    return !!filterReportIds.find((reportId) => reportId === proposedReportId);
  }

  static userCameFromAdminClassroomsView = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const fromAdminClassroomsView = urlParams.get('fromAdminClassroomsView') === 'true';
    return fromAdminClassroomsView;
  }

  static userCameFromSchoolCard = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const institutionName = urlParams.get('institutionName');
    const fromSchoolCard = !!institutionName;
    return fromSchoolCard;
  }

  /**
   * 'district' → 'school' → 'classroom'
   */
  static getNextReportFacultyType = () => {
    const { activeReportFacultyType } = reportIdentityManager;
    const facultyTypes = this.getOrderedReportFacultyTypes();
    if (!activeReportFacultyType) {
      return facultyTypes[0];
    }
    const currentIndex = facultyTypes.findIndex((facultyType) => facultyType === activeReportFacultyType);
    const nextFacultyType = facultyTypes[currentIndex + 1];
    return nextFacultyType;
  }

  /**
   * 'summary' → 'detail' → 'individual'
   */
  static getNextReportTableType = () => {
    const { activeReportTableType } = reportIdentityManager;
    const tableTypes = this.getOrderedReportTableTypes();
    if (!activeReportTableType) {
      return tableTypes[0];
    }
    const currentIndex = tableTypes.findIndex((tableType) => tableType === activeReportTableType);
    const nextTableType = tableTypes[currentIndex + 1];
    return nextTableType;
  }

  static getOrderedReportFacultyTypes = () => {
    const orderedFacultyTypes = [
      REPORT_FACULTY_TYPE.DISTRICT,
      REPORT_FACULTY_TYPE.SCHOOL,
      REPORT_FACULTY_TYPE.CLASSROOM
    ];
    return orderedFacultyTypes;
  }

  static getOrderedReportTableTypes = (reportFacultyType) => {
    reportFacultyType = reportFacultyType || reportIdentityManager.activeReportFacultyType;

    const orderedTableTypes = [
      REPORT_TABLE_TYPE.SUMMARY,
      REPORT_TABLE_TYPE.DETAIL
    ];
    if (reportFacultyType === REPORT_FACULTY_TYPE.CLASSROOM) {
      orderedTableTypes.push(REPORT_TABLE_TYPE.INDIVIDUAL);
    }
    return orderedTableTypes;
  }

  static getReportExportParams = () => {
    const {
      activeReportId,
      activeReportInstitutionId,
      activeReportFacultyType,
      activeReportType,
      isFacultyAdminReport,
      isFacultyClassroomReport,
      isFacultyDistrictReport,
      isFacultySchoolReport
    } = reportIdentityManager;

    const { computedSelectedReportCmapObj, selectedReportCmapContentItemId } = reportStandardsManager;

    const shouldUseCmapId = ReportOverrideService.shouldUseCmapId();

    const urlParams = new URLSearchParams(window.location.search);
    const exportReportId = this.getReportExportId(activeReportType, activeReportFacultyType);

    const courseContentItemId = reportCourseManager.selectedReportCourseContentItemId;

    let curriculumMapId = null;
    if (shouldUseCmapId && isFacultyAdminReport) {
      curriculumMapId = selectedReportCmapContentItemId || null;
    } else if (shouldUseCmapId) {
      curriculumMapId = computedSelectedReportCmapObj?.id || null;
    }
    return {
      baseReportId: activeReportId,
      classroomId: isFacultyClassroomReport ? urlParams.get('classroomId') : null,
      courseContentItemId: (isFacultyAdminReport && !!courseContentItemId) ? courseContentItemId : null,
      curriculumMapId,
      districtId: isFacultyDistrictReport ? activeReportInstitutionId : null,
      reportId: exportReportId,
      schoolId: isFacultySchoolReport ? activeReportInstitutionId : null
    };
  }

  static getReportExportId = (reportType = '', reportFacultyType = '') => {
    if (reportType && reportFacultyType) {
      if (reportType && reportFacultyType) {
        return reportIdentityManager.REPORT_EXPORT_IDS[reportType][reportFacultyType];
      }
    }
  }

  static getAdminClassroomReportTypeSelectorStartUrl = async ({
    classroomId,
    classroomStatus,
    selectedReportUrlKeySuffix = 'course-classroom-summary'
  } = {}) => {
    const reportBaseUrl = `/reports/${selectedReportUrlKeySuffix}`;

    const {
      reportFacultyType,
      reportTableType,
      reportType
    } = this.parseReportTypesFromBaseUrl(reportBaseUrl);

    const reportId = this.getReportId(reportType, reportFacultyType);

    const { districtId, institutionId, institutionName } = await navigationManager.getAdminUrlParams();

    const reportStartUrl = this.getReportUrl(reportType, reportFacultyType, reportTableType, {
      classroomId,
      classroomStatus,
      districtId,
      fromAdminClassroomsView: true,
      institutionId,
      institutionName,
      reportId
    });
    return reportStartUrl;
  }

  // TODO refactor
  static constructContentString = (contentType, name, description) => {
    const parts = [name, description];
    if (userManager.clientPrefix === 'GALL') {
      // prepend content type for Gallopade
      parts.unshift(contentType);
    }
    const transform = UtilityService.reactHtmlParserTransform;
    const contentParts = [];
    for (const part of parts) {
      const cleanPart = ReactHtmlParser(UtilityService.stripHtmlTags(part), { transform });
      if (cleanPart && cleanPart.length) {
        contentParts.push(cleanPart);
      }
    }
    return contentParts.join(' - ');
  }

  // TODO refactor
  static constructContentStringWithUnit = (element) => {
    const name = this.constructContentString(element.contentType, element.elementName, element.elementDescription);
    if (element.level > 1 || element.unitHidden) {
      return name || (element.level > 1 ? 'Section' : null);
    }
    return `${element.unitName || element.courseUnitName || 'Unit'} ${element.orderNum + 1}: ${name}`;
  }
}

registerClass('ReportIdentityService', ReportIdentityService);
