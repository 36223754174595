import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Form,
  Grid,
  Header,
  Input,
  Message,
  Segment
} from 'semantic-ui-react';

import '../css/Login.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

import DynamicSatelliteService from '../services/DynamicSatelliteService';

export default @inject(
  'dynamicSatelliteManager', 'userManager')
@observer
class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPasswordInputType: 'password',
      fail: false,
      invalid: false,
      loaded: false,
      newPassword: '',
      newPasswordInputType: 'password',
      password: '',
      passwordChangeId: '',
      success: false,
      tooShort: false
    };
    this.Logo = SatCoreComponent('Logo');
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');
    this.ShowPasswordButton = SatCoreComponent('ShowPasswordButton');
  }

  async componentDidMount() {
    this.setState({ loaded: false });

    const { dynamicSatelliteManager, location } = this.props;

    const params = location.pathname;
    const passwordChangeId = params.substring(16);
    this.setState({ passwordChangeId });

    if (dynamicSatelliteManager.isDynamicSatellite && !Auth.publisherSatelliteCode) {
      await DynamicSatelliteService.fetchDynamicSatelliteByDomain();
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 500);
    } else {
      this.setState({ loaded: true });
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState(
      {
        [e.target.name]: e.target.value
      }
    );
  };

  handleChangePassword = async (event) => {
    const { userManager } = this.props;
    event.preventDefault();
    this.setState({ tooShort: false, fail: false, success: false, invalid: false });

    if (this.state.newPassword !== this.state.password) {
      this.setState({ invalid: true });
      return;
    }

    if (this.state.newPassword.length < 8) {
      this.setState({ tooShort: true });
      return;
    }

    const response = userManager.resetPassword(this.state.passwordChangeId, this.state.password);
    if (response) {
      this.setState({ success: true });
    } else {
      this.setState({ fail: true });
    }
  };

  handleGoLogin = () => {
    this.props.history.push('/signin');
  };

  handleShowPasswordPressed = (name, isPasswordInput) => {
    const inputType = isPasswordInput ? 'password' : 'text';
    this.setState({ [name]: inputType });
  };

  render() {
    const { Logo, SatCoreLoader, ShowPasswordButton } = this;
    const { loaded } = this.state;

    return loaded ? (
      <Grid className='login-grid new-password' textAlign='center' verticalAlign='middle'>
        <Grid.Column>
          <Form>
            <Header as='h2' attached='top' block>
              <Logo />
              <span className='header-text'>Change Your Password</span>
            </Header>
            <div className='login-divider' />
            <Segment attached className='element-body-login'>
              <Form.Field>
                <label>New Password</label>
                <Input
                  icon={(
                    <ShowPasswordButton
                      isPassword={(this.state.newPasswordInputType === 'password')}
                      name='newPasswordInputType'
                      onFlip={this.handleShowPasswordPressed} />
                  )}
                  name='newPassword'
                  onChange={this.handleChange}
                  placeholder='New Password'
                  type={this.state.newPasswordInputType} />
              </Form.Field>
              <Form.Field>
                <label>Confirm Password</label>
                <Input
                  icon={(
                    <ShowPasswordButton
                      isPassword={(this.state.confirmPasswordInputType === 'password')}
                      name='confirmPasswordInputType'
                      onFlip={this.handleShowPasswordPressed} />
                  )}
                  name='password'
                  onChange={this.handleChange}
                  placeholder='Password'
                  type={this.state.confirmPasswordInputType} />
              </Form.Field>
              <Form.Field>
                <Button className='change-password' onClick={this.handleChangePassword} primary>
                  Update Password
                </Button>
              </Form.Field>
            </Segment>
          </Form>
          {(this.state.success)
            ? (
              <Message positive>
                <Message.Header>Success</Message.Header>
                <p>
                  Your password has been updated.
                </p>
                <Button className='goto-login' color='green' onClick={this.handleGoLogin}>
                  Go to Login
                </Button>
              </Message>
            ) : null}
          {(this.state.fail)
            ? (
              <Message negative>
                <Message.Header>Something Went Wrong</Message.Header>
                <p>
                  Resetting your password has failed. Please check your email and try again.
                </p>
              </Message>
            ) : null}
          {(this.state.invalid)
            ? (
              <Message negative>
                <Message.Header>Your Passwords Do Not Match</Message.Header>
                <p>
                  The passwords do not match. Please try again.
                </p>
              </Message>
            ) : null}
          {(this.state.tooShort) ? (
            <Message negative>
              <Message.Header>Your Password is Too Short</Message.Header>
              <p>
                Password must be at least 8 characters and cannot be all spaces.
              </p>
            </Message>
          ) : null}
        </Grid.Column>
      </Grid>
    ) : <SatCoreLoader />;
  }
}

SatCoreRegister('NewPassword', NewPassword);
