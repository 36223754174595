import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../css/reports/ReportHeader.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import ReportIdentityService from '../../services/reports/ReportIdentityService';
import ReportOverrideService from '../../services/reports/ReportOverrideService';
import ReportMoreFiltersService from '../../services/reports/ReportMoreFiltersService';

const ReportHeader = observer((props) => {
  const {
    reportContextManager,
    reportIdentityManager,
    reportOverrideManager,
    reportStandardsClassroomDetailStudentsManager,
    reportUsageManager,
    userManager
  } = useContext(MobXProviderContext);

  const {
    isCourseReport, isFacultyAdminReport,
    isStandardsReport, reportInfoClassNames
  } = reportIdentityManager;

  const ReportAttributeFiltersDropdown = SatCoreComponent('ReportAttributeFiltersDropdown');
  const ReportCaption = SatCoreComponent('ReportCaption');
  const ReportColorKeyCard = SatCoreComponent('ReportColorKeyCard');
  const ReportContentWithoutScoresToggler = SatCoreComponent('ReportContentWithoutScoresToggler');
  const ReportContextSwitcher = SatCoreComponent('ReportContextSwitcher');
  const ReportCourseDropdown = SatCoreComponent('ReportCourseDropdown');
  const ReportDownloadButtons = SatCoreComponent('ReportDownloadButtons');
  const ReportEngagementFilter = SatCoreComponent('ReportEngagementFilter');
  const ReportEnhancedUsageColorKeyCard = SatCoreComponent('ReportEnhancedUsageColorKeyCard');
  const ReportGroupFilter = SatCoreComponent('ReportGroupFilter');
  const ReportHideColumnsLauncherButton = SatCoreComponent('ReportHideColumnsLauncherButton');
  const ReportLessonFilterDropdown = SatCoreComponent('ReportLessonFilterDropdown');
  const ReportMoreFilters = SatCoreComponent('ReportMoreFilters');
  const ReportProgressFilter = SatCoreComponent('ReportProgressFilter');
  const ReportStandardCard = SatCoreComponent('ReportStandardCard');
  const ReportStandardsDropdown = SatCoreComponent('ReportStandardsDropdown');

  const renderReportHeader = () => {
    return (
      <div className={`report-header ${reportInfoClassNames}`}>
        <div className={`report-header-left ${reportInfoClassNames}`}>
          {renderReportCaption()}
          {renderReportStandardCard()}
          {renderReportContextSwitcher()}
          {renderReportDropdowns()}
          {renderContentWithoutScoresToggler()}
        </div>
        <div className={`report-header-mid ${reportInfoClassNames}`}>
          {/* placeholder */}
        </div>
        <div className={`report-header-right ${reportInfoClassNames}`}>
          <div className={`report-header-right-top ${reportInfoClassNames}`}>
            {renderReportHideColumnsLauncherButton()}
            {renderReportDownloadButtons()}
          </div>
          <div className={`report-header-right-mid ${reportInfoClassNames}`}>
            {/* placeholder */}
          </div>
          <div className={`report-header-right-bottom ${reportInfoClassNames}`}>
            {renderReportColorKeyCard()}
          </div>
        </div>
      </div>
    );
  };

  const renderReportCaption = () => {
    return <ReportCaption {...props} />;
  };

  const renderReportContextSwitcher = () => {
    return <ReportContextSwitcher {...props} />;
  };

  const renderReportDropdowns = () => {
    const isReportStandardsClassroomDetailStudents = ReportIdentityService.isReportStandardsClassroomDetailStudents();
    const shouldRenderReportDropdowns = !isReportStandardsClassroomDetailStudents;
    return shouldRenderReportDropdowns && (
      <div className={`report-dropdowns ${reportInfoClassNames}`}>
        <div className={`report-whitelabel-dropdowns ${reportInfoClassNames}`}>
          {renderReportCourseDropdown()}
          {renderReportLessonFilterDropdown()}
          {renderReportGroupFilter()}
        </div>
        <div className={`report-cmap-dropdowns ${reportInfoClassNames}`}>
          {renderReportStandardsDropdown()}
          {renderReportAttributeFiltersDropdown()}
          {renderReportMoreFiltersDropdown()}
        </div>
      </div>
    );
  };

  const renderReportLessonFilterDropdown = () => {
    const { allowLessonFilterDropdown } = reportOverrideManager;
    const isReportStandardsClassroomDetailStudents = ReportIdentityService.isReportStandardsClassroomDetailStudents();
    const shouldRenderLessonFilterDropdown = allowLessonFilterDropdown && !isReportStandardsClassroomDetailStudents;
    return shouldRenderLessonFilterDropdown && (
      <ReportLessonFilterDropdown {...props} />
    );
  };

  const renderReportGroupFilter = () => {
    // only used on the student detail reports - excluded from Sirius standards because
    // it's contained in the more filters box in that case
    const isStandardsDetail = ReportIdentityService.isReportStandardsClassroomDetail();
    const isCourseDetail = ReportIdentityService.isReportCourseClassroomDetail();
    const isSirius = userManager.clientPrefix === 'SIRIUS';
    const showGroupFilter = isCourseDetail || (isStandardsDetail && !isSirius);
    return showGroupFilter && (
      <ReportGroupFilter {...props} updateOnClose={true} />
    );
  };

  const renderReportCourseDropdown = () => {
    const shouldUseCmapId = ReportOverrideService.shouldUseCmapId();
    return isFacultyAdminReport && !shouldUseCmapId && (
      <ReportCourseDropdown {...props} />
    );
  };

  const renderReportStandardsDropdown = () => {
    const shouldUseCmapId = ReportOverrideService.shouldUseCmapId();
    return isStandardsReport && shouldUseCmapId && (
      <ReportStandardsDropdown {...props} />
    );
  };

  const renderReportAttributeFiltersDropdown = () => {
    const shouldUseCmapId = ReportOverrideService.shouldUseCmapId();
    return isStandardsReport && shouldUseCmapId && (
      <ReportAttributeFiltersDropdown {...props} />
    );
  };

  const renderReportMoreFiltersDropdown = () => {
    // only used by Sirius - contains standards content type and assignment type filters user group filter
    const showMoreFilters = ReportMoreFiltersService.showMoreFilters();
    return showMoreFilters && (
      <ReportMoreFilters {...props} />
    );
  };

  const renderReportStandardCard = () => {
    const isReportStandardsClassroomDetailStudents = ReportIdentityService.isReportStandardsClassroomDetailStudents();
    return isReportStandardsClassroomDetailStudents && (
      <>
        <ReportStandardCard
          {...props}
          standard={reportStandardsClassroomDetailStudentsManager.currentStandard}
          style={{ marginBottom: '12px', padding: '10px' }} />
        <div className='report-standard-card-spacer'>
          {/* placeholder */}
        </div>
      </>
    );
  };

  const renderContentWithoutScoresToggler = () => {
    return isCourseReport && (
      <ReportContentWithoutScoresToggler {...props} />
    );
  };

  const renderReportHideColumnsLauncherButton = () => {
    return <ReportHideColumnsLauncherButton {...props} />;
  };

  const renderReportDownloadButtons = () => {
    return <ReportDownloadButtons {...props} />;
  };

  const renderReportColorKeyCard = () => {
    const { isContextUsageReport, isContextProgressReport, isContextEngagementReport } = reportContextManager;
    const { useEnhancedUsageReports } = reportUsageManager;
    const isReportStandardsClassroomDetailStudents = ReportIdentityService.isReportStandardsClassroomDetailStudents();

    if (useEnhancedUsageReports) {
      return <ReportEnhancedUsageColorKeyCard {...props} />;
    } else if (isReportStandardsClassroomDetailStudents) {
      return <ReportColorKeyCard {...props} />;
    } else if (isContextProgressReport) {
      return <ReportProgressFilter />;
    } else if (isContextEngagementReport) {
      return <ReportEngagementFilter />;
    } else if (!isContextUsageReport) {
      return <ReportColorKeyCard {...props} />;
    } else {
      return null;
    }
  };
  return renderReportHeader();
});
export default ReportHeader;

SatCoreRegister('ReportHeader', ReportHeader);
