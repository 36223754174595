import React, { useEffect, useState } from 'react';

import { Button, Icon } from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/StandardsDocumentsModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

const ModalBanner = SatCoreComponent('ModalBanner');

const Standard = (props) => {
  const { standard } = props;
  const { id, name, description } = standard;

  return (
    <div key={id} className='standard'>
      <span className='name'>{name}</span>
      <span className='description'>{description}</span>
    </div>
  );
};

const Document = (props) => {
  const { document, isOpen, toggleDocState } = props;
  const { id, name, standards } = document;

  return (
    <React.Fragment key={id}>
      <div className='document' data-value={id} onClick={toggleDocState}>
        <Icon name={(isOpen) ? 'caret down' : 'caret right'} />
        <span>{name}</span>
      </div>
      {isOpen && (
        <div className='standards'>
          {standards.map((standard) => {
            return (
              <Standard key={standard.id} standard={standard} />
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

const StandardsDocumentsModal = (props) => {
  const { addInfoOpen, closeInfoModal, lessonName, standards, } = props;
  const { documents } = standards;
  const hasStandards = documents && documents.length > 0;

  const [openDocs, setOpenDocs] = useState({});

  const toggleDocState = (event) => {
    const id = event.target.dataset.value || event.target.parentNode.dataset.value;
    setOpenDocs((prevState) => {
      return { ...prevState, [id]: !prevState[id] };
    });
  };

  useEffect(() => {
    const docs = {};
    if (hasStandards) {
      documents.forEach((document, i) => {
        // initially set just the first document as open
        docs[document.id] = i === 0;
      });
      setOpenDocs(docs);
    }
  }, []);

  return (
    <Modal
      className='standards-documents-modal'
      closeOnDimmerClick={true}
      closeOnEscape={true}
      onClose={closeInfoModal}
      open={addInfoOpen}>
      <ModalBanner
        onClose={() => { closeInfoModal(); }}
        title={lessonName} />
      <div className='standards-label'>Standards</div>
      <div className='standards-underline' />
      <Modal.Content>
        {hasStandards && (
          <div className='container'>
            {documents.map((document) => {
              return (
                <Document
                  key={document.id}
                  data-id={document.id}
                  document={document}
                  isOpen={openDocs[document.id]}
                  toggleDocState={toggleDocState} />
              );
            })}
          </div>
        )}
        {!hasStandards && <div className='container'><div className='document'>No standards</div></div>}
      </Modal.Content>
      <Modal.Actions>
        <Button basic className='modal-close-button' onClick={closeInfoModal} primary type='button'>CLOSE</Button>
      </Modal.Actions>
    </Modal>
  );
};
export default StandardsDocumentsModal;
SatCoreRegister('StandardsDocumentsModal', StandardsDocumentsModal);
