/* sessionStorage wrapper functions; useful for error handling and proper type casting */
export const getSessionStorageItem = (key) => {
  try {
    const value = sessionStorage.getItem(key);
    if (!value || value === 'null' || value === 'undefined' || value === 'false') {
      removeSessionStorageItem(key);
      return undefined;
    } else if (value === 'true') {
      return true;
    } else {
      return value;
    }
  } catch (error) {
    console.error(error);
  }
};

export const setSessionStorageItem = (key, value) => {
  try {
    if (!value || value === 'null' || value === 'undefined' || value === 'false') {
      removeSessionStorageItem(key);
    } else {
      return sessionStorage.setItem(key, value);
    }
  } catch (error) {
    console.error(error);
  }
};

export const removeSessionStorageItem = (key) => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
};

export const clearSessionStorage = () => {
  try {
    return sessionStorage.clear();
  } catch (error) {
    console.error(error);
  }
};
