import { getPercentile } from '../utils/number';

import PopupService from './PopupService';

export default class UsageService {
  /**
   * Converts `viewedTimeInSeconds` from seconds to minutes,
   * then rounds it and returns it as a string.
   *
   * If `lettersToAppendWhenNotZero` is not empty, it will be appended to the returned string.
   * Applicable to label only (not on hover) when final result of number in the label is greater than `0`.
   *
   * If number in label is exactly `0`, `lettersToAppendWhenZero` will be appended instead.
   *
   * If `showUnroundedOnHover` is true, actual (unrounded) time will be displayed as `mm:ss` when hovering over the label.
   * Default: `true`
   *
   * e.g.
   * ```
   * getUsageLabel({ viewedTimeInSeconds: 60, lettersToAppendWhenNotZero: 'm', showUnroundedOnHover: true }); // '1m', '1:00' on hover
   * getUsageLabel({ viewedTimeInSeconds: 60, lettersToAppendWhenNotZero: 'm', showUnroundedOnHover: false }); // '1m'
   * getUsageLabel({ viewedTimeInSeconds: 0, lettersToAppendWhenNotZero: 'm' }); // '0m', '0:00' on hover
   * getUsageLabel({ viewedTimeInSeconds: 0 }); // 0
   * getUsageLabel({ viewedTimeInSeconds: 0, lettersToAppendWhenZero, lettersToAppendWhenNotZero: 'm' }); // '0s', '0:00' on hover
   * getUsageLabel({ viewedTimeInSeconds: 29, lettersToAppendWhenNotZero: 'm', lettersToAppendWhenZero: 's' }); // '<1m', '0:29' on hover
   * getUsageLabel({ viewedTimeInSeconds: 30, lettersToAppendWhenNotZero: 'm', showUnroundedOnHover: false }); // '1m'
   * getUsageLabel({ viewedTimeInSeconds: 59, lettersToAppendWhenNotZero: 'm', showUnroundedOnHover: false }); // '1m'
   * getUsageLabel({ viewedTimeInSeconds: 90, lettersToAppendWhenNotZero: 'm' }); // '2m', '1:30' on hover
   * getUsageLabel({ viewedTimeInSeconds: 89, lettersToAppendWhenNotZero: 'm' }); // '1m', '1:29' on hover
   * getUsageLabel({ viewedTimeInSeconds: 60 }); // returns '1', '1:00' on hover
   * getUsageLabel({ viewedTimeInSeconds: 59, showUnroundedOnHover: false }); // returns '<1'
   * ```
   * @param {{
   *  lettersToAppendWhenNotZero: string | null;
   *  lettersToAppendWhenZero: string | null;
   *  showUnroundedOnHover: boolean;
   *  viewedTimeInSeconds: number;
   * }}
   */
  static getUsageLabel = ({
    lettersToAppendWhenNotZero = '',
    lettersToAppendWhenZero = '',
    showUnroundedOnHover = true,
    viewedTimeInSeconds
  } = {}) => {
    if (typeof viewedTimeInSeconds !== 'number') {
      return '—';
    }
    const viewedTimeInMinutesWithDecimal = viewedTimeInSeconds / 60;
    const roundedViewedTime = Math.round(viewedTimeInMinutesWithDecimal);

    let usageLabel;
    if (roundedViewedTime < 1
      && viewedTimeInMinutesWithDecimal > 0 && viewedTimeInMinutesWithDecimal < 1
    ) {
      usageLabel = `<1${lettersToAppendWhenNotZero || ''}`;
    } else if (viewedTimeInMinutesWithDecimal === 0) {
      usageLabel = `${roundedViewedTime}${lettersToAppendWhenZero || ''}`;
    } else {
      usageLabel = `${roundedViewedTime}${lettersToAppendWhenNotZero || ''}`;
    }

    if (showUnroundedOnHover) {
      const unroundedUsageLabel = this.getUnroundedUsageLabel(viewedTimeInSeconds);
      return PopupService.renderPopup({
        content: unroundedUsageLabel,
        trigger: usageLabel
      });
    }
    return usageLabel;
  }

  static getUnroundedUsageLabel = (viewedTimeInSeconds) => {
    viewedTimeInSeconds = +viewedTimeInSeconds;
    if (typeof viewedTimeInSeconds !== 'number') {
      throw new TypeError('UsageService.getUsageLabel: `viewedTimeInSeconds` is required and must be a number');
    }
    const viewedTimeInMinutesWithDecimal = viewedTimeInSeconds / 60;
    let mm = Math.floor(viewedTimeInMinutesWithDecimal);
    mm = mm < 10 ? `0${mm}` : mm;
    let ss = Math.round(viewedTimeInSeconds % 60);
    ss = ss < 10 ? `0${ss}` : ss;
    return `${mm}:${ss}`;
  }

  /** @param {import('react-table').Cell} currentCell */
  static getScoreCellUsageClassName = (currentCell) => {
    const MORE_TIME_PERCENTILE = 75;
    const LESS_TIME_PERCENTILE = 25;
    const currentCellViewedTime = typeof currentCell?.value === 'number' ? currentCell?.value : currentCell?.value?.viewedTime;

    if (typeof currentCellViewedTime !== 'number' ||
      !Array.isArray(currentCell?.row?.cells) || !currentCell?.row?.cells.length) {
      return 'unknown-time';
    }
    const allViewedTimesInRow = currentCell.row.cells.map((cell) => {
      if (typeof cell?.value === 'number') {
        return cell.value;
      } else if (typeof cell?.value?.viewedTime === 'number') {
        return cell.value.viewedTime;
      } else {
        return undefined;
      }
    }).filter((value) => value);

    const percentile = getPercentile(allViewedTimesInRow, currentCellViewedTime);
    if (typeof percentile !== 'number') {
      return 'unknown-time';
    } else if (percentile <= LESS_TIME_PERCENTILE) {
      return 'less-time';
    } else if (percentile >= MORE_TIME_PERCENTILE) {
      return 'more-time';
    }
    return 'mid-time';
  }
}
