/* eslint-disable lines-between-class-members */
import { action, observable } from 'mobx';
import { generateUrl } from '../utils';

import Auth from './AuthManager';

const EXPORT_ENDPOINTS = {
  SUBMIT_REPORT_REQUEST: '/api/submitReportRequest'
};

const EXCLUDED_PARAMETER_KEYS = 'reportId,emailAddress,parameterKeysArray';

export class ExportManager {
  @observable cachedPartialExportRequestBody = undefined;

  // GETTERS FOR GRADEBOOK EXPORT OPTIONS - do not alphabetize for now
  @observable allowExportFullGradebookSummary = true;
  @observable allowExportMiniGradebookSummary = false;
  @observable allowExportFullGradebookItemAnalysis = true;
  @observable allowExportMiniGradebookItemAnalysis = false;
  @observable allowExportFullGradebookStandards = true;

  // GETTERS FOR COURSE EXPORT OPTIONS - do not alphabetize for now
  @observable allowExportFullCourseReport = true;
  @observable allowExportFullCourseSchoolDetailByStudentReport = false;
  @observable allowExportMiniCourseReportScoresOnly = false;
  @observable allowExportMiniCourseReportPointsOnly = false;

  // GETTERS FOR STANDARDS EXPORT OPTIONS - do not alphabetize for now
  @observable allowExportFullStandardsReport = true;
  @observable allowExportFullStandardsSchoolDetailByStudentReport = false;
  @observable allowExportMiniStandardsReportScoresOnly = false;

  @action setCachedPartialExportRequestBody = (partialRequestBody) => {
    this.cachedPartialExportRequestBody = partialRequestBody;
  }

  /**
   * Generic `requestExport` POST method for `/api/submitReportRequest`
   *
   * Note: if `parameterKeysArray: string[]` not an array (e.g. null),
   * `parameterKeys: string` will be generated on-the-fly and put into the request body.
   *
   * @param {{
   *   baseReportId: string,
   *   classroomId?: string,
   *   contentItemId?: string,
   *   courseContentItemId?: string,
   *   curriculumMapId?: string,
   *   districtId?: string,
   *   emailAddress: string,
   *   institutionId?: string,
   *   parameterKeysArray: string[],
   *   schoolId: string
   * }} obj
   */
  /* @action */ requestExport = async (obj = {}) => {
    try {
      const { emailAddress, parameterKeysArray } = obj;
      if (!emailAddress) {
        throw new TypeError('ExportManager.requestExport: `emailAddress` is required');
      }
      const usingCustomParameterKeysArray = Array.isArray(parameterKeysArray);
      let parameterKeys = '';
      if (usingCustomParameterKeysArray) {
        parameterKeys = parameterKeysArray.toString();
      }
      const objWithNonNullProps = {};
      for (const prop in obj) {
        if (prop !== 'parameterKeysArray' && (obj[prop] || typeof obj[prop] === 'boolean')) {
          objWithNonNullProps[prop] = obj[prop];
          if (!usingCustomParameterKeysArray && !EXCLUDED_PARAMETER_KEYS.includes(prop)) {
            parameterKeys += `${parameterKeys ? ',' : ''}${prop}`;
          }
        }
      }

      const apiUrlPrefix = `${Auth.ecms}${EXPORT_ENDPOINTS.SUBMIT_REPORT_REQUEST}`;
      const apiUrl = generateUrl(apiUrlPrefix, {
        ...objWithNonNullProps,
        parameterKeys
      });
      const response = await Auth.fetch(apiUrl, {
        method: 'POST'
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  // SETTERS FOR GRADEBOOK EXPORT OPTIONS - do not alphabetize for now
  @action setAllowExportFullGradebookSummary = (allow) => {
    this.allowExportFullGradebookSummary = allow;
  }
  @action setAllowExportMiniGradebookSummary = (allow) => {
    this.allowExportMiniGradebookSummary = allow;
  }
  @action setAllowExportFullGradebookItemAnalysis = (allow) => {
    this.allowExportFullGradebookItemAnalysis = allow;
  }
  @action setAllowExportMiniGradebookItemAnalysis = (allow) => {
    this.allowExportMiniGradebookItemAnalysis = allow;
  }
  @action setAllowExportFullGradebookStandards = (allow) => {
    this.allowExportFullGradebookStandards = allow;
  }

  // SETTERS FOR COURSE REPORT EXPORT OPTIONS - do not alphabetize for now
  @action setAllowExportFullCourseReport = (allow) => {
    this.allowExportFullCourseReport = allow;
  }
  @action setAllowExportFullCourseSchoolDetailByStudentReport = (allow) => {
    this.allowExportFullCourseSchoolDetailByStudentReport = allow;
  }
  @action setAllowExportMiniCourseReportScoresOnly = (allow) => {
    this.allowExportMiniCourseReportScoresOnly = allow;
  }
  @action setAllowExportMiniCourseReportPointsOnly = (allow) => {
    this.allowExportMiniCourseReportPointsOnly = allow;
  }

  // SETTERS FOR STANDARDS REPORT EXPORT OPTIONS - do not alphabetize for now
  @action setAllowExportFullStandardsReport = (allow) => {
    this.allowExportFullStandardsReport = allow;
  }
  @action setAllowExportFullStandardsSchoolDetailByStudentReport = (allow) => {
    this.allowExportFullStandardsSchoolDetailByStudentReport = allow;
  }
  @action setAllowExportMiniStandardsReportScoresOnly = (allow) => {
    this.allowExportMiniStandardsReportScoresOnly = allow;
  }
}

export default new ExportManager();
