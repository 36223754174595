import React, { useContext, useMemo, useEffect } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import { Button } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';
import Modal from './Modal';
import '../css/FullScreenModalPage.less';

const FullScreenModalPage = observer((props = {}) => {
  // eslint-disable-next-line no-unused-vars
  const {
    actions, children, className, close, i18n, showLogo, t, tReady, title, ...restProps
  } = props;
  const { userManager } = useContext(MobXProviderContext);
  const Logo = useMemo(() => SatCoreComponent('Logo'), []);

  useEffect(() => {
    const dimmer = document.getElementsByClassName('ui page modals dimmer transition visible active');
    if (dimmer?.[0]?.classList && className && typeof className === 'string') {
      const classNamesArr = className.split(' ');
      for (const currentClassName of classNamesArr) {
        dimmer[0].classList.add(currentClassName);
      }
    }
  }, []);

  return (
    <Modal
      aria-modal='true'
      className={['full-screen-modal-page', className, userManager.isStudent
        ? 'modal-student-client' : null].filter((nextClassName) => nextClassName).join(' ')}
      onClose={close}
      open={true}
      role='dialog'
      size='fullscreen'
      tabIndex='0'
      {...restProps}>
      <div className='f-header'>
        {title ?
          <div className='modal-title-text'>{title}</div> :
          <span className='modal-logo'>{showLogo && <Logo />}</span>}
        {actions || <Button onClick={close} primary>Close</Button>}
      </div>
      {children}
    </Modal>
  );
});

export default FullScreenModalPage;

SatCoreRegister('FullScreenModalPage', FullScreenModalPage);
