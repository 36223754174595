import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { Button, Popup, Segment } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';
import UtilityService from '../services/UtilityService';

import '../css/AssessmentItemCard.less';

const AssessmentItemCard = observer((props) => {
  const { tagContentManager } = useContext(MobXProviderContext);
  const { assessmentManager } = useContext(MobXProviderContext);
  const { assessmentItem, handleAssessmentItemCardRemove, handleLearnosityItemPreview } = props;

  const CategoryTagsList = SatCoreComponent('CategoryTagsList');

  const assessmentNameObj = UtilityService.reactHtmlParserWrapper(
    assessmentItem.name
  );
  const assessmentDescriptionObj = UtilityService.reactHtmlParserWrapper(
    assessmentItem.description
  );

  let assessmentItemCardDescriptionJsx = (
    <div className='assessment-item-description-text subtitle'>
      {assessmentDescriptionObj.parsed}
    </div>
  );

  const trigger = assessmentItemCardDescriptionJsx;
  const popup = (
    <Popup
      content={assessmentDescriptionObj.parsed}
      hoverable
      on='hover'
      position='bottom left'
      positionFixed
      trigger={trigger}
      wide='very' />
  );
  assessmentItemCardDescriptionJsx = popup;

  const tagArray = tagContentManager.getSortedTagsForAssessmentItem(assessmentItem);
  const standardArray = assessmentManager.getSortedStandardsForAssessmentItem(assessmentItem);
  const combinedArray = [...tagArray, ...standardArray];
  combinedArray.sort((a, b) => ((a.sortBy > b.sortBy) ? 1 : -1));

  const cardStyle = {};
  return (
    <>
      <Segment
        key={`assessment-item_card_${assessmentItem.id}`}
        className='assessment-item-card-satcore list-card'
        style={cardStyle}>
        <div className='card-body'>
          <div className='assessment-item-description clickable'>
            {assessmentItemCardDescriptionJsx}
          </div>
          <CategoryTagsList assessmentItem={assessmentItem} isFullList={false} pills={combinedArray} />
          <div
            className='guid-text'
            title={assessmentNameObj.stripped}>
            {assessmentNameObj.parsed}
          </div>
        </div>
        <div className='card-nav-buttons'>
          <Button
            basic
            className='assessment-item-view-button'
            onClick={() => { handleLearnosityItemPreview(assessmentItem); }}
            primary
            type='button'>
            View
          </Button>
          {props.isEditorView && (
          <Button
            basic
            className='assessment-item-remove-button'
            onClick={() => { handleAssessmentItemCardRemove(assessmentItem); }}
            primary
            type='button'>
            Remove
          </Button>
          )}
        </div>
      </Segment>
    </>
  );
});
export default AssessmentItemCard;

SatCoreRegister('AssessmentItemCard', AssessmentItemCard);
