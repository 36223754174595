import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Checkbox } from 'semantic-ui-react';

import '../../css/reports/ReportContentWithoutScoresToggler.less';

import { SatCoreRegister } from '../../SatCoreRegistry';

import ReportTableService from '../../services/reports/ReportTableService';

const ReportContentWithoutScoresToggler = observer((props) => {
  const { t } = props;

  const { reportIdentityManager, reportScoreManager } = useContext(MobXProviderContext);

  const { /* managers */ } = reportIdentityManager;

  const handleToggleContentWithoutScores = (_event, data) => {
    const shouldHideContentWithoutScores = data.checked;
    reportScoreManager.setShouldHideContentWithoutScores(shouldHideContentWithoutScores);
    ReportTableService.forceRerenderReportTable();
  };

  const renderContentWithoutScores = () => {
    const { reportInfoClassNames } = reportIdentityManager;
    const { shouldHideContentWithoutScores } = reportScoreManager;
    return (
      <div className={`report-content-without-scores-toggler ${reportInfoClassNames}`}>
        <div className='toggle-hide-or-show-wrapper'>
          <Checkbox
            className='hide-or-show-toggler hide-or-show-toggler-directly-on-background'
            defaultChecked={shouldHideContentWithoutScores}
            label={t(shouldHideContentWithoutScores ? 'hideContentWithoutScoresTrueLabel' : 'hideContentWithoutScoresFalseLabel')}
            onChange={handleToggleContentWithoutScores}
            toggle />
        </div>
      </div>
    );
  };
  return renderContentWithoutScores();
});
export default ReportContentWithoutScoresToggler;

SatCoreRegister('ReportContentWithoutScoresToggler', ReportContentWithoutScoresToggler);
