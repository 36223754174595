import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Image } from 'semantic-ui-react';

import classNames from 'classnames';

import classroomDefault from '../img/default-classroom.svg';

import '../css/ClassCardImage.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

const ClassCardImage = observer((props) => {
  const {
    additionalClassNames, classroomId, defaultImage, onClick, openImagePicker
  } = props;
  const {
    classroomManager,
    userManager
  } = useContext(MobXProviderContext);

  const DotMenu = SatCoreComponent('DotMenu');

  const renderClassCardImage = () => {
    const { isTeacher } = userManager;
    const classroom = classroomManager.getClassroom(classroomId);
    const imageUrl = classroomManager.getClassroomImageUrl(classroomId);
    return (
      <div className={classNames('class-card-image-wrapper clickable', additionalClassNames, {
        'default-image': !imageUrl
      })}>
        {(isTeacher) ? <DotMenu clickMenu={() => openImagePicker(classroom.id)} /> : null}
        <div className={(imageUrl === null) ? 'class-card-image default-image' : 'class-card-image'} onClick={onClick}>
          {imageUrl === null ?
            <Image src={defaultImage || classroomDefault} /> :
            <Image className='class-image' src={imageUrl} />}
        </div>
      </div>
    );
  };
  return renderClassCardImage();
});

export default ClassCardImage;

SatCoreRegister('ClassCardImage', ClassCardImage);
