import { action, makeObservable, observable } from 'mobx';
import Auth from './AuthManager';

export class LearnosityItemsManager {
    @observable testMap = new Map();

    @observable testBool = false;

    @observable learnosityActivityType = null;

    constructor() {
      makeObservable(this);
    }

    @action setLearnosityInitResponseFields(response) {
      if (response.request.type) {
        this.learnosityActivityType = response.request.type;
      }
    }

    @action getLearnosityItemsApiPreviewRequest = async (contentItemId, showAnswers, renderingType = 'assess') => {
      try {
        const response = await Auth.fetch(`${Auth.ecms}/api/items-preview-init?contentItemId=${contentItemId}&publisherSatelliteCode=${Auth.publisherSatelliteCode}&showAnswers=${showAnswers}&renderingType=${renderingType}`, {
          method: 'GET'
        });

        if (response && response.status === 'SUCCESS') {
          const data = JSON.parse(response.data);
          this.setLearnosityInitResponseFields(JSON.parse(data.init));
          return data;
        }
        if (response && response.status === 'FAILURE') {
          throw response.statusMessage;
        } else {
          throw 'Unexpected Error occured.';
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    }

    @action getLearnosityItemsApiAssessmentRequest = async (assignmentId, showAnswers) => {
      try {
        const response = await Auth.fetch(`${Auth.ecms}/api/items-assessment-init?activityInstanceId=${assignmentId}&publisherSatelliteCode=${Auth.publisherSatelliteCode}&showAnswers=${showAnswers}`, {
          method: 'GET'
        });

        if (response && response.status === 'SUCCESS') {
          const data = JSON.parse(response.data);
          this.setLearnosityInitResponseFields(JSON.parse(data.init));
          return data;
        }
        if (response && response.status === 'FAILURE') {
          throw response.statusMessage;
        } else {
          throw 'Unexpected Error occured.';
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    }

    @action getLearnosityItemsApiInlineReviewRequest = async (assignmentId) => {
      try {
        const response = await Auth.fetch(`${Auth.ecms}/api/items-inline-review-init?activityInstanceId=${assignmentId}&publisherSatelliteCode=${Auth.publisherSatelliteCode}`, {
          method: 'GET'
        });

        if (response && response.status === 'SUCCESS') {
          const data = JSON.parse(response.data);
          this.setLearnosityInitResponseFields(JSON.parse(data.init));
          return data;
        }
        if (response && response.status === 'FAILURE') {
          throw response.statusMessage;
        } else {
          throw 'Unexpected Error occured.';
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
}

export default new LearnosityItemsManager();
