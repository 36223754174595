import { getRegisteredClass, registerClass } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';
import accommodationsManager from '../managers/AccommodationsManager';
import adminUsersManager from '../managers/AdminUsersManager';
import appManager from '../managers/AppManager';
import assignmentManager, { ASSIGNMENT_STATUS } from '../managers/AssignmentManager';
import classroomManager from '../managers/ClassroomManager';
import contentManager from '../managers/ContentManager';
import courseManager from '../managers/CourseManager';
import curriculumMapManager from '../managers/CurriculumMapManager';
import dialogManager, { DIALOG_NAMES } from '../managers/DialogManager';
import dynamicSatelliteManager from '../managers/DynamicSatelliteManager';
import engagementManager from '../managers/EngagementManager';
import filteredHeaderTableManager from '../managers/FilteredHeaderTableManager';
import gradebookManager from '../managers/GradebookManager';
import learnosityDataManager from '../managers/LearnosityDataManager';
import navigationManager from '../managers/NavigationManager';
import notificationManager from '../managers/NotificationManager';
import productManager from '../managers/ProductManager';
import studentContentCardManager from '../managers/StudentContentCardManager';
import studentProgressManager from '../managers/StudentProgressManager';
import teacherProductViewManager from '../managers/TeacherProductViewManager';
import thirdPartyManager from '../managers/ThirdPartyManager';
import topNavManager from '../managers/TopNavManager';
import userManager from '../managers/UserManager';

import ImageService from './ImageService';
import ReportIdentityService from './reports/ReportIdentityService';
import StudentHelperService from './StudentHelperService';
import ThirdPartyService from './ThirdPartyService';

import { getSessionStorageItem, removeSessionStorageItem } from '../utils';

import { register } from '../i18n';

const t = register('InitService');

export default class InitService {
  static doLoginInit() {
    InitService.loginInit();
    ImageService.initImageService();
  }

  static doLogoutCleanUp(strOfItemsToPreserve = '', { authKey } = {}) {
    InitService.logoutCleanUp(strOfItemsToPreserve, { authKey });
  }

  static logoutCleanUp(strOfItemsToPreserve = '', { authKey } = {}) {
    strOfItemsToPreserve = strOfItemsToPreserve || '';

    // TODO - we may want to revisit this in the future
    // ---
    // The `shouldReloadLoginPageAfterLogout` flag was added for GW-76
    // to address a bug that would make the login input fields appear read-only
    // after a user logged out. This is likely caused by a rendering issue caused by
    // login components living in both sat-core and GW.
    // ---
    // We later ran into a problem where this was causing an infinite loop for SSO users,
    // so we also need to make sure we do NOT reload the page if the user is SSO.
    // ---
    // A 'better' solution that will probably work, (if we have time to do this in a future sprint)
    // is if we refactor the Login render logic for all satellites to live in sat-core.
    // ---
    // Refactoring the Login pages will likely eliminate the need to force-refresh the login page
    // for self-reg users logging out.
    let shouldReloadLoginPageAfterLogout;
    if (!authKey && !userManager.isSsoUser && appManager.shouldReloadLoginPageAfterLogout) {
      shouldReloadLoginPageAfterLogout = true;
    }

    ThirdPartyService.removeThirdPartyChatLauncherIfApplicable();

    // add baseline cleanup for managers or services here
    removeSessionStorageItem('classroomId');
    removeSessionStorageItem('adminClassroomReportFacultyName');
    removeSessionStorageItem('adminClassroomReportLeadTeacherName');
    ImageService.cleanUp();
    ReportIdentityService.clearAllReportManagers();
    accommodationsManager.clearAll();
    adminUsersManager.clearAll();
    assignmentManager.clearAll();
    classroomManager.clearAll();
    contentManager.clearAll();
    courseManager.clearAll();
    curriculumMapManager.clearAll();
    engagementManager.clearAll();
    filteredHeaderTableManager.clearAll();
    gradebookManager.clearAll();
    learnosityDataManager.clearAll();
    notificationManager.clearAll();
    productManager.clearAll();
    productManager.setIsFromProduct(false);
    studentContentCardManager.clearAll();
    studentProgressManager.clearAll();
    teacherProductViewManager.clearAll();
    thirdPartyManager.clearAll();
    topNavManager.clearAll();
    userManager.clear();
    userManager.clearSession();
    if (!strOfItemsToPreserve.includes('impersonate')) {
      sessionStorage.removeItem('impersonate');
    }

    Auth.logout();

    // TODO
    if (shouldReloadLoginPageAfterLogout) {
      window.location.reload();
    }
  }

  static loginInit() {
    // add baseline cleanup for managers or services here
    ReportIdentityService.clearAllReportManagers();
    accommodationsManager.clearAll();
    assignmentManager.clearAll();
    classroomManager.clearAll();
    courseManager.clearAll();
    productManager.clearAll();
    studentProgressManager.clearAll();
    productManager.clearAll();
    learnosityDataManager.clearAll();
  }

  static initActiveUserRoleAsHtmlBodyAttr = (permissionId = '') => {
    permissionId = permissionId || userManager.activePermissionId;
    const body = document.getElementsByTagName('body')?.[0];
    if (body && permissionId) {
      body.setAttribute('data-satellite-user-role', permissionId);
    } else if (body?.getAttribute?.('data-satellite-user-role')) {
      body.removeAttribute('data-satellite-user-role');
    }
  }

  static removeHtmlBodyRoleAttr = () => {
    const body = document.getElementsByTagName('data-satellite-user-role')?.[0];
    if (body?.getAttribute?.('data-satellite-user-role')) {
      body.removeAttribute('data-satellite-user-role');
    }
  }

  static viewNotificationModal = () => {
    dialogManager.setOpenDialog(DIALOG_NAMES.VIEW_NOTIFICATION, {
    });
  }

  static async initData(urlParams) {
    appManager.setInitializingApp(true);

    const DynamicSatelliteService = getRegisteredClass('DynamicSatelliteService');

    if (dynamicSatelliteManager.isDynamicSatellite && !Auth.publisherSatelliteCode) {
      await DynamicSatelliteService.fetchDynamicSatelliteByDomain();
    }

    let elementId = '';

    const classroomId = urlParams.get('classroomId');
    if (classroomId) {
      if (classroomId === 'product') {
        productManager.setIsFromProduct(true);
      } else if (classroomId === 'FROM_TEACHER_PRODUCTS_NAV') {
        productManager.setIsFromProduct('FROM_TEACHER_PRODUCTS_NAV');
      }
      classroomManager.setCurrentClassroomId(classroomId);
    }

    if (urlParams.has('courseId')) {
      courseManager.setCurrentCourseId(urlParams.get('courseId'));
    }

    if (urlParams.has('elementId')) {
      elementId = urlParams.get('elementId');
      courseManager.setCurrentElementId(elementId);
    }

    await userManager.checkUser();

    navigationManager.setIsStudent(userManager.isStudent);
    navigationManager.setIsAdmin(userManager.isSchoolAdmin || userManager.isDistrictAdmin || userManager.isProductAdmin);

    // TODO remove // this is likely causing issues causing user to refresh screen to see new data
    // prevent unnecessarily calling `api/viewLeadTeacherClassrooms` more than once
    // const isTeacherDashboardOrReport = userManager.isTeacher && (window.location.pathname === '/' || reportIdentityManager.isReport);
    // if (!isTeacherDashboardOrReport) {
    //   await classroomManager.fetchClassroomData(classroomManager.currentClassroomId, 0, userManager.isStudent, userManager.userId);
    // } else {
    //   classroomManager.setLoaded(true);
    // }

    await classroomManager.fetchClassroomData(classroomManager.currentClassroomId, 0, userManager.isStudent, userManager.userId);
    classroomManager.setLoaded(true);

    const promises = [userManager.fetchMyUserOptions()];
    if (userManager.isStudent && classroomManager.currentClassroomId) {
      promises.push(
        StudentHelperService.handleChangeStudentClassroomAndInitData({
          classroomId: getSessionStorageItem('classroomId'),
          shouldSetInitializingApp: false,
          status: [ASSIGNMENT_STATUS.LOCKED, ASSIGNMENT_STATUS.READY, ASSIGNMENT_STATUS.STARTED]
        })
      );
    } else if (!userManager.isStudent) {
      promises.push(
        notificationManager.fetchManualNotifications({
          page: 0,
          userId: userManager.userId
        })
      );
      promises.push(
        notificationManager.fetchSystemNotifications({
          page: 0,
          userId: userManager.userId
        })
      );
    }

    const notificationId = getSessionStorageItem('c2c_notificationId');
    if (notificationId) {
      removeSessionStorageItem('c2c_notificationId');
      await notificationManager.fetchSystemNotification(notificationId);
      this.viewNotificationModal(notificationId);
    }

    if (dynamicSatelliteManager.isDynamicSatellite) {
      DynamicSatelliteService.initPostLoginSettersForDynamicSatellite();
    }

    await Promise.all(promises);

    appManager.setInitializingApp(false);
  }

  /**
   * Currently used by dynamic satellites only, but can be expanded to non-dynamic satellites in the future (if needed).
   *
   * Injects a `data-locale` body attribute using the `InitService.locale` value (defined in the `dynamicTranslations` object).
   *
   * (default locale is `'en-us'`)
   */
  static initLocale = () => {
    const locale = t('locale');

    const body = document.getElementsByTagName('body')?.[0];
    body?.setAttribute?.('data-locale', locale);

    appManager.setLocale(locale);
  }
}

registerClass('InitService', InitService);
