import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import { register } from '../../../i18n';

import EngagementService from '../../../services/EngagementService';

const EngagementHeaderMainAnnotationsUsageCells = observer((props) => {
  const { engagementManager } = useContext(MobXProviderContext);

  const activeTable = engagementManager.activeEngagementTable;

  const { handleHeaderClick } = props;

  const t = register('EngagementTable');

  const renderEngagementHeaderMainAnnotationsUsageCells = () => {
    const isOnlyShowingMainCells = activeTable === 'summary';
    return (
      <>
        {isOnlyShowingMainCells && renderEngagementHeaderMainAnnotationsUsageCell({
          allowPopupComponent: true,
          className: `main-cell engagement-header-label annotationsUsage active-table-${activeTable}`,
          isHeaderCell: true,
          popupContent: t('annotationsUsageDescriptionMain'),
          popupOn: 'hover',
          popupTrigger: (
            <div className='clickable'
              data-value='annotationsUsage'
              onClick={handleHeaderClick}>
              {t('annotationsUsage')}
            </div>
          )
        })}
        {!isOnlyShowingMainCells && renderEngagementHeaderMainAnnotationsUsageCell({
          allowPopupComponent: true,
          cellText: t('annotationsUsage'),
          className: `main-cell engagement-header-label annotationsUsage active-table-${activeTable}`,
          isHeaderCell: true,
          popupContent: t('annotationsUsageDescription'),
          popupOn: 'click'
        })}
      </>
    );
  };

  const renderEngagementHeaderMainAnnotationsUsageCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementHeaderMainAnnotationsUsageCells();
});
export default EngagementHeaderMainAnnotationsUsageCells;

SatCoreRegister('EngagementHeaderMainAnnotationsUsageCells', EngagementHeaderMainAnnotationsUsageCells);
