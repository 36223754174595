import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Container, Form, Grid, Header, Segment
} from 'semantic-ui-react';

import '../css/Verification.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

import { VIEW_SELECTION } from '../managers/NavigationManager';

import DynamicSatelliteService from '../services/DynamicSatelliteService';

export default
@inject(
  'classroomManager', 'courseManager', 'dynamicSatelliteManager',
  'navigationManager', 'productManager', 'userManager')
@observer
class PublisherCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      loaded: false
    };
    this.Logo = SatCoreComponent('Logo');
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');
  }

  async componentDidMount() {
    const { dynamicSatelliteManager } = this.props;

    this.setState({ loaded: false });

    if (dynamicSatelliteManager.isDynamicSatellite && !Auth.publisherSatelliteCode) {
      await DynamicSatelliteService.fetchDynamicSatelliteByDomain();
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 500);
    } else {
      this.setState({ loaded: true });
    }

    const { location, navigationManager, productManager, userManager } = this.props;

    const urlParams = new URLSearchParams(location.search);

    const courseContentItemId = urlParams.get('courseContentItemId');
    const previewAs = urlParams.get('previewAs');

    userManager.setCourseTeacher(previewAs === 'teacher');
    userManager.setCourseStudent(previewAs === 'student');

    if (productManager.FROM_TEACHER_PRODUCTS_NAV) {
      navigationManager.setView(VIEW_SELECTION.PRODUCTS);
      // productManager.setIsFromProduct('FROM_TEACHER_PRODUCTS_NAV');

      this.navToProducts({
        courseContentItemId,
        previewAs,
        productClassroomId: 'FROM_TEACHER_PRODUCTS_NAV'
      });
    } else {
      navigationManager.setView(VIEW_SELECTION.LOGIN);
      productManager.setIsFromProduct(true);

      let authKey = '';
      let error = '';

      if (urlParams.has('error')) {
        error = urlParams.get('error');
      }

      if (urlParams.has('authKey')) {
        authKey = urlParams.get('authKey');
      }

      if (error) {
        this.verificationError(error);
      } else if (authKey && courseContentItemId) {
        this.publisherLogIn(authKey, courseContentItemId, previewAs);
      } else {
        this.navToLogin();
      }
    }
  }

  verificationError = (error) => {
    this.setState({ errorMessage: error });
  }

  publisherLogIn = async (authKey, courseContentItemId, previewAs) => {
    const { userManager } = this.props;
    const result = await userManager.publisherLogIn(authKey);
    if (result) {
      this.navToProducts({ courseContentItemId, previewAs });
    } else {
      this.navToLogin();
    }
  };

  navToProducts = ({ courseContentItemId, previewAs = 'teacher', productClassroomId = 'product' } = {}) => {
    const { classroomManager, courseManager, history, navigationManager } = this.props;

    courseManager.setCurrentCourseId(courseContentItemId);
    courseManager.setCurrentElementId('');

    classroomManager.setCurrentClassroomId(productClassroomId);

    // eslint-disable-next-line max-len
    let routerUrl = `/class?courseId=${courseContentItemId}&elementId=ROOT&classroomId=${productClassroomId}`;

    if (previewAs === 'student') {
      routerUrl += '&previewAsStudent=true';
    }

    if (productClassroomId === 'FROM_TEACHER_PRODUCTS_NAV') {
      navigationManager.setView(VIEW_SELECTION.BOOK);
      routerUrl += `&view=${VIEW_SELECTION.BOOK}`;
      history.replace(routerUrl);
    } else {
      navigationManager.setView(VIEW_SELECTION.PRODUCTS);
      history.replace(routerUrl);
    }
  }

  navToLogin = () => {
    const { history } = this.props;
    history.replace('/login');
  }

  navToHome = () => {
    const { history } = this.props;
    history.replace('/');
  }

  failPanel = () => {
    const { Logo } = this;
    return (
      <Grid className='fullHeight' textAlign='center' verticalAlign='middle'>
        <Grid.Column>
          <Form>
            <Header as='h2' attached='top' block className='verification-header'>
              <Logo />
              Invalid username or password
            </Header>
            <Segment attached>
              <Form.Field>
                <Container className='login-error-message' text>
                  {/* eslint-disable-next-line react/destructuring-assignment */}
                  {this.state.errorMessage}
                </Container>
                <div className='panel-body'>
                  <Button basic id='home-btn' onClick={this.navToLogin} primary>Retry Login</Button>
                </div>
              </Form.Field>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }

  render() {
    const { loaded } = this.state;
    const { SatCoreLoader } = this;

    return loaded ? (
      <div className='verification-page'>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {(this.state.errorMessage) ? this.failPanel() : null}
      </div>
    ) : <SatCoreLoader />;
  }
}

SatCoreRegister('PublisherCourse', PublisherCourse);
