import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Checkbox, Header, Loader } from 'semantic-ui-react';

import '../css/AccommodationsTogglerSection.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import AccommodationsService from '../services/AccommodationsService';
import { ACCOMMODATION_TOGGLE_KEY } from '../services/AccommodationConstants';
import { ASSIGNMENT_TYPE } from '../managers/AssignmentManager';
import RosterService from '../services/RosterService';

const AccommodationsTogglerSection = observer((props = {}) => {
  const {
    assignment,
    classroomId,
    sectionKey = 'assessmentSection',
    sectionCheckboxChecked = false,
    sectionCheckboxOnChange = () => {},
    sectionCheckboxShow = false,
    style = {},
    t
  } = props;

  let { rosterItems } = props;

  const assignmentType = props.assignmentType || assignment?.assignEntityTypeId;

  const {
    accommodationsManager
  } = useContext(MobXProviderContext);

  const [loadingAccommodationsTogglerSection, setLoadingAccommodationsTogglerSection] = useState(true);

  const config = AccommodationsService.ACCOMMODATIONS_CONFIG();

  const SCCheckbox = SatCoreComponent('SCCheckbox');

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  useEffect(() => {
    (async () => {
      setLoadingAccommodationsTogglerSection(true);
      accommodationsManager.clearAccommodationsRoster();
      accommodationsManager.clearTogglerSectionOffAccommodationsSet();
      initTogglerSectionOffAccommodationsSet();
      updateCurrentAccommodationsRoster();
      setLoadingAccommodationsTogglerSection(false);
    })();
  }, []);

  const initTogglerSectionOffAccommodationsSet = () => {
    const offAccommodationsForAll = AccommodationsService.getOffAccommodationsForAll({
      assignment, config, rosterItems, sectionKey
    });
    for (const accommodationId of offAccommodationsForAll) {
      accommodationsManager.addToTogglerSectionOffAccommodationsSet(accommodationId);
    }
  };

  const handleToggleAccommodation = (_event, { checked }, {
    shouldUpdateCurrentAccommodationsRoster = true,
    toggleKey
  }) => {
    const isAccommodationOn = checked;

    if (config.treatAllSectionsAsOne) {
      const accommodationId_assessmentSection = ACCOMMODATION_TOGGLE_KEY['assessmentSection'][toggleKey];
      const accommodationId_lessonSection = ACCOMMODATION_TOGGLE_KEY['lessonSection'][toggleKey];
      if (isAccommodationOn) {
        accommodationsManager.removeFromTogglerSectionOffAccommodationsSet(accommodationId_assessmentSection);
        accommodationsManager.removeFromTogglerSectionOffAccommodationsSet(accommodationId_lessonSection);
      } else {
        accommodationsManager.addToTogglerSectionOffAccommodationsSet(accommodationId_assessmentSection);
        accommodationsManager.addToTogglerSectionOffAccommodationsSet(accommodationId_lessonSection);
      }
    } else {
      const accommodationId = ACCOMMODATION_TOGGLE_KEY[sectionKey][toggleKey];
      if (isAccommodationOn) {
        accommodationsManager.removeFromTogglerSectionOffAccommodationsSet(accommodationId);
      } else {
        accommodationsManager.addToTogglerSectionOffAccommodationsSet(accommodationId);
      }
    }

    if (shouldUpdateCurrentAccommodationsRoster) {
      updateCurrentAccommodationsRoster({ isAccommodationOn });
    }
  };

  const updateCurrentAccommodationsRoster = async () => {
    if (assignmentType === ASSIGNMENT_TYPE.CLASSROOM_USER) {
      rosterItems = RosterService.getFullAssignmentRoster({ assignment, classroomId });
      accommodationsManager.addToCurrentAccommodationsRoster(rosterItems);
    } else {
      accommodationsManager.addToCurrentAccommodationsRoster(rosterItems);
    }
  };

  const renderAccommodationsTogglerSection = () => {
    const toggleKeys = AccommodationsService.getConfigEnabledAccommodationToggleKeys({ config, sectionKey });
    const { togglerSectionOffAccommodations } = accommodationsManager;
    return loadingAccommodationsTogglerSection ? <Loader active /> : (
      <div className='accommodations-toggler-section'>
        <Header as='h3' className='section-header' style={style}>
          {sectionCheckboxShow ? renderAccommodationTogglerSectionCheckbox() : t('accommodations')}
        </Header>
        <div className='accommodations-togglers'>
          {toggleKeys.map((toggleKey, _index) => {
            const accommodationId = ACCOMMODATION_TOGGLE_KEY[sectionKey][toggleKey];
            const isAccommodationOn = !togglerSectionOffAccommodations.includes(accommodationId);
            return renderAccommodationsToggler({
              checked: isAccommodationOn,
              sectionKey,
              toggleKey
            });
          })}
        </div>
      </div>
    );
  };

  const renderAccommodationTogglerSectionCheckbox = () => {
    return (
      <div className='section-wrapper'>
        <SCCheckbox
          checked={sectionCheckboxChecked}
          label={t('accommodations')}
          onChange={sectionCheckboxOnChange}
          readOnly />
      </div>
    );
  };

  const renderAccommodationsToggler = ({
    checked,
    toggleKey
  }) => {
    return (
      <div key={toggleKey} className={`accommodations-toggler ${toggleKey}`}>
        <div>
          <Checkbox
            defaultChecked={checked}
            disabled={sectionCheckboxShow && !sectionCheckboxChecked}
            label={t(toggleKey)}
            onChange={(event, data) => handleToggleAccommodation(event, data, { toggleKey })}
            toggle />
        </div>
      </div>
    );
  };
  return renderAccommodationsTogglerSection();
});
export default AccommodationsTogglerSection;

SatCoreRegister('AccommodationsTogglerSection', AccommodationsTogglerSection);
