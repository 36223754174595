import React from 'react';

import { Button } from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/TagsModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

const ModalBanner = SatCoreComponent('ModalBanner');

const Tag = (props) => {
  const { tag } = props;
  const { id, name } = tag;

  return (
    <span key={id} className='tag'>{name}</span>
  );
};

const Category = (props) => {
  const { category } = props;
  const { id, name, tags } = category;

  return (
    <>
      <div key={id} className='category'>{name}</div>
      <div className='tags'>
        {tags.map((tag) => {
          return (
            <Tag key={tag.id} tag={tag} />
          );
        })}
      </div>
    </>
  );
};

const TagsModal = (props) => {
  const { addInfoOpen, closeInfoModal, lessonName, tags, } = props;
  const hasTags = tags && tags.length > 0;

  return (
    <Modal
      className='tags-modal'
      closeOnDimmerClick={true}
      closeOnEscape={true}
      onClose={closeInfoModal}
      open={addInfoOpen}>
      <ModalBanner
        onClose={() => { closeInfoModal(); }}
        title={lessonName} />
      <Modal.Content>
        {hasTags && (
          <div className='container'>
            {tags.map((category) => {
              return (
                <Category key={category.id} category={category} />
              );
            })}
          </div>
        )}
        {!hasTags && <div className='container'>No tags</div>}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => { closeInfoModal(); }} primary>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};
export default TagsModal;
SatCoreRegister('TagsModal', TagsModal);
