import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Dropdown } from 'semantic-ui-react';

import '../../css/reports/ReportDropdown.less';

import { SatCoreRegister } from '../../SatCoreRegistry';

import ReportTableService from '../../services/reports/ReportTableService';
import { REPORT_ATTRIBUTE_FILTER_TYPE } from '../../services/reports/ReportConstants';
import { getSessionStorageItem } from '../../utils';

const ReportAttributeFiltersDropdown = observer((props) => {
  const { t } = props;

  const { reportIdentityManager, reportStandardsManager } = useContext(MobXProviderContext);

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  useEffect(() => {
    (async () => {
      reportStandardsManager.setSelectedReportAttributeFilterType(
        getSessionStorageItem('selectedReportAttributeFilterType')
      );
    })();
  }, []);

  const handleChangeDropdown = (_event, { value }) => {
    reportStandardsManager.setSelectedReportAttributeFilterType(value);
    ReportTableService.forceRerenderReportTable();
  };

  const renderReportAttributeFiltersDropdown = () => {
    const { reportInfoClassNames } = reportIdentityManager;
    return (
      <div className={`report-dropdown report-attribute-filters-dropdown ${reportInfoClassNames}`}>
        <div className='dropdown-label'>
          <div className='text'>
            {t('filterBy')}
          </div>
        </div>
        <Dropdown
          onChange={handleChangeDropdown}
          options={Object.values(REPORT_ATTRIBUTE_FILTER_TYPE).map((dropdownOption) => {
            return {
              text: t(dropdownOption),
              value: dropdownOption
            };
          })}
          value={reportStandardsManager.selectedReportAttributeFilterType} />
      </div>
    );
  };
  return renderReportAttributeFiltersDropdown();
});
export default ReportAttributeFiltersDropdown;

SatCoreRegister('ReportAttributeFiltersDropdown', ReportAttributeFiltersDropdown);
