import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Form, Grid, Header, Segment } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import '../css/LTIPlayerLauncher.less';
import { VIEW_SELECTION } from '../managers/NavigationManager';

import { setSessionStorageItem } from '../utils/session';

import Auth from '../managers/AuthManager';

export default
@inject('userManager', 'courseManager', 'dialogManager', 'navigationManager', 'classroomManager',
  'contentManager', 'assignmentManager')
@observer
class StudentAssignmentLauncher extends Component {
  constructor(props) {
    super(props);
    this.state = { errorMesage: null, errorTitle: null };
    this.Logo = SatCoreComponent('Logo');
  }

  // satellite Branded Error panel
  failPanel = () => {
    const { errorTitle, errorMessage } = this.state;
    const { Logo } = this;
    return (
      <Grid className='fullHeight' textAlign='center' verticalAlign='middle'>
        <Grid.Column className='max-width-558'>
          <Form>
            <Header as='h2' attached='top' block>
              <div className='message-header'>
                <Logo />
                <div>{errorTitle}</div>
              </div>
            </Header>
            <Segment attached>
              <Form.Field>
                <Container className='login-error-message' text>
                  {errorMessage}
                </Container>
                {/* <div className='panel-body'>
                  <Button id='home-btn' basic primary  onClick={this.navToLogin}>Retry Login</Button>
                </div> */}
              </Form.Field>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }

  componentDidMount() {
    const { navigationManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.LOGIN);

    // look for verification ID in URL params
    const urlParams = new URLSearchParams(this.props.location.search);
    let linkId = null;
    let error = '';

    // get params error, there shouldn't be anything here but leaving in case it might be needed later
    if (urlParams.has('error') && urlParams.get('error') !== 'null') {
      error = urlParams.get('error');
    }

    // get params 'linkId' - need to save this in session so Student Dashboard can grab it after login
    if (urlParams.has('linkId') && urlParams.get('linkId') !== 'null') {
      linkId = urlParams.get('linkId');
    }

    if (error) {
      this.verificationError('Error', error);
    } else if (linkId) {
      try {
        setSessionStorageItem('c2c_assignmentLinkId', linkId);
      } catch (error) {
        console.error(error);
      }
      // If logged in, go to default otherwise redirect to login, once session is saved.
      if (Auth.loggedIn()) {
        this.props.history.replace('/');
      } else {
        this.props.history.replace('/login');
      }
    } else {
      this.verificationError('Error', 'Not Authorized');
    }
  }

  verificationError = (errorTitle, errorMessage) => {
    this.setState({ errorTitle, errorMessage });
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <div className='lrn-player-launcher-container'>
          {this.failPanel()}
        </div>
      );
    }
    return null;
  }
}

SatCoreRegister('StudentAssignmentLauncher', StudentAssignmentLauncher);
