import { registerClass } from '../SatCoreRegistry';

import navigationManager, { VIEW_SELECTION } from '../managers/NavigationManager';

import classroomManager from '../managers/ClassroomManager';
import courseManager from '../managers/CourseManager';
import curriculumMapManager from '../managers/CurriculumMapManager';
import productManager from '../managers/ProductManager';
import teacherProductViewManager from '../managers/TeacherProductViewManager';
import userManager from '../managers/UserManager';

import ClassroomService from './ClassroomService';

import { register } from '../i18n';

const t = register('TeacherProductViewService');

export default class TeacherProductViewService {
  static manager = () => teacherProductViewManager;

  static fetchProductResourcesByInstitution = async ({
    clearBeforeStoring = false,
    institutionId = null,
    page = 1,
    pageSize = 20,
    resourceId,
    searchText = '',
    sortColumn = null,
    sortDirection = 'ascending',
  } = {}) => {
    const resourceFilter = searchText ? {
      field: 'resourceName',
      operator: 'contains',
      value: searchText
    } : undefined;

    const resourceIdFilter = resourceId ? {
      field: 'resourceId',
      operator: 'eq',
      value: resourceId
    } : undefined;

    const entityFilter = {
      field: 'entityTypeId',
      operator: 'contains',
      value: 'COURSE'
    };

    const productFilter = productManager?.selectedUserProduct?.code ? {
      field: 'productCode',
      operator: 'eq',
      value: productManager.selectedUserProduct.code
    } : undefined;

    const filters = [resourceFilter, resourceIdFilter, entityFilter, productFilter].filter((filterObj) => filterObj);

    // if searchText, get all results at once
    if (searchText) {
      page = 1;
      pageSize = 999;
    }

    return productManager.fetchProductResourcesByInstitution(
      institutionId, page, sortColumn, sortDirection, filters, pageSize, clearBeforeStoring
    );
  }

  static initBaseTeacherProductBreadcrumbs = async ({ clearFirst = true, shouldSetViewToProducts = true } = {}) => {
    if (clearFirst) {
      const noAddDefaultBreadcrumb = true;
      navigationManager.clearAllPaths(noAddDefaultBreadcrumb);
    }
    if (shouldSetViewToProducts) {
      navigationManager.setView(VIEW_SELECTION.PRODUCTS);
    }

    // second breadcrumb
    await navigationManager.setBreadcrumb({
      addAsFirstBreadcrumb: true,
      fromView: VIEW_SELECTION.PRODUCTS,
      path: {
        name: t('courses')
      }
    });

    // first breadcrumb
    await navigationManager.setBreadcrumb({
      addAsFirstBreadcrumb: true,
      fromView: VIEW_SELECTION.PRODUCTS,
      path: {
        name: t('products')
      }
    });
  }

  static addTeacherProductCoursesToClassroom = async ({
    currentTeacherProductCourse,
    selectedClassroomsMap
  } = {}) => {
    const classroomIds = Array.from(selectedClassroomsMap.keys());

    const courseContentItemId = currentTeacherProductCourse.contentItemId || currentTeacherProductCourse.id;

    const productId = productManager?.selectedUserProduct?.id;

    await courseManager.addCourseToClassrooms(
      classroomIds, courseContentItemId, productId
    );
  };

  static updateTeacherProductNavStatus = (pathname) => {
    const urlParams = new URLSearchParams(window.location.search);
    const hasTeacherProductsNavUrlParam = urlParams.get('fromTeacherProductsNav') === 'true';

    const classroomId = ClassroomService.getCurrentClassroomId();

    // if user is on a 'teacher product' related page but isFromProduct isn't properly set, do so
    if (userManager.isTeacher && productManager.isFromProduct !== 'FROM_TEACHER_PRODUCTS_NAV') {
      if (pathname === '/products' || pathname === '/publisherCourse' /* || location === '/class' */
        || classroomId === 'FROM_TEACHER_PRODUCTS_NAV' || hasTeacherProductsNavUrlParam
      ) {
        productManager.setIsFromProduct('FROM_TEACHER_PRODUCTS_NAV');
      }
    // if user is NOT on a 'teacher product' related page, ensure we are resetting the appropriate data (if applicable)
    } else if (productManager.isFromProduct === 'FROM_TEACHER_PRODUCTS_NAV'
      && classroomId !== 'FROM_TEACHER_PRODUCTS_NAV' && !hasTeacherProductsNavUrlParam
    ) {
      productManager.setIsFromProduct(false);
      TeacherProductViewService.clearProductViewSettings();
    }
  }

  static clearProductViewSettings = () => {
    if (ClassroomService.getCurrentClassroomId() === 'FROM_TEACHER_PRODUCTS_NAV') {
      classroomManager.setCurrentClassroomId(null);
    }
    courseManager.clearAll();
    curriculumMapManager.clearAll();
    productManager.clearAll();
    teacherProductViewManager.clearAll();
  }
}

registerClass('TeacherProductViewService', TeacherProductViewService);
