import React, { useMemo } from 'react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import ClassroomApi from '../../api/ClassroomApi';

import '../../css/AddTeacherToClassrooms.less';

const AddTeacherToClassroomsModal = (props) => {
  const CartModal = useMemo(() => SatCoreComponent('CartModal'), []);
  const CartModalClassrooms = useMemo(() => SatCoreComponent('CartModalClassrooms'), []);
  const CartModalClassroomsPopup = useMemo(() => SatCoreComponent('CartModalClassroomsPopup'), []);
  return (
    <CartModal
      {...props}
      cart={({ cart, ...rest }) => <CartModalClassroomsPopup {...rest} classrooms={cart} />}
      className='add-teacher-to-classrooms'
      equal={(item, otherItem) => item.classroomId === otherItem.classroomId}
      list={({ cart, ...rest }) => <CartModalClassrooms {...rest} addLabel='Join' addedLabel='Joined' classrooms={cart} />}
      offset={(empty) => [empty ? -55 : -140, 0]}
      onSave={async (classrooms) => await ClassroomApi.addCoTeacher(classrooms.map((classroom) => classroom.classroomId), props.userId)} />
  );
};

export default AddTeacherToClassroomsModal;

SatCoreRegister('AddTeacherToClassroomsModal', AddTeacherToClassroomsModal);
