import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import { Breadcrumb } from 'semantic-ui-react';

import '../css/BreadCrumb.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import NavigationService from '../services/NavigationService';
import UtilityService from '../services/UtilityService';

export default
@inject(
  'classroomManager', 'courseManager', 'navigationManager', 'userManager'
)
@observer
class BreadCrumbs extends Component {
  clickPath = async (path) => {
    const {
      classroomManager, courseManager, navigationManager, userManager
    } = this.props;
    const { isStudent, isDistrictAdmin, isSchoolAdmin, isProductAdmin } = userManager;
    const isAdmin = isDistrictAdmin || isSchoolAdmin || isProductAdmin;
    let adminUrlParams;
    if (isAdmin) {
      adminUrlParams = await navigationManager.getAdminUrlParams();
    }
    NavigationService.navigateToPath(
      path, this.props.history, courseManager,
      isStudent, classroomManager, isAdmin, adminUrlParams
    );
  }

  getKey = () => UtilityService.getKey()

  render() {
    const { breadcrumbClassName, navigationManager } = this.props;
    const { pathList } = navigationManager;
    return !!pathList?.length && (
      <Breadcrumb className={breadcrumbClassName} size='large'>
        {pathList.map((brc, index) => {
          if (brc && brc.name) {
            const brcNameObj = UtilityService.reactHtmlParserWrapper(
              brc.name
            );
            if (index === (pathList.length - 1)) {
              return (
                <span
                  key={`class_brc_${this.getKey()}`}
                  title={brcNameObj.stripped}>
                  <Breadcrumb.Section key={`class_brc_${this.getKey()}`} active>
                    {brcNameObj.parsed}
                  </Breadcrumb.Section>
                </span>
              );
            }
            return (
              <span
                key={`brc_${this.getKey()}`}
                title={brcNameObj.stripped}>
                <Fragment key={`brc_${this.getKey()}`}>
                  <Breadcrumb.Section
                    className='clickable'
                    link
                    onClick={() => {
                      this.clickPath(brc);
                    }}>
                    {brcNameObj.parsed}
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider icon='right chevron' />
                </Fragment>
              </span>
            );
          }
          // console.warn('BreadCrumb: brc.name not found');
        })}
      </Breadcrumb>
    );
  }
}

SatCoreRegister('BreadCrumbs', BreadCrumbs);
