import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';

import {
  Button, Dropdown, Image, Input, Popup, Search, Table
} from 'semantic-ui-react';

import { register } from '../i18n';

import iconFilter from '../img/filter.svg';
import iconFilterBlue from '../img/filter-blue.svg';
import iconInfo from '../img/icon-info.svg';

import '../css/FilteredHeaderTable.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

/**
  This component renders a semantic-ui table with sortable columns and per column filtering.
  This is a controlled component with the parent supplying most of the data and action functionality
  through passed in props.

  The parent component should register this table with the FilteredHeaderTableManager by calling:
  registerTable(tableId, activePage=0, column='', direction='asc', totalPages=0, filters=[]);

  tableId should be a unique key that can be used to pull data specific to this table and manage it's state.

  Component expects the following props to be passed from parent component:

  <FilteredHeaderTable
    tableId (optional) - This is the key for the table to use when registering the table in the FilteredHeaderTable manager.
    tableHeaderData (required) - This should be an array of header column objects.  See format below -
    tableBodyData (required) - This should be an array of body row objects.  See format below -
    autoCompleteSearchData (optional) - This should be an array of result objects for semantic-ui Search component {key, title}
    handleSort (optional) - This is a handler callback for handling column sorting -
    column (optional) - This is the current sort column sortKey value -
    direction (optional) - this is the current sort direction value -
    filtersData (optional) - data object for the filters
    handleFilter (optional) - This is the handler callback for handling column data filtering (you must set activePage to 1)
    showInfoForm (optional) - an object to control the open/close of the info popup based on the infoKey for each column header
    showInfoFormOpen (optional) - function for the parent to control when to open the info popup by changing the showInfoForm prop
    showInfoFormClose(optional) - function for the parent to control when to close the info popup by changing the showInfoForm prop
  />

  The format for header row column objects is as follows:
  {
    label: - Text label for the column header -
    sortKey: - The key to use to idenfity this column for sorting purposes, if set column header will sort on click -
    filterKey: - The key to use to idenfity this column for filtering purposes, if present filter icon will appear in col header -
    filterComparator: - An optional default comparator to use for the filter.
      By default it's set to 'eq' initially see this.comparatorFilterOptions() for all the values
    filterLabel: - An optional label to use to display a label over the filter controls.
    filterPopupType: - Sets which type of filter popup will render when filterKey is set.
      Default if not set is comparator dropdown with search term -
             Values: 'searchAutoComplete' renders a search box that searches as you type and shows results in a dropdown list
                     'optionSelection' renders a dropdown with options specified in the filterDropdownOptions param
                     'default: renders a dropdown with fixed comparator options and a search term input box.
    filterDropdownOptions: - If the filterPopupType includes a dropdown, this will provide the options collection to render.
      First value will be set as default
    filterAutoCompleteCallback: - Callback function for autocomplete of filter text, if any -
    infoKey: - The key to use for indentifying this column for launching info popup related to the column,
      if present an info icon will appear in col header -
    infoPopupContent: The content to be rendered inside of a semantic Popup component's "content" property for the column
    utilizeDateComparatorFilterOptions: - when `true`, use `this.dateComparatorFilterOptions` instead of `this.comparatorFilterOptions`
    columnCheckBoxOnClick: - If this is present, cell renders an SCCheckbox in the header cell and columnText is the label of the checkbox or none if empty/null,
                            the checkbox onChange is set to this callback function. Set only one of these (columnOnClick, columnButtonOnClick, columnCheckBoxOnClick)
    columnCheckBoxSelected: - If columnCheckBoxOnClick is set, this boolean property will control if the check box in the cell should be checked or not.
  }

  The format for body row objects
  {
    columnName: - column name: not currently used in the code for anything
    columnText: - Text to display in the column, or as button text for column button (see below) -
    columnClassName: - CSS classname to specifiy for the div that wraps the columnText -
    columnOnClick: - Click handler callback.  Set on the table Table.cell element. Set only one of these (columnOnClick, columnButtonOnClick, columnCheckBoxOnClick)
    columnButtonOnClick: - If this is present, cell renders a button and columnText is the text of the button,
                          and the button onClick is set to this callback function. Set only one of these (columnOnClick, columnButtonOnClick, columnCheckBoxOnClick)
    columnButtonBasic: button basic or not
    columnCheckBoxOnClick: - If this is present, cell renders an SCCheckbox in the body cell and columnText is the label of the checkbox or none if empty/null,
                          the checkbox onChange is set to this callback function. Set only one of these (columnOnClick, columnButtonOnClick, columnCheckBoxOnClick)
    columnCheckBoxSelected: - If columnCheckBoxOnClick is set, this boolean property will control if the check box in the cell should be checked or not.
    showTooltip: - true/false whether to render a semantic-ui Popup tooltip (see content text below)
    showTootipEvent: - 'click' or 'hover'.  Default if not set is 'hover'
    tooltipContent: - If set it allows you to specify the text or markup to show in the default cell tooltip content if you
    want it different than the columnText, otherwise it uses the columnText.
},

  Default column header filter
  ---------------------------------------------
  If you want the default column header filter behavior set the filter key on the header row column to the id key that the
  backend service will recognize for that data value.  Set null for "filterAutoCompleteCallback".  This will show a match operator
  dropdown and text entry box and will preform a filter based on the operator chosen and text value you entered.

  Auto Complete Search Header Filter
  ---------------------------------------------
  if you want the header filter to have an auto complete partial search type box, set the "filterAutoCompleteCallback" in the
  header row column (see above).  This should be a callback that accepts a string param of the partial name text and calls out
  to your service to get search results.

  Results should be passed to the component via the "autoCompleteSearchData" param (see above).  Results are in the format for
  the semantic-ui Search component.  Currently expects each object in the results array to have a "key" id value param,
  and "title" param for the display title that will show in the search results box.
  The `id` value will be passed back to the handleFilter just like any other filter.

  Styling:
  below are some of the .css classes defined in this component.  can be overridden by the parent if needed.
  -------------------
  .filteredHeaderTableContainer - className of div that wraps semantic-ui table.
    Some overrides of default header behavior are defined there.
  .filteredHeaderTable - className of the semantic-ui table tag, some general styling of the table is under this .css style.
  .filteredHeaderPopup - className of the semantic-ui Popup component used to show the header filter popups.
  .filterContainer = className of containing div that wraps the popup filter content
  .searchLabel - className of div that wraps optional label that appears at the top of the popup filter content
  .actions - className of div that wraps the buttons of the popup filter content
  .filterButton - className of filter action button of the popup filter content
  .cancelButton - className of the clear/cancel action button of the popup filter content
*/

const t = register('FilteredHeaderTable');

export default @inject('filteredHeaderTableManager')
@observer
class FilteredHeaderTable extends Component {
  comparatorFilterOptions = [
    {
      key: 'eq',
      text: t('eq'),
      value: 'eq'
    },
    {
      key: 'neq',
      text: t('neq'),
      value: 'neq'
    },
    {
      key: 'startswith',
      text: t('startswith'),
      value: 'startswith'
    },
    {
      key: 'contains',
      text: t('contains'),
      value: 'contains'
    },
    {
      key: 'doesnotcontain',
      text: t('doesnotcontain'),
      value: 'doesnotcontain'
    },
    {
      key: 'endswith',
      text: t('endswith'),
      value: 'endswith'
    }
  ];

  // Note: For dates, we want the backend (BE) to do a 'contains/doesnotcontain' comparison.
  // This is because the BE stores the classroom dates in ISO format, and
  // we will pass the 'date' part of an ISO date string to the BE (YYYY-MM-DD), but not the 'time' part.
  // ---
  // Although we will technically be doing a 'contains/doesnotcontain' search on the BE,
  // we want the user to enter an 'exact' date (e.g. 11/28/2023, 11-28-23, November 28 2023, etc).
  // ---
  // So for the text label, we will show the user 'Is equal to' or 'Is not equal to',
  // but technically we will be using 'contains' or 'doesnotcontain'
  dateComparatorFilterOptions = [
    {
      key: 'contains',
      text: 'Is equal to',
      value: 'contains'
    },
    {
      key: 'doesnotcontain',
      text: 'Is not equal to',
      value: 'doesnotcontain'
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      autoCompleteSearch: {
        loading: false,
        value: ''
      },
      showFilterForm: {}
    };
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
  }

  handleFilter = async () => {
    const { handleFilter, filtersData } = this.props;
    // call parent component to do filtering
    handleFilter(filtersData);
    this.showFilterFormClose();
  }

  /**
   * Remove filter having `filterKey` from `filtersData`.
   *
   * If `filterKey` is `'ALL_FILTERS'`, remove **all** filters from `filtersData`.
   */
  handleClear = async (filterKey) => {
    const { handleFilter, filtersData, tableId, filteredHeaderTableManager } = this.props;

    let filteredFilters = [];

    const shouldClearAllFilters = filterKey === 'ALL_FILTERS';

    filteredFilters = !shouldClearAllFilters ? filtersData.filter((filter) => {
      return filter.field !== filterKey;
    }) : [];
    if (filteredFilters?.length < 1) {
      filteredFilters = [];
    }
    if (shouldClearAllFilters) {
      filteredHeaderTableManager.setSortColumn(tableId, '');
      filteredHeaderTableManager.setSortDirection(tableId, 'asc');
    }
    filteredHeaderTableManager.setFilters(tableId, filteredFilters);
    this.setState({ autoCompleteSearch: { loading: false, value: '' } });
    // call parent component to re-load with updated filters
    handleFilter(filteredFilters, { filterKey });
    this.showFilterFormClose();
  }

  showFilterFormOpen = (filterKey, comparator) => {
    const { tableId, filtersData, filteredHeaderTableManager } = this.props;
    if (filtersData) {
      let filter = filtersData.find((filter) => filter.field === filterKey);
      if (!filter) {
        filter = {
          field: filterKey,
          operator: comparator || 'eq',
          value: ''
        };
        filtersData.push(filter);
        filteredHeaderTableManager.setFilters(tableId, filtersData);
      }
      this.setState({ showFilterForm: { filterKey } });
    }
  }

  findFilter = (filterKey) => {
    const { filtersData } = this.props;
    let filter = null;
    if (filtersData) {
      filter = filtersData.find((filter) => filter.field === filterKey);
    }
    return filter;
  }

  showFilterFormClose = () => {
    this.setState({ showFilterForm: {} });
  }

  getFilterSearchOperator = (filterKey) => {
    const filter = this.findFilter(filterKey);
    return filter ? filter.operator : '';
  }

  setFilterSearchOperator = (event, { name, value }) => {
    const { tableId, filtersData, filteredHeaderTableManager } = this.props;
    const filterKey = name.substring('dropdown-'.length);
    const filter = this.findFilter(filterKey);
    if (filter) {
      filter.operator = value;
    }
    const newFilters = filtersData.filter((filter) => filter.field !== filterKey);
    filteredHeaderTableManager.setFilters(tableId, [...newFilters, filter]);
  }

  getFilterSearchValue = (filterKey) => {
    const filter = this.findFilter(filterKey);
    return filter ? filter.value : '';
  }

  setFilterSearchValue = (event, { name, value }) => {
    const { tableId, filtersData, filteredHeaderTableManager } = this.props;
    const filterKey = name.substring('input-'.length);
    const filter = this.findFilter(filterKey);
    if (filter) {
      filter.value = value;
    }
    const existingValidFilters = filtersData.filter((filter) => filter.field !== filterKey && filter.value);
    filteredHeaderTableManager.setFilters(tableId, [...existingValidFilters, filter]);
  }

  updateAutoCompleteSearchSelection = (title, value, filterKey) => {
    const { tableId, filtersData, filteredHeaderTableManager } = this.props;
    const autoCompleteSearch = {
      loading: false,
      value: title // for search component, we use title
    };
    // create the new filter
    const filter = this.findFilter(filterKey);
    if (filter) {
      filter.value = value; // for filter we use the id which is the value passed here
    }
    const newFilters = filtersData.filter((filter) => filter.field !== filterKey);
    filteredHeaderTableManager.setFilters(tableId, [...newFilters, filter]);
    this.setState({ autoCompleteSearch });
  }

  onAutoCompleteSearchChange = (event, data) => {
    const { tableId, filtersData, filteredHeaderTableManager } = this.props;
    const autoCompleteSearch = {
      loading: true,
      value: event.target.value
    };

    if (!autoCompleteSearch.value) {
      // clear the filter if the value has been emptied
      const filter = this.findFilter(data.filterKey);
      if (filter) {
        filter.value = '';
      }
      const newFilters = filtersData.filter((filter) => filter.field !== data.filterKey);
      filteredHeaderTableManager.setFilters(tableId, [...newFilters, filter]);
      autoCompleteSearch.loading = false;
    }
    this.setState({ autoCompleteSearch });
    data.filterAutoCompleteCallback?.(event.target.value);
  };

  getHasFilter = (filterKey) => {
    const filter = this.findFilter(filterKey);
    if (filter && filter.value) {
      return true;
    }
    return false;
  }

  renderFilterPopup = (columnHeader) => {
    const hasFilter = this.getHasFilter(columnHeader.filterKey);
    return (
      <div className='filterContainer'>
        <Popup
          key={columnHeader.filterKey}
          className='filteredHeaderPopup'
          content={this.renderFilterPopupContent(columnHeader)}
          on='click'
          onClose={this.showFilterFormClose}
          onOpen={() => this.showFilterFormOpen(columnHeader.filterKey, columnHeader.filterComparator)}
          open={this.state.showFilterForm.filterKey === columnHeader.filterKey}
          position='bottom right'
          trigger={<Image alt='' src={hasFilter ? iconFilterBlue : iconFilter} />}
          hideOnScroll />
      </div>
    );
  };

  renderFilterPopupContent = (columnHeader) => {
    const { autoCompleteSearchData } = this.props;
    const operatorValue = this.getFilterSearchOperator(columnHeader.filterKey);
    let inputValue = this.getFilterSearchValue(columnHeader.filterKey);
    const searchResultsData = autoCompleteSearchData || [];

    switch (columnHeader.filterPopupType) {
    case 'searchAutoComplete':
      return (
        <div className='filterContainer'>
          {columnHeader.filterLabel &&
          <div className='searchLabel'>{columnHeader.filterLabel}</div>}
          <Search
            loading={this.state.autoCompleteSearch.loading && !inputValue}
            onResultSelect={(e, data) => this.updateAutoCompleteSearchSelection(data.result.title, data.result.key, columnHeader.filterKey)}
            onSearchChange={(event) => this.onAutoCompleteSearchChange(event, columnHeader)}
            results={searchResultsData}
            value={this.state.autoCompleteSearch.value} />
          <div className='actions'>
            <Button className='filterButton' disabled={!inputValue} onClick={() => this.handleFilter()} primary>Filter</Button>
            <Button basic className='cancelButton' onClick={() => this.handleClear(columnHeader.filterKey)}>Clear</Button>
          </div>
        </div>
      );
    case 'optionSelection':
      if (!inputValue) {
        if (columnHeader.filterDropdownOptions && columnHeader.filterDropdownOptions[0]) {
          inputValue = columnHeader.filterDropdownOptions[0].value;
        }
      }
      return (
        <div className='filterContainer'>
          {columnHeader.filterLabel &&
          <div className='searchLabel'>{columnHeader.filterLabel}</div>}
          <Dropdown
            name={`input-${columnHeader.filterKey}`}
            onChange={this.setFilterSearchValue}
            options={columnHeader.filterDropdownOptions ? columnHeader.filterDropdownOptions : []} // Dropdown render can throw a warning if options is undefined at first
            selection
            value={inputValue} />
          <div className='actions'>
            <Button className='filterButton' disabled={!inputValue} onClick={() => this.handleFilter()} primary>Filter</Button>
            <Button basic className='cancelButton' onClick={() => this.handleClear(columnHeader.filterKey)}>Clear</Button>
          </div>
        </div>
      );
    default:
      let comparatorFilterOptions;
      if (columnHeader.utilizeDateComparatorFilterOptions) {
        comparatorFilterOptions = this.dateComparatorFilterOptions;
      } else {
        comparatorFilterOptions = this.comparatorFilterOptions;
      }
      return (
        <div className='filterContainer'>
          {columnHeader.filterLabel &&
          <div className='searchLabel'>{columnHeader.filterLabel}</div>}
          <Dropdown
            name={`dropdown-${columnHeader.filterKey}`}
            onChange={this.setFilterSearchOperator}
            options={comparatorFilterOptions ? comparatorFilterOptions : []} // Dropdown render can throw a warning if options is undefined at first
            selection
            value={operatorValue} />
          <Input
            name={`input-${columnHeader.filterKey}`}
            onChange={this.setFilterSearchValue}
            placeholder='Search...'
            value={inputValue} />
          <div className='actions'>
            <Button className='filterButton' disabled={!inputValue} onClick={() => this.handleFilter()} primary>Filter</Button>
            <Button basic className='cancelButton' onClick={() => this.handleClear(columnHeader.filterKey)}>Clear</Button>
          </div>
        </div>
      );
    }
  }

  renderInfoPopup = (columnHeader) => {
    return (
      <div className='filterContainer'>
        <Popup
          key={columnHeader.filterKey}
          className='filteredHeaderPopup'
          content={columnHeader.infoPopupContent}
          hideOnScroll
          on='click'
          onClose={this.props.showInfoFormClose}
          onOpen={() => this.props.showInfoFormOpen(columnHeader.infoKey)}
          open={this.props.showInfoForm.infoKey === columnHeader.infoKey}
          position='bottom right'
          trigger={<Image alt='' className='leaf-info-icon' spaced src={iconInfo} />}
          wide />
      </div>
    );
  };

  render() {
    const {
      allowClearAllFilters, column, direction, filtersData,
      handleSort, tableBodyData, tableHeaderData
    } = this.props;
    const { SCCheckbox } = this;
    const filters = filtersData;

    return (
      <div className='filteredHeaderTableContainer'>
        {allowClearAllFilters && (
          <div className='reset-table-filters'
            onClick={() => this.handleClear('ALL_FILTERS')}>
            {t('resetTableFilters')}
          </div>
        )}
        {(tableHeaderData && tableHeaderData.length > 0) && (
          <Table className='filteredHeaderTable' sortable striped>
            <Table.Header>
              <Table.Row key='header'>
                {tableHeaderData.map((columnHeader, idx) => {
                  return !columnHeader.hide && (
                    <Table.HeaderCell
                      key={idx}
                      className={classNames(columnHeader.headerCellClassName, {
                        clickable: (columnHeader.sortKey && (columnHeader.filterKey || columnHeader.infoKey))
                      })}
                      id={columnHeader.sortKey || columnHeader.id || idx}
                      sorted={(columnHeader.sortKey && column && direction) && column === columnHeader.sortKey ? direction : null}>
                      <div className='filterable'>
                        {columnHeader.columnCheckBoxOnClick ? (
                          <SCCheckbox
                            label={columnHeader.columnText}
                            checked={columnHeader.columnCheckBoxSelected}
                            onChange={columnHeader.columnCheckBoxOnClick} />
                          ) : (
                            <>
                              {columnHeader.filterKey ? (
                                <>
                                  {columnHeader.sortKey ? (
                                    <div className='label'
                                      onClick={() => handleSort(columnHeader.sortKey, filters)}>{`${columnHeader.label || ''} `}
                                    </div>
                                  ) : (<div className='label'>{`${columnHeader.label || ''} `}</div>)}
                                  {columnHeader.infoKey &&
                                    this.renderInfoPopup(columnHeader)}
                                  {this.renderFilterPopup(columnHeader)}
                                </>
                              ) : (
                                <>
                                  {columnHeader.sortKey ? (
                                    <div className='label'
                                      onClick={() => handleSort(columnHeader.sortKey, filters)}>{`${columnHeader.label || ''} `}
                                    </div>
                                  ) : <div className='label'>{`${columnHeader.label || ''} `}</div>}
                                  {columnHeader.infoKey &&
                                    this.renderInfoPopup(columnHeader)}
                                </>
                              )}
                            </>
                          )}
                      </div>
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <>
                {tableBodyData && tableBodyData.map((rowData, rowIndex) => (
                  <Table.Row key={rowIndex}>
                    {rowData.map((columnData, index) => {
                      return !columnData.hide && (
                        <React.Fragment key={index}>
                          {columnData.columnCheckBoxOnClick && (
                            <Table.Cell
                              key={`cell-${index}`}
                              className='clickableButtonCell'
                              id={columnData.id || index}>
                              <SCCheckbox
                                label={columnData.columnText}
                                checked={columnData.columnCheckBoxSelected}
                                onChange={columnData.columnCheckBoxOnClick} />
                            </Table.Cell>
                          )}
                          {columnData.columnButtonOnClick && (
                            <Table.Cell
                              key={`cell-${index}`}
                              className='clickableButtonCell'
                              id={columnData.id || index}>
                              <Button basic={columnData.columnButtonBasic !== false} onClick={columnData.columnButtonOnClick} primary>
                                {columnData.columnText}
                              </Button>
                            </Table.Cell>
                          )}
                          {columnData.columnOnClick && (
                            <Table.Cell
                              key={`cell-${index}`}
                              className='clickableCell'
                              id={columnData.id || index}
                              onClick={columnData.columnOnClick}>
                              {columnData.columnText}
                            </Table.Cell>
                          )}
                          {(!columnData.columnOnClick && !columnData.columnButtonOnClick && !columnData.columnCheckBoxOnClick) && (
                            <Table.Cell
                              key={`cell-${index}`}
                              className='tableCell'
                              id={columnData.id || index}>
                              {columnData.showTooltip ? (
                                <Popup
                                  className='bodyCellTooltip'
                                  content={<div>{columnData.tooltipContent ? columnData.tooltipContent : columnData.columnText}</div>}
                                  on={columnData.showTootipEvent ? columnData.showTootipEvent : 'hover'}
                                  trigger={<div className={columnData.columnClassName}>{columnData.columnText}</div>}
                                  hideOnScroll />
                              ) : (
                                <div className={columnData.columnClassName}>{columnData.columnText}</div>
                              )}
                            </Table.Cell>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Table.Row>
                ))}
              </>
            </Table.Body>
          </Table>
        )}
      </div>
    );
  }
}

SatCoreRegister('FilteredHeaderTable', FilteredHeaderTable);
