import React, { useMemo } from 'react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import ClassroomApi from '../../api/ClassroomApi';

import '../../css/AddStudentToClassrooms.less';

const AddStudentToClassroomsModal = (props) => {
  const CartModal = useMemo(() => SatCoreComponent('CartModal'), []);
  const CartModalClassrooms = useMemo(() => SatCoreComponent('CartModalClassrooms'), []);
  const CartModalClassroomsPopup = useMemo(() => SatCoreComponent('CartModalClassroomsPopup'), []);
  return (
    <CartModal
      {...props}
      cart={({ cart, ...rest }) => <CartModalClassroomsPopup {...rest} classrooms={cart} />}
      className='add-student-to-classrooms'
      equal={(item, otherItem) => item.classroomId === otherItem.classroomId}
      list={({ cart, ...rest }) => <CartModalClassrooms {...rest} classrooms={cart} />}
      offset={(empty) => [empty ? -55 : -140, 0]}
      onSave={async (classrooms) => await ClassroomApi.addStudent(classrooms.map((classroom) => classroom.classroomId), props.userId)} />
  );
};

export default AddStudentToClassroomsModal;

SatCoreRegister('AddStudentToClassroomsModal', AddStudentToClassroomsModal);
