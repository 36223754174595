import { toJS } from 'mobx';

import classNames from 'classnames';

import assignmentManager, { ASSIGNMENT_STATUS } from '../managers/AssignmentManager';
import classroomManager from '../managers/ClassroomManager';
import courseManager from '../managers/CourseManager';
import exportManager from '../managers/ExportManager';
import gradebookManager from '../managers/GradebookManager';
import navigationManager, { VIEW_SELECTION } from '../managers/NavigationManager';
import productManager from '../managers/ProductManager';
import reportIdentityManager from '../managers/reports/ReportIdentityManager';
import userManager from '../managers/UserManager';

import UtilityService from './UtilityService';

const ESSENTIALLY_ALL_TABLE_ROWS = 9999;

export const SCORE_STATE = {
  AUTO_SCORED: 'AutoScored',
  MANUALLY_OVERRIDEN: 'ManuallyOverridden',
  MANUALLY_SCORED: 'ManuallyScored',
  NOT_SCORED: 'NotScored'
};

export const RESPONSE_CONTENT_STATE = {
  HAS_RESPONSE_CONTENT: 'HasResponseContent',
  NO_RESPONSE_CONTENT: 'NoResponseContent',
};

export const RESPONSE_SUBMITTED_STATE = {
  RESPONSE_SUBMITTED: 'ResponseSubmitted',
  RESPONSE_NOT_SUBMITTED: 'ResponseNotSubmitted',
};

export default class GradebookService {
  /* -------- GENERAL (summary and/or details) -------- */
  /** Return `true | false` based on whether Gradebook should be 'read only' or not. */
  static isReadOnly = () => {
    return userManager.isDistrictOrSchoolAdmin || productManager.shouldShowExpiredLicenseWarning;
  }

  static requestGradebookExport = async (
    reportId, emailAddress, assignment, assignmentId, hideUnscorable = null, {
      isAssignmentsFromRemovedCourses = false,
      isExportForReportTableRowResource = false,
      reportTableRow = undefined
    } = {}
  ) => {
    if (isExportForReportTableRowResource) {
      return this.requestReportTableRowGradebookExport({
        emailAddress,
        hideUnscorable,
        reportId,
        reportTableRow
      });
    } else if (gradebookManager.activeGradebookType === 'aggregate') {
      const parameterKeysArray = [
        'classroomId',
        'contentItemId',
      ];
      if (typeof hideUnscorable === 'boolean') {
        parameterKeysArray.push('hideUnscorable');
      }
      if (!isAssignmentsFromRemovedCourses) {
        parameterKeysArray.push('assignmentId');
        parameterKeysArray.push('courseContentItemId');
      }
      const { classroomId, contentItemId, courseContentItemId } = assignment;
      let body = {
        classroomId: classroomId || assignment.domainId,
        contentItemId,
        emailAddress,
        parameterKeysArray,
        reportId
      };

      if (typeof hideUnscorable === 'boolean') {
        body = { ...body, hideUnscorable };
      }
      if (!isAssignmentsFromRemovedCourses) {
        body = { ...body, assignmentId, courseContentItemId };
      }

      return exportManager.requestExport(body);
    }
  }

  static requestReportTableRowGradebookExport = async ({
    hideUnscorable,
    emailAddress,
    reportId,
    reportTableRow
  } = {}) => {
    const reportFacultyIdKey = reportIdentityManager.isReport ? `${reportIdentityManager.activeReportFacultyType}Id` : undefined;

    const parameterKeysArray = [
      reportFacultyIdKey, // e.g. 'schoolId'
      'contentItemId',
      'compositeId',
      'courseContentItemId',
      'duplicateKey'
    ];
    if (typeof hideUnscorable === 'boolean') {
      parameterKeysArray.push('hideUnscorable');
    }

    const contentItemId = reportTableRow?.original?.elementContentItemId;
    const courseContentItemId = reportTableRow?.original?.courseContentItemId;

    const institutionId = reportIdentityManager.activeReportInstitutionId;

    const compositeId = institutionId + contentItemId;

    let body = {
      compositeId,
      contentItemId,
      courseContentItemId,
      duplicateKey: compositeId,
      emailAddress,
      parameterKeysArray,
      [reportFacultyIdKey]: institutionId,
      reportId
    };
    if (typeof hideUnscorable === 'boolean') {
      body = { ...body, hideUnscorable };
    }
    return exportManager.requestExport(body);
  };

  /* --------------------- SUMMARY -------------------- */
  static navToAggregateGradebookSummaryFromExternalModal = async (props, activeGradebookTable = 'summary') => {
    let { assignment = null } = props;
    const {
      allAssignmentsLabel,
      assignCardData = null,
      currentClassroomId = null,
      fromAssignments = false,
      history,
      urlParamStr = ''
    } = props;

    const urlParams = new URLSearchParams(window.location.search);
    const classroomId = urlParams.get('classroomId') || currentClassroomId ||
      classroomManager.currentClassroomId;
    if (!assignCardData && !assignment) {
      // eslint-disable-next-line prefer-destructuring
      assignment = assignmentManager.assignmentArray[0];
    }
    let assignmentId;
    if (assignment && !history.location.pathname.includes('class-detail-students-standards')) {
      assignmentId = assignment.id;
    } else {
      const { currentCourseElementList } = courseManager;
      let courseElement = null;
      if (assignCardData) {
        courseElement = {
          classroomId,
          contentItemId: assignCardData.contentItemId || assignCardData.resourceContentItemId,
          courseContentItemId: assignCardData.courseContentItemId ||
            (assignCardData.currentCourseId !== 'ALL_COURSES' ? assignCardData.currentCourseId : null),
          elementId: assignCardData.elementId || assignCardData.courseResourceElementId
        };
      } else if (currentCourseElementList && currentCourseElementList[0]) {
        // eslint-disable-next-line prefer-destructuring
        courseElement = currentCourseElementList[0];
      }
      if (courseElement) {
        const { courseContentItemId, elementId, contentItemId } = courseElement;
        const page = 0, dialogOpen = false, returnImmediatelyAfterResponse = true;
        const assignments = await assignmentManager.fetchContentAssignments(
          courseContentItemId, elementId, classroomId,
          contentItemId, page, dialogOpen, returnImmediatelyAfterResponse
        );
        if (assignments && assignments[0]) {
          assignmentId = assignments[0].id;
        }
      }
    }
    gradebookManager.setActiveGradebookTypeOnGradebookComponentMount('aggregate');
    // Set the default Active Table unless one was passed in; right now only looking for engagement
    if (activeGradebookTable && activeGradebookTable === 'engagement') {
      gradebookManager.setActiveGradebookTableOnGradebookComponentMount(activeGradebookTable);
    } else {
      gradebookManager.setActiveGradebookTableOnGradebookComponentMount('summary');
    }

    let routerUrl = `/gradebook?assignmentId=${assignmentId}&view=${VIEW_SELECTION.GRADEBOOK}`;
    routerUrl += !urlParamStr?.includes('classroomId') ? `&classroomId=${classroomId}` : '';

    routerUrl += urlParamStr ? urlParamStr?.replace('?', '&') : '';

    routerUrl += fromAssignments ? `&fromAssignments=${fromAssignments}` : '';

    if (allAssignmentsLabel) {
      await navigationManager.setBreadcrumb({
        fromView: VIEW_SELECTION.GRADEBOOK,
        path: {
          name: allAssignmentsLabel,
          routerUrl: `${routerUrl}${urlParamStr}`
        }
      });
    }
    history.push(routerUrl);
  }

  static isSpecialAggregateGradebookSummarySortCase = (clickedColumn) => clickedColumn === 'status' || clickedColumn === 'studentStatus' || clickedColumn === 'grade'
  /**
   * Intended to be used as a special case to sort a given
   * `clickedColumn` in the aggregate gradebook **summary** table.
   *
   * Uses backend to fetch the dataset, but the data is ultimately sorted on the frontend
   */
  static handleSpecialAggregateGradebookSummarySortCase = async (
    clickedColumn
  ) => {
    const pageSize = ESSENTIALLY_ALL_TABLE_ROWS;

    const rowsCurrentLength = gradebookManager.assignmentInstances.length;
    const rowsTotalLength = gradebookManager.gradebookTableRowsTotalFromBackend || 0;

    if (!rowsTotalLength || rowsCurrentLength < rowsTotalLength) {
      // we do not have the entire dataset stored, so we need to fetch it
      await this.handleGradebookSummarySortOnBackend(clickedColumn, pageSize);
    }
    await this.handleGradebookSummarySortOnFrontend(clickedColumn);
  }

  static handleGradebookSummarySortOnBackend = async (clickedColumn, pageSize) => {
    pageSize = pageSize >= 0 ? pageSize : gradebookManager.GRADEBOOK_TABLE_ROWS_PAGE_SIZE;
    const column = gradebookManager.gradebookSummaryTableSortColumn;
    const direction = gradebookManager.gradebookSummaryTableSortDirection;

    let newDirection;
    if (!clickedColumn) {
      clickedColumn = column;
      newDirection = direction;
    } else {
      if (column !== clickedColumn || direction === 'descending') {
        newDirection = 'ascending';
      } else {
        newDirection = 'descending';
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const assignmentId = urlParams.get('assignmentId');
    const assignment = await assignmentManager.fetchActivity(assignmentId);

    const page = 0, clearFirst = true;
    const functions = {
      isSpecialAggregateGradebookSummarySortCase: this.isSpecialAggregateGradebookSummarySortCase
    };
    await gradebookManager.fetchAggregateGradebookData(
      assignment, clickedColumn, newDirection, page,
      pageSize, clearFirst, functions
    );
  }

  static handleGradebookSummarySortOnFrontend = async (clickedColumn) => {
    const column = gradebookManager.gradebookSummaryTableSortColumn;
    const direction = gradebookManager.gradebookSummaryTableSortDirection;

    if (!column && !clickedColumn) {
      return;
    }

    let newDirection;
    if (!clickedColumn) {
      clickedColumn = column;
      newDirection = direction;
    } else {
      if (column !== clickedColumn || direction === 'descending') {
        newDirection = 'ascending';
      } else {
        newDirection = 'descending';
      }
    }

    let instances = toJS(gradebookManager.assignmentInstances);
    let sortingNumbers = false;

    if (clickedColumn === 'status' || clickedColumn === 'studentStatus') { // sort by STATUS
      /* sort instances in alphabetical order, based on each possible `status` label shown in table */
      const arr = [...instances];

      /**
       * This helper function tells us if the assignment status is `'closed' || 'completed'`
       *
       * If true, any instances of this assignment will only have the Status label `'Submitted'` or `'Not Submitted'`
       * in the gradebook summary table, based on the individual status of a given instance.
       *
       * So this allows us (outside of this function) to decide what status array we should put
       * a given instance in before merging all the status arrays (so they appear sorted in the table).
       *
       * i.e. if this function returns `true`, we can then do a subsequent check
       * (outside of this function) on `instance.status` to see what the status label should be.
       *
       * Outside of this function, if `instance.status` is `'closed' || 'completed'`,
       * we can then assume the label should be `'Submitted'`. Otherwise, the label should be `'Not Submitted'`
       */
      // eslint-disable-next-line no-inner-declarations
      function statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment) {
        return assignment.status === ASSIGNMENT_STATUS.CLOSED || assignment.status === ASSIGNMENT_STATUS.COMPLETED;
      }

      /* SORT BY STATUS: COMPLETED */
      const completed = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return false;
        }
        return instance.status === ASSIGNMENT_STATUS.COMPLETED;
      });

      /* SORT BY STATUS: LOCKED */
      const locked = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return false;
        }
        return instance.status === ASSIGNMENT_STATUS.LOCKED;
      });

      /* SORT BY STATUS: NOT STARTED */
      const notStarted = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return false;
        }
        return instance.status === ASSIGNMENT_STATUS.READY;
      });

      /* SORT BY STATUS: NOT SUBMITTED */
      const notSubmitted = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return !instance.submitted;
        }
      });

      /* SORT BY STATUS: PRESENTATION (edge case, will likely never happen) */
      const presentation = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return false;
        }
        return instance.status === ASSIGNMENT_STATUS.PRESENTATION;
      });

      /* SORT BY STATUS: PREVIEW (edge case, will likely never happen) */
      const preview = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return false;
        }
        return instance.status === ASSIGNMENT_STATUS.PREVIEW;
      });

      /* SORT BY STATUS: STARTED */
      const started = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return false;
        }
        return instance.status === ASSIGNMENT_STATUS.STARTED;
      });

      /* SORT BY STATUS: SUBMITTED */
      const submitted = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return instance.submitted;
        }
        return instance.status === ASSIGNMENT_STATUS.CLOSED;
      });

      instances = [...completed, ...locked, ...notStarted, ...notSubmitted, ...presentation, ...preview, ...started, ...submitted];
      if (newDirection === 'descending') {
        instances = [...instances].reverse();
      }
    } else if (clickedColumn === 'grade') { // sort by GRADE
      /* ensure all unsubmitted grades will be last in the list */
      sortingNumbers = true;
      let submittedInstances = instances.filter((instance) => instance.submitted);
      submittedInstances = UtilityService.sortColumn(submittedInstances, newDirection, clickedColumn, sortingNumbers);
      const unsubmittedInstances = instances.filter((instance) => !instance.submitted);
      instances = [...submittedInstances, ...unsubmittedInstances];
    } else {
      instances = UtilityService.sortColumn(instances, newDirection, clickedColumn, sortingNumbers);
    }
    gradebookManager.clearAssignmentInstances();
    gradebookManager.setAssignmentInstances(instances);

    gradebookManager.setGradebookSummaryTableSortColumn(clickedColumn);
    gradebookManager.setGradebookSummaryTableSortDirection(newDirection);
  }

  /* --------------------- DETAILS --------------------- */
  static isSpecialAggregateGradebookDetailsSortCase = (clickedColumn, headerScoreIndex = -1) => (
    clickedColumn === 'status' ||
    clickedColumn === 'studentStatus' ||
      clickedColumn === 'grade' ||
      (clickedColumn && clickedColumn.includes('Slide')) ||
      headerScoreIndex >= 0
  )

  /**
   * Intended to be used as a special case to sort a given
   * `clickedColumn` in the aggregate gradebook **details** table.
   *
   * Uses backend to fetch the dataset, but the data is ultimately sorted on the frontend
   */
  static handleSpecialAggregateGradebookDetailsSortCase = async (
    clickedColumn, headerScoreIndex = -1
  ) => {
    const pageSize = ESSENTIALLY_ALL_TABLE_ROWS;

    const rowsCurrentLength = gradebookManager.gradebookDetails
      && gradebookManager.gradebookDetails.students
      && gradebookManager.gradebookDetails.students.length || 0;
    const rowsTotalLength = gradebookManager.gradebookTableRowsTotalFromBackend || 0;

    if (!rowsTotalLength || rowsCurrentLength < rowsTotalLength) {
      // we do not have the entire dataset stored, so we need to fetch it
      await this.handleGradebookDetailsSortOnBackend(clickedColumn, pageSize);
    }
    await this.handleGradebookDetailsSortOnFrontend(clickedColumn, headerScoreIndex);
  }

  static handleGradebookDetailsSortOnBackend = async (
    clickedColumn, pageSize
  ) => {
    pageSize = pageSize >= 0 ? pageSize : gradebookManager.GRADEBOOK_TABLE_ROWS_PAGE_SIZE;
    const column = gradebookManager.gradebookDetailsTableSortColumn;
    const direction = gradebookManager.gradebookDetailsTableSortDirection;

    let newDirection;
    if (!clickedColumn) {
      clickedColumn = column;
      newDirection = direction;
    } else {
      if (column !== clickedColumn || direction === 'descending') {
        newDirection = 'ascending';
      } else {
        newDirection = 'descending';
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const assignmentId = urlParams.get('assignmentId');
    const assignment = await assignmentManager.fetchActivity(assignmentId);

    const page = 0, clearStudentsFirst = true, clearAllFirst = true;
    const functions = {
      isSpecialAggregateGradebookDetailsSortCase: this.isSpecialAggregateGradebookDetailsSortCase
    };
    await gradebookManager.fetchAggregateGradebookDetails(
      assignment, clickedColumn, newDirection, page,
      pageSize, clearStudentsFirst, clearAllFirst, functions
    );
  }

  static handleGradebookDetailsSortOnFrontend = async (
    clickedColumn, headerScoreIndex = -1
  ) => {
    gradebookManager.setGradebookDetailsTableSortTrueHeaderScoreIndex(headerScoreIndex);

    const column = gradebookManager.gradebookDetailsTableSortColumn;
    const direction = gradebookManager.gradebookDetailsTableSortDirection;

    let newDirection;
    if (!clickedColumn) {
      clickedColumn = column;
      newDirection = direction;
    } else {
      if (column !== clickedColumn || direction === 'descending') {
        newDirection = 'ascending';
      } else {
        newDirection = 'descending';
      }
    }

    const { activityId, classSummary, headerInfo, maxScores } = gradebookManager.gradebookDetails;
    const clearStudentsFirst = true, clearAllFirst = true;

    let students = toJS(gradebookManager.gradebookDetails.students);
    let sortingNumbers = false;

    if (headerScoreIndex >= 0) { // sort by CELL SCORE
      sortingNumbers = true;

      gradebookManager.setGradebookDetailsTableSortColumn(clickedColumn);
      gradebookManager.setGradebookDetailsTableSortDirection(newDirection);

      const path = `scores.${headerScoreIndex}`;
      students = UtilityService.sortColumn(students, newDirection, path, sortingNumbers);
      gradebookManager.setGradebookDetails({
        activityId, classSummary, headerInfo, maxScores, students
      }, clearStudentsFirst, clearAllFirst);
    } else if (clickedColumn === 'grade') { // sort by GRADE
      /* ensure all unsubmitted grades will be last in the list */
      sortingNumbers = true;
      let submittedStudents = students.filter((student) => {
        if (gradebookManager.activeGradebookType === 'aggregate' && typeof student.submitted === 'boolean') {
          return student.submitted;
        }
        const currentInstance = gradebookManager.assignmentInstances.find((instance) => instance.userId === student.studentId);
        return currentInstance ? currentInstance.submitted : false;
      });
      submittedStudents = UtilityService.sortColumn(submittedStudents, newDirection, clickedColumn, sortingNumbers);

      const unsubmittedStudents = students.filter((student) => {
        if (gradebookManager.activeGradebookType === 'aggregate' && typeof student.submitted === 'boolean') {
          return !student.submitted;
        }
        const currentInstance = gradebookManager.assignmentInstances.find((instance) => instance.userId === student.studentId);
        return currentInstance ? !currentInstance.submitted : true;
      });
      students = [...submittedStudents, ...unsubmittedStudents];

      gradebookManager.setGradebookDetailsTableSortColumn(clickedColumn);
      gradebookManager.setGradebookDetailsTableSortDirection(newDirection);

      gradebookManager.setGradebookDetails({
        activityId, classSummary, headerInfo, maxScores, students
      }, clearStudentsFirst, clearAllFirst);
    } else {
      students = UtilityService.sortColumn(students, newDirection, clickedColumn, sortingNumbers);

      gradebookManager.setGradebookDetailsTableSortColumn(clickedColumn);
      gradebookManager.setGradebookDetailsTableSortDirection(newDirection);

      gradebookManager.setGradebookDetails({
        activityId, classSummary, headerInfo, maxScores, students
      }, clearStudentsFirst, clearAllFirst);
    }
  }

  static getScoreCellCreditClassName = (score, maxScore, isScorable = true) => {
    const allowAccessibilityTriangle = gradebookManager.allowFacultyGradebookAccessibility;
    if (typeof score === 'number' && isScorable) {
      if (!score) {
        return classNames('no-credit');
      } else if (maxScore && score < maxScore) {
        return classNames('partial-credit', {
          'accessibility-triangle': allowAccessibilityTriangle
        });
      }
      return classNames('full-credit', {
        'accessibility-triangle': allowAccessibilityTriangle
      });
    }
    return isScorable ? 'scorable-credit' : '';
  }

  static getPaginatedGradebookDetailsHeaderInfo = () => {
    const PAGE_SIZE = gradebookManager.GRADEBOOK_DETAILS_TABLE_HORIZONTAL_SCORES_PAGE_SIZE;
    const activePage = gradebookManager.activeGradebookDetailsHorizontalScoresPage;
    let paginatedHeaderInfo = gradebookManager.gradebookDetails.headerInfo;
    const start = (activePage - 1) * PAGE_SIZE;
    const end = activePage * PAGE_SIZE;
    paginatedHeaderInfo = paginatedHeaderInfo.slice(start, end);
    return paginatedHeaderInfo;
  }

  static getPaginatedGradebookDetailsMaxScores = () => {
    const PAGE_SIZE = gradebookManager.GRADEBOOK_DETAILS_TABLE_HORIZONTAL_SCORES_PAGE_SIZE;
    const activePage = gradebookManager.activeGradebookDetailsHorizontalScoresPage;
    let paginatedMaxScores = gradebookManager.gradebookDetails.maxScores;
    const start = (activePage - 1) * PAGE_SIZE;
    const end = activePage * PAGE_SIZE;
    paginatedMaxScores = paginatedMaxScores.slice(start, end);
    return paginatedMaxScores;
  }

  static getPaginatedGradebookDetailsStudentMaxScores = (student) => {
    const PAGE_SIZE = gradebookManager.GRADEBOOK_DETAILS_TABLE_HORIZONTAL_SCORES_PAGE_SIZE;
    const activePage = gradebookManager.activeGradebookDetailsHorizontalScoresPage;
    let paginatedStudentMaxScores = [...student.maxScores];
    const start = (activePage - 1) * PAGE_SIZE;
    const end = activePage * PAGE_SIZE;
    paginatedStudentMaxScores = paginatedStudentMaxScores.slice(start, end);
    return paginatedStudentMaxScores;
  }

  static getPaginatedGradebookDetailsClassAverageScores = () => {
    const PAGE_SIZE = gradebookManager.GRADEBOOK_DETAILS_TABLE_HORIZONTAL_SCORES_PAGE_SIZE;
    const activePage = gradebookManager.activeGradebookDetailsHorizontalScoresPage;
    let paginatedClassAverageScores = [...gradebookManager.gradebookDetails.classSummary.scores];
    const start = (activePage - 1) * PAGE_SIZE;
    const end = activePage * PAGE_SIZE;
    paginatedClassAverageScores = paginatedClassAverageScores.slice(start, end);
    return paginatedClassAverageScores;
  }

  static getPaginatedGradebookDetailsStudentScores = (student) => {
    const PAGE_SIZE = gradebookManager.GRADEBOOK_DETAILS_TABLE_HORIZONTAL_SCORES_PAGE_SIZE;
    const activePage = gradebookManager.activeGradebookDetailsHorizontalScoresPage;
    let paginatedStudentScores = [...student.scores];
    const start = (activePage - 1) * PAGE_SIZE;
    const end = activePage * PAGE_SIZE;
    paginatedStudentScores = paginatedStudentScores.slice(start, end);
    return paginatedStudentScores;
  }
}
