import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ReactHtmlParser from 'react-html-parser';

import { Button, Loader } from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/LearnosityScoringModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { VIEW_SELECTION } from '../../managers/NavigationManager';

import UtilityService from '../../services/UtilityService';

import { setSessionStorageItem } from '../../utils/session';

export default
@inject('learnosityItemsManager', 'assignmentManager', 'userManager', 'learnosityDataManager', 'gradebookManager')
@observer
class LearnosityScoringModal extends Component {
  itemsApp;
  totalPoints = 0;
  totalMaxPoints = 0;

  constructor(props) {
    super(props);
    this.state = {
      addInfoOpen: false,
      cancelStart: false,
      errorMessage: '',
      isItemsAPILoaded: false,
      isSaving: false,
      questionData: null,
      responseData: {},
      showErrorDialog: false,
      unattemptedResponseIds: [],
      updatedResponses: [],
    };
    this.InfoModal = SatCoreComponent('InfoModal');
    this.ErrorModal = SatCoreComponent('ErrorModal');
  }

  async componentDidMount() {
    const { learnosityDataManager, activityInstanceId } = this.props;
    if (!document.getElementById('learnosity-script') ||
      !document.getElementById('learnosity-script').attributes.src.value.includes('items.learnosity')) {
      const script = document.createElement('script');
      script.src = 'https://items.learnosity.com/?v2022.1.LTS';
      script.async = true;
      document.body.appendChild(script);
    }

    learnosityDataManager.getLearnosityDataApiResponseScores(activityInstanceId).then((data) => {
      if (data && data.status && data.status === 'SUCCESS') {
        if (data.sessionsScores && data.questions) {
          const responseJSON = JSON.parse(data.sessionsScores);
          const questionData = data.questions;
          learnosityDataManager.setResponseJSON(responseJSON);
          learnosityDataManager.setQuestionData(questionData);
          this.setState({ responseData: responseJSON.responses, questionData });
        }
      } else {
        if (data && data.statusMessage) {
          this.openWarningModal(data.statusMessage);
          console.error(`No question data found for assignment: ${activityInstanceId}, message: ${data.statusMessage}`); // TODO do we have a standard notification for this?
        } else {
          this.openWarningModal('An unexpected error has occurred; please try again.');
          console.error(`No question data found for assignment: ${activityInstanceId}`); // TODO do we have a standard notification for this?
        }
        this.setState({ responseData: [], questionData: [] });
      }
    }).catch((err) => {
      console.error(err);
    });
  }

  async startLoadActivity() {
    // load the activity
    // poll/wait for Learnosity javascript to load
    if (!this.state.isItemsAPILoaded) {
      const interval = await setInterval(async () => {
        if (window.LearnosityItems && !this.state.cancelStart) {
          // stop polling
          clearInterval(interval);
          // render the items api
          await this.loadActivity();
        }
      }, 1000);
    }
  }

  async componentDidUpdate() {
    // if this is the first time, load the activity
    if (this.state.questionData && this.state.questionData.length > 0) {
      this.startLoadActivity();
    }
  }

  // READY listener called when Learnosity API is ready
  readyListener = () => {
    // LEARNOSITY IS INITIALIZED BIND EVENTS HERE.
    this.setState({ isItemsAPILoaded: true });
  };

  // ERROR Listener to capture errors thrown from the API
  errorListener = (e) => {
    console.error('LearnosityScoringModal: learnosity error', e);
  };

  eventOptions = {
    readyListener: this.readyListener,
    errorListener: this.errorListener
  };

  async closeItemsApp() {
    await this.clearItemsApp();
    this.props.closeModalCallback();
  }

  async clearItemsApp() {
    // reset the items app.  But, we may be using annotations api so check that as well first.
    if (this.itemsApp != null) {
      if (this.itemsApp.annotationsApp()) {
        this.itemsApp.annotationsApp().reset();
      }
      this.itemsApp.reset();
    }
  }

  // adds a css stylesheet to the dom
  setLearnosityStylesheet(learnosityStylesheet) {
    if (learnosityStylesheet) {
      // if a link exists, delete it.
      const existingStylesheet = document.querySelector('#learnosity-stylesheet');
      if (existingStylesheet) {
        existingStylesheet.parentNode.removeChild(existingStylesheet);
      }
      // create the link
      const link = document.createElement('link');
      link.href = learnosityStylesheet;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.id = 'learnosity-stylesheet';
      document.body.appendChild(link);
    }
  }

  async loadActivity() {
    const { learnosityItemsManager, activityInstanceId } = this.props;
    learnosityItemsManager.getLearnosityItemsApiInlineReviewRequest(activityInstanceId, true).then((requestJson) => {
      // set the stylesheet
      this.setLearnosityStylesheet(requestJson.stylesheet);
      // Initialize the items api
      this.itemsApp = window.LearnosityItems.init(JSON.parse(requestJson.init), '#l-scoring-card-outer-container', this.eventOptions);
    }).catch((err) => {
      this.openWarningModal(err);
      this.setState({ cancelStart: true });
    });
  }

  updateQuestionScore = (event) => {
    if (event.target && event.target.value) {
      if (isNaN(event.target.value)) {
        event.target.value = 0;
        event.preventDefault();
      } else {
        const responseId = event.target.getAttribute('data-response-id');
        if (responseId) {
          // get the response id
          const score = event.target.value;
          // get the real response so we can check max_score against the value
          const response = this.state.responseData.find((element) => element.response_id == responseId);
          if (response) {
            if (response.max_score >= score && score >= 0) {
              // Check if this response is already in state
              const responseIndex = this.state.updatedResponses.findIndex((element) => element.responseId == responseId);
              // if not, create a new one and add it
              if (responseIndex < 0) {
                const responseData = {
                  score,
                  responseId
                };
                this.setState({ updatedResponses: [...this.state.updatedResponses, responseData] });
              } else {
                // otherwise, just update the score of the existing one
                const newResponse = [...this.state.updatedResponses];
                newResponse[responseIndex] = { ...newResponse[responseIndex], score };
                this.setState({ updatedResponses: newResponse });
              }
            } else {
              // ('Score entered must be between zero and max score.');
              event.target.value = 0;
            }
          }
        }
      }
    }
  }

  handleOpenInfo = () => {
    this.setState({ addInfoOpen: true });
  }

  closeInfoModal = () => {
    this.setState({ addInfoOpen: false });
  }

  openWarningModal = (message) => {
    this.setState({ showErrorDialog: true, errorMessage: message });
  }

  closeWarningModal = () => {
    this.setState({ showErrorDialog: false, errorMessage: '' });
  }

  renderStudentInstructionButton = () => {
    const { instruction, isTeacher } = this.props;
    if (instruction && instruction !== '<p></p>') {
      return (
        <Button
          className={(!isTeacher ? 'basic student-instruction white' : 'basic white')}
          onClick={() => this.handleOpenInfo()}
          primary>
          Student Instructions
        </Button>
      );
    }
    return null;
  }

  // this creates a scoring control for injecting into learnosity dom for teacher
  renderLearnosityScoringControl = (itemRefId, responses) => {
    const components = [];
    if (responses) {
      for (let i = 0; i < responses.length; i++) {
        const response = responses[i];
        const score = Number.isInteger(response.score) ? Number(response.score) : Number(response.score).toFixed(2);
        const maxScore = Number.isInteger(response.max_score) ? Number(response.max_score) : Number(response.max_score).toFixed(2);
        this.totalPoints = Number(this.totalPoints) + Number(score);
        this.totalMaxPoints = Number(this.totalMaxPoints) + Number(maxScore);
        console.log(`maxScore: ${maxScore} totalMaxPoints: ${this.totalMaxPoints}`);
        // set the score container height to the height of the question to align them.
        const questionElem = document.getElementById(response.response_id);
        const questionContentHeight = (questionElem) ? document.getElementById(response.response_id).clientHeight : null;
        if (questionContentHeight) {
          components.push(
            <div
              key={`custom-score-col-${response.question_reference}`}
              className='l-scoring-control-outer-container'
              style={{ height: questionContentHeight }}>
              <div className='l-scoring-control-background'>
                {this.props.isTeacher && this.calcFacultyControlPanelState(maxScore) === 'SCORE' && (
                <div className='l-scoring-control-container'>
                  <div className='scoreText'>Score (Points)</div>
                  <div>
                    <span>
                      <input
                        className='score-entry-box'
                        data-response-id={response.response_id}
                        defaultValue={score}
                        disabled={!this.props.canManageScores}
                        id={`input-${response.response_id}`}
                        onChange={() => this.updateQuestionScore(event)}
                        type='text' />
                    </span>
                    <span>
                      /&nbsp;
                      {maxScore}
                    </span>
                  </div>
                </div>
                )}
                {this.props.isTeacher && this.calcFacultyControlPanelState(maxScore) === 'NO_SCORE' && (
                <div className='l-scoring-control-container'>
                  <div className='scoreText'>Score (Points)</div>
                  <div className='no-score-message'>
                    No score required for zero max score.
                  </div>
                </div>
                )}
                {this.props.isTeacher && this.calcFacultyControlPanelState(maxScore) === 'NONE' && (
                <div className='l-scoring-control-container'>
                  <div className='scoreText'>Score (Points)</div>
                  <div className='null-score-message'>
                    No score required for practice.
                  </div>
                </div>
                )}
                {!this.props.isTeacher && (
                  <div className='l-viewing-control-container'>
                    <div className='scoreText'>Score (Points)</div>
                    <div>
                      {this.calcFacultyControlPanelState(maxScore) === 'SCORE' && (
                        <div>
                          <span>
                            {score}
                            &nbsp;/&nbsp;
                            {maxScore}
                          </span>
                        </div>
                      )}
                      {this.calcFacultyControlPanelState(maxScore) !== 'SCORE' && (
                        <div className='un-scored-message'>
                          <span>This question is unscored.</span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        }
      }
      return components;
    }
  }

  // create a card for each item/response to render learnosity
  renderScoringCards = () => {
    const { responseData } = this.state;
    const questionRefs = this.state.questionData;
    const components = [];
    if (questionRefs != null) {
      if (questionRefs.length > 0) {
        let questionIndex = 1;
        for (let i = 0; i < questionRefs.length; i++) {
          // questions can have objects or strings for references
          const questionRef = questionRefs[i];
          const itemRefId = (typeof questionRef === 'object') ? questionRef.reference : questionRef;
          const itemResponses = responseData.filter((element) => element.item_reference === itemRefId);
          components.push(
            <div key={itemRefId} className='l-scoring-card' id={`scoring-card-${itemRefId}`}>
              <div className='l-scoring-card-header'>
                Item #
                {' '}
                {questionIndex++}
              </div>
              <div className='l-scoring-card-content'>
                <div className='lrn-question-row'>
                  <div className='lrn-question-col'>
                    <span
                      className='learnosity-item'
                      data-reference={itemRefId} />
                  </div>
                  <div className='custom-score-col'>
                    {this.state.isItemsAPILoaded && this.renderLearnosityScoringControl(itemRefId, itemResponses)}
                  </div>
                </div>
              </div>
            </div>
          );
        }
        return <div className='l-scoring-card-inner-container'>{components}</div>;
      }
      return <div className='l-no-questions-container'>No Question Data Returned.</div>;
    }
    return <div className='l-no-questions-container'><Loader active /></div>;
  }

  // Question scoring state will have three possible values
  // NONE - hide the whole panel, SCORE - show scoring and feedback controls, NO_SCORE - Show no scale messaging
  calcFacultyControlPanelState(maxScore) {
    if (maxScore !== undefined && maxScore !== null) {
      if (maxScore > 0) {
        // show scoring controls
        return 'SCORE';
      }
      // show message: This question has zero max score, no score required.
      return 'NO_SCORE';
    }
    // show message: This is a practice question, no score required.
    return 'NONE';
  }

  openPrintComponent = async () => {
    const { activityInstanceId, assignmentId, classroomId, contentTitle, contentSubTitle, studentName, grade } = this.props;
    await this.clearItemsApp();
    // Some string params are in session storage because they could contain reserved url chars.
    const instanceData = {
      contentTitle,
      contentSubTitle,
      points: this.totalPoints,
      studentName,
      maxPoints: this.totalMaxPoints,
      grade
    };
    setSessionStorageItem('c2c_printPreviewData', JSON.stringify(instanceData));

    this.props.history.push(`/lrnPrint?activityInstanceId=${activityInstanceId}&assignmentId=${assignmentId}&classroomId=${classroomId}&returnView=${VIEW_SELECTION.GRADEBOOK}`);
  }

  saveTeacherScores = () => {
    const { learnosityDataManager, activityInstanceId } = this.props;
    // Get the data from state and save it to learnosity
    const { updatedResponses, responseData } = this.state;
    const scores = [];
    const responseIds = [];
    // Create arrays of the response ids and scores the teacher updated
    for (const updatedResponse of updatedResponses) {
      scores.push(updatedResponse.score);
      responseIds.push(updatedResponse.responseId);
    }
    // Find responses with null scores, we need to send 0% for these as teacher has tacitly approved
    // the 0% item score if they are clicking save and we can consider this done.
    // This allows the BE to consider it fully graded.
    if (responseData) {
      for (let i = 0; i < responseData.length; i++) {
        const response = responseData[i];
        const responseId = response.response_id;
        // check for null max scores as sometimes intro/outros have responses (for some unfathomable reason)
        if (response.score === null && response.max_score !== null) {
          // make sure there isn't already a teacher updated response for this
          if (!responseIds.includes(responseId)) {
            scores.push(0);
            responseIds.push(responseId);
          }
        }
      }
    }
    if (responseIds.length > 0) {
      // Call to update response/scores data
      this.setState({ isSaving: true });
      learnosityDataManager.updateLearnosityDataApiResponseScores(activityInstanceId, responseIds, scores).then((data) => {
        try {
          if (data && data.status && data.status === 'SUCCESS') {
            console.log('success!');
            this.closeItemsApp();
          } else {
            if (data && data.statusMessage) {
              this.openWarningModal(data.statusMessage);
              console.error(`Failed to update responses for assignment: ${activityInstanceId}, message: ${data.statusMessage}`); // TODO do we have a standard notification for this?
            } else {
              this.openWarningModal('An unexpected error has occurred; please try again.');
              console.error('Failed to update responses!');
            }
            this.setState({ updatedResponses: [], isSaving: false });
          }
        } catch (err) {
          console.error(err);
          // catch the error.
        }
      });
    }
  }

  ProgressBar = (props) => {
    const { color, percent } = props;
    return (
      <div className={`progress-bar ${color}`}>
        <div className={color} style={{ width: `${percent}%`, maxWidth: '100%' }} />
      </div>
    );
  }

  render() {
    const { InfoModal } = this;
    const { ErrorModal } = this;
    const { ProgressBar } = this;
    const { gradebookManager, t } = this.props;
    const gradeClassName = gradebookManager.getGradeClassName(this.props.grade);
    const transformFn = UtilityService.reactHtmlParserTransform;
    const processedContentTitle = this.props.contentTitle ? UtilityService.reactHtmlParserWrapper(this.props.contentTitle).parsedNoPWrap : '';
    const processedContentSubTitle = this.props.contentSubTitle ? UtilityService.reactHtmlParserWrapper(this.props.contentSubTitle).parsedNoPWrap : '';
    return (
      <div className='learnosity-modal-container'>
        <Modal
          className='l-scoring-modal'
          onMount={() => document.body.classList.add('body-l-scoring-modal')}
          onUnmount={() => document.body.classList.remove('body-l-scoring-modal')}
          open
          size='fullscreen'>
          <div className='l-scoring-modal-container'>
            <div className='l-scoring-modal-upper-header'>
              <div className='modal-title-text'>
                {this.props.isTeacher && (
                  <div className='title-text'>
                    {t('gradebookLabel', 'LearnosityViewerModal translation missing.')}
                    :
                    {' '}
                    {this.props.studentName}
                  </div>
                )}
                {!this.props.isTeacher && (
                  <div className='title-text'>
                    {processedContentTitle}
                  </div>
                )}
              </div>
              <div className='modal-title-button'>
                {this.renderStudentInstructionButton()}
                {this.state.isItemsAPILoaded && this.props.isTeacher && this.props.canManageScores && (
                  <Button
                    className={(!this.props.isTeacher ? 'basic student white' : '')}
                    onClick={() => this.openPrintComponent()}
                    primary>
                    Print Preview
                  </Button>
                )}
                <Button
                  className={(!this.props.isTeacher ? 'basic student white' : '')}
                  onClick={() => this.closeItemsApp()}
                  primary>
                  Close
                </Button>
                {this.props.isTeacher && this.props.canManageScores && (
                  <Button
                    className={(!this.props.isTeacher ? 'basic student white' : '')}
                    disabled={this.state.updatedResponses.length < 1}
                    onClick={() => this.saveTeacherScores()}
                    primary>
                    {this.state.isSaving ? <Loader active inline size='tiny' /> : 'Save'}
                  </Button>
                )}
              </div>
            </div>
            <div className='l-scoring-modal-header-container'>
              <div className='l-scoring-modal-header'>
                <div className='scoring-modal-header-title'>
                  <div className='header-student-name'>
                    {processedContentTitle}
                  </div>
                  <div className='header-resource-name'>
                    {processedContentSubTitle}
                  </div>
                </div>
                {!this.props.isTeacher && (
                <div className='progress-bar-container'>
                  <div className={`progress-text ${gradeClassName}`}>
                    {this.props.grade}
                    %
                  </div>
                  <ProgressBar color={gradeClassName} percent={this.props.grade} />
                </div>
                )}
                {/* <Image className={'feedback clickable'} src={iconCommentNone} /> This may be added back later */}
              </div>
            </div>
            <div className='l-scoring-card-outer-container' id='l-scoring-card-outer-container'>
              {this.renderScoringCards()}
            </div>
          </div>
          <InfoModal
            addInfoOpen={this.state.addInfoOpen}
            closeInfoModal={this.closeInfoModal}
            contentItemDescription={null}
            imageUrl={this.props.contentImageUrl}
            infoName={this.props.resourceName}
            resourceInfo={null}
            studentInstruction={this.props.instruction} />
          <ErrorModal
            addErrorOpen={this.state.showErrorDialog}
            closeErrorModal={this.closeWarningModal}
            errorMessage={this.state.errorMessage} />
        </Modal>
      </div>
    );
  }
}

SatCoreRegister('LearnosityScoringModal', LearnosityScoringModal);
