/* eslint-disable sort-keys */
/* eslint-disable max-len */
import { action, computed, observable, toJS } from 'mobx';
import Auth from './AuthManager';

import { COURSE_ENDPOINTS } from './CourseManager';

import { RESOURCE_BANK_CONTENT_ITEM_TYPES } from './ContentManager';

import { getLibraryResourceMetadata } from './LibraryManager';

import ProductManager from './ProductManager';
import UserManager from './UserManager';

export class ResourceBankShopManager {
  assessment = RESOURCE_BANK_CONTENT_ITEM_TYPES.ASSESSMENT;
  article = RESOURCE_BANK_CONTENT_ITEM_TYPES.ARTICLE_RESOURCE;
  audio = RESOURCE_BANK_CONTENT_ITEM_TYPES.AUDIO_RESOURCE;
  bookmark = RESOURCE_BANK_CONTENT_ITEM_TYPES.BOOKMARK_RESOURCE;
  image = RESOURCE_BANK_CONTENT_ITEM_TYPES.IMAGE_RESOURCE;
  learnosityActivity = RESOURCE_BANK_CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE;
  lesson = RESOURCE_BANK_CONTENT_ITEM_TYPES.LESSON;
  microsites = RESOURCE_BANK_CONTENT_ITEM_TYPES.MICROSITE_RESOURCE;
  pdf = RESOURCE_BANK_CONTENT_ITEM_TYPES.PDF_RESOURCE;
  files = RESOURCE_BANK_CONTENT_ITEM_TYPES.FILE_RESOURCE;
  video = RESOURCE_BANK_CONTENT_ITEM_TYPES.VIDEO_RESOURCE;

  resourceBankTypeOptions =
  [
    { key: this.assessment, value: this.assessment, text: RESOURCE_BANK_CONTENT_ITEM_TYPES.getLabel(this.assessment) },
    { key: this.article, value: this.article, text: RESOURCE_BANK_CONTENT_ITEM_TYPES.getLabel(this.article) },
    { key: this.audio, value: this.audio, text: RESOURCE_BANK_CONTENT_ITEM_TYPES.getLabel(this.audio) },
    { key: this.bookmark, value: this.bookmark, text: RESOURCE_BANK_CONTENT_ITEM_TYPES.getLabel(this.bookmark) },
    { key: this.image, value: this.image, text: RESOURCE_BANK_CONTENT_ITEM_TYPES.getLabel(this.image) },
    { key: this.lesson, value: this.lesson, text: RESOURCE_BANK_CONTENT_ITEM_TYPES.getLabel(this.lesson) },
    { key: this.learnosityActivity, value: this.learnosityActivity, text: RESOURCE_BANK_CONTENT_ITEM_TYPES.getLabel(this.learnosityActivity) },
    { key: this.microsites, value: this.microsites, text: RESOURCE_BANK_CONTENT_ITEM_TYPES.getLabel(this.microsites) },
    { key: this.pdf, value: this.pdf, text: RESOURCE_BANK_CONTENT_ITEM_TYPES.getLabel(this.pdf) },
    { key: this.files, value: this.files, text: RESOURCE_BANK_CONTENT_ITEM_TYPES.getLabel(this.files) },
    { key: this.video, value: this.video, text: RESOURCE_BANK_CONTENT_ITEM_TYPES.getLabel(this.video) },
  ];

  itemBankOnlyTypeOptions =
  [
    { key: this.assessment, value: this.assessment, text: RESOURCE_BANK_CONTENT_ITEM_TYPES.getLabel(this.assessment) },
    { key: this.lesson, value: this.lesson, text: RESOURCE_BANK_CONTENT_ITEM_TYPES.getLabel(this.lesson) },
  ]

  @observable productResources = [];
  @observable resourcesLoading = false;
  @observable activePage = 0;
  @observable totalPages = 0;
  @observable totalResults = 0;
  @observable resources = [];

  @observable searchResourceTypes = [];
  @observable searchOperator = 'OR';
  @observable searchTerms = '';
  @observable searchTags = [];
  @observable searchTextTimeout = null;
  @observable searchStandards = [];
  @observable searchResourceBanks = [];
  @observable searchLocation = '';

  @observable resourceBankOptions = [];

  @observable currentResourcesMap = new Map();
  @observable selectedNotAttachedResources = new Map();

  @action setProductResources = (productResources) => {
    this.productResources = productResources;
  }

  @action setSearchTextTimeout = (searchTextTimeout) => {
    this.searchTextTimeout = searchTextTimeout;
  }

  @action setSearchResourceTypes = async (val) => {
    this.searchResourceTypes = val;
  }

  @action setSearchOperator = async (val) => {
    this.searchOperator = val;
  }

  @action setSearchTerms = async (val) => {
    this.searchTerms = val;
  }

  @action setSearchTags = async (val) => {
    const merged = [].concat(...val);
    this.searchTags = merged;
  }

  @action setSearchStandards = async (val) => {
    this.searchStandards = val;
  }

  @action setSearchResourceBanks = async (val) => {
    this.searchResourceBanks = val;
  }

  @action setSearchLocation = async (val) => {
    this.searchLocation = val;
  }

  @action resetPaginator = () => {
    this.activePage = 0;
    this.totalPages = 0;
    this.totalResults = 0;
  }

  @action setActivePage = (pageNum) => {
    this.activePage = pageNum;
  }

  @action setResourcesLoading = (toggle) => {
    this.resourcesLoading = toggle;
  }

  @action setResources = (resources) => {
    this.resources = resources;
  }

  @action setResourceBankOptions = (resourceBankOptions) => {
    this.resourceBankOptions = resourceBankOptions;
  }

  // library assessment shopping
  @computed get selectedNotAttachedResourceIds() {
    return Array.from(this.selectedNotAttachedResources.keys());
  }

  @computed get selectedNotAttachedResourceArray() {
    return Array.from(this.selectedNotAttachedResources.values());
  }

  @action addResource = (resourceContentItemId, resource) => {
    if (resourceContentItemId && resource) {
      if (!this.currentResourcesMap.has(resourceContentItemId)) {
        this.currentResourcesMap.set(resourceContentItemId, resource);
      }
    }
  }

  @action clearResourcesMap = () => {
    this.currentResourcesMap.clear();
  }

  @action addSelectedNotAttachedResource = (resource) => {
    if (resource && resource.id) {
      this.selectedNotAttachedResources.set(resource.id, resource);
    }
  }

  @action removeSelectedNotAttachedResource = (resourceId) => {
    if (resourceId && this.selectedNotAttachedResources.has(resourceId)) {
      this.selectedNotAttachedResources.delete(resourceId);
    }
  }

  @action clearSelectedNotAttachedResources = () => {
    this.selectedNotAttachedResources.clear();
  }

  @action setResourcesSelected = (resourceId) => {
    const resource = this.currentResourcesMap.get(resourceId);
    let isSelected;
    if (this.selectedNotAttachedResources.has(resourceId)) {
      this.removeSelectedNotAttachedResource(resourceId);
      isSelected = false;
    } else {
      this.addSelectedNotAttachedResource(resource);
      isSelected = true;
    }
    if (resource) {
      resource.selected = isSelected;
      resource.attached = false;
    }
    // const pagedAssessment = this.pagedAssessmentsMap.get(assessmentId);
    // if (pagedAssessment) {
    //   pagedAssessment.selected = isSelected;
    //   pagedAssessment.attached = false;
    // }
  }
  // end assessment shopping

  @action
  searchResourceBankResources = async (
    page = 0,
    pageSize = 25,
    productIds = null,
    searchStandards,
    searchTypes,
    searchTags,
    searchTerms,
    sortColumn = 'modificationDate',
    sortDirection = 'desc',
  ) => {
    this.setResourcesLoading(true);
    try {
      const resources = [];
      let searchProductIds = null;
      if (Array.isArray(productIds)) {
        if (productIds.length > 0) {
          searchProductIds = productIds.join(',');
        }
      } else {
        if (productIds && productIds.length > 0) {
          searchProductIds = productIds;
        }
      }
      if (!searchProductIds) {
        this.setProductResources([]);
        this.setResourcesLoading(false);
        return false;
      }
      const searchResourceTypes = searchTypes ? searchTypes.join(',') : null;
      const allResourceTypes = RESOURCE_BANK_CONTENT_ITEM_TYPES.getResourceContentTypes();
      const searchResourceTags = searchTags ? searchTags.join(',') : null;
      const searchResourceStandards = searchStandards ? searchStandards.join(',') : [];
      const body = {
        productId: searchProductIds,
        tagSearchCondition: 'AND',
        searchTerms: searchTerms || '',
        searchTags: searchResourceTags || '',
        searchTypes: searchResourceTypes || allResourceTypes,
        schools: '',
        includeBlankCard: false,
        take: '25',
        skip: !pageSize ? 0 : (page ? page - 1 : 0) * pageSize,
        page,
        pageSize: 25,
        searchStandards: searchResourceStandards,
        standardSearchCondition: 'OR',
        searchScope: 'LICENSE'
      };
      // if (sortColumn) {
      //   body['sort[0][field]'] = sortColumn;
      // }
      // if (sortDirection) {
      //   body['sort[0][dir]'] = sortDirection;
      // }
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.SEARCH_CONTENT_ITEMS_BY_PRODUCT}`;
      const response = await Auth.fetch(apiUrl, { body, method: 'POST' });
      if (response && response.status === 'SUCCESS') {
        const responseData = response?.contentItems || null;
        if (responseData) {
          this.clearResourcesMap();
          responseData.forEach((resource) => {
            const metaData = getLibraryResourceMetadata(resource);
            const { backgroundImg, cardClassName, cardTypeName, typeDisplayName } = metaData;
            const bankResource = { id: resource.id, backgroundImg, cardClassName, cardTypeName, title: resource.name, typeDisplayName, libraryResource: resource };
            resources.push(bankResource);
            this.addResource(resource.id, bankResource);
          });
        }
        this.totalPages = Math.ceil(+response.pageTotal / pageSize);
        this.totalResults = response.pageTotal;
        this.activePage = page;
        this.setProductResources(resources);
      }
      this.setResourcesLoading(false);
      return true;
    } catch (error) {
      this.setResourcesLoading(false);
      console.error(error);
      return false;
    }
  }

  @action
  fetchMyDraftsResources = async (
    page = 0,
    pageSize = 25,
    searchTypes,
    sortColumn = 'modificationDate',
    sortDirection = 'desc',
    searchTags,
    searchTerms,
    searchStandards
  ) => {
    this.setResourcesLoading(true);
    try {
      const resourceTypeFilter = UserManager.canViewLibraryResourceBank ? 'resourceBank' : 'itemBank';
      const availableResourceTypes = RESOURCE_BANK_CONTENT_ITEM_TYPES.getResourceContentTypes(resourceTypeFilter);
      const searchResourceTypes = searchTypes ? searchTypes.join(',') : null;
      const searchResourceTags = searchTags ? searchTags.join(',') : null;
      const searchResourceStandards = searchStandards ? searchStandards.join(',') : [];
      const myDraftsResources = [];
      const body = {
        searchStandards: searchResourceStandards,
        standardSearchCondition: 'OR',
        searchScope: 'MINE',
        searchTerms: searchTerms || '',
        searchTags: searchResourceTags || '',
        searchTypes: searchResourceTypes || availableResourceTypes,
        searchSubTypes: '',
        tagSearchCondition: 'AND',
        schools: '',
        workspaces: '',
        take: '25',
        skip: !pageSize ? 0 : (page ? page - 1 : 0) * pageSize,
        page,
        pageSize: pageSize || 25,
        includeBlankCard: false,
      };
      if (sortColumn) {
        body['sort[0][field]'] = sortColumn;
      }
      if (sortDirection) {
        body['sort[0][dir]'] = sortDirection;
      }
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.SEARCH_CONTENT_ITEMS}`; // using existing api def from courseManager.
      const resourceResponse = await Auth.fetch(apiUrl, { body, method: 'POST' });
      if (resourceResponse && resourceResponse.status === 'SUCCESS') {
        const resources = resourceResponse.contentItems;
        this.clearResourcesMap();
        for (const resource of resources) {
          const metaData = getLibraryResourceMetadata(resource, true);
          const { backgroundImg, cardClassName, cardTypeName, typeDisplayName } = metaData;
          const libraryResource = { id: resource.id, backgroundImg, cardClassName, cardTypeName, title: resource.name, typeDisplayName, libraryResource: resource };
          myDraftsResources.push(libraryResource);
          this.addResource(resource.id, libraryResource);
        }
        this.totalPages = Math.ceil(+resourceResponse.pageTotal / pageSize);
        this.totalResults = resourceResponse.pageTotal;
        this.activePage = page;
        this.setProductResources(myDraftsResources);
        this.setResourcesLoading(false);
        return myDraftsResources;
      } else {
        this.setResourcesLoading(false);
        return false;
      }
    } catch (error) {
      console.error(error);
      this.setResourcesLoading(false);
      return false;
    }
  }

  @action
  fetchSharedDraftsResources = async (
    page = 0,
    pageSize = 25,
    searchTypes,
    sortColumn = 'modificationDate',
    sortDirection = 'desc',
    searchTags,
    searchTerms,
    searchStandards
  ) => {
    this.setResourcesLoading(true);
    try {
      const resourceTypeFilter = UserManager.canViewLibraryResourceBank ? 'resourceBank' : 'itemBank';
      const availableResourceTypes = RESOURCE_BANK_CONTENT_ITEM_TYPES.getResourceContentTypes(resourceTypeFilter);
      const searchResourceTypes = searchTypes ? searchTypes.join(',') : null;
      const searchResourceTags = searchTags ? searchTags.join(',') : null;
      const searchResourceStandards = searchStandards ? searchStandards.join(',') : [];
      const sharedDraftsResources = [];
      const body = {
        searchStandards: searchResourceStandards,
        standardSearchCondition: 'OR',
        searchTerms: searchTerms || '',
        searchTags: searchResourceTags || '',
        searchTypes: searchResourceTypes || availableResourceTypes,
        searchSubTypes: '',
        tagSearchCondition: 'AND',
        schools: '',
        workspaces: '',
        take: '25',
        skip: !pageSize ? 0 : (page ? page - 1 : 0) * pageSize,
        page,
        pageSize: pageSize || 25,
        includeBlankCard: false,
      };
      if (sortColumn) {
        body['sort[0][field]'] = sortColumn;
      }
      if (sortDirection) {
        body['sort[0][dir]'] = sortDirection;
      }
      const apiUrl = `${Auth.ecms}${COURSE_ENDPOINTS.SEARCH_CONTENT_ITEMS_BY_DISTRICT_WORKSPACE}`; // using existing api def from courseManager.
      const resourceResponse = await Auth.fetch(apiUrl, { body, method: 'POST' });
      if (resourceResponse && resourceResponse.status === 'SUCCESS') {
        const resources = resourceResponse.contentItems;
        this.clearResourcesMap();
        for (const resource of resources) {
          const metaData = getLibraryResourceMetadata(resource, true);
          const { backgroundImg, cardClassName, cardTypeName, typeDisplayName } = metaData;
          const libraryResource = { id: resource.id, backgroundImg, cardClassName, cardTypeName, title: resource.name, typeDisplayName, libraryResource: resource };
          sharedDraftsResources.push(libraryResource);
          this.addResource(resource.id, libraryResource);
        }
        this.totalPages = Math.ceil(+resourceResponse.pageTotal / pageSize);
        this.totalResults = resourceResponse.pageTotal;
        this.activePage = page;
        this.setProductResources(sharedDraftsResources);
        this.setResourcesLoading(false);
        return sharedDraftsResources;
      }
      this.setResourcesLoading(false);
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  fetchResourceBanks = async (
    userId,
    page = 0,
    pageSize = 9999,
    sortColumn = 'modificationDate',
    sortDirection = 'desc'
  ) => {
    try {
      const resourceBankOptions = [];
      const response = await ProductManager.fetchLicensedResourceProducts(userId, page, pageSize, sortColumn, sortDirection);
      if (response && response.status === 'SUCCESS') {
        const responseData = response.data;
        responseData.forEach((resourceBank) => {
          resourceBankOptions.push({ key: resourceBank.id, value: resourceBank.id, text: resourceBank.name });
        });
        this.setResourceBankOptions(resourceBankOptions);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  getArrayFromMap(map) {
    if (map) {
      return Array.from(toJS(map).values());
    }
    return [];
  }

  getQuestionTypeText(id) {
    for (const item of this.questionTypeOptions) {
      if (id === item.key) {
        return item.text;
      }
    }
  }
}

export default new ResourceBankShopManager();
