import React from 'react';

import { Dimmer, Loader, Placeholder, Segment } from 'semantic-ui-react';
import '../css/Dashboard.less';

import { SatCoreRegister } from '../SatCoreRegistry';

const SatCoreLoader = ({ height, t }) => (
  <Segment
    className={`sat-core-loader${!height ? ' view-height' : ''}`}
    style={height ? { height } : {}}>
    <Dimmer active inverted>
      <Loader active inverted>{t('loading')}</Loader>
    </Dimmer>
    <Placeholder>
      <Placeholder.Header>
        <Placeholder.Paragraph />
      </Placeholder.Header>
    </Placeholder>
  </Segment>
);

export default Loader;

SatCoreRegister('SatCoreLoader', SatCoreLoader);
