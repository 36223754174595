import React from 'react';
import { Image } from 'semantic-ui-react';
import goldTrophy from '../img/icon-trophy-gold.png';
import silverTrophy from '../img/icon-trophy-silver.png';
import '../css/Trophy.less';

const Trophy = (({ type, count }) => {
  let trophyImg;
  let bandClass;
  let label;
  let range;

  switch (type) {
    case 'gold':
      trophyImg = goldTrophy;
      bandClass = 'gold-band';
      label = 'Gold';
      range = 'Scores 80% - 100%';
      break;
    case 'silver':
      trophyImg = silverTrophy;
      bandClass = 'silver-band';
      label = 'Silver';
      range = 'Scores 60% - 79%';
      break;
  }

  return (
    <div className='trophy'>
      <Image alt='' className='trophy-img' src={trophyImg} />
      <div className={`trophy-count ${bandClass}`}>
        {count}
        {' '}
        {label}
      </div>
      <div className='trophy-range'>{range}</div>
    </div>
  );
});

export default Trophy;
