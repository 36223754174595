/* eslint-disable sort-keys */
import React from 'react';

import defaultClassroom from '../../img/default-classroom.svg';

import { getRegisteredClass } from '../../SatCoreRegistry';

import classroomManager from '../../managers/ClassroomManager';
import reportCourseManager from '../../managers/reports/ReportCourseManager';
import reportIdentityManager from '../../managers/reports/ReportIdentityManager';
import reportTableManager from '../../managers/reports/ReportTableManager';

import PopupService from '../PopupService';
import ReportJsonHelperService from './ReportJsonHelperService';

import { getInitials } from '../../utils';

import Avatar from '../../components/Avatar';

export default class ReportCourseSchoolService {
  /** Intended to be called via `ReportTypeService.initReportTableFacultyCellConfigArray` */
  static _initReportTableFacultyCellConfigArray = (initProps) => {
    const { handleViewNextReport, renderFacultyResultCell } = initProps;
    const { reportInfoClassNames } = reportIdentityManager;

    const facultyCells = ReportJsonHelperService.REPORT_CLASSROOMS().filter((classroom) => {
      const { selectedReportCourseContentItemId } = reportCourseManager;
      if (!selectedReportCourseContentItemId || !classroom.courseIds) {
        return classroom;
      }
      return classroom.courseIds.find((courseId) => {
        return courseId === selectedReportCourseContentItemId;
      });
    }).map((classroom) => {
      const { classroomId } = classroom;
      return {
        Cell: (props) => {
          return renderFacultyResultCell({ ...initProps, ...props, classroom });
        },
        Header: (props) => {
          return this.renderSchoolHeaderScoreCell({
            ...initProps,
            ...props,
            handleViewNextReport,
            renderFacultyResultCell,
            classroom
          });
        },
        accessor: (props) => {
          const { elementId } = props;
          const allScoreInfo = ReportJsonHelperService.REPORT_SCORE_INFO_BY_ELEMENT_FOR_STUDENTS();
          const scoreInfoObj = allScoreInfo[elementId] && allScoreInfo[elementId][classroomId];

          if (typeof scoreInfoObj === 'object' && !('averageViewedTime' in scoreInfoObj)) {
            scoreInfoObj['averageViewedTime'] = scoreInfoObj['viewedTime'];
          }

          return scoreInfoObj/* && scoreInfoObj[activeReportScorePropName] */;
        },
        className: `cell-faculty-result ${reportInfoClassNames}`,
        id: classroomId,
        originalData: classroom
      };
    });
    reportTableManager.setReportTableFacultyCellsConfig(facultyCells);
    return facultyCells;
  };

  /**
   * @param {{
   *   classroom: import('./ReportJsonHelperService').ReportClassroom,
   * }} props
   */
  static renderSchoolHeaderScoreCell = (props) => {
    const { classroom } = props;

    const { reportInfoClassNames } = reportIdentityManager;

    const imageUrl = classroomManager.createClassroomImageUrl(classroom.imageUrl, classroom.fullImageUrl);

    const ReportIdentityService = getRegisteredClass('ReportIdentityService');
    const reportLeadTeacherName = ReportIdentityService.getReportLeadTeacherName(classroom);

    const popupLabel = PopupService.renderPopup({
      className: `${reportInfoClassNames} report-table-header-popup`,
      content: (
        <div className={`header-cell-faculty-result-content ${reportInfoClassNames}`}>
          <div className='header-cell-faculty-content-teacher-name'>
            {reportLeadTeacherName || 'Unknown'}
          </div>
          <div className='header-cell-faculty-content-classroom-name'>
            {classroom.classroomName}
          </div>
          {classroom.classroomNickname && (
            <div className='header-cell-faculty-content-classroom-displayname'>
              {classroom.classroomNickname}
            </div>
          )}
        </div>
      ),
      offset: [10, 0],
      on: 'hover',
      pinned: true,
      position: 'bottom right',
      trigger: getInitials(classroom.classroomName)
    });

    const urlParams = new URLSearchParams(window.location.search);
    const institutionId = urlParams.get('institutionId');
    const institutionName = urlParams.get('institutionName');
    return (
      <div className={`faculty-avatar-wrapper ${reportInfoClassNames}`}
        onClick={() => props.handleViewNextReport({
          classroomId: classroom.classroomId || null,
          institutionId: institutionId || null,
          institutionName: institutionName || null
        })}>
        <Avatar className='faculty-avatar' defaultImage={defaultClassroom} fullProfileImageUrl={imageUrl} />
        <div className='faculty-avatar-label'>
          {popupLabel}
        </div>
      </div>
    );
  }
}
