/* eslint-disable max-len */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Dropdown, Header } from 'semantic-ui-react';
import Modal from '../Modal';
import { SatCoreRegister } from '../../SatCoreRegistry';
import Auth from '../../managers/AuthManager';
import { DIALOG_NAMES } from '../../managers/DialogManager';
import '../../css/ItemBankItemPreviewModal.less';

export default
@inject('learnosityAuthorManager', 'libraryManager', 'dialogManager')
@observer
class ItemBankItemPreviewModal extends Component {
  authorApp;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      resourceTemplate: '',
      templateOptions: []
    };
  }

  componentDidMount = async () => {
    const { libraryManager, dialogManager } = this.props;
    const { profileParam } = this.state;
    // Get optionsProfiles to use in the template selection dropdown
    const templateOptions = await libraryManager.getOptionProfiles();
    if (templateOptions && templateOptions.length > 0) {
      this.setState({ templateOptions });
    }

    const chosenProfileId = localStorage.getItem('chosen-profile-id');

    if (!profileParam && !chosenProfileId) {
      dialogManager.setOpenDialog(DIALOG_NAMES.SELECT_LESSON_PROFILE_MODAL, {
        closeFull: this.closeSelectLessonProfileModalFull,
        open: true,
        setProfileId: this.setProfileId,
        templateOptions
      });
    }
  }

  openWarningModal = (message) => {
    this.setState({ showErrorDialog: true, errorMessage: message });
  }

  closeThisModal = async () => {
    const { closeItemBankItemPreviewModal } = this.props;
    closeItemBankItemPreviewModal();
  }

  handleChangeResourceTemplate = async (_event, { value }) => {
    const resourceTemplate = value;

    localStorage.setItem('chosen-profile-id', resourceTemplate);

    this.setState({ resourceTemplate });
  }

  closeSelectLessonProfileModalFull = () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.SELECT_LESSON_PROFILE_MODAL);
    this.closeThisModal();
  }

  setProfileId = async (id) => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.SELECT_LESSON_PROFILE_MODAL);
    const resourceTemplate = id;

    localStorage.setItem('chosen-profile-id', resourceTemplate);

    this.setState({ resourceTemplate });
  };

  render() {
    const { templateOptions } = this.state;

    let { resourceTemplate } = this.state;

    const chosenProfileId = localStorage.getItem('chosen-profile-id');

    resourceTemplate = resourceTemplate || chosenProfileId;

    const { displayItemBankItemPreviewModal, contentItemId } = this.props;
    const profileParam = (resourceTemplate) ? `&profileId=${resourceTemplate}` : '';

    const itemPreviewUrl = `${Auth.lesson}/itemPreview?contentItemId=${contentItemId}${profileParam}&sat=true#${Auth.authKey}`;

    return (
      <>
        <Modal
          className='ItemBankItemItemPreviewModal'
          closeOnDimmerClick={false}
          closeOnEscape={false}
          open={displayItemBankItemPreviewModal}
          size='fullscreen'>
          <Modal.Header className='modal-header'>
            <Header.Content className='modal-header-bar'>
              <span className='modal-header-label'>
                Preview Item
              </span>
              <div className='modal-header-buttons'>
                <div className='profile-select-container'>
                  <Dropdown
                    key='profile-select'
                    fluid
                    name='sort-select'
                    onChange={this.handleChangeResourceTemplate}
                    options={templateOptions}
                    placeholder='Select option'
                    search
                    selection
                    value={resourceTemplate} />
                </div>
                <Button
                  className='ui primary button saveButton'
                  onClick={this.closeThisModal}>
                  Exit
                </Button>
              </div>
            </Header.Content>
          </Modal.Header>
          <div className='nav-separator' />
          <Modal.Content>
            <iframe key='preview' allowFullScreen={true} id='preview-id' src={itemPreviewUrl}
              title='Title' />
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

SatCoreRegister('ItemBankItemPreviewModal', ItemBankItemPreviewModal);
