import BaseApi from './BaseApi';

export const CLASSROOM_ENDPOINTS = {
  ADD_CO_TEACHER: '/api/addCoTeacherToClassrooms',
  ADD_STUDENT: '/api/addStudentToClassrooms',
  ADD_STUDENTS: '/api/addStudentsToClassroom',
};

/** @deprecated use ClassroomManager and/or ClassroomService instead */
export class ClassroomApi extends BaseApi {
  async addStudents(classroomId = null, userIds = null) {
    const url = this.getUrl(CLASSROOM_ENDPOINTS.ADD_STUDENTS);
    return !!await this.post(url, {
      body: {
        classroomId,
        studentIds: userIds.join(',')
      }
    });
  }

  async addStudent(classroomIds = null, userId = null) {
    const url = this.getUrl(CLASSROOM_ENDPOINTS.ADD_STUDENT);
    return !!await this.post(url, {
      body: {
        classroomIds: classroomIds.join(','),
        studentId: userId
      }
    });
  }

  async addCoTeacher(classroomIds = null, userId = null) {
    const url = this.getUrl(CLASSROOM_ENDPOINTS.ADD_CO_TEACHER);
    return !!await this.post(url, {
      body: {
        classroomIds: classroomIds.join(','),
        teacherId: userId
      }
    });
  }
}

export default new ClassroomApi();
