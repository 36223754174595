/* eslint-disable sort-keys */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { capitalize } from 'lodash';

import {
  Button, Container, Form, Header, Image, Label, Loader, Pagination
} from 'semantic-ui-react';

import Modal from '../Modal';
import '../../css/ResourceBankShopModal.less';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import { DIALOG_NAMES } from '../../managers/DialogManager';
import { CONTENT_ITEM_TYPES } from '../../managers/ContentManager';

import iconAlert from '../../img/icon-alert-red.svg';

export default
@inject(
  'assessmentManager',
  'contentManager',
  'courseManager',
  'dialogManager',
  'itemBankManager',
  'libraryManager',
  'resourceBankShopManager',
  'tagContentManager'
)
@observer
class ResourceShopModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.ResourceFilter = SatCoreComponent('ResourceFilter');
    this.CourseLayout = SatCoreComponent('CourseLayout');
    this.LibraryResourceCard = SatCoreComponent('LibraryResourceCard');
    this.maxPageSize = 25;
  }

  componentDidMount = async () => {
    const { courseManager, contentManager } = this.props;
    const { currentCourseElementList } = courseManager;
    const { contentItemList } = contentManager;
    const parentContentItemIds = currentCourseElementList.map((element) => element.contentItemId);
    const resourceParentContentItemIds = contentItemList.map((contentItem) => contentItem.parentItemId);
    this.setState({ parentContentItemIds, resourceParentContentItemIds });
  }

  componentWillUnmount = () => {
    this.setState(this.getInitialState());
  }

  getInitialState = (props) => ({
    parentContentItemIds: [],
    resourceParentContentItemIds: []
  })

  closeThisModal = async (fromSave) => {
    const { closeResourceBankShopModal, currentElementId, resourceBankShopManager } = this.props;
    resourceBankShopManager.setSearchTerms('');
    resourceBankShopManager.resetPaginator();
    await resourceBankShopManager.clearSelectedNotAttachedResources();
    closeResourceBankShopModal(currentElementId, fromSave);
  };

  submitAddSelectedResourcesConfirm = () => {
    const { dialogManager, resourceBankShopManager, t } = this.props;
    const message = `${t('saveLiveEditDescriptionPrefix')} ${resourceBankShopManager.selectedNotAttachedResourceIds.length} ${t('saveLiveEditDescriptionSuffix')}`;
    dialogManager.setOpenDialog(DIALOG_NAMES.CONFIRM, {
      title: t('saveLiveEditTitle'),
      message,
      cancelButtonClass: 'cancelButton',
      cancelButtonName: 'No',
      confirmButtonClass: 'saveButton',
      confirmButtonName: 'Yes',
      confirmHandler: async () => {
        await this.submitAddSelectedResources();
        dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
      }
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM));
  }

  submitAddSelectedResources = async () => {
    const { loading, isSelectedResourcesPopupOpen } = this.state;
    if (loading) {
      return false;
    }
    this.setState({ loading: true });
    if (isSelectedResourcesPopupOpen) {
      this.closeThisModal();
    }
    await this.saveResults();
  }

  saveResults = async () => {
    const { resourceBankShopManager } = this.props;
    if (resourceBankShopManager.selectedNotAttachedResourceIds.length > 0) {
      await this.doSaveThenRefresh();
    } else {
      this.setState({ loading: false });
      this.closeThisModal();
    }
  }

  doSaveThenRefresh = async () => {
    const { currentCourseId, currentElementId, libraryManager, resourceBankShopManager } = this.props;
    const result = await libraryManager.addAssessmentsToBankUnit(
      currentCourseId,
      currentElementId,
      resourceBankShopManager.selectedNotAttachedResourceIds
    );
    if (!result) {
      alert('An error occurred when trying to save. Please try again later.');
      this.setState({ loading: false });
    } else {
      await this.doRefresh(result);
    }
  }

  doRefresh = async (closeShoppingExperienceAfterRefresh = true) => {
    const { assessmentBankData, resourceBankShopManager } = this.props;
    const { activePage, searchStandards, searchType, searchTags, searchTerms } = resourceBankShopManager;
    const { isRefresh } = this.state;
    await this.handleFetchShoppingResources(activePage, assessmentBankData.id, searchStandards, searchType, searchTags, searchTerms);
    this.setState({ loading: false, isRefresh: !isRefresh });
    if (closeShoppingExperienceAfterRefresh) {
      const didWeAddAssessments = true;
      this.closeThisModal(didWeAddAssessments);
    }
  }

  openLibraryShoppingCart = () => {
    const { dialogManager, resourceBankShopManager } = this.props;
    const { selectedNotAttachedResourceIds } = resourceBankShopManager;
    const { parentContentItemIds } = this.state;

    const hasSelectedResources = selectedNotAttachedResourceIds.length;
    if (hasSelectedResources) {
      dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_CART_VIEW, {
        closeLibraryShoppingCart: this.closeLibraryShoppingCart,
        getLibraryCardOptions: this.getLibraryCardOptions,
        handleCheck: this.handleCheck,
        hasAssessments: hasSelectedResources,
        parentContentItemIds,
        submitAddSelectedAssessmentsToBank: this.submitAddSelectedResources
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_CART_VIEW));
      this.setState({ isSelectedResourcesPopupOpen: true });
    }
  }

  closeLibraryShoppingCart = () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_CART_VIEW);
    this.setState({ isSelectedResourcesPopupOpen: false });
  }

  handlePageChange = async (_event, pageInfo) => {
    const { resourceBankShopManager } = this.props;
    const { searchStandards, searchResourceTypes, searchTags, searchTerms, searchLocation, searchResourceBanks } = resourceBankShopManager;
    const { activePage } = pageInfo;
    const { isRefresh } = this.state;
    await this.handleFetchShoppingResources(searchTerms, searchTags, searchStandards, searchResourceTypes, searchLocation, searchResourceBanks, activePage);
    this.setState({ isRefresh: !isRefresh });
  };

  handleFetchShoppingResources = async (searchTerms = null, searchTags = null, searchStandards = null, searchTypes = null, selectedLocation = 'myDrafts', selectedProducts = null, activePage = 1) => {
    const { resourceBankShopManager } = this.props;
    const { isRefresh } = this.state;

    // call here for resources in the drafts or resource bank
    // console.log(`in handleFetchShoppingResources: selectedLocation: ${selectedLocation}, activePage: ${activePage}, searchStandards: ${searchStandards}, searchTypes: ${searchTypes}, searchTags: ${searchTags}, searchTerms: ${searchTerms}`);
    if (selectedLocation === 'myDrafts') {
      await resourceBankShopManager.fetchMyDraftsResources(activePage, this.maxPageSize, searchTypes, null, null, searchTags, searchTerms, searchStandards);
    } else if (selectedLocation === 'sharedDrafts') {
      await resourceBankShopManager.fetchSharedDraftsResources(activePage, this.maxPageSize, searchTypes, null, null, searchTags, searchTerms, searchStandards);
    } else if (selectedLocation === 'resourceBank') {
      await resourceBankShopManager.searchResourceBankResources(activePage, this.maxPageSize, selectedProducts, searchStandards, searchTypes, searchTags, searchTerms);
    }
    this.setState({ isRefresh: !isRefresh });
  };

  handleShowDetails = async (assessmentBankData) => {
    const { dialogManager, libraryManager } = this.props;
    const thePopupElement = document.getElementsByClassName('resource-option-popup')[0];
    if (thePopupElement) {
      thePopupElement.style.setProperty('visibility', 'hidden', 'important');
    }
    const titlePrefix = capitalize(CONTENT_ITEM_TYPES.getFlag(assessmentBankData.libraryResource.entityTypeId));
    const results = await libraryManager.getStandardTagAlignmentsByContentId(assessmentBankData.id);
    dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_STANDARDS_TAGS_MODAL, {
      standards: results.standards,
      tags: results.tags,
      name: assessmentBankData.title,
      description: assessmentBankData.libraryResource.description,
      guid: assessmentBankData.id,
      modalTitle: `${titlePrefix} Details`,
      modalType: 'resource-details'
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_STANDARDS_TAGS_MODAL));
  };

  handleCheck = (libraryResource) => {
    const { courseManager, libraryManager, resourceBankShopManager } = this.props;
    const { parentContentItemIds } = this.state;
    const { currentCourseId, currentCourseElementList } = courseManager;
    const isResourceSelected = resourceBankShopManager.selectedNotAttachedResources.has(libraryResource.id);
    if (isResourceSelected) {
      resourceBankShopManager.removeSelectedNotAttachedResource(libraryResource.id);
    } else {
      if (parentContentItemIds.includes(libraryResource.id)) {
        for (const element of currentCourseElementList) {
          if (element.contentItemId === libraryResource.id) {
            libraryManager.removeAssessmentFromBank(element.elementId, currentCourseId);
            // remove the value from the parent array
            const index = parentContentItemIds.indexOf(libraryResource.id);
            if (index > -1) {
              parentContentItemIds.splice(index, 1);
            }
          }
        }
      } else {
        resourceBankShopManager.setResourcesSelected(libraryResource.id);
      }
    }
    this.setState({ parentContentItemIds });
  };

  clearResourceResults = () => {
    const { resourceBankShopManager } = this.props;
    resourceBankShopManager.setProductResources([]);
    this.setState({ isRefresh: true });
  };

  renderResourceBankCards = (productResources) => {
    const { handleView, resourceBankShopManager, isLiveCourse } = this.props;
    const { LibraryResourceCard, SCCheckbox } = this;
    const { parentContentItemIds, resourceParentContentItemIds } = this.state;
    const libraryCards = [];
    if (productResources && productResources.length > 0) {
      resourceBankShopManager.productResources.map((data, index) => {
        const libraryCardKebabOptions = this.getLibraryCardOptions(data);
        const { libraryResource } = data;
        const { id } = libraryResource;
        // We check if the resource or it's parent content item match one already in the course.
        const attached = isLiveCourse ? (parentContentItemIds.includes(id) || resourceParentContentItemIds.includes(id)) : parentContentItemIds.includes(id);
        const isChecked = resourceBankShopManager.selectedNotAttachedResources.has(id) || attached;
        libraryCards.push(
          <Form.Field key={`${index}_${id}_FF`} className='add-assessment-field'>
            <SCCheckbox
              key={`${index}_${id}_SCC`}
              checked={isChecked}
              disabled={attached}
              onChange={() => { this.handleCheck(libraryResource); }}
              value={id} />
            <LibraryResourceCard key={`${index}_${id}_LRC`}
              libraryCardKebabOptions={libraryCardKebabOptions}
              libraryCardParams={data}
              onBodyClick={handleView} />
          </Form.Field>
        );
        return true;
      });
    }
    return (
      <div className='library-view-card-container'>
        {libraryCards}
      </div>
    );
  };

  getLibraryCardOptions = (data) => {
    const { handleView, t } = this.props;
    const options = {};
    // define common options
    const preview = {
      key: 'preview',
      label: t('previewOptionLabel'),
      callback: handleView
    };
    const details = {
      key: 'details',
      label: t('detailsOptionLabel'),
      callback: this.handleShowDetails
    };

    options.preview = preview;
    options.details = details;

    return options;
  };

  renderResourceBankModalContent = () => {
    const { resourceBankShopManager } = this.props;
    return (
      <div className={classNames('resource-bank-search-modal-content-container')}>
        <div className='resource-bank-search-modal-content-left scrolling content'>
          {this.renderResourceFilter()}
        </div>
        <div className='resource-bank-search-modal-content-rest scrolling content'>
          <div className='resource-bank-search-modal-content-mid'>
            {/* placeholder */}
          </div>
          <div className='resource-bank-search-modal-content-right'>
            {this.renderResourceResults(resourceBankShopManager.productResources)}
          </div>
        </div>

      </div>
    );
  };

  renderResourceFilter = () => {
    const { ResourceFilter } = this;
    const { tagContentManager } = this.props;
    // things needed for the item bank filter;
    tagContentManager.fetchAuthorityStates();

    return (
      <div className='resource-bank-filter-wrapper }'>
        <ResourceFilter
          clearResourceResults={this.clearResourceResults}
          handleFetchResourceBankResources={this.handleFetchShoppingResources}
          isShopping={true}
          multiSelect={true} />
      </div>
    );
  };

  renderResourceResults = (productResources) => {
    const { resourceBankShopManager, t } = this.props;
    const { activePage, resourcesLoading, totalPages, totalResults } = resourceBankShopManager;
    const { isRefresh } = this.state;
    const length = (productResources) ? productResources.length : 0;
    const startResultNumber = activePage <= 1 ? 1 : (activePage - 1) * this.maxPageSize + 1;
    const endResultNumber = (activePage * this.maxPageSize) > totalResults ? totalResults : activePage * this.maxPageSize;
    const paginationJsx = (
      <div className='paginator-container'>
        <div className='pagination-text'>
          {`${startResultNumber}-${endResultNumber} of ${totalResults}`}
        </div><Pagination
          activePage={activePage}
          onPageChange={this.handlePageChange}
          totalPages={totalPages} />
      </div>
    );

    let headerContentJsx = <></>;
    if (length > 0) {
      headerContentJsx = paginationJsx;
    } else if (resourcesLoading) {
      headerContentJsx = (
        <Loader active className='modal-loader modal-loader-small-font' />
      );
    } else if (!resourcesLoading) {
      headerContentJsx = t('noResultsZeroStateMsg');
    }

    return (
      <Container className='resource-bank-list-container' fluid refresh-attr={isRefresh ? isRefresh.toString() : 'false'}>
        <div className={classNames('modal-content-header', {
          'resource-bank-list-header-sticky': !resourcesLoading
        })}>
          <div className='modal-content-header-pagination'>
            {headerContentJsx}
          </div>
        </div>
        {this.renderResourceBankCards(productResources)}
      </Container>
    );
  };

  render() {
    const { displayAssessmentShopModal, isLiveCourse, resourceBankShopManager, t } = this.props;
    const { resourcesLoading, selectedNotAttachedResourceIds } = resourceBankShopManager;
    const { isSelectedResourcesPopupOpen } = this.state;
    const selectedCount = selectedNotAttachedResourceIds.length ? selectedNotAttachedResourceIds.length : 0;
    return (
      <>
        <Modal
          className='ResourceBankShopModal'
          closeOnDimmerClick={false}
          closeOnEscape={false}
          onClose={this.closeThisModal}
          open={displayAssessmentShopModal}
          size='fullscreen'
          style={{ overflow: 'hidden', top: '-14px' }}>
          <>
            <Modal.Header className='modal-header'>
              <Header.Content className='modal-header-bar'>
                <span className='modal-header-label'>
                  {t('headerTitle', 'Select Content')}
                </span>
                <div className='modal-header-buttons'>
                  <div className={`selected-count-wrapper ${selectedCount ? 'has-selected-count' : 'has-no-selected-count'}`}>
                    <span className='selected-text-label'>
                      {t('selectedItemsLabel', 'Selections')}
                    </span>
                    <Label circular onClick={this.openLibraryShoppingCart}>
                      {selectedCount}
                    </Label>
                  </div>
                  <Button
                    className='ui primary button saveButton'
                    disabled={isSelectedResourcesPopupOpen || selectedCount < 1}
                    loading={resourcesLoading}
                    onClick={isLiveCourse ? this.submitAddSelectedResourcesConfirm : this.submitAddSelectedResources}>
                    {isLiveCourse ? t('saveButtonLiveLabel') : t('saveButtonLabel')}
                  </Button>
                  <Button
                    basic
                    className='cancelButton'
                    onClick={this.closeThisModal}
                    primary>
                    {t('Exit')}
                  </Button>
                </div>
              </Header.Content>
            </Modal.Header>
            <div className='nav-separator' />
            {isLiveCourse && (
              <Container className='live-edit-banner'>
                <Image alt='alert' className='header-alert-icon' spaced src={iconAlert} />
                <div className='header-alert-message'>{t('liveEditingBannerMsg')}</div>
              </Container>
            )}
            {this.renderResourceBankModalContent()}
          </>
        </Modal>
      </>
    );
  }
}

SatCoreRegister('ResourceShopModal', ResourceShopModal);
