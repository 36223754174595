import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Container } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import RichTextEditor from '../RichTextEditor';
import Modal from '../Modal';

import '../../css/EditAssignmentNotesModal.less';

export default @inject('assignmentManager', 'gradebookManager')
@observer
class EditAssignmentNotesModal extends Component {
  constructor(props) {
    super(props);
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.state = { notes: '', assignment: null};
  }

  componentDidMount = async () => {
    const { assignmentId, assignmentManager } = this.props;

    const assignment = await assignmentManager.getAssignmentAsync(assignmentId);
    this.setState({ assignment });
  }

  cancel = () => {
    this.setState({ notes: '' });
    this.closeEditAssignmentNotesModal();
  }

  closeEditAssignmentNotesModal = () => {
    this.props.closeEditAssignmentNotes();
  }

  editorChange = (value) => {
    this.setState({ notes: value });
  }

  submitEditAssignmentNote = async () => {
    const { assignmentId, assignmentManager, gradebookManager } = this.props;
    const assignment = await assignmentManager.getAssignmentAsync(assignmentId);
    const notes = (this.state.notes !== '') ? this.state.notes : assignment.note;

    const result = await assignmentManager.updateAssignmentNotes(
      assignmentId,
      notes
    );

    if (result) {
      const assignment = await assignmentManager.getAssignmentAsync(assignmentId);
      gradebookManager.setCurrentAssignment(assignment);
      this.closeEditAssignmentNotesModal();
    } else {
      this.setState({
        serverError: true,
        serverErrorMsg: 'Something went wrong when saving the assignment, please try again'
      });
    }
  }

  render() {
    const { editAssignmentOpen } = this.props;
    const { assignment } = this.state;

    if (!assignment) {
      return (<></>);
    }

    const title = (assignment.resourceWebTitle !== '' && assignment.resourceWebTitle != null) ? assignment.resourceWebTitle : assignment.name;

    let note = '';

    if (assignment.note !== null && assignment.note !== undefined) {
      note = assignment.note;
    }

    const { ModalBanner } = this;

    if (assignment !== null && assignment !== undefined) {
      return (
        <>
          <Modal
            className='EditAssignmentModal'
            closeOnDimmerClick={false}
            closeOnEscape={true}
            onClose={this.cancel}
            open={editAssignmentOpen}
            size='small'>
            <ModalBanner
              label='Edit Assignment Notes: '
              onClose={this.cancel}
              title={title} />
            <Modal.Content scrolling>
              <Container>
                <div className='editor-container'>
                  <RichTextEditor
                    data={note}
                    onChange={this.editorChange} />
                  <div>(click to edit)</div>
                </div>
              </Container>
            </Modal.Content>
            <Modal.Actions>
              <Button
                basic
                className='cancelButton'
                onClick={this.cancel}
                primary
                type='button'>
                Cancel
              </Button>
              <Button
                className='ui primary button saveButton'
                onClick={this.submitEditAssignmentNote}
                type='button'>
                Save
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      );
    }
    return (<></>);
  }
}

SatCoreRegister('EditAssignmentNotesModal', EditAssignmentNotesModal);
