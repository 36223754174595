import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button } from 'semantic-ui-react';

// import classNames from 'classnames';

import '../../css/EngagementButtonBar.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

const EngagementButtonBar = observer((props) => {
  const EngagementButtonBarOverride = SatCoreComponent('EngagementButtonBarOverride');

  const hasEngagementButtonBarOverride = !!EngagementButtonBarOverride && EngagementButtonBarOverride !== React.Fragment;
  if (hasEngagementButtonBarOverride) {
    return <EngagementButtonBarOverride {...props} />;
  }

  const { t } = props;

  const { engagementManager } = useContext(MobXProviderContext);

  const handleEngagementButtonClick = (event) => {
    const { value } = event.target.dataset;
    engagementManager.setActiveEngagementTable(value);
  };

  const renderEngagementButtonBar = () => {
    return (
      <div className='EngagementButtonBar engagement-buttons-wrapper'>
        {renderEngagementButton('timeOnTaskRatio', { className: 'bar-btn-left' })}
        {renderEngagementButton('feedbackUsage', { className: 'bar-btn-middle' })}
        {renderEngagementButton('resourcesUsage', { className: 'bar-btn-middle' })}
        {renderEngagementButton('annotationsUsage', { className: 'bar-btn-middle' })}
        {renderEngagementButton('languageToolUsage', { className: 'bar-btn-right' })}
      </div>
    );
  };

  const renderEngagementButton = (dataValue, { className } = {}) => {
    return (
      <Button
        className={className}
        data-value={dataValue}
        onClick={handleEngagementButtonClick}
        primary={engagementManager.activeEngagementTable === dataValue}
        size='tiny'>
        {t(dataValue)}
      </Button>
    );
  };

  return renderEngagementButtonBar();
});
export default EngagementButtonBar;

SatCoreRegister('EngagementButtonBar', EngagementButtonBar);
