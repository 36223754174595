import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Image } from 'semantic-ui-react';

import '../css/OfflineMessageBox.less';

import imageCautionCircle from '../img/image-caution-circle.svg';

import { SatCoreRegister } from '../SatCoreRegistry';

const OfflineMessageBox = observer((props) => {
  const { /* managers */ } = useContext(MobXProviderContext);

  const { t } = props;

  return (
    <>
      <div className='OfflineMessageBox'>
        <div className='offline-box-header'>
          {t('offlineBoxHeaderTitle')}
        </div>
        <div className='offline-box-body'>
          <div className='offline-box-body-img-wrapper'>
            <Image alt='' src={imageCautionCircle} />
          </div>
          <div className='offline-box-body-text-wrapper'>
            <div className='offline-box-body-text-top'>
              {t('offlineBoxBodyTextTop')}
            </div>
            <div className='offline-box-body-text-bottom'>
              {t('offlineBoxBodyTextBottom')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
export default OfflineMessageBox;

SatCoreRegister('OfflineMessageBox', OfflineMessageBox);
