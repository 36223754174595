import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Button, Dropdown, Input, Message } from 'semantic-ui-react';
import Modal from '../Modal';
import '../../css/InputModal.less';

import { SatCoreComponent } from '../../SatCoreRegistry';

const InputModalWithProfiles = observer((props) => {
  const {
    bannerLabel,
    cancelLabel,
    nameLabel = 'Name',
    subtitleLabel = 'Subtitle',
    inputModalClassName,
    isValidName,
    onSave,
    saveLabel,
    hideSubtitle,
    optionProfiles,
    closeMe
  } = props;

  const ModalBanner = SatCoreComponent('ModalBanner');

  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [resourceTemplate, setResourceTemplate] = useState(null);

  const [errorMsgHeader, setErrorMsgHeader] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const [name, setName] = useState(props.name ? props.name : '');
  const [subtitle, setSubtitle] = useState(props.subtitle ? props.subtitle : '');

  const handleCancel = () => {
    setName(props.name);
    setSubtitle(props.subtitle);
    setIsOpen(false);
    onSave(false);
    if (closeMe) {
      closeMe();
    }
  };

  const handleChangeName = (_event, { value }) => {
    if (!value || !value.length || value.trim().length < 1) {
      setErrorMsgHeader('Name is required.');
      setErrorMsg(null);
    } else {
      setErrorMsgHeader(null);
      setErrorMsg(null);
    }
    const MAX_CHAR_LENGTH = 200;
    if (!value || value.length <= MAX_CHAR_LENGTH) {
      setName(value);
    }
  };

  const handleChangeSubtitle = (_event, { value }) => {
    const MAX_CHAR_LENGTH = 65000;
    if (!value || value.length <= MAX_CHAR_LENGTH) {
      setSubtitle(value);
    }
  };

  const handleChangeResourceTemplate = (_event, { value }) => {
    if (!value) {
      setErrorMsgHeader('Option Profile is Required');
      setErrorMsg(null);
    } else {
      setErrorMsgHeader(null);
      setErrorMsg(null);
    }
    setResourceTemplate(value);
  };

  useEffect(() => {
    if (optionProfiles.length > 0 && resourceTemplate === null) {
      setResourceTemplate(optionProfiles[0].value);
    }
  }, [optionProfiles.length]);

  return (
    <Modal
      className={inputModalClassName || 'default-input-modal'}
      closeOnDimmerClick={false}
      closeOnEscape={true}
      onClose={() => {
        handleCancel();
      }}
      open={isOpen}
      size='tiny'>
      <ModalBanner
        label={bannerLabel}
        onClose={() => {
          handleCancel();
        }} />
      <Modal.Content>
        <div className='input-modal-name-wrapper'>
          <div className='input-modal-name-label'>
            {nameLabel}
            <sup>*</sup>
          </div>
          <Input
            className='input-modal-name'
            onChange={handleChangeName}
            placeholder='Name'
            value={name} />
        </div>
        {(!props.hideSubtitle) && (
          <div className='input-modal-subtitle-wrapper' style={{ marginBottom: '16px' }}>
            <div className='input-modal-subtitle-label'>
              {subtitleLabel} (optional)
            </div>
            <Input
              className='input-modal-subtitle'
              onChange={handleChangeSubtitle}
              placeholder={subtitleLabel || 'Subtitle'}
              value={subtitle} />
          </div>

        )}
        <div className='input-modal-subtitle-wrapper'>
          <div className='input-modal-subtitle-label'>Select Option Profile</div>
          <Dropdown
            fluid
            name='template-select'
            onChange={handleChangeResourceTemplate}
            options={optionProfiles}
            placeholder='Select Option Profile'
            search
            selection
            value={resourceTemplate} />
        </div>
        {errorMsgHeader && (
          <Message
            className='input-modal-error-msg'
            content={errorMsg}
            error
            header={errorMsgHeader}
            onDismiss={() => {
              setErrorMsgHeader(null);
              setErrorMsg(null);
            }}
            visible />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          className='input-modal-btn-cancel'
          content={cancelLabel}
          onClick={handleCancel}
          primary />
        <Button
          className='input-modal-btn-save'
          content={saveLabel}
          onClick={() => {
            isValidName(name)
              .then((isValidNameTrue) => {
                if (name && !isValidNameTrue) {
                  setErrorMsgHeader('This title is already in use.');
                  setErrorMsg('Select a different title.');
                } else if (name.trim().length < 1) {
                  setName('');
                  setErrorMsgHeader('Name is required.');
                  setErrorMsg(null);
                } else if (!resourceTemplate) {
                  setErrorMsgHeader('Option Profile is required');
                  setErrorMsg(null);
                } else if (name && resourceTemplate) {
                  onSave(true, name, subtitle, resourceTemplate);
                  setErrorMsgHeader(null);
                  setErrorMsg(null);
                  setIsOpen(false);
                } else {
                  setErrorMsgHeader('Name is required.');
                  setErrorMsg(null);
                }
              });
          }}
          primary />
      </Modal.Actions>
    </Modal>
  );
});
export default InputModalWithProfiles;
