import accommodationsManager from '../managers/AccommodationsManager';
import userManager from '../managers/UserManager';

export default class UserOptionsService {
  static async fetchMyUserOptions() {
    await userManager.fetchMyUserOptions();
  }

  static async getUserOptions() {
    let textHelpEnabled = null;
    if (userManager.showTextHelpForAll && userManager.publisherTextHelpActive) {
      textHelpEnabled = true;
    } else {
      textHelpEnabled = userManager.textHelpEnabled;
    }

    const options = {
      includeClassroomAccommodations: accommodationsManager.includeClassroomAccommodations,
      publisherTextHelpActive: userManager.publisherTextHelpActive,
      textHelpEnabled,
    };
    return options;
  }
}
