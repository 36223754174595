import React, { useContext, useEffect, useState } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import ReactModalDraggable from 'react-modal-resizable-draggable';

import classNames from 'classnames';

import '../../css/ClassroomSelectionModal.less';
import '../../css/ModalBannerDraggable.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { renderButton } from '../../utils';

const ClassroomSelectionModal = observer((props = {}) => {
  const {
    classrooms,
    isOpen,
    label = '',
    onClose = () => {},
    onOpen = () => {},
    onRequestClose, t
  } = props;

  const {
    courseManager, teacherProductViewManager
  } = useContext(MobXProviderContext);

  const [selectedClassroomsMap, setSelectedClassroomsMap] = useState(new Map());

  const [courseClassroomsMap, setCourseClassroomsMap] = useState(new Map());

  const ClassroomSelectionCard = SatCoreComponent('ClassroomSelectionCard');
  const ModalBannerDraggable = SatCoreComponent('ModalBannerDraggable');

  const CLASSROOM_SELECTION_MODAL_INIT_WIDTH = classrooms?.length ? 905 : 460;
  const CLASSROOM_SELECTION_MODAL_BODY_INIT_HEIGHT = classrooms?.length ? 690 : 225;

  const CLASSROOM_SELECTION_MODAL_TOP = window.innerHeight < 925 ? 60 : undefined;

  const CLASSROOM_SELECTION_MODAL_MIN_WIDTH = classrooms?.length ? 905 : 460;
  const CLASSROOM_SELECTION_MODAL_BODY_MIN_HEIGHT = classrooms?.length ? 267 : 225;

  useEffect(() => {
    (async () => {
      if (isOpen && teacherProductViewManager.currentTeacherProductCourse) {
        const course = teacherProductViewManager.currentTeacherProductCourse;

        const map = await courseManager.fetchCourseClassrooms(course.contentItemId || course.id);
        setCourseClassroomsMap(new Map(map));
      }
    })();
  }, []);

  /**
   * @param {import('react').SyntheticEvent} event
   * @param {{}} classroom
   */
  const handleToggleClassroom = (event, classroom) => {
    if (event?.target?.checked) {
      const map = new Map(selectedClassroomsMap);
      map.set(classroom.id, classroom);
      setSelectedClassroomsMap(map);
    } else {
      const map = new Map(selectedClassroomsMap);
      map.delete(classroom.id);
      setSelectedClassroomsMap(map);
    }
  };

  const renderClassroomSelectionModal = () => {
    return (
      <ReactModalDraggable
        className='ClassroomSelectionModal'
        closeOnDimmerClick={false}
        closeOnEscape={true}
        initHeight={CLASSROOM_SELECTION_MODAL_BODY_INIT_HEIGHT}
        initWidth={CLASSROOM_SELECTION_MODAL_INIT_WIDTH}
        isOpen={isOpen}
        minHeight={CLASSROOM_SELECTION_MODAL_BODY_MIN_HEIGHT}
        minWidth={CLASSROOM_SELECTION_MODAL_MIN_WIDTH}
        onClose={onClose}
        onOpen={onOpen}
        onRequestClose={onRequestClose || onClose}
        top={CLASSROOM_SELECTION_MODAL_TOP}>
        <div className='classroom-selection-modal-wrapper'>
          {renderClassroomSelectionModalBanner()}
          {renderClassroomSelectionModalBody()}
          {renderClassroomSelectionModalActions()}
        </div>
      </ReactModalDraggable>
    );
  };

  const renderClassroomSelectionModalBanner = () => {
    return (
      <div className='classroom-selection-modal-banner'>
        <ModalBannerDraggable
          label={label}
          onClose={onClose} />
      </div>
    );
  };

  const renderClassroomSelectionModalBody = () => {
    return (
      <div className='classroom-selection-modal-body'>
        <div className={classNames('classroom-selection-cards', {
          'classroom-selection-cards-zero-state': !classrooms?.length
        })}>
          {classrooms?.length ? classrooms.map((classroom) => {
            return (
              <ClassroomSelectionCard
                key={classroom.id}
                checked={selectedClassroomsMap.has(classroom.id) || courseClassroomsMap.has(classroom.id)}
                classroom={classroom}
                disabled={!selectedClassroomsMap.has(classroom.id) && courseClassroomsMap.has(classroom.id)}
                handleToggleClassroom={handleToggleClassroom} />
            );
          }) : (
            t('classroomSelectionCardsZeroState')
          )}
        </div>
      </div>
    );
  };

  const renderClassroomSelectionModalActions = () => {
    const onCloseActionFn = (event, buttonActionObj) => {
      return onClose(event, buttonActionObj, selectedClassroomsMap);
    };
    return (
      <div className='classroom-selection-modal-actions'>
        {!!classrooms?.length && renderButton({
          additionalClassNames: 'button-basic-bg',
          basic: true,
          buttonKey: 'cancel',
          onClick: onClose,
          primary: true
        })}
        {renderButton({
          additionalClassNames: 'button-primary-bg',
          buttonKey: classrooms?.length ? 'add' : 'ok',
          onClick: onCloseActionFn,
          primary: true
        })}
      </div>
    );
  };

  return renderClassroomSelectionModal();
});

export default ClassroomSelectionModal;

SatCoreRegister('ClassroomSelectionModal', ClassroomSelectionModal);
