import { ItemBankService, userManager } from 'sat-core';

ItemBankService.sortLibraryCardKebabOptions = (options) => {
    if (userManager.isTeacher) {
        const customOptions = {};
        if (options.assign) {
            customOptions['assign'] = options.assign; // This option can be turned off in index.js
        }
        if (options.edit) {
            customOptions['edit'] = options.edit;
        }
        if (options.details) {
            customOptions['details'] = options.details;
        }
        if (options.preview) {
            customOptions['preview'] = options.preview; // This option can be turned off in index.js
        }
        if (options.printPreview) {
            customOptions['printPreview'] = options.printPreview; // This option can be turned off in index.js
        } 
        if (options.printPreviewWithAnswers) {
            customOptions['printPreviewWithAnswers'] = options.printPreviewWithAnswers; // This option can be turned off in index.js
        }
        if (options.copy) {        
            customOptions['copy'] = options.copy;
        }
        if (options.delete) {
            customOptions['delete'] = options.delete;
        }
        return customOptions;
    } else {
        return options;
    }
}
