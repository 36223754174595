import { action, computed, makeObservable, observable } from 'mobx';

import { getRegisteredClass } from '../../SatCoreRegistry';

import Auth from '../AuthManager';
import reportIdentityManager from './ReportIdentityManager';

import { getSessionStorageItem, setSessionStorageItem } from '../../utils';

import { REPORT_ASSIGNMENT_TYPE, REPORT_ATTRIBUTE_FILTER_TYPE, REPORT_CONTENT_TYPE } from '../../services/reports/ReportConstants';

const REPORT_STANDARDS_ENDPOINTS = {
  FETCH_REPORT_CMAP_NAMES_BY_INSTITUTION: '/api/viewInstitutionCurriculumMaps'
};

export class ReportStandardsManager {
  constructor() {
    makeObservable(this);
  }

  @observable loadingReportCmapObjs = false;

  /** @param {Map<string, import('../../services/reports/ReportJsonHelperService').ReportCmapObj>} reportCmapObjsByInstitutionMap */
  @observable reportCmapObjsByInstitutionMap = new Map();

  @observable selectedReportCmapContentItemId = getSessionStorageItem('selectedReportCmapContentItemId');

  @observable selectedReportCmapObj = {
    contentItemId: getSessionStorageItem('selectedReportCmapContentItemId'),
    cutScore: getSessionStorageItem('selectedReportCmapCutScore'),
    id: getSessionStorageItem('selectedReportCmapId'),
    name: getSessionStorageItem('selectedReportCmapName')
  }

  @observable selectedReportAttributeFilterType = getSessionStorageItem(
    'selectedReportAttributeFilterType') || REPORT_ATTRIBUTE_FILTER_TYPE.ALL;

  // TODO remove
  // @observable selectedReportMoreFiltersContentType = getSessionStorageItem(
  //   'selectedReportMoreFiltersContentType') || REPORT_CONTENT_TYPE.ALL;

  // TODO this is **always** `REPORT_CONTENT_TYPE.ALL` at the moment
  @observable selectedReportMoreFiltersContentType = REPORT_CONTENT_TYPE.ALL;

  @observable selectedReportMoreFiltersAssignmentType = getSessionStorageItem(
    'selectedReportMoreFiltersAssignmentType') || REPORT_ASSIGNMENT_TYPE.ALL;

  @observable showCutScoreAssignType = false;

  @action clearAllExceptSession = () => {
    this.setLoadingReportCmapObjs(false);
    this.setReportCmapsByInstitutionMap(null);
  }

  @action clearSession = () => {
    // TODO unused // this.setSelectedReportMoreFiltersContentType(REPORT_CONTENT_TYPE.ALL);
    this.setSelectedReportAttributeFilterType(REPORT_ATTRIBUTE_FILTER_TYPE.ALL);
    this.setSelectedReportCmapInfo(null, null);
    this.setSelectedReportMoreFiltersAssignmentType(REPORT_ASSIGNMENT_TYPE.ALL);
  }

  @action fetchReportCmapObjsByInstitutionMap = async ({
    cmapId,
    institutionId = null,
    shouldFetch = true,
    shouldSetFirstInMapAsSelectedIfNoneSelectedYet = false
  } = {}) => {
    try {
      if (!shouldFetch) {
        return;
      }
      this.setLoadingReportCmapObjs(true);
      this.setReportCmapsByInstitutionMap(null);
      institutionId = institutionId || reportIdentityManager.activeReportInstitutionId;

      let apiUrl = `${Auth.ecms}${REPORT_STANDARDS_ENDPOINTS.FETCH_REPORT_CMAP_NAMES_BY_INSTITUTION}`;
      apiUrl += `?institutionId=${institutionId}`;
      apiUrl += `&curriculumMapId=${cmapId}`;
      apiUrl += '&reactErrorType=true';

      const response = await Auth.fetch(apiUrl, { method: 'GET' });

      if (response?.status === 'SUCCESS') {
        this.setReportCmapsByInstitutionMap(response.data, {
          shouldSetFirstInMapAsSelectedIfNoneSelectedYet
        });
      } else {
        console.error(response);
      }
      this.setLoadingReportCmapObjs(false);
    } catch (error) {
      console.error(error);
    }
  }

  @action setLoadingReportCmapObjs = (toggle) => {
    this.loadingReportCmapObjs = toggle;
  }

  @action setReportCmapsByInstitutionMap = async (reportCmapInfoArrOrObj, {
    shouldSetFirstInMapAsSelectedIfNoneSelectedYet = false
  } = {}) => {
    const cmapObjsMap = new Map();
    if (!reportCmapInfoArrOrObj) {
      this.reportCmapObjsByInstitutionMap = new Map();
    } else if (!Array.isArray(reportCmapInfoArrOrObj)) {
      // admin standards reports
      for (const prop in reportCmapInfoArrOrObj) {
        if (typeof reportCmapInfoArrOrObj[prop] === 'string') {
          cmapObjsMap.set(prop, {
            contentItemId: prop,
            cutScore: null,
            id: null,
            name: reportCmapInfoArrOrObj[prop]
          });
        }
      }
      await this.initSelectedReportCmapInfo({
        cmapObjsMap,
        shouldSetFirstInMapAsSelectedIfNoneSelectedYet
      });
    } else if (Array.isArray(reportCmapInfoArrOrObj)) {
      // classroom (teacher) standards reports
      for (const obj of reportCmapInfoArrOrObj) {
        if (obj.contentItemId && obj.name) {
          const { contentItemId, cutScore, id, name } = obj;
          cmapObjsMap.set(contentItemId, {
            contentItemId,
            cutScore,
            id,
            name
          });
        }
      }
      await this.initSelectedReportCmapInfo({
        cmapObjsMap,
        shouldSetFirstInMapAsSelectedIfNoneSelectedYet
      });
    }
  };

  @action initSelectedReportCmapInfo = async ({
    cmapObjsMap,
    shouldSetFirstInMapAsSelectedIfNoneSelectedYet
  } = {}) => {
    const selectedKey = this.selectedReportCmapContentItemId;
    if (shouldSetFirstInMapAsSelectedIfNoneSelectedYet || selectedKey) {
      const ReportTypeService = getRegisteredClass('ReportTypeService');
      if ((!selectedKey && shouldSetFirstInMapAsSelectedIfNoneSelectedYet) || cmapObjsMap.size === 1) {
        // set first in map as selected if `!selectedKey && shouldSetFirstInMapAsSelectedIfNoneSelectedYet`,
        // OR if there is currently only one cmap in the map
        const [cmapId, cmapObj] = Array.from(cmapObjsMap.entries())[0];
        this.setSelectedReportCmapInfo(cmapId, cmapObj);

        await ReportTypeService.initReportTableData();
        this.reportCmapObjsByInstitutionMap = cmapObjsMap;
      } else if (!cmapObjsMap.has(selectedKey)) {
        // clear out the current selected cmap since it is no longer in the map
        // eslint-disable-next-line no-alert
        alert('Note: The report for the selected standard was not found.');
        this.setSelectedReportCmapInfo(null, null);
        await ReportTypeService.initReportTableData();
        this.reportCmapObjsByInstitutionMap = cmapObjsMap;
      } else {
        this.reportCmapObjsByInstitutionMap = cmapObjsMap;
      }
    } else {
      this.reportCmapObjsByInstitutionMap = cmapObjsMap;
    }
  }

  @action removeCmapEntryFromMap = (cmapContentItemId) => {
    this.reportCmapObjsByInstitutionMap.delete(cmapContentItemId);
  }

  @action setSelectedReportCmapInfo = (cmapContentItemId, cmapObj) => {
    setSessionStorageItem('selectedReportCmapContentItemId', cmapContentItemId);
    setSessionStorageItem('selectedReportCmapCutScore', cmapObj?.cutScore);
    setSessionStorageItem('selectedReportCmapId', cmapObj?.id);
    setSessionStorageItem('selectedReportCmapName', cmapObj?.name);

    this.selectedReportCmapContentItemId = cmapContentItemId || null;
    this.selectedReportCmapObj = cmapObj || null;
  }

  @action setSelectedReportAttributeFilterType = (reportAttributeFilterType) => {
    setSessionStorageItem('selectedReportAttributeFilterType', reportAttributeFilterType);
    this.selectedReportAttributeFilterType = reportAttributeFilterType || REPORT_ATTRIBUTE_FILTER_TYPE.ALL;
  }

  // TODO unused
  // @action setSelectedReportMoreFiltersContentType = (reportContentType) => {
  //   setSessionStorageItem('selectedReportMoreFiltersContentType', reportContentType);
  //   this.selectedReportMoreFiltersContentType = reportContentType || REPORT_CONTENT_TYPE.ALL;
  // }

  @action setSelectedReportMoreFiltersAssignmentType = (reportAssignmentType) => {
    setSessionStorageItem('selectedReportMoreFiltersAssignmentType', reportAssignmentType);
    this.selectedReportMoreFiltersAssignmentType = reportAssignmentType || REPORT_ASSIGNMENT_TYPE.ALL;
  }

  @action setShowCutScoreAssignType = (showCutScoreAssignType) => {
    this.showCutScoreAssignType = showCutScoreAssignType;
  }

  @computed get reportStandardsDropdownOptions() {
    const keys = Array.from(this.reportCmapObjsByInstitutionMap.keys());
    const options = keys.map((cmapContentItemId) => {
      return {
        text: this.reportCmapObjsByInstitutionMap.get(cmapContentItemId)?.name,
        value: cmapContentItemId
      };
    });
    return options;
  }

  @computed get computedSelectedReportCmapObj() {
    const ReportJsonHelperService = getRegisteredClass('ReportJsonHelperService');
    const selectedReportCmapContentItemId = this.selectedReportCmapObj?.contentItemId;
    if (!selectedReportCmapContentItemId) {
      return undefined;
    } else {
      const cmaps = ReportJsonHelperService.REPORT_CMAPS();
      const selectedReportCmapObj = cmaps?.find((cmap) => cmap.contentItemId === selectedReportCmapContentItemId);
      return selectedReportCmapObj || this.selectedReportCmapObj;
    }
  }

  /** @returns {string[]} `reportCmapContentItemIds` */
  @computed get reportCmapContentItemIds() {
    return Array.from(this.reportCmapObjsByInstitutionMap.keys());
  }

  /** @returns {import('../../services/reports/ReportJsonHelperService').ReportCmapObj} `reportCmapObj[]` */
  @computed get reportCmapObjs() {
    return Array.from(this.reportCmapObjsByInstitutionMap.values()).map((obj) => obj);
  }

  /** @returns {string[]} `reportCmapNames` */
  @computed get reportCmapNames() {
    return Array.from(this.reportCmapObjsByInstitutionMap.values()).map((obj) => obj.name);
  }

  // TODO remove // this is no longer used
  // @computed get standardsDocumentStringValue() {
  //   return !!(this.standardsDocumentArray?.length) && this.standardsDocumentArray.join('; ');
  // }
  // @computed get standardsDocumentArray() {
  //   const ReportJsonHelperService = getRegisteredClass('ReportJsonHelperService');
  //   const contentJsonData = ReportJsonHelperService.REPORT_CONTENT_JSON_DATA();
  //   return (contentJsonData?.standardDocuments) || [];
  // }
}

export default new ReportStandardsManager();
