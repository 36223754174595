import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Dropdown, Menu } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

export default
@inject('classroomManager', 'topNavManager', 'userManager')
@observer
class ClassMenu extends Component {
  constructor(props) {
    super(props);
    this.DropdownObserver = SatCoreComponent('DropdownObserver');
  }

  render() {
    const { DropdownObserver } = this;

    const {
      classroomManager,
      handleChangeStudentClassroom,
      handleJoinClass,
      joinClassLabel,
      optionTitleLabel,
      t,
      topNavManager,
      userManager
    } = this.props;
    const { currentClassroomId } = classroomManager;
    const classLabel = currentClassroomId ? classroomManager.getClassName(currentClassroomId) : joinClassLabel;
    return (
      <Menu.Item
        as='a'
        className='tnav-tab class-menu'
        onClick={() => {
          // placeholder
        }}
        onMouseEnter={() => {
          topNavManager.setIsTopNavClassMenuOpen(true);
        }}
        onMouseLeave={() => {
          topNavManager.setIsTopNavClassMenuOpen(false);
        }}
        role='tab'
        tabIndex='-1'>
        <div className='aria-offscreen' id='class-menu-instructions'>
          {topNavManager.isTopNavClassMenuOpen ? t('ariaClassMenuOpenInstructions') : t('ariaClassMenuClosedInstructions')}
        </div>
        <DropdownObserver>
          <Dropdown
            aria-describedby='class-menu-instructions'
            aria-expanded={topNavManager.isTopNavClassMenuOpen}
            aria-haspopup='menu'
            aria-label={t('ariaClassMenuDropdownLabel', { classLabel })}
            as={(props) => <div {...props} role='menu' />}
            className='class-selector'
            closeOnChange
            icon='caret down'
            open={topNavManager.isTopNavClassMenuOpen}
            options={[
              {
                as: (props) => <div {...props} aria-label={joinClassLabel} role='menuitem' tabIndex='-1' />,
                className: 'switch-option',
                key: 'classO_switch',
                onClick: handleJoinClass,
                text: (
                  <>
                    <span className='text'>{optionTitleLabel}</span>
                    {userManager.isSsoUser || (
                      <Button className='join-class'
                        onClick={handleJoinClass}
                        primary
                        tabIndex={topNavManager.isTopNavClassMenuOpen ? '0' : '-1'}
                        type='button'>
                        {joinClassLabel}
                      </Button>
                    )}
                  </>
                )
              },
              ...classroomManager.classroomsArray.map(({ id }) => {
                const classroomName = classroomManager.getClassName(id);
                return ({
                  as: (props) => (
                    <div {...props}
                      aria-label={classroomName} role='menuitem' tabIndex={topNavManager.isTopNavClassMenuOpen ? '0' : '-1'} />
                  ),
                  key: `classO_${id}`,
                  onClick: async (_event, _data) => {
                    return handleChangeStudentClassroom(id);
                  },
                  text: classroomName,
                  value: id
                });
              })
            ]}
            selectOnBlur={false}
            simple
            tabIndex='0'
            trigger={<div className='class-dropdown-selection' tabIndex='-1'>{classLabel}</div>}
            value={currentClassroomId} />
        </DropdownObserver>
      </Menu.Item>
    );
  }
}

SatCoreRegister('ClassMenu', ClassMenu);
