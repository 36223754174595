import { pickBy } from 'lodash';
import BaseApi from './BaseApi';

export const CONTENT_ITEM_ENDPOINTS = {
  CHECK_NAME: '/api/checkContentItemNameAddress',
  DEEP_CLONE: '/api/deepCloneContentItem',
  DELETE: '/api/deleteContentItem',
  DOWNLOAD: '/api/downloadResource',
  LIST: '/api/searchContentItemsByClassroomWorkspace',
  REPLACE: '/api/replaceFileResource',
  UPDATE_NAME: '/api/updateContentItemName',
  UPLOAD: '/api/uploadFileResource',
  VERSION: '/api/versionContentItem',
};

export class ContentItemApi extends BaseApi {
  async list(
    {
      classroomId,
      searchScope = 'all',
      searchTypes = 'audio_resource,image_resource,pdf_resource,file_resource,video_resource',
      page = 1,
      pageSize = 25,
      sort = [{ field: 'sortName', dir: 'asc' }],
      ...params
    } = {}
  ) {
    const body = pickBy({ classroomId, searchScope, searchTypes, skip: pageSize * (page - 1), pageSize, sort, ...params }, (value) => value);
    const url = this.getUrl(CONTENT_ITEM_ENDPOINTS.LIST);
    const data = await this.post(url, { body });
    if (data) {
      data.contentItems = data.contentItems.filter(({ id }) => id);
      data.page = parseInt(page);
      data.totalPages = Math.ceil(parseInt(data.pageTotal) / pageSize);
    }
    return data;
  }

  async upload(body) {
    const url = this.getUrl(CONTENT_ITEM_ENDPOINTS.UPLOAD);
    return await this.post(url, { body, multipart: true });
  }

  async replace(id, { file }) {
    const url = this.getUrl(CONTENT_ITEM_ENDPOINTS.REPLACE);
    return await this.put(url, { body: { contentItemId: id, file }, multipart: true });
  }

  async updateName(id, { name }) {
    const url = this.getUrl(CONTENT_ITEM_ENDPOINTS.UPDATE_NAME);
    return await this.post(url, { body: { id, name }, throwError: true })
      .then((result) => ({ result, exist: false }))
      .catch((error) => ({ result: false, exist: error?.response?.response?.status === 422 }));
  }

  async delete(id) {
    const url = this.getUrl(CONTENT_ITEM_ENDPOINTS.DELETE);
    return await this.post(url, { body: { id } });
  }

  async checkName({ name, entityTypeId }) {
    const url = this.getUrl(CONTENT_ITEM_ENDPOINTS.CHECK_NAME);
    return await this.post(url, { body: { entityTypeId, contentName: name } });
  }

  async versionContentItem({ contentItemId, name, color, workspaceId, googleFolderId }) {
    const url = this.getUrl(CONTENT_ITEM_ENDPOINTS.VERSION);
    const body = {
      contentItemId,
      name,
      color,
      workspaceId,
      googleFolderId
    };
    return await this.post(url, { body });
  }

  async deepCloneContentItem(contentItemId, prefix, workspaceId) {
    const url = this.getUrl(CONTENT_ITEM_ENDPOINTS.DEEP_CLONE);
    const body = {
      contentItemId,
      prefix,
      workspaceId
    };
    return await this.post(url, { body });
  }
}

export default new ContentItemApi();
