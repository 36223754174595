import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Redirect, Route } from 'react-router-dom';

import { Container } from 'semantic-ui-react';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import '../css/MainView.less';

// import ClassroomService from '../services/ClassroomService';
import InitService from '../services/InitService';
// import ThirdPartyService from '../services/ThirdPartyService';
import UserService from '../services/UserService';

import { ImpersonationCautionBanner } from '../components/ImpersonationCautionBanner';

export default
@inject(
  'accessManager', 'accommodationsManager', 'appManager', 'assignmentManager',
  'classroomManager', 'navigationManager', 'productManager',
  'teacherProductViewManager', 'userManager')
@observer
class LTIView extends Component {
  constructor(props) {
    super(props);

    this.AccommodationsView = SatCoreComponent('AccommodationsView');
    this.AdminSchoolsView = SatCoreComponent('AdminSchoolsView');
    this.AssignmentView = SatCoreComponent('AssignmentView');
    this.ClassCourses = SatCoreComponent('ClassCourses');
    this.ClassDetails = SatCoreComponent('ClassDetails');
    this.ClassRoster = SatCoreComponent('ClassRoster');
    this.ClassSettingsView = SatCoreComponent('ClassSettingsView');
    this.ClassWorkspace = SatCoreComponent('ClassWorkspace');
    this.DialogList = SatCoreComponent('DialogList');
    this.EditStudentProfile = SatCoreComponent('EditStudentProfile');
    this.Gradebook = SatCoreComponent('Gradebook');
    this.GroupRoster = SatCoreComponent('GroupRoster');
    this.LTIPlayerLauncher = SatCoreComponent('LTIPlayerLauncher');
    this.LearnosityPrintPreview = SatCoreComponent('LearnosityPrintPreview');
    this.NotificationsView = SatCoreComponent('NotificationsView');
    this.Profile = SatCoreComponent('Profile');
    this.ProgressView = SatCoreComponent('ProgressView');
    this.PublisherCourse = SatCoreComponent('PublisherCourse');
    this.ResourcesView = SatCoreComponent('ResourcesView');
    this.RoleSwitcherView = SatCoreComponent('RoleSwitcherView');
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');
    this.StudentAssignmentLauncher = SatCoreComponent('StudentAssignmentLauncher');
    this.StudentAssignmentView = SatCoreComponent('StudentAssignmentView');
    this.StudentTopNav = SatCoreComponent('StudentTopNav');
    this.TeacherProductView = SatCoreComponent('TeacherProductView');
    this.TopNav = SatCoreComponent('TopNav');
    this.TrialBanner = SatCoreComponent('TrialBanner');
    this.Verification = SatCoreComponent('Verification');
  }

  async componentDidMount() {
    const {
      assignmentManager, classroomManager, userManager,
    } = this.props;

    assignmentManager.isLtiLaunched = true;
    const urlParams = new URLSearchParams(window.location.search);

    let authKey = null;
    if (urlParams.has('authKey') && urlParams.get('authKey') !== 'null') {
      authKey = urlParams.get('authKey');
      await userManager.authKeyLogIn(authKey);
      // If user has multiple roles and has the teacher_user role, set the active role to teacher_user.
      // If this isn't done, no courses are loaded on the courses page when LTI launched.
      if (!userManager.activePermissionId) {
        const roles = UserService.getAvailableRoles();
        if (roles.includes('teacher_user')) {
          userManager.setActivePermissionId('teacher_user');
        }
      }
    }

    if (!classroomManager.currentClassroomId) {
      const classroomId = urlParams.get('classroomId');
      classroomManager.setCurrentClassroomId(classroomId);
      if (!classroomManager.loaded) {
        await classroomManager.fetchClassroomData(classroomId);
      }
    }

    InitService.doLoginInit();
    await InitService.initData(urlParams);
    await userManager.trackLoggedIn();
  }

  getAccessRedirects = () => {
    const {
      userManager: { isStudent },
      accessManager: {
        policies: { mainView: { classDetails, classCourses, resourcesView, assignmentView, studentAssignmentView, progressView } }
      }
    } = this.props;
    return isStudent ? (
      <>
        {!classDetails && <Redirect path='/class' to='/' />}
        {!classCourses && <Redirect path='/courses' to='/' />}
        {!resourcesView && <Redirect exact path='/resources' to='/' />}
        {!assignmentView && <Redirect exact from='/assignments' to='/' />}
        {!studentAssignmentView && <Redirect exact path='/student-assignments' to='/' />}
        {!progressView && <Redirect exact path='/progress' to='/' />}
      </>
    ) : null;
  };

  getRoutes = () => {
    const {
      appManager, teacherProductViewManager, userManager
    } = this.props;

    const {
      isDistrictAdmin, isProductAdmin, isSchoolAdmin, isStudent, isTeacher
    } = userManager;

    const isAdmin = isDistrictAdmin || isSchoolAdmin;
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.getRoutes !== undefined) {
      // eslint-disable-next-line react/destructuring-assignment
      return this.props.getRoutes(this.props, this);
    }

    const {
      // ClassCourses, AssignmentView, StudentAssignmentView, Gradebook, StudentAssignmentLauncher
      AccommodationsView, AssignmentView, ClassCourses, ClassDetails, ClassRoster,
      ClassSettingsView, ClassWorkspace, EditStudentProfile, Gradebook, GroupRoster, LTIPlayerLauncher,
      LearnosityPrintPreview, NotificationsView, Profile, ProgressView, PublisherCourse,
      ResourcesView, RoleSwitcherView, StudentAssignmentLauncher, StudentAssignmentView, TeacherProductView,
      Verification
    } = this;

    let adminOrTeacherRoutes = <></>;
    if (isAdmin || isTeacher) {
      const { accommodationsManager } = this.props;
      adminOrTeacherRoutes = (
        <>
          {accommodationsManager.includeClassroomAccommodations && <Route component={AccommodationsView} path='/accommodations' />}
          <Route component={EditStudentProfile} exact path='/editStudentProfile' />
          <Route component={ClassRoster} path='/roster' />
          <Route component={ClassSettingsView} path='/settings' />
          <Route component={GroupRoster} path='/groups' />
        </>
      );
    }

    return (
      <>
        {this.getAccessRedirects()}
        <Route component={ClassDetails} path='/class' />
        <Route component={ClassCourses} path='/lti-courses' />
        <Route component={ResourcesView} exact path='/resources' />
        <Route component={AssignmentView} exact path='/assignments' />
        <Route component={StudentAssignmentView} exact path='/student-assignments' />
        {appManager.classWorkspaceFeature && <Route component={ClassWorkspace} exact path='/workspace' />}
        {(isTeacher) && (<Route component={Gradebook} exact path='/gradebook' />)}

        {isTeacher && teacherProductViewManager.allowTeacherProductView && (
          <>
            <Route component={TeacherProductView} path='/products' />
          </>
        )}

        {(isTeacher && !isAdmin && !isProductAdmin) && (
          <>
            <Route component={LearnosityPrintPreview} path='/lrnPrint' />
          </>
        )}

        <Route component={Profile} exact path='/profile' />
        <Route component={Verification} exact path='/verification' />
        <Route component={PublisherCourse} exact path='/publisherCourse' />
        {(isStudent) && (<Route component={ProgressView} path='/progress' />)}

        {/* all users except students get notifications Route */}
        {(!isStudent) && (<Route component={NotificationsView} path='/notifications' />)}

        <Route component={LTIPlayerLauncher} exact path='/ltiPlayer' />
        <Route component={StudentAssignmentLauncher} exact path='/launch' />

        {adminOrTeacherRoutes}

        <Route component={RoleSwitcherView} exact path='/switch-role' />
      </>
    );
  }

  getTopNav = () => {
    const { userManager } = this.props;
    const { isTeacher } = userManager;

    if (isTeacher) {
      return this.TopNav;
    }
    return this.StudentTopNav;
  }

  renderTrialBanner() {
    const { TrialBanner } = this;
    const { appManager, userManager } = this.props;
    return appManager.trialFeature && userManager.isLicensedByPurchaseCode ? <TrialBanner /> : null;
  }

  render() {
    const {
      classroomManager, getNavTabs, navigationManager,
      setCurrentClassroomId, topNav, userManager
    } = this.props;
    const { isStudent } = userManager;

    const TopNav = this.getTopNav();

    if (!userManager.isImpersonatorAdmin) {
      if (sessionStorage.getItem('impersonate')) {
        userManager.setIsImpersonatorAdmin(true);
      }
    }

    // This code pattern seems a little verbose and boiler-plate
    // but this is the only way to ensure that a new component isn't created
    // every time render is called and this also ensures the 'this' pointer
    // is not corrupted as the component tree gets large.
    const { DialogList, SatCoreLoader } = this;

    return (classroomManager.loaded && userManager.loaded ? (
      <>
        <Container
          className={`fullHeight main-view${isStudent ? ' student-client' : ''}`}
          fluid>
          <TopNav
            additionalClassName={!userManager.activePermissionId ? 'no-role' : ''}
            getNavTabs={getNavTabs}
            setCurrentClassroomId={setCurrentClassroomId}
            {...topNav} />
          {userManager.isImpersonatorAdmin && <ImpersonationCautionBanner />}
          <Container className='content-area' fluid>
            <div className={`nav-separator ${navigationManager.viewSelection}`} />
            {this.renderTrialBanner()}
            {this.getRoutes()}
          </Container>
          <DialogList />
        </Container>
      </>
    ) :
      <SatCoreLoader />
    );
  }
}

SatCoreRegister('LTIView', LTIView);
