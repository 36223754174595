/* eslint-disable react/jsx-wrap-multilines */
import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { Button, Icon, Image, Popup } from 'semantic-ui-react';

import '../css/GradebookHeader.less';

import iconInfoBlack from '../img/icon-info.svg';
import iconInfoWhite from '../img/icon-info-white.svg';
import assessmentDefaultCardImage from '../img/default-assessment.svg';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { getStyleVar } from '../utils';

import AssignmentService from '../services/AssignmentService';
import EngagementService from '../services/EngagementService';
import GradebookService from '../services/GradebookService';
import ImageService from '../services/ImageService';
import PopupService from '../services/PopupService';
import UtilityService from '../services/UtilityService';

import { customAssessmentDefaultCardImageUri } from '../services/UriService';

const GradebookHeader = observer((props) => {
  const {
    // TODO remove, unused // onClickAggregateGradebookButton,
    assignment,
    assignmentEntityTypeId,
    onClickAssignButton,
    onClickDetailsButton,
    onClickEditBulkAssignmentButton,
    onClickEngagementButton,
    onClickExportGradebook,
    onClickGotoCourse,
    onClickInfoIconButton,
    onClickStandardsButton,
    onClickSummaryButton, t
  } = props;

  const {
    courseManager, dialogManager, engagementManager, gradebookManager, userManager
  } = useContext(MobXProviderContext);
  const { activeGradebookTable, activeGradebookType, useGradebookHeaderResourceInfo } = gradebookManager;

  const ColorKeyCard = SatCoreComponent('ColorKeyCard');
  const EngagementButtonBar = SatCoreComponent('EngagementButtonBar');

  const isReadOnly = GradebookService.isReadOnly();

  const showInfoIconButton = AssignmentService.showAssignmentInfoIconButton(assignment, false);

  const entityTypeId = assignmentEntityTypeId || assignment.contentItemEntityTypeId || assignment.entityTypeId;

  const isAssessment = entityTypeId === 'assessment';
  const isLesson = entityTypeId === 'lesson';
  const isLearnosity = entityTypeId?.includes?.('learnosity');

  const handleClickButton = (event) => {
    const { value } = event.target.dataset;
    // clear selections when changing views.
    gradebookManager.clearBulkCheckedAssignmentMembers();
    switch (value) {
      // TODO remove, unused
      // case 'aggregate':
      //   gradebookManager.setActiveGradebookType('aggregate');
      //   return onClickAggregateGradebookButton();
      case 'summary':
        gradebookManager.setActiveGradebookDetailsHorizontalScoresPage(1);
        gradebookManager.setActiveGradebookTable('summary');
        return onClickSummaryButton();
      case 'details':
        gradebookManager.setActiveGradebookTable('details');
        return onClickDetailsButton();
      case 'engagement':
        gradebookManager.setActiveGradebookTable('engagement');
        engagementManager.clearEngagementTable();
        engagementManager.setActiveEngagementTable('summary');
        return onClickEngagementButton();
      case 'standards':
        gradebookManager.setActiveGradebookTable('standards');
        return onClickStandardsButton();
    }
  };

  const handleClickAssignmentButton = (assignment) => {
    onClickAssignButton(assignment, true); // true for reassign
  };

  const renderAssignmentNameAndDescription = () => {
    const assignmentName = assignment.resourceWebTitle || assignment.name;
    const assignmentDescription = assignment.resourceWebSubtitle;

    const iconInfo = dialogManager.useWhiteInfoIcon ? iconInfoWhite : iconInfoBlack;
    const assignmentNameObj = UtilityService.reactHtmlParserWrapper(assignmentName);
    const assignmentDescriptionObj = UtilityService.reactHtmlParserWrapper(assignmentDescription);
    const contentImageUrl = ImageService.getImageUrl(assignment);

    // Check if the satellite uses the full resource header or just the title/subtitle
    if (useGradebookHeaderResourceInfo) {
      return (
        <div className='assignment-banner'>
          {(contentImageUrl !== null &&
            contentImageUrl !== undefined && contentImageUrl !== '')
            ? <Image alt='' src={contentImageUrl} /> : getDefaultImage()}
          <div className='title-wrapper'>
            <div
              className='flex-header'
              title={assignmentNameObj.stripped}>
              {assignmentNameObj.parsed}
              {showInfoIconButton && (
                <Image
                  alt=''
                  id='info-icon'
                  onClick={onClickInfoIconButton}
                  src={iconInfo} />
              )}
            </div>
            <div
              className='flex-subheader'
              title={assignmentDescriptionObj.stripped}>
              {assignmentDescriptionObj.parsed}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className='header-h1-wrapper'>
            {PopupService.renderPopup({
              content: assignmentNameObj.stripped,
              trigger: (
                <h1 className='header theme-header-title'>
                  {assignmentNameObj.parsed}
                </h1>
              )
            })}
            {showInfoIconButton && (
              <Image
                alt=''
                onClick={onClickInfoIconButton}
                src={iconInfo} />
            )}
          </div>
          {!!assignmentDescription && (
            <div className='header-h3-wrapper'>
              {PopupService.renderPopup({
                content: assignmentDescriptionObj.stripped,
                trigger: (
                  <h3 className='header'>
                    {assignmentDescriptionObj.parsed}
                  </h3>
                )
              })}
            </div>
          )}
        </>
      );
    }
  };

  const getDefaultImage = () => {
    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);

    let customLeafCardImageClassNames = 'default';
    let defaultCustomLeafCardImg = assessmentDefaultCardImage;

    if (courseManager.useSpecialCustomCourseCardImages && isCustomCourse) {
      customLeafCardImageClassNames += 'custom-default-special';
      defaultCustomLeafCardImg = customAssessmentDefaultCardImageUri;
    }
    return (
      <Image
        alt='Custom default lesson image'
        className={customLeafCardImageClassNames}
        src={defaultCustomLeafCardImg} />
    );
  };

  const renderGradebookDetailsColorKeyCard = () => {
    if (activeGradebookTable !== 'details' && activeGradebookTable !== 'standards') {
      return null;
    }

    const allowAccessibilityTriangle = gradebookManager.allowFacultyGradebookAccessibility;

    const colorKeyDataArray = [
      {
        color: getStyleVar('--gradebook-detail-full-credit'),
        colorSquareClassName: classNames('full-credit', {
          'accessibility-triangle': allowAccessibilityTriangle
        }),
        label: 'fullCredit'
      },
      {
        color: getStyleVar('--gradebook-detail-partial-credit'),
        colorSquareClassName: classNames('partial-credit', {
          'accessibility-triangle': allowAccessibilityTriangle
        }),
        label: 'partialCredit'
      },
      {
        color: getStyleVar('--gradebook-detail-no-credit'),
        colorSquareClassName: 'no-credit',
        label: 'noCredit'
      }
    ];
    const iconKeyDataArray = [
      {
        color: 'transparent',
        icon: <span className='clickable link-color'>—</span>,
        colorSquareClassName: classNames('noResponse', {
          'accessibility-triangle': allowAccessibilityTriangle
        }),
        label: 'noResponse'
      },
      {
        color: 'transparent',
        icon: <Icon name='pencil' className='clickable link-color' />,
        colorSquareClassName: classNames('requiresManualScore', {
          'accessibility-triangle': allowAccessibilityTriangle
        }),
        label: 'requiresManualScore'
      }
    ];
    return (
      <div className={`gradebook-colorkeycard-outer-wrapper ${activeGradebookType} ${activeGradebookTable}`}>
        <ColorKeyCard colorKeyDataArray={colorKeyDataArray} iconKeyDataArray={iconKeyDataArray} />
      </div>
    );
  };

  const renderSummaryAndDetailsButtons = () => {
    const { activeGradebookTable, allowLearnosityGradebookDetail, allowGradebookStandards } = gradebookManager;

    const showDetailsButton = isAssessment || isLesson || (isLearnosity && allowLearnosityGradebookDetail);

    let showEngagementButton;

    if (typeof EngagementService.shouldShowGradebookEngagementButton === 'undefined') {
      // default case
      showEngagementButton = userManager.hasViewEngagementDataPermission && showDetailsButton;
    } else {
      // custom case
      showEngagementButton = EngagementService.shouldShowGradebookEngagementButton(entityTypeId);
    }

    const showStandardsButton = allowGradebookStandards;

    // show the summary button if at least one other button is shown
    const showSummaryButton = showDetailsButton || showEngagementButton || showStandardsButton; //! !onClickSummaryButton;

    const detailsButtonClassName = (showEngagementButton || showStandardsButton) ? 'bar-btn-middle' : 'bar-btn-right';
    const engagementButtonClassName = (showStandardsButton) ? 'bar-btn-middle' : 'bar-btn-right';

    return (
      <>
        <div className='btns-summary-and-details-wrapper'>
          {showSummaryButton && (
            <Button
              className='bar-btn-left'
              data-value='summary'
              onClick={handleClickButton}
              primary={activeGradebookTable === 'summary'}
              size='tiny'>
              {t('summary')}
            </Button>
          )}
          {showDetailsButton && (
            <Button
              className={detailsButtonClassName}
              data-value='details'
              onClick={handleClickButton}
              primary={activeGradebookTable === 'details'}
              size='tiny'>
              {t('details')}
            </Button>
          )}
          {showEngagementButton && !isLearnosity && (
            <Button
              className={engagementButtonClassName}
              data-value='engagement'
              onClick={handleClickButton}
              primary={activeGradebookTable === 'engagement'}
              size='tiny'>
              {t('engagement')}
            </Button>
          )}
          {showStandardsButton && (
            <Button
              className='bar-btn-right'
              data-value='standards'
              onClick={handleClickButton}
              primary={activeGradebookTable === 'standards'}
              size='tiny'>
              {t('standards')}
            </Button>
          )}
        </div>
      </>
    );
  };

  const renderEngagementButtonBar = () => {
    const showEngagementButtonBar = (
      gradebookManager.activeGradebookTable === 'engagement' && engagementManager.activeEngagementTable !== 'summary'
    );
    return showEngagementButtonBar ? <EngagementButtonBar /> : null;
  };

  const renderGotoCourseButton = () => {
    return (
      <div className={classNames('btn-goto-course-wrapper', {
        'is-read-only': isReadOnly
      })}>
        <Button
          className='primary goto-course-button'
          onClick={onClickGotoCourse}>
          {t('goToCourseLabel')}
        </Button>
      </div>
    );
  };

  const renderExportGradebookButton = () => {
    return (
      <div className='btn-export-wrapper'>
        <Button
          basic
          className={`btn-export ${activeGradebookTable}`}
          onClick={onClickExportGradebook}
          primary
          type='button'>
          <Icon name='download' />
          {t('export')}
        </Button>
      </div>
    );
  };

  const renderAssignButton = () => {
    if (activeGradebookTable !== 'summary') {
      return null;
    }
    const button = (
      <Button
        className='primary assign-button'
        onClick={() => handleClickAssignmentButton(assignment)}>
        {t('assignButtonLabel')}
      </Button>
    );
    return (
      <div className={classNames('btn-assign-wrapper', {
        'is-read-only': isReadOnly
      })}>
        {button}
      </div>
    );
  };

  const renderBulkUpdateButton = () => {
    const { assignmentToRepInstanceBulkCheckedMap } = gradebookManager;

    if (activeGradebookTable === 'summary') {
      const button = (
        <div className={classNames('btn-bulk-wrapper', {
          'is-read-only': isReadOnly
        })}>
          <Button
            className={`btn-bulk ${activeGradebookTable}`}
            disabled={!assignmentToRepInstanceBulkCheckedMap.size > 0}
            onClick={onClickEditBulkAssignmentButton}
            primary
            type='button'>
            {t('bulkUpdateButtonLabel')}
          </Button>
        </div>
      );
      if (!assignmentToRepInstanceBulkCheckedMap.size > 0) {
        return (
          <Popup
            content={(
              <div>{t('bulkUpdateButtonDisabledMessage')}</div>
            )}
            on='hover'
            position='bottom right'
            trigger={button} />
        );
      }
      return button;
    } else {
      return null;
    }
  };

  return (
    <div className='gradebook-header-container'>
      <div className={`gradebook-header-left ${activeGradebookType} ${activeGradebookTable}`}>
        {renderAssignmentNameAndDescription()}
        {renderSummaryAndDetailsButtons()}
        {renderEngagementButtonBar()}
      </div>
      {/* eslint-disable-next-line max-len */}
      <div className={`gradebook-header-right ${activeGradebookType} ${activeGradebookTable} ${useGradebookHeaderResourceInfo ? 'top-align' : ''}`}>
        <div className={classNames('top-button-wrapper', {
          'is-read-only': isReadOnly
        })}>
          {renderGotoCourseButton()}
          {renderExportGradebookButton()}
        </div>
        {renderGradebookDetailsColorKeyCard()}
        <div className={classNames('bottom-button-wrapper', {
          'is-read-only': isReadOnly
        })}>
          {renderAssignButton()}
          {renderBulkUpdateButton()}
        </div>
      </div>
    </div>
  );
});
export default GradebookHeader;

SatCoreRegister('GradebookHeader', GradebookHeader);
