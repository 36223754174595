import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { debounce } from 'lodash';

import { Dropdown, Menu } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

export default
@inject('topNavManager', 'userManager')
@observer
class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.Avatar = SatCoreComponent('Avatar');
    this.DropdownObserver = SatCoreComponent('DropdownObserver');

    // this.ref = React.createRef();
  }

  getDropdownOptions = (options) => options.filter((option) => option).map((option) => {
    const { topNavManager } = this.props;
    return ({
      as: (props) => {
        return (
          <div {...props} aria-label={option.text} role='menuitem' tabIndex={topNavManager.isTopNavProfileMenuOpen ? '0' : '-1'} />
        );
      },
      key: option.text.toLowerCase().replace(/\s+/g, '_'),
      ...option
    });
  });

  render() {
    const { Avatar, DropdownObserver } = this;

    const { topNavManager, userManager, options, t } = this.props;

    return (
      <Menu.Item
        as='a'
        className='tnav-tab profile-menu'
        id='profile-menu'
        onClick={() => {
          // placeholder
        }}
        onMouseEnter={() => {
          debounce(() => {
            topNavManager.setIsTopNavProfileMenuOpen(true);
          }, 100);
        }}
        onMouseLeave={() => {
          topNavManager.setIsTopNavProfileMenuOpen(false);

          const isWithinProfileMenu = !!document.activeElement?.closest?.('.profile-menu');

          if (isWithinProfileMenu) {
            document.activeElement?.blur?.();
          }
        }}
        role='tab'
        tabIndex='-1'>
        <div className='aria-offscreen' id='profile-menu-instructions'>
          {topNavManager.isTopNavProfileMenuOpen ? t('ariaProfileMenuOpenInstructions') : t('ariaProfileMenuClosedInstructions')}
        </div>
        {/* <Ref innerRef={this.ref}> */}
        <DropdownObserver>
          <Dropdown
            aria-describedby='profile-menu-instructions'
            aria-expanded={topNavManager.isTopNavProfileMenuOpen}
            aria-haspopup='menu'
            aria-label={t('ariaProfileMenuDropdownLabel')}
            as={(props) => <div {...props} role='menu' />}
            className='profile-menu-selector'
            icon={null}
            open={topNavManager.isTopNavProfileMenuOpen}
            options={this.getDropdownOptions(options)}
            simple
            tabIndex='0'
            trigger={<Avatar fullProfileImageUrl={userManager.fullProfileImageUrl} />} />
        </DropdownObserver>
        {/* </Ref> */}
      </Menu.Item>
    );
  }
}

SatCoreRegister('ProfileMenu', ProfileMenu);
