import { action, observable } from 'mobx';

import Auth from './AuthManager';
import userManager from './UserManager';

import ContentService from '../services/ContentService';

export class DocReaderManager {
  // constructor() {
  //   makeObservable(this);
  // }
  _cwin = null;

  _currentPageNumber = 1;

  _isPageChanging = false;
  // _currentPageAnnotationValue = null;

  @observable pageAnnotations = new Map();

  @observable pageHighlights = new Map();

  @observable printUrl = null;

  @observable printSvg = null;

  @action loadPageAnnotations(pageHighlightsAnnotations) {
    if (pageHighlightsAnnotations) {
      pageHighlightsAnnotations.forEach((pageHA) => {
        if (pageHA.pageIndex !== null) {
          thisManager.pageAnnotations.set(pageHA.pageIndex, pageHA.annotation);
          thisManager.pageHighlights.set(pageHA.pageIndex, pageHA.highlight);
        }
      });
    }
  }

  @action setPrintUrl = (url) => {
    if (url !== null) {
      thisManager.printUrl = url;
    }
  }

  @action setPrintSvg = (svg) => {
    if (svg !== null) {
      thisManager.printSvg = svg;
    }
  }

  @action setPageAnnotation = (pageIndex, annotation) => {
    if (pageIndex !== null && annotation !== null) {
      thisManager.pageAnnotations.set(pageIndex, annotation);
    }
  }

  @action setPageHighlight = (pageIndex, highlight) => {
    if (pageIndex !== null && highlight !== null) {
      thisManager.pageHighlights.set(pageIndex, highlight);
    }
  }

  @action setContentWindow = (cWin) => {
    if (cWin !== null) {
      thisManager._cwin = cWin;
    }
  }

  @action setCurrentPage = (page) => {
    if (page !== null) {
      thisManager._currentPageNumber = page;
    }
  }

  @action setIsPageChanging = (isChanging) => {
    if (isChanging !== null) {
      thisManager._isPageChanging = isChanging;
    }
  }

  @action
  clearHighlightsAnnotations = () => {
    this.pageAnnotations.clear();
    this.pageHighlights.clear();
  }

  getAnnotation(key) {
    if (thisManager.pageAnnotations.has(key)) {
      return thisManager.pageAnnotations.get(key);
    }
    return null;
  }

  getHighlight(key) {
    if (thisManager.pageHighlights.has(key)) {
      return thisManager.pageHighlights.get(key);
    }
    return null;
  }

  getContentWindow() {
    return thisManager._cwin;
  }

  activateAnnotationTool() {
    const hltDt = {};
    hltDt['highlighter.displayModeSet'] = ['view'];
    this.windowPostMessage(hltDt);

    const annoDt = {};
    annoDt['annotations.displayModeSet'] = ['edit'];
    this.windowPostMessage(annoDt);
  }

  activateHighlightTool() {
    const annoDt = {};
    annoDt['annotations.displayModeSet'] = ['view'];
    this.windowPostMessage(annoDt);

    const hltDt = {};
    hltDt['highlighter.displayModeSet'] = ['edit'];
    this.windowPostMessage(hltDt);
  }

  neutralizeBothTools() {
    const annoDt = {};
    annoDt['annotations.displayModeSet'] = ['view'];
    this.windowPostMessage(annoDt);

    const hltDt = {};
    hltDt['highlighter.displayModeSet'] = ['view'];
    this.windowPostMessage(hltDt);
  }

  hideBothTools() {
    const annoDt = {};
    annoDt['annotations.displayModeSet'] = ['hidden'];
    this.windowPostMessage(annoDt);

    const hltDt = {};
    hltDt['highlighter.displayModeSet'] = ['off'];
    this.windowPostMessage(hltDt);
  }

  windowPostMessage(dataObj) {
    const eventId = `eventid-${Math.round(Math.random() * 10000)}`;
    const obj = {
      type: 'dcdata',
      dataId: eventId,
      dataObj
    };
    this._cwin.postMessage(obj, '*');

    // console.log('* windowPostMessage posted');
  }

  async doSaveActions(clear = true) {
    let annoResp = null;
    // save the current page annotation
    thisManager.dcData_get(thisManager._cwin, {
      contentGet: null
    }, async (response) => {
      annoResp = response.contentGet;
      thisManager.dcData_get(thisManager._cwin, {
        'highlighter.contentGet': null
      }, async (response) => {
        const hltResp = response['highlighter.contentGet'];
        await thisManager.addUpdateDocumentHighlightAnnotationPage(null, thisManager._currentPageNumber, annoResp, hltResp, clear);
      });
    });
  }

  async getPrintContentSvg() {
    thisManager.dcData_get(thisManager._cwin, {
      contentGetForPrint: null
    }, async (printableResponse) => {
      const printAnnotationContent = printableResponse.contentGetForPrint;

      if (printableResponse && printAnnotationContent) {
        thisManager.setPrintSvg(printAnnotationContent);
        if (printAnnotationContent) {
          thisManager.dcData_get(thisManager._cwin, {
            contentSet: [printAnnotationContent]
          }, () => {
            // console.log('There was annotation data for this page.');
          });
        } else {
          console.log('There was no printable annotation data for this page.');
        }
        console.log(printAnnotationContent);
      } else {
        console.log('There was no printable content data for this page.');
      }
    });
  }

  async getPrintPageUrl() {
    thisManager.dcData_get(thisManager._cwin, {
      pageImageSrc: null
    }, async (urlResponse) => {
      if (urlResponse && urlResponse.pageImageSrc) {
        thisManager.setPrintUrl(urlResponse.pageImageSrc);
      } else {
        console.log('There was no url data for this page.');
      }
    });
  }

  async doPagePrint() {
    thisManager.dcData_get(thisManager._cwin, {
      pagePrint: null
    }, async (response) => {
      setTimeout(() => {
      // reset to regular annotations.
        const pageAnnotationData = thisManager.getAnnotation(response.pageCurrent);
        if (pageAnnotationData) {
          thisManager.dcData_get(thisManager._cwin, {
            contentSet: [pageAnnotationData]
          }, () => {
          });
        } else {
        }
      }, 5000);
    });
  }

  async doPageChangedActions() {
    // set current page number
    thisManager.dcData_get(thisManager._cwin, {
      pageCurrent: null
    }, async (response) => {
      thisManager.setCurrentPage(response.pageCurrent);
      const pageAnnotationData = thisManager.getAnnotation(response.pageCurrent);
      if (pageAnnotationData) {
        thisManager.dcData_get(thisManager._cwin, {
          contentSet: [pageAnnotationData]
        }, () => {
          // console.log('There was annotation data for this page.');
        });
      } else {
        // console.log('There was no annotation data for this page.');
      }

      const pageHighlightData = thisManager.getHighlight(response.pageCurrent);
      if (pageHighlightData) {
        thisManager.dcData_get(thisManager._cwin, {
          'highlighter.contentSet': [pageHighlightData]
        }, () => {
          // console.log('There was highlight data for this page.');
        });
      } else {
        // console.log('There was no highlight data for this page.');
      }
    });
  }

  async clearPageAnnotation() {
    thisManager.dcData_get(thisManager.getContentWindow(), {
      contentClear: null
    }, async () => {
      // _content.fetch();
    });
  }

  async clearPageHighlight() {
    thisManager.dcData_get(thisManager.getContentWindow(), {
      'highlighter.contentClear': null
    }, async () => {
      // _content.fetch();
    });
  }

  _relay = function (e) {
    // This is needed since some browsers use the originalEvent property instead of just sending it straight up.
    const message = e.originalEvent || e;

    if (message.data && message.data.type) {
      // We want to check the message type excplicitly, in case there are other applications that use the same message strucuture.
      switch (message.data.type) {
        case 'dcevent':
          thisManager.dcEvent_fire(message.data.params.eventId, message.data.params.args); // Event message
          break;

        case 'dcdata':
          thisManager.dcData_receive(message.data.params.dataId, message.data.params.returnObj); // Data message
          break;
      }
    } else {
      console.warn('Warning! Could not determine the message type. It\'s possible it was not a docReader message.');
    }
  }

  initializeDocReaderFrame = async () => {
    // console.log('* Start initializeDocReaderFrame');

    thisManager.setContentWindow(document.getElementById('dcr-frame').contentWindow);

    if (window.addEventListener) {
      window.addEventListener('message', thisManager._relay, false);
    } else {
      window.attachEvent('onmessage', thisManager._relay);
    }

    thisManager.dcEvent_on(thisManager._cwin, 'pageloaded', () => {
      // console.log('* pageloaded');
      thisManager.clearHighlightsAnnotations();
      thisManager.fetchDocumentHighlightsAnnotations();
      thisManager.neutralizeBothTools();
      setTimeout(() => {
        thisManager.doPageChangedActions();
      }, 1000);
    });

    thisManager.addEventListeners();
  }

  addEventListeners = async () => {
    // Some things to do when the user has decided to navigate to a new page, but before it has been done.
    thisManager.dcEvent_on(thisManager._cwin, 'beforepagechanged', () => {
      thisManager.setIsPageChanging(true);
      // console.log('beforepagechanged');
      // thisManager.doSaveActions(true);
      thisManager.clearPageAnnotation();
      thisManager.clearPageHighlight();
    });

    // Do this right after the user has navigated to a different page.
    thisManager.dcEvent_on(thisManager._cwin, 'pagechanged', () => {
      thisManager.setIsPageChanging(false);
      // console.log('pagechanged');
      thisManager.doPageChangedActions();
    });

    // Do this right after the user has modified something.
    thisManager.dcEvent_on(thisManager._cwin, 'modcommandsent', (action, data) => {
      if (!thisManager._isPageChanging) {
        if (action === 'svgtools.change' || action === 'highlighter.change') {
          thisManager.doSaveActions(false);
        }
      }
    });
  };

  // _dcEvent
  dcEvent_store = {};

  dcEvent_on = (win, type, handler, context) => {
    const eventId = `dce_${Math.random().toString(36).substring(7)}`;
    // Create an object that holds information about this particular event and store it.
    thisManager.dcEvent_store[eventId] = {
      type, // Event type
      handler, // Function that will be executed when event fires.
      context // The context will be the 'this' keyword in the handler function.
    };

    try {
      // Create the message event payload
      const obj = {
        type: 'dcevent',
        eventType: type.toString(),
        eventId // We need to provide the eventId so that we can identify this particular event registration when the event fires.
      };

      if (win && win.postMessage) {
        // console.log([obj]); // Log some info to the browser console, so that we can track the event.
        win.postMessage(obj, '*'); // Post the message. The asterisk should be replaced with the actual uri of the docReader iframe, if possible.
      }
    } catch (e) {
      console.error(e); // An error was caught, print some info about it to the console.
    }
  }

  dcEvent_fire =(eventId, args) => {
    const e = thisManager.dcEvent_store[eventId];
    // Make sure there is handler function... if so, fire it.
    if (e && typeof e.handler === 'function') {
      // console.log(['dcEvent', e.type, eventId, args]); // Log some info to the browser console.
      e.handler.apply(e.context || window, args);
    } else {
      // Either we could not find the stored event object, or the handler function was in fact not a function. Throw a warning message in any case...
      console.warn('dcEvent', 'Handler is not a function. I\'m confused.');
    }
  }

  // _dcData
    dcData_store = {};

    dcData_get = (win, data, callback, context) => {
      const dataId = `dcd_${Math.random().toString(36).substring(7)}`;
      // Store some information about this message locally.
      thisManager.dcData_store[dataId] = {
        data, // The data object that was sent to docReader
        callback, // The callback function
        context // The context will be the 'this' keyword in the callback function.
      };

      // Log some information about this object, so that we can more easily track it.
      // console.log(['dcData_get : dcData sent:', dataId, data]);

      // And now we post the message.
      win.postMessage({
        type: 'dcdata',
        dataId,
        dataObj: data
      }, '*');
    }

    dcData_receive = (dataId, data) => {
      const d = thisManager.dcData_store[dataId];
      // We want to see what we got back from docReader, so we log it to the browser console.
      // console.log([' dcData_receive : dcData returned: ', dataId, data]);

      // If the callback is a function, execute it. Data messages don't need to have a callback, since they sometimes only want to run a command in docReader.
      if (d && typeof d.callback === 'function') {
        // Callback was found, and it is a function, let's execute it.
        d.callback.apply(d.context || window, [data]);
      } else {
        // Uh oh, we could not execute the callback, let the world know!
        console.warn('Could not execute callback for dataId ', dataId);
      }
    }

    fetchDocumentHighlightsAnnotations = async () => {
      const documentSessionId = await ContentService.getContentSessionId();
      const url = `${Auth.ecms}/api/getDocumentHighlightAnnotationPages?documentSessionId=${documentSessionId}`;
      try {
        const response = await Auth.fetch(url, {
          method: 'GET'
        });

        if (response.status === 'SUCCESS') {
          const pageAnnotations = response.data;
          this.loadPageAnnotations(pageAnnotations);
        }
      } catch (e) {
        console.warn(e);
      }
    }

    addUpdateDocumentHighlightAnnotationPage = async (
      courseResourceElementId, pageIndex, annotation, highlight, clear
    ) => {
      const documentSessionId = await ContentService.getContentSessionId();
      try {
        const body = {
          documentSessionId,
          courseResourceElementId,
          pageIndex,
          annotation,
          highlight,
          userId: userManager.userId
        };

        const response = await Auth.fetch(`${Auth.ecms}/api/addUpdateDocumentHighlightAnnotationPage`, {
          method: 'POST',
          body
        });

        if (response.status === 'SUCCESS') {
          this.setPageAnnotation(pageIndex, annotation);
          this.setPageHighlight(pageIndex, highlight);
          if (clear) {
            this.clearPageAnnotation();
            this.clearPageHighlight();
          }
          return true;
        }
        return false;
      } catch (error) {
        alert('There was an error while saving your response.  Please remove any special characters such as emojis and submit your response again.');
        console.warn(error);
        return false;
      }
    }
}

const thisManager = new DocReaderManager();
export default thisManager;
