import React, { useContext } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import ReactModalDraggable from 'react-modal-resizable-draggable';

import classNames from 'classnames';

import '../../css/GenericModal.less';
import '../../css/ModalBannerDraggable.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { renderButton } from '../../utils';

const GenericModal = observer((props = {}) => {
  const {
    // t,
    bodyMsg = '',
    buttonKeyCancelLabel = '', // need to pass a populated string, else cancel button will not show
    buttonKeyConfirmLabel = 'ok',
    className,
    closeOnDimmerClick = false,
    closeOnEscape = true,
    disableKeystroke = false,
    disableMove = false,
    disableResize = true,
    headerMsg = '',
    initHeight,
    initWidth,
    isLoading,
    isOpen,
    left,
    minHeight = 212,
    minWidth = 450,
    onClose = () => {},
    onOpen = () => {},
    onRequestClose,
    overlay = 'transparent', // 'dark' |  'light' | 'medium' | 'transparent'
    top = window.innerHeight / 2.75,
  } = props;

  const {
    /* managers here */
  } = useContext(MobXProviderContext);

  const ModalBannerDraggable = SatCoreComponent('ModalBannerDraggable');

  // TODO unused
  // useEffect(() => {
  //   (async () => {
  //     // placeholder
  //   })();
  // }, []);

  /**
   * Triggerable by certain actions such as pressing 'escape' or clicking outside of the modal
   */
  const handleRequestClose = (event) => {
    if (event?.type !== 'click' || closeOnDimmerClick) {
      onClose?.(event);
    }
  };

  const renderGenericModal = () => {
    return (
      <div className={classNames('generic-modal-parent', `overlay-${overlay}`)}>
        <ReactModalDraggable
          className={classNames('GenericModal', className)}
          closeOnDimmerClick={closeOnDimmerClick}
          closeOnEscape={closeOnEscape}
          disableKeystroke={disableKeystroke}
          disableMove={disableMove}
          disableResize={disableResize}
          initHeight={initHeight || minHeight}
          initWidth={initWidth || minWidth}
          isOpen={isOpen}
          left={left}
          minHeight={minHeight || initHeight}
          minWidth={minWidth || initWidth}
          onClose={onClose}
          onOpen={onOpen}
          onRequestClose={onRequestClose || handleRequestClose}
          top={top}>
          <div className='generic-modal-wrapper'>
            {renderGenericModalBanner()}
            {renderGenericModalBody()}
            {renderGenericModalActions()}
          </div>
        </ReactModalDraggable>
      </div>
    );
  };

  const renderGenericModalBanner = () => {
    return (
      <div className='generic-modal-banner'>
        <ModalBannerDraggable
          label={headerMsg}
          onClose={onClose} />
      </div>
    );
  };

  const renderGenericModalBody = () => {
    return (
      <div className={classNames('generic-modal-body', {
        'generic-modal-body-loading': isLoading
      })}>
        {typeof bodyMsg === 'function' ? bodyMsg() : bodyMsg}
      </div>
    );
  };

  const renderGenericModalActions = () => {
    const onCloseActionFn = (event, buttonActionObj) => {
      return onClose(event, buttonActionObj);
    };
    return (
      <div className='generic-modal-actions'>
        {!!buttonKeyCancelLabel && renderButton({
          additionalClassNames: 'generic-button-cancel button-basic-bg',
          basic: true,
          buttonKey: buttonKeyCancelLabel,
          disabled: isLoading,
          onClick: onClose,
          primary: true
        })}
        {renderButton({
          additionalClassNames: 'generic-button-confirm button-primary-bg',
          buttonKey: buttonKeyConfirmLabel,
          disabled: isLoading,
          loading: isLoading,
          onClick: onCloseActionFn,
          primary: true
        })}
      </div>
    );
  };

  return renderGenericModal();
});

export default GenericModal;

SatCoreRegister('GenericModal', GenericModal);
