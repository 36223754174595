import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Input, Loader, Message, Radio } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';
import '../css/Profile.less';
import { VIEW_SELECTION } from '../managers/NavigationManager';

export default
@inject(
  'adminClassroomManager', 'classroomManager',
  'navigationManager', 'userManager'
)
@observer
class EditStudentProfile extends Component {
  constructor(props) {
    super(props);
    this.user = null;
    this.state = {
      rosterId: '',
      fullProfileImageUrl: '',
      textHelpEnabled: false,
      firstName: '',
      lastName: '',
      username: '',
      errorMessage: '',
      loading: false,
      saved: false,
      error: false
    };
    this.Avatar = SatCoreComponent('Avatar');
  }

  navToRoster = async () => {
    const {
      history, classroomManager, navigationManager, userManager
    } = this.props;
    const isAdmin = userManager.isDistrictAdmin || userManager.isSchoolAdmin;
    let routerUrl = `/roster?classroomId=${classroomManager.currentClassroomId}`;
    if (isAdmin) {
      const paramsToExcludeFromStr = 'classroomId';
      const { urlParamStr } = await navigationManager.getAdminUrlParams(
        paramsToExcludeFromStr
      );
      routerUrl = urlParamStr ? `${routerUrl}&${urlParamStr}` : routerUrl;
    }
    history.push(routerUrl);
  };

  verifyChanges = async () => {
    const { classroomManager, userManager } = this.props;
    this.setState({ saved: false, error: false });
    let changesHappened = false;
    if (this.state.firstName === '' || this.state.firstName.length === 0) {
      this.setState({ saved: false, error: true, errorMessage: 'The First Name cannot be empty.' });
      return;
    }
    if (this.state.lastName === '' || this.state.lastName.length === 0) {
      this.setState({ saved: false, error: true, errorMessage: 'The Last Name cannot be empty.' });
      return;
    }
    if (this.state.username === '' || this.state.username.length === 0) {
      this.setState({ saved: false, error: true, errorMessage: 'The Username cannot be empty.' });
      return;
    }
    if (this.state.firstName &&
      this.state.firstName !== this.user.firstName) {
      changesHappened = true;
    }
    if (this.state.lastName &&
      this.state.lastName !== this.user.lastName) {
      changesHappened = true;
    }
    if (this.state.username &&
      this.state.username !== this.user.username) {
        // for students, check the username for format
        if (this.user.userRoles.toLowerCase().includes('student')) {
          let usernameResult = userManager.validateStudentUsernameFormat(this.state.username);
          if (!usernameResult.valid) {
            let errorMessage = usernameResult.errorMessage;
            this.setState({ saved: false, error: true, errorMessage });
            return;
          }
        }

      changesHappened = true;
    }
    if (changesHappened) {
      this.setState({ loading: true });
      const result = await classroomManager.editRosterStudent({
        id: this.state.rosterId,
        userId: this.state.userId,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        username: this.state.username,
        active: true
      });
      if (result) {
        this.setState({ saved: true, error: false });
        await classroomManager.fetchClassroomRoster(
          classroomManager.currentClassroomId
        );
      } else {
        this.setState({ saved: false, error: true });
      }
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    const { classroomManager, userManager } = this.props;
    const { currentClassroomId } = classroomManager;
    const isAdmin = userManager.isDistrictAdmin || userManager.isSchoolAdmin;
    if (isAdmin && !classroomManager.getClassroom(currentClassroomId)) {
      await this.initAdminData();
    } else {
      await this.initData();
    }
  }

  initAdminData = async () => {
    const {
      adminClassroomManager,
      classroomManager, history,
      navigationManager
    } = this.props;
    const { currentClassroomId } = classroomManager;
    const classroom = await adminClassroomManager.fetchClassroom(currentClassroomId);
    if (classroom) {
      await classroomManager.setAdminClassroom(currentClassroomId, classroom);
      await this.initData();
    } else {
      const { urlParamStr } = await navigationManager.getAdminUrlParams();
      const routerUrl = `/classrooms?view=${VIEW_SELECTION.CLASSROOMS}&${urlParamStr}`;
      await history.push(routerUrl);
    }
  }

  initData = async () => {
    const {
      classroomManager, navigationManager, userManager
    } = this.props;
    const { currentClassroomId } = classroomManager;
    navigationManager.setView(VIEW_SELECTION.EDIT_STUDENT_PROFILE);
    const urlParams = new URLSearchParams(this.props.location.search);
    if (!currentClassroomId && urlParams.has('classroomId')) {
      classroomManager.setCurrentClassroomId(urlParams.get('classroomId'));
    }
    if (!this.user && urlParams.has('userId') && urlParams.has('rosterId')) {
      this.setState({ loading: true });
      const user = await userManager.fetchUser(urlParams.get('userId'));
      this.setState({ loading: false });
      if (user) {
        this.user = user;
        this.setState({
          rosterId: urlParams.get('rosterId'),
          fullProfileImageUrl: userManager.createFullAuthUrl(this.user.profileImageUrl),
          textHelpEnabled: this.user.textHelpEnabled,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          username: this.user.username,
          userId: this.user.id
        });
      }
    }
  }

  handleChangeFirstName = (e, data) => {
    this.setState({ firstName: data.value });
  };

  handleChangeLastName = (e, data) => {
    this.setState({ lastName: data.value });
  };

  handleChangeUsername = (e, data) => {
    this.setState({ username: data.value.toLowerCase() });
  };

  handleTextHelp = async () => {
    const toggle = !(this.state.textHelpEnabled);
    const { userManager, classroomManager } = this.props;
    const success = await userManager.updateStudentTextHelpOption(this.state.userId, toggle);
    if (success) {
      this.setState({ textHelpEnabled: toggle });
      await classroomManager.fetchClassroomRoster(classroomManager.currentClassroomId, 0);
    }
  }

  render() {
    const { Avatar } = this;
    const { userManager, t } = this.props;
    return (
      <div className='profile-page'>
        <div className='profile-container'>
          <div className='profile-header'>
            <div className='profile-txt'>Student Profile</div>
            <div className='avatar-controls'>
              <Avatar className='profile-avatar' fullProfileImageUrl={this.state.fullProfileImageUrl} />
            </div>
          </div>
          <div className='profile-fields'>
            <div className='profile-field-txt'>{t('firstName', 'First Name')}</div>
            <Input
              name='firstName'
              onChange={this.handleChangeFirstName}
              placeholder='First Name'
              type='text'
              value={this.state.firstName} />
            <div className='profile-field-txt'>{t('lastName', 'Last Name')}</div>
            <Input
              name='lastName'
              onChange={this.handleChangeLastName}
              placeholder='Last Name'
              type='text'
              value={this.state.lastName} />
            <div className='profile-field-txt'>{t('username', 'Username/email')}</div>
            <Input
              onChange={this.handleChangeUsername}
              placeholder='Username/email'
              type='text'
              value={this.state.username} />
            {(userManager.publisherTextHelpActive && !userManager.showTextHelpForAll) ? (
              <>
                <div className='profile-field-txt' />
                <Radio
                  checked={this.state.textHelpEnabled}
                  disabled={false}
                  label='Enable Text Help'
                  onChange={() => { this.handleTextHelp(); }}
                  toggle />
              </>
            ) : ''}
          </div>
          <Button basic className='profile-change-password-btn' onClick={this.navToRoster} primary>
            Back to Roster
          </Button>
          <Button className='profile-save-changes-btn' onClick={this.verifyChanges} primary>
            Save
          </Button>
          <Loader active={this.state.loading} />
          {this.state.saved
            ? (
              <Message positive>
                <Message.Header>Success</Message.Header>
                <p>
                  The student profile has been updated.
                </p>
              </Message>
            ) : null}
          {(this.state.error === true) ? (this.state.errorMessage !== '' && this.state.errorMessage.length > 0)
            ? (
              <Message negative>
                <Message.Header>Something went wrong</Message.Header>
                <p>
                  {this.state.errorMessage}
                </p>
              </Message>
            ) : (
              <Message negative>
                <Message.Header>Something went wrong</Message.Header>
                <p>
                  The student profile failed to save please try again.
                </p>
              </Message>
            )
            : null}
        </div>
      </div>
    );
  }
}

SatCoreRegister('EditStudentProfile', EditStudentProfile);
