import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Image,
  Input,
  Message,
  Segment
} from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';
import navigationManager, { VIEW_SELECTION } from '../managers/NavigationManager';
import backIcon from '../img/angle-right.svg';
import Auth, { AuthManager } from '../managers/AuthManager';

import '../css/Login.less';

export default class LoginOneRoster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordInputType: 'password',
      institutionId: '',
      districts: [],
      oneRosterEnabled: false
    };
    this.Logo = SatCoreComponent('Logo');
    this.ShowPasswordButton = SatCoreComponent('ShowPasswordButton');
  }

  async componentDidMount() {
    navigationManager.setView(VIEW_SELECTION.LOGIN_ONE_ROSTER);
    await this.initOneRoster();
    this.getDistricts();
  }

  initOneRoster = async () => {
    const enabled = await Auth.getOneRosterLoginEnabled();
    this.setState({ oneRosterEnabled: enabled });
  }

  handleShowPasswordPressed = (name, isPasswordInput) => {
    const inputType = isPasswordInput ? 'password' : 'text';
    this.setState({ [name]: inputType });
  }

  getDistricts = async () => {
    try {
      if (this.state.oneRosterEnabled) {
        const districts = await Auth.getOneRosterDistricts();
        const districtOptions = [];
        if (districts) {
          districts.forEach((district) => {
            const districtOption = {
              key: district.id,
              value: district.id,
              text: district.name
            };
            districtOptions.push(districtOption);
          });
        }
        this.setState({ districts: districtOptions });
      }
    } catch (e) {
      console.log(e);
    }
  }

  goBack = () => {
    window.location = '/signin';
  }

  render() {
    const { Logo } = this;
    const { ShowPasswordButton } = this;

    return (
      <Grid className='login-body one-roster-body' textAlign='center' verticalAlign='middle'>
        <Grid.Column>
          <Form>
            <Header as='h2' attached='top' block>
              <Logo />
              <span className='header-text'>Sign In with OneRoster</span>
            </Header>
            <Segment attached className='element-body-login'>
              <div className='back-container'>
                <span className='back-button' onClick={() => this.goBack()}>
                  <Image alt='' src={backIcon} />
&nbsp;Back
                </span>
              </div>
              <Form.Field>
                <label for={'institutionId'}>Select District</label>
                <Dropdown
                  id={'institutionId'}
                  fluid
                  name='institutionId'
                  onChange={this.handleChange}
                  options={this.state.districts}
                  placeholder='Search District'
                  search
                  selection
                  value={this.state.institutionId} />
              </Form.Field>
              <Form.Field>
                <label for={'email'}>Username/email</label>
                <Input
                  id={'email'}
                  autoComplete='username'
                  name='email'
                  onChange={this.handleChange}
                  placeholder='name@email.com'
                  type='email' />
              </Form.Field>
              <Form.Field>
                <label for={'password'}>Password</label>
                <Input
                  id={'password'}
                  autoComplete='current-password'
                  label={(
                    <ShowPasswordButton
                      isPassword={(this.state.passwordInputType === 'password')}
                      name='passwordInputType'
                      onFlip={this.handleShowPasswordPressed} />
                  )}
                  labelPosition='right'
                  name='password'
                  onChange={this.handleChange}
                  onKeyPress={(e) => e.stopPropagation()}
                  placeholder='Password'
                  type={this.state.passwordInputType} />
                <Link className='forgot-link' color='black' to='/forgot-password'>Forgot Password</Link>
              </Form.Field>
              <Form.Field>
                <Segment className='single-button'>
                  <Button className='login' onClick={this.handleFormSubmit} primary>
                    Sign In
                  </Button>
                </Segment>
              </Form.Field>
            </Segment>

            {this.state.loginError
              ? (
                <Message negative>
                  <Message.Header>Invalid username or password</Message.Header>
                  <p>Please try again.</p>
                </Message>
              )
              : null}
          </Form>
        </Grid.Column>
      </Grid>
    );
  }

  handleChange = (e, { name, value }) => {
    e.preventDefault();
    this.setState({
      [name]: value
    });
  }

  handleFormSubmit = async (e) => {
    e.preventDefault();
    await this.setState({ loginError: false });
    try {
      if (this.state.oneRosterEnabled) {
        const loggedIn = await Auth.loginWithOneRoster(this.state.email, this.state.password, this.state.institutionId);
        if (loggedIn) {
          this.props.history.replace('/');
        } else {
          this.setState({ loginError: true });
        }
      } else {
        this.setState({ loginError: true });
      }
    } catch (error) {
      console.log(error);
    }
  }
}

SatCoreRegister('LoginOneRoster', LoginOneRoster);
