import React, { Component } from 'react';
import { inject } from 'mobx-react';
import {
  Image
} from 'semantic-ui-react';
import { SatCoreRegister } from '../../SatCoreRegistry';
import '../../css/AvatarPicker.less';

import animals1_avatar from '../../img/avatars/student/85px-animals-01.png';
import animals2_avatar from '../../img/avatars/student/85px-animals-02.png';
import animals3_avatar from '../../img/avatars/student/85px-animals-03.png';
import animals4_avatar from '../../img/avatars/student/85px-animals-04.png';
import animals5_avatar from '../../img/avatars/student/85px-animals-05.png';

import color1_avatar from '../../img/avatars/student/85px-color-01.png';
import color2_avatar from '../../img/avatars/student/85px-color-02.png';
import color3_avatar from '../../img/avatars/student/85px-color-03.png';
import color4_avatar from '../../img/avatars/student/85px-color-04.png';
import color5_avatar from '../../img/avatars/student/85px-color-05.png';

import nature1_avatar from '../../img/avatars/student/85px-nature-01.png';
import nature2_avatar from '../../img/avatars/student/85px-nature-02.png';
import nature3_avatar from '../../img/avatars/student/85px-nature-03.png';
import nature4_avatar from '../../img/avatars/student/85px-nature-04.png';
import nature5_avatar from '../../img/avatars/student/85px-nature-05.png';

import patterns1_avatar from '../../img/avatars/student/85px-patterns-01.png';
import patterns2_avatar from '../../img/avatars/student/85px-patterns-02.png';
import patterns3_avatar from '../../img/avatars/student/85px-patterns-03.png';
import patterns4_avatar from '../../img/avatars/student/85px-patterns-04.png';
import patterns5_avatar from '../../img/avatars/student/85px-patterns-05.png';

import sports1_avatar from '../../img/avatars/student/85px-sports-01.png';
import sports2_avatar from '../../img/avatars/student/85px-sports-02.png';
import sports3_avatar from '../../img/avatars/student/85px-sports-03.png';
import sports4_avatar from '../../img/avatars/student/85px-sports-04.png';
import sports5_avatar from '../../img/avatars/student/85px-sports-05.png';

export default
@inject('userManager')
class StudentDefaultAvatarPicker extends Component {
  selectDefaultAvatar = (e) => {
    this.props.selectAvatar(e.target.name);
  };

  isAvatarSelected = (name) => {
    if (this.props.selectedAvatar === name) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <div className='avatar-picker-container'>
        <div className='avatar-picker'>
          {/* animals */}
          <Image
            className={this.isAvatarSelected('animals-01') ? 'selected-avatar' : null}
            name='animals-01'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(animals1_avatar.indexOf('base64') > -1) ? animals1_avatar : require(`./${animals1_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('animals-02') ? 'selected-avatar' : null}
            name='animals-02'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(animals2_avatar.indexOf('base64') > -1) ? animals2_avatar : require(`./${animals2_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('animals-03') ? 'selected-avatar' : null}
            name='animals-03'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(animals3_avatar.indexOf('base64') > -1) ? animals3_avatar : require(`./${animals3_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('animals-04') ? 'selected-avatar' : null}
            name='animals-04'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(animals4_avatar.indexOf('base64') > -1) ? animals4_avatar : require(`./${animals4_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('animals-05') ? 'selected-avatar' : null}
            name='animals-05'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(animals5_avatar.indexOf('base64') > -1) ? animals5_avatar : require(`./${animals5_avatar}`).default} />
          {/* color */}
          <Image
            className={this.isAvatarSelected('color-01') ? 'selected-avatar' : null}
            name='color-01'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(color1_avatar.indexOf('base64') > -1) ? color1_avatar : require(`./${color1_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('color-02') ? 'selected-avatar' : null}
            name='color-02'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(color2_avatar.indexOf('base64') > -1) ? color2_avatar : require(`./${color2_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('color-03') ? 'selected-avatar' : null}
            name='color-03'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(color3_avatar.indexOf('base64') > -1) ? color3_avatar : require(`./${color3_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('color-04') ? 'selected-avatar' : null}
            name='color-04'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(color4_avatar.indexOf('base64') > -1) ? color4_avatar : require(`./${color4_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('color-05') ? 'selected-avatar' : null}
            name='color-05'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(color5_avatar.indexOf('base64') > -1) ? color5_avatar : require(`./${color5_avatar}`).default} />
          {/* nature */}
          <Image
            className={this.isAvatarSelected('nature-01') ? 'selected-avatar' : null}
            name='nature-01'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(nature1_avatar.indexOf('base64') > -1) ? nature1_avatar : require(`./${nature1_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('nature-02') ? 'selected-avatar' : null}
            name='nature-02'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(nature2_avatar.indexOf('base64') > -1) ? nature2_avatar : require(`./${nature2_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('nature-03') ? 'selected-avatar' : null}
            name='nature-03'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(nature3_avatar.indexOf('base64') > -1) ? nature3_avatar : require(`./${nature3_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('nature-04') ? 'selected-avatar' : null}
            name='nature-04'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(nature4_avatar.indexOf('base64') > -1) ? nature4_avatar : require(`./${nature4_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('nature-05') ? 'selected-avatar' : null}
            name='nature-05'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(nature5_avatar.indexOf('base64') > -1) ? nature5_avatar : require(`./${nature5_avatar}`).default} />
          {/* patterns */}
          <Image
            className={this.isAvatarSelected('patterns-01') ? 'selected-avatar' : null}
            name='patterns-01'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(patterns1_avatar.indexOf('base64') > -1) ? patterns1_avatar : require(`./${patterns1_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('patterns-02') ? 'selected-avatar' : null}
            name='patterns-02'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(patterns2_avatar.indexOf('base64') > -1) ? patterns2_avatar : require(`./${patterns2_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('patterns-03') ? 'selected-avatar' : null}
            name='patterns-03'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(patterns3_avatar.indexOf('base64') > -1) ? patterns3_avatar : require(`./${patterns3_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('patterns-04') ? 'selected-avatar' : null}
            name='patterns-04'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(patterns4_avatar.indexOf('base64') > -1) ? patterns4_avatar : require(`./${patterns4_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('patterns-05') ? 'selected-avatar' : null}
            name='patterns-05'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(patterns5_avatar.indexOf('base64') > -1) ? patterns5_avatar : require(`./${patterns5_avatar}`).default} />
          {/* sci */}
          <Image
            className={this.isAvatarSelected('sports-01') ? 'selected-avatar' : null}
            name='sports-01'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(sports1_avatar.indexOf('base64') > -1) ? sports1_avatar : require(`./${sports1_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('sports-02') ? 'selected-avatar' : null}
            name='sports-02'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(sports2_avatar.indexOf('base64') > -1) ? sports2_avatar : require(`./${sports2_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('sports-03') ? 'selected-avatar' : null}
            name='sports-03'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(sports3_avatar.indexOf('base64') > -1) ? sports3_avatar : require(`./${sports3_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('sports-04') ? 'selected-avatar' : null}
            name='sports-04'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(sports4_avatar.indexOf('base64') > -1) ? sports4_avatar : require(`./${sports4_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('sports-05') ? 'selected-avatar' : null}
            name='sports-05'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(sports5_avatar.indexOf('base64') > -1) ? sports5_avatar : require(`./${sports5_avatar}`).default} />
        </div>
      </div>
    );
  }
}

SatCoreRegister('StudentDefaultAvatarPicker', StudentDefaultAvatarPicker);
