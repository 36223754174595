/* eslint-disable max-len */
import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../css/ActivityProgressBar.less';

const ActivityProgressBar = observer((props) => {
  const {
    assignmentManager // eslint-disable-line no-unused-vars
  } = useContext(MobXProviderContext);

  if (!assignmentManager.showProgress) {
    return (<></>);
  }

  const label = `${assignmentManager.completedProgress} of ${assignmentManager.totalProgress}`;
  const progress = (assignmentManager.completedProgress < 1) ? 0 : (assignmentManager.completedProgress / assignmentManager.totalProgress) * 100;

  const dataStyle = {
    width: `${progress}%`
  };

  return (
    <div className='activity-progress-bar'>
      <div className='bar-container'>
        <div className='bar-data' style={dataStyle}>
          {/* <span style={progresstext}>{`${progress}%`}</span> */}
        </div>
      </div>
      <div className='bar-label'>
        <span>Progress </span><span className='progress-value'>{label}</span>
      </div>
    </div>

  );
});

export default ActivityProgressBar;
