import { ICON_CIRCLE_STATUS } from '../constants/IconCircleStatusConstants';

import { registerClass } from '../SatCoreRegistry';

export default class IconCircleStatusService {
  static getIconCircleStatusForAdminClassroomReportButton = (classroom) => {
    if (classroom?.hasReports) {
      return ICON_CIRCLE_STATUS.FULL_CIRCLE;
    } else if (classroom?.hasAssignments) {
      return ICON_CIRCLE_STATUS.HALF_CIRCLE;
    } else {
      return ICON_CIRCLE_STATUS.EMPTY_CIRCLE;
    }
    // TODO remove
    // // TODO replace mock data with actual data
    // const random = Math.random();
    // if (random < 0.3) {
    //   // full circle
    //   return ICON_CIRCLE_STATUS.FULL_CIRCLE;
    // } else if (random < 0.6) {
    //   // half circle
    //   return ICON_CIRCLE_STATUS.HALF_CIRCLE;
    // } else {
    //   // empty circle
    //   return ICON_CIRCLE_STATUS.EMPTY_CIRCLE;
    // }
  }

  static isIconCircleStatusFull = (status) => {
    return status.key === ICON_CIRCLE_STATUS.FULL_CIRCLE.key;
  }

  static isIconCircleStatusHalf = (status) => {
    return status.key === ICON_CIRCLE_STATUS.HALF_CIRCLE.key;
  }

  static isIconCircleStatusEmpty = (status) => {
    return status.key === ICON_CIRCLE_STATUS.EMPTY_CIRCLE.key;
  }
}

registerClass('IconCircleStatusService', IconCircleStatusService);
