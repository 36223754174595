import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Container, Loader, Pagination } from 'semantic-ui-react';

import { DIALOG_NAMES } from '../managers/DialogManager';

import '../css/AdminUsersView.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { VIEW_SELECTION } from '../managers/NavigationManager';

import UserService from '../services/UserService';

export default @inject(
  'adminUsersManager',
  'dialogManager',
  'filteredHeaderTableManager',
  'navigationManager',
  'schoolManager',
  'userManager'
)
@observer
class AdminUsersView extends Component {
  filteredHeaderTableKey='admin-users-table-key';

  constructor(props) {
    super(props);

    this.FilteredHeaderTable = SatCoreComponent('FilteredHeaderTable');
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.BreadCrumbs = SatCoreComponent('BreadCrumbs');
    this.RegTypeInfoIconPopup = SatCoreComponent('RegTypeInfoIconPopup');

    this.state = {
      institutionId: null,
      rolesData: []
    };
  }

  async componentDidMount() {
    const {
      filteredHeaderTableManager, navigationManager, schoolManager
    } = this.props;
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('view')) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.USERS);
    }

    let institutionId = '';
    if (urlParams.has('institutionId')) {
      /* user came from a school card */
      institutionId = urlParams.get('institutionId');
      this.setState({ institutionId });

      const { institutionName } = await navigationManager.getAdminUrlParams();
      schoolManager.setSearchText(institutionName);
      schoolManager.setCurrentSchoolId(institutionId);
      await this.setBreadcrumbs();
    }
    filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);
    this.fetchRoles();
    this.fetchUsers(institutionId);
  }

  /**
   * Handle the following cases:
   *
   * (1) User is coming from the 'Schools' tab & needs to break out
   * of being filtered by a previous `institutionId` — or
   *
   * (2) URL changes while the component is still mounted
   *
   * (3) Filters were cleared in manager so we reset and remove all filtering
   */
  async componentDidUpdate() {
    const { filteredHeaderTableManager } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    let changesMade = false;
    let institutionId = '';
    if (urlParams.has('institutionId')) {
      institutionId = urlParams.get('institutionId');
      /* (1) if institutionId was passed to the component & has changed, update it */
      if (institutionId !== this.state.institutionId) {
        await this.setState({ institutionId });
        changesMade = true;
      }
    } else {
      /* (2) If institutionId was not passed, but one still exists in state, clear it */
      if (this.state.institutionId) {
        await this.setState({ institutionId: null });
        changesMade = true;
      }
      /* (3) If table managager is cleared then clear all the filters */
      if (filteredHeaderTableManager.isCleared) {
        filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);
        changesMade = true;
      }
    }
    if (changesMade) {
      this.fetchRoles();
      this.fetchUsers();
    }
  }

  componentWillUnmount = async () => {
    const { adminUsersManager, filteredHeaderTableManager } = this.props;
    // clear the table data
    filteredHeaderTableManager.clearAll();
    adminUsersManager.clearUsers();
  }

  setBreadcrumbs = async () => {
    const { navigationManager, t, userManager } = this.props;
    const { isDistrictAdmin } = userManager;
    const hasMultipleInstitutions = UserService.hasMultipleInstitutions('userManager');
    if (isDistrictAdmin || hasMultipleInstitutions) {
      navigationManager.clearAllPaths();

      const { urlParamStr } = await navigationManager.getAdminUrlParams();

      const schoolsBreadcrumbObj = {
        fromView: VIEW_SELECTION.DASHBOARD,
        path: { name: t('schools') }
      };
      const schoolNameBreadcrumbObj = {
        fromView: VIEW_SELECTION.DASHBOARD,
        path: {},
        paramName: 'institutionName'
      };
      let routerUrl_users = `/users?view=${VIEW_SELECTION.USERS}`;
      if (urlParamStr) {
        routerUrl_users += `&${urlParamStr}`;
      }
      const usersBreadcrumbObj = {
        fromView: VIEW_SELECTION.USERS,
        path: { name: t('users'), routerUrl: routerUrl_users }
      };
      await navigationManager.setBreadcrumb(schoolsBreadcrumbObj);
      await navigationManager.setBreadcrumb(schoolNameBreadcrumbObj);
      await navigationManager.setBreadcrumb(usersBreadcrumbObj);
    }
  }

  fetchUsers = async () => {
    const { adminUsersManager, filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    const { institutionId } = this.state;
    if (tableData) {
      await adminUsersManager.getUsersByInstitution(
        institutionId,
        tableData.activePage,
        tableData.column,
        tableData.direction,
        tableData.filters);
    } else {
      await adminUsersManager.getUsersByInstitution(institutionId);
    }
  }

  fetchRoles = async () => {
    const { adminUsersManager } = this.props;
    await adminUsersManager.fetchSatelliteRoles();
    this.createRolesData();
  }

  onPageChange = async (_event, pageInfo) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, pageInfo.activePage);
      await this.fetchUsers();
    }
  }

  handleSort = async (clickedColumn, filters) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      if (clickedColumn) {
        const newDirection = tableData.direction === 'ascending' ? 'descending' : 'ascending';
        filteredHeaderTableManager.setSortColumn(this.filteredHeaderTableKey, clickedColumn);
        filteredHeaderTableManager.setSortDirection(this.filteredHeaderTableKey, newDirection);
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
        await this.fetchUsers();
      } else {
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      }
    }
  }

  handleFilter = async (filters) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, 1);
      await this.fetchUsers();
    }
  }

  handleClickRoles = (userRoles) => {
    alert(`handle user roles${userRoles}`);
  }

  handleRegisterTeacherModal = async () => {
    const { history, navigationManager } = this.props;
    let routerUrl = `/adminAddTeacher?view=${VIEW_SELECTION.USERS}`;
    const paramsToExcludeFromStr = 'userId, userName';
    const { urlParamStr } = await navigationManager.getAdminUrlParams(
      paramsToExcludeFromStr
    );
    if (urlParamStr) {
      routerUrl += `&${urlParamStr}`;
    }
    history.push(routerUrl);
  }

  gotoUserProfile = async (user) => {
    const { history, navigationManager } = this.props;
    let routerUrl = `/adminProfile?view=${VIEW_SELECTION.USERS}&userId=${user.id}&userName=${user.displayName}`;
    const paramsToExcludeFromStr = 'userId, userName';
    const { urlParamStr } = await navigationManager.getAdminUrlParams(
      paramsToExcludeFromStr
    );
    if (urlParamStr) {
      routerUrl += `&${urlParamStr}`;
    }
    history.push(routerUrl);
  }

  closeUserProfileModal = (refresh) => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.USER_PROFILE);
    if (refresh) {
      this.fetchUsers();
    }
  }

  /** render content for role name popup, we hide non-satellite roles */
  renderRolesContent = (roleIdsArray) => {
    const { adminUsersManager } = this.props;
    const rolesArrayContent = [];
    if (roleIdsArray) {
      if (roleIdsArray.length > 0) {
        roleIdsArray.map((roleId, index) => {
          const role = adminUsersManager.roles.find((role) => role.id === roleId);
          if (role) {
            rolesArrayContent.push(<li key={index}>{role.name}</li>);
          }
        });
      }
    }
    return (
      <ul className='roles-list'>
        {rolesArrayContent}
      </ul>
    );
  }

  /** render names list for column, we hide non-satellite roles */
  validateRolesNames = (roleIdsArray) => {
    const { adminUsersManager } = this.props;
    const roleNamesArray = [];
    if (roleIdsArray) {
      if (roleIdsArray.length > 0) {
        roleIdsArray.map((roleId, index) => {
          const role = adminUsersManager.roles.find((role) => role.id === roleId);
          if (role) {
            roleNamesArray.push(role.name);
          }
        });
      }
    }
    return roleNamesArray;
  }

  createRolesData = async () => {
    const { adminUsersManager, t } = this.props;
    const rolesData = [{
      key: 'default',
      text: t('labelChooseRole'),
      value: 0
    }];
    adminUsersManager.roles.map((role) => {
      rolesData.push(
        {
          key: role.id,
          text: role.name,
          value: role.id
        }
      );
    });
    this.setState({ rolesData });
  }

  createHeaderData = (rolesData) => {
    const { t } = this.props;

    const userStatusFilterOptions = [
      {
        key: 'default',
        text: t('labelChooseStatus'),
        value: 0
      },
      {
        key: 'active',
        text: t('labelStatusActive'),
        value: '1'
      },
      {
        key: 'inactive',
        text: t('labelStatusInactive'),
        value: '0'
      }
    ];

    const headerData = [
      {
        label: t('labelUsername'),
        sortKey: 'username',
        filterKey: 'username',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: t('labelFirstName'),
        sortKey: 'firstName',
        filterKey: 'firstName',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: t('labelLastName'),
        sortKey: 'lastName',
        filterKey: 'lastName',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: t('labelInstitutionName'),
        sortKey: 'institutionName',
        filterKey: 'institutionName',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: t('labelUserRole'),
        sortKey: 'userRoles',
        filterKey: 'userRoles',
        filterLabel: 'Search for Role',
        filterComparator: 'contains',
        filterPopupType: 'optionSelection',
        filterDropdownOptions: rolesData,
        filterAutoCompleteCallback: null
      },
      {
        label: t('labelStatus'),
        sortKey: 'status',
        filterKey: 'status',
        filterLabel: '',
        filterPopupType: 'optionSelection',
        filterDropdownOptions: userStatusFilterOptions,
        filterAutoCompleteCallback: null
      },
      // TODO commented out per CF-2501
      // {
      //   label: 'Last Login',
      //   sortKey: 'lastLogin',
      //   filterKey: '',
      //   filterLabel: '',
      //   filterAutoCompleteCallback: null
      // },
      {
        /* 'View' button placeholder */
        label: '',
        sortKey: '',
        filterKey: ''
      }
    ];
    return headerData;
  }

  createBodyData = () => {
    const { adminUsersManager } = this.props;
    const productResourceRows = [];

    adminUsersManager.users.map((user) => {
      // We have to get valid role names for the user's role ids that can be shown in satellite.
      // We need both a rendered UL list for the popup and a regular flat list of names for the column cell.
      const roleIdsArray = user.userRoleIds.split(',');
      const validRoleNames = this.validateRolesNames(roleIdsArray);
      let rolesListItemContent = null;
      let roleColumnCellText = '—';
      // if more than one role, get the popup content, and display the length in the cell
      if (validRoleNames.length > 1) {
        rolesListItemContent = this.renderRolesContent(roleIdsArray);
        roleColumnCellText = validRoleNames.length;
      } else if (validRoleNames.length == 1) {
        roleColumnCellText = validRoleNames[0];
      }

      const productResourceRow = [
        {
          /* Email/Username */
          columnName: 'username',
          columnText: user.username,
          columnClassName: 'truncate-long',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* First Name */
          columnName: 'firstName',
          columnText: user.firstName,
          columnClassName: 'truncate-short',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* Last Name */
          columnName: 'lastName',
          columnText: user.lastName,
          columnClassName: 'truncate-short',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* School */
          columnName: 'school',
          columnText: user.institutionName,
          columnClassName: 'truncate-long',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* User role */
          columnName: 'userRole',
          columnText: roleColumnCellText,
          columnClassName: (validRoleNames.length > 1) ? 'truncate-short clickableCell' : 'truncate-short',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click',
          tooltipContent: rolesListItemContent
        },
        {
          /* Status */
          columnName: 'enabled',
          columnText: (user.enabled) ? 'Active' : 'Inactive',
          columnClassName: 'truncate-short',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        // TODO commented out per CF-2501
        // {
        //   /* Last Login */
        //   columnName: 'lastLogin',
        //   columnText: (user.lastLogin) ? user.lastLogin : '—',
        //   columnClassName: 'truncate-short',
        //   columnOnClick: null,
        //   columnButtonOnClick: null,
        //   showTooltip: true,
        //   showTootipEvent: 'click',
        //   tooltipContent: (user.lastLogin) ? user.lastLogin : 'No last login to display.'
        // },
        {
          /* 'View' button placeholder */
          columnName: null,
          columnText: 'View',
          columnClassName: '',
          columnOnClick: null,
          columnButtonOnClick: () => this.gotoUserProfile(user)
        }
      ];
      productResourceRows.push(productResourceRow);
    }, this);
    return productResourceRows;
  }

  render() {
    const { adminUsersManager, filteredHeaderTableManager, history, userManager, t } = this.props;
    const { isDistrictAdmin } = userManager;
    const hasMultipleInstitutions = UserService.hasMultipleInstitutions('userManager');
    const { rolesData } = this.state;
    const { BreadCrumbs, FilteredHeaderTable, RegTypeInfoIconPopup } = this;
    let tableBodyData = [];
    if (adminUsersManager.loaded) {
      tableBodyData = this.createBodyData(adminUsersManager.users);
    }
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    let activePage = 0;
    let column = '';
    let direction = '';
    let filters = [];
    if (tableData) {
      activePage = tableData.activePage;
      column = tableData.column;
      direction = tableData.direction;
      filters = tableData.filters;
    }

    const showAddTeacher = this.state.institutionId && !userManager.isSsoUser;

    return (
      <Container className='usersView AdminUsersView' fluid>
        {this.state.institutionId && (isDistrictAdmin || hasMultipleInstitutions) && (
          <div className='header-nav'>
            <Container className='bread-crumb-wrapper top' fluid>
              <BreadCrumbs history={history} />
            </Container>
          </div>
        )}
        <Container>
          <div className={showAddTeacher ? 'tableContainerHeader taller' : 'tableContainerHeader'}>
            <div className='tableTitle theme-header-title'>Users</div>
            <div className='admin-users-table-info-wrapper'>
              <RegTypeInfoIconPopup
                iconImageClassName='admin-users-table-info-icon'
                popupClassName='admin-users-popup' />
            </div>
            <div className='admin-users-table-spacer'>
              {/* placeholder */}
            </div>
            <div className={showAddTeacher ? 'tableRightHeader withAddStudent' : 'tableRightHeader'}>
              {showAddTeacher && (
                <div className='add-new-teacher-button-container'>
                  <Button
                    className='add-button-new-teacher'
                    onClick={this.handleRegisterTeacherModal}
                    primary
                    type='button'>
                    {t('addTeacherButton', '+ Add New Teacher')}
                  </Button>
                </div>
              )}
              <div className='tablePagination'>
                {tableBodyData.length > 0 &&
                  <Pagination activePage={activePage} onPageChange={this.onPageChange} totalPages={adminUsersManager.totalPages} />}
              </div>
            </div>
          </div>
          <div className='tableContainer'>
            <FilteredHeaderTable
              allowClearAllFilters
              column={column}
              direction={direction}
              filtersData={filters}
              handleFilter={this.handleFilter}
              handleSort={this.handleSort}
              tableBodyData={tableBodyData}
              tableHeaderData={this.createHeaderData(rolesData)}
              tableId={this.filteredHeaderTableKey} />
          </div>
          {(!adminUsersManager.loaded) && (
            <div className='null-state-panel'>
              <Loader key={0} active inline />
            </div>
          )}
          {adminUsersManager.loaded && (!tableBodyData || tableBodyData.length < 1) && (
            <div className='null-state-panel'>
              No Data Found
            </div>
          )}
        </Container>
      </Container>
    );
  }
}
SatCoreRegister('AdminUsersView', AdminUsersView);
