import { action, makeObservable, observable } from 'mobx';

export const DIALOG_NAMES = {
  ADD_ASSIGNMENT: 'addassignment',
  ADD_CLASS: 'addclass',
  ADD_COURSE_TO_CLASS: 'addcoursetoclass',
  ADD_CUSTOM_ASSESSMENT: 'addcustomassessment',
  ADD_EDIT_GROUP: 'addEditGroup',
  ADD_JOIN_CLASS: 'add-join-class',
  ADD_LIBRARY_COURSE_TO_CLASSROOMS: 'add-library-course-to-classrooms',
  ADD_LTI_ASSIGNMENT: 'add-lti-assignment',
  ADD_STUDENTS_TO_CLASSROOM: 'add-students-to-classroom',
  ADD_STUDENT_TO_CLASSROOMS: 'add-student-to-classrooms',
  ADD_TEACHER_TO_CLASSROOMS: 'add-teacher-to-classrooms',
  ARCHIVE_OR_ACTIVATE_STUDENT: 'archive-or-activate-student',
  ASSESSMENT_EDITOR: 'assessmenteditor',
  ASSIGNMENT_INFO: 'assignmentinfo',
  AVATAR: 'avatar',
  CERTIFICATE: 'certificate',
  CHANGE_PASSWORD: 'changepassword',
  CHANGE_STUDENT_PASSWORD: 'changestudentpassword',
  CLASSROOM_REPORT_TYPE_SELECTOR_MODAL: 'classroom-report-type-selector-modal',
  CLASS_IMAGE: 'classimage',
  CONFIRM: 'confirm',
  CONTENT_ASSIGNMENT: 'contentassignment',
  CONTENT_ELEMENT: 'contentelement',
  CONTENT_PREVIEW: 'contentPreview',
  COPY_LIBRARY_RESOURCE_MODAL: 'copy-library-resource-modal',
  COURSE_ELEMENT: 'courseelement',
  CREATE_ASSESSMENT_BANK_UNIT_MODAL: 'create-assessment-bank-unit-modal',
  CREATE_NEW_LIBRARY_RESOURCE_MODAL: 'create-new-library-resource-modal',
  EDIT_ASSIGNMENT: 'editassignment',
  EDIT_ASSIGNMENT_NOTES: 'editdialogNotes',
  EDIT_BULK_ASSIGNMENT: 'editbulkassignment',
  ERROR: 'error',
  EXPORT_ASSIGNMENT: 'exportassignment',
  EXPORT_REPORT: 'exportreport',
  INFO: 'info',
  ITEM_BANK_ITEM_PREVIEW: 'itembankitempreview',
  ITEM_BANK_VIEW: 'itembankview',
  JOIN_CLASS: 'joinclass',
  LEARNOSITY_ITEM_PREVIEW: 'learnosityitempreview',
  LIBRARY_ASSESSMENT_BANK_VIEW: 'library-assessment-bank-view',
  LIBRARY_ASSESSMENT_BUILDER_VIEW: 'library-assessment-builder-view',
  LIBRARY_ASSESSMENT_CART_VIEW: 'library-assessment-cart-view',
  LIBRARY_ASSESSMENT_SHOP_VIEW: 'library-assessment-shop-view',
  LIBRARY_CONTENT_USAGE_MODAL: 'library-content-usage-modal',
  LIBRARY_RESOURCE_DETAILS_MODAL: 'library-resource-details-modal',
  LIBRARY_SHARE_ASSESSMENT_USERS_VIEW: 'library-share-assessment-users-view',
  LIBRARY_STANDARDS_TAGS_MODAL: 'library-standards-tags-modal',
  MIGRATE_STUDENT_DATA_MODAL: 'migrate_student_data_modal',
  REACTIVATE_CLASS_MODAL: 'reactivate-class-modal',
  REGISTER_STUDENT: 'registerstudent',
  REPORT_HIDE_COLUMNS_MODAL: 'report-hide-columns-modal',
  RESOURCE_BANK_SHOP: 'resource-bank-shop',
  RESOURCE_BANK_VIEW: 'resource-bank-view',
  RESOURCE_ITEM_SHOP: 'resourceitemshop',
  SCHOOL_IMAGE: 'schoolimage',
  SCORE_ASSIGNMENT: 'scoreassignment',
  SELECT_LESSON_PROFILE_MODAL: 'select-lesson-profile-modal',
  SELECT_NEW_LIBRARY_RESOURCE_MODAL: 'select-new-library-resource-modal',
  SELECT_NEW_RESOURCE_MODAL: 'select-new-resource-modal',
  SELECT_PREVIEW_MODE_MODAL: 'select-preview-mode-modal',
  SHARE_ASSESSMENT_MODAL: 'shareAssessmentModal',
  STANDARDS: 'standards',
  STANDARDS_DOCUMENTS: 'standardsdocuments',
  STUDENT_ASSIGNMENT: 'studentassignment',
  STUDENT_FEEDBACK: 'studentfeedback',
  TAGS: 'tags',
  TAGS_STANDARDS: 'tagsstandards',
  TEACHER_FEEDBACK: 'teacherfeedback',
  TEACHER_LIBRARY_RESOURCE_DETAILS_MODAL: 'teacher-library-resource-details-modal',
  TEXT: 'text',
  TRIAL_ENTER_PURCHASE_CODES: 'trial-enter-codes',
  UPDATE_USER_PASSWORD_MODAL: 'update-user-password-modal',
  VIEW_NOTIFICATION: 'view-notification',
  WORKSPACE_FILE_MODAL: 'workspace-file-modal',
  WORKSPACE_FILE_RENAME_MODAL: 'workspace-file-rename-modal'
};

class DialogManager {
  @observable.shallow openedDialogs = new Map();

  @observable useWhiteInfoIcon = false;

  constructor() {
    makeObservable(this);
  }

  @action setOpenDialog = (dialogName, dialogData, callback = null) => {
    this.openedDialogs.set(dialogName, {
      data: dialogData,
      close: callback === null ? () => this.closeDialog(dialogName) : callback
    });
  }

  @action setUseWhiteInfoIcon = (toggle) => {
    this.useWhiteInfoIcon = toggle;
  }

  @action
  closeDialog = (dialogName) => {
    this.openedDialogs.delete(dialogName);
  }

  @action
  closeAllDialogs = () => {
    this.openedDialogs.clear();
  }
}

export default new DialogManager();
