import { action, computed, makeObservable, observable } from 'mobx';

import { getRegisteredClass } from '../SatCoreRegistry';

import appManager from './AppManager';
import userManager from './UserManager';

export class TeacherProductViewManager {
  @observable allowTeacherProductView = false;
  @observable currentTeacherProductCourse = null;

  @observable isClassroomSelectionModalOpen = false;

  constructor() {
    makeObservable(this);
  }

  @action clearAll = () => {
    this.setCurrentTeacherProductCourse(null);
    this.setIsClassroomSelectionModalOpen(false);
  }

  @action setAllowTeacherProductView = (toggle) => {
    this.allowTeacherProductView = toggle;
  }

  @action setCurrentTeacherProductCourse = (course) => {
    this.currentTeacherProductCourse = course;
  }

  @action setIsClassroomSelectionModalOpen = (toggle) => {
    this.isClassroomSelectionModalOpen = toggle;
  }

  @computed get isPathnameTeacherProducts() {
    return userManager.isTeacher && (appManager.location?.pathname === '/products' ||
      (!appManager.location?.pathname && window.location?.pathname === '/products'));
  }
}

export default new TeacherProductViewManager();
