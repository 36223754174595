/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { SatCoreRegister } from '../../SatCoreRegistry';

import { REPORT_TABLE_TYPE } from '../../services/reports/ReportConstants';

const LTIReportBreadCrumbs = observer((props) => {
  const { reportIdentityManager } = useContext(MobXProviderContext);

  const setLtiReportTable = (event) => {
    const { reportTable } = event.target.dataset;
    reportIdentityManager.setLtiReportTable(reportTable);
  };

  const linkedCrumb = (label, tableType) => {
    return (
      <span title={label}>
        <a
          className='section clickable'
          data-report-table={tableType}
          onClick={setLtiReportTable}>{label}
        </a>
        <i aria-hidden='true' className='right chevron icon divider' />
      </span>
    );
  };

  const unlinkedCrumb = (label) => {
    return (
      <span title={label}>
        <div className='active section'>{label}</div>
      </span>
    );
  };

  const reportTable = reportIdentityManager.ltiReportTable;

  const standardsCrumb = linkedCrumb('Standards Report', REPORT_TABLE_TYPE.SUMMARY);

  const summaryCrumb = (reportTable === REPORT_TABLE_TYPE.SUMMARY)
    ? unlinkedCrumb('Class Summary') : linkedCrumb('Class Summary', REPORT_TABLE_TYPE.SUMMARY);

  let detailCrumb;
  if (reportTable === REPORT_TABLE_TYPE.DETAIL) {
    detailCrumb = unlinkedCrumb('Class Detail (By Student)');
  } else if (reportTable === REPORT_TABLE_TYPE.INDIVIDUAL) {
    detailCrumb = linkedCrumb('Class Detail (By Student)', REPORT_TABLE_TYPE.DETAIL);
  }

  const individualCrumb = (reportTable === REPORT_TABLE_TYPE.INDIVIDUAL)
    ? unlinkedCrumb(reportIdentityManager.studentFullName) : null;

  return (
    <div className='ui large breadcrumb'>
      {standardsCrumb}
      {summaryCrumb}
      {detailCrumb}
      {individualCrumb}
    </div>
  );
});
export default LTIReportBreadCrumbs;

SatCoreRegister('LTIReportBreadCrumbs', LTIReportBreadCrumbs);
