import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Loader } from 'semantic-ui-react';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { PATH_TYPES, VIEW_SELECTION } from '../managers/NavigationManager';

@inject(
  'accommodationsManager', 'adminClassroomManager', 'classroomManager',
  'dialogManager', 'navigationManager', 'userManager')
@observer
class AccommodationsView extends Component {
  constructor(props) {
    super(props);
    this.AccommodationsTable = SatCoreComponent('AccommodationsTable');
    this.CourseListBanner = SatCoreComponent('CourseListBanner');

    this.state = {
      isActive: true,
      loadingAccommodationsView: false
    };
  }

  async componentDidMount() {
    const { accommodationsManager, classroomManager, location, navigationManager, userManager } = this.props;
    const { currentClassroomId } = classroomManager;
    const { isDistrictOrSchoolAdmin } = userManager;

    accommodationsManager.clearAccommodationsRoster();

    if (isDistrictOrSchoolAdmin) {
      await this.initAdminData();
    } else {
      const urlParams = new URLSearchParams(location.search);
      if (!currentClassroomId && urlParams.has('classroomId')) {
        classroomManager.setCurrentClassroomId(urlParams.get('classroomId'));
      }
      navigationManager.clearAllPaths();
      navigationManager.setView(VIEW_SELECTION.DASHBOARD);
      await this.initData();
    }
  }

  async componentWillUnmount() {
    const { accommodationsManager } = this.props;
    accommodationsManager.clearAccommodationsRoster();
  }

  initAdminData = async () => {
    const {
      adminClassroomManager, classroomManager, history, navigationManager
    } = this.props;
    const paramsToExcludeFromStr = 'classroomId';
    const {
      classroomId, institutionId, urlParamStr, userId, userName
    } = await navigationManager.getAdminUrlParams(paramsToExcludeFromStr);
    if (userId && userName) {
      navigationManager.setView(VIEW_SELECTION.USERS);
    } else {
      navigationManager.setView(VIEW_SELECTION.CLASSROOMS);
    }
    navigationManager.clearAllPaths();
    await this.setAdminBreadcrumbs(institutionId, userId, userName, urlParamStr);
    if (classroomId && classroomManager.getClassroom(classroomId)) {
      await this.initData();
    } else {
      const classroom = await adminClassroomManager.fetchClassroom(classroomId);
      if (classroom) {
        await classroomManager.setAdminClassroom(classroomId, classroom);
        await this.initData();
      } else {
        const routerUrl = `/classrooms?view=${VIEW_SELECTION.CLASSROOMS}&${urlParamStr}`;
        await history.push(routerUrl);
      }
    }
  }

  setAdminBreadcrumbs = async (institutionId, userId, userName, urlParamStr) => {
    const { navigationManager, userManager, t } = this.props;
    if (institutionId && userManager.isDistrictAdmin) {
      const schoolsBreadcrumbObj = {
        fromView: VIEW_SELECTION.DASHBOARD,
        path: { name: 'Schools' }
      };
      const schoolNameBreadcrumbObj = {
        fromView: VIEW_SELECTION.DASHBOARD,
        path: {},
        paramName: 'institutionName'
      };
      await navigationManager.setBreadcrumb(schoolsBreadcrumbObj);
      await navigationManager.setBreadcrumb(schoolNameBreadcrumbObj);
    }
    if (userId && userName) {
      let routerUrl_users = `/users?view=${VIEW_SELECTION.USERS}`;
      if (urlParamStr) {
        routerUrl_users += `&${urlParamStr}`;
      }
      const usersBreadcrumbObj = {
        fromView: VIEW_SELECTION.USERS,
        path: { name: 'Users', routerUrl: routerUrl_users }
      };
      let routerUrl_adminProfile = `/adminProfile?view=${VIEW_SELECTION.USERS}`;
      if (urlParamStr) {
        routerUrl_adminProfile += `&${urlParamStr}`;
      }
      const userNameBreadcrumbObj = {
        path: {
          name: userName,
          routerUrl: routerUrl_adminProfile
        }
      };
      await navigationManager.setBreadcrumb(usersBreadcrumbObj);
      await navigationManager.setBreadcrumb(userNameBreadcrumbObj);
    } else {
      let routerUrl_classrooms = `/classrooms?view=${VIEW_SELECTION.CLASSROOMS}`;
      if (urlParamStr) {
        routerUrl_classrooms += `&${urlParamStr}`;
      }
      const classroomsBreadcrumbObj = {
        fromView: VIEW_SELECTION.CLASSROOMS,
        path: { name: t('classesLabel', 'Classes'), routerUrl: routerUrl_classrooms }
      };
      await navigationManager.setBreadcrumb(classroomsBreadcrumbObj);
    }
  }

  initData = async () => {
    const { classroomManager, navigationManager, t } = this.props;
    if (!classroomManager.getClassroom(classroomManager.currentClassroomId)) {
      classroomManager.fetchClassroomData(classroomManager.currentClassroomId).then(() => {
        const classroomName = classroomManager.getClassName(classroomManager.currentClassroomId);
        const breadcrumbName = `${t('accommodationsFor')} ${classroomName}`;
        navigationManager.addPath({
          currentClassroomId: classroomManager.currentClassroomId,
          currentCourseId: null,
          currentElementId: null,
          name: breadcrumbName,
          treeNavigationFunction: null,
          type: PATH_TYPES.LINK,
        });
      });
    } else {
      const classroomName = classroomManager.getClassName(classroomManager.currentClassroomId);
      const breadcrumbName = `${t('accommodationsFor')} ${classroomName}`;
      navigationManager.addPath({
        currentClassroomId: classroomManager.currentClassroomId,
        currentCourseId: null,
        currentElementId: null,
        name: breadcrumbName,
        treeNavigationFunction: null,
        type: PATH_TYPES.LINK,
      });
    }
    // TODO remove
    // navigationManager.setBreadcrumb({
    //   path: { name: t('accommodations'), routerUrl: NavigationService.getCurrentRouterUrl() }
    // });
    await this.loadActiveRoster();
  }

  loadActiveRoster = async () => {
    this.setState({ isActive: true, loadingAccommodationsView: true });
    const { accommodationsManager } = this.props;
    await accommodationsManager.fetchAccommodationsRoster();
    this.setState({ loadingAccommodationsView: false });
  }

  render() {
    const { classroomManager, history, userManager } = this.props;
    const { isDistrictOrSchoolAdmin } = userManager;

    const { isActive, loadingAccommodationsView } = this.state;

    let { readOnly } = this.props;

    const { AccommodationsTable } = this;
    const { CourseListBanner } = this;

    const classroomId = classroomManager.currentClassroomId;
    const classroom = classroomManager.getClassroom(classroomId);

    if (readOnly === undefined || readOnly === null) {
      readOnly = false;
    }

    return loadingAccommodationsView ? <Loader active /> : (
      <>
        {(!isDistrictOrSchoolAdmin || classroom) && (
          <div className='accommodations-view'>
            <CourseListBanner
              hideAddCourseButton={true}
              hideAddStudentButton={true}
              history={history}
              showBreadCrumbs='bottom' />

            <div className='accommodations-table-container'>
              <AccommodationsTable
                {...this.props}
                isActive={isActive}
                readOnly={readOnly} />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default AccommodationsView;

SatCoreRegister('AccommodationsView', AccommodationsView);
