/* eslint-disable max-len */
import CryptoMD5 from 'crypto-js/md5';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import ReactHtmlParser from 'react-html-parser';
import UtilityService from '../services/UtilityService';
import { CONTENT_ITEM_TYPES } from './ContentManager';

import Auth from './AuthManager';
import courseManager from './CourseManager';
import userManager from './UserManager';

import { stripHtmlTags } from '../utils';

export const ASSESSMENT_ENDPOINTS = {
  ADD_ELEMENT_TO_LEARNOSITY_ACTIVITY: '/api/addElementToLearnosityActivity',
  ADD_ITEMS_TO_LEARNOSITY_ACTIVITY_WITH_ALIGNMENTS: '/api/addItemsToLearnosityActivityWithAlignmentsByIds',
  CHECK_CONTENT_ITEM_NAME: '/api/checkContentItemNameAddress',
  CREATE_CUSTOM_COURSE_RESOURCE_ALIGNMENTS: '/api/createCustomCourseResourceAlignments',
  DELETE_ELEMENT_FROM_LEARNOSITY_ACTIVITY: '/api/deleteElementFromLearnosityActivity',
  GET_BY_CONTENT_ITEM_ID_LIST: '/api/contentItem/getByContentItemIdList',
  GET_CMAP_LIST: '/api/searchLicencedItembankCmaps',
  GET_CONTENT_ITEM: '/api/viewContentItem',
  GET_CONTENT_ITEM_LIST: '/api/searchContentItemsByProductLicense',
  GET_FULL_CMAP: '/api/viewFullCurriculumMapJson',
  GET_FULL_STANDARD_DOC: '/api/viewFullStandardDocument',
  GET_ITEMBANK_CMAP_LIST: '/api/viewItembankCmaps',
  GET_PRODUCT_CMAP_LIST: '/api/searchLicencedProductCmaps',
  GET_RESOURCE: '/api/viewResource',
  GET_STANDARD_ALIGNMENTS_BY_CONTENT_ITEM_ID_LIST: '/api/viewContentItemStandardsByIdList',
  MOVE_ELEMENT_WITHIN_LEARNOSITY_ACTIVITY: '/api/moveElementWithinLearnosityActivity',
  UPDATE_CONTENT_ITEM_DISPLAY_PROPS: '/api/updateContentItemDisplayProps',
  UPDATE_CONTENT_ITEM_NAME: '/api/updateContentItemName',
  GET_STATE_PUB_STANDARD_FRAMEWORK: '/api/viewAdoptedEvotextPublisherStandardFrameworksByState'
};

export const ASSESSMENT_TRANSACTION = {
  PAGE_SIZE: 25
};

export class AssessmentManager {
  constructor() {
    makeObservable(this);
  }

  // observables ************************
  @observable assessmentsMap = new Map();

  @observable pagedAssessmentsMap = new Map();

  @observable selectedNotAttachedResources = new Map();

  @observable updatedCachedAssessmentList = null;

  @observable currentContentItem = null;

  @observable currentResourceItem = null;

  /** `originalAssessmentItems` – good for determining if loading vs no results found) */
  @observable originalAssessmentItems = new Map();

  @observable assessmentItems = new Map();

  @observable pagedAssessmentItems = new Map();

  @observable attachedAssessmentItems = new Map();

  @observable cartedAssessmentItems = new Map();

  @observable collectedStandardAlignments = new Map();

  @observable collectedContentItemIds = [];

  @observable searchText = '';

  @observable searchTextTimeout = null;

  @observable totalPages = 0;

  @observable filteredAssessmentSettings = null; // { searchOperator: '', searchTerms: '', searchTags: '' };

  @observable currentAssessmentItemCount = 0;

  @observable assessmentItemsLoading = false;

  @observable productCmaps = new Map();

  @observable cmapStandardsJson = [];

  @observable cmapStandardsMap = new Map();

  @observable adoptedStandardFrameworks = new Map();

  @observable adoptedStandardsJson = [];

  @observable stdFrwkStandardsMap = new Map();

  @observable expanded = [];

  @observable standardsResponseData = null;

  // actions ************************
  // assessment ***
  @action clearCachedAssessments = async () => {
    this.cachedAssessments.clear();
  }

  @action clearAssessments = () => {
    this.assessmentsMap.clear();
  }

  @action clearPagedAssessments = () => {
    this.pagedAssessmentsMap.clear();
  }

  @action setFilteredAssessmentSettings = (settings) => {
    this.filteredAssessmentSettings = settings;
  }

  @action addAssessment = (assessmentContentItemId, assessment, maxPagedAssessmentSize = ASSESSMENT_TRANSACTION.PAGE_SIZE) => {
    if (assessmentContentItemId && assessment) {
      if (this.pagedAssessmentsMap.size < maxPagedAssessmentSize) {
        this.pagedAssessmentsMap.set(assessmentContentItemId, assessment);
      }
      if (!this.assessmentsMap.has(assessmentContentItemId)) {
        this.assessmentsMap.set(assessmentContentItemId, assessment);
      }
    }
  }

  // This loads a categoryId:categorytags map and tagId:tag map
  @action loadProductCmaps = (pCmaps) => {
    if (pCmaps) {
      pCmaps.forEach((pcm) => {
        if (pcm.id !== null && pcm.id !== '' && pcm.id !== undefined) {
          pcm.sortBy = pcm.friendlyName || pcm.displayName || pcm.name;
          this.productCmaps.set(pcm.id, pcm);
        }
      });
    }
  }

  // This loads a standard frameworks map
  @action loadAdoptedStandardFrameworks = (standardFrameworks) => {
    if (standardFrameworks) {
      standardFrameworks.forEach((standardFramework) => {
        if (standardFramework.id !== null && standardFramework.id !== '' && standardFramework.id !== undefined) {
          this.adoptedStandardFrameworks.set(standardFramework.id, standardFramework);
        }
      });
    }
  }

  @computed get selectedNotAttachedAssessmentIds() {
    return Array.from(this.selectedNotAttachedResources.keys());
  }

  @computed get selectedNotAttachedAssessmentArray() {
    return Array.from(this.selectedNotAttachedResources.values());
  }

  @action addSelectedNotAttachedAssessment = (assessment) => {
    if (assessment && assessment.id) {
      this.selectedNotAttachedResources.set(assessment.id, assessment);
    }
  }

  @action removeSelectedNotAttachedAssessment = (assessmentId) => {
    if (assessmentId && this.selectedNotAttachedResources.has(assessmentId)) {
      this.selectedNotAttachedResources.delete(assessmentId);
    }
  }

  @action clearSelectedNotAttachedResources = () => {
    this.selectedNotAttachedResources.clear();
  }

  @action setAssessmentSelected = (assessmentId) => {
    const assessment = this.assessmentsMap.get(assessmentId);
    let isSelected;
    if (this.selectedNotAttachedResources.has(assessmentId)) {
      this.removeSelectedNotAttachedAssessment(assessmentId);
      isSelected = false;
    } else {
      this.addSelectedNotAttachedAssessment(assessment);
      isSelected = true;
    }
    if (assessment) {
      assessment.selected = isSelected;
      assessment.attached = false;
    }
    const pagedAssessment = this.pagedAssessmentsMap.get(assessmentId);
    if (pagedAssessment) {
      pagedAssessment.selected = isSelected;
      pagedAssessment.attached = false;
    }
  }

  @action setUpdatedCachedAssessmentList = (list) => {
    this.updatedCachedAssessmentList = list;
  }

  @action setCurrentContentItem = (item) => {
    this.currentContentItem = item;
  }

  @action setCurrentResourceItem = (item) => {
    this.currentResourceItem = item;
  }

  @action setSearchText = (searchText) => {
    this.searchText = searchText;
  }

  @action setSearchTextTimeout = (searchTextTimeout) => {
    this.searchTextTimeout = searchTextTimeout;
  }

  @action setTotalPages = (totalPages) => {
    this.totalPages = totalPages;
  }

  // assessmentItem ***
  @action setCurrentAssessmentItemCount = (count) => {
    this.currentAssessmentItemCount = count;
  }

  @action clearAttachedAssessmentItems = () => {
    this.attachedAssessmentItems.clear();
  }

  @action clearAssessmentItems = () => {
    this.assessmentItems.clear();
  }

  @action clearCartedAssessmentItems = () => {
    this.cartedAssessmentItems.clear();
  }

  @action clearPagedAssessmentItems = () => {
    this.pagedAssessmentItems.clear();
  }

  @action clearAssessmentItemShopCollections = () => {
    this.pagedAssessmentItems.clear();
    this.assessmentItems.clear();
    this.cartedAssessmentItems.clear();
  }

  @action setOriginalAssessmentItems = (map) => {
    this.originalAssessmentItems = new Map(map);
  }

  @action addAssessmentItem = (id, item) => {
    if (item && id) {
      // if the current content item has the same id, it's checked.
      if (this.attachedAssessmentItems.has(id)) {
        item.selected = true;
      }

      this.pagedAssessmentItems.set(id, item);
      if (!this.assessmentItems.has(id)) {
        this.assessmentItems.set(id, item);
      }
    }
  }

  @action putAssessmentItemInCart = (id, toggle) => {
    const item = this.assessmentItems.get(id);
    if (item) {
      item.selected = toggle;
    }
    const pagedItem = this.pagedAssessmentItems.get(id);
    if (pagedItem) {
      pagedItem.selected = toggle;
    }

    if (toggle) {
      this.addCartAssessmentItem(id, item);
    } else {
      this.removeCartAssessmentItem(id);
    }
  }

  @action clearAssessmentItemSelected = () => {
    this.assessmentItems.forEach((item) => {
      item.selected = false;
    });
    this.pagedAssessmentItems.forEach((pagedItem) => {
      pagedItem.selected = false;
    });
  }

  @action addAttachedAssessmentItem = (id, item) => {
    if (item && id) {
      if (!this.attachedAssessmentItems.has(id)) {
        this.attachedAssessmentItems.set(id, item);
      }
    }
  }

  @action removeAttachedAssessmentItem = (id) => {
    if (id) {
      this.attachedAssessmentItems.delete(id);
    }
  }

  @action addCartAssessmentItem = (id, item) => {
    if (item && id) {
      if (!this.cartedAssessmentItems.has(id)) {
        this.cartedAssessmentItems.set(id, item);
      }
    }
  }

  @action removeCartAssessmentItem = (id) => {
    if (id) {
      this.cartedAssessmentItems.delete(id);
    }
  }

  @action addCollectedStandardAlignment = (id, item) => {
    if (item && id) {
      if (!this.collectedStandardAlignments.has(id)) {
        item.name = item.humanCodingScheme;
        item.sortClass = 'b-custom-category';
        item.sortBy = item.sortClass + item.name;
        this.collectedStandardAlignments.set(id, item);
      }
    }
  }

  @action addCollectedContentItemId = (id) => {
    if (id) {
      if (!this.collectedContentItemIds.includes(id)) {
        this.collectedContentItemIds.set(id);
      }
    }
  }

  @action setAssessmentItemsLoading = (toggle) => {
    this.assessmentItemsLoading = toggle;
  }

  @action setCmapStandardsJson = (json) => {
    this.cmapStandardsJson = json;
  }

  @action addCmapStandardsMapItem = (id, item) => {
    if (item && id) {
      if (!this.cmapStandardsMap.has(id)) {
        this.cmapStandardsMap.set(id, item);
      }
    }
  }

  @action clearCmapStandardsJson = () => {
    this.cmapStandardsJson.clear();
  }

  @action clearAdoptedStandardsJson = () => {
    this.adoptedStandardsJson = [];
  }

  @action clearAssessmentFilterStandardsMaps = () => {
    this.clearCmapStandardsMap();
    this.clearStdFrwkStandardsMap();
  }

  @action clearCmapStandardsMap = () => {
    this.cmapStandardsMap.clear();
  }

  @action setAdoptedStandardsJson = async (json) => {
    this.adoptedStandardsJson = json;
  }

  @action setStandardsResponseData = async (val) => {
    this.standardsResponseData = val;
  }

  @action addStdFrwkStandardsMap = (id, item) => {
    if (item && id) {
      if (!this.stdFrwkStandardsMap.has(id)) {
        this.stdFrwkStandardsMap.set(id, item);
      }
    }
  }

  @action clearStdFrwkStandardsMap = () => {
    this.stdFrwkStandardsMap.clear();
  }

  @action addExpanded = (id) => {
    this.expanded.push(id);
  }

  @action clearExpanded = () => {
    this.expanded = [];
  }

  @action clearProductCmapList = () => {
    this.productCmaps = new Map();
  }

  @action clearAllCmapData = () => {
    this.clearExpanded();
    this.clearProductCmapList();
    this.clearCmapStandardsJson([]);
    this.clearAdoptedStandardsJson();
    this.clearStdFrwkStandardsMap();
    this.clearCmapStandardsMap();
  }

  // computed ************************
  // assessment
  _cachedAssessments = new Map();

  @computed get cachedAssessments() {
    if (courseManager.isCustomCourse()) {
      this._cachedAssessments.clear();
      const list = courseManager.currentCourseElementList;
      list.map((assessment) => {
        this._cachedAssessments.set(assessment.contentItemId, assessment);
      });
      return this._cachedAssessments;
    }
    return new Map();
  }

  @computed get cachedAssessmentList() {
    if (this.updatedCachedAssessmentList) {
      return this.updatedCachedAssessmentList;
    }
    return courseManager.currentCourseElementList;
  }

  @computed get assessmentList() {
    if (this.assessmentsMap) {
      return Array.from(toJS(this.assessmentsMap).values());
    }
    return [];
  }

  @computed get pagedAssessmentList() {
    if (this.pagedAssessmentsMap) {
      return Array.from(toJS(this.pagedAssessmentsMap).values());
    }
    return [];
  }

  @computed get assessmentItemList() {
    if (this.assessmentItems) {
      return Array.from(toJS(this.assessmentItems).values());
    }
    return [];
  }

  @computed get pagedAssessmentItemList() {
    if (this.pagedAssessmentItems) {
      return Array.from(toJS(this.pagedAssessmentItems).values());
    }
    return [];
  }

  @computed get selectedAssessmentItems() {
    const array = [];
    this.assessmentItems.forEach((item) => {
      if (item.selected) {
        array.push(item);
      }
    });
    return array;
  }

  @computed get selectedNotAttachedAssessmentItems() {
    const array = [];
    this.assessmentItems.forEach((item) => {
      if (item.selected && !item.attached) {
        array.push(item.id);
      }
    });
    return array;
  }

  @computed get selectedAndAttachedAssessmentItems() {
    const array = [];
    this.assessmentItems.forEach((item) => {
      if (item.selected && !item.attached) {
        array.push(item.id);
      }
    });
    return array;
  }

  @computed get deletedAssessmentItems() {
    const array = [];
    this.assessmentItems.forEach((item) => {
      if (!item.selected && item.attached) {
        array.push(item.id);
      }
    });
    return array;
  }

  @computed get attachedAssessmentItemList() {
    if (this.attachedAssessmentItems) {
      return Array.from(toJS(this.attachedAssessmentItems).values());
    }
    return [];
  }

  @computed get cartedAssessmentItemList() {
    if (this.cartedAssessmentItems) {
      return Array.from(toJS(this.cartedAssessmentItems).values());
    }
    return [];
  }

  @computed get productCmapArray() {
    if (this.productCmaps) {
      const cmapArray = Array.from(this.productCmaps.values());
      return cmapArray.sort((a, b) => ((a.displayName > b.displayName) ? 1 : -1));
    }
    return null;
  }

  @computed get adoptedStandardFrameworksArray() {
    if (this.adoptedStandardFrameworks) {
      return Array.from(this.adoptedStandardFrameworks.values());
      // don't sort on FE per Brendan
      // return adoptedStandardFrameworkArray.sort((a, b) => ((a.name > b.name) ? 1 : -1));
    }
    return null;
  }

  getArrayFromMap(map) {
    if (map) {
      return Array.from(toJS(map).values());
    }
    return [];
  }

  needsStandardAlignments = (item) => {
    if (this.assessmentItems.has(item.id)) {
      return false;
    }
    return true;
  }

  /**
   * @param {string[] | string} contentItemIds
   * @param {string} courseId
   * @param {string} courseElementId
   * @returns {boolean} `response.status === 'SUCCESS'`
   */
  createCustomCourseResourceAlignments = async (contentItemIds, courseId = null, courseElementId = null) => {
    try {
      let contentItemIdsStr;

      if (!Array.isArray(contentItemIds) && typeof contentItemIds !== 'string') {
        throw new TypeError('contentItemIds must be a string[] || string');
      } else if (Array.isArray(contentItemIds)) {
        contentItemIdsStr = contentItemIds.join(contentItemIds, ',');
      } else {
        contentItemIdsStr = contentItemIds;
      }
      const urlParams = new URLSearchParams(window.location.search);
      courseId = courseId || urlParams.get('courseId');
      courseElementId = courseElementId || urlParams.get('elementId');

      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.CREATE_CUSTOM_COURSE_RESOURCE_ALIGNMENTS}`;

      const body = {
        contentItemIds: contentItemIdsStr,
        courseContentItemId: courseId,
        parentCourseResourceElementId: courseElementId
      };
      const response = await Auth.fetch(apiUrl, {
        body,
        method: 'POST'
      });
      return response && response.status === 'SUCCESS';
    } catch (error) {
      console.error(error);
    }
  }

  fetchContentItem = async (contentItemId) => {
    let response = null;
    try {
      response = await Auth.fetch(`${Auth.ecms}${ASSESSMENT_ENDPOINTS.GET_CONTENT_ITEM}?contentItemId=${contentItemId}`, {
        method: 'GET'
      });
    } catch (error) {
      console.error(error);
    }
    if (response && response.status === 'SUCCESS') {
      this.setCurrentContentItem(response.contentItems[0]);
    }
  }

  getSortedStandardsForAssessmentItem = (assessmentItem, doSort = false) => {
    const tagArray = [];
    if (assessmentItem.standardFrameworkElementList !== null && assessmentItem.standardFrameworkElementList !== '' && assessmentItem.standardFrameworkElementList !== undefined) {
      const idArray = assessmentItem.standardFrameworkElementList.split(',');
      idArray.map((tId, index) => {
        const standard = this.collectedStandardAlignments.get(tId);
        if (standard !== null) {
          tagArray.push(standard);
        }
      });

      if (doSort) {
        tagArray.sort((a, b) => ((a.sortBy > b.sortBy) ? 1 : -1));
      }
    }

    const filteredTagArray = tagArray.filter((t) => t != null);

    return filteredTagArray;
  }

  fetchResource = async (contentItemId) => {
    let response = null;
    this.clearAttachedAssessmentItems();
    this.setCurrentAssessmentItemCount(0);
    try {
      response = await Auth.fetch(`${Auth.ecms}${ASSESSMENT_ENDPOINTS.GET_RESOURCE}?contentItemId=${contentItemId}`, {
        method: 'GET'
      });
    } catch (error) {
      console.error(error);
    }
    if (response && response.status === 'SUCCESS') {
      this.setCurrentResourceItem(response.resources[0]);
      const elements = response.resources[0].learnosityActivityElements;
      const elementContentItemIds = [];
      elements.forEach((elem) => {
        elementContentItemIds.push(elem.entityId);
      });

      const elementContentItemIdsStr = elementContentItemIds.join();
      if (elementContentItemIds.length > 0) {
        this.setCurrentAssessmentItemCount(elementContentItemIds.length);
        await this.getByContentIdList(elementContentItemIdsStr);
        await this.getStandardAlignmentsByContentIdList(elementContentItemIdsStr);
      } else {
        // TODO
        // console.log('PUT IN THE NO ELEMENTS MESSAGE');
      }
      return true;
    }
  }

  fetchCustomAssessmentItemsList = async (searchCondition, searchTerms, searchTags, searchStandards, searchTypes, page = null, pageSize = null) => {
    let response = null;
    try {
      let apiUrlParams = '?includeBlankCard=false';
      apiUrlParams += `&productCode=${Auth.publisherSatelliteCode}`;
      apiUrlParams += (searchCondition !== null && searchCondition.length > 0) ? `&searchCondition=${searchCondition}` : '&searchCondition=OR';
      apiUrlParams += (searchTerms !== null && searchTerms.length > 0) ? `&searchTerms=${searchTerms}` : '';
      apiUrlParams += (searchTags !== null && searchTags.length > 0) ? `&searchTags=${searchTags}` : '';
      apiUrlParams += (searchStandards !== null && searchStandards.length > 0) ? `&searchStandards=${searchStandards}` : '';
      apiUrlParams += (searchTypes !== null && searchTypes.length > 0) ? `&searchTypes=${searchTypes}` : '&searchTypes=learnosity_item_resource';
      apiUrlParams += `&skip=${(page ? page - 1 : 0) * ASSESSMENT_TRANSACTION.PAGE_SIZE}`;
      apiUrlParams += `&pageSize=${pageSize || ASSESSMENT_TRANSACTION.PAGE_SIZE}`;
      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.GET_CONTENT_ITEM_LIST}${apiUrlParams}`;
      response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
    } catch (error) {
      // TODO this.setAssessmentItemsLoading(false);
      console.error(error);
    }
    if (response && response.status === 'SUCCESS') {
      this.clearPagedAssessmentItems();
      const items = response.contentItems;
      const contentItemIds = [];
      items.map((item) => {
        const isAttached = this.attachedAssessmentItems.has(item.id);
        const isInCart = this.cartedAssessmentItems.has(item.id);
        const needsStandards = this.needsStandardAlignments(item);
        if (needsStandards) {
          contentItemIds.push(item.id);
        }
        item.attached = isAttached;
        item.selected = !!((isAttached || isInCart));
        this.addAssessmentItem(item.id, item);
      });

      if (contentItemIds.length > 0) {
        this.getStandardAlignmentsByContentIdList(contentItemIds.join());
      }

      this.setTotalPages(Math.ceil(+response.pageTotal / ASSESSMENT_TRANSACTION.PAGE_SIZE));
      return !!response.status;
    }
    return false;
  }

  fetchCmapList = async (useContentItemList = false) => {
    let response = null;
    try {
      let apiUrlParams = '?includeBlankCard=false';
      apiUrlParams += `&searchTypes=${CONTENT_ITEM_TYPES.CMAP_RESOURCE}`;
      apiUrlParams += '&skip=0';
      apiUrlParams += '&pageSize=100';
      let apiUrl = '';
      if (useContentItemList) {
        apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.GET_CONTENT_ITEM_LIST}${apiUrlParams}`; // old content item based endpoint
      } else {
        apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.GET_CMAP_LIST}${apiUrlParams}`; // new endpoint for item banking
      }
      response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
    } catch (error) {
      console.error(error);
    }
    if (response && response.status === 'SUCCESS') {
      const items = response.contentItems;
      this.loadProductCmaps(items);
      return !!response.status;
    }
    return false;
  }

  fetchProductCmapList = async () => {
    let response = null;
    try {
      let apiUrlParams = '?includeBlankCard=false';
      apiUrlParams += `&searchTypes=${CONTENT_ITEM_TYPES.CMAP_RESOURCE}`;
      apiUrlParams += '&skip=0';
      apiUrlParams += '&pageSize=100';
      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.GET_PRODUCT_CMAP_LIST}${apiUrlParams}`; // new endpoint for resource shopping
      response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
    } catch (error) {
      console.error(error);
    }
    if (response && response.status === 'SUCCESS') {
      const items = response.contentItems;
      this.loadProductCmaps(items);
      return !!response.status;
    }
    return false;
  }

  fetchItembankCmaps= async (itembankId) => {
    let response = null;
    try {
      let apiUrlParams = `?workspaceId=${itembankId}`;
      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.GET_ITEMBANK_CMAP_LIST}${apiUrlParams}`; // new endpoint for resource shopping
      response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
    } catch (error) {
      console.error(error);
    }
    if (response && response.status === 'SUCCESS') {
      const items = response.contentItems;
      this.loadProductCmaps(items);
      return !!response.status;
    }
    return false;
  }

  fetchFullCmap = async (cmapContentItemId) => {
    if (!cmapContentItemId || cmapContentItemId.length < 1) {
      return false;
    }

    let response = null;
    try {
      const apiUrlParams = `?cmapContentItemId=${cmapContentItemId}`;
      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.GET_FULL_CMAP}${apiUrlParams}`;
      response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
    } catch (error) {
      console.error(error);
    }

    if (response && response.jsonString) {
      const jsonObj = JSON.parse(response.jsonString);
      const startObj = jsonObj.CurriculumMapElements[1];
      if (startObj) {
        const formattedJson = this.buildCmapStandardsJson(startObj.CurriculumMapElements, jsonObj.name);
        this.setCmapStandardsJson(formattedJson);
        return true;
      }
    }
    return false;
  }

  buildCmapStandardsJson = (elemInput, cmapName) => {
    let index = 1;
    if (!elemInput || elemInput.length < 0) return [];
    return elemInput.map((obj) => {
      const cmapElementId = obj.id; // curriculumMapElement id is unique but the entity id is not so use this for the node value
      const standardId = obj.entityId || CryptoMD5(obj).toString() || null;
      let name = '';
      // get dropdown label `name` for standards AssignmentItemFilter
      if (obj.displayName || obj.name) {
        // if obj.displayName or obj.name, use that
        name = obj.displayName || obj.name;
      } else if (cmapName) {
        // else if cmapName, use that
        name = cmapName;
      } else /* if (obj.type === 'UNIT') */ {
        // else use default 'Unit' label
        // (edge case, will likely never happen)
        name = obj.unitName || 'Unit';
        name = `${name} ${index}`;
        index++;
      }
      // name = stripHtmlTags(name);
      const showCheckbox = (obj.type === 'STANDARD');

      if (showCheckbox) {
        name = `<span class='standard-name'>${name}</span> <span class='standard-desc'>${obj.decodedDescription}</span>`;
      }

      name = UtilityService.replaceParagraphWithSpan(name);
      name = `<span class='full-name-wrapper'>${name}</span>`;
      name = ReactHtmlParser(name);

      const cmapElement = {
        label: name,
        showCheckbox,
        standardId,
        value: cmapElementId
      };
      if (obj.CurriculumMapElements.length > 0) {
        this.addExpanded(cmapElementId);
        cmapElement.children = this.buildCmapStandardsJson(obj.CurriculumMapElements, '');
      }
      // add a mapping by curriculumElementId so we can swap out the standard id later
      this.addCmapStandardsMapItem(cmapElementId, cmapElement);
      return cmapElement;
    });
  }

  fetchAdoptedPublisherStandardFrameworks = async () => {
    let response = null;
    try {
      const apiUrlParams = `?state=${userManager.institutionStateId}`;
      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.GET_STATE_PUB_STANDARD_FRAMEWORK}${apiUrlParams}`;
      response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
    } catch (error) {
      console.error(error);
    }
    if (response && response.status === 'SUCCESS') {
      const standardFrameworks = response.data;
      this.loadAdoptedStandardFrameworks(standardFrameworks);
      return !!response.status;
    }
    return false;
  }

  fetchFullStandardDocument = async (standardGuid, defaultExpanded) => {
    if (!standardGuid || standardGuid.length < 1) {
      return false;
    }

    let response = null;
    try {
      const apiUrlParams = `?standardGuid=${standardGuid}`;
      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.GET_FULL_STANDARD_DOC}${apiUrlParams}`;
      response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
    } catch (error) {
      console.error(error);
    }

    if (response && response.standardFrameworkElements) {
      await this.setStandardsResponseData(response.standardFrameworkElements);
      const formattedJson = await this.buildAdoptedStandardsJson(this.standardsResponseData, defaultExpanded, false);
      await this.setAdoptedStandardsJson(formattedJson);
      return true;
    }
    return false;
  }

  buildAdoptedStandardsJson = (standardFrameworkElements, defaultExpanded = true, allCheckboxes = false) => {
    const index = 1;
    if (!standardFrameworkElements || standardFrameworkElements.length < 0) return [];
    // if there is an outer wrapper with no parent element id, skip it.
    const treeElements = (!standardFrameworkElements[0].parentElementId) ? standardFrameworkElements[0].standardFrameworkElements : standardFrameworkElements;
    return treeElements.map((obj) => {
      const stdFwkElementId = obj.id;
      let showCheckbox = false;

      if (allCheckboxes) {
        showCheckbox = true;
      } else {
        showCheckbox = (obj.type && obj.type.toUpperCase() === 'STANDARD');
      }
      // Node label for assignment filter tree node 'listName' has the display name but we have to
      // build it from parts because we have to bold the prefix stored in humanCodingScheme.
      const label = obj.humanCodingScheme ? `${stripHtmlTags(obj.humanCodingScheme)} ${stripHtmlTags(obj.fullStatement)}` : `${stripHtmlTags(obj.fullStatement)}`;
      const stdFrwkElement = {
        label,
        showCheckbox,
        standardId: stdFwkElementId,
        value: stdFwkElementId
      };
      if (obj.standardFrameworkElements.length > 0) {
        if (defaultExpanded) {
          this.addExpanded(stdFwkElementId);
        }
        stdFrwkElement.children = this.buildAdoptedStandardsJson(obj.standardFrameworkElements, defaultExpanded, allCheckboxes);
      }
      // add a mapping by stdFwkElementId so we can swap out the standard id later
      this.addStdFrwkStandardsMap(stdFwkElementId, stdFrwkElement);
      return stdFrwkElement;
    });
  }

  addElementToLearnosityActivity = async (
    learnosityActivityContentItemId,
    entityId,
    entityTypeId = 'content_item',
    name,
    type = 'learnosity_item_resource'
  ) => {
    try {
      const body = {
        learnosityActivityContentItemId,
        entityId,
        entityTypeId,
        name,
        type
      };
      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.ADD_ELEMENT_TO_LEARNOSITY_ACTIVITY}?forceUpdate=true`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  addItemsToLearnosityActivityWithAlignments = async (
    learnosityActivityContentItemId,
    entityIds,
    entityTypeId = 'content_item',
    courseResourceElementId,
    courseContentItemId,
    name,
    type = 'learnosity_item_resource'
  ) => {
    try {
      const body = {
        learnosityActivityContentItemId,
        entityIds,
        entityTypeId,
        courseResourceElementId,
        courseContentItemId,
        name,
        type
      };
      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.ADD_ITEMS_TO_LEARNOSITY_ACTIVITY_WITH_ALIGNMENTS}?forceUpdate=true`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  deleteElementFromLearnosityActivity = async (learnosityActivityContentItemId, entityId) => {
    try {
      const body = {
        learnosityActivityContentItemId,
        entityId
      };
      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.DELETE_ELEMENT_FROM_LEARNOSITY_ACTIVITY}?forceUpdate=true`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        // remove it from the active collection.
        this.removeAttachedAssessmentItem(entityId);
        this.setCurrentAssessmentItemCount(this.attachedAssessmentItems.size);

        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  getByContentIdList = async (contentItemIds) => {
    try {
      const body = {
        contentItemIds
      };
      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.GET_BY_CONTENT_ITEM_ID_LIST}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        this.clearAttachedAssessmentItems();
        const items = response.contentItems;
        items.map((item) => {
          this.addAttachedAssessmentItem(item.id, item);
        });
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  getStandardAlignmentsByContentIdList = async (contentItemIds) => {
    try {
      const body = {
        contentItemIds
      };
      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.GET_STANDARD_ALIGNMENTS_BY_CONTENT_ITEM_ID_LIST}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        const alignedStandards = response.standardFrameworkElements;
        alignedStandards.map((item) => {
          this.addCollectedStandardAlignment(item.id, item);
        });

        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  moveElementWithinLearnosityActivity = async (learnosityActivityContentItemId, entityId, newIndex) => {
    try {
      const body = {
        learnosityActivityContentItemId,
        entityId,
        newIndex
      };
      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.MOVE_ELEMENT_WITHIN_LEARNOSITY_ACTIVITY}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        // The page should refresh each time you leave and come back so fetchResource(contentItemId) might not ne neccesary.
        // also, no need to wait for this.

        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  checkContentItemName = async (contentName, entityTypeId, excludeHidden = true) => {
    try {
      const body = {
        contentName,
        entityTypeId,
        excludeHidden
      };
      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.CHECK_CONTENT_ITEM_NAME}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        if (response.contentNameExists) {
          return true;
        }
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  updateContentItemName = async (id, name, subtitle) => {
    try {
      const body = {
        id
      };
      if (name) {
        body.name = name;
      }
      if (subtitle) {
        body.subtitle = subtitle;
      }
      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.UPDATE_CONTENT_ITEM_NAME}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  updateContentItemDisplayProps = async (id, description, subtitle) => {
    try {
      const body = {
        description,
        id,
        subtitle
      };

      const apiUrl = `${Auth.ecms}${ASSESSMENT_ENDPOINTS.UPDATE_CONTENT_ITEM_DISPLAY_PROPS}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'POST',
        body
      });
      if (response && response.status === 'SUCCESS') {
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

export default new AssessmentManager();
