/* eslint-disable sort-keys */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Redirect } from 'react-router-dom';

import {
  Button,
  Form,
  Grid,
  Header,
  Input,
  Message,
  Segment
} from 'semantic-ui-react';

import classNames from 'classnames';

import '../css/Login.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';
import navigationManager, { VIEW_SELECTION } from '../managers/NavigationManager';
import { ERROR_MESSAGES } from '../managers/UserManager';

import DynamicSatelliteService from '../services/DynamicSatelliteService';

export default @inject(
  'dynamicSatelliteManager', 'userManager')
@observer
class RegisterEcommerce extends Component {
  teacherRegisterSuccessMessage = 'Registration complete, check your email to verify your account.';

  studentRegisterSuccessMessage = 'Registration complete, check your email to verify your account.';

  publisherSatelliteCode = Auth.publisherSatelliteCode;

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      accessCode: {
        value: '',
        error: null,
        result: null
      },
      users: [
        // Parent/Teacher
        this.getNewUser({ isStudent: false }),
        // Student
        this.getNewUser({ isStudent: true })
      ]
    };
    this.Logo = SatCoreComponent('Logo');
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');
    this.ShowPasswordButton = SatCoreComponent('ShowPasswordButton');
  }

  async componentDidMount() {
    this.setState({ loaded: false });

    const { dynamicSatelliteManager } = this.props;

    navigationManager.setView(VIEW_SELECTION.LOGIN);

    if (dynamicSatelliteManager.isDynamicSatellite && !Auth.publisherSatelliteCode) {
      await DynamicSatelliteService.fetchDynamicSatelliteByDomain();
      setTimeout(() => {
        this.setState({ loaded: true });
        this.publisherSatelliteCode = Auth.publisherSatelliteCode;
      }, 500);
    } else {
      this.setState({ loaded: true });
    }
  }

  getNewUser = ({ isStudent } = {}) => {
    const user = {
      passwordInputType: 'password',
      passwordInputType2: 'password',
      // accessCode: {
      //   value: '',
      //   error: null,
      //   result: null
      // },
      firstName: {
        value: '',
        error: null
      },
      lastName: {
        value: '',
        error: null
      },
      email: {
        value: '',
        error: null
      },
      password: {
        value: '',
        error: null
      },
      confirmPassword: {
        value: '',
        error: null
      },

      submitted: false,

      success: false,
      student: !!isStudent,
      username: ''
    };
    return user;
  }

  removeUser = ({ userIndex } = {}) => {
    const { users } = this.state;
    this.setState({
      users: users.filter((_user, index) => userIndex !== index)
    });
  }

  handleShowPasswordPressed = ({ name, isPasswordInput, userIndex = 0 } = {}) => {
    const { users } = this.state;
    const user = { ...users[userIndex] };

    const inputType = isPasswordInput ? 'password' : 'text';

    user[name] = inputType;

    this.setState({
      users: users.map((userInArray, index) => {
        if (index !== userIndex) {
          return { ...userInArray };
        } else {
          return user;
        }
      })
    });
  }

  handleChange = ({ event, userIndex = 0 } = {}) => {
    const { accessCode } = this.state;
    const { name, value } = event.target;

    if (name === 'accessCode') {
      this.setState({
        accessCode: {
          ...accessCode,
          value
        }
      });
      return;
    }

    const { users } = this.state;
    const user = { ...users[userIndex] };

    user[name].value = value;

    this.setState({
      users: users.map((userInArray, index) => {
        if (index !== userIndex) {
          return { ...userInArray };
        } else {
          return user;
        }
      })
    });
  }

  handleChangePassword = ({ event, userIndex = 0 } = {}) => {
    const { users } = this.state;
    const user = { ...users[userIndex] };

    this.handleChange({ event, userIndex });
    // this.validateConfirmPassword(this.state.confirmPassword.value, e.target.value);
    this.validateConfirmPassword({
      password: user.confirmPassword.value,
      confirmPassword: event.target.value,
      userIndex
    });
  }

  handleChangeConfirmPassword = ({ event, userIndex = 0 } = {}) => {
    const { users } = this.state;
    const user = { ...users[userIndex] };

    this.handleChange({ event, userIndex });

    this.validateConfirmPassword({
      password: user.password.value,
      confirmPassword: event.target.value,
      userIndex
    });
  };

  validateConfirmPassword = ({ password, confirmPassword, userIndex = 0 } = {}) => {
    this.setError({
      name: 'confirmPassword',
      error: password && confirmPassword && !password.includes(confirmPassword) ? ERROR_MESSAGES.PASSWORDS_DO_NOT_MATCH : null,
      userIndex
    });
  };

  setError = ({ name, error, userIndex = 0 } = {}) => {
    const { accessCode } = this.state;
    if (name === 'accessCode') {
      this.setState({
        accessCode: {
          ...accessCode,
          error
        }
      });
      return;
    }

    const { users } = this.state;
    const user = { ...users[userIndex] };

    user[name].error = error;

    this.setState({
      users: users.map((userInArray, index) => {
        if (index !== userIndex) {
          return { ...userInArray };
        } else {
          return user;
        }
      })
    });
  };

  clearError = ({ event, userIndex = 0 } = {}) => {
    this.setError({
      name: event.target.name,
      error: null,
      userIndex
    });
  };

  validate = async () => {
    const { users } = this.state;

    for (let userIndex = 0; userIndex < users.length; userIndex++) {
      // eslint-disable-next-line no-await-in-loop
      const accessCode = await this.validateAccessCode();

      if (accessCode?.error) {
        return false;
      }

      // eslint-disable-next-line no-await-in-loop
      const validEmail = await this.validateEmail({ userIndex });

      if (!validEmail) {
        return false;
      }

      const validPassword = this.validatePassword({ userIndex });

      if (!validPassword) {
        return false;
      }

      const isLastUserIndex = userIndex === users.length - 1;
      if (isLastUserIndex) {
        // we traversed the list of users and found no invalidations, so return true for validated
        return true;
      }
    }
  }

  validateAccessCode = async () => {
    try {
      const { accessCode } = this.state;

      const data = await Auth.fetch(`${Auth.ecms}/api/validateSignUpCode`, {
        method: 'POST',
        body: {
          signupCode: accessCode.value,
          publisherSatelliteCode: Auth.publisherSatelliteCode
        }
      });

      if (data.codeValid && (data.codeType === 'classroom' || data.codeType === 'district' || data.codeType === 'school')) {
        const accessCodeObj = {
          ...accessCode,
          result: data,
          error: null
        };
        this.setState({
          accessCode: accessCodeObj
        });
        return accessCodeObj;
      } else {
        const accessCodeObj = {
          ...accessCode,
          result: data,
          error: ERROR_MESSAGES.ACCESS_CODE_INVALID
        };
        this.setState({
          accessCode: accessCodeObj
        });
        return accessCodeObj;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  validateEmail = async ({ userIndex = 0 } = {}) => {
    const { autoLoginStudent, autoLoginTeacher, userManager } = this.props;

    const { accessCode, users } = this.state;
    const user = { ...users[userIndex] };

    const { student } = user;

    const autoLogin = student ? autoLoginStudent : autoLoginTeacher;
    const email = { ...user.email };
    let valid = true;

    // for students, check the username for format as some satellites won't allow email address
    if (student) {
      // check if we have a valid username/email format
      const usernameResult = userManager.validateStudentUsernameFormat(email.value);
      email.error = null;
      if (!usernameResult.valid) {
        email.error = usernameResult.errorMessage;
        valid = false;
      }
    } else {
      const emailRegexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      email.error = null;
      if (!autoLogin && !emailRegexp.test(email.value)) {
        email.error = ERROR_MESSAGES.VALID_EMAIL_FORMAT;
        valid = false;
      }
    }

    user.email = email;

    this.setState({
      users: users.map((userInArray, index) => {
        if (index !== userIndex) {
          return { ...userInArray };
        } else {
          return user;
        }
      })
    });

    if (email.value && !email.error && !accessCode.error && accessCode.result) {
      // we must validate if the user already exists only if the
      // user entered a valid access code already. If needed We will
      // check again when the user does enter a valid code in the
      // 'accessCode' case above.
      const result = await this.validateUser({ userIndex });
      if (!result) {
        valid = false;
      }
    }
    return valid;
  }

  validatePassword = ({ userIndex = 0 } = {}) => {
    const { users } = this.state;
    const user = { ...users[userIndex] };

    const { password, confirmPassword } = user;

    let valid = true;
    if (password.value !== confirmPassword.value) {
      confirmPassword.error = ERROR_MESSAGES.PASSWORDS_DO_NOT_MATCH;
      valid = false;
    } else {
      confirmPassword.error = null;
    }
    if (password.value !== null && password.value.length > 0 && password.value.length < 8) {
      password.error = ERROR_MESSAGES.PASSWORD_LENGTH;
      valid = false;
    } else {
      password.error = null;
    }

    user.password = password;
    user.confirmPassword = confirmPassword;

    this.setState({
      users: users.map((userInArray, index) => {
        if (index !== userIndex) {
          return { ...userInArray };
        } else {
          return user;
        }
      })
    });

    return valid;
  }

  validateUser = async ({ userIndex = 0 } = {}) => {
    const { t } = this.props;

    const { accessCode, users } = this.state;
    const user = { ...users[userIndex] };

    const { result } = accessCode;
    const email = { ...user.email };
    try {
      const data = await Auth.fetch(`${Auth.ecms}/api/checkEmailAddress`, {
        method: 'POST',
        body: { publisherId: result.publisherId, emailAddress: email.value }
      });
      email.error = data.emailAddressExists ? t('userExistsError', ERROR_MESSAGES.USER_EXISTS) : null;

      user.email = email;

      this.setState({
        users: users.map((userInArray, index) => {
          if (index !== userIndex) {
            return { ...userInArray };
          } else {
            return user;
          }
        })
      });
      return email.error === null;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  isComplete = () => {
    const { accessCode, users } = this.state;
    return users.every((user) => {
      const { firstName, lastName, email, password, confirmPassword } = user;
      return !!(
        (accessCode.value.length && !accessCode.error) &&
        firstName.value.length && !firstName.error &&
        lastName.value.length && !lastName.error &&
        email.value.length && !email.error &&
        password.value.length && !password.error &&
        confirmPassword.value.length && !confirmPassword.error &&
        password.value === confirmPassword.value
      );
    });
  }

  handleAutoLogin = async ({ userIndex }) => {
    const { history } = this.props;

    const { users } = this.state;
    const user = { ...users[userIndex] };

    const loggedIn = await Auth.login(user.email.value, user.password.value);
    if (loggedIn) {
      history.replace('/');
    }
  }

  handleFormSubmit = async ({ event, userIndex = 0 } = {}) => {
    event.preventDefault();
    const { userManager } = this.props;

    const valid = await this.validate();

    if (!valid) {
      return;
    }
    // NOTE: state will be updated via this.validate(),
    // so here it is important to get `accessCode` and `users` from state
    // **after** calling this.validate(), not before.
    const { accessCode, users } = this.state;

    this.setState({
      users: users.map((userInArray, _index) => {
        return {
          ...userInArray,
          submitted: true
        };
      })
    });

    // NOTE: Here we currently assume `teacherUser` is `users[0]` and `studentUser` is `users[1]`.
    // This may need to be changed in the future if we allow more teachers and/or students to register at once.
    const teacherUser = users[0];
    const studentUser = users[1];

    const body = {
      signupCode: accessCode.result.signupCode,
      publisherSatelliteCode: Auth.publisherSatelliteCode,
      username: teacherUser.email.value,
      firstName: teacherUser.firstName.value,
      lastName: teacherUser.lastName.value,
      password: teacherUser.password.value,
      studentUsername: studentUser.email.value,
      studentFirstName: studentUser.firstName.value,
      studentLastName: studentUser.lastName.value,
      studentPassword: studentUser.password.value
    };

    const result = await userManager.registerEcommerceUser(body);

    const success = result?.status === 'SUCCESS';

    this.setState({
      users: users.map((userInArray, _index) => {
        return {
          ...userInArray,
          success
        };
      })
    });

    if (!success) {
      this.setError({
        name: 'accessCode',
        error: result?.statusMessage || ERROR_MESSAGES.GENERAL,
        userIndex
      });
    }
  }

  renderForm({ userIndex = 0 } = {}) {
    const { t } = this.props;

    const { accessCode, users } = this.state;
    const user = { ...users[userIndex] };

    const { student: isStudent } = user;

    const { email, password, confirmPassword, passwordInputType, passwordInputType2, submitted } = user;

    const { ShowPasswordButton } = this;

    const isFirstUserIndex = userIndex === 0;
    const isLastUserIndex = userIndex === users.length - 1;

    return (
      <Form key={`form-user-${userIndex}`}>
        {isFirstUserIndex ? (
          <Form.Field>
            <Form.Input
              label={!isStudent ? t('accessCode', 'Access Code') : t('studentAccessCode')}
              name='accessCode'
              onChange={(event) => this.handleChange({ event, userIndex })}
              onFocus={(event) => this.clearError({ event, userIndex })}
              placeholder={t('accessCodePlaceholder', 'x-digits')}
              type='text' />
            <Message
              content={accessCode.error}
              error
              visible={accessCode.error !== null} />
          </Form.Field>
        ) : null}
        <Form.Field>
          <Form.Input
            label={!isStudent ? t('firstName', 'First Name') : t('studentFirstName')}
            name='firstName'
            onChange={(event) => this.handleChange({ event, userIndex })}
            onFocus={(event) => this.clearError({ event, userIndex })}
            placeholder={t('firstName', 'First Name')}
            type='text' />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label={!isStudent ? t('lastName', 'Last Name') : t('studentLastName')}
            name='lastName'
            onChange={(event) => this.handleChange({ event, userIndex })}
            onFocus={(event) => this.clearError({ event, userIndex })}
            placeholder={t('lastName', 'Last Name')}
            type='text' />
        </Form.Field>
        <Form.Field>
          <Form.Input
            autoComplete='username'
            label={!isStudent ? t('username', 'Username/email') : t('studentUsername')}
            name='email'
            onChange={(event) => this.handleChange({ event, userIndex })}
            onFocus={(event) => this.clearError({ event, userIndex })}
            placeholder='name@email.com'
            type='text' />
          <Message
            content={email.error}
            error
            visible={email.error !== null} />
        </Form.Field>
        <Form.Field>
          <label>{!isStudent ? t('passwordLabel', 'Enter your password') : t('studentPasswordLabel')}</label>
          <Input
            autoComplete='current-password'
            label={(
              <ShowPasswordButton
                isPassword={passwordInputType === 'password'}
                name='passwordInputType'
                onFlip={(name, isPasswordInput) => this.handleShowPasswordPressed({ name, isPasswordInput, userIndex })} />
            )}
            labelPosition='right'
            name='password'
            onChange={(event) => this.handleChangePassword({ event, userIndex })}
            onFocus={(event) => this.clearError({ event, userIndex })}
            onKeyPress={(event) => event.stopPropagation()}
            placeholder={t('enterPassword', 'Enter password')}
            type={passwordInputType} />
          <div className='note'>
            (
            {t('passwordsNote', 'Passwords are required to be at least 8 characters long.')}
            )
          </div>
        </Form.Field>
        <Message
          content={password.error}
          error
          visible={password.error !== null} />
        <Form.Field>
          <label>{!isStudent ? t('confirmPasswordLabel', 'Confirm your password') : t('studentConfirmPasswordLabel')}</label>
          <Input
            autoComplete='current-password'
            disabled={password.value.length <= 7}
            label={(
              <ShowPasswordButton
                isPassword={passwordInputType2 === 'password'}
                name='passwordInputType2'
                onFlip={(name, isPasswordInput) => this.handleShowPasswordPressed({ name, isPasswordInput, userIndex })} />
            )}
            labelPosition='right'
            name='confirmPassword'
            onChange={(event) => this.handleChangeConfirmPassword({ event, userIndex })}
            onFocus={(event) => this.clearError({ event, userIndex })}
            onKeyPress={(event) => event.stopPropagation()}
            placeholder={!isStudent ? t('confirmPassword', 'Confirm password') : t('studentConfirmPassword')}
            type={passwordInputType2} />
          <Message
            content={confirmPassword.error}
            error
            visible={confirmPassword.error !== null} />
        </Form.Field>
        {isLastUserIndex ? (
          <Form.Field>
            <Button disabled={submitted || !this.isComplete()}
              onClick={(event) => this.handleFormSubmit({ event, userIndex })} primary>
              {t('submit')}
            </Button>
          </Form.Field>
        ) : null}
      </Form>
    );
  }

  renderSuccessMessage() {
    const { t, autoLoginStudent, autoLoginTeacher } = this.props;

    const { users } = this.state;

    // NOTE: The current logic allows the first user in the list (teacher) to autologin after registering.
    const userIndex = 0;
    const user = { ...users[userIndex] };

    const { student } = user;

    if (student ? autoLoginStudent : autoLoginTeacher) {
      return (
        <p>
          <Button basic className='homepage' onClick={() => this.handleAutoLogin({ userIndex })} primary>
            {t('homepage', 'Take me to my home page')}
          </Button>
        </p>
      );
    }
    return student ?
      <p>{t('studentRegisterSuccessMessage', this.studentRegisterSuccessMessage)}</p> :
      <p>{t('teacherRegisterSuccessMessage', this.teacherRegisterSuccessMessage)}</p>;
  }

  render() {
    const { SatCoreLoader } = this;
    const { t } = this.props;
    const { loaded, users } = this.state;

    const success = users.every((user) => !!user.success);

    if (Auth.loggedIn()) {
      return <Redirect to='/' />;
    }
    const { Logo } = this;

    return loaded ? (
      <Grid className='login-body register-body register-ecommerce-body' textAlign='center' verticalAlign='middle'>
        <Grid.Column className='max-width-558'>
          <div>
            <Header as='h2' attached='top' block>
              <Logo />
              <span className='header-text'>
                {t('registration', 'Self-Registration')}
                {' '}
                {success ? 'Success' : null}
              </span>
            </Header>
            <Segment attached className={classNames('element-body-login element-body-login-register', {
              'element-body-login-register-success': success
            })}>
              {success ? this.renderSuccessMessage() : (
                users.map((_user, userIndex) => this.renderForm({ userIndex }))
              )}
            </Segment>
          </div>
        </Grid.Column>
      </Grid>
    ) : <SatCoreLoader active />;
  }
}

RegisterEcommerce.defaultProps = {
  autoLoginStudent: false,
  autoLoginTeacher: true
};

SatCoreRegister('RegisterEcommerce', RegisterEcommerce);
