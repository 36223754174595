import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';
import { Button, Container, Loader, Table, Ref } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroller';
import { SatCoreRegister } from '../SatCoreRegistry';
import { VIEW_SELECTION } from '../managers/NavigationManager';
import {DIALOG_NAMES} from '../managers/DialogManager';

import '../css/ArchivedClasses.less';

export const ArchivedClassesTable = forwardRef(({ children, ...props }, ref) => {
  return <Table striped {...props}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell className='class-name'>Class Name</Table.HeaderCell>
        <Table.HeaderCell className='display-name'>Display Name</Table.HeaderCell>
        <Table.HeaderCell className='status'>Status</Table.HeaderCell>
        <Table.HeaderCell className='end-date'>End Date</Table.HeaderCell>
        <Table.HeaderCell/>
      </Table.Row>
    </Table.Header>
    <Ref innerRef={ref}>
      <Table.Body>
        {children}
      </Table.Body>
    </Ref>
  </Table>;
});

export const ArchivedClassesTableRow = ({ classroom, classroomManager, dialogManager, load, ...props }) => {
  const { archived, displayName, timezoneEndDate } = classroom;
  const status = archived ? 'Archived' : 'Active';
  const onReactivate = useCallback(() => {
    dialogManager.setOpenDialog(DIALOG_NAMES.REACTIVATE_CLASS_MODAL, { classroom }, (_e, _data, saved) => {
      dialogManager.closeDialog(DIALOG_NAMES.REACTIVATE_CLASS_MODAL);
      saved && load(0);
    })
  }, [dialogManager, classroom]);
  return (
    <Table.Row {...props}>
      <Table.Cell className='class-name'>{classroomManager.getClassName(classroom.id)}</Table.Cell>
      <Table.Cell className='display-name'>{displayName}</Table.Cell>
      <Table.Cell className='status'>{status}</Table.Cell>
      <Table.Cell className='end-date'>{timezoneEndDate}</Table.Cell>
      <Table.Cell className='actions'>
        <Button basic primary size='mini' onClick={onReactivate}>Reactivate</Button>
      </Table.Cell>
    </Table.Row>
  );
};

export const ArchivedClassesTableLoader = () => {
  return <Table.Row>
    <Table.Cell className='loader' colSpan='5'>
      <Loader active/>
    </Table.Cell>
  </Table.Row>;
};

export const ArchivedClassesTableNoClasses = () => {
  return <Table.Row>
    <Table.Cell colSpan='5'>
      No Archived Classes
    </Table.Cell>
  </Table.Row>;
};

const ArchivedClasses = observer((props) => {
  const { classroomManager, dialogManager, navigationManager } = useContext(MobXProviderContext);
  const { classroomsArray } = classroomManager;
  const [loaded, setLoaded] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const load = useCallback(async (page) => {
    setHasMore(false);
    setHasMore(await classroomManager.fetchClassroomData(null, page, false, null, true));
    page === 0 && setLoaded(true);
  }, [classroomManager, hasMore, loaded, setHasMore, setLoaded]);

  useEffect(() => {
    navigationManager.setView(VIEW_SELECTION.ARCHIVED_CLASSES);
  }, []);

  const items = useMemo(() => classroomsArray
    .filter(({ archived }) => archived)
    .sort((a, b) => {
      const classroomNameA = a && classroomManager.getClassName(a.id)?.[0] || '';
      const classroomNameB = b && classroomManager.getClassName(b.id)?.[0] || '';
      return classroomNameA.localeCompare(classroomNameB, 'en', { numeric: true });
    }), [classroomsArray]);

  return (
    <Container className='archived-classes-view' fluid>
      <Container>
        <h1>Archived Classes</h1>
        <InfiniteScroll
          element={ArchivedClassesTable}
          hasMore={hasMore}
          initialLoad={true}
          loadMore={load}
          loader={<ArchivedClassesTableLoader key='loader'/>}
          pageStart={-1}
          useWindow={true}>
          {loaded && items.length === 0 ?
            <ArchivedClassesTableNoClasses key='no-classes'/> :
            items.map((classroom, index) => <ArchivedClassesTableRow key={index} {...{classroom, classroomManager, dialogManager, load}}/>)
          }
        </InfiniteScroll>
      </Container>
    </Container>
  );
});

export default ArchivedClasses;
SatCoreRegister('ArchivedClasses', ArchivedClasses);
