import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../css/ClassroomTitle.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import UserService from '../services/UserService';
import UtilityService from '../services/UtilityService';

const ClassroomTitle = observer((props) => {
  const {
    classroom
  } = props;
  const {
    handleClickClassroomTitle,
    userManager
  } = useContext(MobXProviderContext);

  const InfoIcon = SatCoreComponent('InfoIcon');

  const renderClassroomTitle = () => {
    const { isTeacher } = userManager;
    const hasMultipleInstitutions = UserService.hasMultipleInstitutions();

    const classroomName = classroom?.ssoId && classroom.nickname ? classroom.nickname : classroom?.name;

    const classroomNameObj = UtilityService.reactHtmlParserWrapper(classroomName || '');
    return (
      <div className='classroom-title-wrapper'>
        <div
          className='classroom-title'
          onClick={handleClickClassroomTitle}
          title={classroomNameObj.stripped}>
          {classroomNameObj.parsed}
        </div>
        <div className='institution-info-icon-wrapper'>
          {isTeacher && hasMultipleInstitutions && (
            <InfoIcon
              popupOptions={{
                content: <strong>{classroom.institutionName}</strong>
              }}
              useWhiteInfoIcon={false} />
          )}
        </div>
      </div>
    );
  };
  return renderClassroomTitle();
});

export default ClassroomTitle;

SatCoreRegister('ClassroomTitle', ClassroomTitle);
