import React, { useMemo } from 'react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import ClassroomApi from '../../api/ClassroomApi';

import '../../css/AddStudentsToClassroom.less';

const AddStudentsToClassroomModal = (props = {}) => {
  const CartModal = useMemo(() => SatCoreComponent('CartModal'), []);
  const CartModalUsers = useMemo(() => SatCoreComponent('CartModalUsers'), []);
  const CartModalUsersPopup = useMemo(() => SatCoreComponent('CartModalUsersPopup'), []);
  return (
    <CartModal
      {...props}
      cart={({ cart, ...rest }) => <CartModalUsersPopup {...rest} users={cart} />}
      className='add-students-to-classroom'
      list={({ cart, ...rest }) => <CartModalUsers {...rest} roleName={/Student/i} users={cart} />}
      onSave={async (users) => await ClassroomApi.addStudents(props.classroomId, users.map((user) => user.id))} />
  );
};

export default AddStudentsToClassroomModal;

SatCoreRegister('AddStudentsToClassroomModal', AddStudentsToClassroomModal);
