import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { SatCoreRegister } from '../SatCoreRegistry';
import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';

import '../css/GradebookDataBar.less';

export default
@inject('gradebookManager')
@observer
class GradebookDataBar extends Component {
  ProgressBar = (props) => {
    const { color, percent } = props;
    return (
      <div className={`progress-bar ${color}`}>
        <div className={color} style={{ width: `${percent}%`, maxWidth: '100%' }} />
      </div>
    );
  }

  render() {
    const { gradebookManager, t } = this.props;
    const { currentAssignment } = gradebookManager;

    let greenPercent = 0;
    if (currentAssignment.totalActivityInstances !== 0) {
      greenPercent = (currentAssignment.totalActivityInstancesGraded / currentAssignment.totalActivityInstances) * 100;
    }

    const { ProgressBar } = this;
    const grade = (currentAssignment.averageActivityInstancesGrade === null) ? 0 : currentAssignment.averageActivityInstancesGrade.toFixed(2);
    let showGrade = true;
    if (currentAssignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LESSON ||
          currentAssignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT ||
            currentAssignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) {
      if (currentAssignment.totalActivityInstancesSubmitted < 1) {
        showGrade = false;
      }
    }

    return (
      <div className='gradebook-data-content'>
        <div className='fg-col'>
          <div className='card-label'>
            {t('started')}
          </div>
          <div className='card-value'>
            {currentAssignment.totalActivityInstancesStarted}
            /
            {currentAssignment.totalActivityInstances}
          </div>
        </div>
        <div className='fg-col'>
          <div className='card-label'>
            {t('submitted')}
          </div>
          <div className='card-value'>
            {currentAssignment.totalActivityInstancesSubmitted}
            /
            {currentAssignment.totalActivityInstances}
          </div>
        </div>
        <div className='fg-col progress'>
          <div className='card-label'>
            {t('graded')}
          </div>
          <div className='fg-row'>
            <div className='card-value'>
              {currentAssignment.totalActivityInstancesGraded}
              /
              {currentAssignment.totalActivityInstances}
            </div>
            <ProgressBar color='grade-high' percent={greenPercent} />
          </div>
        </div>
        <div className='fg-col progress'>
          <div className='card-label'>
            {t('averageScore')}
          </div>
          {(showGrade) ? (
            <div className='fg-row'>
              <div className={`card-value ${gradebookManager.getGradeClassName(grade)}`}>
                {grade}
                %
              </div>
              <ProgressBar color={gradebookManager.getGradeClassName(grade)} percent={grade} />
            </div>
          ) : (
            <div className='fg-row'>
              <div className='card-value grade-none'>
                {t('notApplicable')}
              </div>
              <ProgressBar />
            </div>
          )}
        </div>
      </div>
    );
  }
}

SatCoreRegister('GradebookDataBar', GradebookDataBar);
