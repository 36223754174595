import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { DIALOG_NAMES } from '../managers/DialogManager';
import { SatCoreRegister } from '../SatCoreRegistry';

import '../css/TrialBanner.less';

const openTrialEnterCodesModal = (props) => {
  const { dialogManager, classroomManager: { currentClassroomId } } = props;
  dialogManager.setOpenDialog(DIALOG_NAMES.TRIAL_ENTER_PURCHASE_CODES, { currentClassroomId }, closeTrialEnterCodesModal.bind(null, props));
};

const closeTrialEnterCodesModal = ({ dialogManager }) => dialogManager.closeDialog(DIALOG_NAMES.TRIAL_ENTER_PURCHASE_CODES);

const TrialBanner = (props) => {
  const { classroomManager, classroomRosterManager, userManager, t } = props;
  if (!userManager.isStudent) {
    return null;
  }
  const { isPurchaseVerified, isExpired, currentRoster: { trialDaysRemaining } } = classroomRosterManager;
  useEffect(
    () => classroomRosterManager.fetchCurrentRoster(userManager.userId, classroomManager.currentClassroomId),
    // eslint-disable-next-line
    [userManager.userId, classroomManager.currentClassroomId]
  );
  if (isPurchaseVerified) {
    return null;
  }
  return (
    <>
      <div className={`trial-banner${isExpired ? ' expired' : ''}`}>
        <div className='container'>
          {isExpired ?
            t('expired', 'Your Trial Has Expired') :
            t('trial', { defaultValue: 'You have {{count}} days left in your trial', count: trialDaysRemaining })}
          <Button onClick={openTrialEnterCodesModal.bind(null, props)}>{t('button', 'Enter Access Code')}</Button>
        </div>
      </div>
      <div className='trial-banner-block' />
    </>
  );
};

export const TrialBannerWrapper = inject('classroomManager', 'classroomRosterManager', 'dialogManager', 'userManager')(observer(TrialBanner));

export default TrialBannerWrapper;

SatCoreRegister('TrialBanner', TrialBannerWrapper);
