import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button } from 'semantic-ui-react';

import '../../css/reports/ReportHideColumnsLauncherButton.less';

import { SatCoreRegister } from '../../SatCoreRegistry';

import { DIALOG_NAMES } from '../../managers/DialogManager';

import ReportIdentityService from '../../services/reports/ReportIdentityService';
import ReportTableService from '../../services/reports/ReportTableService';

const ReportHideColumnsLauncherButton = observer((props) => {
  const { t } = props;

  const {
    dialogManager,
    reportIdentityManager,
    reportTableManager
  } = useContext(MobXProviderContext);

  const {
    isTableIndividualReport,
    isTableSummaryReport
  } = reportIdentityManager;

  const handleLaunchReportHideColumnsModal = () => {
    reportTableManager.clearHiddenReportTableFacultyCellsMap();

    dialogManager.setOpenDialog(DIALOG_NAMES.REPORT_HIDE_COLUMNS_MODAL, {
      // ...ReportIdentityService.getReportExportParams(), // TODO remove
      onClose: () => {
        dialogManager.closeDialog(DIALOG_NAMES.REPORT_HIDE_COLUMNS_MODAL);

        ReportTableService.forceRerenderReportTable();
      }
    }/* , () => {} */);
  };

  const renderReportHideColumnsLauncherButton = () => {
    const isReportStandardsClassroomDetailStudents = ReportIdentityService.isReportStandardsClassroomDetailStudents();
    const allowReportHideColumnsLauncherButton = (
      !isTableSummaryReport && !isTableIndividualReport && !isReportStandardsClassroomDetailStudents
    );

    return allowReportHideColumnsLauncherButton ? (
      <div className='ReportHideColumnsLauncherButton'>
        <div className='report-hide-columns-btn-wrapper'>
          <Button onClick={() => handleLaunchReportHideColumnsModal()}>
            {t('hideColumns')}
          </Button>
        </div>
      </div>
    ) : null;
  };

  return renderReportHideColumnsLauncherButton();
});
export default ReportHideColumnsLauncherButton;

SatCoreRegister('ReportHideColumnsLauncherButton', ReportHideColumnsLauncherButton);
