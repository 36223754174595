import React, { useContext } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import { Image } from 'semantic-ui-react';

import iconCommentActive from '../img/icon-comment-active.svg';
import iconCommentNone from '../img/icon-comment-none.svg';

import { SatCoreRegister } from '../SatCoreRegistry';

const FeedbackIcon = observer((props) => {
  const { className, clickHandler, disabled, hasFeedback, icon } = props;

  const { contentManager } = useContext(MobXProviderContext);

  return (
    <Image
      alt=''
      className={`feedback clickable ${className}`}
      disabled={!!disabled}
      onClick={clickHandler}
      src={hasFeedback ? icon || contentManager.feedbackIconActiveImg || iconCommentActive : iconCommentNone} />
  );
});

export default FeedbackIcon;

SatCoreRegister('FeedbackIcon', FeedbackIcon);
