import React, { Component } from 'react';
import { Button, Container, Grid, Tab } from 'semantic-ui-react';
import Modal from '../Modal';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import tagContentManager from '../../managers/TagContentManager';
import assessmentManager from '../../managers/AssessmentManager';

import '../../css/TagsStandardsModal.less';

export default class TagsStandardsModal extends Component {
  constructor(props) {
    super(props);
    this.CategoryTagsList = SatCoreComponent('CategoryTagsList');
  }

  getItemTags = () => {
    const categories = [];
    tagContentManager.categoriesWithTagsArray.map((pItem, _index) => {
      const customName = tagContentManager.getCustomCategoryNameClass(pItem.name);
      const thisClassName = `filter-dropdown-section ${customName}`;
      categories.push(
        <Container key={`${pItem.id}_a`}>
          <div className={thisClassName}>
            <div className='filter-label'>{pItem.name}</div>
            <div className='top-tags-container'>
              {
                this.getItemTagsAsHtml(pItem.tags)
              }
            </div>
          </div>
        </Container>
      );
    });
    return categories;
  }

  getItemTagsAsHtml = (categoryTags) => {
    const { assessmentItem } = this.props;
    const { CategoryTagsList } = this;
    const assessmentItemTagArray = assessmentItem.tagList.split(',');

    const tagIdArray = [];
    categoryTags.map((tag, _index) => {
      if (assessmentItemTagArray.includes(tag.id)) {
        tagIdArray.push(tag.id);
      }
    });
    const tagArray = tagContentManager.getSortedTagsForTagIdArray(tagIdArray);
    return <CategoryTagsList isFullList={true} pills={tagArray}> </CategoryTagsList>;
  }

  getItemStandardsAsHtml = () => {
    const { assessmentItem } = this.props;
    const { CategoryTagsList } = this;
    const standardArray = assessmentManager.getSortedStandardsForAssessmentItem(assessmentItem, true);
    return <CategoryTagsList isFullList={true} pills={standardArray}> </CategoryTagsList>;
  }

  render() {
    const {
      addInfoOpen,
      infoName,
      closeInfoModal
    } = this.props;
    const title = infoName && infoName[0] && infoName[0].props
      && infoName[0].props.children ? infoName[0].props.children[0] : null;

    const ModalBanner = SatCoreComponent('ModalBanner');

    const panes = [
      { menuItem: 'Tags', render: () => <Tab.Pane><Grid><Grid.Column>{this.getItemTags()}</Grid.Column></Grid></Tab.Pane> },
      { menuItem: 'Standards', render: () => <Tab.Pane><Grid><Grid.Column>{this.getItemStandardsAsHtml()}</Grid.Column></Grid></Tab.Pane> }
    ];

    return (
      <Modal
        className='tags-standards-modal'
        closeOnDimmerClick={true}
        closeOnEscape={true}
        onClose={this.closeInfoModal}
        open={addInfoOpen}>
        <ModalBanner
          label='Item Tags'
          onClose={() => { closeInfoModal(); }}
          title={title} />
        <Modal.Content>
          <Tab panes={panes} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => { closeInfoModal(); }} primary>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('TagsStandardsModal', TagsStandardsModal);
