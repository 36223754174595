import { action, autorun, makeObservable, observable } from 'mobx';
import { get, isArray, isPlainObject, set } from 'lodash';
import classroomRosterManager from './ClassroomRosterManager';

export class AccessManager {
  @observable policies = {
    studentDashboard: {
      bookImageCard: true,
      progressWidget: true,
      assignmentCard: true
    },
    studentTopNav: {
      assignments: true,
      progress: true,
      books: true,
      workspace: true
    },
    mainView: {
      classDetails: true,
      classCourses: true,
      resourcesView: true,
      assignmentView: true,
      studentAssignmentView: true,
      progressView: true
    }
  };

  constructor() {
    makeObservable(this);
    autorun(() => this.change(['studentDashboard', 'studentTopNav', 'mainView'], !classroomRosterManager.isExpired));
  }

  @action change = (key, value) => {
    if (isArray(key)) {
      key.forEach((subKey) => this.change(subKey, value));
      return;
    }
    if (key === null || key === undefined || key === '') {
      Object.keys(this.policies).forEach((subKey) => this.change(subKey, value));
      return;
    }
    if (isPlainObject(get(this.policies, key))) {
      Object.keys(get(this.policies, key)).forEach((subKey) => this.change([key, subKey].join('.'), value));
    } else {
      set(this.policies, key, value === 'toggle' ? !get(this.policies, key) : value);
    }
  };

  @action enable = (key) => this.change(key, true);

  @action disable = (key) => this.change(key, false);

  @action toggle = (key) => this.change(key, 'toggle');
}

export default new AccessManager();
