/**
 * AddCustomAssessmentModal
 * ------------------------
 * NOTE: at this point,
 * `assessment` === `courseElement`
 * and the two terms are used interchangeably.
 */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Container, Form, Grid,
  Header, Input, Label,
  Message, Pagination, Popup
} from 'semantic-ui-react';
import Modal from '../Modal';
import '../../css/AddCustomAssessmentModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { ASSESSMENT_TRANSACTION } from '../../managers/AssessmentManager';

import CustomCourseResourceService from '../../services/CustomCourseResourceService';
import ImageService from '../../services/ImageService';
import NavigationService from '../../services/NavigationService';

import { DIALOG_NAMES } from '../../managers/DialogManager';
import InputModalWithProfiles from './InputModalWithProfiles';

export default
@inject(
  'assessmentManager', 'classroomManager', 'courseManager', 'userManager', 'dialogManager', 'libraryManager')
@observer
class AddCustomAssessmentModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.CourseTreeLeaf = SatCoreComponent('CourseTreeLeaf');
  }

  componentDidMount = async () => {
    const { libraryManager } = this.props;

    const templateOptions = await libraryManager.getOptionProfiles();
    if (templateOptions && templateOptions.length > 0) {
      this.setState({ templateOptions });
    }
    await this.refreshAllAssessmentData();
  }

  getInitialState() { return {
    activePage: 1,
    hasAssessments: false,
    isInputModalOpen: false,
    loading: false,
    isSelectedAssessmentsPopupOpen: false,
    serverErrorMsg: null,
    deletedAssessmentName: null,
    templateOptions: []
  }; }

  closeAddCustomAssessmentModal = async (didWeAddAssessments = false) => {
    this.setState(this.getInitialState());
    this.setState({ loading: true });
    const { assessmentManager } = this.props;
    assessmentManager.clearSelectedNotAttachedResources();
    await this.refreshAllAssessmentData();

    assessmentManager.setUpdatedCachedAssessmentList(null);

    this.props.closeAddCustomAssessmentModal(didWeAddAssessments);
    this.setState({ loading: false });
  }

  submitAddSelectedAssessmentsToCourse = async () => {
    if (this.state.loading) {
      return false;
    }
    this.setState({ loading: true });
    await this.saveResults();
  }

  saveResults = async () => {
    const { assessmentManager } = this.props;
    if (assessmentManager.selectedNotAttachedAssessmentIds.length) {
      await this.doSaveThenRefresh();
    } else {
      this.setState({ loading: false });
      this.closeAddCustomAssessmentModal();
    }
  }

  doSaveThenRefresh = async () => {
    const { assessmentManager } = this.props;
    const result = await assessmentManager.createCustomCourseResourceAlignments(
      assessmentManager.selectedNotAttachedAssessmentIds
    );
    if (!result) {
      alert('An error occurred when trying to save. Please try again later.');
      this.setState({ loading: false });
    } else {
      await this.doRefresh(result);
    }
  }

  doRefresh = async (closeShoppingExperienceAfterRefresh = true) => {
    const { activePage } = this.state;
    await this.fetchAvailableAssessments(activePage);
    this.setState({ loading: false });
    if (closeShoppingExperienceAfterRefresh) {
      const didWeAddAssessments = true;
      this.closeAddCustomAssessmentModal(didWeAddAssessments);
    } else {
      await this.refreshAllAssessmentData();
    }
  }

  handleTogglePopup = (toggleOpen = true) => {
    const { isSelectedAssessmentsPopupOpen } = this.state;
    if (toggleOpen && !isSelectedAssessmentsPopupOpen) {
      this.setState({ isSelectedAssessmentsPopupOpen: true });
    } else if (!toggleOpen && isSelectedAssessmentsPopupOpen) {
      this.setState({ isSelectedAssessmentsPopupOpen: false });
    }
  }

  handlePageChange = async (_event, pageInfo) => {
    this.setState({ loading: true });
    const { activePage } = pageInfo;
    const pageSize = ASSESSMENT_TRANSACTION.PAGE_SIZE;
    await this.fetchAvailableAssessments(activePage, pageSize);
    this.setState({ activePage, loading: false });
  }

  handleChangeSearch = (_event, obj) => {
    const searchText = obj ? obj.value : '';
    const { assessmentManager } = this.props;
    assessmentManager.setSearchText(searchText);
    assessmentManager.setSearchTextTimeout(clearTimeout(assessmentManager.searchTextTimeout));
    assessmentManager.setSearchTextTimeout(setTimeout(() => {
      if (!searchText || searchText.length >= 3) {
        const page = 0, pageSize = 999;
        this.fetchAvailableAssessments(
          page, pageSize, searchText
        ).then(() => {});
      }
    }, 1000));
    this.setState({ deletedAssessmentName: null, serverErrorMsg: null });
  }

  fetchAvailableAssessments = async (
    page = 0,
    pageSize = 999,
    searchText = ''
  ) => {
    const returnImmediatelyAfterResponse = true;
    const { classroomId } = this.props;

    await CustomCourseResourceService.fetchUserCustomAssessmentList(
      returnImmediatelyAfterResponse,
      classroomId, page, pageSize, searchText
    );
  }

  handleCheck = (assessment) => {
    const { assessmentManager } = this.props;
    assessmentManager.setAssessmentSelected(assessment.id);
    this.setState({ deletedAssessmentName: null, serverErrorMsg: null });
  }

  handleClickCreateNewCustomAssessment = () => {
    this.setState({ isInputModalOpen: true });
  }

  doesNotHaveDuplicateAssessmentName = async (name) => {
    const { assessmentManager } = this.props;
    const assessments = assessmentManager.assessmentList;
    const found = assessments.find((assessment) => assessment.name === name || assessment.displayName === name);
    return !found;
  }

  handleSaveCreateCustomAssessment = async (save, name, subtitle, optionProfileId) => {
    if (save) {
      const { assessmentManager, courseManager } = this.props;
      this.setState({ isInputModalOpen: false, loading: true });
      await CustomCourseResourceService.createCustomAssessment(name, subtitle,optionProfileId);
      const closeShoppingExperienceAfterRefresh = false;
      await this.doRefresh(closeShoppingExperienceAfterRefresh);

      /**
       * The backend adds the new assessment to the current course automatically.
       *
       * But in this case, the teacher is shopping for assessments and
       * should be able to manually choose if it should be included
       * in the current course.
       *
       * So here we will delete it from the current course.
       *
       * If the teacher manually re-adds it, it will be put back into the current course.
       */
      const newCachedAssessment = assessmentManager.cachedAssessmentList.find((assessment) => assessment.name === name);
      await courseManager.deleteCourseResourceElement(newCachedAssessment.elementId);

      const newAssessment = assessmentManager.assessmentsMap.get(newCachedAssessment.contentItemId);
      newAssessment.linkedClassroomNames = [];

      this.setState({ loading: false });
    } else {
      this.setState({ isInputModalOpen: false });
    }
  }

  getAssessments = (selectedOnly = null) => {
    const { assessmentManager, t } = this.props;
    const { hasAssessments } = this.state;
    const { SCCheckbox } = this;
    const { CourseTreeLeaf } = this;
    const jsxArray = [];
    let assessmentList = [];

    let zeroStateMsg = null;
    if (!selectedOnly) {
      if (!hasAssessments) {
        zeroStateMsg = t('customAssessmentZeroStateMsg', 'There are no assessments added yet.');
      }
    } else if (selectedOnly) {
      assessmentList = assessmentManager.selectedNotAttachedAssessmentArray;
    }
    if (!selectedOnly && zeroStateMsg) {
      jsxArray.push(
        <div
          key='assessment-modal-zero-state'
          className='assessment-modal-zero-state'>
          <div className='assessment-modal-zero-state-text'>
            {zeroStateMsg}
          </div>
        </div>
      );
      return jsxArray;
    }
    assessmentList = assessmentManager.pagedAssessmentList;

    assessmentList.map((assessment) => {
      const { userManager } = this.props;
      if (!selectedOnly && assessmentManager.assessmentsMap.has(assessment.id)) {
        assessment = { ...assessmentManager.assessmentsMap.get(assessment.id) };
      }
      if (!selectedOnly || (assessment.selected && !assessment.attached)) {
        const isChecked = assessmentManager.selectedNotAttachedResources.has(assessment.id);
        jsxArray.push(
          <Form.Field key={`${assessment.id}_FF`} className='add-assessment-field'>
            <SCCheckbox
              key={assessment.id}
              checked={isChecked}
              disabled={assessment.attached}
              onChange={() => { this.handleCheck(assessment); }} />
            <CourseTreeLeaf
              courseElement={assessment}
              disabled={assessment.attached}
              handleClick={() => { this.handleCheck(assessment); }}
              handleCustomAssessmentRemove={this.deleteCustomAssessmentConfirm}
              hideTypicalCardNavButtons={true}
              imageUrl={ImageService.getImageUrl(assessment)}
              isTeacher={userManager.isTeacher}
              showTrashIcon={true} />
          </Form.Field>);
        return true;
      }
    });
    return jsxArray;
  }

  refreshAllAssessmentData = async () => {
    const { assessmentManager } = this.props;
    this.setState({ loading: true });

    assessmentManager.clearAssessments();
    assessmentManager.clearPagedAssessments();

    assessmentManager.setSearchText('');

    await this.fetchAvailableAssessments();

    const urlParams = new URLSearchParams(window.location.search);
    const currentCourseId = currentCourseId || urlParams.get('courseId');
    const currentClassroomId = currentClassroomId || urlParams.get('classroomId');
    const currentElementId = currentElementId || urlParams.get('elementId');
    const force = true;

    const list = await NavigationService.treeNavigationFunction(
      currentCourseId, currentClassroomId, currentElementId, force
    );
    assessmentManager.setUpdatedCachedAssessmentList(list);

    this.setState({ loading: false, hasAssessments: !!assessmentManager.assessmentsMap.size, deletedAssessmentName: null, serverErrorMsg: null });
  }

  deleteCustomAssessmentConfirm = (_event, contentItem, linkedClassroomNames) => {
    const { dialogManager, t } = this.props;
    if (linkedClassroomNames && linkedClassroomNames.length > 0) {
      this.hasClassesWarning(linkedClassroomNames);
    } else {
      const title = t('confirmDeleteTitle', 'AddCustomAssessmentModal: delete assessment title message missing.');
      const message = t('confirmDelete', 'AddCustomAssessmentModal: hasClasses message missing.');
      dialogManager.setOpenDialog(DIALOG_NAMES.CONFIRM, {
        title,
        message,
        cancelButtonClass: 'keepButton',
        cancelButtonName: 'No, Cancel',
        confirmButtonClass: 'deleteButton',
        confirmButtonName: 'Yes, Continue',
        confirmHandler: () => this.deleteCourseElement(contentItem)
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM));
    }
    if (_event) {
      _event.stopPropagation();
    }
  }

  hasClassesWarning = (linkedClassroomNames) => {
    const { dialogManager, t } = this.props;
    const title = t('hasClassesTitle', 'AddCustomAssessmentModal: hasClassesTitle message missing.');
    let message = t('hasClasses', 'AddCustomAssessmentModal: hasClasses message missing.');
    let index = 0;
    for (const className of linkedClassroomNames) {
      message = `${message + ((index > 0) ? ',' : '')}"${className}"`;
      index++;
    }

    dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);

    dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
      title,
      message,
      closeButtonClass: 'deleteButton',
      closeButtonName: 'Ok'
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
  }

  deleteCourseElement = async (contentItem) => {
    const { dialogManager, courseManager } = this.props;
    const response = await courseManager.deleteContentItem(contentItem.id, true);
    let result = false;
    if (response && response.status === 'SUCCESS') {
      result = true;
    }
    if (response && response.status === 'FAILURE') {
      result = false;
      if (response.statusCode === 'HAS_CLASSROOM_LINKS') {
        this.deleteCustomAssessmentConfirm(null, contentItem, response.linkedClassroomNames);
        return;
      }
    }
    if (!result) {
      console.log(`Error deleting course element: ${contentItem.id}`);
      const errorMessage = `Error deleting course element: ${contentItem.id} ${response.statusMessage}`;
      console.log(errorMessage);
      let tempMessage = errorMessage;
      tempMessage = tempMessage.length > 99 ? `${tempMessage.substring(0, 96)}...` : tempMessage;
      await this.refreshAllAssessmentData();
      this.setState({ deletedAssessmentName: null, serverErrorMsg: tempMessage });
    } else {
      let tempMessage = contentItem.name;
      tempMessage = tempMessage.length > 99 ? `${tempMessage.substring(0, 96)}...` : tempMessage;
      tempMessage += ' Assessment has been removed.';
      await this.refreshAllAssessmentData();
      this.setState({ deletedAssessmentName: tempMessage, serverErrorMsg: tempMessage });
    }

    dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
  }

  hideError = () => {
    this.setState({ deletedAssessmentName: null, serverErrorMsg: null });
  }

  render() {
    const { SatCoreLoader } = this;
    const {
      activePage,
      hasAssessments,
      isInputModalOpen,
      isSelectedAssessmentsPopupOpen,
      templateOptions
    } = this.state;
    const { assessmentManager, displayAddCustomAssessmentModal, t } = this.props;
    const { selectedNotAttachedAssessmentIds } = assessmentManager;
    const selectedCount = selectedNotAttachedAssessmentIds.length ? selectedNotAttachedAssessmentIds.length : 0;

    const paginationJsx = (
      <Pagination
        activePage={activePage}
        onPageChange={this.handlePageChange}
        totalPages={assessmentManager.totalPages} />
    );
    const searchboxJsx = (
      <Grid>
        <Grid.Column>
          <Input
            autoFocus={!!assessmentManager.searchText}
            icon='search'
            name='search'
            onChange={this.handleChangeSearch}
            placeholder='Search assessment name'
            type='text'
            value={assessmentManager.searchText} />
        </Grid.Column>
      </Grid>
    );
    const { length } = assessmentManager.assessmentList;

    const bannerLabel = t('modalAddNewCustomAssessment', 'Create New Custom Assessment');

    const modalContentJsx = (
      <>
        {(this.state.loading) ? (
          <span className='sat-core-loader-wrapper'>
            <SatCoreLoader />
          </span>
        )
          : (
            <Modal.Content scrolling>
              <Container className='course-assessment-container' fluid>
                <Container className='course-assessment-list-container' fluid>
                  <div className='modal-content-header'>
                    <div className='btn-create-custom-assessment-wrapper'>

                      <Button
                        className='ui primary button create-custom-assessment-button'
                        disabled={isSelectedAssessmentsPopupOpen}
                        loading={this.state.loading}
                        onClick={() => this.handleClickCreateNewCustomAssessment()}>
                        {t('addNewCustomAssessment', '+ Create New Custom Assessment')}
                      </Button>

                    </div>
                    <div className='modal-content-header-pagination'>
                      {length ? paginationJsx : (hasAssessments ? t('noResultsZeroStateMsg', 'No results found.') : '')}
                    </div>
                    <div className='modal-content-header-searchbox'>
                      {searchboxJsx}
                    </div>
                  </div>
                  <div className='error-message-wrapper'>
                    <Message
                      className='custom-assessment-modal-error-msg'
                      content={`${this.state.serverErrorMsg}`}
                      error
                      hidden={this.state.serverErrorMsg === null}
                      onDismiss={this.hideError} />
                  </div>
                  {this.getAssessments()}
                  <div className='modal-content-footer'>
                    {!!length && length >= 5 && (
                      <div className='modal-content-footer-pagination'>
                        {paginationJsx}
                      </div>
                    )}
                  </div>
                </Container>
              </Container>
              {isInputModalOpen && (
                <InputModalWithProfiles
                  bannerLabel={bannerLabel}
                  cancelLabel='Cancel'
                  isOpen={isInputModalOpen}
                  isValidName={this.doesNotHaveDuplicateAssessmentName}
                  name=''
                  onSave={(save, name, subtitle, optionProfileId) => this.handleSaveCreateCustomAssessment(save, name, subtitle, optionProfileId)}
                  saveLabel='Create'
                  subtitle=''
                  optionProfiles = {templateOptions}

                  {...this.props} />
              )}
            </Modal.Content>
          )}
      </>
    );
    return (
      <>
        <Modal
          className='AddCustomAssessmentModal'
          closeOnDimmerClick={false}
          closeOnEscape={false}
          onClose={this.closeAddCustomAssessmentModal}
          open={displayAddCustomAssessmentModal}
          size='fullscreen'
          style={{ top: '-14px', overflow: 'hidden' }}>
          {(this.state.loading) ? (
            <Modal.Content>
              <SatCoreLoader />
            </Modal.Content>
          ) : (
            <>
              <Modal.Header className='modal-header'>
                <Header.Content className='modal-header-bar'>
                  <span className='modal-header-label'>
                    Select Assessments
                  </span>
                  <div className='modal-header-buttons'>
                    <Popup
                      className={`selected-assessments-popup ${selectedCount ? 'has-selected-assessments' : 'has-no-selected-assessments'}`}
                      content={selectedCount ? (
                        <>
                          <div className='selected-assessments-popup-title'>
                            Selected Assessments
                          </div>
                          <Container
                            className='course-assessment-container'
                            fluid>
                            <Container className='course-assessment-list-container' fluid>
                              {this.getAssessments('selectedOnly')}
                            </Container>
                          </Container>
                        </>
                      ) : 'No new selected assessments yet.'}
                      hoverable
                      offset={[0, -295]}
                      on='hover'
                      onClose={() => { this.handleTogglePopup(false); }}
                      onOpen={() => { this.handleTogglePopup(true); }}
                      position='left center'
                      trigger={(
                        <div className={`selected-count-wrapper ${selectedCount ? 'has-selected-count' : 'has-no-selected-count'}`}>
                          <Label circular>
                            {selectedCount}
                          </Label>
                          <span className='selected-text-label'>
                            Selected
                          </span>
                        </div>
                      )} />

                    <Button
                      basic
                      className='cancelButton'
                      disabled={isSelectedAssessmentsPopupOpen}
                      onClick={this.closeAddCustomAssessmentModal}
                      primary>
                      Cancel
                    </Button>
                    <Button
                      className='ui primary button saveButton'
                      disabled={isSelectedAssessmentsPopupOpen}
                      loading={this.state.loading}
                      onClick={this.submitAddSelectedAssessmentsToCourse}>
                      Save
                    </Button>
                  </div>
                </Header.Content>
              </Modal.Header>
              <div className='nav-separator' />
              {modalContentJsx}
            </>
          )}
        </Modal>
      </>
    );
  }
}

SatCoreRegister('AddCustomAssessmentModal', AddCustomAssessmentModal);
