import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, Dropdown, Icon, Image } from 'semantic-ui-react';

import imageMoreFiltersNoneSelected from '../../img/image-more-filters-none-selected.png';
import imageMoreFiltersSomeSelected from '../../img/image-more-filters-some-selected.png';

import '../../css/reports/ReportDropdown.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { getSessionStorageItem } from '../../utils';

import { REPORT_ASSIGNMENT_TYPE, REPORT_CONTENT_TYPE, REPORT_FAILURE_BEHAVIOR_TYPE } from '../../services/reports/ReportConstants';

import PopupService from '../../services/PopupService';
import ReportIdentityService from '../../services/reports/ReportIdentityService';
import ReportMoreFiltersService from '../../services/reports/ReportMoreFiltersService';

const ReportMoreFilters = observer((props) => {
  const { t } = props;

  const {
    reportIdentityManager, reportStandardsManager, groupsManager
  } = useContext(MobXProviderContext);

  const [shouldOpenReportMoreFiltersPopup, setShouldOpenReportMoreFiltersPopup] = useState(false);

  const ReportGroupFilter = SatCoreComponent('ReportGroupFilter');

  const [selectedContentType, setSelectedContentType] = useState(
    reportStandardsManager.selectedReportMoreFiltersContentType);

  const [selectedAssignmentType, setSelectedAssignmentType] = useState(
    reportStandardsManager.selectedReportMoreFiltersAssignmentType);

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  useEffect(() => {
    (async () => {
      // TODO unused
      // reportStandardsManager.setSelectedReportMoreFiltersContentType(
      //   getSessionStorageItem('selectedReportMoreFiltersContentType')
      // );
      reportStandardsManager.setSelectedReportMoreFiltersAssignmentType(
        getSessionStorageItem('selectedReportMoreFiltersAssignmentType')
      );
    })();
  }, []);

  /** @param {'content' | 'assignment'} reportDropdownType */
  const handleChangeDropdown = (_event, { value }, reportDropdownType) => {
    if (reportDropdownType === 'content') {
      setSelectedContentType(value);
    } else if (reportDropdownType === 'assignment') {
      setSelectedAssignmentType(value);
    }
  };

  const handleClickApplyButton = async () => {
    // TODO unused // reportStandardsManager.setSelectedReportMoreFiltersContentType(selectedContentType);
    reportStandardsManager.setSelectedReportMoreFiltersAssignmentType(selectedAssignmentType);
    const reportId = ReportIdentityService.getReportId(selectedContentType, selectedAssignmentType, {
      isStandardsFilterReportId: true
    });
    setShouldOpenReportMoreFiltersPopup(false);

    const curriculumMapId = reportStandardsManager.selectedReportCmapContentItemId;

    groupsManager.tmpGroups = groupsManager.groups;

    await groupsManager.fetchGroupListMembers();
    await reportIdentityManager.fetchReportResponseJson({
      curriculumMapId,
      failureBehaviorType: REPORT_FAILURE_BEHAVIOR_TYPE.FALLBACK_AND_FORCE_NO_DATA_STATE,
      reportId
    });
  };

  const renderMoreFilters = () => {
    const { reportInfoClassNames } = reportIdentityManager;

    const selectedCount = ReportMoreFiltersService.getSelectedCount();
    const selectedTranslationKey = ReportMoreFiltersService.getSelectedTranslationKey();

    return PopupService.renderPopup({
      className: 'more-filters-popup',
      content: (
        <div className={`more-filters-popup-content ${reportInfoClassNames}`}>
          <div className='more-filters-dropdowns'>
            {renderTypeFilter('content')}
            {renderTypeFilter('assignment')}
            {renderGroupFilter()}
          </div>
          <div className='more-filters-button-actions'>
            {renderButton('reset')}
            {renderButton('apply')}
          </div>
        </div>
      ),
      on: 'click',
      onClose: () => {
        // TODO remove // setSelectedContentType(reportStandardsManager.selectedReportMoreFiltersContentType);
        setSelectedAssignmentType(reportStandardsManager.selectedReportMoreFiltersAssignmentType);
        // reset groups to what it was the last time apply was pressed
        groupsManager.setGroupsForReport(groupsManager.tmpGroups);
        setShouldOpenReportMoreFiltersPopup(false);
      },
      onOpen: () => {
        setShouldOpenReportMoreFiltersPopup(true);
      },
      open: shouldOpenReportMoreFiltersPopup,
      position: 'bottom center',
      trigger: (
        <div className={`report-more-filters-dropdown-trigger ${reportInfoClassNames}`}>
          <div className='more-filters-img'>
            <Image alt='' src={selectedCount ? imageMoreFiltersSomeSelected : imageMoreFiltersNoneSelected} />
          </div>
          <div className='more-filters-label-wrapper'>
            <div className={`more-filters-label ${selectedCount ? 'some-selected' : 'none-selected'}`}>
              {t(selectedTranslationKey, { selectedCount })}
            </div>
          </div>
          <div className='more-filters-dropdown-icon'>
            <Icon
              aria-hidden='true'
              className={`${selectedCount ? 'some-selected' : 'none-selected'}`}
              name='dropdown' />
          </div>
        </div>
      )
    });
  };

  /** @param {'content' | 'assignment'} reportDropdownType */
  const renderTypeFilter = (reportDropdownType) => {
    if (!ReportMoreFiltersService.showTypeFilters()) {
      return null;
    }

    let dropdownTypeObj, selectedOptionValue;
    if (reportDropdownType === 'content') {
      dropdownTypeObj = REPORT_CONTENT_TYPE;
      selectedOptionValue = selectedContentType;
    } else if (reportDropdownType === 'assignment') {
      dropdownTypeObj = REPORT_ASSIGNMENT_TYPE;
      selectedOptionValue = selectedAssignmentType;
    }
    const dropdownOptions = Object.values(dropdownTypeObj).map((dropdownOption) => {
      return {
        text: t(dropdownOption),
        value: dropdownOption
      };
    });
    return (
      <div className={`report-dropdown report-${reportDropdownType}-dropdown`}>
        <div className='dropdown-label'>
          <div className='text'>
            {`${t(reportDropdownType)}:`}
          </div>
        </div>
        <Dropdown
          onChange={(event, data) => handleChangeDropdown(event, data, reportDropdownType)}
          options={dropdownOptions}
          value={selectedOptionValue} />
      </div>
    );
  };

  const renderGroupFilter = () => {
    const showGroupFilter = ReportMoreFiltersService.showGroupFilter();
    return showGroupFilter && (
      <ReportGroupFilter {...props} label={t('groupSelectLabel')} updateOnClose={false} />
    );
  };

  /** @param {'reset' | 'apply'} buttonType */
  const renderButton = (buttonType) => {
    return (
      <div className={`button-${buttonType}-wrapper`}>
        <Button basic className={buttonType}
          content={t(buttonType)}
          onClick={() => {
            if (buttonType === 'apply') {
              handleClickApplyButton();
            } else if (buttonType === 'reset') {
              setSelectedContentType(REPORT_CONTENT_TYPE.ALL);
              setSelectedAssignmentType(REPORT_ASSIGNMENT_TYPE.ALL);
              groupsManager.groups = [];
            }
          }}
          primary={buttonType === 'apply'} />
      </div>
    );
  };

  return renderMoreFilters();
});
export default ReportMoreFilters;

SatCoreRegister('ReportMoreFilters', ReportMoreFilters);
