import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, Popup } from 'semantic-ui-react';

import classNames from 'classnames';

import '../css/PopupButton.less';

import { SatCoreRegister } from '../SatCoreRegistry';

const PopupButton = observer((props) => {
  const { buttonLabel, basic, className, disabled, popupMsg, popupWide = 'very', primary } = props;

  const { userManager } = useContext(MobXProviderContext);

  const buttonClassNames = classNames('popup-button', {
    'popup-button-student': userManager.isStudent,
    'pseudo-disabled': disabled
  }, className);

  const buttonJsx = (
    <Button
      basic={basic}
      className={buttonClassNames}
      onClick={() => {
        if (!disabled) {
          props.onClick();
        }
      }}
      primary={primary}>
      {buttonLabel}
    </Button>
  );

  return (
    <>
      {popupMsg ? (
        <Popup
          content={popupMsg}
          hoverable
          on='hover'
          position='bottom center'
          positionFixed
          trigger={buttonJsx}
          wide={popupWide === 'very' ? 'very' : undefined} />
      ) : buttonJsx}
    </>
  );
});
export default PopupButton;

SatCoreRegister('PopupButton', PopupButton);
