import { action, computed, makeObservable, observable } from 'mobx';

import { getRegisteredClass } from '../../SatCoreRegistry';

import {
  REPORT_USAGE_COLOR_KEYS, REPORT_USAGE_MODES
} from '../../services/reports/ReportConstants';

import reportContextManager from './ReportContextManager';
import reportOverrideManager from './ReportOverrideManager';

export class ReportUsageManager {
  @observable usageColorKeyDataArray = REPORT_USAGE_COLOR_KEYS;
  @observable usageMode = REPORT_USAGE_MODES.MINUTES_AND_COLORS;

  constructor() {
    makeObservable(this);
  }

  @action clearAll = () => {
    this.usageColorKeyDataArray = REPORT_USAGE_COLOR_KEYS;
    this.usageMode = REPORT_USAGE_MODES.MINUTES_AND_COLORS;
  }

  /** @param {{ color: string, label: string }[]} usageColorKeyDataArray */
  @action setUsageColorKeyDataArray = (usageColorKeyDataArray) => {
    this.usageColorKeyDataArray = usageColorKeyDataArray;
  }

  /** @param {'backgroundColorsOnly' | 'minutesAndColors' | 'minutesOnly'} usageMode */
  @action setUsageMode = (usageMode) => {
    this.usageMode = usageMode;
  }

  @computed get useEnhancedUsageReports() {
    const ReportIdentityService = getRegisteredClass('ReportIdentityService');
    return reportOverrideManager.enableEnhancedUsageReports &&
      ReportIdentityService?.manager().isStandardsReport &&
      reportContextManager.isContextUsageReport;
  }

  @computed get useBackgroundColorsOnly() {
    return this.usageMode === REPORT_USAGE_MODES.BACKGROUND_COLORS_ONLY;
  }
}

export default new ReportUsageManager();
