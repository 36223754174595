import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/EngagementTable.less';

// import { register } from '../../../i18n';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import EngagementService from '../../../services/EngagementService';

const EngagementRowAuxiliaryResourcesUsageCells = observer((props) => {
  const { engagementData } = props;

  const {
    viewBasicCalculatorAvailable,
    viewBasicCalculatorCount,
    viewCustomaryRulerAvailable,
    viewCustomaryRulerCount,
    viewDictionaryAvailable,
    viewDictionaryCount,
    viewGraphingCalculatorAvailable,
    viewGraphingCalculatorCount,
    viewMetricRulerAvailable,
    viewMetricRulerCount,
    viewProtractorAvailable,
    viewProtractorCount,
    viewResourcesAvailable,
    viewResourcesCount,
    viewScientificCalculatorAvailable,
    viewScientificCalculatorCount
  } = engagementData;

  const { engagementManager } = useContext(MobXProviderContext);

  // const t = register('EngagementTable');

  const activeTable = engagementManager.activeEngagementTable;

  const isOnlyShowingMainCells = activeTable === 'summary';

  const renderEngagementRowAuxiliaryResourcesUsageCells = () => {
    return (
      <>
        {EngagementService.allowEngagementColumn('viewBasicCalculatorAvailable') &&
          renderEngagedBasicCalculatorResourceCell()}
        {EngagementService.allowEngagementColumn('viewScientificCalculatorAvailable') &&
          renderEngagedScientificCalculatorResourceCell()}
        {EngagementService.allowEngagementColumn('viewGraphingCalculatorAvailable') &&
          renderEngagedGraphingCalculatorResourceCell()}
        {EngagementService.allowEngagementColumn('viewDictionaryAvailable') &&
          renderEngagedDictionaryResourceCell()}
        {EngagementService.allowEngagementColumn('viewCustomaryRulerAvailable') &&
          renderEngagedCustomaryRulerResourceCell()}
        {EngagementService.allowEngagementColumn('viewMetricRulerAvailable') &&
          renderEngagedMetricRulerResourceCell()}
        {EngagementService.allowEngagementColumn('viewProtractorAvailable') &&
          renderEngagedProtractorResourceCell()}
        {EngagementService.allowEngagementColumn('viewResourcesAvailable') &&
          renderEngagedReferencesResourceCell()}
      </>
    );
  };

  const renderEngagedBasicCalculatorResourceCell = () => {
    const renderPopupTrigger = EngagementService.renderEngagementResourcesUsageCellTrigger;
    return renderEngagementRowAuxiliaryResourcesUsageCell({
      allowPopupComponent: true,
      className: classNames('engagement-body-label detail-resources-usage-body engagedBasicCalculator', {
        'cell-positive': viewBasicCalculatorAvailable && viewBasicCalculatorCount
      }),
      popupContent: EngagementService.renderEngagementResourcesUsageCellContent({
        engagementCellType: 'auxiliary',
        engagementData,
        engagementDataAvailableKey: 'viewBasicCalculatorAvailable',
        engagementDataCountKey: 'viewBasicCalculatorCount'
      }),
      popupDisabled: isOnlyShowingMainCells || (viewBasicCalculatorAvailable && !viewBasicCalculatorCount),
      popupTrigger: renderPopupTrigger({
        engagementCellType: 'auxiliary',
        engagementData,
        engagementDataAvailableKey: 'viewBasicCalculatorAvailable',
        engagementDataCountKey: 'viewBasicCalculatorCount'
      })
    });
  };

  const renderEngagedScientificCalculatorResourceCell = () => {
    const renderPopupTrigger = EngagementService.renderEngagementResourcesUsageCellTrigger;

    const popupContentProps = {
      engagementCellType: 'auxiliary',
      engagementData,
      engagementDataAvailableKey: 'viewScientificCalculatorAvailable',
      engagementDataCountKey: 'viewScientificCalculatorCount'
    };

    return renderEngagementRowAuxiliaryResourcesUsageCell({
      allowPopupComponent: true,
      className: classNames('engagement-body-label detail-resources-usage-body engagedScientificCalculator', {
        'cell-positive': viewScientificCalculatorAvailable && viewScientificCalculatorCount
      }),
      popupContent: EngagementService.renderEngagementResourcesUsageCellContent(popupContentProps),
      popupDisabled: isOnlyShowingMainCells || (viewScientificCalculatorAvailable && !viewScientificCalculatorCount),
      popupTrigger: renderPopupTrigger(popupContentProps)
    });
  };

  const renderEngagedGraphingCalculatorResourceCell = () => {
    const renderPopupTrigger = EngagementService.renderEngagementResourcesUsageCellTrigger;

    const popupContentProps = {
      engagementCellType: 'auxiliary',
      engagementData,
      engagementDataAvailableKey: 'viewGraphingCalculatorAvailable',
      engagementDataCountKey: 'viewGraphingCalculatorCount'
    };

    return renderEngagementRowAuxiliaryResourcesUsageCell({
      allowPopupComponent: true,
      className: classNames('engagement-body-label detail-resources-usage-body engagedGraphingCalculator', {
        'cell-positive': viewGraphingCalculatorAvailable && viewGraphingCalculatorCount
      }),
      popupContent: EngagementService.renderEngagementResourcesUsageCellContent(popupContentProps),
      popupDisabled: isOnlyShowingMainCells || (viewGraphingCalculatorAvailable && !viewGraphingCalculatorCount),
      popupTrigger: renderPopupTrigger(popupContentProps)
    });
  };

  const renderEngagedDictionaryResourceCell = () => {
    const renderPopupTrigger = EngagementService.renderEngagementResourcesUsageCellTrigger;

    const popupContentProps = {
      engagementCellType: 'auxiliary',
      engagementData,
      engagementDataAvailableKey: 'viewDictionaryAvailable',
      engagementDataCountKey: 'viewDictionaryCount'
    };

    return renderEngagementRowAuxiliaryResourcesUsageCell({
      allowPopupComponent: true,
      className: classNames('engagement-body-label detail-resources-usage-body engagedDictionary', {
        'cell-positive': viewDictionaryAvailable && viewDictionaryCount
      }),

      popupContent: EngagementService.renderEngagementResourcesUsageCellContent(popupContentProps),
      popupDisabled: isOnlyShowingMainCells || (viewDictionaryAvailable && !viewDictionaryCount),
      popupTrigger: renderPopupTrigger(popupContentProps)
    });
  };

  const renderEngagedCustomaryRulerResourceCell = () => {
    const renderPopupTrigger = EngagementService.renderEngagementResourcesUsageCellTrigger;

    const popupContentProps = {
      engagementCellType: 'auxiliary',
      engagementData,
      engagementDataAvailableKey: 'viewCustomaryRulerAvailable',
      engagementDataCountKey: 'viewCustomaryRulerCount'
    };

    return renderEngagementRowAuxiliaryResourcesUsageCell({
      allowPopupComponent: true,
      className: classNames('engagement-body-label detail-resources-usage-body engagedCustomaryRuler', {
        'cell-positive': viewCustomaryRulerAvailable && viewCustomaryRulerCount
      }),
      popupContent: EngagementService.renderEngagementResourcesUsageCellContent(popupContentProps),
      popupDisabled: isOnlyShowingMainCells || (viewCustomaryRulerAvailable && !viewCustomaryRulerCount),
      popupTrigger: renderPopupTrigger(popupContentProps)
    });
  };

  const renderEngagedMetricRulerResourceCell = () => {
    const renderPopupTrigger = EngagementService.renderEngagementResourcesUsageCellTrigger;

    const popupContentProps = {
      engagementCellType: 'auxiliary',
      engagementData,
      engagementDataAvailableKey: 'viewMetricRulerAvailable',
      engagementDataCountKey: 'viewMetricRulerCount'
    };

    return renderEngagementRowAuxiliaryResourcesUsageCell({
      allowPopupComponent: true,
      className: classNames('engagement-body-label detail-resources-usage-body engagedMetricRuler', {
        'cell-positive': viewMetricRulerAvailable && viewMetricRulerCount
      }),
      popupContent: EngagementService.renderEngagementResourcesUsageCellContent(popupContentProps),
      popupDisabled: isOnlyShowingMainCells || (viewMetricRulerAvailable && !viewMetricRulerCount),
      popupTrigger: renderPopupTrigger(popupContentProps)
    });
  };

  const renderEngagedProtractorResourceCell = () => {
    const renderPopupTrigger = EngagementService.renderEngagementResourcesUsageCellTrigger;

    const popupContentProps = {
      engagementCellType: 'auxiliary',
      engagementData,
      engagementDataAvailableKey: 'viewProtractorAvailable',
      engagementDataCountKey: 'viewProtractorCount'
    };

    return renderEngagementRowAuxiliaryResourcesUsageCell({
      allowPopupComponent: true,
      className: classNames('engagement-body-label detail-resources-usage-body engagedProtractor', {
        'cell-positive': viewProtractorAvailable && viewProtractorCount
      }),
      popupContent: EngagementService.renderEngagementResourcesUsageCellContent(popupContentProps),
      popupDisabled: isOnlyShowingMainCells || (viewProtractorAvailable && !viewProtractorCount),
      popupTrigger: renderPopupTrigger(popupContentProps)
    });
  };

  const renderEngagedReferencesResourceCell = () => {
    const renderPopupTrigger = EngagementService.renderEngagementResourcesUsageCellTrigger;

    const popupContentProps = {
      engagementCellType: 'auxiliary',
      engagementData,
      engagementDataAvailableKey: 'viewResourcesAvailable',
      engagementDataCountKey: 'viewResourcesCount'
    };

    return renderEngagementRowAuxiliaryResourcesUsageCell({
      allowPopupComponent: true,
      className: classNames('engagement-body-label detail-resources-usage-body engagedReferences', {
        'cell-positive': viewResourcesAvailable && viewResourcesCount
      }),
      popupContent: EngagementService.renderEngagementResourcesUsageCellContent(popupContentProps),
      popupDisabled: isOnlyShowingMainCells || (viewResourcesAvailable && !viewResourcesCount),
      popupTrigger: renderPopupTrigger(popupContentProps)
    });
  };

  const renderEngagementRowAuxiliaryResourcesUsageCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementRowAuxiliaryResourcesUsageCells();
});
export default EngagementRowAuxiliaryResourcesUsageCells;

SatCoreRegister('EngagementRowAuxiliaryResourcesUsageCells', EngagementRowAuxiliaryResourcesUsageCells);
