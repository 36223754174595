/* eslint-disable max-len */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Container, Header, Loader, Pagination, Popup
} from 'semantic-ui-react';
import Modal from '../Modal';
import '../../css/LibraryShareAssessmentUsersModal.less';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { PATH_TYPES, VIEW_SELECTION } from '../../managers/NavigationManager';

import { DIALOG_NAMES } from '../../managers/DialogManager';

export default
@inject(
  'adminUsersManager',
  'dialogManager',
  'filteredHeaderTableManager',
  'navigationManager',
  'schoolManager',
  'userManager'
)
@observer
class LibraryShareAssessmentUsersModal extends Component {
  filteredHeaderTableKey='select-user-table-key';

  constructor(props) {
    super(props);

    this.FilteredHeaderTable = SatCoreComponent('FilteredHeaderTable');
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.BreadCrumbs = SatCoreComponent('BreadCrumbs');
    this.RegTypeInfoIconPopup = SatCoreComponent('RegTypeInfoIconPopup');

    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    rolesData: [],
    selectedUsers: [],
    allUsersSelected: false,
    fetchFinished:false
  })

  checkDefaultFilters = (tableFilters = null) => {
    const { rolesData } = this.state;
    // if there are existing filters start with them.
    const filters = tableFilters ? tableFilters : [];
    // if we aren't already filtering by role, add default roles.
    const existingRoleFilter = tableFilters.find(({ field }) => field === 'userRoles');
    if (!existingRoleFilter) {
      rolesData.map((role) => {
        if (role.value !== 0) {
          filters.push({field: 'userRoles', operator: 'contains', value: role.value})
        }
      });
    }
    return filters; 
  }

  componentDidMount = async () => {
    const {
      filteredHeaderTableManager, navigationManager
    } = this.props;
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('view')) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.USERS);
    }

    filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);

    await this.fetchRoles();
    await this.fetchUsers();
  }

  componentWillUnmount = async () => {
    const { adminUsersManager, filteredHeaderTableManager } = this.props;
    // clear the table data
    filteredHeaderTableManager.clearAll();
    adminUsersManager.clearUsers();
  }

  fetchData = async () => {
    const { } = this.props;
    let response = null;
    return response;
  }

  fetchUsers = async () => {
    const { adminUsersManager, filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    const filters = [];
    if (tableData && tableData.filters) {
      // clone filters array in case we have to add default roles
      tableData.filters.forEach(val => filters.push(Object.assign({}, val)));
      // check for adding default filters
      this.checkDefaultFilters(filters);
      this.setState({ fetchFinished: false });
      await adminUsersManager.getUsersByInstitution(
        null,
        tableData.activePage,
        tableData.column,
        tableData.direction,
        filters);
      this.setState({ fetchFinished: true });
    }
  }

  fetchRoles = async () => {
    const { adminUsersManager } = this.props;
    await adminUsersManager.fetchSatelliteRoles();
    this.createRolesData();
  }

  onPageChange = async (_event, pageInfo) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, pageInfo.activePage);
      await this.fetchUsers();
      this.setState({ allUsersSelected: false });
    }
  }

  handleSort = async (clickedColumn, filters) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      if (clickedColumn) {
        const newDirection = tableData.direction === 'ascending' ? 'descending' : 'ascending';
        filteredHeaderTableManager.setSortColumn(this.filteredHeaderTableKey, clickedColumn);
        filteredHeaderTableManager.setSortDirection(this.filteredHeaderTableKey, newDirection);
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
        await this.fetchUsers();
      } else {
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      }
    }
  }

  handleFilter = async (filters) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, 1);
      await this.fetchUsers();
      this.setState({ allUsersSelected: false });
    }
  }

  closeThisModal = () => {
    const { closeShareAssessmentUsersModal} = this.props;
    closeShareAssessmentUsersModal();
  }

  /** render content for role name popup, we hide non-satellite roles */
  renderRolesContent = (roleIdsArray) => {
    const { adminUsersManager } = this.props;
    const rolesArrayContent = [];
    if (roleIdsArray) {
      if (roleIdsArray.length > 0) {
        roleIdsArray.map((roleId, index) => {
          const role = adminUsersManager.roles.find((role) => role.id === roleId);
          if (role) {
            rolesArrayContent.push(<li key={index}>{role.name}</li>);
          }
        });
      }
    }
    return (
      <ul className='roles-list'>
        {rolesArrayContent}
      </ul>
    );
  }

  /** render names list for column, we hide non-satellite roles */
  validateRolesNames = (roleIdsArray) => {
    const { adminUsersManager } = this.props;
    const roleNamesArray = [];
    if (roleIdsArray) {
      if (roleIdsArray.length > 0) {
        roleIdsArray.map((roleId, index) => {
          const role = adminUsersManager.roles.find((role) => role.id === roleId);
          if (role) {
            roleNamesArray.push(role.name);
          }
        });
      }
    }
    return roleNamesArray;
  }

  createRolesData = async () => {
    const { adminUsersManager, userManager } = this.props;
    const rolesData = [{
      key: 'default',
      text: 'Choose a role...',
      value: 0
    }];
    adminUsersManager.roles.map((role) => {
      // don't include student users and if user is SA do not show DA users.
      if (!role.name.toLowerCase().includes('student') &&
            (userManager.isDistrictAdmin || (userManager.isSchoolAdmin && !role.name.toLowerCase().includes('district')))) {
            rolesData.push(
              {
                key: role.id,
                text: role.name,
                value: role.id
              }
            );
      }
    });
    this.setState({ rolesData });
  }

  toggleSelectUser = async (user) => {
    const { selectedUsers } = this.state;
    let newSelectedUsers = selectedUsers;
    let selectedUser = selectedUsers.find(selectedUser => selectedUser.id === user.id);
    if (!selectedUser) {
      newSelectedUsers.push(user);
    } else {
      selectedUser = newSelectedUsers.find(selectedUser => selectedUser.id === user.id);
      if (selectedUser) {
        if (selectedUsers) {
          newSelectedUsers = selectedUsers.filter(selectedUser => selectedUser.id !== user.id);
        }
        this.setState({ allUsersSelected: false });
      }
    }
    this.setState({ selectedUsers: newSelectedUsers });
  }

  toggleSelectAllUsers = async (event) => {
    const { adminUsersManager } = this.props;
    let { allUsersSelected, selectedUsers } = this.state;
    const isChecked = event.target.checked
    let newSelectedUsers = [];
    if (isChecked) {
      newSelectedUsers = selectedUsers;
      adminUsersManager.users.map((user) => {
        const selectedUser = selectedUsers.find(selectedUser => selectedUser.id === user.id);
        if (!selectedUser) {
          newSelectedUsers.push(user);
        }
      });
      allUsersSelected = true;
    } else {
      selectedUsers.map((user) => {
        const inAdminUsers = adminUsersManager.users.find(adminMapUser => adminMapUser.id === user.id);
        const inSelectedUsers = selectedUsers.find(selectedUser => selectedUser.id === user.id);
        if (inSelectedUsers && !inAdminUsers) {
          newSelectedUsers.push(user);
        }
      });
      allUsersSelected = false;
    }
    this.setState({ selectedUsers: newSelectedUsers, allUsersSelected});
  }

  showShareModal = (libraryCardData) => {
    const { dialogManager } = this.props;
    const { selectedUsers } = this.state;
    const selectedUserIds = selectedUsers.map(user => user.id);
    dialogManager.setOpenDialog(DIALOG_NAMES.SHARE_ASSESSMENT_MODAL, {
      closeShareAssessmentModal: this.closeShareAssessmentModal,
      libraryCardData: libraryCardData,
      selectedUsers: selectedUserIds
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.SHARE_ASSESSMENT_MODAL));
  }

  closeShareAssessmentModal = async (isSave) => {
    const { dialogManager } = this.props;
    if (isSave) {
      this.setState({ selectedUsers: [], allUsersSelected: false });
    }
    dialogManager.closeDialog(DIALOG_NAMES.SHARE_ASSESSMENT_MODAL);
  }

  userStatusFilterOptions = [
    {
      key: 'default',
      text: 'Choose a status...',
      value: 0
    },
    {
      key: 'active',
      text: 'Active',
      value: '1'
    },
    {
      key: 'inactive',
      text: 'Inactive',
      value: '0'
    }
  ];

  createHeaderData = (rolesData) => {
    const { allUsersSelected } = this.state;
    const headerData = [
      {
        /* Checkbox header column */
        label: '',
        sortKey: '',
        filterKey: '',
        columnCheckBoxOnClick: (event) => this.toggleSelectAllUsers(event),
        columnCheckBoxSelected: allUsersSelected
      },
      {
        label: 'Email/Username',
        sortKey: 'username',
        filterKey: 'username',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: 'First Name',
        sortKey: 'firstName',
        filterKey: 'firstName',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: 'Last Name',
        sortKey: 'lastName',
        filterKey: 'lastName',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: 'Primary School',
        sortKey: 'institutionName',
        filterKey: 'institutionName',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: 'User Role',
        sortKey: 'userRoles',
        filterKey: 'userRoles',
        filterLabel: 'Search for Role',
        filterComparator: 'contains',
        filterPopupType: 'optionSelection',
        filterDropdownOptions: rolesData,
        filterAutoCompleteCallback: null
      },
      {
        label: 'Status',
        sortKey: 'status',
        filterKey: 'status',
        filterLabel: '',
        filterPopupType: 'optionSelection',
        filterDropdownOptions: this.userStatusFilterOptions,
        filterAutoCompleteCallback: null
      }
    ];
    return headerData;
  }

  createBodyData = () => {
    const { adminUsersManager } = this.props;
    const { selectedUsers } = this.state;
    const selectedUserIds = selectedUsers.map(user => user.id);
    const userRows = [];
    adminUsersManager.users.map((user) => {
      // We have to get valid role names for the user's role ids that can be shown in satellite.
      // We need both a rendered UL list for the popup and a regular flat list of names for the column cell.
      const roleIdsArray = user.userRoleIds.split(',');
      const validRoleNames = this.validateRolesNames(roleIdsArray);
      let rolesListItemContent = null;
      let roleColumnCellText = '—';
      // if more than one role, get the popup content, and display the length in the cell
      if (validRoleNames.length > 1) {
        rolesListItemContent = this.renderRolesContent(roleIdsArray);
        roleColumnCellText = validRoleNames.length;
      } else if (validRoleNames.length == 1) {
        roleColumnCellText = validRoleNames[0];
      }
      const userRow = [
        {
          /* Checkbox column */
          columnName: null,
          columnText: '',
          columnClassName: '',
          columnOnClick: null,
          columnCheckBoxOnClick: () => this.toggleSelectUser(user),
          columnCheckBoxSelected: selectedUserIds.includes(user.id)
        },
        {
          /* Email/Username */
          columnName: 'username',
          columnText: user.username,
          columnClassName: 'truncate-long',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* First Name */
          columnName: 'firstName',
          columnText: user.firstName,
          columnClassName: 'truncate-short',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* Last Name */
          columnName: 'lastName',
          columnText: user.lastName,
          columnClassName: 'truncate-short',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* School */
          columnName: 'school',
          columnText: user.institutionName,
          columnClassName: 'truncate-long',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* User role */
          columnName: 'userRole',
          columnText: roleColumnCellText,
          columnClassName: (validRoleNames.length > 1) ? 'truncate-short clickableCell' : 'truncate-short',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click',
          tooltipContent: rolesListItemContent
        },
        {
          /* Status */
          columnName: 'enabled',
          columnText: (user.enabled) ? 'Active' : 'Inactive',
          columnClassName: 'truncate-short',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
      ];
      userRows.push(userRow);
    }, this);
    return userRows;
  }
  
  renderTable() {
    const { adminUsersManager, filteredHeaderTableManager, t } = this.props;
    const { loaded, totalPages, users } = adminUsersManager;
    const { fetchFinished, rolesData } = this.state;
    const { FilteredHeaderTable } = this;
    let tableBodyData = (loaded && fetchFinished) && users && users.length > 0 ? this.createBodyData() : [];
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    let activePage = 0;
    let column = '';
    let direction = '';
    let filters = [];
    if (tableData) {
      activePage = tableData.activePage;
      column = tableData.column;
      direction = tableData.direction;
      filters = tableData.filters;
    }
    return (
      <>
        <div className={'tableContainerHeader'}>
          <div className=''>{t('tableHeaderText')}</div>
          <div className='admin-users-table-spacer'>
            {/* placeholder */}
          </div>
          <div className='tableRightHeader'>
            <div className='tablePagination'>
              {tableBodyData.length > 0 &&
                <Pagination activePage={activePage} onPageChange={this.onPageChange} totalPages={totalPages} />}
            </div>
          </div>
        </div>
        <div className='tableContainer'>
          <FilteredHeaderTable
            allowClearAllFilters
            column={column}
            direction={direction}
            filtersData={filters}
            handleFilter={this.handleFilter}
            handleSort={this.handleSort}
            tableBodyData={tableBodyData}
            tableHeaderData={this.createHeaderData(rolesData)}
            tableId={this.filteredHeaderTableKey} />
        </div>
      </>
    )
  }
  
  render() {
    const { adminUsersManager, LibraryShareAssessmentUsersModalOpen, libraryCardData, t } = this.props;
    const { loaded, users } = adminUsersManager;
    const { selectedUsers, fetchFinished } = this.state;
    const selectedUserIds = selectedUsers.map(user => user.id);

    return (
      <Modal
        className='LibraryShareAssessmentUsersModal'
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={this.closeThisModal}
        open={LibraryShareAssessmentUsersModalOpen}
        size='fullscreen'>
        {/* style={{ overflow: 'auto', top: '-14px' }}> */}
        <Modal.Header className='modal-header'>
          <Header.Content className='modal-header-bar'>
            <Popup content={libraryCardData.title} trigger={(
              <span className='modal-header-label'>
                {`Share Assessment: ${libraryCardData.title}`}
              </span>
            )} wide />
            <div className='modal-header-buttons'>
            <Button
                basic
                disabled={!selectedUserIds || selectedUserIds.length < 1}
                className='cancelButton'
                onClick={() => this.showShareModal(libraryCardData)}
                primary>
                {t('shareButtonLabel')}
              </Button>
              <Button
                basic
                className='cancelButton'
                onClick={this.closeThisModal}
                primary>
                {t('Exit')}
              </Button>
            </div>
          </Header.Content>
        </Modal.Header>
        <div className='nav-separator' />
        <Modal.Content scrolling>
          <Container className="selectUsersView">
            {this.renderTable()}
            {(!loaded || !fetchFinished) && (
              <div className='null-state-panel'>
                <Loader key={0} active inline />
              </div>
            )}
            {((loaded && fetchFinished) && !users || users.length < 1) && (
              <div className='null-state-panel'>
                No Data Found
              </div>
            )}
          </Container>
        </Modal.Content>
      </Modal>
    );
  }
}

SatCoreRegister('LibraryShareAssessmentUsersModal', LibraryShareAssessmentUsersModal);
