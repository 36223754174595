import React, { useContext, useEffect, useState } from 'react';

import { MobXProviderContext } from 'mobx-react';

import { Button, Icon } from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/MigrateStudentDataModal.less';
import UtilityService from '../../services/UtilityService';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

const ModalBanner = SatCoreComponent('ModalBanner');

const MigrateStudentDataModal = (props) => {
  const { activityList, message, migrateUserData, migrateStudentDataModalOpen, modalTitle, close, t } = props;

  const {
    contentManager, 
  } = useContext(MobXProviderContext);
  
  const SatCoreLoader = SatCoreComponent('SatCoreLoader');

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  useEffect(() => {

  }, []);

  return (
    <Modal
      className='migrate-student-data-modal'
      closeOnDimmerClick={true}
      closeOnEscape={true}
      open={migrateStudentDataModalOpen}>
      <ModalBanner
        onClose={close} title={modalTitle} />
        <div className='descriptionText'>{message}</div>
        <Modal.Content className='' scrolling>
          {activityList.map((activity, index) => {
            return (
              <div key={index} className='user'>
                {activity.nickname || activity.name}
              </div>
            )
          })}
        </Modal.Content>
          
      <Modal.Actions>
        <Button basic primary className='modal-close-button' onClick={close}
          type='button'>{t('modalCloseLabel')}
        </Button>
        <Button primary className='modal-close-button' onClick={migrateUserData} 
          type='button'>{t('migrateDataLabel')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default MigrateStudentDataModal;
SatCoreRegister('MigrateStudentDataModal', MigrateStudentDataModal);
