import React from 'react';
import { SatCoreRegister } from '../SatCoreRegistry';

const NoAssignmentsMessage = (props) => (
  <div className='no-assignments-wrapper'>
    <div className='no-assignments-img' />
    <div className='no-assignments-message'>{props.message}</div>
  </div>
);

export default NoAssignmentsMessage;

SatCoreRegister('NoAssignmentsMessage', NoAssignmentsMessage);
