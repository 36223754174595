import Auth from '../managers/AuthManager';
import { registerClass } from '../SatCoreRegistry';

export default class NotificationService {
  static getNotificationAttachmentUrl = (attachment) => {
    const attachmentUrl = attachment?.id ? (
      `${Auth.ecms}/api/downloadNotificationAttachment?notificationId=${attachment.id}`
    ) : '';
    return attachmentUrl;
  }
}

registerClass('NotificationService', NotificationService);
