import React, { useContext, useEffect, useState } from 'react';

import { toJS } from 'mobx';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { kebabCase } from 'lodash';

import '../../css/reports/ReportHideColumnsModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import ReportIdentityService from '../../services/reports/ReportIdentityService';
import { REPORT_FACULTY_TABLE_CELL_LABEL_KEY } from '../../services/reports/ReportConstants';

const ReportHideColumnsModal = observer((props = {}) => {
  const {
    buttonKeyCancelLabel = '',
    buttonKeyConfirmLabel = 'hideColumns',
    className = 'ReportHideColumnsModal',
    closeOnDimmerClick = false,
    closeOnEscape = true,
    disableKeystroke = false,
    disableMove = false,
    disableResize = true,
    initHeight,
    initWidth,
    minHeight = 520,
    minWidth = 465,
    onOpen = () => {},
    onRequestClose,
    open: isOpen,
    overlay = 'medium',
    t, top = window.innerHeight / 6
  } = props;

  const {
    reportIdentityManager,
    reportTableManager
  } = useContext(MobXProviderContext);

  const {
    activeReportFacultyType,
    isFacultySchoolReport,
    reportInfoClassNames
  } = reportIdentityManager;

  const [isLoadingReportHideColumnsModal, setIsLoadingReportHideColumnsModal] = useState(false);

  // TODO unused
  // const [originalHiddenReportTableFacultyCellsMap, setOriginalHiddenReportTableFacultyCellsMap] = useState(new Map());

  const GenericModal = SatCoreComponent('GenericModal');
  const SCCheckbox = SatCoreComponent('SCCheckbox');

  // TODO unused
  // useEffect(() => {
  //   const hiddenReportTableFacultyCellsMap = toJS(reportTableManager.hiddenReportTableFacultyCellsMap);
  //   setOriginalHiddenReportTableFacultyCellsMap(new Map(hiddenReportTableFacultyCellsMap));
  // }, []);

  const handleChangeReportFacultyColumnOption = (event, cellObj = {}) => {
    const facultyId = cellObj?.id;
    if (!facultyId) {
      return;
    }
    const checked = !!event?.target?.checked;

    switch (facultyId) {
    case 'selectAll':
      if (checked) {
        for (const cellObj of toJS(reportTableManager.reportTableFacultyCellsConfig)) {
          reportTableManager.addToHiddenReportTableFacultyCellsMap(cellObj);
        }
      }
      break;
    default:
      if (checked) {
        reportTableManager.addToHiddenReportTableFacultyCellsMap(cellObj);
      } else {
        reportTableManager.removeFromHiddenReportTableFacultyCellsMap(cellObj?.id);
      }
      break;
    }
  };

  const handleCloseReportHideColumnsModal = async (_event, actionButtonData = {}) => {
    setIsLoadingReportHideColumnsModal(true);

    if (actionButtonData?.className?.includes?.(kebabCase(buttonKeyConfirmLabel))) {
      // user confirmed changes via 'Hide Columns' action button
      props.onClose?.();

      setIsLoadingReportHideColumnsModal(false);
    } else {
      // // TODO uncomment if we decide to restore original selections upon closing modal without confirmation
      // // user did not confirm changes, so restore original selections
      // reportTableManager.setHiddenReportTableFacultyCellsMap(originalHiddenReportTableFacultyCellsMap);

      props.onClose?.();

      // user did not confirm changes, so clear all selections
      reportTableManager.clearHiddenReportTableFacultyCellsMap();

      setIsLoadingReportHideColumnsModal(false);
    }
  };

  const renderSelectAllReportFacultyColumnsOption = () => {
    const cellObj = {
      id: 'selectAll'
    };
    const checkboxLabel = t(cellObj.id);
    return (
      <div className={classNames('report-hide-columns-modal-faculty-option-row', reportInfoClassNames)}>
        <div className={classNames('report-faculty-option-checkbox-wrapper', reportInfoClassNames)}>
          <SCCheckbox
            checked={reportTableManager.isAllHiddenReportTableFacultyCells}
            disabled={reportTableManager.isAllHiddenReportTableFacultyCells}
            hoverAdditionalClassNames='report-faculty-option-checkbox-popup'
            hoverLabelContent={checkboxLabel}
            hoverMouseEnterDelay={1000}
            label={checkboxLabel}
            onChange={(event) => handleChangeReportFacultyColumnOption(event, cellObj)}
            useHoverLabel
            value='selectAll' />
        </div>
      </div>
    );
  };

  const renderReportFacultyColumnOption = (cellObj) => {
    const facultyLabelPropName = REPORT_FACULTY_TABLE_CELL_LABEL_KEY[activeReportFacultyType.toUpperCase()];

    const facultyId = cellObj?.id;

    const faculty = cellObj?.originalData;
    if (!facultyLabelPropName || !facultyId || !faculty) {
      return null;
    }
    let facultyName;
    if (facultyLabelPropName === REPORT_FACULTY_TABLE_CELL_LABEL_KEY.CLASSROOM) {
      facultyName = ReportIdentityService.getReportStudentName(faculty) || t('unknownFacultyName');
    } else {
      facultyName = faculty[facultyLabelPropName] || t('unknownFacultyName');
    }

    const leadTeacherName = isFacultySchoolReport ? (
      ReportIdentityService.getReportLeadTeacherName(faculty) || t('unknownLeadTeacher')
    ) : undefined;

    let checkboxLabel;
    if (leadTeacherName) {
      checkboxLabel = `${leadTeacherName} ‐ ${facultyName}`;
    } else {
      checkboxLabel = `${facultyName}`;
    }

    return (
      <div key={facultyId}
        className={classNames('report-hide-columns-modal-faculty-option-row', reportInfoClassNames)}>
        <div className={classNames('report-faculty-option-checkbox-wrapper', reportInfoClassNames)}>
          <SCCheckbox
            checked={reportTableManager.hiddenReportTableFacultyCellsMap.has(facultyId)}
            hoverAdditionalClassNames='report-faculty-option-checkbox-popup'
            hoverLabelContent={checkboxLabel}
            hoverMouseEnterDelay={1000}
            label={checkboxLabel}
            onChange={(event) => handleChangeReportFacultyColumnOption(event, cellObj)}
            useHoverLabel
            value={facultyId} />
        </div>
      </div>
    );
  };

  const renderFacultyColumnOptions = () => {
    const facultyLabelPropName = REPORT_FACULTY_TABLE_CELL_LABEL_KEY[activeReportFacultyType.toUpperCase()];

    return (
      <>
        <div className='report-hide-columns-modal-body-top'>
          <div className='report-hide-columns-modal-body-top-left'>
            <div className='report-hide-columns-modal-body-header-label'>
              {t(`${facultyLabelPropName}HeaderLabel`)}
            </div>
          </div>
          <div className='report-hide-columns-modal-body-top-mid'>
            {/* placeholder */}
          </div>
          <div className='report-hide-columns-modal-body-top-right'>
            <div className='report-hide-columns-reset-all'
              onClick={() => {
                reportTableManager.clearHiddenReportTableFacultyCellsMap();
              }}>
              {t('resetAll')}
            </div>
          </div>
        </div>
        <div className='report-hide-columns-modal-faculty-options'>
          {renderSelectAllReportFacultyColumnsOption()}
          {toJS(reportTableManager.reportTableFacultyCellsConfig)?.map((cellObj) => {
            return renderReportFacultyColumnOption(cellObj);
          })}
        </div>
      </>
    );
  };

  const headerMsg = t('hideColumnsHeaderMsg');

  return (
    <GenericModal
      bodyMsg={renderFacultyColumnOptions}
      buttonKeyCancelLabel={buttonKeyCancelLabel}
      buttonKeyConfirmLabel={buttonKeyConfirmLabel}
      className={className}
      closeOnDimmerClick={closeOnDimmerClick}
      closeOnEscape={closeOnEscape}
      disableKeystroke={disableKeystroke}
      disableMove={disableMove}
      disableResize={disableResize}
      headerMsg={headerMsg}
      initHeight={initHeight || minHeight}
      initWidth={initWidth || minWidth}
      isLoading={isLoadingReportHideColumnsModal}
      isOpen={isOpen}
      onClose={handleCloseReportHideColumnsModal}
      onOpen={onOpen}
      onRequestClose={onRequestClose}
      overlay={overlay}
      top={top} />
  );
});

export default ReportHideColumnsModal;

SatCoreRegister('ReportHideColumnsModal', ReportHideColumnsModal);
