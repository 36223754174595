import { action, computed, makeObservable, observable } from 'mobx';

import { getRegisteredClass } from '../SatCoreRegistry';

import {
  INSTITUTION_DROPDOWN_MODE,
  ORIGINAL_SELECTED_DROPDOWN_INSTITUTION_ID_OBSERVABLE_OBJ
} from '../services/UserInstitutionDropdownConstants';

import Auth from './AuthManager';
import userManager from './UserManager';

export class AdminUsersManager {
  @observable users = [];

  @observable roles = [];

  @observable loaded = false;

  /**
   * current (selected) user via a specific action, typically within the admin Users tab.
   *
   * NOTE: this is **not** the same as the logged in user - that is tracked in `userManager`.
   */
  @observable currentUser = {};

  @observable selectedDropdownInstitutionIdObservableObj = { ...ORIGINAL_SELECTED_DROPDOWN_INSTITUTION_ID_OBSERVABLE_OBJ };

  /** allows the ability to provide computed arrays: `institutionIds` and `institutions` */
  @observable institutionsForSelectedUserMap = new Map();

  @observable currentUserEnrolledClassrooms = {};

  @observable currentUserTeachingClassrooms = {};

  @observable totalPages = 0;

  USERS_FETCH_PAGE_SIZE = 12;

  timezoneArray = [
    { key: 'Etc/GMT+12', value: 'Etc/GMT+12', text: 'Etc/GMT+12', longtext: 'Eniwetok, Kwajalein' },
    { key: 'US/Samoa', value: 'US/Samoa', text: 'US/Samoa', longtext: 'Midway Island, Samoa' },
    { key: 'US/Hawaii', value: 'US/Hawaii', text: 'US/Hawaii', longtext: 'Hawaii' },
    { key: 'US/Alaska', value: 'US/Alaska', text: 'US/Alaska', longtext: 'Alaska' },
    { key: 'US/Pacific', value: 'US/Pacific', text: 'US/Pacific', longtext: 'Pacific Time (US and Canada)' },
    { key: 'US/Mountain', value: 'US/Mountain', text: 'US/Mountain', longtext: 'Mountain Time (US and Canada)' },
    { key: 'US/Central', value: 'US/Central', text: 'US/Central', longtext: 'Central Time (US and Canada), Mexico City' },
    { key: 'US/Eastern', value: 'US/Eastern', text: 'US/Eastern', longtext: 'Eastern Time (US and Canada), New York, Bogota, Lima' },
    { key: 'Atlantic/Bermuda', value: 'Atlantic/Bermuda', text: 'Atlantic/Bermuda', longtext: 'Atlantic Time (Canada), Caracas, La Paz' },
    { key: 'Canada/Newfoundland', value: 'Canada/Newfoundland', text: 'Canada/Newfoundland', longtext: 'Newfoundland' },
    { key: 'America/Araguaina', value: 'America/Araguaina', text: 'America/Araguaina', longtext: 'Brazil, Buenos Aires, Georgetown' },
    { key: 'Atlantic/South_Georgia', value: 'Atlantic/South_Georgia', text: 'Atlantic/South_Georgia', longtext: 'Mid-Atlantic' },
    { key: 'Atlantic/Azores', value: 'Atlantic/Azores', text: 'Atlantic/Azores', longtext: 'Azores, Cape Verde Islands' },
    { key: 'UTC', value: 'UTC', text: 'UTC', longtext: '(GMT) Western Europe Time, London, Lisbon, Casablanca' },
    { key: 'ECT', value: 'ECT', text: 'ECT', longtext: 'Brussels, Copenhagen, Madrid, Paris' },
    { key: 'Africa/Maseru', value: 'Africa/Maseru', text: 'Africa/Maseru', longtext: 'Kaliningrad, South Africa' },
    { key: 'Asia/Baghdad', value: 'Asia/Baghdad', text: 'Asia/Baghdad', longtext: 'Baghdad, Riyadh, Moscow, St. Petersburg' },
    { key: 'Asia/Dubai', value: 'Asia/Dubai', text: 'Asia/Dubai', longtext: 'Abu Dhabi, Muscat, Baku, Tbilisi' },
    { key: 'Indian/Maldives', value: 'Indian/Maldives', text: 'Indian/Maldives', longtext: 'Ekaterinburg, Islamabad, Karachi, New Delhi, Tashkent' },
    { key: 'Asia/Almaty', value: 'Asia/Almaty', text: 'Asia/Almaty', longtext: 'Almaty, Dhaka, Colombo' },
    { key: 'Asia/Bangkok', value: 'Asia/Bangkok', text: 'Asia/Bangkok', longtext: 'Bangkok, Hanoi, Jakarta' },
    { key: 'Australia/Perth', value: 'Australia/Perth', text: 'Australia/Perth', longtext: '(Beijing, Perth, Singapore, Hong Kong' },
    { key: 'Asia/Tokyo', value: 'Asia/Tokyo', text: 'Asia/Tokyo', longtext: '(Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
    { key: 'Australia/ACT', value: 'Australia/ACT', text: 'Australia/ACT', longtext: 'Eastern Australia, Guam, Vladivostok' },
    { key: 'Pacific/Norfolk', value: 'Pacific/Norfolk', text: 'Pacific/Norfolk', longtext: 'Norfolk, Magadan, Solomon Islands, New Caledonia' },
    { key: 'Pacific/Fiji', value: 'Pacific/Fiji', text: 'Pacific/Fiji', longtext: 'Auckland, Wellington, Fiji, Kamchatka' }];

  constructor() {
    makeObservable(this);
  }

  @action clearAll() {
    this.currentUser = {};
    this.institutionsForSelectedUserMap = new Map();
    this.loaded = false;
    this.roles.clear();
    this.selectedDropdownInstitutionIdObservableObj = { ...ORIGINAL_SELECTED_DROPDOWN_INSTITUTION_ID_OBSERVABLE_OBJ };
    this.totalPages = 0;
    this.users.clear();
  }

  @action clearUsers() {
    this.users.clear();
  }

  @action initUsers(response) {
    this.users.clear();
    this.setUsers(response);
  }

  @action initRoles(response) {
    this.roles.clear();
    this.setRoles(response);
  }

  getTotalPages = () => this.totalPages

  @action setUsers = (users) => {
    this.users = users;
  }

  @action setUser = (currentUser) => {
    this.currentUser = currentUser;
  }

  @action setUserName = (userName) => {
    this.currentUser.username = userName;
  }

  @action setFirstName = (firstName) => {
    this.currentUser.firstName = firstName;
  }

  @action setLastName = (lastName) => {
    this.currentUser.lastName = lastName;
  }

  @action setPassword = (password) => {
    this.currentUser.password = password;
  }

  @action setTimezoneId = (timezoneId) => {
    this.currentUser.timezoneId = timezoneId;
  }

  @action setEnabled = (enabled) => {
    this.currentUser.enabled = enabled;
  }

  @action setRoles = (roles) => {
    this.roles = roles;
  }

  @action setCurrentUserEnrolledClassrooms = (enrolledClassrooms) => {
    this.currentUserEnrolledClassrooms = enrolledClassrooms;
  }

  @action setCurrentUserTeachingClassrooms = (teachingClassrooms) => {
    this.currentUserTeachingClassrooms = teachingClassrooms;
  }

  @action setLoaded(toggle) {
    if (toggle) {
      this.loaded = toggle;
    }
  }

  /**
   * @param {string} institutionId
   * @param {import('../services/UserInstitutionDropdownConstants').InstitutionDropDownMode} mode
   */
  @action setSelectedDropdownInstitutionIdForSelectedUser = (institutionId, mode = INSTITUTION_DROPDOWN_MODE.ALL_MODES) => {
    const { ADD_CLASSROOM_MODE, ALL_MODES, SELECT_CLASSROOM_MODE, SELECT_REPORT_CLASSROOM_MODE } = INSTITUTION_DROPDOWN_MODE;
    if (mode === ALL_MODES) {
      this.selectedDropdownInstitutionIdObservableObj[ADD_CLASSROOM_MODE] = institutionId;
      this.selectedDropdownInstitutionIdObservableObj[SELECT_CLASSROOM_MODE] = institutionId;
      this.selectedDropdownInstitutionIdObservableObj[SELECT_REPORT_CLASSROOM_MODE] = institutionId;
    } else {
      this.selectedDropdownInstitutionIdObservableObj[mode] = institutionId;
    }
  }

  @action addInstitutionsForSelectedUserToMap = (institutions = []) => {
    for (const institution of institutions) {
      this.institutionsForSelectedUserMap.set(institution.id, institution);
    }
  }

  @action getUsersByInstitution = async (institutionId, page = 1, sortColumn = null, sortDirection = 'ascending', filters) => {
    try {
      const UserService = getRegisteredClass('UserService');
      const hasMultipleInstitutions = UserService.hasMultipleInstitutions();
      this.setLoaded(false);
      let serverDirection;
      if (sortColumn === 'status') {
        /* the data for 'status' column is boolean. it is derived from user.enabled */
        /* 0/false = 'Inactive', 1/true = 'Active' */
        /* in ascending order, we get the false values first (since 0 comes before 1) */
        /* so here for 'status', we are requesting the opposite sortDirection */
        /* this makes the 'status' UI sort icon appear as expected */
        serverDirection = sortDirection.includes('asc') ? 'desc' : 'asc';
      } else {
        serverDirection = sortDirection.includes('asc') ? 'asc' : 'desc';
      }
      const pageSize = this.USERS_FETCH_PAGE_SIZE;
      const usePage = (page - 1) * pageSize;
      let url = `${Auth.ecms}/api/viewInstitutionUsers?skip=${usePage}&pageSize=${pageSize}`;
      if (sortColumn) {
        url += `&sort[0][field]=${sortColumn}&sort[0][dir]=${serverDirection}`;
      }
      if (filters && filters.length > 0) {
        filters.forEach((filter, index) => {
          if (filter.field && filter.operator && filter.value && filter.value.length > 0) {
            // eslint-disable-next-line max-len
            url += `&filter[filters][${index}][field]=${filter.field}&filter[filters][${index}][operator]=${filter.operator}&filter[filters][${index}][value]=${encodeURIComponent(filter.value)}`;
          }
        });
      }
      if (institutionId) {
        url += `&institutionIds=${institutionId}`;
      } else if (userManager.isSchoolAdmin && hasMultipleInstitutions) {
        url += `&institutionIds=${userManager.institutionIds.toString()}`;
      }
      const response = await Auth.fetch(url,
        { method: 'GET' }
      );
      if (response.status === 'SUCCESS') {
        this.initUsers(response.data);
        this.totalPages = Math.ceil(response.pageTotal / pageSize);
        if (this.users.length < +response.pageTotal) {
          this.setLoaded(true);
          return true;
        }
        this.setLoaded(true);
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action async fetchSatelliteRoles() {
    try {
      const apiUrlPrefix = 'api/viewSatelliteRoles';
      const apiUrl = `${Auth.ecms}/${apiUrlPrefix}`;
      const response = await Auth.fetch(`${apiUrl}`, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        this.initRoles(response.data, response);
        this.satelliteRolesLoaded = true;
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action fetchUser = async (userId) => {
    try {
      const result = await Auth.fetch(`${Auth.ecms}/api/viewUser?userId=${userId}`, {
        method: 'GET'
      });
      if (result && result.status && result.status === 'SUCCESS') {
        if (result.data && result.data.length > 0) {
          const user = result.data[0];
          const UserService = getRegisteredClass('UserService');
          const primaryInstitution = await UserService.fetchSingularUserInstitution(user.institutionId);
          const institutions = await UserService.fetchUserInstitutions(user);
          this.institutionsForSelectedUserMap.clear();

          this.addInstitutionsForSelectedUserToMap([...institutions, primaryInstitution]);
          this.setUser(user);
        }
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action saveChanges = async (input) => {
    try {
      const body = {
        firstName: input.firstName,
        lastName: input.lastName,
        userId: input.userId
      };

      if (input.enabled !== undefined) {
        body.enabled = input.enabled;
      }

      if (input.timezoneId) {
        body.timezoneId = input.timezoneId;
      }

      if (input.username) {
        body.username = input.username;
      }

      if (input.id) {
        body.id = input.id;
      }

      if (input.password) {
        body.password = input.password;
      }

      const data = await Auth.fetch(`${Auth.ecms}/api/updateUserProfile`, {
        body,
        method: 'POST'
      });

      if (data && data.status === 'SUCCESS') {
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  @action fetchUserClassrooms = async (userId) => {
    try {
      const result = await Auth.fetch(`${Auth.ecms}/api/viewUserClassrooms?userId=${userId}`, {
        method: 'GET'
      });
      if (result && result.status && result.status === 'SUCCESS') {
        if (result.data && result.data.enrolledClassrooms) {
          this.setCurrentUserEnrolledClassrooms(result.data.enrolledClassrooms);
        }
        if (result.data && result.data.teachingClassrooms) {
          this.setCurrentUserTeachingClassrooms(result.data.teachingClassrooms);
        }
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action validateUserEmail = (email) => {
    const emailRegexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const valid = emailRegexp.test(email);
    return valid;
  }

  @computed get hasMultipleInstitutionsForSelectedUser() {
    return this.institutionsForSelectedUserMap.size > 1;
  }

  @computed get institutionDropdownOptionsForSelectedUser() {
    const keys = this.institutionIdsForSelectedUser;
    const options = keys.map((institutionId) => {
      const institution = this.institutionsForSelectedUserMap.get(institutionId);
      return {
        text: institution.name,
        value: institution.id
      };
    }).sort((a, b) => a.text?.localeCompare(b.text, 'en', { numeric: true }));
    return options;
  }

  @computed get institutionIdsForSelectedUser() {
    return Array.from(this.institutionsForSelectedUserMap.keys()).sort((a, b) => {
      return a.name?.localeCompare(b.name, 'en', { numeric: true });
    });
  }

  @computed get institutionsForSelectedUser() {
    return Array.from(this.institutionsForSelectedUserMap.values()).sort((a, b) => {
      return a.name?.localeCompare(b.name, 'en', { numeric: true });
    });
  }
}

export default new AdminUsersManager();
