import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';
import { Button, Form, Message } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import Modal from '../Modal';

import '../../css/ReactivateClassModal.less';

const ReactivateClassModal = observer((props) => {
  const { open, close, t, classroom } = props;
  const { timezoneStartDate: startDate } = classroom;
  const { classroomManager } = useContext(MobXProviderContext);
  const ModalBanner = useMemo(() => SatCoreComponent('ModalBanner'), []);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const validate = useCallback(() => {
    if (value === null || value === '') {
      setError(t('endDateRequiredError', 'End Date is required!'));
      return false;
    }
    if (startDate !== null && startDate !== '') {
      const endRealDate = new Date(value);
      const startRealDate = new Date(startDate);
      if (endRealDate < startRealDate) {
        setError(t('endDateLessError', 'End date should be after start date'));
        return false;
      }
    }
    return true;
  }, [startDate, value, t]);

  const submit = useCallback(async () => {
    setSubmitted(true);
    if (validate()) {
      const response = await classroomManager.updateClassroomObject({...classroom, archived: 'false', startDate, endDate: value});
      response ? close(null, null, true) : setError(t('generalError', 'Something has gone wrong.'));
    }
    setSubmitted(false);
  }, [validate, classroom, startDate, value, t]);

  return (
    <Modal
      className='reactivate-class-modal'
      closeOnDimmerClick={false}
      closeOnEscape={true}
      onClose={close}
      open={open}>
      <ModalBanner onClose={close} title={t('title', 'Update Class End Date to Reactivate')} />
      <Modal.Content>
        <Form>
          <Form.Field>
            <DateInput
              animation='false'
              dateFormat='MM/DD/YYYY'
              duration={0}
              iconPosition='right'
              label='End Date'
              name='endDate'
              onChange={(e, { value }) => setValue(value)}
              placeholder='End Date'
              value={value}
              />
            {error && <Message content={error} error visible={true} />}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={close} primary>Cancel</Button>
        <Button primary disabled={submitted} onClick={submit}>Reactivate</Button>
      </Modal.Actions>
    </Modal>
  );
});

export default ReactivateClassModal;
SatCoreRegister('ReactivateClassModal', ReactivateClassModal);
