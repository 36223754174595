import { action, computed, makeObservable, observable } from 'mobx';

import { getRegisteredClass } from '../../SatCoreRegistry';

import Auth from '../AuthManager';
import reportIdentityManager from './ReportIdentityManager';

import { getSessionStorageItem, setSessionStorageItem } from '../../utils';

const REPORT_COURSE_ENDPOINTS = {
  FETCH_REPORT_COURSE_NAMES_BY_INSTITUTION: '/api/viewInstitutionCourses'
};

export class ReportCourseManager {
  constructor() {
    makeObservable(this);
  }

  @observable loadingReportCourseNames = false;

  @observable reportCourseNamesByInstitutionMap = new Map();

  @observable selectedReportCourseContentItemId = getSessionStorageItem('selectedReportCourseContentItemId');
  @observable selectedReportCourseName = getSessionStorageItem('selectedReportCourseName');

  @action clearAllExceptSession = () => {
    this.setLoadingReportCourseNames(false);
    this.setReportCourseNamesByInstitutionMap(null);
  }

  @action clearSession = () => {
    this.setSelectedReportCourseInfo(null, null);
  }

  @action fetchReportCourseNamesByInstitutionMap = async ({
    institutionId = null,
    shouldFetch = true,
    shouldSetFirstInMapAsSelectedIfNoneSelectedYet = false
  } = {}) => {
    try {
      if (!shouldFetch) {
        return;
      }
      this.setLoadingReportCourseNames(true);
      this.setReportCourseNamesByInstitutionMap(null);
      institutionId = institutionId || reportIdentityManager.activeReportInstitutionId;

      let apiUrl = `${Auth.ecms}${REPORT_COURSE_ENDPOINTS.FETCH_REPORT_COURSE_NAMES_BY_INSTITUTION}`;
      apiUrl += `?institutionId=${institutionId}`;
      apiUrl += '&reactErrorType=true';

      const response = await Auth.fetch(apiUrl, { method: 'GET' });

      if (response?.status === 'SUCCESS') {
        this.setReportCourseNamesByInstitutionMap(response.courses, {
          shouldSetFirstInMapAsSelectedIfNoneSelectedYet
        });
      } else {
        console.error(response);
      }
      this.setLoadingReportCourseNames(false);
    } catch (error) {
      this.setLoadingReportCourseNames(false);
      console.error(error);
    }
  }

  @action setLoadingReportCourseNames = (toggle) => {
    this.loadingReportCourseNames = toggle;
  }

  @action setReportCourseNamesByInstitutionMap = async (reportCourseInfoObj, {
    shouldSetFirstInMapAsSelectedIfNoneSelectedYet = false
  } = {}) => {
    if (!reportCourseInfoObj) {
      this.reportCourseNamesByInstitutionMap = new Map();
    } else {
      const courseNamesMap = new Map();
      for (const prop in reportCourseInfoObj) {
        courseNamesMap.set(prop, reportCourseInfoObj[prop]);
      }
      const selectedKey = this.selectedReportCourseContentItemId;
      if (shouldSetFirstInMapAsSelectedIfNoneSelectedYet || selectedKey) {
        const ReportTypeService = getRegisteredClass('ReportTypeService');
        if ((!selectedKey && shouldSetFirstInMapAsSelectedIfNoneSelectedYet) || courseNamesMap.size === 1) {
          // set first in map as selected if `!selectedKey && shouldSetFirstInMapAsSelectedIfNoneSelectedYet`,
          // OR if there is currently only one course in the map
          const [courseContentItemId, courseName] = Array.from(courseNamesMap.entries())[0];
          this.setSelectedReportCourseInfo(courseContentItemId, courseName);

          await ReportTypeService.initReportTableData();
          this.reportCourseNamesByInstitutionMap = courseNamesMap;
        } else if (!courseNamesMap.has(selectedKey)) {
          // clear out the current selected course since it is no longer in the map
          // eslint-disable-next-line no-alert
          alert('Note: The report for the selected course was not found.');
          this.setSelectedReportCourseInfo(null, null);
          await ReportTypeService.initReportTableData();
          this.reportCourseNamesByInstitutionMap = courseNamesMap;
        } else {
          this.reportCourseNamesByInstitutionMap = courseNamesMap;
        }
      } else {
        this.reportCourseNamesByInstitutionMap = courseNamesMap;
      }
    }
  };

  @action removeCourseEntryFromMap = (courseContentItemId) => {
    this.reportCourseNamesByInstitutionMap.delete(courseContentItemId);
  }

  @action setSelectedReportCourseInfo = (courseContentItemId, courseName) => {
    setSessionStorageItem('selectedReportCourseContentItemId', courseContentItemId);
    setSessionStorageItem('selectedReportCourseName', courseName);

    this.selectedReportCourseContentItemId = courseContentItemId || null;
    this.selectedReportCourseName = courseName || null;
  }

  @computed get reportCourseDropdownOptions() {
    const keys = this.reportCourseContentItemIds;
    const options = keys.map((courseContentItemId) => {
      return {
        text: this.reportCourseNamesByInstitutionMap.get(courseContentItemId),
        value: courseContentItemId
      };
    });
    return options;
  }

  @computed get reportCourseContentItemIds() {
    return Array.from(this.reportCourseNamesByInstitutionMap.keys());
  }

  @computed get reportCourseNames() {
    return Array.from(this.reportCourseNamesByInstitutionMap.values());
  }
}

export default new ReportCourseManager();
