import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../css/Footer.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import BannerService from '../services/BannerService';

const Footer = observer((props) => {
  const { t } = props;

  let { footerLinkMap, logoInfo } = props;

  const { /* managers */ } = useContext(MobXProviderContext);

  footerLinkMap = footerLinkMap || BannerService.generateFooterLinkMap();
  logoInfo = logoInfo || BannerService.getLogoInfo();

  const Logo = SatCoreComponent('Logo');

  return (
    <div className='satellite-footer'>
      <div className='footer-left'>
        {Array.from(footerLinkMap?.entries() || []).map(([linkKey, link]) => {
          return (
            <div key={linkKey} className='footer-link-wrapper'>
              <a className='footer-link'
                href={link}
                rel='noreferrer noopener'
                target='_blank'>
                {t(linkKey)}
              </a>
            </div>
          );
        })}
      </div>
      <div className='footer-mid'>
        {/* placeholder */}
      </div>
      <div className='footer-right'>
        {logoInfo && (
          <Logo
            ariaLabel='Logo'
            logoImg={logoInfo.logoImg}
            logoUrl={logoInfo.logoUrl}
            navButton />
        )}
      </div>
    </div>
  );
});
export default Footer;

SatCoreRegister('Footer', Footer);
