import React, { Fragment } from 'react';
import withAuth from './hoc/withAuth';
import { withTranslation } from './i18n';

const SatCoreRegistry = new Map();

export function SatCoreRegister(key, SatComponent) {
  if (key === 'MainView') {
    SatCoreRegistry.set(key, withAuth(SatComponent));
  } else {
    SatCoreRegistry.set(key, withTranslation(key)(SatComponent));
  }
}

export function SatCoreComponent(key) {
  const SatComponent = SatCoreRegistry.get(key);
  if (!SatComponent) {
    return Fragment;
  }

  return class extends React.Component {
    displayName = key;

    render() {
      // Wraps the input component in a container, without mutating it. Good!
      return <SatComponent {...this.props} />;
    }
  };
}

export function registerClass(key, SatComponent) {
  SatCoreRegistry.set(key, SatComponent);
}

export function getRegisteredClass(key) {
  const SatComponent = SatCoreRegistry.get(key);
  return SatComponent;
}
