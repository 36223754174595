import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ReactHtmlParser from 'react-html-parser';

import { Button, Container, Loader, Pagination, Segment, Table } from 'semantic-ui-react';

import '../css/AdminProductsView.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';
import { DIALOG_NAMES } from '../managers/DialogManager';
import { VIEW_SELECTION } from '../managers/NavigationManager';

import UserService from '../services/UserService';

import { generateUrl, removeUrlParams } from '../utils';

import Modal from '../components/Modal';

export default @inject(
  'courseManager',
  'dialogManager',
  'filteredHeaderTableManager',
  'navigationManager',
  'productManager',
  'schoolManager',
  'userManager'
)
@observer
class AdminProductsView extends Component {
  filteredHeaderTableKey='admin-products-table-key';

  constructor(props) {
    super(props);

    this.FilteredHeaderTable = SatCoreComponent('FilteredHeaderTable');
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.BreadCrumbs = SatCoreComponent('BreadCrumbs');

    this.state = {
      headerData: [],
      institutionId: null,
      showLicenseDataOpen: false
    };
  }

  async componentDidMount() {
    const {
      filteredHeaderTableManager, history, navigationManager, schoolManager
    } = this.props;

    const urlParams = new URLSearchParams(window.location.search);

    if (['classroomId', 'courseId', 'elementId', 'isCustomCourse'].some((urlParam) => {
      return urlParams.has(urlParam);
    }) || urlParams.get('view') === VIEW_SELECTION.BOOK) {
      // if any of the above urlParams exist, user likely refreshed the screen
      // from CourseElementModal and landed back on AdminProductsView.
      // for that case, we need to remove the following urlParams before re-entering CourseElementModal
      removeUrlParams(urlParams, ['classroomId', 'courseId', 'elementId', 'isCustomCourse', 'view']);

      const additionalParams = Object.fromEntries(urlParams);

      const routerUrl = generateUrl('/products', {
        ...additionalParams,
        view: VIEW_SELECTION.PRODUCTS
      });

      history.push(routerUrl);
    }

    if (urlParams.has('view')) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.PRODUCTS);
    }

    let institutionId = '';
    if (urlParams.has('institutionId')) {
      /* user came from a school card */
      institutionId = urlParams.get('institutionId');
      await this.setState({ institutionId });

      const { institutionName } = await navigationManager.getAdminUrlParams();
      schoolManager.setSearchText(institutionName);
      await this.setBreadcrumbs();
    }
    filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);
    this.createHeaderData();
    this.fetchProducts();
  }

  /**
   * Handle the following cases:
   *
   * (1) User is coming from the 'Schools' tab & needs to break out
   * of being filtered by a previous `institutionId` — or
   *
   * (2) URL changes while the component is still mounted
   *
   * (3) Filters were cleared in manager so we reset and remove all filtering
   */
  async componentDidUpdate() {
    const { filteredHeaderTableManager } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    let changesMade = false;
    let institutionId = '';
    if (urlParams.has('institutionId')) {
      institutionId = urlParams.get('institutionId');
      /* (1) if institutionId was passed to the component & has changed, update it */
      if (institutionId !== this.state.institutionId) {
        await this.setState({ institutionId });
        changesMade = true;
      }
    } else {
      /* (2) If institutionId was not passed, but one still exists in state, clear it */
      if (this.state.institutionId) {
        await this.setState({ institutionId: null });
        changesMade = true;
      }
      /* (3) If table managager is cleared then clear all the filters */
      if (filteredHeaderTableManager.isCleared) {
        filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);
        changesMade = true;
      }
    }
    if (changesMade) {
      this.createHeaderData();
      this.fetchProducts();
    }
  }

  setBreadcrumbs = async () => {
    const { navigationManager, userManager, t } = this.props;
    const { isDistrictAdmin, isDistrictProductAdmin } = userManager;
    const hasMultipleInstitutions = UserService.hasMultipleInstitutions('userManager');
    if (isDistrictAdmin || isDistrictProductAdmin || hasMultipleInstitutions) {
      navigationManager.clearAllPaths();

      const schoolsBreadcrumbObj = {
        fromView: VIEW_SELECTION.DASHBOARD,
        path: { name: t('schoolsLabel') }
      };
      const schoolNameBreadcrumbObj = {
        fromView: VIEW_SELECTION.DASHBOARD,
        paramName: 'institutionName',
        path: {},
      };
      const productsBreadcrumbObj = {
        fromView: VIEW_SELECTION.PRODUCTS,
        path: { name: t('productsLabel', 'Products') }
      };
      await navigationManager.setBreadcrumb(schoolsBreadcrumbObj);
      await navigationManager.setBreadcrumb(schoolNameBreadcrumbObj);
      await navigationManager.setBreadcrumb(productsBreadcrumbObj);
    }
  }

  handleOpenView = (resource) => {
    const { contentItemId, contentItemName } = resource;
    const { courseManager, dialogManager, navigationManager } = this.props;

    courseManager.setCurrentCourseId(contentItemId);
    courseManager.setCurrentCourseName(contentItemName);
    courseManager.setCurrentElementId('');

    navigationManager.clearAllPaths();
    dialogManager.setOpenDialog(DIALOG_NAMES.COURSE_ELEMENT, {
      ...this.props,
      courseId: contentItemId,
      courseName: contentItemName
    },
    this.closeCourseElementModal);
  }

  closeCourseElementModal = async () => {
    const { dialogManager, history } = this.props;
    // the course element modal ends up chaging the url by adding params so reset
    await history.push(`/products?view=${VIEW_SELECTION.PRODUCTS}`);
    dialogManager.closeAllDialogs();
    await this.setBreadcrumbs();
  }

  handleToggleLicense = async (productId) => {
    const { productManager } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.showLicenseDataOpen) {
      this.showLicenseDataModalClose();
    } else {
      this.showLicenseDataModalOpen();
    }
    productManager.setLoadingProductLicenses(true);
    // eslint-disable-next-line react/destructuring-assignment
    await productManager.getProductsLicenseEntities(this.state.institutionId, productId);
    productManager.setLoadingProductLicenses(false);
  }

  fetchProducts = async () => {
    const { productManager, filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    const { institutionId } = this.state;

    if (tableData) {
      await productManager.fetchProductResourcesByInstitution(
        institutionId,
        tableData.activePage,
        tableData.column,
        tableData.direction,
        tableData.filters
      );
    }
  }

  onPageChange = async (_event, pageInfo) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      const { activePage } = pageInfo;
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, activePage);
      this.fetchProducts();
    }
  }

  handleSort = async (clickedColumn, filters) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      if (clickedColumn) {
        const newDirection = tableData.direction === 'ascending' ? 'descending' : 'ascending';
        filteredHeaderTableManager.setSortColumn(this.filteredHeaderTableKey, clickedColumn);
        filteredHeaderTableManager.setSortDirection(this.filteredHeaderTableKey, newDirection);
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
        this.fetchProducts();
      } else {
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      }
    }
  }

  handleFilter = async (filters) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      let tempInstitutionId = this.state.institutionId;
      /* handle special 'institutionId' case */
      if (filters && filters.length > 0) {
        filters.forEach((filter, _index) => {
          if (filter.field && filter.field === 'institutionId') {
            tempInstitutionId = filter.value;
          }
        });
      }
      filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, 1);
      await this.setState({ institutionId: tempInstitutionId });
      this.fetchProducts();
    }
  }

  /**
   * On input, wait for user to stop typing,
   * then call api for filtered schools when text length >= 3.
   */
  handleFilterAutoComplete = (value) => {
    const { schoolManager } = this.props;
    schoolManager.setSearchTextTimeout(clearTimeout(schoolManager.searchTextTimeout));
    schoolManager.setSearchTextTimeout(setTimeout(() => {
      if (!value || value.length >= 3) {
        const page = 0;
        schoolManager.fetchSatelliteInstitutions(value, page, 25);
      }
    }, 1000));
  }

  showLicenseDataModalOpen = async () => {
    const { productManager } = this.props;
    productManager.clearProductLicenses();
    this.setState({ showLicenseDataOpen: true });
  }

  showLicenseDataModalClose = async () => {
    this.setState({ showLicenseDataOpen: false });
  }

  createSchoolData = () => {
    const { schoolManager } = this.props;
    const schoolData = [];
    schoolManager.schoolsArray.map((school) => {
      schoolData.push(
        {
          key: school.id,
          title: `${school.name} (${school.districtName} ${school.city} )`
        }
      );
    });
    return schoolData;
  }

  createHeaderData = () => {
    const { t, userManager } = this.props;
    const headerData = [
      {
        label: t('labelResourceName'),
        columnClassName: '',
        sortKey: 'resourceName',
        filterKey: 'resourceName',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: t('labelResourceType'),
        columnClassName: '',
        sortKey: 'entityTypeId',
        filterKey: 'entityTypeId',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: t('labelResourceId'),
        columnClassName: '',
        sortKey: 'resourceId',
        filterKey: 'resourceId',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: t('labelProductCode'),
        columnClassName: '',
        sortKey: 'productCode',
        filterKey: 'productCode',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: t('labelLicense'),
        columnClassName: '',
        sortKey: 'licenseCount',
        filterKey: '',
        filterLabel: 'Search for School',
        filterAutoCompleteCallback: null
      },
      {
        /* 'View' button placeholder */
        label: '',
        columnClassName: '',
        sortKey: '',
        filterKey: ''
      }
    ];
    /* Set license column filtering only if institution id is not in context. */
    if (!this.state.institutionId && !userManager.isSchoolAdmin && !userManager.isSchoolProductAdmin) {
      headerData[4].filterKey = 'institutionId';
      headerData[4].filterAutoCompleteCallback = this.handleFilterAutoComplete;
      headerData[4].filterPopupType = 'searchAutoComplete';
    }
    this.setState({ headerData });
  }

  createBodyData = () => {
    const { productManager, t } = this.props;
    const productResourceRows = [];

    productManager.productResources.map((productResource) => {
      const productResourceRow = [
        {
          /* Resource name */
          columnName: 'resourceName',
          columnText: ReactHtmlParser(productResource.contentItemName),
          columnClassName: 'truncate-long',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* Resource type */
          columnName: 'resourceType',
          columnText: CONTENT_ITEM_TYPES.getFlag(productResource.contentItemEntityTypeId),
          columnClassName: 'truncate-short',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* Resource Id */
          columnName: 'resourceId',
          columnText: productResource.contentItemId,
          columnClassName: 'truncate-long',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* Product code */
          columnName: 'productCode',
          columnText: productResource.productCode,
          columnClassName: 'truncate-short',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* Product License */
          columnName: 'productLicense',
          columnText: productResource.licenseCount,
          columnClassName: '',
          columnOnClick: async () => this.handleToggleLicense(productResource.productId),
          columnButtonOnClick: null,
          showTooltip: false,
          showTootipEvent: 'click'
        },
        {
          /* View Button */
          columnName: null,
          columnText: t('labelButtonView'),
          columnClassName: '',
          columnOnClick: null,
          columnButtonOnClick: () => this.handleOpenView(productResource),
          showTooltip: false,
          showTootipEvent: 'click'
        }
      ];
      productResourceRows.push(productResourceRow);
    }, this);
    return productResourceRows;
  }

  render() {
    const {
      dialogManager, filteredHeaderTableManager, history,
      productManager, schoolManager, userManager, t
    } = this.props;
    const { loadingProductLicenses } = productManager;
    const { isDistrictAdmin, isDistrictProductAdmin } = userManager;
    const hasMultipleInstitutions = UserService.hasMultipleInstitutions('userManager');
    const { schoolsArray } = schoolManager;
    const { BreadCrumbs, FilteredHeaderTable, ModalBanner } = this;

    let tableBodyData = [];
    if (productManager.loaded) {
      tableBodyData = this.createBodyData(productManager.productResources);
    }

    let productLicenses = [];
    if (productLicenses) {
      productLicenses = productManager.productLicenses;
    }
    let schoolData = null;
    if (schoolsArray && schoolsArray.length > 0) {
      schoolData = this.createSchoolData();
    }

    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    let activePage = 0;
    let column = '';
    let direction = '';
    let filters = [];
    if (tableData) {
      activePage = tableData.activePage;
      column = tableData.column;
      direction = tableData.direction;
      filters = tableData.filters;
    }

    const labelLicense = t('labelLicense');

    return (
      <Container className='productsView' fluid>
        {this.state.institutionId && (isDistrictAdmin || isDistrictProductAdmin || hasMultipleInstitutions) && (
          <div className='header-nav'>
            <Container className='bread-crumb-wrapper top' fluid>
              {!dialogManager.openedDialogs.get(
                DIALOG_NAMES.COURSE_ELEMENT) &&
                <BreadCrumbs history={history} />}
            </Container>
          </div>
        )}
        <Container>
          <div className='tableContainerHeader'>
            <div className='tableTitle theme-header-title'>{t('productsLabel', 'Products')}</div>
            <div className='tablePagination'>
              {tableBodyData.length > 0 &&
                <Pagination activePage={activePage} onPageChange={this.onPageChange} totalPages={productManager.totalPages} />}
            </div>
          </div>
          <div className='tableContainer'>
            <FilteredHeaderTable
              allowClearAllFilters
              autoCompleteSearchData={schoolData}
              column={column}
              direction={direction}
              filtersData={filters}
              handleFilter={this.handleFilter}
              handleSort={this.handleSort}
              tableBodyData={tableBodyData}
              tableHeaderData={this.state.headerData}
              tableId={this.filteredHeaderTableKey} />
          </div>
          {!productManager.loaded && (
          <div className='null-state-panel'>
            <Loader key={0} active inline />
          </div>
          )}
          {productManager.loaded && (!tableBodyData || tableBodyData.length < 1) && (
            <div className='null-state-panel'>
              No Data Found
            </div>
          )}
        </Container>
        {this.state.showLicenseDataOpen && (
        <Modal
          key='showLicenseData'
          className='showLicenseDataModal'
          onClose={() => this.showLicenseDataModalClose()}
          open={this.state.showLicenseDataOpen}
          size='mini'>
          <ModalBanner label={labelLicense} onClose={() => this.showLicenseDataModalClose()} />
          <Modal.Content>
            <div className='licenseDataContainer'>
              {loadingProductLicenses && <Segment basic loading />}
              {productLicenses?.length > 0 && (
                <Table className='licenseTable' striped>
                  <Table.Header>
                    <Table.Row key='header'>
                      <Table.HeaderCell>
                        {t('type')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t('name')}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {productLicenses.map((productLicense, idx) => (
                      <Table.Row key={idx}>
                        <Table.Cell className='capitalize'>
                          {productLicense.entityTypeId}
                        </Table.Cell>
                        <Table.Cell>
                          {productLicense.entityName}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button basic className='cancelButton' onClick={() => this.showLicenseDataModalClose()} primary>
              {t('close')}
            </Button>
          </Modal.Actions>
        </Modal>
        )}
      </Container>
    );
  }
}
SatCoreRegister('AdminProductsView', AdminProductsView);
