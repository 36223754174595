import { action, computed, makeObservable, observable } from 'mobx';

import userManager from '../UserManager';

import { getSessionStorageItem, setSessionStorageItem } from '../../utils';

import {
  REPORT_LESSON_FILTER_TYPE
} from '../../services/reports/ReportConstants';

// const REPORT_TYPE_ENDPOINTS = {
//   /* placeholder */
// };

export class ReportTypeManager {
  constructor() {
    makeObservable(this);
  }

  @observable selectedReportLessonFilterType = getSessionStorageItem('selectedReportLessonFilterType') || REPORT_LESSON_FILTER_TYPE.ALL;

  @action clearAllExceptSession = () => {
    /* placeholder */
  }

  @action clearSession = () => {
    this.setSelectedReportLessonFilterType(REPORT_LESSON_FILTER_TYPE.ALL);
  }

  @action setSelectedReportLessonFilterType = (reportLessonFilterType) => {
    setSessionStorageItem('selectedReportLessonFilterType', reportLessonFilterType);
    this.selectedReportLessonFilterType = reportLessonFilterType;
  }

  @computed get reportLessonFilterDropdownOptions() {
    const options = Object.values(REPORT_LESSON_FILTER_TYPE).map((lessonFilterType) => {
      let shouldIncludeOption;
      if (lessonFilterType !== 'clickbook') {
        shouldIncludeOption = true;
      } else {
        const userState = userManager.institutionState;
        if (typeof userState === 'string' && userState.toLocaleLowerCase().includes('georgia')) {
          shouldIncludeOption = true;
        }
      }
      return shouldIncludeOption ? {
        text: lessonFilterType,
        value: lessonFilterType
      } : undefined;
    }).filter((option) => option);
    return options;
  }
}

export default new ReportTypeManager();
