import { register } from '../../i18n';

import { REPORT_FACULTY_TYPE, REPORT_TABLE_TYPE, REPORT_URL_KEY } from './ReportConstants';

import navigationManager, { VIEW_SELECTION } from '../../managers/NavigationManager';
import reportIdentityManager from '../../managers/reports/ReportIdentityManager';
import schoolManager from '../../managers/SchoolManager';
import userManager from '../../managers/UserManager';

import { generateUrl, removeUrlParams } from '../../utils/url';

import ReportIdentityService from './ReportIdentityService';
import UserService from '../UserService';

const t = register('ReportBreadcrumbService');

export default class ReportBreadcrumbService {
  static initBreadcrumbs = async () => {
    const { isTableIndividualReport, reportStartUrlKey } = reportIdentityManager;
    const { isDistrictAdmin } = userManager;
    const hasMultipleInstitutions = UserService.hasMultipleInstitutions('userManager');

    const noAddDefaultBreadcrumb = true;
    navigationManager.clearAllPaths(noAddDefaultBreadcrumb);

    const reportUrlKeyMap = new Map(reportIdentityManager.allReportTranslationKeysByUrlKeyMap);

    let urlParams = new URLSearchParams(window.location.search);

    const isReportStandardsClassroomDetailStudents = ReportIdentityService.isReportStandardsClassroomDetailStudents();

    if (!isReportStandardsClassroomDetailStudents && !(isTableIndividualReport && urlParams.has('byStudents'))) {
      reportUrlKeyMap.delete(REPORT_URL_KEY['/reports/standards-classroom-detail-students']);
    }

    const allEntries = Array.from(reportUrlKeyMap.entries());

    const startIndex = allEntries.findIndex((entry) => {
      const reportUrlKey = entry[0];
      return reportUrlKey === reportStartUrlKey;
    });

    const {
      reportFacultyType,
      reportTableType,
      reportType
    } = ReportIdentityService.parseReportTypesFromBaseUrl();
    const currentReportUrl = ReportIdentityService.getReportUrl(
      reportType, reportFacultyType, reportTableType
    );

    let currentIndex = allEntries.findIndex(([reportUrlKeyInArray, _translationKey]) => {
      // the check for `/reports/` ensures `reportUrlKeyInArray` has a subroute (rather than just the base route `/reports`)
      return reportUrlKeyInArray.includes('/reports/') && currentReportUrl.includes(reportUrlKeyInArray);
    });
    const lastIndex = allEntries.length - 1;
    if (currentIndex !== lastIndex) {
      const nextEntryIndex = currentIndex + 1;
      const translationKeyIndex = 1;
      if (allEntries[nextEntryIndex][translationKeyIndex] === REPORT_URL_KEY['/reports/standards-classroom-detail-students']) {
        currentIndex += 1;
      }
    }

    // init currentEntries - if `currentIndex` was not found, we are at the base (`/reports`)
    currentIndex = currentIndex > 0 ? currentIndex : 0;
    let currentEntries = allEntries.slice(startIndex, currentIndex + 1);

    const institutionName = urlParams.get('institutionName');

    schoolManager.setSearchText('');

    const fromSchoolCard = ReportIdentityService.userCameFromSchoolCard();

    const fromAdminClassroomsView = ReportIdentityService.userCameFromAdminClassroomsView();

    const adminClassroomsViewReportStartUrl = !!fromAdminClassroomsView && (
      await ReportIdentityService.getAdminClassroomReportTypeSelectorStartUrl({
        classroomId: urlParams.get('classroomId'),
        classroomStatus: urlParams.get('classroomStatus'),
        selectedReportUrlKeySuffix: `${reportType}-classroom-summary`
      })
    );

    if ((fromSchoolCard || fromAdminClassroomsView) && (isDistrictAdmin || hasMultipleInstitutions)) {
      if (!fromAdminClassroomsView) {
        // set ('Schools' > institutionName)
        !!reportType && currentEntries.unshift([`/reports/${reportType}-school-summary`, `${reportType}Report`]);
        currentEntries.unshift(['/reports', '/reports']);
      } else {
        !!reportType && !!adminClassroomsViewReportStartUrl && (
          currentEntries.unshift([adminClassroomsViewReportStartUrl, `${reportType}Report`])
        );
        currentEntries.unshift(['/classrooms', 'classrooms']);
      }
      if (fromSchoolCard) {
        currentEntries.unshift([`/?view=${VIEW_SELECTION.DASHBOARD}&institutionName=${institutionName}`, institutionName]);
        currentEntries.unshift([`/?view=${VIEW_SELECTION.DASHBOARD}`, 'schools']);
      }
      currentEntries = currentEntries.filter(([reportBaseUrl]) => {
        // skip the district admin breadcrumbs since we did not come from there
        const { DISTRICT } = REPORT_FACULTY_TYPE;
        const { parseReportTypesFromBaseUrl } = ReportIdentityService;
        return parseReportTypesFromBaseUrl(reportBaseUrl).reportFacultyType !== DISTRICT;
      });
    } else if (window.location.pathname !== REPORT_URL_KEY['/reports']) {
      if (!fromAdminClassroomsView) {
        // set ('Reports' > 'Course Report' | 'Standards Report')
        !!reportStartUrlKey && currentEntries.unshift([reportStartUrlKey, `${reportType || ''}Report`]);
        currentEntries.unshift(['/reports', '/reports']);
      } else {
        !!adminClassroomsViewReportStartUrl && (
          currentEntries.unshift([adminClassroomsViewReportStartUrl, `${reportType}Report`])
        );
        currentEntries.unshift(['/classrooms', 'classrooms']);
      }
    }

    if (fromAdminClassroomsView) {
      currentEntries = currentEntries.filter(([reportBaseUrl]) => {
        // skip DA and SA report breadcrumbs when coming from admin classrooms
        // (since we did not come from DA/SA reports)
        const { DISTRICT, SCHOOL } = REPORT_FACULTY_TYPE;
        const { parseReportTypesFromBaseUrl } = ReportIdentityService;

        const { reportFacultyType: parsedReportFacultyType } = parseReportTypesFromBaseUrl(reportBaseUrl);

        // return parseReportTypesFromBaseUrl(reportBaseUrl).reportFacultyType !== DISTRICT;

        return parsedReportFacultyType !== DISTRICT && parsedReportFacultyType !== SCHOOL;
      });
    }

    // set remaining applicable report breadcrumbs
    const truncatedStandardDescription = urlParams.get('truncatedStandardDescription');
    const studentFullName = urlParams.get('studentFullName');
    let breadcrumbKey = '';

    for (const entry of currentEntries) {
      const [nextReportUrlKey, nextTranslationKey] = entry;
      if (nextReportUrlKey === REPORT_URL_KEY['/reports'] || nextReportUrlKey === '/classrooms') {
        const institutionId = urlParams.get('institutionId');
        let routerUrlParams = '';
        if (nextReportUrlKey === '/classroom') {
          routerUrlParams += `?view${VIEW_SELECTION.CLASSROOMS}`;
        } else {
          routerUrlParams += `?view${VIEW_SELECTION.REPORTS}`;
        }
        routerUrlParams += fromAdminClassroomsView ? '&fromAdminClassroomsView=true' : '';

        if (fromSchoolCard) {
          routerUrlParams += institutionId ? `&institutionId=${institutionId}` : '';
          routerUrlParams += institutionName ? `&institutionName=${institutionName}` : '';
          routerUrlParams += `&reportFacultyType=${REPORT_FACULTY_TYPE.SCHOOL}`;
        }
        // eslint-disable-next-line no-await-in-loop
        await navigationManager.setBreadcrumb({
          path: {
            name: t(nextTranslationKey),
            routerUrl: `${nextReportUrlKey}${routerUrlParams}`
          }
        });
      } else {
        urlParams = new URLSearchParams(window.location.search);
        urlParams = removeUrlParams(urlParams, [
          'reportId', 'reportType', 'reportFacultyType', 'reportTableType'
        ]);
        const { DISTRICT, SCHOOL, CLASSROOM } = REPORT_FACULTY_TYPE;
        if (nextReportUrlKey.includes(DISTRICT)) {
          urlParams = removeUrlParams(urlParams, [
            'byStudents', 'classroomId', 'classroomStatus', 'institutionId',
            'institutionName', 'standardElementId', 'studentFullName', 'studentId',
            'truncatedStandardDescription'
          ]);
        } else if (nextReportUrlKey.includes(SCHOOL)) {
          urlParams = removeUrlParams(urlParams, [
            'byStudents', 'classroomId', 'classroomStatus', 'standardElementId',
            'studentId', 'studentFullName', 'truncatedStandardDescription'
          ]);
        } else if (nextReportUrlKey.includes(CLASSROOM)) {
          if (nextReportUrlKey !== REPORT_URL_KEY['/reports/standards-classroom-detail-students'] &&
            !nextReportUrlKey.includes(REPORT_TABLE_TYPE.INDIVIDUAL)
          ) {
            urlParams = removeUrlParams(urlParams, ['byStudents', 'standardElementId', 'truncatedStandardDescription']);
          } else if (!nextReportUrlKey.includes(REPORT_TABLE_TYPE.INDIVIDUAL)) {
            urlParams = removeUrlParams(urlParams, [
              'studentFullName', 'studentId'
            ]);
          }
        }
        const next = ReportIdentityService.parseReportTypesFromBaseUrl(nextReportUrlKey);
        const nextReportId = next ? ReportIdentityService.getReportId(next.reportType, next.reportFacultyType) : undefined;

        const additionalParams = Object.fromEntries(urlParams);

        // get breadcrumb translation key
        if (institutionName && nextTranslationKey === institutionName) {
          breadcrumbKey = institutionName;
        } else if (nextReportUrlKey === REPORT_URL_KEY['/reports/standards-classroom-detail-students']) {
          breadcrumbKey = truncatedStandardDescription || nextTranslationKey;
        } else if (nextTranslationKey.includes(REPORT_TABLE_TYPE.INDIVIDUAL)) {
          breadcrumbKey = studentFullName || nextTranslationKey;
        } else {
          breadcrumbKey = nextTranslationKey;
        }
        // get breadcrumb name (translation value)
        let breadcrumbName;
        if (breadcrumbKey === institutionName) {
          breadcrumbName = institutionName;
        } else if (breadcrumbKey === truncatedStandardDescription) {
          breadcrumbName = truncatedStandardDescription;
        } else if (breadcrumbKey === studentFullName) {
          breadcrumbName = studentFullName;
        } else {
          breadcrumbName = t(breadcrumbKey);
        }

        const shouldGenerateUrl = !nextReportUrlKey.includes(VIEW_SELECTION.DASHBOARD);
        // eslint-disable-next-line no-await-in-loop
        await navigationManager.setBreadcrumb({
          path: {
            name: breadcrumbName,
            routerUrl: shouldGenerateUrl ? generateUrl(nextReportUrlKey, {
              ...additionalParams,
              reportFacultyType: next?.reportFacultyType,
              reportId: nextReportId,
              reportTableType: next?.reportTableType,
              reportType: next?.reportType
            }) : nextReportUrlKey
          }
        });
      }
    }
  }
}
