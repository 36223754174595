import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Iframe from 'react-iframe';
import { SatCoreRegister } from '../SatCoreRegistry';
import { VIEW_SELECTION } from '../managers/NavigationManager';
import '../css/ResourcesView.less';

export default
@inject('navigationManager', 'contentManager')
@observer
class ResourcesView extends Component {
  componentDidMount() {
    const { navigationManager } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.has('view') ? urlParams.get('view') : VIEW_SELECTION.RESOURCES;
    navigationManager.setView(view);
  }

  render() {
    const { contentManager, t } = this.props;
    return (
      <div className='ResourcesView content'>
        <Iframe
          height='100%'
          id='resourceFrame'
          position='relative'
          title={t('title', 'Help')}
          url={contentManager.resourceIFrameUrl}
          width='100%' />
      </div>
    );
  }
}

SatCoreRegister('ResourcesView', ResourcesView);
