import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Button, Form, Loader, Message, TextArea } from 'semantic-ui-react';

import Modal from '../Modal';

import '../../css/ShareAssessmentModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

export default
@inject('assessmentManager', 'contentItemManager', 'libraryManager', 'userManager', 'notificationManager')
class ShareAssessmentModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
  }

  getInitialState = () => {
    return {
      error: null,
      messageText: '',
      isAllowEdit: true,
      selectedUsers: []
    };
  }

  componentDidMount = async () => {
    const { selectedUsers } = this.props;
    this.setState({selectedUsers})
  }

  clearError = () => {
    this.setState({ error: null });
  }

  handleChangeMessageText = async (event) => {
    const newContentItemName = event.target.value;
    if (newContentItemName.length <= 200) {
      this.setState({ error: null, messageText });
      this.validate();
    }
  }

  handleSaveMessage = async (event) => {
    const messageText = event.target.value;
    if (messageText.length <= 250) {
      this.setState({ error: null, messageText });
    }
  }

  handleToggleAllowEdit = (event) => {
    if (event?.target?.checked) {
      this.setState({isAllowEdit: true});
    } else {
      this.setState({isAllowEdit: false});
    }
  };

  createShareNotification = async () => {
    const { messageText, isAllowEdit, selectedUsers } = this.state;
    const { libraryCardData, notificationManager, t } = this.props;
    const fullText = `${libraryCardData.title} ${t('sharedMessageText')} ${messageText}`;
    let result = false;
    if (this.validate()) {
     result = await notificationManager.addShareNotification(
        libraryCardData?.title,
        isAllowEdit,
        libraryCardData?.libraryResource?.id,
        libraryCardData?.libraryResource?.entityId,
        libraryCardData?.libraryResource?.entityTypeId,
        fullText,
        selectedUsers
      );
    }
    if (!result) {
      const errorMessage = 'An error occurred while trying to share. Please try again later.';
      this.setState({ error: errorMessage, isSaving: false });
    } else {
      this.closeShareAssessmentModal(true);
    }
  }

  validate = async () => {
    const { assessmentManager, contentItemId, resourceName, selectedResourceType } = this.props;
    let { error, messageText } = this.state;
    let isValid = true;

    return isValid;
  }

  closeShareAssessmentModal = (isSave) => {
    const { closeShareAssessmentModal } = this.props;
    closeShareAssessmentModal(isSave);
  }

  clearError = () => {
    this.setState({ error: null });
  }


  render() {
    const { shareAssessmentModalOpen, headerLabel, t} = this.props;
    const { messageText, isAllowEdit, isSaving, error } = this.state;
    const { ModalBanner, SCCheckbox } = this;
    return (
      <Modal className='share-assessment-modal' open={shareAssessmentModalOpen} size='tiny'>
        <ModalBanner
          label={headerLabel || t('headerLabel')}
          onClose={() => this.closeShareAssessmentModal(false)} />
        <Modal.Content>
          {error ? (
            <Message
              className='share-assessment-modal-error-msg'
              content={error}
              error
              visible={error !== null} />
          ) : (
            <>
              <div className='description-text'>{t('descriptionTextPrefix')}</div>
              <Form.Field className='share-message-field'>
                <Form>
                  <TextArea
                    className='inputText'
                    name='resourceName'
                    onChange={this.handleSaveMessage}
                    // onFocus={this.handleSaveMessage}
                    placeholder={t('placeholderText')}
                    type='text'
                    value={messageText} />
                </Form>
                <div className='inputLabelSubText'>{t('inputLabelSubText')}</div>
              </Form.Field>
              <div className='allow-edit-description-label'>{t('editTextDescriptionLabel')}</div>
              <div className='allow-edit-description'>{t('editTextDescription')}</div>
              <Form.Field className='allow-edit-field'>
                <SCCheckbox
                  checked={isAllowEdit}
                  disabled={false}
                  label={t('editCheckLabel')}
                  onChange={this.handleToggleAllowEdit} />
              </Form.Field>
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={() => { this.closeShareAssessmentModal(false); }} primary>{t('cancelButtonLabel', 'Cancel')}</Button>
          <Button
            disabled={(error !== null)}
            onClick={() => {
              this.setState({ isSaving: true });
              this.createShareNotification();
            }}
            primary>
            {isSaving ? <Loader active inline size='tiny' /> : t('saveButtonLabel', 'Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('ShareAssessmentModal', ShareAssessmentModal);
