/* eslint-disable max-len */
import React, { Component } from 'react';
import Iframe from 'react-iframe';
import { Button, Checkbox, Confirm, Popup } from 'semantic-ui-react';
import { inject } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import Modal from '../Modal';
import Auth from '../../managers/AuthManager';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import '../../css/FileViewerModal.less';
import { CONTENT_MODE } from '../../managers/ContentManager';

// import { _dcEvent, _dcData } from '../../managers/DocReaderManager';

import UtilityService from '../../services/UtilityService';

export default
@inject('assignmentManager', 'classroomManager', 'contentManager',
  'docReaderManager', 'studentContentCardManager', 'userManager')
class DocReaderModal extends Component {
    received = false;
    //    _cwin = null;

    constructor(props) {
      super(props);
      this.InfoModal = SatCoreComponent('InfoModal');
      this.ScoreAssignmentModal = SatCoreComponent('ScoreAssignmentModal');
      // this.PrintDocreaderPageModal = SatCoreComponent('PrintDocreaderPageModal');
      this.state = {
        addInfoOpen: false,
        showScoreModal: false,
        showPrintPageModal: false,
        confirmationOpen: false,
        interval: null,
        showAnnotations: false
      };
    }

    async componentDidMount() {
      const {
        activityId,
        assignmentManager,
        contentItemId,
        contentMode,
        page,
        studentContentCardManager
      } = this.props;
      if (page && page === 'file-viewer') {
        window.addEventListener('message', this.handleIframeTask);
      }
      if (contentMode === CONTENT_MODE.ASSESS) {
        const sessionStart = new Date();
        this.setState({
          interval:
        setInterval(async () => {
          const sessionPing = new Date();
          let viewedTime = (sessionPing - sessionStart) / 1000;
          viewedTime = viewedTime.toFixed(0);
          assignmentManager.markActivityViewed(activityId, contentItemId, viewedTime);
        }, 10000)
        });
      }
      if (studentContentCardManager.isLoading) {
        studentContentCardManager.setIsLoading(false);
      }
    }

    // ------------------------------------------------
    async componentWillUnmount() {
      const { docReaderManager } = this.props;
      // await docReaderManager.doSaveActions();
      await docReaderManager.clearHighlightsAnnotations();
      clearInterval(this.state.interval);
      window.removeEventListener('message', this.handleIframeTask);
      window.removeEventListener('message', docReaderManager._relay);
    }

    handleOpenInfo = () => {
      this.setState({ addInfoOpen: true });
    }

    closeInfoModal = () => {
      this.setState({ addInfoOpen: false });
    }

    handleIframeTask = (e) => {
      if (e.origin === Auth.publisher && e.data === 'closePressed') {
        this.props.closeIframeCallback();
      }
    };

    openGradeAssignment = () => {
      this.setState({ showScoreModal: true });
    }

    closeGradeAssignment = () => {
      this.setState({ showScoreModal: false });
      this.refreshClassroomData();
    }

    openPrintPage = () => {
      const { docReaderManager } = this.props;
      docReaderManager.doPagePrint();
    }

    refreshClassroomData = async () => {
      const { classroomManager, isFromLTI } = this.props;
      if (!isFromLTI) {
        await classroomManager.fetchClassroomData(classroomManager.currentClassroomId);
      }
    }

    submitAndClose = async () => {
      const { assignmentManager, userManager, assignmentId } = this.props;
      if (!userManager.canViewAsTeacher) {
        await assignmentManager.submitActivityInstance(assignmentId);
      }
      this.props.closeModalCallback();
    }

    startSubmit = async () => {
      this.setState({ confirmationOpen: true });
    }

    doInit = async () => {
      const { docReaderManager } = this.props;
      console.log('do init');
      docReaderManager.setContentWindow(document.getElementById('dcr-frame').contentWindow);
      setTimeout(() => {
        docReaderManager.addEventListeners();
      }, 10000);
    }

    noCallback = () => {
      console.log('No Callback');
    }

    initializeDocReaderFrame = async () => {
      const { docReaderManager } = this.props;
      docReaderManager.initializeDocReaderFrame();
    }

    activateAnnotationTool = () => {
      const { docReaderManager } = this.props;
      docReaderManager.activateAnnotationTool();
    }

    activateHighlightTool = () => {
      const { docReaderManager } = this.props;
      docReaderManager.activateHighlightTool();
    }

    hideBothTools = () => {
      const { docReaderManager } = this.props;
      docReaderManager.hideBothTools();
    }

    neutralizeBothTools = () => {
      const { docReaderManager } = this.props;
      docReaderManager.neutralizeBothTools();
    }

    saveCurrentPage = () => {
      const { docReaderManager } = this.props;
      docReaderManager.doSaveActions(false);
    }

    printCurrentPage = async () => {
      setTimeout(() => {
        this.openPrintPage();
      }, 1000);
    }

    doPageChangedActions = () => {
      const { docReaderManager } = this.props;
      docReaderManager.doPageChangedActions();
    }

    closeConfirmation = async () => {
      this.setState({ confirmationOpen: false });
    }

    handleChangeToggle = (_event, data) => {
      this.setState({
        showAnnotations: data.checked
      });

      if (!data.checked) {
        this.neutralizeBothTools();
      } else {
        this.hideBothTools();
      }
    }

    renderSubmitButton = () => {
      const { userManager } = this.props;
      if (!userManager.canViewAsTeacher && this.props.contentMode === CONTENT_MODE.ASSESS) {
        return (<Button className='student' onClick={() => this.startSubmit()} primary>Submit</Button>);
      }
      return null;
    }

    renderPrintButton = () => {
      // This is really based on courseResourceElement.isPrintingAllowed().
      // doing this here to remain consistent with any rules that may be added to control the config.
      const { contentManager, userManager } = this.props;
      const { showAnnotations } = this.state;

      try {
        const { searchParams } = new URL(contentManager.docReaderUrl);
        const confValue = searchParams.get('conf');

        if (confValue && confValue === 'printenabled') {
          return (
            <Popup
              className='gradebook-summary-table-popup gradebook-hourglass-popup'
              hideOnScroll
              hoverable
              on='hover'
              position='top center'
              // style={toolTipStyle}
              trigger={(
                <Button className={(!userManager.canViewAsTeacher ? 'basic student white' : '')}
                  disabled={showAnnotations}
                  onClick={() => this.printCurrentPage()}
                  primary>Print
                </Button>
              )}>
              <Popup.Header><div className='popup-message header'>Print Current Page</div></Popup.Header>
              <Popup.Content>
                <div className='popup-message'>This will print the current page with annotations, if applicable. If you want to print annotations, please be sure that the tools are closed.</div>
              </Popup.Content>
            </Popup>
          );
        } else {
          return null;
        }
      } catch {
        return {};
      }
    }

    renderStudentInstructionButton = () => {
      const { instruction, userManager } = this.props;
      if (instruction && instruction !== '<p></p>') {
        return (
          <Button
            className={(!userManager.canViewAsTeacher ? 'basic student-instruction white' : 'basic white')}
            onClick={() => this.handleOpenInfo()}
            primary>
            Student Instructions
          </Button>
        );
      }
      return null;
    }

    renderScoreButton = () => {
      const { userManager } = this.props;
      if (this.props.contentMode === CONTENT_MODE.REVIEW && userManager.canViewAsTeacher) {
        return (<Button className='basic white' onClick={() => this.openGradeAssignment()} primary>Score</Button>);
      }
      return null;
    }

    renderDivider = () => {
      const { instruction } = this.props;
      if (instruction && instruction !== '<p></p>') {
        return (<div className='divider' />);
      }
      return null;
    }

    render() {
      const { userManager, docReaderManager, contentManager, contentMode, instruction, contentName, contentImageUrl, studentName, closeModalCallback, t } = this.props;
      const { showAnnotations } = this.state;
      const { addInfoOpen, confirmationOpen } = this.state;
      const { InfoModal, ScoreAssignmentModal } = this;
      const transformFn = UtilityService.reactHtmlParserTransform;
      return (
        <>
          <Modal className='f-player-modal' open size='fullscreen'>
            <div className='f-header'>
              <div className='modal-title-text'>
                {
                  userManager.canViewAsTeacher && contentMode === 'review' ? (
                    <p>
                      {t('gradebookLabel', 'DocReaderModal translation missing.')}
                      :
                      {' '}
                      {ReactHtmlParser(studentName, { transform: transformFn })}
                    </p>
                  ) :
                    <p>{ReactHtmlParser(contentName, { transform: transformFn })}</p>
                }
              </div>
              <div className='modal-title-button'>
                {this.renderStudentInstructionButton()}
                {this.renderDivider()}
                {this.renderScoreButton()}
                {(!userManager.canViewAsTeacher && this.props.contentMode === CONTENT_MODE.ASSESS) || userManager.canViewAsTeacher ? (
                  <>
                    <div className='toggle-hide-or-show-wrapper'>
                      <Checkbox
                        className='hide-or-show-toggler hide-or-show-toggler-directly-on-background'
                        defaultChecked={showAnnotations}
                        label={t('Markup')}
                        onChange={(event, data) => { this.handleChangeToggle(event, data); }}
                        toggle />
                    </div>
                    <Button className={(!userManager.canViewAsTeacher ? 'basic student white' : '')} disabled={showAnnotations} onClick={() => this.activateHighlightTool()} primary>Highlights</Button>
                    <Button className={(!userManager.canViewAsTeacher ? 'basic student white' : '')} disabled={showAnnotations} onClick={() => this.activateAnnotationTool()} primary>Annotations</Button>
                    <Button className={(!userManager.canViewAsTeacher ? 'basic student white' : '')} disabled={showAnnotations} onClick={() => this.saveCurrentPage()} primary>Save</Button>
                    {this.renderPrintButton()}
                  </>
                ) : null}
                <Button className={(!userManager.canViewAsTeacher ? 'basic student white' : '')} onClick={() => closeModalCallback()} primary>Close</Button>
                {this.renderSubmitButton()}
              </div>
            </div>
            <div className='f-content'>
              <Iframe height='100%' id='dcr-frame' onLoad={() => this.initializeDocReaderFrame()} position='relative'
                title={UtilityService.stripHtmlTagsAndStrip(contentName)} url={contentManager.docReaderUrl} width='100%' />
            </div>
            <InfoModal
              addInfoOpen={addInfoOpen}
              closeInfoModal={this.closeInfoModal}
              contentItemDescription={null}
              imageUrl={contentImageUrl}
              infoName={contentName}
              resourceInfo={null}
              studentInstruction={instruction} />
            <Confirm
              cancelButton='No'
              confirmButton='Yes'
              content='Submitting this assignment will give it to your teacher.  Are you ready to submit?'
              header='Submit Assignment'
              onCancel={() => this.setState({ confirmationOpen: false })}
              onConfirm={() => {
                this.submitAndClose();
                this.setState({ confirmationOpen: false });
              }}
              open={confirmationOpen} />
          </Modal>
          {/* Using the modal directly as the dialogManager doesn't seem to work for modals within the lti iFrame */}
          <ScoreAssignmentModal
            activityInstanceId={this.props.assignmentId}
            closeGradeAssignment={this.closeGradeAssignment}
            grade={(this.props.grade) ? this.props.grade : 0}
            gradeAssignmentOpen={this.state.showScoreModal}
            maxScore={(this.props.maxScore) ? this.props.maxScore : 100}
            studentName={this.props.studentName}
            totalScore={(this.props.totalScore) ? this.props.totalScore : 0} />
        </>
      );
    }
}
SatCoreRegister('DocReaderModal', DocReaderModal);
