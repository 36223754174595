import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import _ from 'lodash';

import {
  Button, Checkbox, Container, Form, Header,
  Image, Input, Loader, Message, Popup,
  Radio
} from 'semantic-ui-react';

import ReactModalDraggable from 'react-modal-resizable-draggable';

import { DateInput, TimeInput } from 'semantic-ui-calendar-react';

import assessmentDefaultImage from '../../img/default-assessment.svg';
import chapterDefaultImage from '../../img/default-chapter.svg';
import { customAssessmentDefaultCardImageUri } from '../../services/UriService';
import info from '../../img/group-1583.svg';

import '../../css/AddAssignmentModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { ASSIGNMENT_TYPE } from '../../managers/AssignmentManager';
import { CONTENT_ITEM_TYPES } from '../../managers/ContentManager';
import { CONTENT_ITEM_SUB_TYPES } from '../../managers/ContentManager';

import { REPORT_COLOR_KEY } from '../../services/reports/ReportConstants';

import AssignmentService from '../../services/AssignmentService';
import GroupService from '../../services/GroupService';
import ReportScoreService from '../../services/reports/ReportScoreService';
import ResourcePacingService from '../../services/ResourcePacingService';
import UtilityService from '../../services/UtilityService';

import ActivityModeDropdown from '../ActivityModeDropdown';
import ActivityProgressBar from '../ActivityProgressBar';
import RichTextEditor from '../RichTextEditor';
import ClassroomService from '../../services/ClassroomService';
import Auth  from '../../managers/AuthManager';

import { dateFormat } from '../../utils';

export default @inject(
  'accommodationsManager', 'assignmentManager',
  'classroomManager', 'contentManager', 'courseManager', 'groupsManager',
  'reportIdentityManager', 'reportStandardsClassroomDetailStudentsManager')
@observer
class AddAssignmentModal extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.AccommodationsTogglerSection = SatCoreComponent('AccommodationsTogglerSection');
    this.ClassroomDropdown = SatCoreComponent('ClassroomDropdown');
    this.GenericModal = SatCoreComponent('GenericModal');
    this.GroupResourceSelector = SatCoreComponent('GroupResourceSelector');
    this.ModalBannerDraggable = SatCoreComponent('ModalBannerDraggable');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.SCRadio = SatCoreComponent('SCRadio');
    this.StandardsList = SatCoreComponent('StandardsList');

    this.modalRef = React.createRef();
  }

  componentDidMount = async () => {
    const { assignmentManager, contentItemEntityTypeId, contentItemId } = this.props;
    const { alwaysShowTypingSettingsForLessonAssessment } = assignmentManager;
    await this.fetchCourseClassrooms();
    await this.refreshAssignmentModalClassroomData();

    // Keyboarding: If the satellite allows to always show keyboarding settings check the resource types as Lesson, Assessment and Keyboarding Resource
    // are all valid.  if not, only allow for Keyboarding resource.
    const showKeyboardingSettings = alwaysShowTypingSettingsForLessonAssessment ? (
      contentItemEntityTypeId === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE ||
      contentItemEntityTypeId === CONTENT_ITEM_TYPES.LESSON ||
      contentItemEntityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT
    ) : contentItemEntityTypeId === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE;

    if (showKeyboardingSettings) {
      await this.fetchContentItemResource(contentItemId);
    }

    this.setState({ showKeyboardingSettings });

    if (this.modalRef && this.modalRef.current) {
      this.modalRef.current.focus();
    }
  }

  getInitialState = () => {
    const {
      allowMultipleResources,
      alternateModeId,
      assignType,
      assignUsers,
      assignmentManager,
      contentDuration,
      contentTimeframeStartDate,
      contentTimeframeEndDate,
      courseElementToggleDefaults,
      groupsManager,
      isReassign,
      modeOverrideAllowed,
      reportIdentityManager,
      resourceMode,
      timeframeStartDateStr,
      timeframeEndDateStr,
      timeframeStartTimeStr,
      timeframeEndTimeStr,
    } = this.props;
    const {
      includeInReports,
      scoresReleased,
      studentInstruction,
      studentReview
    } = courseElementToggleDefaults;
    const now = new Date();
    const assignmentType = assignType || ASSIGNMENT_TYPE.CLASSROOM_USER;
    const assignmentUsers = assignUsers || [];

    // Get start and end dates. End date may be modified by contentDuration or based on dueDate depending on which is present if any.
    const SEVEN_DAYS = 7;
    const ONE_WEEK_IN_HOURS = ResourcePacingService.getTotalDuration(SEVEN_DAYS);
    const durationTotalHours = allowMultipleResources ? ONE_WEEK_IN_HOURS : contentDuration;
    // eslint-disable-next-line react/destructuring-assignment
    const contentDueDate = !allowMultipleResources ? this.props.contentDueDate : null;

    const formattedTimeframeStartDate = timeframeStartDateStr// these are now formatted on BE ? dateFormat(timeframeStartDate, 'twoDigitDate') : null;
    const formattedTimeframeEndDate = timeframeEndDateStr// these are now formatted on BE ? dateFormat(timeframeEndDate, 'twoDigitDate') : null;
    const formattedTimeframeStartTime = timeframeStartTimeStr;// these are now formatted on BE ? dateFormat(timeframeStartDate, 'twoDigitTime') : null;
    const formattedTimeframeEndTime = timeframeEndTimeStr;// these are now formatted on BE ? dateFormat(timeframeEndDate, 'twoDigitTime') : null;

    const {
      endLocaleDateString,
      endTimeStr,
      startLocaleDateString,
      startTimeStr
    } = assignmentManager.getAssignmentStartAndEnd(now, durationTotalHours, contentDueDate);

    return {
      activityModeId: alternateModeId || '',
      addingAssignment: false,
      allowLateSubmission: false,
      assignmentType,
      classroomGroups: [],
      confirmModalErrorMessage: '',
      courseClassrooms: [],
      currentClassrooms: [],
      currentRoster: assignmentUsers,
      disabledSubmitAssignment: false,
      editorValue: studentInstruction || '',
      endDate: endLocaleDateString,
      endError: false,
      endMessage: '',
      endTime: endTimeStr,
      hasAssignableResources: true,
      ignoreItemOptionShuffle: false,
      includeInReports: includeInReports || false,
      isConfirming: false,
      isReassign: isReassign || false,
      lateSubmitDate: endLocaleDateString,
      lateSubmitError: false,
      lateSubmitMessage: '',
      lateSubmitTime: endTimeStr,
      loadingAddAssignmentModal: true,
      modeOverrideAllowed,
      nickname: '',
      // eslint-disable-next-line no-nested-ternary
      originalReportGroups: !reportIdentityManager.isReport ? [] :
        (groupsManager.groups ? toJS([...groupsManager.groups]) : []),
      randomizeQuestions: true,
      resourceMode,
      scoresReleased: scoresReleased || false,
      selectedClassroomDropdownOption: null, // will be null & unused unless user specifically changes the classroom via ClassroomDropdown
      selectedCutScoreStudents: new Map(),
      selectedCutScores: [],
      serverError: false,
      serverErrorMsg: null,
      showCorrectAnswers: true,
      showKeyboardingSettings: false,
      startDate: startLocaleDateString,
      startError: false,
      startMessage: '',
      startTime: startTimeStr,
      studentReview: studentReview || false,
      timeframeStartDate: contentTimeframeStartDate,
      timeframeEndDate: contentTimeframeEndDate,
      formattedTimeframeStartDate,
      formattedTimeframeEndDate,
      formattedTimeframeStartTime,
      formattedTimeframeEndTime,
      typingAllowBackspace: false,
      typingText: '',
      typingDuration: 0,
      typingMinimumSpeed: 0,
      typingMinimumAccuracy: 0
    };
  };

  fetchCourseClassrooms = async () => {
    const { classroomManager, courseContentItemId, courseManager } = this.props;

    const { currentCourseId } = courseManager;

    const courseId = courseContentItemId || currentCourseId;
    const classrooms = await classroomManager.getClassroomsByCourse(courseId);

    this.setState({ courseClassrooms: classrooms });
  }

  fetchContentItemResource = async (contentItemId) => {
    const { contentManager } = this.props;
    const keyboardingResource = await contentManager.fetchResourceItem(contentItemId);
    this.setState({
      typingAllowBackspace: keyboardingResource.allowBackspace,
      typingText: keyboardingResource.typingText,
      typingDuration: keyboardingResource.durationInSeconds,
      typingMinimumSpeed: keyboardingResource.minSpeed,
      typingMinimumAccuracy: keyboardingResource.minAccuracy
    });
  }

  refreshAssignmentModalClassroomData = async (classroomId) => {
    this.setState({ loadingAddAssignmentModal: true });

    await this.fetchClassroomRoster(classroomId);

    await this.fetchClassroomGroups(classroomId);

    this.setState({
      currentRoster: [],
      loadingAddAssignmentModal: false
    });
  }

  fetchClassroomRoster = async (classroomId) => {
    const {
      classroomManager,
      contentItemEntityTypeId,
      contentManager,
      learnosityActivityResourceId
    } = this.props;

    const { selectedClassroomDropdownOption } = this.state;

    classroomId = classroomId || selectedClassroomDropdownOption?.value || classroomManager.currentClassroomId;

    await classroomManager.fetchClassroomRoster(classroomId);
    if (learnosityActivityResourceId && contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) {
      const lrnActivityResourceSettings = await contentManager.fetchLearnosityActivityContentItemSettings(learnosityActivityResourceId);
      if (lrnActivityResourceSettings) {
        this.setState({ randomizeQuestions: lrnActivityResourceSettings.shuffleItems });
      }
    }
  }

  fetchClassroomGroups = async (classroomId) => {
    const { assignmentManager, classroomManager } = this.props;

    const { selectedClassroomDropdownOption } = this.state;

    assignmentManager.clearGroupResourceSelections();

    this.setState({
      classroomGroups: []
    });

    classroomId = classroomId || selectedClassroomDropdownOption?.value || classroomManager.currentClassroomId;

    await GroupService.initClassroomGroups({
      classroomId,
      clearOnInit: true,
      pageSize: 999 // we want all groups, so send a big page size
    });
  }

  confirmCloseAddAssignmentModal = ({ confirmModalErrorMessage = '' } = {}) => {
    this.setState({ confirmModalErrorMessage, isConfirming: true });
  }

  // eslint-disable-next-line react/sort-comp
  closeAddAssignmentModal = () => {
    this.setState({ isConfirming: false });

    const { assignmentManager, closeAddAssignment, groupsManager, reportIdentityManager } = this.props;
    const { originalReportGroups } = this.state;

    assignmentManager.clearGroupResourceSelections();

    if (reportIdentityManager.isReport) {
      groupsManager.setGroupsForReport(originalReportGroups);
    }

    this.setState(this.getInitialState());
    closeAddAssignment();
  };

  submitAddAssignment = async () => {
    const {
      assignmentManager, contentItemId, courseResourceElementId, t
    } = this.props;
    try {
      const { addingAssignment } = this.state;
      if (addingAssignment) {
        return;
      }
      this.setState({ addingAssignment: true });
      if (!this.validate()) {
        this.setState({ addingAssignment: false });
        return;
      }
      await AssignmentService.submitAddAssignments({
        ...this.props,
        ...this.state,
        singularContentItemId: contentItemId,
        singularCourseResourceElementId: courseResourceElementId
      });

      if (assignmentManager.isLtiLaunched) {
        const assignmentData = assignmentManager.getLastAddedAssignment();
        window.parent.postMessage({
          custom: {
            contentItemId: assignmentData.contentItemId,
            courseResourceElementId: assignmentData.courseResourceElementId,
            subdomainLinkId: assignmentData.subdomainLinkId
          },
          type: 'lti'
        }, '*');
      }

      this.confirmCloseAddAssignmentModal();
      this.setState({ addingAssignment: false });
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        throw error;
      } else {
        throw new TypeError(typeof error === 'string' ? error : t('submitAddAssignmentError'));
      }
    }
  };

  handleChangeStartDate = (event, { value }) => {
    this.setState({ startDate: value, startError: false });
  };

  handleChangeEndDate = (event, { value }) => {
    const { assignmentManager } = this.props;
    const { endTime, lateSubmitDate, lateSubmitTime } = this.state;

    // Change end date, but we may have to adjust late submit date if new end date goes beyond it.
    const lateSubmitRealDate = assignmentManager.convertJSStringToJSDate(lateSubmitDate, lateSubmitTime);
    const endRealDate = assignmentManager.convertJSStringToJSDate(value, endTime);
    if (lateSubmitRealDate < endRealDate) {
      this.setState({ lateSubmitDate: value, lateSubmitError: false });
    }
    this.setState({ endDate: value, endError: false });
  };

  handleChangeStartTime = (event, { value }) => {
    this.setState({ startTime: value ? value.toUpperCase() : '', startError: false });
  };

  handleChangeEndTime = (event, { value }) => {
    this.setState({ endTime: value ? value.toUpperCase() : '', endError: false });
  };

  handleChangeLateSubmitDate = (event, { value }) => {
    this.setState({ lateSubmitDate: value ? value.toUpperCase() : '', lateSubmitError: false });
  };

  handleChangeLateSubmitTime = (event, { value }) => {
    this.setState({ lateSubmitTime: value ? value.toUpperCase() : '', lateSubmitError: false });
  };

  handleClickRandomizeQuestions = (_event) => {
    // const checked = _event.target.value;
    const { randomizeQuestions } = this.state;
    this.setState({
      randomizeQuestions: !randomizeQuestions
    });
  }

  handleClickShowCorrectAnswers = (_event) => {
    // const checked = _event.target.value;
    const { showCorrectAnswers } = this.state;
    this.setState({
      showCorrectAnswers: !showCorrectAnswers
    });
  }

  handleClickIgnoreItemOptionShuffle = (_event) => {
    // const checked = _event.target.value;
    const { ignoreItemOptionShuffle } = this.state;
    this.setState({
      ignoreItemOptionShuffle: !ignoreItemOptionShuffle
    });
  }

  handleRelease = (name, value) => {
    if (name === 'includeInReports') {
      this.setState({ includeInReports: value });
    }
    if (name === 'studentReview') {
      this.setState({ studentReview: value });
    }
    if (name === 'scoresReleased') {
      this.setState({ scoresReleased: value });
    }
  }

  handleAssignmentType = async (event) => {
    this.setState({
      assignmentType: event.target.value,
      currentClassrooms: [],
      currentRoster: [],
      serverError: false,
      serverErrorMsg: ''
    });
  };

  handleChangeNickname = (event) => {
    const { name, value } = event.target;
    if (value.length < 201) {
      this.setState((prevState) => {
        const newState = { ...prevState };
        newState[name] = value;
        return newState;
      });
    }
  }

  handleChangeTypingBackspace = (value) => {
    this.setState({typingAllowBackspace: value});
  }

  handleChangeTypingText = (_event, { value }) => {
    this.setState({typingText: value});
  };

  handleChangeTypingDuration = (_event, { value }) => {
    this.setState({typingDuration: value});
  };

  handleChangeMinimumSpeed = (_event, { value }) => {
    this.setState({typingMinimumSpeed: value});
  };

  handleChangeMinimumAccuracy = (_event, { value }) => {
    this.setState({typingMinimumAccuracy: value});
    setTypingMinimumAccuracy(value);
  };

  handleCheckStudent = (id) => {
    // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
    const currentRoster = this.state.currentRoster.slice();
    const index = currentRoster.indexOf(id);

    if (index > -1) {
      currentRoster.splice(index, 1);
    } else {
      currentRoster.push(id);
    }
    this.setState({ currentRoster });
  };

  handleCheckClassroom = (id) => {
    // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
    const currentClassrooms = this.state.currentClassrooms.slice();
    const index = currentClassrooms.indexOf(id);

    if (index > -1) {
      currentClassrooms.splice(index, 1);
    } else {
      currentClassrooms.push(id);
    }
    this.setState({ currentClassrooms });
  };

  handleCheckClassroomGroup = (id) => {
    // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
    const classroomGroups = this.state.classroomGroups.slice();
    const index = classroomGroups.indexOf(id);

    if (index > -1) {
      classroomGroups.splice(index, 1);
    } else {
      classroomGroups.push(id);
    }

    this.setState({ classroomGroups });
  };

  handleCheckCutScore = (cutScoreKey, cutScoreStudents) => {
    const { selectedCutScores, selectedCutScoreStudents, currentRoster } = this.state;

    let newSelectedCutScores = [];
    if (selectedCutScores.includes(cutScoreKey)) {
      newSelectedCutScores = selectedCutScores.filter((cutScore) => cutScore !== cutScoreKey);
    } else {
      newSelectedCutScores = [...selectedCutScores, ...[cutScoreKey]];
    }

    let newCutScoreStudents = [];
    const currentCutScoreStudents = selectedCutScoreStudents.get(cutScoreKey);
    const currentCutScoreStudentIds = [];
    let newCurrentRoster = [];
    if (currentCutScoreStudents && currentCutScoreStudents.length > 0) {
      // If we are deselecting the cutScore above, reset the students list.
      currentCutScoreStudents.forEach((student) => currentCutScoreStudentIds.push(student.studentId));
      newCurrentRoster = currentRoster.filter((studentId) => !currentCutScoreStudentIds.includes(studentId));
    } else {
      // If we are selecting the cutScore above, pre-check all the students for the cut score.
      cutScoreStudents.forEach((student) => currentCutScoreStudentIds.push(student.studentId));
      newCurrentRoster = [...currentRoster, ...currentCutScoreStudentIds];
      newCutScoreStudents = cutScoreStudents;
    }

    // set the new student list for the current cut score
    selectedCutScoreStudents.set(cutScoreKey, newCutScoreStudents);

    this.setState({ currentRoster: newCurrentRoster, selectedCutScoreStudents, selectedCutScores: newSelectedCutScores });
  };

  handleCheckCutScoreStudent = (student, reportColorKey) => {
    const { selectedCutScores, selectedCutScoreStudents, currentRoster } = this.state;
    const currentRosterTemp = currentRoster.slice();
    const index = currentRosterTemp.indexOf(student.studentId);

    if (index > -1) {
      // Remove the student from the roster
      currentRosterTemp.splice(index, 1);
      // if we are removing, check if we have deselected all the students in the current cut score
      const currrentCutScoreStudents = selectedCutScoreStudents.get(reportColorKey);
      let hasCheckedStudents = false;
      currentRosterTemp.forEach((studentId) => {
        const selectedStudent = currrentCutScoreStudents.find((student) => student.studentId === studentId);
        if (selectedStudent) {
          hasCheckedStudents = true;
        }
      });
      // If all students were deselected, uncheck the category.
      if (!hasCheckedStudents) {
        const newSelectedCutScores = selectedCutScores.filter((cutScore) => cutScore !== reportColorKey);
        selectedCutScoreStudents.set(reportColorKey, []);
        this.setState({ selectedCutScores: newSelectedCutScores });
      }
    } else {
      // Add the student to the current roster
      currentRosterTemp.push(student.studentId);
    }

    this.setState({ currentRoster: currentRosterTemp });
  };

  validate = () => {
    const {
      allowMultipleResources, assignmentManager, classroomManager, groupsManager, timeframeStartDateStr,
      timeframeEndDateStr, timeframeStartTimeStr, timeframeEndTimeStr
    } = this.props;

    const { groupResourceSelectorCheckedChildContentItemIds, loadingGroupResourceSelector } = groupsManager;
    const checkedChildContentItemIds = groupResourceSelectorCheckedChildContentItemIds;

    const {
      allowLateSubmission,
      assignmentType,
      classroomGroups,
      currentClassrooms,
      currentRoster,
      endDate,
      endTime,
      lateSubmitDate,
      lateSubmitTime,
      selectedClassroomDropdownOption,
      startDate,
      startTime,
      timeframeStartDate,
      timeframeEndDate
    } = this.state;

    const classroomId = selectedClassroomDropdownOption?.value || classroomManager.currentClassroomId;

    const validLateSubmissionAssignmentType = assignmentManager.lateSubmissionOptionAllowed &&
      assignmentType !== ASSIGNMENT_TYPE.CLASSROOM && assignmentType !== ASSIGNMENT_TYPE.ALL_CLASSES;

    let hasError = false;

    if (allowMultipleResources && !loadingGroupResourceSelector && !checkedChildContentItemIds?.length) {
      hasError = true;
    }
    if (isNaN(new Date(startDate))) {
      this.setState({
        startError: true,
        startMessage: 'Start date format is not valid.'
      });
      hasError = true;
      return;
    }
    if (isNaN(new Date(endDate))) {
      this.setState({
        endError: true,
        endMessage: 'End date format is not valid.'
      });
      hasError = true;
      return;
    }
    if (!assignmentManager.isValidTimeInput(startTime)) {
      this.setState({
        startError: true,
        startMessage: 'Start time format is not valid (must be hh:mm aa).'
      });
      hasError = true;
      return;
    }
    if (!assignmentManager.isValidTimeInput(endTime)) {
      this.setState({
        endError: true,
        endMessage: 'End time format is not valid (must be hh:mm aa).'
      });
      hasError = true;
      return;
    }
    if (allowLateSubmission && validLateSubmissionAssignmentType) {
      if (isNaN(new Date(lateSubmitDate))) {
        this.setState({
          lateSubmitError: true,
          lateSubmitMessage: 'Late submision date format is not valid.'
        });
        hasError = true;
        return;
      }
      if (!assignmentManager.isValidTimeInput(lateSubmitTime)) {
        this.setState({
          lateSubmitError: true,
          lateSubmitMessage: 'Late submission time format is not valid (must be hh:mm aa).'
        });
        hasError = true;
        return;
      }

      if (lateSubmitDate && startDate) {
        const startRealDate = assignmentManager.convertJSStringToJSDate(startDate, startTime);
        const lateSubmitRealDate = assignmentManager.convertJSStringToJSDate(lateSubmitDate, lateSubmitTime);
        if (lateSubmitRealDate < startRealDate) {
          this.setState({
            lateSubmitDate: null,
            lateSubmitError: true,
            lateSubmitMessage: 'Late submit date cannot be before start date.',
            lateSubmitTime: null
          });
          hasError = true;
        }
      }

      if (lateSubmitDate && endDate) {
        const lateSubmitRealDate = assignmentManager.convertJSStringToJSDate(lateSubmitDate, lateSubmitTime);
        const endRealDate = assignmentManager.convertJSStringToJSDate(endDate, endTime);
        if (lateSubmitRealDate < endRealDate) {
          this.setState({
            lateSubmitDate: null,
            lateSubmitError: true,
            lateSubmitMessage: 'Late submit date cannot be before end date.',
            lateSubmitTime: null
          });
          hasError = true;
        }
      }
    }

    if (endDate && startDate) {
      const startRealDate = assignmentManager.convertJSStringToJSDate(startDate, startTime);
      const endRealDate = assignmentManager.convertJSStringToJSDate(endDate, endTime);
      if (endRealDate < startRealDate) {
        this.setState({
          endDate: null,
          endError: true,
          endMessage: 'Due date cannot be before start date.',
          endTime: null
        });
        hasError = true;
      } else if (endRealDate.getTime() === startRealDate.getTime()) {
        this.setState({
          endDate: null,
          endError: true,
          endMessage: 'Due date must be after start date.',
          endTime: null
        });
        hasError = true;
      }
    }

    if (startDate === null) {
      this.setState({
        startDate: null,
        startTime: null,
        startError: true,
        startMessage: 'Start Date is required!'
      });
      hasError = true;
    }

    if (endDate === null) {
      this.setState({
        endDate: null,
        endError: true,
        endMessage: 'Due Date is required!',
        endTime: null
      });
      hasError = true;
    }

    if (endDate !== null && startDate != null) {
      const startRealDate = assignmentManager.convertJSStringToJSDate(startDate, startTime);
      const endRealDate = assignmentManager.convertJSStringToJSDate(endDate, endTime);
      const classroom = classroomManager.getClassroom(classroomId);
      const classroomStartRealDate = assignmentManager.convertJSStringToJSDate(classroom.timezoneStartDate, classroom.startTime);
      const classroomEndRealDate = assignmentManager.convertJSStringToJSDate(
        classroom.timezoneEndDate, classroom.endTime || '11:59:59 PM');
      if (endRealDate < startRealDate) {
        this.setState({
          startDate: null,
          startError: true,
          startMessage: 'Start date should be before end date.',
          startTime: null
        });
        hasError = true;
      }

      if (startRealDate < classroomStartRealDate) {
        this.setState({
          startDate: null,
          startError: true,
          startMessage: 'Start date should be after classroom start date.',
          startTime: null
        });
        hasError = true;
      }

      if (endRealDate > classroomEndRealDate) {
        this.setState({
          endDate: null,
          endError: true,
          endMessage: 'End date should be before classroom end date.',
          endTime: null
        });
        hasError = true;
      }

      if (endRealDate < new Date()) {
        this.setState({
          endDate: null,
          endError: true,
          endMessage: 'End date should be in the future.',
          endTime: null
        });
        hasError = true;
      }

      if (allowLateSubmission && validLateSubmissionAssignmentType) {
        if (lateSubmitDate > classroomEndRealDate) {
          this.setState({
            endDate: null,
            endError: true,
            endMessage: 'Late submission date should be before classroom end date.',
            endTime: null
          });
          hasError = true;
        }

        if (lateSubmitDate && lateSubmitDate < new Date()) {
          this.setState({
            endDate: null,
            endError: true,
            endMessage: 'Late submission date should be in the future.',
            endTime: null
          });
          hasError = true;
        }
      }
    }

    // check timeframe
    if (timeframeStartDate && startDate !== null) {
      // use timeframe string date parts from BE to build the comparison date to avoid an offset being applied.
      const realTimeFrameStartDate = assignmentManager.convertJSStringToJSDate(timeframeStartDateStr, timeframeStartTimeStr);
      const startRealDate = assignmentManager.convertJSStringToJSDate(startDate, startTime);
      console.log(`timeframeStartDate: ${timeframeStartDate} realTimeFrameEndDate: ${realTimeFrameStartDate}`);// remove
      console.log(`startDate: ${startDate} startTime: ${startTime} startRealDate: ${startRealDate}`);// remove
      console.log(`realTimeFrameStartDate > startRealDate: ${realTimeFrameStartDate > startRealDate}`);// remove
      if (realTimeFrameStartDate > startRealDate) {
        console.log(`setting start date as invalid: `);//remove
        this.setState({
          startDate: null,
          startError: true,
          startMessage: 'Start date should be after timeframe start date.',
          startTime: null
        });
        hasError = true;
      }
    }

    if (timeframeEndDate && endDate !== null) {
      // use timeframe string date parts from BE to build the comparison date to avoid an offset being applied.
      const realTimeFrameEndDate = assignmentManager.convertJSStringToJSDate(timeframeEndDateStr, timeframeEndTimeStr);
      const endRealDate = assignmentManager.convertJSStringToJSDate(endDate, endTime);
      console.log(`timeframeEndDate: ${timeframeEndDate} realTimeFrameEndDate: ${realTimeFrameEndDate}`);// remove
      console.log(`endDate: ${endDate} endTime: ${endTime} endRealDate: ${endRealDate}`);// remove
      console.log(`realTimeFrameEndDate < endRealDate: ${realTimeFrameEndDate < endRealDate}`);// remove
      if (realTimeFrameEndDate < endRealDate) {
        console.log(`setting end date as invalid: `);//remove
        this.setState({
          endDate: null,
          endError: true,
          endMessage: 'End date should be before timeframe end date.',
          endTime: null
        });
        hasError = true;
      }
    }

    if (assignmentType === ASSIGNMENT_TYPE.ALL_CLASSES && currentClassrooms.length <= 0) {
      this.setState({
        serverError: true,
        serverErrorMsg: 'You must choose at least one classroom.'
      });
      hasError = true;
    }

    if (assignmentType === ASSIGNMENT_TYPE.USER && currentRoster.length <= 0) {
      this.setState({
        serverError: true,
        serverErrorMsg: 'You must choose at least one individual.'
      });
      hasError = true;
    }

    if (assignmentType === ASSIGNMENT_TYPE.GROUP && classroomGroups.length <= 0) {
      this.setState({
        serverError: true,
        serverErrorMsg: 'You must choose at least one group.'
      });
      hasError = true;
    }

    if (assignmentType === ASSIGNMENT_TYPE.CUT_SCORE && currentRoster.length <= 0) {
      this.setState({
        serverError: true,
        serverErrorMsg: 'You must choose at least one student from one category.'
      });
      hasError = true;
    }
    return !hasError;
  };

  editorChange = (value) => {
    this.setState({ editorValue: value });
  };

  renderClassroomDropdown = () => {
    const { ClassroomDropdown } = this;
    const { courseClassrooms, loadingAddAssignmentModal } = this.state;
    const classroomDropdownOptions = ClassroomService.getClassroomDropdownOptions(courseClassrooms);
    return (
      <ClassroomDropdown
        {...this.props}
        loading={loadingAddAssignmentModal}
        onChange={async (_event, option) => {
          const classroomId = option?.value;
          this.setState({
            selectedClassroomDropdownOption: option
          });
          await this.refreshAssignmentModalClassroomData(classroomId);
        }}
        options={classroomDropdownOptions} />
    );
  }

  renderAllClassrooms = () => {
    const {
      classroomManager
    } = this.props;

    const { SCCheckbox } = this;

    return (
      <Container className='check-list'>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {this.state.courseClassrooms.map((classroom, _index) => { // eslint-disable-line no-unused-vars
          // eslint-disable-next-line react/destructuring-assignment
          const checked = (this.state.currentClassrooms.indexOf(classroom.id) > -1);

          return (
            <SCCheckbox
              key={`${classroom.id}_R`}
              checked={checked}
              label={classroomManager.getClassName(classroom.id)}
              onChange={() => {
                this.handleCheckClassroom(classroom.id);
              }}
              useHoverLabel={true}
              value={classroom.id} />
          );
        })}
      </Container>
    );
  };

  renderRoster = () => {
    const { classroomManager } = this.props;

    const { selectedClassroomDropdownOption } = this.state;

    const classroomId = selectedClassroomDropdownOption?.value || classroomManager.currentClassroomId;

    const roster = classroomManager.rosterMap.get(classroomId);
    const { SCCheckbox } = this;

    if (roster === null || roster === undefined) {
      return null;
    }

    return (
      <Container className='check-list'>
        {roster.map((student, index) => { // eslint-disable-line no-unused-vars
          const checked = (this.state.currentRoster.indexOf(student.userId) > -1);
          return (
            <SCCheckbox
              key={`${student.userId + student.id}_R`}
              checked={checked}
              label={`${student.firstName} ${student.lastName}`}
              onChange={() => {
                this.handleCheckStudent(student.userId);
              }}
              useHoverLabel={true}
              value={student.userid} />
          );
        })}
      </Container>
    );
  };

  renderClassroomGroups = () => {
    const { groupsManager } = this.props;
    const { classroomGroups } = this.state;
    const { groups } = groupsManager;

    const { SCCheckbox } = this;

    if (groups === null || groups === undefined) {
      return null;
    }

    return (
      <Container className='check-list'>
        {groups.map((group) => { // eslint-disable-line no-unused-vars
          const checked = classroomGroups.find((classroomGroupId) => classroomGroupId === group.id) !== undefined;
          return (
            <SCCheckbox
              key={`${group.id}_R`}
              checked={checked}
              hoverLabelContent={() => groupsManager.renderGroupInfoPopupContent(group, false, true)}
              label={group.name}
              onChange={() => {
                this.handleCheckClassroomGroup(group.id);
              }}
              useHoverLabel={true}
              value={group.id} />
          );
        })}
      </Container>
    );
  };

  renderCutScoreList = () => {
    const { selectedCutScores, selectedCutScoreStudents, currentRoster } = this.state;
    const { t, showCutScoreAssignType, reportStandardsClassroomDetailStudentsManager } = this.props;

    const { SCCheckbox } = this;

    if (!showCutScoreAssignType) {
      return null;
    } else {
      // Getting the list of all possible cut score categories
      const ALLOWED = ReportScoreService.ALLOWED_CUT_SCORE();
      const standardsCutScores = Object.keys(ALLOWED.STANDARDS);

      // Getting the list of cut score data from the report (may not have all categories represented)
      // This map is keyed by the report color key, not the cut score key so we have to do some conversion below.
      const reportCutScores = reportStandardsClassroomDetailStudentsManager.currentCutScoreMap;
      if (!reportCutScores || reportCutScores.length < 1) {
        return null;
      }
      const cutScoreKeys = Array.from(reportCutScores.keys());
      return (
        <Container className='check-list'>
          {/* use allowed standards list to set the proper order. */}
          {standardsCutScores.map((cutScoreKey) => { // eslint-disable-line no-unused-vars
            // convert cutScoreKey to reportColorKey so we can compare with map data
            const reportColorKey = REPORT_COLOR_KEY[cutScoreKey];
            const hasStudents = cutScoreKeys.includes(reportColorKey);
            const checked = (selectedCutScores.indexOf(reportColorKey) > -1);
            const camelCaseReportTypeCutScore = _.camelCase(`standards-${cutScoreKey}`);
            const checkBoxLabel = t(`${camelCaseReportTypeCutScore}Label`);
            const checkBoxNumber = reportCutScores.get(reportColorKey) ? reportCutScores.get(reportColorKey).length : 0;
            const selectedCutScoreStudentsArray = (selectedCutScoreStudents && selectedCutScoreStudents.get(reportColorKey)) ?
              selectedCutScoreStudents.get(reportColorKey) : null;
            return (
              <div key={`${cutScoreKey}_R_wrapper`}>
                <SCCheckbox
                  key={`${cutScoreKey}_R`}
                  checked={checked}
                  disabled={!hasStudents}
                  // hoverLabelContent={() => groupsManager.renderGroupInfoPopupContent(group, false, true)}
                  label={`${checkBoxLabel} (${checkBoxNumber})`}
                  onChange={() => {
                    this.handleCheckCutScore(reportColorKey, reportCutScores.get(reportColorKey));
                  }}
                  // useHoverLabel={true}
                  value={reportColorKey} />
                {selectedCutScoreStudentsArray && (
                  <Container key={`${cutScoreKey}_R_checklist`} className='check-list'>
                    {selectedCutScoreStudentsArray.map((student) => { // eslint-disable-line no-unused-vars
                      const checked = (currentRoster.indexOf(student.studentId) > -1);
                      return (
                        <SCCheckbox
                          key={`${student.studentId}_R_student`}
                          checked={checked}
                          disabled={!hasStudents}
                          // hoverLabelContent={() => groupsManager.renderGroupInfoPopupContent(group, false, true)}
                          label={`${student.firstName} ${student.lastName}`}
                          onChange={() => {
                            this.handleCheckCutScoreStudent(student, reportColorKey);
                          }}
                          useHoverLabel={true}
                          value={student.studentId} />
                      );
                    })}
                  </Container>
                )}
              </div>
            );
          })}
        </Container>
      );
    }
  };

  renderLeftColumn = () => {
    const { SCRadio } = this;
    const { ...start } = this.state;
    const {
      assignmentType, classroomGroups,
      currentClassrooms, currentRoster
    } = this.state;
    const { reportStandardsClassroomDetailStudentsManager, hideAllClassesAssignType = false, showCutScoreAssignType, t } = this.props;
    let cutScoreLabel = '';
    if (showCutScoreAssignType) {
      const standardNameObj = UtilityService.reactHtmlParserWrapper(
        reportStandardsClassroomDetailStudentsManager.currentStandard.name
      );
      cutScoreLabel = `${t('cutScoreLabel', 'Average on')} ${standardNameObj.stripped}`;
    }
    const isGroupsRadioButtonSelected = assignmentType === ASSIGNMENT_TYPE.GROUP;
    return (
      <div className={(showCutScoreAssignType) ? 'left-column for-standard' : 'left-column'}>
        <Header as='h3'>
          {t('assignTo')}
        </Header>
        <Container className='field-wrapper'>
          <Form.Field>
            {this.renderClassroomDropdown()}
            <div className='radio-wrapper'>
              <SCRadio
                checked={assignmentType === ASSIGNMENT_TYPE.CLASSROOM_USER}
                label={t('entireClassIndividualLabel', 'Entire Class')}
                name='typeGroup'
                onChange={this.handleAssignmentType}
                value={ASSIGNMENT_TYPE.CLASSROOM_USER} />
              <Popup
                content={t('entireClassIndividualInfoText')}
                hideOnScroll
                offset={[-11, 0]}
                on='click'
                trigger={<Image alt='' className='img-info' src={info} />} />
            </div>
          </Form.Field>
          <Form.Field>
            <div className='radio-wrapper'>
              <SCRadio
                checked={isGroupsRadioButtonSelected}
                label={(classroomGroups.length > 0 && isGroupsRadioButtonSelected) ? `Groups (${classroomGroups.length})` : 'Groups'}
                name='typeGroup'
                onChange={this.handleAssignmentType}
                value={ASSIGNMENT_TYPE.GROUP} />
              <Popup
                content={t('groupInfoText')}
                hideOnScroll
                offset={[-11, 0]}
                on='click'
                trigger={<Image alt='' className='img-info' src={info} />} />
            </div>
            {(assignmentType === ASSIGNMENT_TYPE.GROUP) ? this.renderClassroomGroups() : null}
          </Form.Field>
          <Form.Field>
            <div className='radio-wrapper'>
              <SCRadio
                checked={assignmentType === ASSIGNMENT_TYPE.USER}
                label={(assignmentType === ASSIGNMENT_TYPE.USER &&
                  currentRoster.length > 0) ? `Individual (${currentRoster.length})` : 'Individual'}
                name='typeGroup'
                onChange={this.handleAssignmentType}
                value={ASSIGNMENT_TYPE.USER} />
              <Popup
                content={t('individualInfoText')}
                hideOnScroll
                offset={[-11, 0]}
                on='click'
                trigger={<Image alt='' className='img-info' src={info} />} />
            </div>
            {(assignmentType === ASSIGNMENT_TYPE.USER) ? this.renderRoster() : null}
          </Form.Field>
          {!hideAllClassesAssignType && (
            <Form.Field>
              <div className='radio-wrapper'>
                <SCRadio
                  checked={assignmentType === ASSIGNMENT_TYPE.ALL_CLASSES}
                  label={(currentClassrooms.length > 0)
                    ? `${t('allClassesLabel', 'All Classes')}(${currentClassrooms.length})`
                    : t('allClassesLabel', 'All Classes')}
                  name='typeGroup'
                  onChange={this.handleAssignmentType}
                  value={ASSIGNMENT_TYPE.ALL_CLASSES} />
                <Popup
                  content={t('allClassesInfoText')}
                  hideOnScroll
                  offset={[-11, 0]}
                  on='click'
                  trigger={<Image alt='' className='img-info' src={info} />} />
              </div>
              {/* eslint-disable-next-line no-nested-ternary */}
              {(assignmentType === ASSIGNMENT_TYPE.ALL_CLASSES) ?
                // eslint-disable-next-line react/destructuring-assignment
                (this.state.courseClassrooms.length > 0) ? this.renderAllClassrooms() : (
                  <Container className='check-list'>
                    <Loader
                      active
                      className='modal-loader'
                      inline />
                  </Container>
                ) : null}
            </Form.Field>
          )}
          {showCutScoreAssignType && (
            <Form.Field>
              <div className='radio-wrapper'>
                <SCRadio
                  checked={assignmentType === ASSIGNMENT_TYPE.CUT_SCORE}
                  label={(assignmentType === ASSIGNMENT_TYPE.CUT_SCORE &&
                    currentRoster.length > 0) ? `${cutScoreLabel} (${currentRoster.length})` : `${cutScoreLabel}`}
                  name='typeGroup'
                  onChange={this.handleAssignmentType}
                  value={ASSIGNMENT_TYPE.CUT_SCORE} />
                <Popup
                  content={t('cutScoreInfoText')}
                  hideOnScroll
                  on='click'
                  trigger={<Image alt='' className='img-info' src={info} />} />
              </div>
              {(assignmentType === ASSIGNMENT_TYPE.CUT_SCORE) ? this.renderCutScoreList() : null}
            </Form.Field>
          )}
        </Container>
        <Message
          className='start-message'
          content={start.serverErrorMsg}
          error
          visible={start.serverError} />
      </div>
    );
  }

  renderRightColumn = () => {
    const {
      allowMultipleResources,
      groupsManager,
      accommodationsManager,
      assignmentManager,
      contentItemEntityTypeId,
      contentItemEntitySubTypeId,
      courseResourceElementId,
      modeOverrideAllowed,
      t
    } = this.props;

    const { includeAssignmentAccommodations } = accommodationsManager;
    const { lateSubmissionOptionAllowed } = assignmentManager;

    // TODO hidden for now per DEMO-1913
    const { groupResourceSelectorCheckedChildContentItemIds, loadingGroupResourceSelector } = groupsManager;
    const checkedChildContentItemIds = groupResourceSelectorCheckedChildContentItemIds;

    const {
      activityModeId, allowLateSubmission, assignmentType, currentRoster, showKeyboardingSettings,
      editorValue, includeInReports, lateSubmitDate, lateSubmitTime, scoresReleased, studentReview,
      typingAllowBackspace, typingText, typingDuration, typingMinimumSpeed, typingMinimumAccuracy
    } = this.state;
    const { ...start } = this.state;
    const { ...end } = this.state;
    // const { timeframeStartDate, timeframeEndDate } = this.state;
    const { AccommodationsTogglerSection, GroupResourceSelector } = this;

    // eslint-disable-next-line max-len
    const validLateSubmissionAssignmentType = (assignmentType !== ASSIGNMENT_TYPE.CLASSROOM && assignmentType !== ASSIGNMENT_TYPE.ALL_CLASSES);

    const { allowBackspaceEdit,
      allowBackpaceShow,
      allowDurationEdit,
      allowDurationShow,
      allowMinSpeedEdit,
      allowMinSpeedShow,
      allowMinAccuracyEdit,
      allowMinAccuracyShow,
      allowTestTextEdit,
      allowTestTextShow } = CONTENT_ITEM_SUB_TYPES.getVisibilityPermissions(contentItemEntitySubTypeId);

    return (
      <div className='right-column'>
        {/* TODO hidden for now per DEMO-1913 */}
        {allowMultipleResources && (
          <>
            <Header as='h3'>{t('assignAll')}</Header>
            <GroupResourceSelector {...this.props} handleNoAssignableResources={this.handleNoAssignableResources}/>
            <Message
              content={t('oneOrMoreResourcesMustBeSelected')}
              error
              visible={!loadingGroupResourceSelector && !checkedChildContentItemIds?.length} />
          </>
        )}
        {(start.timeframeStartDate || end.timeframeEndDate) && (
          <>
            <Header as='h3'>{t('timeFrame')}</Header>
            <div className='sub-text'>
              {t('timeFrameDescription')}
            </div>
            <Container className='field-wrapper'>
              <Container className='time-display'>
                <Form.Field>
                  <label>{t('startDate')}: </label>
                  <span>{start.formattedTimeframeStartDate}</span>
                </Form.Field>
                <Form.Field>
                  <label>{t('startTime')}: </label>
                  <span>{start.formattedTimeframeStartTime}</span>
                </Form.Field>
              </Container>
              <Container className='time-display'>
                <Form.Field>
                  <label>{t('endDate')}: </label>
                  <span>{end.formattedTimeframeEndDate}</span>
                </Form.Field>
                <Form.Field>
                  <label>{t('endTime')}: </label>
                  <span>{end.formattedTimeframeEndTime}</span>
                </Form.Field>
              </Container>
            </Container>
          </>
        )}
        <Header as='h3'>{t('dateRange')}</Header>
        {(start.timeframeStartDate || start.timeframeEndDate) &&
          <div className='sub-text'>
            {t('dateRangeDescription')}
          </div>
        }
        <Container className='field-wrapper'>
          <Container className='time-controls'>
            <Form.Field>
              <DateInput
                animation='false'
                className='date-input'
                dateFormat='MM/DD/YYYY'
                iconPosition='left'
                label={t('startDate')}
                name='startDate'
                onChange={this.handleChangeStartDate}
                placeholder={t('startDate')}
                value={(start.startDate !== null) ? start.startDate : ''} />
            </Form.Field>
            <Form.Field>
              <TimeInput
                animation='false'
                className='time-input'
                iconPosition='left'
                label={t('startTime')}
                name='startTime'
                onChange={this.handleChangeStartTime}
                placeholder={t('startTime')}
                timeFormat='AMPM'
                value={(start.startTime !== null) ? start.startTime : ''} />
            </Form.Field>
          </Container>
          <Message
            className='start-message'
            content={start.startMessage}
            error
            visible={start.startError} />
        </Container>
        <Container className='field-wrapper'>
          <Container className='time-controls'>
            <Form.Field>
              <DateInput
                animation='false'
                className='date-input'
                dateFormat='MM/DD/YYYY'
                iconPosition='left'
                label={t('dueDate')}
                name='endDate'
                onChange={this.handleChangeEndDate}
                placeholder={t('dueDate')}
                value={(end.endDate !== null) ? end.endDate : ''} />
            </Form.Field>
            <Form.Field>
              <TimeInput
                animation='false'
                className='time-input'
                iconPosition='left'
                label={t('dueTime')}
                name='endTime'
                onChange={this.handleChangeEndTime}
                placeholder={t('dueTime')}
                timeFormat='AMPM'
                value={(end.endTime !== null) ? end.endTime : ''} />
            </Form.Field>
          </Container>
          <Message
            content={end.endMessage}
            error
            visible={end.endError} />
        </Container>
        {(lateSubmissionOptionAllowed && validLateSubmissionAssignmentType) && (
          <>
            <Header as='h3'>
              <div className='allow-late-submit-control'>
                <Radio
                  checked={allowLateSubmission}
                  disabled={!lateSubmissionOptionAllowed}
                  label={t('lateSubmission')}
                  onChange={() => { this.setLateSubmission(!allowLateSubmission); }}
                  toggle />
                <Popup
                  content={t('lateSubmissionText')}
                  hideOnScroll
                  on='click'
                  position='top center'
                  trigger={<Image alt='' className='img-info' src={info} />} />
              </div>
            </Header>
            <Container className='field-wrapper'>
              {allowLateSubmission && (
                <Container className='time-controls'>
                  <Form.Field>
                    <DateInput
                      animation='false'
                      className='date-input'
                      dateFormat='MM/DD/YYYY'
                      iconPosition='left'
                      label={t('lateSubmitDateLabel')}
                      name='endDate'
                      onChange={this.handleChangeLateSubmitDate}
                      placeholder='Late Due Date'
                      value={(lateSubmitDate !== null) ? lateSubmitDate : ''} />
                  </Form.Field>
                  <Form.Field>
                    <TimeInput
                      animation='false'
                      className='time-input'
                      iconPosition='left'
                      label='&nbsp;'
                      name='lateSubmitTime'
                      onChange={this.handleChangeLateSubmitTime}
                      placeholder='Late Due Time'
                      timeFormat='AMPM'
                      value={(lateSubmitTime !== null) ? lateSubmitTime : ''} />
                  </Form.Field>
                </Container>
              )}
              <Message
                content={end.lateSubmitMessage}
                error
                visible={end.lateSubmitError} />
            </Container>
          </>
        )}
        {modeOverrideAllowed && (
          <>
            <Header as='h3'>
              {t('modeOption')}
            </Header>
            <div className='sub-text'>
              {t('modeOptionDescription')}
            </div>
            <Container className='field-wrapper'>
              <ActivityModeDropdown
                activityMode={activityModeId}
                courseResourceElementId={courseResourceElementId}
                setActivityMode={this.setActivityMode} />
            </Container>
          </>
        )}
        <Header as='h3'>
          {t('reportsAndGrading')}
        </Header>
        <div className='sub-text'>
          {t('reportsAndGradingDescription')}
        </div>
        <Container className='field-wrapper'>
          <Form.Field className='check-list'>
            <Radio
              checked={includeInReports}
              label={t('releaseToReportsLabel')}
              onChange={() => { this.handleRelease('includeInReports', !includeInReports); }}
              toggle />
            <Radio
              checked={scoresReleased}
              label={t('scoresReleased')}
              onChange={() => { this.handleRelease('scoresReleased', !scoresReleased); }}
              toggle />
            <Radio
              checked={studentReview}
              label={t('studentReview')}
              onChange={() => { this.handleRelease('studentReview', !studentReview); }}
              toggle />
          </Form.Field>
        </Container>
          <>
            {(contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) || showKeyboardingSettings &&
              <Header as='h3'>
                {t('additionalSettings')}
              </Header>
            }
            <Container className='editor-container additionalSettings'>
              {/* Add lessons and assessments to this filter when the showCorrectAnswers feature gets rolled out. */}
              {((contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE)||Auth.showShuffleOverride) && (
                <>
                  {(contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) && (
                  <Form.Field>
                    <Radio
                      checked={this.state.randomizeQuestions} // eslint-disable-line react/destructuring-assignment
                      label={t('randomizeQuestions', 'Randomize question order')}
                      name='randomizeQuestions'
                      onClick={(event) => this.handleClickRandomizeQuestions(event)} />
                  </Form.Field>
                  )}
                  {((contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE)||Auth.showShuffleOverride) && (
                  <Form.Field>
                    <div className={`shuffleItemsWrapper ${Auth.showShuffleOverride?'showShuffleOverride':''}`}>
                      {Auth.showShuffleOverride?
                        (<Checkbox
                        checked={!this.state.ignoreItemOptionShuffle} // eslint-disable-line react/destructuring-assignment
                        label={t('ignoreItemOptionShuffle', 'Ignore item option shuffle')}
                        name='ignoreItemOptionShuffle'
                        onClick={(event) => this.handleClickIgnoreItemOptionShuffle(event)} />)
                        :
                        (<Radio
                        checked={!this.state.ignoreItemOptionShuffle} // eslint-disable-line react/destructuring-assignment
                        label={t('ignoreItemOptionShuffle', 'Ignore item option shuffle')}
                        name='ignoreItemOptionShuffle'
                        onClick={(event) => this.handleClickIgnoreItemOptionShuffle(event)} />)}
                      <Popup
                        content={t('ignoreItemOptionShuffleMessage')}
                        hideOnScroll
                        on='hover'
                        trigger={<Image alt='' className='img-info' src={info} />}
                        wide />
                    </div>
                  </Form.Field>
                  )}
                </>
              )}
              {/* <Form.Field>  TODO - unhide this when this feature gets rolled out
                { (contentItemEntityTypeId === CONTENT_ITEM_TYPES.LESSON ||
                    contentItemEntityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT) &&
                  <Radio
                    checked={this.state.showCorrectAnswers}
                    label={'Show feedback'}
                    name='showCorrectAnswers'
                    onClick={(event) => this.handleClickShowCorrectAnswers(event)}/>
                }
              </Form.Field> */}
              {showKeyboardingSettings && (
                <>
                  {allowBackpaceShow &&
                    <Form.Field>
                      <div className='resource-typing-input-wrapper'>
                        <Radio
                          checked={typingAllowBackspace}
                          label={t('allowBackSpaceLabel')}
                          onChange={() => { this.handleChangeTypingBackspace(!typingAllowBackspace); }}
                          disabled={!allowBackspaceEdit}
                          toggle />
                      </div>
                    </Form.Field>
                  }
                  {allowTestTextShow &&
                   <Form.Field>
                      <div className="resource-option-label">{t('typingTextLabel')}</div>
                      <div className='resource-typing-input-wrapper'>
                        <Input
                          className='input-typing-text'
                          onChange={this.handleChangeTypingText}
                          size='mini'
                          disabled={!allowTestTextEdit}
                          value={typingText} />
                      </div>
                    </Form.Field>
                  }
                  {allowDurationShow &&
                    <Form.Field>
                      <div className="resource-option-label">{t('typingDurationLabel')}</div>
                      <div className='resource-typing-input-wrapper'>
                        <Input
                          className='input-typing-text'
                          onChange={this.handleChangeTypingDuration}
                          size='mini'
                          disabled={!allowDurationEdit}
                          value={typingDuration} />
                      </div>
                    </Form.Field>
                  }
                  <div className='resource-typing-input-group'>
                    {allowMinSpeedShow &&
                      <Form.Field>
                        <div className='resource-typing-input-wrapper col'>
                          <div className="resource-option-label">{t('typingMinimumSpeedLabel')}</div>
                          <Input
                            className='input-typing-text'
                            onChange={this.handleChangeMinimumSpeed}
                            size='mini'
                            disabled={!allowMinSpeedEdit}
                            value={typingMinimumSpeed} />
                        </div>
                      </Form.Field>
                    }
                    {allowMinAccuracyShow &&
                      <Form.Field>
                        <div className='resource-typing-input-wrapper col'>
                          <div className="resource-option-label">{t('typingMinimumAccuracyLabel')}</div>
                          <Input
                            className='input-typing-text'
                            onChange={this.handleChangeMinimumAccuracy}
                            size='mini'
                            disabled={!allowMinAccuracyEdit}
                            value={typingMinimumAccuracy} />
                        </div>
                      </Form.Field>
                    }
                  </div>
                </>
              )}
            </Container>
          </>
        <Header as='h3'>
          {t('studentInstructions')}
        </Header>
        <Container className='editor-container'>
          <Form.Field>
            <RichTextEditor
              data={editorValue}
              maxCharacters={500}
              onChange={this.editorChange} />
          </Form.Field>

          {includeAssignmentAccommodations && (
            <AccommodationsTogglerSection {...this.props}
              assignmentType={assignmentType}
              rosterItems={currentRoster}
              style={{ marginTop: '30px' }} />
          )}
        </Container>
      </div>
    );
  }

  handleNoAssignableResources = () => {
    this.setState({ hasAssignableResources: false });
  }

  getDefaultImage = () => {
    const { allowMultipleResources, courseManager } = this.props;
    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);

    let assignmentModalImageClassNames = isCustomCourse ? 'custom-default' : 'default';

    let defaultImg;
    if (allowMultipleResources) {
      defaultImg = chapterDefaultImage;
      assignmentModalImageClassNames += ' branch';
    } else {
      defaultImg = assessmentDefaultImage;
    }

    if (courseManager.useSpecialCustomCourseCardImages && isCustomCourse) {
      assignmentModalImageClassNames += 'custom-default-special';
      defaultImg = customAssessmentDefaultCardImageUri;
    }
    return (
      <Image
        alt='Lesson default image'
        className={assignmentModalImageClassNames}
        src={defaultImg} />
    );
  }

  setLateSubmission = (allowLateSubmission) => {
    const { endDate, endTime } = this.state;
    if (allowLateSubmission) {
      this.setState({ lateSubmitDate: endDate, lateSubmitTime: endTime });
    }
    this.setState({ allowLateSubmission, lateSubmitError: false, lateSubmitMessage: '' });
  }

  setActivityMode = (activityModeId) => {
    this.setState({ activityModeId });
  }

  renderConfirmAddAssignmentModalIfApplicable = () => {
    const { GenericModal } = this;
    const { t } = this.props;
    const { confirmModalErrorMessage, isConfirming } = this.state;

    return isConfirming && (
      <GenericModal
        bodyMsg={!confirmModalErrorMessage ? t('confirmCloseAddAssignmentModalBodyMsg') : confirmModalErrorMessage}
        buttonKeyCancelLabel={!confirmModalErrorMessage ? 'no' : ''}
        buttonKeyConfirmLabel={!confirmModalErrorMessage ? 'yes' : 'ok'}
        className='ConfirmAddAssignmentModal'
        headerMsg={!confirmModalErrorMessage ? t('confirmCloseAddAssignmentModalHeaderMsg') : t('error')}
        isOpen={isConfirming}
        minHeight={confirmModalErrorMessage ? 250 : undefined}
        minWidth={confirmModalErrorMessage ? 455 : undefined}
        onClose={(event, actionButtonData) => {
          const hasUserClickedOutsideOfConfirmationModal = event?.target?.className === 'flexible-modal-mask';
          if (hasUserClickedOutsideOfConfirmationModal) {
            // we do not want any actions to happen for this case, so skip the remaining logic
            return;
          }
          const hasConfirmedCreateMoreAssignments = actionButtonData?.className?.includes('yes');
          const hasClickedConfirmModalCloseIcon = actionButtonData?.icon === 'close';
          const keepAddAssignmentModalOpen = !confirmModalErrorMessage && (
            !actionButtonData || hasConfirmedCreateMoreAssignments || hasClickedConfirmModalCloseIcon
          );
          if (keepAddAssignmentModalOpen) {
            this.setState({
              isConfirming: false
            });
          } else if (confirmModalErrorMessage) {
            this.setState({
              confirmModalErrorMessage: '',
            });
          }
          if (!keepAddAssignmentModalOpen) {
            this.closeAddAssignmentModal();
          }
        }} />
    );
  }

  render() {
    const {
      addAssignmentOpen, allowMultipleResources, assignmentManager,
      classroomManager, contentItemEntityId, contentName, contentSubname,
      courseElement, groupsManager, displayOverModal, showCutScoreAssignType, standards, t
    } = this.props;

    let { contentImageUrl } = this.props;

    const { loadingGroupResourceSelector } = groupsManager;

    const {
      addingAssignment,
      hasAssignableResources,
      isConfirming,
      loadingAddAssignmentModal,
      nickname,
      selectedClassroomDropdownOption
    } = this.state;

    const { ModalBannerDraggable, StandardsList } = this;

    const classroomId = selectedClassroomDropdownOption?.value || classroomManager.currentClassroomId;

    const classroom = classroomManager.getClassroom(classroomId);
    if (classroom === null) {
      return (
        <ReactModalDraggable
          className='AddAssignmentModal'
          closeOnDimmerClick={false}
          closeOnEscape={!isConfirming}
          disableKeystroke={isConfirming}
          onClose={this.confirmCloseAddAssignmentModal}
          onRequestClose={(event) => {
            const isOverlay = event?.target?.className === 'flexible-modal-mask';
            if (!isOverlay && !isConfirming) {
              this.closeAddAssignmentModal();
            }
          }}
          open={addAssignmentOpen}>
          <ModalBannerDraggable
            label='Assign'
            onClose={this.closeAddAssignmentModal} />
        </ReactModalDraggable>
      );
    }
    const now = new Date();
    const endDate = new Date(classroom.timezoneEndDate);

    const expired = (endDate < now);
    const notStarted = false; // (startDate > now); TODO: KEEP THIS IN CASE THE RESTRICTION IS REINSTATED.

    const parsedContentNameObj = UtilityService.reactHtmlParserWrapper(contentName);
    const parsedContentSubnameObj = UtilityService.reactHtmlParserWrapper(contentSubname);

    let assignmentModalClassNames = 'AddAssignmentModal';
    if (showCutScoreAssignType) {
      assignmentModalClassNames += ' for-standard';
    }
    if (allowMultipleResources) {
      assignmentModalClassNames += ' multiple-resources';
    }
    // TODO remove
    // const groupResourceSelectorHasError = allowMultipleResources &&
    //   !loadingGroupResourceSelector && !groupsManager.groupResourceSelectorCheckedChildContentItemIds?.length;
    const disabledSubmitAssignment = addingAssignment || isConfirming || loadingGroupResourceSelector;
    const disableCancelButton = assignmentManager.showProgress;

    if (!contentImageUrl && courseElement?.attachmentContentItemId) {
      contentImageUrl = CONTENT_ITEM_TYPES.getContentIdImageUrl(
        courseElement.attachmentContentItemId
      );
    }

    const nicknameText = nickname && nickname !== 'undefined' ? nickname : '';

    let className = 'assignmentModal';
    if (displayOverModal) {
      className += ' displayOverModal';
    }

    return (
      <div className={assignmentModalClassNames}>
        <ReactModalDraggable
          className={className}
          closeOnDimmerClick={false}
          closeOnEscape={!isConfirming}
          disableKeystroke={isConfirming}
          isOpen={addAssignmentOpen}
          onRequestClose={(event) => {
            const isOverlay = event?.target?.className === 'flexible-modal-mask';
            if (!isOverlay && !isConfirming) {
              this.closeAddAssignmentModal();
            }
          }}>
          <div ref={this.modalRef} className='modalBody' tabIndex={-1}>
            <ModalBannerDraggable
              label='Assign'
              onClose={this.closeAddAssignmentModal} />
            <div className='content'>
              {hasAssignableResources &&
                <>
                  <div className='assignment-banner'>
                    {contentImageUrl ? <Image src={contentImageUrl} /> : this.getDefaultImage()}
                    <div className='title-wrapper'>
                      <div
                        className='flex-header'
                        title={parsedContentNameObj.stripped}>
                        {parsedContentNameObj.parsed}
                      </div>
                      <div
                        className='flex-subheader'
                        title={parsedContentSubnameObj.stripped}>
                        {parsedContentSubnameObj.parsed}
                      </div>
                      <StandardsList
                        assignmentId={contentItemEntityId}
                        contentImageUrl={contentImageUrl}
                        standards={standards}
                        title={contentName} />
                      {assignmentManager.useAssignmentNickname && (
                        <div className='nickname-wrapper'>
                          <Container className='nickname-container'>
                            <div className='label'>
                              {`${t('nicknameLabel', 'Nickname')}`}:
                            </div>
                            <Form.Field>
                              <Input
                                className='nickname-input'
                                name='nickname'
                                onChange={this.handleChangeNickname}
                                placeholder={t('nicknamePlaceholder', 'Enter nickname...')}
                                value={nicknameText} />
                            </Form.Field>
                          </Container>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {(expired) ? (
                    <Container className='warning'>
                      <p>{t('classExpiredText')}</p>
                      <p>{t('classExpiredSubtext')}</p>
                    </Container>
                  )
                    : (notStarted) ? (
                      <Container className='warning'>
                        <p>{t('classNotStartedText')}</p>
                        <p>{t('classNotStartedSubtext')}</p>
                      </Container>
                    ) : (
                      <Form>
                        <Container className='assignment-wrapper-modal'>
                          <Loader active={loadingAddAssignmentModal} />
                          {this.renderLeftColumn()}
                          {this.renderRightColumn()}
                        </Container>
                      </Form>
                    )}
                </>
              }
              {!hasAssignableResources &&
                <div className='noAssignable'>
                    {t('noAssignableMessage', 'There are no assignable resources avaiable in this section.')}
                </div>
              }
            </div>
            <div className='actions'>
              <ActivityProgressBar />
              <Button
                basic
                className='cancelButton'
                disabled={disableCancelButton}
                onClick={this.closeAddAssignmentModal}
                primary>
                {t('cancel')}
              </Button>

              {(!expired && hasAssignableResources) && (
                <Button
                  className='ui primary button saveButton'
                  disabled={disabledSubmitAssignment}
                  loading={addingAssignment || loadingGroupResourceSelector}
                  onClick={() => {
                    this.submitAddAssignment().catch((error) => {
                      error = error instanceof Error && error?.message ? error.message : t('submitAddAssignmentError');
                      this.confirmCloseAddAssignmentModal({
                        confirmModalErrorMessage: error
                      });
                    });
                  }}
                  type='button'>
                  {t('assign')}
                </Button>
              )}
            </div>
          </div>
        </ReactModalDraggable>
        {this.renderConfirmAddAssignmentModalIfApplicable()}
      </div>
    );
  }
}

SatCoreRegister('AddAssignmentModal', AddAssignmentModal);
