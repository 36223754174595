/* eslint-disable sort-keys */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { kebabCase } from 'lodash';
import {
  Button, Container, Header, Loader, Pagination
} from 'semantic-ui-react';
import Modal from '../Modal';
import '../../css/ItemBankViewModal.less';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import { DIALOG_NAMES } from '../../managers/DialogManager';
import TagContentManager from '../../managers/TagContentManager';

export default
@inject(
  'courseManager',
  'assessmentManager',
  'itemBankManager',
  'dialogManager',
  'tagContentManager',
  'libraryManager'
)
@observer
class ItemBankViewModal extends Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    // props specifying what this modal is being used for
    assessmentItemsSearch: false,
    courseResourcesSearch: false,
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.ItemBankFilter = SatCoreComponent('ItemBankFilter');
    this.CourseLayout = SatCoreComponent('CourseLayout');
    this.ItemBankCard = SatCoreComponent('ItemBankCard');
  }

  getInitialState = () => ({
    activePage: 1,
    customAssessmentName: '',
    customAssessmentSubtitle: '',
    serverError: false,
    serverErrorMsg: ''
  })

  closeThisModal = (fromSave) => {
    const { assessmentManager, closeItemBankModal } = this.props;
    assessmentManager.setSearchText('');
    this.setState(this.getInitialState());
    closeItemBankModal(fromSave);
  }

  handlePageChange = async (_event, pageInfo) => {
    const { itemBankManager, libraryCardData } = this.props;
    const { activePage } = pageInfo;

    await itemBankManager.searchContentItemsForItemBank(
      libraryCardData.id,
      null,
      activePage,
      25,
      'modificationDate',
      'desc');

    // await this.handleFetchItemBankItems(searchOperator, searchTerms, searchTags, searchStandards, activePage);
    this.setState({ activePage });
  }

  handleFetchItemBankItems= async (searchCondition = null, searchTerms = null, searchTags = null, searchStandards = null, searchQuestionTypes = null, page) => {
    const { itemBankManager, libraryCardData } = this.props;
    const activePage = 1;

    await itemBankManager.searchContentItemsForItemBank(
      libraryCardData.id,
      searchQuestionTypes,
      activePage,
      25,
      'modificationDate',
      'desc');

    this.setState({ activePage });
  }

  handleShowItemDetails = async (libraryCardData) => {
    const { dialogManager, libraryManager, itemBankManager, t } = this.props;
    const thePopupElement = document.getElementsByClassName('resource-option-popup')[0];
    if (thePopupElement) {
      thePopupElement.style.setProperty('visibility', 'hidden', 'important');
    }
    const results = await libraryManager.getStandardTagAlignmentsByContentId(libraryCardData.id);
    dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_STANDARDS_TAGS_MODAL, {
      standards: results.standards,
      tags: results.tags,
      text: libraryCardData.libraryResource.questionStem,
      type: itemBankManager.getQuestionTypeText(libraryCardData.libraryResource.entitySubTypeId),
      guid: libraryCardData.id,
      modalTitle: t('itemDetailsModalTitle'),
      modalType: 'item-details',
      name: libraryCardData.libraryResource.name,
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_STANDARDS_TAGS_MODAL));
  }

  handleItemPreview = async (data) => {
    const { dialogManager } = this.props;
    const thePopupElement = document.getElementsByClassName('resource-option-popup')[0];
    if (thePopupElement) {
      thePopupElement.style.setProperty('visibility', 'hidden', 'important');
    }
    dialogManager.setOpenDialog(DIALOG_NAMES.ITEM_BANK_ITEM_PREVIEW, {
      contentItemId: data.id
    }, () => dialogManager.closeDialog(DIALOG_NAMES.ITEM_BANK_ITEM_PREVIEW));
  }

  handleShowItemUsage = async (libraryCardData) => {
    const { dialogManager } = this.props;
    const thePopupElement = document.getElementsByClassName('resource-option-popup')[0];
    if (thePopupElement) {
      thePopupElement.style.setProperty('visibility', 'hidden', 'important');
    }
    dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_CONTENT_USAGE_MODAL, {
      contentItemId: libraryCardData.id
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_CONTENT_USAGE_MODAL));
  }

  renderItemBankCards = () => {
    const { itemBankManager } = this.props;
    const { ItemBankCard } = this;

    const libraryCards = [];
    if (itemBankManager.itemBankItems && itemBankManager.itemBankItems.length > 0) {
      itemBankManager.itemBankItems.map((data, index) => {
        const useCardClickTypes = 'course_resource'; // TODO should other cards have a body click action?
        const useCardClick = useCardClickTypes.includes(data.libraryResource?.entityTypeId);
        const libraryCardKebabOptions = this.getLibraryCardOptions(data);
        libraryCards.push(
          <ItemBankCard key={index}
            handleShowItemDetails={this.handleShowItemDetails}
            libraryCardKebabOptions={libraryCardKebabOptions}
            libraryCardParams={data}
            onBodyClick={this.handleItemPreview} />);
      });
    }
    return (
      <div className='library-view-card-container'>
        {libraryCards}
      </div>
    );
  }

  getLibraryCardOptions = (data) => {
    const { t } = this.props;
    const options = {};
    // define common options
    const preview = {
      key: 'preview',
      label: t('previewOptionLabel'),
      callback: this.handleItemPreview
    };
    const details = {
      key: 'details',
      label: t('detailsOptionLabel'),
      callback: this.handleShowItemDetails
    };
    const usage = {
      key: 'usage',
      label: t('usageOptionLabel'),
      callback: this.handleShowItemUsage
    };

    options.preview = preview;
    options.details = details;
    options.usage = usage;

    return options;
  }

  renderItemBankModalContent = () => {
    const { assessmentItemsSearch } = this.props;
    return (
      <div className={classNames('item-bank-search-modal-content-container')}>
        <div className='item-bank-search-modal-content-left scrolling content'>
          {this.renderItemBankFilter({
            searchType: assessmentItemsSearch ? 'assessmentItemsSearch' : 'courseResourcesSearch'
          })}
        </div>
        <div className='item-bank-search-modal-content-rest scrolling content'>
          <div className='item-bank-search-modal-content-mid'>
            {/* placeholder */}
          </div>
          <div className='item-bank-search-modal-content-right'>
            {this.renderItemCards()}
          </div>
        </div>

      </div>
    );
  }

  /** @param {{ searchType: 'assessmentItemsSearch' | 'courseResourcesSearch' }} */
  renderItemBankFilter = ({ searchType } = {}) => {
    // things needed for the item bank filter;
    const { tagContentManager, libraryCardData } = this.props;
    tagContentManager.fetchAuthorityStates();

    const { ItemBankFilter } = this;
    return (
      <div className={`item-bank-filter-wrapper ${kebabCase(searchType)}`}>
        <ItemBankFilter
          assessmentItemsSearch={searchType === 'assessmentItemsSearch'}
          courseResourcesSearch={searchType === 'courseResourcesSearch'}
          handleFetchItemBankItems={searchType === 'assessmentItemsSearch' ?
            this.handleFetchItemBankItems : this.handleFetchCourseResources}
          itembankId={libraryCardData.id}
          multiSelect={true} />
      </div>
    );
  };

  renderItemCards = () => {
    const { CourseLayout } = this;
    const { assessmentItemsSearch, courseResourcesSearch, courseManager, itemBankManager, history } = this.props;
    const { activePage, itemBankLoading, totalPages, totalResults } = itemBankManager;
    const { assessmentManager, t } = this.props;

    const length = (itemBankManager.itemBankItems) ? itemBankManager.itemBankItems.length : 0;

    // const totalPages = (assessmentItemsSearch) ? assessmentManager.totalPages : courseManager.totalPages;
    const { totalItems } = courseManager;
    const itemCount = courseManager.searchCourseElementList.length;
    let courseSearchResultsCounts = '';

    if (courseResourcesSearch && length > 0) {
      courseSearchResultsCounts = `Showing ${itemCount} of ${totalItems} results`;
    }

    const startResultNumber = activePage <= 1 ? 1 : (activePage - 1) * 25 + 1;
    const endResultNumber = (activePage * 25) > totalResults ? totalResults : activePage * 25;
    const paginationJsx = (
      <div className='paginator-container'>
        <div className='pagination-text'>
          {`${startResultNumber}-${endResultNumber} of ${totalResults}`}
        </div><Pagination
          activePage={activePage}
          onPageChange={this.handlePageChange}
          totalPages={totalPages} />
      </div>
    );

    let headerContentJsx = <></>;
    if (length > 0) {
      headerContentJsx = paginationJsx;
    } else if (assessmentItemsSearch && !assessmentManager.assessmentItemsLoading
        && assessmentManager.originalAssessmentItems.size <= 0
    ) {
      if (sessionStorage.getItem('ItemBankFilter')) {
        headerContentJsx = t('noResultsZeroStateMsg');
      } else {
        headerContentJsx = 'No Items Found';
      }
    } else if (courseResourcesSearch && !courseManager.isLoading
        && courseManager.searchCourseElementList.length === 0
    ) {
      headerContentJsx = t('noResultsZeroStateMsg');
    } else if (assessmentManager.assessmentItemsLoading || courseManager.isLoading) {
      headerContentJsx = (
        <Loader active className='modal-loader modal-loader-small-font' />
      );
    } else if (assessmentManager.originalAssessmentItems.size > 0) {
      headerContentJsx = t('noResultsZeroStateMsg');
    }

    return (
      <Container className='item-bank-list-container' fluid>
        <div className={classNames('modal-content-header', {
          'item-bank-list-header-sticky': !assessmentManager.assessmentItemsLoading && !courseManager.isLoading
        })}>
          <div className='results-counts'>{courseSearchResultsCounts}</div>
          <div className='modal-content-header-pagination'>
            {headerContentJsx}
          </div>
        </div>
        {(courseResourcesSearch && courseManager.searchCourseElementList.length > 0) && (
          <Container className='class-content resources-container class-details-inner-container' fluid>
            <CourseLayout
              courseElementList={courseManager.searchCourseElementList}
              history={history}
              isCourseSearch={true}
              showBanners={true}
              showTags={true} />
          </Container>
        )}
        {assessmentItemsSearch && this.renderItemBankCards()}
      </Container>
    );
  }

  render() {
    const { libraryCardData } = this.props;

    const { displayAssessmentItemShopModal, t } = this.props;
    return (
      <>
        <Modal
          className='ItemBankViewModal'
          closeOnDimmerClick={false}
          closeOnEscape={false}
          onClose={this.closeThisModal}
          open={displayAssessmentItemShopModal}
          size='fullscreen'
          style={{ overflow: 'hidden', top: '-14px' }}>
          <>
            <Modal.Header className='modal-header'>
              <Header.Content className='modal-header-bar'>
                <span className='modal-header-label'>
                  {libraryCardData.title}
                </span>
                <div className='modal-header-buttons'>
                  <Button
                    basic
                    className='cancelButton'
                    onClick={this.closeThisModal}
                    primary>
                    {t('Exit')}
                  </Button>
                </div>
              </Header.Content>
            </Modal.Header>
            <div className='nav-separator' />
            {this.renderItemBankModalContent()}
          </>
        </Modal>
      </>
    );
  }
}

SatCoreRegister('ItemBankViewModal', ItemBankViewModal);
