import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Button, Form, Loader } from 'semantic-ui-react';

import Modal from '../Modal';

import '../../css/CreateAssessmentBankUnitModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { DIALOG_NAMES } from '../../managers/DialogManager';

export default
@inject('courseManager')
class CreateAssessmentBankUnitModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.ModalBanner = SatCoreComponent('ModalBanner');
  }

  getInitialState = () => {
    return {
      error: null,
      originalDescription: '',
      originalName: '',
      selectedResourceType: '',
      unitDescription: '',
      unitName: '',
    };
  }

  componentDidMount = async () => {
    const { courseContentItemId, isEdit } = this.props;
    if (isEdit && courseContentItemId) {
      // if edit get the unit details from props?

    }
  }

  clearError = () => {
    this.setState({ error: null });
  }

  handleChangeUnitDescription = async (event) => {
    const unitDescription = event.target.value;
    this.setState({ unitDescription });
  }

  handleChangeUnitName = async (event) => {
    const unitName = event.target.value;
    this.setState({ unitName });
  }

  createOrEditUnit = async () => {
    const { courseManager, courseContentItemId, isEdit, t } = this.props;
    const { originalDescription, originalName, unitDescription, unitName } = this.state;
    let success = false;
    if (isEdit) {
      if (originalName !== unitName || originalDescription !== unitDescription) {
        success = await courseManager.updateCourseResourceUnit(unitName, unitDescription);
      }
    } else {
      if (courseContentItemId && courseContentItemId.length > 0) {
        success = await courseManager.createCourseResourceUnit(courseContentItemId, unitName, unitDescription);
      }
    }
    if (success) {
      this.closeCreateAssessmentBankUnitModal(true);
    } else {
      this.showTextMessage('Error creating or updating unit.');
    }
  }

  showTextMessage = (title, message) => {
    const { dialogManager } = this.props;
    if (message) {
      dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
        title,
        message
      }, () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
    }
  }

  validate = () => {
    const { isEdit } = this.props;
    const { originalDescription, originalName } = this.state;
    let { unitDescription, unitName } = this.state;
    // remove whitespace around values
    unitDescription = unitDescription.trim();
    unitName = unitName.trim();
    let isValid = false;
    if (isEdit) {
      // for edit if anything has changed, the change is valid.
      isValid = originalDescription !== unitDescription;
      if (isValid) {
        return isValid;
      }
      isValid = !!((unitName && unitName.length > 0) && (originalName !== unitName));
      if (isValid) {
        return isValid;
      }
    } else {
      // for add all the items need to be valid.
      isValid = !!(unitDescription);
      isValid = !!((unitName && unitName.length > 0));
    }
    return isValid;
  }

  closeCreateAssessmentBankUnitModal = (refresh = false) => {
    const { closeCreateAssessmentBankUnitModal } = this.props;
    closeCreateAssessmentBankUnitModal(refresh);
  }

  render() {
    const { close, createAssessmentBankUnitModalOpen, isEdit, t } = this.props;
    const {
      isSaving, unitName, unitDescription
    } = this.state;
    const { ModalBanner } = this;
    return (
      <Modal className='create-assessment-bank-unit-modal' open={createAssessmentBankUnitModalOpen} size='tiny'>
        <ModalBanner
          label={t('createNewUnitHeader', 'Create New Unit')}
          onClose={close} />
        <Modal.Content>
          <div className='inputLabelText'>{t('unitNameLabelText')}</div>
          <div className='inputLabelSubText'>{t('unitNameLabelSubText')}</div>
          <Form.Field className='create-new-unit-field'>
            <Form.Input
              className='inputText'
              name='unitName'
              onChange={this.handleChangeUnitName}
              placeholder={t('inputPlaceholderText')}
              type='text'
              value={unitName} />
          </Form.Field>
          <div className='inputLabelText'>{t('descriptionLabelText')}</div>
          <div className='inputLabelSubText'>{t('descriptionLabelSubText')}</div>
          <Form.Field className='create-new-unit-field'>
            <Form.Input
              className='inputText'
              name='unitDescription'
              onChange={this.handleChangeUnitDescription}
              placeholder={t('inputPlaceholderText')}
              type='text'
              value={unitDescription} />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={() => { this.closeCreateAssessmentBankUnitModal(false); }} primary>{t('cancelButtonLabel', 'Cancel')}</Button>
          <Button
            disabled={!this.validate()}
            onClick={() => {
              this.setState({ isSaving: true });
              this.createOrEditUnit();
            }}
            primary>
            {isEdit ? (isSaving ? <Loader active inline size='tiny' /> : t('saveButtonLabel', 'Save')) : (isSaving ? <Loader active inline size='tiny' /> : t('createButtonLabel', 'Create'))}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('CreateAssessmentBankUnitModal', CreateAssessmentBankUnitModal);
