import { toJS } from 'mobx';

import { registerClass } from '../SatCoreRegistry';

import courseManager from '../managers/CourseManager';
import productManager from '../managers/ProductManager';

export default class ProductService {
  static manager() {
    return productManager;
  }

  static initSelectedUserProductId = () => {
    const selectedUserProductId = productManager.userProductsMap.get(productManager.selectedUserProductId)?.id || 'allProducts';
    productManager.setSelectedUserProductId(selectedUserProductId);
  };

  static fetchLicensedProducts = async (props = {}) => {
    const products = await productManager.fetchLicensedProducts(props);
    productManager.addUserProductsToMap(products);
  }

  static getTotalProductPages = () => {
    const PAGE_SIZE = 25;
    const { selectedUserProductId } = productManager;
    const productCourseList = this.getSelectedProductAvailableCourses();
    const selectedProductCourses = productCourseList.filter((course) => {
      const productIdStr = course.productsList;
      return !selectedUserProductId || selectedUserProductId !== 'allProducts'
        ? productIdStr?.includes(selectedUserProductId) : true;
    });
    const productCount = selectedProductCourses?.length;
    if (!productCount || productCount < 1) {
      return 1;
    } else {
      return Math.ceil(productCount / PAGE_SIZE);
    }
  }

  static getPagedSelectedProductAvailableCourses() {
    const productCourses = this.getSelectedProductAvailableCourses();
    const PAGE_SIZE = 25;
    const start = (courseManager.activeProductCoursePage - 1) * PAGE_SIZE;
    const end = (courseManager.activeProductCoursePage) * PAGE_SIZE;
    const pagedProductCourses = productCourses.slice(start, end);
    return pagedProductCourses;
  }

  static getSelectedProductAvailableCourses() {
    const { selectedUserProductId } = productManager;
    const productCoursesMap = courseManager.productCourses;
    const productCourseList = productCoursesMap ? Array.from(toJS(productCoursesMap).values()) : [];

    return productCourseList.filter((course) => {
      const productIdStr = course.productsList;
      return !productIdStr || (selectedUserProductId !== 'allProducts' ? productIdStr?.includes(selectedUserProductId) : true);
    });
  }

  static getCourseProductCodesAndNamesStrObj = (course) => {
    const productIdsStr = course?.productsList;
    if (!productIdsStr || typeof productIdsStr !== 'string') { // edge case, will likely not happen
      return 'All Products';
    }
    const productIds = productIdsStr.split(',');
    const productCodes = [];
    const productNames = productIds.map((productId) => {
      const product = productManager.userProductsMap.get(productId);
      if (product?.code) {
        productCodes.push(product.code);
      }
      return product?.name;
    }).filter((productName) => productName);
    return {
      productCodesStr: productCodes.join(', '),
      productNamesStr: productNames.join(', ')
    };
  }

  static setShouldShowExpiredLicenseWarning = (toggle) => {
    productManager.setShouldShowExpiredLicenseWarning(toggle);
  }
}

registerClass('ProductService', ProductService);
