/* eslint-disable sort-imports */
/* eslint-disable max-len */
/* eslint-disable react/sort-comp */
/* eslint-disable sort-keys */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { debounce, isEqual } from 'lodash';

import {
  Button, Container, Dropdown, Grid, Input, Loader, Tab, Image
} from 'semantic-ui-react';
import iconBookmark from '../img/bookmark.svg';
import iconBookmarkSelected from '../img/bookmark-selected.svg';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import CourseService from '../services/CourseService';

export default
@inject(
  'assessmentManager',
  'courseManager',
  'curriculumMapManager',
  'itemBankManager',
  'tagContentManager',
  'teacherProductViewManager',
  'userManager'
)
@observer
class ItemBankFilter extends Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    // props specifying what this filter is being used for
    assessmentItemsSearch: false,
    courseResourcesSearch: false,
  };

  courseOptions = [];
  courseMap = {};
  courseIds = [];
  useSessionStorage = false;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.SCRadio = SatCoreComponent('SCRadio');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.saveState = debounce(this.saveState, 1000);
  }

  getInitialState = () => {
    const { tagContentManager } = this.props;
    const dynamicState = {
      activeIndex: 0,
      isUpdating: false,
      searchOperator: 'AND', // TODO GAL-602: this is now **always** 'and'
      searchTerms: '',
      searchQuestionTypes: [],
      searchTags: [],
      searchTagsMap: new Map(),
      productCmap: '',
      searchStandards: [],
      bookmarkedStandards: tagContentManager.bookmarkedStandardIds,
      expanded: [],
      standardFramework: '',
      authorityState: '',
      curriculumFilterType: 'bookmark',
      bookmarkMode: false,
      loadingMode: false,
      refreshTree: 'refresh'
    };
    tagContentManager.categoriesWithTagsArray.map((pItem) => {
      dynamicState[pItem.id] = [];
    });
    return { ...dynamicState, ...this.loadState() };
  }

  componentDidMount = async () => {
    const {
      assessmentManager,
      itembankId
    } = this.props;

    const { searchOperator, searchTerms, searchTags, searchStandards, standardFramework } = this.state;
    let { productCmap } = this.state;
    assessmentManager.setAssessmentItemsLoading(true);
    assessmentManager.clearAssessmentFilterStandardsMaps();
    assessmentManager.setOriginalAssessmentItems(new Map());
    this.fetchSearch(searchOperator, searchTerms, searchTags, searchStandards);
    assessmentManager.clearProductCmapList();
    await assessmentManager.fetchItembankCmaps(itembankId);
    if (assessmentManager.productCmapArray.length == 1) {
      await assessmentManager.clearExpanded();
      productCmap = assessmentManager.productCmapArray.entries().next().value[1].id;
      await this.setState({ productCmap });
      setTimeout(() => {
        this.setState({ expanded: assessmentManager.expanded });
      }, 1000);
    }
    productCmap !== '' && await assessmentManager.fetchFullCmap(productCmap);
    await assessmentManager.fetchAdoptedPublisherStandardFrameworks();
    standardFramework !== '' && await assessmentManager.fetchFullStandardDocument(standardFramework);
    assessmentManager.setAssessmentItemsLoading(false);
  }

  componentDidUpdate(_prevProps, prevState) {
    !isEqual(this.state, prevState) && this.saveState();
  }

  async componentWillUnmount() {
    this.resetFilters();
  }

  // eslint-disable-next-line react/sort-comp
  loadState() {
    try {
      if (this.useSessionStorage) {
        const data = JSON.parse(sessionStorage.getItem('ItemBankFilter') || '{}');
        data.searchTagsMap = new Map(data.searchTagsMap);
        return data;
      }
    } catch {
      return {};
    }
  }

  saveState() {
    const { searchTagsMap } = this.state;
    const data = { ...this.state, searchTagsMap: [...searchTagsMap] };
    if (this.useSessionStorage) {
      sessionStorage.setItem('ItemBankFilter', JSON.stringify(data));
    }
  }

  handleClearCategorySearch = async () => {
    const { tagContentManager } = this.props;
    this.setState({ searchTerms: '' });
    this.setState({ searchTags: [] });
    tagContentManager.categoriesWithTagsArray.map((pItem) => {
      this.setState({ [pItem.id]: [] });
    });
  }

  getCourseResourceElements = () => {
    CourseService.searchCourseResourceElements();
  }

  handleClearStandardsSearch = async () => {
    this.setState({ productCmap: '', standardFramework: '' });
    this.setState({ searchStandards: [] });
  }

  handleChangeSearch = async (_event, { value }) => {
    const { itemBankManager } = this.props;
    const { searchOperator, searchTags, searchStandards } = this.state;
    const searchTerms = value;
    itemBankManager.setSearchTextTimeout(clearTimeout(itemBankManager.searchTextTimeout));
    await itemBankManager.setSearchTerms(searchTerms);
    itemBankManager.setSearchTextTimeout(setTimeout(() => {
      if (!searchTerms || searchTerms.length >= 3) {
        this.resetSearch(searchOperator, searchTerms, searchTags, searchStandards);
      }
    }, 1000));
    this.setState({ searchTerms });
  }

  resetFilters = async () => {
    const { assessmentManager, courseManager, curriculumMapManager, itemBankManager, tagContentManager } = this.props;

    courseManager.setCurriculumMapElementIds([]);
    itemBankManager.setSearchStandards([]);
    itemBankManager.setSearchTags([]);
    itemBankManager.setSearchTerms('');
    itemBankManager.setSearchQuestionTypes([]);
    assessmentManager.clearAdoptedStandardsJson();
    assessmentManager.clearStdFrwkStandardsMap();
    assessmentManager.clearCmapStandardsJson();
    assessmentManager.clearCmapStandardsMap();
    curriculumMapManager.clearAll();

    await this.resetSearch('', '', '', '', '');
    this.setState({
      activeIndex: 0,
      isUpdating: false,
      searchOperator: 'AND', // TODO GAL-602: this is now **always** 'and'
      searchTerms: '',
      searchQuestionTypes: [],
      searchTags: [],
      searchTagsMap: new Map(),
      productCmap: '',
      searchStandards: [],
      expanded: [],
      standardFramework: '',
      authorityState: '',
      curriculumFilterType: 'standards'
    });

    tagContentManager.categoriesWithTagsArray.map((pItem) => {
      this.setState({ [pItem.id]: [] });
    });
  }

  fetchAndUpdateCurriculumMapsIfApplicable = async (currentClassroomId) => {
    const { curriculumMapManager, teacherProductViewManager } = this.props;
    if (!teacherProductViewManager.FROM_TEACHER_PRODUCTS_NAV) {
      await curriculumMapManager.fetchClassroomCurriculumMaps(currentClassroomId);
      this.updateCurriculumMaps();
    } else {
      await curriculumMapManager.fetchCourseResourceCurriculumMaps(
        teacherProductViewManager.currentTeacherProductCourse?.contentItemEntityId
      );
      this.updateCurriculumMaps();
    }
  }

  updateCurriculumMaps = async () => {
    const { curriculumMapManager, courseManager } = this.props;
    this.setState({ searchStandards: [] });
    courseManager.setCurriculumMapElementIds([]);
    if (curriculumMapManager.curriculumMaps && curriculumMapManager.curriculumMaps.length > 0) {
      const curriculumMap = curriculumMapManager.curriculumMaps[0];
      this.setState({ productCmap: curriculumMap.id });
      await curriculumMapManager.fetchCurriculumMap(curriculumMap.id);
      setTimeout(() => {
        this.setState({ expanded: curriculumMapManager.expanded });
      }, 1000);
    }
  }

  handleChangeQuestionTypes = async (_event, { value }, catId) => {
    const { itemBankManager } = this.props;
    await itemBankManager.setSearchQuestionTypes(value);
    const tempSearchQuestionTypes = value;
    const { searchOperator, searchTerms, searchStandards, searchTags } = this.state;
    await this.resetSearch(searchOperator, searchTerms, searchTags, searchStandards, tempSearchQuestionTypes);

    // set the state after search
    this.setState({ searchQuestionTypes: value });
  }

  handleClearDocument = async () => {
    this.setState({ standardFramework: '' });
    this.setState({ bookmarkMode: false });
  }

  handleChangeCategories = async (_event, { value }, catId) => {
    const { itemBankManager } = this.props;
    const { searchTagsMap } = this.state;
    const tempSearchTagsMap = searchTagsMap;

    if (catId !== '' && catId !== null && catId !== undefined && value.length < 1) {
      tempSearchTagsMap.delete(catId);
    } else {
      tempSearchTagsMap.set(catId, value);
    }
    const tempSearchTags = itemBankManager.getArrayFromMap(tempSearchTagsMap);
    await itemBankManager.setSearchTags(tempSearchTags);

    const { searchOperator, searchTerms, searchStandards } = this.state;
    await this.resetSearch(searchOperator, searchTerms, tempSearchTags, searchStandards);

    // set the state after search
    this.setState({ searchTags: tempSearchTags });
    this.setState({ searchTagsMap: tempSearchTagsMap });
    const dynamicState = {};
    dynamicState[catId] = value;
    this.setState(dynamicState);
  }

  handleClickStandardsLabel = async (data) => {
    // do nothing right now.
    console.log(event.target, data);
  }

  handleChangeStandards = async (searchElementIds, node, isTOCSearch = false) => {
    const { assessmentItemsSearch, assessmentManager, itemBankManager } = this.props;
    const { searchOperator, searchTerms, searchTags } = this.state;

    if (assessmentItemsSearch) {
      const searchStandards = [];
      // Convert the search curriculum element ids to a standards list
      if (isTOCSearch && assessmentManager.stdFrwkStandardsMap) {
        searchElementIds.forEach((elementId) => {
          // Get the standard for the stdFrwkElement
          const stdFrwkElement = assessmentManager.stdFrwkStandardsMap.get(elementId);
          if (stdFrwkElement) {
            searchStandards.push(stdFrwkElement.standardId);
          }
        });
      } else if (assessmentManager.cmapStandardsMap) {
        searchElementIds.forEach((elementId) => {
          // Get the standard for the cmapElement
          const cmapElement = assessmentManager.cmapStandardsMap.get(elementId);
          if (cmapElement) {
            searchStandards.push(cmapElement.standardId);
          }
        });
      }

      await itemBankManager.setSearchStandards(searchStandards);
      await this.resetSearch(searchOperator, searchTerms, searchTags, searchStandards);
      this.setState({ searchStandards: searchElementIds });
    }
  }

  handleChangeBookmarks = async (ids, node) => {
    const { tagContentManager, userManager } = this.props;

    if (!node.value) {
      return false;
    }

    if (tagContentManager.bookmarkedStandardIds.includes(node.value)) {
      const bookmark = tagContentManager.standardToBookmarkMap.get(node.value);
      await tagContentManager.deleteStandardFrameworkBookmark(bookmark.id);
    } else {
      await tagContentManager.addStandardFrameworkBookmark(node.value, userManager.userId);
    }

    await tagContentManager.fetchStandardFrameworkBookmarks(userManager.userId);
    this.setState({ bookmarkedStandards: tagContentManager.bookmarkedStandardIds });
  }

  resetSearch = async (searchOperator, searchTerms, searchTags, searchStandards, searchQuestionTypes) => {
    const { assessmentManager } = this.props;
    const filteredAssessmentSettings = {
      searchOperator, searchTerms, searchTags, searchStandards, searchQuestionTypes
    };

    await this.fetchSearch(searchOperator, searchTerms, searchTags, searchStandards, searchQuestionTypes);
    assessmentManager.setFilteredAssessmentSettings(filteredAssessmentSettings);
  }

  fetchSearch = async (searchOperator, searchTerms, searchTags, searchStandards, searchQuestionTypes) => {
    this.setState({ isUpdating: true });
    const { handleFetchItemBankItems } = this.props;
    await handleFetchItemBankItems(searchOperator, searchTerms, searchTags, searchStandards, searchQuestionTypes);
    this.setState({ isUpdating: false });
  }

  getQuestionTypeItems = () => {
    const { itemBankManager } = this.props;
    const thisClassName = 'filter-dropdown-section z-custom-category question-type-control';

    return (
      <Container key='question-type-container'>
        <div className={thisClassName}>
          <div className='filter-label'>Question Type</div>
          <Dropdown
            key='question-types'
            className='assessment-item-filter-dropdown'
            clearable={false}
            closeOnChange
            disabled={false}
            fluid
            multiple // we set this to true for the 'search pill' design effect, but we will only allow one pill per category
            onChange={(event, object) => this.handleChangeQuestionTypes(event, object)}
            options={itemBankManager.questionTypeOptions}
            placeholder='Select Question Types'
            search
            selection
            value={itemBankManager.searchQuestionTypes} />
        </div>
      </Container>
    );
  }

  getCategoryItems = () => {
    const { tagContentManager, multiSelect } = this.props;
    const categories = [];
    tagContentManager.categoriesWithTagsArray.map((pItem) => {
      let value = this.state[pItem.id];
      if (!value || !Array.isArray(value) || !value.length) {
        // value is empty; ensure it is an empty array
        value = [];
      } else if (!multiSelect && value && value.length > 1) {
        // ensure we only have up to 1 active 'search pill' per category
        const lastValueInArray = value[value.length - 1];
        value = lastValueInArray ? [lastValueInArray] : [];
      }

      const customName = tagContentManager.getCustomCategoryNameClass(pItem.name);
      const thisClassName = `filter-dropdown-section ${customName}`;
      categories.push(
        <Container key={`${pItem.id}_a`}>
          <div className={thisClassName}>
            <div className='filter-label'>{pItem.name}</div>
            <Dropdown
              key={`${pItem.id}_dd`}
              className='assessment-item-filter-dropdown'
              clearable={false}
              closeOnChange
              disabled={false}
              fluid
              multiple // we set this to true for the 'search pill' design effect, but we will only allow one pill per category
              onChange={(event, object) => this.handleChangeCategories(event, object, pItem.id)}
              options={this.getTagItemsAsOptions(pItem.tags)}
              placeholder={`Select ${pItem.name || ''}`}
              search
              selection
              value={value} />
          </div>
        </Container>
      );
    });
    return categories;
  }

  getTagItemsAsOptions = (tagsObj) => {
    const options = tagsObj.map((tag, index) => ({
      key: tag.id + index,
      text: tag.name,
      value: tag.id
    }));
    return options;
  }

  handleChangeProductCmap = async (_event, { value }) => {
    const { assessmentItemsSearch, courseResourcesSearch, assessmentManager, curriculumMapManager } = this.props;

    if (assessmentItemsSearch) {
      await assessmentManager.clearExpanded();
      if (value.length > 0) {
        const id = value;
        await assessmentManager.fetchFullCmap(id);
      } else {
        assessmentManager.clearCmapStandardsJson();
        assessmentManager.clearCmapStandardsMap();
      }
      this.setState({ productCmap: value });
      setTimeout(() => {
        this.setState({ expanded: assessmentManager.expanded });
      }, 1000);
    } else if (courseResourcesSearch) {
      await curriculumMapManager.clearExpanded();
      const id = value;
      await curriculumMapManager.fetchCurriculumMap(id);
      this.setState({ productCmap: value });
      setTimeout(() => {
        this.setState({ expanded: curriculumMapManager.expanded });
      }, 1000);
    }
  }

  renderCurriculumFrameworkSearch() {
    // const bookmarkMode = false;
    const { assessmentItemsSearch } = this.props;
    const { authorityState, standardFramework, searchStandards, bookmarkedStandards, expanded, curriculumFilterType, bookmarkMode, loadingMode, refreshTree } = this.state;
    const { assessmentManager, tagContentManager } = this.props;
    const { SCRadio } = this;
    const { SCCheckbox } = this;
    // const standards = (bookmarkMode) ? tagContentManager.bookmarkedStandards : searchStandards;

    let statesOptions = [];
    const isClearable = true;
    if (assessmentItemsSearch) {
      statesOptions = tagContentManager.authorityStates.map((authorityState, index) => ({
        key: authorityState.id + index,
        text: authorityState.name,
        value: authorityState.code
      }));
    }

    return (
      <div className='tab-container'>
        <div className='radio-wrapper'>
          <SCRadio
            checked={curriculumFilterType === 'bookmark'}
            label='Show Bookmarks'
            name='frameworkFilter'
            onChange={this.handleChangeCurriculumFilterType}
            value='bookmark' />
          <SCRadio
            checked={curriculumFilterType === 'standards'}
            label='Browse Standards'
            name='frameworkFilter'
            onChange={this.handleChangeCurriculumFilterType}
            value='standards' />
        </div>
        <Container>
          {/* {(assessmentManager.adoptedStandardFrameworksArray) ? (
            <Dropdown
              clearable={isClearable}
              fluid
              onChange={this.handleChangeStandardsFramework}
              options={options}
              placeholder='Standard Framework'
              selection
              value={standardFramework} />
          ) : null} */}
        </Container>

        {(curriculumFilterType === 'standards') ? (
          <div className='standards-section'>
            <Container>
              {(tagContentManager.authorityStates && !standardFramework) ? (
                <Dropdown
                  clearable={isClearable}
                  fluid
                  onChange={this.handleChangeAuthorityState}
                  options={statesOptions}
                  placeholder='Authority State'
                  selection
                  value={authorityState} />
              ) : null}
            </Container>

            {(authorityState && !standardFramework) ? (
              <div className='framework-list-container'>
                {tagContentManager.standardDocuments.map(
                  (doc, index) => (
                    <div key={index} className={`${(index % 2 !== 0) ? 'shade' : ''} standard-row standard-doc-label`} onClick={() => this.handleSelectStandardsFramework(doc.id)}>
                      <Image src={iconBookmark} />
                      <div key={index} className='standard-doc-label'>{doc.listName}</div>
                    </div>
                  ))}
              </div>
            ) : null}

            {(assessmentManager.adoptedStandardsJson && standardFramework && !loadingMode) ? (
              <>
                <div className='doc-controls'>
                  <SCCheckbox
                    checked={bookmarkMode}
                    label='Bookmark Mode'
                    name='status'
                    onChange={this.handleChangeBookmarkMode}
                    value={bookmarkMode} />
                  <Button
                    className='reset-link'
                    disabled={!standardFramework}
                    onClick={this.handleClearDocument}>
                    Clear Document
                  </Button>
                </div>
                <div className='checkbox-tree-container' refresh-attr={refreshTree}>
                  <CheckboxTree
                    checked={(bookmarkMode) ? bookmarkedStandards : searchStandards}
                    expanded={expanded}
                    icons={{
                      check: <>{(bookmarkMode) ? <Image className='bookmark-mode' src={iconBookmarkSelected} /> : <span className='rct-icon rct-icon-check' />}</>,
                      uncheck: <>{(bookmarkMode) ? <Image className='bookmark-mode' src={iconBookmark} /> : <span className='rct-icon rct-icon-uncheck' />}</>,
                      halfCheck: <span className='rct-icon rct-icon-half-check' />,
                      expandClose: <span className='icon-expand-close' />,
                      expandOpen: <span className='icon-expand-open' />,
                      expandAll: <span className='rct-icon rct-icon-expand-all' />,
                      collapseAll: <span className='rct-icon rct-icon-collapse-all' />,
                      parentClose: <span className='rct-icon rct-icon-parent-close' />,
                      parentOpen: <span className='rct-icon rct-icon-parent-open' />,
                      leaf: <span className='rct-icon rct-icon-leaf' />
                    }}
                    nativeCheckboxes={!bookmarkMode}
                    noCascade={true}
                    nodes={assessmentManager.adoptedStandardsJson}
                    onCheck={(checked, node) => ((bookmarkMode) ? this.handleChangeBookmarks(checked, node) : this.handleChangeStandards(checked, node, true))}
                  // onClick={(event, data) => this.handleClickStandardsLabel(event, data)}
                    onExpand={(expanded) => this.setState({ expanded })} />
                </div>
              </>
            ) : null}

            {(loadingMode) ? <Loader active /> : null}

          </div>
        ) : (
          <div className='bookmark-list-container'>
            {tagContentManager.standardBookmarkArray.map(
              (bookmark, index) => (
                <div key={index} className={`${(index % 2 !== 0) ? 'shade' : ''} standard-row standard-doc-label`} onClick={() => this.handleSelectStandardsFramework(bookmark.sourceId, true)}>
                  <Image src={iconBookmarkSelected} />
                  <div key={index} className='standard-doc-label'>{(bookmark.name.length > 100 ? `${bookmark.name.substring(0, 100)}...` : bookmark.name)}</div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    );
  }

  handleChangeStandardsFramework = async (_event, { value }) => {
    this.handleSelectStandardsFramework(value);
  }

  handleSelectStandardsFramework = async (value, showStandards = false) => {
    const { assessmentItemsSearch, assessmentManager } = this.props;
    if (assessmentItemsSearch) {
      await assessmentManager.clearExpanded();
      if (value.length > 0) {
        const id = value;
        this.handleChangeLoadingMode(true);
        const defaultExpanded = false;
        await assessmentManager.fetchFullStandardDocument(id, defaultExpanded);
        this.handleChangeLoadingMode(false);
      } else {
        assessmentManager.clearAdoptedStandardsJson();
        assessmentManager.clearStdFrwkStandardsMap();
      }
      this.setState({ standardFramework: value });

      if (showStandards) {
        this.setState({ curriculumFilterType: 'standards' });
      }

      setTimeout(() => {
        this.setState({ expanded: assessmentManager.expanded });
      }, 1000);
    }
  }

  handleChangeAuthorityState = async (_event, { value }) => {
    const { assessmentItemsSearch, tagContentManager } = this.props;

    if (assessmentItemsSearch) {
      await tagContentManager.fetchStandardFrameworkDomainList(value);
      this.setState({ authorityState: value });
    }
  }

  handleChangeCurriculumFilterType = async (event) => {
    this.setState({ standardFramework: '' });
    this.setState({ bookmarkMode: false });
    this.setState({ curriculumFilterType: event.target.value });
  }

  handleChangeBookmarkMode = async (event) => {
    const { bookmarkMode } = this.state;
    const { assessmentManager } = this.props;
    this.setState({ bookmarkMode: !bookmarkMode });

    const formattedJson = await assessmentManager.buildAdoptedStandardsJson(assessmentManager.standardsResponseData, false, event.target.checked);
    await assessmentManager.setAdoptedStandardsJson(formattedJson);

    setTimeout(() => {
      this.setState({ refreshTree: 'refreshed' });
    }, 500);
  }

  handleChangeLoadingMode = async (mode) => {
    this.setState({ loadingMode: mode });
  }

  handleSearchOperator = async (_event, { value }) => {
    const { itemBankManager } = this.props;
    await itemBankManager.setSearchOperator(value);

    const { searchTerms, searchStandards, searchTags } = this.state;
    await this.resetSearch(value, searchTerms, searchTags, searchStandards, '');

    this.setState({ searchOperator: value });
  }

  renderSearchOptions() {
    const { searchOperator } = this.state;
    return (
      <div className='search-option-dropdown-wrapper'>
        <div className='filter-label'>Search Options</div>
        <Dropdown
          key='search-operator'
          fluid
          id='search-operator'
          name='sort-select'
          onChange={this.handleSearchOperator}
          options={[
            { key: 'key_or', text: '"OR" Search', value: 'OR' },
            { key: 'key_and', text: '"AND" Search', value: 'AND' },
            { key: 'key_not', text: '"NOT" Search', value: 'NOT' },
          ]}
          placeholder='Sort by'
          search
          selection
          value={searchOperator} />
      </div>
    );
  }

  renderTagsSearch() {
    const { assessmentItemsSearch } = this.props;
    const { searchTerms } = this.state;

    return (
      <div className='tab-container'>
        {assessmentItemsSearch && (
          <Grid>
            <Grid.Column>
              <Input
                icon='search'
                name='searchTerms'
                onChange={this.handleChangeSearch}
                placeholder='Search question stem'
                type='text'
                value={searchTerms || ''} />
            </Grid.Column>
          </Grid>
        )}

        <Grid>
          <Grid.Column>
            {this.getQuestionTypeItems()}
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column>
            {this.getCategoryItems()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  renderCmapSearch() {
    const { assessmentItemsSearch, curriculumMapManager } = this.props;
    const { productCmap, searchStandards, expanded } = this.state;
    const { assessmentManager } = this.props;
    let options = [];
    const isClearable = true;
    if (assessmentItemsSearch) {
      const sortedCmapArray = assessmentManager.productCmapArray.slice();
      sortedCmapArray.sort((a, b) => ((a.sortBy > b.sortBy) ? 1 : -1));
      options = sortedCmapArray.map((pcm, index) => ({
        key: pcm.id + index,
        text: pcm.friendlyName || pcm.displayName || pcm.name,
        value: pcm.id
      }));
    }

    return (
      <div className='tab-container'>
        <Container>
          {(assessmentManager.productCmapArray) ? (
            <Dropdown
              clearable={isClearable}
              fluid
              onChange={this.handleChangeProductCmap}
              options={options}
              placeholder='Select...'
              selection
              value={productCmap} />
          ) : null}
        </Container>
        <div className='checkbox-tree-container'>
          <CheckboxTree
            checked={searchStandards}
            expanded={expanded}
            icons={{
              check: <span className='rct-icon rct-icon-check' />,
              uncheck: <span className='rct-icon rct-icon-uncheck' />,
              halfCheck: <span className='rct-icon rct-icon-half-check' />,
              expandClose: <span className='icon-expand-close' />,
              expandOpen: <span className='icon-expand-open' />,
              expandAll: <span className='rct-icon rct-icon-expand-all' />,
              collapseAll: <span className='rct-icon rct-icon-collapse-all' />,
              parentClose: <span className='rct-icon rct-icon-parent-close' />,
              parentOpen: <span className='rct-icon rct-icon-parent-open' />,
              leaf: <span className='rct-icon rct-icon-leaf' />
            }}
            nativeCheckboxes={true}
            noCascade={true}
            nodes={assessmentItemsSearch ? assessmentManager.cmapStandardsJson : curriculumMapManager.curriculumMapJson}
            onCheck={(checked) => this.handleChangeStandards(checked)}
            onExpand={(expanded) => this.setState({ expanded })} />
        </div>
      </div>
    );
  }

  render() {
    const {
      assessmentManager, t
      // Keep to cause re-render tab pane on change
    } = this.props;

    if (!assessmentManager.assessmentItemsLoading) {
      const { activeIndex } = this.state;
      const panes = [
        { menuItem: 'Standards', render: () => <Tab.Pane>{this.renderCmapSearch()}</Tab.Pane> },
        { menuItem: 'Tags', render: () => <Tab.Pane>{this.renderTagsSearch()}</Tab.Pane> },
        // { menuItem: 'Curriculum Framework', render: () => <Tab.Pane>{this.renderCurriculumFrameworkSearch()}</Tab.Pane> }
      ];

      const { isUpdating } = this.state;

      const filterStandardsAndTagsDescriptionTranslation = t('filterStandardsAndTagsDescription');

      return (
        <div style={isUpdating ? { cursor: 'wait' } : {}}>
          {/* DEMO-1933 - remove search options filter, leaving in case we revisit it later
          <div className='filter-search-options-wrapper'>
            {this.renderSearchOptions()}
          </div> */}
          <div className='filter-title-wrapper'>
            <div className='filter-instruction'>
              <div>
                {t(filterStandardsAndTagsDescriptionTranslation)}
              </div>
            </div>
            <Button
              className='reset-link'
              disabled={isUpdating}
              onClick={this.resetFilters}>
              {t('resetFilters')}
            </Button>
          </div>
          <div
            className='filter'
            style={isUpdating ? { pointerEvents: 'none' } : {}}>
            <Tab
              activeIndex={activeIndex}
              onTabChange={(_event, { activeIndex }) => this.setState({ activeIndex })}
              panes={panes} />
          </div>
        </div>
      );
    }
    return null;
  }
}

SatCoreRegister('ItemBankFilter', ItemBankFilter);
