import groupsManager from '../../managers/GroupsManager';
import reportContextManager from '../../managers/reports/ReportContextManager';
import reportIdentityManager from '../../managers/reports/ReportIdentityManager';
import reportStandardsManager from '../../managers/reports/ReportStandardsManager';
import userManager from '../../managers/UserManager';

import ReportIdentityService from './ReportIdentityService';
import ReportOverrideService from './ReportOverrideService';
import { REPORT_ASSIGNMENT_TYPE, REPORT_CONTENT_TYPE } from './ReportConstants';

export default class ReportMoreFiltersService {
  // content type filter and assignment type filter
  static showTypeFilters = () => {
    const isProgressReport = reportContextManager.isContextProgressReport;
    return !isProgressReport;
  }

  // user group filter
  static showGroupFilter = () => {
    const isStandardsDetail = ReportIdentityService.isReportStandardsClassroomDetail();
    return isStandardsDetail;
  }

  // only used by Sirius and contains type filters and user group filter
  static showMoreFilters = () => {
    const isSirius = userManager.clientPrefix === 'SIRIUS';
    const shouldUseCmapId = ReportOverrideService.shouldUseCmapId();
    const isClassroomReport = reportIdentityManager.isFacultyClassroomReport;
    const showMoreFilters = isSirius && isClassroomReport && shouldUseCmapId
      && (this.showTypeFilters() || this.showGroupFilter());
    return showMoreFilters;
  }

  static getSelectedTranslationKey = () => {
    const count = this.getSelectedCount();
    if (!count) {
      return 'moreFiltersNoneSelected';
    } else if (count === 1) {
      return 'moreFiltersOneSelected';
    } else {
      return 'moreFiltersSomeSelected';
    }
  }

  static getSelectedCount = () => {
    let count = 0;

    const selectedContentType = reportStandardsManager.selectedReportMoreFiltersContentType;
    if (this.showTypeFilters() && selectedContentType && selectedContentType !== REPORT_CONTENT_TYPE.ALL) {
      count++;
    }

    const selectedAssignmentType = reportStandardsManager.selectedReportMoreFiltersAssignmentType;
    if (this.showTypeFilters() && selectedAssignmentType && selectedAssignmentType !== REPORT_ASSIGNMENT_TYPE.ALL) {
      count++;
    }

    if (this.showGroupFilter() && groupsManager.tmpGroups && groupsManager.tmpGroups.length > 0) {
      count++;
    }
    return count;
  }

  static isTypeFilterSelected = () => {
    const selectedContentType = reportStandardsManager.selectedReportMoreFiltersContentType;
    const isContentTypeSelected = selectedContentType && selectedContentType !== REPORT_CONTENT_TYPE.ALL;

    const selectedAssignmentType = reportStandardsManager.selectedReportMoreFiltersAssignmentType;
    const isAssignmentTypeSeleted = selectedAssignmentType && selectedAssignmentType !== REPORT_ASSIGNMENT_TYPE.ALL;

    return (isContentTypeSelected || isAssignmentTypeSeleted);
  }
}
