import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Redirect, Route } from 'react-router-dom';

import classNames from 'classnames';

import { Container } from 'semantic-ui-react';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import '../css/MainView.less';

import { REPORT_URL_KEY } from '../services/reports/ReportConstants';

import InitService from '../services/InitService';
import ThirdPartyService from '../services/ThirdPartyService';
import UserService from '../services/UserService';
import UtilityService from '../services/UtilityService';

import { register } from '../i18n';

import { ImpersonationCautionBanner } from '../components/ImpersonationCautionBanner';
import { getStyleVar, stripNonNumeric } from '../utils';

const t = register('MainView');

export default
@inject(
  'accessManager', 'accommodationsManager', 'appManager', 'classroomManager',
  'contentManager', 'navigationManager', 'notificationManager', 'productManager',
  'teacherProductViewManager', 'userManager')
@observer
class MainView extends Component {
  constructor(props) {
    super(props);

    this.AccommodationsView = SatCoreComponent('AccommodationsView');
    this.AdminAddTeacher = SatCoreComponent('AdminAddTeacher');
    this.AdminClassroomsView = SatCoreComponent('AdminClassroomsView');
    this.AdminContentView = SatCoreComponent('AdminContentView');
    this.AdminDashboard = SatCoreComponent('AdminDashboard');
    this.AdminProductsView = SatCoreComponent('AdminProductsView');
    this.AdminSchoolsView = SatCoreComponent('AdminSchoolsView');
    this.AdminTestBuilderView = SatCoreComponent('AdminTestBuilderView');
    this.AdminTopNav = SatCoreComponent('AdminTopNav');
    this.AdminUserProfile = SatCoreComponent('AdminUserProfile');
    this.AdminUsersView = SatCoreComponent('AdminUsersView');
    this.ArchivedClasses = SatCoreComponent('ArchivedClasses');
    this.AssignmentView = SatCoreComponent('AssignmentView');
    this.CautionBanner = SatCoreComponent('CautionBanner');
    this.ClassCourses = SatCoreComponent('ClassCourses');
    this.ClassDetails = SatCoreComponent('ClassDetails');
    this.ClassRoster = SatCoreComponent('ClassRoster');
    this.ClassSettingsView = SatCoreComponent('ClassSettingsView');
    this.ClassWorkspace = SatCoreComponent('ClassWorkspace');
    this.Dashboard = SatCoreComponent('TeacherDashboard');
    this.DialogList = SatCoreComponent('DialogList');
    this.EditStudentProfile = SatCoreComponent('EditStudentProfile');
    this.Gradebook = SatCoreComponent('Gradebook');
    this.GroupRoster = SatCoreComponent('GroupRoster');
    this.LTIPlayerLauncher = SatCoreComponent('LTIPlayerLauncher');
    this.LearnosityPrintPreview = SatCoreComponent('LearnosityPrintPreview');
    this.NotificationsView = SatCoreComponent('NotificationsView');
    this.Profile = SatCoreComponent('Profile');
    this.ProgressView = SatCoreComponent('ProgressView');
    this.PublisherAdminTopNav = SatCoreComponent('PublisherAdminTopNav');
    this.PublisherCourse = SatCoreComponent('PublisherCourse');
    this.ReportJsonDiff = SatCoreComponent('ReportJsonDiff');
    this.ReportTypeSelectorView = SatCoreComponent('ReportTypeSelectorView');
    this.ReportView = SatCoreComponent('ReportView');
    this.ResourcesView = SatCoreComponent('ResourcesView');
    this.RoleSwitcherView = SatCoreComponent('RoleSwitcherView');
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');
    this.StudentAssignmentLauncher = SatCoreComponent('StudentAssignmentLauncher');
    this.StudentAssignmentView = SatCoreComponent('StudentAssignmentView');
    this.StudentCourseLandingView = SatCoreComponent('StudentCourseLandingView');
    this.StudentDashboard = SatCoreComponent('StudentDashboard');
    this.StudentTopNav = SatCoreComponent('StudentTopNav');
    this.TeacherProductView = SatCoreComponent('TeacherProductView');
    this.TeacherTestBuilderView = SatCoreComponent('TeacherTestBuilderView');
    this.ThirdPartyChatLauncher = SatCoreComponent('ThirdPartyChatLauncher');
    this.TopNav = SatCoreComponent('TopNav');
    this.TrialBanner = SatCoreComponent('TrialBanner');
    this.Verification = SatCoreComponent('Verification');
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);

    InitService.doLoginInit();
    await InitService.initData(urlParams);
    const { userManager } = this.props;
    await userManager.trackLoggedIn();
  }

  getAccessRedirects = () => {
    const {
      accessManager: {
        policies: { mainView: { classDetails, classCourses, resourcesView, assignmentView, studentAssignmentView, progressView } }
      },
      userManager: { isStudent }
    } = this.props;

    return isStudent ? (
      <>
        {!classCourses && <Redirect path='/courses' to='/' />}
        {!classDetails && <Redirect path='/class' to='/' />}
        {!resourcesView && <Redirect exact path='/resources' to='/' />}
        {!assignmentView && <Redirect exact from='/assignments' to='/' />}
        {!studentAssignmentView && <Redirect exact path='/student-assignments' to='/' />}
        {!progressView && <Redirect exact path='/progress' to='/' />}
      </>
    ) : null;
  };

  getRoutes = () => {
    const {
      appManager, teacherProductViewManager, userManager
    } = this.props;

    const {
      isDistrictOrSchoolAdmin, isDistrictAdmin, isProductAdmin,
      isSchoolAdmin, isSsoUser, isStudent, isTeacher
    } = userManager;

    const isAdmin = isDistrictAdmin || isSchoolAdmin;
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.getRoutes !== undefined) {
      // eslint-disable-next-line react/destructuring-assignment
      return this.props.getRoutes(this.props, this);
    }
    const Dashboard = this.getDashboard();

    const {
      AccommodationsView, AdminAddTeacher, AdminClassroomsView, AdminContentView, AdminTestBuilderView, AdminProductsView,
      AdminUserProfile, AdminUsersView, ArchivedClasses, AssignmentView, ClassCourses, ClassDetails, ClassRoster,
      ClassSettingsView, ClassWorkspace, EditStudentProfile, Gradebook, GroupRoster, LTIPlayerLauncher,
      LearnosityPrintPreview, NotificationsView, Profile, ProgressView, PublisherCourse, ReportJsonDiff,
      ReportTypeSelectorView, ResourcesView, RoleSwitcherView, StudentAssignmentLauncher, StudentAssignmentView,
      StudentCourseLandingView, TeacherProductView, TeacherTestBuilderView, Verification
    } = this;

    let adminRoutes = <></>;
    if (isAdmin) {
      const { ReportView } = this;
      adminRoutes = (
        <>
          <Route component={AdminAddTeacher} path='/adminAddTeacher' />
          <Route component={AdminUserProfile} path='/adminProfile' />
          <Route component={AdminUsersView} path='/users' />
          <Route component={AdminClassroomsView} path='/classrooms' />
          {/* COURSE DISTRICT REPORTS */}
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/course-district-summary']} />
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/course-district-detail']} />
          {/* COURSE SCHOOL REPORTS */}
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/course-school-summary']} />
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/course-school-detail']} />
          {/* STANDARDS DISTRICT REPORTS */}
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/standards-district-summary']} />
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/standards-district-detail']} />
          {/* STANDARDS SCHOOL REPORTS */}
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/standards-school-summary']} />
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/standards-school-detail']} />
        </>
      );
    }
    let adminOrAdminProductRoutes = <></>;
    if (isAdmin || isProductAdmin) {
      adminOrAdminProductRoutes = (
        <>
          {userManager.canViewSatelliteContent && (
            <Route component={AdminContentView} path='/content' />
          )} {/* my/shared, live */}
          {userManager.canViewSatelliteProducts && (
            <Route component={AdminProductsView} path='/products' />
          )}
        </>
      );
    }
    let adminOrTeacherRoutes = <></>;
    if (isAdmin || isTeacher) {
      const { accommodationsManager } = this.props;
      const { ReportView } = this;
      adminOrTeacherRoutes = (
        <>
          {accommodationsManager.includeClassroomAccommodations && <Route component={AccommodationsView} path='/accommodations' />}
          <Route component={EditStudentProfile} exact path='/editStudentProfile' />
          <Route component={ClassRoster} path='/roster' />
          <Route component={ClassSettingsView} path='/settings' />
          <Route component={GroupRoster} path='/groups' />
          {/* REPORTS */}
          <Route component={ReportTypeSelectorView} exact path='/reports' />
          {/* COURSE CLASSROOM/INDIVIDUAL REPORTS */}
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/course-classroom-summary']} />
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/course-classroom-detail']} />
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/course-classroom-individual']} />
          {/* STANDARDS CLASSROOM/INDIVIDUAL REPORTS */}
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/standards-classroom-summary']} />
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/standards-classroom-detail']} />
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/standards-classroom-detail-students']} />
          <Route component={ReportView} exact path={REPORT_URL_KEY['/reports/standards-classroom-individual']} />
          {/* TEST BUILDER */}
          {userManager.canViewSatelliteTestBuilder && isAdmin && (
            <Route component={AdminTestBuilderView} path='/adminTestbuilder' />
          )}
          {userManager.canViewSatelliteTestBuilder && isTeacher && (
            <Route component={TeacherTestBuilderView} path='/testbuilder' />
          )}
        </>
      );
    }

    return (
      <>
        {this.getAccessRedirects()}
        <Route component={Dashboard} exact path='/' />
        {isTeacher && !isSsoUser && <Route component={ArchivedClasses} exact path='/archived-classes' />}

        {isStudent && (
          <Route component={StudentCourseLandingView} path='/student-book-courses' />
        )}

        <Route component={ClassDetails} path='/class' />

        <Route component={ClassCourses} path='/courses' />
        <Route component={ResourcesView} exact path='/resources' />
        <Route component={AssignmentView} exact path='/assignments' />
        <Route component={StudentAssignmentView} exact path='/student-assignments' />
        {appManager.classWorkspaceFeature && <Route component={ClassWorkspace} exact path='/workspace' />}
        {(isDistrictOrSchoolAdmin || isTeacher) && (<Route component={Gradebook} exact path='/gradebook' />)}

        {isTeacher && userManager.allowTeacherLibraryContentView && (
          <Route component={AdminContentView} path='/content' />
        )}

        {isTeacher && teacherProductViewManager.allowTeacherProductView && (
          <Route component={TeacherProductView} path='/products' />
        )}

        {(isTeacher && !isAdmin && !isProductAdmin) && (
          <Route component={LearnosityPrintPreview} path='/lrnPrint' />
        )}

        <Route component={Profile} exact path='/profile' />
        <Route component={Verification} exact path='/verification' />
        <Route component={PublisherCourse} exact path='/publisherCourse' />
        {(isStudent) && (<Route component={ProgressView} path='/progress' />)}

        {/* all users except students get notifications Route */}
        {(!isStudent) && (<Route component={NotificationsView} path='/notifications' />)}

        <Route component={ReportJsonDiff} exact path='/reportJsonDiff' />
        <Route component={LTIPlayerLauncher} exact path='/ltiPlayer' />
        <Route component={StudentAssignmentLauncher} exact path='/launch' />

        {adminOrTeacherRoutes}
        {adminRoutes}
        {adminOrAdminProductRoutes}

        <Route component={RoleSwitcherView} exact path='/switch-role' />
      </>
    );
  }

  getTopNav = () => {
    const { productManager, userManager } = this.props;
    const { hasPublisherPermission, isDistrictAdmin, isSchoolAdmin, isProductAdmin, isTeacher } = userManager;
    const { isFromProduct } = productManager;
    const isAdmin = isDistrictAdmin || isSchoolAdmin;

    if (isFromProduct && isFromProduct !== 'FROM_TEACHER_PRODUCTS_NAV' && hasPublisherPermission) {
      return this.PublisherAdminTopNav;
    } else if (isAdmin || isProductAdmin) {
      return this.AdminTopNav;
    } else if (isTeacher) {
      return this.TopNav;
    }
    return this.StudentTopNav;
  }

  getDashboard = () => {
    const { history, userManager } = this.props;
    const {
      activePermissionId, isDistrictAdmin, isProductAdmin,
      isSchoolAdmin, isTeacher, loaded
    } = userManager;

    if (!loaded) {
      return null;
    }
    if (!activePermissionId) {
      UserService.showRoleSwitcher(history);
      return this.RoleSwitcherView;
    }
    const isAdmin = isDistrictAdmin || isSchoolAdmin;
    if (isAdmin || isProductAdmin) {
      return this.AdminDashboard;
    } else if (isTeacher) {
      return this.Dashboard;
    }
    return this.StudentDashboard;
  }

  renderTrialBanner() {
    const { TrialBanner } = this;
    const { appManager, userManager } = this.props;
    return appManager.trialFeature && userManager.isLicensedByPurchaseCode ? <TrialBanner /> : null;
  }

  render() {
    const {
      appManager,
      classroomManager,
      contentManager,
      getNavTabs,
      navigationManager,
      notificationManager,
      productManager,
      setCurrentClassroomId,
      topNav,
      userManager
    } = this.props;

    const { CautionBanner } = this;

    const { isStudent } = userManager;

    const TopNav = this.getTopNav();

    if (!userManager.isImpersonatorAdmin) {
      if (sessionStorage.getItem('impersonate')) {
        userManager.setIsImpersonatorAdmin(true);
      }
    }

    // This code pattern seems a little verbose and boiler-plate
    // but this is the only way to ensure that a new component isn't created
    // every time render is called and this also ensures the 'this' pointer
    // is not corrupted as the component tree gets large.
    const { DialogList, SatCoreLoader, ThirdPartyChatLauncher } = this;

    const shouldAllowThirdPartyChatLauncher = ThirdPartyService.shouldAllowThirdPartyChatLauncherForActiveUser();

    const shouldShowExpiredLicenseWarning = productManager.shouldShowExpiredLicenseWarning && (
      !userManager.isStudent || productManager.allowShowExpiredLicenseWarningForStudent
    );

    // Currently not showing notification banner to students.
    const { shouldShowNotificationBanner } = notificationManager;

    const windowLocationPathname = window.location.pathname;
    const isReadOnlyGradebook = userManager.isDistrictOrSchoolAdmin && (
      appManager.location?.pathname?.includes?.('gradebook') || (
        !appManager.location?.pathname && windowLocationPathname?.includes?.('gradebook')));

    const hasCautionBanner = userManager.isImpersonatorAdmin || isReadOnlyGradebook
    || shouldShowExpiredLicenseWarning || (shouldShowNotificationBanner && !userManager.isStudent);

    const shouldShowGenericCautionBannerComponent = isReadOnlyGradebook || shouldShowExpiredLicenseWarning
    || (shouldShowNotificationBanner && !userManager.isStudent);

    const navSeparator = (
      <div className={classNames(`nav-separator ${navigationManager.viewSelection}`, {
        'nav-separator-top': hasCautionBanner
      })} />
    );

    const topNavHeight = +stripNonNumeric(getStyleVar('--nav-height') || 0);
    const navSeparatorHeight = +stripNonNumeric(getStyleVar('--nav-separator-height') || 0);
    const impersonationCautionBannerMinHeight = +stripNonNumeric(getStyleVar('--impersonation-caution-banner-min-height') || 0);

    const cautionBannerStickyOffset = (
      topNavHeight + navSeparatorHeight + (userManager.isImpersonatorAdmin ? impersonationCautionBannerMinHeight : 0)
    );

    // Check which caution banner messages are applicable and append them to the overall `cautionBannerMsg`.
    // Note that ImpersonationCautionBanner is separate from this, and will be rendered **above** CautionBanner if applicable.
    let cautionBannerMsg = '';
    const cautionBannerKey = 'DefaultCautionBanner';
    if (shouldShowExpiredLicenseWarning) {
      // cautionBannerKey = 'ExpiredLicenseCautionBanner';
      cautionBannerMsg += `${t('expiredLicenseCautionBannerMsg')}<br />`;
    }
    if (isReadOnlyGradebook) {
      // cautionBannerKey = 'ReadOnlyGradebookCautionBanner';
      cautionBannerMsg += `${t('readOnlyGradebookCautionBannerMsg')}<br />`;
    }
    if (shouldShowNotificationBanner) {
      // cautionBannerKey = 'notificationCautionBanner';
      cautionBannerMsg += `${t('notificationCautionBannerMsg')}<br />`;
    }
    cautionBannerMsg = UtilityService.reactHtmlParserWrapper(cautionBannerMsg)?.parsed;

    return (classroomManager.loaded && userManager.loaded ? (
      <>
        {shouldAllowThirdPartyChatLauncher && <ThirdPartyChatLauncher />}
        <Container
          className={classNames('fullHeight main-view', {
            'hidden': contentManager.hideContentPanel,
            'student-client': isStudent
          })}
          fluid>
          <TopNav
            additionalClassName={!userManager.activePermissionId ? 'no-role' : ''}
            getNavTabs={getNavTabs}
            setCurrentClassroomId={setCurrentClassroomId}
            {...topNav} />

          {hasCautionBanner && navSeparator}

          {(userManager.isImpersonatorAdmin) && <ImpersonationCautionBanner />}
          {shouldShowGenericCautionBannerComponent && (
            <CautionBanner bannerKey={cautionBannerKey} cautionBannerMsg={cautionBannerMsg}
              isImpersonationCautionBannerShowing={userManager.isImpersonatorAdmin}
              stickyOffset={cautionBannerStickyOffset} />
          )}
          {/* TODO remove */}
          {/* {isReadOnlyGradebook && (
            <CautionBanner bannerKey='ReadOnlyGradebookCautionBanner' cautionBannerMsg={t('readOnlyGradebookCautionBannerMsg')}
              stickyOffset={cautionBannerStickyOffset + (shouldShowExpiredLicenseWarning ? 50 : 0)} />
          )} */}
          <Container className='content-area' fluid>
            {!hasCautionBanner && navSeparator}

            {this.renderTrialBanner()}
            {this.getRoutes()}
          </Container>
          <DialogList />
        </Container>
      </>
    ) :
      <SatCoreLoader />
    );
  }
}

SatCoreRegister('MainView', MainView);
