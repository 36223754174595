/* eslint-disable max-len */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Header } from 'semantic-ui-react';
import Iframe from 'react-iframe';
import Modal from '../Modal';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import Auth from '../../managers/AuthManager';
import '../../css/LibraryAssessmentBuilderModal.less';

export default
@observer
class LibraryAssessmentBuilderModal extends Component {
  authorApp;

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.handleIframeTask);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleIframeTask);
  }

  handleIframeTask = (e) => {
    const { closeLibraryAssessmentBuilderModal, satellitePreview } = this.props;
    if ((e.data === 'closePressedBuilder' || e.data === 'hideIframeBuilder' || e.data === 'setFullscreenBuilder')) {
      closeLibraryAssessmentBuilderModal();
    }
    if (e.data === 'satellitePreview' ) {
      if(satellitePreview) {
        satellitePreview();
      }
    }
  };

  closeThisModal = async () => {
    const { closeLibraryAssessmentBuilderModal } = this.props;
    closeLibraryAssessmentBuilderModal();
  }

  render() {
    const { displayLibraryAssessmentBuilderModal, contentItemId } = this.props;
    const appUrl = `&appUrl=${encodeURIComponent(window.location.origin)}`;
    const itemPreviewUrl = `${Auth.lesson}/builder?contentItemId=${contentItemId}${appUrl}&satPreview=${Auth.overrideLessonPlayer}&satelliteCode=${Auth.publisherSatelliteCode}&sat=true#${Auth.authKey}`;
    return (
      <>
        <Modal
          className='LibraryAssessmentBuilderModal'
          closeOnDimmerClick={false}
          closeOnEscape={false}
          open={displayLibraryAssessmentBuilderModal}
          size='fullscreen'>
          <Iframe id='assessment-builder'
            allowFullScreen='true'
            height='100%'
            position='relative'
            src={itemPreviewUrl}
            title='Title'
            width='100%' />
        </Modal>
      </>
    );
  }
}

SatCoreRegister('LibraryAssessmentBuilderModal', LibraryAssessmentBuilderModal);
