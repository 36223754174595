/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Image } from 'semantic-ui-react';

import iconNoState from '../img/icon-standards-default.svg';
import icon_florida from '../img/states/icon-florida.svg';
import icon_georgia from '../img/states/icon-georgia.svg';
import icon_louisiana from '../img/states/icon-louisiana.svg';
import icon_ohio from '../img/states/icon-ohio.svg';
import icon_virginia from '../img/states/icon-virginia.svg';
import icon_texas from '../img/states/icon-texas.svg';

import { SatCoreRegister } from '../SatCoreRegistry';

export default @inject('reportOverrideManager', 'userManager')
@observer
class StateIconComponent extends Component {
  selectStateIcon = (institutionState) => {
    // check for an override
    if (this.props.selectStateIcon !== undefined) {
      return this.props.selectStateIcon(institutionState);
    }
    const { reportOverrideManager } = this.props;
    if (reportOverrideManager.reportTypeSelectorViewIconOverride?.standardsIcon) {
      return reportOverrideManager.reportTypeSelectorViewIconOverride?.standardsIcon;
    }
    const state = institutionState.toLowerCase();
    let stateIcon = null;
    switch (state) {
      case 'florida':
        stateIcon = icon_florida;
        break;
      case 'georgia':
        stateIcon = icon_georgia;
        break;
      case 'louisiana':
        stateIcon = icon_louisiana;
        break;
      case 'ohio':
        stateIcon = icon_ohio;
        break;
      case 'virginia':
        stateIcon = icon_virginia;
        break;
      case 'texas':
        stateIcon = icon_texas;
        break;
      default:
        return iconNoState;
    }
    return stateIcon;
  };

  render() {
    const { userManager } = this.props;
    const institutionState = this.props.institutionState || userManager.institutionState?.toLocaleLowerCase();
    const stateIcon = this.selectStateIcon(institutionState);
    return (
      <Image alt='' name={`icon-${institutionState}`} src={stateIcon} />
    );
  }
}

SatCoreRegister('StateIconComponent', StateIconComponent);
