import React, { useContext, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Card } from 'semantic-ui-react';

import classNames from 'classnames';

import '../css/DefaultNotificationCard.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import UtilityService from '../services/UtilityService';

const DefaultNotificationCard = observer((props) => {
  const { notification, t } = props;

  const {
    body,
    endDate: expirationDate,
    subject
  } = notification;

  const { /* managers */ } = useContext(MobXProviderContext);

  const [showMore, setShowMore] = useState(false);

  const handleMoreClick = () => {
    setShowMore(!showMore);
  };

  return (
    <Card className='DefaultNotificationCard' fluid>
      <Card.Content>
        <Card.Header>
          <div>{subject}</div>
          <div>
            <span>
              {t('expirationLabel')}
              :&nbsp;
            </span>
            <span className='regular-text'>{expirationDate}</span>
          </div>
        </Card.Header>
        <Card.Description>
          <div className={classNames({
            messageBody: !showMore,
            messageBodyFull: showMore
          })}>
            {UtilityService.reactHtmlParserWrapper(body || '')?.parsed}
          </div>
        </Card.Description>
        <div className='footer' onClick={() => handleMoreClick()}>
          {(showMore) ?
            t('showLessLink')
            :
            t('showMoreLink')}
        </div>
      </Card.Content>
    </Card>
  );
});
export default DefaultNotificationCard;

SatCoreRegister('DefaultNotificationCard', DefaultNotificationCard);
