import React from 'react';
import { observer } from 'mobx-react';

import ReactHtmlParser from 'react-html-parser';

import { Popup } from 'semantic-ui-react';

import classNames from 'classnames';

import '../css/SCCheckbox.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import UtilityService from '../services/UtilityService';

const transformFn = UtilityService.reactHtmlParserTransform;

const SCCheckbox = observer((props) => {
  const {
    // name,
    checked,
    disabled,
    hoverAdditionalClassNames,
    hoverMouseEnterDelay = 300,
    hoverLabelContent,
    label,
    onChange,
    useHoverLabel,
    value
  } = props;
  const transformedLabel = ReactHtmlParser(label, {
    transform: transformFn
  });
  const content = (useHoverLabel && hoverLabelContent) ? hoverLabelContent : <div>{transformedLabel}</div>;

  return (
    <div className='sc-check'>
      <label className={(disabled)
        ? 'check-label disabled'
        : (checked) ? 'check-label checked' : 'check-label'}>
        <span className={(checked) ? 'checkmark checked' : 'checkmark'} />
        <input
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          type='checkbox'
          value={value} />
        {(useHoverLabel) ? (
          <span>
            <Popup
              className={classNames('sc-checkbox-popup', hoverAdditionalClassNames)}
              content={content}
              hoverable
              mouseEnterDelay={hoverMouseEnterDelay}
              mouseLeaveDelay={0}
              on='hover'
              trigger={<span>{transformedLabel}</span>} />
          </span>
        ) : (
          ReactHtmlParser(label, {
            transform: transformFn
          })
        )}
      </label>
    </div>
  );
});

export default SCCheckbox;
SatCoreRegister('SCCheckbox', SCCheckbox);
