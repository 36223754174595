import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import {
  Button, Container, Form,
  Header, Image
} from 'semantic-ui-react';
import Modal from '../Modal';

import iconDrag from '../../img/icon-drag.svg';
import '../../css/AssessmentEditorModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import dialogManager, { DIALOG_NAMES } from '../../managers/DialogManager';

import CustomCourseResourceService from '../../services/CustomCourseResourceService';

import InputModal from './InputModal';
import { default as AssessmentItemCard } from '../AssessmentItemCard';

export default
@inject('assessmentManager', 'classroomManager', 'courseManager', 'userManager')
@observer
class AssessmentEditorModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');
  }

  componentDidMount = async () => {
    const { assessmentManager } = this.props;

    this.setState({
      draggableAssessmentItems: assessmentManager.attachedAssessmentItemList
    });
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  getInitialState = () => {
    const { assessmentManager } = this.props;
    return {
      activePage: 1,
      disableAdd: false,
      isConfirmAddCustomAssessmentModalOpen: false,
      isInputModalOpen: false,
      isSelectedAssessmentsPopupOpen: false,
      serverError: false,
      serverErrorMsg: '',
      showShoppingView: false,
      draggableAssessmentItems: assessmentManager.attachedAssessmentItemList,
      checkCounter: 0

    };
  }

  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background if dragging
    background: isDragging ? 'lightblue' : 'none',

    // styles we need to apply on draggables
    ...draggableStyle
  });

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightgrey' : 'none'
  });

  reorder = (list, startIndex, endIndex) => {
    const { assessmentManager } = this.props;
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // just do. don't wait for this.
    const learnosityActivityContentItemId = assessmentManager.currentContentItem.id;
    const entityId = removed.id;
    assessmentManager.moveElementWithinLearnosityActivity(learnosityActivityContentItemId, entityId, endIndex);

    return result;
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const draggableAssessmentItems = this.reorder(
      this.state.draggableAssessmentItems,
      result.source.index,
      result.destination.index
    );

    this.setState({
      draggableAssessmentItems
    });
  }

  handleLearnosityItemPreview = async (assessmentItem) => {
    await CustomCourseResourceService.handleLearnosityItemPreview(assessmentItem);
  }

  handleToggleShoppingView = async () => {
    const { history } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.RESOURCE_ITEM_SHOP, { assessmentItemsSearch: true, isCustomCourseResource: true, history },
      (fromSave) => this.closeShopRefreshEditor(fromSave));
  }

  closeShopRefreshEditor = async (/* fromSave */) => {
    const { assessmentManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.RESOURCE_ITEM_SHOP);
    await assessmentManager.clearAssessmentItemShopCollections();
    await assessmentManager.fetchResource(assessmentManager.currentContentItem.id);
    this.refreshItems();
  }

  refreshItems = () => {
    const { assessmentManager } = this.props;
    this.setState({
      draggableAssessmentItems: assessmentManager.attachedAssessmentItemList
    });
  }

  deleteItemFromAssessment = async (assessmentItem) => {
    await CustomCourseResourceService.deleteElementFromLearnosityActivity(assessmentItem.id);
    this.refreshItems();
  }

  closeAssessmentEditorModal = (didWeAddAssessments = false) => {
    this.setState(this.getInitialState());
    const { closeAssessmentEditorModal } = this.props;
    closeAssessmentEditorModal(didWeAddAssessments);
  }

  handleClickRename = async () => {
    this.setState({ isInputModalOpen: true });
  }

  doesNotHaveDuplicateContentName = async (name) => {
    const { assessmentManager } = this.props;
    const contentItem = { ...assessmentManager.currentContentItem };

    const nameDidNotChange = name === contentItem.name;
    if (nameDidNotChange) {
      return true;
    }
    const found = await assessmentManager.checkContentItemName(name, contentItem.entityTypeId);
    return !found;
  }

  handleSaveRenameAssessmentItem = async (save, name, subtitle) => {
    if (save) {
      const { assessmentManager, courseManager, classroomManager } = this.props;
      const contentItem = { ...assessmentManager.currentContentItem };

      if (contentItem.name !== name) {
        await assessmentManager.updateContentItemName(contentItem.id, name, name);
        contentItem.name = name;
      }
      if (subtitle !== contentItem.description) {
        await assessmentManager.updateContentItemDisplayProps(contentItem.id, subtitle, subtitle);
        contentItem.description = subtitle;
      }
      assessmentManager.setCurrentContentItem(contentItem);
      this.setState({
        isInputModalOpen: false
      });
      const courseContentItemId = courseManager.currentCourseId;
      const parentCourseResourceElementId = courseManager.currentElementId;
      const classroomId = classroomManager.currentClassroomId;
      await courseManager.fetchCourseData(courseContentItemId, classroomId, parentCourseResourceElementId, true, false, false);
    } else {
      this.setState({
        isInputModalOpen: false
      });
    }
  }

  getAttachedAssessmentItems = (selectedOnly = null) => {
    const { assessmentManager, userManager } = this.props;
    const items = [];
    this.state.draggableAssessmentItems.map((pItem, _index) => {
      if (!selectedOnly && assessmentManager.attachedAssessmentItems.has(pItem.id)) {
        pItem = { ...assessmentManager.attachedAssessmentItems.get(pItem.id) };
      }
      if (!selectedOnly || (pItem.selected && !pItem.attached)) {
        items.push(
          <Draggable key={pItem.id} draggableId={pItem.id} index={_index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={this.getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}>
                <Form.Field key={`${pItem.id}_FF`} className='add-assessment-field'>
                  <div className='grip-container'>
                    <div className='grip-control'>
                      <Image alt='' src={iconDrag} />
                    </div>
                  </div>
                  <AssessmentItemCard
                    assessmentItem={pItem}
                    authKey={userManager.authKey}
                    disabled={pItem.attached}
                    handleAssessmentItemCardRemove={() => this.deleteItemFromAssessment(pItem)}
                    handleCardClick={null}
                    handleLearnosityItemPreview={() => this.handleLearnosityItemPreview(pItem)}
                    isEditorView={true} />
                </Form.Field>
              </div>
            )}
          </Draggable>

        );
        return true;
      }
    });
    return items;
  }

  render() {
    const { SatCoreLoader } = this;
    const {
      isInputModalOpen,
      isSelectedAssessmentsPopupOpen
    } = this.state;
    const { assessmentManager, displayAssessmentEditorModal } = this.props;

    const { currentContentItem } = assessmentManager;
    const currentName = currentContentItem ? currentContentItem.name : '';
    const currentSubtitle = currentContentItem ? currentContentItem.description : '';

    const itemCountLabel = (assessmentManager.currentAssessmentItemCount === 1) ? `${assessmentManager.currentAssessmentItemCount} Item` : `${assessmentManager.currentAssessmentItemCount} Items`;
    const modalContentJsx = (
      <>
        {assessmentManager.assessmentItemsLoading ? <SatCoreLoader /> : (
          <Modal.Content scrolling>
            <Container className='assessment-item-container' fluid>
              <div className='modal-content-header'>
                <div className='assessment-header-info'>
                  <div className='title-wrapper'>
                    <div>{currentName}</div>
                    <div
                      className='blue clickable rename'
                      onClick={() => this.handleClickRename()}>
                      Rename
                    </div>
                  </div>
                  <div className='subtitle'>{currentSubtitle}</div>
                  <div className='item-count'>{itemCountLabel}</div>
                </div>
                <div className='btn-create-custom-assessment-wrapper'>
                  <Button
                    className='ui primary button create-custom-assessment-button'
                    disabled={isSelectedAssessmentsPopupOpen}
                    loading={this.state.disableAdd}
                    onClick={() => this.handleToggleShoppingView()}>
                    + Add Item
                  </Button>
                </div>
              </div>
              <div className='item-list-container'>
                {(assessmentManager.currentAssessmentItemCount < 1)
                  ?
                    <div className='no-items'> There are no items added yet.</div>
                  : (
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId='droppable'>
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={this.getListStyle(snapshot.isDraggingOver)}>
                            {this.getAttachedAssessmentItems()}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}
              </div>
              <div className='modal-content-footer' />
            </Container>
            {isInputModalOpen && (
              <InputModal
                bannerLabel='Rename'
                cancelLabel='Cancel'
                isOpen={isInputModalOpen}
                isValidName={this.doesNotHaveDuplicateContentName}
                name={assessmentManager.currentContentItem.name}
                onSave={(save, name, subtitle) => this.handleSaveRenameAssessmentItem(save, name, subtitle)}
                saveLabel='Save'
                subtitle={assessmentManager.currentContentItem.description}
                {...this.props} />
            )}
          </Modal.Content>
        )}
      </>
    );

    return (
      <>
        <Modal
          className='AssessmentEditorModal'
          closeOnDimmerClick={false}
          closeOnEscape={false}
          onClose={this.closeAssessmentEditorModal}
          open={displayAssessmentEditorModal}
          size='fullscreen'
          style={{ top: '-14px', overflow: 'hidden' }}>
          {(assessmentManager.assessmentItemsLoading) ? (
            <Modal.Content>
              <SatCoreLoader />
            </Modal.Content>
          ) : (
            <>
              <Modal.Header className='modal-header'>
                <Header.Content className='modal-header-bar'>
                  <span className='modal-header-label'>
                    Assessment Editor
                  </span>
                  <div className='modal-header-buttons'>
                    <Button
                      className='ui primary button saveButton'
                      onClick={this.closeAssessmentEditorModal}>
                      Done
                    </Button>
                    {/* <Button className='ui primary button saveButton'
                      onClick={this.refreshItems}>
                      Refresh Test
                    </Button> */}
                  </div>
                </Header.Content>
              </Modal.Header>
              <div className='nav-separator' />
              {modalContentJsx}
            </>
          )}
        </Modal>
      </>
    );
  }
}

SatCoreRegister('AssessmentEditorModal', AssessmentEditorModal);
