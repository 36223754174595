import { renderToStaticMarkup } from 'react-dom/server';

import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

import _ from 'lodash';

import { stripHtmlEntities, stripHtmlTags, stripHtmlTagsExceptImg, stripWrappingParagraphTags } from '../utils';

/**
 * // TODO rename to HtmlParserService
 *
 * // TODO pull out functions that are unrelated to html parsing
 */
export default class UtilityService {
  static getKey = () => Math.floor((Math.random() * 10000) + 1)

  static getRandomNumber = (min = 1, max = 999999) => Math.floor(Math.random() * (max - min + 1)) + min

  /**
   * Custom sort function, created especially for front end sorting of table column data.
   * @returns sorted column as array
   * @param {any[]} arr
   * @param {'ascending' | 'descending'} direction
   * @param {string[] | string} path the path of the property to get
   * @param {boolean} sortingNumbers default: false. if true, use custom sort strategy for numbers; if false, use localeCompare
   */
  static sortColumn = (arr, direction = 'ascending', path, sortingNumbers = false) => {
    if (direction === 'ascending') {
      arr = [...arr.sort((a, b) => {
        let aValue = _.get(a, path);
        let bValue = _.get(b, path);
        if (!sortingNumbers) {
          // sort in ASCENDING order, using `localeCompare` strategy
          return `${aValue}`.localeCompare(`${bValue}`, 'en', {
            numeric: true
          });
        }
        // sort in ASCENDING order, using custom strategy for sorting numbers, null values last
        aValue = typeof aValue === 'number' ? aValue : null;
        bValue = typeof bValue === 'number' ? bValue : null;
        return (aValue === null) - (bValue === null) || +(aValue > bValue) || -(aValue < bValue);
      })];
      return arr;
    } else if (direction === 'descending') {
      arr = [...arr.sort((a, b) => {
        const aValue = _.get(a, path);
        const bValue = _.get(b, path);
        if (!sortingNumbers) {
          // sort in DESCENDING order, using `localeCompare` strategy
          return `${bValue}`.localeCompare(`${aValue}`, 'en', {
            numeric: true
          });
        }
        // sort in DESCENDING order, using custom strategy for sorting numbers, null values last
        return (aValue === null) - (bValue === null) || -(aValue > bValue) || +(aValue < bValue);
      })];
      return arr;
    }
  }

  static reactHtmlParserWrapper = (str, ...htmlTagsToKeepForSelectivelyStrip) => {
    if (!str) {
      return {
        original: '',
        parsed: '',
        parsedMathOnly: '',
        parsedNoPWrap: '',
        selectivelyStripped: '',
        stripped: ''
      };
    }
    const original = `${str}`;

    let stripped = this.stripHtmlTags(original);
    stripped = stripHtmlEntities(stripped);

    const transformFn = this.reactHtmlParserTransform;

    let selectivelyStripped = stripHtmlTags(original, ...htmlTagsToKeepForSelectivelyStrip);
    selectivelyStripped = ReactHtmlParser(selectivelyStripped, { transform: transformFn });

    const parsed = ReactHtmlParser(str, { transform: transformFn });

    let parsedMathOnly;
    if (typeof str === 'string' && str.includes('</math>')) {
      const node = null, index = null;
      parsedMathOnly = this.parseMath(node, index, str);
    } else {
      parsedMathOnly = `${stripped}`;
    }

    const strNoPWrap = stripWrappingParagraphTags(str);
    const parsedNoPWrap = ReactHtmlParser(
      renderToStaticMarkup(strNoPWrap), {
        transform: transformFn
      });

    const result = {
      original,
      parsed,
      parsedMathOnly,
      parsedNoPWrap,
      selectivelyStripped,
      stripped
    };
    return result;
  }

  static reactHtmlParserTransform = (node, index) => {
    const specialTagNames = ['math'];
    for (const tagName of specialTagNames) {
      if (node.type === 'tag' && node.name === tagName) {
        if (tagName === 'math') {
          const parsedMath = this.parseMath(node, index);
          return parsedMath;
        }
        return null;
      }
    }
  }

  static parseMath = (node, index, rawStr) => {
    let reactElement, mathMarkup;
    const domElement = document.createElement('div');

    if (!rawStr) {
      reactElement = convertNodeToElement(node, index, () => {});
      mathMarkup = renderToStaticMarkup(reactElement);
      domElement.innerHTML = mathMarkup;
    } else {
      mathMarkup = this.removeMathStrAttributes(rawStr);
      domElement.innerHTML = mathMarkup;
    }
    try {
      const wiris = window.com.wiris.js.JsPluginViewer;
      wiris.parseElement(domElement); /* convert <math> tags to <img> */
    } catch {}
    const parsedStr = domElement.innerHTML;
    const parsedMathOnlyStr = stripHtmlTagsExceptImg(parsedStr);
    return ReactHtmlParser(parsedMathOnlyStr);
  }

  static removeMathStrAttributes = (str) => {
    let result = str;
    result = this.removeStrAttribute(result, 'href');
    result = this.removeStrAttribute(result, 'mathbackground');
    result = this.removeStrAttribute(result, 'mathcolor');
    result = this.removeStrAttribute(result, 'mathsize');
    return result;
  }

  static removeStrAttribute = (str, attr) => {
    if (str && attr && str.includes(attr)) {
      let result = str;
      while (result.includes(attr)) {
        const start = result.indexOf(`${attr}="`);
        const next = start + attr.length + 2;
        const end = result.indexOf('"', next);
        const substr = result.substring(start, end + 1);
        result = result.replace(substr, '');
      }
      return result;
    }
    return str;
  }

  static isEmptyHTML = (str) => {
    if (typeof str === 'string') {
      let stripped = this.stripHtmlTags(str);
      stripped = stripHtmlEntities(str);
      return stripped.trim() === '';
    }
  }

  /** @deprecated should use `src/utils/stripHtmlTags` instead */
  static stripHtmlTags = (str) => {
    if (typeof str === 'string') {
      return str.replace(/<\/?[^>]+(>|$)/g, '');
    }
    return str;
  }

  /** @deprecated */
  static stripHtmlTagsAndStrip = (str) => this.strip(this.stripHtmlTags(str))

  /** @deprecated appears to have same functionality as native JS (ES5+) `str.trim()` function */
  static strip = (str) => (typeof str === 'string' ? str.replace(/^\s+|\s+$/g, '') : str)

  /**
   * Uses ReactHtmlParser to convert an HTML string into React components. It uses a custom transform function
   * that utilizes wiris to handle mathml tags. It does not remove any block elements such as <p> and <div> tags.
   * So if the string is used for a table cell in the gradebook or reports and needs to be a single line without
   * line breaks, the table cell element should styled so all inner elements are inline. This can be done with the
   * following css:
   *   .table-cell {
   *     * {
   *       display: inline
   *     }
   *   }
   */
  static parseHtmlWithMath = (str) => {
    const transformFn = this.reactHtmlParserTransform;
    const parsed = ReactHtmlParser(str, { transform: transformFn });
    return parsed;
  }

  static replaceParagraphWithSpan = (str) => {
    // Create a new DOM element
    const tempElement = document.createElement('span');
    tempElement.innerHTML = str;

    // Select all the paragraph elements within the tempElement
    const paragraphs = tempElement.querySelectorAll('p');

    // Iterate over each paragraph and replace it with a span
    paragraphs.forEach((paragraph) => {
      const span = document.createElement('span'); // Create a new span element
      span.textContent = paragraph.textContent; // Copy the text content from the paragraph
      paragraph.parentNode.replaceChild(span, paragraph); // Replace the paragraph with the span
    });

    const parsed = tempElement.innerHTML;

    return parsed;
  }

  static convertAssignmentDateToJavaString = (date) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    };
    let dateStr = Intl.DateTimeFormat('en-US', options).format(new Date(date));
    dateStr = dateStr.replace(',', '').replace(' 24:', ' 00:');
    return dateStr;
  }
}
