import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ReactHtmlParser from 'react-html-parser';

import { Button } from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/LearnosityPlayerModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import { CONTENT_MODE } from '../../managers/ContentManager';
import { VIEW_SELECTION } from '../../managers/NavigationManager';

import { setSessionStorageItem } from '../../utils/session';

export default
@inject('learnosityItemsManager', 'assignmentManager', 'contentManager', 'userManager')
@observer
class LearnosityPlayerModal extends Component {
  interval;

  intervalCount = 0;

  itemsApp;

  isPlayerLoaded = false;

  constructor(props) {
    super(props);
    this.state = {
      addInfoOpen: false,
      cancelStart: false,
      errorMessage: '',
      hasNoContent: false,
      infoMessage: '',
      learnosityStylesheet: '',
      showErrorDialog: false,
      showAnswers: false,
      renderPostPlayerItems: false
    };

    this.InfoModal = SatCoreComponent('InfoModal');
    this.ErrorModal = SatCoreComponent('ErrorModal');
  }

  async componentDidMount() {
    const { contentManager } = this.props;

    this.interval = setInterval(() => {
      const learnosityContainer = document.getElementById('learnosity_assess');
      if (learnosityContainer?.hasChildNodes()) {
        clearInterval(this.interval);
      } else {
        if (this.intervalCount > 0) {
          this.setState({
            hasNoContent: true
          });
          clearInterval(this.interval);
        }
        this.intervalCount++;
      }
    }, 1250);

    // load the learnosity items api script
    if (!document.getElementById('learnosity-script') ||
      !document.getElementById('learnosity-script').attributes.src.value.includes('items.learnosity')) {
      const script = document.createElement('script');
      script.src = 'https://items.learnosity.com/?v2022.1.LTS';
      script.async = true;
      document.body.appendChild(script);

      if (contentManager.webReaderId !== null && contentManager.webReaderId !== undefined && contentManager.webReaderId.length > 0) {
        const scriptRs = document.createElement('script');
        scriptRs.src = `https://cdn1.readspeaker.com/script/${contentManager.webReaderId}/webReaderForEducation/learnosity/current/ReadSpeaker.Learnosity.js`;
        scriptRs.async = true;
        document.body.appendChild(scriptRs);
      }
    }
    // initiate the load assessment process
    this.startLoadAssessment();
  }

  startLoadAssessment = async (clearItemsApp = false) => {
    // Make sure itemsApp is cleared if needed
    if (clearItemsApp) {
      this.clearItemsApp();
    }
    // poll/wait for Learnosity javascript to load
    const interval = await setInterval(async () => {
      if (window.LearnosityItems && !this.state.cancelStart) {
      // stop polling
        clearInterval(interval);
        // render the assess UI
        await this.loadAssessment();
      }
    }, 1000);
  }

  // adds a css stylesheet to the dom
  setLearnosityStylesheet(learnosityStylesheet) {
    if (learnosityStylesheet) {
      // if a link exists, delete it.
      const existingStylesheet = document.querySelector('#learnosity-stylesheet');
      if (existingStylesheet) {
        existingStylesheet.parentNode.removeChild(existingStylesheet);
      }
      // create the link
      const link = document.createElement('link');
      link.href = learnosityStylesheet;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.id = 'learnosity-stylesheet';
      document.body.appendChild(link);
    }
  }

  // READY listener called when Learnosity API is ready
  readyListener = () => {
    // LEARNOSITY IS INITIALIZED BIND EVENTS HERE.
    this.isPlayerLoaded = true;
    this.setState({ renderPostPlayerItems: true });
    console.log(`LearnosityPlayerModal is Ready: ${this.isPlayerLoaded}`);

    // do this to ensure unanswered in session.
    const { learnosityItemsManager } = this.props;
    if (learnosityItemsManager.learnosityActivityType === 'submit_practice') {
      if (this.itemsApp !== null) {
        console.log('Saving itemsApp in readyListener');
        this.itemsApp.save();
      }
    }

    // if there is an open active player. close it.
    // this.closeReadSpeaker();
    const { contentManager } = this.props;
    if (contentManager.webReaderId !== null && contentManager.webReaderId !== undefined && contentManager.webReaderId.length > 0) {
    /// / Callback used to initialise ReadSpeaker toolbar once Learnosity assessment renders.
      window.rsCallbacks;
      // eslint-disable-next-line no-undef
      ReadSpeaker.q(() => {
        // eslint-disable-next-line no-undef
        rspkr.cfg.item('Learnosity.readSelector', '.l-content .content');
        // eslint-disable-next-line no-undef
        ReadSpeaker.Learnosity.init();
      });
    }
    // Set actions to take when an assessment is finished and submitted and closed button is clicked.
    // this may not surface unless something went wrong in the regular submit success.
    this.itemsApp.on('test:finished:submit', () => {
      const { closeModalCallback } = this.props;
      // this.clearItemsApp();
      if (closeModalCallback) {
        closeModalCallback();
      }
    });

    // Set actions to take on save of assessment
    // this.itemsApp.on('test:finished:save', () => {
    // });

    // Set actions to take when student clicks submit
    this.itemsApp.on('test:submit:', async () => {
      const { assignmentId, userManager } = this.props;
      console.log('*** IN test:submit - Student initiated submit of activity.');
      console.log(`*** IN test:submit - assignmentId: ${assignmentId} userId: ${userManager.userId} ****`);
      const assessApp = this.itemsApp.assessApp();
      let submissionData = null;
      if (assessApp) {
        submissionData = assessApp.getSubmissionData();
      }

      if (submissionData) {
        console.log('*** IN test:submit - SubmissionData from learnosity assessment submit: ', submissionData);
      }
    });

    // Set actions to take on submit success
    this.itemsApp.on('test:submit:success', async () => {
      const { assignmentManager, assignmentId, closeModalCallback, userManager } = this.props;
      console.log(`*** IN test:submit:success assignmentId: ${assignmentId} userId: ${userManager.userId} ****`);
      console.log(`*** IN test:submit:success userManager.canViewAsTeacher: ${userManager.canViewAsTeacher} ****`);
      // update the activity and test the status.
      let isSubmitted = false;
      if (!userManager.canViewAsTeacher) {
        const assessApp = this.itemsApp.assessApp();
        let submissionData = null;
        if (assessApp) {
          submissionData = await assessApp.getSubmissionData();
        }
        if (submissionData) {
          console.log('*** IN test:submit:success before call to submit assignment ****');
          isSubmitted = await assignmentManager.submitActivityInstance(assignmentId, submissionData, 'success');
          console.log('*** IN test:submit:success after call to submit assignment ****');
        }
      }
      console.log(`*** IN test:submit:success isSubmitted: ${isSubmitted}`);
      console.log('*** IN test:submit:success before close ReadSpeaker ****');
      await this.closeReadSpeaker();
      console.log('*** IN test:submit:success before handleSaveUnload ****');
      window.removeEventListener('beforeunload', this.handleSaveUnload);
      console.log('*** IN test:submit:success before LRN player close ****');
      // Check if showOutro was true or undefined which defaults to true, if so, do not close the player automatically
      const currentActivity = this.itemsApp.getActivity();
      const showOutro = currentActivity.config.navigation.show_outro !== 'undefined' ? currentActivity.config.navigation.show_outro : true;
      if (!showOutro) {
        if (closeModalCallback) {
          closeModalCallback();
        }
      }
      console.log('*** IN test:submit:success after LRN player close ****');
    });

    // Set actions to take on submit error
    this.itemsApp.on('test:submit:error', async () => {
      const { assignmentId, assignmentManager, userManager } = this.props;
      console.log(`*** IN test:submit:error assignmentId: ${assignmentId} userId: ${userManager.userId} ****`);
      console.log('Error during submission of assessement. May be a network issue.');
      const assessApp = this.itemsApp.assessApp();
      let submissionData = null;
      if (assessApp) {
        submissionData = await assessApp.getSubmissionData();
      }

      if (submissionData) {
        console.log(`*** IN test:submit:error before update submission data - assignmentId: ${assignmentId} userId: ${userManager.userId} ****`);
        await assignmentManager.updateActivityInstanceSubmissionData(assignmentId, submissionData, 'error');
        console.log('SubmissionData from learnosity assessment submit: ', submissionData);
      }
    });

    this.itemsApp.on('item:changing', async () => {
      await this.closeReadSpeaker();
    });

    this.itemsApp.on('item:unload', async () => {
      const { learnosityItemsManager } = this.props;
      if (learnosityItemsManager.learnosityActivityType === 'submit_practice' && this.isPlayerLoaded) {
        console.log('Saving itemsApp on item:unLoad');
        this.itemsApp.save();
      }
      // Get current item to get item ref
      // const currentItem = this.itemsApp.getCurrentItem();
      // console.log('currentItem: ', currentItem);
      // const itemRef = currentItem.reference;
      // console.log(`itemRef: ${itemRef}`);
      // const assessApp = this.itemsApp.assessApp();
      // const itemResponses = assessApp.getItemResponses();
      // const itemResponsesString = JSON.stringify(itemResponses);
      // console.log(`*** responses: ${itemResponsesString}`);
      // const currentResponse = itemResponses[itemRef];
      // console.log('Current item response: ', currentResponse);
    });

    window.addEventListener('beforeunload', this.handleSaveUnload);
  };

  handleSaveUnload = () => {
    const { learnosityItemsManager } = this.props;
    if (learnosityItemsManager.learnosityActivityType === 'submit_practice' && this.isPlayerLoaded) {
      if (this.itemsApp !== null) {
        console.log('Saving itemsApp in handleSaveUnload');
        this.itemsApp.save();
      }
    }
    window.removeEventListener('beforeunload', this.handleSaveUnload);
  };

  // ERROR Listener to capture errors thrown from the API
  errorListener = (e) => {
    console.error('LearnosityPlayerModal: learnosity error', e);
  };

  eventOptions = {
    readyListener: this.readyListener,
    errorListener: this.errorListener
  };

  async closeItemsApp() {
    await this.closeReadSpeaker();
    const { learnosityItemsManager, closeModalCallback } = this.props;
    if (learnosityItemsManager.learnosityActivityType === 'submit_practice' && this.isPlayerLoaded) {
      console.log('Saving itemsApp in closeItemsApp');
      this.itemsApp.save();
      this.clearItemsApp();
    }
    if (closeModalCallback) {
      closeModalCallback();
    }
  }

  async clearItemsApp() {
    if (this.itemsApp != null) {
      this.itemsApp.off();
      this.itemsApp.reset();
      this.isPlayerLoaded = false;
      this.setState({ renderPostPlayerItems: false });
    }
  }

  async closeReadSpeaker() {
    const { contentManager } = this.props;
    if (contentManager.webReaderId !== null && contentManager.webReaderId !== undefined && contentManager.webReaderId.length > 0) {
      // eslint-disable-next-line no-undef
      if (ReadSpeaker && ReadSpeaker.ui && ReadSpeaker.ui.getActivePlayer()) {
      // eslint-disable-next-line no-undef
        ReadSpeaker.ui.getActivePlayer().close();
      }
    }
  }

  async loadAssessment() {
    const { learnosityItemsManager, contentItemId, assignmentId, userManager, contentMode } = this.props;
    try {
      // Data will include the init request and the stylesheet to apply from settings
      let data = null;
      if (userManager.canViewAsTeacher || (userManager.isStudent && contentMode && contentMode === CONTENT_MODE.PREVIEW)) {
        data = await learnosityItemsManager.getLearnosityItemsApiPreviewRequest(contentItemId, userManager.canViewAsTeacher && this.state.showAnswers);
      } else {
        data = await learnosityItemsManager.getLearnosityItemsApiAssessmentRequest(assignmentId, true);
      }
      this.setLearnosityStylesheet(data.stylesheet);
      this.itemsApp = await window.LearnosityItems.init(JSON.parse(data.init), this.eventOptions);
    } catch (err) {
      this.openWarningModal(err);
      this.setState({ cancelStart: true });
    }
  }

  handleOpenInfo = () => {
    this.setState({ addInfoOpen: true });
  }

  closeInfoModal = () => {
    this.setState({ addInfoOpen: false });
  }

  openWarningModal = (message) => {
    this.setState({ showErrorDialog: true, errorMessage: message });
  }

  closeWarningModal = () => {
    this.setState({ showErrorDialog: false, errorMessage: '' });
  }

  openPrintComponent = async () => {
    const { assignmentId, contentItemId, contentName, contentSubTitle } = this.props;
    const { showAnswers } = this.state;
    const { history } = this.props;
    await this.clearItemsApp();
    // Some string params are in session storage because they could contain reserved url chars.
    const instanceData = {
      contentTitle: contentName,
      contentSubTitle,
    };
    setSessionStorageItem('c2c_printPreviewData', JSON.stringify(instanceData));
    history.push(`/lrnPrint?&assignmentId=${assignmentId}&contentItemId=${contentItemId}&returnView=${VIEW_SELECTION.ASSIGNMENTS}&showAnswers=${showAnswers}`);
  }

  toggleShowPrintAnswers = async () => {
    this.setState({ showAnswers: !this.state.showAnswers });
    this.startLoadAssessment();
  }

  renderStudentInstructionButton = () => {
    const { instruction, userManager } = this.props;
    if (instruction && instruction !== '<p></p>') {
      return (
        <Button
          className={(!userManager.canViewAsTeacher ? 'basic student-instruction white' : 'basic white')}
          onClick={() => this.handleOpenInfo()}
          primary>
          Student Instructions
        </Button>
      );
    }
    return null;
  }

  renderDivider = () => {
    const { instruction, userManager } = this.props;
    if (!userManager.canViewAsTeacher && (!instruction || instruction !== '<p></p>')) {
      return (<div className='student-divider' />);
    }
    return null;
  }

  renderPrintButton = () => {
    const { userManager } = this.props;
    if (userManager.canViewAsTeacher) {
      return (
        <>
          <Button className={this.state.showAnswers ? 'basic primary white' : 'primary'} onClick={() => this.toggleShowPrintAnswers()}>Show Answers: {this.state.showAnswers ? 'On' : 'Off'}</Button>
          <Button onClick={() => this.openPrintComponent()} primary>Print Preview</Button>
        </>
      );
    }
    return null;
  }

  renderCloseButton = () => {
    const { learnosityItemsManager } = this.props;
    if (learnosityItemsManager.learnosityActivityType !== 'submit_practice') {
      return (<Button onClick={() => this.closeItemsApp()} primary>Close</Button>);
    }
    return null;
  }

  render() {
    const { InfoModal, ErrorModal } = this;
    const {
      contentImageUrl, contentName, instruction, resourceName, t
    } = this.props;
    const {
      addInfoOpen, errorMessage, hasNoContent, showErrorDialog
    } = this.state;

    if (this.isPlayerLoaded && hasNoContent) {
      this.setState({ hasNoContent: false });
    }
    return (
      <div className='learnosity-modal-container'>
        <Modal
          className='l-player-modal'
          onMount={() => document.body.classList.add('body-l-player-modal')}
          onUnmount={() => document.body.classList.remove('body-l-player-modal')}
          open
          size='fullscreen'>

          <div className='l-container'>
            <div className='l-header'>
              <div className='modal-title-text'>
                {ReactHtmlParser(contentName)}
              </div>
              <div className='modal-title-button'>
                {this.renderStudentInstructionButton()}
                {this.renderDivider()}
                {this.state.renderPostPlayerItems && this.renderPrintButton()}
                {this.renderDivider()}
                {this.renderCloseButton()}
              </div>
            </div>
            <div className='l-content'>
              <div id='learnosity_assess' />
              {hasNoContent && (
                <div className='l-has-no-content'>
                  {t('hasNoContent', 'To view the assessment, content needs to be created in the assessment.')}
                </div>
              )}
            </div>
          </div>
          <InfoModal
            addInfoOpen={addInfoOpen}
            closeInfoModal={this.closeInfoModal}
            contentItemDescription={null}
            imageUrl={contentImageUrl}
            infoName={resourceName}
            resourceInfo={null}
            studentInstruction={instruction} />
          <ErrorModal
            addErrorOpen={showErrorDialog}
            closeErrorModal={this.closeWarningModal}
            errorMessage={errorMessage} />
        </Modal>
      </div>
    );
  }
}

SatCoreRegister('LearnosityPlayerModal', LearnosityPlayerModal);
