import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import InfiniteScroll from 'react-infinite-scroller';

import classNames from 'classnames';

import {
  Button, Checkbox, Confirm, Icon, Image, Loader, Popup, Table
} from 'semantic-ui-react';

import iconLate from '../img/icon-late-submission.svg';
import iconHourglass from '../img/icon-hourglass.svg';
import iconView from '../img/icon-view.svg';

import '../css/GradebookTable.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import EngagementTable from './engagement/EngagementTable';

import Auth from '../managers/AuthManager';

import AssignmentManager, { ASSIGNMENT_STATUS, ASSIGNMENT_TYPE, PDF_DELIVERY_FORMATS } from '../managers/AssignmentManager';
import { CONTENT_ITEM_TYPES, PLAYER_TYPES } from '../managers/ContentManager';
import { DIALOG_NAMES } from '../managers/DialogManager';

import { dateFormat, scrollBrowserWindowToTop, toFixed } from '../utils';

import AssignmentService from '../services/AssignmentService';
import GradebookService from '../services/GradebookService';
import GroupService from '../services/GroupService';
import ImageService from '../services/ImageService';
import PopupService from '../services/PopupService';

export default
@inject(
  'assignmentManager', 'contentManager', 'dialogManager',
  'gradebookManager', 'groupsManager', 'userManager')
@observer
class GradebookTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assignmentPopupOpen: {},
      classroomId: '',
      contentMode: '',
      contentSubTitle: '',
      contentTitle: '',
      contentUrl: '',
      curInstance: null,
      docreaderViewerShowing: false,
      fileViewerShowing: false,
      isFlowpaper: false,
      learnosityScoringPlayerShowing: false,
      lessonPlayerShowing: false,
      loading: false,
      shouldRenderConfirmationForUpdatedAssignmentStatus: false,
      studentName: '',
    };

    const { gradebookManager } = this.props;
    const { currentAssignment } = gradebookManager;

    this.state.contentTitle = (currentAssignment.resourceWebTitle !== '' && currentAssignment.resourceWebTitle != null)
      ? currentAssignment.resourceWebTitle : currentAssignment.name;

    this.state.contentSubTitle = (currentAssignment.resourceWebSubtitle !== '' && currentAssignment.resourceWebSubtitle != null)
      ? currentAssignment.resourceWebSubtitle : currentAssignment.description;

    this.AssignmentStatus = SatCoreComponent('AssignmentStatus');
    this.DocReaderModal = SatCoreComponent('DocReaderModal');
    this.FeedbackIcon = SatCoreComponent('FeedbackIcon');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');
    this.FullscreenModal = SatCoreComponent('FullscreenModal');
    this.GradebookDetailsTable = SatCoreComponent('GradebookDetailsTable');
    this.GradebookStandards = SatCoreComponent('GradebookStandards');
    this.LearnosityScoringModal = SatCoreComponent('LearnosityScoringModal');
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
  }

  componentDidMount = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const classroomId = urlParams.get('classroomId');
    if (classroomId) {
      this.setState({ classroomId });
    }
  };

  componentDidUpdate = async () => {
    const isReviewingStudentWork = !!sessionStorage.getItem('c2c_isReviewingStudentWork');
    if (isReviewingStudentWork) {
      sessionStorage.removeItem('c2c_isReviewingStudentWork');
      // eslint-disable-next-line react/destructuring-assignment
      await this.props.refreshAllGradebookData();
    }
  }

  getTooltipStyle = () => {
    return {
      backgroundColor: '#ffffff',
      border: 'solid 1px #707070',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.5)',
      height: '87.5px',
      lineHeight: '1.38',
      padding: '10px !important',
      width: '191px'
    };
  }

  showGradeColumn = (instanceInfo, isPercent = true) => {
    if (!instanceInfo) {
      return;
    }
    const { assignmentManager, gradebookManager, t } = this.props;

    const isReadOnly = GradebookService.isReadOnly();

    const toolTipStyle = this.getTooltipStyle();
    let assignment;
    if (instanceInfo) {
      assignment = assignmentManager.getAssignment(instanceInfo.activityId);
    }
    assignment = assignment || gradebookManager.currentAssignment || {};

    const entityTypeId = assignment.contentItemEntityTypeId || assignment.entityTypeId || instanceInfo.assignEntityTypeId;

    // Safely handle the assessment and lesson unsubmitted scenario without affecting other types.
    if ((entityTypeId === CONTENT_ITEM_TYPES.LESSON || entityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT ||
      entityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE && instanceInfo.status !== ASSIGNMENT_STATUS.COMPLETED)
      && (instanceInfo && !instanceInfo.submitted)
    ) {
      return (<div className='empty'>—</div>);
    }

    const gradePopup = this.getGradePopup(instanceInfo, isPercent);
    if (instanceInfo.submitted
      || instanceInfo.status === ASSIGNMENT_STATUS.CLOSED
      || instanceInfo.status === ASSIGNMENT_STATUS.COMPLETED
      || instanceInfo.status === 'pending-rescore'
    ) {
      if (this.checkIsPendingScore(instanceInfo)) {
        return (
          <Popup
            className='gradebook-summary-table-popup gradebook-hourglass-popup'
            hideOnScroll
            hoverable
            on='hover'
            position='top center'
            style={toolTipStyle}
            trigger={<Image alt='' className='icon-hourglass' src={iconHourglass} />}>
            <Popup.Header><div className='popup-message header'>Waiting for score to sync with remote system</div></Popup.Header>
            <Popup.Content>
              <div className='popup-message'>This could take 1-2 minutes</div>
            </Popup.Content>
          </Popup>
        );
      } else if (instanceInfo.manualScoring) {
        if (instanceInfo.grade === null || instanceInfo.grade === '') {
          if ((entityTypeId === CONTENT_ITEM_TYPES.PDF_RESOURCE && assignment.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF)
            || CONTENT_ITEM_TYPES.isGradeableType(entityTypeId)
          ) {
            if (gradebookManager.useStudentWorkScoreButton) {
              return (
                <Button
                  className='primary submit-button'
                  disabled={gradebookManager.gradebookManagerLoadingFlagActive || isReadOnly}
                  onClick={() => {
                    this.handleGradeAssignment(instanceInfo);
                  }}>
                  {t('scoreButtonLabel', 'Score')}
                </Button>
              );
            } else {
              return (
                <Icon
                  className={classNames(!isReadOnly ? 'clickable link-color' : '')}
                  name='pencil'
                  onClick={() => {
                    if (!gradebookManager.gradebookManagerLoadingFlagActive && !isReadOnly) {
                      this.handleGradeAssignment(instanceInfo);
                    }
                  }} />
              );
            }
          }
          if (gradebookManager.useStudentWorkScoreButton) {
            return (
              <Button
                className='primary submit-button'
                disabled={gradebookManager.gradebookManagerLoadingFlagActive || isReadOnly}
                onClick={() => this.handlePresent({ instance: instanceInfo })}>
                {t('scoreButtonLabel', 'Score')}
              </Button>
            );
          } else {
            return (
              <Icon
                className={classNames(!isReadOnly ? 'clickable link-color' : '')}
                name='pencil'
                onClick={() => {
                  if (!gradebookManager.gradebookManagerLoadingFlagActive && !isReadOnly) {
                    this.handlePresent({ instance: instanceInfo });
                  }
                }} />
            );
          }
        }
        if ((entityTypeId === CONTENT_ITEM_TYPES.PDF_RESOURCE
            && assignment.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF)
            || CONTENT_ITEM_TYPES.isGradeableType(entityTypeId)
        ) {
          return (
            <div
              className={classNames(!isReadOnly ? 'view-link clickable blue' : '')}
              onClick={() => {
                if (!gradebookManager.gradebookManagerLoadingFlagActive && !isReadOnly) {
                  this.handleGradeAssignment(instanceInfo);
                }
              }}>
              {instanceInfo.grade}
              %
            </div>
          );
        }
        return gradePopup;
      }
      return gradePopup;
    }
    return (<div className='empty'>—</div>);
  }

  getGradePopup = (instanceInfo, isPercent = true) => {
    const { gradebookManager, t } = this.props;

    const isReadOnly = GradebookService.isReadOnly();
    let { grade } = instanceInfo;
    const { manualScoring, maxScore, totalScore } = instanceInfo;

    // Check if we have a null grade for autoscored.  Can happen if student skips all quesitons.
    // setting to zero will allow teacher to view in the score view.
    if (!manualScoring && grade === null) {
      grade = 0;
    }

    // Define popup content depending on whether we are showing percentage or raw score
    let popupContent;
    let gradeDisplay = 0;
    const points = typeof totalScore === 'number' ? toFixed(totalScore) : 0;
    const maxPoints = typeof maxScore === 'number' ? toFixed(maxScore) : 0;
    if (isPercent) {
      gradeDisplay = `${grade}%`;
      const shouldShowPointsOverPoints = (typeof grade === 'number' && typeof maxScore === 'number');
      if (shouldShowPointsOverPoints) {
        popupContent = (
          <>
            <strong>{t('points')}&nbsp;</strong>
            <>{`${points}/${maxPoints}`}</>
          </>
        );
      }
    } else {
      gradeDisplay = `${points}/${maxPoints}`;
      const shouldShowPercentage = typeof grade === 'number';
      if (shouldShowPercentage) {
        popupContent = (
          <>
            <strong>{t('grade')}&nbsp;</strong>
            <>{grade}%</>
          </>
        );
      }
    }
    return PopupService.renderPopup({
      content: popupContent,
      disabled: !popupContent,
      trigger: (
        <div
          className={classNames(!isReadOnly ? 'view-link clickable blue' : '')}
          onClick={() => {
            if (!gradebookManager.gradebookManagerLoadingFlagActive && !isReadOnly) {
              this.handlePresent({ instance: instanceInfo });
            }
          }}>
          {gradeDisplay}
        </div>
      )
    });
  }

  // Determines if we are waiting on a pending rescore
  checkIsPendingScore = (instance) => {
    if (instance.status === 'pending-rescore') {
      return true;
    } else if (instance.manualScoring) {
      return false;
    }
    // check if there's an outstanding pending message update
    if (instance.grade == null) {
      let remoteScoreMessageSent = null;
      let remoteScoreMessageReceived = null;
      if (instance.remoteMessageSentAt) {
        remoteScoreMessageSent = new Date(instance.remoteMessageSentAt);
      }
      if (!remoteScoreMessageSent) {
        return false;
      }
      // These values are updated but never cleared so determine whether we are pending by checking
      // if the sent timestamp is newer than the last received, if any.
      if (instance.remoteMessageReceivedAt) {
        remoteScoreMessageReceived = new Date(instance.remoteMessageReceivedAt);
      }
      if (remoteScoreMessageReceived && remoteScoreMessageReceived > remoteScoreMessageSent) {
        return false;
      }
      return true;
    }
    return false;
  }

  getGrade = (score, max) => {
    if (max > 0 && score != null) {
      return (score / max) * 100;
    }
    return 0;
  }

  launchFeedback = (instance) => {
    const { dialogManager } = this.props;
    const studentName = `${instance.firstName} ${instance.lastName}`;

    const isReadOnly = GradebookService.isReadOnly();

    dialogManager.setOpenDialog(DIALOG_NAMES.TEACHER_FEEDBACK, {
      assignmentInstanceId: instance.id,
      isReadOnly,
      studentName
    }, () => dialogManager.closeDialog(DIALOG_NAMES.TEACHER_FEEDBACK));
  }

  handleLoadMore = async (page) => {
    const { gradebookManager } = this.props;
    const assignment = gradebookManager.currentAssignment;

    const sortField = gradebookManager.gradebookSummaryTableSortColumn;
    const sortDirection = gradebookManager.gradebookSummaryTableSortDirection;

    const pageSize = undefined;
    const clearFirst = undefined;
    const functions = {
      isSpecialAggregateGradebookSummarySortCase: GradebookService.isSpecialAggregateGradebookSummarySortCase
    };
    await gradebookManager.fetchAggregateGradebookData(
      assignment, sortField, sortDirection, page,
      pageSize, clearFirst, functions
    );
  }

  handleGradeAssignment = (instance) => {
    const { dialogManager } = this.props;
    const currentAssignment = AssignmentService.getCurrentAssignment(instance);
    dialogManager.setOpenDialog(
      DIALOG_NAMES.SCORE_ASSIGNMENT, {
        activityInstanceId: instance.id,
        grade: instance.grade,
        maxScore: currentAssignment.maxScore,
        studentName: `${instance.firstName} ${instance.lastName}`,
        totalScore: instance.totalScore
      }, this.closeModalsAndRefresh
    );
  }

  closeModalsAndRefresh = async () => {
    sessionStorage.removeItem('scoreAssignmentModalInfo');
    const { dialogManager } = this.props;
    await dialogManager.closeAllDialogs();
    this.hideModal();
    await this.refreshGradebookTableData();
  }

  handleClickScoreCellDetails = async (scoreCellDetails) => {
    const { gradebookManager } = this.props;
    const { instanceInfo } = scoreCellDetails;
    await this.handleReviewStudentWorkInC2C(instanceInfo, scoreCellDetails);
    gradebookManager.setGradebookDetails(null);
    sessionStorage.setItem('c2c_isReviewingStudentWork', 'true');
  }

  /**
   * refactored to call `handleReviewStudentWorkInC2C()` to better portray what is happening here.
   *
   * keeping this method name around for now though, to prevent potentially breaking other areas of the app.
   */
  handlePresent = async (params) => {
    const { instance, scoreCellDetails = null } = params;
    await this.handleReviewStudentWorkInC2C(instance, scoreCellDetails);
    sessionStorage.setItem('c2c_isReviewingStudentWork', 'true');
  }

  handleReviewStudentWorkInC2C = async (instance, scoreCellDetails = null) => {
    const { assignmentManager, contentManager, gradebookManager, userManager } = this.props;

    const cellActivityElement = scoreCellDetails ? scoreCellDetails.cellActivityElement : null;

    let activityId;
    if (cellActivityElement) {
      activityId = cellActivityElement.activityId;
    } else {
      activityId = instance.activityId;
    }

    const assignment = await assignmentManager.getAssignmentAsync(activityId);

    const elementId = scoreCellDetails?.elementId;

    // TODO : Move this to where it makes sense
    if (assignment && assignment.entityTypeId === CONTENT_ITEM_TYPES.PDF_RESOURCE) {
      const selectedAssignment = gradebookManager.currentAssignment;
      if (selectedAssignment) {
        AssignmentManager.setAssignmentField(assignment.id, 'pdfDeliveryFormat', selectedAssignment.pdfDeliveryFormat);
      }
    }

    const option = await contentManager.getOptionsForTeacherReviewAndScoring(
      assignment, instance,
      window.location.origin, userManager.isTeacher, elementId, activityId
    );
    const { playerType, viewUrl, contentMode, isFlowpaper } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    }

    const { dialogManager } = this.props;
    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        contentItemId: assignment.contentItemId,
        contentItemType: cellActivityElement ?
          cellActivityElement.assignEntityTypeId : assignment.contentItemEntityTypeId || assignment.entityTypeId,
        resourceName: assignment.contentItemName || assignment.resourceWebTitle || assignment.name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
      return;
    }

    contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    this.setState({
      contentMode,
      contentPreviewShowing: (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER),
      contentSubTitle: assignment.resourceWebSubtitle || assignment.description,
      contentTitle: assignment.contentItemName || assignment.resourceWebTitle || assignment.name,
      contentUrl: viewUrl,
      curInstance: instance,
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      isFlowpaper,
      learnosityScoringPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_SCORING_PLAYER),
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      studentName: `${instance.firstName} ${instance.lastName}`
    });
  }

  handleSubmitInstance = async (activityInstanceId) => {
    const { assignmentManager } = this.props;
    await assignmentManager.teacherSubmitActivityInstance(activityInstanceId);
    await this.refreshGradebookTableData();
  }

  handleUnsubmitInstance = async (activityInstanceId) => {
    const { assignmentManager } = this.props;
    await assignmentManager.teacherUnsubmitActivityInstance(activityInstanceId);
    await this.refreshGradebookTableData();
  }

  hideModal = () => {
    this.setState({ learnosityScoringPlayerShowing: false, fileViewerShowing: false, docreaderViewerShowing: false });
  }

  hideIframeFromOuterClick = (event) => {
    if ((event.origin === Auth.publisher || event.origin === Auth.lesson) && event.data === 'hideIframe') {
      this.setState({ lessonPlayerShowing: false });
      this.closeModalsAndRefresh();
      window.removeEventListener('message', this.hideIframeFromOuterClick);
    }
  }

  handleSort = async (clickedColumn = null) => {
    const { gradebookManager } = this.props;
    const { allowLoadMoreAggregateTableRows } = gradebookManager;

    if (allowLoadMoreAggregateTableRows) {
      gradebookManager.setAllowLoadMoreAggregateTableRows(false);
      if (GradebookService.isSpecialAggregateGradebookSummarySortCase(clickedColumn)) {
        await GradebookService.handleSpecialAggregateGradebookSummarySortCase(clickedColumn);
      } else {
        await GradebookService.handleGradebookSummarySortOnBackend(clickedColumn);
      }
      gradebookManager.setAllowLoadMoreAggregateTableRows(true);
    }
  }

  handleExcludeItemFromScore = async (_event, data) => {
    if (!data || data.disabled) {
      return;
    }
    const { gradebookManager, assignmentManager } = this.props;

    if (!data.scoreCellDetails || !data.scoreCellDetails.cellActivityElement || !data.scoreCellDetails.cellActivityElement.id) {
      throw new TypeError('handleExcludeItemFromScore: data.scoreCellDetails.cellActivityElement.id is required');
    }

    this.setState({ loading: true });
    gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(true);

    const { activityId } = data.scoreCellDetails;
    const activityElementId = data.scoreCellDetails.cellActivityElement.id;
    const exclude = data.checked;
    const isAggregate = data.scoreCellDetails.tableSection === 'header';

    await gradebookManager.updateActivityElementExclusion(activityElementId, exclude, isAggregate);

    // After we update the exlusion if the activity has no manually graded questions and its status is closed
    // it gets set to graded. We need to fetch the assignment to make sure we account for this status change.
    // Currently we fetch the assignment if the status is closed. We don't have a check for the manually scored
    // questions, but it could be added in the future if necessary.
    if (data.scoreCellDetails.instanceInfo.status === ASSIGNMENT_STATUS.CLOSED) {
      const assignment = await gradebookManager.fetchGradebookActivity(activityId, true);
      if (assignment) {
        // Update the assignment manager with this fresh assignment.
        assignmentManager.setAssignment(assignment);
      }
    }
    this.setState({ loading: false });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.refreshAllGradebookData();
  }

  handleBringStatusBackToClosed = async (_event, data) => {
    const { gradebookManager } = this.props;

    this.setState({ loading: true });

    const changes = { status: ASSIGNMENT_STATUS.CLOSED };

    let alsoUpdateCurrentAssignment;
    if (gradebookManager.currentAssignment && gradebookManager.currentAssignment.id === data.assignment.id) {
      alsoUpdateCurrentAssignment = true;
    }
    await AssignmentService.updateAssignment(data.assignment, changes, alsoUpdateCurrentAssignment);
    this.setState({ loading: false, shouldRenderConfirmationForUpdatedAssignmentStatus: true });
  }

  handleChangeGradeSettingToggle = async (instance, clickedColumn) => {
    instance[clickedColumn] = !instance[clickedColumn];
    this.setState({ loading: true });

    const { assignmentManager, gradebookManager } = this.props;
    gradebookManager.clearBulkCheckedAssignmentMembers();
    const assignment = await assignmentManager.fetchActivity(instance.activityId);

    const changes = {};
    changes[clickedColumn] = instance[clickedColumn];

    await AssignmentService.updateAssignment(assignment, changes).then(() => {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.refreshAllGradebookData().then(() => {
        this.setState({ loading: false });
      });
    });
  }

  handleOpenEditDomainAssignmentModal = async (instanceInfo) => {
    const { handleOpenEditBulkAssignmentModal } = this.props;
    this.handleLinkedCheck(instanceInfo.id).then(() => {
      handleOpenEditBulkAssignmentModal(instanceInfo);
    });
  }

  handleOpenEditAssignmentModal = async (instanceInfo) => {
    const { assignmentManager, dialogManager, gradebookManager, history } = this.props;
    this.setState({ loading: true });
    const assignment = await gradebookManager.fetchGradebookActivity(instanceInfo.activityId, true);
    if (assignment) {
      // Update the assignment manager with this fresh assignment.
      assignmentManager.setAssignment(assignment);
    }

    scrollBrowserWindowToTop();

    const contentImageUrl = ImageService.getImageUrl(assignment);
    this.setState({ loading: false });
    dialogManager.setOpenDialog(DIALOG_NAMES.EDIT_ASSIGNMENT, {
      activityNumber: instanceInfo.activityNumber,
      assignmentId: assignment.id,
      assignmentInstruction: assignment.instruction,
      classroomId: assignment.classroomId,
      contentImageUrl,
      history,
      standards: assignment.standardsInfo,
      contentTimeframeStartDate: assignment.timeframeStartDate,
      contentTimeframeEndDate: assignment.timeframeEndDate,
      timeframeStartDateStr: assignment.timeframeStartDateStr,
      timeframeEndDateStr: assignment.timeframeEndDateStr,
      timeframeStartTimeStr: assignment.timeframeStartTimeStr,
      timeframeEndTimeStr: assignment.timeframeEndTimeStr,
      
    }, () => {
      dialogManager.closeDialog(DIALOG_NAMES.EDIT_ASSIGNMENT);
      gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(true);
      this.setState({ loading: true });
      // eslint-disable-next-line react/destructuring-assignment
      this.props.refreshAllGradebookData().then(() => {
        this.setState({ loading: false });
        gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(false);
      });
    });
  }

  handleOpenAddAssignmentModal = async (instanceInfo) => {
    const { onClickAssignButton } = this.props;
    onClickAssignButton(instanceInfo, true); // true for reassign
  }

  handleCheck = (checkedActivityInstance) => {
    const { gradebookManager } = this.props;
    gradebookManager.setAssignmentInstanceBulkChecked(checkedActivityInstance);
  }

  handleLinkedCheck = async (checkedActivityInstance) => {
    const { gradebookManager } = this.props;
    gradebookManager.clearBulkCheckedAssignmentMembers();
    gradebookManager.setLinkedAssignmentInstancesBulkChecked(checkedActivityInstance);
  }

  refreshGradebookTableData = async () => {
    // eslint-disable-next-line react/destructuring-assignment
    await this.props.refreshAllGradebookData();
    await this.handleSort();
  }

  useSubmitButton = (instance) => {
    const { gradebookManager } = this.props;
    const currentAssignment = AssignmentService.getCurrentAssignment(instance);

    let useIt = false;
    if (currentAssignment.status !== ASSIGNMENT_STATUS.LOCKED && gradebookManager.useSubmitButton) {
      useIt = true;
    }
    return useIt;
  }

  renderLearnosityScoringModal = () => {
    const { gradebookManager, history, userManager } = this.props;
    const { LearnosityScoringModal, state } = this;

    const currentAssignment = AssignmentService.getCurrentAssignment(state.curInstance);

    return (
      <div className='course-content'>
        <LearnosityScoringModal
          activityInstanceId={state.curInstance.id}
          assignmentId={gradebookManager.currentAssignment.id}
          canManageScores={userManager.canManageLearnosityScores}
          closeModalCallback={this.closeModalsAndRefresh}
          contentImageUrl={null}
          contentItemId={state.contentItemId}
          contentSubTitle={state.contentSubTitle}
          contentTitle={state.contentTitle}
          grade={state.curInstance.grade}
          history={history}
          instruction={currentAssignment.instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-scoring'
          resourceName={currentAssignment.contentItemName}
          studentName={state.studentName} />
      </div>
    );
  }

  renderLessonPlayer = () => {
    const { FullscreenModal, state } = this;
    const { userManager } = this.props;

    const currentAssignment = AssignmentService.getCurrentAssignment(state.curInstance);

    return (
      <div className='course-content'>
        <FullscreenModal
          closeIframeCallback={this.hideModal}
          contentImageUrl={null}
          // eslint-disable-next-line max-len
          contentName={(currentAssignment.resourceWebTitle !== '' && currentAssignment.resourceWebTitle != null) ? currentAssignment.resourceWebTitle : currentAssignment.name}
          instruction={currentAssignment.instruction}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={state.contentUrl} />
      </div>
    );
  }

  getAssignmentNumberCell = (instance, t) => {
    const { assignmentManager, gradebookManager } = this.props;
    const { assignmentPopupOpen } = this.state;

    const isReadOnly = GradebookService.isReadOnly();

    const assignment = assignmentManager.getAssignment(instance.activityId);
    const assignmentTypeId = (instance.subdomainTypeId) ? instance.subdomainTypeId : instance.assignEntityTypeId;
    // figure out how the edit for class/group buttons should be labeled and what edit modal they will open
    let editEntityLabel = null;
    let editForEntityCallback = this.handleOpenEditDomainAssignmentModal; // bulk edit for linked assignments
    if (assignmentTypeId === ASSIGNMENT_TYPE.CLASSROOM) {
      editEntityLabel = t('editForClassButtonText', 'Edit for Class');
      if (instance.assignEntityTypeId === ASSIGNMENT_TYPE.CLASSROOM) {
        // If it's the old type assignment for Entire Class single assignment use regular edit.
        editForEntityCallback = this.handleOpenEditAssignmentModal;
      }
    } else if (assignmentTypeId === ASSIGNMENT_TYPE.GROUP) {
      editEntityLabel = t('editForGroupButtonText', 'Edit for Group');
    }
    const isClosed = instance.status === ASSIGNMENT_STATUS.CLOSED;
    const isGraded = instance.status === ASSIGNMENT_STATUS.COMPLETED;
    // eslint-disable-next-line prefer-destructuring
    const allowAssignmentLink = gradebookManager.allowAssignmentLink;
    const assignmentLinkButtonDisabled = isClosed || isGraded;
    return (
      <Popup
        className='gradebook-assignment-column-popup'
        content={(
          <div className='centered-popup-box'>
            <div className='assignment-name'>{assignment && (assignment.nickname || assignment.name)}</div>
            {instance.assignEntityTypeId === ASSIGNMENT_TYPE.USER && !isReadOnly && (
              <div>
                <Button
                  className='basic primary submit-button'
                  disabled={gradebookManager.gradebookManagerLoadingFlagActive || isReadOnly}
                  onClick={() => {
                    this.setAssignmentPopupClose(instance.id);
                    this.handleOpenEditAssignmentModal(instance);
                  }}>
                  {t('editButtonText', 'Edit')}
                </Button>
              </div>
            )}
            {editEntityLabel && !isReadOnly && (
              <div>
                <Button
                  className='basic primary submit-button'
                  disabled={gradebookManager.gradebookManagerLoadingFlagActive || isReadOnly}
                  onClick={() => {
                    this.setAssignmentPopupClose(instance.id);
                    editForEntityCallback(instance);
                  }}>
                  {editEntityLabel}
                </Button>
              </div>
            )}
            {(allowAssignmentLink && instance.assignEntityTypeId !== ASSIGNMENT_TYPE.CLASSROOM) && !isReadOnly && (
              <div>
                <Button
                  className='basic primary submit-button copy-assign'
                  disabled={gradebookManager.gradebookManagerLoadingFlagActive || assignmentLinkButtonDisabled || isReadOnly}
                  onClick={() => {
                    this.setAssignmentPopupClose(instance.id);
                    this.copyAssignmentURL(instance);
                  }}>
                  {t('copyAssignUrlText', 'Copy Assignment URL')}
                </Button>
              </div>
            )}
          </div>
        )}
        hoverable
        on={['hover', 'click']}
        onClose={() => this.setAssignmentPopupClose()}
        onOpen={() => this.setAssignmentPopupOpen(instance.id)}
        open={assignmentPopupOpen.popupKey === instance.id}
        position='bottom center'
        trigger={(
          <div className={classNames(!isReadOnly ? 'view-link clickable blue' : '')}>
            {instance.activityNumber}
          </div>
      )} />
    );
  };

  setAssignmentPopupOpen = (popupKey) => {
    this.setState({ assignmentPopupOpen: { popupKey } });
  }

  setAssignmentPopupClose = () => {
    this.setState({ assignmentPopupOpen: {} });
  }

  copyAssignmentURL = (instance) => {
    const { gradebookManager, t } = this.props;
    const url = gradebookManager.getAssignmentLaunchUrl(instance.id);
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(url).then(() => {
        // show copied dialog
        this.showTextMessage(t('copyAssignSuccessText'));
      }).catch((err) => {
        // show error dialog
        this.showTextMessage(`${t('copyAssignFailureText')}: ${err}`);
      });
    } else {
      const copied = document.execCommand('copy', true, url);
      if (copied) {
        // show copied dialog
        this.showTextMessaget(t('copyAssignSuccessText'));
      } else {
        // show copied dialog
        this.showTextMessage(t('copyAssignFailureText'));
      }
    }
  }

  showTextMessage = (message) => {
    const { dialogManager } = this.props;
    if (message) {
      dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
        closeButtonName: 'OK',
        message,
        title: 'Copy Assignment URL'
      }, () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
    }
  }

  getSubmittedCell = (instance, isLearnosityResource, t) => {
    const isLate = instance.studentStartedLate && instance.submitted;
    const useSubmitButton = this.useSubmitButton(instance) && (isLearnosityResource ? instance.remoteSessionId !== null : true);
    const toolTipStyle = this.getTooltipStyle();

    const isReadOnly = GradebookService.isReadOnly();

    return (
      // eslint-disable-next-line no-nested-ternary
      useSubmitButton || isReadOnly ? (
        // eslint-disable-next-line no-nested-ternary
        instance.submitted || isReadOnly ? (
          <div className={isLate ? 'submitted-late-wrapper' : ''}>
            {(isLate) && (
              <Popup
                className='gradebook-summary-table-popup'
                content={t('lateIconText', 'Missing translation for late icon')}
                on='hover'
                position='bottom center'
                trigger={(
                  <img alt='' src={iconLate} />
                )}
                wide />
            )}
            <Popup
              className='gradebook-summary-table-popup'
              content={(
                <div className='centered-popup-box'>
                  {!isLearnosityResource && (
                    <>
                      <div>Unsubmit Assignment</div>
                      <div>
                        <Button className='basic primary submit-button'
                          disabled={isReadOnly}
                          onClick={() => this.handleUnsubmitInstance(instance.id)}>
                          {t('unsubmitLabel', 'Unsubmit')}
                        </Button>
                      </div>
                    </>
                  )}
                  <div>Reassign Assignment</div>
                  <div>
                    <Button className='basic primary submit-button'
                      disabled={isReadOnly}
                      onClick={() => this.handleOpenAddAssignmentModal(instance)}>
                      {t('reassignLabel', 'Reassign')}
                    </Button>
                  </div>
                </div>
              )}
              disabled={isReadOnly}
              hoverable
              inline='true'
              position='bottom center'
              trigger={(
                <div className={classNames({
                  'blue': !isReadOnly,
                  'clickable': !isReadOnly,
                  'isLate': isLate,
                  'view-link': !isReadOnly
                })}>
                  {instance.timezoneSubmittedDate || (isReadOnly ? '—' : ' ')}
                </div>
              )} />
          </div>
        ) : (instance.status === 'pending-submit' ? (
          <Popup
            className='gradebook-summary-table-popup'
            hideOnScroll
            on='hover'
            style={toolTipStyle}
            trigger={<Image alt='' className='icon-hourglass' src={iconHourglass} />}>
            <Popup.Header><div className='popup-message header'>Waiting for submit request to sync with remote system</div></Popup.Header>
            <Popup.Content>
              <div className='popup-message'>This could take 1-2 minutes</div>
            </Popup.Content>
          </Popup>
        ) : (
          <Button className='primary submit-button'
            disabled={isReadOnly}
            onClick={() => this.handleSubmitInstance(instance.id)}>
            {t('submitLabel', 'Submit')}
          </Button>
        ))
      ) : (
        instance.submitted ? (
          <div className={isLate ? 'submitted-late-wrapper' : ''}>
            {(isLate) && (
              <Popup
                content={t('lateIconText', 'Missing translation for late icon')}
                on='hover'
                position='bottom center'
                trigger={(
                  <img alt='' src={iconLate} />
                )}
                wide />
            )}
            <div className={isLate ? 'isLate' : ''}>{instance.timezoneSubmittedDate}</div>
          </div>
        ) : <div className='empty'>—</div>
      )
    );
  }

  renderFileViewer = () => {
    const { userManager } = this.props;
    const { FileViewerModal, state } = this;

    const currentAssignment = AssignmentService.getCurrentAssignment(state.curInstance);

    return (
      <div className='course-content'>
        <FileViewerModal
          assignmentId={state.curInstance.id}
          closeModalCallback={this.closeModalsAndRefresh}
          contentImageUrl={null}
          contentMode={state.contentMode}
          // eslint-disable-next-line max-len
          contentName={(currentAssignment.resourceWebTitle !== '' && currentAssignment.resourceWebTitle != null) ? currentAssignment.resourceWebTitle : currentAssignment.name}
          grade={state.curInstance.grade}
          instruction={currentAssignment.instruction}
          isFlowpaper={state.isFlowpaper}
          isTeacher={userManager.isTeacher}
          maxScore={currentAssignment.maxScore}
          page='file-viewer'
          resourceName={currentAssignment.contentItemName}
          studentName={state.studentName}
          totalScore={state.curInstance.totalScore}
          url={state.contentUrl} />
      </div>
    );
  }

  renderDocreaderViewer = () => {
    const { userManager, contentManager } = this.props;

    const { state } = this;

    const currentAssignment = AssignmentService.getCurrentAssignment(state.curInstance);

    const { DocReaderModal } = this;
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.renderDocreaderViewer !== undefined) {
      // eslint-disable-next-line react/destructuring-assignment
      return this.props.renderDocreaderViewer();
    }

    return (
      <div className='course-content'>
        <DocReaderModal
          assignmentId={state.curInstance.id}
          closeModalCallback={this.closeModalsAndRefresh}
          contentImageUrl={null}
          contentMode={state.contentMode}
          // eslint-disable-next-line max-len
          contentName={(currentAssignment.resourceWebTitle !== '' && currentAssignment.resourceWebTitle != null) ? currentAssignment.resourceWebTitle : currentAssignment.name}
          contentType={state.previewContentType}
          grade={state.curInstance.grade}
          instruction={currentAssignment.instruction}
          isFlowpaper={state.isFlowpaper}
          isTeacher={userManager.isTeacher}
          maxScore={currentAssignment.maxScore}
          page='docreader-viewer'
          resourceName={currentAssignment.contentItemName}
          sessionId={contentManager.currentflowPaperSessionId}
          studentName={state.studentName}
          totalScore={state.curInstance.totalScore}
          url={state.contentUrl} />
      </div>
    );
  }

  renderDetailsGradebookTable = () => {
    const { assignmentEntityTypeId, gradebookManager, refreshAllGradebookData, t } = this.props;
    const { gradebookManagerLoadingFlagActive } = gradebookManager;
    const { loading, shouldRenderConfirmationForUpdatedAssignmentStatus } = this.state;
    const { GradebookDetailsTable, ModalBanner } = this;
    const headerJsx = (
      <ModalBanner
        label={t('confirmHeader_assignmentStatusUpdatedToClosed')}
        onClose={() => this.setState({
          shouldRenderConfirmationForUpdatedAssignmentStatus: false
        })} />
    );
    const waitingForGradebookSummaryTableComponent = loading || gradebookManagerLoadingFlagActive
      || shouldRenderConfirmationForUpdatedAssignmentStatus;
    const confirmContentMsg = t('confirmContent_assignmentStatusUpdatedToClosedForAggregate');
    return (
      <>
        <GradebookDetailsTable
          {...this.props}
          assignment={null}
          assignmentEntityTypeId={assignmentEntityTypeId}
          getAssignmentNumberCell={this.getAssignmentNumberCell}
          handleBringStatusBackToClosed={this.handleBringStatusBackToClosed}
          handleExcludeItemFromScore={this.handleExcludeItemFromScore}
          onClickActivityNumber={this.handleOpenEditAssignmentModal}
          // eslint-disable-next-line react/destructuring-assignment
          onClickScoreCellDetails={this.handleClickScoreCellDetails}
          refreshAllGradebookData={refreshAllGradebookData}
          showGradeColumn={this.showGradeColumn}
          waitingForGradebookSummaryTableComponent={waitingForGradebookSummaryTableComponent} />
        <Confirm
          cancelButton={false}
          className='confirm-assignment-status-updated-to-closed'
          confirmButton={t('confirmButton')}
          content={confirmContentMsg}
          header={headerJsx}
          onConfirm={
            () => {
              this.setState({ shouldRenderConfirmationForUpdatedAssignmentStatus: false });
              refreshAllGradebookData();
            }
          }
          open={shouldRenderConfirmationForUpdatedAssignmentStatus} />
      </>
    );
  }

  renderStandardsGradebookTable = () => {
    const { GradebookStandards } = this;

    return (
      <GradebookStandards />
    );
  }

  renderDefaultGradebookTable = () => {
    const { gradebookManager } = this.props;
    const { state } = this;
    const {
      allowLoadMoreAggregateTableRows,
      gradebookManagerLoadingFlagActive,
      hasMoreAggregateTableRows
    } = gradebookManager;

    const isLoading = state.loading || gradebookManagerLoadingFlagActive;
    return (
      <>
        <InfiniteScroll
          hasMore={!isLoading && (allowLoadMoreAggregateTableRows && hasMoreAggregateTableRows)}
          initialLoad={true}
          loadMore={(page) => allowLoadMoreAggregateTableRows && this.handleLoadMore(page)}>
          <Table
            className={`gradebook-summary-table${isLoading ? ' loading-table' : ''}`}
            sortable
            striped>
            <Table.Header>
              {this.renderTableHeader()}
            </Table.Header>
            <Table.Body>
              {this.renderTableBodyRows()}
            </Table.Body>
          </Table>
          <Loader
            active={isLoading && allowLoadMoreAggregateTableRows && hasMoreAggregateTableRows}
            inline='centered'
            style={{ marginBottom: '14px' }} />
        </InfiniteScroll>
      </>
    );
  }

  renderTableHeader = () => {
    const { gradebookManager, t } = this.props;
    const { state, SCCheckbox } = this;
    const {
      activeGradebookType, gradebookManagerLoadingFlagActive
    } = gradebookManager;

    const isLoading = state.loading || gradebookManagerLoadingFlagActive;

    const isReadOnly = GradebookService.isReadOnly();

    const column = gradebookManager.gradebookSummaryTableSortColumn;
    const direction = gradebookManager.gradebookSummaryTableSortDirection;

    return (
      <Table.Row
        className={isLoading ? 'loading-row' : ''}
        disabled={isLoading}>

        {/* HEADER: BULK SELECT */}
        {!isReadOnly && (
          <Table.HeaderCell
            className={classNames(`cell-bulk-select ${activeGradebookType}`)}>
            <SCCheckbox
              key='check-all-activity-instances'
              checked={gradebookManager.bulkCheckedAll}
              onChange={() => { gradebookManager.setBulkCheckAll(); }} />
          </Table.HeaderCell>
        )}

        {/* HEADER: ACTIVITY NUMBER (ASGMT) */}
        <Table.HeaderCell
          className={classNames(`cell-activity-number ${activeGradebookType}`)}
          onClick={() => this.handleSort('activityNumber')}
          sorted={column === 'activityNumber' ? direction : null}>
          {t('activityNumber')}
        </Table.HeaderCell>

        {/* HEADER: START DATE */}
        <Table.HeaderCell
          className={classNames(`cell-start-date ${activeGradebookType}`)}
          onClick={() => this.handleSort('activityTimezoneStartDate')}
          sorted={column === 'activityTimezoneStartDate' ? direction : null}>
          {t('activityTimezoneStartDate')}
        </Table.HeaderCell>

        {/* HEADER: END DATE */}
        <Table.HeaderCell
          className={classNames(`cell-end-date ${activeGradebookType}`)}
          onClick={() => this.handleSort('activityTimezoneEndDate')}
          sorted={column === 'activityTimezoneEndDate' ? direction : null}>
          {t('activityTimezoneEndDate')}
        </Table.HeaderCell>

        {/* HEADER: TYPE ([C]lass or [I]ndividual) */}
        <Table.HeaderCell
          className={classNames(`cell-assigned-type ${activeGradebookType}`)}
          onClick={() => this.handleSort('assignEntityTypeId')}
          sorted={column === 'assignEntityTypeId' ? direction : null}>
          {t('assignedType')}
        </Table.HeaderCell>

        {/* HEADER: FIRST NAME */}
        <Table.HeaderCell
          className={classNames(`cell-first-name ${activeGradebookType}`)}
          onClick={() => this.handleSort('firstName')}
          sorted={column === 'firstName' ? direction : null}>
          {t('firstName')}
        </Table.HeaderCell>

        {/* HEADER: LAST NAME */}
        <Table.HeaderCell
          className={classNames(`cell-last-name ${activeGradebookType}`)}
          onClick={() => this.handleSort('lastName')}
          sorted={column === 'lastName' ? direction : null}>
          {t('lastName')}
        </Table.HeaderCell>

        {/* HEADER: ASSIGNMENT STATUS */}
        <Table.HeaderCell
          className={classNames(`cell-assignment-student-status ${activeGradebookType}`)}
          onClick={() => this.handleSort('status')}
          sorted={column === 'status' ? direction : null}>
          {t('status')}
        </Table.HeaderCell>

        {/* HEADER: STUDENT STATUS */}
        <Table.HeaderCell
          className={classNames(`cell-assignment-student-status ${activeGradebookType}`)}
          onClick={() => this.handleSort('studentStatus')}
          sorted={column === 'studentStatus' ? direction : null}>
          {t('studentStatus')}
        </Table.HeaderCell>

        {/* HEADER: SUBMITTED */}
        <Table.HeaderCell
          className={classNames(`cell-submitted ${activeGradebookType}`)}
          onClick={() => this.handleSort('timezoneSubmittedDate')}
          sorted={column === 'timezoneSubmittedDate' ? direction : null}
          textAlign='center'>
          {t('submitted')}
        </Table.HeaderCell>

        {/* HEADER: STUDENT WORK */}
        <Table.HeaderCell
          className={classNames(`cell-student-version ${activeGradebookType}`)}
          textAlign='center'>
          {t('studentVersion')}
        </Table.HeaderCell>

        {/* HEADER: GRADE
        <Table.HeaderCell
          className={`cell-grade ${activeGradebookType}`}
          onClick={() => this.handleSort('grade')}
          sorted={column === 'grade' ? direction : null}
          textAlign='center'>
          {t('grade')}
        </Table.HeaderCell> */}

        {/* HEADER: GRADES TO REPORTS */}
        <Table.HeaderCell className={classNames(`cell-grades-to-reports ${activeGradebookType}`)}>
          {t('includeInReports')}
        </Table.HeaderCell>

        {/* HEADER: GRADES TO STUDENTS */}
        <Table.HeaderCell className={classNames(`cell-grades-to-students ${activeGradebookType}`)}>
          {t('scoresReleased')}
        </Table.HeaderCell>

        {/* HEADER: STUDENTS CAN REVIEW */}
        <Table.HeaderCell className={classNames(`cell-students-can-review ${activeGradebookType}`)}>
          {t('studentReview')}
        </Table.HeaderCell>

        {/* HEADER: FEEDBACK */}
        <Table.HeaderCell
          className={classNames(`cell-feedback ${activeGradebookType}`)}
          textAlign='center'>
          {t('feedback')}
        </Table.HeaderCell>

      </Table.Row>
    );
  }

  renderTableBodyRows = () => {
    const { gradebookManager, groupsManager, t } = this.props;

    const { AssignmentStatus, FeedbackIcon, SCCheckbox, state } = this;
    const { classroomId, loading } = state;

    const {
      activeGradebookType, assignmentInstances,
      gradebookManagerLoadingFlagActive
    } = gradebookManager;

    const isLoading = loading || gradebookManagerLoadingFlagActive;

    const isReadOnly = GradebookService.isReadOnly();

    const currentAssignment = AssignmentService.getCurrentAssignment(state.curInstance);

    const processingRowColSpan = (isReadOnly) ? 14 : 15;

    const assignmentProcessingText = (gradebookManager.assignmentsProcessingCount === 1) ?
      t('assignmentProcessing') : t('assignmentsProcessing');

    return (
      <>
        {(gradebookManager.assignmentsProcessingCount > 0) && (
          <Table.Row
            key='key-data-row-processing-activities'
            className={isLoading ? 'loading-row' : ''}
            disabled={isLoading}
            style={{ backgroundColor: 'rgba(240, 240, 240)' }}>
            <Table.Cell className={classNames(`cell-processing ${activeGradebookType}`, {
              'is-read-only': isReadOnly
            })} colSpan={processingRowColSpan}>
              <div>
                <p>{gradebookManager.assignmentsProcessingCount} {assignmentProcessingText}
                  <span className='ellipsis-anim'><span>.</span><span>.</span><span>.</span></span>
                </p>
              </div>
            </Table.Cell>
          </Table.Row>
        )}
        {assignmentInstances.map((instance, index) => {
          // const isLocked = instance.status === ASSIGNMENT_STATUS.LOCKED;
          // const notStarted = instance.status === ASSIGNMENT_STATUS.READY;
          const isStarted = instance.status === ASSIGNMENT_STATUS.STARTED;
          const hasStartedDate = instance.startedDate !== null;
          const isClosed = instance.status === ASSIGNMENT_STATUS.CLOSED;
          const isGraded = instance.status === ASSIGNMENT_STATUS.COMPLETED;
          const isSubmitted = instance.submitted;
          // const isManualScored = instance.manualScoring;
          const isPendingRescore = this.checkIsPendingScore(instance);
          const assignmentTypeId = (instance.subdomainTypeId) ? instance.subdomainTypeId : instance.assignEntityTypeId;
          const isBulkChecked = (instance.bulkChecked !== undefined) ? instance.bulkChecked : false;
          const isLearnosityResource = currentAssignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE;
          const allowViewStudentVersion = !isReadOnly && (isStarted ||
              // eslint-disable-next-line max-len
              ((isClosed || isGraded) && !isSubmitted && (!isLearnosityResource || isLearnosityResource && hasStartedDate))) && !isPendingRescore;
          const isLate = instance.studentStartedLate && instance.submitted;
          let studentStatus = ''; // new student status
          let studentPopupStatus = null;
          if (hasStartedDate && (!instance.studentSubmitted)) {
            studentStatus = t('studentStarted');
            if (isSubmitted) {
              studentPopupStatus = `${t('studentStarted')}, teacher submitted`;
            }
          } else if (instance.studentSubmitted) {
            studentStatus = t('studentSubmitted');
          } else {
            studentStatus = t('studentNotStarted');
            if (isSubmitted) {
              studentPopupStatus = `${t('studentNotStarted')}, teacher submitted`;
            }
          }
          return (
            <Table.Row
              key={`key-data-row-${instance.id}-${index}`}
              className={isLoading ? 'loading-row' : ''}
              disabled={isLoading}>

              {/* BODY: BULK SELECT (bulk activity instance update selection) */}
              {!isReadOnly && (
                <Table.Cell className={`cell-bulk-select ${activeGradebookType}`}>
                  <SCCheckbox
                    key='check-all-activity-instances'
                    checked={isBulkChecked}
                    onChange={() => { this.handleCheck(instance.id); }} />
                </Table.Cell>
              )}

              {/* BODY: ACTIVITY NUMBER (ASGMT) */}
              <Table.Cell className={classNames(`cell-activity-number ${activeGradebookType}`, {
                'is-read-only': isReadOnly
              })}>
                <span>
                  {this.getAssignmentNumberCell(instance, t)}
                </span>
              </Table.Cell>

              {/* BODY: START DATE */}
              <Table.Cell className={classNames(`cell-start-date ${activeGradebookType}`, {
                'is-read-only': isReadOnly
              })}>
                <span>
                  {dateFormat(instance.activityTimezoneStartDate, 'twoDigitDate')}
                </span>
              </Table.Cell>

              {/* BODY: END DATE */}
              <Table.Cell className={classNames(`cell-end-date ${activeGradebookType}`, {
                'is-read-only': isReadOnly
              })}>
                <span>
                  {dateFormat(instance.activityTimezoneEndDate, 'twoDigitDate')}
                </span>
              </Table.Cell>

              {/* BODY: TYPE ([C]lass or [I]ndividual) (G) Group */}
              <Table.Cell className={classNames(`cell-assigned-type ${activeGradebookType}`, {
                'is-read-only': isReadOnly
              })}>
                {(instance.subdomainTypeId !== ASSIGNMENT_TYPE.GROUP) && (
                  <Popup
                    className='gradebook-summary-table-popup'
                    content={t(`assignEntityTypeIdVal_${assignmentTypeId}`)}
                    hoverable
                    on='hover'
                    position='top center'
                    positionFixed
                    trigger={(
                      <span>
                        {t(`assignEntityTypeIdKey_${assignmentTypeId}`)}
                      </span>
                    )} />
                )}
                {(instance.subdomainTypeId === ASSIGNMENT_TYPE.GROUP) && (
                  <Popup
                    hideOnScroll={false}
                    hoverable={true}
                    on='hover'
                    onOpen={() => {
                      groupsManager.setGroupMemberPopupLoading(true);
                      const group = groupsManager.getGroup(instance.subdomainId);
                      if (!group) {
                        GroupService.initArchivedAndUnarchivedClassroomGroups({
                          classroomId
                        }).then(() => {
                          groupsManager.setGroupMemberPopupLoading(false);
                        });
                      } else {
                        groupsManager.setGroupMemberPopupLoading(false);
                      }
                    }}
                    position='bottom center'
                    trigger={(
                      <span>
                        {t(`assignEntityTypeIdKey_${assignmentTypeId}`)}
                      </span>
                    )}
                    wide>
                    <>
                      {(!groupsManager.groups.length || groupsManager.groupMemberPopupLoading)
                        ? <Loader active inline /> : this.renderGroupAssignmentInfoPopupContent(instance)}
                    </>
                  </Popup>
                )}
              </Table.Cell>

              {/* BODY: FIRST NAME */}
              <Table.Cell className={classNames(`cell-first-name ${activeGradebookType}`, {
                'is-read-only': isReadOnly
              })}>
                <Popup
                  className='gradebook-summary-table-popup'
                  content={instance.firstName}
                  hoverable
                  on='hover'
                  position='top center'
                  positionFixed
                  trigger={<span>{instance.firstName}</span>} />
              </Table.Cell>

              {/* BODY: LAST NAME */}
              <Table.Cell className={classNames(`cell-last-name ${activeGradebookType}`, {
                'is-read-only': isReadOnly
              })}>
                <Popup
                  className='gradebook-summary-table-popup'
                  content={instance.lastName}
                  hoverable
                  on='hover'
                  position='top center'
                  positionFixed
                  trigger={<span>{instance.lastName}</span>} />
              </Table.Cell>

              {/* BODY: ASSIGNMENT STATUS */}
              <Table.Cell className={classNames(`cell-assignment-status ${activeGradebookType}`, {
                'is-read-only': isReadOnly
              })}>
                <Popup
                  className='gradebook-summary-table-popup'
                  content={t(instance.status, ASSIGNMENT_STATUS.getFlag(instance.status))}
                  hoverable
                  on='hover'
                  position='top center'
                  positionFixed
                  trigger={(
                    <span>
                      <AssignmentStatus
                        isGradebook={true}
                        isSubmitted={instance.submitted}
                        noLabel={true}
                        onClick={() => {
                          if (!gradebookManager.gradebookManagerLoadingFlagActive && !isReadOnly) {
                            this.handleOpenEditAssignmentModal(instance);
                          }
                        }}
                        status={instance.status} />
                    </span>
                  )} />
              </Table.Cell>

              {/* BODY: STUDENT STATUS */}
              <Table.Cell className={classNames(`cell-assignment-student-status ${activeGradebookType}`, {
                'is-read-only': isReadOnly
              })}>
                <Popup
                  className='gradebook-summary-table-popup'
                  content={studentPopupStatus || t(studentStatus)}
                  hoverable
                  on='hover'
                  position='top center'
                  positionFixed
                  trigger={(
                    <span>
                      {studentStatus}
                    </span>
                  )} />
              </Table.Cell>

              {/* BODY: SUBMITTED */}
              <Table.Cell
                className={classNames(`cell-submitted ${activeGradebookType}`, {
                  'is-read-only': isReadOnly,
                  'isLate': isLate
                })}
                textAlign='center'>
                {this.getSubmittedCell(instance, isLearnosityResource, t)}
              </Table.Cell>

              {/* BODY: STUDENT VERSION or BODY: GRADE */}
              {(allowViewStudentVersion) ? (
                <Table.Cell
                  className={classNames(`cell-student-version ${activeGradebookType}`, {
                    'is-read-only': isReadOnly
                  })}
                  textAlign='center'>
                  <div
                    className='view-icon-wrapper'
                    onClick={() => {
                      if (!gradebookManager.gradebookManagerLoadingFlagActive && !isReadOnly) {
                        this.handlePresent({ instance });
                      }
                    }}>
                    <img alt='' src={iconView} />
                  </div>
                </Table.Cell>
              ) : (
                <Table.Cell
                  className={`cell-grade ${activeGradebookType}`}
                  textAlign='center'>
                  {this.showGradeColumn(instance, true)}
                </Table.Cell>
              )}

              {/* BODY: GRADES TO REPORTS */}
              <Table.Cell className={classNames(`cell-grades-to-reports ${activeGradebookType}`, {
                'is-read-only': isReadOnly
              })}>
                {this.renderGradeSettingModifier(instance, 'includeInReports')}
              </Table.Cell>

              {/* BODY: GRADES TO STUDENTS */}
              <Table.Cell className={classNames(`cell-grades-to-students ${activeGradebookType}`, {
                'is-read-only': isReadOnly
              })}>
                {this.renderGradeSettingModifier(instance, 'scoresReleased')}
              </Table.Cell>

              {/* BODY: STUDENTS CAN REVIEW */}
              <Table.Cell className={classNames(`cell-students-can-review ${activeGradebookType}`, {
                'is-read-only': isReadOnly
              })}>
                {this.renderGradeSettingModifier(instance, 'studentReview')}
              </Table.Cell>

              {/* BODY: FEEDBACK */}
              <Table.Cell
                className={classNames(`cell-feedback ${activeGradebookType}`, {
                  'is-read-only': isReadOnly
                })}
                textAlign='center'>
                <FeedbackIcon
                  clickHandler={() => this.launchFeedback(instance)}
                  disabled={isLoading}
                  hasFeedback={instance.teacherFeedback} />
              </Table.Cell>

            </Table.Row>
          );
        })}
      </>
    );
  }

  renderGroupAssignmentInfoPopupContent = (instance) => {
    const { groupsManager } = this.props;
    const groupId = instance.subdomainId;
    const groupName = null;
    const groupMembers = null;
    const showDescription = false;
    const showMemberList = true;
    const content = groupsManager.renderGroupAssignmentInfoPopupContent(
      groupId, groupName, groupMembers, showDescription, showMemberList
    );
    return content;
  }

  renderGradeSettingModifier = (instance, column) => {
    const isReadOnly = GradebookService.isReadOnly();
    if (!isReadOnly && instance.assignEntityTypeId === 'user') {
      return this.renderIndividualGradeSettingToggle(instance, column);
    }
    return this.renderClassroomGradeSettingLink(instance, column);
  }

  renderIndividualGradeSettingToggle = (instance, column) => {
    const { gradebookManager } = this.props;
    const { gradebookManagerLoadingFlagActive } = gradebookManager;
    // eslint-disable-next-line react/destructuring-assignment
    const isLoading = this.state.loading || gradebookManagerLoadingFlagActive;
    return (
      <Checkbox
        checked={instance[column]}
        className='cell-grades-toggler'
        disabled={isLoading}
        onChange={() => this.handleChangeGradeSettingToggle(instance, column)}
        toggle />
    );
  }

  renderClassroomGradeSettingLink = (instance, column) => {
    const { gradebookManager } = this.props;
    const isReadOnly = GradebookService.isReadOnly();

    let isTrue;
    switch (column) {
    case 'includeInReports':
      isTrue = instance.includeInReports === true;
      break;
    case 'scoresReleased':
      isTrue = instance.scoresReleased === true;
      break;
    case 'studentReview':
      isTrue = instance.studentReview === true;
      break;
    }

    const label = isTrue ? 'On' : 'Off';
    return (
      <div
        className='classroom-grade-setting-link-wrapper'
        onClick={() => {
          if (!gradebookManager.gradebookManagerLoadingFlagActive && !isReadOnly) {
            this.handleOpenEditAssignmentModal(instance);
          }
        }}>
        <div className={classNames('classroom-grade-setting-link', {
          'is-read-only': isReadOnly
        })}>
          {label}
        </div>
      </div>
    );
  }

  render() {
    const { gradebookManager } = this.props;
    const { state } = this;
    const { activeGradebookTable } = gradebookManager;

    if (activeGradebookTable === 'engagement') {
      return <EngagementTable {...this.props} getAssignmentNumberCell={this.getAssignmentNumberCell} />;
    }

    if (state.learnosityScoringPlayerShowing) {
      return this.renderLearnosityScoringModal();
    } else if (state.lessonPlayerShowing) {
      return (this.renderLessonPlayer());
    } else if (state.fileViewerShowing) {
      return (this.renderFileViewer());
    } else if (state.docreaderViewerShowing) {
      return (this.renderDocreaderViewer());
    } else if (activeGradebookTable === 'details') {
      return this.renderDetailsGradebookTable();
    } else if (activeGradebookTable === 'standards') {
      return this.renderStandardsGradebookTable();
    }
    return this.renderDefaultGradebookTable();
  }
}

SatCoreRegister('GradebookTable', GradebookTable);
