import React, { Component } from 'react';

import '../../css/FullscreenModal.less';

import { inject } from 'mobx-react';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import Auth from '../../managers/AuthManager';

import { stripHtmlTags } from '../../utils';

import Modal from '../Modal';

export default
@inject('assignmentManager', 'studentContentCardManager')
class FullscreenModal extends Component {
  received = false;

  originalTitle = null;

  constructor(props) {
    super(props);
    this.InfoModal = SatCoreComponent('InfoModal');
    this.state = {
      addInfoOpen: false
    };
  }

  componentDidMount() {
    const { page, studentContentCardManager } = this.props;
    if (page && page === 'lesson-player') {
      window.addEventListener('message', this.handleIframeTask);
      const frame = document.getElementById('fulldialog-frame');
      frame.setAttribute('tabIndex', '0');
    }
    if (studentContentCardManager.isLoading) {
      studentContentCardManager.setIsLoading(false);
    }
  }

  componentWillUnmount() {
    // Waiting a few seconds to remove event listener, because if we remove at unmount we don't get the posted
    // message from the lesson player which prevents us from refreshing assignment data.
    setTimeout(() => {
      window.removeEventListener('message', this.handleIframeTask);
    }, 3000);
    if (this.originalTitle !== null) {
      document.title = this.originalTitle;
    }
  }

  handleIframeTask = (e) => {
    if (e.origin === Auth.lesson && e.data === 'closePressed' || e.data === 'setFullscreen') {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.closeIframeCallback();
    }
    if (e.origin === Auth.publisher && e.data === 'closePressed' || e.data === 'setFullscreen') {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.closeIframeCallback();
    }
    if (e.origin === Auth.publisher && e.data === 'showStudentInstruction') {
      this.handleOpenInfo();
    }
    if (e.origin === Auth.publisher && e.data.indexOf('title...') === 0) {
      const title = e.data.substring(8);

      if (this.originalTitle === null) {
        this.originalTitle = document.title;
      }

      this.changeTitle(title);
    }
  };

  changeTitle = (title) => {
    document.title = stripHtmlTags(title);
  }

  handleOpenInfo = () => {
    this.setState({ addInfoOpen: true });
  }

  closeInfoModal = () => {
    this.setState({ addInfoOpen: false });
  }

  render() {
    const { url, instruction, contentName, contentImageUrl } = this.props;
    const { addInfoOpen } = this.state;
    const { InfoModal } = this;

    const title = stripHtmlTags(contentName);

    return (
      <Modal
        className='lesson-player-modal'
        onMount={() => document.body.classList.add('body-lesson-player-modal')}
        onUnmount={() => document.body.classList.remove('body-lesson-player-modal')}
        open
        size='fullscreen' tabIndex={0}>
        <iframe allow='fullscreen;autoplay;camera *;microphone *;'
          height='100%'
          id='fulldialog-frame'
          src={url}
          style={{
            display: 'initial',
            position: 'relative'
          }}
          tabIndex={0}
          title={title}
          width='100%' />
        <InfoModal
          addInfoOpen={addInfoOpen}
          closeInfoModal={this.closeInfoModal}
          contentItemDescription={null}
          imageUrl={contentImageUrl}
          infoName={contentName}
          resourceInfo={null}
          studentInstruction={instruction} />
      </Modal>
    );
  }
}

SatCoreRegister('FullscreenModal', FullscreenModal);
