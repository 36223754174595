/**
 * Returns a string representing a number in fixed-point notation.
 *
 * This helper function will apply `toFixed` to **non-integer numbers** only.
 *
 * @param {number} num
 * @param {boolean} shouldRound if true, native `toFixed` function will be used
 * @param {number} fractionDigits number of digits after the decimal point
 */
export const toFixed = (num, shouldRound = true, fractionDigits = 2) => {
  if (!num && num !== 0) {
    return;
  }
  if (typeof num !== 'number') {
    console.error('utils/toFixed: typeof num !== \'number\'');
  } else if (Number.isInteger(num)) {
    return num;
  }
  if (shouldRound) {
    return (num).toFixed(fractionDigits);
  } else if (Number.isInteger(num)) {
    return `${num}`;
  }
  const parts = `${num}`.split('.');
  const integerPart = parts[0];
  let decimalPart = parts[1];
  if (decimalPart) {
    decimalPart = decimalPart.substring(0, fractionDigits);
    return `${integerPart || '0'}.${decimalPart}`;
  }
  return `${integerPart}`;
};
