import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { pickBy } from 'lodash';

const getDisplayName = (Comp) => {
  if (typeof Comp === 'string') return Comp;
  if (!Comp) return '';
  return Comp.displayName || Comp.name || 'Component';
};

export const useParams = (props) => {
  const { history: { push, replace, location: { search } }, match: { params: matchParams = {} } = {} } = props;
  const qsParams = qs.parse((search || '').replace(/\?/, ''));
  const params = { ...matchParams, ...qsParams };
  const paramsPath = (newParams) => `?${qs.stringify(pickBy({ ...params, ...newParams }))}`;
  const setParams = (newParams, replaceHistory) => (replaceHistory ? replace : push)(paramsPath(newParams));
  return { params, paramsPath, setParams };
};

export const withParams = (Component) => {
  const klass = (props) => <Component {...props} {...useParams(props)} />;
  klass.displayName = `withParams(${getDisplayName(Component)})`;
  klass.wrappedComponent = Component;
  klass.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired
    }).isRequired
  };
  return klass;
};

export default withParams;
