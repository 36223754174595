import React from 'react';
import { Image } from 'semantic-ui-react';
import defaultUserAvatar from '../img/avatars/default-avatar.png';
import { SatCoreRegister } from '../SatCoreRegistry';

const Avatar = (props) => {
  const defaultAvatar = props.defaultImage ? props.defaultImage : defaultUserAvatar;
  return (
    <Image
      alt='User avatar'
      avatar
      className={props.className}
      onError={(i) => i.target.src = defaultAvatar}
      src={(props.fullProfileImageUrl === null) ? defaultAvatar : props.fullProfileImageUrl}
      tabIndex='-1' />
  );
};

export default Avatar;

SatCoreRegister('Avatar', Avatar);
