import classroomManager from '../managers/ClassroomManager';
import classroomRosterManager from '../managers/ClassroomRosterManager';
import userManager from '../managers/UserManager';
import PurchaseCodeApi from '../api/PurchaseCodeApi';

export default class PurchaseCodeService {
  static async validateCode(code) {
    return PurchaseCodeApi.validate(code);
  }

  static async burnCodes(codes) {
    const result = await PurchaseCodeApi.burnCodes(classroomManager.currentClassroomId, codes);
    if (result) {
      await classroomRosterManager.fetchCurrentRoster(userManager.userId, classroomManager.currentClassroomId);
    }
    return result;
  }

  static async burnCode(code, { classroomId } = {}) {
    const result = await PurchaseCodeApi.burnCode(classroomId, code);
    return result;
  }
}
