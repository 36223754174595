import React, { Component } from 'react';
import {
  Button
} from 'semantic-ui-react';
import { SatCoreRegister } from '../SatCoreRegistry';
import '../css/ShowPasswordButton.less';

export default class ShowPasswordButton extends Component {
  flipInputType = () => {
    const { isPassword, name, onFlip } = this.props;
    if (isPassword) {
      onFlip(name, false);
    } else {
      onFlip(name, true);
    }
  };

  render() {
    const { isPassword, t } = this.props;
    const className = isPassword ? 'password-display-btn' :
      'password-display-btn plain-text';
    return (
      <>
        <Button
          className={className}
          onClick={this.flipInputType}
          onKeyPress={(e) => e.preventDefault()}
          size='mini'
          type='button'>
          {isPassword ? t('showPassword') : t('hidePassword')}
        </Button>
      </>
    );
  }
}

SatCoreRegister('ShowPasswordButton', ShowPasswordButton);
