import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

// import { register } from '../../../i18n';

import EngagementService from '../../../services/EngagementService';

const EngagementRowMainResourcesUsageCells = observer((props) => {
  const { engagementData } = props;

  const {
    viewBasicCalculatorCount,
    viewCustomaryRulerCount,
    viewDictionaryCount,
    viewGraphingCalculatorCount,
    viewMetricRulerCount,
    viewProtractorCount,
    viewResourcesCount,
    viewScientificCalculatorCount
  } = engagementData;

  const allResourcesCount = (
    (+!!viewBasicCalculatorCount || 0) +
    (+!!viewScientificCalculatorCount || 0) +
    (+!!viewGraphingCalculatorCount || 0) +
    (+!!viewDictionaryCount || 0) +
    (+!!viewCustomaryRulerCount || 0) +
    (+!!viewMetricRulerCount || 0) +
    (+!!viewProtractorCount || 0) +
    (+!!viewResourcesCount || 0)
  );

  const { /* managers */ } = useContext(MobXProviderContext);

  // const t = register('EngagementTable');

  const renderEngagementRowMainResourcesUsageCells = () => {
    const popupContentProps = {
      engagementCellType: 'main',
      engagementData,
      engagementDataAvailableKey: 'viewAllResourcesAvailable',
      engagementDataCountKey: allResourcesCount
    };

    return (
      <>
        {renderEngagementRowMainResourcesUsageCell({
          allowPopupComponent: true,
          className: classNames('resources-usage-cell main-cell resourcesUsage', {
            warn: false // TODO
          }),
          popupContent: EngagementService.renderEngagementResourcesUsageCellContent(popupContentProps),
          popupDisabled: false,
          popupOn: 'hover',
          popupTrigger: allResourcesCount
        })}
      </>
    );
  };

  const renderEngagementRowMainResourcesUsageCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementRowMainResourcesUsageCells();
});
export default EngagementRowMainResourcesUsageCells;

SatCoreRegister('EngagementRowMainResourcesUsageCells', EngagementRowMainResourcesUsageCells);
