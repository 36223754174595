import React, { useCallback, useMemo, useState } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import '../css/CartModal.less';

const CartModal = (props) => {
  const {
    open, close, cart: Cart, list: List, className, onSave,
    equal = (item, otherItem) => item.id === otherItem.id,
    offset = (empty) => [empty ? -55 : -200, 0], ...rest
  } = props;
  const FullScreenModalPage = useMemo(() => SatCoreComponent('FullScreenModalPage'), []);
  const [cart, setCart] = useState([]);
  const [saving, setSaving] = useState(false);
  const onAdd = useCallback((item) => setCart([...cart, item]), [cart]);
  const onRemove = useCallback((removedItem) => setCart(cart.filter((item) => !equal(item, removedItem))), [cart]);
  const popupClass = `${className}-cart-popup`;
  const actions = (
    <div className='modal-actions'>
      <Popup
        as={(props) => <div {...props} className={`${props.className} ${popupClass}${cart.length < 1 ? ' empty' : ''}`} />}
        content={<Cart cart={cart} onRemove={onRemove} />}
        offset={offset(cart.length < 1)}
        on='click'
        onMount={() => document.getElementsByClassName(popupClass)[0]?.parentElement.classList.add(`${popupClass}-wrapper`)}
        position='bottom center'
        trigger={<a className='cart-link'>{cart.length}</a>} />
      <span className='cart-label'>Selected</span>
      <Button basic disabled={saving} onClick={close}>Cancel</Button>
      <Button
        basic
        disabled={saving}
        onClick={async () => {
          setSaving(true);
          cart.length === 0 || await onSave(cart) ? close(null, null, cart.length > 0) : setSaving(false);
        }}>
        Save
      </Button>
    </div>
  );
  return (
    <FullScreenModalPage
      {...props}
      actions={actions}
      className={`cart-modal ${className}-modal`}
      closeOnDimmerClick={false}
      closeOnEscape={true}
      open={open}>
      <List {...rest} cart={cart} onAdd={onAdd} onRemove={onRemove} />
    </FullScreenModalPage>
  );
};

export default CartModal;

SatCoreRegister('CartModal', CartModal);
