import { action, makeObservable, observable } from 'mobx';
import Auth from './AuthManager';
import userManager from './UserManager';

export class StudentProgressManager {
  entityId = '';

  classroomId = '';

  // raw data
  studentProgressData = undefined;

  @observable allowStudentProgressNavTab = true;

  @observable classroomProgressData = { courses: [], grades: {} };

  @observable selectedContentType = 'all-content';

  @observable trophyCounts = { gold: 0, silver: 0 };

  @observable meterCounts = { green: 0, yellow: 0, red: 0 };

  @observable coursesVisibility = {};

  // TODO remove
  // @observable isExperimentalReportToggled = false;

  constructor() {
    makeObservable(this);
  }

  // TODO remove
  // @action setIsExperimentalReportToggled = (toggle) => {
  //   this.isExperimentalReportToggled = toggle;
  // }

  @action setAllowStudentProgressNavTab = (allow) => {
    this.allowStudentProgressNavTab = allow;
  }

  @action fetchStudentProgress = async (entityId, classroomId) => {
    let reportId = `${userManager.clientPrefix}_STUDENT_PROGRESS`;
    this.clearAll();
    this.entityId = entityId;

    if (this.isExperimentalReportToggled) {
      reportId += '_R';
    }

    try {
      const response = await Auth.fetch(`${Auth.ecms}/api/viewReportOutput?reportId=${reportId}&entityId=${entityId}&reactErrorType=true`, {
        method: 'GET'
      });

      if (response !== null && response.status === 'SUCCESS') {
        const reportData = response.data[0].contentJson.data;
        this.studentProgressData = reportData;
        this.setClassroom(classroomId);
        // this.classroomProgressData = reportData.classrooms[classroomId];
        // this.initCoursesVisibility(this.classroomProgressData.courses);
        // this.calculateTrophies();
        return true;
      }
    } catch (e) {
      console.log(e);
      // Setting the token in Cookies
      return false;
    }
  }

  @action setClassroom = async (classroomId) => {
    if (this.studentProgressData && this.classroomId != classroomId) {
      this.classroomId = classroomId;
      this.classroomProgressData = this.studentProgressData.classrooms[classroomId];
      if (!this.classroomProgressData) {
        this.classroomProgressData = { courses: [], grades: {} };
      }
      this.initCoursesVisibility();
      this.calculateTrophies();
      this.calculateMeters();
    }
  }

  @action
  clearAll() {
    this.entityId = '';
    this.classroomId = '';
    this.studentProgressData = undefined;
    this.classroomProgressData = { courses: [], grades: {} };
    this.selectedContentType = 'all-content';
    this.coursesVisibility = {};
    this.trophyCounts = { gold: 0, silver: 0 };
    this.meterCounts = { green: 0, yellow: 0, red: 0 };
  }

  @action
  toggleCourseVisibility(courseId) {
    this.coursesVisibility[courseId] = !this.coursesVisibility[courseId];
    this.coursesVisibility = { ...this.coursesVisibility };
  }

  @action
  setSelectedContentType(contentType) {
    this.selectedContentType = contentType;
    this.calculateTrophies();
    this.calculateMeters();
  }

  initCoursesVisibility() {
    const { courses } = this.classroomProgressData;
    const initialCourseVisibility = {};
    for (const course of courses) {
      initialCourseVisibility[course.courseId] = false;
    }
    this.coursesVisibility = initialCourseVisibility;
  }

  calculateTrophies() {
    let goldCount = 0;
    let silverCount = 0;
    const { courses } = this.classroomProgressData;
    const { grades } = this.classroomProgressData;
    for (const course of courses) {
      for (const element of course.courseElements) {
        if (this.selectedContentType === 'Clickbook' && !element.includeWithClickbook) {
          continue;
        } else if (this.selectedContentType === 'ExperTrack' && !element.includeWithExperTrack) {
          continue;
        }

        const gradeObj = grades[element.elementId];
        if (gradeObj) {
          const { grade } = gradeObj;
          if (grade >= 80) {
            goldCount++;
          } else if (grade >= 60) {
            silverCount++;
          }
        }
      }
    }

    this.trophyCounts = { gold: goldCount, silver: silverCount };
  }

  calculateMeters() {
    let greenCount = 0;
    let yellowCount = 0;
    let redCount = 0;
    const { courses } = this.classroomProgressData;
    const { grades } = this.classroomProgressData;
    for (const course of courses) {
      for (const element of course.courseElements) {
        const gradeObj = grades[element.elementId];
        if (gradeObj) {
          const { grade } = gradeObj;
          if (grade >= 80) {
            greenCount++;
          } else if (grade >= 60) {
            yellowCount++;
          } else {
            redCount++;
          }
        }
      }
    }

    this.meterCounts = { green: greenCount, yellow: yellowCount, red: redCount };
  }
}

export default new StudentProgressManager();
