import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Button } from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/InfoDialog.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import UtilityService from '../../services/UtilityService';

export default class ErrorModal extends Component {
  render() {
    const { addErrorOpen, closeErrorModal, errorMessage, errorLabel } = this.props;
    const ModalBanner = SatCoreComponent('ModalBanner');
    const transformFn = UtilityService.reactHtmlParserTransform;
    return (
      <Modal
        open={addErrorOpen}
        size='tiny'>
        <ModalBanner
          label={(errorLabel !== '' && errorLabel !== null && errorLabel !== undefined) ? errorLabel : 'Error: '}
          onClose={() => { closeErrorModal(); }}
          title='' />
        <Modal.Content>
          <p>
            {ReactHtmlParser(errorMessage, {
              transform: transformFn
            })}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button content='OK' onClick={() => { closeErrorModal(); }} primary />
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('ErrorModal', ErrorModal);
