import reportContextManager from '../../managers/reports/ReportContextManager';
import ReportIdentityService from './ReportIdentityService';

import ReportTableService from './ReportTableService';

export default class ReportContextService {
  /** @param {import('../../managers/reports/ReportContextManager').ReportContextType} reportContextType */
  static switchReportContext = (reportContextType) => {
    if (ReportIdentityService.isReportStandardsClassroomDetailStudents()) {
      reportContextManager.setReportStandardsClassroomDetailStudentsContextType(reportContextType);
      ReportTableService.forceRerenderReportTable();
    } else {
      reportContextManager.setReportContextType(reportContextType);
      ReportTableService.forceRerenderReportTable();
    }
  }
}
