import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import InfiniteScroll from 'react-infinite-scroller';

import { Table } from 'semantic-ui-react';

import classNames from 'classnames';

import '../../css/EngagementTable.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import EngagementService from '../../services/EngagementService';

const EngagementTable = observer((props) => {
  const EngagementTableOverride = SatCoreComponent('EngagementTableOverride');

  const hasEngagementTableOverride = !!EngagementTableOverride && EngagementTableOverride !== React.Fragment;
  if (hasEngagementTableOverride) {
    return <EngagementTableOverride {...props} />;
  }

  const { engagementManager, gradebookManager } = useContext(MobXProviderContext);

  const EngagementHeaderCoreCells = SatCoreComponent('EngagementHeaderCoreCells');
  const EngagementHeaderAuxiliaryAnnotationsUsageCells = SatCoreComponent('EngagementHeaderAuxiliaryAnnotationsUsageCells');
  const EngagementHeaderAuxiliaryFeedbackUsageCells = SatCoreComponent('EngagementHeaderAuxiliaryFeedbackUsageCells');
  const EngagementHeaderAuxiliaryLanguageToolUsageCells = SatCoreComponent('EngagementHeaderAuxiliaryLanguageToolUsageCells');
  const EngagementHeaderAuxiliaryTimeOnTaskRatioCells = SatCoreComponent('EngagementHeaderAuxiliaryTimeOnTaskRatioCells');
  const EngagementHeaderAuxiliaryResourcesUsageCells = SatCoreComponent('EngagementHeaderAuxiliaryResourcesUsageCells');
  const EngagementHeaderMainAnnotationsUsageCells = SatCoreComponent('EngagementHeaderMainAnnotationsUsageCells');
  const EngagementHeaderMainFeedbackUsageCells = SatCoreComponent('EngagementHeaderMainFeedbackUsageCells');
  const EngagementHeaderMainLanguageToolUsageCells = SatCoreComponent('EngagementHeaderMainLanguageToolUsageCells');
  const EngagementHeaderMainResourcesUsageCells = SatCoreComponent('EngagementHeaderMainResourcesUsageCells');
  const EngagementHeaderMainTimeOnTaskRatioCells = SatCoreComponent('EngagementHeaderMainTimeOnTaskRatioCells');
  const EngagementRowCoreCells = SatCoreComponent('EngagementRowCoreCells');
  const EngagementRowAuxiliaryAnnotationsUsageCells = SatCoreComponent('EngagementRowAuxiliaryAnnotationsUsageCells');
  const EngagementRowAuxiliaryFeedbackUsageCells = SatCoreComponent('EngagementRowAuxiliaryFeedbackUsageCells');
  const EngagementRowAuxiliaryLanguageToolUsageCells = SatCoreComponent('EngagementRowAuxiliaryLanguageToolUsageCells');
  const EngagementRowAuxiliaryResourcesUsageCells = SatCoreComponent('EngagementRowAuxiliaryResourcesUsageCells');
  const EngagementRowAuxiliaryTimeOnTaskRatioCells = SatCoreComponent('EngagementRowAuxiliaryTimeOnTaskRatioCells');
  const EngagementRowMainAnnotationsUsageCells = SatCoreComponent('EngagementRowMainAnnotationsUsageCells');
  const EngagementRowMainFeedbackUsageCells = SatCoreComponent('EngagementRowMainFeedbackUsageCells');
  const EngagementRowMainLanguageToolUsageCells = SatCoreComponent('EngagementRowMainLanguageToolUsageCells');
  const EngagementRowMainResourcesUsageCells = SatCoreComponent('EngagementRowMainResourcesUsageCells');
  const EngagementRowMainTimeOnTaskRatioCells = SatCoreComponent('EngagementRowMainTimeOnTaskRatioCells');

  const handleHeaderClick = (event) => {
    const { value } = event.target.dataset;
    engagementManager.setActiveEngagementTable(value);
  };

  const handleLoadMore = async () => {
    const assignment = gradebookManager.currentAssignment;
    await EngagementService.fetchAggregateGradebookEngagement(assignment);
  };

  const activeTable = engagementManager.activeEngagementTable;

  const shouldRenderTimeOnTaskRatio = activeTable === 'summary' || activeTable === 'timeOnTaskRatio';
  const shouldRenderFeedbackUsage = activeTable === 'summary' || activeTable === 'feedbackUsage';
  const shouldRenderResourcesUsage = activeTable === 'summary' || activeTable === 'resourcesUsage';
  const shouldRenderAnnotationsUsage = activeTable === 'summary' || activeTable === 'annotationsUsage';
  const shouldRenderLanguageToolUsage = activeTable === 'summary' || activeTable === 'languageToolUsage';

  const hasMore = engagementManager.engagementDataTotal > engagementManager.engagementData.length;
  const isLoading = engagementManager.loadingEngagementData;

  const renderEngagementHeaderMainCells = () => {
    return (
      <>
        {shouldRenderTimeOnTaskRatio && (
          <EngagementHeaderMainTimeOnTaskRatioCells {...props} handleHeaderClick={handleHeaderClick} />
        )}
        {shouldRenderFeedbackUsage && (
          <EngagementHeaderMainFeedbackUsageCells {...props} handleHeaderClick={handleHeaderClick} />
        )}
        {shouldRenderResourcesUsage && (
          <EngagementHeaderMainResourcesUsageCells {...props} handleHeaderClick={handleHeaderClick} />
        )}
        {shouldRenderAnnotationsUsage && (
          <EngagementHeaderMainAnnotationsUsageCells {...props} handleHeaderClick={handleHeaderClick} />
        )}
        {shouldRenderLanguageToolUsage && (
          <EngagementHeaderMainLanguageToolUsageCells {...props} handleHeaderClick={handleHeaderClick} />
        )}
      </>
    );
  };

  const renderEngagementHeaderAuxiliaryCells = () => {
    return (
      <>
        {(activeTable === 'timeOnTaskRatio') && (
          <EngagementHeaderAuxiliaryTimeOnTaskRatioCells {...props} />
        )}
        {(activeTable === 'feedbackUsage') && (
          <EngagementHeaderAuxiliaryFeedbackUsageCells {...props} />
        )}
        {(activeTable === 'resourcesUsage') && (
          <EngagementHeaderAuxiliaryResourcesUsageCells {...props} />
        )}
        {(activeTable === 'annotationsUsage') && (
          <EngagementHeaderAuxiliaryAnnotationsUsageCells {...props} />
        )}
        {(activeTable === 'languageToolUsage') && (
          <EngagementHeaderAuxiliaryLanguageToolUsageCells {...props} />
        )}
      </>
    );
  };

  const renderEngagementBodyRowMainCells = (engagementData) => {
    return (
      <>
        {shouldRenderTimeOnTaskRatio && (
          <EngagementRowMainTimeOnTaskRatioCells {...props}
            engagementData={engagementData} />
        )}
        {shouldRenderFeedbackUsage && (
          <EngagementRowMainFeedbackUsageCells {...props}
            engagementData={engagementData} />
        )}
        {shouldRenderResourcesUsage && (
          <EngagementRowMainResourcesUsageCells {...props}
            engagementData={engagementData} />
        )}
        {shouldRenderAnnotationsUsage && (
          <EngagementRowMainAnnotationsUsageCells {...props}
            engagementData={engagementData} />
        )}
        {shouldRenderLanguageToolUsage && (
          <EngagementRowMainLanguageToolUsageCells {...props}
            engagementData={engagementData} />
        )}
      </>
    );
  };

  const renderEngagementBodyRowAuxiliaryCells = (engagementData) => {
    return (
      <>
        {(activeTable === 'timeOnTaskRatio') && (
          <EngagementRowAuxiliaryTimeOnTaskRatioCells {...props}
            engagementData={engagementData} />
        )}
        {(activeTable === 'feedbackUsage') && (
          <EngagementRowAuxiliaryFeedbackUsageCells {...props}
            engagementData={engagementData} />
        )}
        {(activeTable === 'resourcesUsage') && (
          <EngagementRowAuxiliaryResourcesUsageCells {...props}
            engagementData={engagementData} />
        )}
        {(activeTable === 'annotationsUsage') && (
          <EngagementRowAuxiliaryAnnotationsUsageCells {...props}
            engagementData={engagementData} />
        )}
        {(activeTable === 'languageToolUsage') && (
          <EngagementRowAuxiliaryLanguageToolUsageCells {...props}
            engagementData={engagementData} />
        )}
      </>
    );
  };

  return (
    <InfiniteScroll
      hasMore={!isLoading && hasMore}
      initialLoad={false}
      loadMore={handleLoadMore}>
      <Table
        className={classNames('engagement-table', {
          'engagement-summary-table': engagementManager.activeEngagementTable === 'summary',
          'loading-table': isLoading
        })} striped>
        <Table.Header>
          <Table.Row
            className={isLoading ? 'loading-row' : ''}
            disabled={isLoading}>
            <EngagementHeaderCoreCells {...props} />
            {renderEngagementHeaderMainCells()}
            {renderEngagementHeaderAuxiliaryCells()}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {engagementManager.engagementData.map((data, index) => {
            const { engagementData } = data;
            return (
              <Table.Row key={index}>
                <EngagementRowCoreCells {...props} coreData={data} engagementData={engagementData} />
                {renderEngagementBodyRowMainCells(engagementData)}
                {renderEngagementBodyRowAuxiliaryCells(engagementData)}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </InfiniteScroll>
  );
});
export default EngagementTable;

SatCoreRegister('EngagementTable', EngagementTable);
