/* eslint-disable sort-keys */
import { registerClass } from '../SatCoreRegistry';

import accommodationsManager from '../managers/AccommodationsManager';

import { ACCOMMODATION_TOGGLE_KEY } from './AccommodationConstants';

export default class AccommodationsService {
  /**
   * @param {{ sectionKey: 'assessmentSection' | 'lessonSection' }}
   */
  static getAvailableSectionToggleCount = ({ sectionKey } = {}) => {
    const config = this.ACCOMMODATIONS_CONFIG();
    let count = 0;
    for (const toggleKey of Object.keys(config[sectionKey].toggles)) {
      if (config[sectionKey].toggles[toggleKey]) {
        count++;
      }
    }
    return count;
  }

  static getOffAccommodationsForAll = ({
    assignment = null,
    config,
    rosterItems = [],
    sectionKey = 'assessmentSection',
  } = {}) => {
    config = config || this.ACCOMMODATIONS_CONFIG();
    const toggleKeysTurnedOnByConfig = this.getConfigEnabledAccommodationToggleKeys({
      config, sectionKey
    });
    rosterItems = rosterItems.length ? rosterItems : accommodationsManager.currentAccommodationsRosterArray;

    let offAccommodationsForAll = [];

    if (assignment) {
      offAccommodationsForAll = assignment.additionalPropertiesJson?.accommodationIds?.split(',') || [];
      return offAccommodationsForAll;
    } else {
      for (const toggleKey of toggleKeysTurnedOnByConfig) {
        const isAccommodationOn = (rosterItems)?.every((rosterItem) => {
          return !rosterItem.offAccommodations?.includes(
            ACCOMMODATION_TOGGLE_KEY[sectionKey][toggleKey]
          );
        });
        if (!isAccommodationOn) {
          offAccommodationsForAll.push(toggleKey);
        }
      }
      return offAccommodationsForAll;
    }
  }

  static getConfigEnabledAccommodationToggleKeys = ({
    config,
    sectionKey = 'assessmentSection',
  } = {}) => {
    config = config || this.ACCOMMODATIONS_CONFIG();
    const toggleKeys = Object.keys(config[sectionKey].toggles);
    return toggleKeys.filter((toggleKey) => {
      return config[sectionKey].toggles[toggleKey];
    });
  }

  /** can be overridden */
  static ACCOMMODATIONS_CONFIG = () => {
    return {
      assessmentSection: {
        show: true, // overrides all other assessment options if false
        toggles: {
          // toggles marked as true will appear in the order shown here
          basicCalculatorToggle: true,
          textToSpeechToggle: true,
          translationToggle: true,
          dictionaryToggle: false,
          contentLanguageToggle: true,
          scientificCalculatorToggle: false,
          graphingCalculatorToggle: false,
          spellcheckerToggle: false
        }
      },
      lessonSection: {
        show: true, // overrides all other lesson options if false
        toggles: {
          // toggles marked as true will appear in the order shown here
          basicCalculatorToggle: true,
          textToSpeechToggle: true,
          translationToggle: true,
          dictionaryToggle: true,
          scientificCalculatorToggle: false,
          graphingCalculatorToggle: false,
          spellcheckerToggle: false
        }
      },
      cellDividerClassName: 'section-divider',
      showTopHeader: true,
      tableHeaderFontSize: 'inherit',
      treatAllSectionsAsOne: false
    };
  }
}

registerClass('AccommodationsService', AccommodationsService);
