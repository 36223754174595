import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Image } from 'semantic-ui-react';

import '../css/Badge.less';

import { SatCoreRegister } from '../SatCoreRegistry';

const Badge = observer((props) => {
  const { additionalClassNames, badgeImageSource } = props;
  const badgeName = typeof props.badgeName === 'string' ? props.badgeName : '';

  const { /* managers */ } = useContext(MobXProviderContext);

  if (badgeName) {
    return (
      <span className={`badge badge-text ${badgeName.toLowerCase()}${additionalClassNames ? ` ${additionalClassNames}` : ''}`}>
        {badgeName.charAt(0).toUpperCase()}
      </span>
    );
  } else if (badgeImageSource) {
    return (
      <span className={`badge badge-image${additionalClassNames ? ` ${additionalClassNames}` : ''}`}>
        <Image
          size='mini'
          spaced='right'
          src={badgeImageSource}
          verticalAlign='middle' />
      </span>
    );
  } else {
    return <span className='badge' />;
  }
});
export default Badge;

SatCoreRegister('Badge', Badge);
