import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Dropdown } from 'semantic-ui-react';

import '../css/UserInstitutionDropdown.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import { INSTITUTION_DROPDOWN_MODE } from '../services/UserInstitutionDropdownConstants';

import UserService from '../services/UserService';

const UserInstitutionDropdown = observer((props = {}) => {
  const {
    allowSelectAllInstitutions,
    managerName = 'userManager',
    mode,
    shouldOnlyUpdateCurrentDropdownMode = false,
    showLabel = !allowSelectAllInstitutions,
    t
  } = props;

  const { ADD_CLASSROOM_MODE } = INSTITUTION_DROPDOWN_MODE;

  const { adminUsersManager, classroomManager, userManager } = useContext(MobXProviderContext);
  const { isDistrictOrSchoolAdmin } = userManager;
  const hasMultipleInstitutions = UserService.hasMultipleInstitutions(managerName);

  const { allowSsoCreateSelfRegClassroom } = classroomManager;

  let isSsoUser;
  if (isDistrictOrSchoolAdmin) {
    isSsoUser = adminUsersManager.currentUser?.ssoUser;
  } else {
    isSsoUser = userManager.isSsoUser;
  }

  const handleChangeUserInstitutionDropdown = (event, data) => {
    if (shouldOnlyUpdateCurrentDropdownMode) {
      UserService.setSelectedDropdownInstitutionId(data.value, mode);
    } else {
      UserService.setSelectedDropdownInstitutionId(data.value);
    }
    if (props.onChange) {
      props.onChange(event, data);
    }
  };

  const renderUserInstitutionDropdown = () => {
    let options;
    if (managerName === 'adminUsersManager') {
      options = [...adminUsersManager.institutionDropdownOptionsForSelectedUser];
    } else {
      options = [...userManager.institutionDropdownOptions];
      // if the teacher user has admin districts don't include the district institutions.
      options = options.filter((option) => {
        if (!isDistrictOrSchoolAdmin && option.type === 'District') {
          return false;
        }
        return true;
      })
    }
    if (allowSelectAllInstitutions) {
      options.unshift({ text: t('allInstitutions'), value: 'allInstitutions' });

      if (!UserService.getSelectedDropdownInstitutionId(mode)) {
        UserService.setSelectedDropdownInstitutionId('allInstitutions', mode);
      }
    }
    return hasMultipleInstitutions &&
      ((!isSsoUser || allowSsoCreateSelfRegClassroom) || mode !== ADD_CLASSROOM_MODE) && (
        <div className='user-institution-dropdown'>
          {showLabel && (
            <div className='dropdown-label'>
              <div className='text'>
                {t('selectInstitution')} *
              </div>
            </div>
          )}
          <Dropdown
            onChange={handleChangeUserInstitutionDropdown}
            options={options.map((option) => {
                return ({
                  text: <span title={option.text}>{option.text}</span>,
                  value: option.value
                });
            })}
            placeholder={t('selectInstitution')}
            selection
            value={UserService.getSelectedDropdownInstitutionId(mode)} />
        </div>
    );
  };
  return renderUserInstitutionDropdown();
});
export default UserInstitutionDropdown;

SatCoreRegister('UserInstitutionDropdown', UserInstitutionDropdown);
