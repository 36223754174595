import { getRegisteredClass, registerClass } from '../SatCoreRegistry';

import { INSTITUTION_DROPDOWN_MODE } from './UserInstitutionDropdownConstants';

import Auth from '../managers/AuthManager';
import adminUsersManager from '../managers/AdminUsersManager';
import classroomManager from '../managers/ClassroomManager';
import navigationManager, { VIEW_SELECTION } from '../managers/NavigationManager';
import userManager from '../managers/UserManager';

import { getSessionStorageItem } from '../utils';

import ThirdPartyService from './ThirdPartyService';

export default class UserService {
  static fetchSingularUserInstitution = async (institutionId) => {
    return userManager.fetchSingularUserInstitution(institutionId);
  }

  static fetchUserInstitutions = async (user, options) => {
    user = user || userManager.user;
    return userManager.fetchUserInstitutions(user, options);
  }

  /**
   * @param {string} institutionId
   * @param {import('../services/UserInstitutionDropdownConstants').InstitutionDropDownMode} mode
   */
  static setSelectedDropdownInstitutionId = (institutionId, mode = INSTITUTION_DROPDOWN_MODE.ALL_MODES) => {
    const { isDistrictOrSchoolAdmin } = userManager;
    if (isDistrictOrSchoolAdmin) {
      adminUsersManager.setSelectedDropdownInstitutionIdForSelectedUser(institutionId, mode);
    } else {
      userManager.setSelectedDropdownInstitutionId(institutionId, mode);
    }
  }

  static getAddOrJoinClassButtonKeyForAdmins = ({ managerName = 'adminUsersManager' }) => {
    const { allowSsoCreateSelfRegClassroom } = classroomManager;
    const isSsoUser = this.isSsoUser({ managerName });
    if (!isSsoUser || allowSsoCreateSelfRegClassroom) {
      return 'addJoinClass';
    } else {
      return undefined;
    }
  }

  static getAddOrJoinClassButtonKeyForTeachers = ({ addClassOnly, managerName = 'userManager' } = {}) => {
    const { allowSsoCreateSelfRegClassroom } = classroomManager;
    const isSsoUser = this.isSsoUser({ managerName });

    const isSelfRegUserWithSelfRegInstitution = this.isSelfRegUserWithSelfRegInstitution({ managerName });
    const isSelfRegUserWithSsoInstitution = this.isSelfRegUserWithSsoInstitution({ managerName });

    if (addClassOnly) {
      if (!isSsoUser) {
        return 'addClass';
      }
    } else if (isSelfRegUserWithSelfRegInstitution) {
      return 'addJoinClass';
    } else if (isSelfRegUserWithSsoInstitution) {
      if (allowSsoCreateSelfRegClassroom) {
        return 'addJoinClass';
      } else if (!allowSsoCreateSelfRegClassroom) {
        return 'joinClass';
      }
    } else if (isSsoUser) {
      if (allowSsoCreateSelfRegClassroom) {
        return 'addClass';
      }
    }
  }

  static allowSsoEditClassroomSettings = () => {
    const { allowSsoCreateSelfRegClassroom } = classroomManager;
    const isSsoUserAndSelfRegClassroom = this.isSsoUserAndSelfRegClassroom();
    return allowSsoCreateSelfRegClassroom && isSsoUserAndSelfRegClassroom;
  }

  static canDeleteSsoCoteacherFromClassroom = ({
    classroomId, classroom
  } = {}) => {
    const ClassroomService = getRegisteredClass('ClassroomService');
    classroom = classroom || (
      classroomManager.getClassroom(classroomId || classroomManager.currentClassroomId)
    );
    const isSelfRegInstitution = UserService.isSelfRegInstitution();
    const isSsoClassroom = ClassroomService.isSsoClassroom(classroom);

    return !userManager.isSsoUser || isSelfRegInstitution || !isSsoClassroom;
  }

  static isSelfRegInstitution = () => {
    const institution = this.getCurrentInstitutionForLoggedInUser();
    const isSelfRegInstitution = !institution?.ssoId;
    return isSelfRegInstitution;
  }

  static isSsoUserOrHasSsoInstitution = ({ managerName = 'userManager' } = {}) => {
    return this.isSsoUser({ managerName }) || this.hasSsoInstitution({ managerName });
  }

  static isSelfRegUserWithSelfRegInstitution = ({ managerName = 'userManager' } = {}) => {
    const hasSsoInstitution = this.hasSsoInstitution({ managerName });
    return !this.isSsoUser({ managerName }) && !hasSsoInstitution;
    // TODO remove
    // if (managerName === 'adminUsersManager' && userManager.isDistrictOrSchoolAdmin) {
    //   const hasSsoInstitution = this.hasSsoInstitution({ managerName });
    //   return !this.isSsoUser({ managerName }) && !hasSsoInstitution;
    // } else {
    // const institution = this.getCurrentInstitution({ managerName });
    // const isSsoInstitution = !!institution?.ssoId;
    // return !this.isSsoUser({ managerName }) && !isSsoInstitution;
    // }
  }

  static isSelfRegUserWithSsoInstitution = ({ managerName = 'userManager' } = {}) => {
    const hasSsoInstitution = this.hasSsoInstitution({ managerName });
    return !this.isSsoUser({ managerName }) && hasSsoInstitution;
    // TODO remove
    // if (managerName === 'adminUsersManager' && userManager.isDistrictOrSchoolAdmin) {
    //   const hasSsoInstitution = this.hasSsoInstitution({ managerName });
    //   return !this.isSsoUser({ managerName }) && hasSsoInstitution;
    // } else {
    //   const institution = this.getCurrentInstitution({ managerName });
    //   const isSsoInstitution = !!institution?.ssoId;
    //   return !this.isSsoUser({ managerName }) && isSsoInstitution;
    // }
  }

  static isSsoInstitutionAndSelfRegClassroom = ({
    classroom, classroomId, managerName = 'userManager'
  } = {}) => {
    const ClassroomService = getRegisteredClass('ClassroomService');
    // TODO remove
    // const institution = this.getCurrentInstitutionForLoggedInUser();
    // const isSsoInstitution = !!institution?.ssoId;
    const isSsoClassroom = ClassroomService.isSsoClassroom({ classroom, classroomId });
    return this.hasSsoInstitution({ managerName }) && !isSsoClassroom;
  }

  static isSsoUserOrHasSsoInstitutionWithSelfRegClassroom = ({ classroom, classroomId } = {}) => {
    return this.isSsoUserAndSelfRegClassroom({ classroom, classroomId }) ||
      this.isSsoInstitutionAndSelfRegClassroom({ classroom, classroomId });
  }

  static isSsoUserAndSelfRegClassroom = ({ classroom, classroomId } = {}) => {
    const ClassroomService = getRegisteredClass('ClassroomService');
    const isSsoClassroom = ClassroomService.isSsoClassroom({ classroom, classroomId });
    return userManager.isSsoUser && !isSsoClassroom;
  }

  static isSsoUserWithSelfRegInstitution = () => {
    const institution = this.getCurrentInstitutionForLoggedInUser();
    const isSelfRegInstitution = !institution?.ssoId;
    return userManager.isSsoUser && isSelfRegInstitution;
  }

  static isSsoUserWithSsoInstitution = () => {
    // TODO remove // const institution = this.getCurrentInstitutionForLoggedInUser();
    // TODO remove // const isSsoInstitution = !!institution?.ssoId;
    return userManager.isSsoUser && this.hasSsoInstitution();
  }

  static isSsoUser = ({ managerName } = 'userManager') => {
    if (managerName === 'adminUsersManager' && userManager.isDistrictOrSchoolAdmin) {
      return !!adminUsersManager.currentUser?.ssoUser || !!adminUsersManager.currentUser?.ssoId;
    } else {
      return userManager.isSsoUser;
    }
  }

  // TODO remove in favor of hasSsoInstitution()
  // static isSsoInstitution = () => {
  //   const institution = this.getCurrentInstitutionForLoggedInUser();
  //   const isSsoInstitution = !!institution?.ssoId;
  //   return isSsoInstitution;
  // }

  static hasSsoInstitution = ({ managerName = 'userManager' } = {}) => {
    if (managerName === 'adminUsersManager' && userManager.isDistrictOrSchoolAdmin) {
      return !!adminUsersManager.institutionsForSelectedUser?.find((institution) => {
        return !!institution.ssoId;
      });
    } else {
      return !!userManager.institutions?.find((institution) => {
        return !!institution.ssoId;
      });
    }
  }

  static getUserFullNameStr = (user) => {
    const userFullName = `${user.firstName || ''} ${user.lastName || ''}`.trim();
    return userFullName;
  }

  static getCurrentInstitution = ({ managerName = 'userManager' } = {}) => {
    if (managerName !== 'userManager' && userManager.isDistrictOrSchoolAdmin) {
      return this.getCurrentInstitutionForSelectedUser();
    } else {
      return this.getCurrentInstitutionForLoggedInUser();
    }
  }

  static getCurrentInstitutionForSelectedUser = () => {
    let institutionId = this.getSelectedDropdownInstitutionId();
    institutionId = (institutionId && institutionId !== 'allInstitutions')
      ? institutionId : adminUsersManager.currentUser?.institutionId;
    const institution = adminUsersManager.institutionsForSelectedUserMap.get(institutionId);
    return institution;
  }

  static getCurrentInstitutionForLoggedInUser = (
    { useDefaultInstitutionIfNoneSelected = true } = {}
  ) => {
    let institutionId = this.getSelectedDropdownInstitutionId();
    const { institutionsMap } = userManager;
    if (!useDefaultInstitutionIfNoneSelected) {
      return (institutionId && institutionId !== 'allInstitutions') ?
        institutionsMap.get(institutionId) : undefined;
    } else {
      institutionId = (institutionId && institutionId !== 'allInstitutions')
        ? institutionId : userManager.institutionId;
      return institutionsMap.get(institutionId);
    }
  }

  /** @param {import('./UserInstitutionDropdownConstants').InstitutionDropDownMode} mode */
  static getSelectedDropdownInstitutionId = (mode = INSTITUTION_DROPDOWN_MODE.SELECT_CLASSROOM_MODE) => {
    const { isDistrictOrSchoolAdmin } = userManager;
    if (isDistrictOrSchoolAdmin) {
      return this.getSelectedDropdownInstitutionIdForAdminSelectedUser(mode);
    } else {
      return this.getSelectedDropdownInstitutionIdForLoggedInUser(mode);
    }
  }

  /** @param {import('./UserInstitutionDropdownConstants').InstitutionDropDownMode} mode */
  static getSelectedDropdownInstitutionIdForAdminSelectedUser = (mode) => {
    const obj = adminUsersManager.selectedDropdownInstitutionIdObservableObj;
    return obj?.[mode];
  }

  /** @param {import('./UserInstitutionDropdownConstants').InstitutionDropDownMode} mode */
  static getSelectedDropdownInstitutionIdForLoggedInUser = (mode) => {
    let selectedDropdownInstitutionIdStr = getSessionStorageItem('selectedDropdownInstitutionIdStr') || 'allInstitutions';
    if (!userManager.institutionsMap.has(selectedDropdownInstitutionIdStr)) {
      selectedDropdownInstitutionIdStr = 'allInstitutions';
      userManager.setSelectedDropdownInstitutionId(selectedDropdownInstitutionIdStr);
    }
    const obj = userManager.selectedDropdownInstitutionIdObservableObj || {
      addClassroomMode: selectedDropdownInstitutionIdStr,
      selectClassroomMode: selectedDropdownInstitutionIdStr,
      selectReportClassroomMode: selectedDropdownInstitutionIdStr
    };
    return obj?.[mode];
  }

  static getRoleColumnCountsPerRowArray = () => {
    const availableRoleCount = this.getAvailableRoleCount();
    if (!availableRoleCount || availableRoleCount < 2 || availableRoleCount > 6) {
      // availableRoleCount > 6 should never happen
      // but `availableRoleCount === 1` indicates user only has one role
      // so for that case, we will assume that is the role of the user & not show any role cards
      // instead, RoleSwitcherView will redirect the user to the appropriate dashboard
      return [0, 0];
    }
    switch (availableRoleCount) {
      case 6:
        return [3, 3];
      case 5:
        return [3, 2];
      case 4:
        return [2, 2];
      case 3:
        return [3, 0];
      case 2:
        return [2, 0];
    }
  }

  static getAvailableRoleCount = () => {
    const availableRoles = this.getAvailableRoles();
    const availableRoleCount = availableRoles.length;
    return availableRoleCount;
  }

  static getAvailableRoles = () => {
    const {
      districtAdminId,
      districtProductAdminId,
      permissionIds,
      schoolAdminId,
      schoolProductAdminId,
      studentPermissionId,
      teacherPermissionId
    } = userManager;

    const availableRoles = [];
    // this technique allows roles be displayed in the required order within RoleSwitcherView
    permissionIds.includes(teacherPermissionId) && availableRoles.push(teacherPermissionId);
    permissionIds.includes(studentPermissionId) && availableRoles.push(studentPermissionId);
    permissionIds.includes(schoolAdminId) && availableRoles.push(schoolAdminId);
    permissionIds.includes(districtAdminId) && availableRoles.push(districtAdminId);
    permissionIds.includes(schoolProductAdminId) && availableRoles.push(schoolProductAdminId);
    permissionIds.includes(districtProductAdminId) && availableRoles.push(districtProductAdminId);

    return availableRoles;
  }

  static showRoleSwitcher = (history) => {
    ThirdPartyService.removeThirdPartyChatLauncherIfApplicable({ alsoRegenerateChatLauncherId: true });
    userManager.clearActivePermissionId();
    navigationManager.setView(VIEW_SELECTION.ROLE_SWITCHER);
    history.push(`/switch-role?view=${VIEW_SELECTION.ROLE_SWITCHER}`);
  }

  /**
   * @param {'adminUsersManager' | 'userManager'} managerName
   */
  static hasMultipleInstitutions = (managerName = 'userManager') => {
    const { isDistrictOrSchoolAdmin } = userManager;
    if (managerName !== 'userManager' && isDistrictOrSchoolAdmin) {
      return adminUsersManager.hasMultipleInstitutionsForSelectedUser;
    } else {
      return userManager.hasMultipleInstitutions;
    }
  }

  static hasActivePermissionId = () => {
    return userManager.activePermissionId;
  }

  static isValidRole = (permissionId) => {
    const {
      districtAdminId,
      districtProductAdminId,
      schoolAdminId,
      schoolProductAdminId,
      studentPermissionId,
      teacherPermissionId
    } = userManager;
    return permissionId === districtAdminId
      || permissionId === schoolAdminId
      || permissionId === districtProductAdminId
      || permissionId === schoolProductAdminId
      || permissionId === teacherPermissionId
      || permissionId === studentPermissionId;
  }

  static getUseAssignmentReactPlayer = (activityId) => {
    if(Auth.lesson) {
      return userManager.fetchUseAssignmentReactPlayer(activityId);
    }
    return false;
  }
}

// eslint-disable-next-line max-len
/** @typedef {'district_admin_user' | 'school_admin_user' | 'district_product_admin' | 'school_product_admin' | 'teacher_user' | 'student_user'} UserRole */

registerClass('UserService', UserService);
