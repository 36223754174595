import React, { useContext } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import { SatCoreRegister } from '../SatCoreRegistry';

import ResourcePacingService from '../services/ResourcePacingService';

const AdditionalResourcePills = observer((props = {}) => {
  const { courseElement, t } = props;

  const { courseDeliveryManager } = useContext(MobXProviderContext);

  const resourcePillKeys = props?.resourcePillKeys || courseDeliveryManager.allowedResourcePillKeys;

  const availableResourcePills = ResourcePacingService.getAvailableResourcePills({
    courseElement, resourcePillKeys
  });

  return !!courseElement && !!availableResourcePills.length && (
    <div className='additional-resource-pills'>
      {availableResourcePills.map(({ supportedModes, value }) => {
        return supportedModes.includes(courseElement.type) && (
          <div key={value} className={`leaf-tag tag1 leaf-tag-additional leaf-tag-${value}`}>
            {t(value)}
          </div>
        );
      }).filter((item) => item)}
    </div>
  );
});
export default AdditionalResourcePills;

SatCoreRegister('AdditionalResourcePills', AdditionalResourcePills);
