import { action, computed, makeObservable, observable } from 'mobx';

import Auth from './AuthManager';
import assignmentManager from './AssignmentManager';

import { getSessionStorageItem, setSessionStorageItem } from '../utils';

export const COURSE_DELIVERY_ENDPOINTS = {
  DELETE_COURSE_DELIVERY_OPTIONS_OVERRIDE: '/api/deleteCourseDeliveryOptionsOverride',
  FETCH_COURSE_DELIVERY_OPTIONS_OVERRIDE: '/api/viewCourseDeliveryOptionsOverride',
  OVERRIDE_COURSE_DELIVERY_OPTIONS: '/api/overrideCourseDeliveryOptions'
};

export class CourseDeliveryManager {
  constructor() {
    makeObservable(this);
  }

  // This is true by default but can be overridden by satellites calling setShowResourcePacingToolbar(false) from index.js
  @observable showResourcePacingToolbar = true;

  @observable allowedResourcePillKeys = [];

  @observable resourcePacingToolbarItemKeysToAutoToggleWithoutOpeningModal = [];

  @observable resourcePacingToolbarItemKeysToExclude = [];

  @observable shouldShowHiddenResourcesObservable = this.shouldShowHiddenResources;

  @action setShowResourcePacingToolbar = (toggle) => {
    this.showResourcePacingToolbar = toggle;
  }

  /** @param {import('../services/ResourcePacingService').AdditionalResourcePillKey[]} keys */
  @action setAllowedResourcePillKeys = (keys = []) => {
    this.allowedResourcePillKeys = keys;
  }

  @action setResourcePacingToolbarItemKeysToAutoToggleWithoutOpeningModal = (toolbarItemKeys = []) => {
    this.resourcePacingToolbarItemKeysToAutoToggleWithoutOpeningModal = toolbarItemKeys;
  }

  @action setResourcePacingToolbarItemKeysToExclude = (toolbarItemKeys = []) => {
    this.resourcePacingToolbarItemKeysToExclude = toolbarItemKeys;
  }

  // we are storing this in sessionStorage because we want the
  // user preference to persist within a browser session
  @action setShouldShowHiddenResources = (toggle) => {
    setSessionStorageItem('c2c_shouldShowHiddenResources', toggle);
    this.shouldShowHiddenResourcesObservable = toggle;
  }

  @action
  overrideCourseDeliveryOptions = async (
    classroomId, courseId, elementId,
    pacingMode = null, visibilityMode = null, duration = null, attempts = null,
    includeInReports = false, scoresReleased = false, studentReview = false, studentInstructions = '',
    dueDateTime = '', alternateModeId = ''
  ) => {
    try {
      const dueDate = dueDateTime ? assignmentManager.convertAssignmentDateToJavaString(dueDateTime) : '';
      const apiUrl = `${Auth.ecms}${COURSE_DELIVERY_ENDPOINTS.OVERRIDE_COURSE_DELIVERY_OPTIONS}`;
      const body = {
        alternateModeId,
        attempts,
        classroomId,
        courseContentItemId: courseId,
        courseResourceElementId: elementId,
        dueDate,
        duration,
        includeInReports,
        pacingMode,
        scoresReleased,
        studentInstructions,
        studentReview,
        visibilityMode
      };
      const response = await Auth.fetch(apiUrl, {
        body, method: 'POST'
      });
      if (response && response.status === 'SUCCESS') {
        return response.data[0];
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /** Reset delivery options to original publisher settings for a given resource */
  @action
  deleteCourseDeliveryOptionsOverride = async (
    classroomId, courseId, elementId
  ) => {
    try {
      const apiUrl = `${Auth.ecms}${COURSE_DELIVERY_ENDPOINTS.DELETE_COURSE_DELIVERY_OPTIONS_OVERRIDE}`;
      const body = {
        classroomId,
        courseContentItemId: courseId,
        courseResourceElementId: elementId
      };
      const response = await Auth.fetch(apiUrl, {
        body, method: 'POST'
      });
      if (response && response.status === 'SUCCESS') {
        /* placeholder */
      }
    } catch (error) {
      console.error(error);
    }
  }

  // TODO C2C-2470 unused
  // fetchCourseDeliveryOptionsOverride = async (classroomId, courseId, elementId) => {
  //   try {
  //     const body = {
  //       classroomId,
  //       courseContentItemId: courseId,
  //       courseResourceElementId: elementId
  //     };
  //     const apiUrl = `${Auth.ecms}${COURSE_DELIVERY_ENDPOINTS.FETCH_COURSE_DELIVERY_OPTIONS_OVERRIDE}`;
  //     const response = await Auth.fetch(apiUrl, {
  //       body, method: 'POST'
  //     });
  //     if (response && response.status === 'SUCCESS') {
  //       // TODO
  //       return response.data;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return null;
  //   }
  // }

  @computed get allowAdditionalResourcePills() {
    return !!this.allowedResourcePillKeys?.length;
  }

  // we are fetching this from sessionStorage because we want the
  // user preference to persist within a browser session
  @computed get shouldShowHiddenResources() {
    return !!getSessionStorageItem('c2c_shouldShowHiddenResources');
  }
}

export default new CourseDeliveryManager();
