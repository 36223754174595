import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import ReactDiffViewer from 'react-diff-viewer';

import { generateUrl } from '../utils/url';

import '../css/ProgressView.less';
import '../css/StudentSummaryWidget.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

// TODO unused
// TODO NOTE: This component was made for the support and development of experimental reports and is currently not being used.
const ReportJsonDiff = observer((_props) => {
  const {
    userManager
  } = useContext(MobXProviderContext);

  // TODO DEMO-2356 - this is temporary for testing BE report rewrite
  const allowExperimentalReport = userManager.hasExperimentalAccessPermission;
  if (!allowExperimentalReport) {
    return (
      <div style={{ backgroundColor: 'white', paddingBottom: 20, paddingTop: 20 }}>
        <div style={{ backgroundColor: 'white', fontSize: 20, padding: 10 }}>Access Denied</div>
      </div>
    );
  }

  const [reportData, setReportData] = useState({ data1: '', data2: '' });
  const [errors, setErrors] = useState({ error1: '', error2: '' });

  useEffect(() => {
    const fetchData = async () => {
      const apiUrlPrefix = `${Auth.ecms}/api/viewReportJson`;
      const urlParams = new URLSearchParams(window.location.search);
      const reportId = urlParams.get('reportId');
      const entityId = urlParams.get('entityId');

      const reportId1 = reportId;
      const apiUrl1 = generateUrl(apiUrlPrefix, {
        entityId,
        reactErrorType: true,
        reportId: reportId1,
      });
      const response1 = await Auth.fetch(apiUrl1, { method: 'POST' });

      let reportId2 = `${reportId}_R`;
      // special case because SIRIUS_COURSE_CLASSROOM_PERFORM_R has 33 characters and doesn't fit in the report.id column
      if (reportId2 === 'SIRIUS_COURSE_CLASSROOM_PERFORM_R') {
        reportId2 = 'SIRIUS_COURSE_CLASS_PERFORM_R';
      }
      // and more special cases
      if (reportId2 === 'GALL_STANDARDS_SCHOOL_CLICKBOOK_R') {
        reportId2 = 'GALL_STANDARDS_SCH_CLICKBOOK_R';
      }
      if (reportId2 === 'GALL_STANDARDS_SCHOOL_EXPERTRACK_R') {
        reportId2 = 'GALL_STANDARDS_SCH_EXPERTRACK_R';
      }
      if (reportId2 === 'SIRIUS_STANDARDS_SCHOOL_PERFORM_R') {
        reportId2 = 'SIRIUS_STANDARDS_SCH_PERFORM_R';
      }

      const apiUrl2 = generateUrl(apiUrlPrefix, {
        entityId,
        reactErrorType: true,
        reportId: reportId2,
      });

      const response2 = await Auth.fetch(apiUrl2, { method: 'POST' });

      if (response1 !== null && response1.status === 'SUCCESS' && response2 !== null && response2.status === 'SUCCESS') {
        const data1 = response1.data[0].contentJson;
        const data2 = response2.data[0].contentJson;

        const string1 = JSON.stringify(data1, null, 2);
        const string2 = JSON.stringify(data2, null, 2);

        setReportData({ data1: string1, data2: string2 });
      }

      if (response1 !== null && response1.status === 'FAILURE') {
        setErrors({ error1: `${reportId1}: ${response1.statusMessage}` });
      }

      if (response2 !== null && response2.status === 'FAILURE') {
        setErrors({ error2: `${reportId2}: ${response2.statusMessage}` });
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ backgroundColor: 'white', paddingBottom: 20, paddingTop: 20 }}>
      <ReactDiffViewer
        newValue={reportData.data1}
        oldValue={reportData.data2}
        splitView={true}
      />
      <div style={{ backgroundColor: 'white', fontSize: 20, padding: 10 }}>{errors.error1}</div>
      <div style={{ backgroundColor: 'white', fontSize: 20, padding: 10 }}>{errors.error2}</div>
    </div>
  );
});

export default ReportJsonDiff;

SatCoreRegister('ReportJsonDiff', ReportJsonDiff);
