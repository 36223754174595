import { action, makeObservable, observable, runInAction } from 'mobx';
import ContentItemApi, { CONTENT_ITEM_ENDPOINTS } from '../api/ContentItemApi';

export class ContentItemManager {
  @observable preventStudentWorkspaceOnRenameOption = true;
  @observable preventStudentWorkspaceOnReplaceOption = true;

  @observable resources = [];
  @observable loaded = false;
  @observable page;
  @observable totalPages = 0;
  @observable index = 0; // Just variable used to refetch resources in case resources become outdated

  constructor() {
    makeObservable(this);
  }

  @action setPreventStudentWorkspaceOnRenameOption = (toggle) => {
    this.preventStudentWorkspaceOnRenameOption = toggle;
  }

  @action setPreventStudentWorkspaceOnReplaceOption = (toggle) => {
    this.preventStudentWorkspaceOnReplaceOption = toggle;
  }

  @action async list(params = {}) {
    this.loaded = false;
    this.resources.clear();
    const data = await ContentItemApi.list(params);
    runInAction(() => {
      if (data) {
        this.resources = data.contentItems;
        this.page = data.page;
        this.totalPages = data.totalPages;
      }
      this.loaded = true;
    });
    return data;
  }

  @action async upload(params = {}) {
    const data = await ContentItemApi.upload(params);
    return data;
  }

  @action async replace(id, params) {
    const data = await ContentItemApi.replace(id, params);
    return !!data;
  }

  @action async updateName(id, { name }, sort) {
    const data = await ContentItemApi.updateName(id, { name });
    data.result && runInAction(() => {
      this.resources = this.resources.map((resource) => {
        resource.id === id && (resource.name = name);
        return resource;
      });
      if (sort) {
        this.resources.sort((a, b) => ((a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0));
      }
    });
    return data;
  }

  @action async delete(id) {
    const data = await ContentItemApi.delete(id);
    runInAction(() => {
      data && (this.resources = this.resources.filter(({ id }) => id !== data.contentItemId));
      this.resources.length === 0 && this.totalPages !== 0 && this.totalPages--;
    });
    if (this.totalPages !== this.page) {
      // Just to make sure that list api will not return removed files
      await new Promise((resolve) => setTimeout(resolve, 500));
      runInAction(() => this.index++);
    }
    return !!data;
  }

  @action async checkName({ name, entityTypeId }) {
    const data = await ContentItemApi.checkName({ name, entityTypeId });
    return data.contentNameExists;
  }

  @action
  versionContentItem = async (contentItemId, name, color = null, workspaceId = null, googleFolderId = null) => {
    try {
      const response = await ContentItemApi.versionContentItem({ contentItemId, name, color, workspaceId, googleFolderId });
      return response && response.status === 'SUCCESS';
    } catch (error) {
      console.error(error);
    }
  }

  @action
  deepCloneContentItem = async (contentItemId, name, workspaceId = null) => {
    try {
      const response = await ContentItemApi.deepCloneContentItem(contentItemId, name, workspaceId);
      return response && response.status === 'SUCCESS';
    } catch (error) {
      console.error(error);
    }
  }

  getDownloadUrl({ id }) {
    return ContentItemApi.getUrl(CONTENT_ITEM_ENDPOINTS.DOWNLOAD, { contentItemId: id });
  }
}

export default new ContentItemManager();
