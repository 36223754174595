import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import '../css/CartModalClassroomsPopup.less';

export default
@inject('adminUsersManager', 'filteredHeaderTableManager')
@observer
class CartModalClassroomsPopup extends Component {
  filteredHeaderTableKey = 'cart-modal-classrooms-popup-table';

  constructor(props) {
    super(props);
    this.FilteredHeaderTable = SatCoreComponent('FilteredHeaderTable');
  }

  componentDidMount = async () => {
    const { filteredHeaderTableManager } = this.props;
    filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);
  }

  handleSort = async (clickedColumn, filters) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      if (clickedColumn) {
        const newDirection = tableData.direction === 'ascending' ? 'descending' : 'ascending';
        filteredHeaderTableManager.setSortColumn(this.filteredHeaderTableKey, clickedColumn);
        filteredHeaderTableManager.setSortDirection(this.filteredHeaderTableKey, newDirection);
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      } else {
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      }
    }
  }

  createHeaderData = () => {
    const { t } = this.props;
    return [
      {
        /* Classroom Name */
        label: t('className', 'Class Name'),
        columnClassName: 'truncate-long',
        sortKey: 'classroomName',
        filterKey: '',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        /* Teacher First Name */
        label: 'Teacher First Name',
        columnClassName: 'truncate-medium',
        sortKey: 'teacherFirstName',
        filterKey: '',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        /* Teacher Last Name */
        label: 'Teacher Last Name',
        columnClassName: 'truncate-medium',
        sortKey: 'teacherLastName',
        filterKey: '',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        /* Add Button (placeholder) */
        label: '',
        columnClassName: '',
        sortKey: '',
        filterKey: ''
      }
    ];
  }

  sort = (classrooms) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (!tableData) return classrooms;
    const { column } = tableData;
    const { direction } = tableData;
    if (!column || column === '') return classrooms;
    return classrooms.sort((a, b) => {
      if (a[column] > b[column]) {
        return direction === 'ascending' ? 1 : -1;
      }
      if (a[column] < b[column]) {
        return direction === 'ascending' ? -1 : 1;
      }
      return 0;
    });
  }

  createBodyData = () => {
    const { classrooms, onRemove } = this.props;
    return this.sort(classrooms).map((classroom) => [
      {
        /* Classroom Name */
        columnName: 'classroomName',
        columnText: classroom.classroomName,
        columnClassName: 'truncate-long',
        columnOnClick: null,
        columnButtonOnClick: null,
        showTooltip: true,
        showTootipEvent: 'click'
      },
      {
        /* Teacher First Name */
        columnName: 'teacherFirstName',
        columnText: classroom.leadTeacherFirstName,
        columnClassName: 'truncate-medium',
        columnOnClick: null,
        columnButtonOnClick: null,
        showTooltip: true,
        showTootipEvent: 'click'
      },
      {
        /* Teacher Last Name */
        columnName: 'teacherLastName',
        columnText: classroom.leadTeacherLastName,
        columnClassName: 'truncate-medium',
        columnOnClick: null,
        columnButtonOnClick: null,
        showTooltip: true,
        showTootipEvent: 'click'
      },
      {
        /* Remove Button */
        columnName: null,
        columnText: 'Remove',
        columnClassName: '',
        columnOnClick: null,
        columnButtonOnClick: () => onRemove(classroom),
        columnButtonBasic: false,
        showTooltip: true,
        showTootipEvent: 'click'
      }
    ]);
  }

  render() {
    const { filteredHeaderTableManager, t } = this.props;
    const { FilteredHeaderTable } = this;
    const tableBodyData = this.createBodyData();
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    let column = '';
    let direction = '';
    let filters = [];
    if (tableData) {
      column = tableData.column;
      direction = tableData.direction;
      filters = tableData.filters;
    }

    return (
      <Container className='cart-modal-classrooms-popup' fluid>
        <Container>
          <div className='tableContainerHeader'>
            <div className='tableTitle theme-header-title'>
              Selected {t('classesLabel', 'Classes')}
            </div>
          </div>
          <div className='tableContainer'>
            <FilteredHeaderTable
              autoCompleteSearchData={null}
              column={column}
              direction={direction}
              filtersData={filters}
              handleSort={this.handleSort}
              tableBodyData={tableBodyData}
              tableHeaderData={this.createHeaderData()}
              tableId={this.filteredHeaderTableKey} />
          </div>
          {(!tableBodyData || tableBodyData.length < 1) && (
          <div className='null-state-panel'>
            No Data Found
          </div>
          )}
        </Container>
      </Container>
    );
  }
}

SatCoreRegister('CartModalClassroomsPopup', CartModalClassroomsPopup);
