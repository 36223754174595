import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Container, Form, Grid, Header, Segment
} from 'semantic-ui-react';
import '../css/Verification.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

import navigationManager, { VIEW_SELECTION } from '../managers/NavigationManager';

import DynamicSatelliteService from '../services/DynamicSatelliteService';
import InitService from '../services/InitService';

/*
  - The user will hit this page with a link like this:
    publisherSatelliteUrl + “/verification?id=” + verificationId
  - We need to take that verificationId and hit the 'api/verifyUser' endpoint
    - If valid, display SuccessPanel
    - If invalid, nav back to login
*/

export default
@inject(
  'dynamicSatelliteManager', 'userManager')
@observer
class Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      loaded: false,
      message: false
    };
    this.Logo = SatCoreComponent('Logo');
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');
  }

  async componentDidMount() {
    this.setState({ loaded: false });

    const { dynamicSatelliteManager } = this.props;

    navigationManager.setView(VIEW_SELECTION.LOGIN);

    if (dynamicSatelliteManager.isDynamicSatellite && !Auth.publisherSatelliteCode) {
      await DynamicSatelliteService.fetchDynamicSatelliteByDomain();
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 500);
    } else {
      this.setState({ loaded: true });
    }

    const { location } = this.props;
    // look for verification ID in URL params
    const urlParams = new URLSearchParams(location.search);
    let error = '';
    let verificationId = '';
    let authKey = '';
    let impersonate = false;

    if (urlParams.has('error')) {
      error = urlParams.get('error');
    }

    if (urlParams.has('verificationId')) {
      verificationId = urlParams.get('verificationId');
    }

    if (urlParams.has('authKey')) {
      authKey = urlParams.get('authKey');
    }

    if (urlParams.has('impersonate')) {
      impersonate = true;
      sessionStorage.setItem('impersonate', 'true');
    }
    impersonate = impersonate || !!sessionStorage.getItem('impersonate');

    const strOfItemsToPreserve = 'impersonate';
    if (error) {
      this.verificationError(error);
    } else if (verificationId) {
      this.verifyUser(verificationId);
    } else if (authKey && impersonate) {
      InitService.doLogoutCleanUp(strOfItemsToPreserve, { authKey });
      this.impersonatorLogIn(authKey);
    } else if (authKey) {
      InitService.doLogoutCleanUp(strOfItemsToPreserve, { authKey });
      this.cleverLogIn(authKey);
    } else {
      // go to login
      this.navToLogin();
    }
  }

  verificationError = (error) => {
    this.setState({ errorMessage: error });
  }

  impersonatorLogIn = async (authKey) => {
    const { userManager } = this.props;
    const result = await userManager.impersonatorLogIn(authKey);
    result ? this.navToHome() : this.navToLogin();
  }

  cleverLogIn = async (authKey) => {
    const { userManager } = this.props;
    const result = await userManager.cleverLogIn(authKey);
    result ? this.navToHome() : this.navToLogin();
  };

  verifyUser = async (verificationId) => {
    const { userManager } = this.props;
    // here we call api/verifyUser
    const result = await userManager.verifyUser(verificationId);
    result ? this.setState({ message: true }) : this.navToLogin();
  };

  navToLogin = () => {
    const { history } = this.props;
    history.replace('/signin');
  }

  navToHome = () => {
    const { history } = this.props;
    history.replace('/');
  }

  panel = ({ header, button, message }) => {
    const { Logo } = this;
    return (
      <Grid className='fullHeight' textAlign='center' verticalAlign='middle'>
        <Grid.Column className='max-width-558'>
          <Form>
            <Header as='h2' attached='top' block className='verification-header'>
              <Logo />
              {header}
            </Header>
            <Segment attached className='element-body-verification'>
              <Form.Field>
                {message}
                <div className='panel-body'>
                  <Button basic className='verification' id='home-btn' onClick={this.navToLogin}
                    primary>{button}
                  </Button>
                </div>
              </Form.Field>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }

  successPanel = () => {
    const { t } = this.props;

    const header = (
      <span className='header-text'>
        {t('registration', 'Self-Registration')}
        {' '}
        Success
      </span>
    );

    return this.panel({
      button: t('buttonLogin', 'Take me to login'),
      header
    });
  }

  failPanel = () => {
    const { t } = this.props;
    const { errorMessage } = this.state;
    return this.panel({
      button: t('buttonRetry', 'Retry Login'),
      header: <span className='header-text'>An Error Has Occurred</span>,
      message: <Container className='login-error-message' text>{errorMessage}</Container>
    });
  }

  render() {
    const { SatCoreLoader } = this;
    const { errorMessage, message, loaded } = this.state;
    return loaded ? (
      <div className='verification-page'>
        {message ? this.successPanel() : null}
        {errorMessage ? this.failPanel() : null}
      </div>
    ) : <SatCoreLoader />;
  }
}

SatCoreRegister('Verification', Verification);
