import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { Button, Container, Loader } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import Modal from '../Modal';
import '../../css/ViewNotificationModal.less';
import '../../css/SystemNotificationCard.less';

import { PLAYER_TYPES } from '../../managers/ContentManager';

import { DIALOG_NAMES } from '../../managers/DialogManager';

const ViewNotificationModal = observer((props) => {
  const { contentManager, dialogManager, notificationManager, userManager } = useContext(MobXProviderContext);
  const { open, close, t } = props;
  const ModalBanner = useMemo(() => SatCoreComponent('ModalBanner'), []);
  const SystemNotificationCard = SatCoreComponent('SystemNotificationCard');
  const FullscreenModal = SatCoreComponent('FullscreenModal');
  const [thisModalTitle, setThisModalTitle] = useState();
  const [isError, setIsError] = useState(false);

  const [lessonPlayerShowing, setLessonPlayerShowing] = useState(false);
  const [contentUrl, setContentUrl] = useState('');
  const [contentName, setContentName] = useState('');
  const [contentImageUrl, setContentImageUrl] = useState('');

  useEffect(() => {
    const { systemNotification } = notificationManager;
    if (!systemNotification) {
      setIsError(true);
      setThisModalTitle(t('errorHeader', 'Notification not found'));
    } else {
      setIsError(false);
      if (systemNotification.type === 'report_output') {
        setThisModalTitle(t('exportReady', 'Export Ready'));
      } else if (systemNotification.type === 'share_assessment') {
        setThisModalTitle(t('sharedAssessment', 'Shared Assessment'));
      } else {
        setThisModalTitle('Notice');
      }
    }

  }, []);

  const handleCustomButtonClick = (data) => {
    const { type, value } = data;
    const {
      displayName,
      metadata
    } = value;
    const metadataJson = metadata ? JSON.parse(metadata) : {};
    if (type === 'preview-shared-assessment') {
      handleView(metadataJson.contentItemId, 'lesson', displayName)
    } else if (type === 'edit-shared-assessment') {
      handleEdit(metadataJson.contentItemId);
    }
  }

  const handleView = async (contentItemId, entityTypeId, name) => {
    let option = null;
    option = await contentManager.getOptionsForTeacherPreview(
      contentItemId, entityTypeId, null, origin,
      userManager.canViewAsTeacher, userManager.userId,
      null, null,
      null
    );

    const { playerType, viewUrl, isFlowpaper } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    } else if (playerType === null && viewUrl === null) {
      // if playerType and viewURL are null this is an unviewable type.
      if (entityTypeId === 'file_resource') {
        // For file resources, these are download only so show message.
        dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
          title: t('downloadOnlyTitle'),
          message: t('downloadOnlyModalMsg'),
          closeButtonClass: 'deleteButton',
          closeButtonName: 'Okay'
        },
        () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
        return;
      } else {
        // otherwise show generic message.
        dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
          title: t('noPreviewAvailableTitle'),
          message: t('noPreviewAvailableMsg'),
          closeButtonClass: 'deleteButton',
          closeButtonName: 'Ok'
        },
        () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
        return;
      }
    }

    await contentManager.configPlayerWindow(playerType, window, null);

    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        contentItemId,
        contentItemType: entityTypeId,
        resourceName: name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
    }

    setLessonPlayerShowing(playerType === PLAYER_TYPES.LESSON_PLAYER);
    setContentUrl(viewUrl);
    setContentName(name);
    setContentImageUrl('');
  }

  const closeLibraryAssessmentBuilderModal = async () => {
    dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW);
  }

  const handleEdit = (contentItemId) => {
      // Open the c2c-lesson assessement builder.
      dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW, {
        closeLibraryAssessmentBuilderModal: closeLibraryAssessmentBuilderModal,
        contentItemId,
        history
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW));
  }

  const hideIframe = () => {
    setLessonPlayerShowing(false);
  }

  const renderLessonPlayer = () => {
    return (
      <Container className='libraryView' fluid>
        <FullscreenModal
          closeIframeCallback={hideIframe}
          contentImageUrl={contentImageUrl}
          contentName={contentName}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={contentUrl} />
      </Container>
    );
  }
  if (lessonPlayerShowing) {
    return (renderLessonPlayer());
  } else {
    const { systemNotification } = notificationManager;
    return (
      <Modal
        className='view-notification-modal'
        closeOnDimmerClick={false}
        closeOnEscape={true}
        onClose={close}
        open={open}>
        <ModalBanner onClose={close} title={thisModalTitle} />
        <Modal.Content>
          {(!isError && systemNotification) ? (
            <SystemNotificationCard
              key='linked-notification'
              handleDeleteSystemNotification={null}
              notification={systemNotification}
              onCustomButtonClick={handleCustomButtonClick}
              showDelete={false} />
          )
            : (
              <div className='error-msg'>
                <p>{t('notificationErrorMsg', 'There was a problem retrieving the linked notification')} </p>
              </div>
            )}
        </Modal.Content>
        <Modal.Actions>
          <Button basic className='cancelButton' onClick={close} primary>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
});

export default ViewNotificationModal;

SatCoreRegister('ViewNotificationModal', ViewNotificationModal);
