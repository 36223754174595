import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Checkbox } from 'semantic-ui-react';

import '../../css/reports/ReportCellValueToggler.less';

import { SatCoreRegister } from '../../SatCoreRegistry';

const ReportCellValueToggler = observer((props) => {
  const { t } = props;

  const { reportContextManager, reportIdentityManager } = useContext(MobXProviderContext);

  const { activeReportScorePropName } = reportIdentityManager;

  let showCellValue;
  if (reportContextManager.isContextEngagementReport) {
    showCellValue = reportContextManager.showEngagementNumbers;
  } else if (reportContextManager.isContextProgressReport) {
    showCellValue = reportContextManager.showProgressNumbers;
  } else {
    showCellValue = true;
  }

  const handleClick = (_event, data) => {
    if (reportContextManager.isContextEngagementReport) {
      reportContextManager.setShowEngagementNumbers(!!data?.checked);
    } else if (reportContextManager.isContextProgressReport) {
      reportContextManager.setShowProgressNumbers(!!data?.checked);
    }
  };

  const { reportInfoClassNames } = reportIdentityManager;
  return (
    <div className={`report-cell-value-toggler ${reportInfoClassNames}`}>
      <div className='toggle-on-or-off-wrapper'>
        <Checkbox
          className='on-or-off-toggler'
          defaultChecked={showCellValue}
          label={t(`${activeReportScorePropName}Label`)} // 'engagementLabel' | 'progressScoreLabel'
          onChange={handleClick}
          toggle />
      </div>
    </div>
  );
});
export default ReportCellValueToggler;

SatCoreRegister('ReportCellValueToggler', ReportCellValueToggler);
