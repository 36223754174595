import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Container, Loader, Message, Popup } from 'semantic-ui-react';
import '../css/RosterTable.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { DIALOG_NAMES } from '../managers/DialogManager';
import { PATH_TYPES, VIEW_SELECTION } from '../managers/NavigationManager';

import ClassroomService from '../services/ClassroomService';
import PopupService from '../services/PopupService';
import UserService from '../services/UserService';

@inject(
  'adminClassroomManager', 'classroomManager',
  'dialogManager', 'navigationManager', 'userManager')
@observer
class ClassRoster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classroom: undefined,
      isActive: true,
      isLoadingClassRoster: false,
      serverError: false,
      serverErrorMsg: ''
    };
    this.ClassRosterTable = SatCoreComponent('ClassRosterTable');
    this.StudentAccessCode = SatCoreComponent('StudentAccessCode');
    this.CoTeacherAccessCode = SatCoreComponent('CoTeacherAccessCode');
    this.CourseListBanner = SatCoreComponent('CourseListBanner');
  }

  async componentDidMount() {
    this.setState({
      isLoadingClassRoster: true
    });
    const { classroomManager, navigationManager, userManager } = this.props;
    const { currentClassroomId } = classroomManager;
    const isAdmin = userManager.isDistrictOrSchoolAdmin;

    if (isAdmin) {
      const result = await ClassroomService.initAdminData({
        ...this.props,
        forceFetchClassroom: true
      });

      if (result && result.shouldInitMoreDataIfApplicable) {
        await this.initData();
      }
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      if (!currentClassroomId && urlParams.has('classroomId')) {
        classroomManager.setCurrentClassroomId(urlParams.get('classroomId'));
      }
      navigationManager.clearAllPaths();
      navigationManager.setView(VIEW_SELECTION.DASHBOARD);
      await this.initData();
    }

    this.setState({
      isLoadingClassRoster: false
    });
  }

  setAdminBreadcrumbs = async (institutionId, userId, userName, urlParamStr) => {
    const { navigationManager, userManager, t } = this.props;
    if (institutionId && userManager.isDistrictAdmin) {
      const schoolsBreadcrumbObj = {
        fromView: VIEW_SELECTION.DASHBOARD,
        path: { name: 'Schools' }
      };
      const schoolNameBreadcrumbObj = {
        fromView: VIEW_SELECTION.DASHBOARD,
        paramName: 'institutionName',
        path: {}
      };
      await navigationManager.setBreadcrumb(schoolsBreadcrumbObj);
      await navigationManager.setBreadcrumb(schoolNameBreadcrumbObj);
    }
    if (userId && userName) {
      let routerUrl_users = `/users?view=${VIEW_SELECTION.USERS}`;
      if (urlParamStr) {
        routerUrl_users += `&${urlParamStr}`;
      }
      const usersBreadcrumbObj = {
        fromView: VIEW_SELECTION.USERS,
        path: { name: 'Users', routerUrl: routerUrl_users }
      };
      let routerUrl_adminProfile = `/adminProfile?view=${VIEW_SELECTION.USERS}`;
      if (urlParamStr) {
        routerUrl_adminProfile += `&${urlParamStr}`;
      }
      const userNameBreadcrumbObj = {
        path: {
          name: userName,
          routerUrl: routerUrl_adminProfile
        }
      };
      await navigationManager.setBreadcrumb(usersBreadcrumbObj);
      await navigationManager.setBreadcrumb(userNameBreadcrumbObj);
    } else {
      let routerUrl_classrooms = `/classrooms?view=${VIEW_SELECTION.CLASSROOMS}`;
      if (urlParamStr) {
        routerUrl_classrooms += `&${urlParamStr}`;
      }
      const classroomsBreadcrumbObj = {
        fromView: VIEW_SELECTION.CLASSROOMS,
        path: { name: t('classesLabel', 'Classes'), routerUrl: routerUrl_classrooms }
      };
      await navigationManager.setBreadcrumb(classroomsBreadcrumbObj);
    }
  }

  initData = async () => {
    const { classroomManager, navigationManager } = this.props;

    if (!classroomManager.getClassroom(classroomManager.currentClassroomId)) {
      classroomManager.fetchClassroomData(classroomManager.currentClassroomId).then(() => {
        const classroom = classroomManager.getClassroom(classroomManager.currentClassroomId);
        this.setState({
          classroom,
          name: classroom.name,
          nickname: classroom.nickname,
          startDate: classroom.timezoneStartDate,
          endDate: classroom.timezoneEndDate,
          imageUrl: classroom.imageUrl,
          archived: classroom.archived,
          saved: false,
          failed: false
        });

        navigationManager.addPath({
          currentCourseId: null,
          currentClassroomId: classroomManager.currentClassroomId,
          currentElementId: null,
          name: classroomManager.getClassName(classroomManager.currentClassroomId),
          type: PATH_TYPES.LINK,
          treeNavigationFunction: null
        });
      });
    } else {
      const classroom = classroomManager.getClassroom(classroomManager.currentClassroomId);
      this.setState({
        classroom,
        name: classroom.name,
        nickname: classroom.nickname,
        startDate: classroom.timezoneStartDate,
        endDate: classroom.timezoneEndDate,
        imageUrl: classroom.imageUrl,
        archived: classroom.archived,
        saved: false,
        failed: false
      });
      navigationManager.addPath({
        currentCourseId: null,
        currentClassroomId: classroomManager.currentClassroomId,
        currentElementId: null,
        name: classroomManager.getClassName(classroomManager.currentClassroomId),
        type: PATH_TYPES.LINK,
        treeNavigationFunction: null
      });
    }
    await this.loadActiveRoster();
  }

  deleteCoteacherConfirm = (coteacherName, coteacherId) => {
    const { dialogManager, t } = this.props;
    const title = `Delete ${t('coteacher', 'Co-Teacher')}: ${coteacherName}?`;
    const message = t(
      'message', 'Deleting a Co-Teacher removes their access to the class. Assignments made by the Co-Teacher will remain intact.'
    );
    dialogManager.setOpenDialog(DIALOG_NAMES.CONFIRM, {
      title,
      message,
      cancelButtonClass: 'keepButton',
      cancelButtonName: 'No, Keep',
      confirmButtonClass: 'deleteButton',
      confirmButtonName: 'Yes, Delete',
      confirmHandler: () => this.deleteCoteacher(coteacherId),
      coteacherId
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM));
  }

  deleteCoteacher = async (coteacherId) => {
    const {
      adminClassroomManager, classroomManager, dialogManager, userManager
    } = this.props;
    const { currentClassroomId } = classroomManager;
    const isAdmin = userManager.isDistrictOrSchoolAdmin;
    const result = await classroomManager.deleteCoteacher(currentClassroomId, coteacherId);
    if (result && result.status === 'SUCCESS') {
      this.setState({
        serverError: false,
        serverErrorMsg: ''
      });
      if (!isAdmin) {
        classroomManager.fetchClassroomData(currentClassroomId);
      } else {
        const classroom = await adminClassroomManager.fetchClassroom(currentClassroomId);
        await classroomManager.setAdminClassroom(currentClassroomId, classroom);
      }
    } else {
      this.setState({
        serverError: true,
        serverErrorMsg: (result && result.statusMessage)
          ? result.statusMessage
          : 'Something went wrong when deleting the co-teacher, please try again'
      });
    }
    dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
  }

  renderClassroomLeadTeacher = (leadTeacherName) => {
    const { t } = this.props;
    leadTeacherName = leadTeacherName || t('unknownLeadTeacherName');
    return (
      <div className='leadTeacherListContainer teacherListContainer'>
        <div className='leadTeacherListLabel teacherListLabel'>
          {t('leadTeacher')}
        </div>
        <ul className='leadTeacherList teacherList'>
          <li className='leadTeacherListItem teacherListItem'>
            <div className='leadTeacherListItemContainer teacherListItemContainer'>
              <span className='name'>
                {leadTeacherName}
              </span>
            </div>
          </li>
        </ul>
      </div>
    );
  }

  renderClassroomCoteachers = (coteachers) => {
    const { userManager, classroomManager, t } = this.props;
    const { classroom } = this.state;
    const { currentClassroomId } = classroomManager;

    const canDeleteCoteacherByDefault = (
      classroomManager.isLeadTeacher(currentClassroomId, userManager.userId) ||
        (userManager.isDistrictOrSchoolAdmin)
    );
    const canDeleteSsoCoteacher = UserService.canDeleteSsoCoteacherFromClassroom({ classroom });
    return (
      <>
        {coteachers && coteachers.length > 0 && (
          <div className='coTeacherListContainer teacherListContainer'>
            <div className='coTeacherListLabel teacherListLabel'>
              {t('coteachers', 'Co-Teachers')}
            </div>
            <ul className='coTeacherList teacherList'>
              {coteachers.map((coteacher, index) => {
                const canDeleteCoteacher = canDeleteCoteacherByDefault && (
                  canDeleteSsoCoteacher || !coteacher.ssoId
                );

                let coteacherPopupMsgKey;
                if (!canDeleteCoteacher && !canDeleteCoteacherByDefault) {
                  coteacherPopupMsgKey = 'cannotDeleteCoteacherMsgDefault';
                } else if (!canDeleteCoteacher && !canDeleteSsoCoteacher) {
                  coteacherPopupMsgKey = 'cannotDeleteCoteacherMsgSso';
                }
                const coteacherName = `${coteacher.firstName} ${coteacher.lastName}`;

                const coteacherPopupMsg = coteacherPopupMsgKey ? t(coteacherPopupMsgKey) : undefined;
                return (
                  <li key={index}
                    className='coTeacherListItem teacherListItem'>
                    <div className='coteacherListItemContainer teacherListItemContainer'>
                      {PopupService.renderPopup({
                        content: coteacherPopupMsg,
                        disabled: !coteacherPopupMsg,
                        offset: [-11, 0],
                        position: 'top left',
                        trigger: (
                          <span className='icon' onClick={() => {
                            if (!coteacherPopupMsg) {
                              return this.deleteCoteacherConfirm(coteacherName, coteacher.id);
                            }
                          }}>
                            <Button basic disabled={!!coteacherPopupMsg}
                              icon='close' size='small' />
                          </span>
                        ),
                        wide: false
                      })}
                      <span className='name'>{coteacherName}</span>
                    </div>
                  </li>
                );
              }
              )}
            </ul>
            {
              this.state.serverError ? (
                <div className='server-error-msg'>
                  <Message
                    content={this.state.serverErrorMsg}
                    error />
                </div>
              ) : null
            }
          </div>
        )}
      </>
    );
  }

  renderAccessCodeContainer = () => {
    /* check for an override */
    const { renderAccessCodeContainer, t } = this.props;
    if (renderAccessCodeContainer !== undefined) {
      return renderAccessCodeContainer(this.props, this);
    }

    const { classroomManager, userManager } = this.props;
    const { currentClassroomId } = classroomManager;

    const { coteachers, coteacherIds, leadTeacherName } = classroomManager.getClassroom(currentClassroomId);

    const { CoTeacherAccessCode, StudentAccessCode } = this;

    const isAdmin = userManager.isDistrictOrSchoolAdmin;

    const isUserCoTeacher = coteacherIds.includes(userManager.userId);

    let code = null;
    if (currentClassroomId && currentClassroomId !== '') {
      code = classroomManager.getCoTeacherAccessCode(currentClassroomId);
    }

    const isSelfRegClassroom = ClassroomService.isSelfRegClassroom();

    return (
      <>
        <div className='accessCodeContainer'>
          {!isSelfRegClassroom/* UserService.isSsoUserOrHasSsoInstitution() */ ? (
            <CoTeacherAccessCode classId={currentClassroomId} />
          ) : (
            <>
              <StudentAccessCode classId={currentClassroomId} />
              <CoTeacherAccessCode classId={currentClassroomId} code={code} />
            </>
          )}
          {this.renderClassroomLeadTeacher(leadTeacherName)}
          {this.renderClassroomCoteachers(coteachers)}
          {(isAdmin && code) && (
            <div className='add-admin-coteacher-container'>
              {!isUserCoTeacher ? (
                <Button
                  className='add-admin-coteacher-button'
                  onClick={() => this.addAdminToClassroomAsCoteacher(code)}
                  primary
                  type='button'>
                  {t('addAdminAsCoteacher', 'Make me a Co-Teacher')}
                </Button>
              ) : (
                <Popup
                  content={t('confirmUserCoteacher', 'You are a co-teacher for this class.')}
                  on='hover'
                  position='bottom center'
                  trigger={(
                    <div>
                      <Button
                        className='add-admin-coteacher-button'
                        disabled={isUserCoTeacher}
                        primary
                        type='button'>
                        {t('addAdminAsCoteacher', 'Make me a Co-Teacher')}
                      </Button>
                    </div>
                  )}
                />
              )}
            </div>
          )}
        </div>
      </>
    );
  }

  addAdminToClassroomAsCoteacher = async (accessCode) => {
    const { userManager } = this.props;
    const isAdmin = userManager.isDistrictOrSchoolAdmin;
    if (isAdmin) {
      const result = await ClassroomService.joinClassroom(accessCode, false);
      if (result?.shouldInitMoreDataIfApplicable) {
        await this.initData();
      }
    }
  }

  loadActiveRoster = async () => {
    const { classroomManager } = this.props;
    this.setState({ isActive: true });
    await classroomManager.fetchClassroomRoster(classroomManager.currentClassroomId);
  }

  loadArchiveRoster = async () => {
    const { classroomManager } = this.props;
    this.setState({ isActive: false });
    await classroomManager.fetchArchiveClassroomRoster(classroomManager.currentClassroomId);
  }

  render() {
    const { classroomManager, t } = this.props;
    const { isLoadingClassRoster } = this.state;

    let { readOnly } = this.props;

    const { ClassRosterTable } = this;
    const { CourseListBanner } = this;

    const classroomId = classroomManager.currentClassroomId;
    const classroom = classroomManager.getClassroom(classroomId);

    const currentAccessCodeValue = classroomManager.getStudentAccessCode(classroomId);

    if (readOnly === undefined || readOnly === null) {
      readOnly = false;
    }

    return !isLoadingClassRoster && (/* !userManager.isDistrictOrSchoolAdmin || */classroom) ? (
      <Container className='class-roster-view' fluid>
        <CourseListBanner
          currentAccessCodeValue={currentAccessCodeValue}
          hideAddCourseButton={true}
          hideAddStudentButton={false}
          history={this.props.history}
          showBreadCrumbs='bottom' />
        {(readOnly === false) ? (
          <Container className='roster-tab-container'>
            <Button
              className={(this.state.isActive === true)
                ? 'tab-button active' : 'tab-button'}
              onClick={this.loadActiveRoster}>
              {t('active', 'Active')}
            </Button>
            <Button
              className={(this.state.isActive === false)
                ? 'tab-button active' : 'tab-button'}
              onClick={this.loadArchiveRoster}>
              {t('archived', 'Archived')}
            </Button>
          </Container>
        ) : null}
        <Container className='roster-table-container'>
          <div className='classRosterRow'>
            <ClassRosterTable
              history={this.props.history}
              isActive={this.state.isActive}
              location={this.props.location}
              readOnly={readOnly} />
            {this.renderAccessCodeContainer()}
          </div>
        </Container>
      </Container>
    ) : <Loader active />;
  }
}

export default ClassRoster;

SatCoreRegister('ClassRoster', ClassRoster);
