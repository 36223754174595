import React, { useEffect, useRef } from 'react';
import { Modal, Ref } from 'semantic-ui-react';
import { focusTrap } from '../utils';

const focusModal = () => {
  const modals = document.getElementsByClassName('ui modal');
  modals[modals.length - 1]?.focus();
};

const ModalWrapped = (props) => {
  const { open } = props;
  const modalRef = useRef();
  useEffect(() => (open ? modalRef.current?.querySelector('.ui.modal')?.focus() : focusModal()), [modalRef, open]);
  useEffect(() => focusModal, []);

  return (
    <Ref innerRef={modalRef}>
      <Modal
        aria-modal='true'
        onKeyDown={(event) => focusTrap(modalRef.current, event)}
        role='dialog'
        tabIndex='0'
        {...props} />
    </Ref>
  );
};

ModalWrapped.defaultProps = Modal.defaultProps;
ModalWrapped.autoControlledProps = Modal.autoControlledProps;
ModalWrapped.Actions = Modal.Actions;
ModalWrapped.Content = Modal.Content;
ModalWrapped.Description = Modal.Description;
ModalWrapped.Dimmer = Modal.Dimmer;
ModalWrapped.Header = Modal.Header;

export default ModalWrapped;
