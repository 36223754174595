/* eslint-disable sort-keys */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Container, Dropdown, Input, Loader, Message
} from 'semantic-ui-react';
import PopupButton from './PopupButton';

import '../css/AdminUserProfile.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { DIALOG_NAMES } from '../managers/DialogManager';
import { ERROR_MESSAGES } from '../managers/UserManager';
import { VIEW_SELECTION } from '../managers/NavigationManager';

import UserService from '../services/UserService';

import { scrollElementIntoView } from '../utils';

export default @inject(
  'adminUsersManager',
  'classroomManager',
  'dialogManager',
  'filteredHeaderTableManager',
  'navigationManager',
  'userManager'
)
@observer
class AdminUserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changesHappened: false,
      error: false,
      errorMessage: '',
      isAdminUserProfileLoading: false,
      originalEnabled: false,
      originalFirstName: '',
      originalLastName: '',
      originalPassword: '',
      originalTimezoneId: '',
      originalUsername: '',
      saved: false
    };

    this.Avatar = SatCoreComponent('Avatar');
    this.BreadCrumbs = SatCoreComponent('BreadCrumbs');
    this.FilteredHeaderTable = SatCoreComponent('FilteredHeaderTable');

    this.messageWrapperRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({ isAdminUserProfileLoading: true });
    const {
      adminUsersManager,
      navigationManager
    } = this.props;
    navigationManager.setView(VIEW_SELECTION.USERS);

    await this.setBreadcrumbs();

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('userId')) {
      const userId = urlParams.get('userId');
      await this.fetchRoles();
      await this.fetchUserAndUpdateState(userId);
      await adminUsersManager.fetchUserClassrooms(userId);

      this.setState({ isAdminUserProfileLoading: false });
    } else {
      this.setState({ isAdminUserProfileLoading: false });
    }
  }

  fetchUserAndUpdateState = async (userId) => {
    const { adminUsersManager } = this.props;

    await adminUsersManager.fetchUser(userId);

    this.setState({
      originalEnabled: adminUsersManager.currentUser.enabled,
      originalFirstName: adminUsersManager.currentUser.firstName,
      originalLastName: adminUsersManager.currentUser.lastName,
      originalPassword: adminUsersManager.currentUser.password,
      originalTimezoneId: adminUsersManager.currentUser.timezoneId,
      originalUsername: adminUsersManager.currentUser.username,
    });
  }

  setBreadcrumbs = async () => {
    const { navigationManager, userManager } = this.props;
    navigationManager.clearAllPaths();
    const { institutionName, urlParamStr } = await navigationManager.getAdminUrlParams();

    const schoolsBreadcrumbObj = {
      fromView: VIEW_SELECTION.DASHBOARD,
      path: { name: 'Schools' }
    };
    const schoolNameBreadcrumbObj = {
      fromView: VIEW_SELECTION.DASHBOARD,
      path: {},
      paramName: 'institutionName'
    };
    let routerUrl_users = `/users?view=${VIEW_SELECTION.USERS}`;
    if (urlParamStr) {
      routerUrl_users += `&${urlParamStr}`;
    }
    const usersBreadcrumbObj = {
      fromView: VIEW_SELECTION.USERS,
      path: { name: 'Users', routerUrl: routerUrl_users }
    };
    let routerUrl_adminProfile = `/adminProfile?view=${VIEW_SELECTION.USERS}`;
    if (urlParamStr) {
      routerUrl_adminProfile += `&${urlParamStr}`;
    }
    const userNameBreadcrumbObj = {
      path: {
        routerUrl: routerUrl_adminProfile
      },
      paramName: 'userName'
    };
    if (institutionName && userManager.isDistrictAdmin) {
      await navigationManager.setBreadcrumb(schoolsBreadcrumbObj);
      await navigationManager.setBreadcrumb(schoolNameBreadcrumbObj);
      await navigationManager.setBreadcrumb(usersBreadcrumbObj);
      await navigationManager.setBreadcrumb(userNameBreadcrumbObj);
    } else {
      await navigationManager.setBreadcrumb(usersBreadcrumbObj);
      await navigationManager.setBreadcrumb(userNameBreadcrumbObj);
    }
  }

  verifyChanges = async () => {
    const { adminUsersManager, t, userManager } = this.props;

    this.setState({ saved: false, error: false, errorMessage: '' });

    let changesHappened = false;

    if (!adminUsersManager.currentUser.firstName) {
      this.setState({
        saved: false,
        error: true,
        errorMessage: t('errorMessageFirstName')
      });
      scrollElementIntoView(this.messageWrapperRef.current);
      return;
    }

    if (!adminUsersManager.currentUser.lastName) {
      this.setState({
        saved: false,
        error: true,
        errorMessage: t('errorMessageLastName')
      });
      scrollElementIntoView(this.messageWrapperRef.current);
      return;
    }

    if (!adminUsersManager.currentUser.username) {
      this.setState({
        saved: false,
        error: true,
        errorMessage: t('errorMessageUsername')
      });
      scrollElementIntoView(this.messageWrapperRef.current);
      return;
    }

    if (this.state.originalUsername &&
      this.state.originalUsername !== adminUsersManager.currentUser.username
    ) {
      if (adminUsersManager.currentUser.username) {
        let errorMessage = null;
        // check if this is a student in which case there may be special handling
        if (adminUsersManager.currentUser.userRoles.toLowerCase().includes('student')) {
          // check if we have a valid username/email format
          const usernameResult = userManager.validateStudentUsernameFormat(adminUsersManager.currentUser.username);
          if (!usernameResult.valid) {
            errorMessage = usernameResult.errorMessage;
          }
        }
        // check if username exists
        // eslint-disable-next-line max-len
        const usernameExists = await userManager.validateUserEmail(
          adminUsersManager.currentUser.publisherId, adminUsersManager.currentUser.username
        );
        if (usernameExists) {
          errorMessage = ERROR_MESSAGES.USERNAME_EXISTS;
        }
        if (errorMessage !== null) {
          this.setState({ error: true, errorMessage });
          scrollElementIntoView(this.messageWrapperRef.current);
          return false;
        }
      }
      changesHappened = true;
    }
    if (this.state.originalFirstName &&
      this.state.originalFirstName !== adminUsersManager.currentUser.firstName) {
      changesHappened = true;
    }
    if (this.state.originalLastName &&
      this.state.originalLastName !== adminUsersManager.currentUser.lastName) {
      changesHappened = true;
    }
    if (this.state.originalEnabled !== adminUsersManager.currentUser.enabled) {
      changesHappened = true;
    }
    if (this.state.originalTimezoneId &&
      this.state.originalTimezoneId !== adminUsersManager.currentUser.timezoneId) {
      changesHappened = true;
    }
    if (this.state.originalPassword !== adminUsersManager.currentUser.password) {
      changesHappened = true;
    }

    let result = false;
    const errorMessage = 'Your profile failed to save. Please try again.';

    if (changesHappened) {
      this.setState({ isAdminUserProfileLoading: true });
      result = await adminUsersManager.saveChanges({
        id: adminUsersManager.currentUser.id,
        firstName: adminUsersManager.currentUser.firstName,
        lastName: adminUsersManager.currentUser.lastName,
        enabled: adminUsersManager.currentUser.enabled,
        timezoneId: adminUsersManager.currentUser.timezoneId,
        username: adminUsersManager.currentUser.username,
        password: adminUsersManager.currentUser.password
      });
      if (result) {
        this.setState({
          saved: true,
          error: false,
          changesHappened: false
        });
        this.fetchUserAndUpdateState(adminUsersManager.currentUser.id);
        this.setState({ isAdminUserProfileLoading: false });
        scrollElementIntoView(this.messageWrapperRef.current);
      } else {
        this.setState({
          saved: false,
          error: true,
          errorMessage,
          isAdminUserProfileLoading: false
        });
        scrollElementIntoView(this.messageWrapperRef.current);
      }
    }
  };

  fetchRoles = async () => {
    const { adminUsersManager } = this.props;
    if (!adminUsersManager.roles || adminUsersManager.roles.length < 1) {
      await adminUsersManager.fetchSatelliteRoles();
    }
  }

  checkIfChangesHappened = () => {
    const { adminUsersManager } = this.props;

    if (this.state.originalUsername !== adminUsersManager.currentUser.username) {
      this.setState({ changesHappened: true });
    } else if (this.state.originalFirstName !== adminUsersManager.currentUser.firstName) {
      this.setState({ changesHappened: true });
    } else if (this.state.originalLastName !== adminUsersManager.currentUser.lastName) {
      this.setState({ changesHappened: true });
    } else if (this.state.originalEnabled !== adminUsersManager.currentUser.enabled) {
      this.setState({ changesHappened: true });
    } else if (this.state.originalTimezoneId &&
      this.state.originalTimezoneId !== adminUsersManager.currentUser.timezoneId) {
      this.setState({ changesHappened: true });
    } else if (this.state.originalPassword !== adminUsersManager.currentUser.password) {
      this.setState({ changesHappened: true });
    } else {
      this.setState({ changesHappened: false });
    }
  }

  handleChangeUsername = (_event, data) => {
    const { adminUsersManager } = this.props;
    adminUsersManager.setUserName(data.value.toLowerCase());
    this.checkIfChangesHappened();
  };

  handleChangeFirstName = (_event, data) => {
    const { adminUsersManager } = this.props;
    adminUsersManager.setFirstName(data.value);
    this.checkIfChangesHappened();
  };

  handleChangeLastName = (_event, data) => {
    const { adminUsersManager } = this.props;
    adminUsersManager.setLastName(data.value);
    this.checkIfChangesHappened();
  };

  handleChangeEnabled = (_event, data) => {
    const { adminUsersManager } = this.props;
    adminUsersManager.setEnabled(data.value);
    this.checkIfChangesHappened();
  };

  handleChangeTimezoneId = (_event, data) => {
    const { adminUsersManager } = this.props;
    adminUsersManager.setTimezoneId(data.value);
    this.checkIfChangesHappened();
  };

  onClickOpenModal = () => {
    const { userManager, adminUsersManager, dialogManager, classroomManager } = this.props;
    if (userManager.isSsoUser) {
      return;
    }
    dialogManager.setOpenDialog(DIALOG_NAMES.UPDATE_USER_PASSWORD_MODAL, {
      userName: adminUsersManager.currentUser.displayName,
      save: async (password) => await classroomManager.resetStudentPassword(adminUsersManager.currentUser.id, password)
    });
  }

  toggleAvatarEditor = () => {
    const { adminUsersManager, dialogManager } = this.props;
    if (dialogManager.openedDialogs.has(DIALOG_NAMES.AVATAR)) {
      dialogManager.closeDialog(DIALOG_NAMES.AVATAR);
    } else {
      dialogManager.setOpenDialog(DIALOG_NAMES.AVATAR, { userId: adminUsersManager.currentUser.id }, this.toggleAvatarEditor);
    }
  };

  gotoUserClassRoster = async (classroom) => {
    const { history, navigationManager } = this.props;
    let routerUrl = `/roster?classroomId=${classroom.id}`;
    const paramsToExcludeFromStr = 'classroomId';
    const { urlParamStr } = await navigationManager.getAdminUrlParams(
      paramsToExcludeFromStr
    );
    if (urlParamStr) {
      routerUrl += `&${urlParamStr}`;
    }
    history.push(routerUrl);
  }

  renderRoleNames = (roleIds) => {
    const { adminUsersManager } = this.props;
    const rolesArrayContent = [];
    if (roleIds) {
      const rolesIdsArray = roleIds.split(',');
      if (rolesIdsArray.length > 0) {
        rolesIdsArray.map((roleId, index) => {
          const role = adminUsersManager.roles.find((role) => role.id === roleId);
          if (role) {
            rolesArrayContent.push(<Input key={index} disabled={true} type='text' value={role.name || ''} />);
          }
        });
      }
    }
    if (rolesArrayContent.length < 1) {
      rolesArrayContent.push(<Input key={0} disabled={true} type='text' value='No visible roles set for user.' />);
    }
    return rolesArrayContent;
  }

  /** @param {RegExp} name example: `/Teacher/i` or `/Student/i` */
  userRoleIs = (name) => {
    const { adminUsersManager: { roles, currentUser: { roleIds, userRoles } } } = this.props;
    if (roles.length === 0 && userRoles) {
      return userRoles.split(',').some((role) => role.match(name));
    } else {
      return roleIds && roleIds.split(',')
        .map((roleId) => roles.find((role) => role.id === roleId))
        .some((role) => role && role.name.match(name));
    }
  }

  createHeaderData = () => {
    const { t } = this.props;
    const headerData = [
      {
        label: 'School',
        sortKey: '', // 'institutionName',
        filterKey: '',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: t('className', 'Class Name'),
        sortKey: '', // 'name',
        filterKey: '',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: 'Display Name',
        sortKey: '', // 'displayName',
        filterKey: '',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: 'Status',
        sortKey: '', // 'archived',
        filterKey: '',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: 'Lead Teacher',
        sortKey: '', // 'leadTeacherName',
        filterKey: '',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        /* 'View' button placeholder */
        label: '',
        sortKey: '',
        filterKey: ''
      }
    ];
    return headerData;
  }

  createBodyData = (classroomData) => {
    const classroomRows = [];
    if (classroomData && classroomData.length > 0) {
      classroomData.map((classroom) => {
        const classroomRow = [
          {
            /* School */
            columnName: 'institutionName',
            columnText: classroom.institutionName,
            columnClassName: 'truncate-long',
            columnOnClick: null,
            columnButtonOnClick: null,
            showTooltip: true,
            showTootipEvent: 'click'
          },
          {
            /* Class Name */
            columnName: 'className',
            columnText: classroom.name,
            columnClassName: 'truncate-long',
            columnOnClick: null,
            columnButtonOnClick: null,
            showTooltip: true,
            showTootipEvent: 'click'
          },
          {
            /* Display Name */
            columnName: 'displayName',
            columnText: (classroom.nickname) ? classroom.nickname : '',
            columnClassName: 'truncate-long',
            columnOnClick: null,
            columnButtonOnClick: null,
            showTooltip: true,
            showTootipEvent: 'click'
          },
          {
            /* Status */
            columnName: 'status',
            columnText: classroom.archived ? 'Archived' : 'Active',
            columnClassName: 'truncate-tiny',
            columnOnClick: null,
            columnButtonOnClick: null,
            showTooltip: true,
            showTootipEvent: 'click'
          },
          {
            /* Lead Teacher */
            columnName: 'leadTeacher',
            columnText: classroom.leadTeacherName,
            columnClassName: 'truncate-long',
            columnOnClick: null,
            columnButtonOnClick: null,
            showTooltip: true,
            showTootipEvent: 'click'
          },
          {
            /* 'View' button placeholder */
            columnName: null,
            columnText: 'View',
            columnClassName: '',
            columnOnClick: null,
            columnButtonOnClick: () => this.gotoUserClassRoster(classroom)
          }
        ];
        classroomRows.push(classroomRow);
      }, this);
    }

    return classroomRows;
  }

  handleClassroomOpenModal = (dialogName) => {
    const { dialogManager, adminUsersManager } = this.props;
    const userId = adminUsersManager?.currentUser?.id;
    dialogManager.setOpenDialog(dialogName, { userId },
      async (_event, _data, response, _institutionId) => {
        dialogManager.closeDialog(dialogName);
        if (response) {
          this.setState({ isAdminUserProfileLoading: true });
          UserService.setSelectedDropdownInstitutionId(null);
          setTimeout(async () => {
            await (userId && adminUsersManager.fetchUserClassrooms(userId));
            this.setState({ isAdminUserProfileLoading: false });
          }, 1500);
        }
      }
    );
  }

  handleAddJoinClassroomModal = () => {
    const { dialogManager } = this.props;

    const buttonKey = UserService.getAddOrJoinClassButtonKeyForAdmins({ managerName: 'adminUsersManager' });

    const { ADD_CLASS, ADD_TEACHER_TO_CLASSROOMS } = DIALOG_NAMES;

    if (buttonKey === 'addClass') {
      this.handleClassroomOpenModal(ADD_CLASS);
    } else if (buttonKey === 'joinClass') {
      this.handleClassroomOpenModal(ADD_TEACHER_TO_CLASSROOMS);
    } else if (buttonKey === 'addJoinClass') {
      dialogManager.setOpenDialog(DIALOG_NAMES.ADD_JOIN_CLASS, {
        openAddModal: () => this.handleClassroomOpenModal(ADD_CLASS),
        openJoinModal: () => this.handleClassroomOpenModal(ADD_TEACHER_TO_CLASSROOMS)
      });
    }
  }

  render() {
    const { adminUsersManager, classroomManager, history, t, userManager } = this.props;
    const { changesHappened, isAdminUserProfileLoading } = this.state;

    const { Avatar, BreadCrumbs, FilteredHeaderTable } = this;

    const isSsoUser = !!adminUsersManager.currentUser?.ssoUser;

    const buttonKey = UserService.getAddOrJoinClassButtonKeyForAdmins({ managerName: 'adminUsersManager' });

    let enrolledTableBodyData = [];
    if (adminUsersManager.currentUserEnrolledClassrooms) {
      enrolledTableBodyData = this.createBodyData(adminUsersManager.currentUserEnrolledClassrooms);
    }

    let teachingTableBodyData = [];
    if (adminUsersManager.currentUserTeachingClassrooms) {
      teachingTableBodyData = this.createBodyData(adminUsersManager.currentUserTeachingClassrooms);
    }

    let profileImageUrl;
    if (adminUsersManager && adminUsersManager.currentUser) {
      const { profileImage } = adminUsersManager.currentUser;
      profileImageUrl = userManager.getFullUrlFromName(profileImage);
    }

    return isAdminUserProfileLoading ? <Loader active /> : (
      <div className='admin-user-profile-page'>
        <div className='header-nav'>
          <Container className='bread-crumb-wrapper top' fluid>
            <BreadCrumbs history={history} />
          </Container>
        </div>
        <div className='admin-user-profile-content'>
          <div className='profile-container'>
            <div className='profile-header'>
              <div className='avatar-controls'>
                <Avatar className='profile-avatar' fullProfileImageUrl={profileImageUrl} />
                {/* TODO */}
                {/* { !isSsoUser &&
                  <Button size='mini' className='avatar-button' onClick={this.toggleAvatarEditor}>Edit</Button>
                } */}
              </div>
            </div>
            <div className='profile-fields'>
              <div className='admin-user-profile-header'>
                <div className='header-title-text'>
                  {adminUsersManager.currentUser.displayName}
                </div>
                <div className='modal-title-button'>
                  {/* <Button primary className={''} onClick={() => this.closeProfileDialog(false)}>Cancel</Button> */}
                  {!isSsoUser &&
                  <Button className='' disabled={!changesHappened} onClick={() => this.verifyChanges()} primary>Save</Button>}
                </div>
              </div>
              <div className='profile-fields-row'>
                <div className='profile-field'>
                  <div className='profile-field-txt'>{t('fieldUserId')}</div>
                  <Input
                    disabled={true}
                    name='userId'
                    placeholder={t('fieldUserId')}
                    type='text'
                    value={(isSsoUser) ? adminUsersManager.currentUser.ssoId || '' : adminUsersManager.currentUser.id || ''} />
                </div>
                <div className='profile-field'>
                  <div className='profile-field-txt'>{t('fieldStatus')}</div>
                  <Dropdown
                    disabled={isSsoUser}
                    name='Status'
                    onChange={this.handleChangeEnabled}
                    options={[
                      {
                        key: 'active',
                        text: 'Active',
                        value: true
                      },
                      {
                        key: 'inactive',
                        text: 'Inactive',
                        value: false
                      }]}
                    selection
                    value={adminUsersManager.currentUser.enabled} />
                </div>
              </div>
              <div className='profile-fields-row'>
                <div className='profile-field'>
                  <div className='profile-field-txt'>{t('fieldUserRole')}</div>
                  {this.renderRoleNames(adminUsersManager.currentUser.roleIds)}
                </div>
              </div>
              <div className='profile-fields-row'>
                <div className='profile-field'>
                  <div className='profile-field-txt'>{t('fieldFirstName')}</div>
                  <Input
                    disabled={isSsoUser}
                    name='firstName'
                    onChange={this.handleChangeFirstName}
                    placeholder={t('fieldFirstName')}
                    type='text'
                    value={adminUsersManager.currentUser.firstName || ''} />
                </div>
                <div className='profile-field'>
                  <div className='profile-field-txt'>{t('fieldLastName')}</div>
                  <Input
                    disabled={isSsoUser}
                    name='lastName'
                    onChange={this.handleChangeLastName}
                    placeholder='Last Name'
                    type='text'
                    value={adminUsersManager.currentUser.lastName || ''} />
                </div>
              </div>
              <div className='profile-fields-row'>
                <div className='profile-field fullwidth'>
                  <div className='profile-field-txt'>{t('username', 'Username/email')}</div>
                  <Input
                    disabled={isSsoUser}
                    onChange={this.handleChangeUsername}
                    placeholder={t('username', 'Username/email')}
                    type='text'
                    value={adminUsersManager.currentUser.username || ''} />
                </div>
              </div>
              {!isSsoUser && (
                <div className='profile-fields-row'>
                  <div className='profile-field'>
                    <div className='profile-field-txt'>{t('fieldPassword')}</div>
                    <Button basic className='profile-change-password-btn'
                      onClick={() => this.onClickOpenModal()} primary>
                      {t('fieldChangePassword')}
                    </Button>
                  </div>
                </div>
              )}
              <div className='profile-fields-row'>
                <div className='profile-field'>
                  <div className='profile-field-txt'>{t('fieldDateCreated')}</div>
                  <Input
                    disabled={true}
                    name='creationDate'
                    type='text'
                    value={adminUsersManager.currentUser.creationDate || ''} />
                </div>
                {/* TODO commented out per CF-2501
                  <div className='profile-field'>
                    <div className='profile-field-txt'>Last Login</div>
                    <Input
                      disabled={true}
                      name='lastLogin'
                      type='text'
                      value={adminUsersManager.currentUser.lastLogin || ''} />
                  </div> */}
                {!isSsoUser && (
                  <div className='profile-field'>
                    <div className='profile-field-txt'>{t('fieldFailedAttempts')}</div>
                    <Input
                      disabled={true}
                      name='failedAttempts'
                      type='text'
                      value={adminUsersManager.currentUser.loginAttempts || 0} />
                  </div>
                )}
              </div>
              <div className='profile-fields-row'>
                <div className='profile-field'>
                  <div className='profile-field-txt'>{t('fieldUserTimezone')}</div>
                  <Dropdown
                    disabled={isSsoUser}
                    name='User Timezone'
                    onChange={this.handleChangeTimezoneId}
                    options={adminUsersManager.timezoneArray}
                    selection
                    value={adminUsersManager.currentUser.timezoneId || ''} />
                </div>
                <div className='profile-field'>
                  <div className='profile-field-txt'>{t('fieldSchoolTimezone')}</div>
                  <Input
                    disabled={true}
                    name='schoolTimezone'
                    type='text'
                    value={adminUsersManager.currentUser.institutionTimezoneId || ''} />
                </div>
              </div>
              <div ref={this.messageWrapperRef} className='messageWrapperRef'>
                {this.state.saved && (
                  <Message positive>
                    <Message.Header>{t('successMessageHeader')}</Message.Header>
                    <p>
                      {t('successMessage')}
                    </p>
                  </Message>
                )}
                {this.state.error && (
                  <Message negative>
                    <Message.Header>{t('errorMessageHeader')}</Message.Header>
                    <p>
                      {this.state.errorMessage}
                    </p>
                  </Message>
                )}
              </div>
              {adminUsersManager.currentUserEnrolledClassrooms &&
                adminUsersManager.currentUserEnrolledClassrooms.length > 0 && (
                <div className='profile-fields-row'>
                  <div className='profile-field fullwidth topMargin'>
                    <div className='profile-field-txt'>{t('classesEnrolledLabel', 'Classes Enrolled in')}</div>
                    <div className='profile-classes-container'>
                      <div className='tableContainer'>
                        <FilteredHeaderTable
                          tableBodyData={enrolledTableBodyData}
                          tableHeaderData={this.createHeaderData()} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!userManager.isSsoUser && this.userRoleIs(/Student/i) && (
                <div className='profile-fields-row'>
                  <Button className='add-student-to-class-button'
                    onClick={() => this.handleClassroomOpenModal(DIALOG_NAMES.ADD_STUDENT_TO_CLASSROOMS)}
                    primary type='button'>
                    {t('addStudentToClassButton', 'Add Student to Class')}
                  </Button>
                </div>
              )}
              {adminUsersManager.currentUserTeachingClassrooms &&
                adminUsersManager.currentUserTeachingClassrooms.length > 0 && (
                <div className='profile-fields-row'>
                  <div className='profile-field fullwidth topMargin'>
                    <div className='profile-field-txt'>Classes Teaching</div>
                    <div className='profile-classes-container'>
                      <div className='tableContainer'>
                        <FilteredHeaderTable
                          handleSort={this.handleSort}
                          tableBodyData={teachingTableBodyData}
                          tableHeaderData={this.createHeaderData()} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {(!userManager.isSsoUser || classroomManager.allowSsoCreateSelfRegClassroom) &&
                this.userRoleIs(/Teacher/i) && (
                <div className='profile-fields-row'>
                  <PopupButton buttonLabel={t(buttonKey)} className='add-class-button button-directly-on-background'
                    disabled={!this.state.originalEnabled} onClick={this.handleAddJoinClassroomModal}
                    popupMsg={this.state.originalEnabled ? '' : 'Teacher is inactive'} primary={true} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SatCoreRegister('AdminUserProfile', AdminUserProfile);
