import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { withRouter } from 'react-router-dom';

import classNames from 'classnames';

import { Image, Label, Menu, Popup } from 'semantic-ui-react';

import iconHelp from '../img/icon-help.svg';

import '../css/TopNav.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { VIEW_SELECTION } from '../managers/NavigationManager';

import {
  ADMIN_TOP_NAV,
  DEFAULT_ADMIN_TOP_NAV_ORDER_ARRAY,
  DEFAULT_DISTRICT_ADMIN_TOP_NAV_ORDER_ARRAY,
  DEFAULT_SCHOOL_ADMIN_TOP_NAV_ORDER_ARRAY,
  DISTRICT_ADMIN_TOP_NAV,
  SCHOOL_ADMIN_TOP_NAV,
  TOP_NAV_NAME
} from '../constants';

import InitService from '../services/InitService';
import PopupService from '../services/PopupService';
import TopNavService from '../services/TopNavService';
import UserService from '../services/UserService';

export default
@inject(
  'appManager',
  'contentManager',
  'dynamicSatelliteManager',
  'filteredHeaderTableManager',
  'navigationManager',
  'notificationManager',
  'productManager',
  'schoolManager',
  'topNavManager',
  'userManager')
@observer
class AdminTopNav extends Component {
  constructor() {
    super();
    this.LogoButtons = SatCoreComponent('LogoButtons');
    this.ProfileMenu = SatCoreComponent('ProfileMenu');
    this.menuBarRef = React.createRef();
  }

  componentDidMount() {
    const { appManager, dynamicSatelliteManager, history, schoolManager, topNavManager, userManager } = this.props;

    const { isDistrictOrSchoolAdmin, isDistrictAdmin } = userManager;

    topNavManager.setActiveTopNavName(TOP_NAV_NAME.AdminTopNav);

    schoolManager.setSearchText('');

    InitService.initActiveUserRoleAsHtmlBodyAttr();

    if (dynamicSatelliteManager.isDynamicSatellite && isDistrictOrSchoolAdmin) {
      // send user to a specified custom 'main' nav tab if the following conditions are true:
      // (1) user has the proper satellite and publisher permissions to see the given tab (i.e. the tab is in the displayed list), and
      // (2) user is on currently on the dashboard tab (i.e. likely via redirection after login), and
      // (3) user has likely not manually clicked on the 'dashboard' nav tab since login (i.e. ?view='dashboard' param is absent), and
      // (4) satelliteLayoutConfig has a 'mainTopNavId' that is **not** the dashboard ('default')
      const USER_TOP_NAV = isDistrictAdmin ? DISTRICT_ADMIN_TOP_NAV : SCHOOL_ADMIN_TOP_NAV;

      const { districtAdminMainTopNavId, schoolAdminMainTopNavId } = dynamicSatelliteManager;

      const mainTopNavId = isDistrictAdmin ? districtAdminMainTopNavId : schoolAdminMainTopNavId;

      const topNavObj = USER_TOP_NAV[mainTopNavId];

      if (topNavObj?.functionKey) {
        const pathname = window?.location?.pathname;
        const urlParams = new URLSearchParams(window.location.search);
        if (mainTopNavId !== 'default' && pathname === '/' && urlParams?.get?.('view') !== VIEW_SELECTION.DASHBOARD) {
          this[topNavObj?.functionKey]?.();
        }
      }
    }

    this.unlisten = history?.listen?.((location, _action) => {
      appManager.setLocation(location);
    });
  }

  componentWillUnmount() {
    InitService.removeHtmlBodyRoleAttr();
    this.unlisten?.();
  }

  handleClickProfile = () => {
    const { history, navigationManager } = this.props;
    navigationManager.clearAllPaths();
    history.push('/profile', { url: history.createHref(history.location) });
  }

  handleClickSchools = async () => {
    const {
      filteredHeaderTableManager, history,
      navigationManager, schoolManager
    } = this.props;
    navigationManager.setView(VIEW_SELECTION.DASHBOARD);
    navigationManager.clearAllPaths();
    schoolManager.setSearchText('');
    filteredHeaderTableManager.clearAll();
    await history.push(`/?view=${VIEW_SELECTION.DASHBOARD}`);
  }

  handleClickUsers = async () => {
    const {
      filteredHeaderTableManager, history,
      navigationManager, schoolManager
    } = this.props;
    navigationManager.setView(VIEW_SELECTION.USERS);
    navigationManager.clearAllPaths();
    filteredHeaderTableManager.clearAll();
    schoolManager.setSearchText('');
    await history.push(`/users?view=${VIEW_SELECTION.USERS}`);
  }

  handleClickContent = async () => {
    const {
      filteredHeaderTableManager, history,
      navigationManager, schoolManager, userManager
    } = this.props;
    if (userManager.canViewSatelliteContent) {
      navigationManager.setView(VIEW_SELECTION.CONTENT);
      navigationManager.clearAllPaths();
      schoolManager.setSearchText('');
      filteredHeaderTableManager.clearAll();
      await history.push(`/content?view=${VIEW_SELECTION.CONTENT}`);
    } else {
      await history.replace('/');
    }
  }

  handleClickProducts = async () => {
    const {
      filteredHeaderTableManager, history,
      navigationManager, schoolManager
    } = this.props;
    navigationManager.setView(VIEW_SELECTION.PRODUCTS);
    navigationManager.clearAllPaths();
    schoolManager.setSearchText('');
    filteredHeaderTableManager.clearAll();
    await history.push(`/products?view=${VIEW_SELECTION.PRODUCTS}`);
  }

  handleClickClassrooms = async () => {
    const {
      filteredHeaderTableManager, history,
      navigationManager, schoolManager
    } = this.props;
    navigationManager.setView(VIEW_SELECTION.CLASSROOMS);
    navigationManager.clearAllPaths();
    schoolManager.setSearchText('');
    filteredHeaderTableManager.clearAll();
    await history.push(`/classrooms?view=${VIEW_SELECTION.CLASSROOMS}`);
  }

  handleClickTestBuilder = async () => {
    const {
      filteredHeaderTableManager, history,
      navigationManager, schoolManager, userManager
    } = this.props;
    if (userManager.canViewSatelliteTestBuilder) {
      navigationManager.setView(VIEW_SELECTION.TEST_BUILDER);
      navigationManager.clearAllPaths();
      schoolManager.setSearchText('');
      filteredHeaderTableManager.clearAll();
      await history.push(`/adminTestbuilder?view=${VIEW_SELECTION.TEST_BUILDER}`);
    }
  }

  handleClickReports = async () => {
    const {
      filteredHeaderTableManager,
      history,
      navigationManager,
      schoolManager
    } = this.props;
    if (navigationManager.viewSelection === VIEW_SELECTION.REPORTS) {
      await history.push('/');
    }
    navigationManager.setView(VIEW_SELECTION.REPORTS);
    navigationManager.clearAllPaths();
    schoolManager.setSearchText('');
    filteredHeaderTableManager.clearAll();
    await history.push(`/reports?view=${VIEW_SELECTION.REPORTS}`);
  }

  showHelpWindow = async () => {
    const { contentManager } = this.props;
    if (contentManager.helpWindowUrl !== null) {
      window.open(contentManager.helpWindowUrl, '_blank');
    }
  }

  showNotifications = () => {
    const { history } = this.props;
    history.push(`/notifications?view=${VIEW_SELECTION.NOTIFICATIONS}`);
  }

  showResources = async () => {
    const { history, navigationManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.RESOURCES);
    await history.push(`/resources?view=${VIEW_SELECTION.RESOURCES}`);
  }

  /**
   * Per **DEMO-2504**, to better ensure the Notifications count in AdminTopNav is current,
   *
   * we are now fetching notifications whenever the active AdminTopNav section changes.
   */
  handleFetchNotifications = async () => {
    const { notificationManager, userManager } = this.props;
    await notificationManager.fetchManualNotifications({ page: 0, userId: userManager.userId });
    await notificationManager.fetchSystemNotifications({ page: 0, userId: userManager.userId });
  }

  handleLogout = async () => {
    const { history, navigationManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.LOGIN);
    InitService.doLogoutCleanUp();
    history.replace('/signin');
  }

  isViewSelected = (view) => {
    const { navigationManager: { viewSelection } } = this.props;
    return viewSelection !== null && viewSelection === view;
  }

  isViewSelectedClass = (view) => (this.isViewSelected(view) ? 'selected' : null);

  renderMenuItem = (children, view, props, {
    _id,
    /* navTranslationKey = '', */
    navTranslationRolloverKey = ''
  } = {}) => {
    const { productManager, t } = this.props;

    const rolloverMessage = t(navTranslationRolloverKey);

    return (
      PopupService.renderPopup({
        content: rolloverMessage,
        disabled: !rolloverMessage,
        key: _id,
        trigger: (
          <Menu.Item
            aria-selected={!!this.isViewSelectedClass(view)}
            as='li'
            role='tab'
            tabIndex='0'
            {...props}
            className={['tnav-tab', this.isViewSelectedClass(view), (props || {}).className].filter((className) => className).join(' ')}
            onClick={async () => {
              await this.handleFetchNotifications();
              if (productManager.shouldShowExpiredLicenseWarning) {
                productManager.setShouldShowExpiredLicenseWarning(false);
              }
              await props.onClick();
            }}>
            {children}
          </Menu.Item>
        ),
        wide: false
      })
    );
  }

  getNavTabs = () => {
    const {
      dynamicSatelliteManager,
      userManager: {
        canViewSatelliteContent, canViewSatelliteProducts, canViewSatelliteTestBuilder,
        isSchoolAdmin, isDistrictAdmin, isProductAdmin
      },
      t
    } = this.props;
    const { props } = this;
    const showTestBuilderTab = canViewSatelliteTestBuilder;
    const showContentTab = canViewSatelliteContent;
    const showProductsTab = canViewSatelliteProducts;

    let tabNavOrderArray, USER_TOP_NAV;
    if (isDistrictAdmin) {
      tabNavOrderArray = (dynamicSatelliteManager.isDynamicSatellite ? (
        dynamicSatelliteManager.districtAdminTopNavOrderArray
      ) : DEFAULT_DISTRICT_ADMIN_TOP_NAV_ORDER_ARRAY) || [];
      USER_TOP_NAV = DISTRICT_ADMIN_TOP_NAV;
    } else if (isSchoolAdmin) {
      tabNavOrderArray = (dynamicSatelliteManager.isDynamicSatellite ? (
        dynamicSatelliteManager.schoolAdminTopNavOrderArray
      ) : DEFAULT_SCHOOL_ADMIN_TOP_NAV_ORDER_ARRAY) || [];
      USER_TOP_NAV = SCHOOL_ADMIN_TOP_NAV;
    } else {
      tabNavOrderArray = DEFAULT_ADMIN_TOP_NAV_ORDER_ARRAY;
      USER_TOP_NAV = ADMIN_TOP_NAV;
    }

    let extraNavTabs = <></>;
    if (props.getNavTabs !== undefined) {
      extraNavTabs = props.getNavTabs(this.props, this);
    }

    return (
      <>
        {tabNavOrderArray.map((tabNavId) => {
          const tabNavObj = USER_TOP_NAV[tabNavId];

          const { _id, className, functionKey, viewSelectionKey } = tabNavObj;

          let { navTranslationKey } = tabNavObj;

          switch (_id) {
            case USER_TOP_NAV.classrooms._id:
              if (isProductAdmin) {
                return;
              }
              break;
            case USER_TOP_NAV.libraryContent._id:
              if (!showContentTab) {
                return;
              }
              // Content and Products tab show the same data in different format, preferred label is Products for both.
              navTranslationKey = showProductsTab ? 'contentLabel' : 'productsLabel';
              break;
            case USER_TOP_NAV.products._id:
              if (!showProductsTab) {
                return;
              }
              break;
            case USER_TOP_NAV.reports._id:
              if (!isDistrictAdmin && !isSchoolAdmin) {
                return;
              }
              break;
            case USER_TOP_NAV.schools._id:
              break;
            case USER_TOP_NAV.testBuilder._id:
              if (!showTestBuilderTab) {
                return;
              }
              break;
            case USER_TOP_NAV.users._id:
              if (isProductAdmin) {
                return;
              }
              break;
          }
          return this.renderMenuItem(t(navTranslationKey), VIEW_SELECTION[viewSelectionKey], {
            className, onClick: this[functionKey]
          }, {
            ...(tabNavObj || {})
            // navTranslationKey, navTranslationRolloverKey
          });
        }).filter((tabNavObj) => !!tabNavObj)}
        {extraNavTabs}
        {this.getAdditionalTopNavExternalResources()}
      </>
    );

    // TODO remove
    // return (
    //   <>
    //     {this.renderMenuItem(
    //       t('DashboardLabel', 'Schools'), VIEW_SELECTION.DASHBOARD, { className: 'schools', onClick: this.handleClickSchools },
    //       { navTranslationKey: 'DashboardLabel', navTranslationRolloverKey: 'DashboardRollover' }
    //     )}
    //     {!isProductAdmin && this.renderMenuItem(
    //       t('UsersLabel', 'Users'), VIEW_SELECTION.USERS, { className: 'users', onClick: this.handleClickUsers },
    //       { navTranslationKey: 'UsersLabel', navTranslationRolloverKey: 'UsersRollover' }
    //     )}
    //     {showProductsTab && this.renderMenuItem(
    //       t('productsLabel', 'Products'), VIEW_SELECTION.PRODUCTS, { className: 'products', onClick: this.handleClickProducts },
    //       { navTranslationKey: 'productsLabel', navTranslationRolloverKey: 'productsRollover' }
    //     )}
    //     {showContentTab && this.renderMenuItem(
    //       contentTabLabel, VIEW_SELECTION.CONTENT, { className: 'content', onClick: this.handleClickContent },
    //       { navTranslationKey: 'contentLabel', navTranslationRolloverKey: 'contentRollover' }
    //     )}
    //     {!isProductAdmin && this.renderMenuItem(
    //       t('classesLabel', 'Classes'), VIEW_SELECTION.CLASSROOMS, { className: 'classes', onClick: this.handleClickClassrooms },
    //       { navTranslationKey: 'classesLabel', navTranslationRolloverKey: 'classesRollover' }
    //     )}
    //     {showTestBuilderTab && this.renderMenuItem(
    //  eslint-disable-next-line max-len
    //       t('testBuilderLabel', 'Test Builder'), VIEW_SELECTION.TEST_BUILDER, { className: 'library', onClick: this.handleClickTestBuilder },
    //       { navTranslationKey: 'testBuilderLabel', navTranslationRolloverKey: 'testBuilderRollover' }
    //     )}
    //     {isAdmin && this.renderMenuItem(
    //       t('ReportsLabel', 'Reports'), VIEW_SELECTION.REPORTS, { className: 'reports', onClick: this.handleClickReports },
    //       { navTranslationKey: 'ReportsLabel', navTranslationRolloverKey: 'ReportsRollover' }
    //     )}
    //     {extraNavTabs}
    //   </>
    // );
  }

  getHelpLink = () => {
    const { contentManager } = this.props;
    if (contentManager.helpWindowUrl !== undefined && contentManager.helpWindowUrl !== null && contentManager.helpWindowUrl !== '') {
      return this.renderMenuItem(
        <Image alt='' aria-label='Help link' role='tab' src={iconHelp} />, null, { className: 'help-nav', onClick: this.showHelpWindow });
    }
  }

  getAdditionalTopNavExternalResources = () => {
    const { t, topNavManager } = this.props;

    const { additionalAdminTopNavExternalResources } = topNavManager;

    if (!Array.isArray(additionalAdminTopNavExternalResources) || !additionalAdminTopNavExternalResources?.length) {
      return null;
    }

    return additionalAdminTopNavExternalResources.map((tabNavObj) => {
      if (typeof tabNavObj !== 'object' || !tabNavObj?._id) {
        return;
      }

      const className = tabNavObj.className || classNames('resources', tabNavObj._id);
      const navTranslationKey = tabNavObj.navTranslationKey || tabNavObj._id;
      const viewSelectionKey = tabNavObj.viewSelectionKey || 'resources';

      const handleClickExternalResource = () => {
        if (typeof tabNavObj.url === 'string') {
          window.open(tabNavObj.url);
        }
      };

      return this.renderMenuItem(t(navTranslationKey), VIEW_SELECTION[viewSelectionKey], {
        className, onClick: handleClickExternalResource
      }, {
        ...(tabNavObj || {}),
        className,
        navTranslationKey,
        viewSelectionKey
      });
    }).filter((obj) => obj);
  }

  getNotificationLink = () => {
    const { notificationManager, t, topNavManager } = this.props;
    if (notificationManager.totalNotificationCount > 0) {
      return this.renderMenuItem(
        topNavManager.showFullNotificationBanner ? (
          <div className='notification-count-wrapper notification-count'>
            <div>
              {`Notifications (${notificationManager.topNavNotificationCountLabel})`}
            </div>
          </div>
        ) : (
          <Popup
            hideOnScroll={false}
            hoverable={true}
            on='hover'
            position='bottom center'
            trigger={(
              <div className='notification-count-wrapper notification-count'>
                <Label circular>
                  {notificationManager.topNavNotificationCountLabel}
                </Label>
              </div>
            )}
            wide>
            <div key={1} className='card-value'>{t('notificationsLabel')}</div>
          </Popup>
        ),
        null,
        { className: 'help-nav', onClick: this.showNotifications }
      );
    }
  }

  renderProfileMenu = () => {
    const { ProfileMenu, props: { history, profileMenu, userManager, t } } = this;
    const { profile = true, items, logout = true } = profileMenu || {};

    const { allowSwitchRole } = userManager;
    const hasMultipleRoles = UserService.getAvailableRoleCount() > 1;

    let options = [logout && { onClick: this.handleLogout, text: t('logout') }];
    if (UserService.hasActivePermissionId()) {
      options = [
        profile && { onClick: this.handleClickProfile, text: t('profile') },
        allowSwitchRole && hasMultipleRoles && { onClick: () => UserService.showRoleSwitcher(history), text: t('switchRole') },
        ...(items || []),
        logout && { onClick: this.handleLogout, text: t('logout') }
      ];
    }

    return (
      <ProfileMenu
        {...profileMenu}
        options={options} />
    );
  }

  render() {
    const { additionalClassName = '', history, location } = this.props;
    const { LogoButtons } = this;

    let topNavClassName = 'topNav';
    topNavClassName += additionalClassName ? ` ${additionalClassName}` : '';
    return (
      <>
        <Menu
          as={(props) => <header {...props} ref={this.menuBarRef} />}
          className={topNavClassName}
          fixed='top'
          inverted
          onKeyDown={(e) => TopNavService.topNavHandleKeyDown(e, this.menuBarRef)}
          role='tablist'>
          <LogoButtons history={history} location={location} role='tab' />
          <Menu.Menu
            aria-label='Admin navigation panel'
            as={(props) => <nav {...props} />}
            position='right'
            role='navigation'>
            <ul className='nav-list' role='tablist'>
              {this.getNavTabs()}
              {this.getHelpLink()}
              {this.getNotificationLink()}
            </ul>
            {this.renderProfileMenu()}
          </Menu.Menu>
        </Menu>
      </>
    );
  }
}

SatCoreRegister('AdminTopNav', withRouter(AdminTopNav));
