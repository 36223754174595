/* eslint-disable sort-keys */
import { registerClass } from '../SatCoreRegistry';

export default class ItemBankService {

  // Placeholder method that can be overwritten by other satellites to sort the kebab options.
  static sortLibraryCardKebabOptions = (options) => {
    return options;
  }
}

registerClass('ItemBankService', ItemBankService);
