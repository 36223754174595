/**
 * Given a string of `words`,
 * return an uppercase string of first letter of first word AND first letter of last word.
 * If only one word is passed (i.e. no spaces), the first two letters of that word will be used.
 */
export const getInitials = (words = '') => {
  if (typeof words === 'string') {
    return words.match(/(^\S\S?|\b\S)?/g).join('')
      .match(/(^\S|\S$)?/g).join('')
      .toUpperCase();
  }
};
