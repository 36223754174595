import React, { useState } from 'react';
import { Button, Radio } from 'semantic-ui-react';
import Modal from '../Modal';
import '../../css/LTICourses.less';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

const AddLTIAssignmentModal = (props) => {
  const { closeAddAssignment, openAddAssignment, elementId, addAssignment } = props;
  const ModalBanner = SatCoreComponent('ModalBanner');

  const [studentReview, setStudentReview] = useState(true);
  const [addingAssignment, setAddingAssignment] = useState(false);

  const handleClose = () => {
    if (addingAssignment) {
      return;
    }

    closeAddAssignment();
  };

  const handleAddAssignment = async () => {
    if (addingAssignment) {
      return;
    }

    setAddingAssignment(true);
    await addAssignment(elementId, studentReview);
    setAddingAssignment(false);
    closeAddAssignment();
  };

  const toggleStudentReview = () => {
    setStudentReview((prevState) => {
      return !prevState;
    });
  };

  return (
    <Modal
      className='lti-assign'
      open={openAddAssignment}
      size='tiny'>
      <ModalBanner
        onClose={handleClose}
        title='Content will be added' />
      <Modal.Content>
        <div>Confirm selected content.</div>
        <Radio
          checked={studentReview}
          className='student-review'
          label='Students can review work'
          onChange={toggleStudentReview}
          toggle />
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          onClick={handleClose}
          primary>Cancel
        </Button>
        <Button
          disabled={addingAssignment}
          loading={addingAssignment}
          onClick={handleAddAssignment}
          primary>Add
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default AddLTIAssignmentModal;
SatCoreRegister('AddLTIAssignmentModal', AddLTIAssignmentModal);
