import React from 'react';
import { Image } from 'semantic-ui-react';

// TODO remove // import defaultLogo from '../img/Logo.svg';

import { SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

const Logo = ({ ariaLabel, history, logoImg, logoUrl, navButton }) => {
  return (
    <>
      {navButton ? (
        <Image
          alt=''
          as={(props) => (
            <button type='button' {...props} aria-label={ariaLabel || 'Return to dashboard button'} role='tab' />
          )}
          className='logo'
          onClick={() => {
            if (logoUrl) {
              window.open(logoUrl, '_blank', 'noopener,noreferrer');
            } else {
              history.push('/');
            }
          }}
          src={logoImg || Auth.logo/* || defaultLogo */} />
      ) : (
        <Image alt='' as='div' className='logo' src={logoImg || Auth.logo/* || defaultLogo */} />
      )}
    </>
  );
};

export default Logo;

SatCoreRegister('Logo', Logo);
