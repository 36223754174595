import { capitalize, truncate } from 'lodash';

import { getRegisteredClass, registerClass } from '../../SatCoreRegistry';

import reportContextManager from '../../managers/reports/ReportContextManager';
import reportIdentityManager from '../../managers/reports/ReportIdentityManager';
import reportOverrideManager from '../../managers/reports/ReportOverrideManager';
import reportStandardsManager from '../../managers/reports/ReportStandardsManager';
import reportTableManager from '../../managers/reports/ReportTableManager';

import { flattenChildren, renameKeys, stripHtmlTags } from '../../utils';

import { REPORT_ATTRIBUTE_FILTER_TYPE } from './ReportConstants';

import ReportJsonHelperService from './ReportJsonHelperService';
import ReportScoreService from './ReportScoreService';
import ReportStandardsClassroomService from './ReportStandardsClassroomService';
import ReportStandardsDistrictService from './ReportStandardsDistrictService';
import ReportStandardsSchoolService from './ReportStandardsSchoolService';

export default class ReportStandardsService {
  /** Intended to be called via `ReportTypeService.initReportTableData` */
  static _initReportTableData = async () => {
    const { isFacultyClassroomReport } = reportIdentityManager;
    const { allowUseCmapId } = reportOverrideManager;
    const { selectedReportCmapContentItemId } = reportStandardsManager;

    if (allowUseCmapId && isFacultyClassroomReport && !selectedReportCmapContentItemId) {
      await this.setDefaultReportCmapContentItemId();
    }
    const tableData = this.getAvailableReportStandards();
    reportTableManager.setReportTableData(tableData);
  }

  static setDefaultReportCmapContentItemId = async () => {
    const defaultReportCmapContentItemId = reportStandardsManager.reportCmapContentItemIds[0];
    const defaultReportCmapObj = reportStandardsManager.reportCmapObjs[0];
    reportStandardsManager.setSelectedReportCmapInfo(defaultReportCmapContentItemId, defaultReportCmapObj);
  }

  /** Intended to be called via `ReportTypeService.initReportTableScoreSummaryBarCellConfig` */
  static _initReportTableScoreSummaryBarCellConfig = (initProps) => {
    const { renderScoreSummaryBarCell, renderScoreSummaryBarHeaderCell } = initProps;
    const { reportInfoClassNames } = reportIdentityManager;
    return [
      {
        /** `cell-score-summary-bar` */
        Cell: (props) => renderScoreSummaryBarCell(props),
        Header: (props) => renderScoreSummaryBarHeaderCell(props),
        accessor: (props) => {
          if (props.id === 'rollupAverageRow' || props.hasRollup) {
            return this._initReportTableScoreSummaryBarRollupCellConfig(props);
          } else {
            const standardId = props.id;
            const allScoreInfo = ReportJsonHelperService.REPORT_SCORE_INFO_BY_STANDARD_FOR_STUDENTS();
            const multiScoreInfoObj = allScoreInfo[standardId];
            const scoreSummaryBarData = ReportScoreService.getScoreSummaryData({ multiScoreInfoObj });
            return scoreSummaryBarData; // props.cell.value
          }
        },
        className: `cell-score-summary-bar ${reportInfoClassNames}`,
        id: 'score-summary-bar'
      }
    ];
  }

  /** Intended to be called via `ReportTypeService.initReportTableScoreSummaryBarCellConfig` */
  static _initReportTableScoreSummaryBarRollupCellConfig = (props) => {
    const allScoreInfo = ReportJsonHelperService.REPORT_SCORE_INFO_BY_STANDARD_FOR_STUDENTS();
    let flatStandards;
    if (props.id !== 'rollupAverageRow' && props.hasRollup) {
      flatStandards = props?.subRows ? flattenChildren(props.subRows) : [];
    } else {
      flatStandards = flattenChildren(this.getAvailableReportStandards(), 'subRows');
    }
    const scoreSummaryBarData = flatStandards.map((standard) => {
      const multiScoreInfoObj = allScoreInfo[standard.id];
      return ReportScoreService.getScoreSummaryData({ multiScoreInfoObj });
    }).filter((data) => data).reduce((previous, current) => {
      return {
        mastering: previous.mastering + current.mastering,
        meeting: previous.meeting + current.meeting,
        // eslint-disable-next-line sort-keys
        approaching: previous.approaching + current.approaching,
        developing: previous.developing + current.developing,
        notEnoughData: previous.notEnoughData + current.notEnoughData
      };
    }, {
      mastering: 0,
      meeting: 0,
      // eslint-disable-next-line sort-keys
      approaching: 0,
      developing: 0,
      notEnoughData: 0
    });
    return scoreSummaryBarData;
  }

  /** Intended to be called via `ReportTypeService.initReportTableFacultyCellConfigArray` */
  static _initReportTableFacultyCellConfigArray = (props) => {
    const {
      isFacultyClassroomReport,
      isFacultyDistrictReport,
      isFacultySchoolReport
    } = reportIdentityManager;

    if (isFacultyDistrictReport) {
      return ReportStandardsDistrictService._initReportTableFacultyCellConfigArray(props);
    } else if (isFacultySchoolReport) {
      return ReportStandardsSchoolService._initReportTableFacultyCellConfigArray(props);
    } else if (isFacultyClassroomReport) {
      return ReportStandardsClassroomService._initReportTableFacultyCellConfigArray(props);
    }
  }

  static hasAvailableReportStandards = (reportResponseJson) => {
    return !!this.getAvailableReportStandards(reportResponseJson);
  }

  static getAvailableReportStandards = (reportResponseJson) => {
    const { allowUseCmapId } = reportOverrideManager;
    const { selectedReportAttributeFilterType } = reportStandardsManager;

    reportResponseJson = reportResponseJson || reportIdentityManager.reportResponseJson;

    let REPORT_STANDARDS = (reportResponseJson?.data[0]?.contentJson?.data?.standards) || [];
    REPORT_STANDARDS = [...REPORT_STANDARDS];

    let availableReportStandards = allowUseCmapId ? this.applyCmapFilterToReportTableData(REPORT_STANDARDS) : REPORT_STANDARDS;

    availableReportStandards = renameKeys({ children: 'subRows' }, availableReportStandards);

    availableReportStandards = selectedReportAttributeFilterType ?
      this.applyAttributeFilterToReportTableData(availableReportStandards) : availableReportStandards;

    const shouldShowSummaryBar = reportOverrideManager.shouldShowStandardsReportSummaryBar;

    availableReportStandards = shouldShowSummaryBar ?
      this.getRollupAverageScoreInfoObjs(availableReportStandards) : availableReportStandards;

    return availableReportStandards;
  }

  static getRollupAverageScoreInfoObjs = (tableData = null) => {
    if (!tableData?.length) {
      return [];
    }
    let cutScoreName = reportStandardsManager.selectedReportCmapObj?.cutScore || '';

    const rollupSums = tableData.map((tableRow) => {
      if (!cutScoreName && tableRow.cutScore) {
        cutScoreName = tableRow.cutScore;
      }
      // TODO remove, unused
      // if (reportOverrideManager.hasRollupAverageRowInReportResponseJson) {
      //   return tableRow.overallRollup;
      // } else {
      const flatChildren = flattenChildren(Array.isArray(tableRow) ? tableRow : [tableRow], 'subRows');
      let averageScoreCount = 0;
      const flatChildrenRollupSums = flatChildren.map((tableSubRow) => {
        let average = tableSubRow.averageScore || tableSubRow.classAverage;
        if (typeof average === 'number') {
          average = Math.round(average);
          averageScoreCount++;
        }
        return {
          average,
          // TODO unused // originalRow: tableSubRow,
          remaining: tableSubRow.remaining || 0,
          taken: tableSubRow.taken || 0,
          viewedTime: tableSubRow.averageViewedTime
        };
      }).filter((rollup) => rollup);
      const reducedFlatChildrenRollupSumObj = this.getRollupSumObj(flatChildrenRollupSums);
      const averageScoreDivisor = averageScoreCount || 1;
      const hasAverage = typeof reducedFlatChildrenRollupSumObj.average === 'number';

      const averageFlatChildrenRollupSumObj = {
        average: hasAverage ? reducedFlatChildrenRollupSumObj.average / averageScoreDivisor : undefined,
        remaining: reducedFlatChildrenRollupSumObj.remaining /* / divisor */,
        taken: reducedFlatChildrenRollupSumObj.taken /* / divisor */,
        viewedTime: reducedFlatChildrenRollupSumObj.viewedTime
      };
      return averageFlatChildrenRollupSumObj;
    }).filter((rollup) => rollup);

    const rollupSumObj = this.getRollupSumObj(rollupSums);
    rollupSumObj['cutScore'] = cutScoreName;

    const averageScoreDivisor = rollupSums?.reduce((previous, current) => {
      return previous + (current?.average ? 1 : 0);
    }, 0) || 1;

    const divisor = rollupSums?.length || 1;
    const rollupAverageObj = this.getRollupAverageObj(rollupSumObj, divisor, averageScoreDivisor);
    return [rollupAverageObj, ...tableData];
  }

  static getRollupSumObj = (rollupSums) => {
    let averageSum, remainingSum, takenSum, viewedTimeSum;
    for (const rollupSum of rollupSums) {
      const { average, remaining, taken, viewedTime } = rollupSum;
      if (typeof average === 'number') {
        averageSum = averageSum ? averageSum + average : average;
      }
      if (typeof remaining === 'number') {
        remainingSum = remainingSum ? remainingSum + remaining : remaining;
      }
      if (typeof taken === 'number') {
        takenSum = takenSum ? takenSum + taken : taken;
      }
      if (typeof viewedTime === 'number') {
        viewedTimeSum = viewedTimeSum ? viewedTimeSum + viewedTime : viewedTime;
      }
    }
    const rollupSumObj = {
      average: averageSum,
      remaining: remainingSum,
      taken: takenSum,
      viewedTime: viewedTimeSum
    };
    return rollupSumObj;
  }

  static getRollupAverageObj = (rollupSumObj, _divisor, averageScoreDivisor) => {
    let averageScore;
    if (typeof (rollupSumObj.average / averageScoreDivisor) === 'number') {
      averageScore = Math.round(rollupSumObj.average / averageScoreDivisor);
    }
    const rollupAverageObj = {
      averageScore,
      averageViewedTime: rollupSumObj.viewedTime, // admin reports
      id: 'rollupAverageRow',
      name: !reportContextManager.isContextUsageReport ? 'Summary Score' : 'Summary Usage',
      remaining: rollupSumObj.remaining/* / divisor */,
      taken: rollupSumObj.taken/* / divisor */,
      viewedTime: rollupSumObj.viewedTime // classroom reports
      // TODO unused
      // weightTotal: rollupSumObj.weightTotal / divisor,
      // weightedAverageTotal: rollupSumObj.weightedAverageTotal / divisor
    };
    return rollupAverageObj;
  }

  /**
   * @param {{}} props
   * @param {'taken' | 'remaining'} resourceCountType
   */
  static getCustomResourceCountValue = (props = {}, resourceCountType) => {
    if (!resourceCountType || !props) {
      return props?.value;
    } else if (props.hasRollup && props.id !== 'rollupAverageRow' && typeof props.overallRollup?.[resourceCountType] === 'number') {
      return props.overallRollup[resourceCountType];
    } else {
      return props[resourceCountType];
    }
  }

  static getCustomReportFacultyResultCellValue = (props) => {
    if (!props || !props.flatRows || !props.column?.id || !props.row?.original) {
      return props?.value || null;
    }
    if (props.row.original.id === 'rollupAverageRow') {
      return this._getReportFacultyResultCellValue_rollupAverage(props);
    }
    const { selectedReportAttributeFilterType } = reportStandardsManager;
    const { CLUSTERS, READINESS_AND_SUPPORTING, REPORTING_CATEGORY } = REPORT_ATTRIBUTE_FILTER_TYPE;
    switch (selectedReportAttributeFilterType) {
      case REPORTING_CATEGORY:
        return this._getReportFacultyResultCellValue_reportingCategory(props);
      case CLUSTERS:
        return this._getReportFacultyResultCellValue_clusters(props);
      case READINESS_AND_SUPPORTING:
        return this._getReportFacultyResultCellValue_readinessAndSupporting(props);
      default:
        return props.value;
    }
  }

  /** Intended to be called via `this.getCustomReportFacultyResultCellValue` */
  static _getReportFacultyResultCellValue_reportingCategory = (props) => {
    if (props?.row?.original?.hasRollup) {
      return this._getReportFacultyResultCellValue_rollupAverage(props, { ofAllTableRows: false });
    } else {
      return props.value;
    }
  }

  /** Intended to be called via `this.getCustomReportFacultyResultCellValue` */
  static _getReportFacultyResultCellValue_clusters = (props) => {
    if (props?.row?.original?.hasRollup) {
      return this._getReportFacultyResultCellValue_rollupAverage(props, { ofAllTableRows: false });
    } else {
      return props.value;
    }
  }

  /** Intended to be called via `this.getCustomReportFacultyResultCellValue` */
  static _getReportFacultyResultCellValue_readinessAndSupporting = (props) => {
    if (props?.row?.original?.hasRollup) {
      return this._getReportFacultyResultCellValue_rollupAverage(props, { ofAllTableRows: false });
    } else {
      return props.value;
    }
  }

  /**
   * Intended to be called via `this.getCustomReportFacultyResultCellValue`
   *
   * if `ofAllTableRows` is true, the overall average will be calculated using ALL table rows of the given column.
   *
   * else, the overall average will be calculated using only the columns of the current table row (i.e. props.row.original).
   */
  static _getReportFacultyResultCellValue_rollupAverage = (props, { ofAllTableRows = true } = {}) => {
    const { activeReportScorePropName } = reportIdentityManager;
    const allScoreInfo = ReportJsonHelperService.REPORT_SCORE_INFO_BY_STANDARD_FOR_STUDENTS();
    const facultyId = props.column.id;

    const tableRows = ofAllTableRows ? props.flatRows : flattenChildren(props.row.originalSubRows);

    const standardIds = props.row.original?.standardIds || tableRows.map((tableRow) => tableRow.original?.id || tableRow.id)
      .filter((standardId) => standardId !== 'rollupAverageRow');

    const sumRollupScoreInfoObj = {};
    let divisor = 0;

    for (const standardId of standardIds) {
      const scoreInfoObj = allScoreInfo[standardId] && allScoreInfo[standardId][facultyId];
      if (scoreInfoObj) {
        if (typeof scoreInfoObj[activeReportScorePropName] === 'number') {
          divisor++;
        }
        for (const prop in scoreInfoObj) {
          sumRollupScoreInfoObj[prop] = sumRollupScoreInfoObj[prop] ?
            (sumRollupScoreInfoObj[prop] + scoreInfoObj[prop]) : scoreInfoObj[prop];
        }
      }
    }
    const rollupScoreInfoObj = {};
    for (const prop in sumRollupScoreInfoObj) {
      if (prop === 'viewedTime') {
        rollupScoreInfoObj[prop] = sumRollupScoreInfoObj[prop];
      } else {
        rollupScoreInfoObj[prop] = sumRollupScoreInfoObj[prop] / (divisor || 1);
      }
    }

    const value = (rollupScoreInfoObj && rollupScoreInfoObj[activeReportScorePropName]) || null;
    return value;
  }

  static applyCmapFilterToReportTableData = (tableData, childrenPropName = 'children') => {
    const { isFacultyAdminReport } = reportIdentityManager;
    const { computedSelectedReportCmapObj, selectedReportCmapObj } = reportStandardsManager;
    const selectedReportCmapId = selectedReportCmapObj?.id || computedSelectedReportCmapObj?.id;
    const selectedReportCmapName = selectedReportCmapObj?.name;
    return tableData.filter((standard) => {
      if (isFacultyAdminReport || !(selectedReportCmapId || selectedReportCmapName)) {
        return true;
      }
      return (standard.curriculumMapId && standard.curriculumMapId === selectedReportCmapId)
        || (standard.name && standard.name === selectedReportCmapName);
    }).map((standard) => {
      const parent = standard[childrenPropName] ? standard[childrenPropName].find((child) => child.type === 'UNIT') : undefined;
      if (parent) {
        const children = standard[childrenPropName].filter((child) => child.id !== parent.id);
        return {
          ...parent,
          children: [
            ...parent[childrenPropName],
            ...children
          ],
          rawStandard: standard
        };
      } else {
        return standard;
      }
    });
  }

  static applyAttributeFilterToReportTableData = (tableData) => {
    const { selectedReportAttributeFilterType } = reportStandardsManager;
    const { CLUSTERS, READINESS_AND_SUPPORTING, REPORTING_CATEGORY } = REPORT_ATTRIBUTE_FILTER_TYPE;
    switch (selectedReportAttributeFilterType) {
      case REPORTING_CATEGORY:
        return this.applyReportingCategoryFilterToReportTableData(tableData);
      case CLUSTERS:
        return this.applyClustersFilterToReportTableData(tableData);
      case READINESS_AND_SUPPORTING:
        return this.applyReadinessAndSupportingFilterToReportTableData(tableData);
      default:
        return tableData;
    }
  }

  static applyReportingCategoryFilterToReportTableData = (tableData) => {
    const ReportTypeService = getRegisteredClass('ReportTypeService');
    return ReportTypeService.applyGenericFilterToReportTableData({
      propName: 'reportingCategory', tableData
    });
  }

  static applyClustersFilterToReportTableData = (tableData) => {
    const ReportTypeService = getRegisteredClass('ReportTypeService');
    const clustersTableData = ReportTypeService.applyGenericFilterToReportTableData({
      propName: 'cluster', sortPropName: 'orderNum', tableData
    });
    const clustersAndSubClustersTableData = clustersTableData.map((tableRowParent) => {
      const clusterTags = tableRowParent.rawStandard?.clusterTags || [];
      const clusterTagMap = new Map();
      for (const clusterTag of clusterTags) {
        clusterTagMap.set(clusterTag.id, clusterTag);
      }
      const clusters = (tableRowParent?.subRows || []).map((cluster) => {
        // Don't sort the standards. They should maintain the ordering as received from the server.
        const flatClusterSubRows = flattenChildren(cluster?.subRows, 'subRows');
        // .sort(this.defaultStandardsSortPredicate);

        const clusterTag = clusterTagMap.get(cluster.id);
        const subClusterIdToSubRowWrapperMap = new Map();

        const subClusterTags = clusterTag.subTags ? clusterTag?.subTags?.sort(this.defaultStandardsSortPredicate) : [];

        for (let i = 0; i < subClusterTags.length; i++) {
          const subClusterTag = subClusterTags[i];
          subClusterIdToSubRowWrapperMap.set(subClusterTag.id, {
            order: i,
            rawClusterTag: clusterTag,
            rawSubClusterTag: subClusterTag,
            subRows: []
          });
        }
        for (const subRow of flatClusterSubRows) {
          if (subClusterIdToSubRowWrapperMap.has(subRow?.subCluster?.id)) {
            const subRowWrapper = subClusterIdToSubRowWrapperMap.get(subRow.subCluster.id);
            subClusterIdToSubRowWrapperMap.set(subRow.subCluster.id, {
              ...subRowWrapper,
              subRows: [
                ...subRowWrapper.subRows,
                subRow
              ]
            });
          } else {
            subClusterIdToSubRowWrapperMap.set('rootClusters', [
              ...(subClusterIdToSubRowWrapperMap.get('rootClusters') || []),
              subRow
            ]);
          }
        }
        const subRows = Array.from(subClusterIdToSubRowWrapperMap.entries()).map(([key, data]) => {
          if (key === 'rootClusters') {
            return;
          }
          // Don't sort the standards. They should maintain the ordering as received from the server.
          const sortedDataSubRows = [...data?.subRows]; // .sort(this.defaultStandardsSortPredicate);
          return {
            hasRollup: true,
            overallRollup: this.getRollupAverageScoreInfoObjs(sortedDataSubRows)?.[0],
            ...data.rawSubClusterTag,
            subRows: [...sortedDataSubRows]
          };
        }).filter((subRow) => subRow);
        return {
          ...cluster,
          hasRollup: true,
          overallRollup: this.getRollupAverageScoreInfoObjs(subRows)?.[0],
          standardIds: subRows.map((subClusterOrStandard) => {
            const standardIds = subClusterOrStandard.subRows?.map((standard) => standard.id);
            return standardIds || subClusterOrStandard?.id;
          }).flat(),
          subRows: subClusterIdToSubRowWrapperMap.has('rootClusters') ? [
            ...subClusterIdToSubRowWrapperMap.get('rootClusters'),
            ...subRows
          ] : [...subRows]
        };
      });
      return {
        ...tableRowParent,
        subRows: clusters
      };
    });
    return clustersAndSubClustersTableData;
  }

  static defaultStandardsSortPredicate = (a, b) => {
    let aPropValue = 0;
    if (a['order'] >= 0) {
      aPropValue = a['order'];
    } else if (a['orderNum'] >= 0) {
      aPropValue = a['orderNum'];
    } else if (a['absoluteOrderNum'] >= 0) {
      aPropValue = a['absoluteOrderNum'];
    }
    let bPropValue = 0;
    if (b['order'] >= 0) {
      bPropValue = b['order'];
    } else if (b['orderNum'] >= 0) {
      bPropValue = b['orderNum'];
    } else if (b['absoluteOrderNum'] >= 0) {
      bPropValue = b['absoluteOrderNum'];
    }
    return aPropValue - bPropValue;
  }

  static applyReadinessAndSupportingFilterToReportTableData = (tableData) => {
    const readinessAndSupportingTableData = tableData.map((tableRowParent) => {
      const flatTableRows = flattenChildren(tableRowParent.subRows, 'subRows');
      const subRows = [];
      const tableRowReadiness = this.getTagTypeTableRow(flatTableRows, 'readiness');
      if (tableRowReadiness.subRows?.length) {
        subRows.push(tableRowReadiness);
      }
      const tableRowSupporting = this.getTagTypeTableRow(flatTableRows, 'supporting');
      if (tableRowSupporting.subRows?.length) {
        subRows.push(tableRowSupporting);
      }
      return {
        ...tableRowParent,
        subRows
      };
    }).filter((tableRowParent) => tableRowParent?.subRows?.length);
    return readinessAndSupportingTableData;
  }

  /**
   * @param {[]} flatTableRows
   * @param {'readiness' | 'supporting'} tagType
   */
  static getTagTypeTableRow = (flatTableRows = [], tagType) => {
    const subRows = flatTableRows.filter((tableRow) => {
      return tableRow?.readinessAndSupporting?.name?.toLowerCase() === tagType;
    }).map((tableRow) => {
      return { ...tableRow, overallRollup: null, tagType };
    });
    const tableRow = {
      hasRollup: true,
      id: tagType,
      name: capitalize(tagType),
      overallRollup: this.getRollupAverageScoreInfoObjs(subRows)?.[0],
      subRows
    };
    return tableRow;
  }

  static getTruncatedStandardsDescription = (standardElement) => {
    // truncate current `standardElement` description to pass via urlParams (for breadcrumb purposes)
    const { name, description } = standardElement;
    let truncatedStandardDescription;
    if (standardElement) {
      const strippedName = stripHtmlTags(name || '');
      const strippedDescription = stripHtmlTags(description || '');
      const strippedNameAndDescription = strippedName ? `${strippedName} ${strippedDescription}` : strippedDescription;
      truncatedStandardDescription = truncate(strippedNameAndDescription, { length: 50 });
      return truncatedStandardDescription;
    }
  }

  static getFirstMappedCmapId = () => {
    return reportStandardsManager.reportCmapContentItemIds[0];
  }

  static removeCmapEntryFromMap = (cmapContentItemId) => {
    reportStandardsManager.removeCmapEntryFromMap(cmapContentItemId);
  }

  static getSelectedReportCmapContentItemId = () => {
    return reportStandardsManager.selectedReportCmapContentItemId;
  }

  static clearSelectedReportCmapInfo = () => {
    reportStandardsManager.setSelectedReportCmapInfo(null, null);
  }
}

registerClass('ReportStandardsService', ReportStandardsService);
