import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Dropdown } from 'semantic-ui-react';

import '../css/ClassroomStatusDropdown.less';

import { SatCoreRegister } from '../SatCoreRegistry';

const ClassroomStatusDropdown = observer((props = {}) => {
  const {
    classroomStatus,
    classroomStatusDropdownLoading,
    setClassroomStatus,
    setClassroomStatusDropdownLoading, t
  } = props;

  const { classroomManager } = useContext(MobXProviderContext);

  const statuses = ['active', 'archived'];

  // TODO unused
  // /** equivalent to componentDidMount(), i.e. only called after initial render */
  // useEffect(() => {
  //   (async () => {
  //     /* placeholder */
  //   })();
  // }, []);

  const handleChangeClassroomStatus = async (_event, data) => {
    setClassroomStatusDropdownLoading(true);
    const status = data.value;
    const { currentClassroomId } = classroomManager;

    const page = 0;
    const isStudent = false;
    const userId = null;
    const archived = status === 'archived';

    await classroomManager.fetchClassroomData(
      currentClassroomId, page, isStudent, userId, archived, { pageSize: 999 }
    );
    setClassroomStatus(status);
    setClassroomStatusDropdownLoading(false);
  };

  const renderClassroomStatusDropdown = () => {
    return (
      <div className='classroom-status-dropdown'>
        <Dropdown
          disabled={classroomStatusDropdownLoading}
          // TODO remove // loading={classroomStatusDropdownLoading}
          onChange={handleChangeClassroomStatus}
          options={statuses.map((status) => {
            return {
              text: t(status),
              value: status
            };
          })}
          selection
          value={classroomStatus} />
      </div>
    );
  };
  return renderClassroomStatusDropdown();
});
export default ClassroomStatusDropdown;

SatCoreRegister('ClassroomStatusDropdown', ClassroomStatusDropdown);
