import React from 'react';

import { register } from '../i18n';

import { getRegisteredClass, registerClass } from '../SatCoreRegistry';

import adminClassroomManager from '../managers/AdminClassroomManager';
import adminUsersManager from '../managers/AdminUsersManager';
import classroomManager from '../managers/ClassroomManager';
import dialogManager, { DIALOG_NAMES } from '../managers/DialogManager';
import navigationManager, { VIEW_SELECTION } from '../managers/NavigationManager';
import userManager from '../managers/UserManager';

const t = register('ClassroomService');

export default class ClassroomService {
  /**
   * Return current classroom id.
   *
   * We should still use `classroomManager.currentClassroomId` for rendering,
   * but we can use this method when initializing components
   * (to ensure we are using the proper `classroomId`).
   */
  static getCurrentClassroomId = ({
    alsoUpdateObservableWithUrlParamIfNull = false
  } = {}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const classroomId = classroomManager.currentClassroomId || urlParams.get('classroomId') || null;
    if (alsoUpdateObservableWithUrlParamIfNull && classroomId !== classroomManager.currentClassroomId) {
      classroomManager.setCurrentClassroomId(classroomId);
    }
    return classroomId;
  }

  /**
   * @returns an array of `options` that can be passed to and used by `ClassroomDropdown`
   */
  static getClassroomDropdownOptions(classroomsArray = []) {
    return classroomsArray.map((classroom) => {
      return {
        classroom,
        text: <span title={classroom.name}>{classroom.name}</span>,
        value: classroom.id
      };
    }).filter((option) => option).sort((a, b) => a.classroom?.name?.localeCompare(b.classroom?.name, 'en', { numeric: true }));
  }

  static isSelfRegClassroom = ({
    classroom, classroomId
  } = {}) => {
    if (classroom) {
      return !classroom.ssoId;
    } else {
      classroomId = classroomId || classroomManager.currentClassroomId;
      classroom = classroomManager.getClassroom(classroomId);
      return classroom && !classroom?.ssoId;
    }
  }

  static isSsoClassroom = ({
    classroom, classroomId
  } = {}) => {
    if (classroom) {
      return !!classroom.ssoId;
    } else {
      classroomId = classroomId || classroomManager.currentClassroomId;
      classroom = classroomManager.getClassroom(classroomId);
      return !!classroom?.ssoId;
    }
  }

  static getFailedCreateClassroomResponseAppendedWithErrorStatusMessage = async (response) => {
    if (!response || response?.status === 'SUCCESS') {
      return response;
    }
    const messages = {
      classDateError: 'The dates you entered are invalid. Please make sure the end date occurs after the start date.',
      classNameError: 'The classroom name must be unique. Please choose another name.',
      classUpdateError: 'Something went wrong with creating the classroom. Please try again.'
    };
    const statusMessageStr = messages[response.statusMessage];
    const modifiedResponse = { ...response, statusMessage: statusMessageStr };
    return modifiedResponse;
  }

  /**
   * @returns {{
   *   id: string,
   *   institutionName: string,
   *   leadTeacherName: string,
   *   name: string
   * }} `{...classroom, id, institutionName, leadTeacherName, name}`
   */
  static getClassroomInfoFromClassroomObj = (classroom) => {
    return {
      ...classroom,
      id: (classroom.id || classroom.classroomId || '').trim(),
      institutionName: (classroom.institutionName || '').trim(),
      leadTeacherName: (classroom.leadTeacherName || '').trim(),
      name: userManager.isSsoUser ?
        (classroom.nickname || classroom.classroomNickname || classroom.name || classroom.classroomName || '').trim()
        : (classroom.name || classroom.classroomName || '').trim()
    };
  }

  static initAdminData = async (props = {}) => {
    const {
      forceFetchClassroom,
      history,
      shouldFetchClassroomWithCompleteTeacherData = false
    } = props;

    const paramsToExcludeFromStr = 'classroomId';
    const {
      classroomId,
      institutionId,
      leadTeacherId,
      urlParamStr,
      userId,
      userName
    } = await navigationManager.getAdminUrlParams(paramsToExcludeFromStr);

    if (userManager.isDistrictOrSchoolAdmin && !adminUsersManager.currentUser?.id) {
      const currentUserId = leadTeacherId || userId;
      await adminUsersManager.fetchUser(currentUserId);
    }

    if (userId && userName) {
      navigationManager.setView(VIEW_SELECTION.USERS);
    } else {
      navigationManager.setView(VIEW_SELECTION.CLASSROOMS);
    }
    navigationManager.clearAllPaths();
    await this.setAdminBreadcrumbs(institutionId, userId, userName, urlParamStr);
    if (shouldFetchClassroomWithCompleteTeacherData) {
      const urlParams = new URLSearchParams(window.location.search);
      const teacherId = urlParams.get('leadTeacherId');
      const classroomWithCompleteTeacherData = await classroomManager.fetchClassroom(classroomId, {
        fetchOnlyAndReturnImmediatelyAfterResponse: true,
        teacherId
      });
      if (classroomWithCompleteTeacherData) {
        await classroomManager.setAdminClassroom(classroomId, classroomWithCompleteTeacherData);
      }
      return { shouldInitMoreDataIfApplicable: true };
    } else if (!forceFetchClassroom && classroomId && classroomManager.getClassroom(classroomId)) {
      return { shouldInitMoreDataIfApplicable: true };
    } else {
      const classroom = await adminClassroomManager.fetchClassroom(classroomId);
      if (classroom) {
        await classroomManager.setAdminClassroom(classroomId, classroom);
        return { shouldInitMoreDataIfApplicable: true };
      } else {
        const routerUrl = `/classrooms?view=${VIEW_SELECTION.CLASSROOMS}&${urlParamStr}`;
        await history.push(routerUrl);
      }
    }
  }

  static setAdminBreadcrumbs = async (institutionId, userId, userName, urlParamStr) => {
    if (institutionId && userManager.isDistrictAdmin) {
      const schoolsBreadcrumbObj = {
        fromView: VIEW_SELECTION.DASHBOARD,
        path: { name: 'Schools' }
      };
      const schoolNameBreadcrumbObj = {
        fromView: VIEW_SELECTION.DASHBOARD,
        paramName: 'institutionName',
        path: {}
      };
      await navigationManager.setBreadcrumb(schoolsBreadcrumbObj);
      await navigationManager.setBreadcrumb(schoolNameBreadcrumbObj);
    }
    if (userId && userName) {
      let routerUrl_users = `/users?view=${VIEW_SELECTION.USERS}`;
      if (urlParamStr) {
        routerUrl_users += `&${urlParamStr}`;
      }
      const usersBreadcrumbObj = {
        fromView: VIEW_SELECTION.USERS,
        path: { name: 'Users', routerUrl: routerUrl_users }
      };
      let routerUrl_adminProfile = `/adminProfile?view=${VIEW_SELECTION.USERS}`;
      if (urlParamStr) {
        routerUrl_adminProfile += `&${urlParamStr}`;
      }
      const userNameBreadcrumbObj = {
        path: {
          name: userName,
          routerUrl: routerUrl_adminProfile
        }
      };
      await navigationManager.setBreadcrumb(usersBreadcrumbObj);
      await navigationManager.setBreadcrumb(userNameBreadcrumbObj);
    } else {
      let routerUrl_classrooms = `/classrooms?view=${VIEW_SELECTION.CLASSROOMS}`;
      if (urlParamStr) {
        routerUrl_classrooms += `&${urlParamStr}`;
      }
      const classroomsBreadcrumbObj = {
        fromView: VIEW_SELECTION.CLASSROOMS,
        path: { name: t('classesLabel', 'Classes'), routerUrl: routerUrl_classrooms }
      };
      await navigationManager.setBreadcrumb(classroomsBreadcrumbObj);
    }
  }

  static joinClassroom = async (code, addTeacher = false) => {
    const response = await classroomManager.joinClassroom(code, addTeacher);
    if (!response || userManager.isStudent || response.status === 'WARNING') {
      if (response?.status === 'WARNING' && response.statusMessage) {
        // eslint-disable-next-line no-alert
        alert(response.statusMessage);
      }
      return response;
    }

    classroomManager.setLoaded(false);

    const institutionId = response?.data?.institutionId;
    userManager.setSelectedDropdownInstitutionId(institutionId || 'allInstitutions');
    if (institutionId && !userManager.institutionsMap.has(institutionId)) {
      await userManager.createUserInstitution({
        institutionId,
        userId: userManager.userId
      });
      const UserService = getRegisteredClass('UserService');
      const institution = await UserService.fetchSingularUserInstitution(institutionId);
      userManager.addUserInstitutionsToMap([institution]);
      classroomManager.setLoaded(true);
      return response;
    } else {
      classroomManager.setLoaded(true);
      return response;
    }
  }

  static handleOpenClassroomImagePicker = ({
    classroomId
  } = {}) => {
    if (dialogManager.openedDialogs.has(DIALOG_NAMES.CLASS_IMAGE)) {
      dialogManager.closeDialog(DIALOG_NAMES.CLASS_IMAGE);
    } else {
      dialogManager.setOpenDialog(DIALOG_NAMES.CLASS_IMAGE, {
        classroomId: classroomId || classroomManager.currentClassroomId
      },
      this.toggleClassImageEditor);
    }
  }
}

registerClass('ClassroomService', ClassroomService);
