/* eslint-disable max-len */
import React from 'react';

import { Image, Table } from 'semantic-ui-react';

import iconInfo from '../img/icon-info.svg';

import iconDarkGreenCheck from '../img/icon-dark-green-check.svg';

import { registerClass } from '../SatCoreRegistry';

import { register } from '../i18n';

import engagementManager from '../managers/EngagementManager';

import PopupService from './PopupService';

const t = register('EngagementTable');

export default class EngagementService {
  static fetchAggregateGradebookEngagement = async (assignment) => {
    await engagementManager.fetchAggregateGradebookEngagement(assignment);
  }

  static allowEngagementFlagColumn = () => {
    const activeTable = engagementManager.activeEngagementTable;
    return activeTable === 'summary' || activeTable === 'timeOnTaskRatio' || activeTable === 'feedbackUsage';
  }

  /**
   * determine whether or not a given column (from EngagementTable) has at least some cells that are available to hold data
   * @param {EngagementFeedbackUsageAvailableKey | EngagementResourcesUsageAvailableKey | EngagementAnnotationsUsageAvailableKey | EngagementLanguageToolUsageAvailableKey} engagementUsageAvailableKey
   * @returns {boolean} `true | false`
   */
  static allowEngagementColumn = (engagementUsageAvailableKey) => {
    return engagementManager.engagementData.map((rawDataRow) => rawDataRow.engagementData)
      .some((dataRow) => dataRow[engagementUsageAvailableKey]);
  }

  static renderEngagementCell = ({
    allowPopupComponent = false,
    cellText,
    className,
    isHeaderCell = false,
    popupContent,
    popupDisabled = false,
    popupMouseEnterDelay = 400,
    popupOn = 'hover',
    popupPosition = 'top center',
    popupTrigger = <Image alt='' className='info-icon' spaced src={iconInfo} />,
    popupWide = ''
  } = {}) => {
    const TableCell = isHeaderCell ? Table.HeaderCell : Table.Cell;
    return (
      <TableCell className={className}>
        <div>
          {cellText}
          {allowPopupComponent && PopupService.renderPopup({
            content: popupContent,
            disabled: !!popupDisabled,
            mouseEnterDelay: popupMouseEnterDelay,
            on: popupOn,
            position: popupPosition,
            trigger: popupTrigger,
            wide: popupWide
          })}
        </div>
      </TableCell>
    );
  };

  /**
   * @param {{
   *   engagementCellType: 'main' | 'auxiliary';
   *   engagementData: {};
   *   engagementDataAvailableKey: boolean;
   *   engagementDataCountKey: string | number;
   * }}
   */
  static renderEngagementResourcesUsageCellContent = ({
    engagementCellType,
    engagementData,
    engagementDataAvailableKey,
    engagementDataCountKey,
  } = {}) => {
    if (!engagementData || (!engagementDataCountKey && typeof engagementDataCountKey !== 'number')) {
      return null;
    }

    if (engagementCellType === 'main') {
      return t('resourcesRowMainCellDescription', {
        count: typeof engagementDataCountKey === 'number' ?
          engagementDataCountKey : engagementData[engagementDataCountKey]
      });
    } else if (!engagementData[engagementDataAvailableKey]) {
      return t('resourceNotAvailableDescription');
    } else {
      return t('resourcesRowAuxiliaryCellDescription', {
        count: engagementData[engagementDataCountKey]
      });
    }
  };

  /**
   * @param {{
   *   engagementCellType: 'main' | 'auxiliary';
   *   engagementData: {};
   *   engagementDataAvailableKey: boolean;
   *   engagementDataCountKey: string;
   * }}
   */
  static renderEngagementResourcesUsageCellTrigger = ({
    engagementData,
    engagementDataAvailableKey,
    engagementDataCountKey
  }) => {
    const isResourceAvailable = engagementData?.[engagementDataAvailableKey];
    const resourceCount = engagementData?.[engagementDataCountKey];

    if (isResourceAvailable) {
      return resourceCount ? (
        <Image alt='' src={iconDarkGreenCheck} />
      ) : t('blankTrigger');
    } else {
      return t('resourceNotAvailable');
    }
  };
}

/* EngagementTimeOnTaskRatioAvailableKey */ // unused
/** @typedef {} EngagementTimeOnTaskRatioAvailableKey */

/* EngagementFeedbackUsageAvailableKey */
/** @typedef {'tryAgainAvailable' | 'showHintAvailable' | 'showSolutionAvailable' | 'showAnswerAvailable'} EngagementFeedbackUsageAvailableKey */

/* EngagementResourcesUsageAvailableKey */
/** @typedef {'viewBasicCalculatorAvailable' | 'viewScientificCalculatorAvailable' | 'viewGraphingCalculatorAvailable' | 'viewDictionaryAvailable' | 'viewCustomaryRulerAvailable' | 'viewMetricRulerAvailable' | 'viewProtractorAvailable' | 'viewResourcesAvailable'} EngagementResourcesUsageAvailableKey */

/* EngagementAnnotationsUsageAvailableKey */
/** @typedef {'viewHatAvailable' | 'viewEliminatorAvailable' | 'viewStickyNotesAvailable' | 'viewBookmarkAvailable'} EngagementAnnotationsUsageAvailableKey */

/* EngagementLanguageToolUsageAvailableKey */
/** @typedef {'viewTextToSpeechAvailable' | 'viewTranslateAvailable'} EngagementLanguageToolUsageAvailableKey */

registerClass('EngagementService', EngagementService);
