import { action, computed, makeObservable, observable } from 'mobx';
import { REPORT_CONTEXT_TYPE } from '../../services/reports/ReportConstants';
import reportContextManager from './ReportContextManager';

export class ReportStandardsClassroomDetailStudentsManager {
  constructor() {
    makeObservable(this);
  }

  @observable currentStandard = null;

  // TODO remove
  // @observable currentReportStandardsFullCourseElement = null;

  @observable reportCourseElementsWithStandardAlignments = [];

  @observable currentCutScoreMap = new Map();

  /** activePage */
  @observable activeReportStandardsClassroomDetailStudentsTablePage_itemLevel = 1;
  @observable activeReportStandardsClassroomDetailStudentsTablePage_resourceLevel = 1;

  @observable reportStandardsClassroomDetailContentImageSourceMap = new Map();

  @action clearAll = () => {
    this.setCurrentStandard(null);
    this.clearActiveReportStandardsClassroomDetailStudentsTablePage();
    this.clearReportStandardsClassroomDetailContentImageSourceMap();
    this.clearCurrentStudentCutScoreMap();
  }

  @action setCurrentStandard = (standard) => {
    this.currentStandard = standard;
  }

  // TODO remove
  // @action setCurrentReportStandardsFullCourseElement = (fullCourseElement) => {
  //   this.currentReportStandardsFullCourseElement = fullCourseElement;
  // }

  @action setReportCourseElementsWithStandardAlignments = (reportCourseElementsWithStandardAlignments) => {
    this.reportCourseElementsWithStandardAlignments = reportCourseElementsWithStandardAlignments;
  }

  @action setStudentCutScore = (student, creditClassName) => {
    if (!this.currentCutScoreMap.get(creditClassName)) {
      this.currentCutScoreMap.set(creditClassName, [student]);
    } else if (!this.currentCutScoreMap.get(creditClassName).includes(student)) {
      this.currentCutScoreMap.get(creditClassName).push(student);
    }
  }

  @action clearActiveReportStandardsClassroomDetailStudentsTablePage = () => {
    this.activeReportStandardsClassroomDetailStudentsTablePage_itemLevel = 1;
    this.activeReportStandardsClassroomDetailStudentsTablePage_resourceLevel = 1;
  }

  @action setActiveReportStandardsClassroomDetailStudentsTablePage = (activePage) => {
    if (reportContextManager.reportStandardsClassroomDetailStudentsContextType === REPORT_CONTEXT_TYPE.RESOURCE_LEVEL) {
      this.activeReportStandardsClassroomDetailStudentsTablePage_resourceLevel = activePage;
    } else {
      this.activeReportStandardsClassroomDetailStudentsTablePage_itemLevel = activePage;
    }
  }

  /**
   * @param {string} reportCourseElementId
   * @param {{ imageSource: string, imageSourceClassName: string }} imageSourceObj
   */
  @action addToReportStandardsClassroomDetailContentImageSourceMap = (reportCourseElementId, imageSourceObj) => {
    this.reportStandardsClassroomDetailContentImageSourceMap.set(reportCourseElementId, imageSourceObj);
  };

  @action clearReportStandardsClassroomDetailContentImageSourceMap = () => {
    this.reportStandardsClassroomDetailContentImageSourceMap.clear();
  }

  @computed get activeReportStandardsClassroomDetailStudentsTablePage() {
    if (reportContextManager.reportStandardsClassroomDetailStudentsContextType === REPORT_CONTEXT_TYPE.RESOURCE_LEVEL) {
      return this.activeReportStandardsClassroomDetailStudentsTablePage_resourceLevel;
    } else {
      return this.activeReportStandardsClassroomDetailStudentsTablePage_itemLevel;
    }
  }

  @action clearCurrentStudentCutScoreMap = () => {
    this.currentCutScoreMap.clear();
  }
}

export default new ReportStandardsClassroomDetailStudentsManager();
