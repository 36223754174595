import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { Pagination, Popup } from 'semantic-ui-react';
import { SatCoreRegister } from '../SatCoreRegistry';
import Avatar from './Avatar';
import PopupService from '../services/PopupService';
import UserService from '../services/UserService';
import UtilityService from '../services/UtilityService';
import { getInitials, stripHtmlTags } from '../utils';
import defaultAvatar from '../img/avatars/default-avatar.png';
import '../css/GradebookStandards.less';

const GradebookStandards = observer((props) => {
  const { t } = props;
  // const indentFactor = 0;
  const indentFactor = 14;
  const indentBase = 14;
  // const itemExtraIndent = 25;
  const itemExtraIndent = 14;
  const pageSize = 14;

  const { gradebookManager, userManager } = useContext(MobXProviderContext);
  const data = gradebookManager.gradebookStandardsData;

  if (!data) {
    // Something wicked this way comes. What's done cannot be undone.
    return (
      <div className='gradebook-standards'>
        <div className='error-container'>
          {t('errorMessage')}
        </div>
      </div>
    );
  } else if (!data.hasAlignments) {
    return (
      <div className='gradebook-standards'>
        <div className='error-container'>
          {t('noStandardsMessage')}
        </div>
      </div>
    );
  } else if (!data.hasStudentData) {
    return (
      <div className='gradebook-standards'>
        <div className='error-container'>
          {t('noStudentDataMessage')}
        </div>
      </div>
    );
  }

  const {
    curriculumMaps, students, studentItemScores, studentStandardScores, classItemAverages, classStandardAverages,
    alignedStandardIds, entityTypeId
  } = data;

  const [openStandards, setOpenStandards] = useState({});
  const [activePage, setActivePage] = useState(1);

  const totalStudents = students.length;
  const totalPages = Math.ceil(totalStudents / pageSize);
  const startIndex = (activePage - 1) * pageSize;
  let endIndex = activePage * pageSize;
  endIndex = (endIndex < totalStudents) ? endIndex : totalStudents;
  const pagedStudents = students.slice(startIndex, endIndex);
  const pagedStudentCount = pagedStudents.length;

  const gridTemplate = `500px 70px repeat(${pagedStudents.length}, 50px)`;

  const handlePageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const toggleStandardState = (event) => {
    const id = event.target.dataset.value || event.target.parentNode.dataset.value;
    setOpenStandards((prevState) => {
      return { ...prevState, [id]: !prevState[id] };
    });
  };

  const roundUp = (score) => {
    if (typeof score === 'number') {
      score = Math.round((score + Number.EPSILON) * 10) / 10;
    }
    return score;
  };

  const getStudentScore = (scoreMap, userId, id) => {
    let studentScore = scoreMap?.[userId]?.[id]?.score;
    studentScore = roundUp(studentScore);
    studentScore = (studentScore === undefined) ? '—' : studentScore;
    studentScore = (studentScore === null) ? 0 : studentScore;
    return studentScore;
  };

  useEffect(() => {
    // set the initial state of all the standard blinds to be open
    const tmpOpenStandard = {};

    const initOpenStandards = (standard) => {
      tmpOpenStandard[standard.id] = true;
      standard.children.forEach((child) => {
        initOpenStandards(child);
      });
    };

    curriculumMaps.forEach((cmap) => {
      const { rootList } = cmap;
      if (rootList) {
        rootList.forEach((rootStandard) => {
          initOpenStandards(rootStandard);
        });
      }
    });

    setOpenStandards(tmpOpenStandard);
  }, []);

  // component for student cells in the table header
  const StudentCell = (props) => {
    const { student } = props;
    const studentFullName = UserService.getUserFullNameStr(student);

    const popupLabel = PopupService.renderPopup({
      content: (
        <div>
          <div>{studentFullName}</div>
        </div>
      ),
      offset: [10, 0],
      on: 'hover',
      pinned: true,
      position: 'bottom right',
      trigger: getInitials(studentFullName)
    });

    const imageUrl = userManager.getFullProfileImageUrl(student.profileImage);
    return (
      <div className='avatar-wrapper'>
        <Avatar className='avatar' defaultImage={defaultAvatar} fullProfileImageUrl={imageUrl} />
        <div className='avatar-label'>
          {popupLabel}
        </div>
      </div>
    );
  };

  const HeaderRow = (props) => {
    return (
      <>
        <div className='cell standard-header-cell'>Standards</div>
        <div className='cell class-header-cell'>Class Average</div>
        {pagedStudents.map((student, idx) => {
          const className = (idx === pagedStudentCount - 1) ? 'last-header-cell' : '';

          return (
            <div key={student.userId} className={`cell student-header-cell ${className}`}>
              <StudentCell
                student={student}
              />
            </div>
          );
        })}
      </>
    );
  };

  const ItemRow = (props) => {
    const { item, level } = props;
    const { questionNum, id, stem } = item;

    let parsedStem = gradebookManager.gradebookStandardsMap[id];
    if (!parsedStem) {
      parsedStem = UtilityService.parseHtmlWithMath(stem);
      gradebookManager.gradebookStandardsMap[id] = parsedStem;
    }

    let classAverage = classItemAverages[id];
    classAverage = roundUp(classAverage);

    const showItemNumber = entityTypeId === 'lesson' || entityTypeId === 'assessment' || entityTypeId === 'learnosity_activity_resource';
    const itemLabel = (
      <>
        {showItemNumber && (
          <>
            <span className='gs-item-text'>Item</span>
            <span className='gs-item-num'>{questionNum}</span>
          </>
        )}
        <span className='gs-item-stem'>{parsedStem}</span>
      </>
    );

    const indent = indentBase + itemExtraIndent + (level * indentFactor);

    return (
      <>
        <Popup
          content={(
            <div>{itemLabel}</div>
          )}
          offset={[30, -10]}
          trigger={(
            <div className='cell first-column-cell' style={{ paddingLeft: indent }}>
              {itemLabel}
            </div>
          )}
          wide='very'
        />
        <div className='cell point-cell class-cell'>{classAverage}</div>
        {pagedStudents.map((student) => {
          const { userId } = student;
          const studentScore = getStudentScore(studentItemScores, userId, id);
          const maxScore = studentItemScores?.[userId]?.[id]?.maxScore;

          let className = '';
          if (typeof studentScore !== 'number') {
            className = 'no-response';
          } else if (studentScore === 0) {
            className = 'no-points';
          } else if (studentScore < maxScore) {
            className = 'partial-points';
          } else {
            className = 'full-points';
          }

          return (
            <div key={userId} className={`cell point-cell ${className}`}>{studentScore}</div>
          );
        })}
      </>
    );
  };

  const StandardRow = (props) => {
    const { standard, level } = props;
    const { id, children, items, description, type } = standard;
    let { name } = standard;
    const openedBlind = <i aria-hidden='true' className='caret down icon' />;
    const closedBlind = <i aria-hidden='true' className='caret right icon' />;
    const isOpen = openStandards[id];
    const blindIcon = (isOpen) ? openedBlind : closedBlind;
    const blind = (items.length > 0 || children.length > 0) ? <span data-value={id}>{blindIcon}</span> : null;

    const nextLevel = level + 1;

    if (!name && !description) {
      name = type;
    }
    name = stripHtmlTags(name);

    let parsedDescription = gradebookManager.gradebookStandardsMap[id];
    if (!parsedDescription) {
      parsedDescription = UtilityService.parseHtmlWithMath(description);
      gradebookManager.gradebookStandardsMap[id] = parsedDescription;
    }

    let classAverage = classStandardAverages[id];
    classAverage = roundUp(classAverage);

    const standardLabel = (
      <>
        {name && <span className='gs-standard-name'>{name}</span>}
        <span className='gs-standard-description'>{parsedDescription}</span>
      </>
    );

    return (
      <>
        <Popup
          content={(
            <div>{standardLabel}</div>
          )}
          offset={[30, -10]}
          trigger={(
            <div
              className='cell first-column-cell standard-cell standard-row'
              data-value={id}
              onClick={toggleStandardState}
              style={{ paddingLeft: indentBase + (level * indentFactor) }}
            >
              {blind}{standardLabel}
            </div>
          )}
          wide='very'
        />

        <div className='cell point-cell standard-row class-cell'>{classAverage}</div>
        {pagedStudents.map((student) => {
          const { userId } = student;
          let studentScore = '';
          let className = '';
          if (alignedStandardIds.includes(id)) {
            studentScore = getStudentScore(studentStandardScores, userId, id);

            if (typeof studentScore !== 'number') {
              className = 'no-response';
            }
          }

          return (
            <div key={userId} className={`cell point-cell standard-row ${className}`}>{studentScore}</div>
          );
        })}
        {/* show the items this standard is aligned to */}
        {isOpen && items.map((item) => {
          return (
            <ItemRow
              key={item.id}
              item={item}
              level={nextLevel}
            />
          );
        })}
        {/* show the child standards of this standard */}
        {isOpen && children.map((child) => {
          return (
            <StandardRow
              key={child.id}
              level={nextLevel}
              standard={child}
            />
          );
        })}
      </>
    );
  };

  return (
    <div className='gradebook-standards'>
      <div className='paginator-container'>
        <Pagination
          activePage={activePage}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      </div>
      <div className='outer-container'>
        <div className='grid-container' style={{ gridTemplateColumns: gridTemplate }}>
          <HeaderRow />
          {/* show the standards and items of each curriculum map */}
          {curriculumMaps.map((cmap) => {
            const { rootList } = cmap;
            if (!rootList || rootList.length === 0) {
              return null;
            }

            return (
              <React.Fragment key={cmap.id}>
                {rootList.map((standard) => {
                  return (
                    <StandardRow
                      key={standard.id}
                      level={0}
                      standard={standard}
                    />
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
});
export default GradebookStandards;

SatCoreRegister('GradebookStandards', GradebookStandards);
