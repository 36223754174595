import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Container, Loader } from 'semantic-ui-react';
import Modal from '../Modal';
import '../../css/CourseElementModal.less';
import '../../css/Class.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { PATH_TYPES, VIEW_SELECTION } from '../../managers/NavigationManager';

import CourseService from '../../services/CourseService';
import GradebookService from '../../services/GradebookService';
import ReportIdentityService from '../../services/reports/ReportIdentityService';

export default @inject(
  'assignmentManager', 'classroomManager', 'courseManager',
  'dialogManager', 'navigationManager', 'productManager',
  'userManager')
@observer
class CourseElementModal extends Component {
  constructor(props) {
    super(props);
    this.BreadCrumbs = SatCoreComponent('BreadCrumbs');
    this.CourseLayout = SatCoreComponent('CourseLayout');
    this.ModalBanner = SatCoreComponent('ModalBanner');
  }

  async componentDidMount() {
    const { shouldSkipComponentDidMountLogic } = this.props;
    if (shouldSkipComponentDidMountLogic) {
      return;
    }
    const {
      classroomManager, courseManager, navigationManager, productManager
    } = this.props;

    productManager.setIsFromProduct(true);

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('view')) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.BOOK);
    }
    classroomManager.setCurrentClassroomId('product');
    if (urlParams.has('courseId')) {
      courseManager.setCurrentCourseId(urlParams.get('courseId'));
    }

    const shouldLoadData = true;
    const force = true;
    await CourseService.initCourseDataProduct(shouldLoadData, force);

    if (!navigationManager.pathList || !navigationManager.pathList.length) {
      navigationManager.setBreadcrumb({
        fromView: VIEW_SELECTION.BOOK,
        path: {
          currentCourseId: courseManager.currentCourseId,
          name: courseManager.currentCourseName,
          type: PATH_TYPES.ROOT
        }
      });
    }
  }

  // TODO remove; causes DEMO-1773
  // async componentDidUpdate() {
  //   const shouldCloseCourseElementModalOnUpdate = getSessionStorageItem('shouldCloseCourseElementModalOnUpdate');
  //   if (shouldCloseCourseElementModalOnUpdate) {
  //     removeSessionStorageItem('shouldCloseCourseElementModalOnUpdate');
  //     const { dialogManager } = this.props;
  //     dialogManager.closeDialog(DIALOG_NAMES.COURSE_ELEMENT);
  //   }
  // }

  closeCourseElementModal = async ({ navToAggregateGradebook = false } = {}) => {
    const { productManager, t } = this.props;
    const allAssignmentsLabel = t('allAssignments', 'All Assignments');

    // eslint-disable-next-line react/destructuring-assignment
    this.props.closeCourseElementModal({
      allAssignmentsLabel, navToAggregateGradebook
    });
    if (navToAggregateGradebook) {
      await GradebookService.navToAggregateGradebookSummaryFromExternalModal({
        ...this.props, allAssignmentsLabel
      });
    }

    productManager.setIsFromProduct(false);
  }

  render() {
    const { BreadCrumbs, CourseLayout, ModalBanner } = this;
    const {
      additionalClassNames, classroomManager, courseManager,
      exploreCourseOpen, history, labelInfo, modalTitle, t,
      userManager, showCutScoreAssignType, showAdminIcons
    } = this.props;
    const archivedStatus = classroomManager.currentClassArchiveStatus(classroomManager.currentClassroomId);

    let zeroState = (
      <div className='no-courses'>
        {t('zeroStateNoItems')}
      </div>
    );

    // eslint-disable-next-line react/destructuring-assignment
    zeroState = this.props.zerostate || zeroState;

    let shouldShowAllAssignmentsButton;
    if (ReportIdentityService.isReportStandardsClassroomDetailStudents()) {
      const { currentCourseElementList } = courseManager;

      if (currentCourseElementList && currentCourseElementList[0]) {
        const courseElement = currentCourseElementList[0];

        if (courseManager.forceShowCourseElementModalAllAssignmentsButtonSet.has(courseElement.elementId)) {
          shouldShowAllAssignmentsButton = true;
        } else {
          shouldShowAllAssignmentsButton = currentCourseElementList[0].resourceActivityCount > 0;
        }
      }
    }

    const shouldShowBreadcrumbs = !archivedStatus;
    let classDetailClassName = 'class-detail';
    classDetailClassName += additionalClassNames ? ` ${additionalClassNames}` : '';
    const minHeight = (courseManager.currentCourseElementList.length > 1) ? 'minHeight' : '';
    return (
      <>
        <Modal
          className='CourseElementModal no-background'
          closeOnDimmerClick={false}
          closeOnEscape={true}
          onClose={this.closeCourseElementModal}
          open={exploreCourseOpen}
          size='large'>
          <ModalBanner
            label={labelInfo}
            onClose={this.closeCourseElementModal}
            title={modalTitle || 'Product'} />
          <Modal.Content className={minHeight} scrolling>
            <Container className={classDetailClassName} fluid>
              {shouldShowBreadcrumbs && (
                <Container className='bread-crumb-wrapper' fluid>
                  <BreadCrumbs history={history} />
                </Container>
              )}
              {shouldShowAllAssignmentsButton && !archivedStatus && userManager.isTeacher && (
                <div className='modal-top-buttons'>
                  <div className='btn-all-assignments-wrapper'>
                    <Button
                      className='btn-all-assignments'
                      onClick={(_event, _data) => this.closeCourseElementModal({
                        navToAggregateGradebook: true
                      })}
                      primary>
                      {t('allAssignments')}
                    </Button>
                  </div>
                </div>
              )}
              {(courseManager.currentCourseId !== null && courseManager.currentCourseElementList.length > 0) ? (
                <Container className='class-content scroller-wrapper' fluid>
                  <div className='ui items parent'>
                    <CourseLayout closeParentModal={this.closeCourseElementModal}
                    history={history}
                    showCutScoreAssignType={showCutScoreAssignType}
                    showAdminIcons={showAdminIcons} />
                  </div>
                </Container>
              ) : (
                <Container className='class-content'>
                  {(courseManager.isTreeLoading) ?
                    <Loader active /> :
                    (courseManager.currentCourseElementList.length === 0) && zeroState}
                </Container>
              )}
            </Container>
          </Modal.Content>
          {!archivedStatus && (
            <Modal.Actions>
              <Button
                basic
                className='cancelButton'
                onClick={this.closeCourseElementModal}
                primary>
                {t('close')}
              </Button>
            </Modal.Actions>
          )}
        </Modal>
      </>
    );
  }
}

SatCoreRegister('CourseElementModal', CourseElementModal);
