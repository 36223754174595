import React, { Component } from 'react';
import { inject } from 'mobx-react';

import { Button, Image, Popup, Radio } from 'semantic-ui-react';

import classNames from 'classnames';

import '../css/RosterTable.less';

import iconDisableAlert from '../img/icon-disable-alert.svg';

import { SatCoreRegister } from '../SatCoreRegistry';

import ClassroomService from '../services/ClassroomService';
import PopupService from '../services/PopupService';

export default
@inject(
  'adminClassroomManager', 'classroomManager',
  'dialogManager', 'navigationManager', 'userManager')
class ClassRosterRow extends Component {
  constructor(props) {
    super(props);
  }

  renderCellWithPopup = (content) => (
    <div className='table-cell'>
      <Popup
        content={content}
        on='click'
        position='top center'
        trigger={<span>{content}</span>} />
    </div>
  )

  renderUserEnabledTrialTime = () => {
    const { isActive, rosterItem, t } = this.props;
    if (isActive && rosterItem.purchaseVerified) {
      return <div className='table-cell table-cell-small'>{t('purchaseIsVerified', 'Purchased')}</div>;
    } else if (rosterItem.trialExpired) {
      return <div className='table-cell table-cell-small'>{t('trialExpired', 'Expired')}</div>;
    } else if (!rosterItem.trialDate) {
      return <div className='table-cell table-cell-small'>{t('trialNotStarted', 'Not Started')}</div>;
    }

    return (
      <div className='table-cell table-cell-small'>
        {t('trialDaysRemaining', { count: rosterItem.trialDaysRemaining, defaultValue: '({{count}}) Days' })}
      </div>
    );
  }

  renderPassword = () => {
    const { onClickOpenModal, rosterItem, t } = this.props;

    const isSsoStudent = !!rosterItem?.ssoId;

    let passwordEditableStudentPopupMsgKey;
    if (isSsoStudent) {
      passwordEditableStudentPopupMsgKey = 'cannotEditStudentPasswordMsgSso';
    }
    const passwordEditableStudentPopupMsg = passwordEditableStudentPopupMsgKey ? t(passwordEditableStudentPopupMsgKey) : undefined;

    return (
      <div
        className='table-cell table-cell-small rosterTableCell'
        onClick={onClickOpenModal(rosterItem)}>
        {PopupService.renderPopup({
          content: passwordEditableStudentPopupMsg,
          disabled: !passwordEditableStudentPopupMsg,
          offset: [-11, 0],
          position: 'top left',
          trigger: (
            <u>********</u>
          ),
          wide: false
        })}
      </div>
    );
  }

  renderTextHelpToggler = () => {
    const { handleTextHelp, rosterItem } = this.props;
    return (
      <div className='table-cell table-cell-small rosterTableCell'>
        <Radio
          checked={rosterItem.textHelpEnabled}
          disabled={false}
          onChange={() => { handleTextHelp(rosterItem); }}
          toggle />
      </div>
    );
  }

  renderButtonsToShowWhenUserIsActive = () => {
    const {
      archiveLabel,
      archiveUser,
      classroomManager,
      editLabel,
      editUser,
      hideEdit,
      migrateLabel,
      migrateUser,
      rosterItem, t,
      showTrialTime,
      userEnabled,
      userManager
    } = this.props;

    const isSsoStudent = !!rosterItem?.ssoId;

    // TODO remove // const isSsoUserOrHasSsoInstitution = UserService.isSsoUserOrHasSsoInstitution();
    // TODO remove // const isSsoUserAndSelfRegClassroom = UserService.isSsoUserAndSelfRegClassroom();

    const isSelfRegClassroom = ClassroomService.isSelfRegClassroom();

    const editClassName = classNames('roster-btn-edit', {
      'disabled-btn': !userEnabled || isSsoStudent
    });
    const archiveClassName = classNames('roster-btn-archive', {
      'disabled-btn': !userEnabled
    });
    const migrateDataNameSmall = classNames('roster-btn-migrate-small', {
      'disabled-btn': !userEnabled
    });
    const migrateDataName = classNames('roster-btn-migrate', {
      'disabled-btn': !userEnabled
    });

    let editableStudentPopupMsgKey;
    if (isSsoStudent) {
      editableStudentPopupMsgKey = 'cannotEditStudentMsgSso';
    } else if (!userEnabled) {
      editableStudentPopupMsgKey = 'cannotEditStudentMsgDefault';
    }
    const editableStudentPopupMsg = editableStudentPopupMsgKey ? t(editableStudentPopupMsgKey) : undefined;

    let archivableStudentPopupMsgKey;
    if (!userEnabled) {
      archivableStudentPopupMsgKey = 'cannotEditStudentMsgDefault';
    }
    const archivableStudentPopupMsg = archivableStudentPopupMsgKey ? t(archivableStudentPopupMsgKey) : undefined;

    const migrateLabelClassname = showTrialTime ? 'table-cell-small' : 'table-cell-medium';

    return (
      <>
        {!hideEdit && /* !isSsoUserOrHasSsoInstitution */isSelfRegClassroom && (
          <div className='table-cell Button primary basic table-cell-small'>
            {PopupService.renderPopup({
              content: editableStudentPopupMsg,
              disabled: !editableStudentPopupMsg,
              offset: [-11, 0],
              position: 'top left',
              trigger: (
                <Button
                  basic
                  className={editClassName}
                  onClick={() => userEnabled && !isSsoStudent && (
                    editUser(rosterItem.id, rosterItem.userId)
                  )}
                  primary>
                  {editLabel}
                </Button>
              ),
              wide: false
            })}
          </div>
        )}
        {/* (!isSsoUserOrHasSsoInstitution || isSsoUserAndSelfRegClassroom) */isSelfRegClassroom && (
          <div className='table-cell Button primary basic table-cell-small'>
            {PopupService.renderPopup({
              content: archivableStudentPopupMsg,
              disabled: !archivableStudentPopupMsg,
              offset: [-11, 0],
              position: 'top left',
              trigger: (
                <Button
                  basic
                  className={archiveClassName}
                  onClick={() => userEnabled && archiveUser(rosterItem)}
                  primary>
                  {archiveLabel}
                </Button>
              ),
              wide: false
            })}
          </div>
        )}
        { classroomManager.allowStudentDataMigrate &&
          <div className={`table-cell Button primary basic ${migrateLabelClassname}`}>
            { 
              <Button
                basic
                className={showTrialTime ? migrateDataNameSmall : migrateDataName}
                onClick={() => userEnabled && (
                  migrateUser(rosterItem)
                )}
                primary>
                {migrateLabel}
              </Button>
            }
          </div>
        }
      </>
    );
  }

  renderButtonsToShowWhenUserIsArchived = () => {
    const { activateLabel, activateUser, rosterItem, userEnabled } = this.props;
    let activateClassName = 'roster-btn-activate';
    if (!userEnabled) {
      activateClassName += ' disabled-btn';
    }
    // TODO remove // const isSsoUserOrHasSsoInstitution = UserService.isSsoUserOrHasSsoInstitution();
    // TODO remove // const isSsoUserAndSelfRegClassroom = UserService.isSsoUserAndSelfRegClassroom();
    const isSelfRegClassroom = ClassroomService.isSelfRegClassroom();

    return /* (!isSsoUserOrHasSsoInstitution || isSsoUserAndSelfRegClassroom) */isSelfRegClassroom && (
      <div className='table-cell Button primary basic table-cell-small'>
        <Button
          className={activateClassName}
          onClick={() => userEnabled && activateUser(rosterItem)}
          primary>
          {activateLabel}
        </Button>
      </div>
    );
  }

  render() {
    const {
      enableUser, even, handleTextHelp, hidePassword,
      isActive, publisherTextHelpActive, readOnly,
      rosterItem, showTextHelpForAll, showTrialTime,
      t, userEnabled
    } = this.props;

    const isSsoStudent = !!rosterItem?.ssoId;

    // TODO remove // const isSsoUserOrHasSsoInstitution = UserService.isSsoUserOrHasSsoInstitution();
    const isSelfRegClassroom = ClassroomService.isSelfRegClassroom();

    const userEnabledJsx = (
      <div className={`table-row${even ? ' even' : ''}`}>
        {this.renderCellWithPopup(rosterItem.firstName)}
        {this.renderCellWithPopup(rosterItem.lastName)}
        {this.renderCellWithPopup(rosterItem.username)}

        {showTrialTime && this.renderUserEnabledTrialTime()}
        {!hidePassword && this.renderPassword()}
        {(publisherTextHelpActive && !showTextHelpForAll) && this.renderTextHelpToggler()}

        {(isActive && !readOnly) && this.renderButtonsToShowWhenUserIsActive()}
        {(!isActive && !readOnly) && this.renderButtonsToShowWhenUserIsArchived()}
      </div>
    );

    const userDisabledJsx = (
      <div className={(even) ? 'table-row even' : 'table-row'}>
        <div className='table-cell disabled-cell'>
          <div className='cell-image-txt'>
            <Popup
              content={t('inactiveMsg')}
              offset={[-11, 0]}
              position='top right'
              trigger={<Image alt='' src={iconDisableAlert} />} />
            <div>{rosterItem.firstName}</div>
          </div>
        </div>
        <div className='table-cell disabled-cell'>
          {rosterItem.lastName}
        </div>
        <div className='table-cell disabled-cell'>
          {rosterItem.username}
        </div>
        <div className='table-cell Button primary basic table-cell-small'>
          <Button
            basic
            className={classNames(
              'roster-btn-activate', {
                'disabled-btn': !isSelfRegClassroom || isSsoStudent
              }
            )}
            onClick={() => /* !isSsoUserOrHasSsoInstitution */isSelfRegClassroom && !isSsoStudent && enableUser(rosterItem)}
            primary>
            {t('enable')}
          </Button>
        </div>
        {(publisherTextHelpActive && !showTextHelpForAll) && (
          <div className='table-cell table-cell-small rosterTableCell'>
            <Radio
              checked={rosterItem.textHelpEnabled}
              disabled={true}
              onChange={() => { handleTextHelp(rosterItem); }}
              toggle />
          </div>
        )}
        {(isActive && !readOnly) && this.renderButtonsToShowWhenUserIsActive()}
        {(!isActive && !readOnly) && this.renderButtonsToShowWhenUserIsArchived()}
      </div>
    );
    return <>{userEnabled ? userEnabledJsx : userDisabledJsx}</>;
  }
}

SatCoreRegister('ClassRosterRow', ClassRosterRow);
