import { action, computed, observable } from 'mobx';

import Auth from './AuthManager';
import classroomManager from './ClassroomManager';

export class AccommodationsManager {
  @observable includeAssignmentAccommodations = false;
  @observable includeClassroomAccommodations = false;

  @observable currentAccommodationsRosterMap = new Map();

  @observable togglerSectionOffAccommodationsSet = new Set()

  @action clearAll = () => {
    this.clearAccommodationsRoster();
    this.clearTogglerSectionOffAccommodationsSet();
  }

  @action setIncludeAssignmentAccommodations = (includeAssignmentAccommodations) => {
    this.includeAssignmentAccommodations = includeAssignmentAccommodations;
  }

  @action setIncludeClassroomAccommodations = (includeClassroomAccommodations) => {
    this.includeClassroomAccommodations = includeClassroomAccommodations;
  }

  @action addToCurrentAccommodationsRoster = async (roster = []) => {
    for (const rosterItem of roster) {
      const offAccommodations = (rosterItem.accommodationIds || '').split(',')
        .filter((accommodation) => accommodation);
      this.currentAccommodationsRosterMap.set(rosterItem.id, {
        ...rosterItem, offAccommodations
      });
    }
  }

  @action clearTogglerSectionOffAccommodationsSet = () => {
    this.togglerSectionOffAccommodationsSet = new Set();
  }

  @action addToTogglerSectionOffAccommodationsSet = (accommodationId) => {
    this.togglerSectionOffAccommodationsSet.add(accommodationId);
  }

  @action removeFromTogglerSectionOffAccommodationsSet = (accommodationId) => {
    this.togglerSectionOffAccommodationsSet.delete(accommodationId);
  }

  @action fetchAccommodationsRoster = async (classroomId, _page = 0) => {
    try {
      classroomId = classroomId || classroomManager.currentClassroomId;
      this.clearAccommodationsRoster();
      const response = await Auth.fetch(`${Auth.ecms}/api/viewAccommodationsByClassroom?classroomId=${classroomId}`, { method: 'GET' });
      if (response.status === 'SUCCESS') {
        const roster = response.data.sort((a, b) => {
          const lastNameA = a.lastName ? a.lastName : '';
          const lastNameB = b.lastName ? b.lastName : '';

          const firstNameA = a.firstName ? a.firstName : '';
          const firstNameB = b.firstName ? b.firstName : '';

          const predicate1 = lastNameA.localeCompare(lastNameB, 'en', {
            numeric: true
          });
          const predicate2 = firstNameA.localeCompare(firstNameB, 'en', {
            numeric: true
          });
          return predicate1 || predicate2;
        });
        await this.addToCurrentAccommodationsRoster(roster);
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action clearAccommodationsRoster = () => {
    this.currentAccommodationsRosterMap = new Map();
  }

  @action updateStudentOrClassroomAccommodationOption = async (userId, accommodationId, shouldRemove) => {
    try {
      let endpoint;
      if (shouldRemove) {
        endpoint = userId ? 'removeUserAccommodation' : 'removeUserAccommodationAll';
      } else {
        endpoint = userId ? 'addUserAccommodation' : 'addUserAccommodationAll';
      }
      const body = userId ? {
        accommodationId,
        userId,
        classroomId: classroomManager.currentClassroomId
      } : {
        accommodationId,
        classroomId: classroomManager.currentClassroomId
      };
      const apiUrl = `${Auth.ecms}/api/${endpoint}`;
      const response = await Auth.fetch(apiUrl, {
        body,
        method: 'POST'
      });
      if (response && response.status === 'SUCCESS') {
        if (userId) {
          this.updateAccommodationRosterItem(response.data);
        }
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  @action updateAccommodationRosterItem = async (rosterItem) => {
    const offAccommodations = rosterItem.accommodationIds.split(',');
    rosterItem.offAccommodations = offAccommodations;
    this.currentAccommodationsRosterMap.set(rosterItem.id, rosterItem);
  }

  @computed get currentAccommodationsRosterArray() {
    return Array.from(this.currentAccommodationsRosterMap?.values() || []);
  }

  @computed get togglerSectionOffAccommodations() {
    return Array.from(this.togglerSectionOffAccommodationsSet.values());
  }
}

export default new AccommodationsManager();
