import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Image
} from 'semantic-ui-react';
import { SatCoreRegister } from '../../SatCoreRegistry';
import '../../css/AvatarPicker.less';

import art1_avatar from '../../img/avatars/teacher/85px-art-01.png';
import art2_avatar from '../../img/avatars/teacher/85px-art-02.png';
import art3_avatar from '../../img/avatars/teacher/85px-art-03.png';
import art4_avatar from '../../img/avatars/teacher/85px-art-04.png';
import art5_avatar from '../../img/avatars/teacher/85px-art-05.png';
import art6_avatar from '../../img/avatars/teacher/85px-art-06.png';

import eng1_avatar from '../../img/avatars/teacher/85px-eng-01.png';
import eng2_avatar from '../../img/avatars/teacher/85px-eng-02.png';
import eng3_avatar from '../../img/avatars/teacher/85px-eng-03.png';
import eng4_avatar from '../../img/avatars/teacher/85px-eng-04.png';
import eng5_avatar from '../../img/avatars/teacher/85px-eng-05.png';
import eng6_avatar from '../../img/avatars/teacher/85px-eng-06.png';

import his1_avatar from '../../img/avatars/teacher/85px-his-01.png';
import his2_avatar from '../../img/avatars/teacher/85px-his-02.png';
import his3_avatar from '../../img/avatars/teacher/85px-his-03.png';
import his4_avatar from '../../img/avatars/teacher/85px-his-04.png';
import his5_avatar from '../../img/avatars/teacher/85px-his-05.png';
import his6_avatar from '../../img/avatars/teacher/85px-his-06.png';

import math1_avatar from '../../img/avatars/teacher/85px-math-01.png';
import math2_avatar from '../../img/avatars/teacher/85px-math-02.png';
import math3_avatar from '../../img/avatars/teacher/85px-math-03.png';
import math4_avatar from '../../img/avatars/teacher/85px-math-04.png';
import math5_avatar from '../../img/avatars/teacher/85px-math-05.png';
import math6_avatar from '../../img/avatars/teacher/85px-math-06.png';

import sci1_avatar from '../../img/avatars/teacher/85px-sci-01.png';
import sci2_avatar from '../../img/avatars/teacher/85px-sci-02.png';
import sci3_avatar from '../../img/avatars/teacher/85px-sci-03.png';
import sci4_avatar from '../../img/avatars/teacher/85px-sci-04.png';
import sci5_avatar from '../../img/avatars/teacher/85px-sci-05.png';
import sci6_avatar from '../../img/avatars/teacher/85px-sci-06.png';

export default
@inject('userManager')
@observer
class DefaultAvatarPicker extends Component {
  selectDefaultAvatar = (e) => {
    this.props.selectAvatar(e.target.name);
  };

  isAvatarSelected = (name) => {
    if (this.props.selectedAvatar === name) {
      return true;
    }
    return false;
  };

  saveDefaultAvatar = async () => {
    this.props.saveDefault();
  }

  render() {
    return (
      <div className='avatar-picker-container'>
        <div className='avatar-picker'>
          {/* art */}
          <Image
            className={this.isAvatarSelected('art-01') ? 'selected-avatar' : null}
            name='art-01'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(art1_avatar.indexOf('base64') > -1) ? art1_avatar : require(`./${art1_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('art-02') ? 'selected-avatar' : null}
            name='art-02'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(art2_avatar.indexOf('base64') > -1) ? art2_avatar : require(`./${art2_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('art-03') ? 'selected-avatar' : null}
            name='art-03'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(art3_avatar.indexOf('base64') > -1) ? art3_avatar : require(`./${art3_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('art-04') ? 'selected-avatar' : null}
            name='art-04'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(art4_avatar.indexOf('base64') > -1) ? art4_avatar : require(`./${art4_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('art-05') ? 'selected-avatar' : null}
            name='art-05'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(art5_avatar.indexOf('base64') > -1) ? art5_avatar : require(`./${art5_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('art-06') ? 'selected-avatar' : null}
            name='art-06'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(art6_avatar.indexOf('base64') > -1) ? art6_avatar : require(`./${art6_avatar}`).default} />
          {/* eng */}
          <Image
            className={this.isAvatarSelected('eng-01') ? 'selected-avatar' : null}
            name='eng-01'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(eng1_avatar.indexOf('base64') > -1) ? eng1_avatar : require(`./${eng1_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('eng-02') ? 'selected-avatar' : null}
            name='eng-02'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(eng2_avatar.indexOf('base64') > -1) ? eng2_avatar : require(`./${eng2_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('eng-03') ? 'selected-avatar' : null}
            name='eng-03'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(eng3_avatar.indexOf('base64') > -1) ? eng3_avatar : require(`./${eng3_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('eng-04') ? 'selected-avatar' : null}
            name='eng-04'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(eng4_avatar.indexOf('base64') > -1) ? eng4_avatar : require(`./${eng4_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('eng-05') ? 'selected-avatar' : null}
            name='eng-05'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(eng5_avatar.indexOf('base64') > -1) ? eng5_avatar : require(`./${eng5_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('eng-06') ? 'selected-avatar' : null}
            name='eng-06'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(eng6_avatar.indexOf('base64') > -1) ? eng6_avatar : require(`./${eng6_avatar}`).default} />
          {/* his */}
          <Image
            className={this.isAvatarSelected('his-01') ? 'selected-avatar' : null}
            name='his-01'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(his1_avatar.indexOf('base64') > -1) ? his1_avatar : require(`./${his1_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('his-02') ? 'selected-avatar' : null}
            name='his-02'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(his2_avatar.indexOf('base64') > -1) ? his2_avatar : require(`./${his2_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('his-03') ? 'selected-avatar' : null}
            name='his-03'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(his3_avatar.indexOf('base64') > -1) ? his3_avatar : require(`./${his3_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('his-04') ? 'selected-avatar' : null}
            name='his-04'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(his4_avatar.indexOf('base64') > -1) ? his4_avatar : require(`./${his4_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('his-05') ? 'selected-avatar' : null}
            name='his-05'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(his5_avatar.indexOf('base64') > -1) ? his5_avatar : require(`./${his5_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('his-06') ? 'selected-avatar' : null}
            name='his-06'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(his6_avatar.indexOf('base64') > -1) ? his6_avatar : require(`./${his6_avatar}`).default} />
          {/* math */}
          <Image
            className={this.isAvatarSelected('math-01') ? 'selected-avatar' : null}
            name='math-01'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(math1_avatar.indexOf('base64') > -1) ? math1_avatar : require(`./${math1_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('math-02') ? 'selected-avatar' : null}
            name='math-02'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(math2_avatar.indexOf('base64') > -1) ? math2_avatar : require(`./${math2_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('math-03') ? 'selected-avatar' : null}
            name='math-03'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(math3_avatar.indexOf('base64') > -1) ? math3_avatar : require(`./${math3_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('math-04') ? 'selected-avatar' : null}
            name='math-04'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(math4_avatar.indexOf('base64') > -1) ? math4_avatar : require(`./${math4_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('math-05') ? 'selected-avatar' : null}
            name='math-05'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(math5_avatar.indexOf('base64') > -1) ? math5_avatar : require(`./${math5_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('math-06') ? 'selected-avatar' : null}
            name='math-06'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(math6_avatar.indexOf('base64') > -1) ? math6_avatar : require(`./${math6_avatar}`).default} />
          {/* sci */}
          <Image
            className={this.isAvatarSelected('sci-01') ? 'selected-avatar' : null}
            name='sci-01'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(sci1_avatar.indexOf('base64') > -1) ? sci1_avatar : require(`./${sci1_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('sci-02') ? 'selected-avatar' : null}
            name='sci-02'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(sci2_avatar.indexOf('base64') > -1) ? sci2_avatar : require(`./${sci2_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('sci-03') ? 'selected-avatar' : null}
            name='sci-03'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(sci3_avatar.indexOf('base64') > -1) ? sci3_avatar : require(`./${sci3_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('sci-04') ? 'selected-avatar' : null}
            name='sci-04'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(sci4_avatar.indexOf('base64') > -1) ? sci4_avatar : require(`./${sci4_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('sci-05') ? 'selected-avatar' : null}
            name='sci-05'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(sci5_avatar.indexOf('base64') > -1) ? sci5_avatar : require(`./${sci5_avatar}`).default} />
          <Image
            className={this.isAvatarSelected('sci-06') ? 'selected-avatar' : null}
            name='sci-06'
            onClick={(e) => this.selectDefaultAvatar(e)}
            src={(sci6_avatar.indexOf('base64') > -1) ? sci6_avatar : require(`./${sci6_avatar}`).default} />
        </div>

      </div>
    );
  }
}

SatCoreRegister('DefaultAvatarPicker', DefaultAvatarPicker);
