/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Popup } from 'semantic-ui-react';
import '../css/ResourcePacingToolbar.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import { DIALOG_NAMES } from '../managers/DialogManager';

import ResourcePacingService, { RESOURCE_PACING_TOOLBAR_ITEM } from '../services/ResourcePacingService';

const ResourcePacingToolbar = observer((props) => {
  const { callback, resource, t } = props;

  const { courseDeliveryManager, dialogManager, productManager } = useContext(MobXProviderContext);

  const { showResourcePacingToolbar } = courseDeliveryManager;

  function pacingMode() {
    return ResourcePacingService.pacingMode(resource);
  }
  function visibilityMode() {
    return ResourcePacingService.visibilityMode(resource);
  }
  // function duration() {
  //   return ResourcePacingService.duration(resource);
  // }
  function attempts() {
    return ResourcePacingService.attempts(resource);
  }

  const isAssignableByTeacher = resource.deliveryMode === 'assignable';
  const isStudentFacingAlways = resource.deliveryMode === 'student_always';
  const isTeacherAssigned = pacingMode() === 'teacher_assigned';
  const isStudentPaced = pacingMode() === 'student_paced';
  const isVisibleToStudents = visibilityMode() === 'visible_to_students';
  const isHiddenFromStudents = visibilityMode() === 'hidden_from_students';

  const handleClickToolbarItem = (TOOLBAR_ITEM) => {
    const allowClickToolbarItem = !productManager.isFromProduct && TOOLBAR_ITEM.KEY !== 'manualScoring';
    if (allowClickToolbarItem) {
      if (TOOLBAR_ITEM.KEY !== 'manualScoring') {
        if (TOOLBAR_ITEM.KEY === 'assigned') {
          if (!dialogManager.openedDialogs.has(DIALOG_NAMES.CONTENT_ASSIGNMENT)) {
            props.showContentAssignments(resource);
          }
        } else {
          callback(TOOLBAR_ITEM, resource);
        }
      }
    }
  };

  const toolbarItemsJsx = [];
  let activeToolbarItemIndex = 0;
  for (const property in RESOURCE_PACING_TOOLBAR_ITEM) {
    // eslint-disable-next-line no-prototype-builtins
    if (RESOURCE_PACING_TOOLBAR_ITEM.hasOwnProperty(property)) {
      const TOOLBAR_ITEM = RESOURCE_PACING_TOOLBAR_ITEM[property];
      const allow = ResourcePacingService.allowResourceToolbarItem(TOOLBAR_ITEM);
      let shouldPushItemToToolbar;
      let toolbarItemTooltipSuffix;
      switch (TOOLBAR_ITEM.KEY) {
        case 'assigned':
          const { resourceActivityCount } = resource;
          const count = resourceActivityCount > 0 ? resourceActivityCount : 0;
          shouldPushItemToToolbar = allow && !!count;
          break;
        case 'teacherAssign':
          shouldPushItemToToolbar = allow && showResourcePacingToolbar && isTeacherAssigned && !isStudentFacingAlways;
          break;
        case 'studentPaced':
          shouldPushItemToToolbar = allow && showResourcePacingToolbar && isStudentPaced;
          break;
        case 'visibleToStudents':
          shouldPushItemToToolbar = allow && showResourcePacingToolbar && !isAssignableByTeacher && isVisibleToStudents;
          break;
        case 'hiddenFromStudents':
          shouldPushItemToToolbar = allow && showResourcePacingToolbar && !isAssignableByTeacher && isHiddenFromStudents;
          break;
        // case 'duration':
        //   shouldPushItemToToolbar = showResourcePacingToolbar && !isStudentFacingAlways;
        //   const durationStr = ResourcePacingService.getDurationString(duration());
        //   toolbarItemTooltipSuffix = durationStr;
        //   break;
        case 'attempts':
          shouldPushItemToToolbar = allow && showResourcePacingToolbar && isStudentPaced;
          toolbarItemTooltipSuffix = `${attempts()}`;
          break;
        case 'manualScoring':
          shouldPushItemToToolbar = resource.hasManualScoredItems && !isStudentFacingAlways;
          break;
        /* end switch */
      }
      if (shouldPushItemToToolbar) {
        const toolbarItemTooltipPrefixKey = `${TOOLBAR_ITEM.KEY}Prefix`;
        const toolbarItemTooltipSuffixKey = `${TOOLBAR_ITEM.KEY}Suffix`;

        const toolbarItemTooltipPrefix = t(toolbarItemTooltipPrefixKey);
        if (!toolbarItemTooltipSuffix) {
          toolbarItemTooltipSuffix = t(toolbarItemTooltipSuffixKey);
        }

        toolbarItemsJsx.push(
          <Popup
            key={TOOLBAR_ITEM.DASHED_KEY}
            className={`toolbar-item-popup ${TOOLBAR_ITEM.DASHED_KEY}`}
            content={(
              <>
                <strong>{toolbarItemTooltipPrefix}</strong>
                {' '}
                {toolbarItemTooltipSuffix}
              </>
            )}
            hoverable
            offset={activeToolbarItemIndex === 0 ? [-11, 0] : undefined}
            on='hover'
            position='bottom left'
            positionFixed
            trigger={(
              <div
                className={`toolbar-item-trigger ${TOOLBAR_ITEM.DASHED_KEY}`}
                onClick={() => { handleClickToolbarItem(TOOLBAR_ITEM); }}>
                <img alt='' src={TOOLBAR_ITEM.ICON} />
              </div>
            )}
            wide='very' />
        );
        activeToolbarItemIndex++;
      }
    }
  }
  return (
    <div className='resource-pacing-toolbar-container'>
      {toolbarItemsJsx.map((toolbarItemJsx) => toolbarItemJsx)}
    </div>
  );
});
export default ResourcePacingToolbar;

SatCoreRegister('ResourcePacingToolbar', ResourcePacingToolbar);
