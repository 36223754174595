import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Container, Form, Header,
  Input, Message
} from 'semantic-ui-react';

import '../../css/AddGroupModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import UtilityService from '../../services/UtilityService';

import RichTextEditor from '../RichTextEditor';
import Modal from '../Modal';

export default @inject(
  'classroomManager', 'navigationManager', 'groupsManager'
)
@observer
class AddGroupModal extends Component {
  constructor(props) {
    const { groupsManager } = props;
    super(props);
    this.state = this.getInitialState(groupsManager.getCurrentGroup());
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
  }

    getInitialState = (group) => {
      return {
        archived: group ? !group.active : false,
        editing: !!group,
        groupDescError: false,
        groupDescErrorMsg: '',
        groupDescription: group ? group.description : '',
        groupId: group ? group.id : '',
        groupName: group ? group.name : '',
        groupNameError: false,
        groupNameErrorMsg: '',
        groupStudents: group ? group.members : [],
        groupStudentsError: false,
        groupStudentsErrorMsg: '',
        savingGroup: false,
        serverError: false,
        serverErrorMsg: ''
      };
    };

    handleArchiveGroup = async (groupId) => {
      const { groupsManager } = this.props;

      // this option does not persist unsaved modal changes
      // Per Annette, do not save unsaved changes when archiving
      await groupsManager.archiveClassroomGroup(groupId);

      // this option does persist unsaved modal changes
      // this.setState({ archived: true });
      // this.submitAddGroup(true);

      const event = null;
      const didSave = true;
      const didArchive = true;
      this.closeAddGroupModal(event, didSave, didArchive);
    };

    closeAddGroupModal = (event, didSave, didArchive) => {
      const { groupsManager } = this.props;
      groupsManager.clearCurrentGroup();
      this.setState(this.getInitialState(null));
      const { closeAddGroup } = this.props;
      closeAddGroup(event, didSave, didArchive);
    };

    submitAddGroup = async (archived = false) => {
      const { editing, groupId, groupName, groupDescription, groupStudents } = this.state;
      const {
        classroomManager, groupsManager, t
      } = this.props;

      try {
        if (!this.validate()) {
          this.setState({ savingGroup: false });
          return;
        }

        let result = false;

        const saveGroupEntityId = editing ? groupId : classroomManager.currentClassroomId;
        const saveGroupFunction = editing ? groupsManager.updateClassroomGroup : groupsManager.createClassroomGroup;

        // clean the description
        const cleanDescription = UtilityService.reactHtmlParserWrapper(groupDescription).stripped;
        const description = (cleanDescription && cleanDescription.length > 0 && cleanDescription !== 'null') ? cleanDescription : '';

        // Add/Edit the new group
        saveGroupFunction(saveGroupEntityId, groupName, description, archived).then(async (data) => {
          if (data && data.status && data.status === 'SUCCESS') {
            // Add the group students
            if (groupsManager.currentGroupId) {
              const memberIds = [];
              if (groupStudents) {
                groupStudents.forEach((member) => memberIds.push(member.id));
              }
              result = await groupsManager.setClassroomGroupMembers(groupsManager.currentGroupId, memberIds);
            }
            if (result) {
              this.closeAddGroupModal(null, true);
            } else {
              // TODO SET AN ERROR MESSAGE FOR FAILED GROUP MEMBER ADDITION
            }
          } else {
            if (data && data.statusMessage) {
              if (data.statusMessage === 'The name must be unique') {
                this.setState({ groupNameError: true, groupNameErrorMsg: t('groupNameErrorMsg', 'Submit add group error missing') });
              }
            }
          }
        }).catch((err) => {
          console.error(err);
        });
        this.setState({ savingGroup: false });
        return;
      } catch (error) {
        console.error(error);
        throw new TypeError(t('submitAddGroupError', 'No submitAddGroup found'));
      }
    };

    handleCheckStudent = (student) => {
      let currentRoster = this.state.groupStudents.slice();
      const existingStudent = currentRoster.find((existingStudent) => (existingStudent.id === student.userId));

      if (existingStudent) {
        currentRoster = currentRoster.filter((existingStudent) => (existingStudent.id !== student.userId));
      } else {
        currentRoster.push({ id: student.userId, firstName: student.firstName, lastName: student.lastName });
      }

      this.setState({
        groupStudents: currentRoster,
        groupStudentsError: false,
        groupStudentsErrorMsg: ''
      });
    };

    validate = () => {
      const { groupName, groupDescription, groupStudents, editing } = this.state;
      let hasNoError = true;

      if (!groupName || groupName.length <= 0) {
        this.setState({
          groupNameError: true,
          groupNameErrorMsg: 'You must enter a valid group name.'
        });
        hasNoError = false;
      }

      if (groupName && groupName.length > 30) {
        this.setState({
          groupNameError: true,
          groupNameErrorMsg: 'Group name must be 30 characters or less.'
        });
        hasNoError = false;
      }

      if (groupDescription && UtilityService.reactHtmlParserWrapper(groupDescription).stripped.length > 500) {
        this.setState({
          groupDescError: true,
          groupDescErrorMsg: 'Group description must be 500 characters or less.'
        });
        hasNoError = false;
      }

      if (groupStudents.length <= 0) {
        this.setState({
          groupStudentsError: true,
          groupStudentsErrorMsg: 'You must select at least one student.'
        });
        hasNoError = false;
      }

      return hasNoError;
    };

    editorChange = (value) => {
      const groupDescription = (value && value.length > 0 && value !== 'null') ? value : '';
      this.setState({ groupDescription });
    };

    handleChangeGroupName = (_event, data) => {
      this.setState({
        groupName: data.value,
        groupNameError: false,
        groupNameErrorMsg: ''
      });
    };

    renderRoster = () => {
      const { classroomManager, groupsManager } = this.props;
      const { groupStudents } = this.state;
      const roster = groupsManager.rosterMap.get(classroomManager.currentClassroomId);
      const { SCCheckbox } = this;

      if (roster === null || roster === undefined) {
        return null;
      }

      return (
        <Container className='check-list'>
          {roster.map((student, index) => { // eslint-disable-line no-unused-vars
            const checked = groupStudents.find((member) => member.id === student.userId) !== undefined;
            const studentName = `${student.firstName} ${student.lastName}`;
            return (
              <SCCheckbox
                key={`${student.userId + student.id}_R`}
                checked={checked}
                label={`${student.firstName} ${student.lastName}`}
                onChange={() => {
                  this.handleCheckStudent(student);
                }}
                useHoverLabel={studentName && studentName.length > 23} />
            );
          })}
        </Container>
      );
    };

    render() {
      const {
        addGroupOpen, t
      } = this.props;

      const {
        editing, savingGroup, groupId, groupName, groupDescription, groupDescError,
        groupDescErrorMsg, groupNameError, groupNameErrorMsg, groupStudentsError, groupStudentsErrorMsg
      } = this.state;

      const { ModalBanner } = this;

      const modalTitle = editing ? 'editGroupTitle' : 'addGroupTitle';

      return (
        <Modal
          className='AddGroupModal'
          closeOnDimmerClick={false}
          closeOnEscape={true}
          onClose={(event) => this.closeAddGroupModal(event, false)}
          open={addGroupOpen}
          size='small'>
          <ModalBanner
            label={t(modalTitle, 'Add/Edit Group title missing')}
            onClose={(event) => this.closeAddGroupModal(event, false)} />
          <Modal.Content>
            <div className='group-banner'>
              <div className='title-wrapper'>
                <div
                  className='flex-header'
                  title={t('addGroupSubTitle', 'Add Group sub title missing')}>
                  {t('addGroupSubTitle', 'Add Group title missing')}
                </div>
              </div>
            </div>
            <Form>
              <Container className='group-wrapper-modal'>
                <div className='left-column'>
                  <Header as='h3'>
                    {t('groupNameLabel', 'Group Name missing')}
                    <Header.Subheader>
                      {t('groupNameSubLabel', 'Group Name sub label missing')}
                    </Header.Subheader>
                  </Header>
                  <Container className='field-wrapper'>
                    <Input
                      className='groupName'
                      name='groupName'
                      onChange={this.handleChangeGroupName}
                      placeholder={t('groupNamePlaceholder', 'Group Name Placeholder missing')}
                      type='text'
                      value={groupName} />
                  </Container>
                  <Message
                    className='group-name-message'
                    content={groupNameErrorMsg}
                    error
                    visible={groupNameError} />
                  <Header as='h3'>
                    {t('groupDescriptionLabel', 'Group Description Missing')}
                    <Header.Subheader>
                      {t('groupDescriptionSubLabel', 'Group Description sub label missing')}
                    </Header.Subheader>
                  </Header>
                  <Container className='editor-container'>
                    <Form.Field>
                      <RichTextEditor
                        data={groupDescription}
                        maxCharacters={500}
                        onChange={this.editorChange} />
                    </Form.Field>
                  </Container>
                  <Message
                    className='group-name-message'
                    content={groupDescErrorMsg}
                    error
                    visible={groupDescError} />
                  <Header.Subheader>
                    * = Required field
                  </Header.Subheader>
                </div>
                <div className='right-column'>
                  <Header as='h3'>
                    {t('selectStudentsLabel', 'Select Students missing')}
                  </Header>
                  <Message
                    className='group-name-message'
                    content={groupStudentsErrorMsg}
                    error
                    visible={groupStudentsError} />
                  <Container className='field-wrapper'>
                    <Form.Field>
                      {this.renderRoster()}
                    </Form.Field>
                  </Container>
                </div>
              </Container>
            </Form>
          </Modal.Content>
          <Modal.Actions className={editing ? 'editing' : ''}>
            {editing && (
            <Button
              basic
              className='cancelButton'
              onClick={() => { this.handleArchiveGroup(groupId); }}
              primary>
              {t('archiveGroupButtonText', 'Archive Group')}
            </Button>
            )}
            <div className='saveCancelWrapper'>
              <Button
                basic
                className='cancelButton'
                onClick={(event) => this.closeAddGroupModal(event, false)}
                primary>
                {t('cancelButtonText', 'Cancel Missing')}
              </Button>
              <Button
                className='ui primary button saveButton'
                loading={savingGroup}
                onClick={(event) => {
                  this.submitAddGroup().catch((error) => {
                    error = typeof error === 'string' ? error : t('submitAddGroupError');
                    alert(error);
                    this.closeAddGroupModal(event, false);
                  });
                }}
                type='button'>
                {editing ?
                  t('editButtonText', 'Edit Group Missing')
                  :
                  t('addButtonText', 'Add Group Missing')}
              </Button>
            </div>
          </Modal.Actions>
        </Modal>
      );
    }
}

SatCoreRegister('AddGroupModal', AddGroupModal);
