/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { DateInput, TimeInput } from 'semantic-ui-calendar-react';

import {
  Button, Checkbox, Confirm,
  Input, Message, Radio
} from 'semantic-ui-react';

import Modal from '../Modal';

import '../../css/ResourcePacingModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import ResourcePacingService, {
  INIT_DURATION_DAYS_STUDENT_PACED,
  INIT_DURATION_DAYS_TEACHER_ASSIGNED,
  INIT_DURATION_HOURS_STUDENT_PACED,
  INIT_DURATION_HOURS_TEACHER_ASSIGNED,
  RESOURCE_PACING_TOOLBAR_ITEM
} from '../../services/ResourcePacingService';

import UtilityService from '../../services/UtilityService';

import ActivityModeDropdown from '../ActivityModeDropdown';
import RichTextEditor from '../RichTextEditor';

const ResourcePacingModal = observer((props) => {
  const { resource, t } = props;
  const { courseDeliveryManager, courseManager, assignmentManager } = useContext(MobXProviderContext);

  const isCustomCourse = courseManager.isCustomCourse(resource.id, resource.name, resource);

  const originalResource = { ...resource };

  const { deliveryMode } = resource;

  const urlParams = new URLSearchParams(window.location.search);
  const classroomId = urlParams.get('classroomId');
  const courseId = urlParams.get('courseId');

  const ModalBanner = SatCoreComponent('ModalBanner');

  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState();

  const [isConfirmResetOpen, setIsConfirmResetOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (props.isOpen && props.toolbarItem?.KEY) {
        const shouldAutoToggle = ResourcePacingService.shouldAutoToggleResourceToolbarItemWithoutOpeningModal(props.toolbarItem);
        if (shouldAutoToggle) {
          await handleAutoToggleResourcePacingToolbarItem();
        } else {
          setIsOpen(true);
        }
      }
    })();
  }, []);

  /** @returns {'teacher_assigned' | 'student_paced'} `originalResource.pacingModeOverride || 'teacher_assigned'` */
  function initPacingMode() {
    return originalResource.pacingModeOverride || 'teacher_assigned';
  }
  /** @returns {string} `originalResource.alternateModeId or originalResource.alternateModeIdOverride ` */
  function initLessonMode() {
    return originalResource.alternateModeIdOverride || originalResource.alternateModeId;
  }
  /** @returns {'visible_to_students' | 'hidden_from_students'} `originalResource.visibilityModeOverride` */
  function initVisibilityMode() {
    return originalResource.visibilityModeOverride;
  }
  /** @returns {boolean} `uses originalResource.endDateOverride to determine display` */
  function initShowDuration() {
    return originalResource.endDateOverride == null; // show duration if there is no end date override.
  }
  /** @returns {number} `originalResource.durationOverride` */
  function initDuration() {
    return originalResource.durationOverride;
  }
  /** @returns {boolean} `uses originalResource.endDateOverride to determine display` */
  function initShowAvailableUntil() {
    return originalResource.endDateOverride != null; // show available until if there is a value for end date override.
  }
  /** @returns {boolean} `originalResource.includeInReportsOverride` */
  function initIncludeInReports() {
    return !!originalResource.includeInReportsOverride;
  }
  /** @returns {boolean} `originalResource.scoresReleasedOverride` */
  function initScoresReleased() {
    return !!originalResource.scoresReleasedOverride;
  }
  /** @returns {boolean} `originalResource.studentReviewOverride` */
  function initStudentReview() {
    return !!originalResource.studentReviewOverride;
  }
  /** @returns {number} `originalResource.attemptsOverride || 0` */
  function initAttempts() {
    return originalResource.attemptsOverride || 0;
  }
  /** @returns {string} `originalResource.instructionOverride || 0` */
  function initStudentInstructions() {
    const { instructionOverride } = originalResource;
    const instruction = instructionOverride && instructionOverride !== 'null' ? instructionOverride : '';
    return instruction;
  }
  /** @returns {date} `originalResource.endDateOverride date portion` */
  function initDueDate() {
    const { endDateOverride } = originalResource;
    const dueDate = endDateOverride ? assignmentManager.getJSDateTimeStringParts(endDateOverride).date : '';
    return dueDate;
  }
  /** @returns {date} `originalResource.endDateOverride time portion` */
  function initDueTime() {
    const { endDateOverride } = originalResource;
    const dueTime = endDateOverride ? assignmentManager.getJSDateTimeStringParts(endDateOverride).time : '';
    return dueTime;
  }

  /* PACING: `teacher_assigned` or `student_paced` */
  const [pacingMode, setPacingMode] = useState(initPacingMode());

  /* MODE: which mode to use */
  const [alternateModeId, setLessonMode] = useState(initLessonMode());

  /* VISIBILITY: `visible_to_students` or `hidden_from_students` */
  const [visibilityMode, setVisibilityMode] = useState(initVisibilityMode());

  /* DURATION */
  const [showDuration, setShowDuration] = useState(initShowDuration());
  const ONE_DAY = 24;
  const [durationDays, setDurationDays] = useState(ResourcePacingService.getDurationDays(initDuration()));
  const [durationHours, setDurationHours] = useState(ResourcePacingService.getDurationHours(initDuration()));

  /* DUE DATE */
  const [showAvailableUntil, setShowAvailableUntil] = useState(initShowAvailableUntil());
  const [dueDate, setDuedate] = useState(initDueDate());
  const [dueTime, setDuetime] = useState(initDueTime());

  /* REPORTS AND GRADING */
  const [includeInReports, setIncludeInReports] = useState(initIncludeInReports());
  const [scoresReleased, setScoresReleased] = useState(initScoresReleased());
  const [studentReview, setStudentReview] = useState(initStudentReview());

  /* ATTEMPTS */
  const [attempts, setAttempts] = useState(initAttempts());

  const [errorMsgHeader, setErrorMsgHeader] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  /* INSTRUCTIONS */
  const [studentInstructions, setStudentInstructions] = useState(initStudentInstructions);

  const handleAutoToggleResourcePacingToolbarItem = async () => {
    const toolbarItemKey = props.toolbarItem.KEY;
    if (resource.pacingOverrideAllowed && (toolbarItemKey === 'teacherAssign' || toolbarItemKey === 'studentPaced')) {
      await handleAutoTogglePacingOption();
    } else if (toolbarItemKey === 'visibleToStudents' || toolbarItemKey === 'hiddenFromStudents') {
      await handleAutoToggleVisibilityOption();
    } else {
      props.onClose();
    }
  };

  const handleAutoTogglePacingOption = async () => {
    const toolbarItemKey = props.toolbarItem.KEY;
    // change to student paced if currently teacher assigned and vice versa
    await handleSave({
      _durationDays: toolbarItemKey === 'teacherAssign' ? INIT_DURATION_DAYS_STUDENT_PACED : INIT_DURATION_DAYS_TEACHER_ASSIGNED,
      _durationHours: toolbarItemKey === 'teacherAssign' ? INIT_DURATION_HOURS_STUDENT_PACED : INIT_DURATION_HOURS_TEACHER_ASSIGNED,
      _pacingMode: toolbarItemKey === 'teacherAssign' ? 'student_paced' : 'teacher_assigned'
    });
    const shouldRefresh = true;
    return props.onClose(shouldRefresh);
  };

  const handleAutoToggleVisibilityOption = async () => {
    const toolbarItemKey = props.toolbarItem.KEY;
    // change to visible if currently hidden and vice versa
    await handleSave({
      _visibilityMode: toolbarItemKey === 'visibleToStudents' ? 'hidden_from_students' : 'visible_to_students'
    });
    const shouldRefresh = true;
    return props.onClose(shouldRefresh);
  };

  const handleChangePacingMode = async (_event, { value }) => {
    setPacingMode(value);
    if (value === 'teacher_assigned') {
      setDurationDays(INIT_DURATION_DAYS_TEACHER_ASSIGNED);
      setDurationHours(INIT_DURATION_HOURS_TEACHER_ASSIGNED);
    } else if (value === 'student_paced') {
      setDurationDays(INIT_DURATION_DAYS_STUDENT_PACED);
      setDurationHours(INIT_DURATION_HOURS_STUDENT_PACED);
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleChangeVisibilityMode = async (_event, { value }) => {
    setVisibilityMode(value);
  };

  const handleShowDuration = (_event, data) => {
    const showDuration = data.checked;
    setShowDuration(showDuration);
    setShowAvailableUntil(!showDuration);
  };

  const handleShowAvailableUntil = (_event, data) => {
    const showAvailableUntil = data.checked;
    setShowDuration(!showAvailableUntil);
    setShowAvailableUntil(showAvailableUntil);
  };

  const handleChangeDurationDays = (_event, { value }) => {
    /** ensure `days` is less than 5 chars and a non-decimal **number** */
    let days = value ? value.substring(0, 5) : '';
    days = +(days.replaceAll('.', ''));
    if (typeof days === 'number' && days >= 0) {
      setDurationDays(days);
      if (errorMsgHeader === 'errorMsgHeaderDays') {
        setErrorMsgHeader(null);
        setErrorMsg(null);
      }
    }
    if (typeof days === 'number' && !durationHours && days < 1) {
      setErrorMsgHeader('errorMsgHeaderDays');
      setErrorMsg('errorMsgDays');
    }
  };

  const handleChangeDurationHours = (_event, { value }) => {
    /** ensure `hours` is less than 5 chars and a non-decimal **number** less than `ONE_DAY` */
    let hours = value ? value.substring(0, 5) : '';
    hours = +(hours.replaceAll('.', ''));
    if (typeof hours === 'number' && hours >= 0 && hours < ONE_DAY) {
      setDurationHours(hours);
      if (typeof durationDays === 'number' && !hours && durationDays < 1) {
        setErrorMsgHeader('errorMsgHeaderDays');
        setErrorMsg('errorMsgDays');
      } else if (errorMsgHeader === 'errorMsgHeaderDays') {
        setErrorMsgHeader(null);
        setErrorMsg(null);
      }
      if (errorMsgHeader === 'errorMsgHeaderHours') {
        setErrorMsgHeader(null);
        setErrorMsg(null);
      }
    } else if (typeof hours === 'number' && hours >= ONE_DAY) {
      setDurationHours(hours);
      setErrorMsgHeader('errorMsgHeaderHours');
      setErrorMsg('errorMsgHours');
    }
  };

  const handleChangeDueDate = (event, { value }) => {
    setDuedate(value);
  };

  const handleChangeDueTime = (event, { value }) => {
    const dueTime = value ? value.toUpperCase() : '';
    setDuetime(dueTime);
  };

  const handleRelease = (name, value) => {
    if (name === 'includeInReports') {
      setIncludeInReports(value);
    }
    if (name === 'studentReview') {
      setStudentReview(value);
    }
    if (name === 'scoresReleased') {
      setScoresReleased(value);
    }
  };

  const handleChangeAttempts = (_event, { value }) => {
    /** ensure `attempts` is less than 5 chars and a non-decimal **number** */
    let attempts = value ? value.substring(0, 5) : '';
    attempts = +(attempts.replaceAll('.', ''));
    if (typeof attempts === 'number' && attempts >= 0) {
      setAttempts(attempts);
    }
  };

  const handleStudentInstructions = (value) => {
    setStudentInstructions(value);
  };

  const handleResetToPublisherSettings = async (shouldNotCloseModal = false) => {
    setLoading(true);
    setIsConfirmResetOpen(false);
    await courseDeliveryManager.deleteCourseDeliveryOptionsOverride(
      classroomId, courseId, resource.elementId
    );
    courseManager.changeFullCourseResourceVisibilityMode(resource.elementId);
    if (shouldNotCloseModal) {
      resetSettingsToOriginalResource();
      setLoading(false);
    } else {
      setIsOpen(false);
      setLoading(false);
      const shouldRefresh = true;
      return props.onClose(shouldRefresh);
    }
  };

  const resetSettingsToOriginalResource = () => {
    setPacingMode(initPacingMode());
    setLessonMode(initLessonMode());
    setVisibilityMode(initVisibilityMode());
    setDurationDays(ResourcePacingService.getDurationDays(initDuration()));
    setDurationHours(ResourcePacingService.getDurationHours(initDuration()));
    setIncludeInReports(initIncludeInReports());
    setScoresReleased(initScoresReleased());
    setStudentReview(initStudentReview());
    setAttempts(initAttempts());
  };

  const handleCancel = () => props.onClose();

  const handleSave = async ({
    _durationDays,
    _durationHours,
    _pacingMode,
    _visibilityMode
  }) => {
    setLoading(true);
    const duration = ResourcePacingService.getTotalDuration(
      (typeof _durationDays === 'number' ? _durationDays : durationDays),
      (typeof _durationHours === 'number' ? _durationHours : durationHours)
    );
    // Set dueDate to empty if dueDate is not currently selected.
    const dueRealDate = showAvailableUntil ? assignmentManager.convertJSStringToJSDate(dueDate, dueTime) : '';
    await courseDeliveryManager.overrideCourseDeliveryOptions(
      classroomId,
      courseId,
      resource.elementId,
      (_pacingMode ? _pacingMode : pacingMode),
      (_visibilityMode ? _visibilityMode : visibilityMode),
      duration,
      attempts,
      includeInReports,
      scoresReleased,
      studentReview,
      studentInstructions,
      dueRealDate,
      alternateModeId
    );
    courseManager.changeFullCourseResourceVisibilityMode(resource.elementId, visibilityMode);
    setIsOpen(false);
    setLoading(false);
    const shouldRefresh = true;
    return props.onClose(shouldRefresh);
  };

  const renderModalContentSection = (label) => {
    let options = null;
    let timing = null;
    let reportsAndGrading = null;
    let attempts = null;
    let instructions = null;
    switch (label) {
      case 'pacing':
        options = renderOptions('pacing');
        break;
      case 'mode':
        options = renderMode('mode');
        break;
      case 'visibility':
        options = renderOptions('visibility');
        break;
      case 'timing':
        timing = renderTiming();
        break;
      case 'attempts':
        attempts = renderAttempts();
        break;
      case 'reportsAndGrading':
        reportsAndGrading = renderReportsGrading();
        break;
      case 'instructions':
        instructions = renderStudentInstruction();
    }
    return (
      <div className={`modal-section ${label}`}>
        <div className={`label ${label}`}>
          {t(label)}
        </div>
        <div className={`modal-section-content ${label}`}>
          {options}
          {timing}
          {attempts}
          {reportsAndGrading}
          {instructions}
        </div>
      </div>
    );
  };

  const renderOptions = (optionsType) => {
    const {
      HIDDEN_FROM_STUDENTS, STUDENT_PACED,
      TEACHER_ASSIGN, VISIBLE_TO_STUDENTS
    } = RESOURCE_PACING_TOOLBAR_ITEM;
    switch (optionsType) {
      case 'pacing':
        return (
          <>
            {(resource.pacingOverrideAllowed || pacingMode === 'teacher_assigned') &&
          renderOption(
            TEACHER_ASSIGN,
            resource.pacingOverrideAllowed ? (
              <Checkbox
                key='teacher_assigned'
                checked={pacingMode === 'teacher_assigned'}
                name='pacing'
                onChange={handleChangePacingMode}
                radio
                value='teacher_assigned' />
            ) : <div className='checkbox-radio-placeholder' />
          )}
            {(resource.pacingOverrideAllowed || pacingMode === 'student_paced') &&
          renderOption(
            STUDENT_PACED,
            resource.pacingOverrideAllowed ? (
              <Checkbox
                key='student_paced'
                checked={pacingMode === 'student_paced'}
                name='pacing'
                onChange={handleChangePacingMode}
                radio
                value='student_paced' />
            ) : <div className='checkbox-radio-placeholder' />
          )}
          </>
        );
      case 'visibility':
        return (
          <>
            {renderOption(
              VISIBLE_TO_STUDENTS,
              <Checkbox
                key='visible_to_students'
                checked={visibilityMode === 'visible_to_students'}
                name='visibility'
                onChange={handleChangeVisibilityMode}
                radio
                value='visible_to_students' />
            )}
            {renderOption(
              HIDDEN_FROM_STUDENTS,
              <Checkbox
                key='hidden_from_students'
                checked={visibilityMode === 'hidden_from_students'}
                name='visibility'
                onChange={handleChangeVisibilityMode}
                radio
                value='hidden_from_students' />
            )}
          </>
        );
      /* end renderOptions switch */
    }
  };

  const renderOption = (TOOLBAR_ITEM, checkboxRadioJsx) => (
    <div className={`resource-option ${TOOLBAR_ITEM.DASHED_KEY}`}>
      {checkboxRadioJsx}
      <div className='resource-option-img-wrapper'>
        <img src={TOOLBAR_ITEM.ICON} />
      </div>
      <div className='resource-option-text-wrapper'>
        <div className='resource-option-label'>
          {t(TOOLBAR_ITEM.KEY)}
        </div>
        <div className='resource-option-description'>
          {t(`${TOOLBAR_ITEM.KEY}Description`)}
        </div>
      </div>
    </div>
  );

  const renderTiming = () => {
    const TOOLBAR_ITEM_DURATION = RESOURCE_PACING_TOOLBAR_ITEM.DURATION;
    const TOOLBAR_ITEM_AVAILABLE_UNTIL = RESOURCE_PACING_TOOLBAR_ITEM.AVAILABlE_UNTIL;
    return (
      <>
        {renderOption(
          TOOLBAR_ITEM_DURATION,
          <Checkbox
            key='show_duration'
            checked={showDuration}
            name='show_duration'
            onChange={handleShowDuration}
            radio />
        )}
        {showDuration && (
          <div className='resource-duration-input-wrapper'>
            <Input
              className='input-duration days'
              onChange={handleChangeDurationDays}
              size='mini'
              value={durationDays} />
            <div className='input-duration-label days'>
              {t('days')}
            </div>
            <Input
              className='input-duration hours'
              onChange={handleChangeDurationHours}
              size='mini'
              value={durationHours} />
            <div className='input-duration-label days'>
              {t('hours')}
            </div>
          </div>
        )}
        {renderOption(
          TOOLBAR_ITEM_AVAILABLE_UNTIL,
          <Checkbox
            key='show_available_until'
            checked={showAvailableUntil}
            name='show_available_until'
            onChange={handleShowAvailableUntil}
            radio />
        )}
        {showAvailableUntil && (
          <div className='resource-duration-input-wrapper'>
            <DateInput
              animation='false'
              className='input-duration date'
              dateFormat='MM/DD/YYYY'
              iconPosition='left'
              label={t('dueDate')}
              name='endDate'
              onChange={handleChangeDueDate}
              placeholder='Due Date'
              value={(dueDate !== null) ? dueDate : ''} />
            <TimeInput
              animation='false'
              className='input-duration time'
              iconPosition='left'
              label={t('dueTime')}
              name='endTime'
              onChange={handleChangeDueTime}
              placeholder='Due Time'
              timeFormat='AMPM'
              value={(dueTime !== null) ? dueTime : ''} />
          </div>
        )}
      </>
    );
  };

  const renderReportsGrading = () => {
    const TOOLBAR_ITEM = RESOURCE_PACING_TOOLBAR_ITEM.REPORTS_AND_GRADING;
    return (
      <div className='resource-reports-grading'>
        <div className='resource-reports-grading-description'>
          {t(`${TOOLBAR_ITEM.KEY}Description`)}
        </div>
        <div className='resource-reports-grading-input-wrapper'>
          <Radio
            checked={includeInReports}
            label={t('releaseToReportsLabel', 'Release to reports')}
            onChange={() => {
              handleRelease('includeInReports', !includeInReports);
            }}
            toggle />
          <Radio
            checked={scoresReleased}
            label={t('scoresReleased')}
            onChange={() => {
              handleRelease('scoresReleased', !scoresReleased);
            }}
            toggle />
          <Radio
            checked={studentReview}
            label={t('studentReview')}
            onChange={() => {
              handleRelease('studentReview', !studentReview);
            }}
            toggle />
        </div>
      </div>
    );
  };

  const renderAttempts = () => {
    const TOOLBAR_ITEM_ATTEMPTS = RESOURCE_PACING_TOOLBAR_ITEM.ATTEMPTS;
    return (
      <div className='resource-attempts'>
        <div className='resource-attempts-input-wrapper'>
          <div className='resource-attempts-img-wrapper'>
            <img src={TOOLBAR_ITEM_ATTEMPTS.ICON} />
          </div>
          <Input
            className='input-attempts'
            onChange={handleChangeAttempts}
            size='mini'
            value={attempts} />
        </div>
      </div>
    );
  };

  const renderStudentInstruction = () => {
    const TOOLBAR_ITEM = RESOURCE_PACING_TOOLBAR_ITEM.INSTRUCTIONS;
    return (
      <div className='resource-student-instruction'>
        <div className='resource-student-instruction-input-wrapper'>
          <RichTextEditor data={studentInstructions} maxCharacters={500} onChange={handleStudentInstructions} placeHolder={t('studentInstructionPrompt', 'Missing prompt translation')} />
        </div>
      </div>
    );
  };

  const renderMode = () => {
    const TOOLBAR_ITEM = RESOURCE_PACING_TOOLBAR_ITEM.MODE;
    if (resource.modeOverrideAllowed) {
      return (
        <div className='resource-lesson-mode'>
          <div className='resource-lesson-mode-input-wrapper'>
            <ActivityModeDropdown
              activityMode={alternateModeId}
              courseResourceElementId={resource.elementId}
              setActivityMode={setLessonMode} />
          </div>
        </div>
      );
    } else {
      return '';
    }
  };

  const nameObj = UtilityService.reactHtmlParserWrapper(resource.name);
  const name = nameObj && nameObj.stripped ? nameObj.stripped : '';

  const isUnitOrSection = resource.type === 'UNIT' || resource.type === 'SECTION';

  return (
    <>
      <Modal
        className='resource-pacing-modal-container'
        closeOnDimmerClick={false}
        closeOnEscape={true}
        onClose={() => {
          handleCancel();
        }}
        onOpen={() => {
          handleOpen();
        }}
        open={isOpen}>
        <ModalBanner
          label={`${t('settings')}:`}
          onClose={() => {
            handleCancel();
          }}
          title={name} />
        <Modal.Content>
          {!isUnitOrSection && deliveryMode !== 'student_always' ? renderModalContentSection('pacing') : null}
          {resource.modeOverrideAllowed ? renderModalContentSection('mode') : null}
          {deliveryMode !== 'assignable' && renderModalContentSection('visibility')}
          {pacingMode === 'student_paced' && !isUnitOrSection && deliveryMode !== 'student_always' ? renderModalContentSection('timing') : null}
          {pacingMode === 'student_paced' ? renderModalContentSection('attempts') : null}
          {!isUnitOrSection && deliveryMode !== 'student_always' ? renderModalContentSection('reportsAndGrading') : null}
          {pacingMode === 'student_paced' ? renderModalContentSection('instructions') : null}
          {errorMsgHeader && (
          <Message
            className='resource-pacing-modal-error-msg'
            content={t(errorMsg)}
            error
            header={t(errorMsgHeader)}
            onDismiss={() => {
              setErrorMsgHeader(null);
              setErrorMsg(null);
            }}
            visible />
          )}
        </Modal.Content>
        <Modal.Actions>
          <div className='resource-pacing-modal-buttons'>
            <div className='buttons-left'>
              {!isCustomCourse && (
                <Button
                  basic
                  className='button-reset'
                  content={t('reset')}
                  onClick={() => setIsConfirmResetOpen(true)}
                  primary />
              )}
            </div>
            <div className='buttons-center'>
              {/* placeholder */}
            </div>
            <div className='buttons-right'>
              <Button
                basic
                className='button-cancel'
                content={t('cancel')}
                onClick={handleCancel}
                primary />
              <Button
                className='button-save'
                content={t('save')}
                disabled={!!errorMsgHeader}
                loading={loading}
                onClick={handleSave}
                primary />
            </div>
          </div>
        </Modal.Actions>
        <Confirm
          content={t('askConfirmReset')}
          header={t('confirm')}
          onCancel={() => {
            setIsConfirmResetOpen(false);
          }}
          onConfirm={() => { handleResetToPublisherSettings(); }}
          open={isConfirmResetOpen} />
      </Modal>
    </>
  );
});
export default ResourcePacingModal;

SatCoreRegister('ResourcePacingModal', ResourcePacingModal);
