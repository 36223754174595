import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button } from 'semantic-ui-react';

import '../css/Dialogs.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { DIALOG_NAMES } from '../managers/DialogManager';

import Modal from './Modal';

export default @inject('dialogManager')
@observer
class DialogList extends Component {
  constructor(props) {
    super(props);
    this.AddAssignmentModal = SatCoreComponent('AddAssignmentModal');
    this.AddClassModal = SatCoreComponent('AddClassModal');
    this.AddCourseToClassModal = SatCoreComponent('AddCourseToClassModal');
    this.AddCustomAssessmentModal = SatCoreComponent('AddCustomAssessmentModal');
    this.AddGroupModal = SatCoreComponent('AddGroupModal');
    this.AddJoinClassModal = SatCoreComponent('AddJoinClassModal');
    this.AddLTIAssignmentModal = SatCoreComponent('AddLTIAssignmentModal');
    this.AddLibraryCourseToClassroomsModal = SatCoreComponent('AddLibraryCourseToClassroomsModal');
    this.AddStudentToClassroomsModal = SatCoreComponent('AddStudentToClassroomsModal');
    this.AddStudentsToClassroomModal = SatCoreComponent('AddStudentsToClassroomModal');
    this.AddTeacherToClassroomsModal = SatCoreComponent('AddTeacherToClassroomsModal');
    this.ArchiveOrActivateStudentModal = SatCoreComponent('ArchiveOrActivateStudentModal');
    this.AssessmentEditorModal = SatCoreComponent('AssessmentEditorModal');
    this.AssessmentItemShopModal = SatCoreComponent('AssessmentItemShopModal');
    this.AssignmentInfoModal = SatCoreComponent('AssignmentInfoModal');
    this.AvatarPicker = SatCoreComponent('AvatarPicker');
    this.CertificateModal = SatCoreComponent('CertificateModal');
    this.ChangePassword = SatCoreComponent('ChangePassword');
    this.ClassImagePicker = SatCoreComponent('ClassImagePicker');
    this.ClassroomReportTypeSelectorModal = SatCoreComponent('ClassroomReportTypeSelectorModal');
    this.ContentAssignmentModal = SatCoreComponent('ContentAssignmentModal');
    this.ContentElementModal = SatCoreComponent('ContentElementModal');
    this.ContentPreviewDialog = SatCoreComponent('ContentPreviewDialog');
    this.CopyLibraryResourceModal = SatCoreComponent('CopyLibraryResourceModal');
    this.CourseElementModal = SatCoreComponent('CourseElementModal');
    this.CreateAssessmentBankUnitModal = SatCoreComponent('CreateAssessmentBankUnitModal');
    this.CreateNewLibraryResourceModal = SatCoreComponent('CreateNewLibraryResourceModal');
    this.EditAssignmentModal = SatCoreComponent('EditAssignmentModal');
    this.EditAssignmentNotesModal = SatCoreComponent('EditAssignmentNotesModal');
    this.EditBulkAssignmentModal = SatCoreComponent('EditBulkAssignmentModal');
    this.ErrorModal = SatCoreComponent('ErrorModal');
    this.ExportGradesModal = SatCoreComponent('ExportGradesModal');
    this.ExportReportModal = SatCoreComponent('ExportReportModal');
    this.GeneralMessageModal = SatCoreComponent('GeneralMessageModal');
    this.InfoModal = SatCoreComponent('InfoModal');
    this.ItemBankItemPreviewModal = SatCoreComponent('ItemBankItemPreviewModal');
    this.ItemBankViewModal = SatCoreComponent('ItemBankViewModal');
    this.JoinClassModal = SatCoreComponent('JoinClassModal');
    this.LearnosityItemPreviewModal = SatCoreComponent('LearnosityItemPreviewModal');
    this.LibraryAssessmentBankViewModal = SatCoreComponent('LibraryAssessmentBankViewModal');
    this.LibraryAssessmentBuilderModal = SatCoreComponent('LibraryAssessmentBuilderModal');
    this.LibraryAssessmentShopModal = SatCoreComponent('LibraryAssessmentShopModal');
    this.LibraryContentUsageModal = SatCoreComponent('LibraryContentUsageModal');
    this.LibraryResourceDetailsModal = SatCoreComponent('LibraryResourceDetailsModal');
    this.LibraryShareAssessmentUsersModal = SatCoreComponent('LibraryShareAssessmentUsersModal');
    this.LibraryStandardsTagsModal = SatCoreComponent('LibraryStandardsTagsModal');
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.MigrateStudentDataModal = SatCoreComponent('MigrateStudentDataModal');
    this.ReactivateClassModal = SatCoreComponent('ReactivateClassModal');
    this.RegisterStudentModal = SatCoreComponent('RegisterStudentModal');
    this.ReportHideColumnsModal = SatCoreComponent('ReportHideColumnsModal');
    this.ResourceBankViewModal = SatCoreComponent('ResourceBankViewModal');
    this.ResourceCartModal = SatCoreComponent('ResourceCartModal');
    this.ResourceShopModal = SatCoreComponent('ResourceShopModal');
    this.SchoolImagePicker = SatCoreComponent('SchoolImagePicker');
    this.ScoreAssignmentModal = SatCoreComponent('ScoreAssignmentModal');
    this.SelectLessonProfileModal = SatCoreComponent('SelectLessonProfileModal');
    this.SelectNewLibraryResourceModal = SatCoreComponent('SelectNewLibraryResourceModal');
    this.SelectNewResourceModal = SatCoreComponent('SelectNewResourceModal');
    this.SelectPreviewModeModal = SatCoreComponent('SelectPreviewModeModal');
    this.ShareAssessmentModal = SatCoreComponent('ShareAssessmentModal');
    this.StandardsDocumentsModal = SatCoreComponent('StandardsDocumentsModal');
    this.StandardsModal = SatCoreComponent('StandardsModal');
    this.StudentAssignmentModal = SatCoreComponent('StudentAssignmentModal');
    this.StudentFeedbackModal = SatCoreComponent('StudentFeedbackModal');
    this.TagsModal = SatCoreComponent('TagsModal');
    this.TagsStandardsModal = SatCoreComponent('TagsStandardsModal');
    this.TeacherFeedbackModal = SatCoreComponent('TeacherFeedbackModal');
    this.TeacherLibraryResourceDetailsModal = SatCoreComponent('TeacherLibraryResourceDetailsModal');
    this.TrialEnterPurchaseCodesModal = SatCoreComponent('TrialEnterPurchaseCodesModal');
    this.UpdateUserPasswordModal = SatCoreComponent('UpdateUserPasswordModal');
    this.ViewNotificationModal = SatCoreComponent('ViewNotificationModal');
    this.WorkspaceFileModal = SatCoreComponent('WorkspaceFileModal');
    this.WorkspaceFileRenameModal = SatCoreComponent('WorkspaceFileRenameModal');
  }

  textModal = () => {
    const { dialogManager } = this.props;
    const { openedDialogs } = dialogManager;
    const { ModalBanner } = this;

    const { data, close } = openedDialogs.get(DIALOG_NAMES.TEXT);
    const closeButtonName = data.closeButtonName ? data.closeButtonName : 'Close';
    const closeButtonClass = data.closeButtonClass ? data.closeButtonClass : 'basic';
    const size = (data.size) ? data.size : 'small';
    return (
      <Modal
        key={DIALOG_NAMES.TEXT}
        className={data.className}
        onClose={close}
        open={openedDialogs.has(DIALOG_NAMES.TEXT)}
        size={size}>
        <ModalBanner label={data.title} onClose={close} />
        <Modal.Content scrolling>
          <p>{data.message}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button className={closeButtonClass} onClick={close} primary>{closeButtonName}</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  confirmModal = () => {
    const { dialogManager } = this.props;
    const { openedDialogs } = dialogManager;
    const { ModalBanner } = this;

    const { data, close } = openedDialogs.get(DIALOG_NAMES.CONFIRM);
    return (
      <Modal
        key={DIALOG_NAMES.CONFIRM}
        className={data.className}
        onClose={close}
        open={openedDialogs.has(DIALOG_NAMES.CONFIRM)}
        size='tiny'>
        <ModalBanner label={data.title} onClose={close} />
        <Modal.Content scrolling>
          <p>{data.message}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic className={data.cancelButtonClass} onClick={close} primary>{data.cancelButtonName}</Button>
          <Button className={data.confirmButtonClass} onClick={data.confirmHandler} primary>{data.confirmButtonName}</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    const { dialogManager } = this.props;
    const { openedDialogs } = dialogManager;

    const { AddAssignmentModal } = this;
    const { AddClassModal } = this;
    const { AddCourseToClassModal } = this;
    const { AddCustomAssessmentModal } = this;
    const { AddGroupModal } = this;
    const { AddJoinClassModal } = this;
    const { AddLTIAssignmentModal } = this;
    const { AddLibraryCourseToClassroomsModal } = this;
    const { AddStudentToClassroomsModal } = this;
    const { AddStudentsToClassroomModal } = this;
    const { AddTeacherToClassroomsModal } = this;
    const { ArchiveOrActivateStudentModal } = this;
    const { AssessmentEditorModal } = this;
    const { AssessmentItemShopModal } = this;
    const { AssignmentInfoModal } = this;
    const { AvatarPicker } = this;
    const { CertificateModal } = this;
    const { ChangePassword } = this;
    const { ClassImagePicker } = this;
    const { ClassroomReportTypeSelectorModal } = this;
    const { ContentAssignmentModal } = this;
    const { ContentElementModal } = this;
    const { ContentPreviewDialog } = this;
    const { CopyLibraryResourceModal } = this;
    const { CourseElementModal } = this;
    const { CreateAssessmentBankUnitModal } = this;
    const { CreateNewLibraryResourceModal } = this;
    const { EditAssignmentModal } = this;
    const { EditAssignmentNotesModal } = this;
    const { EditBulkAssignmentModal } = this;
    const { ErrorModal } = this;
    const { ExportGradesModal } = this;
    const { ExportReportModal } = this;
    const { InfoModal } = this;
    const { ItemBankItemPreviewModal } = this;
    const { ItemBankViewModal } = this;
    const { JoinClassModal } = this;
    const { LearnosityItemPreviewModal } = this;
    const { LibraryAssessmentBankViewModal } = this;
    const { LibraryAssessmentBuilderModal } = this;
    const { LibraryAssessmentShopModal } = this;
    const { LibraryContentUsageModal } = this;
    const { LibraryResourceDetailsModal } = this;
    const { LibraryShareAssessmentUsersModal } = this;
    const { LibraryStandardsTagsModal } = this;
    const { MigrateStudentDataModal } = this;
    const { ReactivateClassModal } = this;
    const { RegisterStudentModal } = this;
    const { ReportHideColumnsModal } = this;
    const { ResourceBankViewModal } = this;
    const { ResourceCartModal } = this;
    const { ResourceShopModal } = this;
    const { SchoolImagePicker } = this;
    const { ScoreAssignmentModal } = this;
    const { SelectLessonProfileModal } = this;
    const { SelectNewLibraryResourceModal } = this;
    const { SelectNewResourceModal } = this;
    const { SelectPreviewModeModal } = this;
    const { ShareAssessmentModal } = this;
    const { StandardsDocumentsModal } = this;
    const { StandardsModal } = this;
    const { StudentAssignmentModal } = this;
    const { StudentFeedbackModal } = this;
    const { TagsModal } = this;
    const { TagsStandardsModal } = this;
    const { TeacherFeedbackModal } = this;
    const { TeacherLibraryResourceDetailsModal } = this;
    const { TrialEnterPurchaseCodesModal } = this;
    const { UpdateUserPasswordModal } = this;
    const { ViewNotificationModal } = this;
    const { WorkspaceFileModal } = this;
    const { WorkspaceFileRenameModal } = this;

    const items = [];
    if (openedDialogs.has(DIALOG_NAMES.CONTENT_PREVIEW)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.CONTENT_PREVIEW);
      items.push(<ContentPreviewDialog
        key={DIALOG_NAMES.CONTENT_PREVIEW}
        {...data}
        assignmentId={data.assignmentId}
        close={close}
        closeModalCallback={data.closeModalCallback}
        contentItemId={data.contentItemId}
        contentItemType={data.contentItemType}
        contentMode={data.contentMode}
        instruction={data.instruction}
        open={openedDialogs.has(DIALOG_NAMES.CONTENT_PREVIEW)}
        resourceName={data.resourceName} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.ADD_ASSIGNMENT)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ADD_ASSIGNMENT);
      items.push(<AddAssignmentModal
        key={DIALOG_NAMES.ADD_ASSIGNMENT}
        {...data}
        addAssignmentOpen={openedDialogs.has(DIALOG_NAMES.ADD_ASSIGNMENT)}
        closeAddAssignment={close}
        contentDueDate={data.contentDueDate}
        contentDuration={data.contentDuration}
        contentImageUrl={data.contentImageUrl}
        contentItemId={data.contentItemId}
        contentName={data.contentName}
        contentSubname={data.contentSubname}
        courseContentItemId={data.courseContentItemId}
        courseElementToggleDefaults={data.courseElementToggleDefaults}
        courseResourceElementId={data.courseResourceElementId} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.ADD_LTI_ASSIGNMENT)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ADD_LTI_ASSIGNMENT);
      items.push(<AddLTIAssignmentModal
        key={DIALOG_NAMES.ADD_LTI_ASSIGNMENT}
        {...data}
        closeAddAssignment={close}
        openAddAssignment={openedDialogs.has(DIALOG_NAMES.ADD_LTI_ASSIGNMENT)}
      />);
    }
    if (openedDialogs.has(DIALOG_NAMES.ADD_EDIT_GROUP)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ADD_EDIT_GROUP);
      items.push(<AddGroupModal
        key={DIALOG_NAMES.ADD_EDIT_GROUP}
        {...data}
        addGroupOpen={openedDialogs.has(DIALOG_NAMES.ADD_EDIT_GROUP)}
        closeAddGroup={close} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.SCORE_ASSIGNMENT)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.SCORE_ASSIGNMENT);
      items.push(<ScoreAssignmentModal
        key={DIALOG_NAMES.SCORE_ASSIGNMENT}
        {...data}
        activityInstanceId={data.activityInstanceId}
        closeGradeAssignment={close}
        grade={data.grade}
        gradeAssignmentOpen={openedDialogs.has(DIALOG_NAMES.SCORE_ASSIGNMENT)}
        maxScore={data.maxScore}
        studentName={data.studentName}
        totalScore={data.totalScore} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.INFO)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.INFO);
      items.push(<InfoModal
        key={DIALOG_NAMES.INFO}
        {...data}
        addInfoOpen={openedDialogs.has(DIALOG_NAMES.INFO)}
        closeInfoModal={close}
        contentItemDescription={data.contentItemDescription}
        imageUrl={data.contentItemImageUrl}
        infoName={data.infoName}
        resourceInfo={data.resourceInfo}
        studentInstruction={data.studentInstruction} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.ASSIGNMENT_INFO)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ASSIGNMENT_INFO);
      items.push(<AssignmentInfoModal
        key={DIALOG_NAMES.ASSIGNMENT_INFO}
        {...data}
        addInfoOpen={openedDialogs.has(DIALOG_NAMES.ASSIGNMENT_INFO)}
        assignment={data.assignment}
        closeInfoModal={close}
        contentItemDescription={data.contentItemDescription}
        imageUrl={data.contentItemImageUrl}
        infoName={data.infoName}
        resourceInfo={data.resourceInfo}
        studentInstruction={data.studentInstruction} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.STANDARDS)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.STANDARDS);
      items.push(<StandardsModal
        key={DIALOG_NAMES.STANDARDS}
        {...data}
        addInfoOpen={openedDialogs.has(DIALOG_NAMES.STANDARDS)}
        closeInfoModal={close}
        contentItemDescription={data.contentItemDescription}
        imageUrl={data.imageUrl}
        infoName={data.infoName}
        resourceInfo={data.resourceInfo}
        standards={data.standards}
        studentInstruction={data.studentInstruction} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.TAGS)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.TAGS);
      items.push(<TagsModal
        key={DIALOG_NAMES.TAGS}
        {...data}
        addInfoOpen={openedDialogs.has(DIALOG_NAMES.TAGS)}
        closeInfoModal={close} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.STANDARDS_DOCUMENTS)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.STANDARDS_DOCUMENTS);
      items.push(<StandardsDocumentsModal
        key={DIALOG_NAMES.STANDARDS_DOCUMENTS}
        {...data}
        addInfoOpen={openedDialogs.has(DIALOG_NAMES.STANDARDS_DOCUMENTS)}
        closeInfoModal={close} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.CERTIFICATE)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.CERTIFICATE);
      items.push(<CertificateModal
        key={DIALOG_NAMES.CERTIFICATE}
        {...data}
        certificateOpen={openedDialogs.has(DIALOG_NAMES.CERTIFICATE)}
        closeCertificateModal={close}
        downloadCertificate={data.downloadCertificate} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.TAGS_STANDARDS)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.TAGS_STANDARDS);
      items.push(<TagsStandardsModal
        key={DIALOG_NAMES.TAGS_STANDARDS}
        {...data}
        addInfoOpen={openedDialogs.has(DIALOG_NAMES.TAGS_STANDARDS)}
        assessmentItem={data.assessmentItem}
        closeInfoModal={close} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.EDIT_ASSIGNMENT)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.EDIT_ASSIGNMENT);
      items.push(<EditAssignmentModal
        key={DIALOG_NAMES.EDIT_ASSIGNMENT}
        {...data}
        closeEditAssignment={close}
        editAssignmentOpen={openedDialogs.has(DIALOG_NAMES.EDIT_ASSIGNMENT)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.EDIT_BULK_ASSIGNMENT)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.EDIT_BULK_ASSIGNMENT);
      items.push(<EditBulkAssignmentModal
        key={DIALOG_NAMES.EDIT_BULK_ASSIGNMENT}
        {...data}
        closeEditAssignment={close}
        editAssignmentOpen={openedDialogs.has(DIALOG_NAMES.EDIT_BULK_ASSIGNMENT)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.CONTENT_ASSIGNMENT)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.CONTENT_ASSIGNMENT);
      const courseContentItemId = (data.courseContentItemId) ? data.courseContentItemId : data.currentCourseId;

      items.push(<ContentAssignmentModal
        key={DIALOG_NAMES.CONTENT_ASSIGNMENT}
        {...data}
        closeContentAssignmentModal={close}
        contentAssignmentsOpen={openedDialogs.has(DIALOG_NAMES.CONTENT_ASSIGNMENT)}
        courseContentItemId={courseContentItemId}
        infoPopupText={data.infoPopupText}
        showCourseDropdown={data.showCourseDropdown} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.STUDENT_ASSIGNMENT)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.STUDENT_ASSIGNMENT);
      items.push(<StudentAssignmentModal
        key={DIALOG_NAMES.STUDENT_ASSIGNMENT}
        {...data}
        assignmentElement={data.assignmentElement}
        assignments={data.assignmentArray}
        classeslist={data.classeslist}
        closeStudentAssignmentModal={close}
        contentAssignmentsOpen={openedDialogs.has(DIALOG_NAMES.STUDENT_ASSIGNMENT)}
        contentName={data.contentName}
        courseContentItemId={data.currentCourseId}
        courseElement={data.courseElement}
        fetchCourseActivityInformation={data.fetchCourseActivityInformation}
        handlePresent={data.handlePresent}
        labelInfo={data.labelInfo} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.CONTENT_ELEMENT)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.CONTENT_ELEMENT);
      items.push(<ContentElementModal
        key={DIALOG_NAMES.CONTENT_ELEMENT}
        {...data}
        assignmentElement={data.assignmentElement}
        assignments={data.assignmentArray}
        closeContentAssignmentModal={close}
        cmapElementId={data.cmapElementId}
        contentAssignmentsOpen={openedDialogs.has(DIALOG_NAMES.CONTENT_ELEMENT)}
        contentName={data.contentName}
        courseContentItemId={data.currentCourseId}
        courseElementList={data.courseElementList}
        currentClassroomId={data.currentClassroomId}
        fetchCourseActivityInformation={data.fetchCourseActivityInformation}
        handleAddAssignment={data.handleAddAssignment}
        handleAnswerKey={data.handleAnswerKey}
        handleGradebook={data.handleGradebook}
        handleOpenInfo={data.handleOpenInfo}
        handlePresent={data.handlePresent}
        handleView={data.handleView}
        infoPopupText={data.infoPopupText}
        labelInfo={data.labelInfo}
        showContentAssignments={data.showContentAssignments}
        showCourseDropdown={data.showCourseDropdown}
        standardId={data.standardId} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.COURSE_ELEMENT)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.COURSE_ELEMENT);
      items.push(<CourseElementModal
        key={DIALOG_NAMES.COURSE_ELEMENT}
        {...data}
        closeCourseElementModal={close}
        courseId={data.courseId}
        courseName={data.courseName}
        exploreCourseOpen={openedDialogs.has(DIALOG_NAMES.COURSE_ELEMENT)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.REGISTER_STUDENT)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.REGISTER_STUDENT);
      items.push(<RegisterStudentModal
        key={DIALOG_NAMES.REGISTER_STUDENT}
        {...data}
        accessCode={data.accessCode}
        closeRegisterStudentModal={close}
        currentClassroomId={data.currentClassroomId}
        labelInfo={data.labelInfo}
        registerStudentOpen={openedDialogs.has(DIALOG_NAMES.REGISTER_STUDENT)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.ADD_STUDENTS_TO_CLASSROOM)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ADD_STUDENTS_TO_CLASSROOM);
      items.push(<AddStudentsToClassroomModal
        {...data}
        key={DIALOG_NAMES.ADD_STUDENTS_TO_CLASSROOM}
        close={close}
        open={openedDialogs.has(DIALOG_NAMES.ADD_STUDENTS_TO_CLASSROOM)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.ADD_STUDENT_TO_CLASSROOMS)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ADD_STUDENT_TO_CLASSROOMS);
      items.push(<AddStudentToClassroomsModal
        {...data}
        key={DIALOG_NAMES.ADD_STUDENT_TO_CLASSROOMS}
        close={close}
        open={openedDialogs.has(DIALOG_NAMES.ADD_STUDENT_TO_CLASSROOMS)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.ADD_TEACHER_TO_CLASSROOMS)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ADD_TEACHER_TO_CLASSROOMS);
      items.push(<AddTeacherToClassroomsModal
        {...data}
        key={DIALOG_NAMES.ADD_TEACHER_TO_CLASSROOMS}
        close={close}
        open={openedDialogs.has(DIALOG_NAMES.ADD_TEACHER_TO_CLASSROOMS)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.ADD_JOIN_CLASS)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ADD_JOIN_CLASS);
      items.push(<AddJoinClassModal
        {...data}
        key={DIALOG_NAMES.ADD_JOIN_CLASS}
        close={close}
        open={openedDialogs.has(DIALOG_NAMES.ADD_JOIN_CLASS)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.VIEW_NOTIFICATION)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.VIEW_NOTIFICATION);
      items.push(<ViewNotificationModal
        {...data}
        key={DIALOG_NAMES.VIEW_NOTIFICATION}
        close={close}
        open={openedDialogs.has(DIALOG_NAMES.VIEW_NOTIFICATION)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.CLASSROOM_REPORT_TYPE_SELECTOR_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.CLASSROOM_REPORT_TYPE_SELECTOR_MODAL);
      items.push(<ClassroomReportTypeSelectorModal
        {...data}
        key={DIALOG_NAMES.CLASSROOM_REPORT_TYPE_SELECTOR_MODAL}
        close={close}
        open={openedDialogs.has(DIALOG_NAMES.CLASSROOM_REPORT_TYPE_SELECTOR_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.EXPORT_ASSIGNMENT)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.EXPORT_ASSIGNMENT);
      items.push(<ExportGradesModal
        {...data}
        key={DIALOG_NAMES.EXPORT_ASSIGNMENT}
        assignment={data.assignment}
        assignmentId={data.assignmentId}
        closeExportModal={close}
        exportGradesOpen={openedDialogs.has(DIALOG_NAMES.EXPORT_ASSIGNMENT)} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.EXPORT_REPORT)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.EXPORT_REPORT);
      items.push(<ExportReportModal
        key={DIALOG_NAMES.EXPORT_REPORT}
        {...data}
        baseReportId={data.baseReportId}
        classroomId={data.classroomId}
        closeExportReportModal={close}
        curriculumMapId={data.curriculumMapId}
        exportReportOpen={openedDialogs.has(DIALOG_NAMES.EXPORT_REPORT)}
        institutionId={data.institutionId}
        reportId={data.reportId} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.ADD_COURSE_TO_CLASS)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ADD_COURSE_TO_CLASS);
      items.push(<AddCourseToClassModal
        key={DIALOG_NAMES.ADD_COURSE_TO_CLASS}
        {...data}
        classroomId={data.classroomId}
        closeAddCourseToClassModal={close}
        displayAddCourseToClassModal={openedDialogs.has(DIALOG_NAMES.ADD_COURSE_TO_CLASS)}
        history={data.history} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.ADD_LIBRARY_COURSE_TO_CLASSROOMS)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ADD_LIBRARY_COURSE_TO_CLASSROOMS);
      items.push(<AddLibraryCourseToClassroomsModal
        {...data}
        key={DIALOG_NAMES.ADD_LIBRARY_COURSE_TO_CLASSROOMS}
        close={close}
        open={openedDialogs.has(DIALOG_NAMES.ADD_LIBRARY_COURSE_TO_CLASSROOMS)} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.ADD_CLASS)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ADD_CLASS);
      items.push(<AddClassModal
        {...data}
        key={DIALOG_NAMES.ADD_CLASS}
        close={close}
        open={openedDialogs.has(DIALOG_NAMES.ADD_CLASS)} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.ADD_CUSTOM_ASSESSMENT)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ADD_CUSTOM_ASSESSMENT);
      items.push(<AddCustomAssessmentModal
        key={DIALOG_NAMES.ADD_CUSTOM_ASSESSMENT}
        {...data}
        classroomId={data.classroomId}
        closeAddCustomAssessmentModal={close}
        courseId={data.courseId}
        displayAddCustomAssessmentModal={openedDialogs.has(DIALOG_NAMES.ADD_CUSTOM_ASSESSMENT)}
        history={data.history} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.ASSESSMENT_EDITOR)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ASSESSMENT_EDITOR);
      items.push(<AssessmentEditorModal
        key={DIALOG_NAMES.ASSESSMENT_EDITOR}
        {...data}
        closeAssessmentEditorModal={close}
        courseElement={data.courseElement}
        displayAssessmentEditorModal={openedDialogs.has(DIALOG_NAMES.ASSESSMENT_EDITOR)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.LEARNOSITY_ITEM_PREVIEW)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.LEARNOSITY_ITEM_PREVIEW);
      items.push(<LearnosityItemPreviewModal
        key={DIALOG_NAMES.LEARNOSITY_ITEM_PREVIEW}
        {...data}
        closeLearnosityItemPreviewModal={close}
        contentItemId={data.contentItemId}
        displayLearnosityItemPreviewModal={openedDialogs.has(DIALOG_NAMES.LEARNOSITY_ITEM_PREVIEW)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.ITEM_BANK_ITEM_PREVIEW)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ITEM_BANK_ITEM_PREVIEW);
      items.push(<ItemBankItemPreviewModal
        key={DIALOG_NAMES.ITEM_BANK_ITEM_PREVIEW}
        {...data}
        closeItemBankItemPreviewModal={close}
        contentItemId={data.contentItemId}
        displayItemBankItemPreviewModal={openedDialogs.has(DIALOG_NAMES.ITEM_BANK_ITEM_PREVIEW)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.RESOURCE_ITEM_SHOP)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.RESOURCE_ITEM_SHOP);
      items.push(<AssessmentItemShopModal
        key={DIALOG_NAMES.RESOURCE_ITEM_SHOP}
        {...data}
        closeAssessmentItemShopModal={close}
        courseElement={data.courseElement}
        displayAssessmentItemShopModal={openedDialogs.has(DIALOG_NAMES.RESOURCE_ITEM_SHOP)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.ITEM_BANK_VIEW)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ITEM_BANK_VIEW);
      items.push(<ItemBankViewModal
        key={DIALOG_NAMES.ITEM_BANK_VIEW}
        {...data}
        closeItemBankModal={close}
        courseElement={data.courseElement}
        displayAssessmentItemShopModal={openedDialogs.has(DIALOG_NAMES.ITEM_BANK_VIEW)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW);
      items.push(<LibraryAssessmentBuilderModal
        key={DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW}
        {...data}
        contentItemId={data.contentItemId}
        displayLibraryAssessmentBuilderModal={openedDialogs.has(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.LIBRARY_ASSESSMENT_BANK_VIEW)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.LIBRARY_ASSESSMENT_BANK_VIEW);
      items.push(<LibraryAssessmentBankViewModal
        key={DIALOG_NAMES.LIBRARY_ASSESSMENT_BANK_VIEW}
        {...data}
        libraryAssessmentBankViewModalOpen={openedDialogs.has(DIALOG_NAMES.LIBRARY_ASSESSMENT_BANK_VIEW)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.LIBRARY_ASSESSMENT_CART_VIEW)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.LIBRARY_ASSESSMENT_CART_VIEW);
      items.push(<ResourceCartModal
        key={DIALOG_NAMES.LIBRARY_ASSESSMENT_CART_VIEW}
        close={close}
        {...data}
        resourceCartModalOpen={openedDialogs.has(DIALOG_NAMES.LIBRARY_ASSESSMENT_CART_VIEW)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.LIBRARY_ASSESSMENT_SHOP_VIEW)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.LIBRARY_ASSESSMENT_SHOP_VIEW);
      items.push(<LibraryAssessmentShopModal
        key={DIALOG_NAMES.LIBRARY_ASSESSMENT_SHOP_VIEW}
        close={close}
        {...data}
        libraryAssessmentShopModalOpen={openedDialogs.has(DIALOG_NAMES.LIBRARY_ASSESSMENT_SHOP_VIEW)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.LIBRARY_SHARE_ASSESSMENT_USERS_VIEW)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.LIBRARY_SHARE_ASSESSMENT_USERS_VIEW);
      items.push(<LibraryShareAssessmentUsersModal
        key={DIALOG_NAMES.LIBRARY_SHARE_ASSESSMENT_USERS_VIEW}
        {...data}
        LibraryShareAssessmentUsersModalOpen={openedDialogs.has(DIALOG_NAMES.LIBRARY_SHARE_ASSESSMENT_USERS_VIEW)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.CLASS_IMAGE)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.CLASS_IMAGE);
      items.push(<ClassImagePicker
        key={DIALOG_NAMES.CLASS_IMAGE}
        {...data}
        classroomId={data.classroomId}
        openClassImagePicker={openedDialogs.has(DIALOG_NAMES.CLASS_IMAGE)}
        toggleClassImageEditor={close} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.SCHOOL_IMAGE)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.SCHOOL_IMAGE);
      items.push(<SchoolImagePicker
        key={DIALOG_NAMES.SCHOOL_IMAGE}
        {...data}
        openSchoolImagePicker={openedDialogs.has(DIALOG_NAMES.SCHOOL_IMAGE)}
        school={data.school}
        toggleSchoolImageEditor={close} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.JOIN_CLASS)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.JOIN_CLASS);
      items.push(<JoinClassModal
        key={DIALOG_NAMES.JOIN_CLASS}
        {...data}
        closeJoinClassModal={close}
        displayJoinClassModal={openedDialogs.has(DIALOG_NAMES.JOIN_CLASS)} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.AVATAR)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.AVATAR);
      items.push(<AvatarPicker
        key={DIALOG_NAMES.AVATAR}
        {...data}
        openAvatarPicker={openedDialogs.has(DIALOG_NAMES.AVATAR)}
        toggleAvatarEditor={close}
        userId={data.userId} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.TEXT)) {
      items.push(this.textModal());
    }
    if (openedDialogs.has(DIALOG_NAMES.CONFIRM)) {
      items.push(this.confirmModal());
    }
    if (openedDialogs.has(DIALOG_NAMES.EDIT_ASSIGNMENT_NOTES)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.EDIT_ASSIGNMENT_NOTES);
      items.push(<EditAssignmentNotesModal
        key={DIALOG_NAMES.EDIT_ASSIGNMENT_NOTES}
        {...data}
        assignmentId={data.assignmentId}
        closeEditAssignmentNotes={close}
        editAssignmentOpen={openedDialogs.has(DIALOG_NAMES.EDIT_ASSIGNMENT_NOTES)} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.TEACHER_FEEDBACK)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.TEACHER_FEEDBACK);
      items.push(<TeacherFeedbackModal
        key={DIALOG_NAMES.TEACHER_FEEDBACK}
        {...data}
        assignmentInstanceId={data.assignmentInstanceId}
        closeTeacherFeedbackModal={close}
        feedbackOpen={openedDialogs.has(DIALOG_NAMES.TEACHER_FEEDBACK)}
        studentName={data.studentName} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.STUDENT_FEEDBACK)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.STUDENT_FEEDBACK);
      items.push(<StudentFeedbackModal
        key={DIALOG_NAMES.STUDENT_FEEDBACK}
        {...data}
        assignmentInstanceId={data.assignmentInstanceId}
        closeStudentFeedbackModal={close}
        feedbackOpen={openedDialogs.has(DIALOG_NAMES.STUDENT_FEEDBACK)} />);
    }
    if (openedDialogs.has(DIALOG_NAMES.ERROR)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ERROR);
      items.push(<ErrorModal
        key={DIALOG_NAMES.ERROR}
        {...data}
        addErrorOpen={openedDialogs.has(DIALOG_NAMES.ERROR)}
        closeErrorModal={close}
        errorLabel={data.errorLabel}
        errorMessage={data.errorMessage} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.TRIAL_ENTER_PURCHASE_CODES)) {
      const { close } = openedDialogs.get(DIALOG_NAMES.TRIAL_ENTER_PURCHASE_CODES);
      items.push(
        <TrialEnterPurchaseCodesModal
          key={DIALOG_NAMES.TRIAL_ENTER_PURCHASE_CODES}
          close={close}
          open={openedDialogs.has(DIALOG_NAMES.TRIAL_ENTER_PURCHASE_CODES)} />
      );
    }

    if (openedDialogs.has(DIALOG_NAMES.CHANGE_PASSWORD)) {
      const { close } = openedDialogs.get(DIALOG_NAMES.CHANGE_PASSWORD);
      items.push(
        <ChangePassword
          key={DIALOG_NAMES.CHANGE_PASSWORD}
          close={close}
          open={openedDialogs.has(DIALOG_NAMES.CHANGE_PASSWORD)} />
      );
    }

    if (openedDialogs.has(DIALOG_NAMES.UPDATE_USER_PASSWORD_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.UPDATE_USER_PASSWORD_MODAL);
      items.push(
        <UpdateUserPasswordModal
          key={DIALOG_NAMES.UPDATE_USER_PASSWORD_MODAL}
          close={close}
          open={openedDialogs.has(DIALOG_NAMES.UPDATE_USER_PASSWORD_MODAL)}
          {...data} />
      );
    }

    if (openedDialogs.has(DIALOG_NAMES.WORKSPACE_FILE_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.WORKSPACE_FILE_MODAL);
      items.push(
        <WorkspaceFileModal
          key={DIALOG_NAMES.WORKSPACE_FILE_MODAL}
          close={close}
          open={openedDialogs.has(DIALOG_NAMES.WORKSPACE_FILE_MODAL)}
          {...data} />
      );
    }

    if (openedDialogs.has(DIALOG_NAMES.WORKSPACE_FILE_RENAME_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.WORKSPACE_FILE_RENAME_MODAL);
      items.push(
        <WorkspaceFileRenameModal
          key={DIALOG_NAMES.WORKSPACE_FILE_RENAME_MODAL}
          close={close}
          open={openedDialogs.has(DIALOG_NAMES.WORKSPACE_FILE_RENAME_MODAL)}
          {...data} />
      );
    }

    if (openedDialogs.has(DIALOG_NAMES.REACTIVATE_CLASS_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.REACTIVATE_CLASS_MODAL);
      items.push(
        <ReactivateClassModal
          key={DIALOG_NAMES.REACTIVATE_CLASS_MODAL}
          close={close}
          open={openedDialogs.has(DIALOG_NAMES.REACTIVATE_CLASS_MODAL)}
          {...data} />
      );
    }

    if (openedDialogs.has(DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL);
      items.push(<SelectPreviewModeModal
        {...data}
        key={DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL}
        close={close}
        open={openedDialogs.has(DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.SELECT_LESSON_PROFILE_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.SELECT_LESSON_PROFILE_MODAL);
      items.push(<SelectLessonProfileModal
        {...data}
        key={DIALOG_NAMES.SELECT_LESSON_PROFILE_MODAL}
        close={close}
        open={openedDialogs.has(DIALOG_NAMES.SELECT_LESSON_PROFILE_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.SELECT_NEW_LIBRARY_RESOURCE_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.SELECT_NEW_LIBRARY_RESOURCE_MODAL);
      items.push(<SelectNewLibraryResourceModal
        key={DIALOG_NAMES.SELECT_NEW_LIBRARY_RESOURCE_MODAL}
        {...data}
        close={close}
        closeSelectNewLibraryResourceModal={close}
        selectNewLibraryResourceOpen={openedDialogs.has(DIALOG_NAMES.SELECT_NEW_LIBRARY_RESOURCE_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.SELECT_NEW_RESOURCE_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.SELECT_NEW_RESOURCE_MODAL);
      items.push(<SelectNewResourceModal
        key={DIALOG_NAMES.SELECT_NEW_RESOURCE_MODAL}
        {...data}
        close={close}
        closeSelectNewResourceModal={close}
        selectNewResourceOpen={openedDialogs.has(DIALOG_NAMES.SELECT_NEW_RESOURCE_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.CREATE_ASSESSMENT_BANK_UNIT_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.CREATE_ASSESSMENT_BANK_UNIT_MODAL);
      items.push(<CreateAssessmentBankUnitModal
        key={DIALOG_NAMES.CREATE_ASSESSMENT_BANK_UNIT_MODAL}
        {...data}
        close={close}
        createAssessmentBankUnitModalOpen={openedDialogs.has(DIALOG_NAMES.CREATE_ASSESSMENT_BANK_UNIT_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.CREATE_NEW_LIBRARY_RESOURCE_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.CREATE_NEW_LIBRARY_RESOURCE_MODAL);
      items.push(<CreateNewLibraryResourceModal
        key={DIALOG_NAMES.CREATE_NEW_LIBRARY_RESOURCE_MODAL}
        {...data}
        close={close}
        classroomId={data.classroomId}
        courseId={data.courseId}
        closeCreateNewLibraryResourceModal={close}
        createNewLibraryResourceOpen={openedDialogs.has(DIALOG_NAMES.CREATE_NEW_LIBRARY_RESOURCE_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL);
      items.push(<CopyLibraryResourceModal
        key={DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL}
        {...data}
        close={close}
        copyLibraryResourceOpen={openedDialogs.has(DIALOG_NAMES.COPY_LIBRARY_RESOURCE_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.LIBRARY_RESOURCE_DETAILS_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.LIBRARY_RESOURCE_DETAILS_MODAL);
      items.push(<LibraryResourceDetailsModal
        key={DIALOG_NAMES.LIBRARY_RESOURCE_DETAILS_MODAL}
        {...data}
        close={close}
        closeLibraryResourceDetailsModal={close}
        libraryResourceDetailsOpen={openedDialogs.has(DIALOG_NAMES.LIBRARY_RESOURCE_DETAILS_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.TEACHER_LIBRARY_RESOURCE_DETAILS_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.TEACHER_LIBRARY_RESOURCE_DETAILS_MODAL);
      items.push(<TeacherLibraryResourceDetailsModal
        {...data}
        key={DIALOG_NAMES.TEACHER_LIBRARY_RESOURCE_DETAILS_MODAL}
        close={close}
        open={openedDialogs.has(DIALOG_NAMES.TEACHER_LIBRARY_RESOURCE_DETAILS_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.LIBRARY_STANDARDS_TAGS_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.LIBRARY_STANDARDS_TAGS_MODAL);
      items.push(<LibraryStandardsTagsModal
        key={DIALOG_NAMES.LIBRARY_STANDARDS_TAGS_MODAL}
        {...data}
        close={close}
        libraryStandardsTagsModalOpen={openedDialogs.has(DIALOG_NAMES.LIBRARY_STANDARDS_TAGS_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.LIBRARY_CONTENT_USAGE_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.LIBRARY_CONTENT_USAGE_MODAL);
      items.push(<LibraryContentUsageModal
        key={DIALOG_NAMES.LIBRARY_CONTENT_USAGE_MODAL}
        {...data}
        close={close}
        libraryContentUsageModalOpen={openedDialogs.has(DIALOG_NAMES.LIBRARY_CONTENT_USAGE_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.RESOURCE_BANK_SHOP)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.RESOURCE_BANK_SHOP);
      items.push(<ResourceShopModal
        key={DIALOG_NAMES.RESOURCE_BANK_SHOP}
        {...data}
        close={close}
        courseElement={data.courseElement}
        displayAssessmentShopModal={openedDialogs.has(DIALOG_NAMES.RESOURCE_BANK_SHOP)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.RESOURCE_BANK_VIEW)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.RESOURCE_BANK_VIEW);
      items.push(<ResourceBankViewModal
        key={DIALOG_NAMES.RESOURCE_BANK_VIEW}
        {...data}
        closeResourceBankModal={close}
        courseElement={data.courseElement}
        displayAssessmentShopModal={openedDialogs.has(DIALOG_NAMES.RESOURCE_BANK_VIEW)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.REPORT_HIDE_COLUMNS_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.REPORT_HIDE_COLUMNS_MODAL);
      items.push(<ReportHideColumnsModal
        {...data}
        key={DIALOG_NAMES.REPORT_HIDE_COLUMNS_MODAL}
        close={close}
        open={openedDialogs.has(DIALOG_NAMES.REPORT_HIDE_COLUMNS_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.SHARE_ASSESSMENT_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.SHARE_ASSESSMENT_MODAL);
      items.push(<ShareAssessmentModal
        key={DIALOG_NAMES.SHARE_ASSESSMENT_MODAL}
        {...data}
        shareAssessmentModalOpen={openedDialogs.has(DIALOG_NAMES.SHARE_ASSESSMENT_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.MIGRATE_STUDENT_DATA_MODAL)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.MIGRATE_STUDENT_DATA_MODAL);
      items.push(<MigrateStudentDataModal
        key={DIALOG_NAMES.MIGRATE_STUDENT_DATA_MODAL}
        {...data}
        close={close}
        migrateStudentDataModalOpen={openedDialogs.has(DIALOG_NAMES.MIGRATE_STUDENT_DATA_MODAL)} />);
    }

    if (openedDialogs.has(DIALOG_NAMES.ARCHIVE_OR_ACTIVATE_STUDENT)) {
      const { data, close } = openedDialogs.get(DIALOG_NAMES.ARCHIVE_OR_ACTIVATE_STUDENT);
      items.push(<ArchiveOrActivateStudentModal
        key={DIALOG_NAMES.ARCHIVE_OR_ACTIVATE_STUDENT}
        {...data}
        close={close}
        open={openedDialogs.has(DIALOG_NAMES.ARCHIVE_OR_ACTIVATE_STUDENT)} />);
    }

    items.reverse(); // we need to reverse the list so the dialogs show in the correct order.
    return (
      <div className='dialogs'>
        {items}
      </div>
    );
  }
}

SatCoreRegister('DialogList', DialogList);
