import { action, makeObservable, observable } from 'mobx';

import Moment from 'moment';

import Auth from './AuthManager';
import userManager from './UserManager';

import UserService from '../services/UserService';

export const ADMIN_CLASSROOM_ENDPOINTS = {
  FETCH_CLASSROOM: '/api/viewClassroom',
  FETCH_SATELLITE_CLASSROOMS: '/api/viewSatelliteClassrooms'
};

export class AdminClassroomManager {
  ADMIN_CLASSROOM_FETCH_PAGE_SIZE = 12;

  @observable loaded = false;

  @observable totalPages = 0;

  @observable searchTextTimeout = null;

  @observable classroomsArray = [];

  constructor() {
    makeObservable(this);
  }

  /**
   * @param {string} searchText default `''`
   * @param {number} page default `0`
   * @param {number} pageSize default `ADMIN_CLASSROOM_FETCH_PAGE_SIZE (12)`
   * @param {string} sortColumn default `''`
   * @param {'asc' | 'desc'} sortDirection default `'asc'`
   * @param {{ field: string, operator: string, value: string }[]} filters default `[]`
   */
  @action fetchClassrooms = async (
    page = 1, pageSize = this.ADMIN_CLASSROOM_FETCH_PAGE_SIZE,
    sortColumn = '', sortDirection = 'asc', filters = [], {
      archived = false,
      courseId,
      productId
    } = {}
  ) => {
    try {
      const hasMultipleInstitutions = UserService.hasMultipleInstitutions();
      this.setLoaded(false);
      const apiUrlPrefix = ADMIN_CLASSROOM_ENDPOINTS.FETCH_SATELLITE_CLASSROOMS;
      let apiUrlParams = `?pageSize=${pageSize}`;
      apiUrlParams += `&skip=${(page - 1) * pageSize}`;
      if (sortColumn) {
        apiUrlParams += `&sort[0][field]=${sortColumn}`;
        apiUrlParams += `&sort[0][dir]=${sortDirection}`;
      }
      if (filters && filters.length > 0) {
        filters.forEach((filter, index) => {
          if (filter.field && filter.value) {
            let filterField = filter.field;
            let filterValue = filter.value;

            if (filterField === 'classroomStartDate' || filterField === 'classroomEndDate') {
              // by default, 'moment' allows user to be flexible in entering a date as a string.
              // i.e. the following line will accept all recognizable date formats and convert filter value to 'YYYY-MM-DD' format
              filterValue = Moment(filter.value).format('YYYY-MM-DD');

              // TODO uncomment if we want to force user to search for classroom dates strictly in 'MM/DD/YYYY' format
              // const EXPECTED_UI_DATE_FORMAT = 'MM/DD/YYYY';
              // const strict = false;
              // // if `filter.value` is in `EXPECTED_UI_DATE_FORMAT`, we will pass `filterValue` to the backend in 'YYYY-MM-DD' format.
              // // else, we will pass `filterValue` to the backend as 'Invalid date'.
              // filterValue = Moment(filter.value, EXPECTED_UI_DATE_FORMAT, strict).format('YYYY-MM-DD');
            }

            if ((filterField === 'classroomName' || filterField === 'classroomNickname') &&
              !apiUrlParams.includes('[field]=classroomNameOrClassroomNickname')
            ) {
              filterField = 'classroomNameOrClassroomNickname';
            }
            if (filterField !== 'classroomName' && filterField !== 'classroomNickname') {
              apiUrlParams += `&filter[filters][${index}][field]=${filterField}`;
              apiUrlParams += `&filter[filters][${index}][operator]=${filter.operator}`;
              apiUrlParams += `&filter[filters][${index}][value]=${filterValue}`;
            }
            if (!hasMultipleInstitutions && filterField === 'institutionId') {
              apiUrlParams += `&institutionIds=${filterValue}`;
            }
          }
        });
      }

      /* --- SPECIAL FILTERS --- */
      const archivedFilterIndex = filters?.length || 0;

      let lastFilterIndex = archivedFilterIndex;

      // active/archived classroom filter
      apiUrlParams += `&filter[filters][${archivedFilterIndex}][field]=archived`;
      apiUrlParams += `&filter[filters][${archivedFilterIndex}][operator]=eq`;
      apiUrlParams += `&filter[filters][${archivedFilterIndex}][value]=${archived}`;

      if (productId) {
        // product filter
        lastFilterIndex++;
        apiUrlParams += `&filter[filters][${lastFilterIndex}][field]=productId`;
        apiUrlParams += `&filter[filters][${lastFilterIndex}][operator]=eq`;
        apiUrlParams += `&filter[filters][${lastFilterIndex}][value]=${productId}`;
      }

      if (courseId) {
        // course filter
        lastFilterIndex++;
        apiUrlParams += `&filter[filters][${lastFilterIndex}][field]=courseId`;
        apiUrlParams += `&filter[filters][${lastFilterIndex}][operator]=eq`;
        apiUrlParams += `&filter[filters][${lastFilterIndex}][value]=${courseId}`;
      }
      /* --- end SPECIAL FILTERS -- */

      // ---
      // in a current real-case scenario, a DA should only ever have one district,
      // so no need to check for multiple institutions if user is DA.
      if (/* userManager.isDistrictOrSchoolAdmin */userManager.isSchoolAdmin && hasMultipleInstitutions) {
        // let institutionIds;
        // if (userManager.isDistrictAdmin) {
        //   institutionIds = userManager.institutionIds;
        // } else if (userManager.isSchoolAdmin) {
        const institutionIds = userManager.institutionIds.filter((institutionId) => {
          const institution = userManager.institutionsMap.get(institutionId);
          return institution && !(institution.district || institution.type?.toLowerCase() === 'district');
        });
        // }
        apiUrlParams += institutionIds?.length ? `&institutionIds=${institutionIds.toString()}` : '';
      }

      const apiUrl = `${Auth.ecms}${apiUrlPrefix}${apiUrlParams}`;

      const response = await Auth.fetch(`${apiUrl}`, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        const classrooms = response.data && response.data.length ? [...response.data] : [];

        this.initClassrooms(classrooms);
        this.totalPages = Math.ceil(+response.pageTotal / pageSize);

        this.setLoaded(true);
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action fetchClassroom = async (classroomId, studentId = 'NONE') => {
    const apiUrlPrefix = `${Auth.ecms}${ADMIN_CLASSROOM_ENDPOINTS.FETCH_CLASSROOM}`;
    const apiUrlParams = `?classroomId=${classroomId}&studentId=${studentId}`;
    const apiUrl = `${apiUrlPrefix}${apiUrlParams}`;
    const response = await Auth.fetch(apiUrl, {
      method: 'GET'
    });
    if (response && response.data[0]) {
      return response.data[0];
    }
  }

  @action initClassrooms = async (classrooms) => {
    this.classroomsArray.clear();
    this.classroomsArray = [...classrooms];
  }

  @action setLoaded = (loaded) => {
    this.loaded = loaded;
  }
}
export default new AdminClassroomManager();
