import React, { useContext } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

// import { register } from '../../../i18n';

import EngagementService from '../../../services/EngagementService';

const EngagementRowMainLanguageToolUsageCells = observer((props) => {
  const { engagementData } = props;

  const { viewRawTextToSpeechCount, viewRawTranslateCount } = engagementData;

  const { /* managers */ } = useContext(MobXProviderContext);

  // const t = register('EngagementTable');

  const renderEngagementRowMainLanguageToolUsageCells = () => {
    return (
      <>
        {renderEngagementRowMainLanguageToolUsageCell({
          allowPopupComponent: true,
          className: classNames('language-tool-usage-cell main-cell languageToolUsage', {
            warn: false // TODO
          }),
          popupContent: '',
          popupDisabled: true,
          popupOn: 'hover',
          popupTrigger: viewRawTextToSpeechCount !== 'TBD' && viewRawTranslateCount !== 'TBD' ?
            ((viewRawTextToSpeechCount || 0) + (viewRawTranslateCount || 0)) : 'TBD'
        })}
      </>
    );
  };

  const renderEngagementRowMainLanguageToolUsageCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementRowMainLanguageToolUsageCells();
});
export default EngagementRowMainLanguageToolUsageCells;

SatCoreRegister('EngagementRowMainLanguageToolUsageCells', EngagementRowMainLanguageToolUsageCells);
