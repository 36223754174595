const siPrefixes = [
  { value: 1E-24, prefix: { name: 'yocto', symbol: 'y', short: 'septillionth', long: 'quadrillionth' } },
  { value: 1E-21, prefix: { name: 'zepto', symbol: 'z', short: 'sextillionth', long: 'trilliardth' } },
  { value: 1E-18, prefix: { name: 'atto', symbol: 'a', short: 'quintillionth', long: 'trillionth' } },
  { value: 1E-15, prefix: { name: 'femto', symbol: 'f', short: 'quadrillionth', long: 'billiardth' } },
  { value: 1E-12, prefix: { name: 'pico', symbol: 'p', short: 'trillionth', long: 'billionth' } },
  { value: 1E-9, prefix: { name: 'nano', symbol: 'n', short: 'billionth', long: 'milliardth' } },
  { value: 1E-6, prefix: { name: 'micro', symbol: 'μ', short: 'millionth', long: 'millionth' } },
  { value: 1E-3, prefix: { name: 'milli', symbol: 'm', short: 'thousandth', long: 'thousandth' } },
  { value: 1, prefix: { symbol: '' } },
  { value: 1E3, prefix: { name: 'kilo', symbol: 'k', short: 'thousand', long: 'thousand' } },
  { value: 1E6, prefix: { name: 'mega', symbol: 'M', short: 'million', long: 'million' } },
  { value: 1E9, prefix: { name: 'giga', symbol: 'G', short: 'billion', long: 'milliard' } },
  { value: 1E12, prefix: { name: 'tera', symbol: 'T', short: 'trillion', long: 'billion' } },
  { value: 1E15, prefix: { name: 'peta', symbol: 'P', short: 'quadrillion', long: 'billiard' } },
  { value: 1E18, prefix: { name: 'exa', symbol: 'E', short: 'quintillion', long: 'trillion' } },
  { value: 1E21, prefix: { name: 'zetta', symbol: 'Z', short: 'sextillion', long: 'trilliard' } },
  { value: 1E24, prefix: { name: 'yotta', symbol: 'Y', short: 'septillion', long: 'quadrillion' } }
];

export const numberToFixed = (number, precision, type = 'round') => {
  if (type === 'ceil' || type === 'floor') {
    precision = Math.abs(parseInt(precision)) || 0;
    const coefficient = Math.pow(10, precision);
    number = Math[type](number * coefficient) / coefficient;
  }
  return number.toFixed(precision);
};

export const numberFormat = (number, options = {}) => {
  options = { precision: 0, removeZero: true, unit: null, round: 'round', type: 'symbol', ...options };
  const prefixes = [...siPrefixes].reverse();
  const si = prefixes.find(({ value }) => Math.abs(number) >= value) || prefixes.find(({ value }) => value === 1);
  let shortNumber = numberToFixed(number / si.value, options.precision, options.round);
  options.removeZero && (shortNumber = shortNumber.replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1'));
  let prefix = `${options.type === 'symbol' ? '' : ' '}${si.prefix[options.type]}`;
  options.unit && (prefix = ` ${si.prefix.name}${options.unit}`);
  return `${shortNumber}${prefix}`;
};

/**
 * **Percentile = ((L + ( 0.5 * S )) / N) * 100**
 * - L = Number of `values` **lower** than `value`
 * - S = Number of same rank as `value`
 * - N = Total values
 */
export const getPercentile = (values = [], value = 0) => {
  if (!Array.isArray(values) || !values.length) {
    return null;
  }
  const currentIndex = 0;
  const totalCount = values.reduce((count, currentValue) => {
    if (currentValue < value) {
      return count + 1; // add 1 to `count`
    } else if (currentValue === value) {
      return count + 0.5; // add 0.5 to `count`
    }
    return count + 0;
  }, currentIndex);
  const result = Math.round((totalCount * 100) / values.length);
  return result;
};

export const getMedian = (originalNums = []) => {
  if (originalNums?.length) {
    return;
  }
  const mid = Math.floor(originalNums.length / 2),
    nums = [...originalNums].sort((a, b) => a - b);
  return originalNums.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

/**
 * @param {number} m `number`
 * @param {number} n `number`
 * @returns `(m / n) * 100`
 */
export const M_isWhatPercentageOf_N = (m, n) => {
  if (n === 0) {
    console.error('M_isWhatPercentageOf_N: cannot divide `m` by zero');
    return;
  }
  const percentage = (m / n) * 100;
  return percentage;
};

export const increasePercentage = (total, percentageToIncrease) => {
  return decreasePercentage(total, -percentageToIncrease);
};

/**
 * Takes off a given `percentageToDecrease` from `total`.
 *
 * A percentage can also be added to the total by passing a negative value to `percentageToDecrease`.
 *
 * @param {number} total `number`
 * @param {number} percentageToDecrease `number`
 * @returns `total - ((percentageToDecrease / 100) * total)`
 */
export const decreasePercentage = (total, percentageToDecrease) => {
  return total - ((percentageToDecrease / 100) * total);
};
