import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Container, Icon, Loader, Message } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import { CONTENT_MODE } from '../../managers/ContentManager';
import '../../css/StudentAssignmentModal.less';
import ImageService from '../../services/ImageService';
import ResourcePacingService from '../../services/ResourcePacingService';
import Modal from '../Modal';

export default @inject('classroomManager', 'courseManager', 'assignmentManager')
@observer
class StudentAssignmentModal extends Component {
  state = {
    isLoading: false
  }

  constructor(props) {
    super(props);
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.StudentAssignmentCard = SatCoreComponent('StudentAssignmentCard');
  }

  closeStudentAssignmentModal = () => {
    this.props.closeStudentAssignmentModal();
  }

  renderAssignmentCards = (assignments) => {
    const {
      fetchCourseActivityInformation, handleEditAssignment, handleGradebook,
      currentClassroomId, assignmentElement, handleAnswerKey, handlePresent
    } = this.props;
    const { StudentAssignmentCard } = this;
    const components = [];
    assignments.map((assignment) => {
      const contentImageUrl = ImageService.getImageUrl(assignment);
      components.push(
        <StudentAssignmentCard
          key={`AAC_${assignment.id}`}
          assignment={assignment}
          {...this.props}
          contentImageUrl={contentImageUrl}
          fetchCourseActivityInformation={(assignment) => fetchCourseActivityInformation(
            assignment, assignmentElement, contentImageUrl
          )}
          handleAnswerKey={handleAnswerKey}
          handleEditAssignment={(assignment, contentImageUrl) => handleEditAssignment(
            assignment, contentImageUrl, currentClassroomId, assignmentElement
          )}
          handleGradebook={handleGradebook}
          handlePresent={handlePresent}
          hideTryAgain={true} />
      );
      return true;
    });
    return components;
  }

  handleLoadMore = async (page) => {
    const { assignmentManager, currentClassroomId, courseContentItemId, courseElement } = this.props;

    if (this.props.classeslist === null || this.props.classeslist === undefined) {
      const entityId = (this.props.assignmentElement) ? this.props.assignmentElement.entityId : courseElement.contentItemId;
      const currentCourseContentItemId = courseContentItemId || (courseElement.hasActivities ? courseElement.studentActivities[0].courseContentItemId : null);
      const courseResourceElementId = courseElement.hasActivities ? courseElement.studentActivities[0].courseResourceElementId : null;
      await assignmentManager.fetchContentAssignments(currentCourseContentItemId, courseResourceElementId, currentClassroomId, entityId, page);
    }
  }

  handleSelfPacedOpen = async (contentMode, contentElement) => {
    const { assignmentManager, classroomManager, courseManager } = this.props;
    // This takes a bit with the assignment and then the assess launch so show loader.
    this.setState({ isLoading: true });
    // first, create the assignment
    await assignmentManager.studentCreateAssignment(contentElement.contentItemId, contentElement.elementId, classroomManager.currentClassroomId, courseManager.currentCourseId);
    // then call to open it.
    if (assignmentManager.getLastAddedAssignment()) {
      const assignment = assignmentManager.getLastAddedAssignment();
      const params = { assignment, contentMode };
      this.handleAssignmentViewPresent(params);
    }
  }

  handleAssignmentViewPresent = async (params) => {
    if (params.assignment) {
      const { handlePresent } = this.props;
      await handlePresent(params);
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { contentAssignmentsOpen, contentName, assignmentManager, labelInfo, courseElement } = this.props;
    const { assignmentArray } = assignmentManager;
    let label = 'Assignment Information: ';
    if (labelInfo !== undefined && labelInfo !== '' && labelInfo !== null) {
      label = labelInfo;
    }
    const assignments = assignmentArray || [];
    const numberOfAssignments = assignments.length;
    const startedAssignments = assignments.filter((activity) => activity.status === 'started');
    const hasStartedAssignments = (startedAssignments) ? startedAssignments.length > 0 : false;
    const attempts = (courseElement && courseElement.attempts) ? ResourcePacingService.attempts(courseElement) : 0;
    const attemptsRemaining = (attempts > numberOfAssignments) ? attempts - numberOfAssignments : 0;
    const { ModalBanner } = this;
    return (
      <>
        <Modal
          className='StudentAssignmentModal'
          closeOnDimmerClick={false}
          closeOnEscape={true}
          onClose={this.closeStudentAssignmentModal}
          open={contentAssignmentsOpen}
          size='large'>
          <ModalBanner label={label} onClose={this.closeStudentAssignmentModal} title={contentName} />
          <Modal.Content scrolling>
            {courseElement.pacingMode === 'student_paced' && (
              <Message>
                <Message.Content>
                  <span>
                    Attempts Left:
                    {attemptsRemaining}
                  </span>
                  <span>
                    {(!hasStartedAssignments && attemptsRemaining > 0) && (
                      <Button
                        className='leaf-button'
                        disabled={this.state.isLoading}
                        loading={this.state.isLoading}
                        onClick={() => this.handleSelfPacedOpen(CONTENT_MODE.ASSESS, courseElement)}
                        primary>
                        <Icon name='redo alternate' />
                        Try Again
                      </Button>
                    )}
                  </span>
                </Message.Content>
              </Message>
            )}
            <Container fluid>
              {(assignments.length > 0) ? (
                <div className='ui items parent'>
                  <InfiniteScroll
                    className='ui items scroller'
                    hasMore={assignmentManager.hasMore}
                    loadMore={this.handleLoadMore}
                    loader={<Loader key={0} active inline />}
                    pageStart={0}
                    threshold={50}>
                    {this.renderAssignmentCards(assignments)}
                  </InfiniteScroll>
                </div>
              ) : <Loader active />}
            </Container>
          </Modal.Content>

          <Modal.Actions>
            <Button basic className='cancelButton' onClick={this.closeStudentAssignmentModal} primary>Close</Button>
          </Modal.Actions>

        </Modal>

      </>
    );
  }
}

SatCoreRegister('StudentAssignmentModal', StudentAssignmentModal);
