import React, { useContext, useEffect, useState } from 'react';

import { MobXProviderContext } from 'mobx-react';

import { Button, Icon } from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/LibraryContentUsageModal.less';
import UtilityService from '../../services/UtilityService';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

const ModalBanner = SatCoreComponent('ModalBanner');

const LibraryContentUsageModal = (props) => {
  const { contentItemId, libraryContentUsageModalOpen, close, t } = props;

  const {
    contentManager, 
  } = useContext(MobXProviderContext);
  
  const [usageResults, setUsageResults] = useState([]);
  const [usageResultsLoading, setUsageResultsLoading] = useState(true);

  const SatCoreLoader = SatCoreComponent('SatCoreLoader');

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  useEffect(() => {
    if (!usageResults || usageResults.length < 1) {
      (async () => {
        const contentUsage = await contentManager.fetchContentUsage(contentItemId);
        setUsageResults(contentUsage);
        setUsageResultsLoading(false);
      })();
    }
  }, []);

  return (
    <Modal
      className='library-content-usage-modal'
      closeOnDimmerClick={true}
      closeOnEscape={true}
      open={libraryContentUsageModalOpen}>
      <ModalBanner
        onClose={close} title={t('modalTitle')} />
        <div className='descriptionText'>{(!usageResultsLoading && usageResults.length < 1) ? t('noResultsMessage') : t('modalDescriptionText')}</div>
        {usageResultsLoading ?
            <Modal.Content>
              <SatCoreLoader height='325px'/>
            </Modal.Content>
          :
            <Modal.Content className='' scrolling>
              <div className='container userUsageList'>
                {usageResults.map((usageItem, index) => {
                  return (
                    <div key={index} className='user'>
                      {usageItem.ownerDisplayName}
                    </div>
                  )
                })}
              </div>
            </Modal.Content>
        }
          
      <Modal.Actions>
        <Button basic className='modal-close-button' onClick={close} primary
          type='button'>{t('modalCloseLabel')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default LibraryContentUsageModal;
SatCoreRegister('LibraryContentUsageModal', LibraryContentUsageModal);
