import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Radio } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import Modal from '../Modal';
import '../../css/AddJoinClassModal.less';

const AddJoinClassModal = (props) => {
  const { open, close, openAddModal, openJoinModal, t } = props;
  const ModalBanner = useMemo(() => SatCoreComponent('ModalBanner'), []);
  const [classChoice, setClassChoice] = useState(null);
  const onSave = useCallback(() => {
    close();
    classChoice === 'new' ? openAddModal() : openJoinModal();
  }, [classChoice, close, openAddModal, openJoinModal]);
  return (
    <Modal
      className='add-join-class-modal'
      closeOnDimmerClick={false}
      closeOnEscape={true}
      onClose={close}
      open={open}>
      <ModalBanner onClose={close} title={t('addJoinClass', 'Add / Join Class')} />
      <Modal.Content>
        <Form.Field>
          <Radio
            checked={classChoice === 'new'}
            label={t('addNewClass', '+ Add New Class')}
            name='radioGroup'
            onChange={(e, { value }) => setClassChoice(value)}
            value='new' />
        </Form.Field>
        <Form.Field>
          <Radio
            checked={classChoice === 'join'}
            label={t('joinClass', '+ Join Class')}
            name='radioGroup'
            onChange={(e, { value }) => setClassChoice(value)}
            value='join' />
        </Form.Field>
      </Modal.Content>
      <Modal.Actions>
        <Button basic className='cancelButton' onClick={close} primary>
          {t('cancel')}
        </Button>
        <Button className='ui primary button saveButton' disabled={classChoice === null} onClick={onSave}>
          {t('save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddJoinClassModal;

SatCoreRegister('AddJoinClassModal', AddJoinClassModal);
