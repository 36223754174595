import { action, computed, makeObservable, observable, toJS } from 'mobx';

import { getRegisteredClass } from '../SatCoreRegistry';

import assignmentManager from './AssignmentManager';
import Auth from './AuthManager';

import { generateUrl } from '../utils/url';

const ESSENTIALLY_ALL_TABLE_ROWS = 9999;

const GRADEBOOK_ENDPOINTS = {
  FETCH_AGGREGATE_GRADEBOOK_DATA: '/api/viewAggregateGradebook',
  FETCH_AGGREGATE_GRADEBOOK_DETAILS: '/api/viewAggregateGradebookDetail',
  FETCH_AGGREGATE_GRADEBOOK_STANDARDS: '/api/viewAggregateGradebookStandards',
  FETCH_ASSIGNMENTS_PROCESSING_COUNT: '/api/getAssignmentsProcessingCount',
  FETCH_GRADEBOOK_ACTIVITY: '/api/viewActivityGradebook',
  FETCH_GRADEBOOK_DETAILS: '/api/viewActivityGradebookDetail',
  SUBMIT_REPORT_REQUEST: '/api/submitReportRequest',
  UPDATE_ACTIVITY_ELEMENT_AGGREGATE_EXCLUSION: '/api/setAggregateElementExclusion',
  UPDATE_ACTIVITY_ELEMENT_EXCLUSION: '/api/setActivityElementExclusion',
  UPDATE_GRADE: '/api/updateActivityInstanceGrade'
};

export class GradebookManager {
  @observable activeGradebookType = 'aggregate'; // 'aggregate' only ('nonAggregate' is defunct)
  @observable activeGradebookTable = 'summary'; // 'summary' | 'details' | 'engagement' | 'standards'

  @observable activeGradebookTypeOnGradebookComponentMount = null;
  @observable activeGradebookTableOnGradebookComponentMount = null;

  @observable hasMoreAggregateTableRows = null;
  @observable allowLoadMoreAggregateTableRows = true;

  @observable gradebookSummaryTableSortColumn = null;
  @observable gradebookSummaryTableSortDirection = 'ascending';

  @observable gradebookDetailsTableSortColumn = null;
  @observable gradebookDetailsTableSortDirection = 'ascending';

  @observable gradebookDetailsTableSortTrueHeaderScoreIndex = -1;
  @observable activeGradebookDetailsHorizontalScoresPage = 1;

  @observable gradebookTableRowsTotalFromBackend = null;

  @observable assignmentInstancesMap = new Map();
  @observable assignmentToRepInstanceBulkCheckedMap = new Map();
  @observable bulkCheckedAll = false;
  @observable bulkCheckedAssignmentMembers = new Map();

  @observable currentAssignmentId = null;
  @observable currentAssignment = null;

  @observable graderUrl = null;

  @observable useSubmitButton = true;

  @observable shouldUsePodiumIconForInstructions = false;

  @observable gradebookManagerLoadingFlagWhileHidingViewActive = false;
  @observable gradebookManagerLoadingFlagActive = false;
  @observable forceRefreshAllGradebookData = false;
  @observable shouldHideUnscorableColumnsObservable = this.shouldHideUnscorableColumns;
  @observable useGradebookHeaderResourceInfo = false;
  @observable useStudentWorkScoreButton = false;

  @observable allowAssignmentLink = true;
  @observable allowFacultyGradebookAccessibility = false;
  @observable allowLearnosityGradebookDetail = false;
  @observable allowGradebookStandards = false;

  @observable hideDetailsDates = false; // Optional Sat param to hide both start/end date columns in details page
  @observable showDetailsGradeAsPercentage = true; // Configurable Sat param to show percent grade column in details page
  @observable showDetailsGradeAsRawScore = false; // Configurable Sat param to show raw score column in details page

  @observable gradebookStandardsData = null;

  @observable assignmentsProcessingCount = 0;

  earliestStartDate = null;
  earliestTimezoneStartDate = null;
  earliestTimezoneStartTime = null;
  latestEndDate = null;
  latestTimezoneEndDate = null;
  latestTimezoneEndTime = null;

  // gradebook standards page
  gsClassroomId = null;
  gsCourseContentItemId = null;
  gsResourceContentItemId = null;
  gradebookStandardsMap = new Map();

  /**
   * ```
   * interface GradebookDetails {
   *   activityId: string; // for 'nonAggregate' only
   *   classSummary: ClassSummary; // for 'nonAggregate' only
   *   headerInfo: HeaderInfo[];
   *   maxScores:  number[]; // for 'nonAggregate' only
   *   students:   Student[];
   * }
   * interface ClassSummary { // for 'nonAggregate' only
   *   grade: number;
   *   scores: Array<number | null>;
   *   usages: Array<number | null>;
   *   viewedTime: number; // indicates total average 'Usage', across all score cells
   * }
   * interface HeaderInfo {
   *   activityElement: ActivityElement; // for 'nonAggregate' only
   *   elementId:       string; // for 'nonAggregate' only
   *   label:           string;
   *   number:          string;
   *   questionStem:    string; // for 'nonAggregate' only
   * }
   * interface ActivityElement {
   *   activityId:         string;
   *   autoScored:         boolean;
   *   creationDate:       string;
   *   entityId:           string;
   *   entityTypeId:       string;
   *   excludeFromScoring: boolean;
   *   id:                 string;
   *   maxScore:           number;
   *   modificationDate:   string;
   *   orderNum:           number;
   *   scorable:           boolean;
   *   scoringType:        string;
   * }
   * interface Student { // note: aggregate student object has a lot of properties. not all of them are listed here.
   *   activityElements:          ActivityElement[]; // for 'aggregate' only
   *   activityId:                string; // for 'aggregate' only
   *   activityTimezoneStartDate: string; // for 'aggregate' only
   *   firstName:                 string;
   *   grade:                     number;
   *   id:                        string; // for 'aggregate' only
   *   lastName:                  string;
   *   maxScores:                 number[]; // for 'aggregate' only
   *   questionStems:             Array<string | null>; // for 'aggregate' only
   *   scores:                    Array<number | null>;
   *   scoreStates:               Array<SCORE_STATE[prop] | null>;
   *   studentId:                 string; // for 'nonAggregate' only
   *   submitted:                 boolean; // for 'aggregate' only
   *   usages:                    Array<number | null>
   *   viewedTime:                number; // indicates total 'Usage' for student, across all score cells
   * }
   * ```
   */
  @observable gradebookDetails = null;

  constructor() {
    makeObservable(this);
  }

  @action
  clearAll = () => {
    this.clearAssignmentInstances();
    this.setHasMoreAggregateTableRows(null);
    this.currentAssignmentId = null;
    this.currentAssignment = null;
    this.graderUrl = null;
  }

  @action setActiveGradebookType = (activeGradebookType) => {
    this.activeGradebookType = activeGradebookType;
  }

  @action setActiveGradebookTable = (activeGradebookTable) => {
    this.activeGradebookTable = activeGradebookTable;
  }

  @action setActiveGradebookTypeOnGradebookComponentMount = (activeGradebookTypeOnGradebookComponentMount) => {
    this.activeGradebookTypeOnGradebookComponentMount = activeGradebookTypeOnGradebookComponentMount;
  }

  @action setActiveGradebookTableOnGradebookComponentMount = (activeGradebookTableOnGradebookComponentMount) => {
    this.activeGradebookTableOnGradebookComponentMount = activeGradebookTableOnGradebookComponentMount;
  }

  @action setGradebookSummaryTableSortColumn = (column) => {
    this.gradebookSummaryTableSortColumn = column;
  }

  @action reverseGradebookSummaryTableSortDirection = (direction = null) => {
    direction = direction || this.gradebookSummaryTableSortDirection;
    this.setGradebookSummaryTableSortDirection(
      direction && direction.includes('desc') ? 'ascending' : 'descending'
    );
  }

  @action setGradebookSummaryTableSortDirection = (direction) => {
    this.gradebookSummaryTableSortDirection = direction;
  }

  @action setGradebookDetailsTableSortColumn = (column) => {
    this.gradebookDetailsTableSortColumn = column;
  }

  @action reverseGradebookDetailsTableSortDirection = (direction = null) => {
    direction = direction || this.gradebookDetailsTableSortDirection;
    this.setGradebookDetailsTableSortDirection(
      direction && direction.includes('desc') ? 'ascending' : 'descending'
    );
  }

  @action setGradebookDetailsTableSortDirection = (direction) => {
    this.gradebookDetailsTableSortDirection = direction;
  }

  @action setGradebookDetailsTableSortTrueHeaderScoreIndex = (index) => {
    this.gradebookDetailsTableSortTrueHeaderScoreIndex = index;
  }

  @action setActiveGradebookDetailsHorizontalScoresPage = (page) => {
    this.activeGradebookDetailsHorizontalScoresPage = page;
  }

  @action setGradebookTableRowsTotalFromBackend = (pageTotal) => {
    this.gradebookTableRowsTotalFromBackend = typeof +pageTotal === 'number' ? +pageTotal : 0;
  }

  @action setAssignmentInstances = (instances) => {
    this.clearAssignmentInstances();
    if (instances && instances.length) {
      instances.map((instance) => this.assignmentInstancesMap.set(instance.id, instance));
    }
  }

  @action setCurrentAssignmentId = (id) => {
    this.currentAssignmentId = id;
  }

  @action setCurrentAssignment(assignment) {
    this.currentAssignment = observable(assignment);
    this.setCurrentAssignmentId(this.currentAssignment.id);
  }

  @action setUseSubmitButton = (use) => {
    this.useSubmitButton = use;
  }

  @action setShouldUsePodiumIconForInstructions = (shouldUsePodiumIcon) => {
    this.shouldUsePodiumIconForInstructions = shouldUsePodiumIcon;
  }

  @action setGradebookManagerLoadingFlagWhileHidingViewActive = (toggle) => {
    this.gradebookManagerLoadingFlagWhileHidingViewActive = toggle;
  }

  @action setGradebookManagerLoadingFlagActive = (toggle) => {
    this.gradebookManagerLoadingFlagActive = toggle;
  }

  @action setForceRefreshAllGradebookData = (toggle) => {
    this.forceRefreshAllGradebookData = toggle;
  }

  @action setUseStudentWorkScoreButton = (toggle) => {
    this.useStudentWorkScoreButton = toggle;
  }

  @action clearAssignmentInstances = () => {
    this.assignmentInstancesMap.clear();
  }

  @action clearAssignmentInstancesBulkChecked = () => {
    this.assignmentToRepInstanceBulkCheckedMap.clear();
  }

  @action clearBulkCheckedAssignmentMembers = () => {
    this.assignmentToRepInstanceBulkCheckedMap.clear();
    this.bulkCheckedAssignmentMembers.clear();
    this.bulkCheckedAll = false;
  }

  @action setAssignmentInstanceFeedback = (assignmentInstanceId, hasFeedback) => {
    const assignmentInstance = this.assignmentInstancesMap.get(assignmentInstanceId);
    if (assignmentInstance) {
      assignmentInstance.teacherFeedback = !!hasFeedback;
      this.assignmentInstancesMap.set(assignmentInstanceId, assignmentInstance);
    }
  }

  @action setBulkAssignmentMembers = (assignmentId, userId) => {
    if (this.bulkCheckedAssignmentMembers) {
      if (this.bulkCheckedAssignmentMembers.has(assignmentId)) {
        const assignmentMembers = this.bulkCheckedAssignmentMembers.get(assignmentId);
        if (!assignmentMembers.includes(userId)) {
          assignmentMembers.push(userId);
          this.bulkCheckedAssignmentMembers.set(assignmentId, assignmentMembers);
        }
      } else {
        this.bulkCheckedAssignmentMembers.set(assignmentId, [userId]);
      }
    } else {
      this.bulkCheckedAssignmentMembers = new Map();
      this.bulkCheckedAssignmentMembers.set(assignmentId, [userId]);
    }
  }

  @action setAssignmentInstanceBulkChecked = (assignmentInstanceId) => {
    const selectedAssignmentInstance = this.assignmentInstancesMap.get(assignmentInstanceId);
    // toggle and track bulk checked.
    if (selectedAssignmentInstance) {
      if (selectedAssignmentInstance.bulkChecked) {
        for (const assignmentInstance of this.assignmentInstancesMap.values()) {
          if (assignmentInstance.activityId === selectedAssignmentInstance.activityId) {
            assignmentInstance.bulkChecked = false;
            this.assignmentToRepInstanceBulkCheckedMap.delete(assignmentInstance.activityId);
            this.bulkCheckedAssignmentMembers.delete(assignmentInstance.activityId);
          }
        }
      } else {
        for (const assignmentInstance of this.assignmentInstancesMap.values()) {
          if (assignmentInstance.activityId === selectedAssignmentInstance.activityId) {
            assignmentInstance.bulkChecked = true;
            //  this.assignmentInstancesMap.set(assignmentInstanceId, assignmentInstance);
            if (!this.assignmentToRepInstanceBulkCheckedMap.get(selectedAssignmentInstance.activityId)) {
              this.assignmentToRepInstanceBulkCheckedMap.set(selectedAssignmentInstance.activityId, assignmentInstance);
            }
            // Create a list of all the bulk checked assignment members, as long as they have submitted.
            if (assignmentInstance.submitted) {
              this.setBulkAssignmentMembers(assignmentInstance.activityId, assignmentInstance.userId);
            }
          }
        }
      }
    }
  }

  @action setAllAssignmentInstanceBulkChecked = (checked) => {
    for (const assignmentInstance of this.assignmentInstancesMap.values()) {
      assignmentInstance.bulkChecked = checked;
      // Create a list of all the bulk checked assignment members, as long as they have submitted.
      if (assignmentInstance.submitted) {
        this.setBulkAssignmentMembers(assignmentInstance.activityId, assignmentInstance.userId);
      }
    }

    this.bulkCheckedAll = checked;
  }

  // Will set all linked instances (activityNumber is the same) as bulk checked for class/group level edit purposes
  @action setLinkedAssignmentInstancesBulkChecked = (assignmentInstanceId) => {
    const selectedAssignmentInstance = this.assignmentInstancesMap.get(assignmentInstanceId);
    // toggle and track bulk checked.
    if (selectedAssignmentInstance) {
      for (const assignmentInstance of this.assignmentInstancesMap.values()) {
        if (assignmentInstance.activityNumber === selectedAssignmentInstance.activityNumber) {
          if (!this.assignmentToRepInstanceBulkCheckedMap.get(assignmentInstance.activityId)) {
            this.assignmentToRepInstanceBulkCheckedMap.set(assignmentInstance.activityId, assignmentInstance);
          }
        }
      }
    }
  }

  @action setGradebookDetails = (details, clearStudentsFirst = false, clearAllFirst = false) => {
    if (!details) {
      this.gradebookDetails = null;
      return;
    }
    if (clearAllFirst) {
      this.gradebookDetails = null;
    }
    let currentStudents = [];
    if (!clearStudentsFirst && this.gradebookDetails) {
      currentStudents = [...this.gradebookDetails.students];
    }
    const studentsWithPossibleDuplicates = [...currentStudents, ...details.students];

    const map = new Map();
    studentsWithPossibleDuplicates.map((student) => {
      const studentId = student.studentId || student.id;
      map.set(studentId, student);
    });
    const studentsWithNoDuplicates = Array.from(toJS(map).values());
    details.students = studentsWithNoDuplicates;

    this.gradebookDetails = details;
  }

  @action setGradebookStandards = (gradebookStandardsData) => {
    this.gradebookStandardsData = gradebookStandardsData;
  }

  @action setAssignmentsProcessingCount = (assignmentsProcessingCount) => {
    this.assignmentsProcessingCount = assignmentsProcessingCount;
  }

  /** @param {number | boolean | null} totalLength */
  @action setHasMoreAggregateTableRows = (totalLength, fetchedAllTableRows = false) => {
    if (fetchedAllTableRows) {
      this.hasMoreAggregateTableRows = false;
      return;
    } else if (typeof totalLength === 'boolean') {
      this.hasMoreAggregateTableRows = !!totalLength;
      return;
    } else if (typeof totalLength !== 'number') {
      this.hasMoreAggregateTableRows = null;
      return;
    }
    let currentLength;
    if (this.activeGradebookTable === 'summary') {
      currentLength = this.assignmentInstances && this.assignmentInstances.length || 0;
    } else if (this.activeGradebookTable === 'details') {
      const students = this.gradebookDetails && this.gradebookDetails.students;
      currentLength = students && students.length || 0;
    }
    const hasMore = currentLength < totalLength;
    this.hasMoreAggregateTableRows = hasMore;
  }

  @action setAllowLoadMoreAggregateTableRows = (allowLoadMore) => {
    this.allowLoadMoreAggregateTableRows = allowLoadMore;
  }

  @action setBulkCheckAll = () => {
    if (this.bulkCheckedAll) {
      // clear and set false.
      this.setAllAssignmentInstanceBulkChecked(false);
      this.clearAssignmentInstancesBulkChecked();
      // this.bulkCheckedAll = false;
    } else {
      // populate and set true.
      this.setAllAssignmentInstanceBulkChecked(true);
      for (const assignmentInstance of this.assignmentInstancesMap.values()) {
        if (!this.assignmentToRepInstanceBulkCheckedMap.get(assignmentInstance.activityId)) {
          this.assignmentToRepInstanceBulkCheckedMap.set(assignmentInstance.activityId, assignmentInstance);
        }
      }

      // this.bulkCheckedAll = true;
    }
  }

  @action setShouldHideUnscorableColumns = (toggle) => {
    if (toggle) {
      sessionStorage.setItem('shouldHideUnscorableColumns', 'true');
    } else {
      sessionStorage.removeItem('shouldHideUnscorableColumns');
    }
    this.shouldHideUnscorableColumnsObservable = toggle;
  }

  @action setBoundaryStartEndDates = (earliestStartDate, earliestTimezoneStartDate, earliestTimezoneStartTime,
    latestEndDate, latestTimezoneEndDate, latestTimezoneEndTime) => {
    this.earliestStartDate = earliestStartDate;
    this.earliestTimezoneStartDate = earliestTimezoneStartDate;
    this.earliestTimezoneStartTime = earliestTimezoneStartTime;
    this.latestEndDate = latestEndDate;
    this.latestTimezoneEndDate = latestTimezoneEndDate;
    this.latestTimezoneEndTime = latestTimezoneEndTime;
  }

  @computed get shouldHideUnscorableColumns() {
    let key = null;
    try {
      key = sessionStorage.getItem('shouldHideUnscorableColumns');
      return key === 'true';
    } catch (error) {
      console.debug('SessionStorage access denied', error);
      return false;
    }
  }

  // Set this to true from satellite index.js to show full resource card info in Gradebook header instead of just title.
  setUseGradebookHeaderResourceInfo = (useGradebookHeaderResourceInfo) => {
    this.useGradebookHeaderResourceInfo = useGradebookHeaderResourceInfo;
  }

  // Set this to true from satellite index.js to allow teacher to copy a deep link to an assignment
  setAllowAssignmentLink = (allowAssignmentLink) => {
    this.allowAssignmentLink = allowAssignmentLink;
  }

  @action setAllowFacultyGradebookAccessibility = (allowAccessibility) => {
    this.allowFacultyGradebookAccessibility = allowAccessibility;
  }

  @action setAllowLearnosityGradebookDetail = (allowLearnosityGradebookDetail) => {
    this.allowLearnosityGradebookDetail = allowLearnosityGradebookDetail;
  }

  @action setAllowGradebookStandards = (allowGradebookStandards) => {
    this.allowGradebookStandards = allowGradebookStandards;
  }

  @action setHideDetailsDates = (hideDetailsDates) => {
    this.hideDetailsDates = hideDetailsDates;
  }

  @action setShowDetailsGradeAsPercentage = (showDetailsGradeAsPercentage) => {
    this.showDetailsGradeAsPercentage = showDetailsGradeAsPercentage;
  }

  @action setShowDetailsGradeAsRawScore = (showDetailsGradeAsRawScore) => {
    this.showDetailsGradeAsRawScore = showDetailsGradeAsRawScore;
  }

  @computed get assignmentInstances() {
    if (this.assignmentInstancesMap && this.assignmentInstancesMap.size) {
      return Array.from(toJS(this.assignmentInstancesMap).values());
    }
    return [];
  }

  @computed get bulkActivityIds() {
    if (this.assignmentToRepInstanceBulkCheckedMap && this.assignmentToRepInstanceBulkCheckedMap.size) {
      return Array.from(toJS(this.assignmentToRepInstanceBulkCheckedMap).values()).map(({ activityId }) => activityId);
    }
    return [];
  }

  @computed get bulkActivityNumbers() {
    if (this.assignmentToRepInstanceBulkCheckedMap && this.assignmentToRepInstanceBulkCheckedMap.size) {
      return Array.from(toJS(this.assignmentToRepInstanceBulkCheckedMap).values()).map(({ activityNumber }) => activityNumber);
    }
    return [];
  }

  @computed get gradebookDetailsTableHorizontalScoresPageTotal() {
    if (this.gradebookDetails && this.gradebookDetails.headerInfo) {
      return Math.ceil(+this.gradebookDetails.headerInfo.length / this.GRADEBOOK_DETAILS_TABLE_HORIZONTAL_SCORES_PAGE_SIZE);
    }
    return 1;
  }

  @computed get GRADEBOOK_TABLE_ROWS_PAGE_SIZE() {
    return 25;
  }

  @computed get GRADEBOOK_DETAILS_TABLE_HORIZONTAL_SCORES_PAGE_SIZE() {
    // We have potential for hidden/shown columns based on satellite config so calculate the max based on that.
    let horizontalColumnCount = 9;
    horizontalColumnCount = this.hideDetailsDates ? horizontalColumnCount + 3 : horizontalColumnCount;
    horizontalColumnCount = this.showDetailsGradeAsPercentage ? --horizontalColumnCount : horizontalColumnCount;
    horizontalColumnCount = this.showDetailsGradeAsRawScore ? --horizontalColumnCount : horizontalColumnCount;
    return horizontalColumnCount;
  }

  fetchGradebookData = async (id, { onSetAssignmentInstances } = {}, includeAlignments = false) => {
    try {
      this.setGradebookManagerLoadingFlagActive(true);
      const activityInstancesResponse = await Auth.fetch(`${Auth.ecms}/api/viewActivityInstancesGradebook?id=${id}`, {
        method: 'GET'
      });
      if (activityInstancesResponse && activityInstancesResponse.status === 'SUCCESS') {
        const assignmentInstances = activityInstancesResponse.data.sort((a, b) => {
          const lastNameA = a.lastName ? a.lastName : '';
          const lastNameB = b.lastName ? b.lastName : '';

          const firstNameA = a.firstName ? a.firstName : '';
          const firstNameB = b.firstName ? b.firstName : '';

          const predicate1 = lastNameA.localeCompare(lastNameB, 'en', {
            numeric: true
          });
          const predicate2 = firstNameA.localeCompare(firstNameB, 'en', {
            numeric: true
          });
          return predicate1 || predicate2;
        });
        this.setAssignmentInstances(assignmentInstances);
        onSetAssignmentInstances && onSetAssignmentInstances();
      }
      let url = `${Auth.ecms}${GRADEBOOK_ENDPOINTS.FETCH_GRADEBOOK_ACTIVITY}?id=${id}`;
      if (includeAlignments) {
        url += `&includeAlignments=${includeAlignments}`;
      }
      const activityGradebookResponse = await Auth.fetch(url, {
        method: 'GET'
      });
      this.setGradebookTableRowsTotalFromBackend(null); // we currently handle nonAggregate sorting on the front-end

      if (activityGradebookResponse && activityGradebookResponse.status === 'SUCCESS') {
        const assignment = activityGradebookResponse.data;
        this.setCurrentAssignment(assignment);
        assignmentManager.setAssignment(assignment);
        this.setGradebookManagerLoadingFlagActive(false);
        return this.currentAssignment;
      }
      this.setGradebookManagerLoadingFlagActive(false);
    } catch (error) {
      console.error(error);
      this.setGradebookManagerLoadingFlagActive(false);
      return null;
    }
  }

  fetchAssignmentsProcessingCount = async (assignment) => {
    // console.log('fetchAssignmentsProcessingCount');
    const { contentItemId, courseContentItemId } = assignment;
    const apiUrlPrefix = `${Auth.ecms}${GRADEBOOK_ENDPOINTS.FETCH_ASSIGNMENTS_PROCESSING_COUNT}`;
    let classroomId;
    if (assignment.classroomId) {
      classroomId = assignment.classroomId;
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      classroomId = urlParams.get('classroomId');
    }

    const apiUrl = generateUrl(apiUrlPrefix, {
      classroomId, contentItemId, courseContentItemId
    });

    const response = await Auth.fetch(apiUrl, {
      method: 'GET'
    });

    const count = response.data;
    this.setAssignmentsProcessingCount(count);
    return count;
  };

  // TODO DEMO-746, DEMO-907 paginate pageSize & handle sorting via backend (R32)
  fetchAggregateGradebookData = async (
    assignment, sortField = null, sortDirection = null, page = 0,
    pageSize = null, clearFirst = false, functions = null
  ) => {
    try {
      const ProductService = getRegisteredClass('ProductService');

      // TODO remove; causes potential expired license warnings to disappear for a few milliseconds if sorting/fetching more rows
      // ProductService.setShouldShowExpiredLicenseWarning(false);

      this.setGradebookManagerLoadingFlagActive(true);

      let isSpecialSortCaseFn = () => false, isSpecialSortCase = false;
      if (functions && functions.isSpecialAggregateGradebookSummarySortCase) {
        isSpecialSortCaseFn = functions.isSpecialAggregateGradebookSummarySortCase;
        isSpecialSortCase = isSpecialSortCaseFn(sortField);
      }
      const currentLength = this.assignmentInstances && this.assignmentInstances.length || 0;
      const totalLength = this.gradebookTableRowsTotalFromBackend;

      let skip;

      const alreadyHaveAllData = currentLength && totalLength && currentLength >= totalLength;
      const shouldFetchAllDataAtOnce = pageSize === ESSENTIALLY_ALL_TABLE_ROWS && this.activeGradebookType === 'aggregate';

      if (pageSize !== ESSENTIALLY_ALL_TABLE_ROWS && !clearFirst && alreadyHaveAllData) {
        this.setGradebookManagerLoadingFlagActive(false);
        return;
      } else if (shouldFetchAllDataAtOnce) {
        clearFirst = true;
        skip = 0;
      } else {
        pageSize = pageSize || this.GRADEBOOK_TABLE_ROWS_PAGE_SIZE;
        skip = (page ? page - 1 : 0) * pageSize;

        if (skip > currentLength) {
          // prevent InfiniteScroll `loadMore` bug. fetch all data at once.
          // if a user attempts to sort a column when the table is not completely loaded,
          // then tries to load more, InfiniteScroll `page` does not reset.
          // so if the user is sorting a column, we need to load all table data from scratch
          // (once `loadMore` is triggered after sorting).
          clearFirst = true;
          page = 0;
          skip = 0;
          pageSize = ESSENTIALLY_ALL_TABLE_ROWS;
        }
      }

      sortField = sortField || this.gradebookSummaryTableSortColumn;
      sortDirection = sortDirection || this.gradebookSummaryTableSortDirection;

      const { contentItemId, courseContentItemId } = assignment;
      const apiUrlPrefix = `${Auth.ecms}${GRADEBOOK_ENDPOINTS.FETCH_AGGREGATE_GRADEBOOK_DATA}`;
      let classroomId;
      if (assignment.classroomId) {
        classroomId = assignment.classroomId;
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        classroomId = urlParams.get('classroomId');
      }

      /* GENERATE `apiUrl` */
      let apiUrl = generateUrl(apiUrlPrefix, {
        classroomId, contentItemId, courseContentItemId
      });
      if (sortField && !isSpecialSortCase) {
        apiUrl += `&sort[0][field]=${sortField}`;
      }
      if (sortDirection) {
        apiUrl += `&sort[0][dir]=${sortDirection.includes('desc') ? 'desc' : 'asc'}`;
      }
      apiUrl += `&skip=${skip}&pageSize=${pageSize}`;

      const response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });

      if (!isSpecialSortCase) {
        this.setGradebookSummaryTableSortColumn(sortField);
        this.setGradebookSummaryTableSortDirection(sortDirection);
      }
      const newAssignmentInstances = response.data;

      if (clearFirst || isSpecialSortCase) {
        const assignmentInstances = [...newAssignmentInstances];
        this.setAssignmentInstances(assignmentInstances);

        const shouldShowExpiredLicenseWarning = assignmentInstances.some((assignmentInstance) => assignmentInstance.licenseExpired);
        ProductService.setShouldShowExpiredLicenseWarning(!!shouldShowExpiredLicenseWarning);
      } else {
        const assignmentInstances = [...this.assignmentInstances, ...newAssignmentInstances];
        this.setAssignmentInstances(assignmentInstances);

        const shouldShowExpiredLicenseWarning = assignmentInstances.some((assignmentInstance) => assignmentInstance.licenseExpired);
        ProductService.setShouldShowExpiredLicenseWarning(!!shouldShowExpiredLicenseWarning);
      }

      // aggregate gradebook assignmentInstances can belong to different assignments
      // here we are ensuring we have these assignments fetched in advance
      // we will need them if/when the teacher interacts with any of the table rows
      for (const instance of newAssignmentInstances) {
        await assignmentManager.getAssignmentAsync(instance.activityId);
      }
      const newTotalLength = typeof +response.pageTotal === 'number' ? +response.pageTotal : 0;
      this.setGradebookTableRowsTotalFromBackend(newTotalLength);

      const fetchedAllTableRows = pageSize === ESSENTIALLY_ALL_TABLE_ROWS;
      this.setHasMoreAggregateTableRows(newTotalLength, fetchedAllTableRows);
      this.setGradebookManagerLoadingFlagActive(false);
    } catch (error) {
      console.error(error);
      this.setGradebookManagerLoadingFlagActive(false);
    }
  }

  fetchGradebookDetails = async (activityId) => {
    try {
      this.setGradebookManagerLoadingFlagActive(true);
      this.setGradebookDetails(null);

      const hideUnscorable = this.shouldHideUnscorableColumnsObservable;

      let apiUrl = `${Auth.ecms}${GRADEBOOK_ENDPOINTS.FETCH_GRADEBOOK_DETAILS}`;
      apiUrl += `?id=${activityId}&hideUnscorable=${hideUnscorable}`;

      const response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
      this.setGradebookDetails(response.data);

      const headerInfoLength = +(response.data && response.data.headerInfo && response.data.headerInfo.length) || 0;
      this.determineAndSetActiveGradebookDetailsHorizontalScoresPage(headerInfoLength);

      this.setGradebookTableRowsTotalFromBackend(null); // we currently handle nonAggregate sorting on the front-end
      this.setGradebookManagerLoadingFlagActive(false);
    } catch (error) {
      console.error(error);
      this.setGradebookManagerLoadingFlagActive(false);
    }
  }

  fetchGradebookActivity = async (activityId, includeAlignments = false) => {
    try {
      const apiUrl = `${Auth.ecms}${GRADEBOOK_ENDPOINTS.FETCH_GRADEBOOK_ACTIVITY}?id=${activityId}&includeAlignments=${includeAlignments}`;
      const response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
      const assignment = response && response.data && response.data;
      return assignment;
    } catch (error) {
      console.error(error);
    }
  }

  determineAndSetActiveGradebookDetailsHorizontalScoresPage = (headerInfoLength) => {
    const activePage = this.activeGradebookDetailsHorizontalScoresPage;
    const PAGE_SIZE = this.GRADEBOOK_DETAILS_TABLE_HORIZONTAL_SCORES_PAGE_SIZE;
    const lastPageWithData = Math.ceil(headerInfoLength / PAGE_SIZE);
    this.setActiveGradebookDetailsHorizontalScoresPage(Math.min(activePage, lastPageWithData));
  }

  fetchAggregateGradebookDetails = async (
    assignment, sortField = null, sortDirection = null, page = 0,
    pageSize = null, clearStudentsFirst = false, clearAllFirst = false,
    functions
  ) => {
    try {
      this.setGradebookManagerLoadingFlagActive(true);
      const hideUnscorable = this.shouldHideUnscorableColumnsObservable;

      let isSpecialSortCaseFn = () => false, isSpecialSortCase = false;
      if (functions && functions.isSpecialAggregateGradebookDetailsSortCase) {
        isSpecialSortCaseFn = functions.isSpecialAggregateGradebookDetailsSortCase;
        isSpecialSortCase = isSpecialSortCaseFn(sortField);
      }
      const currentLength = this.gradebookDetails && this.gradebookDetails.students && this.gradebookDetails.students.length || 0;
      const totalLength = this.gradebookTableRowsTotalFromBackend;

      if (!clearStudentsFirst && currentLength && totalLength > 1 && currentLength >= totalLength) {
        this.setGradebookManagerLoadingFlagActive(false);
        return;
      }
      pageSize = pageSize || this.GRADEBOOK_TABLE_ROWS_PAGE_SIZE;
      let skip = (page ? page - 1 : 0) * pageSize;

      if (skip > currentLength) {
        // prevent InfiniteScroll `loadMore` bug.
        // see comments in fetchAggregateGradebookData for more info
        clearStudentsFirst = true;
        page = 0;
        skip = 0;
        pageSize = ESSENTIALLY_ALL_TABLE_ROWS;
      }

      sortField = sortField || this.gradebookDetailsTableSortColumn;
      sortDirection = sortDirection || this.gradebookDetailsTableSortDirection;

      const apiUrlPrefix = `${Auth.ecms}${GRADEBOOK_ENDPOINTS.FETCH_AGGREGATE_GRADEBOOK_DETAILS}`;
      const { contentItemId, courseContentItemId } = assignment;
      let classroomId;
      if (assignment.classroomId) {
        classroomId = assignment.classroomId;
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        classroomId = urlParams.get('classroomId');
      }
      let apiUrl = generateUrl(apiUrlPrefix, {
        classroomId,
        contentItemId,
        courseContentItemId
      });
      if (sortField && !isSpecialSortCase) {
        apiUrl += `&sort[0][field]=${sortField}`;
      }
      if (sortDirection) {
        apiUrl += `&sort[0][dir]=${sortDirection.includes('desc') ? 'desc' : 'asc'}`;
      }
      apiUrl += `&skip=${(page ? page - 1 : 0) * pageSize}`;
      apiUrl += `&pageSize=${pageSize}`;
      apiUrl += `&hideUnscorable=${hideUnscorable}`;

      const response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
      if (!response.data) {
        throw new TypeError('fetchAggregateGradebookDetails: response.data not found');
      }
      if (!isSpecialSortCase) {
        this.setGradebookDetailsTableSortColumn(sortField);
        this.setGradebookDetailsTableSortDirection(sortDirection);
        this.setGradebookDetails(response.data, clearStudentsFirst, clearAllFirst);
      } else {
        clearStudentsFirst = true;
        clearAllFirst = true;
        this.setGradebookDetails(response.data, clearStudentsFirst, clearAllFirst);
      }

      // fetch and store student assignments for later use in aggregate gradebook
      const { students } = response.data;
      for (const student of students) {
        await assignmentManager.fetchActivity(student.activityId);
      }

      if (clearStudentsFirst || isSpecialSortCase) {
        this.setAssignmentInstances([...students]);
      } else {
        this.setAssignmentInstances(
          [...this.assignmentInstances, ...students]
        );
      }

      const headerInfoLength = +(response.data.headerInfo && response.data.headerInfo.length) || 0;
      this.determineAndSetActiveGradebookDetailsHorizontalScoresPage(headerInfoLength);

      const newTotalLength = typeof +response.pageTotal === 'number' ? +response.pageTotal : 0;
      this.setHasMoreAggregateTableRows(newTotalLength);

      this.setGradebookTableRowsTotalFromBackend(+response.pageTotal);
      this.setGradebookManagerLoadingFlagActive(false);
    } catch (error) {
      console.error(error);
      this.setGradebookManagerLoadingFlagActive(false);
    }
  }

  fetchAggregateGradebookStandards = async (assignment) => {
    try {
      this.setGradebookManagerLoadingFlagActive(true);

      const { classroomId } = assignment;
      const { courseContentItemId } = assignment;
      const resourceContentItemId = assignment.contentItemId;

      // use existing data if the assignment is unchanged
      // if (this.gradebookStandardsData && classroomId === this.gsClassroomId && courseContentItemId === this.gsCourseContentItemId
      //     && resourceContentItemId === this.gsResourceContentItemId) {
      //   this.setGradebookManagerLoadingFlagActive(false);
      //   return;
      // }

      this.gsClassroomId = classroomId;
      this.gsCourseContentItemId = courseContentItemId;
      this.gsResourceContentItemId = resourceContentItemId;
      this.gradebookStandardsMap = new Map();
      this.setGradebookStandards(null);

      let apiUrl = '';
      apiUrl = `${Auth.ecms}${GRADEBOOK_ENDPOINTS.FETCH_AGGREGATE_GRADEBOOK_STANDARDS}`;
      apiUrl += `?classroomId=${classroomId}`;
      apiUrl += `&courseContentItemId=${courseContentItemId}`;
      apiUrl += `&resourceContentItemId=${resourceContentItemId}`;

      const response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });

      if (response.status === 'SUCCESS') {
        const { data } = response;

        const { alignmentType, curriculumMaps } = data;
        if (curriculumMaps) {
          if (alignmentType === 'CMAP') {
            // Create a tree of standards with first unit as the root and all other units and all standards with
            // items listed as a flat list under the root.
            curriculumMaps.forEach((cmap) => {
              const { flatStandardList } = cmap;
              if (!flatStandardList || flatStandardList.length === 0) {
                return null;
              }

              const rootList = [];
              let root;
              flatStandardList.forEach((standard) => {
                const { items, type } = standard;
                if (type === 'UNIT' && rootList.length === 0) {
                  root = standard;
                  rootList.push(root);
                } else if (items.length > 0 || type === 'UNIT') {
                  root.children.push(standard);
                }
              });

              cmap['rootList'] = rootList;
            });
          } else if (alignmentType === 'STANDARD') {
            // Create a tree of standards with first standard as the root and all other standards as a flat list under the root.
            curriculumMaps.forEach((cmap) => {
              const { flatStandardList } = cmap;
              if (!flatStandardList || flatStandardList.length === 0) {
                return null;
              }

              const root = flatStandardList[0];
              const rootList = [root];
              flatStandardList.slice(1).forEach((standard) => {
                root.children.push(standard);
              });

              cmap['rootList'] = rootList;
            });
          }
        }

        this.setGradebookStandards(data);
      } else {
        console.error(`${response.status}: ${response.statusMessage}`);
      }

      this.setGradebookManagerLoadingFlagActive(false);
    } catch (error) {
      console.error(error);
      this.setGradebookManagerLoadingFlagActive(false);
    }
  }

  /**
   * update the `excludeFromScoring` boolean flag for the activityElement with the given `activityElementId`
   * @param {string} activityElementId
   * @param {boolean} exclude
   */
  updateActivityElementExclusion = async (activityElementId, exclude, isAggregate = false) => {
    try {
      let apiUrl = '';
      if (isAggregate) {
        apiUrl = `${Auth.ecms}${GRADEBOOK_ENDPOINTS.UPDATE_ACTIVITY_ELEMENT_AGGREGATE_EXCLUSION}`;
      } else {
        apiUrl = `${Auth.ecms}${GRADEBOOK_ENDPOINTS.UPDATE_ACTIVITY_ELEMENT_EXCLUSION}`;
      }
      const body = { activityElementId, exclude };
      const response = await Auth.fetch(apiUrl, { method: 'POST', body });
      if (response.status === 'SUCCESS') {
        // TODO
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  updateActivityInstanceGrade = async (id, grade, score, maxScore) => {
    try {
      const response = await Auth.fetch(Auth.ecms + GRADEBOOK_ENDPOINTS.UPDATE_GRADE, {
        method: 'POST',
        body: {
          id,
          grade,
          score,
          maxScore,
          reactErrorType: true
        }
      });
      if (response.status === 'SUCCESS' && response.activityInstanceStatus !== null && response.activityInstanceStatus !== '') {
        return response.data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // return a className for styling based on grade break.
  getGradeClassName = (grade) => {
    let gradeNum = 0;
    if (isNaN(grade)) {
      gradeNum = parseFloat(grade);
    } else {
      gradeNum = grade;
    }
    if (gradeNum >= 80) {
      return 'grade-high';
    } else if (gradeNum >= 60 && gradeNum < 80) {
      return 'grade-med';
    }
    return 'grade-low';
  }

  getAssignmentLaunchUrl = (linkId) => {
    return `${window.location.protocol}//${window.location.hostname}:${window.location.port}/launch?linkId=${linkId}`;
  }
}

export default new GradebookManager();
