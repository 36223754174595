import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Button, Form, Message } from 'semantic-ui-react';

import Modal from '../Modal';

import '../../css/SelectNewResourceModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { DIALOG_NAMES } from '../../managers/DialogManager';

export default
@inject('courseManager', 'libraryManager', 'dialogManager')
class SelectNewResourceModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.SCRadio = SatCoreComponent('SCRadio');
  }

  getInitialState = () => {
    return {
      error: null,
      selectedAction: ''
    };
  }

  continueCreateLibraryResource = async () => {
    const { closeCreateNewLibraryResourceModal, onCloseCreateNewLibraryResourceModal, openResourceShopping, closeSelectNewResourceModal, dialogManager } = this.props;
    const { selectedAction } = this.state;
    if (selectedAction === 'createNew') {
      // Launch the create new assessment/(bank) wizard 2nd page.
      dialogManager.setOpenDialog(DIALOG_NAMES.CREATE_NEW_LIBRARY_RESOURCE_MODAL, {
        close: closeCreateNewLibraryResourceModal,
        onCloseCreateNewLibraryResourceModal,
        open: true,
        selectedResourceType: 'assessment'
      });
    } else {
      openResourceShopping();
    }
    closeSelectNewResourceModal();
  }

  clearError = () => {
    this.setState({ error: null });
  }

  handleChangeAction = async (event) => {
    const selectedAction = event.target.value;
    this.setState({ selectedAction });
  }

  closeSelectNewResourceModal = () => {
    const { closeSelectNewResourceModal } = this.props;
    closeSelectNewResourceModal();
  }

  render() {
    const { close, selectNewResourceOpen, t } = this.props;
    const { selectedAction, error } = this.state;
    const { ModalBanner } = this;
    const { SCRadio } = this;
    return (
      <Modal className='select-new-resource-modal' open={selectNewResourceOpen} size='tiny'>
        <ModalBanner
          label={t('modalLabel')}
          onClose={close} />
        <Modal.Content>
          <Form.Field className='select-new-resource-field'>
            <div className='description-text'>{t('descriptionText')}</div>
            <div className='description-sub-text'>{t('descriptionSubText')}</div>
            <SCRadio
              checked={selectedAction === 'createNew'}
              label={t('createNewOptionLabel')}
              name='exportGroup'
              onChange={this.handleChangeAction}
              value='createNew' />
            <SCRadio
              checked={selectedAction === 'addExisting'}
              label={t('addExistingOptionLabel')}
              name='exportGroup'
              onChange={this.handleChangeAction}
              value='addExisting' />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button basic content={t('cancelButtonLabel', 'Cancel')} onClick={() => { this.closeSelectNewResourceModal(); }} primary />
          <Button content={t('createButtonLabel', 'Create')} disabled={!this.state.selectedAction} onClick={() => { this.continueCreateLibraryResource(); }} primary />
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('SelectNewResourceModal', SelectNewResourceModal);
