import { isBoolean } from 'lodash';

import { getRegisteredClass, registerClass } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';
import appManager from '../managers/AppManager';
import assignmentManager from '../managers/AssignmentManager';
import courseManager from '../managers/CourseManager';
import contentManager from '../managers/ContentManager';
import dialogManager from '../managers/DialogManager';
import dynamicSatelliteManager from '../managers/DynamicSatelliteManager';
import gradebookManager from '../managers/GradebookManager';
import libraryManager from '../managers/LibraryManager';
import reportOverrideManager from '../managers/reports/ReportOverrideManager';
import studentContentCardManager from '../managers/StudentContentCardManager';
import topNavManager from '../managers/TopNavManager';
import userManager from '../managers/UserManager';

import {
  getCssUrlArgument, getStyleVar, isWhiteStyleColor, setSessionStorageItem
} from '../utils';

import { addSatellite as i18nAddSatellite } from '../i18n';

export default class DynamicSatelliteService {
  static fetchDynamicSatelliteByDomain = async () => {
    try {
      const InitService = getRegisteredClass('InitService');

      const dynamicSatelliteResponseData = await dynamicSatelliteManager.fetchDynamicSatelliteByDomain();

      const {
        reportPrefix, satelliteCode, satelliteCssUrl, satelliteJsonUrl, satelliteLayoutUrl, satelliteName
      } = dynamicSatelliteResponseData;

      // TODO - useful for local development if you use HTTPS localhost; change the above 'const' declaration to 'let' and
      // - set these values to the files you copy to react-sales-demo public folder for the dynamic satellite
      // - you want to use.
      // satelliteCssUrl = '/demo.css';
      // satelliteJsonUrl = '/demoTranslations.json';
      // satelliteLayoutUrl = '/demoSatelliteLayout.json';

      Auth.setPublisherSatelliteCode(satelliteCode);
      userManager.setClientPrefix(reportPrefix);
      setSessionStorageItem('dynamicPublisherSatelliteCode', satelliteCode);

      // TODO useful for local development if you have a lot of Sirius data
      // TODO ---
      // TODO remember to comment back out before committing any changes
      // Auth.setPublisherSatelliteCode('SIRIUS_SATELLITE');
      // userManager.setClientPrefix('SIRIUS');
      // setSessionStorageItem('dynamicPublisherSatelliteCode', 'SIRIUS_SATELLITE');

      // TODO remove
      // console.info('dynamicSatelliteResponseData', dynamicSatelliteResponseData);

      // TODO remove
      // console.info('satelliteCssUrl', satelliteCssUrl);

      document.title = satelliteName;
      appManager.setSatelliteName(satelliteName);

      const dynamicSatelliteLayoutConfig = await this.initDynamicSatelliteLayoutConfig({ satelliteLayoutUrl });

      await this.initDynamicSatelliteFlagOverrides(dynamicSatelliteLayoutConfig);

      await this.initDynamicSatelliteTranslations({ satelliteJsonUrl });

      this.initDynamicSatelliteStyleSheet({ satelliteCssUrl });

      setTimeout(() => {
        this.initDynamicFavIconIfApplicable();
        InitService.initLocale();
      }, 500);
    } catch (error) {
      console.error(error);
    }
  }

  static initDynamicSatelliteLayoutConfig = async ({ satelliteLayoutUrl } = {}) => {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });
    const dynamicLayoutResponse = satelliteLayoutUrl ? await fetch(satelliteLayoutUrl, {
      headers,
      method: 'GET',
      redirect: 'follow'
    }) : undefined;

    const dynamicSatelliteLayoutConfig = await dynamicLayoutResponse?.json?.();
    dynamicSatelliteManager.setDynamicSatelliteLayoutConfig(dynamicSatelliteLayoutConfig);

    return dynamicSatelliteLayoutConfig;
  }

  /**
   * Initialize dynamic satellite override flags that we would typically set in an `index.js` file
   * for a given non-dynamic satellite.
   *
   * Note this method is intended to be used for **dynamic satellites only**.
   *
   * The `dynamicSatelliteLayoutConfig.satelliteIndexFlagOverrides` object will be looked at
   * to determine which flag overrides should be set.
   */
  static initDynamicSatelliteFlagOverrides = async (dynamicSatelliteLayoutConfig) => {
    const flagOverrides = dynamicSatelliteLayoutConfig?.satelliteIndexFlagOverrides;
    if (!flagOverrides || typeof flagOverrides !== 'object') {
      return;
    }

    const {
      additionalAdminTopNavExternalResources,
      additionalStudentTopNavExternalResources,
      additionalTeacherTopNavExternalResources,
      allowDigitalItemBanking,
      allowGradebookStandards,
      allowPrintItemBanking,
      allowReportStandards,
      allowRibbonTOC,
      allowStudentBooks,
      allowTeacherTestBuilderView,
      alwaysExcludeStudentAssignmentCardAlignmentPills,
      includeAlignments,
      studentContentCardDateFormatStrategy
    } = flagOverrides;

    if (Array.isArray(additionalAdminTopNavExternalResources)) {
      topNavManager.setAdditionalTopNavExternalResources('admin', additionalAdminTopNavExternalResources);
    }
    if (Array.isArray(additionalStudentTopNavExternalResources)) {
      topNavManager.setAdditionalTopNavExternalResources('student', additionalStudentTopNavExternalResources);
    }
    if (Array.isArray(additionalTeacherTopNavExternalResources)) {
      topNavManager.setAdditionalTopNavExternalResources('teacher', additionalTeacherTopNavExternalResources);
    }
    if (isBoolean(allowDigitalItemBanking)) {
      libraryManager.setAllowDigitalItemBanking(allowDigitalItemBanking);
    }
    if (isBoolean(allowGradebookStandards)) {
      gradebookManager.setAllowGradebookStandards(allowGradebookStandards);
    }
    if (isBoolean(allowPrintItemBanking)) {
      libraryManager.setAllowPrintItemBanking(allowPrintItemBanking);
    }
    if (isBoolean(allowReportStandards)) {
      reportOverrideManager.setAllowReportStandards(allowReportStandards);
    }
    if (isBoolean(allowRibbonTOC)) {
      courseManager.setAllowRibbonTOC(allowRibbonTOC);
    }
    if (isBoolean(allowStudentBooks)) {
      courseManager.setAllowStudentBooks(allowStudentBooks);
    }
    if (isBoolean(allowTeacherTestBuilderView)) {
      userManager.setAllowTeacherTestBuilderView(allowTeacherTestBuilderView);
    }
    if (isBoolean(alwaysExcludeStudentAssignmentCardAlignmentPills)) {
      assignmentManager.setAlwaysExcludeStudentAssignmentCardAlignmentPills(
        alwaysExcludeStudentAssignmentCardAlignmentPills
      );
    }
    if (isBoolean(includeAlignments)) {
      assignmentManager.setIncludeAlignments(includeAlignments);
      courseManager.setIncludeAlignments(includeAlignments);
    }
    if ('studentContentCardDateFormatStrategy' in flagOverrides) {
      studentContentCardManager.setStudentContentCardDateFormatStrategy(studentContentCardDateFormatStrategy);
    }
  };

  static initDynamicSatelliteTranslations = async ({ satelliteJsonUrl } = {}) => {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });
    const dynamicSatelliteTranslationResponse = satelliteJsonUrl ? await fetch(satelliteJsonUrl, {
      headers,
      method: 'GET',
      redirect: 'follow'
    }) : undefined;

    const dynamicSatelliteTranslations = await dynamicSatelliteTranslationResponse?.json?.();

    // merge the current dynamic satellite translations with the default sat-core translations
    i18nAddSatellite('dynamic', dynamicSatelliteTranslations || {});
  }

  static initDynamicSatelliteStyleSheet = ({ satelliteCssUrl } = {}) => {
    const cssLink = document.createElement('link');
    cssLink.rel = 'stylesheet';
    cssLink.type = 'text/css';

    cssLink.href = satelliteCssUrl;

    document.querySelector('head').appendChild(cssLink);
  }

  static initPostLoginSettersForDynamicSatellite = () => {
    // init satellite fav icon if it is not already set
    this.initDynamicFavIconIfApplicable();

    // set top nav 'Help/Resources' url for Student role if applicable
    if (userManager.isStudent) {
      const cssUrlValue = getStyleVar('--top-nav-dynamic-resource-url-student');
      const topNavResourceUrl = getCssUrlArgument(cssUrlValue);
      contentManager.setResourceIFrameUrlStudent(topNavResourceUrl);
    }

    // set top nav 'Help/Resources' url for non-Student role if applicable
    if (!userManager.isStudent) {
      const cssUrlValue = getStyleVar('--top-nav-dynamic-resource-url');
      const topNavResourceUrl = getCssUrlArgument(cssUrlValue);
      contentManager.setResourceIFrameUrl(topNavResourceUrl);
    }

    // set useWhiteInfoIcon to true/false based on `fontColorDirectlyOnBackground`
    const fontColorOnBackground = userManager.isStudent ? (
      getStyleVar('--theme-font-color-directly-on-background-student')
    ) : (
      getStyleVar('--theme-font-color-directly-on-background')
    );
    const shouldUseWhiteInfoIcon = isWhiteStyleColor(fontColorOnBackground);
    dialogManager.setUseWhiteInfoIcon(shouldUseWhiteInfoIcon);
  }

  /**
   * Manually inject fav icon for a given satellite if it is not already in the DOM.
   *
   * Intended for dynamic satellites where the fav icon url is not hardcoded in `index.html`.
   *
   * If fav icon url is declared in `index.html` and never changes, no need to use this method. */
  static initDynamicFavIconIfApplicable = () => {
    try {
      let favIconLink = document.querySelector("link[rel~='icon']");

      const cssUrlValue = getStyleVar('--fav-icon');
      const favIconUrl = getCssUrlArgument(cssUrlValue);

      if (favIconLink?.href?.includes?.(favIconUrl)) {
        // we have already initialized the fav icon, so no need to run additional logic
        return;
      }

      favIconLink = document.createElement('link');
      favIconLink.rel = 'icon';
      document.head.appendChild(favIconLink);

      favIconLink.href = favIconUrl;
    } catch (error) {
      console.error(error);
    }
  }
}

registerClass('DynamicSatelliteService', DynamicSatelliteService);
