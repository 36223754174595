import userManager from '../managers/UserManager';

import { getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem } from '../utils/session';

import { registerClass } from '../SatCoreRegistry';

import productManager from '../managers/ProductManager';

export default class CourseNavigationService {
  static getCurrentCourseLocation = async (classroomId, courseId = null) => {
    if (!productManager.isFromProduct) {
      const lastCourseLocationSessionStorageKey = this.getLastCourseLocationSessionStorageKey(classroomId, courseId);
      return JSON.parse(getSessionStorageItem(lastCourseLocationSessionStorageKey) || null);
    }
  }

  static saveCurrentCourseLocation = async (classroomId, courseId, courseElementId = '') => {
    if (userManager.isTeacher && !productManager.isFromProduct) {
      const lastLocationData = {
        classroomId,
        courseElementId,
        courseId
      };
      const lastCourseLocationSessionStorageKey = this.getLastCourseLocationSessionStorageKey(classroomId, courseId);
      setSessionStorageItem(lastCourseLocationSessionStorageKey, JSON.stringify(lastLocationData));
    }
  }

  static removeClassroomCourseLocation = async (classroomId, courseId) => {
    if (userManager.isTeacher && !productManager.isFromProduct) {
      const lastCourseLocationSessionStorageKey = this.getLastCourseLocationSessionStorageKey(classroomId, courseId);

      const lastLocationDataStr = getSessionStorageItem(lastCourseLocationSessionStorageKey);
      if (lastLocationDataStr) {
        const lastLocationDataJson = JSON.parse(lastLocationDataStr);
        if (lastLocationDataJson.courseId === courseId) {
          removeSessionStorageItem(lastCourseLocationSessionStorageKey);
        }
      }
    }
  }

  static getLastCourseLocationSessionStorageKey = (classroomId, _courseId) => {
    if (classroomId !== 'FROM_TEACHER_PRODUCTS_NAV' && !productManager.isFromProduct) {
      return `c2c_lastCourseLocation_${classroomId}`;
    }
    // TODO remove
    // if (classroomId === 'FROM_TEACHER_PRODUCTS_NAV') {
    //   return `c2c_lastCourseLocation_${classroomId}_${courseId}`;
    // } else {
    //   return `c2c_lastCourseLocation_${classroomId}`;
    // }
  }
}

registerClass('CourseNavigationService', CourseNavigationService);
