import React from 'react';
import { Login, SatCoreRegister, contentManager } from 'sat-core';

import loginBottomLogo from '../img/si-login-bottom-logo.png';

const RegistrationMessage = ({ t }) => {
  return (
    <div className='signin-message'>
      <a
        href={contentManager.resourceIFrameUrl || ''}
        rel='noopener noreferrer'
        target='_blank'>
        {t('helpAndQuestions')}
      </a>
    </div>
  );
};

const LoginOverride = (props) => {
  return (
    <Login {...props}
      loginBottomLogo={loginBottomLogo}
      registrationMessage={<RegistrationMessage {...props} />} />
  );
};
SatCoreRegister('Login', LoginOverride);
