import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Dropdown } from 'semantic-ui-react';

import '../css/ActivityModeDropdown.less';

import { SatCoreRegister } from '../SatCoreRegistry';

const ActivityModeDropdown = observer((props = {}) => {
  const {
    activityMode,
    courseResourceElementId,
    disabled,
    setActivityMode
  } = props;

  const [modes, setThemes] = useState(props.modes);

  const [activityModesLoading, setActivityModesLoading] = useState(false);

  const { assignmentManager } = useContext(MobXProviderContext);

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  useEffect(() => {
    if (!modes || modes.length < 1) {
      (async () => {
        setActivityModesLoading(true);
        const modes = await assignmentManager.getAssignmentModes(courseResourceElementId);
        setActivityMode(activityMode);
        setThemes(modes);
        setActivityModesLoading(false);
      })();
    }
  }, []);

  const handleChangeActivityMode = async (_event, data) => {
    const modeId = data.value;
    setActivityMode(modeId);
  };

  const renderActivityModeDropdown = () => {
    return (
      <div className='activity-mode-dropdown'>
        <Dropdown
          disabled={disabled || activityModesLoading}
          onChange={handleChangeActivityMode}
          options={modes.map((mode) => {
            return {
              text: mode.displayName,
              value: mode.id
            };
          })}
          selection
          value={activityMode} />
      </div>
    );
  };
  return modes ? renderActivityModeDropdown() : '';
});
export default ActivityModeDropdown;

SatCoreRegister('ActivityModeDropdown', ActivityModeDropdown);
