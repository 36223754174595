import BaseApi from './BaseApi';

export const PURCHASE_CODE_ENDPOINTS = {
  BURN_CODE: '/api/burnPurchaseCode',
  BURN_CODES: '/api/burnPurchaseCodes',
  VALIDATE: '/api/validatePurchaseCode',
  VALIDATE_CODES: '/api/validatePurchaseCodes'
};

export class PurchaseCodeApi extends BaseApi {
  async validate(code = null) {
    const url = this.getUrl(PURCHASE_CODE_ENDPOINTS.VALIDATE);
    const response = await this.post(url, { body: { code } });
    return response?.purchaseCodeValid;
  }

  // TODO: Redo to 1 request after backend start support multiple codes validation result
  // Currently it return only true/false based on all codes
  // validateCodes({'COURSE_ID': 'CODE'})
  async validateCodes(codes = null) {
    // const url = this.getUrl(PURCHASE_CODE_ENDPOINTS.VALIDATE_CODES);
    // const response = await this.post(url, {body: {codes}});
    // return response && response.codes;
    return await Promise.all(Object.entries(codes).map(([id, value]) => this.validate(value).then((result) => [id, value, result])));
  }

  // burnCodes('classroomId', ['code1', 'code2'])
  async burnCodes(classroomId = null, codes = null) {
    const url = this.getUrl(PURCHASE_CODE_ENDPOINTS.BURN_CODES);
    const response = await this.post(url, { body: { classroomId, codes: (codes || []).join(',') } });
    return !!response;
  }

  async burnCode(classroomId, code = null) {
    const url = this.getUrl(PURCHASE_CODE_ENDPOINTS.BURN_CODE);
    const response = await this.post(url, { body: { classroomId, code } });
    return !!response;
  }
}

export default new PurchaseCodeApi();
