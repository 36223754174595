import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import { toFixed } from '../../../utils';

import { REPORT_POPUP_OFFSET } from '../../../services/reports/ReportConstants';

import PopupService from '../../../services/PopupService';
import ReportTableService from '../../../services/reports/ReportTableService';
import UsageService from '../../../services/UsageService';
import ReportEngagementService from '../../../services/reports/ReportEngagementService';

const ReportFacultyResultCell = observer((props) => {
  const { row, t } = props;

  const isRowExemptFromHavingValue = ReportTableService.isRowExemptFromHavingValue(row);
  if (isRowExemptFromHavingValue || !row?.original) {
    return null;
  }

  const {
    reportContextManager,
    reportIdentityManager
  } = useContext(MobXProviderContext);

  const {
    isContextPerformanceReport,
    isContextUsageReport,
    isContextProgressReport,
    isContextEngagementReport,
  } = reportContextManager;

  const {
    activeReportFacultyType,
    activeReportScorePropName,
    activeReportTableType,
    isCourseReport,
    isFacultyClassroomReport,
    reportInfoClassNames
  } = reportIdentityManager;

  const isExpandableRow = ReportTableService.isExpandableRow(props);

  let value, modified;
  if (typeof props.value === 'number') {
    value = props.value;
  } else if (props.value && typeof props.value[activeReportScorePropName] === 'number') {
    value = props.value[activeReportScorePropName];
    modified = isCourseReport && isFacultyClassroomReport && !!props.value.excludeCount;
  } else if (props.value && activeReportScorePropName === 'engagement') {
    value = props.value;
  }

  if (isContextPerformanceReport) {
    const scoreStr = typeof value === 'number' ? `${Math.round(value)}%` : '—';

    let popupClassNames = `cell-faculty-result-popup ${reportInfoClassNames}`;
    popupClassNames += modified ? ' modified' : '';

    let pointsOverPoints;
    const isCellFacultyResult = props?.column?.className?.includes('cell-faculty-result');

    const shouldShowPointsOverPoints = (value || typeof value === 'number')
      && !isExpandableRow && isCellFacultyResult && isCourseReport && isFacultyClassroomReport;
    if (shouldShowPointsOverPoints) {
      const points = typeof props?.value?.points === 'number' ? toFixed(props.value.points) : 0;
      const maxPoints = typeof props?.value?.maxPoints === 'number' ? toFixed(props.value.maxPoints) : 0;
      pointsOverPoints = (
        <>
          <strong>{t('points')}&nbsp;</strong>
          <>{`${points}/${maxPoints}`}</>
        </>
      );
    }

    let innerContent;
    const shouldShowPopup = pointsOverPoints || modified;
    if (shouldShowPopup) {
      if (pointsOverPoints) {
        innerContent = (
          <>
            {pointsOverPoints}
            {modified ? <p>{t('modified')}</p> : null}
          </>
        );
      } else if (!pointsOverPoints && modified) {
        innerContent = t('modified');
      }
    } else {
      innerContent = scoreStr === '—' ? t('dashText') : scoreStr;
    }
    const content = (
      <div className='cell-faculty-result-content'>
        {innerContent}
      </div>
    );
    const trigger = scoreStr;

    const popupOffset = REPORT_POPUP_OFFSET[
      `MODIFIED_${activeReportFacultyType.toUpperCase()}_${activeReportTableType.toUpperCase()}_CELL`];

    return PopupService.renderPopup({
      className: popupClassNames,
      content,
      disabled: !shouldShowPopup,
      offset: modified ? popupOffset : undefined,
      trigger
    });
  } else if (isContextUsageReport) {
    return UsageService.getUsageLabel({ viewedTimeInSeconds: value });
  } else if (isContextProgressReport) {
    return value || '—';
  } else if (isContextEngagementReport) {
    const accessor = value;
    return ReportEngagementService.renderReportEngagementLabel(accessor, {
      isExpandableRow
    }) || '—';
  }
});
export default ReportFacultyResultCell;

SatCoreRegister('ReportFacultyResultCell', ReportFacultyResultCell);
