import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button } from 'semantic-ui-react';

import '../css/CoTeacherAccessCode.less';

import { SatCoreRegister } from '../SatCoreRegistry';

export default
@inject('classroomManager')
@observer
class CoTeacherAccessCode extends Component {
  handleClickRefreshCode = (e) => {
    const { classroomManager, classId } = this.props;
    e.preventDefault();
    classroomManager.refreshCoTeacherAccessCode(classId);
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onClick?.();
  }

  render() {
    const { classId, classroomManager, t } = this.props;
    let { code } = this.props;
    if (!code && classId && classId !== '') {
      code = classroomManager.getCoTeacherAccessCode(classId);
    }
    return (
      <div className='coTeacherAccessCode'>
        <div className='accessCodeHeader'>
          {t('coTeacherAccessCode', 'Co-Teacher Access Code')}
        </div>
        <div className='accessCode'>
          <div className='accessCodeLabel'>{code}</div>
          <div className='accessCodeRefresh'>
            <Button basic onClick={this.handleClickRefreshCode}
              size='mini' type='button'>
              Refresh
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

SatCoreRegister('CoTeacherAccessCode', CoTeacherAccessCode);
