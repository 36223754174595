/* eslint-disable sort-keys */
import { getRegisteredClass, registerClass } from '../SatCoreRegistry';

import { ASSIGNMENT_TYPE } from '../managers/AssignmentManager';

import classroomManager from '../managers/ClassroomManager';
import ClassroomService from './ClassroomService';

export default class RosterService {
  static hasRoster = ({ assignment } = {}) => {
    return !!this.getFullAssignmentRoster({ assignment });
  }

  static getFullAssignmentRoster = ({
    assignment, classroomId
  } = {}) => {
    return classroomManager.rosterMap.get(
      assignment?.classroomId || classroomId || ClassroomService.getCurrentClassroomId()
    );
  }

  static getSelectedUsersRoster = ({
    assignment,
    assignmentReady = true,
    classroomId
  } = {}) => {
    const selectedUserIds = assignment?.assignEntityId?.split(',') || [];

    const ClassroomService = getRegisteredClass('ClassroomService');
    classroomId = classroomId || assignment.classroomId || ClassroomService.getCurrentClassroomId();

    const roster = classroomManager.rosterMap.get(classroomId);

    if (!assignmentReady || !assignment || !selectedUserIds?.length || !roster?.length) {
      return [];
    } else if (assignment?.assignEntityTypeId?.includes(ASSIGNMENT_TYPE.CLASSROOM)) {
      return [...roster];
    } else {
      return roster.filter((student) => {
        return selectedUserIds.includes(student.userId);
      });
    }
  }
}

registerClass('RosterService', RosterService);
