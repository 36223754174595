import { action, makeObservable, observable } from 'mobx';

/**
 * **ReportOverrideManager** consists of Report observables with default values and respective action setters.
 *
 * The intention is to allow a given satellite to use ReportOverrideManager setters
 * to override ReportOverrideManager observables via the satellite's `index.js` file.
 */
export class ReportOverrideManager {
  constructor() {
    makeObservable(this);
  }

  @observable allowReportStandards = true;

  @observable allowCourseReportExpandableRowData = true;

  @observable allowLastModifiedAdditionalReportInfo = false;

  @observable allowLessonFilterDropdown = false;

  @observable allowReportTableRowResourceExport = false;

  @observable allowUseCmapId = false;

  @observable allowUseDefaultImageSource = false;

  @observable allowProgressReportContext = false;

  @observable reportTypeSelectorViewIconOverride = {
    courseIcon: null,
    standardsIcon: null
  };

  @observable shouldAppendCourseElementContentTypeAndElementDescriptionToReportTableRowTitle = false;

  @observable shouldShowStandardsReportSummaryBar = false;

  @observable enableEnhancedUsageReports = false;

  @action clearAll = () => {
    /* placeholder */
  }

  @action setAllowReportStandards = (allowReportStandards) => {
    this.allowReportStandards = allowReportStandards;
  }

  @action setAllowCourseReportExpandableRowData = (toggle) => {
    this.allowCourseReportExpandableRowData = toggle;
  }

  @action setAllowLastModifiedAdditionalReportInfo = (toggle) => {
    this.allowLastModifiedAdditionalReportInfo = toggle;
  }

  @action setAllowLessonFilterDropdown = (toggle) => {
    this.allowLessonFilterDropdown = toggle;
  }

  @action setAllowReportTableRowResourceExport = (toggle) => {
    this.allowReportTableRowResourceExport = toggle;
  }

  @action setAllowUseCmapId = (toggle) => {
    this.allowUseCmapId = toggle;
  }

  @action setAllowUseDefaultImageSource = (toggle) => {
    this.allowUseDefaultImageSource = toggle;
  }

  @action setAllowProgressReportContext = (toggle) => {
    this.allowProgressReportContext = toggle;
  }

  @action setEnableEnhancedUsageReports = (enableEnhancedUsageReports) => {
    this.enableEnhancedUsageReports = enableEnhancedUsageReports;
  }

  @action setReportTypeSelectorViewIconOverride = ({ courseIcon = null, standardsIcon = null } = {}) => {
    this.reportTypeSelectorViewIconOverride = { courseIcon, standardsIcon };
  }

  @action setShouldAppendCourseElementContentTypeAndElementDescriptionToReportTableRowTitle = (toggle) => {
    this.shouldAppendCourseElementContentTypeAndElementDescriptionToReportTableRowTitle = toggle;
  }

  @action setShouldShowStandardsReportSummaryBar = (toggle) => {
    this.shouldShowStandardsReportSummaryBar = toggle;
  }
}

export default new ReportOverrideManager();
