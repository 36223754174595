import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Button, Container, Form, Message } from 'semantic-ui-react';

import Modal from '../Modal';

import '../../css/ExportGradesModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import { VIEW_SELECTION } from '../../managers/NavigationManager';

export default
@inject('exportManager', 'gradebookManager', 'reportIdentityManager', 'userManager')
class ExportReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.SCRadio = SatCoreComponent('SCRadio');
  }

  getInitialState = () => {
    const { reportId, userManager } = this.props;
    // Usernames may or may not be an email. If it is, we want to auto populate it.
    const { username } = userManager;
    let email = '';
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username)) {
      email = username;
    }
    return {
      email,
      error: null,
      exportReportId: reportId,
      valid: email !== ''
    };
  }

  requestExport = async () => {
    const {
      baseReportId,
      classroomId,
      closeExportReportModal,
      courseContentItemId,
      curriculumMapId,
      districtId,
      entityId,
      exportManager,
      reportId,
      schoolId
    } = this.props;
    let { institutionId } = this.props;
    const { email, valid, exportReportId } = this.state;

    if (valid && reportId) {
      const urlParams = new URLSearchParams(window.location.search);
      const view = urlParams.get('view');

      if (view !== VIEW_SELECTION.REPORTS && !institutionId) {
        institutionId = urlParams.get('institutionId');
      }
      await exportManager.requestExport({
        baseReportId,
        classroomId,
        courseContentItemId,
        curriculumMapId,
        districtId,
        emailAddress: email,
        entityId,
        institutionId,
        reportId: exportReportId,
        schoolId
      });
      closeExportReportModal();
    } else {
      this.setState({ error: 'Please enter a valid email.' });
    }
  }

  handleEmailChange = (e) => {
    this.validate(e.target.value);
  }

  validate = (email) => {
    const valid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    this.setState({ email, valid });
  }

  clearError = () => {
    this.setState({ error: null });
  }

  handleChangeExportReportId = async (event) => {
    const exportReportId = event.target.value;
    this.setState({ exportReportId });
  }

  renderRadioOption = (radioOptionToRender) => {
    if (!radioOptionToRender) {
      return null;
    }
    const { SCRadio } = this;

    const { t, userManager } = this.props;
    const { exportReportId } = this.state;

    const { clientPrefix } = userManager;

    const radioOptionKey = radioOptionToRender.replace(`${clientPrefix}_`, '');

    return (
      <SCRadio
        additionalClassNames={radioOptionToRender}
        checked={radioOptionToRender === exportReportId}
        label={t(radioOptionKey)}
        name='exportGroup'
        onChange={this.handleChangeExportReportId}
        value={radioOptionToRender} />
    );
  }

  render() {
    const {
      closeExportReportModal, exportManager, exportReportOpen,
      reportId, reportIdentityManager, t
    } = this.props;
    const { email, error, valid } = this.state;
    const { ModalBanner } = this;

    const {
      isCourseReport,
      isFacultyClassroomReport,
      isFacultySchoolReport,
      isStandardsReport
    } = reportIdentityManager;

    const {
      allowExportFullCourseReport,
      allowExportFullCourseSchoolDetailByStudentReport,
      allowExportMiniCourseReportScoresOnly,
      allowExportMiniCourseReportPointsOnly,
      allowExportFullStandardsReport,
      allowExportFullStandardsSchoolDetailByStudentReport,
      allowExportMiniStandardsReportScoresOnly
    } = exportManager;

    const {
      // School Detail (By Student)
      COURSE_SCH_ST_PERFORM_X,
      STANDARDS_SCH_ST_PERF_X,
      // Classroom Report (Scores Only)
      COURSE_CLASS_PERFORM_S_X,
      STANDARDS_CLASS_PERFORM_S_X,
      // Classroom Report (Points Only)
      COURSE_CLASS_PERFORM_P_X
    } = reportIdentityManager.SPECIAL_FLAT_REPORT_EXPORT_IDS;

    const isSpecialExportCourseSchoolDetailReportCase = (
      isCourseReport && isFacultySchoolReport && allowExportFullCourseSchoolDetailByStudentReport
    );
    const isSpecialExportStandardsSchoolDetailReportCase = (
      isStandardsReport && isFacultySchoolReport && allowExportFullStandardsSchoolDetailByStudentReport
    );

    const radioOptionsJsx = (
      <>
        {/* FULL COURSE EXPORT: Course Report (Full report) */}
        {isCourseReport && allowExportFullCourseReport && this.renderRadioOption(reportId)}
        {/* FULL STANDARDS EXPORT: Standards Report (Full report) */}
        {isStandardsReport && allowExportFullStandardsReport && this.renderRadioOption(reportId)}

        {/* SPECIAL COURSE SCHOOL EXPORT: School Detail (By Student) */}
        {isSpecialExportCourseSchoolDetailReportCase && this.renderRadioOption(COURSE_SCH_ST_PERFORM_X)}
        {/* SPECIAL STANDARDS SCHOOL EXPORT: School Detail (By Student) */}
        {isSpecialExportStandardsSchoolDetailReportCase && this.renderRadioOption(STANDARDS_SCH_ST_PERF_X)}
        {/* SPECIAL CLASSROOM EXPORTS */}
        {isFacultyClassroomReport && (
          <>
            {/* SPECIAL COURSE CLASSROOM EXPORT: Course Report (Scores only) */}
            {isCourseReport && allowExportMiniCourseReportScoresOnly && this.renderRadioOption(COURSE_CLASS_PERFORM_S_X)}
            {/* SPECIAL COURSE CLASSROOM EXPORT: Course Report (Points only) */}
            {isCourseReport && allowExportMiniCourseReportPointsOnly && this.renderRadioOption(COURSE_CLASS_PERFORM_P_X)}
            {/* SPECIAL STANDARDS CLASSROOM EXPORT: Standards Report (Scores only) */}
            {isStandardsReport && allowExportMiniStandardsReportScoresOnly && this.renderRadioOption(STANDARDS_CLASS_PERFORM_S_X)}
          </>
        )}
      </>
    );

    return (
      <Modal className='export-grades-modal export-report-modal' open={exportReportOpen} size='tiny'>
        <ModalBanner
          label='Export CSV'
          onClose={() => { closeExportReportModal(); }} />
        <Modal.Content>
          <Container>
            {radioOptionsJsx}
          </Container>

          <div className='export-generation-explanation-msg'>
            {t('exportGenerationExplanationMsg')}
          </div>

          <Form.Field className='email-export-field'>
            <Form.Input
              label='Email: '
              name='email'
              onChange={this.handleEmailChange}
              onFocus={this.clearError}
              placeholder='name@email.com'
              type='email'
              value={email} />
            {error ? (
              <Message
                content={error}
                error
                visible={error !== null} />
            ) : null}
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button basic content='Cancel' onClick={() => { closeExportReportModal(); }} primary />
          <Button className={valid ? '' : 'display-disabled'} content='Send' onClick={() => { this.requestExport(); }} primary />
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('ExportReportModal', ExportReportModal);
