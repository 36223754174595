/* eslint-disable max-len */
const translations = {
  AccommodationsTable: {
    accommodationsZeroState: 'No students found.',
    assessmentSection: 'Assessment Accommodations',
    assessmentSectionDescription: 'Available in all tests.',
    basicCalculatorToggle: 'Basic Calculator',
    contentLanguageToggle: 'Content & Language',
    dictionaryToggle: 'Dictionary',
    firstName: 'First Name',
    graphingCalculatorToggle: 'Graphing Calculator',
    lastName: 'Last Name',
    lessonSection: 'Practice Accommodations',
    lessonSectionDescription: 'Available only in practice.',
    scientificCalculatorToggle: 'Scientific Calculator',
    selectAllStudents: 'Select All Students',
    spellcheckerToggle: 'Spelling Assistance',
    textToSpeechToggle: 'Text To Speech',
    translationToggle: 'Translation'
  },
  AccommodationsTogglerSection: {
    accommodations: 'Accommodations',
    assessmentSection: 'Assessment Accommodations',
    assessmentSectionDescription: 'Available in all tests.',
    basicCalculatorToggle: 'Basic Calculator',
    contentLanguageToggle: 'Content & Language',
    dictionaryToggle: 'Dictionary',
    graphingCalculatorToggle: 'Graphing Calculator',
    lessonSection: 'Practice Accommodations',
    lessonSectionDescription: 'Available only in practice.',
    scientificCalculatorToggle: 'Scientific Calculator',
    selectAllStudents: 'Select All Students',
    spellcheckerToggle: 'Spelling Assistance',
    textToSpeechToggle: 'Text To Speech',
    translationToggle: 'Translation'
  },
  AccommodationsView: {
    accommodationsFor: 'Accommodations for'
  },
  AddAssignmentModal: {
    additionalSettings: 'Additional Settings',
    allClassesInfoText: 'Entire class assignments are made across all classes.',
    allClassesLabel: 'All Classes',
    allowBackSpaceLabel: 'Allow back space',
    assign: 'Assign',
    assignAll: 'Assign All',
    assignTo: 'Assign To',
    cancel: 'Cancel',
    classExpiredSubtext: 'Please go to the class settings page to extend the class end time.',
    classExpiredText: 'Your class has expired and you cannot assign any more tasks.',
    classNotStartedSubtext: 'Please go to the class settings page to change the class start time.',
    classNotStartedText: 'Your class has not started you cannot assign items until the class starts.',
    confirmCloseAddAssignmentModalBodyMsg: 'This assignment will take a few minutes to generate. Do you want to assign to other students, groups, or classes?',
    confirmCloseAddAssignmentModalHeaderMsg: 'Success!',
    cutScoreInfoText: 'Students are categorized by their average score.',
    cutScoreLabel: 'Student Average Score',
    dateRange: 'Date Range',
    dateRangeDescription: 'Selected dates must be within timeframe above',
    dueDate: 'Due Date',
    dueTime: 'Due Time',
    endDate: 'End Date',
    endTime: 'End Time',
    entireClassIndividualInfoText: 'Entire Class: Individual assignments are made for every individual in the class and can be managed per student or as a whole class.',
    entireClassIndividualLabel: 'Entire Class',
    entireClassIndividualSubtext: 'Individual Assignments',
    entireClassInfoText: 'Entire Class assignments are for the whole class, changes cannot be made for individuals.',
    entireClassLabel: 'Entire Class',
    entireClassSubtext: 'One Assignment for All',
    error: 'Error',
    groupInfoText: 'Create assignments for students in select groups.',
    ignoreItemOptionShuffle: 'Shuffle answer choices',
    ignoreItemOptionShuffleMessage: 'Shuffling may not apply to all question types',
    individualInfoText: 'Create assignments for select individuals.',
    lateSubmission: 'Allow late submissions',
    lateSubmissionDescription: 'Late submission is only available for students who have not yet submitted.',
    lateSubmissionText: 'Late submission is only available for students who have not yet submitted.',
    lateSubmitDateLabel: 'Allow late submissions until',
    modeOption: 'Mode Option',
    modeOptionDescription: 'Set the mode to use for this assignment.',
    nicknameLabel: 'Nickname',
    nicknamePlaceholder: 'Add nickname (optional)...',
    noAssignableMessage: 'There are no assignable resources available in this section.',
    oneOrMoreResourcesMustBeSelected: 'One or more resources must be assigned.',
    randomizeQuestions: 'Shuffle questions',
    releaseToReportsLabel: 'Release to reports',
    reportsAndGrading: 'Reports & Grading',
    reportsAndGradingDescription: 'Toggles determine actions that happen after assignments are complete.',
    scoresReleased: 'Release grades to students',
    standardsApproachingLabel: 'Approaching',
    standardsDevelopingLabel: 'Developing',
    standardsMasteringLabel: 'Mastering',
    standardsMeetingLabel: 'Meeting',
    standardsNotEnoughDataLabel: 'Not Enough Data',
    startDate: 'Start Date',
    startTime: 'Start Time',
    studentInstructions: 'Student Instructions (click to edit)',
    studentReview: 'Students can review work',
    submitAddAssignmentError: 'An error occurred while trying to create assignment(s). Please ensure you have students enrolled in the selected class and try again. If the problem still persists, please contact an administrator.',
    timeFrame: 'Timeframe',
    timeFrameDescription: 'Timeframe is set by content creator and content can only be used within timeframe.',
    typingDurationLabel: 'Duration (s)',
    typingMinimumAccuracyLabel: 'Minimum Accuracy',
    typingMinimumSpeedLabel: 'Minimum Speed',
    typingTextLabel: 'Typing Text'
  },
  AddClassModal: {
    addClassTitle: 'Add Class',
    cancel: 'Cancel',
    classroomName: 'Class Name',
    defaultServerErrorMsg: 'Something went wrong when saving the class, please try again',
    endDate: 'End Date',
    save: 'Save',
    selectInstitution: 'Select Institution',
    startDate: 'Start Date'
  },
  AddCourseToClassModal: {
    addNewCustomCourse: '+ Create New Custom Course',
    cancel: 'Cancel',
    confirmDelete: 'Are you sure you want to delete this Item Bank? This will be permanently removed from your Item Bank list and cannot be undone.',
    confirmDeleteTitle: 'Warning!',
    courseZeroStateForSelectedProductMsg: 'There are no courses found matching the search criteria for the selected product.',
    courseZeroStateMsg: 'There are no courses assigned to this class.',
    customCourseZeroStateMsg: 'No content found. New custom courses may take a moment to appear.',
    customCoursesTabName: 'My Custom Courses',
    hasClasses: 'You cannot delete this Item Bank because it has attached classes:  ',
    hasClassesTitle: 'Warning!',
    modalAddNewCustomCourse: 'Create New Custom Course',
    noNewSelectedCourses: 'No new selected courses yet.',
    noResultsZeroStateMsg: 'No results found.',
    publisherCoursesTabName: 'Publisher Courses',
    save: 'Save',
    searchContentName: 'Search content name',
    selectCourseCardTopLabel: '',
    selectCourses: 'Select Courses',
    selectedCountLabel: 'Selected',
    selectedCourses: 'Selected Courses'
  },
  AddCustomAssessmentModal: {
    addNewCustomAssessment: '+ Create New Custom Assessment',
    confirmDelete: 'Are you sure you want to delete this Item Bank? This will be permanently removed from your Item Bank list and cannot be undone.',
    confirmDeleteTitle: 'Warning!',
    customAssessmentZeroStateMsg: 'There are no assessments added yet.',
    hasClasses: 'You cannot delete this Item Bank because it is attached to classes:  ',
    hasClassesTitle: 'Warning!',
    modalAddNewCustomAssessment: 'Create New Custom Assessment',
    noResultsZeroStateMsg: 'No results found.'
  },
  AddGroupModal: {
    addButtonText: 'Add Group',
    addGroupSubTitle: 'Create a group of students for projects and assignments. Students can be added to multiple groups.',
    addGroupTitle: 'Add Group',
    archiveGroupButtonText: 'Archive Group',
    cancelButtonText: 'Cancel',
    editButtonText: 'Save',
    editGroupTitle: 'Edit Group',
    groupDescriptionLabel: 'Group Description',
    groupDescriptionSubLabel: '500 character limit, only visible to teachers',
    groupNameErrorMsg: 'Group name already exists.  Create a new group name.',
    groupNameLabel: 'Group Name*',
    groupNamePlaceholder: 'Add a group name',
    groupNameSubLabel: '30 character limit',
    selectStudentsLabel: 'Select Students:*',
    submitAddGroupError: 'There was a problem creating the group.'
  },
  AddJoinClassModal: {
    addJoinClass: '+ Add / Join Class',
    addNewClass: '+ Add New Class',
    cancel: 'Cancel',
    joinClass: '+ Join Class',
    save: 'Save'
  },
  AddLibraryCourseToClassroomsModal: {
    addLibraryCourseToClassroomsModalBodyHeaderLabel: 'Select from below the classes to add [{{courseName}}] to',
    addLibraryCourseToClassroomsModalHeaderLabel: 'Add to Class',
    currentlySelectedCourse: 'Currently Selected Course'
  },
  AddStudentsToClassroomModal: {
    addExistingStudents: 'Add Existing Students'
  },
  AdditionalResourcePills: {
    autoScored: 'Auto-Scored',
    interactive: 'Interactive',
    manualScored: 'Manual Scoring',
    pdfPlus: 'PDF+'
  },
  AdminAddTeacher: {
    'addTeacherHeaderText': 'Add New Teacher',
    'cancelButton': 'Cancel',
    'confirmPassword': 'Confirm Teacher Password',
    'enterPassword': 'Enter Teacher Password',
    'firstName': 'First Name',
    'lastName': 'Last Name',
    'save&AddButton': 'Save & Add Another',
    'saveButton': 'Save & Exit',
    'username': 'Username/email'
  },
  AdminClassroomsView: {
    accommodations: 'Accommodations',
    allCourses: 'All Courses',
    allProducts: 'All Products',
    classesLabel: 'Classes',
    classroomEndDate: 'End Date',
    classroomNickname: 'Display Name',
    classroomStartDate: 'Start Date',
    courseDropdownLabel: 'Course:',
    leadTeacherFirstName: 'Teacher First Name',
    leadTeacherLastName: 'Teacher Last Name',
    loadingCourseDropdownOptions: 'Loading...',
    loadingProductDropdownOptions: 'Loading...',
    noDataFound: 'No data found.',
    productDropdownLabel: 'Product:',
    reports: 'Reports',
    roster: 'Roster',
    schoolsLabel: 'Schools',
    settings: 'Settings'
  },
  AdminContentView: {
    addToClassLabel: 'Add to Class',
    assessmentBankLabel: 'Collection',
    confirmCopyPrefix: 'Completing this action will add a copy of',
    confirmCopySuffix: 'to My Drafts.',
    confirmCopyTitle: 'Copy',
    confirmDeletePrefix: 'Completing this action will delete',
    confirmDeleteSuffix: 'from all areas. This cannot be undone. Would you like to complete this action?',
    confirmDeleteTitle: 'Delete',
    copyOptionLabel: 'Copy',
    copyOptionToDraftsLabel: 'Copy to My Drafts',
    course: 'Course',
    coursesButtonLabel: 'Courses',
    coursesDescription: 'These are the courses that your institution has access to.',
    coursesResourcesDescription: 'These are the courses that your institution has access to.',
    coursesResourcesOnlyDescription: 'These are the courses that your institution has access to.',
    deleteOptionLabel: 'Delete',
    detailsDialogHeaderLabel: 'Info',
    detailsOptionLabel: 'Info',
    downloadOnlyModalMsg: 'This file is marked \'Uploaded File\' because it can only be downloaded. Below are a list of file types that can be embedded in lessons. Any other file type, like this one, will create a download link in your content. Once your audience downloads the file, they can open it on their computer. Video: MP4, WEBM Audio: MP3, WAV Images: GIF, JPEG, JPG, PNG Documents: DOCX, DOC, PDF, PPS, PPSX, PPT, PPTX, RTF ,TXT',
    downloadOnlyTitle: 'Download Only',
    editOptionLabel: 'Edit',
    editUnavailableCancelButton: 'Cancel',
    editUnavailableMsg: 'Editing this is not allowed because the assessment is used in a live collection.  Create a copy of this assessment to make changes.',
    editUnavailableTitle: 'Edit Unavailable',
    hasClasses: 'You cannot delete this resource because it has attached classes:  ',
    hasClassesTitle: 'Warning!',
    itemBankDescription: 'Item banks contain test items that can be used to create your own assessments. You may view items here. To start building assessments, select Test Builder in the top bar.',
    itemBankLabel: 'Item Banks',
    libraryTitle: 'Test Builder',
    licensedCourseLabel: 'Course',
    moveToBankOptionLabel: 'Move to Collection',
    noData: 'No results to display for the current selections',
    noPreviewAvailableMsg: 'This resource cannot be previewed.',
    noPreviewAvailableTitle: 'No Preview Available',
    openOptionLabel: 'Open',
    previewOptionLabel: 'Preview',
    productsLabel: 'Products',
    resourceBankLabel: 'Resource Bank',
    resourceBanksButtonLabel: 'Resource Banks',
    resourceBanksDescription: 'You can use Resource Bank Assessments as is or customize them to create your own tests.',
    resourceBanksResourcesOnlyDescription: 'Resource Banks are built by your publisher and their contents may be used to create your own courses.',
    tagsAndStandardsLabel: 'Tags and Standards',
    tagsAndStandardsModalTitle: 'Standards and Tags',
    viewOptionLabel: 'View'
  },
  AdminDashboard: {
    mySchools: 'My Schools',
    welcomeLabel: 'Welcome'
  },
  AdminProductsView: {
    close: 'Close',
    labelButtonView: 'View',
    labelLicense: 'License',
    labelProductCode: 'Product Code',
    labelResourceId: 'Resource Id',
    labelResourceName: 'Resource Name',
    labelResourceType: 'Resource Type',
    name: 'Name',
    productsLabel: 'Products',
    schoolsLabel: 'Schools',
    type: 'Type'
  },
  AdminSchoolCard: {
    classesLabel: 'Classes',
    productsLabel: 'Products',
    reportsLabel: 'Reports',
    usersLabel: 'Users'
  },
  AdminTestBuilderView: {
    assessmentBankLabel: 'Collection',
    confirmCopyPrefix: 'Completing this action will add a copy of',
    confirmCopySuffix: 'to My Drafts.',
    confirmCopyTitle: 'Copy',
    confirmDeletePrefix: 'Completing this action will delete',
    confirmDeleteSuffix: 'from all areas. This cannot be undone. Would you like to complete this action?',
    confirmDeleteTitle: 'Delete',
    copyOptionLabel: 'Copy',
    copyOptionToDraftsLabel: 'Copy to My Drafts',
    coursesButtonLabel: 'Courses',
    coursesDescription: 'These are the courses that your institution has access to.',
    coursesResourcesDescription: 'These are the courses that your institution has access to.',
    coursesResourcesOnlyDescription: 'These are the courses that your institution has access to.',
    createNewButtonLabel: 'Create New',
    deleteOptionLabel: 'Delete',
    detailsOptionLabel: 'Edit Details',
    downloadOnlyModalMsg: 'This file is marked \'Uploaded File\' because it can only be downloaded. Below are a list of file types that can be embedded in lessons. Any other file type, like this one, will create a download link in your content. Once your audience downloads the file, they can open it on their computer. Video: MP4, WEBM Audio: MP3, WAV Images: GIF, JPEG, JPG, PNG Documents: DOCX, DOC, PDF, PPS, PPSX, PPT, PPTX, RTF ,TXT',
    downloadOnlyTitle: 'Download Only',
    editOptionLabelAssessment: 'Edit Assessment',
    editOptionLabelCollection: 'Edit Collection',
    editUnavailableCancelButton: 'Cancel',
    editUnavailableMsg: 'Editing this is not allowed because the assessment is used in a live collection.  Create a copy of this assessment to make changes.',
    editUnavailableTitle: 'Edit Unavailable',
    hasClasses: 'You cannot delete this resource because it has attached classes:  ',
    hasClassesTitle: 'Warning!',
    itemUsageExportLabel: 'Item Usage Report',
    libraryTitle: 'Test Builder',
    liveDescription: 'Live Assessment Collections are available to teachers.',
    liveLabel: 'Live',
    liveResourcesOnlyDescription: 'Live Assessment Collections are available to teachers.',
    moveToBankOptionLabel: 'Move to Collection',
    moveToLiveCancelButton: 'Cancel',
    moveToLiveConfirmButton: 'Move',
    moveToLiveMessagePrefix: 'Completing this action will move',
    moveToLiveMessageSuffix: 'to Live. The move process will take some time so allow a few minutes for your work to appear in live. Once live, teachers will have access. Would you like to complete this action?',
    moveToLiveOptionLabel: 'Move to Live',
    moveToSharedCancelButton: 'Cancel',
    moveToSharedConfirmButton: 'Move',
    moveToSharedMessagePrefix: 'Completing this action will add',
    moveToSharedMessageSuffix: 'to Shared Drafts.  Would you like to complete this action?',
    moveToSharedOptionLabel: 'Move to Shared',
    myDraftsDescription: 'In My Drafts you can build your own Assessments and Collections. Move them to Shared Drafts to share with other collaborators. Move your Collections to Live to share with teachers for use in their classes.',
    myDraftsDescriptionTeacher: 'In Test Builder you can build your own Assessments',
    myDraftsLabel: 'My Drafts',
    myDraftsResourcesOnlyDescription: 'In My Drafts build your Collections and use options to share with other collaborators or share with your teachers by making your drafts live.',
    noData: 'No results to display for the current selections',
    noPreviewAvailableMsg: 'This resource cannot be previewed.',
    noPreviewAvailableTitle: 'No Preview Available',
    openOptionLabel: 'Open',
    previewOptionLabel: 'Preview',
    previewOptionLabelTeacher: 'Print',
    previewOptionLabelTeacherAnswers: 'Print (with answers)',
    removeFromLiveCancelButton: 'Cancel',
    removeFromLiveConfirmButton: 'Remove',
    removeFromLiveDialogLabel: 'Remove',
    removeFromLiveMessagePrefix: 'Completing this action will remove',
    removeFromLiveMessageSuffix: 'from Live. Teachers will no longer be able to access this Collection; any active assignments will lost and scores against these assessments will be lost.  Would you like to complete this action?',
    removeFromLiveOptionLabel: 'Remove from Live',
    removeFromSharedCancelButton: 'Cancel',
    removeFromSharedConfirmButton: 'Remove',
    removeFromSharedDialogLabel: 'Remove',
    removeFromSharedMessagePrefix: 'Completing this action will remove',
    removeFromSharedMessageSuffix: 'from Shared Drafts.  Would you like to complete this action?',
    removeFromSharedOptionLabel: 'Remove from Shared',
    resourceBankLabel: 'Resource Bank',
    resourceBanksButtonLabel: 'Resource Banks',
    resourceBanksDescription: 'You can use Resource Bank Assessments as is or customize them to create your own tests.',
    resourceBanksResourcesOnlyDescription: 'Resource Banks are built by your publisher and their contents may be used to create your own courses.',
    shareLibraryResourceOptionLabel: 'Share With',
    sharedDraftsDescription: 'In Shared Drafts work on assessment and course drafts with other collaborators, to share with your teachers make your drafts live.',
    sharedDraftsLabel: 'Shared Drafts',
    sharedDraftsResourcesOnlyDescription: 'In Shared Drafts work on course drafts with other collaborators, to share with your teachers make your drafts live.',
    submitItemUsageLabel: 'Submit',
    tagsAndStandardsLabel: 'Tags and Standards',
    tagsAndStandardsModalTitle: 'Standards and Tags',
    viewOptionLabel: 'View'
  },
  AdminTopNav: {
    DashboardLabel: 'Schools',
    DashboardRollover: '',
    ReportsLabel: 'Reports',
    ReportsRollover: '',
    UsersLabel: 'Users',
    UsersRollover: '',
    classesLabel: 'Classes',
    classesRollover: '',
    contentLabel: 'Content',
    contentRollover: '',
    logout: 'Logout',
    notificationsLabel: 'Notifications',
    productsLabel: 'Products',
    productsRollover: '',
    profile: 'Profile',
    switchRole: 'Switch Role',
    testBuilderLabel: 'Test Builder',
    testBuilderRollover: ''
  },
  AdminUserProfile: {
    addClass: '+ Add Class',
    addJoinClass: '+ Add / Join Class',
    addStudentToClassButton: 'Add Student to Class',
    errorMessageFirstName: 'The First Name cannot be empty.',
    errorMessageHeader: 'Something went wrong',
    errorMessageLastName: 'The Last Name cannot be empty.',
    errorMessageUsername: 'The Username cannot be empty.',
    fieldChangePassword: 'Change Password',
    fieldDateCreated: 'Date Created',
    fieldFailedAttempts: 'Failed Attempts',
    fieldFirstName: 'First Name',
    fieldLastName: 'Last Name',
    fieldPassword: 'Password',
    fieldSchoolTimezone: 'School Timezone',
    fieldStatus: 'Status',
    fieldUserId: 'User ID',
    fieldUserRole: 'User Role',
    fieldUserTimezone: 'User Timezone',
    firstName: 'First Name',
    joinClass: '+ Join Class',
    lastName: 'Last Name',
    successMessage: 'Your profile has been updated.',
    successMessageHeader: 'Success',
    username: 'Username/email'
  },
  AdminUsersView: {
    addTeacherButton: '+ Add New Teacher',
    labelChooseRole: 'Choose a role...',
    labelChooseStatus: 'Choose a status...',
    labelFirstName: 'First Name',
    labelInstitutionName: 'Primary School',
    labelLastName: 'Last Name',
    labelStatus: 'Status',
    labelStatusActive: 'Active',
    labelStatusInactive: 'Inactive',
    labelUserRole: 'User Role',
    labelUsername: 'Email/Username',
    schools: 'Schools',
    users: 'Users'
  },
  AggregateAssignmentCard: {
    DownloadCourse: 'Download',
    ViewPreview: 'View',
    course: 'Course: ',
    expiredLicenseButtonViewMsg: 'The license for this product has expired; viewing the product is unavailable.',
    gradebookLabel: 'Gradebook',
  },
  AssessmentItemFilter: {
    allCourses: 'All Courses',
    course: 'Course'
  },
  AssessmentItemShopModal: {
    cancel: 'Cancel',
    noItemsPublished: 'No items have been published yet.',
    noResultsZeroStateMsg: 'No results found.',
    noSelectedAssessments: 'No new selected assessments yet.',
    save: 'Save',
    selectItems: 'Select Items',
    selected: 'Selected',
    selectedAssessments: 'Selected Assessments'
  },
  AssignmentCard: {
    DownloadCourse: 'Download',
    ViewPreview: 'View',
    edit: 'Edit',
    expiredLicenseButtonEditMsg: 'The license for this product has expired; editing the product is unavailable.',
    expiredLicenseButtonViewMsg: 'The license for this product has expired; viewing the product is unavailable.',
    gradebookLabel: 'Gradebook',
    noneLabel: 'None:',
    noneValue: 'None'
  },
  AssignmentFilter: {
    allAssignTypes: 'All',
    allClassroomsLabel: 'All Classes',
    allDatesLabel: 'All Time',
    assignedBy: 'Assigned By',
    assignmentDateDes: 'Assignments are organized by date. The default shows assignments due today and in the future. Use the calendar to select a date, then choose a filter method.',
    classLabel: 'Assigned To',
    dateRangeLabel: 'Date Range',
    dateRangeSectionLabel: 'Due Date Range',
    endDateLabel: 'To Selected Date',
    entireClassIndividualInfoText: 'Entire Class: Individual assignments are made for every individual in the class and can be managed per student or as a whole class.',
    entireClassIndividualLabel: 'Individual Student',
    entireClassLabel: 'Entire Class',
    graded: 'Graded',
    groupInfoText: 'Create assignments for students in select groups.',
    groupLabel: 'Group',
    resetFilters: 'Reset Filters',
    startDateLabel: 'From Selected Date',
    status: 'Status',
    validDateFormatMessage: 'Valid Date format is MM/DD/YYYY.',
    validFromDateMessage: 'From Date cannot be after To Date.',
    validToDateMessage: 'To Date cannot be before From Date.'
  },
  AssignmentManager: {
    // TODO remove, unused // EXPORT_ACTIVITY_INSTANCE: 'Summary Export',
    // TODO remove, unused // EXPORT_ACTIVITY_ITEM_ANALYSIS: 'Item Analysis Export',
    EXPORT_AGGREGATE_INSTANCES: 'Summary Export',
    EXPORT_AGGREGATE_INSTANCES_MINI: 'Summary Export (Mini)',
    EXPORT_AGGREGATE_ITEM_ANALYSIS: 'Item Analysis Export',
    EXPORT_AGGREGATE_MINI_ANALYSIS: 'Item Analysis Export (Mini)',
    EXPORT_AGGREGATE_STANDARDS: 'Standards Export',
    allClasses: 'Entire Class',
    assignerTypeAll: 'All',
    assignerTypeCoTeacher: 'Co-Teacher',
    assignerTypeLeadTeacher: 'Lead Teacher',
    assignmentStatusAll: 'All Statuses',
    assignmentStatusClosed: 'Closed',
    assignmentStatusGraded: 'Graded',
    assignmentStatusLocked: 'Locked',
    assignmentStatusPending: 'Pending',
    assignmentStatusPresentation: 'Presentation',
    assignmentStatusPreview: 'Preview',
    assignmentStatusReady: 'Not Started',
    assignmentStatusStarted: 'Started',
    assignmentSubdomainTypeClassroom: 'Classroom',
    assignmentSubdomainTypeGroup: 'Group',
    assignmentSubdomainTypeTeacher: 'Teacher',
    assignmentTypeAllClasses: 'Entire Class',
    assignmentTypeClassroom: 'Entire Class',
    assignmentTypeClassroomUser: 'Entire Class',
    assignmentTypeCutScore: 'Student Average',
    assignmentTypeGroup: 'Group',
    assignmentTypeTeacher: 'Teacher',
    assignmentTypeUnassigned: 'Unassigned',
    assignmentTypeUser: 'Individual',
    classroom: 'Entire Class',
    group: 'Group',
    teacher: 'Teacher',
    unassigned: 'Unassigned',
    user: 'Individual'
  },
  AssignmentMenu: {
    AssignmentLabel: 'My Assignments',
    ClosedAssignments: 'Closed',
    CurrentAssignments: 'Current Assignments',
    DueTodayAssignments: 'Due Today',
    GradedAssignments: 'Graded',
    SearchPlaceholder: 'Enter content name',
    searchContentAriaLabel: 'Search for content name'
  },
  AssignmentReportSettings: {
    assignmentStatusDescription: 'Click Edit above to change status.',
    assignmentStatusLabel: 'Assignment Status',
    cancel: 'Cancel',
    graded: 'Graded',
    includeInReportsDescription: 'Assignment grades will appear in reports',
    includeInReportsLabel: 'Release grades to reports',
    markGradingComplete: 'Mark Grading Complete',
    reportSettingsLabel: 'Report Settings',
    save: 'Save',
    scoresReleasedDescription: 'Assignment grades will be viewable by student',
    scoresReleasedLabel: 'Release grades to students',
    serverErrorMsg: 'Something went wrong when saving the assignment, please try again',
    studentReviewLabel: 'Students can review work'
  },
  AssignmentView: {
    AssignedAssignments: 'Assigned',
    ClosedAssignments: 'Needs Manual Grading',
    DefaultSortOrder: 'asc',
    GradedAssignments: 'Graded',
    InProgressAssignments: 'In Progress',
    NoAssignedAssignments: 'No assignments yet',
    NoClosedAssignments: 'No closed assignments',
    NoGradedAssignments: 'No graded assignments',
    NoInProgressAssignments: 'No in progress assignments',
    ShowAllAssignments: 'Show All',
    activityNameColumnLabel: 'Activity Name',
    allAssignments: 'All Assignments',
    assignEntityTypeIdKey_classroom: 'Class',
    assignEntityTypeIdKey_group: 'Group',
    assignEntityTypeIdKey_teacher: 'Teacher', // edge case, will likely never happen
    assignEntityTypeIdKey_user: 'Individual(s)',
    assignedToColumnLabel: 'Assigned To:',
    assignmentListLabel: 'Assignment List',
    assignmentsLabel: 'Assignments',
    classNameColumnLabel: 'Class Name',
    endDateColumnLabel: 'End Date',
    gradebookLabel: 'Gradebook',
    scorecardColumnLabel: 'Scorecard',
    showAllButtonLabel: 'Show All',
    startDateColumnLabel: 'Start Date',
    statusColumnLabel: 'Status'
  },
  AssignmentsPopup: {
    allAssignments: 'All Assignments',
    gradebookLabel: 'Gradebook',
    infoPopupText: 'Some assignments are entirely aligned to the standard and some are aligned down to certain items within the assignment.',
  },
  BreadCrumbsCourseDropdown: {
    closeLabel: 'Close Nav',
    label: 'Quick Nav',
    openLabel: 'Quick Nav'
  },
  Button: {
    active: 'Active',
    add: 'Add',
    addCourseButton: '+ Add Course',
    addToClass: 'Add to Class',
    archived: 'Archived',
    assign: 'Assign',
    back: 'Back',
    cancel: 'Cancel',
    close: 'Close',
    continue: 'Continue',
    continueTrial: 'Continue Trial',
    enterAccessCode: 'Enter Access Code',
    hideColumns: 'Hide Columns',
    no: 'No',
    ok: 'OK',
    open: 'Open',
    submit: 'Submit',
    yes: 'Yes'
  },
  CartModalClassrooms: {
    classId: 'Class ID',
    className: 'Class Name',
    classesLabel: 'Classes'
  },
  CartModalClassroomsPopup: {
    className: 'Class Name',
    classesLabel: 'Classes'
  },
  CautionBanner: {
    clickToExpand: 'Click to Expand',
    clickToMinimize: 'Click to Minimize'
  },
  CautionIcon: {
    expiredLicenseCautionMsg: 'The license for this product has expired; this will limit your access. Contact your administrator for assistance.',
  },
  ClassCard: {
    accommodations: 'Accommodations',
    assignments: 'Assignments',
    classSettings: 'Class Settings',
    course: 'Course',
    courseLabel: 'Course',
    coursesLabel: 'Courses',
    dueToday: 'Due Today:',
    groupsLabel: 'Groups',
    none: 'None',
    readyToGrade: 'Ready to Grade:',
    roster: 'Roster',
    workspace: 'Workspace'
  },
  ClassCourses: {
    BannerTitle: 'Books',
    InitialBreadcrumb: 'Book',
    InitialBreadcrumb_plural: 'Books',
    courseLabel: 'course',
    courseZeroStateMsg: 'There are no courses assigned to this class.',
    customCourseZeroStateMsg: 'There are no custom courses assigned to this class'
  },
  ClassDetails: {
    BannerTitle: 'Books',
    coursesZeroStateMsg: 'There are no items in this Unit/Chapter',
    customAssessmentZeroStateMsg: 'There are no assessments added yet.',
    showHiddenResources: 'Show hidden resources'
  },
  ClassMenu: {
    ariaClassMenuClosedInstructions: 'Press Enter to open Class Menu.',
    ariaClassMenuDropdownLabel: 'Class Menu. Current class: {{classLabel}}',
    ariaClassMenuOpenInstructions: 'Press Tab or Shift+Tab to navigate to the desired class, then press Enter to select.'
  },
  ClassRoster: {
    activate: 'Activate',
    active: 'Active',
    addAdminAsCoteacher: 'Make me a Co-Teacher',
    archive: 'Archive',
    archived: 'Archived',
    cannotDeleteCoteacherMsgDefault: 'Only administrators and lead teachers can remove co-teachers.',
    cannotDeleteCoteacherMsgSso: 'This co-teacher can only be removed via the single sign on (SSO) system.',
    confirmUserCoteacher: 'You are already a co-teacher for this class.',
    coteacher: 'Co-Teacher',
    coteachers: 'Co-Teachers',
    edit: 'Edit',
    firstName: 'First Name',
    lastName: 'Last Name',
    leadTeacher: 'Lead Teacher',
    message: 'Deleting a Co-Teacher removes their access to the class. Assignments made by the Co-Teacher will remain intact.',
    messageAct: 'Activating this student will display them in your active class roster.',
    messageArch: 'Archiving this student will remove the student from your active roster and all future assignments.',
    migrate: 'Migrate data',
    migrateStudentHeader: 'Migrate Student Data',
    password: 'Password',
    studentData: 'Student Data',
    unknownLeadTeacherName: 'Unknown',
    username: 'User Name'
  },
  ClassRosterRow: {
    cannotArchiveStudentMsgDefault: 'This student\'s information has been marked inactive and cannot be archived.',
    cannotEditStudentMsgDefault: 'This student\'s information has been marked inactive and cannot be modified.',
    cannotEditStudentMsgSso: 'This student\'s information can only be modified via the single sign on (SSO) system.',
    cannotEditStudentPasswordMsgSso: 'This student\'s password can only be modified via the single sign on (SSO) system.',
    enable: 'Enable',
    inactiveMsg: 'This student\'s account is inactive. Click enable to restore their account.',
    migrateButtonLabel: 'Migrate Data',
    migrateButtonLabelSmall: 'Migrate',
    trialDaysRemaining: '({{count}}) Day',
    trialDaysRemaining_plural: '({{count}}) Days',
    trialExpired: 'Expired'
  },
  ClassRosterTable: {
    messageMigrate: 'This student has completed work in another class, work is available to be migrated to this class.  The following assignments are available to be migrated for this student.  Please select to either migrate all assignments or none. ',
    messageMigrateRequestSubmitted: 'This student’s data is being migrated. Check your notifications for when the process completes.',
    messageNoMigrateData: 'This student does not have any class data to migrate',
    migrateButtonLabel: 'Migrate Data',
    migrateButtonLabelSmall: 'Migrate',
    trialTimeRemaining: 'Trial Time Remaining',
    username: 'User Name'
  },
  ClassSettingsView: {
    accessCode: 'Access Code',
    classDisplayName: 'Class Display Name',
    classInformationTitle: 'Class Information',
    className: 'Class Name',
    classStatus: 'Class Status',
    classesLabel: 'Classes',
    classroomNotFound: 'The classroom was not found.',
    endDate: 'End Date',
    institutionName: 'Institution',
    startDate: 'Start Date'
  },
  ClassWorkspace: {
    WorkspaceLabel: 'Workspace',
    workspaceTitle: 'Workspace'
  },
  ClassroomDropdown: {
    loading: 'Loading...',
    selectClassroom: 'Select Class'
  },
  ClassroomInfoBanner: {
    classroom: 'Classroom',
    classroomId: 'Class ID',
    institutionName: 'School',
    leadTeacherName: 'Teacher'
  },
  ClassroomReportTypeSelectorModal: {
    'course-classroom-summary': 'Class Course Report',
    'reports': 'Reports',
    'standards-classroom-summary': 'Class Standards Report'
  },
  ClassroomSelectionCard: {
    classroomAlreadyHasCourse: 'This class already has the chosen course.',
  },
  ClassroomSelectionModal: {
    classroomSelectionCardsZeroState: 'You must have a class to add a course. Navigate to "Classes" and create a class.'
  },
  ClassroomService: {
    classesLabel: 'Classes'
  },
  ClassroomStatusDropdown: {
    active: 'Active Classes',
    archived: 'Archived Classes'
  },
  CoTeacherAccessCode: {
    coTeacherAccessCode: 'Co-Teacher Access Code'
  },
  ColorKeyCard: {
    adminClassroomsColorKeyCardLabel_iconCircleStatusEmpty: 'No data available',
    adminClassroomsColorKeyCardLabel_iconCircleStatusFull: 'Report Run',
    adminClassroomsColorKeyCardLabel_iconCircleStatusHalf: 'Data available, report not run',
    colorKey: 'Color Key',
    courseApproachingLabel: 'Approaching',
    courseApproachingSubLabel: '60% - 79%',
    courseDevelopingLabel: 'Developing',
    courseDevelopingSubLabel: '< 60%',
    courseMasteringLabel: 'Mastering',
    courseMasteringSubLabel: 'N/A',
    courseMeetingLabel: 'Meeting',
    courseMeetingSubLabel: '≥ 80%',
    courseNotEnoughDataLabel: 'Not Enough Data',
    courseNotEnoughDataSubLabel: '',
    fullCredit: 'Full points',
    noCredit: 'No points',
    noResponse: 'No Student Response',
    partialCredit: 'Partial points',
    requiresManualScore: 'Requires Manual Scoring',
    standardsApproachingLabel: 'Approaching',
    standardsApproachingSubLabel: '60% - 79%',
    standardsDevelopingLabel: 'Developing',
    standardsDevelopingSubLabel: '< 60%',
    standardsMasteringLabel: 'Mastering',
    standardsMasteringSubLabel: 'N/A',
    standardsMeetingLabel: 'Meeting',
    standardsMeetingSubLabel: '≥ 80%',
    standardsNotEnoughDataLabel: 'Not Enough Data',
    standardsNotEnoughDataSubLabel: ''
  },
  ContentAssignmentModal: {
    allAssignments: 'All Assignments',
    assignmentsLabel: 'Assignments',
    dueToday: 'Due Today:',
    gradebookLabel: 'Gradebook',
    noActivitiesFound: 'No Activities Found',
    readyToGrade: 'Ready to Grade: '
  },
  ContentElementModal: {
    allAssignments: 'All Assignments',
    gradebookLabel: 'Gradebook',
    noContentFoundToAssign: 'No content found to assign.'
  },
  CopyLibraryResourceModal: {
    cancelButtonLabel: 'Cancel',
    copyButtonLabel: 'Copy',
    descriptionTextPrefix: 'Completing this action will add a copy of',
    descriptionTextSuffix: 'to My Drafts.',
    headerLabel: 'Copy',
    nameLabelSubText: '{Description text goes here}',
    nameLabelSubTextBank: 'Enter a prefix to use for the name',
    nameLabelText: 'Name Copy',
    nameLabelTextBank: 'Name Prefix',
    resourceDescriptionTextSuffix: 'to Collection.',
  },
  CourseCard: {
    courseDeletePermissionMsg: 'You do not have permission to remove this course.',
    open: 'Open',
    readOnlyPreview: 'View',
    remove: 'Remove'
  },
  CourseElementModal: {
    allAssignments: 'All Assignments',
    close: 'Close',
    gradebookLabel: 'Gradebook',
    zeroStateNoItems: 'There are no items in this Unit/Chapter'
  },
  CourseLayout: {
    allAssignments: 'All Assignments',
    contentModalHeaderLabel: 'Assignment Information:',
    gradebookLabel: 'Gradebook'
  },
  CourseListBanner: {
    addExistingStudentButton: '+ Add Existing Student',
    addGroupButton: '+ Add Group',
    addStudentButton: '+ Add New Student',
    courseSearchButton: 'Go to Search',
    courseSearchButtonFromTeacherProducts: 'Course Search',
    products: 'Products',
    progressLabel: 'My Progress'
  },
  CourseManager: {
    imageAssetNameUnit: 'Assessment-Folder-Course-View-image.png'
  },
  CourseTreeBranch: {
    assignAll: 'Assign All',
    editOptionLabel: 'Edit Details',
    editUnitLabel: 'Edit Unit',
    hiddenMsg: 'Resources marked as hidden cannot be opened.',
    open: 'Open',
    rename: 'Rename'
  },
  CourseTreeLeaf: {
    Edit: 'Edit',
    ViewPreview: 'View',
    askRemoveAssessment: 'Are you sure you want to remove this assessment from your class? If it exists in any other classes, it will be removed from those classes as well.',
    details: 'Edit Details',
    dueDate: 'Due Date:',
    dueTime: 'Due Time:',
    expiredLicenseButtonAssignMsg: 'The license for this product has expired; assigning the product is unavailable.',
    expiredLicenseButtonViewMsg: 'The license for this product has expired; viewing the product is unavailable.',
    grade: 'Grade:',
    hiddenMsg: 'Resources marked as hidden do not allow this functionality.',
    isLiveLabelAssessment: 'Used in Live Collection',
    lockedAssignment: 'This assignment is locked.',
    notAvailable: 'n/a',
    notYetAssigned: 'Not Yet Assigned',
    pdf: 'PDF',
    removeFromBank: 'Remove from Collection',
    removeFromBankCancelButton: 'Cancel',
    removeFromBankConfirmButton: 'Remove',
    removeFromBankDialogLabel: 'Remove',
    removeFromBankMessagePrefix: 'Completing this action will remove',
    removeFromBankMessageSuffix: 'from the current collection.  Would you like to complete this action?',
    studentPreview: ' View',
    tagsAndStandards: 'Info',
    teacherButtonAssign: 'Assign',
    teacherButtonPdf: 'PDF',
    teacherButtonRemove: 'Remove',
    itemUsage: 'Item Usage Report',
    waitingOnGrade: 'Waiting on Grade'
  },
  CreateAssessmentBankUnitModal: {
    cancelButtonLabel: 'Cancel',
    createButtonLabel: 'Create',
    createNewUnitHeader: 'Create New Unit',
    descriptionLabelSubText: 'Users will see information you enter here on the line below the Name.',
    descriptionLabelText: 'Description',
    inputPlaceholderText: 'Enter text here',
    saveButtonLabel: 'Save',
    unitNameLabelSubText: 'Enter a title for the unit.',
    unitNameLabelText: 'Name',
  },
  CreateNewLibraryResourceModal: {
    assessmentBankLabel: 'Collection',
    assessmentLabel: 'Assessment',
    assessmentTypePickerMessage: 'Before creating a new assessment, please select the type of assessment you wish to create.',
    cancelButtonLabel: 'Cancel',
    createButtonLabel: 'Create',
    customNameLabelSubText: '',
    dateRange: 'Delivery Option: Timeframe',
    defaultUnitPlaceholderText: 'Unit',
    descriptionLabelSubText: 'Users will see information you enter here on the line below the Name.',
    descriptionLabelText: 'Description',
    descriptionText: 'TBD Form text',
    digitalAssessment: 'Assessment for digital use',
    inputPlaceholderText: 'Enter text here',
    modalCreateLabel: 'Create New',
    modalEditLabel: 'Details',
    modeLabelSubText: 'Select modes available to teacher(s)',
    modeLabelText: 'Select modes available to teacher(s)',
    nameLabelSubText: 'Enter a name for this',
    nameLabelSubTextPostfix: 'Teachers will look for this name when adding it to their classes.',
    nameLabelText: 'Name',
    printAssessment: 'Assessment for print use',
    reviewOutsideTimeframeLabel: 'Allow teacher to review outside of timeframe',
    saveButtonLabel: 'Save',
    teacherCanChangeModeLabel: 'Teacher can change mode',
    templateLabelSubText: 'The template determines which tools and references are available to students.',
    templateLabelText: 'Select Template',
    templatePlaceholderText: 'Select Template',
    unitNameLabelSubText: 'Assessments are organized into units in a Collection. You may add additional units later.',
    unitNameLabelText: 'Name First Unit',
    useAlternateModeLabel: 'Use Alternate Mode',
    usePrimaryModeLabel: 'Use Primary Mode'
  },
  DefaultNotificationCard: {
    expirationLabel: 'Message Expires',
    showLessLink: 'Show Less',
    showMoreLink: 'Show More'
  },
  DemolinkLauncherView: {
    demolinkBoxDescription: '<p>Thanks for stopping by.</p><p>We’re creating a unique user experience just for you.</p><p>This may take up to a minute to complete.</p><p>We appreciate your patience.</p>',
    demolinkBoxErrorDescription: '<p>The provided link is currently unavailable.</p><p>Please contact customer service if this issue persists.</p><p>We apologize for the inconvenience.</p>',
    demolinkBoxErrorTitle: 'Unavailable',
    demolinkBoxTitle: 'Welcome'
  },
  EditAssignmentModal: {
    all: 'All Statuses',
    allClassesLabel: 'All Classes',
    allowBackSpaceLabel: 'Allow back space',
    assignTo: 'Assign To',
    cancel: 'Cancel',
    closed: 'Closed',
    closedDescription: 'Students can no longer access the assignment.',
    completed: 'Graded',
    completedDescription: 'Assignments have been graded.',
    dateRange: 'Date Range',
    dateRangeDescription: 'Selected dates must be within timeframe above',
    description: 'Description',
    dueDate: 'Late Due Date',
    dueTime: 'Late Due Time',
    endDate: 'End Date',
    endTime: 'End Time',
    entireClassLabel: 'Entire Class',
    ignoreItemOptionShuffle: 'Shuffle answer choices ',
    ignoreItemOptionShuffleMessage: 'Shuffling may not apply to all question types',
    lateSubmission: 'Allow late submissions',
    lateSubmissionDescription: 'Late submission is only available for students who have not yet submitted.',
    lateSubmissionText: 'Late submission is only available for students who have not yet submitted.',
    lateSubmitDateLabel: 'Allow late submissions until',
    locked: 'Locked',
    lockedDescription: 'Students cannot view or open the assignment until the start date.',
    modeOption: 'Mode Option',
    modeOptionDescription: 'Set the mode to use for this assignment.',
    name: 'Name',
    nicknameLabel: 'Nickname',
    nicknamePlaceholder: 'Add nickname (optional)...',
    presentation: 'Presentation',
    preview: 'Preview',
    previewDescription: 'Students can preview but not start the assignment.',
    randomizeQuestions: 'Shuffle questions',
    ready: 'Not Started',
    readyDescription: 'Assignments can be started by students.',
    releaseToReportsLabel: 'Release to reports',
    reportsAndGrading: 'Reports & Grading',
    reportsAndGradingDescription: 'Toggles determine actions that happen after assignments are complete.',
    save: 'Save',
    scoresReleased: 'Release grades to students',
    startDate: 'Start Date',
    startTime: 'Start Time',
    started: 'Started',
    startedDescription: 'At least one student has started the assignment.',
    status: 'Status',
    studentReview: 'Students can review work',
    timeFrame: 'Timeframe',
    timeFrameDescription: 'Timeframe is set by content creator and content can only be used within timeframe.',
    typingDurationLabel: 'Duration (s)',
    typingMinimumAccuracyLabel: 'Minimum Accuracy',
    typingMinimumSpeedLabel: 'Minimum Speed',
    typingTextLabel: 'Typing Text',
    viewActivityNumberGradebook: 'View Assignment ({{activityNumber}}) Gradebook'
  },
  EditBulkAssignmentModal: {
    all: 'All Statuses',
    allClassesLabel: 'All Classes',
    allowBackSpaceLabel: 'Allow back space',
    cancel: 'Cancel',
    closed: 'Closed',
    closedDescription: 'Students can no longer access the assignment.',
    completed: 'Graded',
    completedDescription: 'Assignments have been graded.',
    dateRange: 'Date Range',
    dateRangeDescription: 'Selected dates must be within timeframe above',
    description: 'Description',
    dueDate: 'Late Due Date',
    dueTime: 'Late Due Time',
    endDate: 'End Date',
    endTime: 'End Time',
    entireClassLabel: 'Entire Class',
    graded: 'Graded',
    ignoreItemOptionShuffle: 'Shuffle answer choices',
    ignoreItemOptionShuffleMessage: 'Shuffling may not apply to all question types',
    lateSubmission: 'Allow late submissions',
    lateSubmissionDescription: 'Late submission is only available for students who have not yet submitted.',
    lateSubmissionOption: 'Late Submission',
    lateSubmissionText: 'Late submission is only available for students who have not yet submitted.',
    lateSubmitDateLabel: 'Allow late submissions until',
    locked: 'Locked',
    lockedDescription: 'Students cannot view or open the assignment until the start date.',
    modeOption: 'Mode Option',
    modeOptionDescription: 'Set the mode to use for this assignment.',
    name: 'Name',
    nicknameLabel: 'Nickname',
    nicknamePlaceholder: 'Add nickname (optional)...',
    presentation: 'Presentation',
    preview: 'Preview',
    previewDescription: 'Students can preview but not start the assignment.',
    randomizeQuestions: 'Shuffle questions',
    ready: 'Not Started',
    readyDescription: 'Assignments can be started by students.',
    releaseToReportsLabel: 'Release to reports',
    reportsAndGrading: 'Reports & Grading',
    reportsAndGradingDescription: 'Toggles determine actions that happen after assignments are complete.',
    save: 'Save',
    scoresReleased: 'Release grades to students',
    startDate: 'Start Date',
    startTime: 'Start Time',
    started: 'Started',
    startedDescription: 'At least one student has started the assignment.',
    status: 'Status',
    studentReview: 'Students can review work',
    timeFrame: 'Timeframe',
    timeFrameDescription: 'Timeframe is set by content creator and content can only be used within timeframe.',
    typingDurationLabel: 'Duration (s)',
    typingMinimumAccuracyLabel: 'Minimum Accuracy',
    typingMinimumSpeedLabel: 'Minimum Speed',
    typingTextLabel: 'Typing Text',
  },
  EditStudentProfile: {
    firstName: 'First Name',
    lastName: 'Last Name',
    username: 'Username/email'
  },
  EngagementButtonBar: {
    annotationsUsage: 'Annotations Usage',
    feedbackUsage: 'Feedback Usage',
    languageToolUsage: 'Language Tool Usage',
    resourcesUsage: 'Resources Usage',
    timeOnTaskRatio: 'Time on Task Ratio'
  },
  EngagementTable: {
    activityNumber: 'Assignment',
    annotationsUsage: 'Annotations Usage',
    annotationsUsageDescription: 'Number of uses of annotation tools. Maximum count of one use of each tool per item',
    annotationsUsageDescriptionMain: 'Number of uses of Strikethrough, Highlight, Sticky Notes, or Mark for Review',
    assignmentSummary: 'Assignment Summary',
    blankTrigger: '‎',
    editButtonText: 'Edit for Student',
    engagedAverageTimePerItem: 'Average Time per Item',
    engagedAverageTimePerItemCorrect: 'Average Time -‎ ‎ Answered Correctly',
    engagedAverageTimePerItemCorrectDescription: 'Average time per item spent on items answered correctly (in minutes)',
    engagedAverageTimePerItemDescription: 'Average time per item (in minutes)',
    engagedAverageTimePerItemIncorrect: 'Average Time -‎ ‎ Answered Incorrectly',
    engagedAverageTimePerItemIncorrectDescription: 'Average time per item spent on items answered incorrectly (in minutes)',
    engagedBasicCalculator: 'Basic Calculator',
    engagedCorrectOnFirstAttempt: 'Correct on First Attempt',
    engagedCorrectOnFirstAttemptDescription: 'Number of items answered correctly on first attempt out of total possible items',
    engagedCustomaryRuler: 'Customary Ruler',
    engagedDictionary: 'Dictionary',
    engagedGraphingCalculator: 'Graphing Calculator',
    engagedHighlightUsage: 'Highlight Usage',
    engagedHighlightUsageDescription: 'Number of uses of Highlight',
    engagedHintUsage: 'Hint Usage',
    engagedHintUsageDescription: 'Number of uses of Show Hint out of the total number of opportunities',
    engagedMarkForReviewUsage: 'Mark for Review Usage',
    engagedMarkForReviewUsageDescription: 'Number of uses of Mark for Review',
    engagedMetricRuler: 'Metric Ruler',
    engagedProtractor: 'Protractor',
    engagedReferences: 'References',
    engagedSampleAnswerUsage: 'Sample Answer Usage',
    engagedSampleAnswerUsageDescription: 'Number of uses of Show Sample Answer out of the total number of opportunities',
    engagedScientificCalculator: 'Scientific Calculator',
    engagedShowCorrectAnswerUsage: 'Show Correct Answer Usage',
    engagedShowCorrectAnswerUsageDescription: 'Number of uses of Show Correct Answer out of the total number of opportunities',
    engagedStickyNotesUsage: 'Sticky Notes Usage',
    engagedStickyNotesUsageDescription: 'Number of uses of Sticky Notes',
    engagedStrikethroughUsage: 'Strikethrough Usage',
    engagedStrikethroughUsageDescription: 'Number of uses of Strikethrough',
    engagedTextToSpeechUsage: 'Text to Speech Usage',
    engagedTextToSpeechUsageDescription: 'Number of uses of Speak',
    engagedTranslateUsage: 'Translate Usage',
    engagedTranslateUsageDescription: 'Number of uses of Translate',
    engagedTryAgainUsage: 'Try Again Usage',
    engagedTryAgainUsageDescription: 'Number of items where Try Again was used out of the total number of opportunities',
    feedbackUsage: 'Feedback Usage',
    feedbackUsageDescription: 'Number of uses of blue feedback buttons out of total available',
    feedbackUsageDescriptionMain: 'Number of uses of blue feedback buttons out of total available',
    firstName: 'First Name',
    flag: 'Flag',
    flagDisengageDescription: 'Student is not taking advantage of the feedback supports (Hints, Try Again, Show Correct Answer, and Sample Answer).',
    flagFeedbackDescription: 'Student used less than half of feedback',
    flagSpeedDescription: 'Student is likely rushing; student is working quickly and has low performance',
    languageToolUsage: 'Language Tool Usage',
    languageToolUsageDescription: 'Combined number of uses of Speak and Translate',
    languageToolUsageDescriptionMain: 'Number of uses of Translate and Speak tools',
    lastName: 'Last Name',
    resourceNotAvailable: 'N/A',
    resourceNotAvailableDescription: 'Tool not available',
    resourcesRowAuxiliaryCellDescription: 'This tool used in assignment',
    resourcesRowAuxiliaryCellSummaryBarDescription: 'This tool was used in {{count}}/{{total}} assignments',
    resourcesRowMainCellDescription: '{{count}} tools used during this assignment',
    resourcesRowMainCellSummaryBarDescription: 'Average of {{count}} tools used in this assignment',
    resourcesUsage: 'Resources Usage',
    resourcesUsageDescription: 'Number of uses of tools or resources (such as Calculator, Dictionary, References, etc.)',
    resourcesUsageDescriptionMain: 'Number of uses of tools or resources (such as Calculator, Dictionary, References, etc.)',
    timeOnTaskMoreTimeCorrectDescription: 'Student spent significantly more time on items answered correctly.',
    timeOnTaskMoreTimeCorrectLabel: '> 1.5:',
    timeOnTaskMoreTimeIncorrectDescription: 'Student spent significantly more time on items answered incorrectly.',
    timeOnTaskMoreTimeIncorrectLabel: '0 < .5:',
    timeOnTaskRatio: 'Time on Task Ratio',
    timeOnTaskRatioDescription: 'Indicates balance of time spent answering correctly vs incorrectly',
    timeOnTaskRatioDescriptionMain: 'Indicates balance of time spent answering correctly vs incorrectly',
    timeOnTaskSimilarTimeDescription: 'Student spent a similar amount of time on items answered correctly and items answered incorrectly.',
    timeOnTaskSimilarTimeLabel: '.5 < 1.5:'
  },
  ExportGradesModal: {
    exportCSV: 'Export CSV',
    exportGenerationExplanationMsg: 'This document takes a little time to create.  It will be sent to your email.',
    hideUnscorableFalseLabel: '‏‏‏‏‎ ‎‏‏‎ Non-scorable items',
    hideUnscorableTrueLabel: '‏‏‏‏‎ ‎‏‏‎ Non-scorable items',
    noStandardsMessage: 'Standards data is not available for this assignment.',
    noStandardsStudentDataMessage: 'Check back after at least one student has submitted.'
  },
  ExportReportModal: {
    COURSE_CLASS_PERFORM_P_X: 'Course Report (Points only)',
    COURSE_CLASS_PERFORM_S_X: 'Course Report (Scores only)',
    COURSE_CLASS_PERFORM_X: 'Course Report (Full report)',
    COURSE_DISTRICT_PERFORM_X: 'Course Report (Full report)',
    COURSE_DIST_PERFORM_X: 'Course Report (Full report)',
    COURSE_SCHOOL_PERFORM_X: 'Course Report (Full report)',
    COURSE_SCH_PERFORM_X: 'School Detail (By Class)',
    COURSE_SCH_ST_PERFORM_X: 'School Detail (By Student)',
    STANDARDS_CLASS_PERFORM_S_X: 'Standards Report (Scores only)',
    STANDARDS_CLASS_PERFORM_X: 'Standards Report (Full report)',
    STANDARDS_DIST_PERFORM_X: 'Standards Report (Full report)',
    STANDARDS_SCHOOL_PERFORM_X: 'Standards Report (Full report)',
    STANDARDS_SCH_PERFORM_X: 'School Detail (By Class)',
    STANDARDS_SCH_ST_PERF_X: 'School Detail (By Student)',
    exportGenerationExplanationMsg: 'This document takes a little time to create.  It will be sent to your email.'
  },
  FileViewerModal: {
    gradebookLabel: 'Gradebook'
  },
  FilteredHeaderTable: {
    contains: 'Contains',
    doesnotcontain: 'Does not contain',
    endswith: 'Ends with',
    eq: 'Is equal to',
    neq: 'Is not equal to',
    resetTableFilters: 'Reset Filters',
    startswith: 'Starts with'
  },
  Footer: {
    privacy: 'Privacy',
    support: 'Support',
    termsOfUse: 'Terms of Use'
  },
  Footnotes: {
    modifiedContentFootnote: 'Indicates the assignment was modified if its cell has this marker'
  },
  Gradebook: {
    // TODO remove // feedbackDescription: 'Students are not taking advantage of the feedback supports (Hints, Try Again, Show Correct Answer, and Sample Answer).',
    // TODO remove // timeOnTaskRatioDescription: 'Students are likely rushing; they are working quickly and have low performance.',
    aggregateGradebook: 'All Assignments',
    allAssignments: 'All Assignments',
    assignmentInformation: 'Assignment Information',
    assignmentsLabel: 'Assignments',
    cancel: 'Cancel',
    classrooms: 'Classes',
    confirmViewStudentWorkHeader: 'View Student Work',
    confirmViewStudentWorkMsg: 'You are about to view the work of {{studentName}}. In this view, you will be able to navigate between students who belong to the same assignment.',
    gradebookLabel: 'Gradebook',
    viewContent: 'View Content'
  },
  GradebookCard: {
    addOrEditNotes: '+Add/Edit Notes',
    assignedBy: 'Assigned By',
    dueDate: 'Due Date',
    dueTime: 'Due Time',
    edit: 'Edit',
    export: 'Export',
    goToCourseLabel: 'Go To Course',
    notes: 'Notes',
    readMore: 'Read more',
    releaseToReportsLabel: 'Release to reports',
    scoresReleased: 'Release grades to students',
    startDate: 'Start Date',
    startTime: 'Start Time',
    studentInstructions: 'Student Instructions',
    studentReview: 'Students can review work'
  },
  GradebookDataBar: {
    averageScore: 'Average Score:',
    graded: 'Graded:',
    notApplicable: 'N/A',
    started: 'Started:',
    submitted: 'Submitted:'
  },
  GradebookDetailsTable: {
    activityNumber: 'Assignment',
    activityTimezoneEndDate: 'Due Date',
    activityTimezoneStartDate: 'Start Date',
    averageGrade: 'Average Grade:',
    averageScore: 'Average Points:',
    averageViewedTime: 'Average Usage:',
    editButtonText: 'Edit for Student',
    editForClassButtonText: 'Edit for Class',
    editForGroupButtonText: 'Edit for Group',
    excludeFromScore_buttonLabel: 'Bring Status Back to Closed',
    excludeFromScore_checkboxGradedWarning: 'You have items that are graded; exclude items at the individual level.',
    excludeFromScore_checkboxLabel: 'Exclude this item from score',
    excludeFromScore_description: 'Exclude is only available before the assignment status is set to "Graded".   Bring status back to "Closed" to allow an item to be excluded. Once the item has been excluded, the assignment will move back to “Graded”.',
    excludeFromScore_descriptionForAggregate: 'Exclude is only available before the assignment status is set to "Graded".   Bring status back to "Closed" to allow an item to be excluded. Once the item has been excluded, the assignment will move back to “Graded”.',
    firstName: 'First Name',
    grade: 'Grade',
    gradeHeaderLabel: 'Grade',
    hideUnscorableFalseLabel: '‏‏‏‏‎ ‎‏‏‎ Non-scorable items',
    hideUnscorableTrueLabel: '‏‏‏‏‎ ‎‏‏‎ Non-scorable items',
    lastName: 'Last Name',
    maxScoreLabel: 'Max point value: {{maxScore}}',
    notAvailable: 'N/A',
    notAvailableDash: '—',
    scoreHeaderLabel: 'Score',
    slideLabel: 'Slide',
    summaryScore: 'Summary Score',
    surveyQuestionLabel: 'Survey question',
    view: 'View',
    viewOnly: 'View Only',
    viewedTime: 'Usage:',
    viewedTimeHeader: 'Usage'
  },
  GradebookHeader: {
    aggregateGradebook: 'All Assignments',
    assignButtonDisabledMessage: 'Select assignments to assign.',
    assignButtonLabel: 'Assign',
    bulkUpdateButtonDisabledMessage: 'Select assignments to edit.',
    bulkUpdateButtonLabel: 'Edit',
    details: 'Details',
    engagement: 'Engagement',
    export: 'Export',
    goToCourseLabel: 'Go To Course',
    standards: 'Standards',
    summary: 'Summary',
  },
  GradebookStandards: {
    errorMessage: 'Failure retrieving standards data.',
    noStandardsMessage: 'Standards data is not available for this assignment.',
    noStudentDataMessage: 'Will be able to see if at least one student submitted.',
  },
  GradebookTable: {
    activityNumber: 'Assignment',
    activityTimezoneEndDate: 'Due Date',
    activityTimezoneStartDate: 'Start Date',
    assignEntityTypeIdKey_classroom: 'C',
    assignEntityTypeIdKey_group: 'G',
    assignEntityTypeIdKey_teacher: 'T', // edge case, will likely never happen
    assignEntityTypeIdKey_user: 'I',
    assignEntityTypeIdVal_classroom: 'Class',
    assignEntityTypeIdVal_group: 'Group',
    assignEntityTypeIdVal_teacher: 'Teacher Only', // edge case, will likely never happen
    assignEntityTypeIdVal_user: 'Individual',
    assignedType: 'Type',
    assignmentProcessing: 'assignment processing',
    assignmentsProcessing: 'assignments processing',
    confirmButton: 'Okay',
    confirmContent_assignmentStatusUpdatedToClosed: 'This assignment status has been brought from a “Graded” status back to a “Closed” status to allow an item to be excluded or included. Once the item has been included or excluded, the assignment will move back to “Graded”.',
    confirmContent_assignmentStatusUpdatedToClosedForAggregate: 'This assignment status has been brought from a “Graded” status back to a “Closed” status to allow an item to be excluded or included. Once the item has been included or excluded, the assignment will move back to “Graded”.',
    confirmHeader_assignmentStatusUpdatedToClosed: 'Assignment Status Updated',
    copyAssignFailureText: 'There was a problem copying URL',
    copyAssignSuccessText: 'The Assignment URL has been copied to your clipboard.',
    copyAssignUrlText: 'Copy Assignment URL',
    editButtonText: 'Edit for Student',
    editForClassButtonText: 'Edit for Class',
    editForGroupButtonText: 'Edit for Group',
    feedback: 'Feedback',
    feedbackDescription: 'Students are not taking advantage of the feedback supports (Hints, Try Again, Show Correct Answer, and Sample Answer).',
    firstName: 'First Name',
    grade: 'Grade',
    includeInReports: 'Grades to Reports',
    lastName: 'Last Name',
    lateIconText: 'Assignment was submitted after original due date.',
    points: 'Points:',
    reassignLabel: 'Reassign',
    scoreButtonLabel: 'Score',
    scoresReleased: 'Grades to Students',
    status: 'Assignment Status',
    studentNotStarted: 'Not Started',
    studentReview: 'Students can Review',
    studentStarted: 'Started',
    studentStatus: 'Student Status',
    studentSubmitted: 'Submitted',
    studentVersion: 'Student Work',
    submitLabel: 'Submit',
    submitted: 'Submitted',
    timeOnTaskRatioDescription: 'Students are likely rushing; they are working quickly and have low performance.',
    unsubmitLabel: 'Unsubmit'
  },
  GroupResourceSelector: {
    loading: 'Loading...'
  },
  GroupRosterTable: {
    alertDeleteError: 'You cannot delete this group because it has active assignments attached to it. Please remove assignments to delete this group.',
    askRemoveGroup: 'Are you sure you want to delete this group? This will be permanently removed from the Group Archived list and cannot be undone.',
    deleteGroupButtonText: 'Permanently Delete',
    editGroupButtonText: 'Edit Group',
    filterBy: 'Filter by:',
    groupFilterDefaultText: 'All Groups',
    groupFilterSelectedText: ' group(s) selected',
    noDataText: ' No data to display',
    reactivateGroupButtonText: 'Reactivate'
  },
  IndividualStudentPerformance: {
    assignmentsColLabel: 'Assignments',
    classAverageColLabel: 'Class',
    contentColLabel: 'Content'
  },
  InfoModal: {
    ContentHeader: 'Content Description',
    WhenToUse: 'Instructional Strategy'
  },
  InitService: {
    locale: 'en-us'
  },
  ItemBankFilter: {
    filterStandardsAndTagsDescription: 'Select options below to filter by Standards and Tags.',
    resetFilters: 'Reset Filters'
  },
  ItemBankViewModal: {
    detailsOptionLabel: 'Info',
    itemDetailsModalTitle: 'Item Info',
    previewOptionLabel: 'Preview',
    usageOptionLabel: 'Usage'
  },
  JoinClassModal: {
    accessCode: 'Access Code',
    joinTitle: 'Join Class'
  },
  LearnosityPlayerModal: {
    hasNoContent: 'To view the assessment, content needs to be created in the assessment.'
  },
  LearnosityPrintPreview: {
    assignmentsLabel: 'Assignments',
    gradebookLabel: 'Gradebook'
  },
  LearnosityScoringModal: {
    gradebookLabel: 'Gradebook'
  },
  LibraryAssessmentBankViewModal: {
    addNewAssessment: 'Add',
    addNewUnit: 'Create New',
    afterCopyCancelButton: 'Continue',
    afterCopyConfirmButton: 'Go to My Drafts',
    afterCopyMsg: 'Assessments copied from Live appear in My Drafts. To edit the copy select Go to My Drafts, select Continue to continue to work within the Collection. ',
    afterCopyTitle: 'Go to Drafts',
    copyResourcetitle: 'Edit by Copy',
    copyTitle: 'Copy to Drafts',
    editAssessmentUnavailableMsg: 'Editing this is not allowed because the assessment is used in a live collection.  Create a copy of this assessment to make changes.',
    editDetailsLabel: 'Edit Details',
    editResourceUnavailableMsg: 'This resource is not editable',
    editUnavailableCancelButton: 'Cancel',
    editUnavailableTitle: 'Edit',
    liveEditingBannerMsg: 'You are live editing an Assessment Collection.  Teachers using this collection will see your changes immediately.',
    noAssessmentsMsg: 'There are no assessments in this unit.',
    unitLabel: 'unit',
    zeroStateMsg: 'There is no content for this Assessment Collection.'
  },
  LibraryAssessmentShopModal: {
    confirmCopyPrefix: 'Completing this action will add a copy of',
    confirmCopySuffix: 'to My Drafts.',
    confirmCopyTitle: 'Copy',
    copyOptionLabel: 'Copy',
    detailsOptionLabel: 'Details',
    editOptionLabel: 'Edit',
    itemUsageExportLabel: 'Item Usage Report',
    myDraftsLabel: 'My Drafts',
    noData: 'No results to display for the current selections',
    openOptionLabel: 'Open',
    previewOptionLabel: 'Preview',
    removeFromBankCancelButton: 'Cancel',
    removeFromBankConfirmButton: 'Remove',
    removeFromBankMessagePrefix: 'Completing this action will remove',
    removeFromBankMessageSuffix: 'from this Collection.  Would you like to complete this action?',
    removeFromBankOptionLabel: 'Remove from Collection',
    searchLabel: 'Search',
    sharedDraftsLabel: 'Shared Drafts',
    tagsAndStandardsLabel: 'Tags and Standards',
  },
  LibraryContentUsageModal: {
    modalCloseLabel: 'Close',
    modalDescriptionText: 'This item has been used by the following users:',
    modalTitle: 'Item Usage',
    noResultsMessage: 'This item has not been used by other users.'
  },
  LibraryManager: {
    assessmentBankCardLabel: 'Collection',
    assessmentCardLabel: 'Assessment',
    courseCardLabel: 'Course',
    imageAssetNameAssessment: 'Assessment-image.png',
    imageAssetNameAssessmentBank: 'Assessment-Folders-image.png',
    resourceBankCardLabel: 'Resource Bank'
  },
  LibraryResourceCard: {
    classroomsBannerLabel: 'Used in Class(es)',
    isLiveLabel: 'Live',
    isLiveLabelAssessment: 'Used in Live Collection',
    isLiveLabelAssessmentTeacher: 'Assignable',
    liveAssessmentInfoPopupMsg: 'To assign this content go to Custom Content in Courses and select the assessment.',
    updatingToLiveLabel: 'Updating to Live',
    updatingToLiveMessageSfx: 'is locked while updating to Live.',
    updatingToLiveTeacherLabel: 'Assigning...'
  },
  LibraryResourceDetailsModal: {
    closeButtonLabel: 'Close',
    licensesLabelText: 'License(s)',
    loading: 'Loading...',
    unknown: 'UNKNOWN'
  },
  LibraryShareAssessmentUsersModal: {
    shareButtonLabel: 'Share',
    tableHeaderText: 'Select users you want to share this assessment with. '
  },
  LibraryStandardsTagsModal: {
    tagsAndStandardsModalTitle: 'Standards and Tags',
  },
  Login: {
    buttonExternalPurchaseLabel: 'Purchase Courses Now',
    buttonSignInLabel: 'Sign In',
    buttonSignInSsoClassLinkLabel: 'Sign-in with ClassLink',
    buttonSignInSsoCleverLabel: 'Sign-in with Clever',
    buttonSignInSsoEdLinkLabel: 'Sign-in through your LMS',
    buttonSignUpLabel: 'Register',
    forgotPassword: 'Forgot Password',
    helpAndQuestions: 'Help & Questions',
    loginHeader: 'Sign In',
    password: 'Password',
    registerButtonExplanation: 'Accessing a course for the first time?',
    username: 'Username/email'
  },
  MainView: {
    expiredLicenseCautionBannerMsg: 'The license for one or more of your products has expired; this will limit your access. Contact your administrator for assistance.',
    helpStudent: 'Help',
    helpTeacher: 'Support',
    notificationCautionBannerMsg: 'Notification banner message that displays below top nav.',
    readOnlyGradebookCautionBannerMsg: 'As an Administrator, the gradebook is read only. To make changes, consult with the teacher or switch roles if you also have teacher permissions.'
  },
  MigrateStudentDataModal: {
    migrateDataLabel: 'Migrate Data',
    modalCloseLabel: 'Cancel',
    modalDescriptionText: 'This item has been used by the following users:',
    modalTitle: 'Item Usage',
    noResultsMessage: 'This item has not been used by other users.'
  },
  NavigationManager: {
    defaultBreadcrumbLabel: 'Classes'
  },
  NavigationService: {
    InitialBreadcrumb: 'Book',
    InitialBreadcrumb_plural: 'Books',
    aggregateGradebook: 'All Assignments',
    courseReport: 'Course Report',
    defaultBreadcrumbLabel: 'Classes',
    gradebookLabel: 'Gradebook',
    standardsReport: 'Standards Report'
  },
  NotificationsView: {
    manualNotifications: 'Messages ({{count}})',
    notificationsHeaderLabel: 'Notifications',
    systemNotifications: 'Notifications ({{count}})'
  },
  OfflineMessageBox: {
    offlineBoxBodyTextBottom: 'Please try again later.',
    offlineBoxBodyTextTop: 'Sorry, our system is currently down.',
    offlineBoxHeaderTitle: 'Sign In'
  },
  ProductDropdown: {
    allProducts: 'All Products',
    loading: 'Loading...',
    selectProduct: 'Select Product'
  },
  Profile: {
    firstName: 'First Name',
    lastName: 'Last Name',
    username: 'Username/email'
  },
  ProfileMenu: {
    ariaProfileMenuClosedInstructions: 'Press Enter to open Account Menu. Once open, press Tab or Shift+Tab to navigate to a menu item, then press Enter to select.',
    ariaProfileMenuDropdownLabel: 'Account Menu',
    ariaProfileMenuOpenInstructions: 'Press Tab or Shift+Tab to navigate to a menu item, then press Enter to select.'
  },
  ProgressView: {
    BannerTitle: 'Progress',
    CourseProgressLabel: 'Course Progress',
    SummaryLabel: 'Summary (Overall)',
    TrophiesLabel: 'Trophies Earned',
    WeeklyPerformanceLabel: 'Weekly Performance',
    achievement: 'Achievement',
    achievementHigh: 'High',
    achievementLow: 'Low',
    achievementMedium: 'Medium',
    chaptersTitle: 'Chapters In Progress or Complete',
    content: 'Content',
    feedback: 'Feedback',
    highText: 'High',
    lowText: 'Low',
    mediumText: 'Medium',
    noScoresYet: 'No scores yet',
    score: 'Score',
    trophy: 'Trophy'
  },
  ReactivateClassModal: {
    title: 'Update Class End Date to Reactivate'
  },
  Register: {
    accessCode: 'Access Code',
    accessCodePlaceholder: 'x-digits',
    confirmPassword: 'Confirm password',
    confirmPasswordLabel: 'Confirm your password',
    enterPassword: 'Enter password',
    firstName: 'First Name',
    homepage: 'Take me to my home page',
    lastName: 'Last Name',
    passwordLabel: 'Enter your password',
    passwordsNote: 'Password must be at least 8 characters and cannot be all spaces.',
    registration: 'Self-Registration',
    studentRegisterSuccessMessage: 'Registration complete, check your email to verify your account.',
    teacherRegisterSuccessMessage: 'Registration complete, check your email to verify your account.',
    username: 'Username/email'
  },
  RegisterEcommerce: {
    accessCode: 'Access Code',
    accessCodePlaceholder: 'x-digits',
    confirmPassword: 'Confirm password',
    confirmPasswordLabel: 'Confirm your password',
    enterPassword: 'Enter password',
    firstName: 'First Name',
    homepage: 'Take me to my home page',
    lastName: 'Last Name',
    passwordLabel: 'Enter your password',
    passwordsNote: 'Passwords are required to be at least 8 characters long.',
    registration: 'Self-Registration',
    studentAccessCode: 'Student Access Code',
    studentConfirmPassword: 'Confirm password',
    studentConfirmPasswordLabel: 'Confirm Student Password',
    studentEnterPassword: 'Enter Student Password',
    studentFirstName: 'Student First Name',
    studentLastName: 'Student Last Name',
    studentPasswordLabel: 'Enter Student Password',
    studentRegisterSuccessMessage: 'Registration complete, check your email to verify your account.',
    studentUsername: 'Student Username/email',
    submit: 'Submit',
    teacherRegisterSuccessMessage: 'Registration complete, check your email to verify your account.',
    username: 'Username/email'
  },
  RegisterInvite: {
    confirmPassword: 'Confirm password',
    confirmPasswordLabel: 'Confirm your password',
    enterPassword: 'Enter password',
    firstName: 'First Name',
    homepage: 'Take me to my home page',
    lastName: 'Last Name',
    passwordLabel: 'Enter your password',
    passwordsNote: 'Password must be at least 8 characters and cannot be all spaces.',
    registration: 'Registration',
    username: 'Username/email'
  },
  RegisterStudentModal: {
    accessCode: 'Access Code',
    emailLabel: 'Student Username/ email'
  },
  ReportAttributeFiltersDropdown: {
    allStandards: 'All Standards',
    clusters: 'Clusters and Subclusters',
    filterBy: 'Filter by:',
    readinessAndSupporting: 'Readiness and Supporting',
    reportingCategory: 'Reporting Category'
  },
  ReportAverageResultCell: {
    dashText: 'No score yet'
  },
  ReportBreadcrumbService: {
    '/reports': 'Reports',
    '/reports/course-classroom-detail': 'Class Detail (By Student)',
    '/reports/course-classroom-individual': 'Student', // fallback placeholder if missing student name (should not happen)
    '/reports/course-classroom-summary': 'Class Summary',
    '/reports/course-district-detail': 'District Detail (By School)',
    '/reports/course-district-summary': 'District Summary',
    '/reports/course-school-detail': 'School Detail (By Class)',
    '/reports/course-school-summary': 'School Summary',
    '/reports/standards-classroom-detail': 'Class Detail (By Student)',
    '/reports/standards-classroom-detail-students': 'Student Details', // fallback placeholder if missing truncatedStandardDescription
    '/reports/standards-classroom-individual': 'Student', // fallback placeholder if missing student name (should not happen)
    '/reports/standards-classroom-summary': 'Class Summary',
    '/reports/standards-district-detail': 'District Detail (By School)',
    '/reports/standards-district-summary': 'District Summary',
    '/reports/standards-school-detail': 'School Detail (By Class)',
    '/reports/standards-school-summary': 'School Summary',
    'classrooms': 'Classes',
    'courseReport': 'Course Report',
    'schools': 'Schools',
    'standardsReport': 'Standards Report'
  },
  ReportCaption: {
    '/reports': 'Reports',
    '/reports/course-classroom-detail': 'Course Report - Class Detail (By Student)',
    '/reports/course-classroom-individual': 'Course Report - {{studentFullName}}',
    '/reports/course-classroom-summary': 'Course Report - Class Summary',
    '/reports/course-district-detail': 'Course Report - District Detail (By School)',
    '/reports/course-district-summary': 'Course Report - District Summary',
    '/reports/course-school-detail': 'Course Report - School Detail (By Class)',
    '/reports/course-school-summary': 'Course Report - School Summary',
    '/reports/standards-classroom-detail': 'Standards Report - Class Detail (By Student)',
    '/reports/standards-classroom-detail-students': 'Standards Report - Student Details',
    '/reports/standards-classroom-individual': 'Standards Report - {{studentFullName}}',
    '/reports/standards-classroom-summary': 'Standards Report - Class Summary',
    '/reports/standards-district-detail': 'Standards Report - District Detail (By School)',
    '/reports/standards-district-summary': 'Standards Report - District Summary',
    '/reports/standards-school-detail': 'Standards Report - School Detail (By Class)',
    '/reports/standards-school-summary': 'Standards Report - School Summary',
    'classroom': 'Class',
    'classroomInfoMessage': 'Teacher reports refresh twice weekly and are available on Thursday and Saturday mornings. Note that teacher assignment scorecard reports are available in real time, including performance scores, item analysis, engagement, and standards raw data.',
    'district': 'District',
    'districtInfoMessage': 'District Administrator reports refresh twice weekly and are available on Saturday and Monday mornings. Note that teacher assignment scorecard reports are available in real time, including performance scores, item analysis, engagement, and standards raw data.',
    'lastModified': 'Last Modified',
    'lastModifiedPopupTrigger': '(Availability)',
    'leadTeacherName': 'Teacher',
    'modifiedContentInfo': 'Standards reports may not include data from the last 24 hours.',
    'school': 'School',
    'schoolInfoMessage': 'School Administrator reports refresh twice weekly and are available on Friday and Sunday mornings. Note that teacher assignment scorecard reports are available in real time, including performance scores, item analysis, engagement, and standards raw data.',
    'standardsDocument': 'Standards Document',
    'unknownLastModified': 'Unknown',
    'unknownLeadTeacherName': 'Unknown'
  },
  ReportCellValueToggler: {
    engagementLabel: 'Engagement Numbers',
    progressScoreLabel: 'Progress Numbers'
  },
  ReportContentWithoutScoresToggler: {
    hideContentWithoutScoresFalseLabel: '‏‏‏‏‎ ‎‏‏‎ Content without grades',
    hideContentWithoutScoresTrueLabel: '‏‏‏‏‎ ‎‏‏‎ Content without grades'
  },
  ReportContextSwitcher: {
    engagement: 'Engagement',
    growth: 'Progress (Beta)',
    itemLevel: 'Item Level Scores',
    performance: 'Performance',
    progressInfo: 'This report is based on the most recent data released to reports and requires at least (4) scored assignments covering the same standard(s).',
    resourceLevel: 'Assignment Level Scores',
    usage: 'Usage (minutes)',
  },
  ReportCourseDropdown: {
    course: 'Course:',
    noCoursesToDisplay: 'No courses to display',
    selectCourse: 'Select Course'
  },
  ReportDownloadButtons: {
    export: 'Export',
    exportModalBannerLabel: 'Export｛{{resourceName}}｝to CSV',
    print: 'Print',
    reportTableRowResourceExportButtonDescription: 'Export scorecard details for all students at your campus who have completed this assignment'
  },
  ReportEngagementFilter: {
    // TODO remove
    // feedbackDescription: 'Students are not taking advantage of the feedback supports (Hints, Try Again, Show Correct Answer, and Sample Answer).',
    // feedbackLabel: 'Feedback Use',
    // speedDescription: 'Students are likely rushing; they are working quickly and have low performance.',
    // speedLabel: 'Speed Warning',
    highEngagementDescription: '≥ 4.0',
    highEngagementHeaderDescription: 'Engagement score is greater than or equal to 4.0. Additional details are coming soon.',
    highEngagementLabel: 'High',
    lowEngagementDescription: '< 4.0',
    lowEngagementHeaderDescription: 'Engagement score is less than 4.0. Additional details are coming soon.',
    lowEngagementLabel: 'Low'
  },
  ReportEngagementService: {
    engagementFeedbackUsageSubscoreLabel: 'Feedback Usage',
    engagementFeedbackUsageSubscoreValue: '{{value}} pts.',
    engagementMiscSubscoreLabel: 'Resource, Annotation, Language Usage',
    engagementMiscSubscoreValue: '{{value}} pts.',
    engagementTimeOnTaskSubscoreLabel: 'Time on Task Ratio',
    engagementTimeOnTaskSubscoreValue: '{{value}} pts.'
  },
  ReportEnhancedUsageColorKeyCard: {
    backgroundColorsOnly: 'Colors',
    close: 'Close',
    lessTimeHighPerformance: 'Less Time/Meets or Masters',
    lessTimeHighPerformanceDescriptionBody: 'This is the description for Less Time/Meets or Masters.<p>Additional details are coming soon.</p>',
    lessTimeHighPerformanceDescriptionHeader: 'Less Time/Meets or Masters',
    lessTimeLowPerformance: 'Less Time/Does Not Meet',
    lessTimeLowPerformanceDescriptionBody: 'This is the description for Less Time/Does Not Meet.<p>Additional details are coming soon.</p>',
    lessTimeLowPerformanceDescriptionHeader: 'Less Time/Does Not Meet',
    minutesAndColors: 'All',
    minutesOnly: 'Minutes',
    moreTimeHighPerformance: 'More Time/Meets or Masters',
    moreTimeHighPerformanceDescriptionBody: 'This is the description for More Time/Meets or Masters.<p>Additional details are coming soon.</p>',
    moreTimeHighPerformanceDescriptionHeader: 'More Time/Meets or Masters',
    moreTimeLowPerformance: 'More Time/Does Not Meet',
    moreTimeLowPerformanceDescriptionBody: 'This is the description for More Time/Does Not Meet.<p>Additional details are coming soon.</p>',
    moreTimeLowPerformanceDescriptionHeader: 'More Time/Does Not Meet',
    usageReportKey: 'Usage Report Key',
    usageReportKeyInfoDescription: 'Less Time or More Time on task is based on a comparison with the average.'
  },
  ReportFacultyResultCell: {
    dashText: 'No score yet',
    modified: 'The assignment was modified',
    points: 'Points:',
  },
  ReportGroupFilter: {
    groupSelectLabel: 'Student Group:',
    noGroups: 'No groups',
    selectGroup: 'Select Group'
  },
  ReportHideColumnsLauncherButton: {
    hideColumns: 'Hide Columns'
  },
  ReportHideColumnsModal: {
    classroomNameHeaderLabel: 'Teachers and Classrooms:',
    hideColumnsHeaderMsg: 'Hide Report Columns',
    resetAll: 'Reset All',
    schoolNameHeaderLabel: 'Schools:',
    selectAll: 'Select All',
    studentNameHeaderLabel: 'Students:',
    unknownFacultyName: 'Unknown',
    unknownLeadTeacher: 'Unknown'
  },
  ReportLessonFilterDropdown: {
    allLessons: 'All Content',
    clickbook: 'Clickbook Only',
    expertrack: 'ExperTrack Only',
    type: 'Type:'
  },
  ReportMoreFilters: {
    allAssignments: 'All',
    allContent: 'All',
    apply: 'Apply',
    assessment: 'Assessment',
    assignment: 'Assignment',
    content: 'Content',
    groupSelectLabel: 'By Group:',
    instruction: 'Instruction',
    moreFiltersNoneSelected: 'More Filters',
    moreFiltersOneSelected: '+{{selectedCount}} Filter',
    moreFiltersSomeSelected: '+{{selectedCount}} Filters',
    onlevel: 'On Level',
    practice: 'Practice',
    prerequisite: 'Prerequisite',
    reset: 'Reset All'
  },
  ReportProgressFilter: {
    growth: 'Growth shows the trend in student performance. More Growth is a positive trend and Less Growth is a negative trend. This is based on the most recent data released to reports and requires at least five (5) scored assignments.',
    performance: 'Performance levels are based on the STAAR test levels and cut scores.'
  },
  ReportScoreSummaryBarCell: {
    courseApproaching: 'Approaching',
    courseDeveloping: 'Developing',
    courseMastering: 'Mastering',
    courseMeeting: 'Meeting',
    courseNotEnoughData: 'Not Enough Data',
    standardsApproaching: 'Approaching',
    standardsDeveloping: 'Developing',
    standardsMastering: 'Mastering',
    standardsMeeting: 'Meeting',
    standardsNotEnoughData: 'Not Enough Data'
  },
  ReportStandardCard: {
    breakout: 'Breakout',
    weight: 'Weight:'
  },
  ReportStandardsClassroomDetailStudentsTable: {
    assignmentCount: 'Assignments',
    averageScore: 'Avg Score',
    firstName: 'First Name',
    lastName: 'Last Name',
    noDataFound: 'Sorry, no data found to display for this particular report.',
    notAvailable: 'N/A',
    notAvailableDash: '—'
  },
  ReportStandardsDropdown: {
    cmaps: 'Standards:',
    noCmapsToDisplay: 'No standards to display',
    selectCmap: 'Select Standard'
  },
  ReportTable: {
    assignments: 'Assignments',
    averageScore: 'Average Score',
    classroomAverage: 'Class Average',
    courseTableHeader: 'Content',
    descriptionForCollapseAllRows: 'Collapse all rows. This may take a moment, depending on the report data size.',
    descriptionForExpandAllRows: 'Expand all rows. This may take a moment, depending on the report data size.',
    details: 'Details',
    districtAverage: 'District Average',
    remaining: 'Remaining',
    schoolAverage: 'School Average',
    standardsTableHeader: 'Standards',
    taken: 'Taken',
    viewDetails: 'View',
    viewDetailsHeader: 'Details'
  },
  ReportTypeSelectorView: {
    course: 'Course',
    courseReport: 'Course Report',
    districtSummary: 'District Summary',
    loading: 'Loading...',
    reports: 'Reports',
    schoolSummary: 'School Summary',
    selectorZeroState: 'No {{status}} {{activeReportFacultyType}}s found.',
    showReportSampleButton: 'View Report Sample',
    standards: 'Standards',
    standardsReport: 'Standards Report',
    viewReport: 'View Report'
  },
  ReportView: {
    allAssignments: 'All Assignments'
  },
  Reports: {
    // TODO DEMO-1310 refactor
    // noDataLabel: 'No Data to display'
    // noDataLabel: 'Please make pulldown selections above. Standards reports may not include data from the last 24 hours.',
    // noDataLabelCourse: 'Be sure to release scores to reports in your assignments Scorecard in order to view a Course Report.'
  },
  ResourceBankViewModal: {
    detailsOptionLabel: 'Info',
    noResultsZeroStateMsg: 'No results found.',
    previewOptionLabel: 'Preview',
  },
  ResourceFilter: {
    filterStandardsAndTagsDescription: 'Select options below to filter by Standards and Tags.',
    filterStandardsAndTagsDescriptionShopping: '',
    resetFilters: 'Reset Filters',
    resourceBankLabel: 'Resource Bank'
  },
  ResourcePacingModal: {
    askConfirmReset: 'Are you sure you want to reset to publisher settings?',
    attempts: 'Attempts',
    availableUntil: 'Available Until',
    availableUntilDescription: 'Student will have until this date and time to complete the assignment.',
    cancel: 'Cancel',
    confirm: 'Confirm',
    days: 'Days',
    dueDate: 'Due Date',
    dueTime: 'Due Time',
    duration: 'Duration',
    durationDescription: 'Student will have this many days / hours to complete the assignment from the time they start the assignment.',
    errorMsgDays: '',
    errorMsgHeaderDays: 'Days must be at least 1 when no hours are set.',
    errorMsgHeaderHours: 'Hours must be less than 24',
    errorMsgHours: 'To set the duration as 24 hours or greater, you can use the Days field.',
    hiddenFromStudents: 'Hidden from Students',
    hiddenFromStudentsDescription: 'Resource will not appear in student view of course.',
    hours: 'Hours',
    instructions: 'Student Instructions (click to edit)',
    mode: 'Mode Option',
    pacing: 'Pacing',
    releaseToReportsLabel: 'Release to reports',
    reportsAndGrading: 'Reports & Grading',
    reportsAndGradingDescription: 'Toggles determine actions that happen after assignments are complete.',
    reset: 'Reset to Publisher Settings',
    save: 'Save',
    scoresReleased: 'Release grades to students',
    settings: 'Settings',
    studentInstructionPrompt: 'These are instructions that the teacher can give to students.',
    studentPaced: 'Student Paced',
    studentPacedDescription: 'Student may begin assignment at any time.',
    studentReview: 'Students can review work',
    teacherAssign: 'Teacher Assign',
    teacherAssignDescription: 'Only assignable by teacher.',
    timing: 'Timing',
    visibility: 'Visibility',
    visibleToStudents: 'Visible to Students',
    visibleToStudentsDescription: 'Resource will appear in student view of course.'
  },
  ResourcePacingToolbar: {
    assignedPrefix: 'Assignments',
    assignedSuffix: '',
    attemptsPrefix: 'Attempts:',
    attemptsSuffix: '/* note: computed via ResourcePacingService */',
    durationPrefix: 'Duration:',
    durationSuffix: '/* note: computed via ResourcePacingService */',
    hiddenFromStudentsPrefix: 'Visibility:',
    hiddenFromStudentsSuffix: 'Hidden from Students',
    manualScoringPrefix: 'Manual Grade:',
    manualScoringSuffix: 'Contains items / content that teacher must grade.',
    studentPacedPrefix: 'Pacing:',
    studentPacedSuffix: 'Student Paced',
    teacherAssignPrefix: 'Pacing:',
    teacherAssignSuffix: 'Teacher Assign',
    visibleToStudentsPrefix: 'Visibility:',
    visibleToStudentsSuffix: 'Visible to Students'
  },
  ResourceShopModal: {
    detailsOptionLabel: 'Details',
    headerTitle: 'Select Content',
    liveEditingBannerMsg: 'You are live editing an Assessment Collection.  Teachers using this collection will see your changes immediately.',
    noResultsZeroStateMsg: 'No results found.',
    previewOptionLabel: 'Preview',
    saveButtonLabel: 'Add',
    saveButtonLiveLabel: 'Add to Live',
    saveLiveEditDescriptionPrefix: 'You are about to add (',
    saveLiveEditDescriptionSuffix: ') resources to a live course. There is no way to edit or remove these resources once you push to live. Do you wish to proceed?',
    saveLiveEditTitle: 'Add to Live',
    selectedItemsLabel: 'Selections'
  },
  ResourcesView: {
    title: 'Help'
  },
  RoleSwitcherView: {
    district_admin_user: 'District Admin',
    district_product_admin: 'District Product Admin',
    enter: 'Enter',
    school_admin_user: 'School Admin',
    school_product_admin: 'School Product Admin',
    student_user: 'Student',
    teacher_user: 'Teacher'
  },
  SatCoreLoader: {
    loading: 'Loading'
  },
  ScoreAssignmentModal: {
    cancel: 'Cancel',
    grade: 'Grade:',
    save: 'Save',
    scorePercent: 'Score (Percent):',
    serverErrorMsg: 'Something went wrong when saving the grade, please try again',
    totalPointValueDescription: '*Total point value entered will apply globally for this assignment.'
  },
  SelectLessonProfileModal: {
    cancel: 'Cancel',
    save: 'Close',
    selectOption: 'Select option',
    selectOptionProfileDescription: 'Select a test template to view this item with.',
    selectPreviewMode: 'Preview Item'
  },
  SelectNewLibraryResourceModal: {
    assessmentBankLabel: 'Collection',
    assessmentLabel: 'Assessment',
    cancelButtonLabel: 'Cancel',
    createButtonLabel: 'Create',
    descriptionSubText: '{Description text goes here}',
    descriptionText: 'Choose Assessment to build a new test. Choose Collection to gather existing tests that you want to share with teachers.',
    modalLabel: 'Create New'
  },
  SelectNewResourceModal: {
    addExistingOptionLabel: 'Add Existing',
    cancelButtonLabel: 'Cancel',
    createButtonLabel: 'Add',
    createNewOptionLabel: 'Create New',
    descriptionSubText: '{Description text goes here}',
    descriptionText: 'Create new content or add existing',
    modalLabel: 'Add'
  },
  SelectPreviewModeModal: {
    cancel: 'Cancel',
    save: 'View',
    selectPreviewMode: 'Select Preview Mode'
  },
  ShareAssessmentModal: {
    cancelButtonLabel: 'Cancel',
    descriptionTextPrefix: 'This assessment will be shared with the users you selected.  Enter any additional information to be included with the shared assessment below. ',
    descriptionTextSuffix: '',
    editCheckLabel: 'Allow users to edit',
    editTextDescription: 'If this assessment is in Shared Drafts, users will not receive edit access through the share link, but they will still have the edit capability in Share Drafts. Teachers will not be able to edit.',
    editTextDescriptionLabel: 'Allow admin users to have edit access to this assessment',
    headerLabel: 'Share With',
    inputLabelSubText: 'Max characters 250',
    placeholderText: 'Enter text here',
    saveButtonLabel: 'Share',
    sharedMessageText: 'has been shared with you.'
  },
  ShowPasswordButton: {
    hidePassword: 'Hide Password',
    showPassword: 'Show Password'
  },
  StudentAccessCode: {
    studentAccessCode: 'Student Access Code'
  },
  StudentAssignmentCard: {
    course: 'Course: ',
    dueDate: 'Due Date:',
    dueTime: 'Due Time:',
    feedback: 'Feedback',
    grade: 'Grade:',
    lockedByTeacher: 'This assignment has been locked by your teacher.',
    notAvailable: 'n/a',
    notSubmitted: 'Not Submitted',
    submittedLabel: 'Submitted:',
    turnInByDate: 'Turn in late by:',
    waitingOnGrade: 'Waiting on Grade'
  },
  StudentAssignmentView: {
    DefaultSortOrder: 'asc',
    NoAssignmentsYet: 'No assignments yet',
    NoClosedAssignments: 'No closed assignments',
    NoCurrentAssignments: 'No current assignments',
    NoDueAssignments: 'No assignments due today',
    NoGradedAssignments: 'No graded assignments',
    todayIs: 'Today is'
  },
  StudentContentCardManager: {
    closedLockedMessage: 'You did not submit this assignment. Ask your teacher for details.',
    completedLockedMessage: 'Review is not available for this assignment.',
    continue: 'Continue',
    expiredLicenseButtonViewMsg: 'The license for this product has expired; viewing the product is unavailable.',
    grade: 'Grade:',
    locked: 'Locked',
    lockedByTeacher: 'This assignment has been locked by your teacher.',
    notAvailable: 'n/a',
    openNumberOfActivities: 'Open ({{numberOfActivities}})',
    review: 'Review',
    start: 'Start',
    startDateLabel: 'Start Date: ',
    startTimeLabel: 'Start Time: ',
    studentView: 'View',
    tryAgain: 'Try Again',
    turnInLate: 'Turn in Late',
    waitingOnGrade: 'Waiting on Grade',
  },
  StudentDashboard: {
    AssignmentLabel: 'My Assignments',
    BookLabel: 'My Book',
    NoAssignmentsYet: 'No assignments yet',
    WelcomeLabel: ' Welcome',
    myProgress: 'My Progress',
    open: 'Open'
  },
  StudentFeedbackModal: {
    feedBack: 'Feedback'
  },
  StudentHelperService: {
    progressSummaryLegendHighLabel: 'High',
    progressSummaryLegendHighValue: '(> 75%)',
    progressSummaryLegendLowLabel: 'Low',
    progressSummaryLegendLowValue: '(< 50%)',
    progressSummaryLegendMediumLabel: 'Medium',
    progressSummaryLegendMediumValue: '(50-75%)'
  },
  StudentTopNav: {
    AssignmentLabel: 'My Assignments',
    AssignmentRollover: '',
    BookLabel: 'My Book',
    BookRollover: '',
    DashboardLabel: 'My Dashboard',
    DashboardRollover: '',
    ProgressLabel: 'My Progress',
    ProgressRollover: '',
    WorkspaceLabel: 'Workspace',
    WorkspaceRollover: '',
    joinClass: '+ Join Class',
    logout: 'Logout',
    optionTitle: 'Switch Classes',
    profile: 'Profile',
    switchRole: 'Switch Role'
  },
  SystemNotificationCard: {
    downloadAttachment: 'Download \'{{attachmentName}}\''
  },
  TeacherDashboard: {
    WelcomeLabel: 'Welcome',
    addClass: '+ Add Class',
    addJoinClass: '+ Add / Join Class',
    gradebookLabel: 'Gradebook',
    joinClass: '+ Join Class',
    myClasses: 'My Classes',
    noClassesFound: 'No classes were found.',
    noClassesFoundForSelectedInstitution: 'No classes were found for this institution.'
  },
  TeacherFeedbackModal: {
    cancel: 'Cancel',
    editDescription: '(click to edit)',
    feedBack: 'Feedback',
    ok: 'Ok',
    save: 'Save'
  },
  TeacherLibraryResourceDetailsModal: {
    classroomNames: 'Class(es)',
    courseContentItemId: 'Course ID',
    courseContentItemName: 'Course Name',
    licenseNames: 'License(s)',
    none: 'NONE',
    productCode: 'Product Code',
    unknown: 'UNKNOWN'
  },
  TeacherProductListener: {
    classroomSelectionModalBannerLabel: 'Add to Class',
    classroomSelectionModalBannerZeroStateLabel: 'No Classes to Add'
  },
  TeacherProductView: {
    noDataToDisplay: 'No results found.',
    searchCourseName: 'Search course name'
  },
  TeacherProductViewService: {
    courses: 'Courses',
    products: 'Products'
  },
  TeacherTestBuilderView: {
    assignDefaultCourseName: 'Custom Content',
    assignOptionDisabledMessage: 'There are no active classrooms with students to assign to.',
    assignOptionLabelAssessment: 'Assign',
    confirmAddAssignmentMsg: 'Assigning this content will also make this content part of your classroom content available under courses. Once the content is assigned you will no longer be able to make any edits.  Would you like to continue to assign this content?',
    confirmAddAssignmentTitle: 'Assign',
    confirmCopyPrefix: 'Completing this action will add a copy of',
    confirmCopySuffix: 'to My Drafts.',
    confirmCopyTitle: 'Copy',
    confirmDeletePrefix: 'Completing this action will delete',
    confirmDeleteSuffix: 'from all areas. This cannot be undone. Would you like to complete this action?',
    confirmDeleteTitle: 'Delete',
    copyOptionLabel: 'Copy',
    createNewButtonLabel: 'Create New',
    deleteOptionLabel: 'Delete',
    detailsOptionLabel: 'Edit Details',
    downloadOnlyModalMsg: 'This file is marked \'Uploaded File\' because it can only be downloaded. Below are a list of file types that can be embedded in lessons. Any other file type, like this one, will create a download link in your content. Once your audience downloads the file, they can open it on their computer. Video: MP4, WEBM Audio: MP3, WAV Images: GIF, JPEG, JPG, PNG Documents: DOCX, DOC, PDF, PPS, PPSX, PPT, PPTX, RTF ,TXT',
    downloadOnlyTitle: 'Download Only',
    editOptionLabelAssessment: 'Edit',
    editUnavailableCancelButton: 'Cancel',
    editUnavailableMsg: 'Editing this is not allowed because the assessment is used in a live collection.  Create a copy of this assessment to make changes.',
    editUnavailableTitle: 'Edit Unavailable',
    hasClasses: 'You cannot delete this resource because it has attached classes:  ',
    hasClassesTitle: 'Warning!',
    itemUsageExportLabel: 'Item Usage Report',
    libraryTitle: 'Test Builder',
    liveResourcesOnlyDescription: 'Live Assessment Collections are available to teachers.',
    myDraftsDescriptionTeacherDigital: 'In Test Builder you can build your own Assessments and assign them to classes.',
    myDraftsDescriptionTeacherPrint: 'In Test Builder you can build your own printable Assessments.',
    myDraftsLabel: 'My Drafts',
    myDraftsResourcesOnlyDescription: 'In My Drafts build your Collections and use options to share with other collaborators or share with your teachers by making your drafts live.',
    noData: 'No results to display for the current selections',
    noPreviewAvailableMsg: 'This resource cannot be previewed.',
    noPreviewAvailableTitle: 'No Preview Available',
    openOptionLabel: 'Open',
    previewOptionLabel: 'Preview',
    previewOptionLabelTeacher: 'Print',
    previewOptionLabelTeacherAnswers: 'Print (with answers)'
  },
  TopNav: {
    AssignmentLabel: 'Assignments',
    AssignmentRollover: '',
    DashboardLabel: 'Classes',
    DashboardRollover: '',
    ProductLabel: 'Products',
    ProductRollover: '',
    ReportLabel: 'Reports',
    ReportRollover: '',
    archivedClasses: 'Archived Classes',
    libraryContentLabel: 'Library',
    libraryContentRollover: '',
    logout: 'Logout',
    notificationsLabel: 'Notifications',
    profile: 'Profile',
    switchRole: 'Switch Role',
    testBuilderLabel: 'Test Builder',
    testBuilderRollover: ''
  },
  TrialBanner: {
    button: 'Enter Access Code',
    expired: 'Your Trial Has Expired',
    trial: 'You have {{count}} day left in your trial',
    trial_plural: 'You have {{count}} days left in your trial'
  },
  TrialEnterPurchaseCodesModal: {
    cancelButtonLabel: 'Cancel',
    codeError: 'The code is incorrect or has already been redeemed.',
    codeInputPlaceholder: 'Enter code',
    codeUniqError: 'The code is incorrect or has already been redeemed.',
    findAndPurchaseLabel: 'Find & Purchase',
    findAndPurchaseUrl: 'http://www.lablearning.com/Find-Course-Materials',
    generalError: 'Something has gone wrong.',
    modalTitle: 'Enter Your Access Code',
    submitButtonLabel: 'Submit'
  },
  TrialLauncherView: {
    dayVerbiage: 'day',
    daysVerbiage: 'days',
    invalidAccessCode: 'The provided Access Code is invalid, expired or unavailable.',
    trialLauncherBoxAccessCodeDescription: 'Please enter an Access Code to continue.',
    trialLauncherBoxAccessCodeInputPlaceholder: 'Enter Access Code Here',
    trialLauncherBoxAccessCodeTitle: 'Enter Access Code',
    trialLauncherBoxDescription: 'You have {{trialDaysRemaining}} {{dayVerbiage}} left in your trial.',
    trialLauncherBoxExpiredDescription: 'Please enter an Access Code to continue.',
    trialLauncherBoxExpiredTitle: 'Trial Expired',
    trialLauncherBoxTitle: 'Registration',
    trialLauncherBoxUnauthenticatedDescription: '<p>We were unable to validate your user credentials.</p><p>If this issue persists, please contact an administrator.</p>',
    trialLauncherBoxUnauthenticatedTitle: 'Unable to Validate'
  },
  UserInstitutionDropdown: {
    allInstitutions: 'All Institutions',
    selectInstitution: 'Select Institution'
  },
  Verification: {
    buttonLogin: 'Take me to login',
    buttonRetry: 'Retry Login',
    registration: 'Self-Registration'
  },
  ViewNotificationModal: {
    errorHeader: 'Notification not found',
    exportMsg: 'Export Message',
    exportReady: 'Export Ready',
    notificationErrorMsg: 'There was a problem retrieving the linked notification',
    sharedAssessment: 'Shared Assessment'
  },
  ViewSelection: {
    ARCHIVED_CLASSES: 'Archived Classes',
    ASSIGNMENTS: 'Assignments',
    BOOK: 'Content',
    CLASSES: 'Classes',
    CLASSROOMS: 'Classes',
    CLASS_PERFORMANCE: 'Class Performance',
    CONTENT: 'Content',
    COURSES: 'Courses',
    DASHBOARD: 'Dashboard',
    EDIT_STUDENT_PROFILE: 'Edit Student Profile',
    GRADEBOOK: 'Gradebook',
    GROUPS: 'Groups',
    LOGIN: 'Login',
    LOGIN_ONE_ROSTER: 'Login',
    NOTIFICATIONS: 'Notifications',
    PRODUCTS: 'Products',
    PROFILE: 'Profile',
    PROGRESS: 'Progress',
    REPORTS: 'Reports',
    RESOURCES: 'Resources',
    ROLE_SWITCHER: 'Role Switcher',
    TEST_BUILDER: 'Test Builder',
    USERS: 'Users',
    WORKSPACE: 'Workspace'
  },
  Workspace: {
    confirmDeleteCancelButtonLabel: 'Cancel',
    confirmDeleteMessage: 'Are you sure you want to remove this file?',
    confirmDeleteSubmitButtonLabel: 'Remove',
    confirmDeleteTitle: 'Remove File',
    workspaceTitle: 'Workspace'
  },
  WorkspaceFileModal: {
    addFile: 'Add File',
    cancelButtonLabel: 'Cancel',
    replaceFile: 'Replace File',
    submitButtonLabel: 'Ok'
  },
  WorkspaceRenameModal: {
    cancelButtonLabel: 'Cancel',
    modalTitle: 'Rename File',
    nameInputPlaceholder: 'Enter name',
    submitButtonLabel: 'Ok'
  },
  ZeroStateCard: {
    noDataToDisplay: 'No data to display',
    noManualNotificationsToDisplay: 'No messages available for this section.',
    noReportDataToDisplay: 'No data to display',
    noReportLastModified: 'Your first report will generate shortly. Please check back.',
    noReportLastModifiedClassroom: 'If there are any graded assignments for this report, it will generate shortly. Please check back.',
    noSystemNotificationsToDisplay: 'No notifications available for this section.',
    selectCourse: 'Select a course to display data',
    selectStandard: 'Select a standard to display data'
  }
};

export default translations;
