import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { Button, Message } from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';
import Modal from '../Modal';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import '../../css/WorkspaceFileModal.less';

const WorkspaceFileModal = observer((props) => {
  const { open, close, submit = true, id, classroomId, t } = props;
  const { contentItemManager } = useContext(MobXProviderContext);
  const [value, setValue] = useState([]);
  const [error, setError] = useState();
  const [submitted, setSubmitted] = useState(false);
  const ModalBanner = useMemo(() => SatCoreComponent('ModalBanner'), []);

  const upload = useCallback(async (files) => {
    return id ?
      contentItemManager.replace(id, { file: files }) :
      contentItemManager.upload({ workspaceId: classroomId, file: files });
  }, [contentItemManager]);

  const handleDrop = useCallback(async (files) => {
    if (!submit) {
      setSubmitted(true);
      const result = await upload(files);
      setSubmitted(false);
      !result && setError(t('generalError', 'Something has gone wrong.'));
    }
    id ? setValue(files) : setValue([...value, ...files]);
  }, [upload, value]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop: handleDrop, multiple: !id});

  const valid = useMemo(() => value.length > 0, [value]);
  const handleSubmit = useCallback(async () => {
    if (!valid) {
      return;
    }
    setSubmitted(true);
    const result = await upload(value);
    result ? close() : setSubmitted(false) || setError(t('generalError', 'Something has gone wrong.'));
  }, [upload, valid, value]);

  return (
    <Modal
      className='workspace-modal'
      closeOnDimmerClick={false}
      closeOnEscape={true}
      onClose={close}
      open={open}
      size='tiny'>
      <ModalBanner onClose={close} title={id ? t('replaceFile', 'Replace File') : t('addFile', 'Add File')} />
      <Modal.Content>
        <div className='workspace-modal-description'>Select or drag and drop files you would like to upload</div>
        <div className={`drop-zone${isDragActive ? ' active' : ''}`} {...getRootProps()}>
          <div>
            <p className='drop-zone-title'>+Add Files</p>
            <p className='drop-zone-subtitle'>Drop files here to upload</p>
          </div>
        </div>

        <div className='file-field' style={submit ? {} : {justifyContent: 'space-between'}}>
          <label className='ui button basic primary' htmlFor='file'>Select Files...</label>
          <input id='file' {...getInputProps()} />
          {submit && <div className='files'>{value.map(({ name }, index) => <div key={index}>{name}</div>)}</div>}
          {!submit && submitted && <div className='status'>Uploading</div>}
        </div>

        {!submit && <div className='uploaded-files'>
          {value.map(({ name }, index) => <div key={index}><span>{name}</span><span>Upload Complete</span></div>)}
        </div>}

        {error && <Message content={error} error visible={error !== null} />}
      </Modal.Content>
      <Modal.Actions>
        {submit && <Button basic className='cancelButton' onClick={close} primary>{t('cancelButtonLabel', 'Cancel')}</Button>}
        <Button disabled={submit && (submitted || !valid)} onClick={submit ? handleSubmit : close} primary>{t('submitButtonLabel', 'Ok')}</Button>
      </Modal.Actions>
    </Modal>
  );
});

export default WorkspaceFileModal;
SatCoreRegister('WorkspaceFileModal', WorkspaceFileModal);
