import React from 'react';
import { SatCoreRegister } from '../SatCoreRegistry';
import '../css/DotMenu.less';

const DotMenu = (props) => (
  <div className='dot-menu' onClick={props.clickMenu}>
    <div className='dot' />
    <div className='dot' />
    <div className='dot' />
  </div>
);

export default DotMenu;

SatCoreRegister('DotMenu', DotMenu);
