/* eslint-disable sort-keys */
import React from 'react';

import defaultAvatar from '../../img/avatars/default-avatar.png';

import { getRegisteredClass } from '../../SatCoreRegistry';

import { getInitials } from '../../utils';

import groupsManager from '../../managers/GroupsManager';
import reportIdentityManager from '../../managers/reports/ReportIdentityManager';
import reportTableManager from '../../managers/reports/ReportTableManager';
import userManager from '../../managers/UserManager';

import PopupService from '../PopupService';
import ReportJsonHelperService from './ReportJsonHelperService';

import Avatar from '../../components/Avatar';

export default class ReportCourseClassroomService {
  /** Intended to be called via `ReportTypeService.initReportTableFacultyCellConfigArray` */
  static _initReportTableFacultyCellConfigArray = (initProps) => {
    const { handleViewNextReport, renderFacultyResultCell } = initProps;
    const { isTableIndividualReport, reportInfoClassNames } = reportIdentityManager;

    let students = [];
    if (isTableIndividualReport) {
      const urlParams = new URLSearchParams(window.location.search);

      const currentStudent = ReportJsonHelperService.REPORT_STUDENTS().find((student) => {
        return student.studentId === urlParams.get('studentId');
      });
      if (currentStudent) {
        students = [currentStudent];
      }
    } else {
      students = ReportJsonHelperService.REPORT_STUDENTS();
    }

    const facultyCells = students.map((student) => {
      const { studentId } = student;
      return {
        Cell: (props) => {
          return renderFacultyResultCell({ ...initProps, ...props, student });
        },
        Header: (props) => {
          return this.renderClassroomHeaderScoreCell({
            ...initProps,
            ...props,
            handleViewNextReport,
            renderFacultyResultCell,
            student
          });
        },
        accessor: (props) => {
          const { elementId } = props;
          const allScoreInfo = ReportJsonHelperService.REPORT_SCORE_INFO_BY_ELEMENT_FOR_STUDENTS();
          const scoreInfoObj = allScoreInfo[elementId] && allScoreInfo[elementId][studentId];
          return scoreInfoObj;

          // TODO remove
          // // TODO this is mock data - we will need to use BE data once we have it
          // let rawNum = _.random(0, 12, true);
          // // TODO this mocks some empty cells - remove once we have real BE data
          // rawNum = rawNum <= 10 ? rawNum : undefined;

          // // round to 1 decimal point max; do not include decimal if first decimal digit is 0
          // const engagementScore = rawNum ? Number(rawNum.toFixed(1)) : undefined;

          // // TODO - engagementScore is mock data, need to replace with real BE Data
          // return {
          //   ...scoreInfoObj || {},
          //   engagementData: {
          //     ...scoreInfoObj?.engagementData || {},
          //     engagementScore // TODO remove, this is mock data
          //   }
          // };
        },
        className: `cell-faculty-result ${reportInfoClassNames}`,
        id: studentId,
        originalData: student
      };
    });
    reportTableManager.setReportTableFacultyCellsConfig(facultyCells);
    return facultyCells;
  };

  /**
   * @param {{
   *   student: import('./ReportJsonHelperService').ReportStudent,
   * }} props
   */
  static renderClassroomHeaderScoreCell = (props) => {
    const { student } = props;

    const { isTableIndividualReport, reportInfoClassNames } = reportIdentityManager;

    const UserService = getRegisteredClass('UserService');
    const studentFullName = UserService.getUserFullNameStr(student);

    const groups = groupsManager.memberMap[student.studentId];
    let groupList = '';
    if (groups) {
      const groupNames = groups.map((group) => group.name);
      groupList = `Group(s): ${groupNames.join(', ')}`;
    }

    const popupLabel = PopupService.renderPopup({
      className: `${reportInfoClassNames} report-table-header-popup`,
      content: (
        <div className={`header-cell-faculty-result-content ${reportInfoClassNames}`}>
          <div>{studentFullName}</div>
          <div>{groupList}</div>
        </div>
      ),
      offset: [10, 0],
      on: 'hover',
      pinned: true,
      position: 'bottom right',
      trigger: isTableIndividualReport ? studentFullName : getInitials(studentFullName)
    });

    const urlParams = new URLSearchParams(window.location.search);
    const classroomId = urlParams.get('classroomId');
    const classroomStatus = urlParams.get('classroomStatus');
    const institutionId = urlParams.get('institutionId');
    const institutionName = urlParams.get('institutionName');

    const imageUrl = userManager.getFullProfileImageUrl(student.profileImage);
    return (
      <div className={`faculty-avatar-wrapper ${reportInfoClassNames}`}
        onClick={() => !isTableIndividualReport && props.handleViewNextReport({
          classroomId,
          classroomStatus,
          institutionId,
          institutionName,
          studentFullName,
          studentId: student.studentId
        })}>
        {!isTableIndividualReport && (
          <Avatar className='faculty-avatar' defaultImage={defaultAvatar} fullProfileImageUrl={imageUrl} />)}
        <div className='faculty-avatar-label'>
          {popupLabel}
        </div>
      </div>
    );
  }
}
