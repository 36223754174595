import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Dropdown } from 'semantic-ui-react';

import '../css/ClassroomDropdown.less';

import { SatCoreRegister } from '../SatCoreRegistry';

const ClassroomDropdown = observer((props = {}) => {
  const { loading, options, t } = props;

  const { classroomManager } = useContext(MobXProviderContext);

  const [selectedClassroomId, setSelectedClassroomId] = useState(classroomManager.currentClassroomId);

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  useEffect(() => {
    (async () => {
      // placeholder
    })();
  }, []);

  const handleChangeClassroomDropdown = (event, data) => {
    const classroomId = data.value;

    setSelectedClassroomId(classroomId);

    if (props.onChange) {
      const option = data.options.find((option) => option.value === classroomId);
      props.onChange(event, option);
    }
  };

  const renderClassroomDropdown = () => {
    return (
      <div className='classroom-dropdown'>
        <Dropdown
          disabled={loading}
          onChange={handleChangeClassroomDropdown}
          options={options}
          placeholder={loading ? t('loading') : t('selectClassroom')}
          selectOnNavigation={false}
          selection
          value={selectedClassroomId} />
      </div>
    );
  };
  return renderClassroomDropdown();
});
export default ClassroomDropdown;

SatCoreRegister('ClassroomDropdown', ClassroomDropdown);
