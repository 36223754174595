import React from 'react';
import { SatCoreRegister, TeacherDashboard } from 'sat-core';

import starCenterLogo from '../img/si-star-center-logo.png';

const TeacherDashboardOverride = (props) => {
  return (
    <>
      <div className='teacher-banner-container'>
        <img alt='Star Center Logo' className='teacher-banner-logo' src={starCenterLogo} />
        <span className='teacher-banner-text'>2024 - 2025 S²TaR Center</span>
        <img alt='Star Center Logo' className='teacher-banner-logo' src={starCenterLogo} />
      </div>
      <TeacherDashboard {...props} />
    </>
  );
};

SatCoreRegister('TeacherDashboard', TeacherDashboardOverride);
