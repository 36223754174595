import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

// import { register } from '../../../i18n';

import EngagementService from '../../../services/EngagementService';

const EngagementRowAuxiliaryFeedbackUsageCells = observer((props) => {
  const { engagementData } = props;

  const { /* managers */ } = useContext(MobXProviderContext);

  // const t = register('EngagementTable');

  const renderEngagementRowAuxiliaryFeedbackUsageCells = () => {
    return (
      <>
        {renderEngagementRowAuxiliaryFeedbackUsageCell({
          allowPopupComponent: true,
          className: 'engagement-body-label detail-feedback-usage-body engagedCorrectOnFirstAttempt',
          popupContent: '',
          popupDisabled: true,
          popupTrigger: `${engagementData.correctCount || 0}/${engagementData.totalCount || 0}`
        })}
        {EngagementService.allowEngagementColumn('tryAgainAvailable') &&
          renderEngagementRowAuxiliaryFeedbackUsageCell({
            allowPopupComponent: true,
            className: 'engagement-body-label detail-feedback-usage-body engagedTryAgainUsage',
            popupContent: '',
            popupDisabled: true,
            popupTrigger: `${engagementData.tryAgainCount || 0}/${engagementData.tryAgainAvailable || 0}`
          })}
        {EngagementService.allowEngagementColumn('showHintAvailable') &&
          renderEngagementRowAuxiliaryFeedbackUsageCell({
            allowPopupComponent: true,
            className: 'engagement-body-label detail-feedback-usage-body engagedHintUsage',
            popupContent: '',
            popupDisabled: true,
            popupTrigger: `${engagementData.showHintCount || 0}/${engagementData.showHintAvailable || 0}`
          })}
        {EngagementService.allowEngagementColumn('showSolutionAvailable') &&
          renderEngagementRowAuxiliaryFeedbackUsageCell({
            allowPopupComponent: true,
            className: 'engagement-body-label detail-feedback-usage-header engagedSampleAnswerUsage',
            popupContent: '',
            popupDisabled: true,
            popupTrigger: `${engagementData.showSolutionCount || 0}/${engagementData.showSolutionAvailable || 0}`
          })}
        {EngagementService.allowEngagementColumn('showAnswerAvailable') &&
          renderEngagementRowAuxiliaryFeedbackUsageCell({
            allowPopupComponent: true,
            className: 'engagement-body-label detail-feedback-usage-header engagedShowCorrectAnswerUsage',
            popupContent: '',
            popupDisabled: true,
            popupTrigger: `${engagementData.showAnswerCount || 0}/${engagementData.showAnswerAvailable || 0}`
          })}
      </>
    );
  };

  const renderEngagementRowAuxiliaryFeedbackUsageCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementRowAuxiliaryFeedbackUsageCells();
});
export default EngagementRowAuxiliaryFeedbackUsageCells;

SatCoreRegister('EngagementRowAuxiliaryFeedbackUsageCells', EngagementRowAuxiliaryFeedbackUsageCells);
