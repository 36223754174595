import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { SatCoreComponent } from '../SatCoreRegistry';
import { getStyleVar, stripNonNumeric } from '../utils';

export const ImpersonationCautionBanner = observer(() => {
  const { userManager } = useContext(MobXProviderContext);
  const { firstName, lastName } = userManager;

  const CautionBanner = SatCoreComponent('CautionBanner');

  const fullName = `${firstName || ''} ${lastName || ''}`;

  const bannerKey = 'ImpersonationCautionBanner';
  const cautionBannerMsg = (`
    Caution! You are logged in as ${fullName},
    any and all actions taken beyond this point will be logged as
    activity by ${fullName} and attributed to their account.
    You must log out of this account in order to stop impersonating ${fullName}.
  `);

  const navSeparatorHeight = +stripNonNumeric(getStyleVar('--nav-separator-height') || 0);
  const stickyOffset = 50 + navSeparatorHeight;

  return <CautionBanner bannerKey={bannerKey} cautionBannerMsg={cautionBannerMsg} stickyOffset={stickyOffset} />;
});
