import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { Sticky } from 'semantic-ui-react';

import '../css/CautionBanner.less';
import imageCaution from '../img/image-caution.png';

import { SatCoreRegister } from '../SatCoreRegistry';

const CautionBanner = observer(({
  bannerKey, cautionBannerMsg, isImpersonationCautionBannerShowing, stickyOffset, t
} = {}) => {
  const { notificationManager } = useContext(MobXProviderContext);
  return !notificationManager.isCautionBannerLoading ? (
    <Sticky className={classNames('caution-banner-sticky', `${bannerKey}-sticky`, {
      isImpersonationCautionBannerShowing
    })} offset={stickyOffset || 50}>
      <div className={classNames('caution-banner-inner', `${bannerKey}-inner`, {
        'caution-banner-inner-minimized': notificationManager.isCautionBannerMinimized
      })} onClick={() => {
        notificationManager.setIsCautionBannerLoading(true);
        notificationManager.setIsCautionBannerMinimized(!notificationManager.isCautionBannerMinimized);
        setTimeout(() => {
          notificationManager.setIsCautionBannerLoading(false);
        }, 1);
      }} title={notificationManager.isCautionBannerMinimized ? t('clickToExpand') : t('clickToMinimize')}>
        <div className='caution-banner-icon'>
          <img alt='' src={imageCaution} />
        </div>
        <div className='caution-banner-text'>
          {cautionBannerMsg}
        </div>
      </div>
    </Sticky>
  ) : null;
});
export default CautionBanner;

SatCoreRegister('CautionBanner', CautionBanner);
