import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import { register } from '../../../i18n';

import EngagementService from '../../../services/EngagementService';

const EngagementHeaderMainTimeOnTaskRatioCells = observer((props) => {
  const { engagementManager } = useContext(MobXProviderContext);

  const activeTable = engagementManager.activeEngagementTable;

  const { handleHeaderClick } = props;

  const t = register('EngagementTable');

  const renderEngagementHeaderMainTimeOnTaskRatioCells = () => {
    const isOnlyShowingMainCells = activeTable === 'summary';
    return (
      <>
        {isOnlyShowingMainCells && renderEngagementHeaderMainTimeOnTaskRatioCell({
          allowPopupComponent: true,
          className: `main-cell engagement-header-label timeOnTaskRatio active-table-${activeTable}`,
          isHeaderCell: true,
          popupContent: t('timeOnTaskRatioDescriptionMain'),
          popupOn: 'hover',
          popupTrigger: (
            <div className='clickable'
              data-value='timeOnTaskRatio'
              onClick={handleHeaderClick}>
              {t('timeOnTaskRatio')}
            </div>
          )
        })}
        {!isOnlyShowingMainCells && renderEngagementHeaderMainTimeOnTaskRatioCell({
          allowPopupComponent: true,
          cellText: t('timeOnTaskRatio'),
          className: `main-cell engagement-header-label timeOnTaskRatio active-table-${activeTable}`,
          isHeaderCell: true,
          popupContent: t('timeOnTaskRatioDescription'),
          popupOn: 'click'
        })}
      </>
    );
  };

  const renderEngagementHeaderMainTimeOnTaskRatioCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementHeaderMainTimeOnTaskRatioCells();
});
export default EngagementHeaderMainTimeOnTaskRatioCells;

SatCoreRegister('EngagementHeaderMainTimeOnTaskRatioCells', EngagementHeaderMainTimeOnTaskRatioCells);
