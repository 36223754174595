import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Card, Container, Header, Loader } from 'semantic-ui-react';

import '../css/Dashboard.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { CONTENT_ITEM_TYPES, CONTENT_MODE, PLAYER_TYPES } from '../managers/ContentManager';
import { DIALOG_NAMES } from '../managers/DialogManager';
import { INSTITUTION_DROPDOWN_MODE } from '../services/UserInstitutionDropdownConstants';
import { VIEW_SELECTION } from '../managers/NavigationManager';

import Auth from '../managers/AuthManager';

import AssignmentService from '../services/AssignmentService';
import ClassroomService from '../services/ClassroomService';
import CourseService from '../services/CourseService';
import GradebookService from '../services/GradebookService';
import ImageService from '../services/ImageService';
import NavigationService from '../services/NavigationService';
import UserService from '../services/UserService';

import { dateFormat, getSessionStorageItem, removeSessionStorageItem } from '../utils';

export default
@inject(
  'appManager',
  'assignmentManager',
  'classroomManager',
  'contentManager',
  'courseManager',
  'dialogManager',
  'dynamicSatelliteManager',
  'navigationManager',
  'productManager',
  'userManager')
@observer
class TeacherDashboard extends Component {
  constructor(props) {
    super(props);
    this.ClassCard = SatCoreComponent('ClassCard');
    this.DocReaderModal = SatCoreComponent('DocReaderModal');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');
    this.FullscreenModal = SatCoreComponent('FullscreenModal');
    this.LearnosityPlayerModal = SatCoreComponent('LearnosityPlayerModal');
    this.UserInstitutionDropdown = SatCoreComponent('UserInstitutionDropdown');
    this.state = {
      // TODO remove // allowLoadMore: true,
      docreaderViewerShowing: false,
      fileViewerShowing: false,
      isTeacherDashboardLoading: true,
      learnosityPlayerShowing: false,
      lessonPlayerShowing: false,
      // TODO remove // truePage: 0
    };
  }

  async componentDidMount() {
    const { dynamicSatelliteManager, location, navigationManager, productManager } = this.props;
    const { isDynamicSatellite } = dynamicSatelliteManager;

    const certificateId = getSessionStorageItem('c2c_certificateId');

    // ensure we are still on the TeacherDashboard before loading its data
    if (!isDynamicSatellite || certificateId || window.location.pathname === '/') {
      if (productManager.shouldShowExpiredLicenseWarning) {
        productManager.setShouldShowExpiredLicenseWarning(false);
      }

      await this.fetchClassroomData();

      // If "Course Report" or "Standards Reports" breadcrumbs are clicked we redirect to the dashboard and then
      // back to reports this check was added to make sure we don't change the view when we are still on reports
      // See NavigationService.js line 106
      if (!location?.state?.fromReport) {
        navigationManager.setView(VIEW_SELECTION.DASHBOARD);
      }

      // if there is a certificateId, show the certificate download modal
      if (certificateId) {
        removeSessionStorageItem('c2c_certificateId');
        this.showCertificateDownloadModal(certificateId);
      }
    }
  }

  handleChangeUserInstitutionDropdown = (_event, _data) => {
    /* placeholder */
  }

  handleCardClick = async (id) => {
    const { classroomManager, courseManager, navigationManager, userManager, history } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.handleCardClick !== undefined) {
      // eslint-disable-next-line react/destructuring-assignment
      await this.props.handleCardClick(id);
      return;
    }
    classroomManager.setCurrentClassroomId(id);
    courseManager.setCurrentCourseId(null);

    await CourseService.fetchClassroomCourses(id, userManager.isTeacher, userManager.isStudent);
    navigationManager.navigateToLastClassroomCourseLocation(id, history, true);
  }

  handleClickCourse = async (classroomId, course) => {
    const { history } = this.props;
    await NavigationService.handleClickCourse(classroomId, course, history);
  }

  // TODO remove
  // handleLoadMore = async (_page) => {
  //   const { truePage } = this.state;
  //   const nextPage = truePage + 1;
  //   this.setState({
  //     allowLoadMore: false,
  //     truePage: nextPage
  //   });
  //   const page = 0;
  //   await this.fetchClassroomData(nextPage);
  //   this.setState({ allowLoadMore: true });
  // }

  handleEditAssignment = (assignment, contentImageUrl, classroomId, _assignmentElement, deleteAssignment) => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.EDIT_ASSIGNMENT, {
      assignmentId: assignment.id,
      assignmentInstruction: assignment.instruction,
      classroomId,
      contentImageUrl,
      deleteAssignment,
      contentTimeframeStartDate: assignment.timeframeStartDate,
      contentTimeframeEndDate: assignment.timeframeEndDate,
      timeframeStartDateStr: assignment.timeframeStartDateStr,
      timeframeEndDateStr: assignment.timeframeEndDateStr,
      timeframeStartTimeStr: assignment.timeframeStartTimeStr,
      timeframeEndTimeStr: assignment.timeframeEndTimeStr,
    },
    () => this.closeEditAssignment(classroomId));
  }

  closeEditAssignment = async (classroomId) => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.EDIT_ASSIGNMENT);
    await this.showContentAssignments(classroomId);
  }

  handleGradebook = async (cardData) => {
    const { history, t } = this.props;
    await GradebookService.navToAggregateGradebookSummaryFromExternalModal(
      {
        allAssignmentsLabel: t('gradebookLabel', 'Gradebook'),
        assignCardData: cardData,
        currentClassroomId: cardData.classroomId,
        history
      }
    );
  }

  handleOpenClassroomImagePicker = (classroomId) => {
    ClassroomService.handleOpenClassroomImagePicker({
      alsoUpdateCurrentClassroomId: true,
      classroomId
    });
  }

  showCertificateDownloadModal = (certificateId) => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.CERTIFICATE,
      {
        downloadCertificate: () => window.open(
          `${Auth.ecms}/api/downloadCertificate?certificateId=${certificateId}&authKey=${Auth.authKey}`, '_self'
        ),
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.CERTIFICATE)
    );
  }

  handleNavigationClick = (url, classroomId) => {
    const { classroomManager, history } = this.props;
    classroomManager.setCurrentClassroomId(classroomId);
    history.push(url);
  }

  fetchCourseActivityInformation = async (assignment, element, contentImageUrl) => {
    const { assignmentManager } = this.props;
    const result = await assignmentManager.fetchCourseActivityInformation(assignment.id);
    if (result) {
      const title = result.webTitle ? result.webTitle : result.contentName;
      this.handleOpenAssignmentInfo(
        title, result.contentDescription, result.resourceInformation, contentImageUrl, assignment.instruction, assignment
      );
    }
  }

  handleOpenInfo = (infoName, contentItemDescription, resourceInfo, contentItemImageUrl, instruction, assignment) => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.INFO, {
      contentItemDescription,
      contentItemImageUrl,
      infoName,
      resourceInfo,
      studentInstruction: instruction
    },
    () => this.closeOpenInfo(assignment.classroomId));
  }

  closeOpenInfo = async (classroomId) => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.INFO);
    await this.showContentAssignments(classroomId);
  }

  handleOpenAssignmentInfo = (infoName, contentItemDescription, resourceInfo, contentItemImageUrl, instruction, assignment) => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.ASSIGNMENT_INFO, {
      assignment,
      contentItemDescription,
      contentItemImageUrl,
      infoName,
      resourceInfo,
      studentInstruction: instruction,
    },
    () => this.closeOpenAssignmentInfo(assignment.classroomId));
  }

  closeOpenAssignmentInfo = async (classroomId) => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.ASSIGNMENT_INFO);
    await this.showContentAssignments(classroomId);
  }

  handleAddClass = () => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.ADD_CLASS, {
      ...this.props,
    }, this.closeAddClassModal);
  }

  handlePresent = async (params) => {
    const { contentManager, userManager } = this.props;
    const { assignment, publisherModeId = null } = params;
    const option = await contentManager.getOptionsForTeacherPreviewAssigned(assignment, window.location.origin,
      userManager.isTeacher, userManager.userId, publisherModeId);
    const { playerType, viewUrl, isFlowpaper } = option;
    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    }

    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);
    const contentImageUrl = ImageService.getImageUrl(assignment);

    const { dialogManager } = this.props;
    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        contentItemId: assignment.contentItemId,
        contentItemType: assignment.contentItemEntityTypeId,
        resourceName: assignment.name,
      });
    }

    const {
      resourceWebTitle, resourceWebSubtitle
    } = AssignmentService.getAssignmentTitleAndDescription(assignment);

    this.setState({
      assignmentId: assignment.id,
      contentImageUrl,
      contentItemId: assignment.contentItemId,
      contentName: resourceWebTitle,
      contentSubtitle: resourceWebSubtitle,
      contentUrl: viewUrl,
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      instruction: assignment.instruction,
      isFlowpaper,
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      previewContentItemId: assignment.id,
      previewContentType: assignment.contentItemEntityTypeId,
      resourceName: assignment.name,
    });
  }

  handleView = async (params) => {
    const { cardData, publisherModeId = null } = params;
    const {
      contentManager,
      courseManager,
      dialogManager,
      userManager,
      classroomManager
    } = this.props;

    const contentMode = CONTENT_MODE.PREVIEW;
    params.contentMode = contentMode;

    // TODO get the courseResourceElement using cardData.courseResourceElementId
    const courseElement = await courseManager.fetchCourseResourceElement(cardData.courseResourceElementId);

    if (!courseElement) {
      console.info(`courseResourceElement not found for elementId: ${cardData.courseResourceElementId}`);
      return;
    } else if (cardData.modeOverrideAllowed === undefined) {
      cardData.modeOverrideAllowed = courseElement.modeOverrideAllowed;
    }
    if (publisherModeId === null && courseElement.modeOverrideAllowed) {
      this.getPublisherModeId(params);
      return;
    }

    let option = null;
    const { deliveryMode, entityTypeId, id, name, pdfDeliveryFormat, type } = courseElement;
    const contentItemId = cardData.resourceContentItemId;
    const courseResourceElementId = id;
    const classroomId = cardData.classroomId || classroomManager.currentClassroomId;

    option = await contentManager.getOptionsForTeacherPreview(
      contentItemId, type, pdfDeliveryFormat, origin,
      userManager.canViewAsTeacher, userManager.userId,
      courseResourceElementId, classroomId,
      publisherModeId
    );

    const { playerType, viewUrl, isFlowpaper } = option;

    if (!deliveryMode.includes('student_always') && !userManager.canViewAsTeacher) {
      // TODO: IS STUDENT PREVIEW MODE FOR LESSON GOING TO BE SOMETHING? API RETURNS ONLY TEACHER PREVIEW WITHOUT ACTIVITY.
      if (playerType === PLAYER_TYPES.LESSON_PLAYER) {
        this.handleGeneralMessage('This type is unsupported.');
        return false;
      }
    }
    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    }

    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        contentItemId,
        contentItemType: entityTypeId,
        resourceName: name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
    }

    this.setState({
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      contentUrl: viewUrl,
      contentMode,
      isFlowpaper,
      contentItemId,
      contentItemType: entityTypeId,
      contentName: name,
      assignmentId: '',
      previewContentType: entityTypeId,
      previewContentItemId: '',
      resourceName: '',
      instruction: '',
      contentImageUrl: ''
    });
  }

    getPublisherModeId = async (params) => {
      const { cardData, contentMode, pdfFormatSelected = null, publisherModeId = null } = params;
      const { dialogManager, userManager } = this.props;
      const { modeOverrideAllowed } = cardData;
      const alternateModeId = publisherModeId || cardData.alternateModeIdOverride;
      if (userManager.isTeacher && publisherModeId === null && modeOverrideAllowed) {
      // If mode override is allowed, open a modal for the teacher to select a mode for preview
        dialogManager.setOpenDialog(DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL, {
          close: this.closeSelectPreviewModeModal,
          contentMode,
          courseElement: cardData,
          open: true,
          pdfFormatSelected,
          setPreviewModeId: this.setPreviewModeId
        });
      } else if (userManager.isTeacher && alternateModeId !== null) {
        return this.handleView({ cardData, alternateModeId });
      } else {
      // eslint-disable-next-line react/destructuring-assignment
        return this.handleView({ cardData });
      }
    }

  closeSelectPreviewModeModal = () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL);
  }

  setPreviewModeId = async (courseElement, contentMode = null, pdfFormatSelected = null, publisherModeId) => {
    const { dialogManager } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    this.handleView({ cardData: courseElement, publisherModeId });
    dialogManager.closeDialog(DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL);
  };

  handleAnswerKey = async (assignment) => {
    const { contentManager, userManager } = this.props;
    const option = await contentManager.getOptionsForAnswerKey(
      assignment.attachmentContentItemId, CONTENT_ITEM_TYPES.PDF_RESOURCE,
      assignment.classroomId, origin, userManager.isTeacher, userManager.userId);
    const { playerType, viewUrl, isFlowpaper } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    }

    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    this.setState({
      contentName: assignment.attachmentContentItemTitle,
      contentUrl: viewUrl,
      docreaderViewerShowing: true,
      fileViewerShowing: true,
      instruction: null,
      isFlowpaper,
      learnosityPlayerShowing: false,
      lessonPlayerShowing: false,
    });
  }

  showContentAssignments = async (classroomId, fetchAssignmentsToGrade = false, useAggregateCards = false) => {
    const { classroomManager, dialogManager } = this.props;
    const classroomName = classroomManager.getClassName(classroomId);
    dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_ASSIGNMENT, {
      ...this.props,
      classeslist: true,
      contentName: classroomName,
      currentClassroomId: classroomId,
      currentCourseId: null,
      fetchAssignmentsToGrade,
      fetchCourseActivityInformation: this.fetchCourseActivityInformation,
      handleAnswerKey: this.handleAnswerKey,
      handleEditAssignment: this.handleEditAssignment,
      handleGradebook: this.handleGradebook,
      handlePresent: this.handlePresent,
      handleView: this.handleView,
      setPreviewModeId: this.setPreviewModeId,
      useAggregateCards
    }, () => this.closeContentAssignmentModal(classroomId));
  }

  closeContentAssignmentModal = async (classroomId) => {
    const { assignmentManager, classroomManager, dialogManager } = this.props;
    assignmentManager.clearAssignments();
    await classroomManager.fetchClassroom(classroomId);
    dialogManager.closeAllDialogs();
  }

  hideIframeFromOuterClick = (event) => {
    if ((event.origin === Auth.publisher || event.origin === Auth.lesson) && event.data === 'hideIframe') {
      this.setState({ lessonPlayerShowing: false });
      window.removeEventListener('message', this.hideIframeFromOuterClick);
    }
  }

  hideIframe = () => {
    this.setState({ lessonPlayerShowing: false });
  }

  hideModal = () => {
    this.setState({
      docreaderViewerShowing: false,
      fileViewerShowing: false,
      learnosityPlayerShowing: false
    });
  }

  handleJoinClass = () => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.JOIN_CLASS, {
      ...this.props,
    }, this.closeJoinClassModal);
  }

  closeJoinClassModal = async () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.JOIN_CLASS);
    this.setState({ isTeacherDashboardLoading: true });
    setTimeout(async () => {
      await this.fetchClassroomData();
    }, 1500);
  }

  fetchClassroomData = async (nextPage = 0) => {
    this.setState({ isTeacherDashboardLoading: true });
    const { classroomManager } = this.props;
    const { currentClassroomId } = classroomManager;
    const hasMultipleInstitutions = UserService.hasMultipleInstitutions();

    const page = hasMultipleInstitutions ? 0 : nextPage;
    const isStudent = false;
    const userId = null;
    const archived = false;

    await classroomManager.fetchClassroomData(
      currentClassroomId, page, isStudent, userId, archived, {
        // note: for users with multiple institutions, we currently need to fetch all classrooms at once
        // (we handle filtering multiple institutions on the FE at the moment, so we need all the data in advance)
        pageSize: hasMultipleInstitutions ? 999 : undefined
      }
    );
    this.setState({ isTeacherDashboardLoading: false });
  }

  closeAddClassModal = async (_event, _data, response, institutionId) => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.ADD_CLASS);
    if (response) {
      this.setState({ isTeacherDashboardLoading: true });
      const { ADD_CLASSROOM_MODE } = INSTITUTION_DROPDOWN_MODE;
      UserService.setSelectedDropdownInstitutionId(institutionId ||
        UserService.getSelectedDropdownInstitutionId(ADD_CLASSROOM_MODE)
      );
      setTimeout(async () => {
        await this.fetchClassroomData();
      }, 1500);
    }
  }

  showAddJoinModal = () => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.ADD_JOIN_CLASS, {
      openAddModal: this.handleAddClass,
      openJoinModal: this.handleJoinClass
    });
  }

  /** @param {'addClass' | 'joinClass' | 'addJoinClass'} labelKey */
  renderAddOrJoinClassButton = (labelKey = '', onClick) => {
    const { t } = this.props;
    return (
      <div className='addClassButtonWrapper'>
        <Button
          className='addClassButton'
          onClick={onClick}
          primary>
          {t(labelKey)}
        </Button>
      </div>
    );
  }

  renderRest() {
    // TODO remove
    // const months = [
    //   'January', 'February', 'March', 'April', 'May', 'June',
    //   'July', 'August', 'September', 'October', 'November', 'December'
    // ];
    // const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const {
      addClassOnly, appManager, classroomManager,
      defaultImage, noClasses, showAddJoinModal, t,
      userManager
    } = this.props;
    const { isSsoUser, isTeacher } = userManager;
    const { /* allowLoadMore, */ isTeacherDashboardLoading } = this.state;

    const hasMultipleInstitutions = UserService.hasMultipleInstitutions();

    const { ClassCard, UserInstitutionDropdown } = this;

    let items = [];

    const { SELECT_CLASSROOM_MODE } = INSTITUTION_DROPDOWN_MODE;

    classroomManager.availableClassroomsArray.map((classroom) => {
      items.push(
        <ClassCard
          key={`class_${classroom.id}`}
          classroomId={classroom.id}
          classroomName={classroomManager.getClassName(classroom.id)}
          defaultImage={defaultImage}
          handleCardClick={this.handleCardClick}
          handleClickCourse={this.handleClickCourse}
          handleNavigationClick={this.handleNavigationClick}
          isLeadTeacher={classroomManager.isLeadTeacher(classroom.id, userManager.userId)}
          isSsoUser={isSsoUser}
          isTeacher={isTeacher}
          openImagePicker={this.handleOpenClassroomImagePicker}
          showContentAssignments={this.showContentAssignments} />);
      return true;
    });
    items = [...items].sort((a, b) => {
      const classroomNameA = a && a.props && a.props.classroomName &&
        a.props.classroomName[0] ? a.props.classroomName[0] : '';
      const classroomNameB = b && b.props && b.props.classroomName &&
        b.props.classroomName[0] ? b.props.classroomName[0] : '';
      return classroomNameA.localeCompare(classroomNameB, 'en', {
        numeric: true
      });
    });

    const handleShowAddJoinModal = showAddJoinModal || this.showAddJoinModal;
    let addOrJoinClassBtn;

    const buttonKey = UserService.getAddOrJoinClassButtonKeyForTeachers({ addClassOnly });

    if (buttonKey === 'addClass') {
      addOrJoinClassBtn = this.renderAddOrJoinClassButton('addClass', this.handleAddClass);
    } else if (buttonKey === 'addJoinClass') {
      addOrJoinClassBtn = this.renderAddOrJoinClassButton('addJoinClass', handleShowAddJoinModal);
    } else if (buttonKey === 'joinClass') {
      addOrJoinClassBtn = this.renderAddOrJoinClassButton('joinClass', this.handleJoinClass);
    }

    // const locale = InitService.getLocale();
    const date = new Date();

    return isTeacherDashboardLoading ? <Loader active /> : (
      <>
        <Container
          className={items.length <= 0
            ? 'wide dashboard-container teacher-dashboard-empty-container'
            : 'dashboard-container teacher-dashboard-container'}>
          <div className='headerContainer'>
            <div className='headerWrapper'>
              <Header
                as='h1'
                className='welcome-header theme-header-title'>
                {t('WelcomeLabel', 'Welcome')}
                &nbsp;
                <br className='mobile-only' />
                {userManager.firstName}
                &nbsp;
                {userManager.lastName}!
              </Header>
              <p className='welcome-date'>
                {(noClasses !== undefined) ? null : `${t('myClasses', 'My Classes')}  |  `}
                {' '}
                {dateFormat(date, 'fullDate', appManager.locale)}
                {/* TODO remove */}
                {/* {`${days[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`} */}
              </p>
            </div>
            {isTeacher && addOrJoinClassBtn}
          </div>

          <UserInstitutionDropdown
            allowSelectAllInstitutions
            mode={SELECT_CLASSROOM_MODE}
            onChange={this.handleChangeUserInstitutionDropdown} />

          {/* eslint-disable-next-line no-nested-ternary */}
          {(items.length > 0) ? (
            <Card.Group>
              {(userManager.loaded && classroomManager.loaded) ? (
                items
                // TODO remove - using InfiniteScroll here becomes difficult & unreliable as we add new features
                // TODO such as the ability to add/filter classes by multiple institutions
                // TODO in many cases we need all the class data at once (instead of in paged intervals)
                // <InfiniteScroll
                //   hasMore={classroomManager.hasMore}
                //   initialLoad={false}
                //   loadMore={() => (!hasMultipleInstitutions && allowLoadMore
                //     && classroomManager.hasMore ? this.handleLoadMore() : {})}
                //   loader={<Loader key={0} active inline='centered' style={{ marginBottom: '14px' }} />}
                //   pageStart={0}
                //   useWindow={true}>
                //   {items}
                // </InfiniteScroll>
              ) : null}
            </Card.Group>
          ) : (userManager.loaded) &&
            (noClasses !== undefined ? noClasses() : (
              <div className='no-classrooms-found'>
                {t(hasMultipleInstitutions ? 'noClassesFoundForSelectedInstitution' : 'noClassesFound')}
              </div>
            ))}
        </Container>
      </>
    );
  }

  renderLessonPlayer = () => {
    const { userManager } = this.props;
    const { contentUrl, contentName, instruction, contentImageUrl } = this.state;
    const { FullscreenModal } = this;
    return (
      <div className='course-content'>
        <FullscreenModal
          closeIframeCallback={this.hideIframe}
          contentImageUrl={contentImageUrl}
          contentName={contentName}
          instruction={instruction}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={contentUrl} />
      </div>
    );
  }

  renderFileViewer = () => {
    const { userManager } = this.props;
    const { contentUrl, contentName, isFlowpaper, instruction, contentImageUrl, resourceName, previewContentType } = this.state;
    const { FileViewerModal } = this;
    return (
      <div className='course-content'>
        <FileViewerModal
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentName={contentName}
          contentType={previewContentType}
          instruction={instruction}
          isFlowpaper={isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='file-viewer'
          resourceName={resourceName}
          url={contentUrl} />
      </div>
    );
  }

  renderDocreaderViewer = () => {
    const { userManager, contentManager, renderDocreaderViewer } = this.props;
    const { DocReaderModal } = this;
    if (renderDocreaderViewer !== undefined) {
      return renderDocreaderViewer();
    }
    const {
      assignmentId, contentImageUrl, contentMode, contentName,
      contentUrl, instruction, isFlowpaper, previewContentType,
      resourceName
    } = this.state;
    return (
      <div className='course-content'>
        <DocReaderModal
          assignmentId={assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentMode={contentMode}
          contentName={contentName}
          contentType={previewContentType}
          instruction={instruction}
          isFlowpaper={isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='docreader-viewer'
          resourceName={resourceName}
          sessionId={contentManager.currentflowPaperSessionId}
          url={contentUrl} />
      </div>
    );
  }

  renderLearnosityPlayer = () => {
    const { userManager, history } = this.props;
    const { contentItemId, contentName, contentSubtitle, assignmentId, instruction, contentImageUrl, resourceName } = this.state;
    const { LearnosityPlayerModal } = this;
    return (
      <div className='course-content'>
        <LearnosityPlayerModal
          assignmentId={assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentItemId={contentItemId}
          contentName={contentName}
          contentSubtitle={contentSubtitle}
          history={history}
          instruction={instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-player'
          resourceName={resourceName} />
      </div>
    );
  }

  render() {
    const { learnosityPlayerShowing, lessonPlayerShowing, fileViewerShowing, docreaderViewerShowing } = this.state;
    if (learnosityPlayerShowing) {
      return this.renderLearnosityPlayer();
    } else if (lessonPlayerShowing) {
      return (this.renderLessonPlayer());
    } else if (fileViewerShowing) {
      return (this.renderFileViewer());
    } else if (docreaderViewerShowing) {
      return (this.renderDocreaderViewer());
    }
    return (this.renderRest());
  }
}

SatCoreRegister('TeacherDashboard', TeacherDashboard);
