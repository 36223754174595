import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Image, Loader, Popup, Segment } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';
import '../css/AssignmentInfoCard.less';
import { ASSIGNMENT_TYPE } from '../managers/AssignmentManager';
import ImageService from '../services/ImageService';

export default @inject('assignmentManager', 'dialogManager', 'groupsManager',
  'courseManager', 'userManager', 'classroomManager', 'assignmentManager', 'navigationManager')
@observer
class AssignmentInfoCard extends Component {
  constructor(props) {
    super(props);
    this.AssignmentStatus = SatCoreComponent('AssignmentStatus');
  }

  formatTime = (str) => {
    str = str.replace(/\s/g, '').replace(/^0+/, '');
    return str.toLowerCase();
  }

  render() {
    const { assignment, assignmentManager, groupsManager } = this.props;
    const currentAssignment = assignment;
    const { AssignmentStatus } = this;
    const contentImageUrl = ImageService.getImageUrl(currentAssignment);

    let assignedByName;
    let { assignerFirstName, assignerLastName } = currentAssignment;
    assignerFirstName = assignerFirstName || '';
    assignerLastName = assignerLastName || '';
    assignedByName = `${assignerFirstName} ${assignerLastName}`.trim();
    assignedByName = assignedByName || 'N/A';

    return (
      <Segment className='assignment-info-card' vertical>
        {(contentImageUrl !== null && contentImageUrl !== undefined) ? <Image src={contentImageUrl} /> :
        <div className='image-placeholder' />}
        <div className='assignment-info-card-content'>
          <AssignmentStatus direction='horizontal' noLabel={false} status={currentAssignment.status} />
          <div className='fg-row'>
            <div className='card-label'>
              {assignmentManager.getTranslatedAssignmentTypeFlag(currentAssignment)}
              :
            </div>
            {(currentAssignment.totalActivityInstances > 0 && currentAssignment.subdomainTypeId === ASSIGNMENT_TYPE.GROUP) && (
            <Popup
              hideOnScroll={false}
              hoverable={true}
              on='hover'
              position='bottom left'
              trigger={
                <div className='card-value blue clickable'>{currentAssignment.totalActivityInstances}</div>
              }
              wide>
              <>
                {groupsManager.renderGroupAssignmentInfoPopupContent(
                  null, currentAssignment.groupName, currentAssignment.groupStudentNames, false, true
                )}
              </>
            </Popup>
            )}
            {(currentAssignment.totalActivityInstances > 0 && currentAssignment.subdomainTypeId !== ASSIGNMENT_TYPE.GROUP) && (
            <Popup
              hideOnScroll={false}
              hoverable={true}
              on='hover'
              onOpen={() => {
                assignmentManager.assignmentMemberPopupLoading = true;
                assignmentManager.clearAssignmentMembers().then(() => {
                  const forceLoad = true;
                  assignmentManager.fetchAssignmentMembers(currentAssignment.id, forceLoad).then(() => {
                    assignmentManager.assignmentMemberPopupLoading = false;
                  });
                });
              }}
              position='bottom left'
              trigger={
                <div className='card-value blue clickable'>{currentAssignment.totalActivityInstances}</div>
              }
              wide>
              <>
                {(assignmentManager.getAssignmentMembers().length === 0
                            && assignmentManager.assignmentMemberPopupLoading)
                  ? <Loader active inline /> : null}
                {assignmentManager.getAssignmentMembers().map((member, index) => (<div key={index} className='card-value'>{member}</div>)
                )}
              </>
            </Popup>
            )}
            {currentAssignment.totalActivityInstances < 1 &&
              <div className='card-value'>None</div>}
          </div>
          <div className='fg-row'>
            <div className='card-label'>Start Date:</div>
            <div className='card-value'>{currentAssignment.timezoneStartDate}</div>
          </div>
          <div className='fg-row'>
            <div className='card-label'>Start Time:</div>
            <div className='card-value'>{this.formatTime(currentAssignment.timezoneStartTime)}</div>
          </div>
          <div className='fg-row'>
            <div className='card-label'>Due Date:</div>
            <div className='card-value'>{currentAssignment.timezoneEndDate}</div>
          </div>
          <div className='fg-row'>
            <div className='card-label'>Due Time:</div>
            <div className='card-value'>{this.formatTime(currentAssignment.timezoneEndTime)}</div>
          </div>
          <div className='fg-col '>
            <div className='card-label'>Assigned&nbsp;By:</div>
            <div className='card-value'>
              {`${assignedByName}${currentAssignment.createDate ? ` (${currentAssignment.createDate})` : ''}`}
            </div>
          </div>
        </div>
      </Segment>
    );
  }
}

SatCoreRegister('AssignmentInfoCard', AssignmentInfoCard);
