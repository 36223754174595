import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Popup } from 'semantic-ui-react';

import '../css/StackedBar.less';

import { SatCoreRegister } from '../SatCoreRegistry';

const StackedBar = observer((props) => {
  const { data, additionalClassNames = '' } = props;
  let { height, margin, popupOn, width } = props;

  const { /* managers */ } = useContext(MobXProviderContext);

  const columns = data.map((x) => `${x.value}fr`);
  const template = columns.join(' ');

  // default props
  height = height || 20;
  margin = margin || '0';
  popupOn = popupOn === 'hover' ? 'hover' : 'click';
  width = width || '100%';

  let stackedBarClassName = 'stacked-bar';
  let popupStackedBarClassName = 'stacked-bar-popup';

  stackedBarClassName += additionalClassNames ? ` ${additionalClassNames}` : '';
  popupStackedBarClassName += additionalClassNames ? ` ${additionalClassNames}` : '';

  return (
    <div className={stackedBarClassName} style={{
      display: 'inline-grid',
      gridTemplateColumns: template,
      height,
      margin,
      width
    }}>
      {data.map((val, i) => {
        return (
          <Popup
            key={i}
            className={popupStackedBarClassName}
            content={val.popupContent}
            hoverable={popupOn === 'hover'}
            on={popupOn}
            position='top center'
            trigger={<div className={val.colorKey} />} />
        );
      })}
    </div>
  );
});
export default StackedBar;

SatCoreRegister('StackedBar', StackedBar);
