import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import InfiniteScroll from 'react-infinite-scroller';

import { Button, Container, Icon, Loader } from 'semantic-ui-react';

import '../css/NotificationsView.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { NOTIFICATION_NAV_TYPE } from '../constants';

import { VIEW_SELECTION } from '../managers/NavigationManager';

import { PLAYER_TYPES } from '../managers/ContentManager';

import { DIALOG_NAMES } from '../managers/DialogManager';

const NotificationsView = observer((props) => {
  const { history, t } = props;
  const { contentManager, dialogManager, navigationManager, notificationManager, userManager } = useContext(MobXProviderContext);

  const [isRefreshingNotifications, setIsRefreshingNotifications] = useState(false);

  const [lessonPlayerShowing, setLessonPlayerShowing] = useState(false);
  const [contentUrl, setContentUrl] = useState('');
  const [contentName, setContentName] = useState('');
  const [contentImageUrl, setContentImageUrl] = useState('');

  const ButtonBar = SatCoreComponent('ButtonBar');
  const DefaultNotificationCard = SatCoreComponent('DefaultNotificationCard');
  const SatCoreLoader = SatCoreComponent('SatCoreLoader');
  const SystemNotificationCard = SatCoreComponent('SystemNotificationCard');
  const ZeroStateCard = SatCoreComponent('ZeroStateCard');
  const FullscreenModal = SatCoreComponent('FullscreenModal');

  useEffect(() => {
    (async () => {
      navigationManager.setView(VIEW_SELECTION.NOTIFICATIONS);
      // TODO remove
      // TODO notifications are now fetched whenever TopNav active tab changes (per DEMO-2504)
      // TODO so we no longer need to call it here
      // await handleFetchNotifications();
    })();
  }, []);

  const handleClickNotificationBarButton = async (_event, buttonBarObj = {}) => {
    const notificationsType = buttonBarObj?.buttonKey || NOTIFICATION_NAV_TYPE.MANUAL;
    notificationManager.setActiveNotificationType(notificationsType);
    await handleFetchNotifications();
  };

  const handleFetchNotifications = async (page) => {
    if (!isRefreshingNotifications && !page) {
      setIsRefreshingNotifications(true);
    }
    if (notificationManager.activeNotificationType === NOTIFICATION_NAV_TYPE.SYSTEM) {
      await notificationManager.fetchSystemNotifications({
        page,
        userId: userManager.userId
      });
      if (!page) {
        setIsRefreshingNotifications(false);
      }
    } else {
      await notificationManager.fetchManualNotifications({
        page,
        userId: userManager.userId
      });
      if (!page) {
        setIsRefreshingNotifications(false);
      }
    }
  };

  const handleDeleteSystemNotification = async (notification) => {
    if (isRefreshingNotifications) {
      return;
    }
    setIsRefreshingNotifications(true);
    await notificationManager.deleteNotification(notification.id);
    await handleFetchNotifications();
    setIsRefreshingNotifications(false);
  };

  const handleDeleteAllNotifications = async () => {
    if (isRefreshingNotifications) {
      return;
    }
    dialogManager.setOpenDialog(
      DIALOG_NAMES.CONFIRM,
      {
        cancelButtonName: t('confirmDeleteCancelButtonLabel', 'Cancel'),
        className: 'delete-notifications-modal',
        confirmButtonName: t('confirmDeleteSubmitButtonLabel', 'Delete'),
        confirmHandler: async () => {
          setIsRefreshingNotifications(true);
          await notificationManager.deleteAllNotifications();
          await handleFetchNotifications();
          setIsRefreshingNotifications(false);
          dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
        },
        message: t('confirmDeleteMessage', 'Are you sure you want to delete all of your notifications?'),
        title: t('confirmDeleteTitle', 'Are You Sure'),
      }
    );
  };

  const handleCustomButtonClick = (data) => {
    const { type, value } = data;
    const {
      displayName,
      metadata
    } = value;
    const metadataJson = metadata ? JSON.parse(metadata) : {};
    if (type === 'preview-shared-assessment') {
      handleView(metadataJson.contentItemId, 'lesson', displayName)
    } else if (type === 'edit-shared-assessment') {
      handleEdit(metadataJson.contentItemId);
    }
  }

  const handleView = async (contentItemId, entityTypeId, name) => {
    let option = null;
    option = await contentManager.getOptionsForTeacherPreview(
      contentItemId, entityTypeId, null, origin,
      userManager.canViewAsTeacher, userManager.userId,
      null, null,
      null
    );

    const { playerType, viewUrl, isFlowpaper } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    } else if (playerType === null && viewUrl === null) {
      // if playerType and viewURL are null this is an unviewable type.
      if (entityTypeId === 'file_resource') {
        // For file resources, these are download only so show message.
        dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
          title: t('downloadOnlyTitle'),
          message: t('downloadOnlyModalMsg'),
          closeButtonClass: 'deleteButton',
          closeButtonName: 'Okay'
        },
        () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
        return;
      } else {
        // otherwise show generic message.
        dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
          title: t('noPreviewAvailableTitle'),
          message: t('noPreviewAvailableMsg'),
          closeButtonClass: 'deleteButton',
          closeButtonName: 'Ok'
        },
        () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
        return;
      }
    }

    await contentManager.configPlayerWindow(playerType, window, null);

    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        contentItemId,
        contentItemType: entityTypeId,
        resourceName: name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
    }

    setLessonPlayerShowing(playerType === PLAYER_TYPES.LESSON_PLAYER);
    setContentUrl(viewUrl);
    setContentName(name);
    setContentImageUrl('');
  }

  const closeLibraryAssessmentBuilderModal = async () => {
    dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW);
  }

  const handleEdit = (contentItemId) => {
      // Open the c2c-lesson assessement builder.
      dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW, {
        closeLibraryAssessmentBuilderModal: closeLibraryAssessmentBuilderModal,
        contentItemId,
        history
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW));
  }

  const hideIframe = () => {
    setLessonPlayerShowing(false);
  }

  const renderLessonPlayer = () => {
    return (
      <Container className='libraryView' fluid>
        <FullscreenModal
          closeIframeCallback={hideIframe}
          contentImageUrl={contentImageUrl}
          contentName={contentName}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={contentUrl} />
      </Container>
    );
  }

  const renderNotificationButtonBar = () => {
    return (
      <Container className='notifications-button-bar-container'>
        <ButtonBar
          buttonBarObjs={[
            {
              buttonKey: NOTIFICATION_NAV_TYPE.MANUAL,
              defaultActive: notificationManager.activeNotificationType === NOTIFICATION_NAV_TYPE.MANUAL,
              doNotTranslate: true,
              translatedButtonKey: t(NOTIFICATION_NAV_TYPE.MANUAL, { count: notificationManager.manualNotificationCount }),
            },
            {
              buttonKey: NOTIFICATION_NAV_TYPE.SYSTEM,
              defaultActive: notificationManager.activeNotificationType === NOTIFICATION_NAV_TYPE.SYSTEM,
              doNotTranslate: true,
              translatedButtonKey: t(NOTIFICATION_NAV_TYPE.SYSTEM, { count: notificationManager.systemNotificationCount }),
            }
          ]}
          onClickBarButton={handleClickNotificationBarButton}
        />
      </Container>
    );
  };

  const renderNotificationCards = () => {
    if (notificationManager.activeNotificationType === NOTIFICATION_NAV_TYPE.SYSTEM) {
      if (notificationManager.systemNotificationMessages?.length) {
        return notificationManager.systemNotificationMessages?.map?.((notification, _index) => {
          return (
            <SystemNotificationCard
              key={notification.id}
              handleDeleteSystemNotification={handleDeleteSystemNotification}
              notification={notification}
              onCustomButtonClick={handleCustomButtonClick}
              showDelete={true} />
          );
        });
      } else if (notificationManager.loaded) {
        return (
          <ZeroStateCard translationKey='noSystemNotificationsToDisplay' />
        );
      }
    } else {
      if (notificationManager.manualNotificationMessages?.length) {
        return notificationManager.manualNotificationMessages?.map?.((notification, _index) => {
          return (
            <DefaultNotificationCard
              key={notification.id}
              notification={notification} />
          );
        });
      } else if (notificationManager.loaded) {
        return (
          <ZeroStateCard translationKey='noManualNotificationsToDisplay' />
        );
      }
    }
  };

  let hasMoreNotifications;
  if (notificationManager.activeNotificationType === NOTIFICATION_NAV_TYPE.SYSTEM) {
    hasMoreNotifications = notificationManager.hasMoreSystemNotifications;
  } else {
    hasMoreNotifications = notificationManager.hasMoreManualNotifications;
  }

  if (lessonPlayerShowing) {
    return (renderLessonPlayer());
  } else {
    return (
      <div className='notifications-view'>
        <Container className='notifications-view-header'>
          <div className='notifications-view-header-title'>
            {t('notificationsHeaderLabel')}
          </div>
        </Container>

        <Container className='button-bar-outer-container'>
          {renderNotificationButtonBar()}
          {notificationManager.activeNotificationType === NOTIFICATION_NAV_TYPE.SYSTEM &&
          notificationManager.systemNotificationMessages.length > 1 ?
            (
              <Button className='delete-all-notifications' icon labelPosition='right' onClick={handleDeleteAllNotifications}>
                {t('deleteAllNotificationsButton', 'Delete All')}
                <Icon name='trash alternate outline' />
              </Button>
            ) : null}
        </Container>

        {/* NOTE: checking for `notificationManager.loaded` when rendering InfiniteScroll will
        cause the scroll position to be lost. That is why we are currently commenting it out here. */}

        <Container className='notification-cards-container'>
          {(userManager.loaded /* && notificationManager.loaded */ && !isRefreshingNotifications) ? (
            <InfiniteScroll
              className='ui items scroller'
              hasMore={hasMoreNotifications}
              initialLoad={false}
              loadMore={handleFetchNotifications}
              loader={<Loader key={0} active inline />}
              pageStart={0}
              useWindow={true}>
              {renderNotificationCards()}
            </InfiniteScroll>
          ) : (
            <SatCoreLoader height='70vh' />
          )}
        </Container>
      </div>
    );
  }
});
export default NotificationsView;

SatCoreRegister('NotificationsView', NotificationsView);
