import { action, makeObservable, observable } from 'mobx';
import Auth from './AuthManager';

export class learnosityAuthorManager {
    @observable testMap = new Map();

    @observable testBool = false;

    @observable learnosityActivityType = null;

    constructor() {
      makeObservable(this);
    }

    @action getLearnosityAuthorApiPreviewRequest = async (contentItemId, isPreview) => {
      try {
        const response = await Auth.fetch(`${Auth.ecms}/api/author-item-init-preview?contentItemId=${contentItemId}&publisherSatelliteCode=${Auth.publisherSatelliteCode}&isPreview=${isPreview}`, {
          method: 'GET'
        });

        if (response && response.status === 'SUCCESS') {
          const data = JSON.parse(response.data);
          return data;
        }
        if (response && response.status === 'FAILURE') {
          throw response.statusMessage;
        } else {
          throw 'Unexpected Error occured.';
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
}

export default new learnosityAuthorManager();
