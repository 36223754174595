import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import {
  Image,
  Menu
} from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';
import closeWin from '../img/glyph-x.svg';

import '../css/TopNav.less';

export default
@inject(
  'appManager', 'courseManager')
@observer
class PublisherAdminTopNav extends Component {
  constructor(props) {
    super(props);

    this.Avatar = SatCoreComponent('Avatar');
    this.LogoButtons = SatCoreComponent('LogoButtons');
  }

  componentDidMount = () => {
    const { appManager, history } = this.props;
    this.unlisten = history?.listen?.((location, _action) => {
      appManager.setLocation(location);
    });
  }

  componentWillUnmount = () => {
    this.unlisten?.();
  }

  closeWindow = async () => {
    window.close();
  }

  render() {
    const { courseManager } = this.props;
    const Logo = SatCoreComponent('Logo');

    return (
      <Menu className='topNav' fixed='top' inverted>
        <Menu.Item>
          <Logo />
        </Menu.Item>
        <Menu.Item>
          Previewing :
          {' '}
          {courseManager.currentCourseName}
        </Menu.Item>
        <Menu.Menu position='right'>
          <Menu.Item as='a' className='tnav-tab close-nav' onClick={this.closeWindow} tabIndex='0'>
            <Image alt='' src={closeWin} />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
}

SatCoreRegister('PublisherAdminTopNav', withRouter(PublisherAdminTopNav));
