import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import {
  REPORT_COLOR_KEY
} from '../../../services/reports/ReportConstants';

import reportIdentityManager from '../../../managers/reports/ReportIdentityManager';

import ReportTableService from '../../../services/reports/ReportTableService';

import StackedBar from '../../StackedBar';

const ReportScoreSummaryBarCell = observer((props) => {
  const { cell, row, t } = props;

  const isRowExemptFromHavingValue = ReportTableService.isRowExemptFromHavingValue(row);
  if (isRowExemptFromHavingValue) {
    return null;
  }

  const scoreSummaryBarData = cell.value;

  const { reportInfoClassNames } = reportIdentityManager;
  const labelKeyPrefix = reportIdentityManager.isCourseReport ? 'course' : 'standards';

  const {
    /* managers */
  } = useContext(MobXProviderContext);

  const stackedBarData = Object.values(REPORT_COLOR_KEY).map((colorKeyVal) => {
    colorKeyVal = colorKeyVal === 'not-enough-data' ? 'notEnoughData' : colorKeyVal;

    // append faculty to colorKeyVal. e.g. 'meeting' becomes 'courseMeeting' or 'standardsMeeting'
    const labelKey = labelKeyPrefix + colorKeyVal.charAt(0).toUpperCase() + colorKeyVal.slice(1);

    const scoreCount = scoreSummaryBarData[colorKeyVal];
    return {
      colorKey: colorKeyVal,
      popupContent: (
        <div style={{ fontWeight: 600 }}>
          <span>
            {t(labelKey)}:{' '}
          </span>
          <span className={colorKeyVal}>
            {scoreCount}x
          </span>
        </div>
      ),
      value: scoreCount
    };
  });
  const elementType = props?.row?.original?.elementType;
  const expandableRowClassName = ReportTableService.getExpandableRowClassNameIfApplicable(elementType, props);

  const additionalClassNames = `${reportInfoClassNames}${expandableRowClassName}`;
  return (
    <StackedBar
      additionalClassNames={additionalClassNames}
      data={stackedBarData} />
  );
});
export default ReportScoreSummaryBarCell;

SatCoreRegister('ReportScoreSummaryBarCell', ReportScoreSummaryBarCell);
