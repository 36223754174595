import { action, computed, makeObservable, observable } from 'mobx';

import Auth from './AuthManager';
import userManager from './UserManager';

export class SchoolManager {
  constructor() {
    makeObservable(this);
  }

  SCHOOL_FETCH_PAGE_SIZE = 15;

  @observable schoolsMap = new Map();

  @observable lastSearchSchoolsMap = new Map();

  @observable currentSchoolId = null;

  @observable loaded = false;

  @observable hasMore = true;

  @observable searchText = '';

  @observable searchTextTimeout = null;

  @observable updatedSchoolImageContentItemId = null;

  @computed get schoolsArray() {
    return this.schoolsMap ? Array.from(this.schoolsMap.values()) : [];
  }

  @computed get lastSearchSchoolsArray() {
    return this.lastSearchSchoolsMap ? Array.from(this.lastSearchSchoolsMap.values()) : [];
  }

  @action setCurrentSchoolId = (id) => {
    if (!id || id === 'null' || id === 'undefined') {
      this.currentSchoolId = null;
    } else {
      this.currentSchoolId = id;
    }
  }

  @action setSearchText = (searchText) => {
    this.searchText = searchText;
  }

  @action setSearchTextTimeout = (searchTextTimeout) => {
    this.searchTextTimeout = searchTextTimeout;
  }

  getSchool = (id) => {
    if (id) {
      const school = this.schoolsMap.get(id);
      if (school !== null && school !== undefined) {
        return school;
      }
    }
    return null;
  }

  getCurrentSchool = () => {
    if (this.currentSchoolId) {
      const school = this.schoolsMap.get(this.currentSchoolId);
      if (school !== null && school !== undefined) {
        return school;
      }
    }
    return null;
  }

  @action fetchSatelliteInstitutions = async (searchText = '', page = 0, pageSize = this.SCHOOL_FETCH_PAGE_SIZE) => {
    try {
      let institutionIds;
      if (userManager.isDistrictAdmin) {
        // fetch all institutions associated with any districts belonging to the logged in user
        // note that there will typically only ever be one district associated with a DA
        institutionIds = userManager.institutionIds.filter((institutionId) => {
          const institution = userManager.institutionsMap.get(institutionId);
          return institution && (institution.district || institution.type?.toLowerCase() === 'district');
        });
      } else {
        institutionIds = userManager.institutionIds.filter((institutionId) => {
          const institution = userManager.institutionsMap.get(institutionId);
          // user primary role is NOT District Admin, so we do not want to query districts
          // i.e. we should not send any district ids to api/viewSatelliteInstitutions
          return institution && (institution.district !== true && institution?.type?.toLowerCase() !== 'district');
        });
      }
      this.setLoaded(false);
      const apiUrlPrefix = 'api/viewSatelliteInstitutions';
      let apiUrlParams = `?searchText=${searchText}`;
      apiUrlParams += `&pageSize=${pageSize}`;
      apiUrlParams += `&skip=${page * pageSize}`;
      apiUrlParams += `&institutionIds=${institutionIds.toString()}`;

      const apiUrl = `${Auth.ecms}/${apiUrlPrefix}${apiUrlParams}`;
      const response = await Auth.fetch(`${apiUrl}`, {
        method: 'GET'
      });
      if (response.status === 'SUCCESS') {
        if (!page || page <= 0) {
          await this.initSchools(response.data, searchText, response);
        } else {
          await this.loadSchools(response.data, searchText, response);
        }
      } else {
        console.error(response);
        this.setHasMore(false);
        this.setLoaded(true);
      }
    } catch (error) {
      console.error(error);
      this.setHasMore(false);
      this.setLoaded(true);
    }
  }

  @action initSchools = async (schools, searchText, response) => {
    if (this.schoolsMap) {
      this.schoolsMap.clear();
    }
    await this.loadSchools(schools, searchText, response);
  }

  @action loadSchools = async (schools, searchText, response) => {
    if (this.schoolsMap && schools && schools.length) {
      if (searchText) {
        this.lastSearchSchoolsMap.clear();
      }
      schools.forEach((school) => {
        if (school && school.id) {
          this.schoolsMap.set(school.id, school);
          if (searchText) {
            this.lastSearchSchoolsMap.set(school.id, school);
          }
        }
      });
      if (!searchText) {
        this.lastSearchSchoolsArray.forEach((school) => {
          if (school && school.id) {
            this.schoolsMap.set(school.id, school);
          }
        });
      }
      const curSize = this.schoolsMap.size;
      const actualSize = +`${response.pageTotal}`;

      if (curSize < actualSize) {
        this.setHasMore(true);
        this.setLoaded(true);
      } else {
        this.setHasMore(false);
        this.setLoaded(true);
      }
    } else {
      this.setHasMore(false);
      this.setLoaded(true);
    }
  }

  @action setHasMore = (hasMore) => {
    this.hasMore = hasMore;
  }

  @action setLoaded = (loaded) => {
    this.loaded = loaded;
  }

  fetchDirectSchoolImageUrl(school, authKey = null) {
    const { imageContentItemId } = school;
    return new Promise((resolve, _reject) => {
      const token = Auth.getToken();
      const headers = new Headers({
        Authorization: token
      });
      const apiUrlPrefix = `${Auth.ecms}/api/viewContentItem`;
      const apiUrlParams = `?contentItemId=${imageContentItemId}`;
      const apiUrl = `${apiUrlPrefix}${apiUrlParams}`;
      Auth.fetch(apiUrl, {
        method: 'GET',
        headers
      }).then((response) => {
        if (response.status === 'SUCCESS') {
          const contentItem = response.contentItems[0];
          const fullImageUrl = this.getFullImageUrl(contentItem.id);
          const directSchoolImageUrl = `${contentItem.fullImageUrl}&authKey=${authKey}`;
          resolve({ fullImageUrl, directSchoolImageUrl });
        } else {
          resolve(null);
        }
      }).catch(() => {
        resolve(null);
      });
    });
  }

  uploadSchoolImage = async (formData, authKey) => {
    const token = Auth.getToken();
    const headers = new Headers({
      Authorization: token,
      Accept: 'application/json; text/plain'
    });
    try {
      const apiUrl = `${Auth.ecms}/api/uploadFileResource`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData,
        headers
      });
      const schoolImageUploadData = await response.json();
      this.updatedSchoolImageContentItemId = schoolImageUploadData.contentItemId;

      const fullImageUrl = this.getFullImageUrl(schoolImageUploadData.contentItemId);
      const directSchoolImageUrl = `${schoolImageUploadData.url}&authKey=${authKey}`;

      return { fullImageUrl, directSchoolImageUrl };
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  getFullImageUrl = (imageContentItemId) => `${Auth.ecms}/api/redirectToResource?contentItemId=${imageContentItemId}`

  setImageToSchool = async (schoolId, imageContentItemId) => {
    const token = Auth.getToken();
    const headers = new Headers({
      Authorization: token
    });
    const apiUrlPrefix = `${Auth.ecms}/api/updateInstitutionImage`;
    const apiUrlParams = `?id=${schoolId}&contentItemId=${imageContentItemId}`;

    const apiUrl = `${apiUrlPrefix}${apiUrlParams}`;
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers
    });
    const json = await response.json();
    const updatedSchool = { ...json.data[0] };
    this.schoolsMap.set(updatedSchool.id, updatedSchool);

    return updatedSchool;
  }

  createSchoolImageUrl = (imageUrl) => {
    if (!imageUrl) return null;
    const date = new Date(); /* to prevent caching of image */
    return `${imageUrl}&authKey=${Auth.authKey}&rn=${date.getTime()}`;
  }
}
export default new SchoolManager();
