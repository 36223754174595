import React, {useState} from 'react';
import { Button } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import Modal from '../Modal';

const ArchiveOrActivateStudentModal = (props) => {
  const { open, close, title, message, confirmButtonName, confirmHandler } = props;
  const ModalBanner = SatCoreComponent('ModalBanner');

  const [archivingOrActivatingUser, setArchivingOrActivatingUser] = useState(false);

  const handleConfirmClick = () => {
    setArchivingOrActivatingUser(true);
    confirmHandler();
  };

  return (
    <Modal
      onClose={close}
      open={open}
      size='tiny'>
      <ModalBanner label={title} onClose={close} />
      <Modal.Content scrolling>
        <p>{message}</p>
      </Modal.Content>
      <Modal.Actions>
        {!archivingOrActivatingUser && <Button basic className='cancelButton' onClick={close} primary>Cancel</Button>}
        <Button className='saveButton'
          disabled={archivingOrActivatingUser}
          loading={archivingOrActivatingUser}
          onClick={handleConfirmClick}
          primary>{confirmButtonName}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ArchiveOrActivateStudentModal;

SatCoreRegister('ArchiveOrActivateStudentModal', ArchiveOrActivateStudentModal);
