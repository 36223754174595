import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

// TODO remove // import iconDisengage from '../../img/icon-disengage.svg';
// TODO remove // import iconDisengageGreyed from '../../img/icon-disengage-greyed.svg';
// TODO remove // import iconEyeLineThruBold from '../../img/icon-eye-with-line-thru-bold.svg';
// TODO remove // import iconSpeed from '../../img/icon-speed.svg';
// TODO remove // import iconSpeedGreyed from '../../img/icon-speed-greyed.svg';
import iconEyeBold from '../../img/icon-eye-bold.svg';
import iconEyeLineThru from '../../img/icon-eye-with-line-thru.svg';

import '../../css/reports/ReportEngagementFilter.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

const ReportEngagementFilter = observer((props) => {
  const { reportContextManager, reportIdentityManager } = useContext(MobXProviderContext);
  const { t } = props;

  const InfoIcon = SatCoreComponent('InfoIcon');
  const ReportCellValueToggler = SatCoreComponent('ReportCellValueToggler');

  function toggleFilter(id) {
    const { engagementFilters } = reportContextManager;
    engagementFilters[id] = !engagementFilters[id];
    reportContextManager.setEngagementFilters({ ...engagementFilters });
  }

  const { engagementFilters } = reportContextManager;

  const renderReportEngagementFilter = () => {
    const { reportInfoClassNames } = reportIdentityManager;
    return (
      <>
        <div className={`report-engagement-filter ${reportInfoClassNames}`}>
          {/* HEADER: LOW ENGAGEMENT */}
          <div className='label-info low-engagement'>
            <div>{t('lowEngagementLabel')}</div>
            <div className='info-icon'>
              <InfoIcon popupOptions={{ content: t('lowEngagementHeaderDescription'), on: 'click' }}
                useWhiteInfoIcon={false} />
            </div>
          </div>
          {/* HEADER: HIGH ENGAGEMENT */}
          <div className='label-info high-engagement'>
            <div>{t('highEngagementLabel')}</div>
            <div className='info-icon'>
              <InfoIcon popupOptions={{ content: t('highEngagementHeaderDescription'), on: 'click', position: 'top right' }}
                useWhiteInfoIcon={false} />
            </div>
          </div>
          {/* BODY: LOW ENGAGEMENT */}
          <div className='eye-btn low-engagement'>
            <button
              className={(engagementFilters.lowEngagement) ? 'filter' : 'filter-off'}
              onClick={() => toggleFilter('lowEngagement')}
              type='button'>
              <img alt='' className='eye' src={(engagementFilters.lowEngagement) ? iconEyeBold : iconEyeLineThru} />
              <span className='low-engagement-description'>{t('lowEngagementDescription')}</span>
              {/* TODO remove */}
              {/* <img alt='' className='lowEngagement' src={(engagementFilters.speed) ? iconSpeed : iconSpeedGreyed} /> */}
            </button>
          </div>
          {/* BODY: HIGH ENAGEMENT */}
          <div className='eye-btn high-engagement'>
            <button
              className={(engagementFilters.highEngagement) ? 'filter' : 'filter-off'}
              onClick={() => toggleFilter('highEngagement')}
              type='button'>
              <img alt='' className='eye' src={(engagementFilters.highEngagement) ? iconEyeBold : iconEyeLineThru} />
              <span className='high-engagement-description'>{t('highEngagementDescription')}</span>
              {/* TODO remove */}
              {/* <img alt='' className='disengage' src={(engagementFilters.disengage) ? iconDisengage : iconDisengageGreyed} /> */}
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {renderReportEngagementFilter()}
      <ReportCellValueToggler />
    </>
  );
});
export default ReportEngagementFilter;

SatCoreRegister('ReportEngagementFilter', ReportEngagementFilter);
