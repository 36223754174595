import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { SatCoreRegister } from '../SatCoreRegistry';
import '../css/D3PieChart.less';

/*
 * data - array of objects with the data for each pie wedge
 * width - number specifying the px width of the pie
 * height - number specifying the px height of the pie
 * fontSize - number specifying the px font size of the labels
 * labelPlacement - number between 0 and 1 specifying distance of label from center
 */
function D3PieChart({ data, width, height, fontSize, fontColor, labelPlacement }) {
  // set defaults for optional parameters
  width = (width) || 100;
  height = (height) || 100;
  fontSize = (fontSize) || 12;
  fontColor = (fontColor) || '#fff';
  labelPlacement = (labelPlacement) || 0.5;

  const containerRef = useRef();
  const svgRef = useRef();

  let tooltip;
  const margin = 3;

  useEffect(() => {
    draw();
  }, [data]);

  // the radius of the pie chart is half the smallest side
  const radius = Math.min(width, height) / 2 - margin;

  const lighten = (color, amount) => {
    const colorScale = d3.scaleLinear()
      .domain([1, 10])
      .range([color, '#fff']);
    return colorScale(amount);
  };

  const pieArc = d3.arc()
    // .innerRadius(radius * .2) // donut
    .innerRadius(0)
    .outerRadius(radius);

  const labelArc = d3.arc()
    .innerRadius(radius * labelPlacement)
    .outerRadius(radius);

  const mouseenter = function (event, d) {
    // position the tooltip over the pie wedge
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = containerRef.current.getBoundingClientRect();
    const left = elemRect.left - bodyRect.left;
    const top = elemRect.top - bodyRect.top;
    const x = pieArc.centroid(d)[0] + (width / 2) + left;
    const y = pieArc.centroid(d)[1] + (height / 2) + top;

    // create, position, and unhide the tooltip
    tooltip
      .html(d.data.tooltip)
      .style('left', `${x}px`)
      .style('top', `${y}px`)
      .style('opacity', 1);

    // highlight the pie wedge
    d3.select(this).select('path')
      .style('stroke', lighten(d.data.color, 5))
      .attr('stroke-width', '3px');
  };

  const mouseleave = function () {
    // hide the tooltip
    tooltip.html('<div></div>')
      .style('opacity', 0);

    // unhighlight the pie wedge
    d3.select(this).select('path')
      .style('stroke', 'none');
  };

  const draw = () => {
    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr(
        'transform',
        `translate(${width / 2},${height / 2}) rotate(0)`
      );

    // create the tooltip div and hide it
    tooltip = d3.select(containerRef.current)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'pie-chart-tooltip');

    // // this draws a border around the svg to help with debugging
    // const borderPath = svg.append('rect') // eslint-disable-line no-unused-vars
    //   .attr('x', -(width/2))
    //   .attr('y', -(height/2))
    //   .attr('width', width)
    //   .attr('height', height)
    //   .style('stroke', 'green')
    //   .style('fill', 'none')
    //   .style('stroke-width', 1);

    const pie = d3.pie().value((d) => Number(d.value)).sort(null);

    const g = svg.selectAll('g')
      .data(pie(data))
      .enter()
      .append('g');

    g.append('path')
      .attr('d', pieArc)
      .style('fill', (d) => d.data.color);

    g.append('text')
      .text((d) => d.data.label)
      .attr('transform', (d) => `translate(${labelArc.centroid(d)})`)
      .style('text-anchor', 'middle')
      .style('font-size', fontSize)
      .style('font-weight', 'bold')
      .style('fill', fontColor);

    g.on('mouseenter', mouseenter);
    g.on('mouseleave', mouseleave);
  };

  return (
    <div ref={containerRef} className='d3-pie-chart'>
      <svg ref={svgRef} />
    </div>
  );
}

export default D3PieChart;

SatCoreRegister('D3PieChart', D3PieChart);
