import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ReactHtmlParser from 'react-html-parser';

import { Button, Loader } from 'semantic-ui-react';
import Auth from '../managers/AuthManager';

import '../css/LearnosityPrintPreview.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import UtilityService from '../services/UtilityService';

import { getSessionStorageItem, removeSessionStorageItem } from '../utils/session';

export default
@inject('learnosityItemsManager', 'assignmentManager', 'userManager', 'learnosityDataManager', 'gradebookManager')
@observer
class LearnosityPrintPreview extends Component {
  itemsApp;

  constructor(props) {
    super(props);
    this.state = {
      activityInstanceId: '',
      assignmentId: '',
      classroomId: '',
      contentTitle: '',
      contentSubTitle: '',
      studentName: '',
      isItemsAPILoaded: false,
      responseData: {},
      questionData: null,
      showErrorDialog: false,
      errorMessage: '',
      cancelStart: false,
      showAnswers: true,
      returnView: '',
      totalMaxPoints: '',
      totalPoints: ''
    };
  }

  async componentDidMount() {
    const { learnosityDataManager } = this.props;

    if (!document.getElementById('learnosity-script') ||
      !document.getElementById('learnosity-script').attributes.src.value.includes('items.learnosity')) {
      const script = document.createElement('script');
      script.src = 'https://items.learnosity.com/?v2022.1.LTS';
      script.async = true;
      document.body.appendChild(script);
    } else {
      this.setState({ isItemsAPILoaded: true });
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('activityInstanceId')) {
      const activityInstanceId = urlParams.get('activityInstanceId');
      this.setState({ activityInstanceId });
    }

    if (urlParams.has('assignmentId')) {
      const assignmentId = urlParams.get('assignmentId');
      this.setState({ assignmentId });
    }

    let contentItemId = null;
    if (urlParams.has('contentItemId')) {
      contentItemId = urlParams.get('contentItemId');
      this.setState({ contentItemId });
    }

    // Some string params are in session storage because they could contain reserved url chars.
    const instanceDataString = getSessionStorageItem('c2c_printPreviewData');
    const instanceData = JSON.parse(instanceDataString); 

    if (instanceData) {
      if (instanceData.contentTitle) {
        this.setState({ contentTitle: instanceData.contentTitle });
      }
      if (instanceData.contentSubTitle) {
          this.setState({ contentSubTitle: instanceData.contentSubTitle });
      }
      if (instanceData.studentName) {
        this.setState({ studentName: instanceData.studentName });
      }

      if (instanceData.grade) {
        this.setState({ grade:instanceData.grade });
      }
  
      if (instanceData.points) {
        this.setState({ totalPoints: instanceData.points});
      }
  
      if (instanceData.maxPoints) {
        this.setState({ totalMaxPoints: instanceData.maxPoints });
      }

      removeSessionStorageItem('c2c_printPreviewData');
    }

    if (urlParams.has('showAnswers')) {
      const showAnswers = urlParams.get('showAnswers') === 'true';
      this.setState({ showAnswers });
    }

    if (urlParams.has('returnView')) {
      const returnView = urlParams.get('returnView');
      this.setState({ returnView });
    }

    this.getQuestionData(contentItemId);
  }

  async componentWillUnmount() {
    const { learnosityDataManager } = this.props;
    learnosityDataManager.clearAll();
  }

  async getQuestionData(contentItemId) {
    const { learnosityDataManager } = this.props;
    let questionData = [];
    let responseData = [];
    const { responseJSON } = learnosityDataManager;

    if (!contentItemId) {
      questionData = learnosityDataManager.questionData;
      responseData = responseJSON.responses;
      if (questionData && questionData.length > 0) {
        this.renderDropdownPossibleValues(responseData, questionData)
      }
    } else {
      learnosityDataManager.getLearnosityDataApiActivityQuestions(contentItemId).then((data) => {
        if (data && data.status && data.status === 'SUCCESS') {
          if (data.questions) {
            questionData = data.questions;
            learnosityDataManager.setQuestionData(questionData);
          }
        } else {
          if (data && data.statusMessage) {
            console.error(`No question data found for contentItem: ${contentItemId}, message: ${data.statusMessage}`); // TODO do we have a standard notification for this?
          } else {
            this.openWarningModal('An unexpected error has occurred; please try again.');
            console.error(`No question data found for contentItem: ${contentItemId}`); // TODO do we have a standard notification for this?
          }
        }
        if (questionData && questionData.length > 0) {
          this.renderDropdownPossibleValues(responseData, questionData);
        }
      }).catch((err) => {
        console.error(err);
      });
    }
  }

  async renderDropdownPossibleValues(responseData, questionData) {
    const { learnosityDataManager } = this.props;
    // SEE IF ANY OF THE QUESTIONS HAVE TYPE "clozedropdown" OR "imageclozedropdown"
    // IF SO, EXTRACT THE "POSSIBLE_RESPONSES" ARRAY (OF ARRAYS) OUT SO WE CAN RENDER
    // THE OPTIONS FOR EACH DROPDOWN IN THE ARRAY.  THE LENGTH OF THE POSSIBLE_RESPONSES
    const dropdownPossibleValues = new Map();
    const dropdownCorrectValues = new Map();
    const itemsQuestions = await learnosityDataManager.getLearnosityDataApiItemQuestions(questionData);
    const itemRefsArr = (itemsQuestions && Object.keys(itemsQuestions).length > 0) ? Object.keys(itemsQuestions): [];
    for (const itemRef of itemRefsArr) {
      // each item may have multiple questions
      const itemPossibleValues = new Map();
      const itemCorrectValues = new Map();
      for (const itemQuestion of itemsQuestions[itemRef]) {
        const itemQuestionType = itemQuestion.type;
        if (itemQuestionType === 'clozedropdown' || itemQuestionType === 'imageclozedropdown') {
          const possibleValues = itemQuestion?.data?.possible_responses;
          const correctValues = itemQuestion?.data?.validation?.valid_response?.value;
          if (possibleValues) {
            itemPossibleValues.set(itemQuestion.reference, possibleValues)
          }
          if (correctValues) {
            itemCorrectValues.set(itemQuestion.reference, correctValues);
          }
        }
      }
      dropdownPossibleValues.set(itemRef, itemPossibleValues);
      dropdownCorrectValues.set(itemRef, itemCorrectValues);
    }
    this.setState({ responseData, questionData, dropdownPossibleValues, dropdownCorrectValues });
  }

  async startLoadActivity() {
    // load the activity
    // poll/wait for Learnosity javascript to load
    if (!this.state.isItemsAPILoaded) {
      const interval = await setInterval(async () => {
        if (window.LearnosityItems && !this.state.cancelStart) {
          // stop polling
          clearInterval(interval);
          // render the items api
          await this.loadActivity();
        }
      }, 1000);
    }
  }

  async componentDidUpdate() {
    // if this is the first time, load the activity
    if (this.state.questionData && this.state.questionData.length > 0) {
      this.startLoadActivity();
    }
  }

  // READY listener called when Learnosity API is ready
  readyListener = () => {
    // LEARNOSITY IS INITIALIZED BIND EVENTS HERE.
    this.setState({ isItemsAPILoaded: true });
  };

  // ERROR Listener to capture errors thrown from the API
  errorListener = (e) => {
    console.error('LearnosityScoringModal: learnosity error', e);
  };

  eventOptions = {
    readyListener: this.readyListener,
    errorListener: this.errorListener
  };

  async closeItemsApp() {
    await this.clearItemsApp();
  }

  async clearItemsApp() {
    // reset the items app
    if (this.itemsApp != null) {
      this.itemsApp.reset();
    }
  }

  // adds a css stylesheet to the dom
  setLearnosityStylesheet(learnosityStylesheet) {
    if (learnosityStylesheet) {
      // if a link exists, delete it.
      const existingStylesheet = document.querySelector('#learnosity-stylesheet');
      if (existingStylesheet) {
        existingStylesheet.parentNode.removeChild(existingStylesheet);
      }
      // create the link
      const link = document.createElement('link');
      link.href = learnosityStylesheet;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.id = 'learnosity-stylesheet';
      document.body.appendChild(link);
    }
  }

  async loadActivity() {
    const { learnosityItemsManager } = this.props;
    const { activityInstanceId, contentItemId, showAnswers } = this.state;

    if (contentItemId) {
      learnosityItemsManager.getLearnosityItemsApiPreviewRequest(contentItemId, showAnswers, 'inline').then((requestJson) => {
        // set the stylesheet
        this.setLearnosityStylesheet(requestJson.stylesheet);
        // Initialize the items api
        this.itemsApp = window.LearnosityItems.init(JSON.parse(requestJson.init), this.eventOptions);
      }).catch((err) => {
        this.openWarningModal(err);
        this.setState({ cancelStart: true });
      });
    } else if (activityInstanceId) {
      learnosityItemsManager.getLearnosityItemsApiInlineReviewRequest(activityInstanceId).then((requestJson) => {
        // set the stylesheet
        this.setLearnosityStylesheet(requestJson.stylesheet);
        // Initialize the items api
        this.itemsApp = window.LearnosityItems.init(JSON.parse(requestJson.init), this.eventOptions);
      }).catch((err) => {
        this.openWarningModal(err);
        this.setState({ cancelStart: true });
      });
    }
  }

  openWarningModal = (message) => {
    this.setState({ showErrorDialog: true, errorMessage: message });
  }

  closeWarningModal = () => {
    this.setState({ showErrorDialog: false, errorMessage: '' });
  }

  // this creates a scoring control for injecting into learnosity dom for teacher
  renderLearnosityScoringControl = (itemRefId, responses) => {
    const { userManager } = this.props;
    const components = [];
    if (responses) {
      for (let i = 0; i < responses.length; i++) {
        const response = responses[i];
        let score = null;
        if (response.score !== undefined && response.score !== null) {
          score = Number.isInteger(response.score) ? Number(response.score) : Number(response.score).toFixed(2);
        }
        const maxScore = response.max_score;
        // set the score container height to the height of the question to align them.
        const questionElem = document.getElementById(response.response_id);
        const questionContentHeight = (questionElem) ? document.getElementById(response.response_id).clientHeight : null;
        components.push(
          <div
            key={`custom-score-col-${response.question_reference}`}
            className='l-scoring-control-outer-container'>
            <div className='l-scoring-control-background'>
              {userManager.isTeacher && this.calcFacultyControlPanelState(score, maxScore) === 'SCORE' && (
              <div className='l-scoring-control-container'>
                <div className='scoreText'>Score (Points)</div>
                <div className='scoreText'>
                  <span>
                    {score}&nbsp;/&nbsp;{maxScore}
                  </span>
                </div>
              </div>
              )}
              {userManager.isTeacher && this.calcFacultyControlPanelState(score, maxScore) === 'NO_SCORE' && (
                <div className='l-scoring-control-container'>
                  {/* <div className='scoreText'>Score (Points)</div> */}
                  <div className='no-score-message'>
                    No score required for zero max score.
                  </div>
                </div>
              )}
              {userManager.isTeacher && this.calcFacultyControlPanelState(score, maxScore) === 'NONE' && (
                <div className='l-scoring-control-container'>
                  {/* <div className='scoreText'>Score (Points)</div> */}
                  <div className='null-score-message'>
                    No score required for practice.
                  </div>
                </div>
              )}
              {userManager.isTeacher && this.calcFacultyControlPanelState(score, maxScore) === 'UNSCORED' && (
                <div className='l-scoring-control-container'>
                  {/* <div className='scoreText'>Score (Points)</div> */}
                  <div className='null-score-message'>
                    Not yet scored.
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      }
      return components;
    }
  }

  // create a card for each item/response to render learnosity
  renderScoringCards = () => {
    const { dropdownCorrectValues, dropdownPossibleValues, responseData, isItemsAPILoaded, showAnswers } = this.state;
    const { publisherSatelliteCode } = Auth;
    const questionRefs = this.state.questionData;
    const components = [];
    if (questionRefs != null) {
      if (questionRefs.length > 0) {
        let questionIndex = 1;
        for (let i = 0; i < questionRefs.length; i++) {
          // questions can have objects or strings for references
          const questionRef = questionRefs[i];
          const itemRefId = (typeof questionRef === 'object') ? questionRef.reference : questionRef;
          const itemResponses = responseData && responseData.length > 0 ? responseData.filter((element) => element.item_reference === itemRefId) : [];

          // Dropdown questions have unsurfaced options we need to show GAL-734, see if there are any for current question
          const itemPossibleValues = dropdownPossibleValues.get(itemRefId);
          const itemCorrectValues = dropdownCorrectValues.get(itemRefId);
          const dropdownPossibleValuesArr = [];
          const dropdownCorrectValuesArr = [];
          if (itemPossibleValues.size > 0) {
            for (const itemQuestionRef of itemPossibleValues.keys()) {
              const itemValuesArr = itemPossibleValues.get(itemQuestionRef);
              for (const questionValuesArr of itemValuesArr) {
                dropdownPossibleValuesArr.push(questionValuesArr);
              }
            }
          }
          if (itemCorrectValues.size > 0  && showAnswers) {
            for (const itemQuestionRef of itemCorrectValues.keys()) {
              const itemCorrectValuesArr = itemCorrectValues.get(itemQuestionRef);
              for (const questionCorrectValuesArr of itemCorrectValuesArr) {
                dropdownCorrectValuesArr.push(questionCorrectValuesArr);
              }
            }
          }

          let cardClassName = 'l-scoring-card';
          if (i > 0) {
            cardClassName += ' page-break';
          }
          components.push(
            <div key={itemRefId} className={cardClassName} id={`scoring-card-${itemRefId}`}>
              <div className='l-scoring-card-header'>
                <div>
                  Item #
                  {' '}
                  {questionIndex++}
                </div>
                <div className='custom-score-col'>
                  {isItemsAPILoaded && this.renderLearnosityScoringControl(itemRefId, itemResponses)}
                </div>
              </div>
              <div className='l-scoring-card-content'>
                <div className='lrn-question-row'>
                  <div className='lrn-question-col'>
                    <span
                      className={showAnswers ? 'learnosity-item hide-possibility-list' : 'learnosity-item'}
                      data-reference={itemRefId} />
                  </div>
                </div>
                {(dropdownPossibleValuesArr) &&
                  <div className='lrn-dropdown-options-row'>
                    <ul className='lrn-dropdown-options-list'>
                    {dropdownPossibleValuesArr.map((itemValuesArr, index) => {
                        return (
                          <li key={index} className='lrn-dropdown-option'>
                              <span className='lrn-dropdown-option-label'>Item # {questionIndex - 1} Dropdown {index + 1} options:&nbsp;</span>
                              <span className='lrn-dropdown-option-text'>{itemValuesArr.toString().split(',').join(', ')}&nbsp;</span>
                              { dropdownPossibleValuesArr && showAnswers &&
                                <>
                                  <span className='lrn-dropdown-option-label'>&nbsp;Correct:&nbsp;&nbsp;&nbsp;</span>
                                  <span className='lrn-dropdown-option-text'>{dropdownCorrectValuesArr[index]}&nbsp;</span>
                                </>
                              }
                          </li>
                        );
                      })
                    }
                    </ul>
                  </div>
                }
              </div>
              {publisherSatelliteCode === 'GALL_SATELLITE' && <div className='divPrintFooter'>&copy; Gallopade</div>}
            </div>
          );
        }
        return <div className='l-scoring-card-inner-container'>{components}</div>;
      }
      return <div className='l-no-questions-container'>No Question Data Returned.</div>;
    }
    return <div className='l-no-questions-container'><Loader active /></div>;
  }

  // Question scoring state will have four possible values
  // NONE - hide the whole score, SCORE - show score, NO_SCORE - show no score messaging
  // UNSCORED - for manual questions that are not yet scored, show unscored message
  calcFacultyControlPanelState(score, maxScore) {
    const goodMaxScore = (maxScore !== undefined && maxScore !== null);
    // console.log('score: ', score);//remove
    // console.log('maxScore: ', maxScore);//remove
    if (score === null && goodMaxScore) {
      return 'UNSCORED';
    } else if (score != null && goodMaxScore) {
      if (maxScore > 0) {
        // show scoring controls
        return 'SCORE';
      }
      // show message: This question has zero max score, no score required.
      return 'NO_SCORE';
    }
    // show message: This is a practice question, no score required.
    return 'NONE';
  }

  returnToPreviousView = async () => {
    const { assignmentId, classroomId } = this.state;
    const { history } = this.props;
    await this.closeItemsApp();
    // this.props.history.push(`/gradebook?assignmentId=${assignmentId}&view=gradebook&fromAssignments=false&classroomId=${classroomId}`);
    history.goBack();
  }

  ProgressBar = (props) => {
    const { color, percent } = props;
    return (
      <div className={`progress-bar ${color}`}>
        <div className={color} style={{ width: `${percent}%`, maxWidth: '100%' }} />
      </div>
    );
  }

  render() {
    const { gradebookManager } = this.props;
    const { ProgressBar } = this;
    // const transformFn = UtilityService.reactHtmlParserTransform;
    let gradeClassName = null;
    let grade = null;
    if (this.state.grade && !isNaN(this.state.grade)) {
      gradeClassName = gradebookManager.getGradeClassName(this.state.grade);
      grade = this.state.grade;
    }

    const processedContentTitle = this.state.contentTitle ? UtilityService.reactHtmlParserWrapper(this.state.contentTitle).parsedNoPWrap : '';
    const processedContentSubTitle = this.state.contentSubTitle ? UtilityService.reactHtmlParserWrapper(this.state.contentSubTitle).parsedNoPWrap : '';
    return (
      <div className='learnosity-preview-container'>
        <div className='l-preview-header-container'>
          <div className='l-preview-header'>
            <div className='preview-header-title'>
              <div className='preview-content-title'>
                {processedContentTitle}
              </div>
              <div className='preview-content-subtitle'>
                {processedContentSubTitle}
              </div>
              <div className='preview-content-student-name'>
                {this.state.studentName && `Student: ${this.state.studentName}`}
              </div>
            </div>
            {grade && (
              <div className='progress-bar-container'>
                <div className={`progress-text ${gradeClassName}`}>
                  {grade}
                  %
                  <span className='total-points'>
                    ({this.state.totalPoints}/{this.state.totalMaxPoints})
                  </span>
                </div>
                <ProgressBar color={gradeClassName} percent={grade} />
              </div>
            )}
            <div className='btn-return-gradebook-wrapper'>
              {this.state.isItemsAPILoaded && (
              <Button
                className='btn-print'
                onClick={() => window.print()}
                primary>
                Print
              </Button>
              )}
              <Button
                className='btn-return-gradebook'
                onClick={() => this.returnToPreviousView()}
                primary>
                Return
              </Button>
            </div>
          </div>
        </div>
        <div className='l-scoring-card-outer-container'>
          {this.renderScoringCards()}
        </div>
      </div>
    );
  }
}

SatCoreRegister('LearnosityPrintPreview', LearnosityPrintPreview);
