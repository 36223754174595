import React, { useContext, useState } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import { SatCoreComponent, SatCoreRegister } from '../../../SatCoreRegistry';

import PopupService from '../../../services/PopupService';
import ReportStandardsClassroomDetailStudentsService from '../../../services/reports/ReportStandardsClassroomDetailStudentsService';
import ReportTableService from '../../../services/reports/ReportTableService';

const ReportResourceCountCell = observer((props) => {
  const { allowOnClick, resourceCountColumnName } = props;

  const {
    assignmentManager, classroomManager, reportIdentityManager
  } = useContext(MobXProviderContext);

  const [shouldRenderAssignmentsModal, setShouldRenderAssignmentsModal] = useState(false);

  const { reportInfoClassNames } = reportIdentityManager;

  const AssignmentsModal = SatCoreComponent('AssignmentsPopup');

  const handleClearReportResourceAssignmentModal = () => {
    assignmentManager.setLoaded(false);
    assignmentManager.clearAssignments();
  };

  const renderReportResourceCountCell = () => {
    const resourceCount = typeof props.value === 'number' ? Math.round(props.value) : 0;
    const resourceCountStr = `${resourceCount}x`;
    const content = (
      <div className={`cell-resource-count-content ${reportInfoClassNames}`}>
        {resourceCountStr}
      </div>
    );
    let cellResourceCountTriggerClassNames = `cell-resource-count-trigger ${reportInfoClassNames}`;
    cellResourceCountTriggerClassNames += allowOnClick ? ' clickable' : '';
    const trigger = (
      <div className={cellResourceCountTriggerClassNames}
        onClick={() => {
          if (allowOnClick) {
            handleClearReportResourceAssignmentModal();
            setShouldRenderAssignmentsModal(true);
          }
        }}>
        {resourceCountStr}
      </div>
    );

    let assignmentsModalJsx;
    if (shouldRenderAssignmentsModal) {
      let courseElementRow, courseResourceElementId;
      if (reportIdentityManager.isCourseReport) {
        courseElementRow = props.row.original;
        courseResourceElementId = courseElementRow.courseResourceElementId || courseElementRow.parentElementId;
      } else if (reportIdentityManager.isStandardsReport) {
        const standard = props.row.original;
        const { initReportCourseElementForModal, initReportCourseElements } = ReportStandardsClassroomDetailStudentsService;
        const reportCourseElement = initReportCourseElements({
          async: false,
          returnOnly: true,
          standardElementId: standard.id
        })[0];
        (async () => {
          initReportCourseElementForModal(reportCourseElement);
        })();

        courseElementRow = { ...standard, ...reportCourseElement };
        courseResourceElementId = courseElementRow.courseResourceElementId || courseElementRow.parentElementId;
      }

      const urlParams = new URLSearchParams(window.location.search);
      const classroomId = urlParams.get('classroomId');
      classroomId && classroomManager.setCurrentClassroomId(classroomId);
      assignmentsModalJsx = (
        <AssignmentsModal
          {...props}
          clearFirst='true'
          courseElementRow={{ ...courseElementRow, courseResourceElementId }}
          hideSelf={() => {
            handleClearReportResourceAssignmentModal();
            setShouldRenderAssignmentsModal(false);
          }}
          resourceCountColumnName={resourceCountColumnName} // 'taken' || 'remaining' || 'assignments'
          showCourseDropdown={false}
          skipClearBreadcrumbsOnClose={true} />
      );
    }

    return (
      <>
        {PopupService.renderPopup({
          className: `cell-resource-count-popup ${reportInfoClassNames}`,
          content,
          disabled: true, // TODO set to false if future sprint requires rollover for resource count
          trigger
        })}
        {assignmentsModalJsx}
      </>
    );
  };

  if (!props || ReportTableService.isRowExemptFromHavingValue(props.row)) {
    return null;
  }
  return renderReportResourceCountCell();
});
export default ReportResourceCountCell;

SatCoreRegister('ReportResourceCountCell', ReportResourceCountCell);
