import React from 'react';
import { observer } from 'mobx-react';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import { register } from '../../../i18n';

import EngagementService from '../../../services/EngagementService';

const EngagementHeaderAuxiliaryFeedbackUsageCells = observer((props) => {
  const t = register('EngagementTable');

  const renderEngagementHeaderAuxiliaryFeedbackUsageCells = () => {
    return (
      <>
        {renderEngagementHeaderAuxiliaryFeedbackUsageCell({
          allowPopupComponent: true,
          cellText: t('engagedCorrectOnFirstAttempt'),
          className: 'engagement-header-label detail-feedback-usage-header engagedCorrectOnFirstAttempt',
          isHeaderCell: true,
          popupContent: t('engagedCorrectOnFirstAttemptDescription'),
          popupOn: 'click'
        })}
        {EngagementService.allowEngagementColumn('tryAgainAvailable') &&
          renderEngagementHeaderAuxiliaryFeedbackUsageCell({
            allowPopupComponent: true,
            cellText: t('engagedTryAgainUsage'),
            className: 'engagement-header-label detail-feedback-usage-header engagedTryAgainUsage',
            isHeaderCell: true,
            popupContent: t('engagedTryAgainUsageDescription'),
            popupOn: 'click'
          })}
        {EngagementService.allowEngagementColumn('showHintAvailable') &&
          renderEngagementHeaderAuxiliaryFeedbackUsageCell({
            allowPopupComponent: true,
            cellText: t('engagedHintUsage'),
            className: 'engagement-header-label detail-feedback-usage-header engagedHintUsage',
            isHeaderCell: true,
            popupContent: t('engagedHintUsageDescription'),
            popupOn: 'click'
          })}
        {EngagementService.allowEngagementColumn('showSolutionAvailable') &&
          renderEngagementHeaderAuxiliaryFeedbackUsageCell({
            allowPopupComponent: true,
            cellText: t('engagedSampleAnswerUsage'),
            className: 'engagement-header-label detail-feedback-usage-header engagedSampleAnswerUsage',
            isHeaderCell: true,
            popupContent: t('engagedSampleAnswerUsageDescription'),
            popupOn: 'click'
          })}
        {EngagementService.allowEngagementColumn('showAnswerAvailable') &&
          renderEngagementHeaderAuxiliaryFeedbackUsageCell({
            allowPopupComponent: true,
            cellText: t('engagedShowCorrectAnswerUsage'),
            className: 'engagement-header-label detail-feedback-usage-header engagedShowCorrectAnswerUsage',
            isHeaderCell: true,
            popupContent: t('engagedShowCorrectAnswerUsageDescription'),
            popupOn: 'click'
          })}
      </>
    );
  };

  const renderEngagementHeaderAuxiliaryFeedbackUsageCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementHeaderAuxiliaryFeedbackUsageCells();
});
export default EngagementHeaderAuxiliaryFeedbackUsageCells;

SatCoreRegister('EngagementHeaderAuxiliaryFeedbackUsageCells', EngagementHeaderAuxiliaryFeedbackUsageCells);
