import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Image, Popup, Radio } from 'semantic-ui-react';
import Modal from './Modal';

import info from '../img/group-1583.svg';
import '../css/AssignmentReportSettings.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { ASSIGNMENT_STATUS } from '../managers/AssignmentManager';

export default @inject(
  'assignmentManager', 'classroomManager',
  'courseManager', 'gradebookManager')
@observer
class AssignmentReportSettings extends Component {
  state = {
    assignmentStatus: '',
    assignmentId: '',
    markGradingCompleteOpen: false,
    originalIncludeInReports: false,
    originalStudentReview: false,
    originalScoresReleased: false,
    includeInReports: false,
    studentReview: false,
    scoresReleased: false
  }

  constructor(props) {
    super(props);
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
  }

  componentDidMount = () => {
    const { currentAssignment } = this.props;
    const assignment = currentAssignment;

    // set initial state
    this.setState({
      assignmentStatus: assignment.status,
      assignmentId: assignment.id,
      originalIncludeInReports: assignment.includeInReports,
      originalScoresReleased: assignment.scoresReleased,
      originalStudentReview: assignment.studentReview,
      includeInReports: assignment.includeInReports,
      scoresReleased: assignment.scoresReleased,
      studentReview: assignment.studentReview
    });
  }

  componentDidUpdate = async () => {
    const { assignmentManager, currentAssignment } = this.props;
    const assignment = await assignmentManager.getAssignmentAsync(currentAssignment.id);

    // Only do the following if the assignment has changed, but not while we are editing settings.
    if (assignment && this.hasAssignmentChanged(assignment) && !this.state.markGradingCompleteOpen) {
      // initialize state if we have a good assignment object
      this.setState({
        assignmentStatus: assignment.status,
        assignmentId: assignment.id,
        originalIncludeInReports: assignment.includeInReports,
        originalScoresReleased: assignment.scoresReleased,
        originalStudentReview: assignment.studentReview,
        includeInReports: assignment.includeInReports,
        scoresReleased: assignment.scoresReleased,
        studentReview: assignment.studentReview
      });
    }
  }

  hasAssignmentChanged = (assignment) =>
    // check if state properties have changed
    this.state.assignmentId && assignment.id !== this.state.assignmentId ||
      this.state.assignmentStatus && assignment.status !== this.state.assignmentStatus ||
      this.state.includeInReports && assignment.includeInReports !== this.state.includeInReports ||
      this.state.includeInReports && assignment.scoresReleased !== this.state.scoresReleased ||
      this.state.studentReview && assignment.studentReview !== this.state.studentReview

  markGradingCompleteModalOpen = () => {
    this.setState({ markGradingCompleteOpen: true });
  }

  markGradingCompleteModalClose = async (isCancel, assignmentId) => {
    const { assignmentManager, gradebookManager, classroomManager, courseManager } = this.props;
    const assignment = await assignmentManager.getAssignmentAsync(assignmentId);

    // if we are cancelling, just reset the report settings in state and close
    if (isCancel) {
      this.setState({
        markGradingCompleteOpen: false,
        includeInReports: this.state.originalIncludeInReports,
        studentReview: this.state.originalStudentReview,
        scoresReleased: this.state.originalScoresReleased
      });
    } else {
      // if this was from a save, then refresh data (code taken from EditAssignmentModal)
      if (assignment === null) {
        const classId = classroomManager.currentClassroomId;
        const courseId = courseManager.currentCourseId;
        const elementId = courseManager.currentElementId;

        await classroomManager.fetchClassroomData(classroomManager.currentClassroomId);
        await courseManager.fetchCourseData(courseId, classId, elementId, true, false);
      } else {
        await gradebookManager.fetchGradebookData(assignmentId);
        gradebookManager.setCurrentAssignment(assignment);
        this.setState({ markGradingCompleteOpen: false });
      }
    }
  }

  // Update the state for the setting that changed.
  setGradingSetting = (optionChecked, value) => {
    value = !(value); // flip the value
    if (optionChecked === 'includeInReports') {
      this.setState({ includeInReports: value });
    } else if (optionChecked === 'scoresReleased') {
      this.setState({ scoresReleased: value });
    } else if (optionChecked === 'studentReview') {
      this.setState({ studentReview: value });
    }
  }

  submitEditAssignment = async (assignmentId) => {
    const { assignmentManager, classroomManager, gradebookManager, t } = this.props;

    gradebookManager.setGradebookManagerLoadingFlagActive(true);
    const assignment = await assignmentManager.getAssignmentAsync(assignmentId);
    let result = false;

    const startRealDate = assignmentManager.convertJSStringToJSDate(assignment.timezoneStartDate, assignment.timezoneStartTime);
    const endRealDate = assignmentManager.convertJSStringToJSDate(assignment.timezoneEndDate, assignment.timezoneEndTime);
    const studentInstruction = assignment.instruction;
    let { status } = assignment;
    if (this.state.includeInReports || this.state.scoresReleased || this.state.studentReview) {
      status = ASSIGNMENT_STATUS.COMPLETED;
    }

    result = await assignmentManager.updateAssignments({
      assignmentId: assignment.id,
      startDateTime: startRealDate,
      endDateTime: endRealDate,
      instruction: studentInstruction,
      status,
      maxScore: assignment.maxScore,
      includeInReports: this.state.includeInReports,
      studentReview: this.state.studentReview,
      scoresReleased: this.state.scoresReleased,
      additionalPropertiesJson: assignment.additionalPropertiesJson,
      nickname: assignment.nickname
    });

    if (result) {
      await classroomManager.fetchClassroomData(classroomManager.currentClassroomId);
      await this.markGradingCompleteModalClose(false, assignmentId);
    } else {
      this.setState({
        serverError: true,
        serverErrorMsg: t('serverErrorMsg')
      });
    }
    gradebookManager.setGradebookManagerLoadingFlagActive(false);
  }

  render() {
    const { gradebookManager, t } = this.props;
    const { gradebookManagerLoadingFlagActive } = gradebookManager;
    const { ModalBanner } = this;
    const status = this.state.assignmentStatus;
    const { assignmentId } = this.state;
    const disabledState = !(status === ASSIGNMENT_STATUS.CLOSED || status === ASSIGNMENT_STATUS.COMPLETED);
    return (
      <>
        {!this.state.markGradingCompleteOpen && (
        <div className='assignment-status-control'>
          <div className='assignment-status-header'>
            {t('assignmentStatusLabel')}
            <Popup
              content={t('assignmentStatusDescription')}
              on='click'
              trigger={<Image alt='' className='img-info' src={info} />} />
          </div>
          <ul className='assignment-status-list'>
            <li className={status === ASSIGNMENT_STATUS.LOCKED ? 'active' : ''}>
              <div>{ASSIGNMENT_STATUS.getFlag(ASSIGNMENT_STATUS.LOCKED)}</div>
            </li>
            <li className={status === ASSIGNMENT_STATUS.READY ? 'active' : ''}>
              <div>{ASSIGNMENT_STATUS.getFlag(ASSIGNMENT_STATUS.READY)}</div>
            </li>
            <li className={status === ASSIGNMENT_STATUS.STARTED ? 'active' : ''}>
              <div>{ASSIGNMENT_STATUS.getFlag(ASSIGNMENT_STATUS.STARTED)}</div>
            </li>
            <li className={status === ASSIGNMENT_STATUS.CLOSED ? 'active' : ''}>
              <div>{ASSIGNMENT_STATUS.getFlag(ASSIGNMENT_STATUS.CLOSED)}</div>
            </li>
            <li className={status === ASSIGNMENT_STATUS.COMPLETED ? 'active' : ''}>
              <div>{t('graded')}</div>
            </li>
          </ul>
          <Button
            className={disabledState ? 'mark-grading-button' : 'primary mark-grading-button'}
            disabled={disabledState}
            onClick={() => this.markGradingCompleteModalOpen()}>
            {status === ASSIGNMENT_STATUS.COMPLETED ? t('reportSettingsLabel') : t('markGradingComplete')}
          </Button>
        </div>
        )}
        {this.state.markGradingCompleteOpen && (
          <Modal
            key='markGradingCompleteModal'
            className='markGradingCompleteModal'
            onClose={() => this.markGradingCompleteModalClose(true, assignmentId)}
            open={this.state.markGradingCompleteOpen}
            size='mini'>
            <ModalBanner
              label={status === ASSIGNMENT_STATUS.COMPLETED ? t('reportSettingsLabel') : 'Mark Grading Complete'}
              onClose={() => this.markGradingCompleteModalClose(true, assignmentId)} />
            <Modal.Content>
              <div className='checkboxContainer'>
                <div className='checkboxRow'>
                  <Radio
                    checked={this.state.includeInReports}
                    label={t('includeInReportsLabel')}
                    onChange={() => {
                      this.setGradingSetting('includeInReports', this.state.includeInReports);
                    }}
                    toggle />
                  {this.state.includeInReports && (
                    <div className='optionSubText'>
                      {t('includeInReportsDescription')}
                    </div>
                  )}
                </div>
                <div className='checkboxRow'>
                  <Radio
                    checked={this.state.scoresReleased}
                    label={t('scoresReleasedLabel')}
                    onChange={() => {
                      this.setGradingSetting('scoresReleased', this.state.scoresReleased);
                    }}
                    toggle />
                  {this.state.scoresReleased && (
                    <div className='optionSubText'>
                      {t('scoresReleasedDescription')}
                    </div>
                  )}
                </div>
                <div className='checkboxRow'>
                  <Radio
                    checked={this.state.studentReview}
                    label={t('studentReviewLabel')}
                    onChange={() => {
                      this.setGradingSetting('studentReview', this.state.studentReview);
                    }}
                    toggle />
                </div>
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                basic
                className='cancelButton'
                onClick={() => this.markGradingCompleteModalClose(true, assignmentId)}
                primary>
                {t('cancel')}
              </Button>
              <Button
                className='ui primary button saveButton'
                loading={gradebookManagerLoadingFlagActive}
                onClick={() => this.submitEditAssignment(assignmentId)}>
                {t('save')}
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </>
    );
  }
}

SatCoreRegister('AssignmentReportSettings', AssignmentReportSettings);
