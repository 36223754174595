import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { DIALOG_NAMES } from '../managers/DialogManager';
import Auth from '../managers/AuthManager';
import { ASSIGNMENT_STATUS } from '../managers/AssignmentManager';
import { CONTENT_MODE, PLAYER_TYPES } from '../managers/ContentManager';
import ImageService from '../services/ImageService';
import AssignmentService from '../services/AssignmentService';

export default @inject(
  'assignmentManager',
  'classroomManager',
  'contentManager',
  'dialogManager',
  'studentContentCardManager',
  'userManager')
@observer
class StudentAssignmentsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      learnosityPlayerShowing: false,
      learnosityScoringPlayerShowing: false,
      lessonPlayerShowing: false,
      contentName: null,
      contentItemType: null,
      contentItemId: null,
      isFlowpaper: false,
      instruction: null,
      disablePresent: false
    };

    this.LearnosityPlayerModal = SatCoreComponent('LearnosityPlayerModal');
    this.LearnosityScoringModal = SatCoreComponent('LearnosityScoringModal');
    this.FullscreenModal = SatCoreComponent('FullscreenModal');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');
    this.DocReaderModal = SatCoreComponent('DocReaderModal');
    this.Avatar = SatCoreComponent('Avatar');
  }

  async componentDidMount() {
    const { courseElement, activityInstanceId } = this.props;
    this.showStudentAssignment(activityInstanceId, courseElement);
  }

  closeAssignmentModal = () => {
    const { dialogManager } = this.props;
    this.props.hideSelf();
    dialogManager.closeAllDialogs();
  }

  hideIframeFromOuterClick = (event) => {
    if ((event.origin === Auth.publisher || event.origin === Auth.lesson) && event.data === 'hideIframe') {
      this.setState({ lessonPlayerShowing: false });
      window.removeEventListener('message', this.hideIframeFromOuterClick);
    }
  }

  hideIframe = async () => {
    const { refreshData, courseElement, contentManager} = this.props;
    this.setState({ lessonPlayerShowing: false });
    await refreshData(courseElement);
  }

  hideModal = () => {
    this.setState({ learnosityScoringPlayerShowing: false, learnosityPlayerShowing: false, fileViewerShowing: false, docreaderViewerShowing: false, instruction: '' });

    // re-create the assignment popup to stop the review button from showing loading
    const { courseElement, activityInstanceId } = this.props;
    this.showStudentAssignment(activityInstanceId, courseElement);
  }

  showStudentAssignment = async (activityInstanceId, courseElement) => {
    const { assignmentManager, dialogManager, classroomManager } = this.props;
    const { currentClassroomId } = classroomManager;

    if (activityInstanceId) {
      await assignmentManager.fetchStudentAssignment(activityInstanceId);
    } else {
      const entityId = (this.props.assignmentElement) ? this.props.assignmentElement.entityId : courseElement.contentItemId;
      const currentCourseContentItemId = courseElement.hasActivities ? courseElement.studentActivities[0].courseContentItemId : null;
      await assignmentManager.fetchContentAssignments(currentCourseContentItemId, courseElement.studentActivities[0].courseResourceElementId, currentClassroomId, entityId, 0);
    }

    dialogManager.setOpenDialog(DIALOG_NAMES.STUDENT_ASSIGNMENT, {
      currentClassroomId,
      // assignmentElement: courseElement,
      contentName: courseElement.elementName,
      courseElement,
      // assignments:assignmentManager.assignmentArray,
      handlePresent: this.handlePresent
      // fetchCourseActivityInformation:this.fetchCourseActivityInformation,
      // currentCourseId:courseManager.currentCourseId,
      // handleEditAssignment:this.handleEditAssignment,
      // handleGradebook: this.handleGradebook,
      // classeslist:null,
      // handleAnswerKey: this.handleAnswerKey
    },
    this.closeAssignmentModal);
  }

  handlePresent = async (params) => {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.disablePresent) {
      return false;
    }
    const { contentManager, assignmentManager, userManager, tagContentManager } = this.props;
    const { studentContentCardManager } = this.props;

    const { assignment, contentMode = CONTENT_MODE.REVIEW, turnInLate } = params;

    // the assignment object could be an activity instance or an activity so check the ids.
    const activityId = assignment.activityId || assignment.id;
    const activityInstanceId = assignment.activityInstanceId || assignment.id;
    this.setState({ disablePresent: true });

    if (contentMode === CONTENT_MODE.ASSESS) {
      const preStartContentItemId = assignment.contentItemId;
      if (ASSIGNMENT_STATUS.getCheckableStatuses().includes(assignment.status)) {
        const isReady = await assignmentManager.checkActivity(activityId);
        if (isReady) {
          const isOk = await assignmentManager.startActivity(activityInstanceId, activityId, turnInLate);
          if (isOk === false) {
            return false;
          }
        } else {
          this.setState({ disablePresent: false });
          return false;
        }
      }

      const postStartContentItemId = assignment.contentItemId;
      if (preStartContentItemId !== postStartContentItemId && tagContentManager.useContentTags) {
        // this has been versioned. Make sure the image is added.
        // console.info('*** A new version: ' + postStartContentItemId +' has been created from: '+ preStartContentItemId);
        tagContentManager.createNewCardContentTagFromOld(preStartContentItemId, postStartContentItemId);
      }
    }

    let option = null;
    if (contentMode === CONTENT_MODE.REVIEW) {
      option = await contentManager.getOptionsForStudentReview(
        assignment, window.location.origin, userManager.isTeacher, userManager.userId
      );
    } else {
      option = await contentManager.getOptionsForStudentAssessment(
        assignment, window.location.origin, userManager.isTeacher, userManager.userId
      );
    }

    const { playerType, viewUrl, isFlowpaper } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      this.setState({ disablePresent: false });

      if (studentContentCardManager.isLoading) {
        studentContentCardManager.setIsLoading(false);
      }
      return;
    }

    contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    const contentImageUrl = ImageService.getImageUrl(assignment);

    const { dialogManager } = this.props;
    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        resourceName: (assignment.resourceWebTitle !== '' && assignment.resourceWebTitle != null) ?
          assignment.resourceWebTitle : assignment.name,
        contentItemId: assignment.contentItemId,
        contentItemType: assignment.contentItemEntityTypeId,
        instruction: assignment.activityInstruction,
        assignmentId: assignment.id,
        contentMode
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
      this.setState({ disablePresent: false });
      return;
    }

    const {
      resourceWebTitle, resourceWebSubtitle
    } = AssignmentService.getAssignmentTitleAndDescription(assignment);

    this.setState({
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      contentPreviewShowing: (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER),
      learnosityScoringPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_SCORING_PLAYER),
      contentUrl: viewUrl,
      contentName: resourceWebTitle,
      contentSubtitle: resourceWebSubtitle,
      isFlowpaper,
      assignmentId: assignment.id,
      contentItemId: assignment.contentItemId,
      previewContentType: assignment.contentItemEntityTypeId,
      previewContentItemId: assignment.id,
      resourceName: assignment.contentItemName,
      instruction: assignment.activityInstruction,
      contentImageUrl,
      activityId: assignment.activityId,
      contentMode,
      grade: assignment.grade
    });

    this.setState({ disablePresent: false });
  }

  handleView = async (courseElement, contentMode, pdfFormatSelected) => {
    const { contentManager, assignmentManager, dialogManager, userManager, classroomManager } = this.props;
    let option = null;
    const contentItemId = courseElement.entityId;
    const courseResourceElementId = courseElement.elementId;
    const entityTypeId = courseElement.type;
    const { name } = courseElement;
    let { pdfDeliveryFormat } = courseElement;
    const { deliveryMode } = courseElement;

    // if the user selected a particular format, use that.
    if (pdfFormatSelected) {
      pdfDeliveryFormat = pdfFormatSelected;
    }
    const alternateModeId = courseElement.alternateModeIdOverride;
    if (deliveryMode.includes('student') && userManager.isStudent) {
      option = await contentManager.getOptionsForStudentPreview(
        contentItemId, entityTypeId, pdfDeliveryFormat, origin, userManager.isTeacher, userManager.userId,
        courseResourceElementId, deliveryMode, classroomManager.currentClassroomId, alternateModeId);
    } else {
      if (contentMode === CONTENT_MODE.PRESENT) {
        const currentAssignment = assignmentManager.getLastAddedAssignment();
        option = await contentManager.getOptionsForTeacherPresent(contentItemId, currentAssignment, entityTypeId,
          origin, userManager.isTeacher);
      } else {
        option = await contentManager.getOptionsForTeacherPreview(contentItemId, entityTypeId, pdfDeliveryFormat,
          origin, userManager.isTeacher, userManager.userId, courseResourceElementId, classroomManager.currentClassroomId,
          alternateModeId);
      }
    }

    const { playerType, viewUrl, isFlowpaper } = option;

    if (!userManager.isTeacher) {
      // TODO: IS STUDENT PREVIEW MODE FOR LESSON GOING TO BE SOMETHING? API RETURNS ONLY TEACHER PREVIEW WITHOUT ACTIVITY.
      if (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER || playerType === PLAYER_TYPES.LESSON_PLAYER) {
        this.handleGeneralMessage('This type is unsupported.');
        return false;
      }
    }

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    }

    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        contentItemId,
        contentItemType: entityTypeId,
        resourceName: name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
    }

    this.setState({
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      contentUrl: viewUrl,
      isFlowpaper,
      contentItemId,
      contentItemType: entityTypeId,
      contentName: name,
      assignmentId: '',
      previewContentType: entityTypeId,
      previewContentItemId: '',
      resourceName: '',
      instruction: '',
      contentImageUrl: ''
    });
  }

  renderLessonPlayer = () => {
    const { FullscreenModal } = this;
    // const { userManager } = this.props;

    // if (this.props.renderLessonPlayer !== undefined) {
    //   return this.props.renderLessonPlayer();
    // }

    return (
      <div className='course-content'>
        <FullscreenModal
          closeIframeCallback={this.hideIframe}
          contentImageUrl={this.state.contentImageUrl}
          contentName={this.state.contentName}
          instruction={this.state.instruction}
          isTeacher={false}
          page='lesson-player'
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderFileViewer = () => {
    // const { userManager } = this.props;
    const { FileViewerModal } = this;

    // if (this.props.renderFileViewer !== undefined) {
    //   return this.props.renderFileViewer();
    // }

    return (
      <div className='course-content'>
        <FileViewerModal
          assignmentId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentType={this.state.previewContentType}
          instruction={this.state.instruction}
          isFlowpaper={this.state.isFlowpaper}
          isTeacher={false}
          page='file-viewer'
          resourceName={this.state.resourceName}
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderDocreaderViewer = () => {
    const { userManager, contentManager } = this.props;
    const { DocReaderModal } = this;
    if (this.props.renderDocreaderViewer !== undefined) {
      return this.props.renderDocreaderViewer();
    }

    return (
      <div className='course-content'>
        <DocReaderModal
          assignmentId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentType={this.state.previewContentType}
          instruction={this.state.instruction}
          isFlowpaper={this.state.isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='docreader-viewer'
          resourceName={this.state.resourceName}
          sessionId={contentManager.currentflowPaperSessionId}
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderLearnosityPlayer = () => {
    const { history } = this.props;
    const { LearnosityPlayerModal } = this;

    // if (this.props.renderLearnosityPlayer !== undefined) {
    //   return this.props.renderLearnosityPlayer();
    // }

    return (
      <div className='course-content'>
        <LearnosityPlayerModal
          assignmentId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentItemId={this.state.contentItemId}
          contentName={this.state.contentName}
          contentSubtitle={this.state.contentSubTitle}
          history={history}
          instruction={this.state.instruction}
          isTeacher={false}
          page='learnosity-player'
          resourceName={this.state.resourceName} />
      </div>
    );
  }

  renderLearnosityScoringModal = () => {
    const { userManager } = this.props;
    const { LearnosityScoringModal } = this;
    return (
      <div className='course-content'>
        <LearnosityScoringModal
          activityInstanceId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentItemId={this.state.contentItemId}
          contentSubTitle={this.state.contentItemDescription}
          contentTitle={this.state.contentName}
          grade={this.state.grade}
          history={this.props.history}
          instruction={this.state.instruction}
          isTeacher={false}
          page='learnosity-scoring'
          resourceName={this.state.resourceName}
          studentName={`${userManager.firstName} ${userManager.lastName}`} />
      </div>
    );
  }

  render() {
    if (this.state.learnosityPlayerShowing) {
      return this.renderLearnosityPlayer();
    } else if (this.state.lessonPlayerShowing) {
      return (this.renderLessonPlayer());
    } else if (this.state.fileViewerShowing) {
      return (this.renderFileViewer());
    } else if (this.state.docreaderViewerShowing) {
      return (this.renderDocreaderViewer());
    } else if (this.state.learnosityScoringPlayerShowing) {
      return this.renderLearnosityScoringModal();
    }
    return <></>;
  }
}

SatCoreRegister('StudentAssignmentsPopup', StudentAssignmentsPopup);
