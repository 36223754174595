import React from 'react';

import { stripHtmlTags } from '../utils';

export default class CheckboxTreeService {
  static getCustomCheckboxTreeIconObj = () => {
    return {
      check: <span className='custom rct-icon rct-icon-check check square icon' />,
      collapseAll: <span className='custom rct-icon rct-icon-collapse-all' />,
      expandAll: <span className='custom rct-icon rct-icon-expand-all' />,
      expandClose: <span className='custom rct-icon rct-icon-expand-close' />,
      expandOpen: <span className='custom rct-icon rct-icon-expand-open' />,
      halfCheck: <span className='custom rct-icon rct-icon-half-check check square icon' />,
      leaf: <span className='custom rct-icon rct-icon-leaf' />,
      parentClose: <span className='custom rct-icon rct-icon-parent-close' />,
      parentOpen: <span className='custom rct-icon rct-icon-parent-open' />,
      uncheck: <span className='custom rct-icon rct-icon-uncheck check square outline icon' />
    };
  }

  static getCheckboxTreeNodes = ({
    checkedChildIds = [],
    originalChildrenDataMap = new Map(),
    labelPropName = '',
    parentLabel = '',
    parentValue = '',
    valuePropName = ''
  } = {}) => {
    const checkedIdSet = new Set(checkedChildIds);
    const originalChildren = Array.from(originalChildrenDataMap.values());

    const allChildrenChecked = originalChildrenDataMap.size === checkedIdSet.size;

    const nodes = [
      {
        children: originalChildren.map((child) => {
          const childId = child[valuePropName];
          const childLabel = child[labelPropName];
          const checkedClassName = checkedIdSet.has(childId) ? 'checked' : '';
          return {
            className: checkedClassName,
            label: stripHtmlTags(childLabel),
            value: childId
          };
        }),
        className: `${allChildrenChecked ? 'checked' : ''}`,
        label: stripHtmlTags(parentLabel),
        value: parentValue
      }
    ];
    return nodes;
  }
}
