export const ICON_CIRCLE_STATUS = {
  EMPTY_CIRCLE: {
    _id: 'EMPTY_CIRCLE',
    className: 'icon-circle-status-empty',
    key: 'iconCircleStatusEmpty'
  },
  FULL_CIRCLE: {
    _id: 'FULL_CIRCLE',
    className: 'icon-circle-status-full',
    key: 'iconCircleStatusFull'
  },
  HALF_CIRCLE: {
    _id: 'HALF_CIRCLE',
    className: 'icon-circle-status-half',
    key: 'iconCircleStatusHalf'
  }
};
