import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Button, Loader } from 'semantic-ui-react';

import Modal from '../Modal';

import '../../css/LibraryResourceDetailsModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

export default
@inject('courseManager', 'productManager')
class LibraryResourceDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      isLoadingLibraryResourceDetailsModal: true,
      resourceCode: '',
      resourceId: '',
      resourceLicenses: [],
      resourceName: ''
    };
  }

  async componentDidMount() {
    const { courseId, courseManager, productId, resourceLicenses } = this.props;
    if (resourceLicenses && resourceLicenses.length > 0) {
      this.setState({
        isLoadingLibraryResourceDetailsModal: false,
        resourceLicenses
      });
    } else {
      const courseDetails = await courseManager.fetchAdminCourseDetails(courseId, productId);
      this.setState({
        isLoadingLibraryResourceDetailsModal: false,
        resourceCode: courseDetails.resourceCode,
        resourceId: courseDetails.resourceId,
        resourceLicenses: courseDetails.licenseElements, // courseDetails.productLicenses,
        resourceName: courseDetails.resourceName
      });
    }
  }

  closeLibraryResourceDetailsModal = (data = null) => {
    const { closeLibraryResourceDetailsModal, onCloseLibraryResourceDetailsModal } = this.props;
    if (onCloseLibraryResourceDetailsModal) {
      onCloseLibraryResourceDetailsModal(data);
    }
    this.setState(this.getInitialState());
    closeLibraryResourceDetailsModal();
  }

  render() {
    const {
      close, headerLabel, libraryResourceDetailsOpen, resourceIdLabel, resourceNameLabel, resourceCodeLabel, t
    } = this.props;
    const {
      isLoadingLibraryResourceDetailsModal,
      resourceCode,
      resourceId,
      resourceLicenses: stateResourceLicenses,
      resourceName
    } = this.state;
    const { ModalBanner } = this;

    const unknown = !isLoadingLibraryResourceDetailsModal ? t('unknown') : t('loading');

    const resourceLicenses = stateResourceLicenses?.length ? stateResourceLicenses : [{ licenseEntityName: unknown }];

    return (
      <Modal className='library-resource-details-modal' open={libraryResourceDetailsOpen} size='tiny'>
        <ModalBanner
          label={headerLabel}
          onClose={close} />
        <Modal.Content>
          <div className='fieldWrapper'>
            <div className='fieldLabel'>{resourceNameLabel}:&nbsp;</div>
            <div className='fieldValue'>{resourceName || unknown}</div>
          </div>
          <div className='fieldWrapper'>
            <div className='fieldLabel'>{resourceIdLabel}:&nbsp;</div>
            <div className='fieldValue'>{resourceId || unknown}</div>
          </div>
          <div className='fieldWrapper'>
            <div className='fieldLabel'>{resourceCodeLabel}:&nbsp;</div>
            <div className='fieldValue'>{resourceCode || unknown}</div>
          </div>
          <div className='fieldWrapper'>
            <div className='fieldLabel'>{t('licensesLabelText')}:&nbsp;</div>
            <div className='fieldValue licenses'>
              {resourceLicenses.map((license, index) => {
                const licenseEntityTypeId = license?.licenseEntityTypeId || license?.entityTypeId;
                const licenseEntityName = license?.licenseEntityName || license?.entityName || license?.licenseName || unknown;
                return (
                  <div key={index}>
                    {!!licenseEntityTypeId && (<span className='capitalize'>{licenseEntityTypeId}: </span>)}
                    <span>{licenseEntityName || unknown}</span>
                  </div>
                );
              }
              )}
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={() => { this.closeLibraryResourceDetailsModal(); }} primary>{t('closeButtonLabel', 'Close')}</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('LibraryResourceDetailsModal', LibraryResourceDetailsModal);
