import React, { useContext, useState } from 'react';

import {
  Button, Checkbox, Container, Form
} from 'semantic-ui-react';

import { MobXProviderContext, observer } from 'mobx-react';

import Modal from '../Modal';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import '../../css/ResourceCartModal.less';

const ResourceCartModal = observer((props = {}) => {
  const {
    resourceBankShopManager
  } = useContext(MobXProviderContext);

  const SCCheckbox = SatCoreComponent('SCCheckbox');
  const LibraryResourceCard = SatCoreComponent('LibraryResourceCard');
  const ModalBanner = SatCoreComponent('ModalBanner');

  const [cartRemovals, setCartRemovals] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const {
    closeLibraryShoppingCart,
    getLibraryCardOptions,
    hasAssessments,
    resourceCartModalOpen,
    t
  } = props;

  const handleCancel = () => {
    closeLibraryShoppingCart();
  };

  const handleSave = () => {
    if (cartRemovals.length > 0) {
      for (const assessmentId of cartRemovals) {
        resourceBankShopManager.removeSelectedNotAttachedResource(assessmentId);
      }
    }
    closeLibraryShoppingCart();
  };

  const handleCheck = (_event) => {
    const assessmentId = _event?.target?.value;
    if (assessmentId) {
      // toggle cartRemovals
      if (cartRemovals.includes(assessmentId)) {
        const index = cartRemovals.indexOf(assessmentId);
        // Remove the assessment id from cartRemovals
        if (index > -1) { // only splice array when item is found
          cartRemovals.splice(index, 1); // 2nd parameter means remove one item only
          setCartRemovals(cartRemovals);
        }
      } else {
        // Add assessment id to cart removals
        setCartRemovals([...[assessmentId], ...cartRemovals]);
      }
      setRefresh(!refresh);
    }
  };

  const assessmentList = resourceBankShopManager.selectedNotAttachedResourceArray;

  const getSelectedAssessments = (assessmentList) => {
    const { libraryLoading } = resourceBankShopManager;

    let zeroStateMsg = null;
    if (!hasAssessments && !libraryLoading) {
      zeroStateMsg = t('customAssessmentZeroStateMsg', 'There are no assessments added yet.');
    }

    if (zeroStateMsg) {
      return (
        <div
          key='assessment-modal-zero-state'
          className='assessment-modal-zero-state'>
          <div className='assessment-modal-zero-state-text'>
            {zeroStateMsg}
          </div>
        </div>
      );
    } else {
      return (
        <>
          {assessmentList.map((assessment, index) => {
            // Adding isChecked to form and checkbox keys because state change of cartRemovals collection isn't picked up.
            const isChecked = !cartRemovals.includes(assessment.id);
            return (
              <>
                {(assessment.selected && !assessment.attached) && (
                  <Form.Field key={`${index}_${assessment.id}_FF`} className='add-assessment-field' refresh-attr={refresh ? refresh.toString() : 'false'}>
                    <SCCheckbox
                      key={`${index}_${assessment.id}_SCC`}
                      checked={isChecked}
                      disabled={assessment.attached}
                      onChange={handleCheck}
                      value={assessment.id} />
                    <LibraryResourceCard key={`${index}_${assessment.id}_LRC`}
                      horizontalLayout={true}
                      libraryCardKebabOptions={getLibraryCardOptions(assessment)}
                      libraryCardParams={assessment}
                      showLiveBanner={false} />
                  </Form.Field>
                )}
              </>
            );
          })}
        </>
      );
    }
  };

  return (
    <div className='resource-cart'>
      <Modal
        className='ResourceCartModal'
        closeOnDimmerClick={false}
        closeOnEscape={false}
        open={resourceCartModalOpen}>
        <ModalBanner
          label='Selected Items'
          onClose={() => {
            handleCancel();
          }} />
        <Modal.Content>
          <Container
            className='course-assessment-container'
            fluid>
            <Container className='course-assessment-list-container' fluid>
              {getSelectedAssessments(assessmentList)}
            </Container>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button basic className='preview-button' onClick={handleCancel} primary>Cancel</Button>
          <Button className='add-assessments-button' onClick={handleSave} primary>Save</Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
});
export default ResourceCartModal;

SatCoreRegister('ResourceCartModal', ResourceCartModal);
