import groupsManager from '../managers/GroupsManager';

export default class GroupService {
  static initArchivedAndUnarchivedClassroomGroups = async ({ classroomId } = {}) => {
    groupsManager.clearGroups();
    await this.initClassroomGroups({ archived: true, classroomId });
    await this.initClassroomGroups({ archived: false, classroomId });
  }

  static initClassroomGroups = async ({
    classroomId = undefined,
    clearOnInit = false,
    groupIds = undefined,
    archived = false,
    page = 1,
    sortColumn = null,
    sortDirection = 'ascending',
    filters = [],
    pageSize = 999
  } = {}) => {
    return groupsManager.getFilteredGroupsByClassroom(
      classroomId, groupIds, archived, page,
      sortColumn, sortDirection, filters, pageSize, clearOnInit
    );
  }

  static isGroupResource = (groupingMode) => {
    return groupingMode === 'resources_are_grouped';
  }
}
