/* eslint-disable sort-keys,react/destructuring-assignment */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Form, Grid, Header, Segment } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';
import { PLAYER_TYPES } from '../managers/ContentManager';
import { DIALOG_NAMES } from '../managers/DialogManager';
import { VIEW_SELECTION } from '../managers/NavigationManager';
import '../css/LTIPlayerLauncher.less';

export default
@inject(
  'assignmentManager', 'classroomManager', 'contentManager', 'courseManager',
  'navigationManager', 'productManager', 'userManager')
@observer
class LTIPreview extends Component {
  constructor(props) {
    super(props);
    this.Logo = SatCoreComponent('Logo');
    this.LearnosityPlayerModal = SatCoreComponent('LearnosityPlayerModal');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');
    this.DocReaderModal = SatCoreComponent('DocReaderModal');

    this.state = {
      learnosityPlayerShowing: false,
      lessonPlayerShowing: false,
      fileViewerShowing: false,
      docreaderViewerShowing: false,
      contentName: null,
      contentSubtitle: null,
      contentItemId: null,
      contentImageUrl: null,
      resourceName: null,
      errorMessage: null
    };
  }

  componentDidMount() {
    const { assignmentManager, navigationManager, productManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.LOGIN);
    productManager.setIsFromProduct(true);
    assignmentManager.isLtiLaunched = true;

    const { courseElement } = this.props;
    this.handleView(courseElement);
  }

  // satellite Branded Error panel
  failPanel = () => {
    const { errorTitle, errorMessage } = this.state;
    const { Logo } = this;
    return (
      <Grid className='fullHeight' textAlign='center' verticalAlign='middle'>
        <Grid.Column className='max-width-558'>
          <Form>
            <Header as='h2' attached='top' block>
              <div className='message-header'>
                <Logo />
                <div>{errorTitle}</div>
              </div>
            </Header>
            <Segment attached>
              <Form.Field>
                <Container className='login-error-message' text>
                  {errorMessage}
                </Container>
                {/* <div className='panel-body'>
                  <Button id='home-btn' basic primary  onClick={this.navToLogin}>Retry Login</Button>
                </div> */}
              </Form.Field>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }

  verificationError = (errorTitle, errorMessage) => {
    this.setState({ errorTitle, errorMessage });
  }

  handleView = async (courseElement, contentMode, pdfFormatSelected, publisherModeId = null) => {
    const {
      classroomManager,
      contentManager,
      dialogManager,
      userManager
    } = this.props;

    let option = null;
    const contentItemId = courseElement.entityId;
    const courseResourceElementId = courseElement.elementId;
    const entityTypeId = courseElement.type;
    const courseContentItemId = courseElement.contentItemId;
    const { name } = courseElement;
    let { pdfDeliveryFormat } = courseElement;

    // if the user selected a particular format, use that.
    if (pdfFormatSelected) {
      pdfDeliveryFormat = pdfFormatSelected;
    }

    option = await contentManager.getOptionsForTeacherPreview(
      contentItemId, entityTypeId, pdfDeliveryFormat, origin,
      userManager.canViewAsTeacher, userManager.userId,
      courseResourceElementId, classroomManager.currentClassroomId,
      courseContentItemId,
      publisherModeId
    );

    const { playerType, viewUrl, isFlowpaper } = option;

    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        contentItemId,
        resourceName: name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
    }

    this.setState({
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      contentUrl: viewUrl,
      contentMode,
      isFlowpaper,
      contentItemId,
      contentName: name,
      previewContentType: entityTypeId,
      resourceName: '',
      instruction: '',
      contentImageUrl: ''
    });
  }

  hideIframeFromOuterClick = (event) => {
    if (event.data === 'hideIframe' || event.data === 'closePressed') {
      this.hideModal();
    }
  }

  hideModal = () => {
    this.setState({
      lessonPlayerShowing: false,
      learnosityPlayerShowing: false,
      fileViewerShowing: false,
      docreaderViewerShowing: false,
    });

    const { close } = this.props;
    close();
  }

  renderLessonPlayer = () => {
    const { userManager } = this.props;
    const FullscreenModal = SatCoreComponent('FullscreenModal');
    const hideIframe = (this.hideIframe) ? this.hideIframe : () => {};
    return (
      <div className='course-content'>
        <FullscreenModal
          closeIframeCallback={hideIframe}
          contentImageUrl={this.state.contentImageUrl}
          contentName={this.state.contentName}
          instruction={this.state.instruction}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderFileViewer = () => {
    const { userManager } = this.props;
    const { FileViewerModal } = this;
    return (
      <div className='course-content'>
        <FileViewerModal
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentType={this.state.previewContentType}
          instruction={this.state.instruction}
          isFlowpaper={this.state.isFlowpaper}
          isFromLTI={true}
          isTeacher={userManager.isTeacher}
          maxScore={this.state.maxScore}
          page='file-viewer'
          resourceName={this.state.resourceName}
          totalScore={this.state.totalScore}
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderDocreaderViewer = () => {
    const { userManager, contentManager } = this.props;
    const { DocReaderModal } = this;
    if (this.props.renderDocreaderViewer !== undefined) {
      return this.props.renderDocreaderViewer();
    }

    return (
      <div className='course-content'>
        <DocReaderModal
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentType={this.state.previewContentType}
          instruction={this.state.instruction}
          isFlowpaper={this.state.isFlowpaper}
          isFromLTI={true}
          isTeacher={userManager.isTeacher}
          maxScore={this.state.maxScore}
          page='docreader-viewer'
          resourceName={this.state.resourceName}
          sessionId={contentManager.currentflowPaperSessionId}
          totalScore={this.state.totalScore}
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderLearnosityPlayer = () => {
    const { userManager } = this.props;
    const { LearnosityPlayerModal } = this;
    return (
      <div className='course-content'>
        <LearnosityPlayerModal
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentItemId={this.state.contentItemId}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentSubtitle={this.state.contentSubtitle}
          instruction={this.state.instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-player'
          resourceName={this.state.resourceName} />
      </div>
    );
  }

  render() {
    if (this.state.learnosityPlayerShowing) {
      return this.renderLearnosityPlayer();
    } else if (this.state.lessonPlayerShowing) {
      return (this.renderLessonPlayer());
    } else if (this.state.fileViewerShowing) {
      return (this.renderFileViewer());
    } else if (this.state.docreaderViewerShowing) {
      return (this.renderDocreaderViewer());
    } else if (this.state.errorMessage) {
      return (
        <div className='lrn-player-launcher-container'>
          {this.failPanel()}
        </div>
      );
    }
    return null;
  }
}

SatCoreRegister('LTIPreview', LTIPreview);
