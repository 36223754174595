import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Container, Header } from 'semantic-ui-react';
import Modal from '../Modal';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import '../../css/LearnosityItemPreviewModal.less';

export default
@inject('learnosityAuthorManager')
@observer
class LearnosityItemPreviewModal extends Component {
  authorApp;

  constructor(props) {
    super(props);
    this.state = {
      isPlayerLoaded: false,
      addInfoOpen: false,
      infoMessage: '',
      showErrorDialog: false,
      errorMessage: '',
      cancelStart: false,
      learnosityStylesheet: ''
    };

    this.InfoModal = SatCoreComponent('InfoModal');
    this.ErrorModal = SatCoreComponent('ErrorModal');
  }

  async componentDidMount() {
    // load the learnosity items api script
    if (!document.getElementById('learnosity-author-app-script')) {
      const script = document.createElement('script');
      script.src = 'https://authorapi.learnosity.com';
      script.async = true;
      script.id = 'learnosity-author-app-script';
      document.body.appendChild(script);
    }

    // poll/wait for Learnosity javascript to load
    const interval = await setInterval(async () => {
      if (window.LearnosityAuthor && !this.state.cancelStart) {
      // stop polling
        clearInterval(interval);
        // render the assess UI
        await this.loadAssessment();
      }
    }, 1000);
  }

  // READY listener called when Learnosity API is ready
  readyListener = () => {
    // LEARNOSITY IS INITIALIZED BIND EVENTS HERE.
    console.log('LearnosityPlayerModal is Ready');
    this.setState({ isPlayerLoaded: true });

    // Set actions to take on submit success
    this.authorApp.on('test:submit:success', async () => {
    });

    this.authorApp.on('item:changing', async () => {
    });

    this.authorApp.on('item:unload', async () => {
      this.clearAuthorApp();
    });
  };

  // ERROR Listener to capture errors thrown from the API
  errorListener = (e) => {
    console.error('LearnosityPlayerModal: learnosity error', e);
  };

  eventOptions = {
    readyListener: this.readyListener,
    errorListener: this.errorListener
  };

  async clearAuthorApp() {
    if (this.authorApp != null) {
      this.authorApp.destroy();
    }
  }

  async loadAssessment() {
    const { learnosityAuthorManager, contentItemId } = this.props;
    try {
      const response = await learnosityAuthorManager.getLearnosityAuthorApiPreviewRequest(contentItemId, true);
      this.authorApp = await window.LearnosityAuthor.init(response, this.eventOptions);
    } catch (err) {
      this.openWarningModal(err);
      this.setState({ cancelStart: true });
    }
  }

  openWarningModal = (message) => {
    this.setState({ showErrorDialog: true, errorMessage: message });
  }

  closeThisModal = async () => {
    // this.clearAuthorApp();
    await this.clearAuthorApp();
    this.props.closeLearnosityItemPreviewModal();
  }

  render() {
    const { displayLearnosityItemPreviewModal } = this.props;

    return (
      <>
        <Modal
          className='LearnosityItemPreviewModal'
          closeOnDimmerClick={false}
          closeOnEscape={false}
          open={displayLearnosityItemPreviewModal}
          size='fullscreen'>
          <Modal.Header className='modal-header'>
            <Header.Content className='modal-header-bar'>
              <span className='modal-header-label'>
                Preview Item
              </span>
              <div className='modal-header-buttons'>
                <Button
                  className='ui primary button saveButton'
                  onClick={this.closeThisModal}>
                  Done
                </Button>
              </div>
            </Header.Content>
          </Modal.Header>
          <div className='nav-separator' />
          <Modal.Content>
            <Container fluid>
              <div className='l-content'>
                <div id='learnosity-author' />
              </div>
            </Container>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

SatCoreRegister('LearnosityItemPreviewModal', LearnosityItemPreviewModal);
