/* eslint-disable sort-keys */
import { AccommodationsService } from 'sat-core';

AccommodationsService.ACCOMMODATIONS_CONFIG = () => {
  return {
    assessmentSection: {
      show: true, // overrides all other assessment options if false
      toggles: {
        // toggles marked as true will appear in the order shown here
        basicCalculatorToggle: true,
        scientificCalculatorToggle: true,
        graphingCalculatorToggle: true,
        dictionaryToggle: false,
        textToSpeechToggle: false,
        translationToggle: false
      }
    },
    lessonSection: {
      show: false
    },
    cellDividerClassName: 'cell-divider',
    showTopHeader: false,
    tableHeaderFontSize: '12px',
    treatAllSectionsAsOne: true
  };
};
