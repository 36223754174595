import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

// import { register } from '../../../i18n';

import EngagementService from '../../../services/EngagementService';
import UsageService from '../../../services/UsageService';

const EngagementRowAuxiliaryTimeOnTaskRatioCells = observer((props) => {
  const { engagementData } = props;

  const { averageTimePerItem, averageTimePerCorrect, averageTimePerIncorrect } = engagementData;

  const { /* managers */ } = useContext(MobXProviderContext);

  // const t = register('EngagementTable');

  const renderEngagementRowAuxiliaryTimeOnTaskRatioCells = () => {
    // TODO remove
    // const averageTimePerItem = Math.round((engagementData.averageTimePerItem || 0) * 100) / 100;
    // const averageTimePerCorrect = Math.round((engagementData.averageTimePerCorrect || 0) * 100) / 100;
    // const averageTimePerIncorrect = Math.round((engagementData.averageTimePerIncorrect || 0) * 100) / 100;

    return (
      <>
        {renderEngagementRowAuxiliaryTimeOnTaskRatioCell({
          allowPopupComponent: true,
          className: 'engagement-body-label detail-time-on-task-ratio-body averageTimePerItem',
          popupContent: '',
          popupDisabled: true,
          popupTrigger: UsageService.getUnroundedUsageLabel(averageTimePerItem || 0)
        })}
        {renderEngagementRowAuxiliaryTimeOnTaskRatioCell({
          allowPopupComponent: true,
          className: 'engagement-body-label detail-time-on-task-ratio-body averageTimePerItemCorrect',
          popupContent: '',
          popupDisabled: true,
          popupTrigger: UsageService.getUnroundedUsageLabel(averageTimePerCorrect || 0)
        })}
        {renderEngagementRowAuxiliaryTimeOnTaskRatioCell({
          allowPopupComponent: true,
          className: 'engagement-body-label detail-time-on-task-ratio-body averageTimePerItemIncorrect',
          popupContent: '',
          popupDisabled: true,
          popupTrigger: UsageService.getUnroundedUsageLabel(averageTimePerIncorrect || 0)
        })}
      </>
    );
  };

  const renderEngagementRowAuxiliaryTimeOnTaskRatioCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementRowAuxiliaryTimeOnTaskRatioCells();
});
export default EngagementRowAuxiliaryTimeOnTaskRatioCells;

SatCoreRegister('EngagementRowAuxiliaryTimeOnTaskRatioCells', EngagementRowAuxiliaryTimeOnTaskRatioCells);
