import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { Image } from 'semantic-ui-react';

import '../css/ZeroStateCard.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import UtilityService from '../services/UtilityService';

const ZeroStateCard = observer((props) => {
  const { /* managers */ } = useContext(MobXProviderContext);

  const { additionalClassNames, icon, style, t, translationKey } = props;
  return (
    <div className={classNames('zero-state-card', additionalClassNames)} style={{
      margin: '0px 48px',
      ...style
    }}>
      {icon && (
        <div className={classNames('zero-state-icon', additionalClassNames)}>
          <Image alt='' src={icon} />
        </div>
      )}
      {translationKey && (
        <div className={classNames('zero-state-title', additionalClassNames)}>
          {UtilityService.reactHtmlParserWrapper(t(translationKey)).parsed}
        </div>
      )}
    </div>
  );
});
export default ZeroStateCard;

SatCoreRegister('ZeroStateCard', ZeroStateCard);
