import React, { useContext, useEffect } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import { Loader } from 'semantic-ui-react';

import { SatCoreRegister } from '../SatCoreRegistry';

import { VIEW_SELECTION } from '../managers/NavigationManager';

const StudentCourseLandingView = observer((props) => {
  const { history } = props;

  const {
    appManager, classroomManager, courseManager, navigationManager, userManager
  } = useContext(MobXProviderContext);

  useEffect(() => {
    if (!userManager.isStudent || !courseManager.allowStudentBooks) {
      return;
    }
    const TIME_DELAY = 500;
    const MAX_TIME_ELAPSED = 10000;
    let timeElapsed = 0;
    const interval = setInterval(async () => {
      // Student data is currently initialized immediately upon login.
      // --
      // To ensure the student is redirected to the proper view (Course vs Course Element),
      // we need to ensure the student's course data has been completely initialized
      // before redirecting to the 'Books' aka 'My Content' aka 'Student Courses' nav tab.
      // --
      // The logic will check if `appManager.initializingApp` is false per every `TIME_DELAY` up to `MAX_TIME_ELAPSED`.
      // --
      // Once `appManager.initializingApp` is false, we assume the course data has been loaded
      // and can now redirect the user to student courses (Books/My Content).
      const shouldAbort = timeElapsed >= MAX_TIME_ELAPSED;

      if (!shouldAbort && !appManager.initializingApp) {
        navToCurrentCourseView();
        clearInterval(interval);
      } else {
        timeElapsed += TIME_DELAY;
        if (timeElapsed >= MAX_TIME_ELAPSED) {
          clearInterval(interval);
          navToCurrentCourseView();
        }
      }
    }, TIME_DELAY);
  }, []);

  const navToCurrentCourseView = async () => {
    courseManager.setCurrentElementId('');
    navigationManager.setView(VIEW_SELECTION.BOOK);
    await navigationManager.navigateToClassroomSkipSingleCourse(classroomManager.currentClassroomId,
      courseManager.getClassroomCourseCount(classroomManager.currentClassroomId),
      courseManager.currentCourseId,
      VIEW_SELECTION.BOOK,
      history,
      false
    );
  };

  return <Loader active />;
});
export default StudentCourseLandingView;

SatCoreRegister('StudentCourseLandingView', StudentCourseLandingView);
