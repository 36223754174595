import { isObject } from 'lodash';

/**
 * Example usage:
 * ```
 * const keysToRename = { uid: 'id', children: 'subRows' };
 * const arr = [{ uid: 123, name: 'example', children: [] }];
 * renameKeys(keysToRename, arr); // [{ id: 123, name: 'example', subRows: [] }]
 * ```
 */
export const renameKeys = (keysToRename = {}, arr = []) => {
  if (!isObject(arr)) {
    return arr;
  }
  if (Array.isArray(arr)) {
    return arr.map((obj) => renameKeys(keysToRename, obj));
  }
  return Object.entries(arr).reduce((result, [key, value]) => {
    const newKey = keysToRename[key] || key;
    return {
      ...result,
      [newKey]: renameKeys(keysToRename, value)
    };
  }, {});
};
