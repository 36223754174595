import React, { useCallback, useContext, useMemo, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { Button, Form, Message } from 'semantic-ui-react';
import Modal from '../Modal';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import '../../css/WorkspaceFileModal.less';

const WorkspaceFileRenameModal = observer((props) => {
  const { open, close, resource: { id, name: resourceName }, t } = props;
  const { contentItemManager } = useContext(MobXProviderContext);
  const [name, setName] = useState(resourceName);
  const [error, setError] = useState();
  const [submitted, setSubmitted] = useState(false);
  const ModalBanner = useMemo(() => SatCoreComponent('ModalBanner'), []);

  const handleSubmit = useCallback(async () => {
    if (!name) {
      setError(t('requiredError', 'Name is required'));
      return;
    }
    setSubmitted(true);
    const { result, exist } = await contentItemManager.updateName(id, { name }, true)
    if (result) {
      close();
    } else {
      setSubmitted(false)
      setError(
        exist ?
          t('nameExistError', 'Name already exists.') :
          t('generalError', 'Something has gone wrong.')
      );
    }
  }, [contentItemManager, setError, setSubmitted, close, name]);

  return (
    <Modal
      className='workspace-modal'
      closeOnDimmerClick={false}
      closeOnEscape={true}
      onClose={close}
      open={open}
      size='tiny'>
      <ModalBanner onClose={close} title={t('modalTitle', 'Rename File')} />
      <Modal.Content>
        <Form>
          <Form.Input
            name='name'
            onChange={({ target: { value } }) => setName(value)}
            onFocus={() => setError(null)}
            placeholder={t('nameInputPlaceholder', 'Enter name')}
            type='text'
            value={name} />
          {error && <Message content={error} error visible={error !== null} />}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic className='cancelButton' onClick={close} primary>{t('cancelButtonLabel', 'Cancel')}</Button>
        <Button disabled={submitted || !name} onClick={handleSubmit} primary>{t('submitButtonLabel', 'Ok')}</Button>
      </Modal.Actions>
    </Modal>
  );
});

export default WorkspaceFileRenameModal;
SatCoreRegister('WorkspaceFileRenameModal', WorkspaceFileRenameModal);
