import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import { register } from '../../../i18n';
import EngagementService from '../../../services/EngagementService';

const EngagementHeaderMainResourcesUsageCells = observer((props) => {
  const { engagementManager } = useContext(MobXProviderContext);

  const activeTable = engagementManager.activeEngagementTable;

  const { handleHeaderClick } = props;

  const t = register('EngagementTable');

  const renderEngagementHeaderMainResourcesUsageCells = () => {
    const isOnlyShowingMainCells = activeTable === 'summary';
    return (
      <>
        {isOnlyShowingMainCells && renderEngagementHeaderMainResourcesUsageCell({
          allowPopupComponent: true,
          className: `main-cell engagement-header-label resourcesUsage active-table-${activeTable}`,
          isHeaderCell: true,
          popupContent: t('resourcesUsageDescriptionMain'),
          popupOn: 'hover',
          popupTrigger: (
            <div className='clickable'
              data-value='resourcesUsage'
              onClick={handleHeaderClick}>
              {t('resourcesUsage')}
            </div>
          )
        })}
        {!isOnlyShowingMainCells && renderEngagementHeaderMainResourcesUsageCell({
          allowPopupComponent: true,
          cellText: t('resourcesUsage'),
          className: `main-cell engagement-header-label resourcesUsage active-table-${activeTable}`,
          isHeaderCell: true,
          popupContent: t('resourcesUsageDescription'),
          popupOn: 'click'
        })}
      </>
    );
  };

  const renderEngagementHeaderMainResourcesUsageCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementHeaderMainResourcesUsageCells();
});
export default EngagementHeaderMainResourcesUsageCells;

SatCoreRegister('EngagementHeaderMainResourcesUsageCells', EngagementHeaderMainResourcesUsageCells);
