import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import { register } from '../../../i18n';

import EngagementService from '../../../services/EngagementService';

const EngagementRowMainTimeOnTaskRatioCells = observer((props) => {
  const { engagementData } = props;

  const { /* managers */ } = useContext(MobXProviderContext);

  const t = register('EngagementTable');

  const renderEngagementRowMainTimeOnTaskRatioCells = () => {
    return (
      <>
        {renderEngagementRowMainTimeOnTaskRatioCell({
          allowPopupComponent: true,
          className: classNames('time-on-task-ratio-cell main-cell timeOnTaskRatio', {
            warn: engagementData.engagement
          }),
          popupContent: renderEngagementRowMainTimeOnTaskRatioCellContent(engagementData),
          popupDisabled: false,
          popupOn: 'hover',
          popupTrigger: typeof engagementData.timeOnTaskSummary === 'number' ?
            (Math.round(engagementData.timeOnTaskSummary * 100) / 100 || 0) : engagementData.timeOnTaskSummary || 0
        })}
      </>
    );
  };

  const renderEngagementRowMainTimeOnTaskRatioCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  const renderEngagementRowMainTimeOnTaskRatioCellContent = () => {
    return (
      <div className='time-on-task-cell-content'>
        {renderEngagementRowMainTimeOnTaskRatioCellContentSection('timeOnTaskMoreTimeIncorrect')}
        {renderEngagementRowMainTimeOnTaskRatioCellContentSection('timeOnTaskSimilarTime')}
        {renderEngagementRowMainTimeOnTaskRatioCellContentSection('timeOnTaskMoreTimeCorrect')}
      </div>
    );
  };

  const renderEngagementRowMainTimeOnTaskRatioCellContentSection = (timeOnTaskKey) => {
    return (
      <div className='tot-cell-content-section'>
        <div className={classNames('tot-cell-content-section-label', timeOnTaskKey)}>
          <strong>{t(`${timeOnTaskKey}Label`)}</strong>
        </div>
        <div className={classNames('tot-cell-content-section-description', timeOnTaskKey)}>
          {t(`${timeOnTaskKey}Description`)}
        </div>
      </div>
    );
  };

  return renderEngagementRowMainTimeOnTaskRatioCells();
});
export default EngagementRowMainTimeOnTaskRatioCells;

SatCoreRegister('EngagementRowMainTimeOnTaskRatioCells', EngagementRowMainTimeOnTaskRatioCells);
