import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { Container, Form, Grid, Header, Segment } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';
import { VIEW_SELECTION } from '../managers/NavigationManager';
import { setSessionStorageItem } from '../utils/session';
import Auth from '../managers/AuthManager';

const Logo = SatCoreComponent('Logo');

const CertificateView = observer((props) => {
  const {
    navigationManager
  } = useContext(MobXProviderContext);

  const [errorObj, setErrorObj] = useState(null);

  useEffect(() => {
    navigationManager.setView(VIEW_SELECTION.LOGIN);

    // get URL params
    const urlParams = new URLSearchParams(props.location.search);
    let certificateId = null;
    let error = '';

    // get error param, there shouldn't be anything here but leaving in case it might be needed later
    if (urlParams.has('error') && urlParams.get('error') !== 'null') {
      error = urlParams.get('error');
    }

    // get certificateId param and save in session so certificate pdf can be signed and downloaded after login
    if (urlParams.has('certificateId') && urlParams.get('certificateId') !== 'null') {
      certificateId = urlParams.get('certificateId');
    }

    if (error) {
      verificationError('Error', error);
    } else if (certificateId) {
      try {
        setSessionStorageItem('c2c_certificateId', certificateId);
      } catch (error) {
        console.error(error);
      }

      // If logged in, go to default otherwise redirect to login, once seession is saved.
      if (Auth.loggedIn()) {
        props.history.replace('/');
      } else {
        props.history.replace('/login');
      }
    } else {
      verificationError('Error', 'Not Authorized');
    }
  }, []);

  const verificationError = (errorTitle, errorMessage) => {
    setErrorObj({ errorMessage, errorTitle });
  };

  return (
    <>
      {errorObj && (
        <Grid className='fullHeight' textAlign='center' verticalAlign='middle'>
          <Grid.Column className='max-width-558'>
            <Form>
              <Header as='h2' attached='top' block>
                <div className='message-header'>
                  <Logo />
                  <span className='header-text'>{errorObj.errorTitle}</span>
                </div>
              </Header>
              <Segment attached>
                <Form.Field>
                  <Container className='login-error-message' text>
                    {errorObj.errorMessage}
                  </Container>
                </Form.Field>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      )}
    </>
  );
});

export default CertificateView;
SatCoreRegister('CertificateView', CertificateView);
