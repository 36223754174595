import BaseApi from './BaseApi';

export const CLASSROOM_ROSTER_ENDPOINTS = {
  GET_BY_STUDENT: '/api/viewRosterDataForStudent'
};

export class ClassroomRosterApi extends BaseApi {
  async getByStudent(userId = null, classroomId = null) {
    const url = this.getUrl(CLASSROOM_ROSTER_ENDPOINTS.GET_BY_STUDENT, { studentId: userId, classroomId });
    const response = await this.fetch(url);
    return response && response.data[0];
  }
}

export default new ClassroomRosterApi();
