import { action, computed, makeObservable, observable } from 'mobx';

import { REPORT_CONTEXT_TYPE } from '../../services/reports/ReportConstants';
import { getSessionStorageItem, setSessionStorageItem } from '../../utils';

/**
 * @typedef {'performance' | 'usage' | 'itemLevel' | 'resourceLevel'} ReportContextType
 */
export class ReportContextManager {
  @observable reportContextType = getSessionStorageItem('reportContextType') || REPORT_CONTEXT_TYPE.PERFORMANCE;
  @observable reportStandardsClassroomDetailStudentsContextType = getSessionStorageItem(
    'reportStandardsClassroomDetailStudentsContextType') || REPORT_CONTEXT_TYPE.RESOURCE_LEVEL;

  @observable progressFilters = {
    averageLess: true,
    averageMore: true,
    highLess: true,
    highMore: true,
    lowLess: true,
    lowMore: true
  };

  @observable engagementFilters = {
    // TODO remove // disengage: true,
    // TODO remove // speed: true,
    highEngagement: true,
    lowEngagement: true,
  };

  @observable showEngagementNumbers = true;
  @observable showProgressNumbers = false;

  constructor() {
    makeObservable(this);
  }

  @action clearAllExceptSession = () => {
    /* placeholder */
  }

  @action clearSession = () => {
    this.setReportContextType(REPORT_CONTEXT_TYPE.PERFORMANCE);
    this.setReportStandardsClassroomDetailStudentsContextType(REPORT_CONTEXT_TYPE.RESOURCE_LEVEL);
  }

  /** @param {ReportContextType} reportContextType */
  @action setReportContextType = (reportContextType) => {
    this.reportContextType = reportContextType;
    setSessionStorageItem('reportContextType', reportContextType);
  }

  /** @param {ReportContextType} reportContextType */
  @action setReportStandardsClassroomDetailStudentsContextType = (reportContextType) => {
    this.reportStandardsClassroomDetailStudentsContextType = reportContextType;
    setSessionStorageItem('reportStandardsClassroomDetailStudentsContextType', reportContextType);
  }

  @action setProgressFilters = (progressFilters) => {
    this.progressFilters = progressFilters;
  }

  @action setEngagementFilters = (engagementFilters) => {
    this.engagementFilters = engagementFilters;
  }

  @action setShowEngagementNumbers = (showEngagementNumbers) => {
    this.showEngagementNumbers = showEngagementNumbers;
  }

  @action setShowProgressNumbers = (showProgressNumbers) => {
    this.showProgressNumbers = showProgressNumbers;
  }

  @computed get isContextPerformanceReport() {
    return this.reportContextType === REPORT_CONTEXT_TYPE.PERFORMANCE;
  }

  @computed get isContextUsageReport() {
    return this.reportContextType === REPORT_CONTEXT_TYPE.USAGE;
  }

  @computed get isContextProgressReport() {
    return this.reportContextType === REPORT_CONTEXT_TYPE.PROGRESS;
  }

  @computed get isContextEngagementReport() {
    return this.reportContextType === REPORT_CONTEXT_TYPE.ENGAGEMENT;
  }

  @computed get isContextItemLevelReport() {
    return this.reportStandardsClassroomDetailStudentsContextType === REPORT_CONTEXT_TYPE.ITEM_LEVEL;
  }

  @computed get isContextResourceLevelReport() {
    return this.reportStandardsClassroomDetailStudentsContextType === REPORT_CONTEXT_TYPE.RESOURCE_LEVEL;
  }
}

export default new ReportContextManager();
