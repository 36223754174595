import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Container, Form,
  Grid, Input, Message
} from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import Modal from '../Modal';

import '../../css/ScoreAssignment.less';

export default
@inject('assignmentManager', 'classroomManager', 'gradebookManager')
@observer
class ScoreAssignmentModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.ModalBanner = SatCoreComponent('ModalBanner');
  }

  getInitialState = () => {
    const { maxScore, totalScore } = this.props;

    const savedInfo = null;

    try {
      // Retrieves the user token
      savedInfo = JSON.parse(sessionStorage.getItem('scoreAssignmentModalInfo'));
    } catch (error) {
      // TODO RM: this is a work around because for LTI launch of /lrnPlayer we aren't authenticated yet when react is
      // initializing this manager so sesssion storage is not allowed.  Not sure if there's a better way to avoid this.
      // no-op this shouldn't happen but may happen for lrnPlayer launch.
      console.debug('SessionStorage access denied', error);
    }

    const initPointsGiven = typeof +totalScore === 'number' ? +totalScore : 0.0;
    const initMaxPoints = typeof +maxScore === 'number' ? +maxScore : 0.0;

    return savedInfo || {
      pointsGiven: initPointsGiven,
      maxPoints: initMaxPoints
    };
  }

  handleChangePointsGiven = async (_event, { value }) => {
    if (+value >= 0) {
      this.setState({ pointsGiven: +value });
    }
  }

  handleChangeMaxPoints = async (_event, { value }) => {
    if (+value >= 0) {
      this.setState({ maxPoints: +value });
    }
  }

  getCalculatedScoreFromPoints = () => {
    const { maxPoints, pointsGiven } = this.state;

    if (typeof maxPoints === 'number' && typeof pointsGiven === 'number') {
      if ((pointsGiven && maxPoints && (pointsGiven > maxPoints)) || maxPoints < 1) {
        return 'Invalid ';
      }
      return ((pointsGiven / maxPoints) * 100).toFixed(2);
    }
    return '0.00';
  }

  closeGradeAssignmentModal = () => {
    this.props.closeGradeAssignment();
  }

  submitAssignmentGrade = async () => {
    const { activityInstanceId, gradebookManager, t } = this.props;
    const { maxPoints, pointsGiven } = this.state;

    const calculatedScoreFromPoints = this.getCalculatedScoreFromPoints();

    let responseData;
    responseData = await gradebookManager.updateActivityInstanceGrade(
      activityInstanceId, calculatedScoreFromPoints, pointsGiven, maxPoints
    );
    if (responseData && !responseData.grade) {
      // TODO CF-1917
      // TODO if `maxScore` (maxPoints) exists on the BE and has changed on the FE,
      // TODO bug on BE exists where when initially calling `/api/updateActivityInstanceGrade`,
      // TODO `grade` (calculatedScoreFromPoints) is being stored as null on the BE.
      // TODO this is a temporary workaround to ensure the proper `grade` has been stored on the BE.
      responseData = await gradebookManager.updateActivityInstanceGrade(
        activityInstanceId, calculatedScoreFromPoints, pointsGiven, maxPoints
      );
      await this.setUpdatedScoreAssignmentInfoAndCloseModalIfNoErrors(responseData);
    } else {
      await this.setUpdatedScoreAssignmentInfoAndCloseModalIfNoErrors(responseData);
    }
  }

  setUpdatedScoreAssignmentInfoAndCloseModalIfNoErrors = async (responseData) => {
    const { classroomManager, t } = this.props;
    if (responseData) {
      const { maxPoints, pointsGiven } = this.state;
      sessionStorage.setItem('scoreAssignmentModalInfo', JSON.stringify({ pointsGiven, maxPoints }));
      this.closeGradeAssignmentModal();
      await classroomManager.fetchClassroomData(classroomManager.currentClassroomId);
    } else {
      this.setState({
        serverError: true,
        serverErrorMsg: t('serverErrorMsg')
      });
      this.closeGradeAssignmentModal();
    }
  }

  render() {
    const { gradeAssignmentOpen, studentName, t } = this.props;
    const { maxPoints, pointsGiven, serverError, serverErrorMsg } = this.state;

    const { ModalBanner } = this;

    const calculatedScoreFromPoints = this.getCalculatedScoreFromPoints();
    const waitingOnScore = typeof +calculatedScoreFromPoints !== 'number' || calculatedScoreFromPoints === 'Invalid ';

    return (
      <Modal
        className='ScoreAssignmentModal'
        closeOnDimmerClick={false}
        closeOnEscape={true}
        onClose={this.closeGradeAssignmentModal}
        open={gradeAssignmentOpen}
        size='tiny'>
        <ModalBanner
          label={t('grade')}
          onClose={this.closeGradeAssignmentModal}
          title={studentName} />
        <Modal.Content>
          <Form>
            <Grid columns='equal'>
              <Grid.Row>
                <Grid.Column>
                  <div className='text-top'>
                    {t('scorePercent')}
                    {calculatedScoreFromPoints}
                    %
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Container className='score-input-container'>
                    <Input
                      className='score-input text-middle'
                      label={{ basic: true, content: 'Student Score' }}
                      labelPosition='left'
                      onChange={this.handleChangePointsGiven}
                      placeholder='Score'
                      type='number'
                      value={pointsGiven} />
                  </Container>
                </Grid.Column>
                <Grid.Column className='middle'>
                  <Container>
                    <div className='grade-divider'>/</div>
                  </Container>
                </Grid.Column>
                <Grid.Column>
                  <Container className='score-input-container'>
                    <Input
                      className='score-input text-middle'
                      label={{ basic: true, content: 'Total Points*' }}
                      labelPosition='right'
                      onChange={this.handleChangeMaxPoints}
                      placeholder='Points'
                      type='number'
                      value={maxPoints} />
                  </Container>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div className='text-bottom'>
                    {t('totalPointValueDescription')}
                  </div>
                </Grid.Column>
              </Grid.Row>
              {(serverError) ? (
                <div className='server-error-msg'>
                  <Message
                    content={serverErrorMsg}
                    error
                    visible={serverError} />
                </div>
              ) : null}
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            className='cancelButton'
            onClick={this.closeGradeAssignmentModal}
            primary>
            {t('cancel')}
          </Button>
          <Button
            className='ui primary button saveButton'
            disabled={waitingOnScore}
            onClick={this.submitAssignmentGrade}>
            {t('save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('ScoreAssignmentModal', ScoreAssignmentModal);
