import React, { useContext, useEffect } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import CheckboxTree from 'react-checkbox-tree';

import '../css/CustomCheckboxTree.less';
import '../css/GroupResourceSelector.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import { DELIVERY_MODES } from '../managers/AssignmentManager';

import CheckboxTreeService from '../services/CheckboxTreeService';
import ClassroomService from '../services/ClassroomService';

const GroupResourceSelector = observer((props) => {
  const { courseElement, handleNoAssignableResources, t } = props;

  const {
    courseManager, groupsManager
  } = useContext(MobXProviderContext);

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  useEffect(() => {
    (async () => {
      groupsManager.setLoadingGroupResourceSelector(true);

      const assignableGroupResources = await courseManager.fetchTeacherCourseElements({
        classroomId: ClassroomService.getCurrentClassroomId(),
        courseContentItemId: courseManager.currentCourseId,
        courseResourceElementId: courseElement.elementId
      });

      const assignableGroupResourcesMap = new Map();
      const { STUDENT_ALWAYS, TEACHER_ONLY } = DELIVERY_MODES;
      for (const resource of assignableGroupResources) {
        if (resource.deliveryMode !== STUDENT_ALWAYS && resource.deliveryMode !== TEACHER_ONLY && resource.type !== 'SECTION') {
          assignableGroupResourcesMap.set(resource.contentItemId, resource);
        }
      }

      if (assignableGroupResourcesMap.size < 1) {
        handleNoAssignableResources();
      }
      

      groupsManager.setAssignableGroupResourcesMap(assignableGroupResourcesMap);

      const assignableGroupResourceContentItemIds = Array.from(assignableGroupResourcesMap.keys());
      groupsManager.setGroupResourceSelectorCheckedChildContentItemIds(assignableGroupResourceContentItemIds);

      groupsManager.setLoadingGroupResourceSelector(false);
    })();
  }, []);

  const handleCheckSelections = (checkedChildContentItemIds) => {
    groupsManager.setGroupResourceSelectorCheckedChildContentItemIds(checkedChildContentItemIds);
  };

  const handleExpandSelections = (_expandedChildIds) => {
    // TODO we currently only have one parent (courseElement)
    // TODO it is always expanded by default (and currently cannot be collapsed)
    // TODO groupsManager.setGroupResourceSelectorExpandedChildIds(expandedChildIds);
  };

  const renderGroupResourceSelector = () => {
    const { loadingGroupResourceSelector } = groupsManager;
    const checkedChildContentItemIds = groupsManager.groupResourceSelectorCheckedChildContentItemIds;

    const originalChildrenDataMap = groupsManager.assignableGroupResourcesMap;
    return loadingGroupResourceSelector ? t('loading') : (
      <div className='group-resource-selector custom-checkbox-tree'>
        <CheckboxTree
          checked={checkedChildContentItemIds}
          disabled={originalChildrenDataMap.size < 1}
          expanded={[courseElement.elementId]}
          icons={CheckboxTreeService.getCustomCheckboxTreeIconObj()}
          nodes={CheckboxTreeService.getCheckboxTreeNodes({
            checkedChildIds: checkedChildContentItemIds,
            labelPropName: 'name',
            originalChildrenDataMap,
            parentLabel: courseElement.name,
            parentValue: courseElement.elementId,
            valuePropName: 'contentItemId'
          })}
          onCheck={handleCheckSelections}
          onExpand={handleExpandSelections}
          showNodeIcon={false} />
      </div>
    );
  };
  return renderGroupResourceSelector();
});
export default GroupResourceSelector;

SatCoreRegister('GroupResourceSelector', GroupResourceSelector);
