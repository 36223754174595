import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Menu } from 'semantic-ui-react';

import '../css/LTIContent.less';
import '../css/TopNav.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

import DynamicSatelliteService from '../services/DynamicSatelliteService';
import ReportIdentityService from '../services/reports/ReportIdentityService';

import DialogList from '../components/DialogList';
import LTICourses from './LTICourses';
import Logo from '../components/Logo';

const LTIContent = observer((props) => {
  const { dynamicSatelliteManager } = useContext(MobXProviderContext);

  const ReportView = SatCoreComponent('ReportView');
  const SatCoreLoader = SatCoreComponent('SatCoreLoader');

  const [loaded, setLoaded] = useState(false);
  const [view, setView] = useState('content');

  useEffect(() => {
    (async () => {
      if (dynamicSatelliteManager.isDynamicSatellite && !Auth.publisherSatelliteCode) {
        await DynamicSatelliteService.fetchDynamicSatelliteByDomain();
        setTimeout(() => {
          setLoaded(true);
        }, 500);
      } else {
        setLoaded(true);
      }
    })();
  }, []);

  const handleMenuClick = (e, { name }) => {
    if (name === 'reports' && name !== view) {
      ReportIdentityService.clearAllReportManagers();
    }
    setView(name);
  };

  const TopNavBar = () => {
    const contentSelected = (view === 'content') ? 'selected' : '';
    const reportsSelected = (view === 'reports') ? 'selected' : '';

    return (
      <Menu
        className='ui inverted top fixed topNav menu'
      >
        <Logo
          className='logo'
          role='tab'
          style={{ margin: 0 }}
        />
        <div className='nav-separator' />
        <Menu.Menu
          position='right'
          role='navigation'>
          <Menu.Item
            className={`menu-item tnav-tab ${contentSelected}`}
            name='content'
            onClick={handleMenuClick}
            role='tab'
            tabIndex='0'
          >
            Content
          </Menu.Item>
          <Menu.Item
            className={`menu-item tnav-tab ${reportsSelected}`}
            name='reports'
            onClick={handleMenuClick}
          >
            Reports
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  };

  return loaded ? (
    <div className='lti-content'>
      <TopNavBar />
      {(view === 'content') && (
        <LTICourses {...props} />
      )}
      {(view === 'reports') && (
        <div className='outer-container reports'>
          <div className='reports-container'>
            <ReportView />
          </div>
        </div>
      )}
      <DialogList />
    </div>
  ) : <SatCoreLoader />;
});
export default LTIContent;

SatCoreRegister('LTIContent', LTIContent);
