import BaseApi from './BaseApi';

export const COURSES_ENDPOINTS = {
  GET_FULL_COURSE_RESOURCE: '/api/viewFullCourseResourceJson',
  GET_HIDDEN_COURSE_RESOURCE_IDS: '/api/getHiddenCourseResourceElementIds'
};

export class CoursesApi extends BaseApi {
  async getFullCourseResource(courseContentItemId) {
    const url = this.getUrl(COURSES_ENDPOINTS.GET_FULL_COURSE_RESOURCE, { courseContentItemId });
    const response = await this.fetch(url, { withoutCheck: true });
    return response && JSON.parse(response.jsonString);
  }

  async getHiddenCourseResourceIds(classroomId, courseContentItemId) {
    const url = this.getUrl(COURSES_ENDPOINTS.GET_HIDDEN_COURSE_RESOURCE_IDS, { classroomId, courseContentItemId });
    const response = await this.fetch(url);
    return response.courseResourceElementIds;
  }
}

export default new CoursesApi();
