import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';

import { kebabCase } from 'lodash';

import {
  Button, Container, Form, Header,
  Label, Loader, Pagination, Popup
} from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/AssessmentItemShopModal.less';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import CustomCourseResourceService from '../../services/CustomCourseResourceService';
import CourseService from '../../services/CourseService';

import AssessmentItemCard from '../AssessmentItemCard';

/** AssessmentItemShopModal aka resourceItemShopModal is shared among two features: 'COURSE SEARCH' and 'LEARNOSITY CUSTOM COURSES' */
export default
@inject(
  'assessmentManager',
  'courseManager',
  'userManager'
)
@observer
class AssessmentItemShopModal extends Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    // props specifying what this modal is being used for
    assessmentItemsSearch: false,
    courseResourcesSearch: false,
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.AssessmentItemFilter = SatCoreComponent('AssessmentItemFilter');
    this.CautionBanner = SatCoreComponent('CautionBanner');
    this.CourseLayout = SatCoreComponent('CourseLayout');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
  }

  getInitialState = () => ({
    activePage: 1,
    customAssessmentName: '',
    customAssessmentSubtitle: '',
    disableAdd: false,
    isConfirmAddCustomAssessmentModalOpen: false,
    isCustomAssessmentModalErrorMsgVisible: false,
    isSelectedAssessmentsPopupOpen: false,
    serverError: false,
    serverErrorMsg: ''
  })

  closeThisModal = (fromSave) => {
    const { assessmentManager, closeAssessmentItemShopModal } = this.props;
    assessmentManager.setSearchText('');
    this.setState(this.getInitialState());
    closeAssessmentItemShopModal(fromSave);
  }

  submitAddItemsToAssessment = async () => {
    await CustomCourseResourceService.addElementsToLearnosityActivity();
    this.closeThisModal(true);
  }

  handleTogglePopup = (toggleOpen = true) => {
    const { courseManager } = this.props;
    const { isSelectedAssessmentsPopupOpen } = this.state;
    if (toggleOpen && !isSelectedAssessmentsPopupOpen) {
      // open the modal
      this.setState({ isSelectedAssessmentsPopupOpen: true });
    } else if (!toggleOpen && isSelectedAssessmentsPopupOpen) {
      // reset courseId back to what it is in the course card navigator
      const urlParams = new URLSearchParams(window.location.search);
      const currentCourseId = urlParams.get('courseId') || '';
      courseManager.setCurrentCourseId(currentCourseId);
      // close the modal
      this.setState({ isSelectedAssessmentsPopupOpen: false });
    }
  }

  handlePageChange = async (_event, pageInfo) => {
    const { assessmentItemsSearch, courseResourcesSearch, assessmentManager, courseManager } = this.props;
    const { activePage } = pageInfo;

    if (assessmentItemsSearch) {
      const settings = assessmentManager.filteredAssessmentSettings;
      let searchOperator = null;
      let searchTerms = null;
      let searchTags = null;
      let searchStandards = null;

      if (settings !== null && settings !== undefined && settings !== '') {
        searchOperator = settings.searchOperator;
        searchTerms = settings.searchTerms;
        searchTags = settings.searchTags;
        searchStandards = settings.searchStandards;
      }

      await this.handleFetchAssessmentItems(searchOperator, searchTerms, searchTags, searchStandards, activePage);
      this.setState({ activePage });
    } else if (courseResourcesSearch) {
      this.setState({ activePage });
      courseManager.activePage = activePage;
      CourseService.searchCourseResourceElements();
    }
  }

  handleCheck = (item) => {
    const { assessmentManager } = this.props;
    assessmentManager.putAssessmentItemInCart(item.id, !item.selected);
  }

  handleChangeCustomAssessmentName = (_event, { value }) => {
    const MAX_CHAR_LENGTH = 200;
    if (!value || value.length <= MAX_CHAR_LENGTH) {
      this.setState({ customAssessmentName: value });
    }
  }

  handleChangeCustomAssessmentSubtitle = (_event, { value }) => {
    const MAX_CHAR_LENGTH = 65000;
    if (!value || value.length <= MAX_CHAR_LENGTH) {
      this.setState({ customAssessmentSubtitle: value });
    }
  }

  handleCreateCustomAssessment = async () => {
    const { customAssessmentName, customAssessmentSubtitle } = this.state;
    await CustomCourseResourceService.createCustomAssessment(customAssessmentName, customAssessmentSubtitle);
  }

  handleFetchAssessmentItems= async (searchCondition = null, searchTerms = null, searchTags = null, searchStandards = null, page) => {
    await CustomCourseResourceService.fetchCustomAssessmentItemsList(searchCondition, searchTerms, searchTags, searchStandards, page);
  }

  handleFetchCourseResources= async (searchCondition = null, searchTerms = null, searchTags = null, searchStandards = null, page) => {
    await CustomCourseResourceService.fetchCourseResourcesList(searchCondition, searchTerms, searchTags, searchStandards, page);
  }

  handleToggleConfirmAddCustomAssessmentModal = (openOrClose) => {
    const toggle = openOrClose === 'open';
    const { isConfirmAddCustomAssessmentModalOpen } = this.state;
    if (toggle !== isConfirmAddCustomAssessmentModalOpen) {
      this.setState({ isConfirmAddCustomAssessmentModalOpen: toggle });
      if (openOrClose === 'close') {
        this.setState({
          customAssessmentName: '',
          customAssessmentSubtitle: ''
        });
      }
    }
  }

  handleLearnosityItemPreview = async (assessmentItem) => {
    await CustomCourseResourceService.handleLearnosityItemPreview(assessmentItem);
  }

  getShoppingItems = (selectedOnly = null) => {
    const { assessmentManager, userManager } = this.props;
    const { SCCheckbox } = this;
    const items = [];
    let assessmentItemList = [];
    if (selectedOnly) {
      assessmentItemList = assessmentManager.cartedAssessmentItemList;
    } else {
      assessmentItemList = assessmentManager.pagedAssessmentItemList;
    }

    assessmentItemList.map((pItem, _index) => {
      if (!selectedOnly && assessmentManager.assessmentItems.has(pItem.id)) {
        pItem = { ...assessmentManager.assessmentItems.get(pItem.id) };
      }
      if (!selectedOnly || (pItem.selected && !pItem.attached)) {
        items.push(
          <Form.Field key={`${pItem.id}_FF`} className='add-assessment-field'>
            <SCCheckbox
              key={pItem.id}
              checked={pItem.selected}
              disabled={pItem.attached}
              onChange={() => { this.handleCheck(pItem); }} />
            <AssessmentItemCard
              assessmentItem={pItem}
              authKey={userManager.authKey}
              disabled={pItem.attached}
              handleCardClick={() => {
                if (!pItem.attached) {
                  this.handleCheck(pItem);
                }
              }}
              handleLearnosityItemPreview={() => this.handleLearnosityItemPreview(pItem)}
              imageUrl={pItem.imageUrl} />
          </Form.Field>);
        return true;
      }
    });
    return items;
  }

  renderAssessmentItemModalContent = () => {
    const { assessmentItemsSearch } = this.props;
    return (
      <div className={classNames('assessment-item-search-modal-content-container', {
        'assessment-item-search-modal-content-container-row-reversed': assessmentItemsSearch,
        'course-search-container': !assessmentItemsSearch
      })}>
        <div className='assessment-item-search-modal-content-left scrolling content'>
          {this.renderAssessmentItemFilter({
            searchType: assessmentItemsSearch ? 'assessmentItemsSearch' : 'courseResourcesSearch'
          })}
        </div>
        <div className='assessment-item-search-modal-content-rest scrolling content'>
          <div className='assessment-item-search-modal-content-mid'>
            {/* placeholder */}
          </div>
          <div className='assessment-item-search-modal-content-right'>
            {this.renderAssessmentItemList()}
          </div>
        </div>

      </div>
    );
  }

  /** @param {{ searchType: 'assessmentItemsSearch' | 'courseResourcesSearch' }} */
  renderAssessmentItemFilter = ({ searchType } = {}) => {
    const { AssessmentItemFilter } = this;
    const { isCustomCourseResource } = this.props;
    return (
      <div className={`assessment-item-filter-wrapper ${kebabCase(searchType)}`}>
        <AssessmentItemFilter
          assessmentItemsSearch={searchType === 'assessmentItemsSearch'}
          courseResourcesSearch={searchType === 'courseResourcesSearch'}
          handleFetchAssessmentItems={searchType === 'assessmentItemsSearch' ?
            this.handleFetchAssessmentItems : this.handleFetchCourseResources}
          isCustomCourseResource={isCustomCourseResource}
          multiSelect={searchType === 'courseResourcesSearch'} />
      </div>
    );
  };

  renderAssessmentItemList = () => {
    const { CourseLayout } = this;
    const { assessmentItemsSearch, courseResourcesSearch, courseManager, userManager, history } = this.props;

    let { activePage } = this.state;
    if (courseResourcesSearch) {
      activePage = courseManager.activePage;
    }

    const { assessmentManager, t } = this.props;

    const length = (assessmentItemsSearch) ? assessmentManager.pagedAssessmentItems.size : courseManager.searchCourseElementList.length;

    const totalPages = (assessmentItemsSearch) ? assessmentManager.totalPages : courseManager.totalPages;
    const { totalItems } = courseManager;
    const itemCount = courseManager.searchCourseElementList.length;
    let courseSearchResultsCounts = '';

    if (courseResourcesSearch && length > 0) {
      courseSearchResultsCounts = `Showing ${itemCount} of ${totalItems} results`;
    }

    const paginationJsx = (
      <Pagination
        activePage={activePage}
        onPageChange={this.handlePageChange}
        totalPages={totalPages} />
    );

    let headerContentJsx = <></>;
    if (length > 0) {
      headerContentJsx = paginationJsx;
    } else if (assessmentItemsSearch && !assessmentManager.assessmentItemsLoading
        && assessmentManager.originalAssessmentItems.size <= 0
    ) {
      if (sessionStorage.getItem(`AssessmentItemFilter-${userManager.userId}`)) {
        headerContentJsx = t('noResultsZeroStateMsg');
      } else {
        headerContentJsx = t('noItemsPublished');
      }
    } else if (courseResourcesSearch && !courseManager.isLoading
        && courseManager.searchCourseElementList.length === 0
    ) {
      headerContentJsx = t('noResultsZeroStateMsg');
    } else if (assessmentManager.assessmentItemsLoading || courseManager.isLoading) {
      headerContentJsx = (
        <Loader active className='modal-loader modal-loader-small-font' />
      );
    } else if (assessmentManager.originalAssessmentItems.size > 0) {
      headerContentJsx = t('noResultsZeroStateMsg');
    }

    return (
      <Container className='assessment-item-list-container' fluid>
        <div className={classNames('modal-content-header', {
          'assessment-item-list-header-sticky': !assessmentManager.assessmentItemsLoading && !courseManager.isLoading
        })}>
          <div className='results-counts'>{courseSearchResultsCounts}</div>
          <div className='modal-content-header-pagination'>
            {headerContentJsx}
          </div>
        </div>
        {(courseResourcesSearch && courseManager.searchCourseElementList.length > 0) && (
          <Container className='class-content resources-container class-details-inner-container' fluid>
            <CourseLayout
              courseElementList={courseManager.searchCourseElementList}
              history={history}
              isCourseSearch={true}
              showBanners={true}
              showTags={true} />
          </Container>
        )}
        {assessmentItemsSearch && this.getShoppingItems()}
        {/* TODO remove, header pagination is now positioned as sticky for this modal */}
        {/* TODO so there is no longer a need for footer pagination here */}
        {/* <div className='modal-content-footer'>
          {!!length && length >= 5 && (
          <div className='modal-content-footer-pagination'>
            {paginationJsx}
          </div>
          )}
        </div> */}
      </Container>
    );
  }

  render() {
    const { assessmentItemsSearch, assessmentManager, displayAssessmentItemShopModal, t } = this.props;

    const { disableAdd, isSelectedAssessmentsPopupOpen } = this.state;

    const selectedCount = assessmentManager.cartedAssessmentItems.size;

    return (
      <>
        <Modal
          className='AssessmentItemShopModal'
          closeOnDimmerClick={false}
          closeOnEscape={false}
          onClose={this.closeThisModal}
          open={displayAssessmentItemShopModal}
          size='fullscreen'
          style={{ overflow: 'hidden', top: '-14px' }}>
          <>
            <Modal.Header className='modal-header'>
              <Header.Content className='modal-header-bar'>
                <span className='modal-header-label'>
                  {t('selectItems')}
                </span>
                <div className='modal-header-buttons'>
                  {assessmentItemsSearch && (
                    <Popup
                      className={`selected-assessments-popup ${selectedCount ? 'has-selected-assessments' : 'has-no-selected-assessments'}`}
                      content={selectedCount ? (
                        <>
                          <div className='selected-assessments-popup-title'>
                            {t('Selected Assessments')}
                          </div>
                          <Container
                            className='course-assessment-container'
                            fluid>
                            <Container className='assessment-item-list-container' fluid>
                              {this.getShoppingItems('selectedOnly')}
                            </Container>
                          </Container>
                        </>
                      ) : t('noSelectedAssessments')}
                      hoverable
                      offset={[0, -295]}
                      on='hover'
                      onClose={() => { this.handleTogglePopup(false); }}
                      onOpen={() => { this.handleTogglePopup(true); }}
                      position='left center'
                      trigger={(
                        <div className={`selected-count-wrapper ${selectedCount ? 'has-selected-count' : 'has-no-selected-count'}`}>
                          <Label circular>
                            {selectedCount}
                          </Label>
                          <span className='selected-text-label'>
                            {t('Selected')}
                          </span>
                        </div>
                    )} />
                  )}

                  <Button
                    basic
                    className='cancelButton'
                    disabled={isSelectedAssessmentsPopupOpen}
                    onClick={this.closeThisModal}
                    primary>
                    {t('cancel')}
                  </Button>
                  {assessmentItemsSearch && (
                    <Button
                      className='ui primary button saveButton'
                      disabled={isSelectedAssessmentsPopupOpen}
                      loading={disableAdd}
                      onClick={this.submitAddItemsToAssessment}>
                      {t('save')}
                    </Button>
                  )}
                </div>
              </Header.Content>
            </Modal.Header>
            <div className='nav-separator' />
            {this.renderAssessmentItemModalContent()}
          </>
        </Modal>
      </>
    );
  }
}

SatCoreRegister('AssessmentItemShopModal', AssessmentItemShopModal);
