/* eslint-disable sort-keys */
import React from 'react';

import defaultAvatar from '../../img/avatars/default-avatar.png';

import { getRegisteredClass } from '../../SatCoreRegistry';

import { getInitials } from '../../utils';

import reportIdentityManager from '../../managers/reports/ReportIdentityManager';
import reportTableManager from '../../managers/reports/ReportTableManager';
import userManager from '../../managers/UserManager';
import groupsManager from '../../managers/GroupsManager';

import PopupService from '../PopupService';
import ReportJsonHelperService from './ReportJsonHelperService';

import Avatar from '../../components/Avatar';

export default class ReportStandardsClassroomService {
  /** Intended to be called via `ReportTypeService.initReportTableFacultyCellConfigArray` */
  static _initReportTableFacultyCellConfigArray = (initProps) => {
    const { handleViewNextReport, renderFacultyResultCell } = initProps;
    const { isTableIndividualReport, reportInfoClassNames } = reportIdentityManager;

    let students = [];
    if (isTableIndividualReport) {
      const { pathname } = window.location;
      const isLti = pathname.includes('/lti-courses');
      const urlParams = new URLSearchParams(window.location.search);
      const studentId = (isLti) ? reportIdentityManager.studentId : urlParams.get('studentId');

      const currentStudent = ReportJsonHelperService.REPORT_STUDENTS().find((student) => {
        return student.studentId === studentId;
      });
      if (currentStudent) {
        students = [currentStudent];
      }
    } else {
      students = ReportJsonHelperService.REPORT_STUDENTS();
    }

    const facultyCells = students.map((student) => {
      const { studentId } = student;
      return {
        Cell: (props) => {
          return renderFacultyResultCell({ ...initProps, ...props, student });
        },
        Header: (props) => {
          return this.renderClassroomHeaderScoreCell({
            ...initProps,
            ...props,
            handleViewNextReport,
            renderFacultyResultCell,
            student
          });
        },
        accessor: (props) => {
          const standardId = props.id;
          const allScoreInfo = ReportJsonHelperService.REPORT_SCORE_INFO_BY_STANDARD_FOR_STUDENTS();
          const scoreInfoObj = allScoreInfo[standardId] && allScoreInfo[standardId][studentId];

          // get progress scores
          const allProgressInfo = ReportJsonHelperService.REPORT_PROGRESS_INFO_BY_STANDARD_FOR_STUDENTS();
          const progressInfoObj = allProgressInfo[standardId] && allProgressInfo[standardId][studentId];

          // combine props of these info objects
          const infoObj = {
            ...scoreInfoObj,
            ...progressInfoObj
          };

          return infoObj;
        },
        className: `cell-faculty-result ${reportInfoClassNames}`,
        id: studentId,
        originalData: student
      };
    });
    reportTableManager.setReportTableFacultyCellsConfig(facultyCells);
    return facultyCells;
  };

  /**
   * @param {{
   *   student: import('./ReportJsonHelperService').ReportStudent,
   * }} props
   */
  static renderClassroomHeaderScoreCell = (props) => {
    const { student } = props;

    const { isTableIndividualReport, reportInfoClassNames } = reportIdentityManager;

    const UserService = getRegisteredClass('UserService');
    const studentFullName = UserService.getUserFullNameStr(student);

    const groups = groupsManager.memberMap[student.studentId];
    let groupList = '';
    if (groups) {
      const groupNames = groups.map((group) => group.name);
      groupList = `Group(s): ${groupNames.join(', ')}`;
    }

    const popupLabel = PopupService.renderPopup({
      className: `${reportInfoClassNames} report-table-header-popup`,
      content: (
        <div className={`header-cell-faculty-result-content ${reportInfoClassNames}`}>
          <div>{studentFullName}</div>
          <div>{groupList}</div>
        </div>
      ),
      offset: [10, 0],
      on: 'hover',
      pinned: true,
      position: 'bottom right',
      trigger: isTableIndividualReport ? studentFullName : getInitials(studentFullName)
    });

    const urlParams = new URLSearchParams(window.location.search);
    const classroomId = urlParams.get('classroomId');
    const classroomStatus = urlParams.get('classroomStatus');
    const institutionId = urlParams.get('institutionId');
    const institutionName = urlParams.get('institutionName');

    const imageUrl = userManager.getFullProfileImageUrl(student.profileImage);
    return (
      <div className={`faculty-avatar-wrapper ${reportInfoClassNames}`}
        onClick={() => !isTableIndividualReport && props.handleViewNextReport({
          classroomId,
          classroomStatus,
          institutionId,
          institutionName,
          studentFullName,
          studentId: student.studentId
        })}>
        {!isTableIndividualReport && (
          <Avatar className='faculty-avatar' defaultImage={defaultAvatar} fullProfileImageUrl={imageUrl} />)}
        <div className='faculty-avatar-label'>
          {popupLabel}
        </div>
      </div>
    );
  }
}
