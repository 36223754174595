/* eslint-disable indent, react/destructuring-assignment, sort-keys */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import '../css/CourseListing.less';

import { ASSIGNMENT_STATUS, ASSIGNMENT_TYPE } from '../managers/AssignmentManager';
import { CONTENT_ITEM_TYPES, CONTENT_MODE, PLAYER_TYPES } from '../managers/ContentManager';
import { DIALOG_NAMES } from '../managers/DialogManager';

import Auth from '../managers/AuthManager';

import { scrollBrowserWindowToTop } from '../utils';

import AssignmentService from '../services/AssignmentService';
import CourseService from '../services/CourseService';
import GradebookService from '../services/GradebookService';
import ImageService from '../services/ImageService';
import ResourcePacingService from '../services/ResourcePacingService';
import TeacherProductViewService from '../services/TeacherProductViewService';

import StudentAssignmentsPopup from '../components/StudentAssignmentsPopup';

export default
@inject(
  'assignmentManager',
  'classroomManager',
  'contentManager',
  'courseManager',
  'dialogManager',
  'productManager',
  'studentContentCardManager',
  'teacherProductViewManager',
  'userManager')
@observer
class CourseLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentItemId: null,
      contentItemType: null,
      contentName: null,
      contentSubtitle: null,
      courseElement: null,
      disablePresent: false,
      instruction: null,
      isFlowpaper: false,
      isLoading: false,
      learnosityPlayerShowing: false,
      learnosityScoringPlayerShowing: false,
      lessonPlayerShowing: false,
      renderStudentAssignmentsPopup: false
    };

    this.CourseList = SatCoreComponent('CourseElementList');
    this.DocReaderModal = SatCoreComponent('DocReaderModal');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');
    this.FullscreenModal = SatCoreComponent('FullscreenModal');
    this.LearnosityPlayerModal = SatCoreComponent('LearnosityPlayerModal');
    this.LearnosityScoringModal = SatCoreComponent('LearnosityScoringModal');
  }

  componentDidMount = async () => {
    const { productManager } = this.props;
    if (productManager.FROM_TEACHER_PRODUCTS_NAV) {
      await this.fetchSelectedTeacherProductCourseResourceIfApplicable();
    }
  }

  fetchSelectedTeacherProductCourseResourceIfApplicable = async () => {
    const { courseManager, teacherProductViewManager, productManager } = this.props;
    const { currentTeacherProductCourse } = teacherProductViewManager;
    if (productManager.FROM_TEACHER_PRODUCTS_NAV && !currentTeacherProductCourse) {
      this.setState({ isLoading: true });
      await TeacherProductViewService.fetchProductResourcesByInstitution({
        clearBeforeStoring: true,
        resourceId: courseManager.currentCourseId
      });
      teacherProductViewManager.setCurrentTeacherProductCourse(productManager.productResources?.[0]);

      this.setState({ isLoading: false });
    }
  }

  handleAnswerKey = async (contentItem) => {
    const { contentManager, userManager, classroomManager } = this.props;
    const option = await contentManager.getOptionsForAnswerKey(
      contentItem.attachmentContentItemId, CONTENT_ITEM_TYPES.PDF_RESOURCE,
      classroomManager.currentClassroomId, origin, userManager.isTeacher,
      userManager.userId
    );
    const { playerType, viewUrl, isFlowpaper } = option;

    const { attachmentContentItemTitle } = contentItem;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    }

    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    this.setState({
      contentName: attachmentContentItemTitle,
      contentUrl: viewUrl,
      docreaderViewerShowing: false,
      fileViewerShowing: true,
      instruction: null,
      isFlowpaper,
      learnosityPlayerShowing: false,
      lessonPlayerShowing: false
    });
  }

  handleAddAssignment = (courseElement, contentImageUrl, { allowMultipleResources = false } = {}) => {
    if (!courseElement) {
      throw new TypeError('CourseLayout.handleAddAssignment: courseElement is required');
    }
    const urlParams = new URLSearchParams(window.location.search);
    const { dialogManager, isCourseSearch, showCutScoreAssignType, closeParentModal, refreshRibbonHandler } = this.props;
    // if a close modal callback was sent, call it before showing the add assignment modal
    if (closeParentModal && closeParentModal !== undefined) {
      closeParentModal();
    }

    scrollBrowserWindowToTop();

    dialogManager.setOpenDialog(DIALOG_NAMES.ADD_ASSIGNMENT, {
      ...this.props,
      allowMultipleResources,
      alternateModeId: courseElement.alternateModeIdOverride || courseElement.alternateModeId,
      contentDueDate: ResourcePacingService.dueDate(courseElement),
      contentDuration: ResourcePacingService.duration(courseElement),
      contentImageUrl,
      contentItemEntityId: courseElement.contentItemEntityId,
      contentItemEntityTypeId: courseElement.contentItemEntityTypeId,
      contentItemEntitySubTypeId: courseElement.contentItemEntitySubTypeId,
      contentItemId: courseElement.contentItemId,
      contentName: courseElement.name,
      contentSubname: courseElement.description,
      contentTimeframeStartDate: courseElement.timeframeStartDate,
      contentTimeframeEndDate: courseElement.timeframeEndDate,
      timeframeStartDateStr: courseElement.timeframeStartDateStr,
      timeframeEndDateStr: courseElement.timeframeEndDateStr,
      timeframeStartTimeStr: courseElement.timeframeStartTimeStr,
      timeframeEndTimeStr: courseElement.timeframeEndTimeStr,
      courseContentItemId: !allowMultipleResources ?
        (courseElement.courseContentItemId || urlParams.get('courseId') || null) : urlParams.get('courseId'),
      courseElement,
      courseElementToggleDefaults: {
        includeInReports: courseElement.includeInReportsOverride,
        scoresReleased: courseElement.scoresReleasedOverride,
        studentInstruction: courseElement.instructionOverride,
        studentReview: courseElement.studentReviewOverride
      },
      courseResourceElementId: !allowMultipleResources ? courseElement.elementId : null,
      displayOverModal: isCourseSearch,
      learnosityActivityResourceId: courseElement.contentItemEntityId,
      showCutScoreAssignType,
      standards: courseElement.standardsInfo,
      resourceMode: courseElement.resourceMode,
      modeOverrideAllowed: courseElement.modeOverrideAllowed
    }, async () => {
      const { isCourseSearch, courseManager } = this.props;

      dialogManager.closeDialog(DIALOG_NAMES.ADD_ASSIGNMENT);
      this.setState({ isLoading: true });
      if (courseManager.allowRibbonTOC) {
        refreshRibbonHandler(null, null);
      } else {
        if (isCourseSearch) {
          await CourseService.searchCourseResourceElements();
        } else {
          await CourseService.forceRefreshCourseData();
        }
      }

      this.setState({ isLoading: false });
    });
  }

  handleEditAssignment = (assignment, contentImageUrl, classroomId, assignmentElement, deleteAssignment) => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.EDIT_ASSIGNMENT, {
        assignmentId: assignment.id,
        assignmentInstruction: assignment.instruction,
        classroomId,
        contentImageUrl,
        deleteAssignment,
        contentTimeframeStartDate: assignment.timeframeStartDate,
        contentTimeframeEndDate: assignment.timeframeEndDate,
        timeframeStartDateStr: assignment.timeframeStartDateStr,
        timeframeEndDateStr: assignment.timeframeEndDateStr,
        timeframeStartTimeStr: assignment.timeframeStartTimeStr,
        timeframeEndTimeStr: assignment.timeframeEndTimeStr,
      },
      () => this.closeEditAssignment(classroomId, assignmentElement)
    );
  }

  closeEditAssignment = async (_classroomId, assignmentElement) => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.EDIT_ASSIGNMENT);
    await this.showContentAssignments(assignmentElement);
  }

  handleGeneralMessage = (message) => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
        message
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.TEXT)
    );
  }

  handleGradebook = async (assignment) => {
    const { classroomManager, courseManager, dialogManager, history, t } = this.props;
    const { currentClassroomId } = classroomManager;
    dialogManager.closeAllDialogs();
    if (!assignment.currentCourseId) {
      assignment.currentCourseId = courseManager.currentCourseId; // course id is needed if not there
    }

    await GradebookService.navToAggregateGradebookSummaryFromExternalModal(
      {
        allAssignmentsLabel: t('gradebookLabel', 'Gradebook'),
        assignCardData: assignment,
        assignment: null,
        currentClassroomId: assignment.classroomId || currentClassroomId,
        history
      }
    );
    // history.push(`/gradebook?assignmentId=${assignment.id}&view=${VIEW_SELECTION.GRADEBOOK}&classroomId=${assignment.classroomId}`);
  }

  showContentAssignments = async (courseElement) => {
    const { assignmentManager, classroomManager, courseManager, dialogManager, t } = this.props;
    const { currentClassroomId } = classroomManager;
    const labelInfo = t('contentModalHeaderLabel', 'Assignment Information');
    // clear assignments happens in the the fetch at the right time; don't put it here
    dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_ASSIGNMENT, {
      ...this.props,
      assignmentElement: courseElement,
      assignments: assignmentManager.assignmentArray,
      classeslist: null,
      courseContentItemId: courseElement.courseContentItemId,
      courseResourceElementId: courseElement.courseResourceElementId,
      currentClassroomId,
      currentCourseId: courseManager.currentCourseId,
      elementId: courseElement.elementId,
      fetchCourseActivityInformation: this.fetchCourseActivityInformation,
      handleAnswerKey: this.handleAnswerKey,
      handleEditAssignment: this.handleEditAssignment,
      handleGradebook: this.handleGradebook,
      handlePresent: this.handlePresent,
      labelInfo,
      parentElementId: courseElement.parentElementId,
      possiblyShowAllAssignmentsButton: true
    }, this.closeContentAssignmentModal);
  }

  showContentStudentAssignments = async (courseElement) => {
    const { assignmentManager } = this.props;
    if (courseElement) {
      assignmentManager.clearAssignments();
      assignmentManager.loadAssignments(courseElement.studentActivities);
      this.setState({
        renderStudentAssignmentsPopup: true,
        courseElement,
        assignmentsPopupKey: Math.random()
      });
    }
  }

  hideContentStudentAssignments = async () => {
    await this.fetchCourseData();
    this.setState({ renderStudentAssignmentsPopup: false, courseElement: null });
  }

  refreshStudentAssignmentsData = async (courseElement) => {
    const { courseManager, dialogManager } = this.props;
    this.setState({ renderStudentAssignmentsPopup: false, courseElement: null });
    dialogManager.closeAllDialogs();
    const currentElement = courseManager.currentCourseElementList.find((elem) => elem.id === courseElement.id);
    this.showContentStudentAssignments(currentElement);
  }

  handleOpenInfoFromDialog = (infoName, contentItemDescription, resourceInfo, contentItemImageUrl, instruction, assignment, assignmentElement) => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.INFO, {
        infoName,
        contentItemDescription,
        resourceInfo,
        contentItemImageUrl,
        studentInstruction: instruction
      },
      () => this.closeOpenInfo(assignment.classroomId, assignmentElement));
  }

  handleOpenInfo = (infoName, contentItemDescription, resourceInfo, contentItemImageUrl) => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.INFO, {
        infoName,
        contentItemDescription,
        resourceInfo,
        contentItemImageUrl,
        studentInstruction: null
      },
      () => dialogManager.closeDialog(DIALOG_NAMES.INFO)
    );
  }

  closeOpenInfo = async (classroomId, assignmentElement) => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.INFO);
    await this.showContentAssignments(assignmentElement);
  }

  closeContentAssignmentModal = async () => {
    const { dialogManager, classroomManager } = this.props;
    dialogManager.closeAllDialogs();
    await classroomManager.fetchClassroomData(classroomManager.currentClassroomId);
    await this.fetchCourseData();
  }

  hideIframeFromOuterClick = async (event) => {

    if ((event.origin === Auth.publisher || event.origin === Auth.lesson) && event.data === 'hideIframe') {
      await this.fetchCourseData();
      this.setState({ lessonPlayerShowing: false, isLoading: false });
      window.removeEventListener('message', this.hideIframeFromOuterClick);
    }
  }

  hideIframe = async () => {
    await this.fetchCourseData();
    this.setState({ lessonPlayerShowing: false, isLoading: false });
  }

  hideModal = async () => {
    await this.fetchCourseData();
    this.setState({
      docreaderViewerShowing: false,
      fileViewerShowing: false,
      instruction: '',
      isLoading: false,
      learnosityPlayerShowing: false,
      learnosityScoringPlayerShowing: false
    });
  }

  handleView = async (courseElement, contentMode, pdfFormatSelected, publisherModeId = null) => {
    const {
      assignmentManager,
      classroomManager,
      contentManager,
      dialogManager,
      studentContentCardManager,
      userManager
    } = this.props;

    let option = null;
    const contentItemId = courseElement.entityId;
    const courseResourceElementId = courseElement.elementId;
    const entityTypeId = courseElement.type;
    const { name } = courseElement;
    let { pdfDeliveryFormat } = courseElement;
    const deliveryMode = typeof courseElement.deliveryMode === 'string' ? courseElement.deliveryMode : '';

    // if the user selected a particular format, use that.
    if (pdfFormatSelected) {
      pdfDeliveryFormat = pdfFormatSelected;
    }

    if (deliveryMode.includes('student') && userManager.isStudent) {
      option = await contentManager.getOptionsForStudentPreview(
        contentItemId, entityTypeId, pdfDeliveryFormat, origin,
        userManager.isTeacher, userManager.userId, courseResourceElementId,
        deliveryMode, classroomManager.currentClassroomId, publisherModeId
      );
      if (!contentMode) {
        contentMode = option.optionContentMode;
      }
    } else {
      if (contentMode === CONTENT_MODE.PRESENT) {
        const currentAssignment = assignmentManager.getLastAddedAssignment();
        option = await contentManager.getOptionsForTeacherPresent(
          contentItemId, currentAssignment, entityTypeId, origin, userManager.isTeacher
        );
      } else {
        option = await contentManager.getOptionsForTeacherPreview(
          contentItemId, entityTypeId, pdfDeliveryFormat, origin,
          userManager.canViewAsTeacher, userManager.userId,
          courseResourceElementId, classroomManager.currentClassroomId,
          publisherModeId
        );
      }
    }

    const { playerType, viewUrl, isFlowpaper } = option;

    if (!deliveryMode.includes('student_always') && !userManager.canViewAsTeacher) {
      // TODO: IS STUDENT PREVIEW MODE FOR LESSON GOING TO BE SOMETHING? API RETURNS ONLY TEACHER PREVIEW WITHOUT ACTIVITY.
      if (playerType === PLAYER_TYPES.LESSON_PLAYER) {
        this.handleGeneralMessage('This type is unsupported.');
        return false;
      }
    }
    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');

      if (studentContentCardManager.isLoading && courseElement.type === 'bookmark_resource') {
        studentContentCardManager.setIsLoading(false);
        this.forceUpdate();
        await this.fetchCourseData();
      }
      return;
    }

    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        contentItemId,
        contentItemType: entityTypeId,
        resourceName: name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
    }

    this.setState({
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      contentUrl: viewUrl,
      contentMode,
      isFlowpaper,
      contentItemId,
      contentItemType: entityTypeId,
      contentName: name,
      assignmentId: '',
      previewContentType: entityTypeId,
      previewContentItemId: '',
      resourceName: '',
      instruction: '',
      contentImageUrl: ''
    });
  }

  handleViewPresentLoading = async (params) => {
    this.setState({ isLoading: true });
    this.handlePresent(params);
  }

  handleSelfPacedOpen = async (contentMode, contentElement) => {
    const {
      assignmentManager, classroomManager, courseManager, studentContentCardManager
    } = this.props;

    this.setState({ isLoading: true });
    studentContentCardManager.setIsLoading(true);

    // first, create the assignment
    await assignmentManager.studentCreateAssignment(
      contentElement.contentItemId, contentElement.elementId,
      classroomManager.currentClassroomId, courseManager.currentCourseId);
    // then call to open it.
    if (assignmentManager.getLastAddedAssignment()) {
      await this.handlePresent({ assignment: assignmentManager.getLastAddedAssignment(), contentMode });
      this.setState({ isLoading: false });
      studentContentCardManager.setIsLoading(false);
    } else {
      studentContentCardManager.setIsLoading(false);
    }
  }

  // handlePresent used to call handleView. This had to diverge based on the required object type (assignment)
  handlePresent = async (params) => {
    const {
      assignmentManager,
      classroomManager,
      contentManager,
      courseId,
      courseManager,
      studentContentCardManager,
      tagContentManager,
      userManager,
    } = this.props;
    let option = null;
    const contentMode = params.contentMode || CONTENT_MODE.PREVIEW;
    const assignment = params.assignment || null;
    const publisherModeId = params.publisherModeId || null;
    if (userManager.isTeacher && contentMode === CONTENT_MODE.PREVIEW) {
      option = await contentManager.getOptionsForTeacherPreviewAssigned(assignment, window.location.origin,
        userManager.isTeacher, userManager.userId, publisherModeId);
    } else if (userManager.isTeacher && contentMode === CONTENT_MODE.PRESENT) {
      const today = new Date();
      const todayWithBuffer = new Date();
      todayWithBuffer.setHours(today.getHours() + 1);
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      const activityContentStr = JSON.stringify([{
        contentItemId: assignment.contentItemId,
        courseResourceElementId: assignment.courseResourceElementId
      }]);
      await assignmentManager.createAssignment({
        activityContent: activityContentStr,
        assignEntityIds: userManager.userId,
        assignEntityTypeId: ASSIGNMENT_TYPE.TEACHER,
        classroomId: classroomManager.currentClassroomId,
        courseContentItemId: courseManager.currentCourseId || courseId,
        endRealDate: tomorrow,
        instruction: '',
        startRealDate: todayWithBuffer,
        status: ASSIGNMENT_STATUS.PRESENTATION
      });
      const currentAssignment = await assignmentManager.getLastAddedAssignment();

      option = await contentManager.getOptionsForTeacherPresent(
        currentAssignment.contentItemId, currentAssignment, currentAssignment.entityTypeId, window.location.origin, userManager.isTeacher);
    } else if (userManager.isStudent && contentMode === CONTENT_MODE.ASSESS) {
      if (this.state.disablePresent) {
        return false;
      }
        // the assignment object could be an activity instance or an activity so check the ids.
        const activityId = assignment.activityId || assignment.id;
        const activityInstanceId = assignment.activityInstanceId || assignment.id;
        this.setState({ disablePresent: true });
        if (contentMode === CONTENT_MODE.ASSESS) {
          const preStartContentItemId = assignment.contentItemId;
          if (ASSIGNMENT_STATUS.getCheckableStatuses().includes(assignment.status)) {
            const isReady = await assignmentManager.checkActivity(activityId);
            if (isReady) {
              const isOk = await assignmentManager.startActivity(activityInstanceId, activityId, params.turnInLate);
              if (isOk === false) {
                return false;
              }
            } else {
              this.setState({ disablePresent: false });
              return false;
            }
          }

          const postStartContentItemId = assignment.contentItemId;
          if (preStartContentItemId !== postStartContentItemId && tagContentManager.useContentTags) {
          // this has been versioned. Make sure the image is added.
          // console.info('*** A new version: ' + postStartContentItemId +' has been created from: '+ preStartContentItemId);
            tagContentManager.createNewCardContentTagFromOld(preStartContentItemId, postStartContentItemId);
          }
        }

        // eslint-disable-next-line max-len
        option = await contentManager.getOptionsForStudentAssessment(assignment, window.location.origin, userManager.isTeacher, userManager.userId);
    } else if (userManager.isStudent && contentMode === CONTENT_MODE.REVIEW) {
      // eslint-disable-next-line max-len
      option = await contentManager.getOptionsForStudentReview(assignment, window.location.origin, userManager.isTeacher, userManager.userId);
    } else {
      // TODO: put in default and be mindful that this is a shared view (teacher/student).
      console.warn('unable to view');
      return false;
    }

    const { playerType, viewUrl, isFlowpaper } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      this.setState({ disablePresent: false });

      if (studentContentCardManager.isLoading && assignment.contentItemEntityTypeId === 'bookmark_resource') {
        studentContentCardManager.setIsLoading(false);
        this.forceUpdate();
        if (assignment.contentItemEntityTypeId === 'bookmark_resource') {
          await this.fetchCourseData();
        }
      }
      return;
    }
    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);
    const contentImageUrl = ImageService.getImageUrl(assignment);

    const { dialogManager } = this.props;
    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        resourceName: (assignment.resourceWebTitle !== '' && assignment.resourceWebTitle != null) ?
          assignment.resourceWebTitle : assignment.name,
        assignmentId: assignment.id,
        contentItemId: assignment.contentItemId,
        contentItemType: assignment.contentItemEntityTypeId,
        contentMode,
        instruction: assignment.activityInstruction,
        closeModalCallback: this.hideModal
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
      this.setState({ disablePresent: false });
      return;
    }

    const {
      resourceWebTitle, resourceWebSubtitle
    } = AssignmentService.getAssignmentTitleAndDescription(assignment, false);

    this.setState({
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      learnosityScoringPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_SCORING_PLAYER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      contentUrl: viewUrl,
      contentName: resourceWebTitle,
      contentSubtitle: resourceWebSubtitle,
      isFlowpaper,
      assignmentId: assignment.id,
      contentItemId: assignment.contentItemId,
      previewContentType: assignment.contentItemEntityTypeId,
      previewContentItemId: assignment.id,
      resourceName: assignment.name,
      instruction: assignment.instruction ? assignment.instruction : assignment.activityInstruction,
      contentImageUrl,
      activityId: assignment.activityId,
      contentMode,
      grade: assignment.grade,
      disablePresent: false
    });
  }

  renderLessonPlayer = () => {
    const { FullscreenModal } = this;
    const { userManager, contentManager } = this.props;

    if (this.props.renderLessonPlayer !== undefined) {
      return this.props.renderLessonPlayer();
    }

    return (
      <div className='course-content'>
        <FullscreenModal
          closeIframeCallback={this.hideIframe}
          contentImageUrl={this.state.contentImageUrl}
          contentName={this.state.contentName}
          instruction={this.state.instruction}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderFileViewer = () => {
    const { userManager } = this.props;
    const { FileViewerModal } = this;

    if (this.props.renderFileViewer !== undefined) {
      return this.props.renderFileViewer();
    }

    return (
      <div className='course-content'>
        <FileViewerModal
          assignmentId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentType={this.state.previewContentType}
          instruction={this.state.instruction}
          isFlowpaper={this.state.isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='file-viewer'
          resourceName={this.state.resourceName}
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderDocreaderViewer = () => {
    const { userManager, contentManager } = this.props;
    const { DocReaderModal } = this;
    if (this.props.renderDocreaderViewer !== undefined) {
      return this.props.renderDocreaderViewer();
    }

    return (
      <div className='course-content'>
        <DocReaderModal
          assignmentId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentType={this.state.previewContentType}
          instruction={this.state.instruction}
          isFlowpaper={this.state.isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='docreader-viewer'
          resourceName={this.state.resourceName}
          sessionId={contentManager.currentflowPaperSessionId}
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderLearnosityPlayer = () => {
    const { userManager, history } = this.props;
    const { LearnosityPlayerModal } = this;

    if (this.props.renderLearnosityPlayer !== undefined) {
      return this.props.renderLearnosityPlayer();
    }

    return (
      <div className='course-content'>
        <LearnosityPlayerModal
          assignmentId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentItemId={this.state.contentItemId}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentSubtitle={this.state.contentSubtitle}
          history={history}
          instruction={this.state.instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-player'
          resourceName={this.state.resourceName} />
      </div>
    );
  }

  renderLearnosityScoringModal = () => {
    const { userManager } = this.props;
    const { LearnosityScoringModal } = this;
    return (
      <div className='course-content'>
        <LearnosityScoringModal
          activityInstanceId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentItemId={this.state.contentItemId}
          contentSubTitle={this.state.contentItemDescription}
          contentTitle={this.state.contentName}
          grade={this.state.grade}
          history={this.props.history}
          instruction={this.state.instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-scoring'
          resourceName={this.state.resourceName}
          studentName={`${userManager.firstName} ${userManager.lastName}`} />
      </div>
    );
  }

  fetchCourseData = async () => {
    await CourseService.forceRefreshCourseData();
  }

  fetchCourseActivityInformation = async (assignment, assignmentElement, contentImageUrl) => {
    const { assignmentManager, userManager } = this.props;
    if (userManager.isTeacher) {
      const result = await assignmentManager.fetchCourseActivityInformation(assignment.id);
      if (result) {
        const title = result.webTitle ? result.webTitle : result.contentName;
        this.handleOpenInfoFromDialog(title, result.contentDescription, result.resourceInformation,
          contentImageUrl, assignment.instruction, assignment, assignmentElement);
      }
    } else {
      // do student things
    }
  }

  renderRest = () => {
    const {
      classroomId, classroomManager, courseElementList, courseId, courseManager, showAdminControls, userManager,
      handleAdminLibraryEdit = null
    } = this.props;
    const { CourseList } = this;
    if (this.props.renderRest !== undefined) {
      return this.props.renderRest();
    }

    return (
      <>
        <CourseList
          {...this.props}
          closeContentAssignments={this.closeContentAssignments}
          courseElementList={courseElementList || courseManager.currentCourseElementList}
          currentClassroomId={classroomId || classroomManager.currentClassroomId}
          currentCourseId={courseId || courseManager.currentCourseId}
          currentElementId=''
          handleAddAssignment={this.handleAddAssignment}
          handleAdminLibraryEdit={handleAdminLibraryEdit}
          handleAnswerKey={this.handleAnswerKey}
          handleOpenInfo={this.handleOpenInfo}
          handlePresent={this.handleViewPresentLoading}
          handleSelfPacedOpen={this.handleSelfPacedOpen}
          handleView={this.handleView}
          // eslint-disable-next-line react/destructuring-assignment
          isLoading={this.state.isLoading}
          isTeacher={userManager.isTeacher}
          showAdminControls={showAdminControls}
          showContentAssignments={this.handleGradebook} // DEMO-1626 - go directly to gradebook instead of assignments modal
          showContentStudentAssignments={this.showContentStudentAssignments} />
      </>
    );
  }

  shouldRenderRest = () => {
    const { courseManager, shouldRenderRest, courseElementList } = this.props;
    if (shouldRenderRest) {
      return shouldRenderRest();
    }
    const { currentCourseElementList } = courseManager;
    return (currentCourseElementList && currentCourseElementList.length > 0) || (courseElementList && courseElementList.length > 0);
  }

  render() {
    const { history } = this.props;

    const {
      courseElement,
      docreaderViewerShowing,
      fileViewerShowing,
      learnosityPlayerShowing,
      learnosityScoringPlayerShowing,
      lessonPlayerShowing,
      renderStudentAssignmentsPopup
    } = this.state;

    if (learnosityPlayerShowing) {
      return this.renderLearnosityPlayer();
    } else if (learnosityScoringPlayerShowing) {
      return this.renderLearnosityScoringModal();
    } else if (lessonPlayerShowing) {
      return (this.renderLessonPlayer());
    } else if (fileViewerShowing) {
      return (this.renderFileViewer());
    } else if (docreaderViewerShowing) {
      return (this.renderDocreaderViewer());
    } else if (renderStudentAssignmentsPopup) {
      return (
        <StudentAssignmentsPopup
          activityInstanceId={null}
          courseElement={courseElement}
          hideSelf={this.hideContentStudentAssignments}
          history={history}
          refreshData={this.refreshStudentAssignmentsData} />
      );
    } else if (this.shouldRenderRest()) {
      return (this.renderRest());
    }
    return (<></>);
  }
}

SatCoreRegister('CourseLayout', CourseLayout);
