import React, { useContext, useEffect } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Dropdown } from 'semantic-ui-react';

import '../../css/reports/ReportDropdown.less';

import { SatCoreRegister } from '../../SatCoreRegistry';

import { getSessionStorageItem } from '../../utils';

import { REPORT_FAILURE_BEHAVIOR_TYPE, REPORT_LESSON_FILTER_TYPE } from '../../services/reports/ReportConstants';

import ReportIdentityService from '../../services/reports/ReportIdentityService';
import ReportTableService from '../../services/reports/ReportTableService';
import ReportTypeService from '../../services/reports/ReportTypeService';

const ReportLessonFilterDropdown = observer((props) => {
  const { t } = props;

  const {
    reportCourseManager, reportIdentityManager, reportTypeManager
  } = useContext(MobXProviderContext);

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  useEffect(() => {
    (async () => {
      reportTypeManager.setSelectedReportLessonFilterType(
        getSessionStorageItem('selectedReportLessonFilterType') || REPORT_LESSON_FILTER_TYPE.ALL
      );
    })();
  }, []);

  const handleChangeReportLessonFilterDropdown = async (_event, { value }) => {
    // TODO // const { activeReportFacultyType, isCourseReport, isStandardsReport } = reportIdentityManager;
    const lessonFilterType = value;
    reportTypeManager.setSelectedReportLessonFilterType(lessonFilterType);

    const { selectedReportCourseContentItemId } = reportCourseManager;

    const { isFacultyDistrictReport, isFacultySchoolReport, isStandardsReport } = reportIdentityManager;

    if (isStandardsReport) {
      if ((isFacultyDistrictReport || isFacultySchoolReport) && !selectedReportCourseContentItemId) {
        // Report is a standards district or school report, but a courseId (from ReportCourseDropdown) is not selected yet.
        // Here, standards district and school reports need a courseId associated with them.
        // So for this case, do not attempt to fetch a new report.
        ReportTableService.forceRerenderReportTable();
        return;
      }
      let reportId = ReportTypeService.getLessonFilterReportIdIfApplicable(lessonFilterType);

      let failureBehaviorType;
      const { RETRY_WITH_PARSED_REPORT_ID_AND_FORCE_NO_DATA_STATE, NORMAL } = REPORT_FAILURE_BEHAVIOR_TYPE;
      if (reportId) {
        failureBehaviorType = RETRY_WITH_PARSED_REPORT_ID_AND_FORCE_NO_DATA_STATE;
      } else {
        failureBehaviorType = NORMAL;
      }
      reportId = reportId || ReportIdentityService.getReportId();

      await reportIdentityManager.fetchReportResponseJson({ failureBehaviorType, reportId });
    } else {
      ReportTableService.forceRerenderReportTable();
    }
  };

  const renderReportLessonFilterDropdown = () => {
    const { reportLessonFilterDropdownOptions, selectedReportLessonFilterType } = reportTypeManager;
    const { reportInfoClassNames } = reportIdentityManager;

    return (
      <div className={`report-dropdown report-lesson-filter-dropdown ${reportInfoClassNames}`}>
        <div className='dropdown-label'>
          <div className='text'>
            {t('type')}
          </div>
        </div>
        <Dropdown
          onChange={handleChangeReportLessonFilterDropdown}
          options={reportLessonFilterDropdownOptions.map((option) => {
            return {
              text: t(option.text),
              value: option.value
            };
          })}
          search
          selection
          value={selectedReportLessonFilterType} />
      </div>
    );
  };
  return renderReportLessonFilterDropdown();
});
export default ReportLessonFilterDropdown;

SatCoreRegister('ReportLessonFilterDropdown', ReportLessonFilterDropdown);
