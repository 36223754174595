import React, { useContext } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.less';
import 'slick-carousel/slick/slick-theme.less';
import '../css/CarouselContainer.less';

import { SatCoreRegister } from '../SatCoreRegistry';

const CarouselContainer = observer((props) => {
  const { navigationManager } = useContext(MobXProviderContext);

  return (
    <div className='carousel-container'>
      <Slider
        adaptiveHeight={navigationManager.allowCarouselAdaptiveHeight}
        {...props} />
    </div>
  );
});
export default CarouselContainer;

SatCoreRegister('CarouselContainer', CarouselContainer);
