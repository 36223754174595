import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Container } from 'semantic-ui-react';
import '../css/GroupRosterTable.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { PATH_TYPES, VIEW_SELECTION } from '../managers/NavigationManager';

import ClassroomService from '../services/ClassroomService';

@inject(
  'adminClassroomManager', 'classroomManager',
  'groupsManager', 'navigationManager', 'userManager')
@observer
class GroupRoster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: true,
      // TODO unused // serverError: false,
      // TODO unused // serverErrorMsg: ''
    };
    this.CourseListBanner = SatCoreComponent('CourseListBanner');
    this.GroupRosterTable = SatCoreComponent('GroupRosterTable');
  }

  async componentDidMount() {
    const {
      classroomManager, groupsManager, location,
      navigationManager, userManager
    } = this.props;
    const { currentClassroomId } = classroomManager;

    await groupsManager.fetchClassroomRoster(currentClassroomId);

    const isAdmin = userManager.isDistrictAdmin || userManager.isSchoolAdmin;
    if (isAdmin) {
      const result = await ClassroomService.initAdminData({ ...this.props });
      if (result && result.shouldInitMoreDataIfApplicable) {
        await this.initData();
      }
    } else {
      const urlParams = new URLSearchParams(location.search);
      if (!currentClassroomId && urlParams.has('classroomId')) {
        classroomManager.setCurrentClassroomId(urlParams.get('classroomId'));
      }
      navigationManager.clearAllPaths();
      navigationManager.setView(VIEW_SELECTION.GROUPS);
      await this.initData();
    }
  }

  initAdminData = async () => {
    const {
      adminClassroomManager, classroomManager, history, navigationManager
    } = this.props;
    const paramsToExcludeFromStr = 'classroomId';
    const {
      classroomId, institutionId, urlParamStr, userId, userName
    } = await navigationManager.getAdminUrlParams(paramsToExcludeFromStr);
    if (userId && userName) {
      navigationManager.setView(VIEW_SELECTION.USERS);
    } else {
      navigationManager.setView(VIEW_SELECTION.CLASSROOMS);
    }
    navigationManager.clearAllPaths();
    await this.setAdminBreadcrumbs(institutionId, userId, userName, urlParamStr);
    if (classroomId && classroomManager.getClassroom(classroomId)) {
      await this.initData();
    } else {
      const classroom = await adminClassroomManager.fetchClassroom(classroomId);
      if (classroom) {
        await classroomManager.setAdminClassroom(classroomId, classroom);
        await this.initData();
      } else {
        const routerUrl = `/classrooms?view=${VIEW_SELECTION.CLASSROOMS}&${urlParamStr}`;
        await history.push(routerUrl);
      }
    }
  }

  setAdminBreadcrumbs = async (institutionId, userId, userName, urlParamStr) => {
    const { navigationManager, userManager, t } = this.props;
    if (institutionId && userManager.isDistrictAdmin) {
      const schoolsBreadcrumbObj = {
        fromView: VIEW_SELECTION.DASHBOARD,
        path: { name: 'Schools' }
      };
      const schoolNameBreadcrumbObj = {
        fromView: VIEW_SELECTION.DASHBOARD,
        paramName: 'institutionName',
        path: {},
      };
      await navigationManager.setBreadcrumb(schoolsBreadcrumbObj);
      await navigationManager.setBreadcrumb(schoolNameBreadcrumbObj);
    }
    if (userId && userName) {
      let routerUrl_users = `/users?view=${VIEW_SELECTION.USERS}`;
      if (urlParamStr) {
        routerUrl_users += `&${urlParamStr}`;
      }
      const usersBreadcrumbObj = {
        fromView: VIEW_SELECTION.USERS,
        path: { name: 'Users', routerUrl: routerUrl_users }
      };
      let routerUrl_adminProfile = `/adminProfile?view=${VIEW_SELECTION.USERS}`;
      if (urlParamStr) {
        routerUrl_adminProfile += `&${urlParamStr}`;
      }
      const userNameBreadcrumbObj = {
        path: {
          name: userName,
          routerUrl: routerUrl_adminProfile
        }
      };
      await navigationManager.setBreadcrumb(usersBreadcrumbObj);
      await navigationManager.setBreadcrumb(userNameBreadcrumbObj);
    } else {
      let routerUrl_classrooms = `/classrooms?view=${VIEW_SELECTION.CLASSROOMS}`;
      if (urlParamStr) {
        routerUrl_classrooms += `&${urlParamStr}`;
      }
      const classroomsBreadcrumbObj = {
        fromView: VIEW_SELECTION.CLASSROOMS,
        path: { name: t('classesLabel', 'Classes'), routerUrl: routerUrl_classrooms }
      };
      await navigationManager.setBreadcrumb(classroomsBreadcrumbObj);
    }
  }

  initData = async () => {
    const { classroomManager, navigationManager } = this.props;
    if (!classroomManager.getClassroom(classroomManager.currentClassroomId)) {
      classroomManager.fetchClassroomData(classroomManager.currentClassroomId).then(() => {
        // TODO unused
        // const classroom = classroomManager.getClassroom(classroomManager.currentClassroomId);
        // this.setState({
        //   archived: classroom.archived,
        //   endDate: classroom.timezoneEndDate,
        //   failed: false,
        //   imageUrl: classroom.imageUrl,
        //   name: classroom.name,
        //   nickname: classroom.nickname,
        //   saved: false,
        //   startDate: classroom.timezoneStartDate
        // });

        navigationManager.addPath({
          currentClassroomId: classroomManager.currentClassroomId,
          currentCourseId: null,
          currentElementId: null,
          name: classroomManager.getClassName(classroomManager.currentClassroomId),
          treeNavigationFunction: null,
          type: PATH_TYPES.LINK,
        });
      });
    } else {
      // TODO unused
      // const classroom = classroomManager.getClassroom(classroomManager.currentClassroomId);
      // this.setState({
      //   archived: classroom.archived,
      //   endDate: classroom.timezoneEndDate,
      //   failed: false,
      //   imageUrl: classroom.imageUrl,
      //   name: classroom.name,
      //   nickname: classroom.nickname,
      //   saved: false,
      //   startDate: classroom.timezoneStartDate,
      // });
      navigationManager.addPath({
        currentClassroomId: classroomManager.currentClassroomId,
        currentCourseId: null,
        currentElementId: null,
        name: 'Groups',
        treeNavigationFunction: null,
        type: PATH_TYPES.TREE,
      });
    }
    await this.loadActiveRoster();
  }

  loadActiveRoster = async () => {
    this.setState({ isActive: true });
  }

  loadArchiveRoster = async () => {
    this.setState({ isActive: false });
  }

  render() {
    const { classroomManager, history, location, t, userManager } = this.props;
    const { isActive } = this.state;
    const isAdmin = userManager.isDistrictAdmin || userManager.isSchoolAdmin;

    let { readOnly } = this.props;

    const { CourseListBanner, GroupRosterTable } = this;

    const classroomId = classroomManager.currentClassroomId;
    const classroom = classroomManager.getClassroom(classroomId);
    const currentAccessCodeValue = classroomManager.getStudentAccessCode(classroomId);

    if (readOnly === undefined || readOnly === null) {
      readOnly = false;
    }

    return (
      <>
        {' '}
        {(!isAdmin || classroom) && (
        <Container className='group-roster-view' fluid>
          <CourseListBanner
            currentAccessCodeValue={currentAccessCodeValue}
            hideAddCourseButton={true}
            hideAddStudentButton={true}
            history={history}
            showAddGroupButton={true}
            showBreadCrumbs='bottom' />
          <Container>
            {(readOnly === false) ? (
              <Container className='group-roster-tab-container'>
                <Button
                  className={(isActive === true)
                    ? 'tab-button active' : 'tab-button'}
                  onClick={this.loadActiveRoster}>
                  {t('active', 'Active')}
                </Button>
                <Button
                  className={(isActive === false)
                    ? 'tab-button active' : 'tab-button'}
                  onClick={this.loadArchiveRoster}>
                  {t('archived', 'Archived')}
                </Button>
              </Container>
            ) : null}
            <Container className='group-roster-table-container'>
              <GroupRosterTable
                history={history}
                isActive={isActive}
                location={location}
                readOnly={readOnly} />
            </Container>
          </Container>
        </Container>
        )}
      </>
    );
  }
}

export default GroupRoster;

SatCoreRegister('GroupRoster', GroupRoster);
