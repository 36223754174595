import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Image,
  Input,
  Loader,
  Tab
} from 'semantic-ui-react';
import { SatCoreRegister } from '../../SatCoreRegistry';
import { CONTENT_ITEM_TYPES } from '../../managers/ContentManager';
import ModalBanner from './ModalBanner';
import Modal from '../Modal';
import '../../css/ClassImagePicker.less';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export default
@inject('classroomManager')
@observer
class ClassImagePicker extends Component {
  cropPaneIndex = 1;

  constructor(props) {
    super(props);
    this.state = {
      panes: [],
      crop: { unit: '%', aspect: 1.22, x: 5, y: 5, width: 90 },
      showCropper: false,
      loading: false,
      currentImageName: null
    };
  }

   cropImagePane = () => (
     <div className='image-crop-pane'>
       {this.state.showCropper
         ? this.renderCropElements()
         : null}
     </div>
   );

   uploadImagePane = () => (
     <div className='image-upload-pane'>
       <label className='ui button basic upload-button' htmlFor='class-image-file'>Choose File (PNG, JPG)</label>
       <Input
         accept='image/png, image/jpg'
         className='class-image-file'
         id='class-image-file'
         name='class-image-file'
         onChange={this.uploadClassImage}
         style={{ opacity: 0 }}
         type='file' />
       {this.currentImage()}
       <Loader active={this.state.loading} className='modal-loader' inline />
     </div>
   );

  currentImage = () => {
    const { classroomManager, classroomId } = this.props;
    const classroom = classroomManager.getClassroom(classroomId);
    const classImageUrl = classroomManager.getClassroomImageUrl(classroom.id);
    const { currentImageName } = this.state;

    if (currentImageName) {
      const fullImageUrl = CONTENT_ITEM_TYPES.getTempClassroomImageUrl(currentImageName);

      return (<Image className='current-image' src={fullImageUrl} wrapped />);
    } else if (classImageUrl) {
      return (<Image className='current-image' src={classImageUrl} wrapped />);
    }
    return (<div className='no-image'>Please upload an image file.</div>);
  }

  createPanes = () => {
    const uploadPane = { menuItem: 'Upload Image', render: () => <Tab.Pane>{this.uploadImagePane()}</Tab.Pane> };
    const cropPane = { menuItem: 'Crop Image', render: () => <Tab.Pane className='crop-tab'>{this.cropImagePane()}</Tab.Pane> };

    return [uploadPane, cropPane];
  };

  setCrop = (newCrop) => {
    this.setState({ crop: newCrop });
  };

  handleClose = () => {
    this.toggleClassImageEditor(false);
  }

  saveImage = async () => {
    const { currentImageName } = this.state;
    const { classroomManager, classroomId } = this.props;

    if (currentImageName) {
      const classroom = classroomManager.setClassroomImageUrl(classroomId, currentImageName);
      await classroomManager.updateClassroomObject(classroom);
    }
    this.toggleClassImageEditor(!!currentImageName, currentImageName);
  }

  toggleClassImageEditor = (saved, currentImageName) => {
    const { toggleClassImageEditor } = this.props;
    toggleClassImageEditor(saved, currentImageName);
    this.setState({ currentImageName: null });
  }

  uploadClassImage = async () => {
    this.setState({ loading: true });

    const formData = new FormData();
    const classImageFile = document.getElementById('class-image-file');

    if (!classImageFile.files.length) {
      this.setState({ loading: false });
      alert('Select an image to upload');
      return;
    }
    formData.append('contentItemImageSelectLocalImage', classImageFile.files[0]);

    const result = await this.props.classroomManager.updateClassroomImage(this.props.classroomId, formData);
    if (result) {
      this.setState({ currentImageName: result, loading: false });
    } else {
      this.setState({ currentImageName: null, loading: false });
      alert('Uploading the image has failed. Please try again');
    }
  };

  handleTabChange = (e, { activeIndex }) => {
    if (activeIndex === this.cropPaneIndex) {
      this.setState({ showCropper: true });
    } else {
      this.setState({ showCropper: false });
    }
  }

  loadImage = (image) => {
    this.scaleX = image.naturalWidth / image.width;
    this.scaleY = image.naturalHeight / image.height;
    const crop = { unit: '%', aspect: 1.22, x: 5, y: 5, width: 90 };
    this.setState({ crop });
  }

  submitCrop = async () => {
    const { classroomId, classroomManager } = this.props;
    const { currentImageName, crop } = this.state;
    if (this.scaleX !== undefined) {
      crop.x *= this.scaleX;
      crop.width *= this.scaleX;
    }

    if (this.scaleY !== undefined) {
      crop.y *= this.scaleY;
      crop.height *= this.scaleY;
    }

    const newImage = await classroomManager.submitCrop(classroomId, crop, currentImageName);
    this.toggleClassImageEditor(newImage !== '', newImage);
  }

  renderCropElements = () => {
    const { classroomManager, classroomId } = this.props;
    const classroom = classroomManager.getClassroom(classroomId);
    const classroomUrl = classroomManager.getClassroomImageUrl(classroom.id);
    const currentUrl = CONTENT_ITEM_TYPES.getTempClassroomImageUrl(this.state.currentImageName);

    if (this.state.currentImageName === null && classroomUrl === null) {
      return this.currentImage();
    }
    return (
      <div className='cropper-container'>
        <ReactCrop crop={this.state.crop} onChange={(newCrop) => this.setCrop(newCrop)} onImageLoaded={this.loadImage} src={(this.state.currentImageName) ? currentUrl : classroomUrl} />
      </div>
    );
  }

  render() {
    const panes = this.createPanes();
    const { showCropper } = this.state;
    const { openClassImagePicker } = this.props;
    return (
      <Modal
        className='class-picker-dialog'
        closeOnDimmerClick={false}
        closeOnEscape={true}
        onClose={this.handleClose}
        open={openClassImagePicker}>
        <ModalBanner
          label='Edit Card Image'
          onClose={this.handleClose} />
        <Modal.Content image scrolling>
          <div className='class-image-picker-parent'>
            <Tab onTabChange={this.handleTabChange} panes={panes} />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button basic className='save-button' onClick={this.handleClose} primary>Cancel</Button>
          <Button className='save-button' onClick={(showCropper) ? this.submitCrop : this.saveImage} primary>{(showCropper) ? 'Crop and Save' : 'Save'}</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('ClassImagePicker', ClassImagePicker);
