import { registerClass } from '../SatCoreRegistry';

import { register } from '../i18n';

import classroomManager from '../managers/ClassroomManager';
import courseManager, { ELEMENT_TYPE } from '../managers/CourseManager';
import dialogManager, { DIALOG_NAMES } from '../managers/DialogManager';
import navigationManager, { PATH_TYPES, VIEW_SELECTION } from '../managers/NavigationManager';
import productManager from '../managers/ProductManager';
import schoolManager from '../managers/SchoolManager';
import userManager from '../managers/UserManager';

import CourseNavigationService from './CourseNavigationService';
import ReportIdentityService from './reports/ReportIdentityService';
import TeacherProductViewService from './TeacherProductViewService';

const t = register('NavigationService');

export default class NavigationService {
  static manager = () => navigationManager;

  static getCurrentRouterUrl = () => {
    const routerUrl = `${window.location.pathname}${window.location.search}`;
    return routerUrl;
  }

  static handleCourseElementClick = async (courseElement, history) => {
    const { currentClassroomId } = classroomManager;
    const { currentCourseId } = courseManager;

    if (ELEMENT_TYPE.isBranchType((courseElement))) {
      await NavigationService.navigateToPath({
        currentClassroomId,
        currentCourseId,
        currentElementId: courseElement.elementId,
        name: courseElement.name,
        parentElementId: courseElement.parentElementId,
        treeNavigationFunction: NavigationService.treeNavigationFunction,
        type: PATH_TYPES.TREE
      }, history, courseManager, userManager.isStudent, classroomManager, userManager.isDistrictOrSchoolAdmin);
    }
  }

  static handleClickCourse = async (classroomId, course, history) => {
    const isCustomCourse = courseManager.isCustomCourse(course.id, course.name, course);

    classroomManager.setCurrentClassroomId(productManager.productClassroomId || classroomId);
    courseManager.setCurrentCourseId(course.id);
    courseManager.setCurrentElementId('');

    // Save current location
    await CourseNavigationService.saveCurrentCourseLocation(classroomId, course.id);

    navigationManager.addPath({
      currentCourseId: course.id,
      currentClassroomId: classroomId,
      currentElementId: '',
      name: course.name,
      type: PATH_TYPES.ROOT,
      treeNavigationFunction: NavigationService.treeNavigationFunction
    });
    let routerUrl = `/class?classroomId=${classroomManager.currentClassroomId}`;
    routerUrl += `&courseId=${course.id}`;
    routerUrl += `&elementId=ROOT&view=${VIEW_SELECTION.BOOK}`;
    routerUrl += `&isCustomCourse=${isCustomCourse}`;
    history.push(routerUrl);
  }

  static async treeNavigationFunction(currentCourseId, currentClassroomId, currentElementId, force = false) {
    const list = await courseManager.fetchCourseData(
      currentCourseId, currentClassroomId,
      currentElementId, force, userManager.isStudent
    );
    return list;
  }

  static navigateAwayFromGradebook = async (classroomManager, userManager, courseManager, history) => {
    if (navigationManager.pathList.length < 2) {
      history.replace(`/?view=${VIEW_SELECTION.DASHBOARD}`);
      return;
    }
    let pathIndex = 0;
    for (let i = 0; i < navigationManager.pathList.length; i++) {
      if (navigationManager.pathList[i].name === t('gradebookLabel', 'Gradebook')) {
        pathIndex = i - 1;
      }
    }
    const path = navigationManager.pathList[pathIndex];

    await NavigationService.navigateToPath(path, history, courseManager, userManager.isStudent, classroomManager, userManager.isDistrictOrSchoolAdmin);
  };

  static navigateToPath = async (
    path, history, courseManager, isStudent = false,
    classroomManager, isAdmin = false
  ) => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('view') === VIEW_SELECTION.REPORTS) {
      if (dialogManager.openedDialogs.has(DIALOG_NAMES.COURSE_ELEMENT)) {
        dialogManager.closeDialog(DIALOG_NAMES.COURSE_ELEMENT);
      }
      const noAddDefaultBreadcrumb = true;
      navigationManager.clearAllPaths(noAddDefaultBreadcrumb);
      if (path.name === t('courseReport') || path.name === t('standardsReport')) {
        // Added to fix bug that caused view to change to dashboard
        await history.push('/', { fromReport: true });
        ReportIdentityService.clearAllReportManagers();
        ReportIdentityService.clearAllReportSessionStorage();
        await history.push(path.routerUrl);
      } else {
        await history.push(path.routerUrl);
      }
      return;
    }

    const {
      currentCourseId, currentClassroomId, currentElementId,
      name, parentElementId, treeNavigationFunction, type, resetCurrentClassroomId = true
    } = path;

    const isCustomCourse = courseManager.isCustomCourse(currentCourseId);

    let paths = navigationManager.pathList;
    paths = paths && paths.length ? paths : [];

    // TODO remove // const lastIndex = paths.length ? paths.length - 1 : 0;

    if (type === PATH_TYPES.LINK &&
      (!NavigationService.valueAssigned(currentCourseId)
      && !NavigationService.valueAssigned(currentClassroomId))
    ) {
      if (name === 'Assignments') {
        history.push(`/assignments?view=${VIEW_SELECTION.ASSIGNMENTS}`);
        return;
      }
      if (name === 'Resources') {
        history.push(`/resources?view=${VIEW_SELECTION.RESOURCES}`);
        return;
      }
      if (!isAdmin && (name === t('classesLabel', 'Classes') || name === 'Home')) {
        if (productManager.isFromProduct) {
          // user is likely previewing a course (read only), so we do not want the 'Classes' (dashboard) breadcrumb link to function
          // i.e. in 'course preview mode', the user should not be able to go to the 'Classes' (dashboard) page
          return;
        }

        history.push(`/?view=${VIEW_SELECTION.DASHBOARD}`);
        if (classroomManager.currentClassroomId) {
          resetCurrentClassroomId && classroomManager.setCurrentClassroomId(null);
        }
        return;
      }
      const index = navigationManager.getPathIndex(path);

      /* handle special admin cases */
      if (isAdmin) {
        if (path.fromView === VIEW_SELECTION.DASHBOARD && path.name === 'Schools') {
          schoolManager.setSearchText('');
        }
      }

      /* handle case where path.routerUrl exists */
      if (path && path.routerUrl) {
        await history.push(path.routerUrl);
        return;
      }

      /* handle case where breadcrumb element was found */
      if (index >= 0) {
        const howFarBackToGo = index - paths.length + 1;
        history.go(howFarBackToGo);
        return;
      }

      /* else */
      history.push(`/?view=${VIEW_SELECTION.DASHBOARD}`);
      return;
    }

    if (type === PATH_TYPES.LINK &&
      (!NavigationService.valueAssigned(currentCourseId)
      && NavigationService.valueAssigned(currentClassroomId) && history)
    ) {
      history.push(`/courses?classroomId=${currentClassroomId}&view=${VIEW_SELECTION.COURSES}`);
      return;
    }
    if ((type === PATH_TYPES.TREE || type === PATH_TYPES.ROOT)
      && (NavigationService.valueAssigned(currentCourseId)
      && NavigationService.valueAssigned(productManager.productClassroomId || currentClassroomId))
    ) {
      let list;
      if (type === PATH_TYPES.ROOT && history) {
        let pathPrefix = '/class';
        if (isAdmin) {
          pathPrefix = history?.location?.pathname;
        }
        // eslint-disable-next-line max-len
        history.replace(`${pathPrefix}?classroomId=${currentClassroomId}&courseId=${currentCourseId}&elementId=ROOT&view=${VIEW_SELECTION.BOOK}&isCustomCourse=${isCustomCourse}`);
        list = await treeNavigationFunction(currentCourseId, currentClassroomId, currentElementId);
      } else {
        if (history) {
          if (isAdmin) {
            const pathPrefix = history?.location?.pathname;
            // eslint-disable-next-line max-len
            history.replace(`${pathPrefix}?classroomId=${currentClassroomId}&courseId=${currentCourseId}&elementId=${currentElementId}&view=${VIEW_SELECTION.BOOK}&isCustomCourse=${isCustomCourse}`);
            list = await treeNavigationFunction(currentCourseId, currentClassroomId, currentElementId);
          } else {
            // We need to save the new location if it's not the root.
            const currentView = navigationManager.viewSelection;
            if (currentView === VIEW_SELECTION.BOOK) {
              // save the location
              await CourseNavigationService.saveCurrentCourseLocation(currentClassroomId, currentCourseId, currentElementId);
            }
            // eslint-disable-next-line max-len
            history.replace(`/class?classroomId=${currentClassroomId}&courseId=${currentCourseId}&elementId=${currentElementId}&view=${VIEW_SELECTION.BOOK}&isCustomCourse=${isCustomCourse}`);
            list = await treeNavigationFunction(currentCourseId, currentClassroomId, currentElementId);
          }
        }
      }

      let classroom = null;
      let course = null;
      let courseCount = null;
      if (productManager.isFromProduct) {
        classroom = productManager.getProductClassroom();
        course = productManager.getProductCourse(courseManager.currentCourseId, courseManager.currentCourseName);
        courseCount = 1;
      } else {
        classroom = classroomManager.getClassroom(currentClassroomId);
        course = courseManager.getCurrentCourse(currentClassroomId);
        courseCount = courseManager.getClassroomCourseCount(currentClassroomId);
      }

      if (list.length < 1) {
        // Then the unit has no content. Use the parent unit as the path for breadcrumbs
        let parentList = await treeNavigationFunction(currentCourseId, currentClassroomId, parentElementId);
        list = [parentList.find((elem) => elem.elementId === currentElementId)];
        if (list && !list[0]) {
          // If the current element wasn't found try refreshing the cached data as it may be a new unit added.
          parentList = await treeNavigationFunction(currentCourseId, currentClassroomId, parentElementId, true);
          list = [parentList.find((elem) => elem.elementId === currentElementId)];
        }
        // To render correctly, reset the current view to the currentElement.
        // To improve this: In CourseManager create a way to run `fetchCourseData` to just return data without changing the actual view
        await treeNavigationFunction(currentCourseId, currentClassroomId, currentElementId);
        NavigationService.buildReFreshPaths(
          classroom, courseCount, classroomManager.getClassName(currentClassroomId), course, list, courseManager, isStudent, false);
      } else {
        NavigationService.buildReFreshPaths(
          classroom, courseCount, classroomManager.getClassName(currentClassroomId), course, list, courseManager, isStudent, true);
      }
    }
  }

  static valueAssigned = (v) => !(v === null || v === undefined)

  static buildReFreshPaths = (
    classroom, courseCount, classroomName, course, elementList, _courseManager, isStudent, removeCurrentItem = true
  ) => {
    navigationManager.clearAllPaths();
    if (!classroom || !course) {
      return;
    }

    if (!navigationManager.isStudent && navigationManager.viewSelection !== VIEW_SELECTION.GRADEBOOK) {
      if (!productManager.isFromProduct) {
        navigationManager.addPath({
          currentCourseId: null,
          currentClassroomId: classroom.id,
          currentElementId: null,
          name: classroomName,
          type: PATH_TYPES.LINK,
          treeNavigationFunction: null // only valid if type
        });
      }
      navigationManager.addPath({
        currentCourseId: course.id,
        currentClassroomId: classroom.id,
        currentElementId: null,
        name: course.name,
        type: PATH_TYPES.ROOT,
        treeNavigationFunction: NavigationService.treeNavigationFunction
      });
    } else {
      if (courseCount > 1) {
        navigationManager.addPath({
          currentCourseId: null,
          currentClassroomId: classroom.id,
          currentElementId: null,
          name: t('InitialBreadcrumb_plural', 'No Initial Breadcrumb Translation'),
          type: PATH_TYPES.LINK,
          treeNavigationFunction: null // only valid if type
        });
      }
      if (navigationManager.viewSelection === VIEW_SELECTION.BOOK && isStudent) {
        navigationManager.addPath({
          currentCourseId: course.id,
          currentClassroomId: classroom.id,
          currentElementId: null,
          name: (courseCount === 1) ? t('InitialBreadcrumb', 'No Initial Breadcrumb Translation') : course.name,
          type: PATH_TYPES.ROOT,
          treeNavigationFunction: NavigationService.treeNavigationFunction
        });
      }
    }

    let unitOrderCount = 1;
    if (elementList && elementList.length > 0) {
      const elem = elementList[0];
      if (elem && elem.contextPath && elem.contextPath.length > 1) {
        let paths = elem.contextPath.slice();
        if (removeCurrentItem) {
          paths.shift(); // remove the current item
        }
        paths = paths.reverse();
        if (paths[0].type === 'ROOT') {
          paths.shift(); // now remove the root this is handled in course view
        }
        if (navigationManager.skipTypes !== null && navigationManager.skipTypes.length > 0) {
          while (paths.length > 0 && navigationManager.skipTypes.includes(paths[0].type)) {
            paths.shift(); // skip all configured levels of the tree.  in most cases
            // skipTypes should be null this is for special customer
            // requested behavior
          }
        }
        for (let i = 0; i < paths.length; ++i) {
          const theName = (paths[i].name === '') ? `${paths[i].type} ${unitOrderCount++}` : paths[i].name;
          navigationManager.addPath({
            name: theName,
            currentElementId: paths[i].id,
            currentClassroomId: classroom.id,
            currentCourseId: course.id,
            type: PATH_TYPES.TREE,
            treeNavigationFunction: NavigationService.treeNavigationFunction
          });
        }
      }
    }

    if (productManager.FROM_TEACHER_PRODUCTS_NAV) {
      TeacherProductViewService.initBaseTeacherProductBreadcrumbs({
        clearFirst: false, shouldSetViewToProducts: false
      });
    }
  }
}

registerClass('NavigationService', NavigationService);
