/* eslint-disable sort-keys */
import thirdPartyManager from '../managers/ThirdPartyManager';
import userManager from '../managers/UserManager';

import { registerClass } from '../SatCoreRegistry';

/**
 * All methods in this service can be overridden via a custom `ThirdPartyServiceOverrideService` for any given satellite.
 *
 * See `c2c-our/src/services/ThirdPartyServiceOverride.js` for an example.
 */
export default class ThirdPartyService {
  /** Can be overridden via `ThirdPartyServiceOverride` for a given satellite. */
  static shouldAllowThirdPartyChatLauncherForActiveUser = () => {
    const allowedChatUserRoles = thirdPartyManager.allowedThirdPartyChatLauncherUserRoles;
    return allowedChatUserRoles?.includes?.(userManager.activePermissionId);
  }

  /** Can be overridden via `ThirdPartyServiceOverride` for a given satellite. */
  static removeThirdPartyChatLauncherIfApplicable = ({ alsoRegenerateChatLauncherId = false } = {}) => {
    const zohoChatElement = document.getElementById('zohohc-asap-web-helper-main');
    if (zohoChatElement) {
      zohoChatElement.remove();
      if (alsoRegenerateChatLauncherId) {
        thirdPartyManager.regenerateThirdPartyChatLauncherId();
      }
    }
  }

  /** Can be overridden via `ThirdPartyServiceOverride` for a given satellite. */
  static initAdditionalThirdPartyChatLauncherLogic = undefined;
}

registerClass('ThirdPartyService', ThirdPartyService);
