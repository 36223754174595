import defaultAssessmentSvg from '../img/default-assessment.svg';
import defaultChapterSvg from '../img/default-chapter.svg';
import defaultCourseSvg from '../img/default-course.svg';

import { customAssessmentDefaultCardImageUri } from './UriService';

import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';

import courseManager from '../managers/CourseManager';

import ReportTableService from './reports/ReportTableService';

export default class ImageService {
  static initImageService() {
    /* do nothing. can be overridden in satellites */
  }

  static getImageUrl(element) {
    let imageUrl = null;
    if (element && (element.imageUrl || element.elementContentItemImageUrl)) {
      imageUrl = CONTENT_ITEM_TYPES.getContentImageUrl(
        element.imageUrl || element.elementContentItemImageUrl
      );
    }
    return imageUrl;
  }

  static getDefaultReportImageSource(row) {
    const elementType = row?.original?.elementType;
    if (row?.canExpand && elementType === 'ROOT') {
      return defaultCourseSvg;
    } else if (ReportTableService.getExpandableRowClassNameIfApplicable(elementType, { row })) {
      return defaultChapterSvg;
    } else {
      return defaultAssessmentSvg;
    }
  }

  static getDefaultImageSourceAndClassName() {
    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);

    let className = 'default';
    let defaultImageSource = defaultAssessmentSvg;

    if (isCustomCourse) {
      if (courseManager.useSpecialCustomCourseCardImages) {
        className = 'custom-default-special';
        defaultImageSource = customAssessmentDefaultCardImageUri;
      } else {
        className = 'custom-default';
        defaultImageSource = defaultAssessmentSvg;
      }
    }
    return { className, defaultImageSource };
  }

  /**
   * @see https://github.com/DominicTobias/react-image-crop#faq
   * @see https://stackoverflow.com/questions/55595893/get-cropped-image-through-react-image-crop-module
   * @param {String} imgUrl - URL `string` of the image to be cropped
   * @param {Object} crop - react-image-crop `Object`
   * @param {String} fileName - Name of the returned `Blob`
   * @returns {Promise<Blob>} Cropped image as `Promise<Blob>`
   */
  static cropImage = async (imgUrl, crop, fileName) => {
    const canvas = document.createElement('canvas');
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    const image = new Image();
    const promise = new Promise((resolve, _reject) => {
      image.onload = () => {
        ctx.drawImage(
          image, crop.x, crop.y,
          crop.width, crop.height,
          0, 0, crop.width, crop.height
        );
        resolve();
      };
      image.crossOrigin = 'anonymous';
      image.src = imgUrl;
    }).then(() => new Promise((resolve, _reject) => {
      canvas.toBlob((blob) => {
        blob.name = fileName;
        resolve(blob);
      }, 'image/png');
    }));
    return promise;
  }

  static cleanUp() {
    /* do nothing. can be overridden in satellites */
  }
}
