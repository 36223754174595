import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ReactHtmlParser from 'react-html-parser';

import { Button, Icon, Image, Loader, Popup, Segment } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import '../css/GradebookCard.less';

import assessmentDefaultCardImage from '../img/default-assessment.svg';
import { customAssessmentDefaultCardImageUri } from '../services/UriService';

import { DIALOG_NAMES } from '../managers/DialogManager';

import { ASSIGNMENT_TYPE } from '../managers/AssignmentManager';

import ImageService from '../services/ImageService';
import NavigationService from '../services/NavigationService';
import UtilityService from '../services/UtilityService';
import GradebookService from '../services/GradebookService';

export default @inject(
  'assignmentManager', 'classroomManager', 'courseManager', 'navigationManager',
  'dialogManager', 'gradebookManager', 'userManager', 'groupsManager')
@observer
class GradebookCard extends Component {
  constructor(props) {
    super(props);
    this.AssignmentReportSettings = SatCoreComponent('AssignmentReportSettings');
  }

  getDefaultImage = () => {
    const { courseManager } = this.props;
    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);

    let customLeafCardImageClassNames = 'default';
    let defaultCustomLeafCardImg = assessmentDefaultCardImage;

    if (courseManager.useSpecialCustomCourseCardImages && isCustomCourse) {
      customLeafCardImageClassNames += 'custom-default-special';
      defaultCustomLeafCardImg = customAssessmentDefaultCardImageUri;
    }
    return (
      <Image
        alt='Custom default lesson image'
        className={customLeafCardImageClassNames}
        src={defaultCustomLeafCardImg} />
    );
  }

  handleEditAssignment = (contentImageUrl) => {
    const { classroomManager, gradebookManager, dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.EDIT_ASSIGNMENT, {
      assignmentId: gradebookManager.currentAssignmentId,
      assignmentInstruction: gradebookManager.currentAssignment.instruction,
      classroomId: classroomManager.currentClassroomId,
      contentImageUrl,
      standards: gradebookManager.currentAssignment.standardsInfo,
      contentTimeframeStartDate: gradebookManager.currentAssignment.timeframeStartDate,
      contentTimeframeEndDate: gradebookManager.currentAssignment.timeframeEndDate,
      timeframeStartDateStr: gradebookManager.currentAssignment.timeframeStartDateStr,
      timeframeEndDateStr: gradebookManager.currentAssignment.timeframeEndDateStr,
      timeframeStartTimeStr: gradebookManager.currentAssignment.timeframeStartTimeStr,
      timeframeEndTimeStr: gradebookManager.currentAssignment.timeframeEndTimeStr,

    }, this.closeEditAssignment);
  }

  closeEditAssignment = async () => {
    const {
      assignmentManager, classroomManager, courseManager,
      dialogManager, gradebookManager, userManager
    } = this.props;

    const assignment = await assignmentManager.getAssignmentAsync(gradebookManager.currentAssignmentId);

    if (assignment === null) {
      const classId = classroomManager.currentClassroomId;
      const courseId = courseManager.currentCourseId;
      const elementId = courseManager.currentElementId;

      gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(true);
      dialogManager.closeDialog(DIALOG_NAMES.EDIT_ASSIGNMENT);
      await classroomManager.fetchClassroomData(classroomManager.currentClassroomId);
      await courseManager.fetchCourseData(courseId, classId, elementId, true, false);
      await NavigationService.navigateAwayFromGradebook(classroomManager, userManager, courseManager, this.props.history);
      gradebookManager.setGradebookManagerLoadingFlagWhileHidingViewActive(false);
    } else {
      await gradebookManager.fetchGradebookData(gradebookManager.currentAssignmentId, {
        onSetAssignmentInstances: () => GradebookService.handleGradebookSummarySortOnFrontend()
      });
      gradebookManager.setCurrentAssignment(assignment);
      dialogManager.closeDialog(DIALOG_NAMES.EDIT_ASSIGNMENT);
    }
  }

  formatTime = (str) => {
    str = str.replace(/\s/g, '').replace(/^0+/, '');
    return str.toLowerCase();
  }

  readMore = (message) => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
      title: 'Student Instructions',
      message
    }, () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
  }

  editNotes = () => {
    const { dialogManager, gradebookManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.EDIT_ASSIGNMENT_NOTES, {
      assignmentId: gradebookManager.currentAssignmentId
    }, () => dialogManager.closeDialog(DIALOG_NAMES.EDIT_ASSIGNMENT_NOTES));
  }

  render() {
    const {
      gradebookManager,
      handleAnswerKey,
      onClickGotoCourse,
      onClickExportGradebook,
      assignmentManager,
      groupsManager,
      t
    } = this.props;
    const { currentAssignment } = gradebookManager;
    const { AssignmentReportSettings } = this;

    let showKey = false;
    let assignmentKeyTitle = '';
    if (currentAssignment.attachmentContentItemId !== '' && currentAssignment.attachmentContentItemId !== null) {
      showKey = true;
      assignmentKeyTitle = currentAssignment.attachmentContentItemTitle ? currentAssignment.attachmentContentItemTitle : 'Answer Key';
    }

    const contentImageUrl = ImageService.getImageUrl(currentAssignment);
    const transformFn = UtilityService.reactHtmlParserTransform;
    return (
      <Segment className='gradebook-card' vertical>
        {contentImageUrl ? <Image alt='Lesson image' src={contentImageUrl} /> : this.getDefaultImage()}
        <div className='gradebook-card-content'>
          <div className='button-container'>
            <div className='top-button-container'>
              <Button
                className='primary goto-course-button'
                onClick={onClickGotoCourse}>
                {t('goToCourseLabel')}
              </Button>
            </div>
            <div className='bottom-button-container'>
              <Button
                basic
                onClick={() => this.handleEditAssignment(contentImageUrl)}
                primary
                type='button'>
                {t('edit')}
              </Button>
              <Button
                basic
                onClick={onClickExportGradebook}
                primary
                type='button'>
                <Icon name='download' />
                {t('export')}
              </Button>
            </div>
          </div>
          <div className='fg-row'>
            {showKey ? (
              <>
                <div
                  className='answer-key card-list-text blue clickable'
                  onClick={() => handleAnswerKey(currentAssignment)}>
                  {assignmentKeyTitle}
                </div>
              </>
            )
              : ''}
          </div>
          <div className='fg-row'>
            <div className='card-label'>
              {assignmentManager.getTranslatedAssignmentTypeFlag(currentAssignment)}
              :
            </div>
            {(currentAssignment.totalActivityInstances > 0 && currentAssignment.subdomainTypeId === ASSIGNMENT_TYPE.GROUP) && (
            <Popup
              hideOnScroll={false}
              hoverable={true}
              on='hover'
              position='bottom left'
              trigger={
                <div className='card-value blue clickable'>{currentAssignment.totalActivityInstances}</div>
              }
              wide>
              <>
                {groupsManager.renderGroupAssignmentInfoPopupContent(
                  null, currentAssignment.groupName, currentAssignment.groupStudentNames, false, true
                )}
              </>
            </Popup>
            )}
            {(currentAssignment.totalActivityInstances > 0 && currentAssignment.subdomainTypeId !== ASSIGNMENT_TYPE.GROUP) && (
            <Popup
              hideOnScroll={false}
              hoverable={true}
              on='hover'
              onOpen={() => {
                assignmentManager.assignmentMemberPopupLoading = true;
                assignmentManager.clearAssignmentMembers().then(() => {
                  const forceLoad = true;
                  assignmentManager.fetchAssignmentMembers(currentAssignment.id, forceLoad).then(() => {
                    assignmentManager.assignmentMemberPopupLoading = false;
                  });
                });
              }}
              position='bottom left'
              trigger={
                <div className='card-value blue clickable'>{currentAssignment.totalActivityInstances}</div>
              }
              wide>
              <>
                {(assignmentManager.getAssignmentMembers().length === 0
                            && assignmentManager.assignmentMemberPopupLoading)
                  ? <Loader active inline /> : null}
                {assignmentManager.getAssignmentMembers().map((member, index) => (<div key={index} className='card-value'>{member}</div>)
                )}
              </>
            </Popup>
            )}
            {currentAssignment.totalActivityInstances < 1 &&
              <div className='card-value'>None</div>}
          </div>

          <div className='fg-row'>
            <div className='card-label'>
              {t('startDate')}
              :
            </div>
            <div className='card-value'>
              {currentAssignment.timezoneStartDate}
            </div>
          </div>

          <div className='fg-row'>
            <div className='card-label'>
              {t('startTime')}
              :
            </div>
            <div className='card-value'>
              {this.formatTime(currentAssignment.timezoneStartTime)}
            </div>
          </div>

          <div className='fg-row'>
            <div className='card-label'>
              {t('dueDate')}
              :
            </div>
            <div className='card-value'>{currentAssignment.timezoneEndDate}</div>
          </div>

          <div className='fg-row'>
            <div className='card-label'>
              {t('dueTime')}
              :
            </div>
            <div className='card-value'>{this.formatTime(currentAssignment.timezoneEndTime)}</div>
          </div>

          <div className='fg-row '>
            <div className='card-label'>
              {t('assignedBy')}
              :
            </div>
          </div>
          <div className='fg-row '>
            <div className='card-value small'>
              {`${currentAssignment.assignerFirstName} ${currentAssignment.assignerLastName} (${currentAssignment.createDate})`}
            </div>
          </div>

          <div className='hr-rule' />
          <div className='fg-row '>
            <AssignmentReportSettings currentAssignment={currentAssignment} {...this.props} />
          </div>

          <div className='fg-row '>
            <div className='card-label small'>
              {t('releaseToReportsLabel')}
              :
            </div>
            <div className='card-value small'>
              {currentAssignment.includeInReports ? 'Yes' : 'No'}
            </div>
          </div>

          <div className='fg-row '>
            <div className='card-label small'>
              {t('scoresReleased')}
              :
            </div>
            <div className='card-value small'>
              {currentAssignment.scoresReleased ? 'Yes' : 'No'}
            </div>
          </div>

          <div className='fg-row '>
            <div className='card-label small'>
              {t('studentReview')}
              :
            </div>
            <div className='card-value small'>
              {currentAssignment.studentReview ? 'Yes' : 'No'}
            </div>
          </div>

          <div className='hr-rule' />
          <div className='fg-col instruc'>
            <div className='fg-row'>
              <div className='card-label'>
                {t('studentInstructions')}
                :
              </div>
            </div>

            <div className='fg-row'>
              <div className='card-value-multi'>
                {ReactHtmlParser(currentAssignment.instruction, {
                  transform: transformFn
                })}
              </div>
            </div>

            <div className='block' />
            <div className='fg-row locked'>
              <div
                className='clickable blue'
                onClick={() => this.readMore(
                  ReactHtmlParser(currentAssignment.instruction, {
                    transform: transformFn
                  })
                )}>
                {t('readMore')}
              </div>
            </div>
          </div>

          <div className='hr-rule' />

          <div className='fg-col instruc'>
            <div className='fg-row'>
              <div className='card-label'>
                {t('notes')}
                :
              </div>
            </div>
            <div className='fg-row'>
              <div className='card-value-multi'>
                {ReactHtmlParser(currentAssignment.note, {
                  transform: transformFn
                })}
              </div>
            </div>
            <div className='fg-row locked'>
              <div className='clickable blue' onClick={this.editNotes}>
                {t('addOrEditNotes')}
              </div>
            </div>
          </div>
        </div>
      </Segment>
    );
  }
}

SatCoreRegister('GradebookCard', GradebookCard);
