import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Container } from 'semantic-ui-react';
import ReactHtmlParser from 'react-html-parser';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import Modal from '../Modal';

import '../../css/StudentFeedBackModal.less';

import UtilityService from '../../services/UtilityService';

export default
@inject('assignmentManager', 'gradebookManager')
@observer
class StudentFeedbackModal extends Component {
  constructor(props) {
    super(props);
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.state = {
      feedback: ''
    };
  }

  async componentDidMount() {
    const { assignmentManager, assignmentInstanceId } = this.props;

    const feedback = await assignmentManager.viewAssignmentFeedback(assignmentInstanceId);

    if (feedback !== null && feedback !== '') {
      this.setState({
        feedback
      });
    }
  }

  cancel = () => {
    this.setState({
      feedback: null
    });

    this.closeStudentFeedbackModal();
  }

  closeStudentFeedbackModal = () => {
    this.props.closeStudentFeedbackModal();
  }

  render() {
    const { feedbackOpen, t } = this.props;
    const { ModalBanner } = this;

    const transformFn = UtilityService.reactHtmlParserTransform;
    return (
      <>
        <Modal
          className='EditAssignmentModal'
          closeOnDimmerClick={false}
          closeOnEscape={true}
          onClose={this.cancel}
          open={feedbackOpen}
          size='small'>
          <ModalBanner
            label={t('feedBack', 'Missing view or preview translation')}
            onClose={this.cancel} />
          <Modal.Content scrolling>
            <Container>
              <div className='feedback-text'>
                {ReactHtmlParser(this.state.feedback, {
                  transform: transformFn
                })}
              </div>
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              className='cancelButton'
              onClick={this.cancel}
              primary
              type='button'>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

SatCoreRegister('StudentFeedbackModal', StudentFeedbackModal);
