import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Container, Dimmer, Loader, Pagination } from 'semantic-ui-react';

import '../css/CartModalUsers.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

export default
@inject('adminUsersManager', 'filteredHeaderTableManager')
@observer
class CartModalUsers extends Component {
  filteredHeaderTableKey = 'cart-modal-users-table';

  constructor(props) {
    super(props);
    this.FilteredHeaderTable = SatCoreComponent('FilteredHeaderTable');
    this.state = {
      loading: false,
      rolesData: []
    };
  }

  componentDidMount = async () => {
    const { filteredHeaderTableManager, institutionId } = this.props;
    filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);
    await this.fetchRoles();
    await this.fetchUsers(institutionId);
  }

  async componentDidUpdate(prevProps) {
    const { filteredHeaderTableManager, institutionId } = this.props;
    let changesMade = false;
    if (institutionId !== prevProps.institutionId) {
      changesMade = true;
    }
    if (!institutionId && filteredHeaderTableManager.isCleared) {
      filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);
      changesMade = true;
    }
    if (changesMade) {
      await this.fetchUsers();
    }
  }

  roleFilter = () => {
    const { adminUsersManager, roleName } = this.props;
    const role = adminUsersManager.roles.find((role) => role.name.match(roleName));
    return role && { field: 'userRoles', operator: 'contains', value: role.id };
  }

  fetchUsers = async () => {
    const { adminUsersManager, filteredHeaderTableManager, institutionId } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      this.setState({ loading: true });
      await adminUsersManager.getUsersByInstitution(
        institutionId,
        tableData.activePage,
        tableData.column,
        tableData.direction,
        [...tableData.filters, this.roleFilter()].filter((item) => item)
      );
      this.setState({ loading: false });
    } else {
      this.setState({ loading: true });
      await adminUsersManager.getUsersByInstitution(institutionId);
      this.setState({ loading: false });
    }
  }

  fetchRoles = async () => {
    const { adminUsersManager } = this.props;
    await adminUsersManager.fetchSatelliteRoles();
    this.createRolesData();
  }

  onPageChange = async (_event, pageInfo) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, pageInfo.activePage);
      await this.fetchUsers();
    }
  }

  handleSort = async (clickedColumn, filters) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      if (clickedColumn) {
        const newDirection = tableData.direction === 'ascending' ? 'descending' : 'ascending';
        filteredHeaderTableManager.setSortColumn(this.filteredHeaderTableKey, clickedColumn);
        filteredHeaderTableManager.setSortDirection(this.filteredHeaderTableKey, newDirection);
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
        await this.fetchUsers();
      } else {
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      }
    }
  }

  handleFilter = async (filters) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, 1);
      await this.fetchUsers();
    }
  }

  renderRolesContent = (user) => {
    // We have to get valid role names for the user's role ids that can be shown in satellite.
    // We need both a rendered UL list for the popup and a regular flat list of names for the column cell.
    const { adminUsersManager } = this.props;
    const roleIdsArray = user.userRoleIds.split(',');
    const roles = (roleIdsArray || []).map((roleId) => adminUsersManager.roles.find((role) => role.id === roleId)).filter((role) => role);
    const validRoleNames = roles.map((role) => role.name);

    let rolesListItemContent = null;
    let roleColumnCellText = '—';
    // if more than one role, get the popup content, and display the length in the cell
    if (validRoleNames.length > 1) {
      rolesListItemContent = (
        <ul className='roles-list'>
          {this.roles(roleIdsArray).map((role, index) => <li key={index}>{role.name}</li>)}
        </ul>
      );
      roleColumnCellText = validRoleNames.length;
    } else if (validRoleNames.length === 1) {
      roleColumnCellText = validRoleNames[0];
    }
    return { rolesListItemContent, roleColumnCellText, validRoleNames };
  }

  userStatusFilterOptions = [
    {
      key: 'default',
      text: 'Choose a status...',
      value: 0
    },
    {
      key: 'active',
      text: 'Active',
      value: '1'
    },
    {
      key: 'inactive',
      text: 'Inactive',
      value: '0'
    }
  ];

  createRolesData = () => {
    const { adminUsersManager } = this.props;
    const rolesData = [{
      key: 'default',
      text: 'Choose a role...',
      value: 0
    }];
    adminUsersManager.roles.map((role) => {
      rolesData.push(
        {
          key: role.id,
          text: role.name,
          value: role.id
        }
      );
    });
    this.setState({ rolesData });
  }

  createHeaderData = () => {
    const { roleName } = this.props;
    const { rolesData } = this.state;
    return [
      {
        label: 'Email/Username',
        sortKey: 'username',
        filterKey: 'username',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: 'First Name',
        sortKey: 'firstName',
        filterKey: 'firstName',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: 'Last Name',
        sortKey: 'lastName',
        filterKey: 'lastName',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: 'School',
        sortKey: 'institutionName',
        filterKey: 'institutionName',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: 'User Role',
        sortKey: 'userRoles',
        filterKey: roleName ? '' : 'userRoles',
        filterLabel: 'Search for Role',
        filterComparator: 'contains',
        filterPopupType: 'optionSelection',
        filterDropdownOptions: rolesData,
        filterAutoCompleteCallback: null
      },
      {
        label: 'Status',
        sortKey: 'status',
        filterKey: 'status',
        filterLabel: '',
        filterPopupType: 'optionSelection',
        filterDropdownOptions: this.userStatusFilterOptions,
        filterAutoCompleteCallback: null
      },
      // TODO commented out per CF-2501
      // {
      //   label: 'Last Login',
      //   sortKey: 'lastLogin',
      //   filterKey: '',
      //   filterLabel: '',
      //   filterAutoCompleteCallback: null
      // },
      {
        /* 'View' button placeholder */
        label: '',
        sortKey: '',
        filterKey: ''
      }
    ];
  }

  createBodyData = () => {
    const { adminUsersManager, users, onAdd, onRemove, addLabel = 'Add', addedLabel = 'Added' } = this.props;
    return adminUsersManager.users.map((user) => {
      const { rolesListItemContent, roleColumnCellText, validRoleNames } = this.renderRolesContent(user);
      const inCart = !!users.find((item) => item.id === user.id);
      return [
        {
          /* Email/Username */
          columnName: 'username',
          columnText: user.username,
          columnClassName: 'truncate-long',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTooltipEvent: 'click'
        },
        {
          /* First Name */
          columnName: 'firstName',
          columnText: user.firstName,
          columnClassName: 'truncate-short',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTooltipEvent: 'click'
        },
        {
          /* Last Name */
          columnName: 'lastName',
          columnText: user.lastName,
          columnClassName: 'truncate-short',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTooltipEvent: 'click'
        },
        {
          /* School */
          columnName: 'school',
          columnText: user.institutionName,
          columnClassName: 'truncate-long',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTooltipEvent: 'click'
        },
        {
          /* User role */
          columnName: 'userRole',
          columnText: roleColumnCellText,
          columnClassName: validRoleNames.length > 1 ? 'truncate-short clickableCell' : 'truncate-short',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTooltipEvent: 'click',
          tooltipContent: rolesListItemContent
        },
        {
          /* Status */
          columnName: 'enabled',
          columnText: user.enabled ? 'Active' : 'Inactive',
          columnClassName: 'truncate-short',
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTooltipEvent: 'click'
        },
        // TODO commented out per CF-2501
        // {
        //   /* Last Login */
        //   columnName: 'lastLogin',
        //   columnText: user.lastLogin ? user.lastLogin : '—',
        //   columnClassName: 'truncate-short',
        //   columnOnClick: null,
        //   columnButtonOnClick: null,
        //   showTooltip: true,
        //   showTooltipEvent: 'click',
        //   tooltipContent: user.lastLogin ? user.lastLogin : 'No last login to display.'
        // },
        {
          /* 'Add' button placeholder */
          columnName: null,
          columnText: inCart ? addedLabel : addLabel,
          columnClassName: '',
          columnOnClick: null,
          columnButtonOnClick: () => (inCart ? onRemove(user) : onAdd(user)),
          columnButtonBasic: !inCart
        }
      ];
    });
  }

  render() {
    const { adminUsersManager, filteredHeaderTableManager } = this.props;
    const { FilteredHeaderTable } = this;
    const tableBodyData = adminUsersManager.loaded ? this.createBodyData() : [];
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    let activePage = 0;
    let column = '';
    let direction = '';
    let filters = [];
    if (tableData) {
      activePage = tableData.activePage;
      column = tableData.column;
      direction = tableData.direction;
      filters = tableData.filters;
    }

    const loading = this.state.loading || !adminUsersManager.loaded;

    return (
      <Container className='cart-modal-users' fluid>
        <Container>
          <div className='tableContainerHeader'>
            <div className='tableTitle theme-header-title'>
              Students
            </div>
            <div className='tableSpacer' />
            <div className='tableRightHeader'>
              <div className='tablePagination'>
                {tableBodyData.length > 0 && (
                <Pagination
                  activePage={activePage}
                  onPageChange={this.onPageChange}
                  totalPages={adminUsersManager.totalPages} />
                )}
              </div>
            </div>
          </div>
          <div className='tableContainer'>
            {loading && (!tableBodyData.length) && <Loader active className='modal-loader' />}
            <Dimmer.Dimmable
              className='cart-modal-dimmable'
              dimmed={loading && !!tableBodyData.length}>
              <Dimmer active={loading && !!tableBodyData.length} inverted>
                <Loader className='modal-loader' />
              </Dimmer>

              <FilteredHeaderTable
                allowClearAllFilters
                column={column}
                direction={direction}
                filtersData={filters}
                handleFilter={this.handleFilter}
                handleSort={this.handleSort}
                tableBodyData={tableBodyData}
                tableHeaderData={this.createHeaderData()}
                tableId={this.filteredHeaderTableKey} />
            </Dimmer.Dimmable>
          </div>

          {!loading && (!tableBodyData.length) && (
          <div className='null-state-panel'>
            No Data Found
          </div>
          )}
        </Container>
      </Container>
    );
  }
}

SatCoreRegister('CartModalUsers', CartModalUsers);
