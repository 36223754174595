import decode from 'jwt-decode';

import { getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem } from '../utils/session';

export class AuthManager {
  publisherSatelliteCode = '';

  authKey = null;

  jwtToken = null;

  lesson = null;

  overrideLessonPlayer = false;

  showShuffleOverride  = false;

  setPublisherSatelliteCode = (satelliteCode) => {
    this.publisherSatelliteCode = satelliteCode;
  }

  setAuthKey = (key) => {
    this.authKey = key;
  }

  setJwtToken = (key) => {
    this.jwtToken = key;
  }

  init(API_ROOT, PUBLISHER_ROOT, PUBLISHER_SATELLITE_CODE, LOGO, LESSON_ROOT, {
    // additional (optional) props
    LOGO_STUDENT,
    SHOW_SHUFFLE_OVERRIDE
  } = {}) {
    this.ecms = API_ROOT;
    this.publisher = PUBLISHER_ROOT;
    this.publisherSatelliteCode = PUBLISHER_SATELLITE_CODE;
    this.JSONendpoints = ['/login', '/api/createLMSActivities'];
    this.logo = LOGO;
    this.logoStudent = LOGO_STUDENT || LOGO;
    this.lesson = LESSON_ROOT || null;
    this.showShuffleOverride = (SHOW_SHUFFLE_OVERRIDE !== undefined )?SHOW_SHUFFLE_OVERRIDE:false;
  }

  getToday = () => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    return today;
  }

  async login(username, password) {
    // Set the publisher satellite code
    const publisherSatelliteCode = `${this.publisherSatelliteCode}`;
    // Get a token from api server using the fetch api
    const token = await this.post(`${this.ecms}/login`, {
      body: JSON.stringify({
        username,
        password,
        publisherSatelliteCode
      })
    });
    if (token) {
      this.setToken(token);
      return true;
    }
    return false;
  }

  async checkUser() {
    return await this.safe_fetch(`${this.ecms}/api/checkUser`);
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // Getting token from session storage

    if (token === null || token === undefined || token === 'null') return false;
    if (this.isTokenExpired(token)) return false;
    if (!token.includes('Bearer')) return false;
    return true;
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) { // Checking if token is expired.
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  setToken(idToken) {
    try {
      // Saves user token
      this.setJwtToken(idToken);
      setSessionStorageItem(`jwtToken_${this.publisherSatelliteCode}`, idToken);
    } catch (error) {
      // TODO RM: this is a work around because for LTI launch of /lrnPlayer we aren't authenticated yet when react is
      // initializing this manager so sesssion storage is not allowed.  Not sure if there's a better way to avoid this.
      // no-op this shouldn't happen but may happen for lrnPlayer launch.
      console.debug('SessionStorage access denied', error);
    }
  }

  getToken() {
    try {
      // Retrieves the user token
      const publisherSatelliteCode = this.publisherSatelliteCode || getSessionStorageItem('dynamicPublisherSatelliteCode');
      return getSessionStorageItem(`jwtToken_${publisherSatelliteCode}`);
    } catch (error) {
      // TODO RM: this is a work around because for LTI launch of /lrnPlayer we aren't authenticated yet when react is
      // initializing this manager so sesssion storage is not allowed.  Not sure if there's a better way to avoid this.
      // no-op this shouldn't happen but may happen for lrnPlayer launch.
      console.debug('SessionStorage access denied', error);
      return this.jwtToken;
    }
  }

  logout() {
    // Clear user token and profile data from session storage
    const publisherSatelliteCode = this.publisherSatelliteCode || getSessionStorageItem('dynamicPublisherSatelliteCode');
    removeSessionStorageItem('dynamicPublisherSatelliteCode');
    removeSessionStorageItem(`jwtToken_${publisherSatelliteCode}`);
  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    return decode(this.getToken());
  }

  convertToFormData(data, parentKey = null) {
    if (Array.isArray(data)) {
      return data.map((value, index) => this.convertToFormData(value, `${parentKey || ''}[${index}]`)).join('&');
    } else if (typeof data === 'object' && data !== null) {
      return Object.entries(data).map(([key, value]) => this.convertToFormData(value, parentKey ? `${parentKey}[${key}]` : key)).join('&');
    }
    return [parentKey, encodeURIComponent(typeof data === 'string' ? data : `${data}`)].join('=');
  }

  convertToFormDataMultipart(data, formData = new FormData(), parentKey = null) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach((key) => this.convertToFormDataMultipart(data[key], formData, parentKey ? `${parentKey}[${key}]` : key)
      );
    } else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
    return formData;
  }

  async getCleverLoginUrl() {
    const result = await this.safe_fetch(`${this.ecms}/api/sso/clever/url?code=${this.publisherSatelliteCode}`);
    return result ? result.clever_url : null;
  }

  async getClassLinkLoginUrl() {
    const result = await this.safe_fetch(`${this.ecms}/api/sso/classlink/url?code=${this.publisherSatelliteCode}`);
    return result ? result.classlink_url : null;
  }

  async getEdLinkLoginUrl() {
    const result = await this.safe_fetch(`${this.ecms}/api/sso/isEdlinkEnabled?code=${this.publisherSatelliteCode}`);
    return result ? result.edlink_enabled : null;
  }

  async getOneRosterDistricts() {
    const result = await this.safe_fetch(`${this.ecms}/api/sso/ViewOneRosterDistricts?code=${this.publisherSatelliteCode}`);
    return result ? result.districts : null;
  }

  async loginWithOneRoster(username, password, institutionId) {
    // Get a token from api server using the fetch api
    try {
      const result = await this.fetch(`${this.ecms}/api/sso/oneroster/oauth/${this.publisherSatelliteCode}`, {
        method: 'POST',
        body: {
          username,
          password,
          institutionId
        }
      });
      this.setToken(result);
      return true;
    } catch (e) {
      console.error(e);
      // Setting the token in session storage
      return false;
    }
  }

  async logInWithAuthKey(authKey) {
    const response = await this.safe_fetch(`${this.ecms}/api/checkUser?authKey=${authKey}`);
    if (response) {
      this.setToken(response.jwtToken);
      return response;
    }
    return null;
  }

  async fetch(url, { multipart = false, ...options }) {
    // performs api calls sending the required authentication headers
    const headers = {
      Accept: 'application/json'
    };

    const JSONendpoint = this.JSONendpoints.some((endpoint) => (url.indexOf(endpoint) >= 0));
    if (JSONendpoint) {
      headers['Content-Type'] = 'application/json';
    } else {
      !multipart && (headers['Content-Type'] = 'application/x-www-form-urlencoded');
    }
    if (this.loggedIn()) {
      headers.Authorization = this.getToken();
    }
    if (typeof options.body === 'object') {
      if (multipart) {
        options.body = this.convertToFormDataMultipart(options.body);
      } else {
        options.body = this.convertToFormData(options.body);
      }
    }

    return fetch(url, {
      headers,
      ...options
    }).then((response) => {
      const success = response.status >= 200 && response.status < 300; // Success status lies between 200 to 300
      if (success) {
        return url.includes('/login') ? response.headers.get('authorization') : response.json();
      }
      response.statusText && console.info(response.statusText);
      throw { message: response.statusText || 'Something went wrong', response };
    }).catch((e) => {
      throw { message: e.message, response: e };
    });
  }

  /** @deprecated */
  async request(url, { withoutCheck, throwError, ...options }) {
    return await this.fetch(url, { method: 'GET', ...options })
      .then((response) => (url.includes('/login') || response.status === 'SUCCESS' || response.status === 'WARNING' || withoutCheck ? response : null))
      .catch((error) => {
        console.warn(error);
        if (throwError) {
          throw error
        }
        return null;
      });
  }

  /** @deprecated */
  async safe_fetch(url, options) {
    return await this.request(url, { ...options, method: 'GET' });
  }

  /** @deprecated */
  async post(url, options) {
    return await this.request(url, { ...options, method: 'POST' });
  }

  /** @deprecated */
  async put(url, options) {
    return await this.request(url, { ...options, method: 'PUT' });
  }

  /** @deprecated */
  async delete(url, options) {
    return await this.request(url, { ...options, method: 'DELETE' });
  }
}

export default new AuthManager();
