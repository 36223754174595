import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import '../../../css/EngagementTable.less';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import { register } from '../../../i18n';

import EngagementService from '../../../services/EngagementService';

const EngagementHeaderAuxiliaryTimeOnTaskRatioCells = observer((props) => {
  const { /* managers */ } = useContext(MobXProviderContext);

  const t = register('EngagementTable');

  const renderEngagementHeaderAuxiliaryTimeOnTaskRatioCells = () => {
    return (
      <>
        {renderEngagementHeaderAuxiliaryTimeOnTaskCell({
          allowPopupComponent: true,
          cellText: t('engagedAverageTimePerItem'),
          className: 'engagement-header-label detail-time-on-task-ratio-header engagedAverageTimePerItem',
          isHeaderCell: true,
          popupContent: t('engagedAverageTimePerItemDescription'),
          popupOn: 'click'
        })}
        {renderEngagementHeaderAuxiliaryTimeOnTaskCell({
          allowPopupComponent: true,
          cellText: t('engagedAverageTimePerItemCorrect'),
          className: 'engagement-header-label detail-time-on-task-ratio-header engagedAverageTimePerItemCorrect',
          isHeaderCell: true,
          popupContent: t('engagedAverageTimePerItemCorrectDescription'),
          popupOn: 'click'
        })}
        {renderEngagementHeaderAuxiliaryTimeOnTaskCell({
          allowPopupComponent: true,
          cellText: t('engagedAverageTimePerItemIncorrect'),
          className: 'engagement-header-label detail-time-on-task-ratio-header engagedAverageTimePerItemIncorrect',
          isHeaderCell: true,
          popupContent: t('engagedAverageTimePerItemIncorrectDescription'),
          popupOn: 'click'
        })}
      </>
    );
  };

  const renderEngagementHeaderAuxiliaryTimeOnTaskCell = (props = {}) => {
    return EngagementService.renderEngagementCell(props);
  };

  return renderEngagementHeaderAuxiliaryTimeOnTaskRatioCells();
});
export default EngagementHeaderAuxiliaryTimeOnTaskRatioCells;

SatCoreRegister('EngagementHeaderAuxiliaryTimeOnTaskRatioCells', EngagementHeaderAuxiliaryTimeOnTaskRatioCells);
