import React, { Component } from 'react';
import Iframe from 'react-iframe';
import { PDFObject } from 'react-pdfobject';
import { Button, Confirm, Image } from 'semantic-ui-react';
import { inject } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import Modal from '../Modal';
import Auth from '../../managers/AuthManager';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import '../../css/FileViewerModal.less';
import { CONTENT_ITEM_TYPES, CONTENT_MODE } from '../../managers/ContentManager';
import iconListen from '../../img/icon-listen.svg';

import UtilityService from '../../services/UtilityService';
import ThirdPartyService from '../../services/ThirdPartyService';

export default
@inject('assignmentManager', 'classroomManager', 'contentManager', 'studentContentCardManager', 'userManager')
class FileViewerModal extends Component {
    received = false;

    constructor(props) {
      super(props);
      this.InfoModal = SatCoreComponent('InfoModal');
      this.ScoreAssignmentModal = SatCoreComponent('ScoreAssignmentModal');
      this.state = {
        addInfoOpen: false,
        showScoreModal: false,
        confirmationOpen: false,
        interval: null
      };
    }

    async componentDidMount() {
      const {
        assignmentManager, userManager, activityId, contentItemId,
        contentMode, contentType, isFlowpaper, page, studentContentCardManager
      } = this.props;
      if (page && page === 'file-viewer') {
        window.addEventListener('message', this.handleIframeTask);
      }
      if (contentMode === CONTENT_MODE.ASSESS && !isFlowpaper) {
        const sessionStart = new Date();
        this.setState({
          interval:
        setInterval(async () => {
          const sessionPing = new Date();
          let viewedTime = (sessionPing - sessionStart) / 1000;
          viewedTime = viewedTime.toFixed(0);
          assignmentManager.markActivityViewed(activityId, contentItemId, viewedTime);
        }, 10000)
        });
      }

      if (studentContentCardManager.isLoading) {
        studentContentCardManager.setIsLoading(false);
      }
    }

    async componentWillUnmount() {
      clearInterval(this.state.interval);
      window.removeEventListener('message', this.handleIframeTask);
    }

    handleOpenInfo = () => {
      this.setState({ addInfoOpen: true });
    }

    closeInfoModal = () => {
      this.setState({ addInfoOpen: false });
    }

    handleIframeTask = (e) => {
      if (e.origin === Auth.publisher && e.data === 'closePressed') {
        this.props.closeIframeCallback();
      }
    };

    openGradeAssignment = () => {
      this.setState({ showScoreModal: true });
    }

    closeGradeAssignment = () => {
      this.setState({ showScoreModal: false });
      this.refreshClassroomData();
    }

    refreshClassroomData = async () => {
      const { classroomManager, isFromLTI } = this.props;
      if (!isFromLTI) {
        await classroomManager.fetchClassroomData(classroomManager.currentClassroomId);
      }
    }

    handleOpenDocReader = async () => {
      const { contentManager } = this.props;
      await contentManager.fetchDocReaderUrl(contentManager.currentContentItemId);
      contentManager.openTabContent(contentManager.docReaderUrl);
    }

    submitAndClose = async () => {
      const { assignmentManager, userManager, assignmentId } = this.props;
      if (!userManager.canViewAsTeacher) {
        await assignmentManager.submitActivityInstance(assignmentId);
      }

      this.justClose();
    }

    justClose = async () => {
      const { contentType, closeModalCallback } = this.props;
      if (contentType === CONTENT_ITEM_TYPES.SCORM_RESOURCE) {
        const innerIframe = document.getElementById('myFrame').contentWindow;
        const message = {
          action: 'unload',
          newUrl: 'about:blank', // maybe point to a spinner asset here.
          origin: 'satellite'
        };

        innerIframe.postMessage(message, '*');

        setTimeout(() => {
          closeModalCallback();
        }, 2000);
      } else {
        closeModalCallback();
      }
    }

    startSubmit = async () => {
      this.setState({ confirmationOpen: true });
    }

    closeConfirmation = async () => {
      this.setState({ confirmationOpen: false });
    }

    renderSubmitButton = () => {
      const { userManager, contentType } = this.props;
      if (!userManager.canViewAsTeacher && this.props.contentMode === CONTENT_MODE.ASSESS) {
        return (<Button className='student' onClick={() => this.startSubmit()} primary>Submit</Button>);
      }
      return null;
    }

    renderStudentInstructionButton = () => {
      const { instruction, userManager } = this.props;
      if (instruction && instruction !== '<p></p>') {
        return (
          <Button
            className={(!userManager.canViewAsTeacher ? 'basic student-instruction white' : 'basic white')}
            onClick={() => this.handleOpenInfo()}
            primary>
            Student Instructions
          </Button>
        );
      }
      return null;
    }

    renderScoreButton = () => {
      const { userManager } = this.props;
      if (this.props.contentMode === CONTENT_MODE.REVIEW && userManager.canViewAsTeacher) {
        return (<Button className='basic white' onClick={() => this.openGradeAssignment()} primary>Score</Button>);
      }
      return null;
    }

    renderDocReaderButton = () => {
      const { contentManager, userManager } = this.props;
      if (this.props.isFlowpaper && contentManager.docReaderId !== '' && contentManager.docReaderId !== null && contentManager.docReaderId !== undefined) {
        return (
          <Button className={(!userManager.canViewAsTeacher ? 'basic student white' : 'basic white')} onClick={() => this.handleOpenDocReader()} primary>
            <Image alt='' className='space-right' src={iconListen} />
            {' '}
            Listen
          </Button>
        );
      }
      return null;
    }

    renderFullscreenButton = () => {
      const { contentType, userManager } = this.props;
      if (contentType === CONTENT_ITEM_TYPES.CODEMONKEY_RESOURCE) {
        return (
          <Button className={(!userManager.canViewAsTeacher ? 'basic student white' : 'basic white')}
            onClick={() => this.handleFullscreen()} primary>Fullscreen
          </Button>
        );
      }
      return null;
    }

    renderDivider = () => {
      const { instruction } = this.props;
      if (instruction && instruction !== '<p></p>') {
        return (<div className='divider' />);
      }
      return null;
    }

    handleFullscreen = () => {
      const iframe = document.getElementById('myFrame');
      if (iframe) {
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen();
        } else if (iframe.webkitRequestFullscreen) { // Chrome and Safari
          iframe.webkitRequestFullscreen();
        } else if (iframe.mozRequestFullScreen) { // FFox
          iframe.mozRequestFullScreen();
        } else if (iframe.msRequestFullscreen) { // IE
          iframe.msRequestFullscreen();
        }
      }
    }

    renderResourceUrl() {
      const {
        userManager, contentManager, contentMode, url, instruction, isFlowpaper, contentName,
        contentType, contentImageUrl, studentName, closeModalCallback, t
      } = this.props;
      const { currentContentItemId } = contentManager;
      const { addInfoOpen, confirmationOpen } = this.state;
      const { InfoModal } = this;
      const transformFn = UtilityService.reactHtmlParserTransform;

      return (
        <Modal className='f-player-modal' open size='fullscreen'>
          <div className='f-header'>
            <div className='modal-title-text'>
              {
                userManager.canViewAsTeacher && contentMode === 'review' ? (
                  <p>
                    {t('gradebookLabel', 'FileViewerModal translation missing.')}
                    :
                    {' '}
                    {ReactHtmlParser(studentName, { transform: transformFn })}
                  </p>
                ) :
                  <p>{ReactHtmlParser(contentName, { transform: transformFn })}</p>
              }
            </div>
            <div className='modal-title-button'>
              {this.renderFullscreenButton()}
              {/* {this.renderDocReaderButton()} CF-4585 */}
              {this.renderStudentInstructionButton()}
              {this.renderDivider()}
              {this.renderScoreButton()}
              <Button className={(!userManager.canViewAsTeacher ? 'basic student white' : '')}
                onClick={() => this.justClose()} primary>Close
              </Button>
              {this.renderSubmitButton()}
            </div>
          </div>
          <div className='f-content'>
            {(isFlowpaper || contentType === CONTENT_ITEM_TYPES.BOOKMARK_RESOURCE ||
            CONTENT_ITEM_TYPES.isExternalAppType(contentType)) && (
              <Iframe height='100%' id='myFrame' position='relative' title={UtilityService.stripHtmlTagsAndStrip(contentName)}
                url={url} width='100%' />
            )}
          </div>
          <InfoModal
            addInfoOpen={addInfoOpen}
            closeInfoModal={this.closeInfoModal}
            contentItemDescription={null}
            imageUrl={contentImageUrl}
            infoName={contentName}
            resourceInfo={null}
            studentInstruction={instruction} />
          <Confirm
            cancelButton='No'
            confirmButton='Yes'
            content='Submitting this assignment will give it to your teacher.  Are you ready to submit?'
            header='Submit Assignment'
            onCancel={() => this.setState({ confirmationOpen: false })}
            onConfirm={() => {
              this.submitAndClose();
              this.setState({ confirmationOpen: false });
            }}
            open={confirmationOpen} />
        </Modal>
      );
    }

    renderPdf() {
      const { userManager } = this.props;
      const { InfoModal, ConfirmationModal } = this;
      // interface Props {
      //   url: string;
      //   containerId?: string;
      //   containerProps?: React.HTMLProps<HTMLDivElement>;
      //   width?: string;
      //   height?: string;
      //   page?: string | number;
      //   id?: string;
      //   fallbackLink?: string | false;
      // }
      const transformFn = UtilityService.reactHtmlParserTransform;
      return (
        <Modal
          className='f-player-modal'
          onMount={() => document.body.classList.add('body-f-player-modal')}
          onUnmount={() => document.body.classList.remove('body-f-player-modal')}
          open
          size='fullscreen'>
          <div className='f-header'>
            <div className='modal-title-text'>
              {ReactHtmlParser(this.props.contentName, {
                transform: transformFn
              })}
            </div>
            <div className='modal-title-button'>
              {this.renderStudentInstructionButton()}
              {this.renderDivider()}
              {this.renderScoreButton()}
              {this.renderSubmitButton()}
              <Button
                className={(!userManager.canViewAsTeacher ? 'basic student white' : '')}
                onClick={() => this.props.closeModalCallback()}
                primary>
                Close
              </Button>
            </div>
          </div>
          <div className='f-content'>
            <PDFObject height='100%' url={this.props.url} />
          </div>
          <InfoModal
            addInfoOpen={this.state.addInfoOpen}
            closeInfoModal={this.closeInfoModal}
            contentItemDescription={null}
            imageUrl={this.props.contentImageUrl}
            infoName={this.props.contentName}
            resourceInfo={null}
            studentInstruction={this.props.instruction} />
          <Confirm
            cancelButton='No'
            confirmButton='Yes'
            content='Submitting this assignment will give it to your teacher.  Are you ready to submit?'
            header='Submit Assignment'
            onCancel={() => this.setState({ confirmationOpen: false })}
            onConfirm={() => {
              this.submitAndClose();
              this.setState({ confirmationOpen: false });
            }}
            open={this.state.confirmationOpen} />
        </Modal>
      );
    }

    render() {
      const { ScoreAssignmentModal, closeGradeAssignment, grade } = this;
      const { isFlowpaper, contentType, assignmentId, maxScore, studentName, totalScore } = this.props;
      const { showScoreModal } = this.state;

      return (
        <>
          {(isFlowpaper || CONTENT_ITEM_TYPES.isExternalAppType(contentType) || contentType === CONTENT_ITEM_TYPES.BOOKMARK_RESOURCE) ?
            this.renderResourceUrl()
            :
            this.renderPdf()}
          {/* Using the modal directly as the dialogManager doesn't seem to work for modals within the lti iFrame */}
          <ScoreAssignmentModal
            activityInstanceId={assignmentId}
            closeGradeAssignment={closeGradeAssignment}
            grade={(grade) || 0}
            gradeAssignmentOpen={showScoreModal}
            maxScore={(maxScore) || 100}
            studentName={studentName}
            totalScore={(totalScore) || 0} />
        </>
      );
    }
}
SatCoreRegister('FileViewerModal', FileViewerModal);
