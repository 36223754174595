import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { Popup, Table } from 'semantic-ui-react';

import classNames from 'classnames';

import '../../../css/EngagementTable.less';

// import iconDisengage from '../../../img/icon-disengage.svg';
import iconFeedbackDarkYellow from '../../../img/icon-feedback-dark-yellow.svg';
import iconSpeed from '../../../img/icon-speed.svg';

import { register } from '../../../i18n';

import { SatCoreRegister } from '../../../SatCoreRegistry';

import EngagementService from '../../../services/EngagementService';
import PopupService from '../../../services/PopupService';

const EngagementRowCoreCells = observer((props = {}) => {
  const { coreData, engagementData, getAssignmentNumberCell } = props;

  const { /* managers */ } = useContext(MobXProviderContext);

  const allowEngagementFlagColumn = EngagementService.allowEngagementFlagColumn();

  const t = register('EngagementTable');

  const renderEngagementRowCoreCells = () => {
    return (
      <>
        {/* activity number */}
        <Table.Cell className={classNames('engagement-core-cell engagement-activity-number-cell')}>
          {getAssignmentNumberCell(coreData, t)}
        </Table.Cell>
        {/* first name */}
        <Table.Cell className={classNames('engagement-core-cell engagement-first-name-cell')}>
          <Popup
            className='gradebook-summary-table-popup'
            content={coreData.firstName}
            hoverable
            on='hover'
            position='top center'
            positionFixed
            trigger={<span>{coreData.firstName}</span>} />
        </Table.Cell>
        {/* last name */}
        <Table.Cell className={classNames('engagement-core-cell engagement-last-name-cell')}>
          <Popup
            className='gradebook-summary-table-popup'
            content={coreData.lastName}
            hoverable
            on='hover'
            position='top center'
            positionFixed
            trigger={<span>{coreData.lastName}</span>} />
        </Table.Cell>
        {/* flag icon(s) */}
        {allowEngagementFlagColumn && (
          <Table.Cell className={classNames('engagement-core-cell engagement-flag-cell')}>
            <>
              {engagementData.engagement && (
                PopupService.renderPopup({
                  content: t('flagSpeedDescription'),
                  position: 'top center',
                  trigger: <img alt='' src={iconSpeed} />,
                  wide: ''
                })
              )}
              {engagementData.supportToolUse && (
                PopupService.renderPopup({
                  content: t('flagFeedbackDescription'),
                  position: 'top center',
                  trigger: <img alt='' src={iconFeedbackDarkYellow} />,
                  wide: ''
                })
              )}
            </>
          </Table.Cell>
        )}
      </>
    );
  };
  return renderEngagementRowCoreCells();
});
export default EngagementRowCoreCells;

SatCoreRegister('EngagementRowCoreCells', EngagementRowCoreCells);
