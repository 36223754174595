import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { Image } from 'semantic-ui-react';

import iconInfo from '../img/icon-info.svg';
import iconInfoWhite from '../img/icon-info-white.svg';

import '../css/InfoIcon.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import PopupService from '../services/PopupService';

const InfoIcon = observer((props = {}) => {
  const { popupOptions = {} } = props;
  const {
    content,
    disabled = false,
    header = null,
    offset = null,
    on = 'hover',
    onClose = null,
    onOpen = null,
    open,
    position = 'top center',
    wide = ''
  } = popupOptions;

  const { dialogManager } = useContext(MobXProviderContext);

  const className = classNames('InfoIcon', props.className);

  const useWhiteInfoIcon = typeof props.useWhiteInfoIcon === 'boolean' ? props.useWhiteInfoIcon : dialogManager.useWhiteInfoIcon;
  const icon = props.icon || (useWhiteInfoIcon ? iconInfoWhite : iconInfo);

  return PopupService.renderPopup({
    className,
    content,
    disabled: disabled || !content,
    header,
    offset,
    on,
    onClose,
    onOpen,
    open,
    position,
    trigger: (
      <Image alt='' className={classNames('InfoIcon-trigger', props.className)} spaced src={icon} />
    ),
    wide: wide === 'very' ? wide : ''
  });
});
export default InfoIcon;

SatCoreRegister('InfoIcon', InfoIcon);
