import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, Card, Image } from 'semantic-ui-react';

import classNames from 'classnames';

import '../css/SystemNotificationCard.less';

import iconTrash from '../img/icon-trash-can-gray.svg';

import { SatCoreRegister } from '../SatCoreRegistry';

import UtilityService from '../services/UtilityService';
import NotificationService from '../services/NotificationService';

import { unfocus } from '../utils';

const SystemNotificationCard = observer((props = {}) => {
  const { onCustomButtonClick, notification, showDelete, t } = props;

  const {
    creationTime,
    displayName,
    downloadableFiles,
    message,
    metadata
  } = notification;

  const { userManager } = useContext(MobXProviderContext);

  const metadataJson = metadata ? JSON.parse(metadata) : {};

  return (
    <Card className='SystemNotificationCard' fluid>
      <Card.Content>
        <Card.Header>
          <div className='notification-card-header-left'>
            <div className='notification-card-datetime-label'>
              {creationTime}
            </div>
            <div className='notification-card-user-label'>
              {displayName}
            </div>
          </div>
          <div className='notification-card-header-mid'>
            {/* placeholder */}
          </div>
          <div className='notification-card-header-right'>
            {showDelete && (
              <button className='notification-card-trash-icon-wrapper unstyled-button'
                onClick={() => {
                  props.handleDeleteSystemNotification?.(notification);
                  unfocus();
                }}
                type='button'>
                <Image alt='' src={iconTrash} />
              </button>
            )}
          </div>
        </Card.Header>
        <Card.Description>
          <div className={classNames('messageBodyFull')}>
            <div className='notification-card-message-wrapper'>
              {UtilityService.reactHtmlParserWrapper(message || '')?.parsed}
            </div>
            <div className='notification-card-attachments-wrapper'>
              {downloadableFiles?.map?.((attachment) => {
                return (
                  <button key={attachment.id}
                    className='notification-card-attachment unstyled-button'
                    onClick={() => {
                      const attachmentUrl = NotificationService.getNotificationAttachmentUrl(attachment);
                      window.open(attachmentUrl, '_blank');
                      unfocus();
                    }}
                    type='button'>
                    {t('downloadAttachment', { attachmentName: attachment.displayName })}
                  </button>
                );
              })}
            </div>
            {notification.type === 'share_assessment' &&
              <div className='notification-card-button-wrapper'>
                  <Button
                    className=''
                    basic
                    primary
                    onClick={() => {
                      onCustomButtonClick({type: 'preview-shared-assessment', value: notification});
                      unfocus();
                    }}>
                    {t('preview', 'Preview')}
                  </Button>
                  {(metadataJson.allowEdit && !userManager.isTeacher && userManager.canViewLibraryItemBank) &&
                    <Button
                      className=''
                      basic
                      primary
                      onClick={() => {
                        onCustomButtonClick({type: 'edit-shared-assessment', value: notification});
                        unfocus();
                      }}>
                      {t('edit', 'Edit')}
                    </Button>
                  }
              </div>
            } 
          </div>
        </Card.Description>
      </Card.Content>
    </Card>
  );
});
export default SystemNotificationCard;

SatCoreRegister('SystemNotificationCard', SystemNotificationCard);
